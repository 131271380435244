import moment from 'moment';

import i18n from '../../../i18n';
import {
  MONTH_FILTER_OPTIONS_ENUM,
  Months,
  UtilizationActionEnum,
} from '../../../utils/enums/projectUtilization';
import {
  IObjectProjectResource,
  IObjectProjectUtilization,
} from '../../../utils/types/commonTypes';
import { ProjectEmployeeWorkloadState } from '../../../utils/enums/project';
import { IObjectTimespan } from '../ResourceOverview/resourceOverviewHelper';

const DEFAULT_ACTION_COLOR = '#EDEDED';
const ACTION_DESIRED_COLOR = '#FFFCC9';
const ACTION_NECESSARY_COLOR = '#FF7276';

const BOOKED_COLOR = '#4192C4';
const BOOKED_OPEN_RESERVED_COLOR = '#CAE9FF';
const PLANNED_COLOR = '#B2CC7B';

export const t = (keyName: string) => i18n.t(`UtilizationOverview.${keyName}`);

export const workloadt = (keyName: string) =>
  i18n.t(`WorkloadState.${keyName}`);

/**
 * Gets the next 3 months based on the criteria of filter
 * @param monthFilter basis of what should be the 1st month to be recorded or viewed
 * @returns 3 months based on the start of month from filter
 */
export const getMonthRange = (monthFilter: string) => {
  const monthRange = [];
  const currentDate = moment();
  let dateByFilter;

  switch (monthFilter) {
    case MONTH_FILTER_OPTIONS_ENUM.lastMonth.code:
      dateByFilter = currentDate.subtract(1, 'month');
      break;
    case MONTH_FILTER_OPTIONS_ENUM.nextMonth.code:
      dateByFilter = currentDate.add(1, 'month');
      break;
    default:
      dateByFilter = currentDate;
      break;
  }

  for (let i = 0; i < 3; i += 1) {
    const date = i === 0 ? dateByFilter : dateByFilter.add(i, 'months');
    const monthYearValue = {
      month: date.month(),
      year: date.year(),
      label: Months[date.month()],
    } as IObjectTimespan;

    monthRange.push(monthYearValue);
  }

  return monthRange;
};

/**
 *
 * @param month month to be converted
 * @param year year to be appended
 * @returns a string name with format MMM(YYYY)
 */
export const getMonthAndYearTitle = (month: string, year: number) => {
  const monthAbbreviation = month.substring(0, 3);
  return `${monthAbbreviation}(${year})`;
};

/**
 * Function to check if monthName "month" is the current month
 * @param {*} monthObject - month object, contains the following property: value, label, year
 * @returns
 */
export const checkIfCurrentMonthAndYear = (month: number, year: number) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  return currentMonth === month && year === currentYear;
};

/**
 * Computes the total workload of the employee per project in a month
 * @param projectUtilization of employee to be computed
 * @param month reference for filtering
 * @returns a value of computed workload in a specific month
 */
export const totalWorkloadUtilizationByMonth = (
  projectUtilization: (IObjectProjectResource | IObjectProjectUtilization)[],
  month: number
) => {
  let totalWorkloadInAMonth = 0;
  const workloadUtilization: { workloadPercentage: number; month: number }[] =
    [];
  projectUtilization?.forEach((item) => {
    if ('workloadUtilizations' in item) {
      workloadUtilization.push(...item.workloadUtilizations);
    }
  });
  const workloadByMonth = workloadUtilization.filter(
    ({ month: workloadMonth }) => workloadMonth === month + 1
  );

  workloadByMonth?.forEach(
    ({ workloadPercentage }) => (totalWorkloadInAMonth += workloadPercentage)
  );
  return totalWorkloadInAMonth.toFixed(2);
};

/**
 * Handles changing of color depending on current action
 * @param action
 * @returns color of table cells to render
 */
export const changeActionColor = (action: string) => {
  switch (action) {
    case UtilizationActionEnum.NO_ACTION.toString():
      return DEFAULT_ACTION_COLOR;
    case UtilizationActionEnum.ACTION_DESIRED.toString():
      return ACTION_DESIRED_COLOR;
    case UtilizationActionEnum.ACTION_NECESSARY.toString():
      return ACTION_NECESSARY_COLOR;
    default:
      return DEFAULT_ACTION_COLOR;
  }
};

/**
 * Changes the color of cell depending on the state
 * @param workloadState
 * @param workloadPercentage
 * @returns return the color of the cell
 */
export const changeCellColorByState = (
  workloadState: string,
  workloadPercentage: number
) => {
  if (
    workloadState === ProjectEmployeeWorkloadState.BOOKED &&
    workloadPercentage > 0
  ) {
    return BOOKED_COLOR;
  }
  if (
    workloadState === ProjectEmployeeWorkloadState.BOOKED_AND_OPEN &&
    workloadPercentage > 0
  ) {
    return BOOKED_OPEN_RESERVED_COLOR;
  }
  if (
    workloadState === ProjectEmployeeWorkloadState.RESERVED &&
    workloadPercentage > 0
  ) {
    return PLANNED_COLOR;
  }
  return undefined;
};
