import { TextField, Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { getContactPersonRoleItems } from '../../../services/api/contactPerson';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IContactPersonRole } from '../../../utils/types/modelTypes';

interface IProps extends IWithModalsProps {
  onChange: (newRole: string) => void;
  role: string | null;
}

const ContactRoleItem = ({ modalErrorHandler, role, onChange }: IProps) => {
  const [suggestions, setSuggestions] = useState<IContactPersonRole[]>([]);

  const fetchAllRoles = async () => {
    try {
      const { data } = await getContactPersonRoleItems();
      setSuggestions(data);
    } catch (error) {
      modalErrorHandler(
        i18n.t('ContactPersonFormInput.failedToGetAllContactPersonRoles'),
        error
      );
    }
  };

  useEffect(() => {
    fetchAllRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      data-testid="autocomplete-role"
      inputValue={role ?? ''}
      onInputChange={(_event, value) => onChange(value)}
      freeSolo
      style={{ padding: '0px' }}
      options={suggestions.map(({ role }) => role)}
      renderInput={({ InputLabelProps, ...params }) => (
        <TextField
          {...params}
          size="medium"
          placeholder={i18n.t('ContactPersonFormInput.role')}
        />
      )}
    />
  );
};

export default withModals(ContactRoleItem);
