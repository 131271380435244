import { PayloadAction } from '@reduxjs/toolkit';

import { getEmployeeNames, getListOfResponsibleNames } from '../services/api/employee';
import { Ownership } from '../utils/enums/ownership';
import {
  AccessType,
  PERMISSION_URI,
} from '../utils/enums/permission';
import { SortBy, SortType } from '../utils/enums/sort';
import { maxPageLength } from '../utils/constants';
import { objectNameAndIdToDropdownOptions } from '../utils/helpers/dropdown';
import { removeDuplicates } from '../utils/helpers/table';
import { createQueryParameters } from '../pages/crm/ContactList/contactListHelpers';
import { IDropdownOption } from '../utils/types/commonTypes';
import { IContactPersonListItem } from '../utils/types/responseTypes';
import { IContactPersonListFilters } from '../utils/types/stateTypes';
import createListSlice, { ListState } from './createListSlice';
import type { AppThunk } from './store';
import {
  deleteContactPerson as deleteContactPersonItem,
  getContactPersonListItems,
} from '../services/api/contactPerson';
import { objectTypeEnum } from '../utils/enums/enum';

type AdditionalState = {
  responsibles: IDropdownOption<number>[];
  involvedResponsibles: IDropdownOption<number>[];
  employeesWithPermission: IDropdownOption<number>[];
};

type ContactPersonListState = ListState<
  IContactPersonListItem,
  IContactPersonListFilters,
  AdditionalState
>;

const initialState: ContactPersonListState = {
  listItems: [],
  hasMore: true,
  page: 0,
  pageSize: 0,
  pageCount: 0,
  filters: {
    lastname: '',
    firstname: '',
    customerAccount: '',
    customerName: '',
    customerSiteName: '',
    target: [],
    acquisition: [],
    nextAction: [],
    dueDate: null,
    responsible: [],
    involvedEmployees: [],
    statusGdpr: [],
    assignmentState: [],
    ownership: Ownership.RESPONSIBLE,
    responsibleWithPermission: null,
  },
  showFilters: false,
  sortBy: SortBy.LAST_NAME,
  sortType: SortType.ASCENDING,
  additionalState: {
    responsibles: [],
    involvedResponsibles: [],
    employeesWithPermission: [],
  },
};

/**
 * Contact Person List Slice - contains the state of the Contact Person List page
 *
 * 1. List of contact person
 * 2. Page of items to be fetched and if there is more data to fetch
 * 3. List of contact person responsibles
 * 4. List of contact person involved responsibles
 * 5. List of employees with contact person list permission
 * 6. Applied filters
 * 7. Applied sorting criteria
 */
const contactPersonList = createListSlice({
  name: 'contactPersonList',
  initialState,
  reducers: {
    setResponsibles: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        responsibles: [...payload],
      },
    }),
    setInvolvedResponsibles: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        involvedResponsibles: [...payload],
      },
    }),
    setEmployeesWithPermission: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        employeesWithPermission: [...payload],
      },
    }),
  },
});

export const {
  removeListItem,
  setListItems,
  setHasMoreListItems,
  setPage,
  setFilters,
  toggleFilter,
  setSortBy,
  setSortType,
  setResponsibles,
  setInvolvedResponsibles,
  setEmployeesWithPermission,
} = contactPersonList.actions;

/**
 * Thunk for fetching a single contact person list item and updating contact person list slice
 * @param contactPersonId Id of contact person list item to fetch
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching a single contact person list item
 */
export const fetchContactPersonListItem =
  (contactPersonId: number, errorHandler: (error: any) => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        contactPersonList: { listItems },
      } = getState();

      const { data: fetchedListItem } = await getContactPersonListItems({
        'id.equals': contactPersonId.toString(),
      });

      dispatch(setListItems(removeDuplicates(listItems, fetchedListItem)));
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching contact person list items and updating contact person list slice
 * @param isSortOrFilterFetchType Whether fetching due to sort / filter / scroll update
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching contact person list items
 */
export const fetchContactPersonListItems =
  (
    isSortOrFilterFetchType: boolean,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        contactPersonList: { page, filters, sortBy, sortType, listItems },
      } = getState();

      const { data: fetchedListItems } = await getContactPersonListItems(
        createQueryParameters(page, filters, sortBy, sortType)
      );

      const processedListItems = isSortOrFilterFetchType
        ? [...fetchedListItems]
        : removeDuplicates(listItems, fetchedListItems);

      dispatch(
        setPage(!(fetchedListItems.length < maxPageLength) ? page + 1 : page)
      );
      dispatch(setHasMoreListItems(!(fetchedListItems.length < maxPageLength)));
      dispatch(setListItems(processedListItems));
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for deleting contact person and updating contact person list slice
 * @param contactPersonId Id of contact person to delete
 * @param successHandler Function for handling logic to execute upon successful contact person deletion
 * @param errorHandler Function for handling errors from deleting contact person
 * @returns Inner thunk function containing async logic for deleting contact person
 */
export const deleteContactPerson =
  (
    contactPersonId: number,
    successHandler: () => void,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      await deleteContactPersonItem(contactPersonId);

      dispatch(removeListItem(contactPersonId));
      successHandler();
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching contact person responsibles and updating contact person list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching contact person responsibles
 */
export const fetchContactPersonResponsibles =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedResponsibleNames } = await getListOfResponsibleNames(
        {
          'ownership.in': Ownership.RESPONSIBLE,
          'objectType.equals': objectTypeEnum.contactPerson.code,
        }
      );

      dispatch(
        setResponsibles(
          objectNameAndIdToDropdownOptions(fetchedResponsibleNames)
        )
      );
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching contact person involved responsibles and updating contact person list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching contact person responsibles
 */
export const fetchContactPersonInvolvedResponsibles =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedInvolvedResponsibleNames } =
        await getListOfResponsibleNames({
          'ownership.in': Ownership.INVOLVED,
          'objectType.equals': objectTypeEnum.contactPerson.code,
        });

      dispatch(
        setInvolvedResponsibles(
          objectNameAndIdToDropdownOptions(fetchedInvolvedResponsibleNames)
        )
      );
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching employees with contact person list permission and updating project list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching employees with contact person list permission
 */
export const fetchEmployeesWithContactPersonListPermission =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedEmployees } = await getEmployeeNames({
        'permissionsFilter.in': PERMISSION_URI.contactPeopleList.readWrite.uri,
        'accessTypeFilter.in': AccessType.READWRITE,
      });

      dispatch(
        setEmployeesWithPermission(
          objectNameAndIdToDropdownOptions(fetchedEmployees)
        )
      );
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for updating contact person due to editing of contact person or adding contact person site, and updating contact person list slice
 * @param contactPersonId Id of contact person to update
 * @returns Inner thunk function containing async logic for updating contact person
 */
export const updateContactPersonListItem =
  (contactPersonId: number): AppThunk =>
  async (dispatch, getState) => {
    const {
      contactPersonList: { listItems },
    } = getState();

    const {
      data: [updatedListItem],
    } = await getContactPersonListItems({
      'id.equals': contactPersonId.toString(),
    });

    const updatedListItems = [...listItems];
    if (updatedListItem) {
      updatedListItems[
        updatedListItems.findIndex(({ id }) => id === contactPersonId)
      ] = updatedListItem;
    }

    dispatch(setListItems(updatedListItems));
  };

export default contactPersonList.reducer;
