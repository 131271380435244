import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import Select from 'react-select';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import InputFormLabel from '../../../components/form/InputFormLabel';
import {
  DISQUALIFIED,
  MAIL_SENT,
  MET_PERSONALLY,
  NOT_REACHED,
  PROSPECTION_CANCELLED,
  PROSPECTION_DONE,
  PROSPECTION_POSTPONED,
  REACHED,
  REACHED_AND_APPOINTMENT,
  REACHED_AND_DISQUALIFIED,
  REACHED_AND_INTERESTED,
  REACHED_AND_SEND_EMAIL,
  SOCIAL_MEDIA_CONTACT,
} from '../../../utils/constants';
import { ContactStatus } from '../../../utils/enums/contact';
import {
  CONFIRM_OR_DISQUALIFY,
  NEXT_ACTION_ENUMS,
} from '../../../utils/enums/activity';
import { IContactPersonAssignment } from '../../../utils/types/modelTypes';
import ModalConfirmOrDisqualify from './ModalConfirmOrDisqualify';
import { addWeekdays } from '../../../utils/helpers/date';
import Auth from '../../../services/axios/Auth';
import { AccessType, PERMISSION_URI } from '../../../utils/enums/permission';
import { ContactPersonDetailsContext } from './ContactPersonDetailsProvider';
import {
  translateModalAddActivity as t,
  weekdaysOnly,
} from './contactPersonHelper';

interface IProps {
  modal: boolean;
  buttonAction: string;
  target: boolean;
  currentStatus: IContactPersonAssignment;
  size: string;
  noScheduledRecall: boolean;
  commentsValue: string;

  toggle: () => void;
  addComments: React.Dispatch<React.SetStateAction<string>>;
  handleChange: (key: string, value: any) => void;
  updateStatus: (confirmAndAddProjectOption?: boolean) => Promise<void>;
  cancelMeeting: (checked: boolean) => void;
  deleteContactPerson: () => void;
  resetContactPerson: () => void;
  changeNoScheduledRecall: (noScheduledRecall: boolean) => void;
}

/**
 * Modal to show the form for the selected action button
 */
const ModalAction = ({
  modal,
  buttonAction,
  target,
  currentStatus,
  commentsValue,
  size,
  noScheduledRecall,
  toggle,
  addComments,
  handleChange,
  updateStatus,
  cancelMeeting,
  deleteContactPerson,
  resetContactPerson,
  changeNoScheduledRecall,
}: IProps) => {
  const { contactDetail } = useContext(ContactPersonDetailsContext);

  const [call, setCall] = useState(moment());
  const [meeting, setMeeting] = useState(moment());
  const [dueDate, setDueDate] = useState(moment());
  const [comments, setComments] = useState(commentsValue);
  const [nextAction, setAction] = useState<string | undefined>('');
  const [meetingType, setMeetingType] = useState('');
  const [meetingTime, setMeetingTime] = useState(moment);
  const [showModalConfirmOrDisqualify, setShowModalConfirmOrDisqualify] =
    useState(false);

  const userHasPermission = Auth.hasPermission(
    [PERMISSION_URI.projectOptions.readWrite.uri],
    [AccessType.READWRITE],
    -1
  );
  const hasCustomerOrCustomerSite =
    !!contactDetail.customer?.id || !!contactDetail.customerSite?.id;

  const { dueDate: date, nextAction: action, assignmentState } = currentStatus;

  useEffect(() => {
    if (date) {
      // Removed "date" from moment instead of wholesale removing this
      // This is to keep the logic if in case having an arealy existing next activity changes the need
      setCall(moment().add(1, 'day'));
      setMeeting(moment().add(3, 'day'));
      setMeetingTime(moment().add(3, 'day'));
      setDueDate(moment().add(3, 'day'));
      handleChange('dueDate', moment().add(3, 'day'));
    }

    switch (buttonAction) {
      case REACHED_AND_INTERESTED:
        handleChange('recall', call ? moment(call) : moment().add(6, 'week'));
        setCall(addWeekdays(moment(), 1));
        break;
      case NOT_REACHED:
        if (call) {
          handleChange('recall', moment(call));
        } else {
          handleChange('recall', moment().add(1, 'day'));
          setCall(moment().add(1, 'day'));
        }
        break;
      case REACHED_AND_APPOINTMENT:
        handleChange('meeting', moment(meeting));
        handleChange('meetingTime', moment(meetingTime));
        break;
      case REACHED_AND_SEND_EMAIL:
        handleChange('dueDate', dueDate ? moment(dueDate) : moment());
        break;
      case MAIL_SENT:
      case REACHED:
      case PROSPECTION_POSTPONED:
        if (
          assignmentState === ContactStatus.PROSPECT.code ||
          assignmentState === ContactStatus.MEETING.code
        ) {
          setDueDate(moment(date));
          handleChange('dueDate', moment(date));
          setAction(action);
          handleChange('nextAction', action);
        }
        break;
      default:
        break;
    }

    if (assignmentState === ContactStatus.PROSPECT.code) {
      setMeetingType('Prospection');
    }
    if (assignmentState === ContactStatus.MEETING.code) {
      setMeetingType('Follow up');
    }
    handleChange('target', target);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonAction, target, date, action, assignmentState]);

  /**
   * Handles the cancel button
   */
  const handleCancel = () => {
    setComments('');
    toggle();
  };

  /**
   * Handles the checkbox value change
   * @param {*} event - React.ChangeEvent<HTMLInputElement>
   */
  const handleNoScheduledRecallCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    changeNoScheduledRecall(event.target.checked);
  };

  /**
   * Displays JSX Elements depending on the selected action button
   * @returns JSX Elements
   */
  const bodyForm = () => {
    let labelComponent = null;
    switch (buttonAction) {
      case NOT_REACHED:
      case REACHED_AND_INTERESTED:
        return (
          <>
            <FormGroup>
              <Row>
                <Col>
                  <Label>{t('recall')}:</Label>
                </Col>
              </Row>
              <div style={{ display: 'flex', paddingRight: '5px' }}>
                <div style={{ flex: 1, marginRight: '20px' }}>
                  <Datetime
                    onChange={(event) => {
                      handleChange('recall', moment(event));
                      setCall(moment(event));
                    }}
                    dateFormat
                    timeFormat={false}
                    value={call}
                    closeOnSelect
                    isValidDate={(current: Moment) =>
                      buttonAction === NOT_REACHED
                        ? weekdaysOnly(current)
                        : true
                    }
                  />
                </div>
                <div
                  style={{ flex: 1, marginLeft: '20px', paddingRight: '5px' }}
                >
                  <Label style={{ display: 'inline-block' }}>
                    <Input
                      aria-label="noScheduledRecall-checkbox"
                      type="checkbox"
                      name="no_scheduled_recall"
                      id="noScheduledRecall"
                      checked={noScheduledRecall}
                      onChange={handleNoScheduledRecallCheckbox}
                    />
                    {t('noScheduledRecall')}
                  </Label>
                </div>
              </div>
            </FormGroup>
            {!noScheduledRecall && (
              <FormGroup>
                <Label>{t('addTimeToRecallDate')}:</Label>
                <Datetime
                  onChange={(event) => {
                    handleChange('recallTime', moment(event));
                  }}
                  dateFormat={false}
                  timeFormat
                  closeOnSelect
                />
              </FormGroup>
            )}
          </>
        );
      case REACHED_AND_SEND_EMAIL:
        return (
          <FormGroup>
            <Label>{t('dueDate')}:</Label>
            <Datetime
              onChange={(event) => {
                setDueDate(moment(event));
                handleChange('dueDate', moment(event));
              }}
              dateFormat
              timeFormat={false}
              closeOnSelect
              value={dueDate}
            />
          </FormGroup>
        );
      case REACHED_AND_APPOINTMENT:
        return (
          <>
            <FormGroup>
              <Label>{t('meetingDay')}:</Label>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginRight: '20px' }}>
                  <Datetime
                    onChange={(event) => {
                      handleChange('meeting', moment(event));
                      setMeeting(moment(event));
                    }}
                    dateFormat
                    timeFormat={false}
                    closeOnSelect
                    value={meeting}
                  />
                </div>
                <div
                  style={{ flex: 1, marginLeft: '20px', paddingRight: '5px' }}
                >
                  <Label style={{ display: 'inline-block' }}>
                    <Input
                      type="checkbox"
                      name="no_scheduled_recall"
                      id="noScheduledRecall"
                      checked={noScheduledRecall}
                      onChange={handleNoScheduledRecallCheckbox}
                    />
                    {t('noScheduledRecall')}
                  </Label>
                </div>
              </div>
            </FormGroup>
            <FormGroup>
              {!noScheduledRecall && (
                <>
                  <Label>{t('meetingTime')}:</Label>
                  <Datetime
                    onChange={(event) => {
                      handleChange('meetingTime', moment(event));
                      setMeetingTime(moment(event));
                    }}
                    dateFormat={false}
                    timeFormat
                    closeOnSelect
                    value={meetingTime}
                  />
                </>
              )}
            </FormGroup>
          </>
        );
      case MAIL_SENT:
        return (
          <>
            <FormGroup>
              <Label>{t('nextActivity')}:</Label>
              <Select
                options={Object.values(NEXT_ACTION_ENUMS).map((action) => ({
                  label: action,
                  value: action,
                }))}
                onChange={(event: { label: string; value: string }) => {
                  handleChange('nextAction', event.label);
                  setAction(event.label);
                }}
                value={Object.values(NEXT_ACTION_ENUMS)
                  .map((action) => ({
                    label: action,
                    value: action,
                  }))
                  .filter(({ label }) => label === nextAction)}
                isDisabled={assignmentState === ContactStatus.MEETING.code}
              />
            </FormGroup>
            <FormGroup>
              <Label>{t('dueDate')}:</Label>
              <Datetime
                onChange={(event) => {
                  setDueDate(moment(event));
                  handleChange('dueDate', moment(event));
                }}
                dateFormat
                timeFormat={false}
                closeOnSelect
                value={dueDate}
              />
            </FormGroup>
          </>
        );
      case REACHED:
        return (
          <FormGroup>
            <Label>{t('nextActivity')}:</Label>
            <Select
              options={Object.values(NEXT_ACTION_ENUMS).map((action) => ({
                label: action,
                value: action,
              }))}
              onChange={(event: { label: string; value: string }) => {
                handleChange('nextAction', event.label);
                setAction(event.label);
              }}
              value={Object.values(NEXT_ACTION_ENUMS)
                .map((action) => ({
                  label: action,
                  value: action,
                }))
                .filter(({ label }) => label === nextAction)}
              isDisabled={
                buttonAction === REACHED &&
                assignmentState === ContactStatus.MEETING.code
              }
            />
          </FormGroup>
        );
      case MET_PERSONALLY:
      case SOCIAL_MEDIA_CONTACT:
        return (
          <>
            <FormGroup>
              <InputFormLabel
                text={`${t('circumstancesContent')}:`}
                isRequired
              />
              <Input
                aria-label="circumstances-text-input"
                type="textarea"
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setComments(event.target.value);
                }}
              />
              <br />
              <Label>{t('nextActivity')}:</Label>
              <Select
                options={Object.values(NEXT_ACTION_ENUMS).map((action) => ({
                  label: action,
                  value: action,
                }))}
                onChange={(event: { label: string; value: string }) => {
                  handleChange('nextAction', event.label);
                  setAction(event.label);
                }}
                value={Object.values(NEXT_ACTION_ENUMS)
                  .map((action) => ({
                    label: action,
                    value: action,
                  }))
                  .filter(({ label }) => label === nextAction)}
                isDisabled={
                  assignmentState === ContactStatus.PROSPECT.code ||
                  assignmentState === ContactStatus.MEETING.code
                }
              />
              <br />
              <Label>{t('dueDate')}:</Label>
              <Datetime
                onChange={(event) => {
                  setDueDate(moment(event));
                  handleChange('dueDate', moment(event));
                }}
                dateFormat
                timeFormat={false}
                closeOnSelect
                value={dueDate}
                inputProps={{
                  disabled:
                    assignmentState === ContactStatus.PROSPECT.code ||
                    assignmentState === ContactStatus.MEETING.code,
                }}
              />
              <br />
              <Label>{t('addTimeToRecallDate')}:</Label>
              <Datetime
                onChange={(event) => {
                  handleChange('recallTime', moment(event));
                }}
                dateFormat={false}
                timeFormat
                closeOnSelect
              />
            </FormGroup>
            {(buttonAction === MET_PERSONALLY ||
              buttonAction === SOCIAL_MEDIA_CONTACT) &&
              (assignmentState === ContactStatus.PROSPECT.code ||
                assignmentState === ContactStatus.MEETING.code) && (
                <FormGroup>
                  <Col>
                    <Label>
                      <Input
                        type="checkbox"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          cancelMeeting(event.target.checked);
                        }}
                      />
                      {assignmentState === ContactStatus.PROSPECT.code
                        ? 'Cancel Prospection'
                        : 'Cancel Follow up'}
                    </Label>
                  </Col>
                </FormGroup>
              )}
          </>
        );

      case PROSPECTION_DONE:
      case PROSPECTION_CANCELLED:
      case PROSPECTION_POSTPONED:
        if (buttonAction === PROSPECTION_DONE) {
          labelComponent = `${meetingType} Done`;
        } else if (buttonAction === PROSPECTION_CANCELLED) {
          labelComponent = `${meetingType} Cancelled`;
        } else {
          labelComponent = `${meetingType} Postponed`;
        }
        return (
          <>
            <FormGroup>
              <Label>{labelComponent}</Label>
              <Input
                aria-label="prospection-text-input"
                type="textarea"
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setComments(event.target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>{t('nextActivity')}:</Label>

              {buttonAction === PROSPECTION_POSTPONED ? (
                <Input
                  type="textbox"
                  value={nextAction}
                  disabled={
                    assignmentState === ContactStatus.PROSPECT.code ||
                    assignmentState === ContactStatus.MEETING.code
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange('nextAction', event.target.value);
                    setAction(event.target.value);
                  }}
                />
              ) : (
                <Select
                  options={Object.values(NEXT_ACTION_ENUMS).map((action) => ({
                    label: action,
                    value: action,
                  }))}
                  onChange={(event: { label: string; value: string }) => {
                    handleChange('nextAction', event.label);
                    setAction(event.label);
                  }}
                />
              )}
              <Label>{t('dueDate')}:</Label>
              <Datetime
                onChange={(event) => {
                  setDueDate(moment(event));
                  handleChange('dueDate', moment(event));
                }}
                dateFormat
                timeFormat={false}
                closeOnSelect
                value={dueDate}
              />
            </FormGroup>
          </>
        );
      default:
        return null;
    }
  };

  /**
   * Displays JSX elements depending on selectd action button
   * @returns JSX Elements
   */
  const commentsForm = () => {
    switch (buttonAction) {
      case DISQUALIFIED:
      case REACHED_AND_DISQUALIFIED:
        return (
          <>
            <FormGroup>
              <Label>{t('reasonForDisqualification')}:</Label>
              <Input
                aria-label="disqualification-reason-text-input"
                type="textarea"
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setComments(event.target.value);
                }}
              />
            </FormGroup>
            <Button
              color="primary"
              onClick={() => {
                deleteContactPerson();
              }}
            >
              {t('contactDoesNotExist')}
            </Button>
            &nbsp;&nbsp;
            <Button
              color="primary"
              onClick={() => {
                resetContactPerson();
              }}
            >
              {t('contactNotInCompany')}
            </Button>
          </>
        );
      case MAIL_SENT:
      case MET_PERSONALLY:
      case SOCIAL_MEDIA_CONTACT:
      case PROSPECTION_CANCELLED:
      case PROSPECTION_DONE:
      case PROSPECTION_POSTPONED:
      case REACHED:
        return (
          <FormGroup>
            <Label>{t('comments')}:</Label>
            <Input
              aria-label="comments-text-input"
              type="textarea"
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                setComments(event.target.value);
              }}
            />
          </FormGroup>
        );
      default:
        return (
          <FormGroup>
            <Label>
              {buttonAction === REACHED_AND_APPOINTMENT
                ? t('commentsInfo')
                : t('comments')}
            </Label>
            <Input
              aria-label="comments-text-input"
              type="textarea"
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                setComments(event.target.value);
              }}
            />
          </FormGroup>
        );
    }
  };

  /**
   * Function that will show he ModalConfirmOrDisqualify
   */
  const handleConfirmOrDisqualify = () => {
    setShowModalConfirmOrDisqualify(!showModalConfirmOrDisqualify);
  };

  /**
   * Function for handling "Confirm" button based on the (Activity)
   * @param {*} activity
   * @param {*} confirmAndAddProjectOption
   */
  const confirmButton = async (
    activity: string,
    confirmAndAddProjectOption: boolean
  ) => {
    // Flow of confirm button based on the activity.
    switch (activity) {
      // ! Currently unreachable case
      case MAIL_SENT:
        if (
          assignmentState === ContactStatus.COLD.code ||
          assignmentState === ContactStatus.RECALL.code
        ) {
          addComments(comments);
          handleChange('newStatus', ContactStatus.RECALL.code);
          await updateStatus();
        }
        if (assignmentState === ContactStatus.PROSPECT.code) {
          addComments(comments);
          handleChange('newStatus', ContactStatus.PROSPECT.code);
          await updateStatus();
        }
        break;

      case PROSPECTION_DONE:
      case MET_PERSONALLY:
        addComments(comments);
        handleChange('newStatus', ContactStatus.MEETING.code);
        handleChange('target', true);
        // If confirmAndAddProjectOption. Set acquisitin to true, else don't change acquisition
        if (confirmAndAddProjectOption) {
          handleChange('acquisition', true);
        }
        if (buttonAction === PROSPECTION_DONE && !confirmAndAddProjectOption) {
          handleChange('acquisition', false);
        }
        await updateStatus(confirmAndAddProjectOption);
        break;

      // ! Handling change of status for this is redundant
      case SOCIAL_MEDIA_CONTACT:
        addComments(comments);
        if (assignmentState === ContactStatus.PROSPECT.code) {
          handleChange('newStatus', ContactStatus.PROSPECT.code);
        } else if (assignmentState === ContactStatus.MEETING.code) {
          handleChange('newStatus', ContactStatus.MEETING.code);
        } else {
          handleChange('newStatus', ContactStatus.RECALL.code);
        }
        handleChange('target', true);
        // If confirmAndAddProjectOption. Set acquisitin to true, else don't change acquisition
        if (confirmAndAddProjectOption) {
          handleChange('acquisition', true);
        }
        await updateStatus(confirmAndAddProjectOption);
        break;

      case PROSPECTION_CANCELLED:
        addComments(comments);
        handleChange(
          'newStatus',
          assignmentState === ContactStatus.PROSPECT.code
            ? ContactStatus.RECALL.code
            : ContactStatus.MEETING.code
        );
        handleChange('acquisition', false);
        handleChange('target', true);
        await updateStatus();
        break;

      default:
        addComments(comments);
        await updateStatus();
    }
  };

  /**
   * Function that handles changes when a decision is 'disqualify'
   */
  const handleDisqualify = async () => {
    addComments(comments);
    handleChange('newStatus', ContactStatus.DISQUALIFIED.code);
    handleChange('acquisition', false);
    handleChange('target', false);
    handleChange('nextAction', NEXT_ACTION_ENUMS.none);
    handleChange('dueDate', null);
    await updateStatus();
  };

  /**
   * Function that handles changes when a decision is 'keep next activity'
   * @param newStatus - status to use
   */
  const handleKeepNextActivity = async (newStatus: string) => {
    addComments(comments);
    handleChange('newStatus', newStatus);
    handleChange('target', true);
    await updateStatus();
  };

  /**
   * Common updates of kept activity for assignment state 'Prospection ahead'
   */
  const commonUpdates = () => {
    addComments(comments);
    handleChange('acquisition', false);
    handleChange('target', true);
  };

  /**
   * Handle changes for acvtivity prospectoin cancelled
   * @param decision - decision if disqualy or keep activity
   */
  const handleProspectionCancelled = async (decision: string) => {
    if (decision === CONFIRM_OR_DISQUALIFY.disqualify.label) {
      await handleDisqualify();
    } else {
      commonUpdates();
      if (currentStatus?.assignmentState === ContactStatus.PROSPECT.code) {
        handleChange('newStatus', ContactStatus.RECALL.code);
      }
      await updateStatus();
    }
  };

  /**
   * Handle changes for acvtivity prospectoin done
   * @param decision - decision if disqualy or keep activity
   */
  const handleProspectionDone = async (decision: string) => {
    if (decision === CONFIRM_OR_DISQUALIFY.disqualify.label) {
      await handleDisqualify();
    } else {
      commonUpdates();
      if (currentStatus?.assignmentState === ContactStatus.PROSPECT.code) {
        handleChange('newStatus', ContactStatus.MEETING.code);
      }
      await updateStatus();
    }
  };

  /**
   * Function to handle what changes to be made based on the selectedDecision from the ModalConfirmOrDisqualify
   * @param {*} decision
   * @returns
   */
  const selectedDecision = async (decision: string) => {
    // Also check what actvity is selected
    switch (buttonAction) {
      case MET_PERSONALLY:
        if (decision === CONFIRM_OR_DISQUALIFY.disqualify.label) {
          await handleDisqualify();
        } else if (decision === CONFIRM_OR_DISQUALIFY.keepNextActivity.label) {
          await handleKeepNextActivity(ContactStatus.MEETING.code);
        }
        break;
      case SOCIAL_MEDIA_CONTACT:
        if (decision === CONFIRM_OR_DISQUALIFY.disqualify.label) {
          await handleDisqualify();
        } else if (decision === CONFIRM_OR_DISQUALIFY.keepNextActivity.label) {
          await handleKeepNextActivity(ContactStatus.RECALL.code);
        }
        break;
      case PROSPECTION_CANCELLED:
        await handleProspectionCancelled(decision);
        break;
      case PROSPECTION_DONE:
        await handleProspectionDone(decision);
        break;
      default:
    }
  };

  /**
   * Displays buttons depending on Action button
   * @returns
   */
  const buttons = () => {
    switch (buttonAction) {
      case MET_PERSONALLY:
      case SOCIAL_MEDIA_CONTACT:
      case PROSPECTION_DONE:
      case PROSPECTION_CANCELLED:
        return (
          <>
            {!(buttonAction === PROSPECTION_CANCELLED) && (
              <Button
                color="primary"
                onClick={async () => {
                  await confirmButton(buttonAction, true);
                }}
                disabled={
                  !(comments?.trim().length > 0) ||
                  !hasCustomerOrCustomerSite ||
                  !userHasPermission
                }
              >
                {t('confirmAndCreateProjectOption')}
              </Button>
            )}
            &nbsp;
            <Button
              color="primary"
              onClick={async () => {
                await selectedDecision('Disqualify');
              }}
              disabled={!(comments?.trim().length > 0)}
            >
              {t('confirmOrDisqualify')}
            </Button>
            <Button
              color="primary"
              onClick={async () => {
                await confirmButton(buttonAction, false);
              }}
              disabled={!(comments?.trim().length > 0)}
            >
              {t('confirm')}
            </Button>
            &nbsp;
            <Button color="primary" onClick={handleCancel}>
              {t('cancel')}
            </Button>
          </>
        );
      default:
        if (
          buttonAction === MAIL_SENT ||
          buttonAction === MET_PERSONALLY ||
          buttonAction === SOCIAL_MEDIA_CONTACT
        ) {
          return (
            <>
              <Button
                color="primary"
                onClick={async () => {
                  addComments(comments);
                  await updateStatus();
                }}
                disabled={
                  !(!meeting || !meetingTime || comments?.trim().length > 0)
                }
              >
                {t('confirm')}
              </Button>
              &nbsp;
              <Button color="primary" onClick={handleCancel}>
                {t('cancel')}
              </Button>
            </>
          );
        }
        return (
          <>
            {buttonAction === REACHED ||
            buttonAction === REACHED_AND_APPOINTMENT ||
            buttonAction === REACHED_AND_INTERESTED ? (
              <Button
                color="primary"
                onClick={async () => {
                  addComments(comments);
                  handleChange('target', true);
                  handleChange('acquisition', true);
                  const currentAssignmentState = currentStatus?.assignmentState;
                  if (
                    currentAssignmentState === ContactStatus.COLD.code ||
                    currentAssignmentState === ContactStatus.RECALL.code ||
                    currentAssignmentState === ContactStatus.DISQUALIFIED.code
                  ) {
                    handleChange('newStatus', ContactStatus.RECALL.code);
                  }
                  await updateStatus(true);
                }}
                disabled={
                  !(
                    (buttonAction === REACHED_AND_APPOINTMENT &&
                      meeting && // Button clicked must be 'Reached & Appointment' with a meeting day and time set to enable button
                      meetingTime) || // Or button clicked must not be 'Reached & Appointment' to enable button
                    buttonAction !== REACHED_AND_APPOINTMENT
                  ) ||
                  !hasCustomerOrCustomerSite ||
                  !userHasPermission
                }
              >
                {t('confirmAndCreateProjectOption')}
              </Button>
            ) : null}
            <Button
              color="primary"
              onClick={async () => {
                addComments(comments);
                await updateStatus();
              }}
              disabled={
                !(
                  (buttonAction === REACHED_AND_APPOINTMENT &&
                    meeting && // Button clicked must be 'Reached & Appointment' with a meeting day and time set to enable button
                    meetingTime) || // Or button clicked must not be 'Reached & Appointment' to enable button
                  buttonAction !== REACHED_AND_APPOINTMENT
                )
              }
            >
              {t('confirm')}
            </Button>
            &nbsp;
            <Button color="primary" onClick={handleCancel}>
              {t('cancel')}
            </Button>
          </>
        );
    }
  };

  /**
   * Function that calls the modal form for Confirm and Disqualify
   * @returns
   */
  const toggleConfirmOrDisqualify = () => (
    <ModalConfirmOrDisqualify
      toggleConfirmOrDisqualify={handleConfirmOrDisqualify}
      modalTitle={t('confirmOrDisqualify')}
      isOpen={showModalConfirmOrDisqualify}
      selectedDecision={selectedDecision}
    />
  );

  /**
   * Function to get modal title based on the activity
   * @param {*} buttonAction
   * @returns {string} Modal title
   */
  const getTranslatedbuttonAction = (buttonAction: string | undefined) => {
    switch (buttonAction) {
      case MET_PERSONALLY:
        return 'metPersonally';
      case SOCIAL_MEDIA_CONTACT:
        return 'contactViaSocialMedia';
      case MAIL_SENT:
        return 'mailSent';
      default:
        return 'additionalInfo';
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size={size}>
      <ModalHeader toggle={toggle}>
        {t(getTranslatedbuttonAction(buttonAction))}
      </ModalHeader>
      <ModalBody>
        <Form>
          {bodyForm()}
          {commentsForm()}
          {(buttonAction === MET_PERSONALLY ||
            buttonAction === SOCIAL_MEDIA_CONTACT ||
            buttonAction === PROSPECTION_CANCELLED ||
            buttonAction === PROSPECTION_DONE) &&
            toggleConfirmOrDisqualify()}
        </Form>
      </ModalBody>
      <ModalFooter> {buttons()}</ModalFooter>
    </Modal>
  );
};

export default ModalAction;
