import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button } from 'reactstrap';

import {
  ResourceViewMode,
  generateTimespanValues,
  isCurrentWeek,
  t,
} from './resourceOverviewHelper';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import ChangeTimeSpanForm from './ChangeTimespanForm';
import { checkIfCurrentMonthAndYear } from '../UtilizationOverview/utilizationHelper';
import { IResourceListItem } from '../../../utils/types/responseTypes';
import ResourceOverviewTableRow from './ResourceOverviewTableRow';

interface IProps extends IWithModalsProps {
  timespanSelected: {
    startingDate: Date;
    endingDate: Date;
  };
  setTimespanSelected: Dispatch<
    SetStateAction<{
      startingDate: Date;
      endingDate: Date;
    }>
  >;
  resourceList: IResourceListItem[];
  setResources: Dispatch<SetStateAction<IResourceListItem[]>>;
  fetchResources: (queryParameters: { [key: string]: string }) => Promise<void>;
}

const ResourceOverviewTable = ({
  timespanSelected,
  setTimespanSelected,
  resourceList,
  setResources,
  fetchResources,
  modalFormHandler,
  toggleModalForm,
}: IProps) => {
  const [modeView, setModeView] = useState<ResourceViewMode>(
    ResourceViewMode.MONTHLY
  );

  const [timespanRange, setTimespanRange] = useState(
    generateTimespanValues(ResourceViewMode.MONTHLY)
  );

  const handleToggleModeView = () => {
    if (modeView === ResourceViewMode.MONTHLY) {
      setModeView(ResourceViewMode.WEEKLY);
    } else {
      setModeView(ResourceViewMode.MONTHLY);
    }
  };

  const handleChangeTimespan = () => {
    modalFormHandler(
      t('changeTimespan'),
      <ChangeTimeSpanForm
        startAndEndDate={timespanSelected}
        setTimespan={setTimespanSelected}
        onClose={toggleModalForm}
      />,
      'sm'
    );
  };

  useEffect(() => {
    const { startingDate, endingDate } = timespanSelected;
    if (modeView === ResourceViewMode.MONTHLY) {
      setTimespanRange(
        generateTimespanValues(
          ResourceViewMode.MONTHLY,
          startingDate.toDateString(),
          endingDate.toDateString()
        )
      );
    }
    if (modeView === ResourceViewMode.WEEKLY) {
      setTimespanRange(
        generateTimespanValues(
          ResourceViewMode.WEEKLY,
          startingDate.toDateString(),
          endingDate.toDateString()
        )
      );
    }
  }, [timespanSelected, modeView]);

  return (
    <>
      <Button
        className="float-end m-2"
        color="primary"
        onClick={() => handleChangeTimespan()}
      >
        {t('changeTimespan')}
      </Button>
      <div className="w-100" style={{ overflow: 'auto' }}>
        <table className="w-100 border-white text-center">
          <thead>
            <tr>
              <td colSpan={2}>
                <Button onClick={() => handleToggleModeView()} color="primary">
                  {modeView}
                </Button>
              </td>
              {timespanRange.map(({ label, month, year, week }, idx) => {
                const isCurrentMonth =
                  modeView === ResourceViewMode.MONTHLY &&
                  checkIfCurrentMonthAndYear(month, year);
                const isTodayCurrentWeek =
                  modeView === ResourceViewMode.WEEKLY &&
                  week &&
                  isCurrentWeek(week);

                return (
                  <td
                    key={`${label}-${idx}-`}
                    className="stickyHeaderMonthOrWeek py-2"
                    style={{
                      fontWeight:
                        isCurrentMonth || isTodayCurrentWeek
                          ? 'bold'
                          : 'normal',
                    }}
                  >
                    {label}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {resourceList.map((projectResource, idx) => (
              <ResourceOverviewTableRow
                key={idx}
                modeView={modeView}
                employeeResource={projectResource}
                timespan={timespanRange}
                setResources={setResources}
                fetchResources={fetchResources}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default withModals(ResourceOverviewTable);
