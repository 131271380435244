import moment from 'moment';
import React, { useEffect, useState } from 'react';

import withModals, { IWithModalsProps } from '../../utils/withModals';
import { getDateFormat } from '../../utils/helpers/date';
import { ICalendarDay } from '../../utils/types/modelTypes';
import { getCalendarDay } from '../../services/api/calendar';
import DetailsCard, { CardData } from '../../components/cards/DetailsCard';
import { act } from './homeHelper';

interface IProps extends IWithModalsProps {
  holidayDataId: number;
}

const CalendarViewHolidayDialog = ({
  holidayDataId,
  modalErrorHandler,
}: IProps) => {
  const [calendarDay, setCalendarDay] = useState({} as ICalendarDay);

  const organizeCalendarDay = (retrievedCalendarDay: ICalendarDay) => {
    const { date } = retrievedCalendarDay;
    const organizedCalendarDay: ICalendarDay = {
      ...retrievedCalendarDay,
      date: moment(date).startOf('day').format(getDateFormat()),
    };

    setCalendarDay(organizedCalendarDay);
  };

  useEffect(() => {
    getCalendarDay(holidayDataId)
      .then(({ data }) => {
        organizeCalendarDay(data);
      })
      .catch((error) => {
        modalErrorHandler(act('failedToSaveAbsenceState'), error);
      });
  }, []);

  const { title, description, date } = calendarDay;
  const fields: CardData[] = [
    {
      label: act('title'),
      value: <div style={{ minHeight: '20px' }}>{title}</div>,
    },
    {
      label: act('description'),
      value: <div style={{ minHeight: '20px' }}>{description}</div>,
    },
    {
      label: act('date'),
      value: <div style={{ minHeight: '20px' }}>{date}</div>,
    },
  ];
  return <DetailsCard title="" fields={fields} />;
};
export default withModals(CalendarViewHolidayDialog);
