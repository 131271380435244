import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { isEmpty } from '../../../utils/helpers/array';
import { IFileAttachmentListItem } from '../../../utils/types/modelTypes';
import { generateFileAttachmentLink } from '../../../utils/helpers/fileAttachment';

interface IProps {
  attachments: IFileAttachmentListItem[];
  onDelete?: (file: IFileAttachmentListItem) => void;
  onUpdate?: (file: IFileAttachmentListItem) => void;
}

/**
 * Creates a list of file attachment with action buttons such as delete and update
 */
const FileAttachmentList = ({ attachments, onDelete, onUpdate }: IProps) => (
  <Container fluid aria-label="file-attachment-list">
    {!isEmpty(attachments) &&
      attachments.map((file) => {
        const { id, fileName } = file;
        return (
          <Row key={id} className="my-1">
            <Col>
              <a
                target="_blank"
                download={fileName}
                href={generateFileAttachmentLink(file)}
                rel="noopener noreferrer"
              >
                {fileName}
              </a>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              {onUpdate && (
                <Button
                  className="mx-2"
                  aria-label="file-attachment-list-button-update"
                  color="primary"
                  onClick={() => onUpdate(file)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              )}
              {onDelete && (
                <Button
                  aria-label="file-attachment-list-button-delete"
                  color="danger"
                  onClick={() => onDelete(file)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </Col>
          </Row>
        );
      })}
  </Container>
);

export default FileAttachmentList;
