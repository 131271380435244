import React from 'react';
import Select from 'react-select';

import { TIME_SPAN_ENUM } from '../../../utils/enums/timeTracking';
import { IDropdownOption } from '../../../utils/types/commonTypes';

interface IProps {
  selectedTime: string;
  onChange: (time: string) => void;
}

/**
 * Class containing the dropdown for Time span
 */
const TimeSpanDropdown = ({ selectedTime, onChange }: IProps) => {
  const timespanOptions = Object.values(TIME_SPAN_ENUM).map((item) => ({
    value: item.code,
    label: item.name,
  }));

  /**
   * Handles change event
   * @param {*} event
   */
  const onChangeHandler = (event: IDropdownOption) => {
    const selectedTime = event.value ?? null;
    onChange(selectedTime);
  };

  return (
    <div>
      <Select
        aria-label="timespan-dropdown"
        options={timespanOptions}
        value={timespanOptions.find(({ value }) => selectedTime === value)}
        onChange={onChangeHandler}
      />
    </div>
  );
};
export default TimeSpanDropdown;
