import {
  faExclamationTriangle,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar, View, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ConnectedProps, connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Button } from 'reactstrap';

import Auth from '../../services/axios/Auth';
import { absenceStateEnum } from '../../utils/enums/calendar';
import { DEFAULT_CURRENT_USER_ABSENCE_STATES } from '../../utils/constants';
import i18n from '../../i18n';
import { IAbsence, IAbsenceEntry } from '../../utils/types/modelTypes';
import CalendarViewAbsenceDialog from './CalendarViewAbsenceDialog';
import CalendarViewHolidayDialog from './CalendarViewHolidayDialog';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import {
  getCurrentAbsences,
  refreshPersonioAbsences,
} from '../../services/api/absence';
import { RootState } from '../../redux/store';
import { isEmpty } from '../../utils/helpers/array';
import { getCalendarDays } from '../../services/api/calendar';
import {
  act,
  calendarEvent,
  changeCalendarDaysToEvent,
  createAbsenceEntry,
  cst,
  eventAgenda,
  eventStyleGetter,
  instanceOfAbsence,
  instanceOfCalendarDay,
} from './homeHelper';

interface IProps extends PropsFromRedux, IWithModalsProps {}

/**
 * Absence Calendar.
 *
 * A page or functional component that shows absences in a calendar view
 *
 */
const AbsenceCalendar = ({
  // Modals
  modalErrorHandler,
  modalFormHandler,
  modalConfirmHandler,
  // Account
  employeeDetails,
}: IProps) => {
  const [absenceEntries, setAbsenceEntries] = useState([] as IAbsenceEntry[]);
  const [holidayEntries, setHolidayEntries] = useState([] as IAbsenceEntry[]);
  const [currentCalendarView, setCurrentCalendarView] = useState(
    'month' as View
  );

  const { calendarId: locationCalendarId } =
    employeeDetails.employeeLocation || {};

  /**
   * Handles showing of calendar absence dialog.
   * @param {*} event
   */
  const showCalendarViewAbsenceDialog = (event: IAbsenceEntry) => {
    const {
      resource: { id: dataId },
      resource,
    } = event;
    if (instanceOfAbsence(resource)) {
      modalFormHandler(
        act('absenceDetail'),
        <CalendarViewAbsenceDialog absenceDataId={dataId ?? 0} />
      );
    } else if (instanceOfCalendarDay(resource)) {
      modalFormHandler(
        act('holidayDetail'),
        <CalendarViewHolidayDialog holidayDataId={dataId ?? 0} />
      );
    }
  };

  /**
   * Handles setting calendar events.
   */
  const setupCalendarEvents = () => {
    getCurrentAbsences({
      'absenceState.in': DEFAULT_CURRENT_USER_ABSENCE_STATES.join(),
    })
      .then(({ data: absenceData }) => {
        const calendarAbsences: IAbsenceEntry[] = absenceData
          .filter(
            ({ absenceState }) =>
              absenceState === absenceStateEnum.Declined.code ||
              absenceState === absenceStateEnum.Canceled.code
          )
          .map((absence: IAbsence) => createAbsenceEntry(absence));
        setAbsenceEntries(calendarAbsences);
      })
      .catch((error) => {
        modalErrorHandler(act('failedToRetrieveAbsencesData'), error);
      });
  };

  const fetchCalendarDays = (locationIdCheck: number) => {
    getCalendarDays({
      'calendarId.equals': locationIdCheck.toString(),
    })
      .then((response) => {
        if (!isEmpty(response.data)) {
          const calendarDaysRetrieved: IAbsenceEntry[] =
            changeCalendarDaysToEvent(response.data);
          setHolidayEntries(calendarDaysRetrieved);
        }
      })
      .catch((error) => {
        modalErrorHandler(act('retrieveHolidaysDataFailed'), error);
      });
    setupCalendarEvents();
  };

  useEffect(() => {
    if (locationCalendarId) {
      fetchCalendarDays(locationCalendarId);
    }
  }, [locationCalendarId]);

  /**
   * Method to do a full sync with personio
   * @param {*} updatedAbsence
   */
  const handlePersonioSyncForced = () => {
    modalConfirmHandler(
      act('ManualPersonioSyncFull'),
      act('ManualPersonioSyncFullWarning'),
      () =>
        refreshPersonioAbsences({
          forceCheckAll: 'true',
        })
          .then(() => {
            setupCalendarEvents();
          })
          .catch((error) => {
            modalErrorHandler(act('personioSyncFailed'), error);
          })
    );
  };

  /**
   * Method to update the view on the frontend after syncing with the personio DB
   * @param {*} updatedAbsence
   */
  const handlePersonioSyncRegular = () => {
    refreshPersonioAbsences({
      forceCheckAll: 'false',
    })
      .then(() => {
        setupCalendarEvents();
      })
      .catch((error) => {
        modalErrorHandler(act('personioSyncFailed'), error);
      });
  };

  const eventHolidaysAbsencesAppointments = [
    ...holidayEntries,
    ...absenceEntries,
  ];

  return (
    <Card className="flex-fill" style={{ overflow: 'hidden', border: 'none' }}>
      <CardHeader>
        <CardTitle className="mb-0">
          <h2>
            {act('calendar')}
            <div className="card-actions float-end">
              {Auth.isAdmin() && (
                <Tooltip title={act('syncPersonioAbsencesForce')}>
                  <Button
                    color="primary"
                    size="lg"
                    onClick={() => handlePersonioSyncForced()}
                  >
                    <FontAwesomeIcon
                      icon={faSync}
                      style={{ fontSize: '20px' }}
                    />{' '}
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      style={{ fontSize: '20px' }}
                    />{' '}
                  </Button>
                </Tooltip>
              )}
              <Tooltip title={act('syncPersonioAbsencesRegular')}>
                <Button
                  color="primary"
                  size="lg"
                  onClick={() => {
                    handlePersonioSyncRegular();
                  }}
                >
                  <FontAwesomeIcon icon={faSync} style={{ fontSize: '20px' }} />{' '}
                </Button>
              </Tooltip>
            </div>
          </h2>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Calendar
          defaultDate={moment().toDate()}
          onView={setCurrentCalendarView}
          events={eventHolidaysAbsencesAppointments}
          style={{ height: 600, width: '100%', textAlign: 'center' }}
          selectable
          step={30}
          onSelectEvent={(event) => showCalendarViewAbsenceDialog(event)}
          localizer={momentLocalizer(moment)}
          eventPropGetter={eventStyleGetter}
          view={currentCalendarView}
          components={{
            event: calendarEvent,
            agenda: {
              event: eventAgenda,
            },
          }}
          culture={i18n.language}
          messages={{
            time: cst('time'),
            event: cst('event'),
            allDay: cst('allDay'),
            week: cst('week'),
            work_week: cst('work_week'),
            day: cst('day'),
            month: cst('month'),
            previous: cst('previous'),
            next: cst('next'),
            yesterday: cst('yesterday'),
            tomorrow: cst('tomorrow'),
            today: cst('today'),
            agenda: cst('agenda'),
            noEventsInRange: cst('noEventsInRange'),
            showMore(e) {
              return `+${e}${cst('more')}`;
            },
          }}
        />
      </CardBody>
    </Card>
  );
};

const mapStateToProps = ({ account }: RootState) => ({
  employeeDetails: account.employeeDetails,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(AbsenceCalendar));
