import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IEmployee } from '../utils/types/modelTypes';
import { IEmployeeState } from '../utils/types/stateTypes';

/**
 * Employee Slice - contains the employees state of the application
 * 1.) List of employees
 */

const initialState: IEmployeeState = {
  employees: [],
};

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployees: (state, { payload }: PayloadAction<IEmployee[]>) => ({
      ...state,
      employees: [...payload],
    }),
  },
});

export const { setEmployees } = employeeSlice.actions;

export default employeeSlice.reducer;
