import { ContactStatus } from '../enums/contact';
import { screamingSnakeCaseToStartCase } from './string';

/**
 * Gets the assignment status name
 * @param {*} assignmentState
 * @returns assignment status name
 */
// eslint-disable-next-line import/prefer-default-export
export const getAssignmentStatusName = (assignmentState: string) => {
  switch (assignmentState) {
    case ContactStatus.COLD.code:
      return screamingSnakeCaseToStartCase(ContactStatus.COLD.name);
    case ContactStatus.RECALL.code:
      return screamingSnakeCaseToStartCase(ContactStatus.RECALL.name);
    case ContactStatus.PROSPECT.code:
      return screamingSnakeCaseToStartCase(ContactStatus.PROSPECT.name);
    case ContactStatus.MEETING.code:
      return screamingSnakeCaseToStartCase(ContactStatus.MEETING.name);
    case ContactStatus.FIRST_PROJECT.code:
      return screamingSnakeCaseToStartCase(ContactStatus.FIRST_PROJECT.name);
    case ContactStatus.ONE_PROJECT.code:
      return screamingSnakeCaseToStartCase(ContactStatus.ONE_PROJECT.name);
    case ContactStatus.ANOTHER_PROJECT.code:
      return screamingSnakeCaseToStartCase(ContactStatus.ANOTHER_PROJECT.name);
    case ContactStatus.MULTIPLE_PROJECT.code:
      return screamingSnakeCaseToStartCase(ContactStatus.MULTIPLE_PROJECT.name);
    case ContactStatus.DISQUALIFIED.code:
      return screamingSnakeCaseToStartCase(ContactStatus.DISQUALIFIED.name);
    default:
      return '';
  }
};
