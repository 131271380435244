import React from 'react';
import ReactQuill from 'react-quill';
import { Button, Input } from 'reactstrap';
import InputFormLabel from '../../../components/form/InputFormLabel';
import i18n from '../../../i18n';

/**
 * Component that contains the email form for the Campaign Manager
 *
 * Created by: Reimon Angelo Tito
 * Created on: 03/09/2021
 *
 * Edited by: Reimon Angelo Tito
 * Changes made: Added feature to add placeholders to subject and body fields
 * Edited on: 08/09/2021
 */
class CampaignManagerEmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSubject: null,
      emailBody: null,
    };

    this.reactQuillRef = null;
  }

  t(keyName) {
    return i18n.t('CampaignManager.' + keyName);
  }

  // Handle changes to the subject field
  handleEmailSubjectChange = (event) => {
    this.setState({
      emailSubject: event.target.value.trim() ? event.target.value : null,
    });
  };

  // Handle changes to the email field
  handleEmailBodyChange = (value) => {
    this.setState({
      emailBody:
        value.replace(/<(.|\n)*?>/g, '').trim().length === 0 ? null : value,
    });
  };

  // Send the email to the recipients
  sendEmail = () => {
    if (this.props.onSend) {
      this.props.onSend(
        this.state.emailSubject,
        this.state.emailBody,
        this.props.contacts
      );
    }
  };

  insertPlaceholder = (name, fieldToInsert) => {
    if (fieldToInsert === 'subject') {
      let emailSubject = '';
      if (this.state.emailSubject !== null) {
        emailSubject = this.state.emailSubject;
      }
      emailSubject += name;
      this.setState({
        emailSubject: emailSubject,
      });
    } else {
      const quill = this.reactQuillRef.getEditor();
      if (quill.getLength() - 1 === 0) {
        quill.insertText(quill.getLength() - 1, name);
      } else {
        quill.insertText(quill.getLength() - 1, name);
      }
    }
  };

  render() {
    const emailSubject = this.state.emailSubject;
    const emailBody = this.state.emailBody;
    return (
      <React.Fragment>
        <InputFormLabel text={'Subject'} isRequired />
        <Input
          type="string"
          onChange={this.handleEmailSubjectChange}
          value={emailSubject}
        ></Input>
        <br />
        <Button
          color="primary"
          onClick={() => this.insertPlaceholder('{{firstname}}', 'subject')}
        >
          {this.t('firstNamePlaceholderSubject')}
        </Button>{' '}
        <Button
          color="primary"
          onClick={() => this.insertPlaceholder('{{lastname}}', 'subject')}
        >
          {this.t('lastNamePlaceholderSubject')}
        </Button>
        <br />
        <br />
        <InputFormLabel text={'Body'} isRequired />
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          onChange={this.handleEmailBodyChange}
        ></ReactQuill>
        <br />
        <Button
          color="primary"
          onClick={() => this.insertPlaceholder('{{firstname}}', 'body')}
        >
          {this.t('firstNamePlaceholderBody')}
        </Button>{' '}
        <Button
          color="primary"
          onClick={() => this.insertPlaceholder('{{lastname}}', 'body')}
        >
          {this.t('lastNamePlaceholderBody')}
        </Button>
        <br />
        <br />
        <Button
          color="primary"
          onClick={() => this.sendEmail()}
          disabled={!emailSubject || !emailBody}
        >
          {this.t('send')}
        </Button>{' '}
        <Button color="primary" onClick={() => this.props.onCancel()}>
          {this.t('cancel')}
        </Button>
      </React.Fragment>
    );
  }
}

export default CampaignManagerEmailForm;
