import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import i18n from '../../i18n';

/**
 * Displays a data in a table cell by row\
 * maximum of 2 rows will be displayed initially with a button "...more"
 * to show all data
 * Variable props:\
 *  components - An array to be displayed in a table cell\
 */
class TableDataComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
      components: Array.isArray(props.components) ? [...props.components] : [],
    };

    this.modalTitle = '';
    this.error = null;
  }

  t(keyName) {
    return i18n.t('TableDataComponent.' + keyName);
  }

  toggleShowMore = (e) => {
    e.preventDefault();
    this.setState({ showMore: true });
  };

  render() {
    let showMore = this.state.showMore;
    let components = this.state.components;
    return (
      <>
        {Array.isArray(components) && components.length > 0
          ? components.map((component, index) =>
              index < 2 || showMore ? (
                <Row key={index}>
                  <Col key={index}>
                    {component}{' '}
                    {index === 1 && components.length > 2 && !showMore ? (
                      <Link
                        style={{ fontWeight: 'bolder' }}
                        to="#"
                        onClick={(e) => this.toggleShowMore(e)}
                      >
                        {'...more'}
                      </Link>
                    ) : null}
                  </Col>
                </Row>
              ) : null
            )
          : null}
      </>
    );
  }
}

export default withRouter(TableDataComponent);
