import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input, Row, Container, Button, ButtonGroup } from 'reactstrap';

import i18n from '../../../i18n';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IFileAttachmentListItem } from '../../../utils/types/modelTypes';
import { getAllAttachments } from '../../../services/api/fileAttachment';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import Table from '../../tables/Table';

interface IProps extends IWithModalsProps {
  attachedFiles: IFileAttachmentListItem[];
  onSubmit: (files: IFileAttachmentListItem[]) => void;
  onCancel: () => void;
}

interface IModifiedFileAttachmentList extends IFileAttachmentListItem {
  isChecked: boolean;
}

const t = (keyName: string) => i18n.t(`FileAttachmentSelectionCard.${keyName}`);

/**
 * A Card that displays all files related to offer without the files that are already attached
 * You can include these files shown by checking their respective checkboxes
 */
const FileAttachmentSelectionCard = ({
  attachedFiles,
  onSubmit,
  onCancel,
  modalErrorHandler,
}: IProps) => {
  const [selectedFiles, setSelectedFiles] = useState<IFileAttachmentListItem[]>(
    []
  );
  const [searchKeyword, setSearchKeyword] = useState('');
  const [allFilesAttached, setAllFilesAttached] = useState<
    IModifiedFileAttachmentList[]
  >([]);
  const [searchFilteredAttachments, setSearchFilteredAttachments] = useState<
    IModifiedFileAttachmentList[]
  >([]);

  const attachedFileIds = attachedFiles.map(({ id }) => id);

  const fetchAllFileAttachments = async () => {
    try {
      const { data: attachments } = await getAllAttachments({
        'objectType.equals': OBJECT_TYPE_ENUM.offer.code,
      });

      // Add a checked property for checkboxes and filter files that have already been attached
      const filteredAttachments = attachments
        .map((file) => ({ ...file, isChecked: false }))
        .filter(({ id }) => !attachedFileIds.includes(id));

      setAllFilesAttached(filteredAttachments);
      setSearchFilteredAttachments(filteredAttachments);
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveFileAttachments'), error);
    }
  };

  const handleSearchKeywordChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(value.trim());

    // Filter files based on value, if empty return all default
    if (value.trim().length > 0) {
      const searchBoundAttachments = allFilesAttached.filter(({ fileName }) =>
        fileName.toLowerCase().includes(value.trim().toLowerCase())
      );
      setSearchFilteredAttachments(searchBoundAttachments);
    } else {
      setSearchFilteredAttachments(allFilesAttached);
    }
  };

  const handleCheckboxChange = (file: IModifiedFileAttachmentList) => {
    const { id, isChecked } = file;
    const modifiedFile = { ...file, isChecked: !isChecked };
    const { isChecked: isFileSelected, ...originalFileProperties } =
      modifiedFile;

    setAllFilesAttached((prev) =>
      prev.map((attachment) =>
        attachment.id === id ? modifiedFile : attachment
      )
    );

    if (isFileSelected) {
      setSelectedFiles((prev) => [...prev, originalFileProperties]);
    }
  };

  const handleConfirmation = () => {
    if (selectedFiles.length > 0) {
      onSubmit(selectedFiles);
    }
  };

  const tableData = searchFilteredAttachments.map((file) => {
    const { fileName, description, isChecked } = file;
    return {
      '': (
        <Input
          type="checkbox"
          checked={isChecked}
          onChange={() => handleCheckboxChange(file)}
          readOnly
        />
      ),
      [t('fileName')]: `${fileName}`,
      [t('description')]: `${description ?? ''}`,
    };
  });

  useEffect(() => {
    fetchAllFileAttachments();
  }, []);

  return (
    <Container>
      <Row className="w-25 mb-2">
        <Input
          type="string"
          placeholder={t('searchFileName')}
          value={searchKeyword}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleSearchKeywordChange(event)
          }
        />
      </Row>
      <Row className="mb-2">
        <Table
          tableData={tableData}
          noDataPlaceholder={t('noAttachmentsFound')}
          striped
        />
      </Row>
      <ButtonGroup className="gap-1 float-end">
        <Button color="primary" onClick={() => handleConfirmation()}>
          {t('confirm')}
        </Button>
        <Button color="primary" onClick={() => onCancel()}>
          {t('cancel')}
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default withModals(FileAttachmentSelectionCard);
