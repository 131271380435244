/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from 'moment';

import i18n from '../../i18n';
import {
  IAbsence,
  ICalendarDay,
  IEmployee,
  IPlanningListRecord,
  IProjectEmployeeWorkload,
} from '../types/modelTypes';
import { ABSENCE_STATE_ENUM } from '../enums/project';
import {
  getDateFormat,
  GetDatesInBetween,
  getDatesInBetweenExcludingWeekends,
  isEmpty,
} from './GenericHelper';
import { IPreparedData } from '../types/commonTypes';

const dateFormat = getDateFormat();

export interface IWeekObject {
  weekNumber: number;
  year: number;
}

export interface IMonthObject {
  value: number;
  label: string;
  year: number;
}

/**
 * Retrieves array of workableDates or dates with workload of an employee.
 * This excludes calendar holidays and absences of the employee
 * @param extendedEmployee
 * @param calendarHolidays
 * @param startEndDate - object with startDate and endDate as attributes
 * @param monthIndex
 * @param year
 * @returns array of workable dates/dates with workload
 */
export const getWorkableDates = (
  extendedEmployee: any,
  calendarHolidays: ICalendarDay[],
  startEndDate: any,
  monthIndex: number,
  year: number
) => {
  const employee = extendedEmployee.employee;
  const employeeAbsence = extendedEmployee.absences;
  // Create list of dates using the dateStart and dateEnd excluding weekends
  let datesBetweenDateStartAndDateEndExcludeWeekends: string[] = [];
  // Contains combined dates of absences and holidays without duplicate copy
  let absencesAndHolidays = [] as any[];

  if (employee !== null) {
    const acceptedAbsenceDatesExcludingWeekends =
      getAcceptedAbsenceDatesExcludingWeekends(employeeAbsence);

    let holidayDatesExcludingWeekends: string[] = [];

    // Contains holiday dates of the selected employee
    if (
      employee?.employeeLocation !== null ||
      employee.employeeLocation !== undefined
    ) {
      // Contains holidays assigned to the employee based on the location and the calendar and calendar days.
      holidayDatesExcludingWeekends = getHolidayDatesOfEmployee(
        employee,
        calendarHolidays
      );
    }

    // This list combines the holidayDates and absencesDates.Filter it and remove possible duplicates
    absencesAndHolidays = [
      ...new Set([
        ...acceptedAbsenceDatesExcludingWeekends,
        ...holidayDatesExcludingWeekends,
      ]),
    ];
  }

  // The dateStart and dateEnd will be based on projectEmployeeWorkload
  // Filter the dates that for the month using the monthIndex
  const dateStart = startEndDate.startDate;
  const dateEnd = startEndDate.endDate;

  if (dateStart !== null && dateEnd !== null) {
    const datesBetweenDateStartAndDateEnd = GetDatesInBetween(
      dateStart,
      dateEnd
    );
    const filteredDatesForThisMonth = datesBetweenDateStartAndDateEnd.filter(
      (date) => {
        const formattedDate = moment(date).format(dateFormat);
        const dateMonthIndex = moment(formattedDate).month();
        return dateMonthIndex === monthIndex;
      }
    );

    const filteredDatesByYear = filteredDatesForThisMonth.filter((date) => {
      const formattedDate = moment(date).format(dateFormat);
      const yearNumber = moment(formattedDate).year();
      return yearNumber === year;
    });

    // Get only dates based from the selected year
    // Const filteredDatesByYear = this.filterDatesByYear(filteredDatesForThisMonth);
    datesBetweenDateStartAndDateEndExcludeWeekends = filteredDatesByYear.filter(
      (date) => {
        const formattedDate = moment(date).format(dateFormat);
        return (
          moment(formattedDate).day() !== 6 && moment(formattedDate).day() !== 0
        );
      }
    );
  }
  // Const filteredAbsenceAndHolidaysByYear =	this.filterDatesByYear(absencesAndHolidays);

  const filteredAbsenceAndHolidaysByYear = absencesAndHolidays.filter(
    (date) => {
      const formattedDate = moment(date).format(dateFormat);
      const yearNumber = moment(formattedDate).year();
      return yearNumber === year;
    }
  );

  // Create list of dates that excludes the following: Vacation absence, Holiday, Weekends
  const workableDates = [] as string[];
  // Create list of acceptable dates. Iterate through the datesBetweenDateStartAndDateEnd. Check if the date is on the list of absencesAndHolidays
  if (!isEmpty(datesBetweenDateStartAndDateEndExcludeWeekends)) {
    datesBetweenDateStartAndDateEndExcludeWeekends.forEach((date) => {
      const formattedDate = moment(date).format(dateFormat);
      const isTheAcceptedDateOnTheList =
        filteredAbsenceAndHolidaysByYear.includes(formattedDate);
      if (!isTheAcceptedDateOnTheList) {
        workableDates.push(formattedDate);
      }
    });
  }

  return workableDates;
};

/**
 * Function that create lists of dates that start from the absence.start upto absence.end
 * Only contains absences dates of an absence with type of Vacation.
 * @param vacationAndSicknessAbsences
 * @returns
 */
export const getAcceptedAbsenceDatesExcludingWeekends = (
  vacationAndSicknessAbsences: IAbsence[]
) => {
  // Contains list of dates of absences with absenceState of "ACCEPTED" excluding weekends
  let acceptedAbsenceDatesExcludingWeekends: string[] = [];

  let absencesForThisEmployee = [] as IAbsence[];
  if (!isEmpty(vacationAndSicknessAbsences)) {
    absencesForThisEmployee = vacationAndSicknessAbsences.filter(
      (absence) => absence.absenceState === ABSENCE_STATE_ENUM.Accepted.code
    );
  }

  if (!isEmpty(absencesForThisEmployee)) {
    absencesForThisEmployee.forEach((absence) => {
      acceptedAbsenceDatesExcludingWeekends =
        getDatesInBetweenExcludingWeekends(
          absence?.startDate,
          absence?.endDate
        );
    });
  }

  return acceptedAbsenceDatesExcludingWeekends;
};

/**
 * Handles retrieval of holidays being used by the employee
 * @param {*} employee
 * @returns holiday dates used by the employee
 */
export const getHolidayDatesOfEmployee = (
  employee: IEmployee,
  calendayHolidays: ICalendarDay[]
) => {
  const employeeLocation = employee?.employeeLocation;
  let employeeCalendarId: number | null | undefined = null;
  if (employeeLocation) {
    employeeCalendarId = employeeLocation.calendarId;
  }

  const holidayDatesExcludingWeekends: string[] = [];
  if (!isEmpty(calendayHolidays)) {
    const filteredCalendarDaysByCalendarId = calendayHolidays.filter(
      (calendarDay) => calendarDay?.calendar?.id === employeeCalendarId
    );

    if (!isEmpty(filteredCalendarDaysByCalendarId)) {
      filteredCalendarDaysByCalendarId.forEach((holiday) => {
        const formattedDate = moment(holiday?.date).format(dateFormat);
        // Exclude saturday and sunday
        if (
          moment(formattedDate).day() !== 6 &&
          moment(formattedDate).day() !== 0
        ) {
          holidayDatesExcludingWeekends.push(formattedDate);
        }
      });
    }
  }

  return holidayDatesExcludingWeekends;
};

/**
 * Retrieves workable dates for this week and year
 * @param {*} extendedEmployee
 * @param {*} calendarHolidays
 * @param {*} startEndDateObject
 * @param {*} week
 * @param {*} year
 * @returns workable dates
 */
export const getWorkableDatesForThisWeek = (
  extendedEmployee: any,
  calendarHolidays: ICalendarDay[],
  startEndDateObject: any,
  week: number,
  year?: number
) => {
  const employee = extendedEmployee?.employee;
  const employeeAbsence = extendedEmployee?.absences;
  // Create list of dates using the dateStart and dateEnd excluding weekends
  let datesBetweenDateStartAndDateEndExcludeWeekends = [] as string[];

  // Contains combined dates of absences and holidays without duplicate copy
  let absencesAndHolidays = [] as any[];

  if (employee !== null) {
    const acceptedAbsenceDatesExcludingWeekends =
      getAcceptedAbsenceDatesExcludingWeekends(employeeAbsence);

    let holidayDatesExcludingWeekends: string[] = [];

    // Contains holiday dates of the selected employee
    if (
      employee?.employeeLocation !== null ||
      employee.employeeLocation !== undefined
    ) {
      // Contains holidays assigned to the employee based on the location and the calendar and calendar days.
      holidayDatesExcludingWeekends = getHolidayDatesOfEmployee(
        employee,
        calendarHolidays
      );
    }

    // This list combines the holidayDates and absencesDates.Filter it and remove possible duplicates
    absencesAndHolidays = [
      ...new Set([
        ...acceptedAbsenceDatesExcludingWeekends,
        ...holidayDatesExcludingWeekends,
      ]),
    ];
  }

  // The dateStart and dateEnd will be based on projectEmployeeWorkload
  const dateStart = startEndDateObject.startDate;
  const dateEnd = startEndDateObject.endDate;

  if (dateStart !== null && dateEnd !== null) {
    const datesBetweenDateStartAndDateEnd = GetDatesInBetween(
      dateStart,
      dateEnd
    );

    let filteredDatesForThisWeek = datesBetweenDateStartAndDateEnd;
    if (year) {
      filteredDatesForThisWeek = datesBetweenDateStartAndDateEnd.filter(
        (date) => {
          const formattedDate = moment(date).format(dateFormat);
          const weekOfThisDate = moment(formattedDate).week();
          const yearOfThisDate = moment(formattedDate).year();
          return weekOfThisDate === week && yearOfThisDate === year;
        }
      );
    }
    datesBetweenDateStartAndDateEndExcludeWeekends =
      filteredDatesForThisWeek.filter((date) => {
        const formattedDate = moment(date).format(dateFormat);
        return (
          moment(formattedDate).day() !== 6 && moment(formattedDate).day() !== 0
        );
      });
  }

  // Create list of dates that excludes the following: Vacation absence, Holiday, Weekends
  const workableDates = [] as string[];
  if (!isEmpty(datesBetweenDateStartAndDateEndExcludeWeekends)) {
    datesBetweenDateStartAndDateEndExcludeWeekends.forEach((date) => {
      const formattedDate = moment(date).format(dateFormat);
      const isTheAcceptedDateOnTheList =
        absencesAndHolidays.includes(formattedDate);
      if (!isTheAcceptedDateOnTheList) {
        workableDates.push(formattedDate);
      }
    });
  }
  return workableDates;
};

/**
 * Get list of dates based on the week object.
 * @param {*} weekObject - object that has the following property: weekNumber, year
 * @returns list of dates sharing the same week number
 */
export const getWeekDaysByWeekObject = (weekObject: IWeekObject) => {
  const weekObjectData = weekObject;
  const date = moment()
    .week(weekObjectData.weekNumber || 1)
    .year(weekObjectData.year)
    .startOf('W');
  let weeklength = 7;
  const result = [];
  while (weeklength--) {
    result.push(date.format(getDateFormat()));
    date.add(1, 'day');
  }

  return result;
};

/**
 * Function that creates string containing the abbreviated month and the year
 * @param {*} selectedMonth - selected month object, contains the following property: value, label, year
 * @returns String of abbreviated month and year
 */
export const getMonthAbbreviationAndYear = (selectedMonth: IMonthObject) => {
  const firstDayOfThisMonthAndYear = new Date(
    selectedMonth?.year,
    selectedMonth?.value,
    1
  );
  const languageSelected = i18n.language;
  const monthNameAbbreviation = firstDayOfThisMonthAndYear.toLocaleString(
    languageSelected,
    { month: 'short' }
  );

  return `${monthNameAbbreviation}(${selectedMonth?.year})`;
};

/**
 * Filters and returns an array of prepared data objects based on the provided date filter range.
 * This function filters the 'planningListRecords' property of each data object using the specified date filter range.
 *
 * @param {IPreparedData[]} preparedData - The array of prepared data objects to be filtered.
 * @param {IMonthObject[] | IWeekObject[]} monthOrWeekRangeFilter - The date filter range to apply on 'planningListRecords'.
 * @param {boolean} isMonthFilter - A boolean flag indicating whether the filter range is for months (true) or weeks (false).
 * @returns {IPreparedData[]} - An array of prepared data objects with the 'planningListRecords' filtered based on the date filter range.
 */
export const filterPreparedDataHelper = (
  preparedData: IPreparedData[],
  monthOrWeekRangeFilter: IMonthObject[] | IWeekObject[],
  isMonthFilter: boolean
) => {
  const workablePreparedData: IPreparedData[] = [];

  // Iterate through each prepared data object in the provided array
  preparedData.forEach((data) => {
    // Check if the 'planningListRecords' property exists for the data object
    if (data?.planningListRecords) {
      // Filter the 'planningListRecords' based on the date filter range
      const filteredPlanningListRecords =
        getFilteredPlanningListByDateFilterRange(
          data.planningListRecords,
          monthOrWeekRangeFilter,
          isMonthFilter
        );

      // If filteredPlanninglistRecords is empty, skip to the next item in the loop
      if (isEmpty(filteredPlanningListRecords)) {
        return;
      }

      // Create a shallow copy of the 'data' object to preserve other properties
      const updatedData = {
        ...data,
      };

      // Update the 'planningListRecords' property with the filtered data
      updatedData.planningListRecords = filteredPlanningListRecords;

      // Add the updated data object to the 'workablePreparedData' array
      workablePreparedData.push(updatedData);
    }
  });

  // Return the array of prepared data with the filtered planning list records
  return workablePreparedData;
};

/**
 * Filters and returns an array of planning records based on the provided date filter range.
 * This function filters the 'projectEmployeeWorkloads' property of each planning record using the specified date filter range.
 *
 * @param {IPlanningListRecord[]} planningListRecords - The array of planning records to be filtered.
 * @param {IMonthObject[] | IWeekObject[]} monthOrWeekRangeFilter - The date filter range to apply on 'projectEmployeeWorkloads'.
 * @param {boolean} isMonthFilter - A boolean flag indicating whether the filter range is for months (true) or weeks (false).
 * @returns {IPlanningListRecord[]} - An array of planning records containing 'projectEmployeeWorkloads' falling within the date filter range.
 */
const getFilteredPlanningListByDateFilterRange = (
  planningListRecords: IPlanningListRecord[],
  monthOrWeekRangeFilter: IMonthObject[] | IWeekObject[],
  isMonthFilter: boolean
) => {
  const workablePlanningList: IPlanningListRecord[] = [];

  // Iteratate through each planning record in the provided array
  planningListRecords.forEach((planningListRecord) => {
    if (
      !!planningListRecord?.projectEmployeeWorkloads &&
      checkProjectEmployeeWorkloadsIfWithinRange(
        planningListRecord.projectEmployeeWorkloads,
        monthOrWeekRangeFilter,
        isMonthFilter
      )
    ) {
      // If at least one workload falls within the date filter range, add the planning record to the 'workablePlanningList'
      workablePlanningList.push(planningListRecord);
    }
  });

  // Return the filtered array of planning records containing workloads falling withing the date filter range
  return workablePlanningList;
};

/**
 * Checks if any of the project employees' workloads are within the specified date filter range.
 *
 * @param {IProjectEmployeeWorkload[]} projectEmployeeWorkloads - The array of project employees' workloads to check.
 * @param {IMonthObject[] | IWeekObject[]} monthOrWeekRangeFilter - The date filter range to compare with the workloads' dates.
 * @param {boolean} isMonthFilter - A boolean flag indicating whether the filter range is for months (true) or weeks (false).
 * @returns {boolean} - A boolean value indicating whether any of the project employees' workloads fall within the date filter range.
 */
const checkProjectEmployeeWorkloadsIfWithinRange = (
  projectEmployeeWorkloads: IProjectEmployeeWorkload[],
  monthOrWeekRangeFilter: IMonthObject[] | IWeekObject[],
  isMonthFilter: boolean
) => {
  let isWorkloadWithinRange = false;

  // Iterate through each project employee's workload
  projectEmployeeWorkloads.forEach(
    ({ startDate, endDate }: IProjectEmployeeWorkload) => {
      // Get the list of dates in between 'startDate' and 'endDate'
      const datesInBetween = GetDatesInBetween(startDate, endDate);

      // Check if any of the dates within the 'datesInBetween' are within the range of the selected month or week filter
      isWorkloadWithinRange = checkDatesIfWithinMonthOrWeekFilter(
        datesInBetween,
        monthOrWeekRangeFilter,
        isMonthFilter
      );

      // If at least one workload is within range, exit the loop
      if (isWorkloadWithinRange) {
        return;
      }
    }
  );

  // Return the final result indicating whether any of the project employees' workloads are within the specified range
  return isWorkloadWithinRange;
};

/**
 * Checks if any of the provided dates fall within the specified date filter range (either month or week).
 *
 * @param {string[]} dates - An array of date strings to be checked against the date filter range.
 * @param {IMonthObject[] | IWeekObject[]} monthOrWeekRangeFilter - The date filter range to compare with the provided dates.
 * @param {boolean} isMonthFilter - A boolean flag indicating whether the filter range is for months (true) or weeks (false).
 * @returns {boolean} - A boolean value indicating whether any of the provided dates fall within the date filter range.
 */
const checkDatesIfWithinMonthOrWeekFilter = (
  dates: string[],
  monthOrWeekRangeFilter: IMonthObject[] | IWeekObject[],
  isMonthFilter: boolean
) => {
  let dateIsWithinRange = false;

  // Iterate through each date in the provided array
  dates.forEach((date) => {
    // Extract the month, year, and week number from the current date
    const dateMonth = moment(date).month();
    const dateYear = moment(date).year();
    const dateWeek = moment(date).week();

    // Create an empty array to store the filtered range (either months or weeks) that match the current date
    let filteredMonthOrWeekRange: IMonthObject[] | IWeekObject[] = [];

    // Check if the filter range is for months or weeks and filter the 'monthOrWeekRangeFilter' array accordingly
    if (isMonthFilter) {
      const monthObjectFilter = monthOrWeekRangeFilter as IMonthObject[];
      filteredMonthOrWeekRange = monthObjectFilter.filter(
        ({ year, value }: IMonthObject) =>
          year === dateYear && value === dateMonth
      );
    } else {
      const weekObjectFilter = monthOrWeekRangeFilter as IWeekObject[];
      filteredMonthOrWeekRange = weekObjectFilter.filter(
        ({ year, weekNumber }) => year === dateYear && weekNumber === dateWeek
      );
    }

    // If any matching range is found, set the 'dateIsWithinRange' flag to true and exit the loop
    if (!isEmpty(filteredMonthOrWeekRange)) {
      dateIsWithinRange = true;
      return;
    }
  });
  return dateIsWithinRange;
};
