import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ConnectedProps, connect } from 'react-redux';

import { ContactPersonDetailsContext } from './ContactPersonDetailsProvider';
import {
  IDropdownOption,
  IDropdownOptionInvolved,
  IObjectInvolvedResponsible,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import MultipleResponsibleDropdown from '../../../components/form/MultipleResponsibleDropdown';
import SelectNewResponsibleModal from '../../../components/form/SelectNewResponsibleModal';
import DetailsCard from '../../../components/cards/DetailsCard';
import { isEmpty } from '../../../utils/helpers/array';
import { RootState } from '../../../redux/store';
import { fetchEmployeesWithContactPersonListPermission } from '../../../redux/contactPersonListSlice';
import { updateContactPersonDetails } from '../../../services/api/contactPerson';
import { IContactPersonDetail } from '../../../utils/types/responseTypes';
import i18n from '../../../i18n';
import { generateTitle } from '../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { transformContactPersonToDetails } from './contactPersonHelper';

// !-- Used in ContactPersonDetails, ContactPersonOverview

interface IProps extends IWithModalsProps, PropsFromRedux {
  userCanWrite: boolean;
}

export const t = (keyName: string) => i18n.t(`ResponsibilityCard.${keyName}`);

/**
 * Displays and allows editing of Responsible and Involved Employees
 */
const ContactPersonResponsibleCard = ({
  userCanWrite,
  // Redux
  fetchEmployeesWithContactPersonListPermission,
  employeesWithPermission,
  // WithModals
  modalFormHandler,
  modalErrorHandler,
  toggleModalForm,
}: IProps) => {
  const {
    setResponsibleDetail,
    contactDetail: { id, firstname, lastname },
    responsibleDetail: { responsible, involvedEmployees },
  } = useContext(ContactPersonDetailsContext);

  const [editedResponsible, setEditedResponsible] = useState(
    {} as IObjectNameAndId
  );
  const [editedInvolved, setEditedInvolved] = useState(
    [] as IObjectInvolvedResponsible[]
  );
  const [involvedToggle, setInvolvedToggle] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [filteredInvolvedIds, setFilteredInvolvedIds] = useState<number[]>();

  const handleReset = () => {
    setEditedResponsible(responsible);
    setEditedInvolved(involvedEmployees);
    setInvolvedToggle(false);
  };

  const handleSaveResponsible = async (newResponsible: IObjectNameAndId) => {
    const propertyToSave = {
      id,
      responsible: newResponsible,
    } as IContactPersonDetail;
    try {
      const { data } = await updateContactPersonDetails(propertyToSave);
      const { responsibleDetail } = transformContactPersonToDetails(data);
      setResponsibleDetail(responsibleDetail);
    } catch (error) {
      modalErrorHandler(t('failedToUpdateResponsibles'), error);
    }
  };

  const handleResponsibleChange = ({ id }: IObjectNameAndId) => {
    const newEmployee = employeesWithPermission?.find((r) => r.value === id);
    if (newEmployee) {
      const newResponsible = {
        id: newEmployee.value,
        name: newEmployee.label,
      };
      setEditedResponsible(newResponsible);
      handleSaveResponsible(newResponsible);
    }
    toggleModalForm();
  };

  const handleSetNewResponsible = () => {
    modalFormHandler(
      t('newResponsible'),
      <SelectNewResponsibleModal
        objectName={`${firstname} ${lastname}`}
        objectType={t('contactPerson')}
        currentResponsible={editedResponsible}
        employeesWithPermission={employeesWithPermission}
        onSetResponsible={handleResponsibleChange}
        onCancel={toggleModalForm}
      />
    );
  };

  const handleSaveInvolved = async () => {
    const propertiesToSave = {
      id,
      involvedEmployees: editedInvolved,
    } as IContactPersonDetail;
    try {
      const { data } = await updateContactPersonDetails(propertiesToSave);
      const { responsibleDetail } = transformContactPersonToDetails(data);
      setResponsibleDetail(responsibleDetail);
    } catch (error) {
      modalErrorHandler(t('failedToUpdateInvolved'), error);
    }
    setInvolvedToggle(false);
  };

  const handleSetInvolved = (newInvolved: IDropdownOptionInvolved[]) => {
    const involvedResponsibleArray: IObjectInvolvedResponsible[] =
      newInvolved.map(
        ({ responsible, responsibleRole }) =>
          ({
            ...(responsible
              ? {
                  id: responsible?.value,
                  name: responsible?.label,
                }
              : {}),
            ...(responsibleRole
              ? {
                  responsibleRole: {
                    id: responsibleRole?.value ?? 0,
                    name: responsibleRole?.label ?? '',
                  },
                }
              : {
                  responsibleRole: {
                    id: 0,
                    name: '',
                  },
                }),
          } as IObjectInvolvedResponsible)
      );
    setEditedInvolved(involvedResponsibleArray);
  };

  const involvedField = (
    <>
      <Row>
        <div className="card-actions mb-2 d-flex justify-content-end ml-5">
          {userCanWrite && involvedToggle && (
            <>
              <Button
                color="primary"
                onClick={handleSaveInvolved}
                data-testid="save"
                disabled={isSaveDisabled}
              >
                {generateTitle(BUTTON_TITLE_ENUM.SAVE.code)}
              </Button>
              <Button color="primary" onClick={handleReset} data-testid="reset">
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </>
          )}
        </div>
      </Row>

      {involvedToggle ? (
        <div data-testid="involvedcombobox">
          <MultipleResponsibleDropdown
            responsibles={editedInvolved.map(
              ({ id, name, responsibleRole }) =>
                ({
                  ...(id && name
                    ? {
                        responsible: { label: name, value: id },
                      }
                    : {}),
                  ...(responsibleRole
                    ? {
                        responsibleRole: {
                          label: responsibleRole.name,
                          value: responsibleRole.id,
                        },
                      }
                    : {}),
                } as {
                  responsible: IDropdownOption<number>;
                  responsibleRole: IDropdownOption<number>;
                })
            )}
            setResponsibles={handleSetInvolved}
            responsibleOptions={
              employeesWithPermission?.filter(
                (employee) => !filteredInvolvedIds?.includes(employee.value)
              ) ?? ({} as IDropdownOption<number>[])
            }
          />
        </div>
      ) : (
        <div
          onClick={() => setInvolvedToggle(true)}
          style={{ cursor: 'text' }}
          data-testid="involved"
        >
          {!isEmpty(editedInvolved)
            ? editedInvolved?.map(({ id, name, responsibleRole }) => (
                <Row key={id} style={{ height: '22px' }}>
                  <Col key={id}>
                    <Link to={`/employees/employee-list/employee-detail/${id}`}>
                      {`${name} ${
                        responsibleRole?.id ? `(${responsibleRole.name})` : ''
                      }`}
                    </Link>
                  </Col>
                </Row>
              ))
            : t('noEmployeeFound')}
        </div>
      )}
    </>
  );

  const cardData = [
    {
      label: t('responsible'),
      value: (
        <div style={{ lineHeight: '1.5em' }}>
          <Link
            style={{ paddingRight: '30px' }}
            to={`/employees/employee-list/employee-detail/${editedResponsible?.id}`}
          >
            {editedResponsible?.name}
          </Link>
          <Button
            disabled={!userCanWrite}
            color="primary"
            onClick={handleSetNewResponsible}
          >
            {t('setNewResponsible')}
          </Button>
        </div>
      ),
      isRequired: false,
    },
    {
      label: t('involved'),
      value: involvedField,
      isRequired: false,
    },
  ];

  useEffect(() => {
    if (isEmpty(employeesWithPermission)) {
      fetchEmployeesWithContactPersonListPermission((error) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responsible?.id) {
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsible, involvedEmployees]);

  useEffect(() => {
    // Disable if they are the same
    setIsSaveDisabled(
      JSON.stringify(editedInvolved) === JSON.stringify(involvedEmployees)
    );

    const newFilteredInvolvedIds = editedInvolved?.map(
      (involved) => involved.id
    );
    if (editedResponsible?.id) {
      newFilteredInvolvedIds?.push(editedResponsible.id);
    }
    if (!isEmpty(newFilteredInvolvedIds)) {
      setFilteredInvolvedIds([...newFilteredInvolvedIds]);
    }
  }, [editedInvolved, editedResponsible, responsible, involvedEmployees]);

  return (
    <DetailsCard
      title={t('details')}
      // HeaderButtons={headerButtons as JSX.Element}
      leftTitle
      largeTitle
      fields={cardData}
    />
  );
};

const mapStateToProps = (store: RootState) => ({
  employeesWithPermission:
    store.contactPersonList.additionalState?.employeesWithPermission,
});

const mapDispatchToProps = {
  fetchEmployeesWithContactPersonListPermission,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(ContactPersonResponsibleCard));
