import React, { useState } from 'react';
import Select from 'react-select';
import { Button, Form, FormGroup, Label } from 'reactstrap';

import i18n from '../../../i18n';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { READ, READWRITE } from '../../../utils/constants';

interface IProps {
  dropdownLabel: string;
  options: IDropdownOption[];
  subjectType: string;
  onSave: (
    accessType: string,
    subjectId: number,
    subjectType: string
  ) => Promise<void>;
}

const t = (keyName: string) => i18n.t(`AccessDialog.${keyName}`);

/**
 * AccessDialogPermissionComponent
 * Used by ModalAccessAddDialog in order to create a new Permission
 *
 * Variable props:
 *  dropdownLabel: title of the dropdown
 *  options: items in the dropdown
 * Variable function:
 *  onSaveRead: Function executed when SAVEREAD is clicked
 *  onSaveReadWrite: Function executed when SAVEREADANDWRITE is clicked
 *
 */

const AccessDialogPermissionComponent = ({
  dropdownLabel,
  options,
  subjectType,
  onSave,
}: IProps) => {
  const [subjectId, setSubjectId] = useState<number>(0);

  // Handles the change of value in the modal.
  const handleEmployeeChange = (event: IDropdownOption) => {
    setSubjectId(parseInt(event.value, 10));
  };

  const onSaveRead = () => {
    onSave(READ, subjectId, subjectType);
  };

  const onSaveReadWrite = () => {
    onSave(READWRITE, subjectId, subjectType);
  };

  return (
    <Form>
      {options.length > 0 ? (
        <>
          <FormGroup>
            <legend>
              <Label>{dropdownLabel}</Label>
            </legend>
            <Select
              aria-label="employee-select"
              options={options}
              onChange={handleEmployeeChange}
            />
          </FormGroup>
          <div className="float-right">
            <Button color="primary" onClick={onSaveRead}>
              {t('SAVEREADONLY')}
            </Button>{' '}
            <Button color="primary" onClick={onSaveReadWrite}>
              {t('SAVEREADANDWRITE')}
            </Button>
          </div>
        </>
      ) : (
        <div>{t('NoChoicesFound')}</div>
      )}
    </Form>
  );
};

export default AccessDialogPermissionComponent;
