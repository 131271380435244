import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardBody, Container } from 'reactstrap';

import {
  PROJECT_ASSOCIATED,
  PROJECT_CURRENT,
} from '../../../utils/constants';
import { IEmployeeProjectListItem } from '../../../utils/types/responseTypes';
import { createProjectEmployeeEntry, edt } from './employeeDetailHelper';
import { EmployeeDetailsContext } from './EmployeeDetailProvider';
import TableCard from '../../../components/cards/TableCard';

/**
 * Display Internal Relations of the Employee Overview
 */
const EmployeeProjectsCard = () => {
  const {
    employeeDetail: { employeeProjectsActive, employeeProjectsAssociated },
  } = useContext(EmployeeDetailsContext);

  // Current Project Data
  const preparedDataCurrent = employeeProjectsActive.map(
    (User: IEmployeeProjectListItem) =>
      createProjectEmployeeEntry(User, PROJECT_CURRENT)
  );

  // Associated Project Data
  const preparedDataAssociated = employeeProjectsAssociated.map(
    (User: IEmployeeProjectListItem) =>
      createProjectEmployeeEntry(User, PROJECT_ASSOCIATED)
  );

  return (
    <Container fluid>
      <Card>
        <CardHeader>
          <CardTitle>
            <h1>{edt('employeeProjects')}</h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Card style={{ padding: '5px' }}>
            <TableCard
              title={edt('employeeCurrentProjects')}
              tableData={preparedDataCurrent}
              noDataPlaceholder={edt('noData')}
              border
            />
          </Card>
          <Card style={{ padding: '5px' }}>
            <TableCard
              title={edt('employeeAssignedProjects')}
              tableData={preparedDataAssociated}
              noDataPlaceholder={edt('noData')}
              border
            />
          </Card>
        </CardBody>
      </Card>
    </Container>
  );
};

export default withRouter(EmployeeProjectsCard);
