import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Container,
  Input,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';

import axios from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import { isEmpty } from '../../../utils/helpers/GenericHelper';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IContactPersonTopicListItem } from '../../../utils/types/modelTypes';
import { clickHandler } from '../../../utils/helpers/click';

interface IProps extends IWithModalsProps {
  contactPersonTopic: IContactPersonTopicListItem | null;
  onSave: (contactPersonTopic: IContactPersonTopicListItem) => void;
  onCancel: () => void;
}
const ContactPersonTopicInput: React.FC<IProps> = ({
  contactPersonTopic,
  onSave,
  modalOkHandler,
  modalErrorHandler,
  onCancel,
}) => {
  const t = (keyName: string) =>
    i18n.t('ContactPersonTopicSettings.' + keyName);

  const [topic, setTopic] = useState(
    contactPersonTopic ? contactPersonTopic.topic : ''
  );

  const submitEvent = async () => {
    if (topic.length === 0) {
      modalOkHandler(t('warning'), t('topicNameRequired'));
      return;
    }

    const payload = {
      id: contactPersonTopic ? contactPersonTopic.id : null,
      topic: topic.trim(),
      contactPeople: !isEmpty(contactPersonTopic?.contactPeople)
        ? contactPersonTopic?.contactPeople.map((contactPerson) => ({
            id: contactPerson.id,
          }))
        : [],
    };

    await axios.employee
      .save('contact-person-topics-settings', payload)
      .then((response: AxiosResponse<IContactPersonTopicListItem>) => {
        onSave(response.data);
        onCancel();
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedtoSaveTopic'), error);
      });
  };

  return (
    <Container fluid>
      <Card>
        <CardBody>
          <InputFormLabel isRequired text={t('topic')} />
          <Input
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTopic(event.target.value);
            }}
            value={topic}
          />
          <br />
          <Button
            color="primary"
            size="m"
            className="float-end"
            onClick={(event: MouseEvent) => clickHandler(event, submitEvent)}
            disabled={topic?.trim() === ''}
          >
            {t('save')}
          </Button>
        </CardBody>
      </Card>
      {!isEmpty(contactPersonTopic?.contactPeople) && (
        <Card>
          <CardBody>
            <Button color="outline" id="linkedContactPersons">
              <FontAwesomeIcon icon={faCaretDown} />
            </Button>
            <InputFormLabel
              isRequired={false}
              text={t('linkedContactPeople')}
            />
            <UncontrolledCollapse toggler="#linkedContactPersons">
              <td className="col-md-2">
                {contactPersonTopic?.contactPeople.map((link, index) => (
                  <Row key={index}>
                    <Link to={`/customer/my-contacts/profile/${link.id}`}>
                      {link.name}
                    </Link>
                  </Row>
                ))}
              </td>
            </UncontrolledCollapse>
          </CardBody>
        </Card>
      )}
    </Container>
  );
};

export default withModals(ContactPersonTopicInput);
