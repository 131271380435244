import React from 'react';
import { Button, Table } from 'reactstrap';

import i18n from '../../i18n';

/**
 * Dynamic table component that shows the column hidder/shower
 * props:
 *  columnList = An array that contains all the columns items
 *  columnReturn = the function that is called when any changes are made
 *  ** parameter - the updated columnList array
 *  ToggleVisibility = Checks if the controll header is visible or not
 */

interface IColumn {
  type: string;
  header: string;
  accessor: string;
  alignleft: boolean;
  filterkey: string;
  showsearch: boolean;
  sortFunc: (sortBy: string) => void;
  filterFunc: () => void;
  filterType: string;
  filterOptions: { label: string; value: string }[];
  filterComponentWidth: string;
  width: string;
  filterValue: string;
}

export interface IProps {
  columnList: IColumn[];
  columnReturn: (columnList: string[]) => void;
  toggleVisibility: boolean;
}

interface IState {
  columnVisibility: string[];
}

class DynamicTableColumnControl extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      columnVisibility: Array.from(Array(this.props.columnList?.length)).map(
        () => 'true'
      ),
    };
  }

  t(keyName: string) {
    return i18n.t(`DynamicTableColumnControl.${keyName}`);
  }

  componentDidMount() {
    this.props.columnReturn(this.state.columnVisibility);
  }

  toggleColumnVisibility = (columnNo: number) => {
    const newcolumnVisibility = this.state.columnVisibility;
    newcolumnVisibility[columnNo] =
      newcolumnVisibility[columnNo] === 'true' ? 'false' : 'true';
    this.setState({ columnVisibility: newcolumnVisibility });
    this.props.columnReturn(newcolumnVisibility);
  };

  render() {
    const { columnList } = this.props;
    const { columnVisibility } = this.state;
    return (
      <>
        {this.props.toggleVisibility ? (
          <Table>
            <thead>
              <tr>
                {columnList.map((item: IColumn) => (
                  <th key={item.header} style={{ width: '15%' }}>
                    {item.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {columnVisibility.map((item: string, i: number) => (
                  <th key={i} style={{ width: '15%' }}>
                    <Button
                      color={item === 'true' ? 'primary' : 'danger'}
                      size="m"
                      onClick={() => {
                        this.toggleColumnVisibility(i);
                      }}
                    >
                      {item === 'true' ? this.t('visible') : this.t('hidden')}
                    </Button>
                  </th>
                ))}
              </tr>
            </tbody>
          </Table>
        ) : null}
      </>
    );
  }
}

export default DynamicTableColumnControl;
