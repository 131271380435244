import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Input,
  Table,
} from 'reactstrap';

import axios from '../../../services/axios/axios';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { SUBJECT_TYPE } from '../../../utils/enums/employee';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { handleError } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import { RootState } from '../../../redux/store';
import { IErrorMessage } from '../../../utils/types/commonTypes';
import {
  IEmployee,
  IEmployeeGroup,
  IEmployeeWithRelationship,
  IExtendedEmployeeGroup,
  IRoleGroup,
  ISubject2Role,
} from '../../../utils/types/modelTypes';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import EmployeeCard from './EmployeeCard';
import RoleCard from './RoleCard';
import RoleGroupCard from './RoleGroupCard';

type IProps = RouteComponentProps<{ id: string; pathname: string }>;

interface IState {
  employeeGroup: IEmployeeGroup;
  showModalError: boolean;
  employee: IEmployee;
  employeeInGroup: IEmployeeWithRelationship[];
  employeeGroupRoles: ISubject2Role[];
  employeeGroupRoleGroups: ISubject2Role[];
  showModalAddRole: boolean;
  modalForm: JSX.Element | null;
  deleteModal: null;
  showModalForm: boolean;
  editMode: boolean;
  roleGroupOptions: IRoleGroup[];
}

/*
 * Class that displays the details of an Employee Group
 */
class EmployeeGroupDetail extends React.Component<IProps, IState> {
  error: IErrorMessage = {} as IErrorMessage;

  constructor(props: IProps) {
    super(props);
    this.state = {
      employeeGroup: {},
      showModalError: false,
      employee: {} as IEmployee,
      employeeInGroup: [],
      employeeGroupRoles: [],
      employeeGroupRoleGroups: [],
      showModalAddRole: false,
      modalForm: null,
      deleteModal: null,
      showModalForm: false,
      editMode: false,
      roleGroupOptions: [],
    };
  }

  t(keyName: string) {
    return i18n.t(`EmployeeGroupDetail.${keyName}`);
  }

  async componentDidMount() {
    // Get the Employee Group Details
    await axios.employee
      .get(`extended-employee-groups?id.equals=${this.props.match.params.id}`)
      .then((response: AxiosResponse<IExtendedEmployeeGroup[]>) => {
        const extendedEmployee = response.data;
        this.setState({
          employeeGroup:
            extendedEmployee[0]?.employeeGroup ?? ({} as IEmployeeGroup),
          employeeGroupRoles: extendedEmployee[0]?.roles ?? [],
          employeeGroupRoleGroups: extendedEmployee[0]?.roleGroups ?? [],
          employeeInGroup: extendedEmployee[0]?.employeeRelations ?? [],
        });
      })
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveEmployeeGroupDetails');
        this.handleError(mainError, error);
      });

    // Get all of the role groups for the details and dropdown options
    await axios.employee
      .get('role-groups')
      .then((response: AxiosResponse<IRoleGroup[]>) => {
        this.setState({
          roleGroupOptions: response.data,
        });
      })
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveRoleGroups');
        this.handleError(mainError, error);
      });
  }

  handleError = (mainError: string, errorObject: AxiosError) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  toggleShowModalForm = () => {
    this.setState({ showModalForm: !this.state.showModalForm });
  };

  editDetails = () => {
    this.setState({
      editMode: true,
    });
  };

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      employeeGroup: {
        ...this.state.employeeGroup,
        name: event.target.value,
      },
    });
  };

  handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      employeeGroup: {
        ...this.state.employeeGroup,
        description: event.target.value,
      },
    });
  };

  /**
   * Method that handles the saving of employee group
   */
  saveEmployeeGroup = async () => {
    await axios.employee.put('employee-groups', this.state.employeeGroup);
    this.setState({
      editMode: false,
    });
  };

  render() {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>Employee Group Detail</HeaderTitle>
          {generateBreadcrumb(this.props.location.pathname, this.t('settings'))}
        </Header>
        <Container fluid>
          <Card>
            <CardHeader>
              {this.state.editMode ? (
                <div className="card-actions float-end">
                  {' '}
                  <Button
                    color="primary"
                    onClick={async () => {
                      await this.componentDidMount();
                      this.setState({
                        editMode: false,
                      });
                    }}
                  >
                    {generateTitle(
                      BUTTON_TITLE_ENUM.UNDO.code,
                      this.t('reset')
                    )}
                  </Button>{' '}
                  <Button
                    color="primary"
                    onClick={() => this.saveEmployeeGroup()}
                  >
                    {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, this.t('save'))}
                  </Button>{' '}
                </div>
              ) : (
                <Button
                  className="float-end"
                  color="primary"
                  onClick={() => this.editDetails()}
                >
                  {this.t('edit')}
                </Button>
              )}

              <CardTitle className="mb-0">
                <h1>{this.t('employeeGroupDetail')}</h1>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table>
                <tbody>
                  {this.state.editMode ? (
                    <>
                      {' '}
                      <tr>
                        <th>{this.t('name')}</th>
                        <td>
                          <Input
                            type="text"
                            value={this.state.employeeGroup.name}
                            onChange={this.handleNameChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{this.t('description')}</th>
                        <td>
                          {' '}
                          <Input
                            type="text"
                            value={this.state.employeeGroup.description}
                            onChange={this.handleDescriptionChange}
                          />
                        </td>
                      </tr>{' '}
                    </>
                  ) : (
                    <>
                      {' '}
                      <tr>
                        <th>{this.t('name')}</th>
                        <td>{this.state.employeeGroup.name}</td>
                      </tr>
                      <tr>
                        <th>{this.t('description')}</th>
                        <td>{this.state.employeeGroup.description}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Container>
        <RoleCard
          cardTitle={this.t('employeeGroupRoles')}
          buttonName={this.t('addRole')}
          subjectId={parseInt(this.props.match.params.id)}
          subjectType={SUBJECT_TYPE.employeeGroup.code}
          roles={this.state.employeeGroupRoles}
          showRoleAdd
        />
        <RoleGroupCard
          cardTitle={this.t('employeeGroupRoleGroups')}
          buttonName={this.t('addRoleGroup')}
          subjectType={SUBJECT_TYPE.employeeGroup.code}
          subjectId={parseInt(this.props.match.params.id)}
          roleGroups={this.state.employeeGroupRoleGroups}
          roleGroupOptions={this.state.roleGroupOptions}
        />
        <EmployeeCard
          buttonName={this.t('addEmployee')}
          employees={this.state.employeeInGroup}
          displayType={SUBJECT_TYPE.employeeGroup.code}
          roleOrGroupId={parseInt(this.props.match.params.id)}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error.mainError}
          errorReason={this.error.errorReason}
          errorResponse={this.error.errorResponse}
          modalTitle={this.t('error')}
        />
        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleShowModalForm}
          ref={this.state.modalForm}
          modalTitle={this.t('addRole')}
          size="m"
        >
          {this.state.modalForm}
        </ModalForm>
      </Container>
    );
  }
}

export default connect((store: RootState) => ({
  calling: store.calling,
}))(EmployeeGroupDetail);
