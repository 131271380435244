import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Button, Input } from 'reactstrap';
import { v4 } from 'uuid';

import { ProjectState } from '../../../../../utils/enums/project';
import { IDropdownOption } from '../../../../../utils/types/commonTypes';
import {
  IContactPersonDetail,
  IProjectDetail,
} from '../../../../../utils/types/responseTypes';
import withModals, { IWithModalsProps } from '../../../../../utils/withModals';
import type {
  ProjectContactPerson,
  ProjectDetailsForm,
} from '../../CreateNewProjectModal';
import DetailsCard from '../../../../../components/cards/DetailsCard';
import ContactPersonModal from '../../../../../components/form/ContactPersonModal';
import { t } from '../../projectListHelpers';
import {
  IApprovalRequest,
  IEmployee,
  IResponsible,
} from '../../../../../utils/types/modelTypes';
import { objectTypeEnum } from '../../../../../utils/enums/enum';
import { ApprovalObjectAttribute } from '../../../../../utils/enums/approval';
import { OBJECT_TYPE_ENUM } from '../../../../../utils/enums/objectType';
import { Ownership } from '../../../../../utils/enums/ownership';
import {
  getEmployee,
  getResponsibles,
} from '../../../../../services/api/employee';

interface IProps extends IWithModalsProps, RouteComponentProps {
  project: IProjectDetail;
  customerList: IDropdownOption<number>[];
  customerSiteList: IDropdownOption<number>[];
  contactPersonList: Map<number, ProjectContactPerson>;
  departmentList: IDropdownOption<number>[];
  setContactPersonList: Dispatch<
    SetStateAction<Map<number, ProjectContactPerson>>
  >;
  isCheckingTitle: boolean;
  isTitleValid: boolean;
  formValues: ProjectDetailsForm;
  setFormValues: Dispatch<SetStateAction<ProjectDetailsForm>>;
  onApproverChange: (approvalRequest: IApprovalRequest) => void;
}

/**
 * General Information Card in Internal Project Modal -
 * Displays project title, customer, customer site, contact persons and departments
 * and allows viewing of contact person details, and adding of new contact person
 */
const InternalProjectGeneralInformation = ({
  project: { title: originalTitle, state: projectState },
  customerList,
  customerSiteList,
  contactPersonList,
  departmentList,
  setContactPersonList,
  isCheckingTitle,
  isTitleValid,
  formValues: {
    state,
    title,
    customer,
    customerSite,
    contactPersons,
    departments,
  },
  setFormValues,
  onApproverChange,
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
  history,
}: IProps) => {
  const isRequired =
    state !== ProjectState.ORDERED && state !== ProjectState.LOST;

  const disabled =
    state === ProjectState.LOST || state === ProjectState.ORDERED;

  const contactPersonOptions = [...contactPersonList.values()]
    .filter(
      ({ contactPerson: { value } }) =>
        !contactPersons
          .map(({ contactPerson }) =>
            contactPerson ? contactPerson.value : null
          )
          .includes(value)
    )
    .map(({ contactPerson }) => contactPerson)
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  const handleContactPersonChange = (
    value: IDropdownOption<number>,
    idx: number
  ) => {
    const modifiedContactPersons = [...contactPersons];
    const contactPerson = contactPersonList.get(value.value);

    modifiedContactPersons.splice(idx, 1, {
      contactPerson: value,
      role: contactPerson?.role as string,
      functionRole: contactPerson?.functionRole as string,
    });

    setFormValues((formValues) => ({
      ...formValues,
      contactPersons: modifiedContactPersons,
    }));
  };

  const handleContactPersonDelete = (idx: number) => {
    if (contactPersons.length === 1) {
      modalErrorHandler(t('atLeastOneContactPersonRequired'));
      return;
    }

    const modifiedContactPersons = [...contactPersons];
    modifiedContactPersons.splice(idx, 1);

    setFormValues((formValues) => ({
      ...formValues,
      contactPersons: modifiedContactPersons,
    }));
  };

  const contactPersonSections = contactPersons.map(
    ({ contactPerson }, idx) => ({
      key: v4(),
      fields: [
        {
          label: t('contactPerson'),
          value: (
            <div style={{ display: 'flex' }}>
              <div style={{ width: '300px' }}>
                <Select
                  aria-label="project-info-input-contact"
                  value={contactPerson}
                  options={contactPersonOptions}
                  placeholder={t('selectContactPerson')}
                  onChange={(value: IDropdownOption<number>) =>
                    handleContactPersonChange(value, idx)
                  }
                  isDisabled={disabled}
                />
              </div>
              <Button
                aria-label="project-info-button-delete-contact"
                color="link"
                onClick={() => handleContactPersonDelete(idx)}
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          ),
        },
        {
          label: t('role'),
          value: contactPersons[idx]?.role || 'N/A',
        },
        {
          label: t('function'),
          value: contactPersons[idx]?.functionRole || 'N/A',
        },
        {
          value: (
            <Button
              aria-label="project-info-button-show-contact"
              color="primary"
              size="sm"
              onClick={() => {
                history.push(
                  `/customer/my-contacts/profile/${
                    contactPersons[idx]?.contactPerson?.value as number
                  }`
                );
              }}
              disabled={!contactPersons[idx]?.contactPerson?.value}
            >
              {t('contactPersonDetails')}
            </Button>
          ),
        },
      ],
    })
  );

  const handleTitleChange = (title: string) => {
    setFormValues((formValues) => ({
      ...formValues,
      title,
    }));
  };

  const handleCustomerChange = (value: IDropdownOption<number>) => {
    setFormValues((formValues) => ({
      ...formValues,
      customer: value,
      customerSite: {} as IDropdownOption<number>,
      contactPersons: [],
    }));
  };

  const handleCustomerSiteChange = (value: IDropdownOption<number>) => {
    setFormValues((formValues) => ({
      ...formValues,
      customerSite: value,
      contactPersons: [
        {} as {
          contactPerson: IDropdownOption<number>;
          role: string;
          functionRole: string;
        },
      ],
    }));
  };

  const handleDepartmentsChange = async (
    department: IDropdownOption<number>
  ) => {
    setFormValues((formValues) => ({
      ...formValues,
      departments: [department],
    }));

    // In general, selected department will always be one
    // Get the Responsibles
    try {
      const { data: responsibles } = await getResponsibles({
        'ownership.in': Ownership.RESPONSIBLE,
        'objectType.equals': OBJECT_TYPE_ENUM.department.code,
        'objectId.equals': department.value.toString(),
        'ownership.in=': Ownership.RESPONSIBLE,
      });

      const retrievedResponsible = responsibles[0] ?? ({} as IResponsible);
      // Get employee details based on the retrieved responsible
      if (retrievedResponsible?.employeeId !== undefined) {
        try {
          const { data: employee } = await getEmployee(
            retrievedResponsible.employeeId
          );
          const headOfDepartment = employee ?? ({} as IEmployee);
          // Create approvalRequest
          const approvalRequest = {
            id: 0,
            objectId: 0,
            objectType: objectTypeEnum.project.code,
            objectAttribute: ApprovalObjectAttribute.PROJECT_STATE,
            attributeValueConfirmed: projectState,
            attributeValueRejected: state,
            modifiable: true,
            createdDate: new Date().toISOString(),
            employee: headOfDepartment,
          };
          onApproverChange(approvalRequest);
        } catch (error) {
          modalErrorHandler(t('failedToRetrieveEmployee'), error);
        }
      }
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveResponsibles'), error);
    }
  };

  const fields = [
    {
      label: t('project'),
      value: (
        <>
          <Input
            aria-label="project-info-input-title"
            type="string"
            bsSize="lg"
            value={title}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleTitleChange(event.target.value)
            }
            disabled={disabled}
          />
          {isCheckingTitle && (
            <>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ color: 'gray' }}
                className="margin-right"
              />
              {t('checkingTitle')}
            </>
          )}
          {title && !isCheckingTitle && originalTitle !== title && (
            <>
              <FontAwesomeIcon
                icon={isTitleValid ? faCheckCircle : faTimesCircle}
                style={{ color: isTitleValid ? 'green' : 'red' }}
                className="margin-right"
              />
              {t(isTitleValid ? 'validTitle' : 'invalidTitle')}
            </>
          )}
        </>
      ),
      isRequired,
    },
    {
      label: t('customer'),
      value: (
        <Select
          aria-label="project-info-input-customer"
          value={customer.value ? customer : ''}
          options={customerList}
          placeholder={t('selectCustomer')}
          onChange={handleCustomerChange}
          isDisabled={disabled}
        />
      ),
      isRequired,
    },
    {
      label: t('customerSite'),
      value: (
        <Select
          aria-label="project-info-input-customer-site"
          value={customerSite.value ? customerSite : ''}
          options={customerSiteList}
          placeholder={t('selectLocation')}
          onChange={handleCustomerSiteChange}
          isDisabled={!customer.value || disabled}
        />
      ),
      isRequired,
    },
    {
      label: t('department'),
      value: (
        <Select
          aria-label="project-general-information-input-departments"
          isClearable
          placeHolder={t('selectDepartment')}
          options={departmentList}
          value={departments}
          onChange={handleDepartmentsChange}
          isDisabled={disabled}
        />
      ),
      isRequired,
    },
  ];

  const afterAddingNewContactPerson = ({
    id,
    lastname,
    firstname,
    role: { name: role },
    functionRole,
  }: IContactPersonDetail) => {
    toggleModalForm();
    const { contactPerson } = contactPersons[contactPersons.length - 1] || {
      contactPerson: { value: 0 },
    };

    if (contactPerson && contactPerson.value) {
      setFormValues((formValues) => ({
        ...formValues,
        contactPersons: [
          ...contactPersons,
          {
            contactPerson: {
              label: `${firstname} ${lastname}`,
              value: id,
            },
            role,
            functionRole,
          },
        ],
      }));
    } else {
      const modifiedContactPersons = [...contactPersons];
      modifiedContactPersons.splice(contactPersons.length - 1, 1, {
        contactPerson: {
          label: `${firstname} ${lastname}`,
          value: id,
        },
        role,
        functionRole,
      });

      setFormValues((formValues) => ({
        ...formValues,
        contactPersons: modifiedContactPersons,
      }));
    }

    setContactPersonList(
      contactPersonList.set(id, {
        contactPerson: {
          label: `${firstname} ${lastname}`,
          value: id,
        },
        role,
        functionRole,
      })
    );
  };

  const handleAddNewContactPerson = () => {
    modalFormHandler(
      <>
        <FontAwesomeIcon icon={faTrash} />
        {t('addContactPerson')}
      </>,
      <ContactPersonModal
        onSave={(contactPersonDetail: IContactPersonDetail) =>
          afterAddingNewContactPerson(contactPersonDetail)
        }
        onCancel={toggleModalForm}
        disableCustomerAndCustomerSite={!!customerSite}
        customerId={customer.value ?? 0}
        customerSiteId={customerSite.value ?? 0}
      />,
      'xl'
    );
  };

  const handleAddExistingContactPerson = () => {
    setFormValues((formValues) => ({
      ...formValues,
      contactPersons: [
        ...contactPersons,
        {} as {
          contactPerson: IDropdownOption<number>;
          role: string;
          functionRole: string;
        },
      ],
    }));
  };

  const footerButtons = (
    <>
      <Button
        aria-label="project-info-button-add-new-contact"
        color="primary"
        size="sm"
        onClick={handleAddNewContactPerson}
        disabled={!customer.value || !customerSite.value || disabled}
      >
        {t('addNewContact')}
      </Button>
      <Button
        aria-label="project-info-button-add-existing-contact"
        color="primary"
        size="sm"
        onClick={handleAddExistingContactPerson}
        disabled={!customer.value || !customerSite.value || disabled}
      >
        {t('addExistingContactPerson')}
      </Button>
    </>
  );

  return (
    <DetailsCard
      title={t('generalInformation')}
      fields={fields}
      additionalSections={contactPersonSections}
      footerButtons={footerButtons}
      border
    />
  );
};

export default withRouter(withModals(InternalProjectGeneralInformation));
