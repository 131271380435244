import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Input,
  FormGroup,
  FormFeedback,
} from 'reactstrap';
import axios from '../../../services/axios/axios';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import InputFormLabel from '../../../components/form/InputFormLabel';
import {
  addRecord,
  dataGeneration,
  updateRecord,
} from '../../../utils/constants';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { errorHandler } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';
import ModalOK from '../../../components/modals/ModalOK';

/**
 * Contract Type Input Form
 *
 * Allows user to add or update an existing contract type on employee settings
 */

class ContractTypeInputForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdate: false,
      id: null,
      type: '',
      types: [],
      description: '',
      showModalOK: false,
    };
  }

  t(keyName) {
    return i18n.t('ContractTypeInputForm.' + keyName);
  }

  //Toggle for showing the modal for duplicate entries.
  ToggleModalOK = () => {
    this.setState({ showModalOK: !this.state.showModalOK });
  };

  componentDidMount() {
    axios.employee.get('contract-types').then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({
          types: response.data,
        });
      }
    });
    if (this.props.match.params.id) {
      axios.employee
        .get(`contract-types/${this.props.match.params.id}`)
        .then((response) => {
          this.setState({
            type: response.data.type,
            description: response.data.description,
          });
        })
        .catch((error) => {
          this.message = errorHandler(
            dataGeneration,
            error,
            this.t('contractType')
          );
          this.TogglModalError();
        });
      this.setState({
        id: this.props.match.params.id,
        isUpdate: true,
      });
    }
  }

  handleSubmit = (event) => {
    var sameNameExistingEntry = this.state.types.find(
      (item) => item.type === this.state.type
    );
    if (
      sameNameExistingEntry !== undefined &&
      sameNameExistingEntry?.id !== parseInt(this.state.id, 10)
    ) {
      this.errorMessage = this.t('nameAlreadyExist');
      this.ToggleModalOK();
      return;
    }
    this.state.isUpdate ? this.updateForm() : this.insertForm();
  };

  updateForm() {
    var formData = {
      id: this.state.id,
      type: this.state.type,
      description: this.state.description,
    };

    axios.employee
      .put('contract-types', formData)
      .then((response) => {
        this.props.history.push({
          pathname: '/settings/employee',
          state: {
            successMessage: `${this.t('updatedContractType')}${
              response.data.id
            }`,
          },
        });
      })
      .catch((error) => {
        this.message = errorHandler(
          updateRecord,
          error,
          this.t('contractType')
        );
        this.ToggleModalError();
      });
  }

  insertForm() {
    var formData = {
      id: this.state.id,
      type: this.state.type,
      description: this.state.description,
    };

    axios.employee
      .post('contract-types', formData)
      .then((response) => {
        if (this.props.onSave) {
          this.props.onSave();
        } else {
          this.props.history.push({
            pathname: this.props.location?.state?.previousPath,
            state: {
              successMessage: this.t('addedNewContractType'),
            },
          });
        }
      })
      .catch((error) => {
        this.message = errorHandler(addRecord, error, this.t('contractType'));
        this.TogglModalError();
      });
  }

  handleTypeChange = (e) => {
    this.setState({ type: e.target.value });
  };

  handleDescriptionChange = (e) => {
    this.setState({ description: e.target.value });
  };

  ToggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };
  render() {
    return (
      <Container fluid>
        {this.state.isUpdate ? (
          <Header>
            <HeaderTitle>Contract Type Detail</HeaderTitle>
            {generateBreadcrumb(
              this.props.location.pathname,
              this.t('settings')
            )}
          </Header>
        ) : null}
        <Card>
          <CardHeader>
            <CardTitle>
              <h1>
                {this.state.isUpdate
                  ? 'Contract Type Detail' +
                    ' - ' +
                    `${this.props.match.params.id}`
                  : null}
              </h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <InputFormLabel isRequired={true} text={this.t('contractType')} />
              <Input
                onChange={this.handleTypeChange}
                value={this.state.type}
                name="type"
                type="text"
                invalid={this.state.calendarTitle === undefined}
              />
              <FormFeedback>{this.t('contractTypeRequired')}</FormFeedback>
            </FormGroup>

            <InputFormLabel isRequired={false} text={this.t('description')} />
            <Input
              type="textbox"
              onChange={this.handleDescriptionChange}
              value={this.state.description}
            />

            <div className="float-end" style={{ marginTop: 15 }}>
              <Button color="primary" size="m" onClick={this.handleSubmit}>
                Save
              </Button>

              {this.state.isUpdate ? (
                <Link to="/settings/employee">
                  <Button style={{ marginLeft: 15 }} color="primary">
                    {this.t('cancel')}
                  </Button>
                </Link>
              ) : null}
            </div>
          </CardBody>
        </Card>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.ToggleModalError}
          mainError={this.message?.mainError}
          errorReason={this.message?.errorReason}
          errorResponse={this.message?.errorResponse}
          modalTitle="Error"
        />
        <ModalOK
          isOpen={this.state.showModalOK}
          onClose={this.ToggleModalOK}
          modalTitle="Error"
          modalBodyText={this.errorMessage}
        />
      </Container>
    );
  }
}

export default withRouter(ContractTypeInputForm);
