import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IEmployee, IPermission, IUserAccount } from '../utils/types/modelTypes';
import { IAccountState } from '../utils/types/stateTypes';

const initialState: IAccountState = {
  accountDetails: {} as IUserAccount,
  defaultCurrency: '',
  employeeDetails: {} as IEmployee,
  permissions: [],
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountDetails: (state, { payload }: PayloadAction<IUserAccount>) => ({
      ...state,
      accountDetails: payload,
    }),
    setDefaultCurrency: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      defaultCurrency: payload,
    }),
    setEmployeeDetails: (state, { payload }: PayloadAction<IEmployee>) => ({
      ...state,
      employeeDetails: payload,
    }),
    setPermissions: (state, { payload }: PayloadAction<IPermission[]>) => ({
      ...state,
      permissions: payload,
    }),
  },
});

export const {
  setAccountDetails,
  setDefaultCurrency,
  setEmployeeDetails,
  setPermissions,
} = accountSlice.actions;

export default accountSlice.reducer;
