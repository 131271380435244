import { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';
import {
  generateTitle,
} from '../../utils/helpers/icon';
import { clickHandler, modalCloseButton } from '../../utils/helpers/click';
import i18n from '../../i18n';

/**
 * ModalError contains commands that would enable a modal dialog for errors
 * to be opened up in a webpage
 * @Author Reimon Tito
 */
const ModalError = (props) => {
  const { modalTitle, mainError, errorReason, errorResponse, isOpen, onClose } =
    props;

  const [modal, setModal] = useState(isOpen);

  const toggle = () => {
    onClose && onClose();
    setModal(!modal);
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  var AcceptButton = (
    <Button color="dark" onClick={(event) => clickHandler(event, toggle)}>
      {i18n.t('ModalError.ok')}
    </Button>
  );

  return (
    <Draggable>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader close={modalCloseButton(toggle)}>
          {generateTitle(BUTTON_TITLE_ENUM.ERROR.code, modalTitle)}
        </ModalHeader>
        <ModalBody>
          <table>
            <tbody>
              {Array.isArray(mainError) && mainError.length > 0 ? (
                mainError.map((error, i) => (
                  <tr key={`entity-${i}`}>
                    <td>{`- ${error?.errorMessage}`}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>{mainError}</td>
                </tr>
              )}
              {errorReason === '' ? null : (
                <tr>
                  <td>
                    {i18n.t('ModalError.reason')} {errorReason}
                  </td>
                </tr>
              )}
              {errorResponse === '' ? null : (
                <tr>
                  <td>
                    {i18n.t('ModalError.requestResponse')} {errorResponse}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>{AcceptButton}</ModalFooter>
      </Modal>
    </Draggable>
  );
};

export default ModalError;
