import moment, { Moment } from 'moment';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import DateTime from 'react-datetime';
import { ConnectedProps, connect } from 'react-redux';

import { RootState } from '../../../redux/store';
import user from '../../../user';
import { ContactPersonDetailsContext } from './ContactPersonDetailsProvider';
import {
  IContactPersonAssignment,
  IEmailBody,
} from '../../../utils/types/modelTypes';
import { cleanValues } from './contactPersonHelper';

interface IProps extends PropsFromRedux {
  modal: boolean;
  toggle: () => void;
  sendEmail: (emailBody: IEmailBody) => Promise<void>;
  status: IContactPersonAssignment;
  emailAdd: string;
}

const ModalSendEmail = ({
  modal,
  toggle,
  sendEmail,
  status,
  emailAdd,
  employeeDetails: { firstname: employeeFirstName, name: employeeLastName },
}: IProps) => {
  const {
    contactDetail: { firstname, lastname },
  } = useContext(ContactPersonDetailsContext);

  const [emailBody, setEmailBody] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [eventDate, setDate] = useState<Moment>(moment());
  const [eventStart, setStart] = useState<Moment>(moment());
  const [eventEnd, setEnd] = useState<Moment>(moment());

  const handleSendEmail = async () => {
    const start = cleanValues(eventDate, eventStart);
    const end = cleanValues(eventDate, eventEnd);
    const email = {
      email: emailBody,
      start,
      end,
      addresses: [
        {
          name: `${firstname} ${lastname}`,
          email: emailAddress,
        },
        {
          name: `${employeeFirstName} ${employeeLastName}`,
          email: user.userId,
        },
      ],
    } as IEmailBody;
    await sendEmail(email);
  };

  useEffect(() => {
    if (status) {
      setDate(moment(status.meeting));
      setStart(moment(status.meeting));
      setEnd(moment(status.meeting).add(2, 'hours'));
    }
    if (emailAdd) {
      setEmailAddress(emailAdd);
    }

    setEmailBody(
      `Good Day ${firstname} ${lastname}\n\n` +
        'Thank you for the pleasant phone call earlier. As agreed, I would like to confirm our meeting this way' +
        `\n\n${status?.notes ?? ''}\n\n` +
        'I am looking forward to our meeting'
    );
  }, [firstname, lastname, status, emailAdd]);

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Send Invite</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Label>Email Address:</Label>
            <Input
              type="text"
              value={emailAddress}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setEmailAddress(event.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Date:</Label>
            <DateTime
              timeFormat={false}
              dateFormat
              onChange={(event) => {
                setDate(moment(event));
              }}
              closeOnSelect
              value={eventDate}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Start:</Label>
            <DateTime
              timeFormat
              dateFormat={false}
              onChange={(event) => {
                setStart(moment(event));
              }}
              closeOnSelect
              value={eventStart}
            />
          </Col>
          <Col>
            <Label>End:</Label>
            <DateTime
              timeFormat
              dateFormat={false}
              onChange={(event) => {
                setEnd(moment(event));
              }}
              closeOnSelect
              value={eventEnd}
            />
          </Col>
        </Row>

        <Form>
          <Label>
            This is the default email text, please change some things if you
            like.
          </Label>
          <Input
            type="textarea"
            value={emailBody}
            style={{ height: 500 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setEmailBody(event.target.value);
            }}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSendEmail}>
          Confirm
        </Button>
        &nbsp;
        <Button color="dark" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (store: RootState) => ({
  employeeDetails: store.account.employeeDetails,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModalSendEmail);
