import React from 'react';
import axios from './services/axios/axios';
export default class logout extends React.Component {
  async componentDidMount() {
    axios.employee.post('logout').then((response) => {
      window.location.href = response.data.logoutUrl;
    });
  }

  render() {
    return null;
  }
}
