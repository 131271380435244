import axios, { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { urlBuilder } from '../../utils/helpers/url';
import { Url } from './constants';
import { ICalendarDay } from '../../utils/types/modelTypes';

export const getCalendarDay = (calendarId: number) =>
  trackPromise<AxiosResponse<ICalendarDay>>(
    axios.post(urlBuilder(`${Url.CALENDAR_DAYS}/${calendarId}`, {}), {})
  );

export const getCalendarDays = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<ICalendarDay[]>>(
    axios.get(urlBuilder(Url.CALENDAR_DAYS, queryParameters))
  );
