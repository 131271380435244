import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INotification } from '../utils/types/modelTypes';
import { INotificationsState } from '../utils/types/stateTypes';

const initialState: INotificationsState = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    loadNotifications: (
      state,
      { payload }: PayloadAction<INotification[]>
    ) => ({
      ...state,
      notifications: payload,
    }),
  },
});

export const { loadNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
