import React from 'react';
import { Link } from 'react-router-dom';

import { PROJECT_CURRENT } from '../../../utils/constants';
import i18n from '../../../i18n';
import {
  IEmployeeDetail,
  IEmployeeProjectListItem,
} from '../../../utils/types/responseTypes';

export const edt = (keyName: string) => i18n.t(`EmployeeDetails.${keyName}`);

export enum EmployeeDetailFieldsProperties {
  RELEASE = 'release',
  INVOLVED = 'involved',
}

/**
 * Creates employee project entry (row) in Employee Projects Table
 * @param EmployeeListItem Item to create a row for in Employee List Table
 * @returns Row / Employee Entry for Employee List Table
 */
export const createProjectEmployeeEntry = (
  {
    id,
    title: projectTitle,
    projectId,
    customerSite: { name: projectSite },
    state: projectStatus,
    role: projectRole,
    responsible: { name: projectResponsible },
    start: projectStart,
    end: projectEnd,
    assigned: projectAssigned,
  }: IEmployeeProjectListItem,
  type: string
) => {
  const projectEntry = {
    [edt('projectTitle')]: projectTitle,
    [edt('projectId')]: projectId ?? 'N/A',
    [edt('projectSite')]: projectSite,
    [edt('projectStatus')]: projectStatus,
    [edt('projectRole')]: projectRole,
    [edt('projectResponsible')]: projectResponsible,
    [edt('projectStart')]: projectStart,
    [edt('projectEnd')]: projectEnd,
  } as { [x: string]: React.JSX.Element | string };

  return type === PROJECT_CURRENT
    ? {
        [edt('projectTitle')]: id ? (
          <Link to={`/projects/overview/project-details/${id}`}>
            {projectTitle}
          </Link>
        ) : (
          <div>{projectTitle}</div>
        ),
        ...projectEntry,
        [edt('projectAssigned')]: projectAssigned,
      }
    : {
        [edt('projectTitle')]: projectTitle,
        ...projectEntry,
        [edt('projectEnd')]: projectEnd,
      };
};

/**
 * Updates the employee Detail
 * @param employeeDetailOld
 * @param employeeDetailNew
 * @returns updatedEmployeeDetail
 */
export const updatedEmployeeDetail = (
  employeeDetailOld: IEmployeeDetail,
  {
    releaseStatus,
    involvedResponsibles,
    mobility,
    employeeDefense,
    employeeAbroad,
    commitment,
  }: IEmployeeDetail
) =>
  ({
    ...employeeDetailOld,
    releaseStatus: releaseStatus ?? employeeDetailOld.releaseStatus,
    involvedResponsibles:
      involvedResponsibles ?? employeeDetailOld.involvedResponsibles,
    mobility: mobility ?? employeeDetailOld.mobility,
    employeeDefense: employeeDefense ?? employeeDetailOld.employeeDefense,
    employeeAbroad: employeeAbroad ?? employeeDetailOld.employeeAbroad,
    commitment: commitment ?? employeeDetailOld.commitment,
  } as IEmployeeDetail);
