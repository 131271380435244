import { connect, ConnectedProps } from 'react-redux';
import React, { ChangeEvent, useEffect } from 'react';
import { AxiosError } from 'axios';

import { RootState } from '../../../redux/store';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  setListItems,
  setHasMoreListItems,
  setPage,
  setFilters,
  toggleFilter,
  setSortBy,
  setSortType,
  fetchEmployeeListItems,
} from '../../../redux/employeeListSlice';
import useMounted from '../../../hooks/useMounted';
import { IEmployeeListItem } from '../../../utils/types/responseTypes';
import DynamicTable from '../../../components/tables/DynamicTable';
import { IEmployeeListFilters } from '../../../utils/types/stateTypes';
import { SortBy, SortType } from '../../../utils/enums/sort';
import { getNextSortType } from '../../../utils/helpers/table';
import { createEmployeeEntry, t } from './employeeListHelpers';
import { DEFAULT_LOAD_TIMEOUT } from '../../../utils/constants';
import { isEmpty } from '../../../utils/helpers/array';

interface IProps extends IWithModalsProps, PropsFromRedux {}

const EmployeeListTable = ({
  // WithModals
  modalErrorHandler,
  // Redux State
  listItems,
  filters,
  showFilters,
  hasMore,
  sortBy,
  sortType,
  // Redux actions
  setHasMoreListItems,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  // Thunks
  fetchEmployeeListItems,
}: IProps) => {
  const isMounted = useMounted();

  /**
   * Handles the fetching of User list items
   * @param isSortOrFilterFetchType Whether the data fetching is for sorting / filtering / scrolling
   */
  const fetchEmployeeList = (isSortOrFilterFetchType = false) => {
    fetchEmployeeListItems(isSortOrFilterFetchType, (error: AxiosError) => {
      modalErrorHandler(t('Users'), error);
    });
  };

  // Fetch Employee list on component mount
  useEffect(() => {
    if (isEmpty(listItems)) {
      fetchEmployeeList(false);
    }
  }, []);

  // Fetch User list on sort update
  useEffect(() => {
    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);
      fetchEmployeeList(true);
    }
  }, [sortType, sortBy]);

  // Fetch User list on filter update
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isMounted) {
      timer = setTimeout(() => {
        setPage(0);
        setHasMoreListItems(true);
        fetchEmployeeList(true);
      }, DEFAULT_LOAD_TIMEOUT);
    }
    return () => clearTimeout(timer);
  }, [filters]);

  /**
   * Handles the sorting logic
   * @param nextSortBy
   */
  const handleSort = (nextSortBy: SortBy) => {
    const nextSortType = getNextSortType(sortType, nextSortBy, sortBy);

    setSortType(
      nextSortType === SortType.DEFAULT ? SortType.DESCENDING : nextSortType
    );
    setSortBy(nextSortType === SortType.DEFAULT ? SortBy.ID : nextSortBy);
  };

  /**
   * Handles the input filter's value change
   * @param event
   * @param fieldName
   */
  const handleInputFilterChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: event.target.value ?? '',
    });
  };

  /**
   * Creates the columns of the table, specificaly formatted for dynamic table
   * @returns the parameters for dynamic table to display the data
   */
  const tableColumns = [
    {
      type: 'data',
      header: t('lastname'),
      accessor: 'lastname',
      show: 'true',
      filterValue: filters['lastname' as keyof IEmployeeListFilters],
      filterFunc: handleInputFilterChange,
      sortFunc: handleSort,
    },
    {
      type: 'data',
      header: t('firstname'),
      accessor: 'firstname',
      show: 'true',
      filterValue: filters['firstname' as keyof IEmployeeListFilters],
      filterFunc: handleInputFilterChange,
      sortFunc: handleSort,
    },
    {
      type: 'data',
      header: t('abbreviation'),
      accessor: 'abbreviation',
      show: 'true',
    },
    {
      type: 'data',
      header: t('department'),
      accessor: 'department',
      show: 'true',
      filterValue: filters['department' as keyof IEmployeeListFilters],
      filterFunc: handleInputFilterChange,
    },
    {
      type: 'data',
      header: t('location'),
      accessor: 'location',
      show: 'true',
      filterValue: filters['location' as keyof IEmployeeListFilters],
      filterFunc: handleInputFilterChange,
    },
    {
      type: 'data',
      header: t('careerLevel'),
      accessor: 'careerLevel',
      show: 'true',
      filterValue: filters['careerLevel' as keyof IEmployeeListFilters],
      filterFunc: handleInputFilterChange,
    },
    {
      type: 'data',
      header: t('responsibleName'),
      accessor: 'responsibleName',
      show: 'true',
      filterValue: filters['responsibleName' as keyof IEmployeeListFilters],
      filterFunc: handleInputFilterChange,
    },
  ];

  return (
    <DynamicTable
      data={listItems.map((User: IEmployeeListItem) =>
        createEmployeeEntry(User)
      )}
      columns={tableColumns}
      fetchData={fetchEmployeeList}
      hasMoreData={hasMore}
      infiniteScroll
      sortType={sortType}
      sortBy={sortBy}
      enableFilterFunction
      showFilters={showFilters}
    />
  );
};

const mapStateToProps = (store: RootState) => ({
  listItems: store.employeeList.listItems,
  filters: store.employeeList.filters,
  showFilters: store.employeeList.showFilters,
  hasMore: store.employeeList.hasMore,
  page: store.employeeList.page,
  sortBy: store.employeeList.sortBy,
  sortType: store.employeeList.sortType,
});

const mapDispatchToProps = {
  fetchEmployeeListItems,
  setListItems,
  setHasMoreListItems,
  setPage,
  setFilters,
  toggleFilter,
  setSortBy,
  setSortType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(EmployeeListTable));
