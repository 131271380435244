import { trackPromise } from 'react-promise-tracker';
import axios, { AxiosResponse } from 'axios';

import { IOrderDetails } from '../../utils/types/responseTypes';
import { Url } from './constants';
import { urlBuilder } from '../../utils/helpers/url';

export const saveOrderDetails = (order: IOrderDetails) =>
  trackPromise<AxiosResponse<IOrderDetails>>(
    axios.post(urlBuilder(Url.ORDER_DETAILS, {}), order)
  );
