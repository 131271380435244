import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';

import withModals, { IWithModalsProps } from '../../utils/withModals';
import {
  IFileAttachment,
  IFileAttachmentListItem,
} from '../../utils/types/modelTypes';
import { generateFileAttachmentLink } from '../../utils/helpers/fileAttachment';
import { generateTitle } from '../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';
import * as Constants from '../../utils/constants';
import TableCard from './TableCard';
import i18n from '../../i18n';
import AddOrUpdateFileAttachmentForm from '../form/AddOrUpdateFileAttachmentForm';
import { deleteAttachment } from '../../services/api/fileAttachment';

/**
 * Common Card used  for File attachments
 * Props (fileAttachments, objectId, objectType, onSave, onDelete).\
 * fileAttachments: array of files
 * objectid = id of object
 * objectType = REQUEST, OFFER or ORDER, PROJECT
 */
interface IProps extends IWithModalsProps {
  fileAttachments: IFileAttachmentListItem[];
  objectId: number;
  objectType: string;
  onSave?: (file: IFileAttachment) => void;
  onDelete?: () => void;
}

const t = (keyName: string) => i18n.t(`FileAttachmentCard.${keyName}`);

const FileAttachmentCard = ({
  modalErrorHandler,
  modalDeleteHandler,
  modalFormHandler,
  toggleModalForm,
  fileAttachments,
  objectId,
  objectType,
  onSave,
  onDelete,
}: IProps) => {
  const [files, setFiles] = useState([] as IFileAttachmentListItem[]);

  const saveAttachment = (attachment: IFileAttachment, index?: number) => {
    const { id, fileName, description, fileObject, fileObjectContentType } =
      attachment;

    const newAttachment = {
      id,
      fileName,
      description,
      fileObject,
      fileObjectContentType,
    } as IFileAttachmentListItem;

    if (index !== undefined) {
      const newFiles = [...files];
      newFiles[index] = newAttachment;
      setFiles(newFiles);
    } else {
      setFiles([newAttachment, ...files]);
    }
    toggleModalForm();
    if (onSave) {
      onSave(attachment);
    }
  };

  const addOrUpdateFile = (file?: IFileAttachmentListItem, index?: number) => {
    modalFormHandler(
      generateTitle(BUTTON_TITLE_ENUM.UPLOAD.code, t('uploadAttachment')),
      <AddOrUpdateFileAttachmentForm
        onCancel={toggleModalForm}
        onSave={(attachment) => {
          saveAttachment(attachment, index);
        }}
        file={file}
        objectId={objectId}
        objectType={objectType}
      />,
      'lg'
    );
  };

  const onDeleteFileAttachment = (
    { id }: IFileAttachmentListItem,
    index: number
  ) => {
    // Confirm if the user wants to delete attachment
    modalDeleteHandler(
      t('deleteAttachment'),
      t('deleteAttachmentConfirm'),
      async () => {
        // Delete attachment in the database if it already exist.
        if (id) {
          await deleteAttachment(id).catch((error) => {
            modalErrorHandler(t('failedToDeleteFileAttachment'), error);
          });
        }
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
      }
    );
    if (onDelete) {
      onDelete();
    }
  };

  const tableData = files.map((file, index) => ({
    [t('fileName')]: (
      <a
        target="_blank"
        download={file.fileName}
        href={generateFileAttachmentLink(file)}
        rel="noopener noreferrer"
      >
        {file?.fileName}
      </a>
    ),
    '': (
      <div className="table-buttons" style={{ float: 'right' }}>
        <Button
          style={{ margin: '2px' }}
          color="primary"
          onClick={() => {
            addOrUpdateFile(file, index);
          }}
        >
          {generateTitle(BUTTON_TITLE_ENUM.UPDATE.code)}
        </Button>
        <Button
          style={{ margin: '2px' }}
          color="primary"
          onClick={() => {
            onDeleteFileAttachment(file, index);
          }}
        >
          {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
        </Button>
      </div>
    ),
  }));

  const headerButtons = (
    <Button color="primary" size="m" onClick={() => addOrUpdateFile()}>
      {generateTitle(
        BUTTON_TITLE_ENUM.UPLOAD.code,
        t(Constants.uploadAttachment.toLowerCase())
      )}
    </Button>
  );

  useEffect(() => {
    setFiles(fileAttachments);
  }, [fileAttachments]);

  return (
    <TableCard
      headerButtons={headerButtons}
      border
      leftTitle
      title={t('fileAttachment')}
      tableData={tableData}
      noDataPlaceholder={t('noAttachmentsFound')}
    />
  );
};

export default withModals(FileAttachmentCard);
