import { createSlice } from '@reduxjs/toolkit';

import { ISidebarState } from '../utils/types/stateTypes';

const initialState: ISidebarState = {
  isOpen: true,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state) => ({
      ...state,
      isOpen: !state.isOpen,
    }),
  },
});

export const { toggleSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
