import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { Label } from 'reactstrap';

import { IDropdownOption } from '../../utils/types/commonTypes';
import Table, { TableData } from './Table';

import './Table.scss';

const itemsPerPageOptions = [
  {
    value: 3,
    label: 3,
  },
  {
    value: 5,
    label: 5,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
];

interface IProps {
  tableData: TableData[];
  highlightedRows?: number[];
  noDataPlaceholder?: string;
  striped?: boolean;
  defaultSize?: number;
}

/**
 * Paginated Table -
 * Displays data in table form with paginations, and has options for
 * highlighting rows and striped rows
 */
const PaginatedTable = ({
  tableData,
  highlightedRows = [],
  noDataPlaceholder,
  striped,
  defaultSize,
}: IProps) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(defaultSize ?? 3);

  const displayedData = tableData.slice(itemOffset, itemOffset + itemsPerPage);

  const handlePageClick = ({ selected }: { selected: number }) => {
    setItemOffset((selected * itemsPerPage) % tableData.length);
  };

  const handleItemsPerPageSelect = ({ value }: IDropdownOption<number>) => {
    setItemsPerPage(value);
  };

  return (
    <>
      <Table
        tableData={displayedData}
        highlightedRows={highlightedRows}
        {...(noDataPlaceholder && { noDataPlaceholder })}
        {...(striped && { striped })}
      />
      {tableData.length > 0 && (
        <div>
          <ReactPaginate
            pageCount={Math.ceil(tableData.length / itemsPerPage)}
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            onPageChange={handlePageClick}
            containerClassName="paginated-table"
            pageLinkClassName="page"
            previousLinkClassName="page"
            nextLinkClassName="page"
            activeLinkClassName="page-active"
            breakLinkClassName="break"
          />
          <div className="float-end">
            <Label>Data per page:</Label>
            <Select
              aria-label="paginated-table-select"
              options={itemsPerPageOptions}
              onChange={handleItemsPerPageSelect}
              value={{ label: itemsPerPage, value: itemsPerPage }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PaginatedTable;
