export const OBJECT_TYPE_ENUM = {
  request: { code: 'REQUEST', name: 'Request' },
  order: { code: 'ORDER', name: 'Order' },
  offer: { code: 'OFFER', name: 'Offer' },
  customer: { code: 'CUSTOMER', name: 'Customer' },
  finance: { code: 'FINANCE', name: 'Finance' },
  contactPerson: { code: 'CONTACT_PERSON', name: 'Contact Person' },
  call: { code: 'CALL', name: 'Call' },
  customerSite: { code: 'CUSTOMER_SITE', name: 'Customer Site' },
  customerAccount: { code: 'CUSTOMER_ACCOUNT', name: 'Customer Account' },
  project: { code: 'PROJECT', name: 'Project' },
  offerVersion: { code: 'OFFER_VERSION', name: 'Offer Version' },
  absenceSubmitted: { code: 'ABSENCE_SUBMITTED', name: 'Absence Submitted' },
  absenceCancelledPending: {
    code: 'ABSENCE_CANCELLED_PENDING',
    name: 'Absence Cancelled Pending',
  },
  absenceRetreated: { code: 'ABSENCE_RETREATED', name: 'Absence Retreated' },
  absenceApproved: { code: 'ABSENCE_APPROVED', name: 'Absence Approved' },
  absenceDeclined: { code: 'ABSENCE_DECLINED', name: 'Absence Declined' },
  employee: { code: 'EMPLOYEE', name: 'Employee' },
  contact: { code: 'CONTACT', name: 'Contact' },
  projectTimeSheet: { code: 'PROJECT_TIMESHEET', name: 'Project Timesheet' },
  responsible: { code: 'RESPONSIBLE', name: 'Responsible' },
  skillSet: { code: 'SKILL_SET', name: 'Skill Set' },
  projectOption: { code: 'PROJECT_OPTION', name: 'Project Option' },
  department: { code: 'DEPARTMENT', name: 'Department' },
  fieldOfBusiness: { code: 'FIELD_OF_BUSINESS', name: 'Field of Business' },
  none: { code: 'NONE', name: 'None' },
  projectEmployeeWorkload: {
    code: 'PROJECT_EMPLOYEE_WORKLOAD',
    name: 'Project Employee Workload',
  },
};

export const ROLE_ENUM = {
  role: {
    code: 'ROLE',
    name: 'role',
  },
  roleGroup: {
    code: 'ROLEGROUP',
    name: 'roleGroup',
  },
};

export const COLLG = {
  half: 6,
  full: 12,
  eight: 8,
};
