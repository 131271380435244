import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IRestrictedCustomer } from '../utils/types/modelTypes';
import { ICustomerState } from '../utils/types/stateTypes';

const initialState: ICustomerState = {
  storedCustomers: [],
  customerId: 0,
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    updateCustomer: (
      state,
      { payload }: PayloadAction<IRestrictedCustomer>
    ) => ({
      ...state,
      storedCustomers: state.storedCustomers.map((customer) =>
        customer.id === payload.id ? { ...customer, ...payload } : customer
      ),
    }),
    loadCustomerId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      customerId: payload,
    }),
  },
});

export const { updateCustomer, loadCustomerId } = customersSlice.actions;

export default customersSlice.reducer;
