import moment from 'moment';
import React from 'react';

import DetailsCard from '../../../components/cards/DetailsCard';
import i18n from '../../../i18n';
import { IProjectOptionDetails } from '../../../utils/types/responseTypes';
import { dateFormat, t } from './projectOptionHelpers';

interface IProps {
  project: IProjectOptionDetails;
}

/**
 * History Card in Project Option Modal -
 * Displays the history of project activities
 */
const ProjectOptionHistory = ({ project: { activities = [] } }: IProps) => {
  const fields = activities.map(({ action, dueDate }) => ({
    label: moment(dueDate).format(dateFormat),
    value: i18n.t(`ProjectActivities.${action}`),
  }));

  return (
    <DetailsCard
      title={t('history')}
      fields={fields}
      noDataPlaceholder={t('noHistory')}
      border
    />
  );
};

export default ProjectOptionHistory;
