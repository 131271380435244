import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IEmployee } from '../utils/types/modelTypes';
import { IEmployeeState } from '../utils/types/stateTypes';

const initialState: IEmployeeState = {
  employees: [],
};

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    loadEmployees: (state, { payload }: PayloadAction<IEmployee[]>) => ({
      ...state,
      employees: payload,
    }),
  },
});

export const { loadEmployees } = employeesSlice.actions;

export default employeesSlice.reducer;
