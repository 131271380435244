import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import i18n from '../../../i18n';
import FadeAlert from '../../../components/layout/FadeAlert';
import ContactPersonView from './ContactPersonView';
import ContractTypeListView from './ContractTypeListView';
import LocationListView from './LocationListView';

/**
 * This class is for creating the Employee Settings Page
 * which allows you to navigate on various of settings in the employee entity
 */
export default class EmployeeSettingsView extends React.Component {
  t(keyName) {
    return i18n.t('EmployeeSettingsView.' + keyName);
  }

  render() {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{this.t('employeeSettings')}</HeaderTitle>
          {generateBreadcrumb(this.props.location.pathname, this.t('settings'))}
        </Header>

        <Card>
          <CardHeader>
            {this.props.location.state?.successMessage && (
              <FadeAlert color="success">
                {this.props.location.state.successMessage}
              </FadeAlert>
            )}
            <CardTitle>
              <h1>{this.t('employeeSettings')}</h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Button
                className="gap-1"
                color="outline"
                id="employeeContractToggler"
              >
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('contractTypes')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#employeeContractToggler">
              <ContractTypeListView />
            </UncontrolledCollapse>
            <br />
            <Row>
              <Button
                className="gap-1"
                color="outline"
                id="employeeLocationToggler"
              >
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('location')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#employeeLocationToggler">
              <LocationListView t={this.t} />
            </UncontrolledCollapse>
            <br />

            <Row>
              <Button
                className="gap-1"
                color="outline"
                id="contactPersonToggle"
              >
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('contactPerson')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#contactPersonToggle">
              <ContactPersonView />
            </UncontrolledCollapse>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
