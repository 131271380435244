import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import Auth from '../../../services/axios/Auth';
import { READWRITE } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { generateTitle } from '../../../utils/helpers/icon';
import FadeAlert from '../../../components/layout/FadeAlert';
import CustomerAccountDetailsTable from './CustomerAccountDetailsTable';
import ExistingCustomerSelection from './ExistingCustomerSelection';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { ICustomerAccountDetail } from '../../../utils/types/responseTypes';
import { getCustomerAccountDetails } from '../../../services/api/customer';
import ModalAccessAddDialog from '../../../components/form/AddAccessForm/ModalAccessAddDialog';
import { translateCustomerAccountDetails } from './customerAccountDetailsHelper';
import AddCustomerModal from '../../../components/form/AddCustomerModal';
import UpdateCustomerAccountForm from './UpdateCustomerAccountForm';

interface IProps
  extends IWithModalsProps,
    RouteComponentProps<{ customerAccountId: string }> {}

/**
 * Card used in CustomerAccount Details page that show details about the customerAccount
 */
const CustomerAccountDetailsCard: React.FC<IProps> = ({
  match,
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
}: IProps) => {
  const [customerAccountDetail, setCustomerAccountDetail] =
    useState<ICustomerAccountDetail>({} as ICustomerAccountDetail);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [toggleCardUpdate, setToggleCardUpdate] = useState<boolean>(false);

  const hasUpdatePermission = Auth.hasPermission(
    [PERMISSION_URI.customerAccount.readWrite.uri],
    [READWRITE],
    match.params.customerAccountId
  );
  const hasCreateCustomerPermission = Auth.hasPermission(
    [PERMISSION_URI.customerList.readWrite.uri],
    [READWRITE]
  );

  const fetchCustomerAccountDetails = () => {
    // Get the customerAccount details
    getCustomerAccountDetails(match.params.customerAccountId)
      .then((res) => {
        setCustomerAccountDetail(res.data);
      })
      .catch((error) => {
        modalErrorHandler(
          translateCustomerAccountDetails(
            'failedToRetrieveCustomerAccountDetails'
          ),
          error
        );
      });
  };

  // Handles changes when customer is updated
  const handleUpdateCustomerAccount = (
    customerAccount: ICustomerAccountDetail
  ) => {
    setCustomerAccountDetail(customerAccount);
    setSuccessMessage(translateCustomerAccountDetails('customerUpdated'));

    toggleModalForm();
    setToggleCardUpdate(!toggleCardUpdate);
  };

  const onAddingCustomer = () => {
    fetchCustomerAccountDetails();

    setToggleCardUpdate(!toggleCardUpdate);
    toggleModalForm();
  };

  const handleAddFromExistingCustomer = () => {
    modalFormHandler(
      generateTitle(
        BUTTON_TITLE_ENUM.ADD.code,
        translateCustomerAccountDetails('addExistingCustomer')
      ),
      <ExistingCustomerSelection
        customerAccountDetail={customerAccountDetail}
        onConfirm={onAddingCustomer}
        onCancel={toggleModalForm}
      />,
      'xl'
    );
  };

  const handleAddCustomer = () => {
    modalFormHandler(
      'Create Customer for Account',
      <AddCustomerModal
        existingCustomerAccount={{
          id: customerAccountDetail.id,
          name: customerAccountDetail.name,
        }}
        onSave={onAddingCustomer}
        onCancel={toggleModalForm}
      />,
      'xl'
    );
  };

  const showPermissionModal = () => {
    modalFormHandler(
      translateCustomerAccountDetails('grantPermission'),
      <ModalAccessAddDialog
        restriction={parseInt(match.params.customerAccountId, 10)}
        entityType="DATA"
      />,
      'xl'
    );
  };

  const updateCustomerAccount = () => {
    modalFormHandler(
      translateCustomerAccountDetails('updateAccount'),
      <UpdateCustomerAccountForm
        customerAccountDetail={customerAccountDetail}
        onSave={handleUpdateCustomerAccount}
        onCancel={toggleModalForm}
      />
    );
  };

  useEffect(() => {
    fetchCustomerAccountDetails();
  }, []);

  return (
    <Card>
      <CardHeader>
        {successMessage && (
          <FadeAlert color="success">{successMessage}</FadeAlert>
        )}
        <div className="card-actions float-end flex-button">
          {hasCreateCustomerPermission && (
            <Button
              color="primary"
              size="s"
              onClick={() => handleAddCustomer()}
            >
              {translateCustomerAccountDetails('createCustomer')}
            </Button>
          )}
          {hasUpdatePermission && (
            <>
              <Button
                color="primary"
                size="s"
                onClick={() => handleAddFromExistingCustomer()}
              >
                {translateCustomerAccountDetails(
                  'addExistingCustomerToAccount'
                )}
              </Button>
              <Button
                color="primary"
                size="s"
                onClick={() => updateCustomerAccount()}
              >
                {generateTitle(
                  BUTTON_TITLE_ENUM.UPDATE.code,
                  translateCustomerAccountDetails('update')
                )}
              </Button>
              <Button
                color="primary"
                size="s"
                onClick={() => showPermissionModal()}
              >
                {translateCustomerAccountDetails('grantPermission')}
              </Button>
            </>
          )}
        </div>
        <CardTitle>
          <h1>{customerAccountDetail.name}</h1>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <CustomerAccountDetailsTable
          customerAccountDetail={customerAccountDetail}
        />
      </CardBody>
    </Card>
  );
};

export default withRouter(withModals(CustomerAccountDetailsCard));
