import React, { useContext } from 'react';

import { notApplicableAbbreviation } from '../../../utils/constants';
import { OfferDetailsContext } from './OfferDetailsProvider';
import DetailsCard from '../../../components/cards/DetailsCard';
import { translate } from './offerHelper';

const OfferProjectDetailsCard = () => {
  const { project } = useContext(OfferDetailsContext);

  const { projectTitle, customer, customerSite, contactPerson } = project;

  const detailList = [
    {
      label: translate('projectName'),
      value: projectTitle,
    },
    {
      label: translate('customerName'),
      value: customer.name,
    },
    {
      label: translate('customerSiteName'),
      value: customerSite.name,
    },
    {
      label: translate('customerAbbreviation'),
      value: customer.abbreviation,
    },
    {
      label: translate('preliminaryCAForOffer'),
      value: notApplicableAbbreviation,
    },
  ];

  const contactPersonList = contactPerson.map((contactPerson) => ({
    title: '',
    fields: [
      {
        label: translate('contactPersonName'),
        value: contactPerson.name,
      },
      {
        label: translate('contactPersonRole'),
        value: contactPerson.role ?? 'N/A',
      },
      {
        label: translate('contactPersonFunction'),
        value: contactPerson.functionRole ?? 'N/A',
      },
    ],
  }));

  return (
    <DetailsCard
      title={translate('projectDetailsTitle')}
      fields={detailList}
      additionalSections={contactPersonList}
      border
    />
  );
};

export default OfferProjectDetailsCard;
