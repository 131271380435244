import { getEmployeeListItems } from '../services/api/employee';
import { SortBy, SortType } from '../utils/enums/sort';
import { maxPageLength } from '../utils/constants';
import { removeDuplicates } from '../utils/helpers/table';
import { createQueryParameters } from '../pages/employees/EmployeeList/employeeListHelpers';
import { IEmployeeListItem } from '../utils/types/responseTypes';
import { IEmployeeListFilters } from '../utils/types/stateTypes';
import createListSlice, { ListState } from './createListSlice';
import type { AppThunk } from './store';

type EmployeeListState = ListState<
  IEmployeeListItem,
  IEmployeeListFilters,
  Record<string, never>
>;

const initialState: EmployeeListState = {
  listItems: [],
  hasMore: true,
  page: 0,
  pageSize: 0,
  pageCount: 0,
  filters: {
    firstname: '',
    lastname: '',
    department: '',
    responsibleName: '',
    location: '',
    careerLevel: '',
  } as IEmployeeListFilters,
  showFilters: false,
  sortBy: SortBy.ID,
  sortType: SortType.DESCENDING,
};

/**
 * Employee List Slice - contains the state of the Employee List page
 * 1.) List of Employees
 * 2.) Page of items to be fetched and if there is more data to fetch
 * 3.) Applied filters
 * 4.) Applied sorting criteria
 */
const employeeListSlice = createListSlice({
  name: 'employeeList',
  initialState,
  reducers: {},
});

export const {
  setListItems,
  setHasMoreListItems,
  setPage,
  setFilters,
  toggleFilter,
  setSortBy,
  setSortType,
} = employeeListSlice.actions;

/**
 * Thunk for fetching employee list items and updating employee list slice
 * @param isSortOrFilterFetchType Whether fetching due to sort / filter / scroll update
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching employee list items
 */
export const fetchEmployeeListItems =
  (
    isSortOrFilterFetchType: boolean,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        employeeList: { page, filters, sortBy, sortType, listItems },
      } = getState();

      const { data: fetchedListItems } = await getEmployeeListItems(
        createQueryParameters(page, filters, sortBy, sortType)
      );

      const processedListItems = isSortOrFilterFetchType
        ? [...fetchedListItems]
        : removeDuplicates(listItems, fetchedListItems);

      dispatch(
        setPage(!(fetchedListItems.length < maxPageLength) ? page + 1 : page)
      );
      dispatch(setHasMoreListItems(!(fetchedListItems.length < maxPageLength)));
      dispatch(setListItems(processedListItems));
    } catch (error) {
      errorHandler(error);
    }
  };

export default employeeListSlice.reducer;
