import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Container } from 'reactstrap';
import { ConnectedProps, connect } from 'react-redux';

import DynamicTable from '../../../components/tables/DynamicTable';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { sortByDate } from '../../../utils/helpers/GenericHelper';
import FadeAlert from '../../../components/layout/FadeAlert';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  createContactStatisticsEntry,
  translateContactStatistics,
} from './contactStatisticsHelper';
import { RootState } from '../../../redux/store';
import { fetchContactStatisticsListItems } from '../../../redux/contactStatisticsListSlice';
import { IContactPersonStatisticsListItem } from '../../../utils/types/responseTypes';

interface IProps
  extends PropsFromRedux,
    IWithModalsProps,
    RouteComponentProps<
      Record<string, never>,
      Record<string, never>,
      { successMessage: string }
    > {}

interface ContactEntry {
  name: string;
  date: string;
  time: string;
  day: string | undefined;
  called: JSX.Element;
}

/*
 * Class that show all the available contact persons assigned to the user
 */
const ContactStatistics = ({
  fetchContactStatisticsListItems,
  listItems,
  modalErrorHandler,
  location,
}: IProps) => {
  const preparedContactStatistics: ContactEntry[] = listItems.map(
    (item: IContactPersonStatisticsListItem) =>
      createContactStatisticsEntry(item)
  );

  useEffect(() => {
    fetchContactStatisticsListItems((error) => {
      modalErrorHandler(
        translateContactStatistics('failedToRetrieveContactPersonStatistics'),
        error
      );
    });
  }, []);

  // Column Settings for the Dynamic Table
  const preparedColumns = [
    {
      type: 'data',
      header: 'Name',
      accessor: 'name',
      show: 'true',
      filterkey: 'name',
      showsearch: 'true',
    },
    {
      type: 'data',
      header: 'Date',
      accessor: 'date',
      show: 'true',
      filterkey: 'date',
      showsearch: 'true',
    },
    {
      type: 'data',
      header: 'Day',
      accessor: 'day',
      show: 'true',
      filterkey: 'day',
      showsearch: 'true',
    },
    {
      type: 'data',
      header: 'Time',
      accessor: 'time',
      show: 'true',
      filterkey: 'time',
      showsearch: 'true',
    },
    {
      type: 'data',
      header: 'Called',
      accessor: 'called',
      show: 'true',
      filterkey: 'called',
      showsearch: 'true',
    },
  ];

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          {translateContactStatistics('contactStatistics')}
        </HeaderTitle>
        {generateBreadcrumb(
          location.pathname,
          translateContactStatistics('customer')
        )}
      </Header>
      <Card>
        <CardHeader>
          {location.state && (
            <FadeAlert color="success">
              {location.state.successMessage}
            </FadeAlert>
          )}
          <CardTitle className="mb-0">
            <h1>{translateContactStatistics('contactStatistics')}</h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <DynamicTable
            data={sortByDate(preparedContactStatistics)}
            columns={preparedColumns}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  listItems: store.contactStatisticsList.listItems,
});

const mapDispatchToProps = {
  fetchContactStatisticsListItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withModals(withRouter(connector(ContactStatistics)));
