import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { dataGeneration } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { errorHandler, sortByPropValue } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import ContractTypeInputForm from './ContractTypeInputForm';

/**
 * Contract Type List View
 *
 * Displays contract type available in employee settings.
 * Also allows user to delete an existing contract type
 */

class ContractTypeListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractTypes: [],
      showModal: false,

      showInputForm: false,
      modalForm: null,
      modalTitle: '',
    };
    this.modalEvent = null;

    this.showInputModal = this.showInputModal.bind(this);
    this.toggleInputModal = this.toggleInputModal.bind(this);
  }

  t(keyName) {
    return i18n.t('ContractTypeListView.' + keyName);
  }

  componentDidMount() {
    axios.employee
      .get('contract-types')
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({ contractTypes: response.data });
        }
      })
      .catch((error) => {
        this.message = errorHandler(
          dataGeneration,
          error,
          this.t('contractType')
        );
        this.toggleModalError();
      });
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  toggleModal = (e) => {
    this.setState({ showModal: !this.state.showModal });
  };

  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  toggleInputModal() {
    this.setState({ showInputForm: !this.state.showInputForm });
  }

  showInputModal = () => {
    this.setState({
      modalForm: <ContractTypeInputForm onSave={this.onSaveType} />,
      modalTitle: this.t('addContractType'),
    });
    this.toggleInputModal();
  };

  onSaveType = () => {
    this.toggleInputModal();
    this.componentDidMount();
  };

  deleteType = (contract) => {
    this.toggleModal();
    this.modalEvent = () =>
      axios.employee.delete(`contract-types/${contract.id}`).then(() => {
        let newContractTypes = this.state.contractTypes.filter((value) => {
          return value.id !== contract.id;
        });
        this.setState({ contractTypes: newContractTypes });
      });
  };
  preparedTableData = (employeeData) => {
    const newTableData = [];
    if (Array.isArray(employeeData) && employeeData.length > 0) {
      employeeData.forEach((employee) => {
        const entry = {
          id: employee?.id,
          type: employee?.type,
          deleteButton: (
            <>
              <Button color="primary" onClick={() => this.deleteType(employee)}>
                {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
              </Button>
            </>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  render() {
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('type'),
        accessor: 'type',
        show: 'true',
        link: '/settings/employee/contract-type-update/',
        linkAccessor: 'id',
      },
      {
        type: 'data',
        header: this.t('delete'),
        accessor: 'deleteButton',
        show: 'true',
        alignRight: 'true',
      },
    ];
    return (
      <Container fluid>
        <div>
          <Button
            color="primary"
            className="float-end"
            onClick={() => this.showInputModal()}
          >
            {generateTitle(BUTTON_TITLE_ENUM.ADD.code, this.t('add'))}
          </Button>
          <br />
          <br />
        </div>

        <DynamicTable
          data={this.preparedTableData(
            sortByPropValue(this.state.contractTypes, 'type')
          )}
          columns={preparedColumns}
        />

        <ModalDelete
          isOpen={this.state.showModal}
          event={this.modalEvent}
          onClose={this.toggleModal}
          modalTitle={this.t('deleteContractType')}
          modalBodyText={this.t('deleteContractTypeConfirm')}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.message?.mainError}
          errorReason={this.message?.errorReason}
          errorResponse={this.message?.errorResponse}
          modalTitle={this.t('error')}
        />
        <ModalForm
          isOpen={this.state.showInputForm}
          eventOnClose={this.toggleInputModal}
          ref={this.state.modalForm}
          modalTitle={this.state.modalTitle}
        >
          {this.state.modalForm}
        </ModalForm>
      </Container>
    );
  }
}

export default withRouter(ContractTypeListView);
