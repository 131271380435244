import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card } from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';

import {
  IApprovalRequestListItem,
  IProjectDetail,
} from '../../../utils/types/responseTypes';
import {
  alt,
  isInputValid,
  templateApprovalRequest,
  templateProjectDetail,
} from './approvalListHelpers';
import { getProjectDetails } from '../../../services/api/project';
import { objectTypeEnum } from '../../../utils/enums/enum';
import { Approval } from '../../../utils/enums/approval';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  monthDateYearFormat,
  updateRecord,
} from '../../../utils/constants';
import { updateApprovalListItems } from '../../../services/api/approvalRequest';
import DetailsCard, { CardData } from '../../../components/cards/DetailsCard';
import { createLinkIfAuthorised } from '../../../utils/helpers/permission';
import { EntityType } from '../../../utils/enums/pageComponents';
import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';
import { IDropdownOption } from '../../../utils/types/commonTypes';

interface IProps extends IWithModalsProps {
  projectId: number;
  approvalId: number;
  onUpdate: () => void;
}

/**
 * Modal for the approval of projects.
 */
const ApprovalProjectModal = ({
  projectId,
  approvalId,
  onUpdate,
  modalErrorHandler,
}: IProps) => {
  const [project, setProject] = useState<IProjectDetail>(templateProjectDetail);

  const [durationType, setDurationType] = useState<IDropdownOption | null>(
    null
  );
  const [approvalRequest, setApprovalRequest] =
    useState<IApprovalRequestListItem>(templateApprovalRequest);
  const { id: approvalIdTemplate } = approvalRequest;

  const handleDurationTypeChange = (durationType: IDropdownOption) => {
    setDurationType(durationType);
  };

  const {
    id: projectIdObject,
    title: projectTitle,
    end: endDate,
    start: startDate,
    customer: { id: customerId, name: customerName },
    customerSite: { id: customerSiteId, name: customerSiteName },
    departments: projectDepartments,
    responsibleContactPersons: projectResponsibleContactPersons,
    description: projectDescription,
    projectSharePoint,
    state: projectState,
    responsible: { id: responsibleId, name: responsibleName },
    involvedResponsibles: projectInvolved,
  } = project;

  const durationEnum = enumValuesToDropdownOptions(
    Object.values({
      DAYS: `${moment(endDate).diff(moment(startDate), 'days')} ${alt('days')}`,
      WEEKS: `${moment(endDate).diff(moment(startDate), 'weeks')} ${alt(
        'weeks'
      )}`,
      MONTHS: `${moment(endDate).diff(moment(startDate), 'months')} ${alt(
        'months'
      )}`,
    })
  );

  // Add the general fields
  const generalFields = [
    {
      label: alt('projectName'),
      value:
        projectIdObject !== 0 &&
        createLinkIfAuthorised(
          EntityType.PROJECT,
          projectTitle,
          projectIdObject
        ),
      isRequired: projectTitle === '',
    },
    {
      label: alt('customer'),
      value:
        customerId !== 0 &&
        createLinkIfAuthorised(EntityType.CUSTOMER, customerName, customerId),
      isRequired: customerId === 0,
    },
    {
      label: alt('customerSite'),
      value:
        customerSiteId !== 0 &&
        createLinkIfAuthorised(
          EntityType.CUSTOMER_SITE,
          customerSiteName,
          customerSiteId
        ),
      isRequired: customerSiteId === 0,
    },
    {
      label: alt('department'),
      value: projectDepartments.map(({ id, name }) => (
        <div key={id}>{name}</div>
      )),
      isRequired: false,
    },
    {
      label: alt('contactInformation'),
      value: projectResponsibleContactPersons.map(({ id, name }) => (
        <div key={id}>
          {createLinkIfAuthorised(EntityType.CONTACT_PERSON, name, id)}
        </div>
      )),
      isRequired: false,
    },
  ] as CardData[];

  // Add the description fields
  const descriptionFields = [
    {
      label: alt('description'),
      value: projectDescription,
      isRequired: false,
    },
    {
      label: alt('sharepoint'),
      value: projectSharePoint,
      isRequired: false,
    },
  ] as CardData[];

  // Add the budget and schedule fields
  const budgetAndScheduleFields = [
    {
      label: alt('dateOfProjectStart'),
      value: moment(startDate).format(monthDateYearFormat),
      isRequired: false,
    },
    {
      label: alt('totalProjectDuration'),
      value: startDate && endDate && (
        <div>
          <Select
            options={durationEnum}
            value={durationType}
            placeholder={alt('seeDurationDropdown')}
            onChange={handleDurationTypeChange}
          />
        </div>
      ),
      isRequired: false,
    },
    {
      label: alt('projectStatus'),
      value: projectState,
      isRequired: false,
    },
  ] as CardData[];

  // Add the responsible fields
  const responsibleFields = [
    {
      label: alt('invResponsible'),
      value:
        responsibleId !== 0 &&
        createLinkIfAuthorised(
          EntityType.EMPLOYEE,
          responsibleName,
          responsibleId
        ),
      isRequired: responsibleId === 0,
    },
    {
      label: alt('involved'),
      value: projectInvolved.map(({ id, name }) => (
        <div key={id}>
          {createLinkIfAuthorised(EntityType.EMPLOYEE, name, id)}
        </div>
      )),
      isRequired: false,
    },
  ] as CardData[];

  /**
   * Processes the request into a patch map
   */
  const processRequest = (approvalRequestStatus: IApprovalRequestListItem) => {
    updateApprovalListItems({
      ...approvalRequestStatus,
      responseDate: moment(new Date()).toISOString(),
      objectType: objectTypeEnum.project.code,
    })
      .then(() => onUpdate())
      .catch((error) => {
        modalErrorHandler(updateRecord, error);
      });
  };

  /**
   * Handles descision of project
   */
  const handleProjectDescision = (approvalStatus: string) => {
    if (approvalIdTemplate === 0) {
      onUpdate();
      return;
    }
    processRequest({
      ...approvalRequest,
      approvalStatus,
    });
  };

  useEffect(() => {
    getProjectDetails(projectId)
      .then(({ data }) => setProject(data))
      .catch((error) => {
        modalErrorHandler(updateRecord, error);
      });
    setApprovalRequest({
      ...templateApprovalRequest,
      id: approvalId,
    });
  }, []);

  return (
    <Container fluid>
      <Row className="justify-content-center">
        {!isInputValid(project) && (
          <span className="marker-color">* {alt('lackingAsterisk')}</span>
        )}
      </Row>
      <Row>
        <Col>
          <Row>
            <Card>
              <DetailsCard
                title={alt('general')}
                fields={generalFields}
                border
              />
            </Card>
          </Row>
          <Row>
            <Card>
              <DetailsCard
                title={alt('description')}
                fields={descriptionFields}
                border
              />
            </Card>
          </Row>
        </Col>
        <Col>
          <Row>
            <Card>
              <DetailsCard
                title={alt('budgetAndSchedule')}
                fields={budgetAndScheduleFields}
                border
              />
            </Card>
          </Row>
          <Row>
            <Card>
              <DetailsCard
                title={alt('responsible')}
                fields={responsibleFields}
                border
              />
            </Card>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <div className="centered-div">
          <Button
            color="primary"
            className="continue-and-cancel-button"
            onClick={() => handleProjectDescision(Approval.APPROVED)}
            disabled={!isInputValid(project)}
          >
            {isInputValid(project) ? alt('approve') : alt('lacking')}
          </Button>

          <Button
            color="primary"
            className="continue-and-cancel-button"
            onClick={() => handleProjectDescision(Approval.REJECTED)}
          >
            {alt('refuse')}
          </Button>
        </div>
      </Row>
    </Container>
  );
};

export default withModals(ApprovalProjectModal);
