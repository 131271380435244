export const CONFIRM_OR_DISQUALIFY = {
  disqualify: {
    code: 'disqualify',
    label: 'Disqualify',
  },
  keepNextActivity: {
    code: 'keepNextActivity',
    label: 'Keep Next Activity',
  },
};

export const TO_DO_ACTIVITY_STATE_ENUM = {
  ACTIVE: 'ACTIVE',
  REMOVED: 'REMOVED',
  ACCOMPLISHED: 'ACCOMPLISHED',
  DELEGATED: 'DELEGATED',
};

export const NEXT_ACTION_ENUMS = {
  callContact: 'Call Contact',
  doProspect: 'Do Prospect',
  backlog: 'Backlog',
  none: 'None',
  searchContact: 'Search Contact',
  special: 'Special',
  doFollowUp: 'Do Follow-Up Meeting',
  sendEmail: 'Send Email',
  createOffer: 'Create Offer',
  sendOffer: 'Send Offer',
};

/**
 * @deprecated use NextAction
 */
export const NEXT_ACTIVITY_ENUMS = {
  callContact: 'CALL_CONTACT',
  doProspect: 'DO_PROSPECT',
  backlog: 'BACKLOG',
  none: 'NONE',
  searchContact: 'SEARCH_CONTACT',
  special: 'SPECIAL',
  doFollowUp: 'DO_FOLLOW_UP_MEETING',
  sendEmail: 'SEND_EMAIL',
  createOffer: 'CREATE_OFFER',
  sendOffer: 'SEND_OFFER',
};

export const ACTIVITY_STATE_ENUM = {
  dueDate: 'dueDate',
  recall: 'recall',
  recallTime: 'recallTime',
  meeting: 'meeting',
  meetingTime: 'meetingTime',
  nextAction: 'nextAction',
  target: 'target',
  newStatus: 'newStatus',
  acquisition: 'acquisition',
};

export const ACTIVITY_DESCRIPTIONS = {
  changedNextActivity: 'Changed Next Planned Activity from',
  mailSent: 'Mail Sent.',
  contactedViaSocial: 'Contacted via Social Media.',
  metPersonally: 'Met Personally.',
  prospectionDone: 'Prosepction done.',
  prospectionCancelled: 'Prospection cancelled.',
  prospectionPostponed: 'Prospection postponed.',
  followUpDone: 'Follow up done.',
  followUpCancelled: 'Follow up cancelled.',
  followUpPostponed: 'Follow up postponed.',
  disqualified: 'Disqualified.',
  projectOptionOutlined: 'Project Option outlined.',
  calledBeforeProspection: 'Called before prospection.',
  called: 'Called.',
  missedCall: 'Misssed Call.',
  prospection: 'Prospection',
  followUpMeeting: 'Follow up meeting',
};

export const ACTIVITY_COMMENTS = {
  relevant: 'relevant',
  irrelevant: 'irrelevant',
};

export enum ActivityType {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  CUSTOM_ACTIVITY = 'CUSTOM_ACTIVITY',
  REMINDER = 'REMINDER',
}

export enum NextAction {
  CALL_CONTACT = 'CALL_CONTACT',
  DO_PROSPECT = 'DO_PROSPECT',
  BACKLOG = 'BACKLOG',
  NONE = 'NONE',
  SEARCH_CONTACT = 'SEARCH_CONTACT',
  SPECIAL = 'SPECIAL',
  DO_FOLLOW_UP_MEETING = 'DO_FOLLOW_UP_MEETING',
  SEND_EMAIL = 'SEND_EMAIL',
  CREATE_OFFER = 'CREATE_OFFER',
  SEND_OFFER = 'SEND_OFFER',
}
