import { trackPromise } from 'react-promise-tracker';
import axios from '../axios/Axios-Interceptors';
import { maxInFilterSize } from '../../utils/constants';
import { isEmpty } from '../../utils/helpers/array';

const BaseUrl = '/';

const timetrackingApiUrl = 'api/';
const salesApiUrl = 'api/';
const projectApiUrl = 'api/';
const accountApiUrl = 'api/account';
const serviceConfigurationApiUrl = 'api/service-configurations';
const employeeApiUrl = 'api/';
const absenceApiUrl = 'api/';
const roleApiUrl = 'api/authorities/';
const userApiUrl = 'api/users';
const notificationApiUrl = 'api/';
const downloadApiUri = 'api/';

const axiosMethods = {
  authentication: {
    get(url) {
      return trackPromise(axios.get(BaseUrl + 'api/' + url));
    },
  },
  timetracking: {
    get(url) {
      return trackPromise(axios.get(BaseUrl + timetrackingApiUrl + url));
    },
    post(url, data) {
      return trackPromise(axios.post(BaseUrl + timetrackingApiUrl + url, data));
    },
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + timetrackingApiUrl + url, data));
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + timetrackingApiUrl + url));
    },
  },

  project: {
    get(url) {
      return trackPromise(axios.get(BaseUrl + projectApiUrl + url));
    },
    post(url, data) {
      return trackPromise(axios.post(BaseUrl + projectApiUrl + url, data));
    },
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + projectApiUrl + url, data));
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + projectApiUrl + url));
    },
    save(url, data) {
      if (data.id) {
        return trackPromise(axios.put(BaseUrl + projectApiUrl + url, data));
      } else {
        return trackPromise(axios.post(BaseUrl + projectApiUrl + url, data));
      }
    },
  },

  employee: {
    get(url) {
      return trackPromise(axios.get(BaseUrl + employeeApiUrl + url));
    },
    post(url, payload) {
      return trackPromise(axios.post(BaseUrl + employeeApiUrl + url, payload));
    },
    put(url, payload) {
      return trackPromise(axios.put(BaseUrl + employeeApiUrl + url, payload));
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + employeeApiUrl + url));
    },
    save(url, data) {
      if (data.id) {
        return trackPromise(axios.put(BaseUrl + projectApiUrl + url, data));
      } else {
        return trackPromise(axios.post(BaseUrl + projectApiUrl + url, data));
      }
    },
  },
  account: {
    get() {
      return trackPromise(axios.get(BaseUrl + accountApiUrl));
    },
  },

  serviceConfiguration: {
    get(url) {
      return trackPromise(
        axios.get(BaseUrl + serviceConfigurationApiUrl + url)
      );
    },
    post(url, data) {
      return trackPromise(
        axios.post(BaseUrl + serviceConfigurationApiUrl + url, data)
      );
    },
    put(url, data) {
      return trackPromise(
        axios.put(BaseUrl + serviceConfigurationApiUrl + url, data)
      );
    },
    delete(url, data) {
      return trackPromise(
        axios({
          method: 'delete',
          url: BaseUrl + serviceConfigurationApiUrl + url,
          data: data,
        })
      );
    },
    all(urlarray) {
      return trackPromise(axios.all(urlarray));
    },
  },

  // add get,post,put,delete here for sales app
  sales: {
    get(url, data) {
      return trackPromise(
        axios({
          method: 'get',
          url: BaseUrl + salesApiUrl + url,
          data: data,
        })
      );
    },
    post(url, data) {
      return trackPromise(axios.post(BaseUrl + salesApiUrl + url, data));
    },
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + salesApiUrl + url, data));
    },
    save(url, data) {
      if (data.id) {
        return trackPromise(axios.put(BaseUrl + salesApiUrl + url, data));
      } else {
        return trackPromise(axios.post(BaseUrl + salesApiUrl + url, data));
      }
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + salesApiUrl + url));
    },
    all(urlarray) {
      return trackPromise(axios.all(urlarray));
    },
    getOrCreateContactPersonRoleName(roleName) {
      return trackPromise(
        axios.get(
          BaseUrl + salesApiUrl + 'contact-person-role-by-role/' + roleName
        )
      );
    },
    getOrCreateTitle(title) {
      return trackPromise(
        axios.get(BaseUrl + salesApiUrl + 'title-by-title/' + title)
      );
    },
    getAllContactPeopleForRequests() {
      return trackPromise(
        axios.get(
          BaseUrl +
            salesApiUrl +
            'responsible-contact-people?objectType.equals=REQUEST'
        )
      );
    },
    getAllNotesForObjectType(ObjectType) {
      return trackPromise(
        axios.get(
          BaseUrl + salesApiUrl + `activities?objectType.equals=${ObjectType}`
        )
      );
    },
    getResponsiblesByObjectIdsAndType(objectIds, objectType) {
      if (!isEmpty(objectIds) && objectIds.length <= maxInFilterSize) {
        return trackPromise(
          axios.get(
            BaseUrl +
              salesApiUrl +
              `responsibles?objectId.in=${objectIds}&objectType.equals=${objectType}`
          )
        );
      } else {
        return null;
      }
    },
    getContactInformationByContactIds(contactPersonIds) {
      if (
        !isEmpty(contactPersonIds) &&
        contactPersonIds.length <= maxInFilterSize
      ) {
        return trackPromise(
          axios.get(
            BaseUrl +
              salesApiUrl +
              `contact-informations?contactPersonId.in=${contactPersonIds}`
          )
        );
      } else {
        return null;
      }
    },
  },

  role: {
    put(payload) {
      return trackPromise(axios.put(BaseUrl + roleApiUrl, payload));
    },
    get() {
      return trackPromise(axios.get(BaseUrl + roleApiUrl));
    },
    delete(payload) {
      return trackPromise(axios.delete(BaseUrl + roleApiUrl + payload));
    },
  },

  user: {
    get() {
      return trackPromise(axios.get(BaseUrl + userApiUrl));
    },
    put(payload) {
      return trackPromise(axios.put(BaseUrl + userApiUrl, payload));
    },
  },

  notification: {
    get(url) {
      return trackPromise(axios.get(BaseUrl + notificationApiUrl + url));
    },
    post(url, data) {
      return trackPromise(axios.post(BaseUrl + notificationApiUrl + url, data));
    },
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + notificationApiUrl + url, data));
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + notificationApiUrl + url));
    },
  },

  // add get,post,put,delete here for absence app
  absence: {
    get(url) {
      return trackPromise(axios.get(BaseUrl + absenceApiUrl + url));
    },
    post(url, data) {
      return trackPromise(axios.post(BaseUrl + absenceApiUrl + url, data));
    },
    put(url, data) {
      return trackPromise(axios.put(BaseUrl + absenceApiUrl + url, data));
    },
    delete(url) {
      return trackPromise(axios.delete(BaseUrl + absenceApiUrl + url));
    },
    all(urlarray) {
      return trackPromise(axios.all(urlarray));
    },
    spread() {
      return trackPromise(axios.spread);
    },
    save(url, data) {
      if (data.id) {
        return trackPromise(axios.put(BaseUrl + absenceApiUrl + url, data));
      } else {
        return trackPromise(axios.post(BaseUrl + absenceApiUrl + url, data));
      }
    },
  },

  // Used to call APIs with a file
  download: {
    get(url, fileType) {
      return trackPromise(
        axios.get(BaseUrl + downloadApiUri + url, { responseType: fileType })
      );
    },
    spread() {
      return trackPromise(axios.spread);
    },
  },
};

export default axiosMethods;
