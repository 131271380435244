import i18n from '../../i18n';

export enum CustomerState {
  ACTIVE = 'ACTIVE',
  IN_ACQUISITION = 'IN_ACQUISITION',
  FORMER = 'FORMER',
  NONE = 'NONE',
}

/**
 * @deprecated Use CustomerState enum
 */
export const CUSTOMER_STATE_ENUM = {
  active: {
    name: i18n.t('CustomerStates.ACTIVE'),
    code: 'ACTIVE',
  },
  acquisition: {
    name: i18n.t('CustomerStates.IN_ACQUISITION'),
    code: 'IN_ACQUISITION',
  },
  former: {
    name: i18n.t('CustomerStates.FORMER'),
    code: 'FORMER',
  },
  none: {
    name: i18n.t('CustomerStates.NONE'),
    code: 'NONE',
  },
};

export enum TargetPriority {
  HIGH_PRIORITY = 'HIGH_PRIORITY',
  MID_PRIORITY = 'MID_PRIORITY',
  LOW_PRIORITY = 'LOW_PRIORITY',
  NO_PRIORITY = 'NO_PRIORITY',
  TO_BE_DEFINED = 'TO_BE_DEFINED',
}

/**
 * @deprecated Use TargetPriority enum
 */
export const TARGET_PRIORITY_ENUM = {
  highPriority: {
    name: i18n.t('TargetPriority.highPriority'),
    code: 'HIGH_PRIORITY',
  },
  midPriority: {
    name: i18n.t('TargetPriority.midPriority'),
    code: 'MID_PRIORITY',
  },
  lowPriority: {
    name: i18n.t('TargetPriority.lowPriority'),
    code: 'LOW_PRIORITY',
  },
  noPriority: {
    name: i18n.t('TargetPriority.noPriority'),
    code: 'NO_PRIORITY',
  },
  toBeDefined: {
    name: i18n.t('TargetPriority.toBeDefined'),
    code: 'TO_BE_DEFINED',
  },
};
