import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import withModals, { IWithModalsProps } from '../../utils/withModals';
import i18n from '../../i18n';
import { IDropdownOption, IObjectNameAndId } from '../../utils/types/commonTypes';
import {
  dropdownOptionToObjectNameAndId,
  objectNameAndIdToDropdownOptions,
} from '../../utils/helpers/dropdown';
import { isEmpty } from '../../utils/helpers/array';
import { getCustomerAccountDropdown } from '../../services/api/customer';

/**
 * Dropdown that has all customerAccount as options
 */
interface IProps extends IWithModalsProps {
  customerAccountId: number;
  isDisabled?: boolean;
  isClearable: boolean;
  onChange: (account: IObjectNameAndId) => void;
}

const CustomerAccountDropdown = ({
  modalErrorHandler,
  customerAccountId,
  isClearable,
  isDisabled = false,
  onChange,
}: IProps) => {
  const [customerAccounts, setCustomerAccounts] = useState<
    IDropdownOption<number>[]
  >([]);

  const currentSelectedOption = customerAccounts.find(
    ({ value }) => value === customerAccountId
  );

  useEffect(() => {
    if (isEmpty(customerAccounts)) {
      getCustomerAccountDropdown()
        .then((response) => {
          setCustomerAccounts(objectNameAndIdToDropdownOptions(response.data));
        })
        .catch((error) => {
          modalErrorHandler(
            i18n.t(
              'CustomerAccountDropdown.customerAccountDataGenerationFailed'
            ),
            error
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid="customer-account-dropdown-div">
      <Select
        options={customerAccounts}
        value={
          currentSelectedOption != null
            ? {
                value: currentSelectedOption.value,
                label: currentSelectedOption.label,
              }
            : null
        }
        onChange={(selectedOption: IDropdownOption<number>) =>
          onChange(
            dropdownOptionToObjectNameAndId(
              customerAccounts.find(
                ({ value }) => value === selectedOption.value
              ) ?? {
                value: 0,
                label: '',
              }
            ) as IObjectNameAndId
          )
        }
        isClearable={isClearable}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default withModals(CustomerAccountDropdown);
