import { CategoryScale } from 'chart.js';
import { Chart as ChartJS } from 'chart.js/auto';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import Select from 'react-select';
import { Col, Input, Label, Row } from 'reactstrap';
import { ALL, NO_INDUSTRY, TIME_RANGES } from '../../../utils/constants';
import { CONTACT_STATUS } from "../../../utils/enums/contact";
import { TIME_SPAN_ENUM } from "../../../utils/enums/timeTracking";
import {
  CALL_STATISTICS_RATE_ENUM,
  CALL_STATUS_TYPE_ENUM
} from "../../../utils/enums/callStatus";
import { isEmpty, sortCallDataByType } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import TimeSpanDropdown from './TimeSpanDropdown';

/**
 * Card that contains the successful hours for calling section
 */
class SuccessfulHoursForCallingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callData: [],
      sectors: [],
      sectorIds: [],
      contactPersonSectors: [],
      contactPersonAssignments: [],

      isColdChecked: true,
      isWarmChecked: true,

      selectedTime: TIME_SPAN_ENUM.thisMonth.code,
    };
    ChartJS.register(CategoryScale);
  }

  t(keyName) {
    return i18n.t('SuccessfulHoursForCallingCard.' + keyName);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        callData: this.props.callData,
        sectors: this.props.sectors,
        sectorIds: this.props.sectorIds,
        contactPersonSectors: this.props.contactPersonSectors,
        contactPersonAssignments: this.props.contactPersonAssignments,
      });
    }
  }

  /**
   * Handles data filtering
   * @returns filtered data
   */
  handleDataFilter = () => {
    let currentDate = new moment();
    let sectorIds = this.state.sectorIds;

    let data = this.state.callData;
    let dataBasedFromAssignments = [];
    let toBeFilteredData = [];
    let filteredData = [];

    let contactsIdsFromSectorsChosen = this.state.contactPersonSectors
      .filter((item) => sectorIds?.includes(item.sector?.id))
      .map((contact) => contact?.contactPerson?.id);

    let contactIdsFromSectors = this.state.contactPersonSectors.map(
      (contact) => contact?.contactPerson?.id
    );

    // Filtering for cold call data
    if (this.state.isColdChecked) {
      let coldContactData = data?.filter(
        (contact) =>
          contact.assignmentState === CONTACT_STATUS.cold.code ||
          contact.assignmentState === CONTACT_STATUS.recall.code ||
          contact.assignmentState === CONTACT_STATUS.prospect.code
      );

      if (!isEmpty(coldContactData)) {
        dataBasedFromAssignments.push(...coldContactData);
      }
    }

    // Filtering for warm call data
    if (this.state.isWarmChecked) {
      let warmContactData = data?.filter(
        (contact) =>
          contact.assignmentState === CONTACT_STATUS.meeting.code ||
          contact.assignmentState === CONTACT_STATUS.firstProject.code ||
          contact.assignmentState === CONTACT_STATUS.oneProject.code ||
          contact.assignmentState === CONTACT_STATUS.anotherProject.code ||
          contact.assignmentState === CONTACT_STATUS.multipleProject.code
      );
      if (!isEmpty(warmContactData)) {
        dataBasedFromAssignments.push(...warmContactData);
      }
    }

    // Filtering of dall data by industries selected
    toBeFilteredData = dataBasedFromAssignments?.filter((call) =>
      contactsIdsFromSectorsChosen?.includes(call?.contactId)
    );

    if (sectorIds.includes(NO_INDUSTRY)) {
      let callsWithNoIndustry = dataBasedFromAssignments?.filter(
        (call) => !contactIdsFromSectors.includes(call?.contactId)
      );
      toBeFilteredData?.push(...callsWithNoIndustry);
    } else if (sectorIds.includes(ALL)) {
      toBeFilteredData = dataBasedFromAssignments;
    }

    // Filtering of call data by time selected
    switch (this.state.selectedTime) {
      case TIME_SPAN_ENUM.today.code:
        filteredData = toBeFilteredData?.filter(
          (item) => moment(item?.date).format('LL') === currentDate.format('LL')
        );
        break;
      case TIME_SPAN_ENUM.thisWeek.code:
        filteredData = toBeFilteredData?.filter(
          (item) => moment(item?.date).week() === currentDate.week()
        );
        break;
      case TIME_SPAN_ENUM.lastWeek.code:
        filteredData = toBeFilteredData?.filter(
          (item) => moment(item?.date).week() === currentDate.week() - 1
        );
        break;
      case TIME_SPAN_ENUM.thisMonth.code:
        filteredData = toBeFilteredData?.filter(
          (item) => moment(item?.date).month() === currentDate.month()
        );
        break;
      case TIME_SPAN_ENUM.lastMonth.code:
        filteredData = toBeFilteredData?.filter(
          (item) => moment(item?.date).month() === currentDate.month() - 1
        );
        break;
      case TIME_SPAN_ENUM.thisYear.code:
        filteredData = toBeFilteredData?.filter(
          (item) => moment(item?.date).year() === currentDate.year()
        );
        break;
      case TIME_SPAN_ENUM.overall.code:
        filteredData = toBeFilteredData;
        break;
      default:
        filteredData = toBeFilteredData;
        break;
    }

    return filteredData;
  };

  /**
   * Checks if the date passed that is converted to time format
   * is within time range
   * @param {*} date to be converted to time format
   * @param {*} timeRange
   * @returns true if the time is in range
   */
  checkIfTimeIsWithinTimeRange = (date, timeRange) => {
    return (
      moment(date).format('HH:mm:ss') > timeRange.start &&
      moment(date).format('HH:mm:ss') < timeRange.end
    );
  };

  /**
   * Retrieves data to be used in the bar graph
   * @returns data
   */
  getBarDataSet = () => {
    let filteredData = this.handleDataFilter();
    let legends = filteredData?.map((call) => call?.callStatusType);
    legends = [...new Set(legends)];

    let data = legends.map((type) => ({
      label: type,
      data: TIME_RANGES.map(
        (time) =>
          filteredData.filter((item) => {
            return (
              this.checkIfTimeIsWithinTimeRange(item?.date, time) &&
              item?.callStatusType === type
            );
          })?.length
      ),
      backgroundColor: filteredData.find(
        (call) => call?.callStatusType === type
      )?.statusColor,
    }));

    let sortedData = sortCallDataByType(data);
    return sortedData;
  };

  /**
   * Retrieves data to be used in the line graph
   * @param {*} rateType
   * @returns calculatedStatistics
   */
  getLineDataSet = (rateType) => {
    let filteredData = this.handleDataFilter();
    let callStatsFromTimeRange = TIME_RANGES.map((time) => ({
      total: filteredData?.filter((item) => {
        return this.checkIfTimeIsWithinTimeRange(item?.date, time);
      })?.length,

      calls: filteredData?.filter((item) => {
        return (
          this.checkIfTimeIsWithinTimeRange(item?.date, time) &&
          item?.callStatusType === CALL_STATUS_TYPE_ENUM.calls.name
        );
      })?.length,

      reached: filteredData?.filter((item) => {
        return (
          this.checkIfTimeIsWithinTimeRange(item?.date, time) &&
          item?.callStatusType === CALL_STATUS_TYPE_ENUM.reached.name
        );
      })?.length,

      appointments: filteredData?.filter((item) => {
        return (
          this.checkIfTimeIsWithinTimeRange(item?.date, time) &&
          item?.callStatusType === CALL_STATUS_TYPE_ENUM.appointments.name
        );
      })?.length,
    }));

    let calculatedStatistics = [];

    callStatsFromTimeRange.forEach((stats) => {
      let value = 0;
      if (stats.total !== 0) {
        switch (rateType) {
          case CALL_STATISTICS_RATE_ENUM.appointmentRate.name:
            value = stats.appointments / stats.total;
            break;
          case CALL_STATISTICS_RATE_ENUM.callingSuccess.name:
            value = stats.reached / stats.total;
            break;
          default:
            break;
        }
      }
      let roundedValue =
        value < 0.1 ? Math.round(value * 1000) / 10 : Math.round(value * 100);
      calculatedStatistics.push(roundedValue);
    });
    return calculatedStatistics;
  };

  /**
   * Handles changes to timespan dropdown
   * @param {*} time
   */
  handleTimeSpanChange = (time) => {
    this.setState({
      selectedTime: time,
    });
  };

  /**
   * Handles changes to industries dropdown
   * @param {*} selectedSectors
   */
  handleMultipleSectorChange = (selectedSectors) => {
    if (Array.isArray(selectedSectors)) {
      let newSectors = selectedSectors;
      if (newSectors.some((sector) => sector.value.toString() === ALL)) {
        newSectors = this.state.sectors.filter(
          (sector) => sector.value.toString() !== ALL
        );
      }
      let sectorIds = newSectors.map((sector) => sector.value);

      this.setState({
        sectorIds: sectorIds,
      });
    } else {
      this.setState({
        sectorIds: [],
      });
    }
  };

  /**
   * Handles changes to cold call checkbox
   * @param {*} event
   */
  handleColdContactCheck = (event) => {
    this.setState({
      isColdChecked: event.target.checked,
    });
  };

  /**
   * Handles changes to warm call checkbox
   * @param {*} event
   */
  handleWarmContactCheck = (event) => {
    this.setState({
      isWarmChecked: event.target.checked,
    });
  };

  render() {
    const appointmentRate = CALL_STATISTICS_RATE_ENUM.appointmentRate;
    const callingSuccess = CALL_STATISTICS_RATE_ENUM.callingSuccess;
    return (
      <Fragment>
        <Col>
          <h3>{this.t('successfulHoursForCalling')} </h3>
          <Row>
            <div style={{ width: '200px', padding: '10px' }}>
              <TimeSpanDropdown
                onChange={this.handleTimeSpanChange}
                selectedTime={
                  this.state.selectedTime ?? TIME_SPAN_ENUM.thisMonth.code
                }
              />
            </div>
            <div style={{ width: '60%', padding: '10px' }}>
              <Select
                isMulti
                options={this.state.sectors}
                value={this.state.sectors?.filter((sector) =>
                  this.state.sectorIds?.some((id) => id === sector.value)
                )}
                onChange={this.handleMultipleSectorChange}
                isClearable={true}
                placeholder={this.t('selectIndustries')}
              />
            </div>
          </Row>
          <Row>
            <div className="calling-stats-graph">
              <Bar
                data={{
                  labels: TIME_RANGES.map((time) => time.label),
                  datasets: this.getBarDataSet(),
                }}
                options={this.props.barOptions}
              />
            </div>
            <div className="calling-stats-graph">
              <Line
                data={{
                  labels: TIME_RANGES.map((time) => time.label),
                  datasets: [
                    {
                      label: appointmentRate.name,
                      data: this.getLineDataSet(appointmentRate.name),
                      backgroundColor: 'transparent',
                      borderColor: appointmentRate.color,
                      pointBackgroundColor: appointmentRate.color,
                      pointStyle: true,
                    },
                    {
                      label: callingSuccess.name,
                      data: this.getLineDataSet(callingSuccess.name),
                      backgroundColor: 'transparent',
                      borderColor: callingSuccess.color,
                      pointBackgroundColor: callingSuccess.color,
                      pointStyle: true,
                    },
                  ],
                }}
                options={this.props.lineOptions}
              />
            </div>
            <div className="calling-stats-checkbox">
              <Row>
                <Label>
                  <Input
                    type="checkbox"
                    checked={this.state.isColdChecked}
                    onChange={this.handleColdContactCheck}
                  />
                  {this.t('cold')}
                </Label>
              </Row>
              <Row>
                <Label>
                  <Input
                    type="checkbox"
                    checked={this.state.isWarmChecked}
                    onChange={this.handleWarmContactCheck}
                  />
                  {this.t('warm')}
                </Label>
              </Row>
            </div>
          </Row>
        </Col>
      </Fragment>
    );
  }
}
export default SuccessfulHoursForCallingCard;
