import { faEdit, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'reactstrap';

import { deleteAttachment } from '../../services/api/fileAttachment';
import { generateFileAttachmentLink } from '../../utils/helpers/fileAttachment';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import i18n from '../../i18n';
import {
  IFileAttachment,
  IFileAttachmentListItem,
} from '../../utils/types/modelTypes';
import AddOrUpdateFileAttachmentForm from '../form/AddOrUpdateFileAttachmentForm';
import Table from '../tables/Table';
import CollapsibleCard from './CollapsibleCard';

interface IProps extends IWithModalsProps {
  fileAttachments: IFileAttachmentListItem[];
  objectId: number;
  objectType: string;
  onSave: (file: IFileAttachment) => void;
  onDelete: (fileId: number) => void;
  defaultOpen?: boolean;
}

const t = (keyName: string) => i18n.t(`FileAttachmentCard.${keyName}`);

/**
 * Collapsible Files Card -
 * Displays a file attachment table with edit and delete buttons, and
 * contains header button for uploading files, and hamburger collapse icon
 */
const CollapsibleFilesCard = ({
  fileAttachments,
  objectId,
  objectType,
  onSave,
  onDelete,
  defaultOpen,
  modalDeleteHandler,
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
}: IProps) => {
  const handleUploadFile = () => {
    modalFormHandler(
      t('uploadAttachment'),
      <AddOrUpdateFileAttachmentForm
        onCancel={toggleModalForm}
        onSave={(file) => {
          onSave(file);
          toggleModalForm();
        }}
        objectId={objectId}
        objectType={objectType}
      />,
      'lg'
    );
  };

  const handleEditFile = (file: IFileAttachmentListItem) => {
    modalFormHandler(
      t('updateAttachment'),
      <AddOrUpdateFileAttachmentForm
        onCancel={toggleModalForm}
        onSave={(file) => {
          onSave(file);
          toggleModalForm();
        }}
        file={file}
        objectId={objectId}
        objectType={objectType}
      />,
      'lg'
    );
  };

  const handleDeleteFile = ({ id }: IFileAttachmentListItem) => {
    modalDeleteHandler(
      t('deleteAttachment'),
      t('deleteAttachmentConfirm'),
      async () => {
        try {
          await deleteAttachment(id);
          onDelete(id);
        } catch (error) {
          modalErrorHandler(t('failedToDeleteFileAttachment'), error);
        }
      }
    );
  };

  const headerButtons = (
    <Button
      aria-label="collapsible-files-card-button-upload"
      color="primary"
      size="m"
      onClick={() => handleUploadFile()}
    >
      <FontAwesomeIcon icon={faUpload} className="margin-right" />
      <span>{t('upload')}</span>
    </Button>
  );

  const tableData = fileAttachments.map((file) => ({
    [t('fileName')]: (
      <a
        target="_blank"
        download={file.fileName}
        href={generateFileAttachmentLink(file)}
        rel="noopener noreferrer"
      >
        {file.fileName}
      </a>
    ),
    '': (
      <div className="table-buttons float-end">
        <Button
          aria-label="collapsible-files-card-button-edit"
          style={{ margin: '2px' }}
          color="primary"
          onClick={() => {
            handleEditFile(file);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          aria-label="collapsible-files-card-button-delete"
          style={{ margin: '2px' }}
          color="primary"
          onClick={() => {
            handleDeleteFile(file);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div>
    ),
  }));

  return (
    <CollapsibleCard
      title={t('fileAttachment')}
      headerButtons={headerButtons}
      {...(defaultOpen && { defaultOpen })}
    >
      <Table
        tableData={tableData}
        noDataPlaceholder={t('noAttachmentsFound')}
      />
    </CollapsibleCard>
  );
};

export default withModals(CollapsibleFilesCard);
