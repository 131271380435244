import React, { Component } from 'react';
import Select from 'react-select';
import { preferredLanguages } from '../../utils/constants';
import i18n from '../../i18n';

/**
 * Dropdown that has all languages as options.\
 * props: onChange, value \
 */

export default class PreferredLanguageDropdown extends Component {
  t(keyName) {
    return i18n.t('PreferredLanguageDropdown.' + keyName);
  }

  handleChange = (event) => {
    let selectedValue = event?.value ?? null;
    this.props.onChange(selectedValue);
  };

  render() {
    return (
      <div data-testid="language-dropdown-div">
        <Select
          onChange={this.handleChange}
          value={this.props.value}
          options={Object.values(preferredLanguages)}
          placeholder={this.t('selectLanguage')}
        />
      </div>
    );
  }
}
