import {
  faInfoCircle,
  faExclamationTriangle,
  faPlus,
  faTrash,
  faEdit,
  faExclamationCircle,
  faSave,
  faWindowClose,
  faUpload,
  faUndo,
  faPen,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import i18n from '../../i18n';
import { Approval } from './approval';
import {
  PermissionAbsences,
  PermissionAdmin,
  PermissionCrm,
  PermissionData,
  PermissionEmployees,
  PermissionFinance,
  PermissionProject,
  PermissionSales,
  PermissionTimeTracking,
  PERMISSION_CAMPAIGN_MANAGEMENT,
  PERMISSION_RESOURCE_PLANNER,
  READ,
  READWRITE,
} from '../constants';

export const buttonTitleEnum = {
  INFORMATION: { code: 'INFORMATION', icon: faInfoCircle },
  WARNING: { code: 'WARNING', icon: faExclamationTriangle },
  ADD: { code: 'ADD', icon: faPlus },
  DELETE: { code: 'DELETE', icon: faTrash },
  UPDATE: { code: 'UPDATE', icon: faEdit },
  ERROR: { code: 'ERROR', icon: faExclamationCircle },
  SAVE: { code: 'SAVE', icon: faSave },
  EXIT: { code: 'EXIT', icon: faWindowClose },
  UPLOAD: { code: 'UPLOAD', icon: faUpload },
  UNDO: { code: 'UNDO', icon: faUndo },
  EDIT: { code: 'EDIT', icon: faPen },
  CANCEL: { code: 'CANCEL', icon: faTimes },
};

export const incomeType = {
  gross: { code: 'GROSS', name: 'Gross' },
  net: { code: 'NET', name: 'Net' },
};

export const timetrackingStateEnum = {
  open: 'OPEN',
  approved: 'APPROVED',
};

export const projectEmployeeStates = {
  planned: {
    code: 'PLANNED',
  },
  confirmed: {
    code: 'CONFIRMED',
  },
  waiting_for_approval: {
    code: 'WAITING_FOR_APPROVAL',
  },
};

export const totalUtilizationColorEnum = {
  allBooked: {
    name: 'Dark Blue',
    color: '#4192c4',
  },
  notAllBooked: {
    name: 'Dark Green',
    color: '#307237',
  },
};

export const projectEmployeeWorkloadState = {
  waiting_for_approval: {
    name: i18n.t('WorkloadState.WAITING_FOR_APPROVAL'),
    code: 'WAITING_FOR_APPROVAL',
  },
  planned: {
    name: i18n.t('WorkloadState.PLANNED'),
    code: 'PLANNED',
    color: '#B2CC7B',
  },
  assigned: {
    name: i18n.t('WorkloadState.ASSIGNED'),
    code: 'ASSIGNED',
  },
  booked: {
    name: i18n.t('WorkloadState.BOOKED'),
    code: 'BOOKED',
    color: '#4192C4',
  },
  reserved: {
    name: i18n.t('WorkloadState.RESERVED'),
    code: 'RESERVED',
    color: '#CAE9FF',
  },
  booked_and_open: {
    name: i18n.t('WorkloadState.BOOKED_AND_OPEN'),
    code: 'BOOKED_AND_OPEN',
    color: '#CAE9FF',
  },
  cancelled: {
    name: 'Cancelled',
    code: 'CANCELLED',
  },
};

export const ToDoActivityStateEnum = {
  ACTIVE: 'ACTIVE',
  REMOVED: 'REMOVED',
  ACCOMPLISHED: 'ACCOMPLISHED',
  DELEGATED: 'DELEGATED',
};

export const projectStateEnum = {
  vague: {
    name: i18n.t('ProjectStates.VAGUE'),
    code: 'VAGUE',
  },
  defined: {
    name: i18n.t('ProjectStates.DEFINED'),
    code: 'DEFINED',
  },
  proposalAhead: {
    name: i18n.t('ProjectStates.PROPOSAL_AHEAD'),
    code: 'PROPOSAL_AHEAD',
  },
  proposalTechnicalGo: {
    name: i18n.t('ProjectStates.PROPOSAL_TECHNICAL_GO'),
    code: 'PROPOSAL_TECHNICAL_GO',
  },
  pitching: {
    name: i18n.t('ProjectStates.PITCHING'),
    code: 'PITCHING',
  },
  planning: {
    name: i18n.t('ProjectStates.PLANNING'),
    code: 'PLANNING',
  },
  estimating: {
    name: i18n.t('ProjectStates.ESTIMATING'),
    code: 'ESTIMATING',
  },
  aligning: {
    name: i18n.t('ProjectStates.ALIGNING'),
    code: 'ALIGNING',
  },
  offerSent: {
    name: i18n.t('ProjectStates.OFFER_SENT'),
    code: 'OFFER_SENT',
  },
  lost: {
    name: i18n.t('ProjectStates.LOST'),
    code: 'LOST',
  },
  ordered: {
    name: i18n.t('ProjectStates.ORDERED'),
    code: 'ORDERED',
  },
  active: {
    name: i18n.t('ProjectStates.ACTIVE'),
    code: 'ACTIVE',
  },
  paused: {
    name: i18n.t('ProjectStates.PAUSED'),
    code: 'PAUSED',
  },
  closed: {
    name: i18n.t('ProjectStates.CLOSED'),
    code: 'CLOSED',
  },
  cancelled: {
    name: i18n.t('ProjectStates.CANCELLED'),
    code: 'CANCELLED',
  },
};

export const projectStateEnumOrdered = [
  { rank: '1', title: 'vague' },
  { rank: '2', title: 'defined' },
  { rank: '3a', title: 'proposalAhead' },
  { rank: '3a', title: 'proposalTechnicalGo' },
  { rank: '3b', title: 'pitching' },
  { rank: '3b', title: 'planning' },
  { rank: '3c', title: 'estimating' },
  { rank: '3c', title: 'aligning' },
  { rank: '4', title: 'offerSent' },
];

export const acquisitionTypeEnum = {
  undefined: {
    name: i18n.t('Acquisitions.UNDEFINED'),
    code: 'UNDEFINED',
  },
  proposal: {
    name: i18n.t('Acquisitions.PROPOSAL'),
    code: 'PROPOSAL',
  },
  pitch: {
    name: i18n.t('Acquisitions.PITCH'),
    code: 'PITCH',
  },
  specification: {
    name: i18n.t('Acquisitions.SPECIFICATION'),
    code: 'SPECIFICATION',
  },
};

export const originEnum = {
  coldCalling: {
    name: i18n.t('Options.COLD_CALLING'),
    code: 'COLD_CALLING',
  },
  statusMeeting: {
    name: i18n.t('Options.STATUS_MEETING'),
    code: 'STATUS_MEETING',
  },
  csInfoCall: {
    name: i18n.t('Options.CS_INFO_CALL'),
    code: 'CS_INFO_CALL',
  },
  internalCustomerMarketing: {
    name: i18n.t('Options.INTERNAL_CUSTOMER_MARKETING'),
    code: 'INTERNAL_CUSTOMER_MARKETING',
  },
  goodFormerCollab: {
    name: i18n.t('Options.GOOD_FORMER_COLLAB'),
    code: 'GOOD_FORMER_COLLAB',
  },
  strategyPurchase: {
    name: i18n.t('Options.STRATEGY_PURCHASE'),
    code: 'STRATEGY_PURCHASE',
  },
  anuRequest: {
    name: i18n.t('Options.ANU_REQUEST'),
    code: 'ANU_REQUEST',
  },
  customerRequest: {
    name: i18n.t('Options.CUSTOMER_REQUEST'),
    code: 'CUSTOMER_REQUEST',
  },
  campaign: {
    name: i18n.t('Options.CAMPAIGN'),
    code: 'CAMPAIGN',
  },
  personalNetwork: {
    name: i18n.t('Options.PERSONAL_NETWORK'),
    code: 'PERSONAL_NETWORK',
  },
  conferences: {
    name: i18n.t('Options.CONFERENCES'),
    code: 'CONFERENCES',
  },
  alumni: {
    name: i18n.t('Options.INV_ALUMNI'),
    code: 'INV_ALUMNI',
  },
  trainingAtCustomer: {
    name: i18n.t('Options.TRAINING_AT_CUSTOMER'),
    code: 'TRAINING_AT_CUSTOMER',
  },
  tenderRfp: {
    name: i18n.t('Options.TENDER_RFP'),
    code: 'TENDER_RFP',
  },
};

export const linkRedirectEnum = {
  CUSTOMERPAGE: 1,
  DETAILSPAGE: 2,
  LISTPAGE: 3,
};

export const contactInfoEnum = {
  phone: { code: 'Phone', name: 'Phone' },
  email: { code: 'Email', name: 'Email' },
  mobile: { code: 'Mobile', name: 'Mobile' },
  xing: { code: 'Xing', name: 'Xing' },
  linkedIn: { code: 'LinkedIn', name: 'LinkedIn' },
  website: { code: 'Website', name: 'Website' },
};

export const customerSiteFieldsEnum = {
  name: 'name',
  customerSiteState: 'customerSiteState',
  street: 'street',
  location: 'location',
  zipCode: 'zipCode',
  countryState: 'countryState',
  country: 'country',
  sectors: 'sectors',
  customerSiteType: 'customerSiteType',
  contactInformation: 'contactInformation',
  contactPersons: 'contactPersons',
  responsible: 'responsible',
  targetPriority: 'targetPriority',
  involvedEmployees: 'involvedEmployees',
  customer: 'customer',
  customerAccount: 'customerAccount',
  involvedResponsibleDetailed: 'involvedResponsibleDetailed',
  sectorsDetailed: 'sectorsDetailed',
  activities: 'activities',
  fileAttachments: 'fileAttachments',
};

export const objectTypeEnum = {
  request: { code: 'REQUEST', name: 'Request' },
  order: { code: 'ORDER', name: 'Order' },
  offer: { code: 'OFFER', name: 'Offer' },
  customer: { code: 'CUSTOMER', name: 'Customer' },
  finance: { code: 'FINANCE', name: 'Finance' },
  contactPerson: { code: 'CONTACT_PERSON', name: 'Contact Person' },
  call: { code: 'CALL', name: 'Call' },
  customerSite: { code: 'CUSTOMER_SITE', name: 'Customer Site' },
  customerAccount: { code: 'CUSTOMER_ACCOUNT', name: 'Customer Account' },
  project: { code: 'PROJECT', name: 'Project' },
  offerVersion: { code: 'OFFER_VERSION', name: 'Offer Version' },
  absenceSubmitted: { code: 'ABSENCE_SUBMITTED', name: 'Absence Submitted' },
  absenceCancelledPending: {
    code: 'ABSENCE_CANCELLED_PENDING',
    name: 'Absence Cancelled Pending',
  },
  invoice: { code: 'INVOICE', name: 'Invoice' },
  absenceRetreated: { code: 'ABSENCE_RETREATED', name: 'Absence Retreated' },
  absenceApproved: { code: 'ABSENCE_APPROVED', name: 'Absence Approved' },
  absenceDeclined: { code: 'ABSENCE_DECLINED', name: 'Absence Declined' },
  employee: { code: 'EMPLOYEE', name: 'Employee' },
  contact: { code: 'CONTACT', name: 'Contact' },
  projectTimeSheet: { code: 'PROJECT_TIMESHEET', name: 'Project Timesheet' },
  responsible: { code: 'RESPONSIBLE', name: 'Responsible' },
  skillSet: { code: 'SKILL_SET', name: 'Skill Set' },
  projectOption: { code: 'PROJECT_OPTION', name: 'Project Option' },
  department: { code: 'DEPARTMENT', name: 'Department' },
  fieldOfBusiness: { code: 'FIELD_OF_BUSINESS', name: 'Field of Business' },
  none: { code: 'NONE', name: 'None' },
  projectEmployeeWorkload: {
    code: 'PROJECT_EMPLOYEE_WORKLOAD',
    name: 'Project Employee Workload',
  },
};

export const rejectionCategories = {
  official: { code: 'CST', name: i18n.t('ProjectOptionLostModal.customer') },
  internal: { code: 'INV', name: 'INVENSITY' },
};

export const contactStatus = {
  cold: {
    code: '01',
    name: i18n.t('ContactStatus.No contact with INV'),
    color: 'blue',
  },
  recall: {
    code: '02',
    name: i18n.t('ContactStatus.Phone contact established'),
    color: 'blue',
  },
  prospect: {
    code: '03',
    name: i18n.t('ContactStatus.Prospection ahead'),
    color: 'blue',
  },
  meeting: {
    code: '04',
    name: i18n.t('ContactStatus.Personal contact established'),
    color: 'blue',
  },
  firstProject: {
    code: 'P',
    name: i18n.t('ContactStatus.Currently first project together'),
    color: 'green',
  },
  oneProject: {
    code: 'X',
    name: i18n.t('ContactStatus.One project in the past'),
    color: 'green',
  },
  anotherProject: {
    code: 'Q',
    name: i18n.t('ContactStatus.Another project together'),
    color: 'green',
  },
  multipleProject: {
    code: 'M',
    name: i18n.t('ContactStatus.Multiple project in the past'),
    color: 'green',
  },
  disqualified: {
    code: '00',
    name: i18n.t('ContactStatus.Disqualified'),
    color: 'grey',
  },
  undefined: {
    code: 'U',
    name: i18n.t('ContactStatus.Undefined'),
    color: 'grey',
  },
};

// Used for the employee access panel
export const subjectType = {
  employee: {
    code: 'EMPLOYEE',
    name: 'Employee',
  },
  employeeGroup: {
    code: 'EMPLOYEEGROUP',
    name: 'employeeGroup',
  },
};

export const permissionUri = {
  adminAccess: {
    readWrite: {
      label: 'Admin Access',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: '*',
      description: i18n.t('PermissionDescriptions.adminAccess'),
      section: PermissionAdmin,
    },
  },
  campaignManagementAccess: {
    readWrite: {
      label: 'Campaign Management Access',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.campaignManagementAccess',
      description: i18n.t('PermissionDescriptions.campaignManagementAccess'),
      section: PERMISSION_CAMPAIGN_MANAGEMENT,
    },
  },
  timeTracking: {
    readWrite: {
      label: 'Display Timetracking',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.timeTracking.timeTracking',
      description: i18n.t('PermissionDescriptions.displayTimeTracking'),
      section: PermissionTimeTracking,
    },
  },
  timeTrackingApprovalList: {
    readWrite: {
      label: 'Display Timetracking Approval List',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.timeTracking.approvalList',
      description: i18n.t(
        'PermissionDescriptions.displayTimeTrackingApprovalList'
      ),
      section: PermissionTimeTracking,
    },
  },
  azkOverview: {
    readWrite: {
      label: 'Display AZK Overview',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.timeTracking.azkOverview',
      description: i18n.t('PermissionDescriptions.displayAzkOverview'),
      section: PermissionTimeTracking,
    },
  },
  fullCustomerAccountList: {
    read: {
      label: 'Display Full Customer Account List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.fullCustomerAccountList',
      description: i18n.t(
        'PermissionDescriptions.displayFullCustomerAccountList'
      ),
      section: PermissionCrm,
    },
  },
  customerAccountList: {
    read: {
      label: 'Display Own Customer Account List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerAccountList',
      description: i18n.t(
        'PermissionDescriptions.displayOwnCustomerAccountList'
      ),
      section: PermissionCrm,
    },
    readWrite: {
      label: 'Create Customer Accounts',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerAccountList',
      description: i18n.t('PermissionDescriptions.createCustomerAccounts'),
      section: PermissionCrm,
    },
  },
  responsibleHandoverNotificationForCustomer: {
    readWrite: {
      label:
        'Informed when Customer or Site Responsible handover is done within Account',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.responsibleHandoverNotificationForCustomer',
      description: i18n.t(
        'PermissionDescriptions.responsibleHandoverNotificationForCustomer'
      ),
      section: PermissionCrm,
    },
  },
  fullCustomerList: {
    read: {
      label: 'Display Full Customer List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.fullCustomerList',
      description: i18n.t('PermissionDescriptions.displayFullCustomerList'),
      section: PermissionCrm,
    },
  },
  customerList: {
    read: {
      label: 'Display Own Customer List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerList',
      description: i18n.t('PermissionDescriptions.displayOwnCustomerList'),
      section: PermissionCrm,
    },
    readWrite: {
      label: 'Create Customers',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerList',
      description: i18n.t('PermissionDescriptions.createCustomers'),
      section: PermissionCrm,
    },
  },
  responsibleHandoverNotificationForCustomerSite: {
    readWrite: {
      label:
        'Informed when Customer Site Responsible handover is done within Customer',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.responsibleHandoverNotificationForCustomerSite',
      description: i18n.t(
        'PermissionDescriptions.responsibleHandoverNotificationForCustomerSite'
      ),
      section: PermissionCrm,
    },
  },
  fullCustomerSiteList: {
    read: {
      label: 'Display Full Customer Site List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.fullCustomerSiteList',
      description: i18n.t('PermissionDescriptions.displayFullCustomerSiteList'),
      section: PermissionCrm,
    },
  },
  customerSiteList: {
    read: {
      label: 'Display Own Customer Site List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerSiteList',
      description: i18n.t('PermissionDescriptions.displayOwnCustomerSiteList'),
      section: PermissionCrm,
    },
    readWrite: {
      label: 'Create Customer Sites',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerSiteList',
      description: i18n.t('PermissionDescriptions.createCustomerSites'),
      section: PermissionCrm,
    },
  },
  fullContactPeopleList: {
    read: {
      label: 'Display Full Contact People List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.fullContactPeopleList',
      description: i18n.t(
        'PermissionDescriptions.displayFullContactPeopleList'
      ),
      section: PermissionCrm,
    },
  },
  contactPeopleList: {
    read: {
      label: 'Display Own Contact People List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.contactPeopleList',
      description: i18n.t('PermissionDescriptions.displayOwnContactPeopleList'),
      section: PermissionCrm,
    },
    readWrite: {
      label: 'Create Contact People',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.contactPeopleList',
      description: i18n.t('PermissionDescriptions.createContactPeople'),
      section: PermissionCrm,
    },
  },
  campaignManager: {
    readWrite: {
      label: 'Display Campaign Manager',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.campaignManager',
      description: i18n.t('PermissionDescriptions.displayCampaignManager'),
      section: PermissionCrm,
    },
  },
  gdprOverview: {
    readWrite: {
      label: 'Display GDPR Overview',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.gdprOverview',
      description: i18n.t('PermissionDescriptions.displayGdprOverview'),
      section: PermissionCrm,
    },
  },
  callingStats: {
    read: {
      label: 'Display Calling Stats for all Sales Managers',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.salesManagerCallingStats',
      description: i18n.t(
        'PermissionDescriptions.displaySalesManagerCallingStats'
      ),
      section: PermissionCrm,
    },
  },
  myCallingStats: {
    read: {
      label: 'Display My Calling Stats',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.myCallingStats',
      description: i18n.t('PermissionDescriptions.displayMyCallingStats'),
      section: PermissionCrm,
    },
  },
  globalCallingStats: {
    read: {
      label: 'Display Global Calling Stats',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.globalCallingStats',
      description: i18n.t('PermissionDescriptions.displayGlobalCallingStats'),
      section: PermissionCrm,
    },
  },
  customerTargetPriorityAccess: {
    readWrite: {
      label: 'Edit Customer Target Priority',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerTargetPriorityAccess',
      description: i18n.t(
        'PermissionDescriptions.customerTargetPriorityAccess'
      ),
      section: PermissionCrm,
    },
  },
  responsibleHandoverNotification: {
    readWrite: {
      label:
        'Informed when Customer or Site Responsible handover is done within INV',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.responsibleHandoverNotification',
      description: i18n.t(
        'PermissionDescriptions.responsibleHandoverNotification'
      ),
      section: PermissionCrm,
    },
  },
  sales: {
    read: {
      label: 'Read Sales Data',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.sales',
      description: i18n.t('PermissionDescriptions.readSalesData'),
      section: PermissionCrm,
    },
    readWrite: {
      label: 'Access Sales Data',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.sales',
      description: i18n.t('PermissionDescriptions.accessSalesData'),
      section: PermissionCrm,
    },
  },
  projectOptions: {
    read: {
      label: 'Display Project Options List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.project.projectOptionList',
      description: i18n.t('PermissionDescriptions.displayProjectOptionsList'),
      section: PermissionSales,
    },
    readWrite: {
      label: 'Create Project Options',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.project.projectOption',
      description: i18n.t('PermissionDescriptions.createProjectOptions'),
      section: PermissionSales,
    },
  },
  resourceOverview: {
    read: {
      label: 'Display Resource Overview',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.resource',
      description: i18n.t('PermissionDescriptions.viewResources'),
      section: PERMISSION_RESOURCE_PLANNER,
    },
  },
  resourcePlannerAppear: {
    readWrite: {
      label: 'Appear in Resource Planner',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.resourcePlannerAppearance',
      description: i18n.t('PermissionDescriptions.resourcePlannerAppearance'),
      section: PERMISSION_RESOURCE_PLANNER,
    },
  },
  assignedToProjects: {
    readWrite: {
      label: 'Become Assigned to Projects/Project Options',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.assignedToProjects',
      description: i18n.t('PermissionDescriptions.assignedToProjects'),
      section: PERMISSION_RESOURCE_PLANNER,
    },
  },
  assignResourcesToProjects: {
    readWrite: {
      label: 'Assign Resources to owned Projects',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.assignResourcesToProjects',
      description: i18n.t('PermissionDescriptions.assignResourcesToProjects'),
      section: PERMISSION_RESOURCE_PLANNER,
    },
  },
  assignResourcesToProjectOptions: {
    readWrite: {
      label: 'Assign Resources to owned Project Options',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.assignResourcesToProjectOptions',
      description: i18n.t(
        'PermissionDescriptions.assignResourcesToProjectOptions'
      ),
      section: PERMISSION_RESOURCE_PLANNER,
    },
  },
  assignResources: {
    readWrite: {
      label: 'Assign Resources to all Projects and Project Options',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.assignResources',
      description: i18n.t('PermissionDescriptions.assignResources'),
      section: PERMISSION_RESOURCE_PLANNER,
    },
  },
  projectList: {
    read: {
      label: 'Display Full Project List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.projectList',
      description: i18n.t('PermissionDescriptions.displayProjectList'),
      section: PermissionProject,
    },
    readWrite: {
      label: 'Create Projects',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.projectList',
      description: i18n.t('PermissionDescriptions.createProjects'),
      section: PermissionProject,
    },
  },
  internalProject: {
    readWrite: {
      label: 'Create Internal Projects',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.internalProject',
      description: i18n.t('PermissionDescriptions.createInternalProjects'),
      section: PermissionProject,
    },
  },
  internalProjectApproval: {
    readWrite: {
      label: 'Approve Budget for Internal Projects',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.internalProjectApproval',
      description: i18n.t('PermissionDescriptions.internalProjectApproval'),
      section: PermissionProject,
    },
  },
  becomeProjectOwner: {
    readWrite: {
      label: 'Become Project Owner',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.becomeProjectOwner',
      description: i18n.t('PermissionDescriptions.becomeProjectOwner'),
      section: PermissionProject,
    },
  },
  becomeProjectManager: {
    readWrite: {
      label: 'Become Project Manager',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.becomeProjectManager',
      description: i18n.t('PermissionDescriptions.becomeProjectManager'),
      section: PermissionProject,
    },
  },
  projectBudget: {
    read: {
      label: 'Project Budget Permission',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.projectBudget',
      description: i18n.t('PermissionDescriptions.projectBudgetPermission'),
      section: PermissionProject,
    },
  },
  manageDepartments: {
    readWrite: {
      label: 'Manage Departments',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.manageDepartments',
      description: i18n.t('PermissionDescriptions.manageDepartments'),
      section: PermissionProject,
    },
  },
  controllingList: {
    read: {
      label: 'Display Controlling List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.finance.controllingList',
      description: i18n.t('PermissionDescriptions.displayControllingList'),
      section: PermissionFinance,
    },
  },
  finance: {
    read: {
      label: 'Finance Permission',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.finance',
      description: i18n.t('PermissionDescriptions.financePermission'),
      section: PermissionFinance,
    },
    readWrite: {
      label: 'Create Invoices',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.finance',
      description: i18n.t('PermissionDescriptions.createInvoices'),
      section: PermissionFinance,
    },
  },
  employeeList: {
    read: {
      label: 'Display Full Employee List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.employeeList',
      description: i18n.t('PermissionDescriptions.displayEmployeeList'),
      section: PermissionEmployees,
    },
  },
  fullEmployeeDetails: {
    read: {
      label: 'Display Full Employee Details',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.fullEmployeeDetails',
      description: i18n.t('PermissionDescriptions.displayFullEmployeeDetails'),
      section: PermissionEmployees,
    },
  },
  employeeManagement: {
    read: {
      label: 'Display Employee Management',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.management',
      description: i18n.t('PermissionDescriptions.displayEmployeeManagement'),
      section: PermissionEmployees,
    },
    readWrite: {
      label: 'Manage Employees',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.management',
      description: i18n.t('PermissionDescriptions.manageEmployees'),
      section: PermissionEmployees,
    },
  },
  employeeManageMyEmployees: {
    readWrite: {
      label: 'Manage My Employees',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.manageMyEmployees',
      description: i18n.t('PermissionDescriptions.manageMyEmployees'),
      section: PermissionEmployees,
    },
  },
  employeeMyTeam: {
    read: {
      label: 'displayMyTeam',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.myTeam',
      description: i18n.t('PermissionDescriptions.displayEmployeeMyTeam'),
      section: PermissionEmployees,
    },
  },
  employeeApprovalsList: {
    read: {
      label: 'Display Employee Approval List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.approvalList',
      description: i18n.t('PermissionDescriptions.displayEmployeeApprovalList'),
      section: PermissionEmployees,
    },
  },
  employeeAsResponsible: {
    readWrite: {
      label: 'Be in choice for INV-Responsible',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.employeeAsResponsible',
      description: i18n.t('PermissionDescriptions.employeeAsResponsible'),
      section: PermissionEmployees,
    },
  },
  absenceApprovalsList: {
    read: {
      label: 'Display Absence Approval List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.absence.approvalList',
      description: i18n.t('PermissionDescriptions.displayAbsenceApprovalList'),
      section: PermissionAbsences,
    },
  },
  teamAbsencesList: {
    read: {
      label: 'Display Team Absences List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.absence.teamAbsencesList',
      description: i18n.t('PermissionDescriptions.displayTeamAbsencesList'),
      section: PermissionAbsences,
    },
  },
  absenceApprovalsResponsibleList: {
    readWrite: {
      label: 'Approve Absences of responsible',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.absence.approvalListResponsible',
      description: i18n.t(
        'PermissionDescriptions.displayAbsenceApprovalListResponsible'
      ),
      section: PermissionAbsences,
    },
  },
  absenceStatistics: {
    read: {
      label: 'Read Absence Statistics',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.absence.absenceStatistics',
      description: i18n.t('PermissionDescriptions.readAbsenceStatistics'),
      section: PermissionAbsences,
    },
  },
  adminDataAccess: {
    readWrite: {
      label: 'Admin Access',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: '*',
      description: i18n.t('PermissionDescriptions.adminAccess'),
      section: PermissionData,
    },
  },
  employee: {
    readWrite: {
      label: 'Employee Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.Employee',
      description: i18n.t('PermissionDescriptions.readWriteAllEmployeeData'),
      section: PermissionData,
    },
  },
  employeeGroup: {
    readWrite: {
      label: 'Employee Group Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.EmployeeGroup',
      description: i18n.t(
        'PermissionDescriptions.readWriteAllEmployeeGroupData'
      ),
      section: PermissionData,
    },
  },
  project: {
    readWrite: {
      label: 'Project Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.Project',
      description: i18n.t('PermissionDescriptions.readWriteAllProjectData'),
      section: PermissionData,
    },
  },
  customerAccount: {
    readWrite: {
      label: 'Customer Account Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.CustomerAccount',
      description: i18n.t(
        'PermissionDescriptions.readWriteAllCustomerAccountData'
      ),
      section: PermissionData,
    },
  },
  customer: {
    read: {
      label: 'Customer Read Permission',
      accessType: READ,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.Customer',
      description: i18n.t('PermissionDescriptions.readAllCustomerData'),
      section: PermissionData,
    },
    readWrite: {
      label: 'Customer ReadWrite Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.Customer',
      description: i18n.t('PermissionDescriptions.readWriteAllCustomerData'),
      section: PermissionData,
    },
  },
  customerSite: {
    readWrite: {
      label: 'Customer Site Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.CustomerSite',
      description: i18n.t(
        'PermissionDescriptions.readWriteAllCustomerSiteData'
      ),
      section: PermissionData,
    },
  },
  contactPerson: {
    readWrite: {
      label: 'Contact Person Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.ContactPerson',
      description: i18n.t(
        'PermissionDescriptions.readWriteAllContactPersonData'
      ),
      section: PermissionData,
    },
  },
  timetracking: {
    readWrite: {
      label: 'Timetracking Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.Timetracking',
      description: i18n.t(
        'PermissionDescriptions.readWriteAllTimetrackingData'
      ),
      section: PermissionData,
    },
  },
  approval: {
    readWrite: {
      label: 'Approval Requests',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.domain.ApprovalRequest',
      description: i18n.t('PermissionDescriptions.approvalRequest'),
      section: PermissionData,
    },
  },
};

export const nextActionEnums = {
  callContact: 'Call Contact',
  doProspect: 'Do Prospect',
  backlog: 'Backlog',
  none: 'None',
  searchContact: 'Search Contact',
  special: 'Special',
  doFollowUp: 'Do Follow-Up Meeting',
  sendEmail: 'Send Email',
  createOffer: 'Create Offer',
  sendOffer: 'Send Offer',
};

export const serviceConfig = {
  coldCalling: {
    service: 'Customer',
    key: 'com.invensity.com.itwcrm.customer.coldcalling',
    value: 'true',
  },
  workingHoursPerMonth: {
    service: 'Core',
    key: 'defaultWorkingHoursPerMonth',
  },
  customerPriority: {
    service: 'Customer',
    key: 'com.invensity.itwcrm.action.customer.targetPriority',
    value: {
      both: 'both',
      bddHead: 'bddHead',
      responsible: 'responsible',
    },
  },
  prioritizationTargetGoal: {
    service: 'Sales',
    key: 'priorityTargetGoal',
  },
};

export const genderEnum = {
  female: i18n.t('Gender.female'),
  male: i18n.t('Gender.male'),
  unknown: i18n.t('Gender.unknown'),
};

export const contactPersonAttitude = {
  neutral: {
    value: '0',
    label: 'Neutral',
    color: 'blue',
  },
  positive: {
    value: '1',
    label: 'Positive',
    color: 'green',
  },
  veryPositive: {
    value: '2',
    label: 'Very Positive',
    color: 'green',
  },
  vipCollaborator: {
    value: '3',
    label: 'VIP Collaborator',
    color: 'green',
  },
  negative: {
    value: '-1',
    label: 'Negative',
    color: 'orange',
  },
  risk: {
    value: '-2',
    label: 'Project Risk when Involved',
    color: 'red',
  },
};

export const contactPersonTarget = {
  targetForAcquisition: {
    value: true,
    label: i18n.t('ContactPersonTarget.targetForAcquisition'),
  },
  noTargetForAcquisition: {
    value: false,
    label: i18n.t('ContactPersonTarget.noTargetForAcquisition'),
  },
};

export const confirmOrDisqualify = {
  disqualify: {
    code: 'disqualify',
    label: 'Disqualify',
  },
  keepNextActivity: {
    code: 'keepNextActivity',
    label: 'Keep Next Activity',
  },
};

export const customerStateEnum = {
  active: {
    name: i18n.t('CustomerStates.ACTIVE'),
    code: 'ACTIVE',
  },
  acquisition: {
    name: i18n.t('CustomerStates.IN_ACQUISITION'),
    code: 'IN_ACQUISITION',
  },
  former: {
    name: i18n.t('CustomerStates.FORMER'),
    code: 'FORMER',
  },
  none: {
    name: i18n.t('CustomerStates.NONE'),
    code: 'NONE',
  },
};

export const skillAssignmentEnum = {
  requested: {
    name: i18n.t('SkillAssignments.REQUESTED'),
    code: 'REQUESTED',
  },
  accepted: {
    name: i18n.t('SkillAssignments.ACCEPTED'),
    code: 'ACCEPTED',
  },
  to_be_discussed: {
    name: i18n.t('SkillAssignments.TO_BE_DISCUSSED'),
    code: 'TO_BE_DISCUSSED',
  },
  preliminary: {
    name: i18n.t('SkillAssignments.PRELIMINARY'),
    code: 'PRELIMINARY',
  },
};

export const employeeStateEnum = {
  tbd: {
    name: i18n.t('EmployeeStates.TBD'),
    code: 'TBD',
  },
  signed: {
    name: i18n.t('EmployeeStates.SIGNED'),
    code: 'SIGNED',
  },
  active: {
    name: i18n.t('EmployeeStates.ACTIVE'),
    code: 'ACTIVE',
  },
  former: {
    name: i18n.t('EmployeeStates.FORMER'),
    code: 'FORMER',
  },
};

export const staffedEnum = {
  no_staff: {
    code: 'NO',
    name: i18n.t('StaffedEnum.NO'),
  },
  partly_staffed: {
    code: 'PARTLY',
    name: i18n.t('StaffedEnum.PARTLY'),
  },
  fully_staffed: {
    code: 'FULLY',
    name: i18n.t('StaffedEnum.FULLY'),
  },
};

export const skillsType = {
  certification: {
    code: 'CERTIFICATION',
    name: 'Certification',
  },
  framework: {
    code: 'FRAMEWORK',
    name: 'Framework',
  },
  language: {
    code: 'LANGUAGE',
    name: ' Language',
  },
  library: {
    code: 'LIBRARY',
    name: 'Library',
  },
  method: {
    code: 'METHOD',
    name: 'Method',
  },
  protocol: {
    code: 'PROTOCOL',
    name: 'Protocol',
  },
  skill: {
    code: 'SKILL',
    name: 'Skill',
  },
  standard: {
    code: 'STANDARD',
    name: 'Standard',
  },
  tool: {
    code: 'TOOL',
    name: 'Tool',
  },
  topic: {
    code: 'TOPIC',
    name: 'Topic',
  },
  others: {
    code: 'OTHERS',
    name: 'Others',
  },
};

export const projectOptionActivities = {
  doFollowUpMeeting: {
    code: 'DO_FOLLOW_UP_MEETING',
    name: i18n.t('ProjectActivities.DO_FOLLOW_UP_MEETING'),
  },
  callContact: {
    code: 'CALL_CONTACT',
    name: i18n.t('ProjectActivities.CALL_CONTACT'),
  },
  sendEO: {
    code: 'SEND_EO',
    name: i18n.t('ProjectActivities.SEND_EO'),
  },
  arrangeProposal: {
    code: 'ARRANGE_PROPOSAL',
    name: i18n.t('ProjectActivities.ARRANGE_PROPOSAL'),
  },
  prepareProposal: {
    code: 'PREPARE_PROPOSAL',
    name: i18n.t('ProjectActivities.PREPARE_PROPOSAL'),
  },
  doProposal: {
    code: 'DO_PROPOSAL',
    name: i18n.t('ProjectActivities.DO_PROPOSAL'),
  },
  callForFeedback: {
    code: 'CALL_FOR_FEEDBACK',
    name: i18n.t('ProjectActivities.CALL_FOR_FEEDBACK'),
  },
  doPitch: {
    code: 'DO_PITCH',
    name: i18n.t('ProjectActivities.DO_PITCH'),
  },
  callForSpec: {
    code: 'CALL_FOR_SPEC',
    name: i18n.t('ProjectActivities.CALL_FOR_SPEC'),
  },
  finalizeEstimation: {
    code: 'FINALIZE_ESTIMATION',
    name: i18n.t('ProjectActivities.FINALIZE_ESTIMATION'),
  },
  modifyEstimation: {
    code: 'MODIFY_ESTIMATION',
    name: i18n.t('ProjectActivities.MODIFY_ESTIMATION'),
  },
  createOffer: {
    code: 'CREATE_OFFER',
    name: i18n.t('ProjectActivities.CREATE_OFFER'),
  },
  sendPurchaseDocs: {
    code: 'SEND_PURCHASE_DOCS',
    name: i18n.t('ProjectActivities.SEND_PURCHASE_DOCS'),
  },
  defineEstimationTeam: {
    code: 'DEFINE_ESTIMATION_TEAM',
    name: i18n.t('ProjectActivities.DEFINE_ESTIMATION_TEAM'),
  },
  sendOffer: {
    code: 'SEND_OFFER',
    name: i18n.t('ProjectActivities.SEND_OFFER'),
  },
  defineProjectManager: {
    code: 'DEFINE_PROJECT_MANAGER',
    name: i18n.t('ProjectActivities.DEFINE_PROJECT_MANAGER'),
  },
  initializeKickOff: {
    code: 'INITIALIZE_KICK_OFF',
    name: i18n.t('ProjectActivities.INITIALIZE_KICK_OFF'),
  },
  initializeInternalKickOff: {
    code: 'INITIALIZE_INTERNAL_KICK_OFF',
    name: i18n.t('ProjectActivities.INITIALIZE_INTERNAL_KICK_OFF'),
  },
  initializeStatusMeeting: {
    code: 'INITIALIZE_STATUS_MEETING',
    name: i18n.t('ProjectActivities.INITIALIZE_STATUS_MEETING'),
  },
  prepareExtension: {
    code: 'PREPARE_EXTENSION',
    name: i18n.t('ProjectActivities.PREPARE_EXTENSION'),
  },
  initializeClosingMeeting: {
    code: 'INITIALIZE_CLOSING_MEETING',
    name: i18n.t('ProjectActivities.INITIALIZE_CLOSING_MEETING'),
  },
  doLessonsLearnt: {
    code: 'DO_LESSONS_LEARNT',
    name: i18n.t('ProjectActivities.DO_LESSONS_LEARNT'),
  },
};

export const absenceStateEnum = {
  Planned: {
    name: 'Planned',
    code: 'PLANNED',
    badge: 'badge badge-primary',
  },
  Submitted: {
    name: 'Submitted',
    code: 'SUBMITTED',
    badge: 'badge badge-warning',
  },
  Retreated: {
    name: 'Retreated',
    code: 'RETREATED',
    badge: 'badge badge-info',
  },
  Declined: {
    code: 'DECLINED',
    name: 'Declined',
    badge: 'badge badge-danger',
  },
  Accepted: {
    name: 'Accepted',
    code: 'ACCEPTED',
    badge: 'badge badge-success',
  },
  Canceled: {
    name: 'Canceled',
    code: 'CANCELED',
    badge: 'badge badge-dark',
  },
  Canceled_Pending: {
    name: 'Canceled_Pending',
    code: 'CANCELED_PENDING',
    badge: 'badge badge-secondary',
  },
};

export const targetPriorityEnum = {
  highPriority: {
    name: i18n.t('TargetPriority.highPriority'),
    code: 'HIGH_PRIORITY',
  },
  midPriority: {
    name: i18n.t('TargetPriority.midPriority'),
    code: 'MID_PRIORITY',
  },
  lowPriority: {
    name: i18n.t('TargetPriority.lowPriority'),
    code: 'LOW_PRIORITY',
  },
  noPriority: {
    name: i18n.t('TargetPriority.noPriority'),
    code: 'NO_PRIORITY',
  },
  toBeDefined: {
    name: i18n.t('TargetPriority.toBeDefined'),
    code: 'TO_BE_DEFINED',
  },
};

export const timeSpanEnum = {
  today: {
    name: i18n.t('TimeSpanEnum.today'),
    code: 'today',
  },
  thisWeek: {
    name: i18n.t('TimeSpanEnum.thisWeek'),
    code: 'thisWeek',
  },
  lastWeek: {
    name: i18n.t('TimeSpanEnum.lastWeek'),
    code: 'lastWeek',
  },
  thisMonth: {
    name: i18n.t('TimeSpanEnum.thisMonth'),
    code: 'thisMonth',
  },
  lastMonth: {
    name: i18n.t('TimeSpanEnum.lastMonth'),
    code: 'lastMonth',
  },
  thisYear: {
    name: i18n.t('TimeSpanEnum.thisYear'),
    code: 'thisYear',
  },
  overall: {
    name: i18n.t('TimeSpanEnum.overall'),
    code: 'overall',
  },
};

/**
 * Service name enum to refer what service the notification is being categorized and to identify the menu tree route
 */
export const serviceNamesEnum = {
  crm: {
    name: i18n.t('index.crm'),
    code: 'CRM',
  },
  salesFunnel: {
    name: i18n.t('index.sales'),
    code: 'Sales Funnel',
  },
  projects: {
    name: i18n.t('index.projects'),
    code: 'Projects',
  },
  resourcePlanner: {
    name: i18n.t('index.resourcePlanner'),
    code: 'Resource Planner',
  },
  employees: {
    name: i18n.t('index.employees'),
    code: 'Employees',
  },
  all: {
    name: i18n.t('index.all'),
    code: 'All',
  },
};

/**
 * Send notification via
 */
export const sendNotificationViaEnum = {
  email: {
    name: 'Email',
    code: 'Via Email',
  },
  crmTool: {
    name: 'CRM Tool',
    code: 'Via CRM Tool',
  },
};

/**
 * Contact Alumnus
 */
export const contactAlumnusEnum = {
  none: {
    label: i18n.t('ContactAlumnus.none'),
    value: 'NONE',
    color: 'grey',
    code: 'N',
  },
  former: {
    label: i18n.t('ContactAlumnus.former'),
    value: 'FORMER',
    color: 'blue',
    code: 'F',
  },
  confirmed: {
    label: i18n.t('ContactAlumnus.confirmed'),
    value: 'CONFIRMED',
    color: 'green',
    code: 'A',
  },
};

/**
 * GDPR status
 */
export const gdprStatusEnum = {
  none: {
    name: i18n.t('GdprOverview.none'),
    code: 'NONE',
    description: i18n.t('GdprOverview.noneDescription'),
  },
  requested: {
    name: i18n.t('GdprOverview.requested'),
    code: 'REQUESTED',
    description: i18n.t('GdprOverview.requestedDescription'),
  },
  accepted: {
    name: i18n.t('GdprOverview.accepted'),
    code: 'ACCEPTED',
    description: i18n.t('GdprOverview.acceptedDescription'),
  },
  declined: {
    name: i18n.t('GdprOverview.declined'),
    code: 'DECLINED',
    description: i18n.t('GdprOverview.declinedDescription'),
  },
  legacy: {
    name: i18n.t('GdprOverview.legacy'),
    code: 'LEGACY',
    description: i18n.t('GdprOverview.legacyDescription'),
  },
  bounced: {
    name: i18n.t('GdprOverview.bounced'),
    code: 'BOUNCED',
    description: i18n.t('GdprOverview.bouncedDescription'),
  },
};

/**
 * Contact information status
 */
export const contactInformationStatusEnum = {
  deliverable: {
    name: i18n.t('CampaignManager.DELIVERABLE'),
    code: 'DELIVERABLE',
  },
  undeliverable: {
    name: i18n.t('CampaignManager.UNDELIVERABLE'),
    code: 'UNDELIVERABLE',
  },
  risky: {
    name: i18n.t('CampaignManager.RISKY'),
    code: 'RISKY',
  },
  unknown: {
    name: i18n.t('CampaignManager.UNKNOWN'),
    code: 'UNKNOWN',
  },
  guessed: {
    name: i18n.t('CampaignManager.GUESSED'),
    code: 'GUESSED',
  },
};

export const roleEnum = {
  role: {
    code: 'ROLE',
    name: 'role',
  },
  roleGroup: {
    code: 'ROLEGROUP',
    name: 'roleGroup',
  },
};

export const employmentTypeEnum = {
  cng: {
    name: 'CNG',
    code: 'CNG',
    careerLevels: [
      'Analyst',
      'Trainee Consultant',
      'Associate Consultant',
      'Consultant',
      'Senior Consultant',
      'Principal Consultant',
      'Managing Consultant',
      'Senior Managing Consultant',
    ],
  },
  bdd: {
    name: 'BDD',
    code: 'BDD',
    careerLevels: [
      'Trainee Business Consultant',
      'Associate Business Consultant',
      'Business Consultant',
      'Senior Business Consultant',
    ],
  },
  amd: {
    name: 'AMD',
    code: 'AMD',
    careerLevels: [
      'Apprentice',
      'Trainee Assistant',
      'Team Assistant',
      'Managing Assistant',
      'Senior Managing Assistant',
    ],
  },
  mgm: {
    name: 'MGM',
    code: 'MGM',
    careerLevels: [
      'Associate Manager',
      'Manager',
      'Senior Manager',
      'Associate Director',
      'Director',
      'Managing Director',
    ],
  },
  vc: {
    name: 'VC',
    code: 'VC',
    careerLevels: ['Visiting Consultant'],
  },
};

export const callStatusTypeEnum = {
  appointments: {
    code: 'APPOINTMENTS',
    name: 'Appointments',
    color: '#32CD32',
  },
  reached: {
    code: 'REACHED',
    name: 'Reached',
    color: '#87ceeb',
  },
  calls: {
    code: 'CALLS',
    name: 'Calls',
    color: '#808080',
  },
};

export const callStatisticsRateEnum = {
  appointmentRate: {
    name: 'Appointment Rate',
    color: '#2E8BC0',
  },
  callingSuccess: {
    name: 'Calling Success',
    color: '#FFA500',
  },
};

export const projectTypesEnum = {
  customerProject: {
    name: 'Customer Project',
    code: 'CUSTOMER_PROJECT',
  },
  investmentProject: {
    name: 'Customer Project (unbilled)',
    code: 'INVESTMENT_PROJECT',
  },
  internalProject: {
    name: 'Internal Project',
    code: 'INTERNAL_PROJECT',
  },
  internalRole: {
    name: 'Internal Role',
    code: 'INTERNAL_ROLE',
  },
  nonProject: {
    name: 'Non-Project',
    code: 'NON_PROJECT',
  },
};

export const entitiesLink = {
  customer: {
    code: 'customer',
    link: '/customer/customers/details/',
  },
  customerAccount: {
    code: 'customerAccount',
    link: '/customer/accounts/details/',
  },
  customerSite: {
    code: 'customerSite',
    link: '/customer/customer-sites/details/',
  },
  contactPerson: {
    code: 'contactPerson',
    link: '/customer/my-contacts/profile/',
  },
  employee: {
    code: 'employee',
    link: '/employees/employee-list/employee-detail/',
  },
};

export const fieldOfBusinessAndDepartmentStateEnum = {
  active: {
    value: 'ACTIVE',
    label: 'Active',
  },
  planned: {
    value: 'PLANNED',
    label: 'Planned',
  },
  archived: {
    value: 'ARCHIVED',
    label: 'Archived',
  },
};

export const departmentStatusEnum = {
  active: {
    code: 'ACTIVE',
    name: i18n.t('DepartmentState.active'),
  },
  planned: {
    code: 'PLANNED',
    name: i18n.t('DepartmentState.planned'),
  },
  archived: {
    code: 'ARCHIVED',
    name: i18n.t('DepartmentState.archived'),
  },
};

export const monthFilterOptionsEnum = {
  thisMonth: {
    code: 'THIS_MONTH',
    name: i18n.t('TimeSpanEnum.thisMonth'),
  },
  lastMonth: {
    code: 'LAST_MONTH',
    name: i18n.t('TimeSpanEnum.lastMonth'),
  },
  nextMonth: {
    code: 'NEXT_MONTH',
    name: i18n.t('TimeSpanEnum.nextMonth'),
  },
};

export const approvalRequestEnum = [
  {
    value: Approval.PENDING,
    label: i18n.t('ApprovalsList.pending'),
  },
  {
    value: Approval.APPROVED,
    label: i18n.t('ApprovalsList.approved'),
  },
  {
    value: Approval.REJECTED,
    label: i18n.t('ApprovalsList.rejected'),
  },
];

export const approvalRequestDropdownEnum = [
  {
    value: Approval.PENDING,
    label: i18n.t('ApprovalsList.pending'),
  },
  {
    value: 'HISTORIC',
    label: i18n.t('ApprovalsList.historic'),
  },
];
export const projectListTypeEnum = {
  projectOverview: {
    code: 'PROJECT_OVERVIEW',
    link: '/projects/overview',
  },
  customerProject: {
    code: 'CUSTOMER_PROJECT',
    link: '/projects/customer',
  },
  ownedProject: {
    code: 'OWNED_PROJECT',
    link: '/projects/owned',
  },
};

// Dropdown options for payment goal
export const paymentGoalOptions = [
  {
    value: 30,
    label: '30 days',
  },
  {
    value: 45,
    label: '45 days',
  },
  {
    value: 60,
    label: '60 days',
  },
  {
    value: 90,
    label: '90 days',
  },
  {
    value: -1,
    label: 'Others',
  },
];
