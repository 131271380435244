import { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { Button, Card, CardBody, Container, Input, Label } from 'reactstrap';

import axios from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import { clickHandler } from '../../../utils/helpers/click';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IRole } from '../../../utils/types/modelTypes';

interface IProps extends IWithModalsProps {
  roles: IRole[];
  onSave: (group: IRole) => void;
  onCancel: () => void;
}
const RoleAddForm: React.FC<IProps> = ({
  roles,
  onSave,
  modalOkHandler,
  modalErrorHandler,
  onCancel,
}) => {
  const t = (keyName: string) => i18n.t(`Roles.${keyName}`);

  const [role, setRole] = useState('');
  const [description, setDescription] = useState('');

  const submitEvent = async () => {
    if (role.length === 0) {
      modalOkHandler(t('warning'), t('nameRequired'));
      return;
    }
    if (roles.some((item) => item.role === role.trim())) {
      modalOkHandler(t('error'), t('duplicateEntry'));
      return;
    }

    const payload = {
      role: role.trim(),
      description: description.trim(),
    };

    await axios.employee
      .save('roles', payload)
      .then((response: AxiosResponse<IRole>) => {
        onSave(response.data);
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToSaveRole'), error);
      });

    onCancel();
  };

  return (
    <Container fluid>
      <Card>
        <CardBody>
          <InputFormLabel isRequired text={t('role')} />
          <Input
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setRole(event.target.value);
            }}
          />
          <Label>{t('description')}:</Label>
          <Input
            type="textarea"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(event.target.value);
            }}
          />
          <br />
          <Button
            color="primary"
            size="m"
            className="float-end"
            onClick={(event: MouseEvent) => clickHandler(event, submitEvent)}
            disabled={role?.trim() === ''}
          >
            {t('save')}
          </Button>
        </CardBody>
      </Card>
    </Container>
  );
};

export default withModals(RoleAddForm);
