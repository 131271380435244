import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  CardBody,
  CardHeader,
  Label,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import DynamicTable from '../../../components/tables/DynamicTable';
import '../../../assets/css/collapseLabel.css';
import { getDateFormat } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';

/*
 * Displays project workloads in a list.
 * Props:
 *  - employeeWorkloads = data to be displayed
 */
class ResourceWorkloadCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFormat: getDateFormat(),
      showWorkloadInHours: true,
    };
  }

  t(keyName) {
    return i18n.t('ResourceProfileCard.' + keyName);
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  componentDidMount(prevProps) {
    if (prevProps !== this.props) {
      this.render();
    }
  }

  handleWorkloadAppearanceChange = () => {
    this.setState({
      showWorkloadInHours: !this.state.showWorkloadInHours,
    });
  };

  workloadAppearance = (workloadHours) => {
    if (this.state.showWorkloadInHours) {
      return workloadHours + this.t('hours');
    } else {
      var percentWorkload =
        Math.ceil(
          (workloadHours / this.state.employee?.workhoursPerDay) * 100
        ) + ' %';
      return percentWorkload;
    }
  };
  // Get the duration of project in business days, returns an integer
  getProjectDuration(startDate, endDate) {
    const DAYS_IN_A_WEEK = 7;
    const BUSINESS_DAYS = 5;
    const MILISECONDS_IN_A_DAY = 86400000;
    const SUNDAY_DAY_VALUE = 0;
    const FRIDAY_DAY_VALUE = 5;
    const SATURDAY_DAY_VALUE = 6;
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    var elapsed, daysBeforeFirstSunday, daysAfterLastSunday;
    var ifThen = function (dayA, dayB, dayC) {
      return dayA === dayB ? dayC : dayA;
    };
    elapsed = endDate - startDate;
    elapsed /= MILISECONDS_IN_A_DAY;

    daysBeforeFirstSunday =
      (DAYS_IN_A_WEEK - startDate.getDay()) % DAYS_IN_A_WEEK;
    daysAfterLastSunday = endDate.getDay();

    elapsed -= daysBeforeFirstSunday + daysAfterLastSunday;
    elapsed = (elapsed / DAYS_IN_A_WEEK) * BUSINESS_DAYS;

    // -1 is used to check if the selected day is Sunday.
    elapsed +=
      ifThen(daysBeforeFirstSunday - 1, -1, SUNDAY_DAY_VALUE) +
      ifThen(daysAfterLastSunday, SATURDAY_DAY_VALUE, FRIDAY_DAY_VALUE);

    return Math.ceil(elapsed);
  }

  prepareTableData = (employeeWorkloads) => {
    // Re create appointment details entry.
    const newTableData = [];
    if (Array.isArray(employeeWorkloads) && employeeWorkloads.length > 0) {
      employeeWorkloads.forEach((employeeWorkload) => {
        const entry = {
          project: employeeWorkload?.project,
          projectId: employeeWorkload?.projectId,
          workloadState: i18n.exists(
            `WorkloadState.${employeeWorkload?.workload?.workloadState}`
          )
            ? i18n.t(
                `WorkloadState.${employeeWorkload?.workload?.workloadState}`
              )
            : employeeWorkload?.workload?.workloadState,
          startDate: moment(employeeWorkload?.workload?.startDate).format(
            this.state.dateFormat
          ),
          endDate: moment(employeeWorkload?.workload?.endDate).format(
            this.state.dateFormat
          ),
          //   workload: employeeWorkload?.workload?.workload,
          workload: this.workloadAppearance(
            employeeWorkload?.workload.workload
          ),
        };
        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  render() {
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('project'),
        accessor: 'project',
        show: 'true',
        filterkey: 'projectTitle',
        showsearch: 'true',
        link: `/projects/overview/project-details/`,
        linkAccessor: 'projectId',
      },
      {
        type: 'data',
        header: this.t('start'),
        accessor: 'startDate',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('end'),
        accessor: 'endDate',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('workloads'),
        accessor: 'workload',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('state'),
        accessor: 'workloadState',
        show: 'true',
      },
    ];

    return (
      <Fragment>
        <CardHeader>
          <Row>
            <Button color="outline" id="resourceWorkload">
              <FontAwesomeIcon icon={faBars} />
            </Button>
            <h2 className="collapse-label">{this.t('projectWorkloads')}</h2>
          </Row>
        </CardHeader>
        <UncontrolledCollapse toggler="#resourceWorkload">
          <CardBody>
            <td width="20px"></td>
            {/* Comment for now. Showing of workload in percent doesn't work correctly
            <td>
              <Input
                type="checkbox"
                onChange={this.handleWorkloadAppearanceChange}
                checked={this.state.showWorkloadInHours}
              />
            </td> 
            <td>{this.t("showWorkloadInHours")}</td> */}
            {Array.isArray(this.props.employeeWorkloads) &&
            this.props.employeeWorkloads.length > 0 ? (
              <DynamicTable
                data={this.prepareTableData(this.props.employeeWorkloads)}
                columns={preparedColumns}
              />
            ) : (
              <Label className="text-center" tag="h4">
                {this.t('noWorkloadAvailable')}
              </Label>
            )}
          </CardBody>
        </UncontrolledCollapse>
      </Fragment>
    );
  }
}

export default withRouter(ResourceWorkloadCard);
