import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import i18n from '../../i18n';

const Page404 = () => (
  <div className="text-center">
    <h1 className="display-1 font-weight-bold">404</h1>
    <p className="h1">{i18n.t('Page404.pageNotFound')}</p>
    <p className="h2 font-weight-normal mt-3 mb-4">
      {i18n.t('Page404.pageMightHaveBeenRemoved')}
    </p>
    <Link to="/">
      <Button color="primary" size="lg">
        {i18n.t('Page404.returnToWebsite')}
      </Button>
    </Link>
  </div>
);

export default Page404;
