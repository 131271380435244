import React, { ChangeEvent, useContext, useState } from 'react';
import { Form, FormGroup, Button, CardBody, Label, Input } from 'reactstrap';
import Datetime from 'react-datetime';
import moment, { Moment } from 'moment';
import { AxiosError } from 'axios';

import { OfferDetailsContext } from '../../../pages/projects/OfferModal/OfferDetailsProvider';
import i18n from '../../../i18n';
import {
  IOfferDetails,
  IOfferListItem,
  IOfferVersion,
} from '../../../utils/types/responseTypes';
import InputFormLabel from '../InputFormLabel';
import { saveOfferVersion, sendOffer } from '../../../services/api/offer';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { generateTitle } from '../../../utils/helpers/icon';
import { buttonTitleEnum } from '../../../utils/enums/enum';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import {
  IFileAttachment,
  IFileAttachmentListItem,
} from '../../../utils/types/modelTypes';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import FileAttachmentSelectionCard from './FileAttachmentSelectionCard';
import { OfferState } from '../../../utils/enums/offer';
import {
  DATE_FORMAT,
  acceptedSentDate,
  translate,
  createFormValueFromOfferDetails,
  createOfferListItemFromDetails,
} from '../../../pages/projects/OfferModal/offerHelper';
import AddOrUpdateFileAttachmentForm from '../AddOrUpdateFileAttachmentForm';
import { convertToFileAttachmentListItem } from '../../../utils/helpers/fileAttachment';
import FileAttachmentList from './FileAttachmentList';

interface IProps extends IWithModalsProps {
  offerFromOption?: IOfferListItem;
  onClose: () => void;
}

const AddOfferVersion = ({
  modalFormHandler,
  modalErrorHandler,
  toggleModalForm,
  onClose,
  offerFromOption,
}: IProps) => {
  const {
    offer,
    setOffer,
    offersFromForms,
    pendingOfferFromForms,
    setProjectOptions,
    setProjectDetails,
    isProjectOption,
    setFormValues,
  } = useContext(OfferDetailsContext);

  const [offerVersion, setOfferVersion] = useState<IOfferVersion>({
    version: offerFromOption?.offerVersion ?? offer.offerVersion,
    date: offerFromOption?.date ?? offer.date,
    description: offerFromOption ? '' : offer.detail,
    offerId: offerFromOption?.id ?? offer.id,
  } as IOfferVersion);

  const [fileAttachments, setFileAttachments] = useState(
    offer.offerFileAttachments
  );

  const onChoosingAttachment = (file: IFileAttachment) => {
    setFileAttachments([
      ...fileAttachments,
      convertToFileAttachmentListItem(file),
    ]);
    toggleModalForm();
  };

  const onChoosingMultipleAttachment = (files: IFileAttachmentListItem[]) => {
    setFileAttachments([...fileAttachments, ...files]);
    toggleModalForm();
  };

  const addNewAttachment = () => {
    modalFormHandler(
      generateTitle(
        buttonTitleEnum.UPDATE.code,
        translate('newFileAttachment')
      ),
      <AddOrUpdateFileAttachmentForm
        objectType={OBJECT_TYPE_ENUM.offer.code}
        objectId={offer.id ?? null}
        onSave={onChoosingAttachment}
        onCancel={() => toggleModalForm()}
      />,
      'l'
    );
  };

  const addFromOtherAttachments = () => {
    modalFormHandler(
      generateTitle(
        BUTTON_TITLE_ENUM.UPLOAD.code,
        translate('selectFromOffer')
      ),
      <FileAttachmentSelectionCard
        attachedFiles={fileAttachments}
        onSubmit={onChoosingMultipleAttachment}
        onCancel={() => toggleModalForm()}
      />,
      'l'
    );
  };

  const deleteFileAttachment = (file: IFileAttachmentListItem) => {
    const updatedFileAttachments = fileAttachments.filter(
      ({ id }) => id !== file.id
    );
    setFileAttachments(updatedFileAttachments);
  };

  const handleChangeDate = (date: Moment | string) => {
    setOfferVersion({ ...offerVersion, date: date.toString() });
    if (!offerFromOption) {
      setOffer({ ...offer, offerSent: moment(date).format(DATE_FORMAT) });
    }
  };

  const handleUpdateOfferToOfferList = (offer: IOfferDetails) => {
    if (isProjectOption && setProjectOptions) {
      const updatedOfferList = offersFromForms.map((indivOffer) =>
        indivOffer.id === offer.id
          ? { ...indivOffer, ...createOfferListItemFromDetails(offer) }
          : indivOffer
      );
      setProjectOptions((values) => ({
        ...values,
        offers: updatedOfferList,
      }));
    } else if (pendingOfferFromForms && setProjectDetails) {
      const updatedPendingOffersList = pendingOfferFromForms.map((indivOffer) =>
        indivOffer.id === offer.id
          ? { ...indivOffer, ...createOfferListItemFromDetails(offer) }
          : indivOffer
      );
      setProjectDetails((values) => ({
        ...values,
        pendingOffers: updatedPendingOffersList,
      }));
    }
  };

  const handleSendingOffer = () => {
    // Change State of offer to sent
    const sentOffer = offerFromOption
      ? {
          ...(offerFromOption as IOfferDetails),
          offerState: OfferState.SENT,
          offerFileAttachment: fileAttachments,
        }
      : {
          ...offer,
          offerState: OfferState.SENT,
          offerFileAttachment: fileAttachments,
        };
    // Update OfferState to Sent and set the offer sent to current date
    sendOffer(sentOffer)
      .then(({ data: response }) => {
        if (!offerFromOption) {
          setOffer(response);
          setFormValues(createFormValueFromOfferDetails(response));
          // Update offer list
          handleUpdateOfferToOfferList(response);
        }
      })
      .catch((error: AxiosError) =>
        modalErrorHandler(translate('failedToSendOffer'), error.message)
      );

    saveOfferVersion(offerVersion)
      .then(() => onClose())
      .catch((error: AxiosError) =>
        modalErrorHandler(translate('failedToSaveOfferVersion'), error.message)
      );
  };

  return (
    <Form>
      <FormGroup>
        <CardBody>
          <div className="input-label">
            <InputFormLabel text={translate('dateRequired')} isRequired />
          </div>
          <div>
            <Datetime
              data-testid="add-offer-version-date-input"
              dateFormat={DATE_FORMAT}
              timeFormat={false}
              isValidDate={(date: Moment) =>
                acceptedSentDate(offerVersion.date, date)
              }
              onChange={handleChangeDate}
              value={
                offerVersion.date
                  ? moment(offerVersion.date).format(DATE_FORMAT)
                  : moment().startOf('day').format(DATE_FORMAT)
              }
              closeOnSelect
              locale={i18n.language}
            />
          </div>
          <div>
            <Label className="input-label">{translate('attachments')}</Label>
            <span>
              <Button
                color="primary"
                size="sm"
                onClick={() => addNewAttachment()}
              >
                {translate('new')}
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => addFromOtherAttachments()}
              >
                {translate('existing')}
              </Button>
            </span>
            <FileAttachmentList
              attachments={fileAttachments}
              onDelete={deleteFileAttachment}
            />
          </div>
          <div>
            <Label className="input-label">{translate('description')}</Label>
            <Input
              type="textarea"
              style={{ minHeight: '120px' }}
              value={offerVersion.description ?? ''}
              placeholder={translate('optional')}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setOfferVersion((values) => ({
                  ...values,
                  description: event.target.value,
                }));
              }}
            />
          </div>
        </CardBody>
        <Button color="primary" onClick={handleSendingOffer}>
          {translate('send')}
        </Button>
      </FormGroup>
    </Form>
  );
};

export default withModals(AddOfferVersion);
