import React, { Dispatch, SetStateAction } from 'react';

import ResponsiblesCard from '../../../components/cards/ResponsiblesCard';
import { ProjectState } from '../../../utils/enums/project';
import { IDropdownOption, IProjectInvolvedResponsible } from '../../../utils/types/commonTypes';
import type {
  ProjectOptionDetailsForm,
} from './ProjectOptionModal';

interface IProps {
  responsibleList: IDropdownOption<number>[];
  formValues: ProjectOptionDetailsForm;
  setFormValues: Dispatch<SetStateAction<ProjectOptionDetailsForm>>;
}

/**
 * Responsibles Card in Project Option Modal -
 * Displays project responsible and involved responsibles
 */
const ProjectOptionResponsibles = ({
  responsibleList,
  formValues: { state, responsible, involvedResponsibles },
  setFormValues,
}: IProps) => (
  <ResponsiblesCard
    responsibleList={responsibleList}
    responsible={responsible}
    involvedResponsibles={involvedResponsibles}
    setResponsible={(value: IDropdownOption<number>) => {
      setFormValues((formValues) => ({
        ...formValues,
        responsible: value,
      }));
    }}
    setInvolvedResponsibles={(
      involvedResponsibles: IProjectInvolvedResponsible[]
    ) => {
      setFormValues((formValues) => ({
        ...formValues,
        involvedResponsibles,
      }));
    }}
    isRequired={state !== ProjectState.ORDERED}
    isDisabled={state === ProjectState.LOST || state === ProjectState.ORDERED}
  />
);

export default ProjectOptionResponsibles;
