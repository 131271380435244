import moment from 'moment';
import { dateFormat } from './utils/constants';
import { getLatestItem } from './utils/helpers/GenericHelper';

//The enum for the status of notifications
export const NotificationEnum = {
  New: 'NEW',
  Read: 'READ',
  Significant: 'SIGNIFICANT',
  Removed: 'REMOVED',
};

//filters out all notifications that aren't new or significant and date is not on current date
export function UnreadNotifications(Notifications) {
  if (Notifications?.length === 0 || Notifications === undefined) {
    return [];
  }
  let newNotifications = Notifications.filter((notification) => {
    let latestStatus = getLatestItem(notification.notificationStatuses);
    return (
      latestStatus.status === NotificationEnum.New ||
      latestStatus.status === NotificationEnum.Significant ||
      moment().format(dateFormat) < moment(latestStatus.date).format(dateFormat)
    );
  });
  return newNotifications;
}

//Sorts notification from latest to oldest
export function SortNotifications(Notifications) {
  if (Notifications?.length === 0 || Notifications === undefined) {
    return [];
  }
  let sortedNotifications = Notifications.sort(
    (a, b) =>
      new Date(getLatestItem(b.notificationStatuses).date) -
      new Date(getLatestItem(a.notificationStatuses).date)
  );
  let newNotifs = sortedNotifications.filter((notif) =>
    moment(getLatestItem(notif.notificationStatuses).date).isSameOrBefore(
      moment().add(2, 'hours')
    )
  );
  return newNotifs;
}
