import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Col, Form, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';

import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { ContactInfoType } from '../../../utils/enums/contact';
import { generateTitle } from '../../../utils/helpers/icon';
import { RootState } from '../../../redux/store';
import {
  IDropdownOption,
  IDropdownOptionInvolved,
  IObjectContactInformation,
  IObjectInvolvedResponsible,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import { ICountryState } from '../../../utils/types/modelTypes';
import CountryDropdown from '../../../components/dropdowns/CountryDropdown';
import CountryStateDropdown from '../../../components/dropdowns/CountryStateDropdown';
import { updateCustomerListItem } from '../../../redux/customerListSlice';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import InputFormLabel from '../../../components/form/InputFormLabel';
import {
  ICustomerDropdownItem,
  ICustomerSiteDetail,
} from '../../../utils/types/responseTypes';
import { customerStateEnum } from '../../../utils/enums/enum';
import MultipleSectorSelect from '../../../components/dropdowns/MultipleSectorSelect';
import {
  getCustomerSiteDetails,
  saveCustomerSiteDetails,
  saveNewCustomerSiteDetails,
} from '../../../services/api/customerSite';
import ResponsibleContactPersonEditor from '../../../components/form/ResponsibleContactPersonForm/ResponsibleContactPersonEditor';
import { ContactInformationStatus } from '../../../utils/enums/contactPerson';
import MultipleResponsibleDropdown from '../../../components/form/MultipleResponsibleDropdown';
import {
  ICollapsibleCustomerSiteEditedDetails,
  customerSiteDetailsState,
  customerSiteFields,
  distributeFieldValues,
  emptyObjectNameAndId,
  getEditedFieldValues,
  translateCustomerSite,
} from './customerDetailsHelper';
import {
  isEmpty,
  isInvolvedResponsiblesNotModified,
  isStringArraySimilar,
} from '../../../utils/helpers/array';
import GeneralInfoForm from '../../../components/form/GeneralInfoForm/GeneralInfoForm';
import CustomerDropdown from '../../../components/dropdowns/CustomerDropdown';
import { targetPrioritiesDropdownOptions } from '../../../utils/helpers/dropdown';

interface IProps extends PropsFromRedux, IWithModalsProps {
  customerSiteId: number;
  existingCustomer: IObjectNameAndId;
  customerSectors: IObjectNameAndId[];
  employeesWithPermission: IDropdownOption<number>[] | undefined;
  onSave: (newCustomerSite: ICustomerSiteDetail) => void;
  onCancel: () => void;
}

const AddOrUpdateCustomerSiteModal = ({
  modalErrorHandler,
  modalConfirmHandler,
  existingCustomer,
  customerSiteId,
  customerSectors,
  currentUser,
  employeesWithPermission,
  onSave,
  onCancel,
}: IProps) => {
  const [customerSiteDetails, setCustomerSiteDetails] =
    useState<ICollapsibleCustomerSiteEditedDetails>(
      customerSiteDetailsState(existingCustomer, customerSectors, currentUser)
    );
  const [originalDetails, setOriginalDetails] =
    useState<ICollapsibleCustomerSiteEditedDetails>(
      customerSiteDetailsState(existingCustomer, customerSectors, currentUser)
    );
  const [filteredInvolvedIds, setFilteredInvolvedIds] = useState<number[]>();
  const [fieldsWithUpdates, setFieldsWithUpdates] = useState<string[]>([]);

  const {
    state,
    location,
    country,
    sectors,
    contactInformation,
    contactPersons,
    name,
    street,
    zipCode,
    countryState,
    customerSiteType,
    targetPriority,
    responsible,
    involvedEmployees,
    customer,
  } = customerSiteDetails;

  const fetchCustomerSite = async (customerSiteId: number) => {
    try {
      const { data } = await getCustomerSiteDetails(customerSiteId.toString());
      setCustomerSiteDetails(distributeFieldValues(data));
      setOriginalDetails(distributeFieldValues(data));
    } catch (error) {
      modalErrorHandler(
        translateCustomerSite('failedToRetrieveCustomerSiteDetails'),
        error
      );
    }
  };

  const updateFieldsWithChanges = (fieldValue: string, noChange: boolean) => {
    setFieldsWithUpdates((prevFields) => {
      if (noChange) {
        return prevFields.filter((item) => item !== fieldValue);
      }
      if (prevFields.includes(fieldValue)) {
        return prevFields;
      }
      return [...prevFields, fieldValue];
    });
  };

  const handleSaveCustomerSiteDetails = async ({
    id,
    ...detailToSave
  }: ICollapsibleCustomerSiteEditedDetails) => {
    if (id === 0) {
      await saveNewCustomerSiteDetails({
        ...detailToSave,
        customer,
        targetPriority,
        responsible,
        sectors,
      } as unknown as ICustomerSiteDetail)
        .then(({ data }) => {
          onSave(data);
        })
        .catch((error) =>
          modalErrorHandler(
            translateCustomerSite('failedToSaveCustomerSiteDetails'),
            error
          )
        );
    } else {
      await saveCustomerSiteDetails({
        id,
        ...detailToSave,
      } as unknown as ICustomerSiteDetail)
        .then(({ data }) => {
          onSave(data);
        })
        .catch((error) =>
          modalErrorHandler(
            translateCustomerSite('failedToUpdateCustomerSite'),
            error
          )
        );
    }
  };

  const handleSaveWithSectors = async (
    detailToSave: ICollapsibleCustomerSiteEditedDetails
  ) => {
    const unlinkedSectors = detailToSave.sectors.filter(
      (newSector) =>
        !originalDetails.sectors.find(
          (oldSector: IObjectNameAndId) => newSector.id === oldSector.id
        )
    );
    if (isEmpty(unlinkedSectors)) {
      await handleSaveCustomerSiteDetails(detailToSave);
    } else {
      modalConfirmHandler(
        translateCustomerSite('warning'),
        <>
          {translateCustomerSite('warningCauseDialog')}
          {customer.name}:
          <br />
          <strong>
            {unlinkedSectors.map((sector) => sector.name).join(', ')}
          </strong>
          <br />
          {translateCustomerSite('warningEffectDialog')}
        </>,
        async () => {
          await handleSaveCustomerSiteDetails(detailToSave);
        }
      );
    }
  };

  const addUpdateCustomerSiteDetails = async () => {
    const detailToSave = {
      ...getEditedFieldValues(fieldsWithUpdates, customerSiteDetails),
    };
    detailToSave.id = customerSiteId;
    if (fieldsWithUpdates.includes(customerSiteFields.contactInformation)) {
      detailToSave.contactInformation = detailToSave.contactInformation.filter(
        (contactInfo: IObjectContactInformation) => contactInfo.info !== ''
      );
    }
    if (fieldsWithUpdates.includes(customerSiteFields.sectors)) {
      await handleSaveWithSectors(detailToSave);
    } else {
      await handleSaveCustomerSiteDetails(detailToSave);
    }
  };

  const handleTextBoxChange = (value: string, stateKey: string) => {
    setCustomerSiteDetails({
      ...customerSiteDetails,
      [stateKey]: value,
    });
    updateFieldsWithChanges(
      stateKey,
      (originalDetails as never)[stateKey] === value
    );
  };

  const handleMultipleSectorSelectionChange = (sectors: IObjectNameAndId[]) => {
    if (sectors.length > 0) {
      setCustomerSiteDetails({ ...customerSiteDetails, sectors });
      updateFieldsWithChanges(
        customerSiteFields.sectors,
        isStringArraySimilar(
          sectors.map((sector) => sector.name),
          originalDetails.sectors.map((sector) => sector.name)
        )
      );
    }
  };

  const handleSetResponsible = (involved: IDropdownOptionInvolved[]) => {
    setCustomerSiteDetails({
      ...customerSiteDetails,
      involvedEmployees: involved.map(
        ({ responsible, responsibleRole }) =>
          ({
            ...(responsible
              ? {
                  id: responsible?.value,
                  name: responsible?.label,
                }
              : {}),
            ...(responsibleRole
              ? {
                  responsibleRole: {
                    id: responsibleRole?.value,
                    name: responsibleRole?.label,
                  },
                }
              : {}),
          } as IObjectInvolvedResponsible)
      ),
    });
    updateFieldsWithChanges(
      customerSiteFields.involvedEmployees,
      isInvolvedResponsiblesNotModified(
        involvedEmployees,
        originalDetails.involvedEmployees
      )
    );
  };

  const handleCountryChange = (country: IObjectNameAndId) => {
    const updatedCountry = {
      country,
      countryState,
    };
    if (countryState.country.id !== country.id) {
      updatedCountry.countryState = {
        ...emptyObjectNameAndId,
        country: emptyObjectNameAndId,
      };
    }
    setCustomerSiteDetails({ ...customerSiteDetails, ...updatedCountry });
    updateFieldsWithChanges(
      customerSiteFields.country,
      country.id === originalDetails.country?.id
    );
    updateFieldsWithChanges(
      customerSiteFields.countryState,
      updatedCountry.countryState.name === originalDetails.countryState?.name
    );
  };

  const handleCountryStateChange = (countryState: ICountryState) => {
    if (country.id === 0) {
      setCustomerSiteDetails({
        ...customerSiteDetails,
        countryState,
        country: countryState.country,
      });
    } else {
      setCustomerSiteDetails({
        ...customerSiteDetails,
        countryState,
      });
    }
    updateFieldsWithChanges(
      customerSiteFields.countryState,
      countryState.name === originalDetails.countryState?.name
    );
    updateFieldsWithChanges(
      customerSiteFields.country,
      countryState.country.name === originalDetails.country?.name
    );
  };

  const handleResponsibleChange = ({ value }: IDropdownOption<number>) => {
    const newResponsible = employeesWithPermission?.find(
      (r) => r.value === value
    );
    if (newResponsible) {
      setCustomerSiteDetails({
        ...customerSiteDetails,
        responsible: { id: newResponsible.value, name: newResponsible.label },
      });
      updateFieldsWithChanges(
        customerSiteFields.responsible,
        newResponsible.value === originalDetails.responsible.id
      );
    }
  };

  const handleUpdateContactInformation = (
    contactInformation: IObjectContactInformation[]
  ) => {
    setCustomerSiteDetails({
      ...customerSiteDetails,
      contactInformation,
    });
    updateFieldsWithChanges(customerSiteFields.contactInformation, false);
  };

  const handleContactPersonChange = (
    newContactPersons: IDropdownOption<number>[]
  ) => {
    setCustomerSiteDetails({
      ...customerSiteDetails,
      contactPersons: newContactPersons
        .filter((contactPerson) => contactPerson.label)
        .map((contact) => ({
          id: contact.value,
          name: contact.label,
        })),
    });
    updateFieldsWithChanges(
      customerSiteFields.contactPersons,
      isStringArraySimilar(
        newContactPersons.map((contact) => contact.label),
        contactPersons.map((contact) => contact.name)
      )
    );
  };

  const handlePhoneChange = (phone: ChangeEvent<HTMLInputElement>) => {
    if (Number(phone.target.value)) {
      const newPhoneContact: IObjectContactInformation = {
        id: 0,
        type: ContactInfoType.PHONE,
        info: phone.target.value,
        status: ContactInformationStatus.UNKNOWN,
      };
      const newContactInformations = contactInformation.filter(
        (contactInfo) => contactInfo.type !== ContactInfoType.PHONE
      );
      newContactInformations.push(newPhoneContact);

      setCustomerSiteDetails({
        ...customerSiteDetails,
        contactInformation: newContactInformations,
        customer: { id: 1601, name: 'test' },
      });
      updateFieldsWithChanges(customerSiteFields.contactInformation, false);
    }
  };

  const handleCustomerChange = ({
    id,
    name,
    sectors,
  }: ICustomerDropdownItem) => {
    setCustomerSiteDetails({
      ...customerSiteDetails,
      customer: { id, name },
      sectors,
    });
    setOriginalDetails({
      ...originalDetails,
      sectors,
    });
    updateFieldsWithChanges(
      customerSiteFields.customer,
      id === customerSiteDetails.customer.id
    );
    updateFieldsWithChanges(
      customerSiteFields.sectors,
      isStringArraySimilar(
        sectors.map((sector) => sector.name),
        originalDetails.sectors.map((sector) => sector.name)
      )
    );
  };

  useEffect(() => {
    setFilteredInvolvedIds([
      responsible.id,
      ...involvedEmployees.map((involved) => involved.id),
    ]);
  }, [responsible, involvedEmployees]);

  useEffect(() => {
    if (customerSiteId) {
      fetchCustomerSite(customerSiteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form>
      <Row>
        <Col>
          <InputFormLabel isRequired text={translateCustomerSite('customer')} />
          <CustomerDropdown
            customer={customer}
            onCustomerSelect={handleCustomerChange}
            isDisabled={existingCustomer.id !== 0}
          />
        </Col>
        <Col>
          <InputFormLabel
            isRequired
            text={translateCustomerSite('compNameAtSite')}
          />
          <Input
            bsSize="lg"
            type="string"
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              handleTextBoxChange(value, 'name')
            }
            value={name}
            data-testid="name-input"
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <InputFormLabel
            isRequired={false}
            text={translateCustomerSite('state')}
          />
          <Input
            bsSize="lg"
            type="string"
            value={state !== '' ? state : customerStateEnum.none.code}
            readOnly
            disabled
          />
        </Col>
        <Col>
          <InputFormLabel
            isRequired={false}
            text={translateCustomerSite('street')}
          />
          <Input
            bsSize="lg"
            type="string"
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              handleTextBoxChange(value, 'street')
            }
            data-testid="street-input"
            value={street}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <InputFormLabel isRequired text={translateCustomerSite('location')} />
          <Input
            bsSize="lg"
            type="string"
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              handleTextBoxChange(value, 'location')
            }
            value={location}
            data-testid="location-input"
          />
        </Col>
        <Col>
          <InputFormLabel
            isRequired={false}
            text={translateCustomerSite('zipCode')}
          />
          <Input
            bsSize="lg"
            type="string"
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              handleTextBoxChange(value, 'zipCode')
            }
            data-testid="zipcode-input"
            value={zipCode}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <InputFormLabel
            isRequired={false}
            text={translateCustomerSite('country')}
          />
          <CountryDropdown
            onChange={handleCountryChange}
            countryId={country?.id ?? 0}
          />
        </Col>
        <Col>
          <InputFormLabel
            isRequired={false}
            text={translateCustomerSite('countryState')}
          />
          <CountryStateDropdown
            countryStateId={countryState?.id ?? 0}
            countryId={country?.id ?? 0}
            onChange={handleCountryStateChange}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <InputFormLabel isRequired text={translateCustomerSite('sector')} />
          <MultipleSectorSelect
            onChange={handleMultipleSectorSelectionChange}
            sectorIds={sectors.map((sector) => sector.id)}
          />
        </Col>
        <Col>
          <InputFormLabel
            isRequired={false}
            text={translateCustomerSite('siteType')}
          />
          <Input
            bsSize="lg"
            type="string"
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              handleTextBoxChange(value, 'customerSiteType')
            }
            data-testid="customer-site-type-input"
            value={customerSiteType}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <InputFormLabel
            isRequired={false}
            text={translateCustomerSite('phone')}
          />
          <Input
            bsSize="lg"
            type="number"
            onChange={handlePhoneChange}
            data-testid="phone-input"
            value={
              contactInformation.find(
                (contact) => contact.type === ContactInfoType.PHONE
              )?.info ?? null
            }
          />
        </Col>
        <Col>
          <InputFormLabel
            isRequired
            text={translateCustomerSite('targetPriority')}
          />
          <div data-testid="targetpriority-div">
            <Select
              options={targetPrioritiesDropdownOptions}
              value={targetPrioritiesDropdownOptions.find(
                ({ value }) => value === targetPriority
              )}
              onChange={({ value }: IDropdownOption) =>
                handleTextBoxChange(value, customerSiteFields.targetPriority)
              }
            />
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Row>
            <Col>
              <Label style={{ fontWeight: 'bold', paddingBottom: '8px' }}>
                {translateCustomerSite('contactPersons')}
              </Label>
            </Col>
          </Row>
          {customer.id !== 0 && (
            <Row>
              <Col>
                <ResponsibleContactPersonEditor
                  responsibleContactPersons={contactPersons}
                  customerId={customer?.id}
                  onChange={handleContactPersonChange}
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col>
          <Row>
            <Col>
              <Label style={{ fontWeight: 'bold' }}>
                {translateCustomerSite('responsible')}
              </Label>
            </Col>
          </Row>
          <div data-testid="responsible-div">
            <Select
              options={employeesWithPermission?.filter(
                (employee) =>
                  employee.value !== responsible.id &&
                  !involvedEmployees.find(
                    (involved) => employee.value === involved.id
                  )
              )}
              value={{
                value: responsible.id,
                label: responsible.name,
              }}
              onChange={handleResponsibleChange}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <Label style={{ fontWeight: 'bold' }}>
                {translateCustomerSite('generalContactInformation')}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <GeneralInfoForm
                contactInformations={contactInformation}
                onChange={handleUpdateContactInformation}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Label style={{ fontWeight: 'bold' }}>
                {translateCustomerSite('involved')}
              </Label>
            </Col>
          </Row>
          <MultipleResponsibleDropdown
            responsibles={involvedEmployees.map(
              ({ id, name, responsibleRole }) =>
                ({
                  ...(id && name
                    ? {
                        responsible: { label: name, value: id },
                      }
                    : {}),
                  ...(responsibleRole
                    ? {
                        responsibleRole: {
                          label: responsibleRole.name,
                          value: responsibleRole.id,
                        },
                      }
                    : {}),
                } as {
                  responsible: IDropdownOption<number>;
                  responsibleRole: IDropdownOption<number>;
                })
            )}
            setResponsibles={handleSetResponsible}
            responsibleOptions={
              employeesWithPermission?.filter(
                (employee) => !filteredInvolvedIds?.includes(employee.value)
              ) as IDropdownOption<number>[]
            }
          />
        </Col>
      </Row>
      <br />
      <br />
      <Button
        color="primary"
        onClick={addUpdateCustomerSiteDetails}
        disabled={location === '' || name === '' || !customer.id}
        data-testid="save-button"
      >
        {customerSiteId
          ? generateTitle(
              BUTTON_TITLE_ENUM.SAVE.code,
              translateCustomerSite('update')
            )
          : generateTitle(
              BUTTON_TITLE_ENUM.SAVE.code,
              translateCustomerSite('save')
            )}
      </Button>{' '}
      <Button color="primary" onClick={onCancel}>
        {translateCustomerSite('cancel')}
      </Button>
    </Form>
  );
};

const mapStateToProps = (store: RootState) => ({
  currentUser: {
    id: store.account.employeeDetails.id,
    name: `${store.account.employeeDetails.firstname} ${store.account.employeeDetails.name}`,
  } as IObjectNameAndId,
});

const mapDispatchToProps = {
  updateCustomerListItem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(AddOrUpdateCustomerSiteModal));
