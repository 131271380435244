import i18n from '../../i18n';

export enum ContactInfoType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
  XING = 'XING',
  LINKEDIN = 'LINKEDIN',
  WEBSITE = 'WEBSITE',
}

/**
 * @deprecated Use ContactInfoType enum
 */
export const CONTACT_INFO_ENUM = {
  phone: { code: 'PHONE' },
  email: { code: 'EMAIL' },
  mobile: { code: 'MOBILE' },
  xing: { code: 'XING' },
  linkedIn: { code: 'LINKEDIN' },
  website: { code: 'WEBSITE' },
};

/**
 * Description/Name for Contact Status
 */
export enum ContactStatusEnum {
  COLD = 'NO_CONTACT_WITH_INV',
  RECALL = 'PHONE_CONTACT_ESTABLISHED',
  PROSPECT = 'PROSPECTION_AHEAD',
  MEETING = 'PERSONAL_CONTACT_ESTABLISHED',
  FIRST_PROJECT = 'CURRENTLY_FIRST_PROJECT_TOGETHER',
  ONE_PROJECT = 'ONE_PROJECT_IN_THE_PAST',
  ANOTHER_PROJECT = 'ANOTHER_PROJECT_TOGETHER',
  MULTIPLE_PROJECT = 'MULTIPLE_PROJECTS_IN_THE_PAST',
  DISQUALIFIED = 'DIQUALIFIED',
  UNDEFINED = 'UNDEFINED',
}

export const ContactStatus = {
  COLD: {
    code: '01',
    name: ContactStatusEnum.COLD,
    color: 'blue',
  },
  RECALL: {
    code: '02',
    name: ContactStatusEnum.RECALL,
    color: 'blue',
  },
  PROSPECT: {
    code: '03',
    name: ContactStatusEnum.PROSPECT,
    color: 'blue',
  },
  MEETING: {
    code: '04',
    name: ContactStatusEnum.MEETING,
    color: 'blue',
  },
  FIRST_PROJECT: {
    code: 'P',
    name: ContactStatusEnum.FIRST_PROJECT,
    color: 'green',
  },
  ONE_PROJECT: {
    code: 'X',
    name: ContactStatusEnum.ONE_PROJECT,
    color: 'green',
  },
  ANOTHER_PROJECT: {
    code: 'Q',
    name: ContactStatusEnum.ANOTHER_PROJECT,
    color: 'green',
  },
  MULTIPLE_PROJECT: {
    code: 'M',
    name: ContactStatusEnum.MULTIPLE_PROJECT,
    color: 'green',
  },
  DISQUALIFIED: {
    code: '00',
    name: ContactStatusEnum.DISQUALIFIED,
    color: 'grey',
  },
  UNDEFINED: {
    code: 'U',
    name: ContactStatusEnum.UNDEFINED,
    color: 'grey',
  },
};

/**
 * @deprecated Use ContactStatus
 */
export const CONTACT_STATUS = {
  cold: {
    code: '01',
    name: i18n.t('ContactStatus.No contact with INV'),
    color: 'blue',
  },
  recall: {
    code: '02',
    name: i18n.t('ContactStatus.Phone contact established'),
    color: 'blue',
  },
  prospect: {
    code: '03',
    name: i18n.t('ContactStatus.Prospection ahead'),
    color: 'blue',
  },
  meeting: {
    code: '04',
    name: i18n.t('ContactStatus.Personal contact established'),
    color: 'blue',
  },
  firstProject: {
    code: 'P',
    name: i18n.t('ContactStatus.Currently first project together'),
    color: 'green',
  },
  oneProject: {
    code: 'X',
    name: i18n.t('ContactStatus.One project in the past'),
    color: 'green',
  },
  anotherProject: {
    code: 'Q',
    name: i18n.t('ContactStatus.Another project together'),
    color: 'green',
  },
  multipleProject: {
    code: 'M',
    name: i18n.t('ContactStatus.Multiple project in the past'),
    color: 'green',
  },
  disqualified: {
    code: '00',
    name: i18n.t('ContactStatus.Disqualified'),
    color: 'grey',
  },
  undefined: {
    code: 'U',
    name: i18n.t('ContactStatus.Undefined'),
    color: 'grey',
  },
};

/**
 * Contact Alumnus
 */
export const CONTACT_ALUMNUS_ENUM = {
  none: {
    label: i18n.t('ContactAlumnus.none'),
    value: 'NONE',
    color: 'grey',
    code: 'N',
  },
  former: {
    label: i18n.t('ContactAlumnus.former'),
    value: 'FORMER',
    color: 'blue',
    code: 'F',
  },
  confirmed: {
    label: i18n.t('ContactAlumnus.confirmed'),
    value: 'CONFIRMED',
    color: 'green',
    code: 'A',
  },
};

export enum GDPRStatus {
  NONE = 'NONE',
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  LEGACY = 'LEGACY',
  BOUNCED = 'BOUNCED',
}

/**
 * @deprecated Use GDPRStatus
 */
export const GDPR_STATUS_ENUM = {
  none: {
    name: i18n.t('GdprOverview.none'),
    code: 'NONE',
    description: i18n.t('GdprOverview.noneDescription'),
  },
  requested: {
    name: i18n.t('GdprOverview.requested'),
    code: 'REQUESTED',
    description: i18n.t('GdprOverview.requestedDescription'),
  },
  accepted: {
    name: i18n.t('GdprOverview.accepted'),
    code: 'ACCEPTED',
    description: i18n.t('GdprOverview.acceptedDescription'),
  },
  declined: {
    name: i18n.t('GdprOverview.declined'),
    code: 'DECLINED',
    description: i18n.t('GdprOverview.declinedDescription'),
  },
  legacy: {
    name: i18n.t('GdprOverview.legacy'),
    code: 'LEGACY',
    description: i18n.t('GdprOverview.legacyDescription'),
  },
  bounced: {
    name: i18n.t('GdprOverview.bounced'),
    code: 'BOUNCED',
    description: i18n.t('GdprOverview.bouncedDescription'),
  },
};

export enum NewsletterStatus {
  ACTIVE = 'ACTIVE',
  BOUNCED = 'BOUNCED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}
