import React from 'react';
import Select from 'react-select';

import { Currency } from '../../utils/enums/currency';
import i18n from '../../i18n';
import { enumValuesToDropdownOptions } from '../../utils/helpers/dropdown';
import { IDropdownOption } from '../../utils/types/commonTypes';

interface IProps {
  currency: string | null;
  isClearable: boolean;
  onChange: (currency: string) => void;
}

/**
 * Dropdown that has all Currency as options.\
 * props:
 *  currency, onChange, isClearable
 */
const CurrencyDropdown = ({ currency, onChange, isClearable }: IProps) => {
  const t = (keyName: string) => i18n.t(`CurrencyDropdownInput.${keyName}`);

  return (
    <div>
      <Select
        aria-label="currency-dropdown"
        onChange={(selectedOption: IDropdownOption | null) =>
          onChange(selectedOption?.value || '')
        }
        options={enumValuesToDropdownOptions(Object.values(Currency))}
        value={enumValuesToDropdownOptions(Object.values(Currency)).find(
          ({ value }) => currency === value
        )}
        isClearable={isClearable}
        placeholder={t('selectCurrency')}
      />
    </div>
  );
};

export default CurrencyDropdown;
