import { t } from 'i18next';

export const ORDER_INVOICE_SENDING = {
  email: { code: 'Email', name: t('email') },
};

export enum ProjectIdModificationMessage {
  INVALID_NUMBER = 'invalidNumber',
  STILL_IN_USE_PROJECT_ID = 'stillInUseProjectId',
}
