import i18n from '../../i18n';

export enum ProjectEmployeeState {
  PLANNED = 'PLANNED',
  CONFIRMED = 'CONFIRMED',
}

export enum ProjectEmployeeWorkloadState {
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  PLANNED = 'PLANNED',
  ASSIGNED = 'ASSIGNED',
  BOOKED = 'BOOKED',
  RESERVED = 'RESERVED',
  BOOKED_AND_OPEN = 'BOOKED_AND_OPEN',
  CANCELLED = 'CANCELLED',
}

export enum ProjectState {
  VAGUE = 'VAGUE',
  DEFINED = 'DEFINED',
  PROPOSAL_AHEAD = 'PROPOSAL_AHEAD',
  PROPOSAL_TECHNICAL_GO = 'PROPOSAL_TECHNICAL_GO',
  PITCHING = 'PITCHING',
  PLANNING = 'PLANNING',
  ESTIMATING = 'ESTIMATING',
  ALIGNING = 'ALIGNING',
  OFFER_SENT = 'OFFER_SENT',
  LOST = 'LOST',
  ORDERED = 'ORDERED',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
}

/**
 * @deprecated Use ProjectState enum
 */
export const PROJECT_STATE_ENUM = {
  vague: {
    name: i18n.t('ProjectStates.VAGUE'),
    code: 'VAGUE',
  },
  defined: {
    name: i18n.t('ProjectStates.DEFINED'),
    code: 'DEFINED',
  },
  proposalAhead: {
    name: i18n.t('ProjectStates.PROPOSAL_AHEAD'),
    code: 'PROPOSAL_AHEAD',
  },
  proposalTechnicalGo: {
    name: i18n.t('ProjectStates.PROPOSAL_TECHNICAL_GO'),
    code: 'PROPOSAL_TECHNICAL_GO',
  },
  pitching: {
    name: i18n.t('ProjectStates.PITCHING'),
    code: 'PITCHING',
  },
  planning: {
    name: i18n.t('ProjectStates.PLANNING'),
    code: 'PLANNING',
  },
  estimating: {
    name: i18n.t('ProjectStates.ESTIMATING'),
    code: 'ESTIMATING',
  },
  aligning: {
    name: i18n.t('ProjectStates.ALIGNING'),
    code: 'ALIGNING',
  },
  offerSent: {
    name: i18n.t('ProjectStates.OFFER_SENT'),
    code: 'OFFER_SENT',
  },
  lost: {
    name: i18n.t('ProjectStates.LOST'),
    code: 'LOST',
  },
  ordered: {
    name: i18n.t('ProjectStates.ORDERED'),
    code: 'ORDERED',
  },
  active: {
    name: i18n.t('ProjectStates.ACTIVE'),
    code: 'ACTIVE',
  },
  paused: {
    name: i18n.t('ProjectStates.PAUSED'),
    code: 'PAUSED',
  },
  closed: {
    name: i18n.t('ProjectStates.CLOSED'),
    code: 'CLOSED',
  },
  cancelled: {
    name: i18n.t('ProjectStates.CANCELLED'),
    code: 'CANCELLED',
  },
};

export enum Activity {
  DO_FOLLOW_UP_MEETING = 'DO_FOLLOW_UP_MEETING',
  CALL_CONTACT = 'CALL_CONTACT',
  SEND_EO = 'SEND_EO',
  ARRANGE_PROPOSAL = 'ARRANGE_PROPOSAL',
  PREPARE_PROPOSAL = 'PREPARE_PROPOSAL',
  DO_PROPOSAL = 'DO_PROPOSAL',
  CALL_FOR_FEEDBACK = 'CALL_FOR_FEEDBACK',
  DO_PITCH = 'DO_PITCH',
  CALL_FOR_SPEC = 'CALL_FOR_SPEC',
  FINALIZE_ESTIMATION = 'FINALIZE_ESTIMATION',
  MODIFY_ESTIMATION = 'MODIFY_ESTIMATION',
  CREATE_OFFER = 'CREATE_OFFER',
  SEND_PURCHASE_DOCS = 'SEND_PURCHASE_DOCS',
  DEFINE_ESTIMATION_TEAM = 'DEFINE_ESTIMATION_TEAM',
  SEND_OFFER = 'SEND_OFFER',
  DEFINE_PROJECT_MANAGER = 'DEFINE_PROJECT_MANAGER',
  INITIALIZE_KICK_OFF = 'INITIALIZE_KICK_OFF',
  INITIALIZE_INTERNAL_KICK_OFF = 'INITIALIZE_INTERNAL_KICK_OFF',
  INITIALIZE_STATUS_MEETING = 'INITIALIZE_STATUS_MEETING',
  PREPARE_EXTENSION = 'PREPARE_EXTENSION',
  INITIALIZE_CLOSING_MEETING = 'INITIALIZE_CLOSING_MEETING',
  DO_LESSONS_LEARNT = 'DO_LESSONS_LEARNT',
}

/**
 * @deprecated Use Activity enum
 */
export const PROJECT_OPTION_ACTIVITIES = {
  doFollowUpMeeting: {
    code: 'DO_FOLLOW_UP_MEETING',
    name: i18n.t('ProjectActivities.DO_FOLLOW_UP_MEETING'),
  },
  callContact: {
    code: 'CALL_CONTACT',
    name: i18n.t('ProjectActivities.CALL_CONTACT'),
  },
  sendEO: {
    code: 'SEND_EO',
    name: i18n.t('ProjectActivities.SEND_EO'),
  },
  arrangeProposal: {
    code: 'ARRANGE_PROPOSAL',
    name: i18n.t('ProjectActivities.ARRANGE_PROPOSAL'),
  },
  prepareProposal: {
    code: 'PREPARE_PROPOSAL',
    name: i18n.t('ProjectActivities.PREPARE_PROPOSAL'),
  },
  doProposal: {
    code: 'DO_PROPOSAL',
    name: i18n.t('ProjectActivities.DO_PROPOSAL'),
  },
  callForFeedback: {
    code: 'CALL_FOR_FEEDBACK',
    name: i18n.t('ProjectActivities.CALL_FOR_FEEDBACK'),
  },
  doPitch: {
    code: 'DO_PITCH',
    name: i18n.t('ProjectActivities.DO_PITCH'),
  },
  callForSpec: {
    code: 'CALL_FOR_SPEC',
    name: i18n.t('ProjectActivities.CALL_FOR_SPEC'),
  },
  finalizeEstimation: {
    code: 'FINALIZE_ESTIMATION',
    name: i18n.t('ProjectActivities.FINALIZE_ESTIMATION'),
  },
  modifyEstimation: {
    code: 'MODIFY_ESTIMATION',
    name: i18n.t('ProjectActivities.MODIFY_ESTIMATION'),
  },
  createOffer: {
    code: 'CREATE_OFFER',
    name: i18n.t('ProjectActivities.CREATE_OFFER'),
  },
  sendPurchaseDocs: {
    code: 'SEND_PURCHASE_DOCS',
    name: i18n.t('ProjectActivities.SEND_PURCHASE_DOCS'),
  },
  defineEstimationTeam: {
    code: 'DEFINE_ESTIMATION_TEAM',
    name: i18n.t('ProjectActivities.DEFINE_ESTIMATION_TEAM'),
  },
  sendOffer: {
    code: 'SEND_OFFER',
    name: i18n.t('ProjectActivities.SEND_OFFER'),
  },
  defineProjectManager: {
    code: 'DEFINE_PROJECT_MANAGER',
    name: i18n.t('ProjectActivities.DEFINE_PROJECT_MANAGER'),
  },
  initializeKickOff: {
    code: 'INITIALIZE_KICK_OFF',
    name: i18n.t('ProjectActivities.INITIALIZE_KICK_OFF'),
  },
  initializeInternalKickOff: {
    code: 'INITIALIZE_INTERNAL_KICK_OFF',
    name: i18n.t('ProjectActivities.INITIALIZE_INTERNAL_KICK_OFF'),
  },
  initializeStatusMeeting: {
    code: 'INITIALIZE_STATUS_MEETING',
    name: i18n.t('ProjectActivities.INITIALIZE_STATUS_MEETING'),
  },
  prepareExtension: {
    code: 'PREPARE_EXTENSION',
    name: i18n.t('ProjectActivities.PREPARE_EXTENSION'),
  },
  initializeClosingMeeting: {
    code: 'INITIALIZE_CLOSING_MEETING',
    name: i18n.t('ProjectActivities.INITIALIZE_CLOSING_MEETING'),
  },
  doLessonsLearnt: {
    code: 'DO_LESSONS_LEARNT',
    name: i18n.t('ProjectActivities.DO_LESSONS_LEARNT'),
  },
};

export enum ProjectType {
  CUSTOMER_PROJECT = 'CUSTOMER_PROJECT',
  INVESTMENT_PROJECT = 'INVESTMENT_PROJECT',
  INTERNAL_PROJECT = 'INTERNAL_PROJECT',
  INTERNAL_ROLE = 'INTERNAL_ROLE',
  NON_PROJECT = 'NON_PROJECT',
}

/**
 * @deprecated Use ProjectType enum
 */
export const PROJECT_TYPES_ENUM = {
  customerProject: {
    name: 'Customer Project',
    code: 'CUSTOMER_PROJECT',
  },
  investmentProject: {
    name: 'Customer Project (unbilled)',
    code: 'INVESTMENT_PROJECT',
  },
  internalProject: {
    name: 'Internal Project',
    code: 'INTERNAL_PROJECT',
  },
  internalRole: {
    name: 'Internal Role',
    code: 'INTERNAL_ROLE',
  },
  nonProject: {
    name: 'Non-Project',
    code: 'NON_PROJECT',
  },
};

export enum ProjectEmployeeRole {
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
  MEMBER = 'MEMBER',
}

export enum AcquisitionType {
  PITCH = 'PITCH',
  PROPOSAL = 'PROPOSAL',
  SPECIFICATION = 'SPECIFICATION',
  UNDEFINED = 'UNDEFINED',
}

/**
 * @deprecated Use AcquisitionType enum
 */
export const ACQUISITION_TYPE_ENUM = {
  undefined: {
    name: i18n.t('Acquisitions.UNDEFINED'),
    code: 'UNDEFINED',
  },
  proposal: {
    name: i18n.t('Acquisitions.PROPOSAL'),
    code: 'PROPOSAL',
  },
  pitch: {
    name: i18n.t('Acquisitions.PITCH'),
    code: 'PITCH',
  },
  specification: {
    name: i18n.t('Acquisitions.SPECIFICATION'),
    code: 'SPECIFICATION',
  },
};

export enum OriginOfOption {
  COLD_CALLING = 'COLD_CALLING',
  STATUS_MEETING = 'STATUS_MEETING',
  CS_INFO_CALL = 'CS_INFO_CALL',
  INTERNAL_CUSTOMER_MARKETING = 'INTERNAL_CUSTOMER_MARKETING',
  GOOD_FORMER_COLLAB = 'GOOD_FORMER_COLLAB',
  STRATEGY_PURCHASE = 'STRATEGY_PURCHASE',
  ANU_REQUEST = 'ANU_REQUEST',
  CUSTOMER_REQUEST = 'CUSTOMER_REQUEST',
  CAMPAIGN = 'CAMPAIGN',
  PERSONAL_NETWORK = 'PERSONAL_NETWORK',
  CONFERENCES = 'CONFERENCES',
  ALUMNI = 'INV_ALUMNI',
  TRAINING_AT_CUSTOMER = 'TRAINING_AT_CUSTOMER',
  TENDER_RFP = 'TENDER_RFP',
}

export const REJECTION_CATEGORIES = {
  official: { code: 'CST', name: i18n.t('ProjectOptionLostModal.customer') },
  internal: { code: 'INV', name: 'INVENSITY' },
};

export enum StaffedOption {
  FULLY = 'FULLY',
  PARTLY = 'PARTLY',
  NO = 'NO',
}

/**
 * @deprecated use StaffedOption enum
 */
export const STAFFED_ENUM = {
  no_staff: {
    code: 'NO',
    name: i18n.t('StaffedEnum.NO'),
  },
  partly_staffed: {
    code: 'PARTLY',
    name: i18n.t('StaffedEnum.PARTLY'),
  },
  fully_staffed: {
    code: 'FULLY',
    name: i18n.t('StaffedEnum.FULLY'),
  },
};

export const ABSENCE_STATE_ENUM = {
  Planned: {
    name: 'Planned',
    code: 'PLANNED',
    badge: 'badge badge-primary',
  },
  Submitted: {
    name: 'Submitted',
    code: 'SUBMITTED',
    badge: 'badge badge-warning',
  },
  Retreated: {
    name: 'Retreated',
    code: 'RETREATED',
    badge: 'badge badge-info',
  },
  Declined: {
    code: 'DECLINED',
    name: 'Declined',
    badge: 'badge badge-danger',
  },
  Accepted: {
    name: 'Accepted',
    code: 'ACCEPTED',
    badge: 'badge badge-success',
  },
  Canceled: {
    name: 'Canceled',
    code: 'CANCELED',
    badge: 'badge badge-dark',
  },
  Canceled_Pending: {
    name: 'Canceled_Pending',
    code: 'CANCELED_PENDING',
    badge: 'badge badge-secondary',
  },
};

export enum ProjectDropdown {
  PROJECT = 'PROJECT',
  PROJECT_OPTION = 'PROJECT_OPTION',
  BOTH = 'BOTH',
  ALL = 'ALL',
}

export enum ProjectLocationTypeEnum {
  FULLY_REMOTE = 'FULLY_REMOTE',
  FULLY_ONSITE = 'FULLY_ONSITE',
  PARTIALY_REMOTE_ONSITE = 'PARTIALY_REMOTE_ONSITE',
  MIXED_LOCATIONS = 'MIXED_LOCATIONS',
}
