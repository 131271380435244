import React, { useEffect, useState } from 'react';
import { Card, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AxiosResponse, AxiosError } from 'axios';

import i18n from '../../../i18n';
import { IObjectNameIdDescription } from '../../../utils/types/commonTypes';
import { NO_ID } from '../../../utils/constants';
import AddRoleGroupModal from '../../../components/form/AddRoleGroupModal';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IRoleGroup, ISubject2Role } from '../../../utils/types/modelTypes';
import { getRoleGroups } from '../../../services/api/employee';
import TableCard from '../../../components/cards/TableCard';

// !-- Rename to RoleGroupCard

interface IProps extends IWithModalsProps {
  showRoleGroupAdd: boolean;
  subjectId: number;
  subjectType: string;
  roleGroups: IObjectNameIdDescription[];
}

interface IRoleGroupOptions {
  value: number;
  label: string;
  description: string;
}

/**
 * This class displays the list of role groups given by the parent
 * TODO: add functionality to add/delete role groups to a given item (can only add/delete to employee groups)
 * Do along side things that edit the following: Employee Group Detail and Role Detail
 */
const RoleGroupViewCard: React.FC<IProps> = ({
  showRoleGroupAdd,
  subjectId,
  subjectType,
  roleGroups,
  // WithModal
  modalFormHandler,
  modalErrorHandler,
  toggleModalForm,
}: IProps) => {
  const t = (keyName: string) => i18n.t(`RoleGroupCard.${keyName}`);

  const [roleGroupDisplay, setRoleGroupDisplay] = useState(roleGroups);
  const [roleOptions, setRoleOptions] = useState([] as IRoleGroupOptions[]);

  /**
   * Table Data format when using Role Groups
   */
  const prepareTableDataRoleGroup = roleGroupDisplay.map(
    ({ id, name, description }: IObjectNameIdDescription) => {
      const entry = {
        [t('roleGroup')]: (
          <Link
            to={`/settings/permissions/role-group-details/${
              id ?? (NO_ID as number)
            }`}
          >
            {name}
          </Link>
        ),
        [t('description')]: description,
      };
      return entry;
    }
  );

  /**
   * Method that adds saved role to the current list of roles on the frontend
   * @param roleGroup The subject2role Role
   */
  const onSaveRoleGroup = (roleGroup: ISubject2Role) => {
    toggleModalForm();
    const roleGroupToAdd = roleOptions.find(
      (roleGroupData) => roleGroupData.value === roleGroup.roleId
    );

    if (roleGroupToAdd === undefined) {
      return;
    }

    setRoleGroupDisplay([
      ...roleGroupDisplay,
      {
        id: roleGroupToAdd.value,
        name: roleGroupToAdd.label,
        description: roleGroupToAdd.description,
      },
    ]);
  };

  /**
   * Method that shows the Add Role Modal
   */
  const addNewRoleGroup = () => {
    // Filter out the already existing role from the options
    const roleGroupOptionsFiltered = roleOptions.filter(
      (roleGroupOption) =>
        !roleGroups.some(
          (existingRoleGroup) => existingRoleGroup.id === roleGroupOption.value
        )
    );
    modalFormHandler(
      t('addRoleGroup'),
      <AddRoleGroupModal
        subjectId={subjectId}
        subjectType={subjectType}
        roleGroupOptions={roleGroupOptionsFiltered}
        onSave={onSaveRoleGroup}
        onCancel={toggleModalForm}
      />
    );
  };

  useEffect(() => {
    getRoleGroups()
      .then(({ data }: AxiosResponse<IRoleGroup[]>) => {
        setRoleOptions(
          data.map(({ id, roleGroup, description }: IRoleGroup) => ({
            value: id ?? (NO_ID as number),
            label: roleGroup ?? '',
            description: description ?? '',
          }))
        );
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToRetrieveRoles'), error);
      });
  }, []);

  useEffect(() => {
    setRoleGroupDisplay(roleGroups);
  }, [roleGroups]);

  const headerButtons = (
    <Button
      className="float-end"
      color="primary"
      onClick={() => addNewRoleGroup()}
    >
      {t('addRole')}
    </Button>
  );

  return (
    <Container fluid>
      <Card>
        <TableCard
          tableData={prepareTableDataRoleGroup}
          noDataPlaceholder={t('roleGroupEmpty')}
          border={false}
          title={t('cardTitle')}
          {...(showRoleGroupAdd && {
            headerButtons,
          })}
          largeTitle
          leftTitle
        />
      </Card>
    </Container>
  );
};

export default withModals(RoleGroupViewCard);
