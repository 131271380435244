export const BASE_URL = '/api';

export enum Url {
  // Customer Accounts
  CUSTOMER_ACCOUNT_LIST = '/customer-account-list-items',
  CUSTOMER_ACCOUNT_DROPDOWN = '/customer-account-dropdown',
  CUSTOMER_ACCOUNT = '/customer-accounts',
  CUSTOMER_ACCOUNT_DETAILS = '/customer-account-details',
  CUSTOMER_ACCOUNT_DETAIL = '/customer-account-detail',
  // Customers
  CUSTOMER_LIST = '/customer-list-items',
  CUSTOMERS = '/customers',
  MERGE_CUSTOMERS = '/merge-customers',
  CUSTOMER_DETAILS = '/customer-details',
  CUSTOMER_DETAILS_MULTIPLE = '/customer-details/multiple',
  CUSTOMER_SECTORS = '/customer-sectors',
  CUSTOMER_WITH_SECTORS_DROPDOWN = '/customer-with-sectors-dropdown',
  CUSTOMER_ABBREVIATION = '/customer-abbreviation',
  CUSTOMER_ABBREVIATION_IS_VALID = '/customer-abbreviation/is-valid-abbreviation',
  CUSTOMERS_COUNT = '/customers/count',
  // Customer Sites
  CUSTOMER_SITES = '/customer-sites',
  CUSTOMER_SITE_FOR_CONTACT_PERSON = '/customer-sites-for-contact-person',
  CUSTOMER_SITE_LIST = '/customer-site-list-items',
  CUSTOMER_SITE_DETAILS = '/customer-site-details',
  CUSTOMER_SITE_NAME_AND_LOCATION = '/customer-site/customer-site-with-name-and-location-in-use',
  CUSTOMER_SITE_OBJECT = '/customer-site-sectors-object',
  // Departments
  DEPARTMENTS = '/departments',
  // Employees
  EMPLOYEES = '/employees',
  EMPLOYEE_DETAILS = '/employees-details',
  PERSONIO_SYNC_BY_EMAIL = '/sync-personio-data-by-email',
  EMPLOYEE_LIST = '/employee-list-items',
  EMPLOYEE_NAMES = '/employee-names',
  EMPLOYEE_GROUPS = '/employee-groups',
  RESPONSIBLE_NAMES = '/responsible-names-and-ids',
  RESPONSIBLE_ROLES = '/responsible-roles',
  EMPLOYEE_WORKING_DAYS = '/working-employee-days-count',
  // Projects
  PROJECT_APPROVAL_STATUS = '/approval-requests',
  PROJECT_COUNT = '/projects/count',
  PROJECT_DETAILS = '/project-details',
  PROJECT_EMPLOYEES = '/project-employees',
  PROJECT_WORKLOAD_BREAKDOWN = '/employee-workload-breakdown',
  PROJECT_EMPLOYEES_RATE = '/project-employee-rates',
  PROJECT_LIST = '/project-list-items',
  PROJECT_OPTION_LIST = '/project-option-list-items',
  PROJECT_DROPDOWN = '/project-dropdown',
  PROJECTS = '/projects',
  PROJECT_OPTION_DETAILS = '/project-option-details',
  ADD_PROJECT = '/add-project',
  UPDATE_PROJECT = '/update-project',
  IS_PROJECT_ID_VALID = '/is-project-id-valid',
  PROJECT_EMPLOYEES_COMPLETE = '/project-employees-complete',
  PROJECT_EMPLOYEES_COMPLEX = '/project-employees-complex',
  PROJECT_EMPLOYEES_DETAIL = '/project-employees-detail',
  CUSTOMER_EXISTING_NAME = '/customers-existing-names',
  GENERATE_PROJECT_ID = '/generate-projectId',
  // Responsibles
  RESPONSIBLE = '/responsibles',
  // Offer
  OFFER_DETAILS = '/offer-details',
  OLDER_OFFER_DETAILS = '/older-version-details',
  OFFERS = '/offers',
  // Offer Version
  OFFER_VERSIONS = '/offer-versions',
  // Order
  ORDER_DETAILS = '/orders',
  // Campaign Manager
  COUNTRIES = '/countries',
  COUNTRY_STATES = '/country-states',
  SECTORS = '/sectors',
  CONTACT_PERSON_TOPICS = '/contact-person-topics-settings',
  EXPORT_CAMPAIGN_MANAGER_CONTACT_PEOPLE = '/campaign-manager-export-contact-people',
  CAMPAIGN_MANAGER_SEND_EMAIL = '/campaign-manager-send-emails',
  // Contact Persons
  CONTACT_PEOPLE = '/contact-people',
  CONTACT_PERSON_DETAILS = '/contact-person-details',
  EXTENDED_CONTACT_PEOPLE = '/extended-contact-people',
  EXTENDED_CONTACT_PEOPLE_SITES = '/extended-contact-people-sites',
  CONTACT_PEOPLE_ADDRESS = '/contact-people/address',
  EXTENDED_CONTACT_PEOPLE_COUNT_CALL_OVERDUE = '/extended-contact-people/count/call-overdue',
  EXTENDED_CONTACT_PEOPLE_COUNT_CALL = '/extended-contact-people/count/call',
  CAMPAIGN_MANAGER_CONTACT_PEOPLE = '/campaign-manager-contact-people',
  CAMPAIGN_MANAGER_SEND_EMAILS = '/campaign-manager-send-emails',
  CAMPAIGN_MANAGER_EXPORT_CONTACT_PEOPLE = '/campaign-manager-export-contact-people/{lang}',
  EXPORT_CONTACT_PEOPLE_TO_OUTLOOK = '/export-contact-people-to-outlook',
  EXPORT_ALL_CONTACTS_TO_OUTLOOK = '/export-all-contacts-to-outlook',
  STATISTICS_CONTACT_PEOPLE_UPDATE_CREATION = '/statistics-contact-people-update-creation',
  TARGET_PRIO_CALL_STAT_BY_SECTORID_SELECTEDTIME = '/target-prioritization-call-statistics/{sectorIds}/{selectedTime}',
  CONTACT_PERSON_DROPDOWN = '/contact-person-dropdown',
  CONTACT_PERSON_LIST = '/contact-person-list-items',
  GDPR_CONTACT_PERSON_LIST = '/gdpr-contact-person-list-items',
  SAVE_INFO_GDPR = '/save-info-gdpr',
  CONTACT_PERSON_ASSIGNMENTS = '/contact-person-assignments',
  CONTACT_INFORMATIONS = '/contact-informations',
  EMAIL = '/email',
  REMOVE_CONTACT_PERSON = '/remove-contact-person',
  TITLES = '/titles',
  CONTACT_PERSON_ROLES = '/contact-person-roles-unpaged',
  CONTACT_PERSON_ATTITUDES = '/attitudes',
  EXISTING_CONTACT_PERSON_LIST_ITEMS = '/existing-contact-person-list-items',
  RESET_CONTACT_PERSON = '/reset-contact-person-details',
  SET_FLAG_CONTACT_PERSON = '/set-call-flag',
  ADD_CONTACT_PEOPLE_TO_CUSTOMER = '/add-contact-people-to-customer',
  RESPONSIBLE_CONTACT_PEOPLE = '/responsible-contact-people',
  // Calling
  CALL_STATISTICS = '/call-statistics',
  // Contact Statistics
  CONTACT_PERSON_STATISTICS_LIST = '/contact-person-statistics-list',
  CONTACT_PERSON_STATISTICS = '/contact-person-statistics',
  // Roles, Role groups and Employee Groups
  ROLES = '/roles',
  ROLE_GROUPS = '/role-groups',
  SUBJECT_2_ROLE = '/subject-2-roles',
  // User To Do Activities
  USER_TO_DO_ACTIVITIES = '/user-to-do-activities',
  CURRENT_USER_ACTIVITIES = '/current-user-activities',
  // Responsible Role
  // Activity
  ACTIVITY = '/activities',
  // File Attachment
  FILE_ATTACHMENT = '/file-attachments',
  // Approval Requests
  APPROVAL_REQUEST_LIST = '/approval-requests-list-items',
  APPROVAL_REQUEST_PATCH = '/approval-request-update',
  APPROVAL_REQUEST_CREATE = '/approval-request-create',
  // Permissions
  CAN_WRITE = '/permissions/can-write',
  PERMISSIONS = '/permissions',
  // Calendar
  CALENDAR = '/calendars',
  CALENDAR_DAYS = '/calendar-days',
  PERSONIO_ABSENCES = '/sync-absences-data',
  ABSENCES_OF_CURRENT_USER = '/absences-of-current-user',
  ABSENCES = '/absences',
  SKILL_OPTIONS = '/skill-options',
  // Login
  LOGIN = '/oauth2/authorization/oidc',
  // Resource Planner\
  RESOURCE = '/project-employee-resource',
  UTILIZATION = '/project-employee-utilization',
  WORKLOAD_UTILIZATION = '/employee-workload',
  UTILIZATION_ACTIVITY = '/utilization-activity',
}
