import React from 'react';
import { Table, Button } from 'reactstrap';

import i18n from '../../../i18n';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { deleteContactInformation } from '../../../services/api/contactPerson';
import { isEmpty } from '../../../utils/helpers/array';
import { generateTitle } from '../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { add } from '../../../utils/constants';
import { IObjectContactInformation } from '../../../utils/types/commonTypes';
import { ContactInformationStatus } from '../../../utils/enums/contactPerson';
import GeneralInfoFormRow from './GeneralInfoFormRow';

interface IProps extends IWithModalsProps {
  contactInformations: IObjectContactInformation[];
  onChange: (contactInformation: IObjectContactInformation[]) => void;
}

const t = (keyName: string) => i18n.t(`GeneralInfosFormInput.${keyName}`);

const GeneralInfoForm = ({
  // WithModals
  modalDeleteHandler,
  modalErrorHandler,
  contactInformations,
  onChange,
}: IProps) => {
  const handleDeleteContactInformation = (
    { id }: IObjectContactInformation,
    index: number
  ) => {
    const updatedContactInformation = [...contactInformations];
    updatedContactInformation.splice(index, 1);
    if (!id) {
      onChange(updatedContactInformation);
    } else {
      modalDeleteHandler(
        t('deleteContactInfo'),
        t('deleteContactInfoConfirm'),
        async () => {
          try {
            await deleteContactInformation(id);
            onChange(updatedContactInformation);
          } catch (error) {
            modalErrorHandler(t('deleteContactInfoFailed'), error);
          }
        }
      );
    }
  };

  const handleAddContactInformation = () => {
    onChange([
      ...contactInformations,
      {
        id: 0,
        type: '',
        info: '',
        status: ContactInformationStatus.UNKNOWN,
      },
    ]);
  };

  const handleUpdateContactInformation = (
    contactInformation: IObjectContactInformation,
    index: number
  ) => {
    const updatedContactInformation = [...contactInformations];
    updatedContactInformation[index] = contactInformation;
    onChange(updatedContactInformation);
  };

  return (
    <div data-testid="contactinformation-div">
      {!isEmpty(contactInformations) && (
        <Table size="sm" data-testid="general-info-form-table">
          <thead>
            <tr>
              <th className="generalInfos-th">{t('info')}</th>
              <th className="generalInfos-th">{t('type')}</th>
              <th className="generalInfos-th">{t('delete')}</th>
            </tr>
          </thead>
          <tbody data-testid="general-info-form-row">
            {contactInformations.map((contactInformation, index) => (
              <GeneralInfoFormRow
                key={index}
                contactInformation={contactInformation}
                index={index}
                onChange={handleUpdateContactInformation}
                onDelete={handleDeleteContactInformation}
              />
            ))}
          </tbody>
        </Table>
      )}
      <Button
        color="primary"
        size="sm"
        onClick={handleAddContactInformation}
        data-testid="general-info-form-button-add"
      >
        {generateTitle(BUTTON_TITLE_ENUM.ADD.code, t(add.toLowerCase()))}
      </Button>
    </div>
  );
};

export default withModals(GeneralInfoForm);
