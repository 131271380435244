export enum EmployeeState {
  TBD = 'TBD',
  SIGNED = 'SIGNED',
  ACTIVE = 'ACTIVE',
  FORMER = 'FORMER',
}

export enum EmployeeReleaseStatus {
  RELEASED = 'RELEASED',
  NOT_RELEASED = 'NOT_RELEASED',
}

export enum EmployeeAbroad {
  DESIRED = 'DESIRED',
  NEUTRAL = 'NEUTRAL',
  DENIAL = 'DENIAL',
  UNKNOWN = 'UNKNOWN',
}

export enum EmployeeMobility {
  FULL = 'FULL',
  PARTLY = 'PARTLY',
  REGIONAL_FULL = 'REGIONAL_FULL',
  REGIONAL_PARTLY = 'REGIONAL_PARTLY',
  NO_MOBILITY = 'NO_MOBILITY',
  UNKNOWN = 'UNKNOWN',
}

export enum EmployeeDefence {
  NO_DEFENSE = 'NO_DEFENSE',
  LIMITATIONS = 'LIMITATIONS',
  OK = 'OK',
  UNKNOWN = 'UNKNOWN',
}

export const EMPLOYEMENT_TYPE_ENUM = {
  cng: {
    name: 'CNG',
    code: 'CNG',
    careerLevels: [
      'Analyst',
      'Trainee Consultant',
      'Associate Consultant',
      'Consultant',
      'Senior Consultant',
      'Principal Consultant',
      'Managing Consultant',
      'Senior Managing Consultant',
    ],
  },
  bdd: {
    name: 'BDD',
    code: 'BDD',
    careerLevels: [
      'Trainee Business Consultant',
      'Associate Business Consultant',
      'Business Consultant',
      'Senior Business Consultant',
    ],
  },
  amd: {
    name: 'AMD',
    code: 'AMD',
    careerLevels: [
      'Apprentice',
      'Trainee Assistant',
      'Team Assistant',
      'Managing Assistant',
      'Senior Managing Assistant',
    ],
  },
  mgm: {
    name: 'MGM',
    code: 'MGM',
    careerLevels: [
      'Associate Manager',
      'Manager',
      'Senior Manager',
      'Associate Director',
      'Director',
      'Managing Director',
    ],
  },
  vc: {
    name: 'VC',
    code: 'VC',
    careerLevels: ['Visiting Consultant'],
  },
};

// Used for the employee access panel
export const SUBJECT_TYPE = {
  employee: {
    code: 'EMPLOYEE',
    name: 'Employee',
  },
  employeeGroup: {
    code: 'EMPLOYEEGROUP',
    name: 'employeeGroup',
  },
};
