/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { isNullOrUndefinedOrEmpty } from '../../../utils/helpers/GenericHelper';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { getContactPersonTopics } from '../../../services/api/contactPerson';

interface IProps extends IWithModalsProps {
  topicIds: number[];
  onChange: (event: IDropdownOption<number>[]) => void;
  readOnly?: boolean;
}
/**
 * Displays dropdown for a contact persons topic options
 */
const ContactPersonTopicDropdown = ({
  modalErrorHandler,
  topicIds,
  onChange,
  readOnly,
}: IProps) => {
  const [dropdownOptions, setDropdownOptions] = useState<
    IDropdownOption<number>[]
  >([]);

  const fetchTopics = async () => {
    try {
      const { data: topics } = await getContactPersonTopics();
      setDropdownOptions(
        topics
          .filter((option) => option.id !== 0 || option.topic !== '')
          .map((topic) => ({
            label: topic?.topic ?? '',
            value: topic?.id ?? '',
          }))
      );
    } catch (error) {
      modalErrorHandler(
        i18n.t('ContactPersonTopicDropdown.failedToRetrieveTopics'),
        error
      );
    }
  };

  const onChangeHandler = (selectedTopics: IDropdownOption<number>[]) => {
    if (isNullOrUndefinedOrEmpty(selectedTopics)) {
      onChange([]);
      return;
    }

    const selectedTopicIds = selectedTopics.map((id) => id.value);

    const chosenTopics = dropdownOptions.filter(
      (topic) => topic.value && selectedTopicIds.includes(topic.value)
    );

    onChange(chosenTopics);
  };

  useEffect(() => {
    fetchTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      isMulti
      options={dropdownOptions}
      value={dropdownOptions?.filter((topic) =>
        topicIds?.some((id) => id === topic.value)
      )}
      onChange={onChangeHandler}
      isClearable
      isDisabled={readOnly ?? false}
      placeholder={i18n.t('ContactPersonTopicDropdown.selectTopics')}
    />
  );
};

export default withModals(ContactPersonTopicDropdown);
