/**
 * @deprecated
 */
export const CALL_STATUS_TYPE_ENUM = {
  appointments: {
    code: 'APPOINTMENTS',
    name: 'Appointments',
    color: '#32CD32',
  },
  reached: {
    code: 'REACHED',
    name: 'Reached',
    color: '#87ceeb',
  },
  calls: {
    code: 'CALLS',
    name: 'Calls',
    color: '#808080',
  },
};

/**
 * @deprecated
 */
export const CALL_STATISTICS_RATE_ENUM = {
  appointmentRate: {
    name: 'Appointment Rate',
    color: '#2E8BC0',
  },
  callingSuccess: {
    name: 'Calling Success',
    color: '#FFA500',
  },
};

export enum CallStatusType {
  APPPOINTMENTS = 'APPOINTMENTS',
  REACHED = 'REACHED',
  CALLS = 'CALLS',
}
