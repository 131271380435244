import React from 'react';
import Select from 'react-select';
import { Button, Col, Row } from 'reactstrap';

import axios from '../../services/axios/axios';
import { generateTitle } from '../../utils/helpers/icon';
import i18n from '../../i18n';
import {
  IEmployee,
  IResponsible,
  IResponsibleRole,
} from '../../utils/types/modelTypes';
import { NO_ID, noEmployeeChoice } from '../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';
import { COLLG } from '../../utils/enums/objectType';
import { IDropdownOption } from '../../utils/types/commonTypes';
import { Ownership } from '../../utils/enums/ownership';
import { sortOptionsByValue } from '../../utils/helpers/dropdown';

/**
 * A dropdown that has all the employees that can be assigned to an object.\
 * props: employees, selectedResponsibles, onChange(), onDeleteResponsible(),
 * objectType, objectId, responsibleIndex, entityIndex, responsible, removeNoneOwnership\
 * enableResponsibleRole(optional) - to enable choosing responsible role
 * serviceToUse - Sales and Employee have both responsible roles. -> Select what service to get the existing responsible-role. Sales service being default.
 */

interface IProps {
  onChange: (
    responsible: IResponsible,
    responsibleIndex?: number,
    entityIndex?: number
  ) => Promise<void> | void;
  employees: IEmployee[];
  selectedResponsibles: IResponsible[];
  onDeleteResponsible?: (
    resposibleToDelete: IResponsible,
    index?: number,
    entityIndex?: number
  ) => Promise<void> | void;
  objectType: string;
  objectId: number;
  responsibleIndex?: number;
  responsible: IResponsible;
  enableResponsibleRole?: boolean;
  removeNoneOwnership?: boolean;
  hideOwnershipField?: boolean;
  disableDelete?: boolean;
  lockInputs?: boolean;
  entityIndex?: number;
  readOnly?: boolean;
}

interface IState {
  selectedEmployee: IResponsible;
  selectedResponsibleRole?: IResponsibleRole | undefined;
  ownership: string;
  employeeChoices: { value: number; label: string }[]; // Contains all the employee choices without the already selected responsibles
  responsibleRoles: IResponsibleRole[];
  ownershipOptions: { value: string; label: string }[];
}

class ResponsibleDropdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedEmployee: this.props.responsible, // Contains the employees that will be selected
      selectedResponsibleRole: this.props.responsible.responsibleRole,
      ownership: props.responsible?.ownership
        ? props.responsible.ownership
        : Ownership.RESPONSIBLE,
      employeeChoices: [],
      responsibleRoles: [],
      ownershipOptions: [],
    };
  }

  t(keyName: string) {
    return i18n.t('ResponsibleDropdown.' + keyName);
  }

  async componentDidMount() {
    // Remove all the selected Responsible in the employee choices
    const selectedResponsibles: IResponsible[] | null =
      this.props.selectedResponsibles;
    const employees: IEmployee[] = this.props.employees;
    const employeeChoices = this.removeSelectedFromOption(
      selectedResponsibles,
      employees
    );
    let ownershipStatuses: string[] = Object.values(Ownership);
    if (this.props.removeNoneOwnership) {
      ownershipStatuses = ownershipStatuses.filter(
        (item) => item !== Ownership.NONE
      );
    }
    this.setState({
      employeeChoices: employeeChoices?.map((emp) =>
        emp.id
          ? {
              value: emp.id,
              label: `${emp.firstname} ${emp.name}`,
            }
          : noEmployeeChoice
      ),
      ownershipOptions: ownershipStatuses.map((statuses: string) => ({
        value: statuses,
        label: i18n.exists(`OwnershipModel.${statuses}`)
          ? i18n.t('OwnershipModel.ownership') +
            i18n.t(`OwnershipModel.${statuses}`)
          : statuses,
      })),
    });

    // Get configurations if enableResponsibleRole is in props
    if (this.props.enableResponsibleRole) {
      await axios.employee.get('responsible-roles').then((response) => {
        const responsibleRoles = response.data;
        if (Array.isArray(responsibleRoles)) {
          this.setState({ responsibleRoles });
        }
      });
    }
  }

  // Method to remove in the employees those that are in the selectedResponsibles
  removeSelectedFromOption(
    selectedResponsibles: IResponsible[],
    employees: IEmployee[]
  ) {
    const { responsible } = this.props;
    const employeeChoices: IEmployee[] = [];
    const { employeeId } = this.props.responsible;
    if (Array.isArray(employees)) {
      employees.forEach((employee) => {
        if (
          Array.isArray(selectedResponsibles) &&
          selectedResponsibles.length > 0
        ) {
          // If employee is not in the selectedResponsibles, include in the choices
          if (
            !selectedResponsibles?.some(
              (responsible) => responsible?.employeeId === employee?.id
            )
          ) {
            employeeChoices.push(employee);
          } else if (employeeId && employeeId === employee.id) {
            // If employee.id is not equal to responsible employeeId, include in the choices
            if (
              responsible?.employeeId &&
              responsible?.employeeId === employee.id
            ) {
              employeeChoices.push(employee);
            }
          }
        } else {
          employeeChoices.push(employee);
        }
      });
      return employeeChoices;
    }
    return employees;
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props !== prevProps) {
      const employeeChoices = this.removeSelectedFromOption(
        this.props.selectedResponsibles,
        this.props.employees
      );

      let options: { value: number ; label: string }[] = [];
      options = employeeChoices?.map((emp) => ({
        value: emp.id ?? NO_ID,
        label: `${emp.firstname} ${emp.name}`,
      }));
      this.setState({
        employeeChoices: options,
        selectedEmployee: this.props.responsible ?? null,
        selectedResponsibleRole: this.props.responsible?.responsibleRole,
        ownership: this.props.responsible?.ownership
          ? this.props.responsible.ownership
          : Ownership.RESPONSIBLE,
      });
    }
  }

  // Method to send the selected employees person as new Responsible
  changeResponsibleHandler = async (event: { value: string }) => {
    const responsible: IResponsible = {
      id: this.props.responsible.id ?? null,
      employeeId: parseInt(event.value),
      objectId: this.props.objectId,
      objectType: this.props.objectType,
      responsibleRole: this.state.selectedResponsibleRole,
      ownership: this.state.ownership,
    };
    this.setState({ selectedEmployee: responsible });
    await this.props.onChange(responsible, this.props.responsibleIndex);
  };

  /**
   * Handles changes made in the reponsibleRole
   * @param {*} value
   */
  changeResponsibleRole = (value: IDropdownOption) => {
    let responsibleRole;
    // Set responsibleRole if value is null or equal to "". Else, find the role equal
    // To the value. If not in the responsibleRoles available, create a responsibleRole
    // Object with null id
    if (value === null) {
      responsibleRole = undefined;
    } else {
      responsibleRole = this.state.responsibleRoles.find(
        (role) => role.id === Number(value.value)
      );
    }
    // Set the responsibleRole to selected employee
    const responsible = this.state.selectedEmployee;
    if (responsible) {
      responsible.responsibleRole = responsibleRole;
      responsible.ownership = this.state.ownership;
    }
    this.setState({
      selectedEmployee: responsible,
      selectedResponsibleRole: responsibleRole,
    });
    void this.props.onChange(
      responsible,
      this.props.responsibleIndex,
      this.props.entityIndex
    );
  };

  changeOwnership = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const responsible = this.state.selectedEmployee;
    if (responsible) {
      responsible.ownership = event.target.value;
      responsible.responsibleRole = this.state.selectedResponsibleRole;
    }
    this.setState({
      selectedEmployee: responsible,
      ownership: event.target.value,
    });
    await this.props.onChange(
      responsible,
      this.props.responsibleIndex,
      this.props.entityIndex
    );
  };

  render() {
    const responsibleOrInvolvedOptions = this.state.responsibleRoles?.map(
      (role) => ({
        value: role.id,
        label: role.role,
      })
    );
    const optionPlacement = 'auto';
    const employeChoices = this.state.employeeChoices;
    const selectedResponsibleRole = this.state.selectedResponsibleRole;
    const deleteButton = (
      <>
        <Col style={{ marginTop: 8 }} sm="1">
          <Button
            className="float-end p-1"
            color="link"
            onClick={async () => {
              if (this.props.onDeleteResponsible) {
                await this.props.onDeleteResponsible(
                  this.state.selectedEmployee,
                  this.props.responsibleIndex,
                  this.props.entityIndex
                );
              }
            }}
          >
            {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
          </Button>
        </Col>
      </>
    );
    const sortedEmplloyeeList = sortOptionsByValue(employeChoices);
    return (
      <>
        <Row>
          <Col
            lg={
              // eslint-disable-next-line no-nested-ternary
              this.props.enableResponsibleRole
                ? COLLG.half
                : this.props.disableDelete
                ? COLLG.full
                : COLLG.eight
            }
          >
            <Select
              size="sm"
              value={
                this.state.selectedEmployee
                  ? employeChoices.find(
                      (employee) =>
                        employee.value ===
                        this.state.selectedEmployee.employeeId
                    )
                  : noEmployeeChoice
              }
              options={sortedEmplloyeeList}
              isMulti={false}
              {...this.props}
              onChange={this.changeResponsibleHandler}
              placeholder={this.t('select')}
              isDisabled={this.props.lockInputs}
              menuPlacement={optionPlacement}
            />
          </Col>
          {this.props.enableResponsibleRole ? (
            <Col md="5">
              <Select
                value={responsibleOrInvolvedOptions.find(
                  (role) => Number(role.value) === selectedResponsibleRole?.id
                )}
                options={responsibleOrInvolvedOptions}
                onChange={this.changeResponsibleRole}
                size={'small'}
                placeholder={this.t('responsibleRole')}
                menuPlacement={optionPlacement}
              />
            </Col>
          ) : null}
          {!this.props.disableDelete &&
            this.props.hideOwnershipField &&
            deleteButton}
        </Row>
        <Row>
          {!this.props.hideOwnershipField && (
            <>
              <Col
                style={{ marginTop: 8 }}
                lg={this.props.enableResponsibleRole ? COLLG.half : COLLG.full}
              >
                <Select
                  value={this.state.ownershipOptions.find(
                    (dataEntry) => dataEntry.value === this.state.ownership
                  )}
                  options={sortOptionsByValue(
                    this.state.ownershipOptions
                  )}
                  isMulti={false}
                  {...this.props}
                  onChange={this.changeOwnership}
                  isDisabled={this.props.readOnly}
                  menuPlacement={optionPlacement}
                />
              </Col>
              {deleteButton}
            </>
          )}
        </Row>
        <br />
      </>
    );
  }
}

export default ResponsibleDropdown;
