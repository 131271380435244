import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { AxiosError } from 'axios';

import { READWRITE, notApplicable } from '../../../utils/constants';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { getEmploymentTypeByCareerLevel } from '../../../utils/helpers/GenericHelper';
import {
  IObjectInvolvedResponsible,
  IDropdownOption,
} from '../../../utils/types/commonTypes';
import { IEmployeeDetail } from '../../../utils/types/responseTypes';
import { getEmployeeNames } from '../../../services/api/employee';
import Auth from '../../../services/axios/Auth';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { EmployeeDetailFieldsProperties, edt } from './employeeDetailHelper';
import DetailsCard, { CardData } from '../../../components/cards/DetailsCard';
import MultipleResponsibleDropdown from '../../../components/form/MultipleResponsibleDropdown';
import { objectNameAndIdToDropdownOptions } from '../../../utils/helpers/dropdown';

/**
 * Display Internal Relations of the Employee Overview
 */

interface IProps extends RouteComponentProps, IWithModalsProps {
  selectedEmployee: IEmployeeDetail;
  selectedEmployeeIsFormer: boolean;
  hasPermission: boolean;
  selectEditableField: string;
  onChangeResponsible: (responsiblesNew: IObjectInvolvedResponsible[]) => void;
  onChangeSelectedField: (field: string) => void;
  employeeEdited: IEmployeeDetail;
}

const EmployeeOverviewInternalRelations = ({
  selectedEmployee,
  selectedEmployee: {
    id: selectedEmployeeId,
    mentorEmployee,
    hrResponsible,
    responsibleEmployee: {
      id: supervisorEmployeeId,
      name: supervisorEmployeeName,
    },
    involvedResponsibles: selectedEmployeeInvolvedResponsibles,
    careerLevel,
    location,
    holidayCalendar,
  },
  selectedEmployeeIsFormer,
  hasPermission,
  selectEditableField,
  onChangeSelectedField,
  onChangeResponsible,
  employeeEdited: { involvedResponsibles: editedInvolvedResponsibles },
  // WithModals
  modalErrorHandler,
}: IProps) => {
  const [involvedResponsibleOptions, setInvolvedResponsibleOptions] = useState(
    [] as IDropdownOption<number>[]
  );

  const involvedResponsibles =
    editedInvolvedResponsibles ?? selectedEmployeeInvolvedResponsibles;

  const canEdit =
    Auth.hasPermission(
      [PERMISSION_URI.employee.readWrite.uri],
      [READWRITE],
      selectedEmployeeId
    ) || hasPermission;

  /**
   * Fetch customer list, customer account list items, customer responsibles,
   * customer involved responsibles on component mount
   */
  const fetchData = () => {
    /* Get people that are allowed to be involved */
    getEmployeeNames({
      'permissionsFilter.in':
        PERMISSION_URI.employeeAsResponsible.readWrite.uri,
      'accessTypeFilter.in':
        PERMISSION_URI.employeeAsResponsible.readWrite.accessType,
    })
      .then(({ data: involvedResponsiblesOptions }) => {
        setInvolvedResponsibleOptions(
          objectNameAndIdToDropdownOptions(
            involvedResponsiblesOptions.filter(
              ({ id }) =>
                id !== supervisorEmployeeId && id !== selectedEmployeeId
            )
          )
        );
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(edt('failedToGetEmployees'), error);
      });
  };

  /**
   * Toggles the multipule responsible
   * @param toggleInvolvedField
   */
  const setMultipleResponsibles = () => {
    onChangeSelectedField(EmployeeDetailFieldsProperties.INVOLVED);
  };

  useEffect(() => {
    fetchData();
  }, [selectedEmployee]);

  // Add the initial fields
  const fields = [
    {
      label: edt('invResponsible'),
      value: supervisorEmployeeId ? (
        <Link
          to={`/employees/employee-list/employee-detail/${supervisorEmployeeId}`}
        >
          {supervisorEmployeeName}
        </Link>
      ) : (
        'N/A'
      ),
      isRequired: false,
    },
    {
      label: edt('employmentType'),
      value: getEmploymentTypeByCareerLevel(careerLevel),
      isRequired: false,
    },
  ] as CardData[];
  if (selectedEmployeeIsFormer) {
    fields.slice(0, 2);
  }

  // Adds the career level and the office
  fields.push(
    {
      label: selectedEmployeeIsFormer
        ? edt('lastCareerLevel')
        : edt('careerLevel'),
      value: careerLevel,
      isRequired: false,
    },
    {
      label: selectedEmployeeIsFormer
        ? edt('lastInvOffice')
        : edt('currentInvOffice'),
      value: location,
      isRequired: false,
    }
  );

  if (!selectedEmployeeIsFormer) {
    fields.push(
      {
        label: edt('publicHolidayRegulation'),
        value: holidayCalendar,
        isRequired: false,
      },
      {
        label: edt('hrResponsible'),
        value: hrResponsible?.id ? (
          <Link
            to={`/employees/employee-list/employee-detail/${hrResponsible.id}`}
          >
            {hrResponsible.name}
          </Link>
        ) : (
          'N/A'
        ),
        isRequired: false,
      },
      {
        label: edt('mentor'),
        value: mentorEmployee?.id ? (
          <Link
            to={`/employees/employee-list/employee-detail/${hrResponsible.id}`}
          >
            {mentorEmployee.name}
          </Link>
        ) : (
          'N/A'
        ),
        isRequired: false,
      },
      {
        label: edt('involvedUsers'),
        value: (
          <div onClick={setMultipleResponsibles}>
            {selectEditableField === EmployeeDetailFieldsProperties.INVOLVED &&
            canEdit ? (
              <MultipleResponsibleDropdown
                responsibles={involvedResponsibles.map(
                  ({ id, name, responsibleRole }) =>
                    ({
                      ...(id && name
                        ? {
                            responsible: { label: name, value: id },
                          }
                        : {}),
                      ...(responsibleRole
                        ? {
                            responsibleRole: {
                              label: responsibleRole.name,
                              value: responsibleRole.id,
                            },
                          }
                        : {}),
                    } as {
                      responsible: IDropdownOption<number>;
                      responsibleRole: IDropdownOption<number>;
                    })
                )}
                setResponsibles={(responsibles) => {
                  onChangeResponsible(
                    responsibles.map(
                      ({ responsible, responsibleRole }) =>
                        ({
                          ...(responsible
                            ? {
                                id: responsible?.value,
                                name: responsible?.label,
                              }
                            : {}),
                          ...(responsibleRole
                            ? {
                                responsibleRole: {
                                  id: responsibleRole?.value,
                                  name: responsibleRole?.label,
                                },
                              }
                            : {}),
                        } as IObjectInvolvedResponsible)
                    )
                  );
                }}
                responsibleOptions={involvedResponsibleOptions.filter(
                  ({ value }) =>
                    !involvedResponsibles.some((e) => e.id === value)
                )}
              />
            ) : (
              involvedResponsibles?.map(
                ({
                  id: responsibleId,
                  name: responsibleName,
                  responsibleRole: { name: responsibleRoleName },
                }) => (
                  <Row key={responsibleId} style={{ height: '22px' }}>
                    {responsibleId ? (
                      <Col>
                        <Link
                          to={`/employees/employee-list/employee-detail/${responsibleId}`}
                          data-employeeId={responsibleId}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          {`${responsibleName}(${
                            responsibleRoleName ?? undefined
                          })`}
                        </Link>
                      </Col>
                    ) : (
                      <span>{edt('noEmployeeFound')}</span>
                    )}
                  </Row>
                )
              )
            )}
            {selectEditableField !== EmployeeDetailFieldsProperties.INVOLVED &&
              involvedResponsibles?.length <= 0 &&
              notApplicable}
          </div>
        ),
        isRequired: false,
      }
    );
  }
  return (
    <DetailsCard title={edt('internalRelations')} fields={fields} border />
  );
};

export default withRouter(withModals(EmployeeOverviewInternalRelations));
