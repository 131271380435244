import React, { Dispatch, SetStateAction } from 'react';
import { Input, Col, Row } from 'reactstrap';
import Select from 'react-select';

import { IDropdownOption } from '../../../utils/types/commonTypes';
import {
  DurationTypeEnum,
  LocationTypeEnum,
  ProjectDetailFieldsProperties,
  t,
} from './projectDetailHelper';
import TextareaCard from '../../../components/cards/TextareaCard';
import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';
import type { ProjectDetailFormValues } from './ProjectDetailOverview';

const durationOptions = enumValuesToDropdownOptions(
  Object.values(DurationTypeEnum),
  'DurationTypeEnum'
);

const locationOptions = enumValuesToDropdownOptions(
  Object.values(LocationTypeEnum),
  'LocationTypeEnum'
);

interface IProps {
  formValues: ProjectDetailFormValues;
  setFormValues: Dispatch<SetStateAction<ProjectDetailFormValues>>;
  departmentList: IDropdownOption<number>[];
  fieldToUpdate: string;
  setFieldToUpdate: Dispatch<SetStateAction<string>>;
}

const ProjectDescription = ({
  formValues: {
    departments,
    description,
    durationType,
    locationType,
    projectSharePoint,
  },
  setFormValues,
  departmentList,
  fieldToUpdate,
  setFieldToUpdate,
}: IProps) => {
  const departmentValue = departmentList.filter(({ value }) =>
    departments.some(({ id }) => id === value)
  );

  const durationValue = durationOptions.find(
    ({ value }) => durationType === value
  );

  const locationValue = locationOptions.find(
    ({ value }) => locationType === value
  );

  const handleDepartmentChange = (departments: IDropdownOption[]) => {
    setFormValues((formValues) => ({
      ...formValues,
      departments: departments.map(({ value, label }: IDropdownOption) => ({
        id: parseInt(value, 10),
        name: label,
      })),
    }));
  };

  const handleDurationChange = ({ value }: IDropdownOption) => {
    setFormValues((formValues) => ({
      ...formValues,
      durationType: value,
    }));
  };

  const handleLocationChange = ({ value }: IDropdownOption) => {
    setFormValues((formValues) => ({
      ...formValues,
      locationType: value,
    }));
  };

  const handleSharepointChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((formValues) => ({
      ...formValues,
      projectSharePoint: value,
    }));
  };

  const handleDescriptionChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((formValues) => ({
      ...formValues,
      description: value,
    }));
  };

  const fields = [
    {
      label: t('departments'),
      value: (
        <div
          style={{ minHeight: '20px' }}
          onClick={() =>
            setFieldToUpdate(ProjectDetailFieldsProperties.DEPARTMENTS)
          }
        >
          {fieldToUpdate === ProjectDetailFieldsProperties.DEPARTMENTS ? (
            <Select
              options={departmentList}
              value={departmentValue}
              onChange={handleDepartmentChange}
              placeholder={t('selectDepartments')}
              isMulti
              isClearable
            />
          ) : (
            Array.isArray(departments) &&
            departments.map(({ id, name }) => (
              <Row key={id}>
                <Col>{name}</Col>
              </Row>
            ))
          )}
        </div>
      ),
    },
    {
      label: t('duration'),
      value: (
        <div
          style={{ minHeight: '20px' }}
          onClick={() =>
            setFieldToUpdate(ProjectDetailFieldsProperties.PROJECT_DURATION)
          }
        >
          {fieldToUpdate === ProjectDetailFieldsProperties.PROJECT_DURATION ? (
            <Select
              onChange={handleDurationChange}
              options={durationOptions}
              value={durationValue}
            />
          ) : (
            durationValue?.label
          )}
        </div>
      ),
    },
    {
      label: t('location'),
      value: (
        <div
          style={{ minHeight: '20px' }}
          onClick={() =>
            setFieldToUpdate(ProjectDetailFieldsProperties.PROJECT_LOCATION)
          }
        >
          {fieldToUpdate === ProjectDetailFieldsProperties.PROJECT_LOCATION ? (
            <Select
              onChange={handleLocationChange}
              options={locationOptions}
              value={locationValue}
            />
          ) : (
            locationValue?.label
          )}
        </div>
      ),
    },
    {
      label: t('sharepoint'),
      value: (
        <div
          style={{ minHeight: '20px' }}
          onClick={() =>
            setFieldToUpdate(ProjectDetailFieldsProperties.PROJECT_SHAREPOINT)
          }
        >
          {fieldToUpdate ===
          ProjectDetailFieldsProperties.PROJECT_SHAREPOINT ? (
            <Input
              bsSize="lg"
              type="string"
              value={projectSharePoint}
              onChange={handleSharepointChange}
            />
          ) : (
            projectSharePoint
          )}
        </div>
      ),
    },
  ];

  return (
    <TextareaCard
      title={t('projectDescription')}
      value={description}
      fields={fields}
      onChange={handleDescriptionChange}
      border
      leftTitle
    />
  );
};

export default ProjectDescription;
