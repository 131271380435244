import moment from 'moment';
import React, { Fragment } from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import { ALL, NO_INDUSTRY } from '../../../utils/constants';
import { CONTACT_STATUS } from "../../../utils/enums/contact";
import { TIME_SPAN_ENUM } from "../../../utils/enums/timeTracking";
import { isEmpty } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import TimeSpanDropdown from './TimeSpanDropdown';

const REACHED = 'Called';
const APPOINTMENT = 'appointment';

/**
 * Card that contains the matrix overview table and it's filters
 */
class MatrixOverviewCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactPersonAssignments: [],
      callActivities: [],
      contactPersonSectors: [],

      selectedTime: TIME_SPAN_ENUM.thisMonth.code,
      sectors: [],
      sectorIds: [],

      filteredContacts: [],
      filteredCallActivities: [],

      filteredColdContacts: [],
      filteredWarmContacts: [],
      statisticsTotal: [],
    };
  }

  t(keyName) {
    return i18n.t('MatrixOverviewCard.' + keyName);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      let filteredContactPersonSectors = this.props.contactPersonSectors.filter(
        (contact) => contact.contactPerson !== null
      );
      this.setState({
        contactPersonAssignments: this.props.contactPersonAssignments,
        callActivities: this.props.callActivities,
        contactPersonSectors: filteredContactPersonSectors,
        filteredContacts: this.props.contactPersonAssignments,
        filteredCallActivities: this.props.callActivities,
        sectors: this.props.sectors,
        sectorIds: this.props.sectorIds,
      });
      if (prevState === this.state) {
        setTimeout(() => {
          this.handleUpdateTableByTime();
        }, 300);
      }
    }
  }

  /**
   * Handles updates of table value based on time value
   * @param {*} time
   */
  handleUpdateTableByTime = () => {
    let currentDate = new moment();
    // Gets all cold contact people from contactPersonAssignment
    let coldContactPeople = this.state.filteredContacts.filter(
      (contact) =>
        contact.assignmentState === CONTACT_STATUS.cold.code ||
        contact.assignmentState === CONTACT_STATUS.recall.code ||
        contact.assignmentState === CONTACT_STATUS.prospect.code
    );

    // Gets all warm contact people from contactPersonAssignment
    let warmContactPeople = this.state.filteredContacts.filter(
      (contact) =>
        contact.assignmentState === CONTACT_STATUS.meeting.code ||
        contact.assignmentState === CONTACT_STATUS.firstProject.code ||
        contact.assignmentState === CONTACT_STATUS.oneProject.code ||
        contact.assignmentState === CONTACT_STATUS.anotherProject.code ||
        contact.assignmentState === CONTACT_STATUS.multipleProject.code
    );

    let filteredColdContacts = [];
    let filteredWarmContacts = [];
    let dateFilteredCallActivities = [];

    switch (this.state.selectedTime) {
      // If selected Time span is today
      case TIME_SPAN_ENUM.today.code:
        filteredColdContacts = coldContactPeople.filter(
          (contact) =>
            moment(contact.date).format('LL') === currentDate.format('LL')
        );
        filteredWarmContacts = warmContactPeople.filter(
          (contact) =>
            moment(contact.date).format('LL') === currentDate.format('LL')
        );
        dateFilteredCallActivities = this.state.filteredCallActivities.filter(
          (call) => moment(call.date).format('LL') === currentDate.format('LL')
        );

        break;

      // If selected Time span is this week
      case TIME_SPAN_ENUM.thisWeek.code:
        filteredColdContacts = coldContactPeople.filter(
          (contact) => moment(contact.date).week() === currentDate.week()
        );
        filteredWarmContacts = warmContactPeople.filter(
          (contact) => moment(contact.date).week() === currentDate.week()
        );
        dateFilteredCallActivities = this.state.filteredCallActivities.filter(
          (call) => moment(call.date).week() === currentDate.week()
        );
        break;

      // If selected Time span is last week
      case TIME_SPAN_ENUM.lastWeek.code:
        filteredColdContacts = coldContactPeople.filter(
          (contact) => moment(contact.date).week() === currentDate.week() - 1
        );
        filteredWarmContacts = warmContactPeople.filter(
          (contact) => moment(contact.date).week() === currentDate.week() - 1
        );
        dateFilteredCallActivities = this.state.filteredCallActivities.filter(
          (call) => moment(call.date).week === currentDate.week() - 1
        );
        break;

      // If selected Time span is this month
      case TIME_SPAN_ENUM.thisMonth.code:
        filteredColdContacts = coldContactPeople.filter(
          (contact) => moment(contact.date).month() === currentDate.month()
        );
        filteredWarmContacts = warmContactPeople.filter(
          (contact) => moment(contact.date).month() === currentDate.month()
        );
        dateFilteredCallActivities = this.state.filteredCallActivities.filter(
          (call) => moment(call.date).month() === currentDate.month()
        );
        break;

      // If selected Time span is last month
      case TIME_SPAN_ENUM.lastMonth.code:
        filteredColdContacts = coldContactPeople.filter(
          (contact) => moment(contact.date).month() === currentDate.month() - 1
        );
        filteredWarmContacts = warmContactPeople.filter(
          (contact) => moment(contact.date).month() === currentDate.month() - 1
        );
        dateFilteredCallActivities = this.state.filteredCallActivities.filter(
          (call) => moment(call.date).month() === currentDate.month() - 1
        );
        break;

      // If selected Time span is this year
      case TIME_SPAN_ENUM.thisYear.code:
        filteredColdContacts = coldContactPeople.filter(
          (contact) => moment(contact.date).year() === currentDate.year()
        );
        filteredWarmContacts = warmContactPeople.filter(
          (contact) => moment(contact.date).year() === currentDate.year()
        );
        dateFilteredCallActivities = this.state.filteredCallActivities.filter(
          (call) => moment(call.date).year() === currentDate.year()
        );
        break;

      // If selected Time span is overall
      case TIME_SPAN_ENUM.overall.code:
        filteredColdContacts = coldContactPeople;
        filteredWarmContacts = warmContactPeople;
        dateFilteredCallActivities = this.state.filteredCallActivities;
        break;

      default:
        dateFilteredCallActivities = this.state.filteredCallActivities;
        break;
    }

    // Retrieval of statistics data for table values
    let coldContactIds = filteredColdContacts.map(
      (contact) => contact?.contactPerson?.id
    );
    let warmContactIds = filteredWarmContacts.map(
      (contact) => contact?.contactPerson?.id
    );

    let coldCalls = dateFilteredCallActivities.filter((call) =>
      coldContactIds.includes(call.objectId)
    );

    let warmCalls = dateFilteredCallActivities.filter((call) =>
      warmContactIds.includes(call.objectId)
    );

    let coldReached = dateFilteredCallActivities.filter(
      (call) =>
        call.description.includes(REACHED) &&
        coldContactIds.includes(call.objectId)
    );

    let warmReached = dateFilteredCallActivities.filter(
      (call) =>
        call.description.includes(REACHED) &&
        warmContactIds.includes(call.objectId)
    );

    let coldAppointed = dateFilteredCallActivities.filter(
      (call) =>
        call.description.includes(APPOINTMENT) &&
        coldContactIds.includes(call.objectId)
    );

    let warmAppointed = dateFilteredCallActivities.filter(
      (call) =>
        call.description.includes(APPOINTMENT) &&
        warmContactIds.includes(call.objectId)
    );

    let statisticsTotal = {
      coldCalls: coldCalls.length,
      warmCalls: warmCalls.length,
      coldCallsReached: coldReached.length,
      warmCallsReached: warmReached.length,
      coldCallsAppointed: coldAppointed.length,
      warmCallsAppointed: warmAppointed.length,
    };

    this.setState({
      filteredColdContacts,
      filteredWarmContacts,
      statisticsTotal,
    });
  };

  /**
   * Handles updating of table depending on the industries selected
   * @param {*} sectorIds
   */
  handleUpdateTableByIndustry = (sectorIds) => {
    let contactsIdsFromSectorsChosen = this.state.contactPersonSectors
      .filter((item) => sectorIds.includes(item.sector?.id))
      .map((contact) => contact?.contactPerson?.id);

    let contactIdsFromSectors = this.state.contactPersonSectors.map(
      (contact) => contact?.contactPerson?.id
    );

    let filteredContacts = [];
    let filteredCallActivities = [];

    // Filtering of Contacts by industry
    filteredContacts = this.state.contactPersonAssignments.filter((contact) =>
      contactsIdsFromSectorsChosen.includes(contact?.contactPerson?.id)
    );

    // Filtering of Call activities by industry
    filteredCallActivities = this.state.callActivities.filter((call) =>
      contactsIdsFromSectorsChosen.includes(call?.objectId)
    );

    if (sectorIds.includes(NO_INDUSTRY)) {
      let contactsWithNoIndustry = this.state.contactPersonAssignments.filter(
        (contact) => !contactIdsFromSectors.includes(contact?.contactPerson?.id)
      );
      let callsWithNoIndustry = this.state.callActivities.filter(
        (call) => !contactIdsFromSectors.includes(call?.objectId)
      );

      filteredContacts.push(...contactsWithNoIndustry);
      filteredCallActivities.push(...callsWithNoIndustry);
    } else if (sectorIds.includes(ALL)) {
      filteredContacts = this.state.contactPersonAssignments;
      filteredCallActivities = this.state.callActivities;
    }

    this.setState({
      filteredContacts,
      filteredCallActivities,
    });

    setTimeout(() => {
      this.handleUpdateTableByTime();
    }, 300);
  };

  /**
   * Handles changes to time span dropdown
   * @param {*} time
   */
  handleTimeSpanChange = (time) => {
    this.setState({
      selectedTime: time,
    });
    setTimeout(() => {
      this.handleUpdateTableByTime();
    }, 300);
  };

  /**
   * Handles changes to industries dropdown
   * @param {*} sectors
   */
  handleMultipleSectorChange = (sectors) => {
    if (Array.isArray(sectors)) {
      let newSectors = sectors;
      if (newSectors.some((sector) => sector.value.toString() === ALL)) {
        newSectors = this.state.sectors.filter(
          (sector) => sector.value.toString() !== ALL
        );
      }
      let sectorIds = newSectors.map((sector) => sector.value);

      this.setState({
        sectorIds: sectorIds,
      });
      this.handleUpdateTableByIndustry(sectorIds);
    } else {
      this.setState({
        sectorIds: [],
      });
    }
  };

  /**
   * Method for getting percentage
   * @param {*} statistics
   * @param {*} dividend
   * @param {*} divisor
   * @returns percentage
   */
  handleCalculatePercentage = (statistics, dividend, divisor) => {
    if (!isEmpty(statistics) && divisor !== 0) {
      return `${Math.round((dividend / divisor) * 100)}%`;
    } else {
      return '0%';
    }
  };

  /**
   * Handles getting total from items and checking if statistics
   * is not empty to avoid returning NaN value
   * @param {*} statistics
   * @param {*} firstItem
   * @param {*} secondItem
   * @returns total
   */
  getTotalFromStatistics = (statistics, firstItem, secondItem) => {
    return !isEmpty(statistics) ? firstItem + secondItem : 0;
  };

  render() {
    const selectedTime = this.state.selectedTime;
    const filteredColdContacts = this.state.filteredColdContacts;
    const filteredWarmContacts = this.state.filteredWarmContacts;
    const statisticsTotal = this.state.statisticsTotal;
    const callsTotal = this.getTotalFromStatistics(
      statisticsTotal,
      statisticsTotal?.coldCalls,
      statisticsTotal?.warmCalls
    );
    const reachedTotal = this.getTotalFromStatistics(
      statisticsTotal,
      statisticsTotal?.coldCallsReached,
      statisticsTotal?.warmCallsReached
    );
    const appointmentsTotal = this.getTotalFromStatistics(
      statisticsTotal,
      statisticsTotal?.coldCallsAppointed,
      statisticsTotal?.warmCallsAppointed
    );
    return (
      <Fragment>
        <Col>
          <h3>{this.t('matrixOverview')}</h3>
          <Row>
            <div style={{ width: '200px', padding: '10px' }}>
              <TimeSpanDropdown
                onChange={this.handleTimeSpanChange}
                selectedTime={selectedTime ?? TIME_SPAN_ENUM.thisMonth.code}
              />
            </div>
            <div style={{ width: '60%', padding: '10px' }}>
              <Select
                isMulti
                options={this.state.sectors}
                value={this.state.sectors?.filter((sector) =>
                  this.state.sectorIds?.some((id) => id === sector.value)
                )}
                onChange={this.handleMultipleSectorChange}
                isClearable={true}
                placeholder={this.t('selectIndustries')}
              />
            </div>
          </Row>
          {/*Table for matrix overview*/}
          <table className="matrix-overview-table">
            <thead>
              <tr>
                <td></td>
                <td>{this.t('newContacts')}</td>
                <td>{this.t('calls')}</td>
                <td>{this.t('reached')}</td>
                <td>{this.t('appointments')}</td>
                <td> {this.t('callingSuccessRate')} </td>
                <td> {this.t('appointmentRate')}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    background: '#3180CF',
                    color: 'white',
                    fontSize: '15px',
                    fontWeight: 'normal',
                  }}
                >
                  {this.t('cold')}
                </td>
                <td>{filteredColdContacts.length}</td>
                <td>{statisticsTotal?.coldCalls}</td>
                <td>{statisticsTotal?.coldCallsReached}</td>
                <td>{statisticsTotal?.coldCallsAppointed}</td>
                <td>
                  {this.handleCalculatePercentage(
                    statisticsTotal,
                    statisticsTotal?.coldCallsReached,
                    statisticsTotal?.coldCalls
                  )}{' '}
                </td>
                <td>
                  {this.handleCalculatePercentage(
                    statisticsTotal,
                    statisticsTotal?.coldCallsAppointed,
                    statisticsTotal?.coldCalls
                  )}{' '}
                </td>
              </tr>
              <tr
                style={{
                  background: '#DFE8F1',
                }}
              >
                <td
                  style={{
                    background: '#3180CF',
                    color: 'white',
                    fontSize: '15px',
                    fontWeight: 'normal',
                  }}
                >
                  {this.t('warm')}
                </td>
                <td>{filteredWarmContacts.length}</td>
                <td>{statisticsTotal?.warmCalls}</td>
                <td>{statisticsTotal?.warmCallsReached}</td>
                <td>{statisticsTotal?.warmCallsAppointed}</td>
                <td>
                  {this.handleCalculatePercentage(
                    statisticsTotal,
                    statisticsTotal?.warmCallsReached,
                    statisticsTotal?.warmCalls
                  )}{' '}
                </td>
                <td>
                  {this.handleCalculatePercentage(
                    statisticsTotal,
                    statisticsTotal?.warmCallsAppointed,
                    statisticsTotal?.warmCalls
                  )}{' '}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    background: '#3180CF',
                    color: 'white',
                    fontSize: '15px',
                    fontWeight: 'normal',
                  }}
                >
                  {this.t('total')}
                </td>
                <td>
                  {filteredColdContacts.length + filteredWarmContacts.length}{' '}
                </td>
                <td>{callsTotal} </td>
                <td>{reachedTotal} </td>
                <td>{appointmentsTotal} </td>
                <td>
                  {this.handleCalculatePercentage(
                    statisticsTotal,
                    reachedTotal,
                    callsTotal
                  )}{' '}
                </td>
                <td>
                  {this.handleCalculatePercentage(
                    statisticsTotal,
                    appointmentsTotal,
                    callsTotal
                  )}{' '}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Fragment>
    );
  }
}
export default MatrixOverviewCard;
