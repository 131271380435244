import React from 'react';
import { Row } from 'reactstrap';

import { ContactInfoType } from '../../../utils/enums/contact';
import i18n from '../../../i18n';
import { ICustomerDetailContactPersonListItem } from '../../../utils/types/responseTypes';
import TableCard from '../../../components/cards/TableCard';

interface IProps {
  contactPersons: ICustomerDetailContactPersonListItem[];
  selectedContactPersonIds: number[];
  onSelectionChange: (id: number) => void;
}

const ExistingContactPersonSelectionTable = ({
  contactPersons,
  selectedContactPersonIds,
  onSelectionChange,
}: IProps) => {
  const tableData = contactPersons.map(
    ({ id, firstname, lastname, contactInformation }) => ({
      '': (
        <input
          type="checkbox"
          checked={selectedContactPersonIds.includes(id)}
          onChange={() => onSelectionChange(id)}
        />
      ),
      [i18n.t('CustomerContactPersonAdd.name')]: `${firstname} ${lastname}`,
      [i18n.t('CustomerContactPersonAdd.email')]: (
        <>
          {contactInformation.map(({ type, info, id }) =>
            type === ContactInfoType.EMAIL ? (
              <Row key={id}>
                <a href={`mailto:${info}`}>{info}</a>
              </Row>
            ) : (
              ''
            )
          )}
        </>
      ),
      [i18n.t('CustomerContactPersonAdd.phone')]: (
        <>
          {contactInformation.map(({ type, info, id }) =>
            type === ContactInfoType.PHONE ? <Row key={id}>{info}</Row> : ''
          )}{' '}
        </>
      ),
    })
  );

  return <TableCard tableData={tableData} title="" />;
};

export default ExistingContactPersonSelectionTable;
