import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';
import { generateTitle } from '../../utils/helpers/icon';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import i18n from '../../i18n';
import { IActivityListItem } from '../../utils/types/modelTypes';
import {
  deleteActivity,
  saveActivity,
  updateActivity,
} from '../../services/api/activity';
import { ActivityType } from '../../utils/enums/activity';

/**
 * Card used in for activity notes
 * Props (activities, objectId, objectType).\
 * activities= list of IActivityListItem
 * objectType = REQUEST, OFFER or ORDER or PROJECT
 * objectId = id of object
 * onSave and OnDelete, can be added for future use
 */
interface IProps extends IWithModalsProps {
  activities: IActivityListItem[];
  objectId: number;
  objectType: string;
  setActivities: (activities: IActivityListItem[]) => void;
}

const t = (keyName: string) => i18n.t(`ActivityCard.${keyName}`);

const ActivityNotesCard = ({
  activities,
  objectId,
  objectType,
  modalErrorHandler,
  setActivities,
}: IProps) => {
  const [newActivity, setNewActivity] = useState({} as IActivityListItem);
  const [activityList, setActivityList] = useState([] as IActivityListItem[]);
  // Index of activities being edited
  const [editingIndexList, setEditingIndexList] = useState([] as number[]);

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    activity: IActivityListItem,
    index: number | undefined
  ) => {
    if (index !== undefined) {
      const updatedActivity: IActivityListItem = {
        ...activity,
        description: event.target.value,
      };
      activityList[index] = updatedActivity;
      setActivityList([...activityList]);
    } else {
      setNewActivity({ ...newActivity, description: event.target.value });
    }
  };

  const resetUpdate = (id: number, index: number) => {
    const newList = editingIndexList.filter((editIndex) => editIndex !== index);
    activityList[index] =
      activities.find((activity) => activity.id === id) ??
      ({} as IActivityListItem);
    setActivityList([...activityList]);
    setEditingIndexList(newList);
  };

  const save = async (activityToSave: IActivityListItem, index?: number) => {
    const savedActivities = activities;
    if (index === undefined) {
      try {
        const { data: newActivity } = await saveActivity({
          activityType: ActivityType.CUSTOM_ACTIVITY,
          description: activityToSave.description,
          date: new Date().toISOString(),
          objectId,
          objectType,
        });
        savedActivities.push(newActivity);
        setActivityList([newActivity, ...activityList]);
        setNewActivity({} as IActivityListItem);
      } catch (error) {
        modalErrorHandler(t('failedToAddActivity'), error);
      }
    } else {
      try {
        const { data: updatedActivity } = await updateActivity({
          activityType: ActivityType.CUSTOM_ACTIVITY,
          date: activityToSave.date,
          description: activityToSave.description,
          id: activityToSave.id,
          objectId,
          objectType,
        });
        savedActivities.push(updatedActivity);
        activityList[index] = updatedActivity;
        setActivityList([...activityList]);
        setEditingIndexList(
          editingIndexList.filter((editIndex) => editIndex !== index)
        );
      } catch (error) {
        modalErrorHandler(t('failedToUpdateActivity'), error);
      }
    }
    setActivities(savedActivities);
  };

  const removeActivity = async (id: number, index: number) => {
    try {
      await deleteActivity(id);
      activityList.splice(index, 1);
      setActivityList([...activityList]);
    } catch (error) {
      modalErrorHandler(t('failedToDeleteActivity'), error);
    }
  };

  const textArea = (activity: IActivityListItem, index?: number) => (
    <FormGroup>
      <Row>
        <Col>
          <Input
            type="textarea"
            style={{ minHeight: '120px' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleDescriptionChange(event, activity, index)
            }
            value={activity?.description ? activity.description : ''}
            placeholder={'Enter description'}
          />
        </Col>

        <Col
          lg="2"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '3px',
            verticalAlign: 'middle',
          }}
        >
          <div className="float-end">
            <Button
              color="primary"
              onClick={() => save(activity, index)}
              disabled={!activity.description}
            >
              {generateTitle(BUTTON_TITLE_ENUM.SAVE.code)}
            </Button>
            {activity.id !== undefined && index !== undefined ? (
              <Button
                color="primary"
                onClick={() => resetUpdate(activity.id, index)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            ) : null}
          </div>
        </Col>
      </Row>
    </FormGroup>
  );

  const tableData = (
    <>
      {activityList.map((activity, index) => (
        <>
          {editingIndexList.includes(index) ? (
            textArea(activity, index)
          ) : (
            <Row key={index} style={{ paddingBottom: '10px' }}>
              <Col
                onClick={() => {
                  setEditingIndexList([...editingIndexList, index]);
                }}
              >
                <span>{activity.description}</span>
              </Col>
              <Col lg="2">{activity.creator.name}</Col>
              <Col lg="3">
                <div
                  className="float-end"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '3px',
                    verticalAlign: 'middle',
                  }}
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      setEditingIndexList([...editingIndexList, index]);
                    }}
                  >
                    {generateTitle(BUTTON_TITLE_ENUM.UPDATE.code)}
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => removeActivity(activity.id, index)}
                  >
                    {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </>
      ))}
    </>
  );

  useEffect(() => {
    setActivityList(
      activities
        .filter(
          ({ activityType }) =>
            activityType === ActivityType.CUSTOM_ACTIVITY
        )
        .sort((a, b) => (a.date > b.date ? -1 : 1))
    );
  }, [activities]);

  return (
    <Card className={'common-card with-border'}>
      <CardBody>
        <CardTitle className="float-start">
          <h3>{t('notes')}</h3>
        </CardTitle>
      </CardBody>
      <CardBody>{tableData}</CardBody>
      <CardBody>{textArea(newActivity)}</CardBody>
    </Card>
  );
};

export default withModals(ActivityNotesCard);
