import React, { useContext } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import { translate } from './offerHelper';
import { OfferDetailsContext } from './OfferDetailsProvider';
import FileAttachmentCard from '../../../components/cards/FileAttachmentCard';
import { objectTypeEnum } from '../../../utils/enums/enum';

const OfferFilesCard = () => {
  const { project, offer } = useContext(OfferDetailsContext);

  const { id: offerId, offerFileAttachments } = offer;
  const { sharepointLink } = project;

  return (
    <Card className="common-card with-border">
      <CardBody>
        <div>
          <FileAttachmentCard
            fileAttachments={offerFileAttachments}
            objectId={offerId}
            objectType={objectTypeEnum.offer.code}
          />
        </div>
        <tr>
          <th>{translate('sharePoint')}</th>
          <td>
            {sharepointLink !== '' ? (
              <Link to={sharepointLink}>{sharepointLink}</Link>
            ) : (
              <h5 style={{ captionSide: 'block-end', fontSize: 'small' }}>
                {translate('noProjectSharePoint')}
              </h5>
            )}
          </td>
        </tr>
      </CardBody>
    </Card>
  );
};

export default OfferFilesCard;
