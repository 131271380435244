import { getContactStatisticsListItems } from '../services/api/contactStatistics';
import { SortBy, SortType } from '../utils/enums/sort';
import { IContactPersonStatisticsListItem } from '../utils/types/responseTypes';
import createListSlice, { ListState } from './createListSlice';
import type { AppThunk } from './store';

const initialState: ListState<
  IContactPersonStatisticsListItem,
  Record<string, never>
> = {
  listItems: [],
  hasMore: false,
  page: 0,
  pageSize: 0,
  pageCount: 0,
  filters: {},
  showFilters: false,
  sortBy: SortBy.NAME,
  sortType: SortType.DESCENDING,
};

export const contactStatisticsListSlice = createListSlice({
  name: 'contactStatisticsList',
  initialState,
  reducers: {},
});

export const { setListItems } = contactStatisticsListSlice.actions;

/**
 * Thunk for fetching contact statistics list items
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching contact statistics list items
 */
export const fetchContactStatisticsListItems =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedContactStatisticsListItems } =
        await getContactStatisticsListItems();

      dispatch(setListItems(fetchedContactStatisticsListItems));
    } catch (error) {
      errorHandler(error);
    }
  };

export default contactStatisticsListSlice.reducer;
