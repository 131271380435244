import React from 'react';
import Select from 'react-select';

import i18n from '../../i18n';
import { Ownership } from '../../utils/enums/ownership';
import { IDropdownOption } from '../../utils/types/commonTypes';
import { enumValuesToDropdownOptions } from '../../utils/helpers/dropdown';

interface IProps {
  includeResponsibleAndInvolvedOption: boolean;
  value: Ownership | Ownership[];
  onChange: (ownership: Ownership | Ownership[]) => void;
}

/**
 * Dropdown that has all ResponsibleOwnership as options.\
 * Variable props:
 *  value - chosen value
 * Function props:
 *  onChange
 */
const ResponsibleOwnershipDropdown = ({
  includeResponsibleAndInvolvedOption,
  value,
  onChange,
}: IProps) => {
  const t = (keyName: string) => i18n.t(`OwnershipModel.${keyName}`);

  const ownerShipFilter = includeResponsibleAndInvolvedOption
    ? [
        {
          value: Ownership.RESPONSIBLE,
          label: t('RESPONSIBLE'),
        },
        {
          value: Ownership.INVOLVED,
          label: t('INVOLVED'),
        },
        {
          value: [Ownership.INVOLVED, Ownership.RESPONSIBLE],
          label: t('responsibleAndInvolved'),
        },
        {
          value: Ownership.ALL,
          label: t('all'),
        },
      ]
    : [
        {
          value: Ownership.RESPONSIBLE,
          label: t('RESPONSIBLE'),
        },
        {
          value: Ownership.INVOLVED,
          label: t('INVOLVED'),
        },
        {
          value: Ownership.ALL,
          label: t('all'),
        },
      ];

  const onChangeHandler = (event: IDropdownOption) => {
    const filter = ownerShipFilter.find((item) => item.value === event.value);
    if (filter) {
      onChange(filter.value);
    } else {
      onChange(value);
    }
  };

  return (
    <div>
      <Select
        aria-label="responsible-ownership-dropdown"
        options={ownerShipFilter}
        value={enumValuesToDropdownOptions(Object.values(Ownership)).find(
          (ownership) => value === ownership.value
        )}
        onChange={onChangeHandler}
        styles={{
          menu: (styles: { [key: string]: React.CSSProperties }) => ({
            ...styles,
            zIndex: 100,
          }),
        }}
      />
    </div>
  );
};

export default ResponsibleOwnershipDropdown;
