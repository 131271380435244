import axios, { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { urlBuilder } from '../../utils/helpers/url';
import { Url } from './constants';
import {
  IContactPersonTopicListItem,
  ICountryState,
  IExtendedContactPerson,
  ISector,
} from '../../utils/types/modelTypes';
import { ICampaignManagerContactPersonListItem } from '../../utils/types/responseTypes';
import { IObjectNameAndId } from '../../utils/types/commonTypes';

export const sendCampaignManagerContactPersonEmails = (emailObject: {
  emailSubject: string;
  emailBody: string;
  recipients: IExtendedContactPerson[];
}) =>
  trackPromise<AxiosResponse<void>>(
    axios.post(urlBuilder(Url.CAMPAIGN_MANAGER_SEND_EMAIL, {}), emailObject)
  );

export const exportCampaignManagerContactPersonListItems = (
  queryParameters: {
    [key: string]: string;
  },
  lang: string
) =>
  trackPromise<AxiosResponse<Blob>>(
    axios.get(
      urlBuilder(
        `${Url.EXPORT_CAMPAIGN_MANAGER_CONTACT_PEOPLE}/${lang}`,
        queryParameters
      ),
      {
        responseType: 'blob',
      }
    )
  );

export const getCampaignManagerContactPersonListItems = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<ICampaignManagerContactPersonListItem[]>>(
    axios.get(urlBuilder(Url.CAMPAIGN_MANAGER_CONTACT_PEOPLE, queryParameters))
  );

export const getCountryOptions = () =>
  trackPromise<AxiosResponse<IObjectNameAndId[]>>(
    axios.get(urlBuilder(Url.COUNTRIES, {}))
  );

export const getCountryStateOptions = () =>
  trackPromise<AxiosResponse<ICountryState[]>>(
    axios.get(urlBuilder(Url.COUNTRY_STATES, {}))
  );

export const getSectorOptions = () =>
  trackPromise<AxiosResponse<ISector[]>>(
    axios.get(urlBuilder(Url.SECTORS, {}))
  );

export const getTopicOptions = () =>
  trackPromise<AxiosResponse<IContactPersonTopicListItem[]>>(
    axios.get(urlBuilder(Url.CONTACT_PERSON_TOPICS, {}))
  );
