import i18n from '../../i18n';

// eslint-disable-next-line import/prefer-default-export
export const TIME_SPAN_ENUM = {
  today: {
    name: i18n.t('TimeSpanEnum.today'),
    code: 'today',
  },
  thisWeek: {
    name: i18n.t('TimeSpanEnum.thisWeek'),
    code: 'thisWeek',
  },
  lastWeek: {
    name: i18n.t('TimeSpanEnum.lastWeek'),
    code: 'lastWeek',
  },
  thisMonth: {
    name: i18n.t('TimeSpanEnum.thisMonth'),
    code: 'thisMonth',
  },
  lastMonth: {
    name: i18n.t('TimeSpanEnum.lastMonth'),
    code: 'lastMonth',
  },
  thisYear: {
    name: i18n.t('TimeSpanEnum.thisYear'),
    code: 'thisYear',
  },
  overall: {
    name: i18n.t('TimeSpanEnum.overall'),
    code: 'overall',
  },
};
