import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import { Button, Col, ListGroup, ListGroupItem, Row, Table } from 'reactstrap';
import stringInject from 'stringinject';

import Auth from '../../services/axios/Auth';
import axios from '../../services/axios/axios';
import {
  APPROVAL_REQUEST_APPROVED_NOTIFICATION,
  APPROVAL_REQUEST_NOTIFICATION,
  APPROVAL_REQUEST_REJECTED_NOTIFICATION,
  PROJECT_NEXT_ACTIVITY_NOTIFICATION,
  READ,
  READWRITE,
  UPDATE_CUSTOMER_RESPONSIBLE_NOTIFICATION,
  UPDATE_CUSTOMER_SITE_RESPONSIBLE_NOTIFICATION,
  UPDATE_RESPONSIBLE_NOTIFICATION,
} from '../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';
import { OBJECT_TYPE_ENUM } from '../../utils/enums/objectType';
import {
  SEND_NOTIFICATIONS_VIA_ENUM,
  SERVICE_NAMES_ENUM,
} from '../../utils/enums/service';
import {
  getDateFormat,
  getLatestItem,
  isEmpty,
  sortByDate,
} from '../../utils/helpers/GenericHelper';
import { generateTitle } from '../../utils/helpers/icon';
import i18n from '../../i18n';
import { loadNotifications } from '../../redux/notificationsSlice';
import { RootState } from '../../redux/store';
import routes from '../../routes/index';
import { IRouteCategory } from '../../utils/types/commonTypes';
import {
  INotification,
  INotificationSettingConfig,
  INotificationStatus,
} from '../../utils/types/modelTypes';
import {
  NotificationEnum,
  SortNotifications,
} from '../../NotificationFunctions';

/* Currently no error handlers
const NOTIFICATION_DATA_ERROR = i18n.t(
  'NotificationSetup.notificationDataError'
);
*/

const CONTACT_PERSON_LINK = '/customer/my-contacts/profile/';
const EMPLOYEE_DETAILS_LINK = '/employees/employee-list/employee-detail/';
const PROJECT_DETAILS_LINK = '/projects/overview/project-details/';
const EMPLOYEE_APPROVAL_LINK = '/employees/employee-approval';
const REQUEST_DETAILS_LINK = '/sales/request/details/';
const OFFER_DETAILS_LINK = '/sales/offer/details/';

const APPROVAL_KEY_LINK = i18n.t('NotificationNavBar.approvalKeyLink');

interface IProps extends PropsFromRedux, RouteComponentProps {
  selectedService?: string;
  selectedNotificationFromService: INotificationSettingConfig['selectedFromServices'];
  selectedEmailOrCRMTool: INotificationSettingConfig['sendNotificationVia'];
  toggleNotificationListTable: boolean;
}

interface IState {
  notifications: INotification[];
  objectRouteCategories: {
    uriList: IRouteCategory['permissionUri'];
    serviceName: IRouteCategory['serviceName'];
  }[];
  selectedService: string | undefined;
}

/**
 * Class that generates the actual table for the notifications
 */
class NotificationsListTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      notifications: this.props.notifications?.notifications
        ? this.props.notifications.notifications
        : [],
      objectRouteCategories: [],
      selectedService: this.props.selectedService
        ? this.props.selectedService
        : SERVICE_NAMES_ENUM.all.code,
    };
  }

  translation(keyName: string) {
    return i18n.t('index.' + keyName);
  }

  t(keyName: string) {
    return i18n.t('NotificationNavBar.' + keyName);
  }

  projectActivityTranslation(keyName: string) {
    return i18n.t('ProjectActivities.' + keyName);
  }

  componentDidMount = () => {
    const validRoutes = routes.filter(
      (route: IRouteCategory) => route?.serviceName
    );
    // Create list of object, containing uri, serviceName
    const objectRouteCategories: {
      uriList: IRouteCategory['permissionUri'];
      serviceName: IRouteCategory['serviceName'];
    }[] = [];
    if (validRoutes.length > 0) {
      validRoutes.forEach((category: IRouteCategory) => {
        const entry = {
          uriList: category.permissionUri,
          serviceName: category.serviceName,
        };
        objectRouteCategories.push(entry);
      });
    } else {
      return;
    }

    this.setState({
      objectRouteCategories,
    });
  };

  /**
   * Use to check if the string is a valid JSON.
   */
  checkIfStringIsJson = (string: string) => {
    try {
      const parsedJSON = JSON.parse(string);
      return Object.prototype.hasOwnProperty.call(parsedJSON, 'objectToInsert');
    } catch (e) {
      return false;
    }
  };

  // Get data to be shown based on the service, objectId and objectType.
  getData(
    service: INotification['fromService'],
    description: INotification['msg'],
    objectType: INotification['objectType'],
    fontToUseBasedOnStatus: string,
    dueDate: INotification['dueDate']
  ) {
    switch (service) {
      case SERVICE_NAMES_ENUM.absencePlanner.code:
        if (description && this.checkIfStringIsJson(description)) {
          const messageJSONObject = JSON.parse(description);
          // Translation key, that will return the translated string where the stringKey will be injected into
          const translationKey = messageJSONObject?.translationKey;
          const objectListToInsert = messageJSONObject?.objectToInsert; // For absence, list only contains one object => "employeeFullName" and "employeeId" of the absence sender
          switch (translationKey) {
            case 'absenceSubmitted':
            case 'absenceRetreated':
              return this.dataWithLinks(
                this.replaceString(
                  this.replaceString(
                    stringInject(this.t(translationKey), [
                      objectListToInsert[0].label,
                    ]),
                    null,
                    '',
                    dueDate
                  ),
                  objectListToInsert[0],
                  '',
                  dueDate
                ),
                dueDate,
                fontToUseBasedOnStatus
              );
            case 'absenceAccepted':
            case 'absenceCanceled':
            case 'absenceDeclined':
              return this.dataWithLinks(
                <span
                  style={{
                    color: this.getColorFromDueDate(dueDate),
                  }}
                >
                  {stringInject(this.t(translationKey), [
                    moment(objectListToInsert[0].startDate).format(
                      getDateFormat()
                    ),
                    moment(objectListToInsert[1].endDate).format(
                      getDateFormat()
                    ),
                    messageJSONObject?.approvedBy?.label,
                  ])}
                </span>,
                dueDate,
                fontToUseBasedOnStatus
              );
            default:
              break;
          }
        }
        return this.defaultData(description, fontToUseBasedOnStatus);

      case SERVICE_NAMES_ENUM.crm.code:
        if (description && this.checkIfStringIsJson(description)) {
          const messageJSONObject = JSON.parse(description);
          const translationKey = messageJSONObject?.translationKey;
          const objectListToInsert = messageJSONObject?.objectToInsert; // List contains three objects, "entity", "previousResponsible" and "newResponsible"

          // Check if notification is for updating a responsible, else return defaultData
          if (translationKey === UPDATE_RESPONSIBLE_NOTIFICATION) {
            let updatedResponsible;

            switch (objectType) {
              case OBJECT_TYPE_ENUM.contactPerson.code:
                updatedResponsible = this.t(translationKey);
                break;
              case OBJECT_TYPE_ENUM.customer.code:
                updatedResponsible = this.t(
                  UPDATE_CUSTOMER_RESPONSIBLE_NOTIFICATION
                );
                break;
              case OBJECT_TYPE_ENUM.customerSite.code:
                updatedResponsible = this.t(
                  UPDATE_CUSTOMER_SITE_RESPONSIBLE_NOTIFICATION
                );
                break;
              default:
                updatedResponsible = this.t(translationKey);
                break;
            }

            const entity = objectListToInsert[0];
            const previousResponsible = objectListToInsert[1];
            const newResponsible = objectListToInsert[2];

            const myDescription = stringInject(updatedResponsible, [
              entity.label,
              previousResponsible.label,
              newResponsible.label,
            ]);

            let injectedNotificationMessage;
            injectedNotificationMessage = this.replaceString(
              myDescription,
              entity,
              CONTACT_PERSON_LINK,
              dueDate
            );
            injectedNotificationMessage = this.replaceString(
              injectedNotificationMessage,
              previousResponsible,
              EMPLOYEE_DETAILS_LINK,
              dueDate
            );
            injectedNotificationMessage = this.replaceString(
              injectedNotificationMessage,
              newResponsible,
              EMPLOYEE_DETAILS_LINK,
              dueDate
            );

            return this.dataWithLinks(
              injectedNotificationMessage,
              dueDate,
              fontToUseBasedOnStatus
            );
          }

          if (objectType === OBJECT_TYPE_ENUM.contact.code) {
            const objectToInsert = messageJSONObject?.objectToInsert[0];
            const message = this.t(translationKey);
            const notificationMessage = stringInject(message, [
              objectToInsert.label,
            ]);
            const link = '';

            const injectedNotificationMessage = this.replaceString(
              notificationMessage,
              objectToInsert,
              link,
              dueDate
            );

            return this.dataWithLinks(
              injectedNotificationMessage,
              dueDate,
              fontToUseBasedOnStatus
            );
          }
          return this.defaultData(description, fontToUseBasedOnStatus);
        }
        return this.defaultData(description, fontToUseBasedOnStatus);

      case SERVICE_NAMES_ENUM.employees.code:
        if (description && this.checkIfStringIsJson(description)) {
          const messageJSONObject = JSON.parse(description);
          const translationKey = messageJSONObject?.translationKey;
          const objectListToInsert = messageJSONObject?.objectToInsert;

          const employeeDetails = objectListToInsert[0];

          const message = this.t(translationKey);

          const notificationMessage = stringInject(message, [
            employeeDetails.label,
          ]);

          let injectedNotificationMessage = this.replaceString(
            notificationMessage,
            null,
            EMPLOYEE_APPROVAL_LINK,
            dueDate
          );

          injectedNotificationMessage = this.replaceString(
            injectedNotificationMessage,
            employeeDetails,
            EMPLOYEE_DETAILS_LINK,
            dueDate
          );

          return this.dataWithLinks(
            injectedNotificationMessage,
            dueDate,
            fontToUseBasedOnStatus
          );
        }
        return this.defaultData(description, fontToUseBasedOnStatus);

      case SERVICE_NAMES_ENUM.salesFunnel.code:
      case SERVICE_NAMES_ENUM.projects.code:
        if (description && this.checkIfStringIsJson(description)) {
          const messageJSONObject = JSON.parse(description);
          const translationKey = messageJSONObject?.translationKey;
          const objectListToInsert = messageJSONObject?.objectToInsert; // List only contains one object => "employeeFullName" and "employeeId" of the absence sender

          // Check if notification is for Next Activity Notification,
          // If not, check for objectType, else return defaultData
          if (translationKey === PROJECT_NEXT_ACTIVITY_NOTIFICATION) {
            // Expect that the objectListToInsert contains 3 items.
            // Create object based on the objectProperty, hasOwnProperty
            if (
              Array.isArray(objectListToInsert) &&
              objectListToInsert.length === 3
            ) {
              // NextActivityObject
              const nextActivityObject = objectListToInsert.find((object) =>
                Object.prototype.hasOwnProperty.call(object, 'nextActivity')
              );
              const nextActivityLabel = nextActivityObject?.label;
              const nextActivityLabelTranslated =
                this.projectActivityTranslation(nextActivityLabel);

              // Contact person object
              const contactPersonObject = objectListToInsert.find((object) =>
                Object.prototype.hasOwnProperty.call(object, 'contactPersonId')
              );

              // Project object
              const projectObject = objectListToInsert.find((object) =>
                Object.prototype.hasOwnProperty.call(object, 'projectId')
              );

              const projectNextActivityNotificationMessage =
                this.t(translationKey);
              const myDescription = stringInject(
                projectNextActivityNotificationMessage,
                [
                  nextActivityLabelTranslated,
                  contactPersonObject?.label,
                  projectObject?.label,
                ]
              );

              let injectedNotificationMessage;
              injectedNotificationMessage = this.replaceString(
                myDescription,
                contactPersonObject,
                CONTACT_PERSON_LINK,
                dueDate
              );

              injectedNotificationMessage = this.replaceString(
                injectedNotificationMessage,
                projectObject,
                PROJECT_DETAILS_LINK,
                dueDate
              );

              return this.dataWithLinks(
                injectedNotificationMessage,
                dueDate,
                fontToUseBasedOnStatus
              );
            }
            return (
              <React.Fragment>
                <div className="text-muted small mt-1">
                  {'projectNextActivityNotificationMessage'}
                </div>
              </React.Fragment>
            );
          } else if (objectType === OBJECT_TYPE_ENUM.project.code) {
            let message = '';
            let link = '';
            switch (translationKey) {
              case APPROVAL_REQUEST_NOTIFICATION:
              case APPROVAL_REQUEST_REJECTED_NOTIFICATION:
              case APPROVAL_REQUEST_APPROVED_NOTIFICATION:
                message = `${this.t('project')} ${this.t(translationKey)}`;
                break;
              default:
                message = this.t(translationKey);
                link = PROJECT_DETAILS_LINK;
                break;
            }
            return this.dataWithLinks(
              this.replaceString(
                stringInject(message, [objectListToInsert[0].label]),
                objectListToInsert[0],
                link,
                dueDate
              ),
              dueDate,
              fontToUseBasedOnStatus
            );
          } else if (
            objectType === OBJECT_TYPE_ENUM.contact.code ||
            objectType === OBJECT_TYPE_ENUM.request.code ||
            objectType === OBJECT_TYPE_ENUM.offer.code
          ) {
            const objectToInsert = messageJSONObject?.objectToInsert[0];
            const message = this.t(translationKey);
            const notificationMessage = stringInject(message, [
              objectToInsert.label,
            ]);

            let link = '';
            switch (objectType) {
              case OBJECT_TYPE_ENUM.request.code:
                link = REQUEST_DETAILS_LINK;
                break;
              case OBJECT_TYPE_ENUM.offer.code:
                link = OFFER_DETAILS_LINK;
                break;
              default:
                break;
            }

            const injectedNotificationMessage = this.replaceString(
              notificationMessage,
              objectToInsert,
              link,
              dueDate
            );

            return this.dataWithLinks(
              injectedNotificationMessage,
              dueDate,
              fontToUseBasedOnStatus
            );
          } else {
            return this.defaultData(description, fontToUseBasedOnStatus);
          }
        }
        return this.defaultData(description, fontToUseBasedOnStatus);

      default:
        return this.defaultData(description, fontToUseBasedOnStatus);
    }
  }

  /**
   * Return JSX Element for data with links
   * @param {*} injectedNotificationMessage
   * @param {*} dueDate
   * @param {*} fontToUseBasedOnStatus
   * @returns JSX Element
   */
  dataWithLinks = (
    injectedNotificationMessage: ReactNode,
    dueDate: INotification['dueDate'],
    fontToUseBasedOnStatus: string
  ) => (
    <React.Fragment>
      <div
        className="text-muted small mt-1"
        style={{ fontWeight: fontToUseBasedOnStatus }}
      >
        <span
          style={{
            color: this.getColorFromDueDate(dueDate),
          }}
        >
          {injectedNotificationMessage}
        </span>
      </div>
    </React.Fragment>
  );

  /**
   * Returns JSX Element for default data
   * @param {*} description
   * @param {*} fontToUseBasedOnStatus
   * @returns JSX Element
   */
  defaultData = (
    description: INotification['msg'],
    fontToUseBasedOnStatus: string
  ) => (
    <React.Fragment>
      <div
        className="text-muted small mt-1"
        style={{ fontWeight: fontToUseBasedOnStatus }}
      >
        {description}
      </div>
    </React.Fragment>
  );

  /**
   * Replaces part of a string with JSX expression with a link
   * @param {*} notificationMessage
   * @param {*} object
   * @param {*} link
   * @param {*} dueDate
   * @returns JSX Element
   */
  replaceString = (
    notificationMessage: ReactNode[] | string,
    object: any,
    link: string,
    dueDate: INotification['dueDate']
  ) => {
    const stringToBeReplaced =
      object === null ? APPROVAL_KEY_LINK : object.label;
    const actualLink =
      object === null ? link : `${link}${Object.values(object)[1]}`;
    return reactStringReplace(
      notificationMessage,
      stringToBeReplaced,
      (match) => (
        <a
          key={match}
          className={link === '' ? 'disabled-link' : 'cat-link'}
          href={actualLink}
          style={{ color: this.getColorFromDueDate(dueDate) }}
        >
          {match}
        </a>
      )
    );
  };

  getColorFromDueDate = (dueDate: INotification['dueDate']) => {
    if (dueDate === null) {
      return '';
    }
    const stringColor =
      moment(dueDate).startOf('day') < moment(new Date()).startOf('day')
        ? 'red'
        : '';
    return stringColor;
  };

  /**
   * Handles updating of notification
   * @param {*} notification
   * @param {*} status
   */
  handleNotificationUpdate = async (
    notification: INotification,
    status: string
  ) => {
    const latestStatus = getLatestItem(notification.notificationStatuses);
    const selectedService = this.state.selectedService;
    let newStatus = null;
    if (
      latestStatus.status === NotificationEnum.New ||
      latestStatus.status === NotificationEnum.Significant ||
      status === NotificationEnum.Removed
    ) {
      newStatus = {
        status,
        date: new Date(),
      };
    } else {
      newStatus = {
        status: NotificationEnum.New,
        date: new Date(),
      };
    }

    const savedStatus = await axios.notification
      .post('notification-statuses/', newStatus)
      .then((response) => response.data)
      .catch((error) => {
        this.props.history?.push({
          pathname: '',
          state: {
            errorMessage: `${error.toString()}. ${this.translation(
              'updateNotificationFailed'
            )}`,
          },
        });
      });

    const newNotification = {
      ...notification,
      notificationStatuses: [
        ...(notification.notificationStatuses as INotificationStatus[]),
        savedStatus,
      ],
    };

    await this.saveUpdatedNotification(
      newNotification,
      selectedService,
      status
    );
  };

  /**
   * Saves the updated notifation
   * @param newNotification
   * @param selectedService
   * @param status
   */
  saveUpdatedNotification = async (
    newNotification: INotification,
    selectedService: string | undefined,
    status: string
  ) => {
    await axios.notification.put('notifications/', newNotification).then(() => {
      this.loadNotificationByService(selectedService);
      this.props.history?.push({
        pathname: '',
        state: {
          successMessage:
            status === NotificationEnum.Removed
              ? 'Notification has been deleted'
              : 'Notification state has been updated',
        },
      });
    });
  };

  /**
   * Function that returns the translated service name
   * @param {*} serviceName
   * @returns translated service name, null for default
   */
  getTranslatedServiceName(serviceName: IRouteCategory['serviceName']) {
    switch (serviceName) {
      case SERVICE_NAMES_ENUM.crm.code:
        return SERVICE_NAMES_ENUM.crm.name;
      case SERVICE_NAMES_ENUM.salesFunnel.code:
        return SERVICE_NAMES_ENUM.salesFunnel.name;
      case SERVICE_NAMES_ENUM.projects.code:
        return SERVICE_NAMES_ENUM.projects.name;
      case SERVICE_NAMES_ENUM.employees.code:
        return SERVICE_NAMES_ENUM.employees.name;
      case SERVICE_NAMES_ENUM.resourcePlanner.code:
        return SERVICE_NAMES_ENUM.resourcePlanner.name;
      case SERVICE_NAMES_ENUM.absencePlanner.code:
        return SERVICE_NAMES_ENUM.absencePlanner.name;
      case SERVICE_NAMES_ENUM.all.code:
        return SERVICE_NAMES_ENUM.all.name;
      default:
        return null;
    }
  }

  /**
   * Method that retrieve notification entries based on the selected serviceName
   * @param {*} serviceName
   * @returns Handles the update of redux store containing the selected fromService to be used for retrieve notification entries
   */
  loadNotificationByService = (serviceName: string | undefined) => {
    const currentUserId = this.props.account?.employeeDetails?.id;
    if (currentUserId === undefined) {
      return;
    }

    // Check if the userSetting of sending a notification via crm tool is included
    const sendNotificationViaList = this.props.selectedEmailOrCRMTool;

    if (
      !sendNotificationViaList?.includes(
        SEND_NOTIFICATIONS_VIA_ENUM.crmTool.code
      )
    ) {
      return;
    }

    // List of selected services fro retrieving notification
    let selectedNotificationFromService =
      this.props.selectedNotificationFromService;

    if (serviceName !== SERVICE_NAMES_ENUM.all.code) {
      selectedNotificationFromService = selectedNotificationFromService.filter(
        (fromService) => fromService === serviceName
      );
    }

    axios.notification
      .get(
        `notifications?employeeId.equals=${currentUserId}&fromService.in=${selectedNotificationFromService}`
      )
      .then((response) => {
        const employeeNotifications = response.data;
        this.props.loadNotifications(SortNotifications(employeeNotifications));
      })
      .catch(() => {
        /* Currently no error handlers
          this.errorHandler(NOTIFICATION_DATA_ERROR, error);
        */
      });

    this.setState({
      selectedService: serviceName,
    });
  };

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps?.toggleNotificationListTable !==
      this.props?.toggleNotificationListTable
    ) {
      this.render();
    }
  }

  render() {
    let selectedNotificationFromService =
      this.props.selectedNotificationFromService;

    const selectedEmailOrCRMTool = this.props.selectedEmailOrCRMTool;

    const notificationsByService =
      this.state.selectedService === SERVICE_NAMES_ENUM.all.code
        ? [...this.props.notifications.notifications]
        : [...this.props.notifications.notifications].filter(
            (notification) =>
              notification?.fromService === this.state.selectedService
          );
    const sortedNotificationByDueDate = sortByDate(
      notificationsByService,
      'dueDate',
      false
    );

    if (
      !selectedEmailOrCRMTool?.includes(
        SEND_NOTIFICATIONS_VIA_ENUM.crmTool.code
      )
    ) {
      selectedNotificationFromService = [];
    }

    const objectRouteCategories = this.state.objectRouteCategories;
    const filterObjectRouteByFromService = objectRouteCategories.filter(
      (objectRoute) =>
        selectedNotificationFromService.find(
          (fromService) => fromService === objectRoute?.serviceName
        ) && Auth.hasPermission(objectRoute.uriList, [READ, READWRITE])
    );

    return (
      <Table fluid="true">
        <tbody>
          <div
            className="flex-fill"
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              background: 'white',
            }}
          >
            <Button
              onClick={() =>
                this.loadNotificationByService(SERVICE_NAMES_ENUM.all.code)
              }
              className={'border-grey'}
              color="white"
            >
              {SERVICE_NAMES_ENUM.all.name}
            </Button>
            {filterObjectRouteByFromService?.map((category, index) => (
              <Button
                className={'border-grey'}
                color="white"
                onClick={() =>
                  this.loadNotificationByService(category.serviceName)
                }
                key={index}
              >
                {this.getTranslatedServiceName(category.serviceName)}
              </Button>
            ))}
          </div>
          <br />
          <ListGroup style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            {!isEmpty(sortedNotificationByDueDate) &&
              sortedNotificationByDueDate?.map((notification, key) => {
                const latestStatus = getLatestItem(
                  notification.notificationStatuses
                );
                if (latestStatus.status === NotificationEnum.Removed) {
                  return '';
                }

                const fontToUseBasedOnStatus =
                  latestStatus.status === NotificationEnum.New ? 'bold' : '';

                const readUnreadIcon =
                  latestStatus.status === NotificationEnum.New ? (
                    <FontAwesomeIcon icon={faEye} className="text-primary" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      className="text-primary"
                    />
                  );

                const item = this.getData(
                  notification.fromService,
                  notification.msg,
                  notification.objectType,
                  fontToUseBasedOnStatus,
                  notification.dueDate
                );

                return (
                  <ListGroupItem key={key}>
                    <Row>
                      <Col>{item}</Col>
                      <Col md="auto">
                        {notification?.dueDate
                          ? moment(notification.dueDate).format(getDateFormat())
                          : ''}
                      </Col>
                      <Col md="auto">
                        <Button
                          color="link"
                          onClick={async () => {
                            await this.handleNotificationUpdate(
                              notification,
                              NotificationEnum.Read
                            );
                          }}
                        >
                          {readUnreadIcon}
                        </Button>
                        <Button
                          color="link"
                          onClick={async () => {
                            await this.handleNotificationUpdate(
                              notification,
                              NotificationEnum.Removed
                            );
                          }}
                        >
                          {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                );
              })}
          </ListGroup>
        </tbody>
      </Table>
    );
  }
}

const mapStateToProps = (store: RootState) => ({
  notifications: store.notifications,
  account: store.account,
});

const mapDispatchToProps = {
  loadNotifications,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withTranslation()(NotificationsListTable));
