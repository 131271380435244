import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { Button, CardBody, CardHeader, Container, Label } from 'reactstrap';

import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { NO_ID } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { isEmpty, sortByPropValue } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IError } from '../../../utils/types/commonTypes';
import { IRole } from '../../../utils/types/modelTypes';
import RoleAddForm from './RoleAddForm';

interface IProps extends IWithModalsProps {}

interface IState {
  roles: IRole[];
  role: string;
  description: string;
}

interface IEntry {
  role: string;
  roleId: number;
  description: string;
  menuComponent: JSX.Element;
}
/*
 * Class that contains all Role Settings in the Permission Settings
 */
class Roles extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      roles: [],
      role: '',
      description: '',
    };
  }

  async componentDidMount() {
    await axios.employee
      .get('roles')
      .then((response: AxiosResponse<IRole[]>) => {
        this.setState({
          roles: response.data,
        });
      })
      .catch((error: AxiosError) => {
        this.props.modalErrorHandler(this.t('failedToRetrieveRoles'), error);
      });
  }

  t(keyname: string) {
    return i18n.t(`Roles.${keyname}`);
  }

  /**
   * Handles deletion of role
   * @param {*} roleId
   */
  deleteRole = (roleId: number) => {
    this.props.modalDeleteHandler(
      this.t('deleteRole'),
      <>
        <h1 style={{ color: 'red' }}>{this.t('warning')}</h1>
        <p>{this.t('initialWarning')}</p>
        <ul>
          <li>{this.t('linkedEmployeeGroups')}</li>
          <li>{this.t('linkedRoleGroups')}</li>
        </ul>
        <p>{this.t('employeeWarning')}</p>
        <p>{this.t('proceed')}</p>
      </>,
      () => {
        axios.employee
          .delete(`roles/${roleId}`)
          .then(() => {
            this.setState({
              roles: this.state.roles.filter((role) => role.id !== roleId),
            });
          })
          .catch((error: AxiosError<IError>) => {
            if (error.response?.data.title === 'Employee Still Connected') {
              this.props.modalOkHandler(
                this.t('warning'),
                <>
                  <p>{this.t('cannotDeleteRole')}</p>
                  <p>{this.t('employeeWarning')}</p>
                </>
              );
            } else {
              this.props.modalErrorHandler(this.t('cannotDeleteRole'), error);
            }
          });
      }
    );
  };

  // Here the Data is prepared to function with the DynamicTable Component
  prepareTabledata = (roles: IRole[]) => {
    if (!isEmpty(roles)) {
      const newTableData: IEntry[] = [];

      roles.forEach((role) => {
        const entry: IEntry = {
          role: role.role,
          roleId: role.id ?? (NO_ID as number),
          description: role.description ?? '',
          menuComponent: (
            <div>
              <Button
                color="primary"
                onClick={() => this.deleteRole(role?.id ?? (NO_ID as number))}
              >
                {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
              </Button>
            </div>
          ),
        };
        newTableData.push(entry);
      });

      return newTableData;
    }
    return [];
  };

  /**
   * Method that updates the list of roles
   */
  onSaveUpdateList = (role: IRole) => {
    const { roles } = this.state;
    roles.push(role);
    this.setState({ roles });
  };

  /**
   * Method that shows the add role modal
   */
  addRole = () => {
    this.props.modalFormHandler(
      this.t('addRole'),
      <RoleAddForm
        roles={this.state.roles}
        onSave={this.onSaveUpdateList}
        onCancel={() => this.props.toggleModalForm()}
      />
    );
  };

  render() {
    const { roles } = this.state;
    // Column Settings for the Dynamic Table
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('role'),
        accessor: 'role',
        show: 'true',
        link: '/settings/permissions/role-details/',
        linkAccessor: 'roleId',
      },
      {
        type: 'data',
        header: this.t('description'),
        accessor: 'description',
        show: 'true',
      },
      {
        type: 'Dropdown',
        header: this.t('menu'),
        show: 'true',
        accessor: 'menuComponent',
      },
    ];
    return (
      <Container fluid>
        <CardHeader>
          <div className="card-actions float-end">
            <Button
              color="primary"
              className="float-end"
              size="m"
              onClick={() => this.addRole()}
            >
              {this.t('addRole')}
            </Button>
          </div>
        </CardHeader>
        <br />
        <CardBody>
          {!isEmpty(roles) ? (
            <DynamicTable
              data={this.prepareTabledata(
                sortByPropValue(roles, 'role') as IRole[]
              )}
              columns={preparedColumns}
            />
          ) : (
            <Label>{this.t('rolesEmpty')}</Label>
          )}
        </CardBody>
      </Container>
    );
  }
}

export default withModals(Roles);
