import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { sortByPropValue } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import AddOrUpdateResponsibleRole from './AddOrUpdateResponsibleRole';

/*
 * Class that shows all the Responsible Role
 */
class ResponsibleRoleListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsibleRoles: [],
      showModal: false,
      showModalError: false,

      showInputForm: false,
      modalForm: null,
      modalTitle: '',
    };
    this.modalEvent = null;

    this.translation = this.props.t;

    this.mainError = '';
    this.errorReason = '';
    this.errorResponse = '';

    this.showInputModal = this.showInputModal.bind(this);
    this.toggleInputModal = this.toggleInputModal.bind(this);
  }

  componentDidMount() {
    axios.sales
      .get('responsible-roles')
      .then((response) => {
        if (Array.isArray(response.data))
          this.setState({ responsibleRoles: response.data });
      })
      .catch((error) => {
        let mainError = this.translation(
          'ResponsibleRoleListView.failedToGetResponsibleRoles'
        );
        this.handleError(mainError, error.message);
      });
  }

  // method to handle all errors encountered.
  // shows the modalError dialog
  handleError = (mainError, errorMessage) => {
    this.mainError = mainError;
    this.errorReason = this.translation('ResponsibleRoleListView.serverFailed');
    this.errorResponse = errorMessage;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  toggleInputModal() {
    this.setState({ showInputForm: !this.state.showInputForm });
  }

  showInputModal = () => {
    this.setState({
      modalForm: <AddOrUpdateResponsibleRole onSave={this.onSaveType} />,
      modalTitle: this.translation(
        'ResponsibleRoleListView.addResponsibleRole'
      ),
    });
    this.toggleInputModal();
  };

  onSaveType = () => {
    this.toggleInputModal();
    this.componentDidMount();
  };

  deleteResponsibleRole = (responsibleRoleId) => {
    this.toggleModal();
    this.modalEvent = () =>
      axios.sales
        .delete(`responsible-roles/${responsibleRoleId}`)
        .then(() => {
          let newResponsibleRoles = this.state.responsibleRoles.filter(
            (responsibleRole) => {
              return responsibleRole.id !== responsibleRoleId;
            }
          );
          this.setState({ responsibleRoles: newResponsibleRoles });
        })
        .catch((error) => {
          let mainError = this.translation(
            'ResponsibleRoleListView.failedToGetResponsibleRoles'
          );
          this.handleError(mainError, error.message);
        });
  };

  preparedTableData = (salesData) => {
    const newTableData = [];
    if (Array.isArray(salesData) && salesData.length > 0) {
      salesData.forEach((responsibleRole) => {
        const entry = {
          id: responsibleRole?.id,
          role: responsibleRole?.role,
          deleteButton: (
            <>
              <Button
                color="primary"
                onClick={() => this.deleteResponsibleRole(responsibleRole.id)}
              >
                {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
              </Button>
            </>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  render() {
    const preparedColumns = [
      {
        type: 'data',
        header: this.translation('ResponsibleRoleListView.role'),
        accessor: 'role',
        show: 'true',
        link: '/settings/sales/responsible-roles-update/',
        linkAccessor: 'id',
      },
      {
        type: 'data',
        header: this.translation('ResponsibleRoleListView.delete'),
        accessor: 'deleteButton',
        show: 'true',
        alignRight: 'true',
      },
    ];
    return (
      <Container fluid>
        <div>
          <Button
            color="primary"
            className="float-end"
            onClick={() => this.showInputModal()}
          >
            {generateTitle(
              BUTTON_TITLE_ENUM.ADD.code,
              this.translation('ResponsibleRoleListView.add')
            )}
          </Button>
          <br />
          <br />
        </div>

        <DynamicTable
          data={this.preparedTableData(
            sortByPropValue(this.state.responsibleRoles, 'role')
          )}
          columns={preparedColumns}
        />

        <ModalDelete
          isOpen={this.state.showModal}
          event={this.modalEvent}
          onClose={this.toggleModal}
          modalTitle={this.translation(
            'ResponsibleRoleListView.responsibleRoleDelete'
          )}
          modalBodyText={this.translation(
            'ResponsibleRoleListView.responsibleRoleDeleteConfirm'
          )}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalForm
          isOpen={this.state.showInputForm}
          eventOnClose={this.toggleInputModal}
          ref={this.state.modalForm}
          modalTitle={this.state.modalTitle}
        >
          {this.state.modalForm}
        </ModalForm>
      </Container>
    );
  }
}

export default withRouter(withTranslation()(ResponsibleRoleListView));
