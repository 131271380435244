import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Label } from 'reactstrap';

import axiosMethods from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { dataGeneration } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import {
  errorHandler,
  isEmpty,
  sortByPropValue,
} from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import { IErrorMessage } from '../../../utils/types/commonTypes';
import {
  IEmployee,
  IFieldOfBusiness,
  IResponsible,
} from '../../../utils/types/modelTypes';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import FieldsOfBusinessAddOrUpdate from './FieldsOfBusinessAddOrUpdate';
import { Ownership } from '../../../utils/enums/ownership';

interface IProps {}

interface IState {
  fieldsOfBusiness: IFieldOfBusiness[];
  modalForm: JSX.Element | null;
  modalTitle: string;
  showInputForm: boolean;
  showModalError: boolean;
  responsibles: IResponsible[];
  involvedResponsibles: IResponsible[];
  responsibleEmployees: IEmployee[];
}
/**
 * This class is for fields of business list
 */
class FieldsOfBusinessView extends React.Component<IProps, IState> {
  errorMessage: IErrorMessage = {} as IErrorMessage;

  modalTitle = '';

  constructor(props: IProps) {
    super(props);
    this.state = {
      fieldsOfBusiness: [],
      modalForm: null,
      modalTitle: '',
      showInputForm: false,
      showModalError: false,
      responsibles: [],
      involvedResponsibles: [],
      responsibleEmployees: [],
    };
  }

  t(keyName: string) {
    return i18n.t('FieldsOfBusinessView.' + keyName);
  }

  /**
   * Handles toggling of error modal
   */
  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  componentDidMount = async () => {
    await axiosMethods.project
      .get('field-of-businesses')
      .then((response: AxiosResponse<IFieldOfBusiness[]>) => {
        if (Array.isArray(response.data)) {
          this.setState({ fieldsOfBusiness: response.data });
        }
      })
      .catch((error: AxiosError) => {
        this.errorMessage = errorHandler(
          dataGeneration,
          error,
          this.t('failedToRetrieveFieldsOfBusiness')
        );
        this.toggleModalError();
      });

    const { responsibleEmployees, involvedResponsibles, responsibles } =
      await this.getResponsibleAndInvolved();

    this.setState({
      responsibleEmployees,
      involvedResponsibles,
      responsibles,
    });
  };

  /**
   * Handles toggling of input modal
   */
  toggleInputModal = () => {
    this.setState({ showInputForm: !this.state.showInputForm });
  };

  /**
   * Handles events after saving
   */
  onSave = async (savedFieldOfBusiness: IFieldOfBusiness) => {
    // Extracts the new field of business
    const { fieldsOfBusiness } = this.state;
    const fobIndex = fieldsOfBusiness.findIndex(
      (fieldsOfBusiness) => fieldsOfBusiness.id === savedFieldOfBusiness.id
    );
    if (fobIndex === -1) {
      fieldsOfBusiness.push(savedFieldOfBusiness);
    } else {
      fieldsOfBusiness[fobIndex] = savedFieldOfBusiness;
    }

    // Refreshes the Responsible and Involved
    const { responsibleEmployees, involvedResponsibles, responsibles } =
      await this.getResponsibleAndInvolved();

    this.setState({
      fieldsOfBusiness,
      responsibleEmployees,
      involvedResponsibles,
      responsibles,
    });

    this.toggleInputModal();
    return;
  };

  /**
   * Removes the deconsted responsible in the list
   * @param deconstedResponsible
   */
  onDeconsteResponsible = (deconstedResponsible: IResponsible) => {
    const responsibles = this.state.responsibles.filter(
      (responsible: IResponsible) =>
        responsible?.id !== deconstedResponsible?.id
    );
    this.setState({ responsibles });
  };

  /**
   * Handles preparation of data to be used in the table
   * @param {*} fieldData
   * @returns table data
   */
  prepareTableData = (fieldData: IFieldOfBusiness[]) => {
    const newTableData: any = [];
    if (!isEmpty(fieldData)) {
      fieldData.forEach((field: IFieldOfBusiness) => {
        const responsible = this.state.responsibles?.find(
          (responsible: IResponsible) => responsible?.objectId === field?.id
        );
        const involved = this.state.involvedResponsibles?.filter(
          (involved: IResponsible) => involved?.objectId === field?.id
        );
        const responsibleEmployee = this.state.responsibleEmployees.find(
          (employee: IEmployee) => employee?.id === responsible?.employeeId
        );

        const FieldsOfBusinessAddOrUpdateProps = {
          onSave: this.onSave,
          fieldOfBusiness: field,
          responsible,
          involvedResponsibles: involved,
          onDeconsteResponsible: this.onDeconsteResponsible,
        };

        const entry = {
          id: field?.id,
          title: field?.title,
          abbreviation: field?.fieldAbbreviation,
          fieldOfBusinessId: field?.fieldOfBusinessId,
          responsible: (
            <>
              <Link
                to={`/employees/employee-list/employee-detail/${responsibleEmployee?.id}`}
              >
                {`${responsibleEmployee?.firstname ?? ''} ${
                  responsibleEmployee?.name ?? ''
                }`}
              </Link>
            </>
          ),
          status: i18n.exists(`FieldsOfBusinessStates.${field?.status}`)
            ? i18n.t(`FieldsOfBusinessStates.${field?.status}`)
            : field?.status,
          actions: (
            <>
              <Button
                color="primary"
                onClick={() => {
                  this.setState({
                    modalForm: (
                      <FieldsOfBusinessAddOrUpdate
                        {...FieldsOfBusinessAddOrUpdateProps}
                      />
                    ),
                    modalTitle: this.t('updateFieldOfBusiness'),
                  });
                  this.toggleInputModal();
                }}
              >
                {generateTitle(BUTTON_TITLE_ENUM.UPDATE.code, this.t('modify'))}
              </Button>
            </>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  private async getResponsibleAndInvolved() {
    const responsibles = await axiosMethods.project
      .get(
        `responsibles?objectType.equals=${OBJECT_TYPE_ENUM.fieldOfBusiness.code}&ownership.in=${Ownership.RESPONSIBLE}`
      )
      .then((response: AxiosResponse<IResponsible[]>) => response.data)
      .catch((error: AxiosError) => {
        this.errorMessage = errorHandler(
          dataGeneration,
          error,
          this.t('failedToRetrieveResponsibles')
        );
        this.toggleModalError();
        return [];
      });

    const responsibleEmployeeIds = [
      ...new Set(
        responsibles.map((responsible: IResponsible) => responsible.employeeId)
      ),
    ];

    const responsibleEmployees = await axiosMethods.project
      .get(`employees?id.in=${responsibleEmployeeIds.join(',')}`)
      .then((response: AxiosResponse<IEmployee[]>) => response.data)
      .catch((error: AxiosError) => {
        this.errorMessage = errorHandler(
          dataGeneration,
          error,
          this.t('failedToRetrieveEmployees')
        );
        this.toggleModalError();
        return [];
      });

    const involvedResponsibles = await axiosMethods.project
      .get(
        `responsibles?objectType.equals=${OBJECT_TYPE_ENUM.fieldOfBusiness.code}&ownership.in=${Ownership.INVOLVED}`
      )
      .then((response: AxiosResponse<IResponsible[]>) => response.data)
      .catch((error: AxiosError) => {
        this.errorMessage = errorHandler(
          dataGeneration,
          error,
          this.t('failedToRetrieveResponsibles')
        );
        this.toggleModalError();
        return [];
      });
    return { responsibleEmployees, involvedResponsibles, responsibles };
  }

  render() {
    const fieldsOfBusiness = this.state.fieldsOfBusiness;
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('title'),
        accessor: 'title',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('abbreviation'),
        accessor: 'abbreviation',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('fieldOfBusinessId'),
        accessor: 'fieldOfBusinessId',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('responsible'),
        accessor: 'responsible',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('status'),
        accessor: 'status',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('action'),
        accessor: 'actions',
        show: 'true',
        alignRight: 'true',
      },
    ];
    return (
      <Container fluid>
        <div>
          <Button
            color="primary"
            className="float-end"
            onClick={() => {
              this.setState({
                modalForm: <FieldsOfBusinessAddOrUpdate onSave={this.onSave} />,
                modalTitle: this.t('addFieldOfBusiness'),
              });
              this.toggleInputModal();
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="margin-right" />{' '}
            {this.t('add')}
          </Button>
          <br />
          <br />
        </div>
        {!isEmpty(fieldsOfBusiness) ? (
          <DynamicTable
            data={this.prepareTableData(
              sortByPropValue(fieldsOfBusiness, 'title')
            )}
            columns={preparedColumns}
          />
        ) : (
          <Label className="text-center" tag="h4">
            {this.t('noFieldsOfBusinessFound')}
          </Label>
        )}
        <ModalForm
          isOpen={this.state.showInputForm}
          eventOnClose={this.toggleInputModal}
          ref={this.state.modalForm}
          modalTitle={this.state.modalTitle}
        >
          {this.state.modalForm}
        </ModalForm>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.errorMessage?.mainError}
          errorReason={this.errorMessage?.errorReason}
          errorResponse={this.errorMessage?.errorResponse}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}

export default FieldsOfBusinessView;
