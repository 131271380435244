import moment, { Moment } from 'moment';

import { ProjectDropdown, ProjectState } from '../../../utils/enums/project';
import Auth from '../../../services/axios/Auth';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { NONE, READWRITE } from '../../../utils/constants';
import { monthsOfYear } from '../../../utils/enums/calendar';
import { IWorkloadBreakdown } from '../../../utils/types/modelTypes';
import i18n from '../../../i18n';

/**
 * Checks for valid start date
 * @param {*} date
 * @returns valid start date
 */
export const acceptedDateStart = (
  date: Moment,
  validUntil: Date | Moment | string
) => {
  if (!validUntil || !moment(validUntil).isValid()) {
    return true;
  }
  return date <= moment(validUntil);
};

/**
 * Checks for a valid end date
 * @param {*} date
 * @returns valid end dates
 */
export const acceptedDateEnd = (
  date: any,
  validFrom: Date | Moment | string
) => {
  if (!validFrom || !moment(validFrom).isValid()) {
    return true;
  }
  return date >= moment(validFrom);
};

/**
 * Check if the project is a project option or not
 * @param {*} project
 * @returns
 * @author Zoren Alvano
 * @createdOn 19/08/2021
 */
export function isProjectOption(state: string) {
  return (
    state === ProjectState.DEFINED ||
    state === ProjectState.VAGUE ||
    state === ProjectState.OFFER_SENT ||
    state === ProjectState.PROPOSAL_AHEAD ||
    state === ProjectState.PROPOSAL_TECHNICAL_GO ||
    state === ProjectState.PITCHING ||
    state === ProjectState.ESTIMATING ||
    state === ProjectState.LOST ||
    state === ProjectState.PLANNING ||
    state === ProjectState.ALIGNING
  );
}

/**
 * Checks if the user has permission to assign to projects, project options or both
 * @returns
 */
export const checkUserForResourceAssignment = () => {
  const assignResourceToAll = Auth.hasPermission(
    [PERMISSION_URI.assignResources.readWrite.uri],
    [READWRITE]
  );

  if (assignResourceToAll) {
    return ProjectDropdown.ALL;
  }

  const assignResourceToOwnedProjectOptions = Auth.hasPermission(
    [PERMISSION_URI.assignResourcesToProjectOptions.readWrite.uri],
    [READWRITE]
  );

  const assignResourceToOwnedProjects = Auth.hasPermission(
    [PERMISSION_URI.assignResourcesToProjects.readWrite.uri],
    [READWRITE]
  );

  if (assignResourceToOwnedProjectOptions && assignResourceToOwnedProjects) {
    return ProjectDropdown.BOTH;
  }
  if (assignResourceToOwnedProjects) {
    return ProjectDropdown.PROJECT;
  }
  if (assignResourceToOwnedProjectOptions) {
    return ProjectDropdown.PROJECT_OPTION;
  }
  return NONE;
};

export const resourceFields = {
  bookedPercentage: 'bookedPercentage',
  plannedPercentage: 'plannedPercentage',
  bookedOrOpenPercentage: 'bookedOrOpenPercentage',
  role: 'role',
  rate: 'rate',
};

export const COLOR_WHITE = 'white';

/**
 * Handles color setting for each cell
 * @param workload
 * @param field
 * @returns
 */
export const setCellColor = (
  workload: IWorkloadBreakdown,
  field: string,
  projectOption: boolean
) => {
  // For project options, set booked percentage column color to light grey
  if (projectOption && field === resourceFields.bookedPercentage) {
    return 'lightgrey';
  }
  if (
    (field === resourceFields.bookedPercentage &&
      workload.bookedPercentage > workload.maxAvailability) ||
    (field === resourceFields.bookedOrOpenPercentage &&
      workload.bookedPercentage + workload.bookedOrOpenPercentage >
        workload.maxAvailability)
  ) {
    return 'lightsalmon';
  }
  return COLOR_WHITE;
};

/**
 * Function to get month options
 * @returns month options
 */
export const getMonthOptions = () => {
  const monthOptions = Object.values(monthsOfYear).map((month) => ({
    value: month.value,
    label: i18n.t(`Months.${month.name}`),
    year: new Date().getFullYear(),
  }));
  return monthOptions;
};
