/* eslint-disable @typescript-eslint/no-floating-promises */
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button } from 'reactstrap';

import i18n from '../../i18n';
import { IDropdownOption } from '../../utils/types/commonTypes';
import { getResponsibleRoles } from '../../services/api/employee';
import withModals, { IWithModalsProps } from '../../utils/withModals';

const t = (key: string) => i18n.t(`ResponsibleDropdown.${key}`);

interface IProps extends IWithModalsProps {
  responsibles: {
    responsible: IDropdownOption<number>;
    responsibleRole: IDropdownOption<number>;
  }[];
  setResponsibles: (
    responsibles: {
      responsible: IDropdownOption<number>;
      responsibleRole: IDropdownOption<number>;
    }[]
  ) => void;
  responsibleOptions: IDropdownOption<number>[];
  disabled?: boolean;
}

/**
 * Displays multiple responsible dropdowns with responsible role dropdowns
 */
const MultipleResponsibleDropdown = ({
  modalErrorHandler,
  responsibles,
  setResponsibles,
  responsibleOptions,
  disabled,
}: IProps) => {
  const [roleOptions, setRoleOptions] = useState<IDropdownOption<number>[]>();

  const fetchRoles = async () => {
    try {
      const { data } = await getResponsibleRoles();
      setRoleOptions(
        data.map(({ id, role }) => ({
          value: id as number,
          label: role as string,
        }))
      );
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveRoles'), error);
    }
  };

  const handleResponsibleChange = (
    responsible: IDropdownOption<number>,
    idx: number
  ) => {
    const modifiedResponsibles = [...responsibles];
    modifiedResponsibles.splice(idx, 1, {
      responsible,
      responsibleRole: responsibles[idx]
        ?.responsibleRole as IDropdownOption<number>,
    });

    setResponsibles(modifiedResponsibles);
  };

  const handleResponsibleRoleChange = (
    responsibleRole: IDropdownOption<number>,
    idx: number
  ) => {
    const modifiedResponsibles = [...responsibles];
    modifiedResponsibles.splice(idx, 1, {
      responsible: responsibles[idx]?.responsible as IDropdownOption<number>,
      responsibleRole,
    });

    setResponsibles(modifiedResponsibles);
  };

  const handleResponsibleDelete = (idx: number) => {
    const modifiedResponsibles = [...responsibles];
    modifiedResponsibles.splice(idx, 1);

    setResponsibles(modifiedResponsibles);
  };

  const responsibleFields = responsibles.map((_, idx) => (
    <div
      key={responsibles[idx]?.responsible?.value || idx}
      className="d-flex mb-1 gap-1"
    >
      <div className="col-6">
        <Select
          aria-label="multiple-responsible-input-responsible"
          value={responsibles[idx]?.responsible}
          options={responsibleOptions}
          onChange={(value: IDropdownOption<number>) =>
            handleResponsibleChange(value, idx)
          }
          isDisabled={disabled}
        />
      </div>
      <div className="col-5">
        <Select
          aria-label="multiple-responsible-input-responsible-role"
          value={responsibles[idx]?.responsibleRole}
          options={roleOptions}
          placeholder={t('responsibleRole')}
          onChange={(value: IDropdownOption<number>) =>
            handleResponsibleRoleChange(value, idx)
          }
          isDisabled={disabled}
        />
      </div>
      <div className="col-1">
        <Button
          data-testid="multiple-responsible-delete-button"
          color="link"
          onClick={() => handleResponsibleDelete(idx)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div>
    </div>
  ));

  const handleResponsibleAdd = () => {
    setResponsibles([
      ...responsibles,
      {
        responsible: {
          value: 0,
          label: '',
        },
        responsibleRole: {
          value: 0,
          label: '',
        },
      } as {
        responsible: IDropdownOption<number>;
        responsibleRole: IDropdownOption<number>;
      },
    ]);
  };

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid="involved-div">
      {responsibleFields}
      <Button
        aria-label="multiple-responsible-button-add"
        color="primary"
        size="sm"
        onClick={() => handleResponsibleAdd()}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faPlus} />
        <span style={{ marginLeft: '5px' }}>{t('add')}</span>
      </Button>
    </div>
  );
};

export default withModals(MultipleResponsibleDropdown);
