import {
  faEye,
  faEyeSlash,
  faFilter,
  faGear,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { Label, Input, Button } from 'reactstrap';
import { v4 } from 'uuid';

import { SortBy, SortType } from '../../utils/enums/sort';
import { IDropdownOption } from '../../utils/types/commonTypes';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import { TableData } from './Table';

import './Table.scss';

/**
 * Form for toggling the visibility of table columns
 */
const ColumnVisibilityForm = ({
  columns,
  onConfirm,
}: {
  columns: { [key: string]: boolean };
  onConfirm: (columnVisiblity: { [key: string]: boolean }) => void;
}) => {
  const [columnVisibility, setColumnVisibility] = useState({ ...columns });

  const handleColumnVisibilityChange = (columnName: string) => {
    setColumnVisibility({
      ...columnVisibility,
      [columnName]: !columnVisibility[columnName],
    });
  };

  const handleOkButtonClick = () => {
    onConfirm(columnVisibility);
  };

  return (
    <>
      <div className="d-flex flex-column">
        {Object.entries(columnVisibility).map(([name, isDisplayed]) => (
          <div key={name} className="d-flex gap-2 align-items-center">
            <FontAwesomeIcon
              aria-label="button-column-visibility"
              icon={isDisplayed ? faEye : faEyeSlash}
              onClick={() => handleColumnVisibilityChange(name)}
            />
            {name}
          </div>
        ))}
      </div>
      <div className="float-end">
        <Button
          aria-label="button-ok"
          color="primary"
          onClick={handleOkButtonClick}
        >
          OK
        </Button>
      </div>
    </>
  );
};

/**
 * Get sort icon depending on values of sort by and sort type,
 * and column selected for sorting
 *
 * @param sortBy Current value of sortBy
 * @param sortType Current value of sortType
 * @param accessor New value of sortBy
 * @returns Default / ascending / descending sort icon
 */
const getSortIcon = (sortBy: SortBy, sortType: SortType, accessor: string) => {
  if (sortBy !== accessor) return faSort;

  return sortType === SortType.ASCENDING ? faSortUp : faSortDown;
};

export enum FilterType {
  DROPDOWN = 'DROPDOWN',
  INPUT = 'INPUT',
}

export type TableColumn = {
  name: string;
  accessor: string;
  disableSort?: boolean;
  filterType?: FilterType;
  filterValue?:
    | IDropdownOption
    | IDropdownOption[]
    | IDropdownOption<number>
    | IDropdownOption<number>[]
    | string;
  filterOptions?: IDropdownOption[] | IDropdownOption<number>[];
};

interface IProps extends IWithModalsProps {
  data: TableData[];
  columns: TableColumn[];
  page: number;
  pageSize: number;
  pageCount: number;
  pageSizeOptions: IDropdownOption<number>[];
  sortBy?: SortBy;
  sortType?: SortType;
  noDataPlaceholder?: string;
  displayFilters?: boolean;
  editableColumns?: boolean;
  loading?: boolean;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  filterFn?: (value: IDropdownOption[] | string, accessor: string) => void;
  sortFn?: (nextSortBy: SortBy) => void;
  setDisplayFilters?: () => void;
}

/**
 * Paginated table that allows highlighting rows, filtering, sorting,
 * and editing displayed table columns. Displays spinner when loading table data.
 */
const DynamicTable = ({
  // Props
  data,
  columns,
  page,
  pageSize,
  pageCount,
  pageSizeOptions,
  sortBy,
  sortType,
  noDataPlaceholder,
  editableColumns,
  loading,
  displayFilters,
  filterFn,
  sortFn,
  setPage,
  setPageSize,
  setDisplayFilters,
  // Modals
  modalFormHandler,
  toggleModalForm,
}: IProps) => {
  const [displayedColumns, setDisplayedColumns] = useState(
    columns.reduce(
      (displayedColumns, { name }) => ({
        ...displayedColumns,
        [name]: true,
      }),
      {} as { [key: string]: boolean }
    )
  );

  const hasFilters = filterFn && setDisplayFilters;
  const hasSort = sortBy && sortType && sortFn;

  const handlePageClick = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  const handlePageSizeSelect = ({ value }: IDropdownOption<number>) => {
    setPageSize(value);
  };

  const handleColumnVisibilityChange = (columnVisibility: {
    [key: string]: boolean;
  }) => {
    setDisplayedColumns({ ...columnVisibility });
    toggleModalForm();
  };

  const handleColumnVisibilityModalDisplay = () => {
    modalFormHandler(
      'Select columns',
      <ColumnVisibilityForm
        columns={displayedColumns}
        onConfirm={handleColumnVisibilityChange}
      />,
      'sm'
    );
  };

  return (
    <>
      <div className="d-flex gap-1 float-end">
        {hasFilters && (
          <Button
            aria-label="button-filter"
            color="primary"
            onClick={setDisplayFilters}
          >
            <FontAwesomeIcon icon={faFilter} />
          </Button>
        )}
        {editableColumns && (
          <Button
            aria-label="button-edit-columns"
            color="primary"
            onClick={handleColumnVisibilityModalDisplay}
          >
            <FontAwesomeIcon icon={faGear} />
          </Button>
        )}
      </div>
      <div className="table-responsive w-100">
        <table
          aria-label="dynamic-table"
          className="table table-light table-striped table-borderless"
        >
          <thead>
            <tr>
              {columns
                .filter(({ name }) => displayedColumns[name])
                .map(
                  ({
                    name,
                    accessor,
                    filterType,
                    filterValue,
                    filterOptions,
                    disableSort = false,
                  }) => (
                    <th key={name} className="align-middle">
                      <div className="d-flex flex-column gap-1">
                        <div className="d-flex gap-2 align-items-center">
                          {name}
                          {hasSort && !disableSort && (
                            <FontAwesomeIcon
                              aria-label="button-sort"
                              icon={getSortIcon(sortBy, sortType, accessor)}
                              onClick={() => sortFn(accessor as SortBy)}
                            />
                          )}
                        </div>
                        {displayFilters &&
                          filterFn &&
                          filterType === FilterType.INPUT && (
                            <div className="w-auto">
                              <Input
                                aria-label="input-filter"
                                type="string"
                                value={filterValue}
                                onChange={({
                                  target: { value },
                                }: ChangeEvent<HTMLInputElement>) =>
                                  filterFn(value, accessor)
                                }
                              />
                            </div>
                          )}
                        {displayFilters &&
                          filterFn &&
                          filterType === FilterType.DROPDOWN && (
                            <Select
                              aria-label="dropdown-filter"
                              value={filterValue}
                              options={filterOptions}
                              onChange={(value: IDropdownOption[]) =>
                                filterFn(value, accessor)
                              }
                              styles={{
                                control: (baseStyles: {
                                  [key: string]: string;
                                }) => ({
                                  ...baseStyles,
                                  fontWeight: 'normal',
                                }),
                                container: (baseStyles: {
                                  [key: string]: string;
                                }) => ({
                                  ...baseStyles,
                                  fontWeight: 'normal',
                                }),
                              }}
                              isMulti
                              isClearable
                              allowSelectAll
                            />
                          )}
                      </div>
                    </th>
                  )
                )}
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data.map((data) => (
                <tr key={v4()}>
                  {columns
                    .filter(({ name }) => displayedColumns[name])
                    .map(({ accessor }) => (
                      <td key={v4()}>{data[accessor]}</td>
                    ))}
                </tr>
              ))}
          </tbody>
          {loading && (
            <ThreeDots
              ariaLabel="loader"
              color="#3e4676"
              height="100"
              width="100"
              visible
            />
          )}
          {!loading && data.length === 0 && (
            <Label className="text-center" tag="h4">
              {noDataPlaceholder}
            </Label>
          )}
        </table>
      </div>
      {data.length > 0 && (
        <div>
          <ReactPaginate
            forcePage={page}
            pageCount={pageCount}
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            onPageChange={handlePageClick}
            containerClassName="paginated-table"
            pageLinkClassName="page"
            previousLinkClassName="page"
            nextLinkClassName="page"
            activeLinkClassName="page-active"
            breakLinkClassName="break"
          />
          <div className="float-end">
            <Label>Data per page:</Label>
            <Select
              aria-label="paginated-table-select"
              options={pageSizeOptions}
              onChange={handlePageSizeSelect}
              value={{ label: pageSize, value: pageSize }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default withModals(DynamicTable);
