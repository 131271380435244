import type { ICampaignManagerContactPersonListItem } from '../utils/types/responseTypes';
import createListSlice, { ListState } from './createListSlice';
import { SortBy, SortType } from '../utils/enums/sort';
import { ICampaignManagerContactPersonListFilters } from '../utils/types/stateTypes';
import type { AppThunk } from './store';
import { getCampaignManagerContactPersonListItems } from '../services/api/campaignManager';
import { createQueryParameters } from '../pages/crm/CampaignManager/campaignManagerHelper';
import { removeDuplicates } from '../utils/helpers/table';
import { maxPageLength } from '../utils/constants';

type CampaignManagerContactPersonListState = ListState<
  ICampaignManagerContactPersonListItem,
  ICampaignManagerContactPersonListFilters
>;

const initialState: CampaignManagerContactPersonListState = {
  listItems: [],
  hasMore: false,
  page: 0,
  pageSize: 0,
  pageCount: 0,
  filters: {
    campaignName: '',
    role: '',
    customer: '',
    customerSite: '',
    functionRoles: [],
    industries: [],
    countries: [],
    statuses: [],
    emailStatuses: [],
    selectedLanguage: [],
    gdprStatuses: [],
    newsletters: [],
    topics: [],
  },
  showFilters: false,
  sortBy: SortBy.LAST_NAME,
  sortType: SortType.ASCENDING,
};

export const campaignManagerContactPersonListSlice = createListSlice({
  name: 'campaignManagerContactPersonList',
  initialState,
  reducers: {},
});

export const {
  setListItems,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  setHasMoreListItems,
} = campaignManagerContactPersonListSlice.actions;

/**
 * Thunk for fetching campaign manager contact persons
 * @param updateSelectedContacts Function to update selected contacts in table
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching customer list items
 */
export const fetchCampaignManagerContactPersonListItems =
  (
    updateSelectedContacts: (
      contacts: ICampaignManagerContactPersonListItem[]
    ) => void,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        filters,
        sortBy,
        sortType,
        page,
        listItems: existingItems,
      } = getState().campaignManagerContactPersonList;
      const {
        data: fetchedListItems,
        data: { length },
      } = await getCampaignManagerContactPersonListItems(
        createQueryParameters(filters, sortBy, sortType, page)
      );

      if (length > 0) {
        dispatch(
          setListItems(removeDuplicates(existingItems, fetchedListItems))
        );
        dispatch(setPage(!(length < maxPageLength) ? page + 1 : page));
        dispatch(setHasMoreListItems(!(length < maxPageLength)));
        updateSelectedContacts(fetchedListItems);
      } else {
        dispatch(setHasMoreListItems(false));
      }
    } catch (error) {
      errorHandler(error);
    }
  };

export default campaignManagerContactPersonListSlice.reducer;
