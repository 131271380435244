import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button } from 'reactstrap';
import Select from 'react-select';

import axios from '../../../services/axios/axios';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { RootState } from '../../../redux/store';
import { IContactPersonDropdownItem } from '../../../utils/types/modelTypes';
import {
  IDropdownOption,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import { generateTitle } from '../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { NO_ID } from '../../../utils/constants';
import { isEmpty } from '../../../utils/helpers/array';
import {
  contactPersonToDropdownOptions,
  objectNameAndIdToDropdownOptions,
  sortOptionsByValue,
} from '../../../utils/helpers/dropdown';
import { IContactPersonDetail } from '../../../utils/types/responseTypes';
import ContactPersonModal from '../ContactPersonModal';

interface IProps extends PropsFromRedux, IWithModalsProps {
  responsibleContactPersons: IObjectNameAndId[];
  customerId: number;
  customerSiteId?: number;
  onChange: (value: IDropdownOption<number>[]) => void;
}

const t = (keyName: string) =>
  i18n.t(`ResponsibleContactPersonEditor.${keyName}`);

const ResponsibleContactPersonEditor = ({
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
  responsibleContactPersons,
  customerId,
  customerSiteId,
  onChange,
}: IProps) => {
  const [selectedContactPersons, setSelectedContactPersons] = useState<
    IDropdownOption<number>[]
  >([]);
  const [dropdownOptions, setDropdownOptions] = useState<
    IDropdownOption<number>[]
  >([]);

  const fetchAllContactPerson = async () => {
    const axiosString = customerId
      ? `contact-person-dropdown?customerId.equals=${customerId}`
      : 'existing-contact-person-list-items';
    await axios.sales
      .get(axiosString)
      .then((response: AxiosResponse<IContactPersonDropdownItem[]>) => {
        if (Array.isArray(response.data)) {
          setDropdownOptions(contactPersonToDropdownOptions(response.data));
        }
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToLoadRecords'), error);
      });
  };

  const handleContactPersonChange = (
    { value }: IDropdownOption,
    index: number
  ) => {
    const newContactPersons = selectedContactPersons;
    newContactPersons[index] =
      dropdownOptions.find((item) => item.value === Number(value)) ??
      ({} as IDropdownOption<number>);
    setSelectedContactPersons([...newContactPersons]);
    onChange(newContactPersons);
  };

  const addExistingResponsibleContactPerson = () => {
    if (
      !selectedContactPersons.some((item) => Object.keys(item).length === 0)
    ) {
      setSelectedContactPersons([
        ...selectedContactPersons,
        {} as IDropdownOption<number>,
      ]);
    }
  };

  const deleteResponsibleContactPerson = (index: number) => {
    const newContactPersons = [...selectedContactPersons];
    newContactPersons.splice(index, 1);
    onChange(newContactPersons.filter((contactPerson) => contactPerson?.value));
    setSelectedContactPersons(newContactPersons);
  };

  const afterAddingNewContactPerson = (contactPerson: IContactPersonDetail) => {
    toggleModalForm();

    const newContactPersons = [
      ...selectedContactPersons,
      {
        value: contactPerson.id ?? NO_ID,
        label: `${contactPerson.firstname} ${contactPerson.lastname}`,
      },
    ];

    setSelectedContactPersons(newContactPersons);
    setDropdownOptions(newContactPersons);

    onChange(newContactPersons);
  };

  useEffect(() => {
    fetchAllContactPerson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  useEffect(() => {
    if (!isEmpty(responsibleContactPersons)) {
      setSelectedContactPersons(
        objectNameAndIdToDropdownOptions(responsibleContactPersons)
      );
    }
  }, [responsibleContactPersons]);

  const addNewResponsibleContactPerson = () => {
    modalFormHandler(
      t('addContactPerson'),
      <ContactPersonModal
        onSave={(contactPersonDetail: IContactPersonDetail) =>
          afterAddingNewContactPerson(contactPersonDetail)
        }
        onCancel={toggleModalForm}
        disableCustomerAndCustomerSite
        customerId={customerId}
        customerSiteId={customerSiteId ?? 0}
      />,
      'xl'
    );
  };

  return (
    <>
      {!isEmpty(selectedContactPersons) && (
        <>
          {selectedContactPersons.map((responsibleContactPerson, index) => (
            <Row key={index}>
              <Col style={{ marginTop: 8 }} xs={10}>
                <Select
                  value={
                    responsibleContactPerson?.value
                      ? dropdownOptions.find(
                          (item) =>
                            item.value === responsibleContactPerson.value
                        )
                      : null
                  }
                  options={sortOptionsByValue(
                    dropdownOptions.filter(
                      (dropdown) =>
                        dropdown.value === responsibleContactPerson.value ||
                        selectedContactPersons.filter(
                          (e) => e.value === dropdown.value
                        ).length === 0
                    ) ?? []
                  )}
                  isMulti={false}
                  onChange={(event: IDropdownOption) =>
                    handleContactPersonChange(event, index)
                  }
                  placeholder={t('select')}
                  index={index}
                  key={index}
                  aria-label="contact-person-select"
                />
              </Col>
              <Col style={{ marginTop: 8 }} xs={2}>
                <Button
                  className="p-1"
                  style={{ height: '100%' }}
                  color="link"
                  onClick={() => deleteResponsibleContactPerson(index)}
                  data-testid="delete-existing-contact-person-button"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Col>
            </Row>
          ))}
          <br />
        </>
      )}{' '}
      <Button
        color="primary"
        size="sm"
        onClick={() => addExistingResponsibleContactPerson()}
        data-testid="add-existing-contact-person-button"
      >
        {generateTitle(BUTTON_TITLE_ENUM.ADD.code, t('existing'))}
      </Button>{' '}
      <Button
        color="primary"
        size="sm"
        onClick={() => addNewResponsibleContactPerson()}
        data-testid="add-new-contact-person-button"
      >
        {generateTitle(BUTTON_TITLE_ENUM.ADD.code, 'new')}
      </Button>
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  account: store.account,
  projects: store.projects,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withModals(connector(ResponsibleContactPersonEditor));
