import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { EntityType } from '../../../utils/enums/pageComponents';
import { getDateFormat } from '../../../utils/helpers/date';
import { isNullOrUndefinedOrEmpty } from '../../../utils/helpers/GenericHelper';
import { isEmpty } from '../../../utils/helpers/array';
import {
  createLinkIfAuthorised,
  userHasPermission,
} from '../../../utils/helpers/permission';
import i18n from '../../../i18n';
import { IProjectOptionListItem } from '../../../utils/types/responseTypes';
import { IProjectOptionListFilters } from '../../../utils/types/stateTypes';
import TableDataComponent from '../../../components/tables/TableDataComponent';

/**
 * Translator function for Project Options List
 * @param keyName Key for phrase to be translated
 * @returns Translated string
 */
export const t = (keyName: string) => i18n.t(`ProjectOptionsList.${keyName}`);

/**
 * Creates the query parameters to be used to fetch data
 * @returns Object containing query parameters
 */
export const createQueryParameters = (
  page: number,
  {
    customer,
    customerSite,
    projectId,
    title,
    responsible,
    state,
    probabilityMin,
    probabilityMax,
    responsibleContactPerson,
    staffedOption,
    createdOn,
    nextAction,
    dueDate,
  }: IProjectOptionListFilters,
  sortBy: string,
  sortType: string
) => ({
  page: page.toString(),
  ...(customer && {
    'customerName.contains': customer,
  }),
  ...(projectId && {
    'projectId.contains': projectId,
  }),
  ...(title && {
    'title.contains': title,
  }),
  ...(customerSite && {
    'customerSiteName.contains': customerSite,
  }),
  ...(!isEmpty(responsible) && {
    'responsibleId.in': responsible.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(state) && {
    'state.in': state.map(({ value }) => value).join(','),
  }),
  ...(!isNullOrUndefinedOrEmpty(probabilityMin) && {
    'projectGainProbability.greaterThanOrEqual': probabilityMin,
  }),
  ...(!isNullOrUndefinedOrEmpty(probabilityMax) && {
    'projectGainProbability.lessThanOrEqual': probabilityMax,
  }),
  ...(responsibleContactPerson && {
    'contactPersons.contains': responsibleContactPerson,
  }),
  ...(!isEmpty(staffedOption) && {
    'staffedOption.in': staffedOption.map(({ value }) => value).join(','),
  }),
  ...(createdOn && {
    'createdOn.greaterThanOrEqual': moment(createdOn).toISOString(),
  }),
  ...(createdOn && {
    'createdOn.lessThanOrEqual': moment(createdOn).endOf('day').toISOString(),
  }),
  ...(!isEmpty(nextAction) && {
    'nextActivity.in': nextAction.map(({ value }) => value).join(','),
  }),
  ...(dueDate && {
    'nextActivityDate.greaterThanOrEqual': moment(dueDate)
      .startOf('day')
      .toISOString(),
  }),
  ...(dueDate && {
    'nextActivityDate.lessThanOrEqual': moment(dueDate)
      .endOf('day')
      .toISOString(),
  }),
  ...(sortBy &&
    sortType && {
      sort: `${sortBy}%2C${sortType}`,
    }),
});

/**
 * Creates project option list entry (row) in Project Option List Table
 * @param projectOptionListItem Item to create a row for in Project Option List Table
 * @returns Row / Project Option Entry for Project Option List Table
 */
export const createProjectOptionEntry = (
  {
    id,
    title,
    customer: { id: customerId, name: customerName },
    projectId,
    projectType,
    customerSite: { id: customerSiteId, name: customerSiteName },
    responsible: { id: responsibleId, name: responsibleName },
    responsibleContactPersons,
    staffedOption,
    createdOn,
    nextAction,
    dueDate,
    projectGainProbability,
    state,
  }: IProjectOptionListItem,
  onDelete: () => void,
  onProjectOptionUpdate: () => void
) => ({
  id,
  customer: createLinkIfAuthorised(
    EntityType.CUSTOMER,
    customerName,
    customerId
  ),
  customerSite: createLinkIfAuthorised(
    EntityType.CUSTOMER_SITE,
    customerSiteName,
    customerSiteId
  ),
  projectId,
  projectType,
  title: (
    <Link to="#" onClick={onProjectOptionUpdate}>
      {title}
    </Link>
  ),
  responsible: createLinkIfAuthorised(
    EntityType.EMPLOYEE,
    responsibleName,
    responsibleId
  ),
  responsibleContactPerson: (
    <TableDataComponent
      components={responsibleContactPersons.map((responsibleContactPerson) => {
        const { id, name } = responsibleContactPerson;

        return createLinkIfAuthorised(EntityType.CONTACT_PERSON, name, id);
      })}
    />
  ),
  staffedOption,
  createdOn: createdOn ? moment(createdOn).format(getDateFormat()) : null,
  nextAction: nextAction
    ? i18n.t(`ProjectActivities.${nextAction.toUpperCase()}`)
    : null,
  dueDate: dueDate ? moment(dueDate).format(getDateFormat()) : null,
  projectGainProbability: `${projectGainProbability || 0}%`,
  state: i18n.t(`ProjectStates.${state.toUpperCase()}`),
  menu: userHasPermission(EntityType.PROJECT, id) ? (
    <div>
      <Button
        color="primary"
        onClick={() => onDelete()}
        aria-label="button-delete"
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </div>
  ) : null,
});
