import { faChartArea } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { Ownership } from '../../../utils/enums/ownership';
import {
  BUTTON_TITLE_ENUM,
  EntityType,
} from '../../../utils/enums/pageComponents';
import { SortBy, SortType } from '../../../utils/enums/sort';
import { isEmpty } from '../../../utils/helpers/array';
import { generateTitle } from '../../../utils/helpers/icon';
import {
  createLinkIfAuthorised,
  userHasPermission,
} from '../../../utils/helpers/permission';
import {
  generateContactPersonTooltip,
  generateCustomerSiteTooltip,
} from '../../../utils/helpers/table';
import i18n from '../../../i18n';
import { ICustomerListItem } from '../../../utils/types/responseTypes';
import { ICustomerListFilters } from '../../../utils/types/stateTypes';
import TableDataComponent from '../../../components/tables/TableDataComponent';

/**
 * Translator function for Customer List
 * @param keyName Key for phrase to be translated
 * @returns Translated string
 */
export const t = (keyName: string) => i18n.t(`CustomerList.${keyName}`);

/**
 * Creates the query parameters to be used to fetch data
 * @returns Object containing query parameters
 */
export const createQueryParameters = (
  page: number,
  {
    name,
    targetPriority,
    state,
    sectors,
    customerSites,
    customerAccount,
    responsible,
    involvedResponsibles,
    ownership,
    responsibleWithPermission,
  }: ICustomerListFilters,
  sortBy: SortBy,
  sortType: SortType
) => ({
  page: page.toString(),
  ...(name && {
    'name.contains': name,
  }),
  ...(!isEmpty(state) && {
    'state.in': state.map(({ value }) => value).join(','),
  }),
  ...(sectors && {
    'sectors.contains': sectors,
  }),
  ...(customerSites && {
    'customerSites.contains': customerSites,
  }),
  ...(!isEmpty(responsible) && {
    'responsibleId.in': responsible.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(involvedResponsibles) && {
    'involvedId.in': involvedResponsibles.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(customerAccount) && {
    'customerAccountId.in': customerAccount.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(targetPriority) && {
    'targetPriority.in': targetPriority.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(ownership) && {
    'ownership.in': `${
      Array.isArray(ownership) ? ownership.join(',') : ownership
    }`,
    ...(responsibleWithPermission && {
      ...(ownership.includes(Ownership.RESPONSIBLE) && {
        'responsibleId.equals': responsibleWithPermission.value.toString(),
      }),
      ...(ownership.includes(Ownership.INVOLVED) && {
        'involvedId.equals': responsibleWithPermission.value.toString(),
      }),
    }),
  }),
  ...(sortBy &&
    sortType && {
      sort: `${sortBy}%2C${sortType}`,
    }),
});

/**
 * Creates the customer entries to be shown in the DynamicTable
 * @param customer
 * @returns customerEntry
 */
export const createCustomerEntry = (
  {
    id,
    name,
    targetPriority,
    state,
    sectors,
    customerSites,
    customerAccount,
    responsible: { id: responsibleId, name: responsibleName },
    involvedResponsibles,
  }: ICustomerListItem,
  onDelete: () => void
) => {
  const { id: accountId, name: accountName } = { ...customerAccount };

  return {
    id,
    checkbox: {
      id,
    },
    name: (
      <Tooltip title={`${t('account')}: ${accountName ?? 'N/A'}`}>
        <span>{createLinkIfAuthorised(EntityType.CUSTOMER, name, id)}</span>
      </Tooltip>
    ),
    targetPriority: i18n.t(`TargetPriority.${targetPriority}`),
    state: i18n.t(`CustomerStates.${state}`),
    sectors: (
      <TableDataComponent components={sectors.map((sector) => sector)} />
    ),
    customerSites: (
      <TableDataComponent
        components={customerSites.map((customerSite) => {
          const { id, name, location } = customerSite;

          return (
            <Tooltip
              key={id}
              title={generateCustomerSiteTooltip(customerSite)}
              leaveTouchDelay={3000}
            >
              <span>{`${name} (${location ?? 'N/A'})`}</span>
            </Tooltip>
          );
        })}
      />
    ),
    customerAccount: createLinkIfAuthorised(
      EntityType.CUSTOMER_ACCOUNT,
      accountName,
      accountId
    ),
    responsible: createLinkIfAuthorised(
      EntityType.EMPLOYEE,
      responsibleName,
      responsibleId
    ),
    involvedResponsibles: involvedResponsibles.map(({ id, name }) => (
      <Row key={id}>
        <Col key={id}>
          {createLinkIfAuthorised(EntityType.EMPLOYEE, name, id)}
        </Col>
      </Row>
    )),
    menuComponent: userHasPermission(EntityType.CUSTOMER, id) ? (
      <div>
        <Link
          to={{
            pathname: `/customer/customers/customer-statistics/${id}`,
          }}
          component={() => (
            <Button color="primary">
              <FontAwesomeIcon icon={faChartArea} />
            </Button>
          )}
        />{' '}
        <Button
          color="primary"
          onClick={() => {
            onDelete();
          }}
          aria-label="button-delete"
        >
          {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
        </Button>
      </div>
    ) : null,
  };
};

/**
 * Create customer entry for the merge customer table
 * @param customer
 * @returns entry
 */
export const createMergeCustomerEntry = ({
  name,
  contactInformation,
  state,
  sectors,
  customerSites,
  contactPersons,
  responsible,
}: ICustomerListItem) => ({
  name,
  phone: (
    <TableDataComponent
      components={contactInformation.map(({ info }) => info)}
    />
  ),
  state: i18n.t(`CustomerStates.${state}`),
  sector: <TableDataComponent components={sectors.map((sector) => sector)} />,
  sites: (
    <TableDataComponent
      components={customerSites.map(
        ({ name, location }) => `${name} (${location})`
      )}
    />
  ),
  contactPerson: (
    <TableDataComponent
      components={contactPersons.map((contactPerson) => (
        <Tooltip
          key={contactPerson.id}
          title={generateContactPersonTooltip(contactPerson)}
        >
          <span>{`${contactPerson.name}`}</span>
        </Tooltip>
      ))}
    />
  ),
  responsible: createLinkIfAuthorised(
    EntityType.EMPLOYEE,
    responsible.name,
    responsible.id
  ),
});
