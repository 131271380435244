import axios, { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { IContactPersonStatisticsListItem } from '../../utils/types/responseTypes';
import { IContactPersonStatistics } from '../../utils/types/modelTypes';
import { urlBuilder } from '../../utils/helpers/url';
import { Url } from './constants';

export const getContactStatisticsListItems = () =>
  trackPromise<AxiosResponse<IContactPersonStatisticsListItem[]>>(
    axios.get(urlBuilder(Url.CONTACT_PERSON_STATISTICS_LIST, {}))
  );

export const saveContactStatistics = (
  contactPersonStatistics: IContactPersonStatistics
) =>
  trackPromise<AxiosResponse<IContactPersonStatistics>>(
    axios.post(
      urlBuilder(Url.CONTACT_PERSON_STATISTICS, {}),
      contactPersonStatistics
    )
  );

export default getContactStatisticsListItems;
