import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from 'reactstrap';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { dateFormat, RECORDS_PER_PAGE } from '../../../utils/constants';
import i18n from '../../../i18n';
import { OfferState } from '../../../utils/enums/offer';
import ModalError from '../../../components/modals/ModalError';

var entityProcessed = { REQUEST: 1, OFFER: 2, ORDER: 3 };

/*
 * Class for generating the Customer Statistics Page for a chosen customer
 */
class CustomerStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null,
      customerRequests: [],
      customerOffers: [],
      averageOffersPerRequest: null,
      offerRecordTableData: [],
      customerOrders: [],
      showModalError: false,
      entitiesChosen: [],
      yearsOption: [],
      yearsChosen: [],
      monthsOption: [],
      monthsChosen: [],
      enableCustomDate: false,
      customDateFrom: null,
      customDateUntil: null,
      requestCurrentPage: 0,
      offerCurrentPage: 0,
      orderCurrentPage: 0,
      requestPagesCount: 0,
      offerPagesCount: 0,
      orderPagesCount: 0,
      paginatedRequests: [],
      paginatedOffers: [],
      paginatedOrders: [],
      itemsPerPage: RECORDS_PER_PAGE[1],
    };
    this.id = this.props.match.params.id;
    this.declinedOffers = 0;
    this.declineRate = 0;
    this.mainError = '';
    this.errorReason = '';
    this.errorResponse = '';
    this.ToggleModalError = this.ToggleModalError.bind(this);
    this.getCustomerInformation = this.getCustomerInformation.bind(this);
    this.getOffersPerRequest = this.getOffersPerRequest.bind(this);
    this.getCustomerOffers = this.getCustomerOffers.bind(this);
    this.recordTotalObjectCreation = this.recordTotalObjectCreation.bind(this);
    this.calculatePriceDifference = this.calculatePriceDifference.bind(this);
    this.handleCustomDateFromChange =
      this.handleCustomDateFromChange.bind(this);
    this.handleCustomDateUntilChange =
      this.handleCustomDateUntilChange.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
  }

  t(keyName) {
    return i18n.t('CustomerStatistics.' + keyName);
  }

  //Functions to execute when the page mounts
  async componentDidMount() {
    this.getCustomerInformation();
    let offers = await this.getCustomerOffers();
    let orders = await this.getCustomerOrders();
    let yearsOption = [];
    if (Array.isArray(offers)) {
      offers.forEach((offer) => {
        let year = moment(offer.date).year();
        if (yearsOption.length === 0) {
          yearsOption.push({ value: year, label: year });
        } else {
          if (!yearsOption.some((yr) => yr.value === year)) {
            yearsOption.push({ value: year, label: year });
          }
        }
      });
      this.setState({ customerOffers: offers }, () => {
        this.getDeclinedOffers(this.state.customerOffers);
      });
      this.paginateData(entityProcessed.OFFER, offers, this.state.itemsPerPage);
    }
    if (Array.isArray(orders)) {
      orders.forEach((order) => {
        let year = moment(order.orderDate).year();
        if (yearsOption.length === 0) {
          yearsOption.push({ value: year, label: year });
        } else {
          if (!yearsOption.some((yr) => yr.value === year)) {
            yearsOption.push({ value: year, label: year });
          }
        }
      });
      this.setState({ customerOrders: orders });
      this.paginateData(entityProcessed.ORDER, orders, this.state.itemsPerPage);
    }
    let monthsOption = moment.months().map((option, i) => ({
      value: i,
      label: option,
    }));
    this.setState({ yearsOption: yearsOption, monthsOption: monthsOption });
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  // Paginated data to show based on how many items per page
  paginateData(entity, entityList, itemsPerPage) {
    let allData = entityList.filter((data) => true);
    let pagesCount = Math.ceil(entityList.length / itemsPerPage?.value);
    let paginatedList = [];
    for (let page = 0; page < pagesCount; page++) {
      let data = allData.splice(0, itemsPerPage?.value);
      paginatedList.push(data);
    }
    switch (entity) {
      case entityProcessed.REQUEST:
        this.setState({
          paginatedRequests: paginatedList,
          requestPagesCount: pagesCount,
          requestCurrentPage:
            pagesCount === 1
              ? 0
              : this.state.requestCurrentPage === pagesCount
              ? pagesCount - 1
              : this.state.requestCurrentPage,
        });
        break;
      case entityProcessed.OFFER:
        this.setState({
          paginatedOffers: paginatedList,
          offerPagesCount: pagesCount,
          offerCurrentPage:
            pagesCount === 1
              ? 0
              : this.state.offerCurrentPage === pagesCount
              ? pagesCount - 1
              : this.state.offerCurrentPage,
        });
        break;
      case entityProcessed.ORDER:
        this.setState({
          paginatedOrders: paginatedList,
          orderPagesCount: pagesCount,
          orderCurrentPage:
            pagesCount === 1
              ? 0
              : this.state.orderCurrentPage === pagesCount
              ? pagesCount - 1
              : this.state.orderCurrentPage,
        });
        break;
      default:
    }
  }

  ToggleModalError() {
    this.setState({ showModalError: !this.state.showModalError });
  }

  //Gets the customer's information
  getCustomerInformation() {
    axios.sales
      .get(`customers/${this.id}`)
      .then((response) => {
        this.setState({ customer: response.data });
      })
      .catch((error) => {
        this.errorHandler('Data Generation', error);
      });
  }

  // Gets the customer's Orders
  async getCustomerOrders() {
    let count = await axios.sales
      .get(`orders/count?customerId.equals=${this.id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler('Data Generation', error);
      });
    let orders = await axios.sales
      .get(`orders?customerId.equals=${this.id}&size=${count}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler('Data Generation', error);
      });
    return orders;
  }

  //Calculates the average number of requests made per request
  getOffersPerRequest(customerRequests) {
    let initialArray = [];
    let finalArray = [];
    for (var index = 0; index < customerRequests.length; index++) {
      initialArray
        .push(
          axios.sales.get(
            `offers/count?requestId.equals=${customerRequests[index].id}`
          )
        )
        .catch((error) => {
          this.errorHandler('Data Generation', error);
        });
    }

    Promise.all(initialArray).then((response) => {
      for (var index = 0; index < response.length; index++) {
        finalArray.push(response[index].data);
      }
      if (customerRequests.length !== 0) {
        this.setState({
          averageOffersPerRequest:
            finalArray.reduce((a, b) => a + b, 0) / customerRequests.length,
        });
      }
    });
  }

  //Gets the offers linked to the customer's requests
  async getCustomerOffers() {
    let count = await axios.sales
      .get(`offers/count?customerId.equals=${this.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        this.errorHandler('Data Generation', error);
      });
    let offers = await axios.sales
      .get(`offers?customerId.equals=${this.id}&size=${count}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        this.errorHandler('Data Generation', error);
      });
    return offers;
  }

  //Gets the offer rejection rate of the customer
  getDeclinedOffers(customerOffers) {
    for (var index = 0; index < customerOffers.length; index++) {
      if (customerOffers[index].offerState === OfferState.DECLINED) {
        this.declinedOffers += 1;
      }
    }
    if (this.state.customerOffers.length !== 0) {
      this.declineRate =
        (this.declinedOffers / this.state.customerOffers.length) * 100;
      this.declineRate = this.declineRate.toFixed(2);
    }
  }

  //Generates the table data to be displayed in the customer statistics page
  //The table contains the following: request, firstOfferSum, lastOfferSum, priceDifference
  recordTotalObjectCreation(
    requestId,
    requestTitle,
    firstOffer,
    lastOffer,
    lastOfferStatus,
    offerRecordList
  ) {
    let firstOfferSum = 0;
    let lastOfferSum = 0;
    let taxIncrease = null;
    let firstOfferId = null;
    let lastOfferId = null;

    for (var index = 0; index < offerRecordList.length; index++) {
      let itemCost =
        offerRecordList[index].quantity === null
          ? offerRecordList[index].price
          : offerRecordList[index].price * offerRecordList[index].quantity;

      if (offerRecordList[index].offer?.id === firstOffer) {
        firstOfferSum = firstOfferSum + itemCost;
        firstOfferId = offerRecordList[index].offer?.id;
      }

      if (offerRecordList[index].offer?.id === lastOffer) {
        if (lastOfferStatus === OfferState.ACCEPTED) {
          lastOfferSum = lastOfferSum + itemCost;
          taxIncrease = itemCost * offerRecordList[index].taxRate;
          lastOfferId = offerRecordList[index].offer?.id;
        } else {
          lastOfferSum = null;
        }
      }
    }
    return {
      requestId: requestId,
      requestTitle: requestTitle,
      firstOfferSum: firstOfferSum,
      lastOfferSum: lastOfferSum,
      taxIncrease: taxIncrease,
      firstOfferId: firstOfferId,
      lastOfferId: lastOfferId,
      priceDifference:
        lastOfferStatus === OfferState.ACCEPTED
          ? this.calculatePriceDifference(firstOfferSum, lastOfferSum) + '%'
          : null,
    };
  }

  //Calculating the price difference (in percentage value) between the first offer sum and the last offer sum
  calculatePriceDifference(firstOfferSum, lastOfferSum) {
    var priceDifference =
      ((lastOfferSum - firstOfferSum) / firstOfferSum) * 100;
    priceDifference = priceDifference.toFixed(2);
    return priceDifference;
  }

  //Error handling function for this file
  errorHandler(currentOperation, error) {
    if (currentOperation === 'Data Generation') {
      this.mainError = this.t('generateDataFailed');
    }
    this.errorReason = this.t('serverFailed');
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.ToggleModalError();
    }
  }

  // Handles changes made to chosen entities to show
  changeEntityChoices = (entities) => {
    if (entities && entities.length > 0) {
      var entitiesChosen = [];
      for (var entity of entities) {
        entitiesChosen.push(entity.value);
      }
      this.setState({ entitiesChosen: entitiesChosen });
    } else {
      this.setState({ entitiesChosen: [] });
    }
  };

  // Handles changes made to what year will be shown
  changeYearChoices = (years) => {
    if (years && years.length > 0) {
      var yearsChosen = [];
      for (var year of years) {
        yearsChosen.push(year.value);
      }
      this.setState({ yearsChosen: yearsChosen }, () => this.setDataToShow());
    } else {
      this.setState({ yearsChosen: [] }, () => this.setDataToShow());
    }
  };

  // Handles changes made to what month/s will be shown
  changeMonthChoices = (months) => {
    if (months && months.length > 0) {
      var monthsChosen = [];
      for (var month of months) {
        monthsChosen.push(month.value);
      }
      this.setState({ monthsChosen: monthsChosen }, () => this.setDataToShow());
    } else {
      this.setState({ monthsChosen: [] }, () => this.setDataToShow());
    }
  };

  // Filters Offers and Orders based on custom date
  setCustomDateData = (event) => {
    event.preventDefault();
    if (
      this.state.customDateFrom === null ||
      this.state.customDateUntil === null
    ) {
      this.mainError = this.t('selectStartAndEndDate');
      this.errorReason = this.errorResponse = '';
      if (!this.state.showModalError) {
        this.ToggleModalError();
      }
    } else if (
      !moment(this.state.customDateUntil).isAfter(
        moment(this.state.customDateFrom)
      ) &&
      !moment(this.state.customDateUntil).isSame(
        moment(this.state.customDateFrom)
      )
    ) {
      this.mainError = this.t('endDateRequirement');
      this.errorReason = this.errorResponse = '';
      if (!this.state.showModalError) {
        this.ToggleModalError();
      }
    } else {
      // handle changes
      let offers = this.state.customerOffers.filter(
        (offer) =>
          moment(offer?.date) >= moment(this.state.customDateFrom) &&
          moment(offer?.date) <= moment(this.state.customDateUntil)
      );
      let orders = this.state.customerOrders.filter(
        (order) =>
          moment(order?.orderDate) >= moment(this.state.customDateFrom) &&
          moment(order?.orderDate) <= moment(this.state.customDateUntil)
      );
      let request = this.state.customerRequests.filter(
        (request) =>
          moment(request?.requestDate) >= moment(this.state.customDateFrom) &&
          moment(request?.requestDate) <= moment(this.state.customDateUntil)
      );
      this.paginateData(entityProcessed.OFFER, offers, this.state.itemsPerPage);
      this.paginateData(entityProcessed.ORDER, orders, this.state.itemsPerPage);
      this.paginateData(
        entityProcessed.REQUEST,
        request,
        this.state.itemsPerPage
      );
    }
  };

  // set data to show based on years and months filters
  setDataToShow() {
    if (
      this.state.yearsChosen.length === 0 &&
      this.state.monthsChosen.length === 0
    ) {
      this.paginateData(
        entityProcessed.OFFER,
        this.state.customerOffers,
        this.state.itemsPerPage
      );
      this.paginateData(
        entityProcessed.ORDER,
        this.state.customerOrders,
        this.state.itemsPerPage
      );
      this.paginateData(
        entityProcessed.REQUEST,
        this.state.customerRequests,
        this.state.itemsPerPage
      );
    } else {
      let offers = this.state.customerOffers.filter(() => true);
      let orders = this.state.customerOrders.filter(() => true);
      let request = this.state.customerRequests.filter(() => true);
      if (this.state.yearsChosen.length > 0) {
        offers = offers.filter((offer) =>
          this.state.yearsChosen.includes(moment(offer?.date).year())
        );
        orders = orders.filter((order) =>
          this.state.yearsChosen.includes(moment(order?.orderDate).year())
        );
        request = request.filter((request) =>
          this.state.yearsChosen.includes(moment(request?.requestDate).year())
        );
      }
      if (this.state.monthsChosen.length > 0) {
        offers = offers.filter((offer) =>
          this.state.monthsChosen.includes(moment(offer?.date).month())
        );
        orders = orders.filter((order) =>
          this.state.monthsChosen.includes(moment(order?.orderDate).month())
        );
        request = request.filter((request) =>
          this.state.monthsChosen.includes(moment(request?.requestDate).month())
        );
      }
      this.paginateData(entityProcessed.OFFER, offers, this.state.itemsPerPage);
      this.paginateData(entityProcessed.ORDER, orders, this.state.itemsPerPage);
      this.paginateData(
        entityProcessed.REQUEST,
        request,
        this.state.itemsPerPage
      );
    }
  }
  // handles changes on start date of Custom date
  handleCustomDateFromChange(e) {
    e.preventDefault();
    this.setState({ customDateFrom: new Date(e.target.value) });
  }
  // handles changes on end date of Custom date
  handleCustomDateUntilChange(e) {
    e.preventDefault();
    this.setState({ customDateUntil: new Date(e.target.value) });
  }

  handleEnableCustomDateChange = () => {
    if (this.state.enableCustomDate) {
      this.setState({ customDateFrom: null, customDateUntil: null });
      this.paginateData(
        entityProcessed.REQUEST,
        this.state.customerRequests,
        this.state.itemsPerPage
      );
      this.paginateData(
        entityProcessed.OFFER,
        this.state.customerOffers,
        this.state.itemsPerPage
      );
      this.paginateData(
        entityProcessed.ORDER,
        this.state.customerOrders,
        this.state.itemsPerPage
      );
    } else {
      this.setState({ yearsChosen: [], monthsChosen: [] });
    }
    this.setState({ enableCustomDate: !this.state.enableCustomDate });
  };

  // Handles changing of current page
  handlePageChange(event, index, entity) {
    event.preventDefault();
    switch (entity) {
      case entityProcessed.REQUEST:
        this.setState({ requestCurrentPage: index });
        break;
      case entityProcessed.OFFER:
        this.setState({ offerCurrentPage: index });
        break;
      case entityProcessed.ORDER:
        this.setState({ orderCurrentPage: index });
        break;
      default:
    }
  }

  changeItemsPerPage = (items) => {
    this.setState({
      itemsPerPage: items,
      requestCurrentPage: 0,
      offerCurrentPage: 0,
      orderCurrentPage: 0,
    });
    let requests = this.getEntityList(this.state.paginatedRequests);
    let offers = this.getEntityList(this.state.paginatedOffers);
    let orders = this.getEntityList(this.state.paginatedOrders);
    this.paginateData(entityProcessed.REQUEST, requests, items);
    this.paginateData(entityProcessed.OFFER, offers, items);
    this.paginateData(entityProcessed.ORDER, orders, items);
  };

  // Method to convert paginatedList to a normal List
  getEntityList(paginatedList) {
    let entityList = [];
    paginatedList.forEach((list) => {
      list.forEach((data) => {
        entityList.push(data);
      });
    });
    return entityList;
  }

  // Method to get the total items in a paginated list
  getTotal(paginatedList) {
    let count = 0;
    paginatedList.forEach((list) => {
      count += list.length;
    });
    return count;
  }

  prepareTableData = (data) => {
    if (Array.isArray(data) && data.length > 0) {
      let newTableData = [];

      data.forEach((individualData) => {
        let entry = {
          requestId: individualData.requestId,
          requestTitle: individualData.requestTitle,
          firstOfferSum: individualData.firstOfferSum,
          firstOfferId: individualData.firstOfferId,
          lastOfferSum: individualData.lastOfferSum,
          lastOfferId: individualData.lastOfferId,
          taxIncrease: individualData.taxIncrease,
          priceDifference: individualData.priceDifference,
        };

        newTableData.push(entry);
      });

      return newTableData;
    } else {
      return [];
    }
  };

  render() {
    var entityChoices = [
      { value: 'REQUEST', label: this.t('request') },
      { value: 'OFFER', label: this.t('offer') },
      { value: 'ORDER', label: this.t('order') },
    ];
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('request'),
        accessor: 'requestTitle',
        show: 'true',
        filterkey: 'requestTitle',
        showsearch: 'true',
        link: `/sales/request/details/`,
        linkAccessor: 'requestId',
      },
      {
        type: 'data',
        header: this.t('firstOfferNetSumTotal'),
        accessor: 'firstOfferSum',
        show: 'true',
        filterkey: 'firstOfferSum',
        showsearch: 'true',
        link: `/sales/offer/details/`,
        linkAccessor: 'firstOfferId',
      },
      {
        type: 'data',
        header: this.t('lastOfferNetSumTotal'),
        accessor: 'lastOfferSum',
        show: 'true',
        filterkey: 'lastOfferSum',
        showsearch: 'true',
        link: `/sales/offer/details/`,
        linkAccessor: 'lastOfferId',
      },
      {
        type: 'data',
        header: this.t('lastOfferTaxSumTotal'),
        accessor: 'taxIncrease',
        show: 'true',
        filterkey: 'taxIncrease',
        alignleft: 'true',
      },
      {
        type: 'data',
        header: this.t('priceDifference'),
        accessor: 'priceDifference',
        show: 'true',
        filterkey: 'priceDifference',
        alignleft: 'true',
      },
    ];
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{this.t('customerStatistics')}</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/customer/customers">{this.t('salesManagement')}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/customer/customers">{this.t('customers')}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {this.t('customerStatistics')}
            </BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <Card>
          <CardHeader>
            <CardTitle>
              <h1>
                {this.state.customer?.name}
                {this.t('statistics')}
              </h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg="6">
                <Table striped className="my-0">
                  <tbody>
                    <tr>
                      <th className="d-none d-xl-table-cell">
                        <h4>{this.t('numberOfRequestsMade')}</h4>
                      </th>
                      <td className="d-none d-xl-table-cell">
                        <h4>{this.state.customerRequests?.length}</h4>
                      </td>
                    </tr>
                    <tr>
                      <th className="d-none d-xl-table-cell">
                        <h4>{this.t('numberOfOffersReceived')}</h4>
                      </th>
                      <td className="d-none d-xl-table-cell">
                        <h4>{this.state.customerOffers?.length}</h4>
                      </td>
                    </tr>
                    <tr>
                      <th className="d-none d-xl-table-cell">
                        <h4>{this.t('averageNumberOfOffers')}</h4>
                      </th>
                      <td className="d-none d-xl-table-cell">
                        <h4>{Math.ceil(this.state.averageOffersPerRequest)}</h4>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col lg="6">
                <Table striped className="my-0">
                  <tbody>
                    <tr>
                      <th className="d-none d-xl-table-cell">
                        <h4>{this.t('numberOfDeclinedOffers')}</h4>
                      </th>
                      <td className="d-none d-xl-table-cell">
                        <h4>{this.declinedOffers}</h4>
                      </td>
                    </tr>
                    <tr>
                      <th className="d-none d-xl-table-cell">
                        <h4>{this.t('offerRejectionRate')}</h4>
                      </th>
                      <td className="d-none d-xl-table-cell">
                        <h4>{this.declineRate}%</h4>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
          <CardBody>
            <CardTitle>
              <h1>{this.t('requestsWithPricedOffers')}</h1>
            </CardTitle>
            {Array.isArray(this.state.offerRecordTableData) &&
              (this.state.offerRecordTableData.length > 0 ? (
                <DynamicTable
                  data={this.prepareTableData(this.state.offerRecordTableData)}
                  columns={preparedColumns}
                />
              ) : (
                <div className="text-center">
                  <Label>{this.t('noRequestsWithPricedOffers')}</Label>
                </div>
              ))}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>
              <h4>{this.t('viewRelatedRequestsOffersAndOrders')}</h4>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <Label style={{ fontWeight: 'bold' }}>
                  {this.t('filterOptions')}
                </Label>
              </Col>
            </Row>
            <Row>
              <Col md={3} style={{ position: 'relative' }}>
                <Label>{this.t('selectEntitiesToShow')}</Label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions={entityChoices}
                  onChange={this.changeEntityChoices}
                  closeMenuOnSelect={true}
                  isClearable
                  isMulti
                  placeholder={this.t('selectEntityEntities')}
                  styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
                />
              </Col>
              <Col md={3} style={{ position: 'relative' }}>
                <Label>{this.t('itemsPerPage')}</Label>
                <Select
                  options={RECORDS_PER_PAGE}
                  onChange={this.changeItemsPerPage}
                  placeholder={this.t('selectItemsPerPage')}
                  value={this.state.itemsPerPage}
                  styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg="3">
                <Label>{this.t('enableCustomDateSearch')}</Label>
              </Col>
              <Col>
                <Input
                  type="checkbox"
                  onChange={this.handleEnableCustomDateChange}
                  value={this.state.enableCustomDate}
                />
              </Col>
            </Row>
            {this.state.enableCustomDate ? (
              <div>
                <Row>
                  <Col md={3} style={{ position: 'relative', zIndex: 5 }}>
                    <Label>{this.t('selectStartDate')}</Label>
                    <Input
                      type="date"
                      onChange={this.handleCustomDateFromChange}
                      value={
                        this.state.customDateFrom
                          ? moment(this.state.customDateFrom).format(
                              'YYYY-MM-DD'
                            )
                          : null
                      }
                    />
                  </Col>
                  <Col md={3} style={{ position: 'relative', zIndex: 5 }}>
                    <Label>{this.t('selectEndDate')}</Label>
                    <Input
                      type="date"
                      onChange={this.handleCustomDateUntilChange}
                      value={
                        this.state.customDateUntil
                          ? moment(this.state.customDateUntil).format(
                              'YYYY-MM-DD'
                            )
                          : null
                      }
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={3} style={{ position: 'relative', zIndex: 5 }}>
                    <Button color="primary" onClick={this.setCustomDateData}>
                      {this.t('filter')}
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <Row>
                <Col md={3} style={{ position: 'relative', zIndex: 5 }}>
                  <Label>{this.t('selectYears')}:</Label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={this.state.yearsOption}
                    onChange={this.changeYearChoices}
                    closeMenuOnSelect={true}
                    isClearable
                    isMulti
                    placeholder={this.t('selectYears')}
                  />
                </Col>
                <Col md={3} style={{ position: 'relative', zIndex: 5 }}>
                  <Label>{this.t('selectMonths')}:</Label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={this.state.monthsOption}
                    onChange={this.changeMonthChoices}
                    closeMenuOnSelect={true}
                    isClearable
                    isMulti
                    placeholder={this.t('selectMonths')}
                  />
                </Col>
              </Row>
            )}
            <br />
            <Label style={{ fontWeight: 'bold' }}>{this.t('summary')}</Label>
            <Col lg="6">
              <Table striped className="my-0">
                <tbody>
                  {this.state.entitiesChosen.includes('REQUEST') ||
                  this.state.entitiesChosen.length === 0 ? (
                    <tr>
                      <th className="d-none d-xl-table-cell">
                        {this.t('totalRequests')}
                      </th>
                      <td className="d-none d-xl-table-cell">
                        {this.getTotal(this.state.paginatedRequests)}
                      </td>
                    </tr>
                  ) : null}
                  {this.state.entitiesChosen.includes('OFFER') ||
                  this.state.entitiesChosen.length === 0 ? (
                    <tr>
                      <th className="d-none d-xl-table-cell">
                        {this.t('totalOffers')}
                      </th>
                      <td className="d-none d-xl-table-cell">
                        {this.getTotal(this.state.paginatedOffers)}
                      </td>
                    </tr>
                  ) : null}
                  {this.state.entitiesChosen.includes('ORDER') ||
                  this.state.entitiesChosen.length === 0 ? (
                    <tr>
                      <th className="d-none d-xl-table-cell">
                        {this.t('totalOrders')}
                      </th>
                      <td className="d-none d-xl-table-cell">
                        {this.getTotal(this.state.paginatedOrders)}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </Table>
            </Col>
          </CardBody>
        </Card>
        {this.state.entitiesChosen.includes('REQUEST') ||
        this.state.entitiesChosen.length === 0 ? (
          <Card>
            <CardHeader>
              <CardTitle>
                <h3>{this.t('requests')}</h3>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>{this.t('id')}</th>
                    <th>{this.t('title')}</th>
                    <th>{this.t('state')}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.paginatedRequests[
                    this.state.requestCurrentPage
                  ]?.map((request, i) => (
                    <tr key={`entity-${i}`}>
                      <td>{request?.id}</td>
                      <td>
                        <Link to={`/sales/request/details/${request.id}`}>
                          {request?.title}
                        </Link>
                      </td>
                      <td>
                        {request?.requestState
                          ? i18n.exists(
                              `requestStatesModel.${request?.requestState.toLowerCase()}`
                            )
                            ? i18n.t(
                                `requestStatesModel.${request?.requestState.toLowerCase()}`
                              )
                            : request?.requestState
                          : 'N/A'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
            <Pagination size="lg" aria-label="Page navigation example">
              <PaginationItem disabled={this.state.requestCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    this.handlePageChange(e, 0, entityProcessed.REQUEST)
                  }
                  first
                  href="#"
                />
              </PaginationItem>
              <PaginationItem disabled={this.state.requestCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    this.handlePageChange(
                      e,
                      this.state.requestCurrentPage - 1,
                      entityProcessed.REQUEST
                    )
                  }
                  previous
                  href="#"
                />
              </PaginationItem>
              {[...Array(this.state.requestPagesCount)].map((page, i) => (
                <PaginationItem
                  active={i === this.state.requestCurrentPage}
                  key={i}
                >
                  <PaginationLink
                    href="#"
                    onClick={(e) =>
                      this.handlePageChange(e, i, entityProcessed.REQUEST)
                    }
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                disabled={
                  this.state.requestCurrentPage >=
                  this.state.requestPagesCount - 1
                }
              >
                <PaginationLink
                  onClick={(e) =>
                    this.handlePageChange(
                      e,
                      this.state.requestCurrentPage + 1,
                      entityProcessed.REQUEST
                    )
                  }
                  next
                  href="#"
                />
              </PaginationItem>
              <PaginationItem
                disabled={
                  this.state.requestCurrentPage >=
                  this.state.requestPagesCount - 1
                }
              >
                <PaginationLink
                  onClick={(e) =>
                    this.handlePageChange(
                      e,
                      this.state.requestPagesCount - 1,
                      entityProcessed.REQUEST
                    )
                  }
                  last
                  href="#"
                />
              </PaginationItem>
            </Pagination>
          </Card>
        ) : null}
        {this.state.entitiesChosen.includes('OFFER') ||
        this.state.entitiesChosen.length === 0 ? (
          <Card>
            <CardHeader>
              <CardTitle>
                <h3>{this.t('offers')}</h3>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>{this.t('id')}</th>
                    <th>{this.t('title')}</th>
                    <th>{this.t('date')}</th>
                    <th>{this.t('state')}</th>
                    <th>{this.t('request')}</th>
                    <th>{this.t('order')}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.paginatedOffers[this.state.offerCurrentPage]?.map(
                    (offer, i) => (
                      <tr key={`entity-${i}`}>
                        <td>{offer?.id}</td>
                        <td>
                          <Link to={`/sales/offer/details/${offer.id}`}>
                            {offer?.title}
                          </Link>
                        </td>
                        <td>{moment(offer?.date).format(dateFormat)}</td>
                        <td>
                          {i18n.exists(
                            `offer_states_model.${offer?.offerState.toLowerCase()}`
                          )
                            ? i18n.t(
                                `offer_states_model.${offer?.offerState.toLowerCase()}`
                              )
                            : offer?.offerState}
                        </td>
                        <td>
                          <Link
                            to={`/sales/request/details/${offer.request?.id}`}
                          >
                            {offer?.request?.title}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/sales/order/order-detail/${offer.order?.id}`}
                          >
                            {offer?.order?.title}
                          </Link>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </CardBody>
            <Pagination size="lg" aria-label="Page navigation example">
              <PaginationItem disabled={this.state.offerCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    this.handlePageChange(e, 0, entityProcessed.OFFER)
                  }
                  first
                  href="#"
                />
              </PaginationItem>
              <PaginationItem disabled={this.state.offerCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    this.handlePageChange(
                      e,
                      this.state.offerCurrentPage - 1,
                      entityProcessed.OFFER
                    )
                  }
                  previous
                  href="#"
                />
              </PaginationItem>
              {[...Array(this.state.offerPagesCount)].map((page, i) => (
                <PaginationItem
                  active={i === this.state.offerCurrentPage}
                  key={i}
                >
                  <PaginationLink
                    href="#"
                    onClick={(e) =>
                      this.handlePageChange(e, i, entityProcessed.OFFER)
                    }
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                disabled={
                  this.state.offerCurrentPage >= this.state.offerPagesCount - 1
                }
              >
                <PaginationLink
                  onClick={(e) =>
                    this.handlePageChange(
                      e,
                      this.state.offerCurrentPage + 1,
                      entityProcessed.OFFER
                    )
                  }
                  next
                  href="#"
                />
              </PaginationItem>
              <PaginationItem
                disabled={
                  this.state.offerCurrentPage >= this.state.offerPagesCount - 1
                }
              >
                <PaginationLink
                  onClick={(e) =>
                    this.handlePageChange(
                      e,
                      this.state.offerPagesCount - 1,
                      entityProcessed.OFFER
                    )
                  }
                  last
                  href="#"
                />
              </PaginationItem>
            </Pagination>
          </Card>
        ) : null}
        {this.state.entitiesChosen.includes('ORDER') ||
        this.state.entitiesChosen.length === 0 ? (
          <Card>
            <CardHeader>
              <CardTitle>
                <h3>{this.t('orders')}</h3>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>{this.t('id')}</th>
                    <th>{this.t('title')}</th>
                    <th>{this.t('orderDate')}</th>
                    <th>{this.t('orderStatus')}</th>
                    <th>{this.t('projectStart')}</th>
                    <th>{this.t('projectEnd')}</th>
                    <th>{this.t('offer')}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.paginatedOrders[this.state.orderCurrentPage]?.map(
                    (order, i) => (
                      <tr key={`entity-${i}`}>
                        <td>{order?.id}</td>
                        <td>
                          <Link to={`/sales/order/order-detail/${order.id}`}>
                            {order?.title}
                          </Link>
                        </td>
                        <td>{moment(order?.orderDate).format(dateFormat)}</td>
                        <td>
                          {i18n.exists(
                            `order_states_model.${order?.orderState.toLowerCase()}`
                          )
                            ? i18n.t(
                                `order_states_model.${order?.orderState.toLowerCase()}`
                              )
                            : order?.orderState}
                        </td>
                        <td>{moment(order?.orderStart).format(dateFormat)}</td>
                        <td>{moment(order?.orderEnd).format(dateFormat)}</td>
                        <td>
                          <Link to={`/sales/offer/details/${order?.offer?.id}`}>
                            {order?.offer?.title}
                          </Link>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </CardBody>
            <Pagination size="lg" aria-label="Page navigation example">
              <PaginationItem disabled={this.state.orderCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    this.handlePageChange(e, 0, entityProcessed.ORDER)
                  }
                  first
                  href="#"
                />
              </PaginationItem>
              <PaginationItem disabled={this.state.orderCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    this.handlePageChange(
                      e,
                      this.state.orderCurrentPage - 1,
                      entityProcessed.ORDER
                    )
                  }
                  previous
                  href="#"
                />
              </PaginationItem>
              {[...Array(this.state.orderPagesCount)].map((page, i) => (
                <PaginationItem
                  active={i === this.state.orderCurrentPage}
                  key={i}
                >
                  <PaginationLink
                    href="#"
                    onClick={(e) =>
                      this.handlePageChange(e, i, entityProcessed.ORDER)
                    }
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                disabled={
                  this.state.orderCurrentPage >= this.state.orderPagesCount - 1
                }
              >
                <PaginationLink
                  onClick={(e) =>
                    this.handlePageChange(
                      e,
                      this.state.orderCurrentPage + 1,
                      entityProcessed.ORDER
                    )
                  }
                  next
                  href="#"
                />
              </PaginationItem>
              <PaginationItem
                disabled={
                  this.state.orderCurrentPage >= this.state.orderPagesCount - 1
                }
              >
                <PaginationLink
                  onClick={(e) =>
                    this.handlePageChange(
                      e,
                      this.state.orderPagesCount - 1,
                      entityProcessed.ORDER
                    )
                  }
                  last
                  href="#"
                />
              </PaginationItem>
            </Pagination>
          </Card>
        ) : null}
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.ToggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </Container>
    );
  }
}

export default CustomerStatistics;
