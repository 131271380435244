import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React from 'react';

import i18n from '../../i18n';
import { BUTTON_TITLE_ENUM } from '../enums/pageComponents';

/**
 * Function that generates icon or icon with text for buttons or title headers
 * @param icon
 * @param message
 * @returns JSX.Element containing Icon or Icon with Message
 */
function generator(icon: IconProp, message: string): JSX.Element {
  return (
    <Tooltip title={i18n.t('GenericHelper.addUpdateInformationMessage')}>
      <div>
        <FontAwesomeIcon icon={icon} className={message && 'margin-right'} />
        <span>{message}</span>
      </div>
    </Tooltip>
  );
}

/**
 *
 * Generates Icon or Icon with Message
 *
 * @param {String} action - enum values, calls which icon to display
 * @param {String} message - displayed message
 * @returns JSX.Element | String - calls which icon to display with the messsage
 */
export const generateTitle = (action: string, message = '') => {
  switch (action) {
    case BUTTON_TITLE_ENUM.INFORMATION.code: {
      return generator(BUTTON_TITLE_ENUM.INFORMATION.icon, message);
    }
    case BUTTON_TITLE_ENUM.WARNING.code: {
      return generator(BUTTON_TITLE_ENUM.WARNING.icon, message);
    }
    case BUTTON_TITLE_ENUM.ADD.code: {
      return generator(BUTTON_TITLE_ENUM.ADD.icon, message);
    }
    case BUTTON_TITLE_ENUM.DELETE.code: {
      return generator(BUTTON_TITLE_ENUM.DELETE.icon, message);
    }
    case BUTTON_TITLE_ENUM.UPDATE.code: {
      return generator(BUTTON_TITLE_ENUM.UPDATE.icon, message);
    }
    case BUTTON_TITLE_ENUM.ERROR.code: {
      return generator(BUTTON_TITLE_ENUM.ERROR.icon, message);
    }
    case BUTTON_TITLE_ENUM.SAVE.code: {
      return generator(BUTTON_TITLE_ENUM.SAVE.icon, message);
    }
    case BUTTON_TITLE_ENUM.EXIT.code: {
      return generator(BUTTON_TITLE_ENUM.EXIT.icon, message);
    }
    case BUTTON_TITLE_ENUM.UPLOAD.code: {
      return generator(BUTTON_TITLE_ENUM.UPLOAD.icon, message);
    }
    case BUTTON_TITLE_ENUM.UNDO.code: {
      return generator(BUTTON_TITLE_ENUM.UNDO.icon, message);
    }
    case BUTTON_TITLE_ENUM.EDIT.code: {
      return generator(BUTTON_TITLE_ENUM.EDIT.icon, message);
    }
    case BUTTON_TITLE_ENUM.CANCEL.code: {
      return generator(BUTTON_TITLE_ENUM.CANCEL.icon, message);
    }
    case BUTTON_TITLE_ENUM.SEND.code: {
      return generator(BUTTON_TITLE_ENUM.SEND.icon, message);
    }
    case BUTTON_TITLE_ENUM.SYNC.code: {
      return generator(BUTTON_TITLE_ENUM.SYNC.icon, message);
    }
    default:
      return '';
  }
};
