import React, { useEffect, useContext, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { setThenRemoveAlert } from '../../redux/alertsSlice';
import { RootState } from '../../redux/store';
import ContactPersonDetailsCard from '../crm/ContactDetails/ContactPersonDetailsCard';
import { ContactPersonDetailsContext } from '../crm/ContactDetails/ContactPersonDetailsProvider';
import ContactPersonDescriptionCard from '../crm/ContactDetails/ContactPersonDescriptionCard';
import ContactPersonStatusCard from '../crm/ContactDetails/ContactPersonStatusCard';
import CollapsibleActivityHistoryCard from '../crm/ContactDetails/CollapsibleActivityHistoryCard';
import ContactPersonResponsibleCard from '../crm/ContactDetails/ContactPersonResponsibleCard';
import CollapsibleRelatedActivitiesCard from '../crm/ContactDetails/CollapsibleRelatedActivitiesCard';
import {
  deleteContactPerson,
  getContactPersonAssignment,
  getContactPersonDetails,
  resetContactPerson,
  saveContactPersonAssignment,
  updateContactPersonAssignment,
} from '../../services/api/contactPerson';
import { transformContactPersonToDetails } from '../crm/ContactDetails/contactPersonHelper';
import CollapsibleRelatedEntitiesCard from '../crm/ContactDetails/CollapsibleRelatedEntitiesCard';
import { isEmpty } from '../../utils/helpers/array';
import i18n from '../../i18n';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import { IContactPersonAssignment } from '../../utils/types/modelTypes';
import { OBJECT_TYPE_ENUM } from '../../utils/enums/objectType';
import { canWrite } from '../../utils/helpers/GenericHelper';

interface IProps extends PropsFromRedux, IWithModalsProps, RouteComponentProps {
  contactId: number;
  handleUpdateActivity: () => Promise<void>;
}

const t = (keyName: string) => i18n.t(`ContactPersonOverview.${keyName}`);

/**
 * Handles logic between connected components for contact person
 */
const ContactPersonOverview = ({
  contactId,
  handleUpdateActivity,
  // Redux
  history,
  employeeDetails: { id: currentUserId },
  // WithModals
  modalErrorHandler,
}: IProps) => {
  const {
    contactDetail,
    setDescription,
    setContactDetail,
    setStatusDetail,
    setRelatedActivitiesDetail,
    setHistoryOfActivitiesDetail,
    setResponsibleDetail,
    setRelatedEntities,
  } = useContext(ContactPersonDetailsContext);

  const [contactPersonAssignment, setContactPersonAssignment] = useState(
    {} as IContactPersonAssignment
  );

  const [userCanWrite, setUserCanWrite] = useState(false);

  // Get contact person details
  const fetchContactPersonDetails = async (contactId: number) => {
    try {
      const { data: contactPerson } = await getContactPersonDetails(contactId);
      const {
        contactDetail,
        statusDetail,
        description,
        relatedActivitiesDetail,
        historyOfActivitiesDetail,
        relatedEntitiesDetail,
        responsibleDetail,
      } = transformContactPersonToDetails(contactPerson);
      setContactDetail(contactDetail);
      setStatusDetail(statusDetail);
      setDescription(description);
      setRelatedActivitiesDetail(relatedActivitiesDetail);
      setHistoryOfActivitiesDetail(historyOfActivitiesDetail);
      setResponsibleDetail(responsibleDetail);
      setRelatedEntities(relatedEntitiesDetail);
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveContactPersonDetails'), error);
    }
  };

  /**
   * Obtain the latest contact person assignment
   */
  const fetchContactPersonAssignment = async () => {
    try {
      const { data: contactPersonAssignment } =
        await getContactPersonAssignment({
          'latest.equals': 'TRUE',
          'contactPersonId.equals': `${contactId}`,
        });
      // Null check
      if (!isEmpty(contactPersonAssignment) && contactPersonAssignment[0]) {
        setContactPersonAssignment(contactPersonAssignment[0]);
      }
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveContactPersonAssignment'), error);
    }
  };

  /**
   * Retrieves the permissions of the current user
   */
  const fetchPermissions = async (objectId: number) => {
    const objectType = OBJECT_TYPE_ENUM.contactPerson.code;
    try {
      setUserCanWrite(
        (await canWrite(currentUserId, objectId, objectType)) as boolean
      );
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveUserAccessType'), error);
    }
  };

  /**
   * Deletes the current Contact Person
   */
  const handleDeleteContactPerson = async () => {
    try {
      await deleteContactPerson(contactId);
      setThenRemoveAlert(t('contactPersonSuccessfullyDeleted'), 'success');
      history.push({
        pathname: '/customer/my-contacts',
      });
    } catch (error) {
      modalErrorHandler(t('failedToDeleteContact'), error);
    }
  };

  /**
   * Handles resetting of Contact Person's details:
   * Customer, CustomerSite, Role, Function, Phone and Mobile Numbers
   */
  const handleResetContactPerson = async () => {
    try {
      const { data } = await resetContactPerson(contactId);
      // Pass the changes into the context
      const { contactDetail } = transformContactPersonToDetails(data);
      setContactDetail(contactDetail);
      // Update the states of the context
      setThenRemoveAlert(t('contactPersonSuccessfullyReset'), 'success');
    } catch (error) {
      modalErrorHandler(t('resettingContactPersonFailed'), error);
    }
  };

  /**
   * Handles updating contact person assignment
   */
  const handleUpdateContactPersonAssignment = async (
    contactPersonAssignment: IContactPersonAssignment
  ) => {
    try {
      const { data } = contactPersonAssignment.id
        ? await updateContactPersonAssignment(contactPersonAssignment)
        : await saveContactPersonAssignment(contactPersonAssignment);
      setContactPersonAssignment(data);
    } catch (error) {
      modalErrorHandler(t('failedToSaveContactPersonAssignment'), error);
    }
  };

  useEffect(() => {
    fetchContactPersonDetails(contactId);
    fetchContactPersonAssignment();
    fetchPermissions(contactId);
  }, []);

  return (
    <Row>
      <Col>
        <ContactPersonDetailsCard userCanWrite={userCanWrite} />
        <Card>
          <ContactPersonStatusCard
            userCanWrite={userCanWrite}
            onSave={fetchContactPersonAssignment}
          />
        </Card>
        <Card>
          <CardBody>
            <ContactPersonDescriptionCard />
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <CollapsibleRelatedActivitiesCard
              contactPersonId={contactDetail.id}
              onDeleteContactPerson={handleDeleteContactPerson}
              onResetContactPerson={handleResetContactPerson}
              contactPersonAssignment={contactPersonAssignment}
              handleUpdateContactPersonAssignment={
                handleUpdateContactPersonAssignment
              }
              handleUpdateActivity={handleUpdateActivity}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CollapsibleActivityHistoryCard />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CollapsibleRelatedEntitiesCard />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <ContactPersonResponsibleCard userCanWrite={userCanWrite} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (store: RootState) => ({
  employeeDetails: store.account.employeeDetails,
});

const mapDispatchToProps = {
  setThenRemoveAlert,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(ContactPersonOverview)));
