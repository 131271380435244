import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AxiosError, AxiosResponse } from 'axios';
import { v4 } from 'uuid';

import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { generateTitle } from '../../../utils/helpers/icon';
import {
  READWRITE,
  maxMobileCount,
  maxPhoneCount,
  maxProfileCount,
  preferredLanguages,
} from '../../../utils/constants';
import {
  BUTTON_TITLE_ENUM,
  EntityType,
} from '../../../utils/enums/pageComponents';
import {
  IContactPersonRole,
  IResponsible,
  IStatusDetail,
} from '../../../utils/types/modelTypes';
import {
  dropdownOptionsToObjectNameAndId,
  enumValuesToDropdownOptions,
  gdprStatusDropdownOptions,
  objectNameAndIdToDropdownOptions,
} from '../../../utils/helpers/dropdown';
import {
  CONTACT_PERSON_FUNCTION_ENUM,
  ContactInformationStatus,
  GENDER_ENUM,
} from '../../../utils/enums/contactPerson';
import {
  IDropdownOption,
  IObjectContactInformation,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import {
  CONTACT_ALUMNUS_ENUM,
  ContactInfoType,
  NewsletterStatus,
} from '../../../utils/enums/contact';
import {
  ContactFieldsEnum,
  handleGenderPrefixChange,
  inheritedSectorsStillPresent,
  removeSimilarSectors,
  transformContactPersonToDetails,
} from './contactPersonHelper';
import { getResponsibilityList } from '../../../utils/helpers/GenericHelper';
import { clickHandler } from '../../../utils/helpers/click';
import {
  ContactPersonDetailsContext,
  IContactDetail,
} from './ContactPersonDetailsProvider';
import ModalAccessAddDialog from '../../../components/form/AddAccessForm/ModalAccessAddDialog';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import Auth from '../../../services/axios/Auth';
import { setThenRemoveAlert } from '../../../redux/alertsSlice';
import {
  getContactPersonRoleItems,
  getContactPersonTitle,
  updateContactPersonDetails,
} from '../../../services/api/contactPerson';
import { getCustomerDropdownItems } from '../../../services/api/customer';
import {
  getAllCustomerSiteForContactPerson,
  getCustomerSiteForContactPerson,
  getCustomerSites,
} from '../../../services/api/customerSite';
import {
  getSectorOptions,
  getTopicOptions,
} from '../../../services/api/campaignManager';
import {
  IContactPersonDetail,
  ICustomerDropdownItem,
  IObjectContactPersonCustomerSite,
} from '../../../utils/types/responseTypes';
import { createLinkIfAuthorised } from '../../../utils/helpers/permission';
import { isEmpty } from '../../../utils/helpers/array';
import { getCorrectContactNumberInput } from '../../../utils/helpers/string';
import CustomerRoleItem from '../../../components/dropdowns/CustomerRoleItem';
import { RootState } from '../../../redux/store';

// !-- Used in ContactPersonDetails, ContactPersonOverview

interface IProps extends PropsFromRedux, RouteComponentProps, IWithModalsProps {
  userCanWrite: boolean;
}

const t = (keyName: string) => i18n.t(`ContactDetails.${keyName}`);

/**
 * Displays and edit basic details of Contact Person
 */
const ContactPersonDetailsCard = ({
  userCanWrite,
  // WithModals
  modalErrorHandler,
  modalFormHandler,
  // Redux
  employeeDetails: { id: currentUserId },
}: IProps) => {
  const {
    setUnsavedDetail,
    contactDetail,
    setContactDetail,
    statusDetail,
    setStatusDetail,
  } = useContext(ContactPersonDetailsContext);

  const [editedContactDetail, setEditedContactDetail] = useState(
    {} as IContactDetail
  );
  const [editedStatusDetail, setEditedStatusDetail] = useState(
    {} as IStatusDetail
  );
  const [toggledField, setToggledField] = useState('');
  const [updatedFields, setUpdatedFields] = useState<string[]>([]);

  // Dropdowns
  const [titleList, setTitleList] = useState<IObjectNameAndId[]>([]);
  const [customerList, setCustomerList] = useState<ICustomerDropdownItem[]>([]);
  const [customerSiteList, setCustomerSiteList] = useState<
    IObjectContactPersonCustomerSite[]
  >([]);
  const [sectorList, setSectorList] = useState<IObjectNameAndId[]>([]);
  const [roleList, setRoleList] = useState<IContactPersonRole[]>([]);
  const [topicList, setTopicList] = useState<IObjectNameAndId[]>([]);

  const [
    responsibilityContactPersonIdList,
    setResponsibilityContactPersonIdList,
  ] = useState<number[]>([]);

  const [inheritedSector, setInheritedSector] = useState<IObjectNameAndId[]>(
    [] as IObjectNameAndId[]
  );

  // Get dropdown data for Titles
  const fetchTitleList = async () => {
    try {
      const { data: fetchedTitles } = await getContactPersonTitle();
      if (fetchedTitles.length > 0) {
        setTitleList(
          fetchedTitles.map(({ id, title }) => ({
            id: id || 0,
            name: title || '',
          }))
        );
      }
    } catch (error) {
      modalErrorHandler(t('failedToGetAllTitles'), error);
    }
  };

  // Get dropdown data for Customers with sectors
  const fetchCustomerList = async () => {
    try {
      const { data: fetchedCustomers } = await getCustomerDropdownItems();
      if (!isEmpty(fetchedCustomers)) {
        setCustomerList(fetchedCustomers);
      }
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveCustomers'), error);
    }
  };

  // Get dropdown data for CustomerSites
  const fetchCustomerSiteList = async (customerId: number) => {
    try {
      const { data: fetchedCustomerSites } =
        customerId !== 0
          ? await getCustomerSiteForContactPerson({
              'customerId.equals': `${customerId}`,
            })
          : await getAllCustomerSiteForContactPerson();
      if (!isEmpty(fetchedCustomerSites)) {
        setCustomerSiteList(fetchedCustomerSites);
      } else {
        setCustomerSiteList([]);
      }
    } catch (error) {
      modalErrorHandler(t('failedToGetCustomerSite'), error);
    }
  };

  // Get dropdown data for Sectors
  const fetchSectorList = async () => {
    try {
      const { data: fetchedSectors } = await getSectorOptions();
      if (fetchedSectors.length > 0) {
        setSectorList(
          fetchedSectors.map(({ id, sector }) => ({
            id: id || 0,
            name: sector || '',
          }))
        );
      }
    } catch (error) {
      modalErrorHandler(t('failedToGetSectors'), error);
    }
  };

  // Get dropdown data for Roles
  const fetchRoleList = async () => {
    try {
      const { data: fetchedRoles } = await getContactPersonRoleItems();
      if (!isEmpty(fetchedRoles.length)) {
        setRoleList(fetchedRoles);
      }
    } catch (error) {
      modalErrorHandler(t('failedToGetAllContactPersonRoles'), error);
    }
  };

  // Get dropdown data for Topics
  const fetchTopicList = async () => {
    try {
      const { data: fetchedTopics } = await getTopicOptions();
      if (fetchedTopics.length > 0) {
        setTopicList(
          fetchedTopics.map(({ id, topic }) => ({
            id: id || 0,
            name: topic || '',
          }))
        );
      }
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveTopics'), error);
    }
  };

  const fetchResponsibilityList = () => {
    getResponsibilityList(currentUserId, OBJECT_TYPE_ENUM.contactPerson.code)
      .then((response: AxiosResponse<IResponsible[]>) => {
        const { data: contactPersonResponsiblesForThisUser } = response;
        let responsibilityContactPersonIdList: number[] = [];
        if (Array.isArray(contactPersonResponsiblesForThisUser)) {
          responsibilityContactPersonIdList =
            contactPersonResponsiblesForThisUser.map(
              (responsible: IResponsible) => responsible.objectId
            );
        }
        setResponsibilityContactPersonIdList(responsibilityContactPersonIdList);
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToRetrieveResponsibles'), error);
      });
  };

  // Get the inherited sector based on the customer and customer site
  const fetchInheritedSector = () => {
    const { customer, customerSite } = contactDetail;
    const customerSectors =
      customerList.find(({ id }) => id === customer?.id)?.sectors || [];
    const siteSectors = customerSite?.sectors || [];

    const uniqueSectors = removeSimilarSectors(customerSectors, siteSectors);
    setInheritedSector(
      !isEmpty(uniqueSectors)
        ? [...customerSectors, ...uniqueSectors]
        : customerSectors
    );
  };

  const toggleUpdateField = (fieldName: string) => () => {
    if (userCanWrite && toggledField !== fieldName) {
      setToggledField(fieldName);
    }
  };

  const updateFieldChanges = (fieldValue: string, noChange: boolean) => {
    setUpdatedFields((prevFields) => {
      if (noChange) {
        return prevFields.filter((field) => field !== fieldValue);
      }
      if (prevFields.includes(fieldValue)) {
        return prevFields;
      }
      return [...prevFields, fieldValue];
    });
  };

  const cancelForm = () => {
    setToggledField('');
    setUpdatedFields([]);
    fetchCustomerSiteList(contactDetail.customer?.id ?? 0);
    setEditedContactDetail(contactDetail);
  };

  const showPermissionModal = () => {
    const { id } = editedContactDetail;
    modalFormHandler(
      t('grantPermission'),
      <ModalAccessAddDialog restriction={id} entityType="DATA" />,
      'xl'
    );
  };

  // Functions to change details
  const handleContactPersonTitleChange = ({
    value,
    label,
  }: IDropdownOption) => {
    const { title: oldTitle } = contactDetail;
    const titleObject: IObjectNameAndId = {
      id: parseInt(value, 10) ?? 0,
      name: label,
    };
    updateFieldChanges(ContactFieldsEnum.TITLE, oldTitle === titleObject);
    setEditedContactDetail({ ...editedContactDetail, title: titleObject });
  };

  const handleNameChange = (
    event: ChangeEvent<HTMLInputElement>,
    namePosition: string
  ) => {
    const { firstname: oldFirstName, lastname: oldLastName } = contactDetail;
    const name = event.target.value.trim() === '' ? null : event.target.value;

    if (namePosition === ContactFieldsEnum.FIRST_NAME.toLowerCase()) {
      const isChanged = oldFirstName === name;
      updateFieldChanges(namePosition, isChanged);
    }
    if (namePosition === ContactFieldsEnum.LAST_NAME.toLowerCase()) {
      const isChanged = oldLastName === name;
      updateFieldChanges(namePosition, isChanged);
    }

    setEditedContactDetail({ ...editedContactDetail, [namePosition]: name });
  };

  const generateContactPersonField = () => {
    const { title, firstname, lastname, genderPrefix } = editedContactDetail;

    if (toggledField === ContactFieldsEnum.CONTACT_PERSON_NAME) {
      return (
        <Row style={{ display: 'inline-block', width: '100%' }}>
          <Col>
            <Select
              aria-label="title-dropdown"
              onChange={handleContactPersonTitleChange}
              options={objectNameAndIdToDropdownOptions(titleList)}
              value={objectNameAndIdToDropdownOptions(titleList).find(
                (item) => title?.id === item.value
              )}
              placeholder={t('selectTitle')}
            />
          </Col>
          <Col style={{ marginTop: '8px' }}>
            <Input
              aria-label="firstName-input"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleNameChange(
                  event,
                  ContactFieldsEnum.FIRST_NAME.toLowerCase()
                )
              }
              value={firstname}
              placeholder={t('firstName')}
            />
          </Col>
          <Col style={{ marginTop: '8px' }}>
            <Input
              aria-label="lastName-input"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleNameChange(
                  event,
                  ContactFieldsEnum.LAST_NAME.toLowerCase()
                )
              }
              value={lastname}
              placeholder={t('lastName')}
            />
          </Col>
        </Row>
      );
    }
    return `${genderPrefix ?? ''}
    ${title?.name ?? ''} ${firstname} ${lastname}`;
  };

  const handleGenderChange = ({ value }: IDropdownOption) => {
    const { gender: oldGender } = contactDetail;
    const newGenderPrefix = handleGenderPrefixChange(value);

    updateFieldChanges(ContactFieldsEnum.GENDER, value === oldGender);
    setEditedContactDetail({
      ...editedContactDetail,
      gender: value,
      genderPrefix: newGenderPrefix,
    });
  };

  const generateGenderField = () => {
    const { gender } = editedContactDetail;
    if (toggledField === ContactFieldsEnum.GENDER) {
      return (
        <Select
          aria-label="contactPerson-gender-dropdown"
          onChange={handleGenderChange}
          options={enumValuesToDropdownOptions(Object.values(GENDER_ENUM))}
          value={enumValuesToDropdownOptions(Object.values(GENDER_ENUM)).filter(
            ({ value }) => gender?.toLowerCase() === value.toLowerCase()
          )}
          placeholder={t('selectGender')}
        />
      );
    }
    return Object.values(GENDER_ENUM).filter(
      (item) => gender?.toLowerCase() === item.toLowerCase()
    );
  };

  const handleCustomerChange = ({ value }: IDropdownOption<number>) => {
    const { customer: oldCustomer } = contactDetail;
    const { customerSite } = editedContactDetail;
    const newCustomer = customerList.find(({ id }) => id === value);

    if (newCustomer) {
      updateFieldChanges(
        ContactFieldsEnum.CUSTOMER,
        oldCustomer === newCustomer
      );

      fetchCustomerSiteList(newCustomer.id);

      // Compare if customersite is part of customerSiteList
      const customerSiteInList = customerSiteList.some(
        ({ id }) => id === customerSite?.id
      );

      // If customerSite is not part of the list, set it to empty
      if (!customerSiteInList) {
        updateFieldChanges(ContactFieldsEnum.CUSTOMER_SITE, true);
      }

      setEditedContactDetail({
        ...editedContactDetail,
        customer: newCustomer,
        ...(!customerSiteInList && {
          customerSite: {} as IObjectContactPersonCustomerSite,
        }),
      });

      // Inherited Sector would come from new chosen customer
      setInheritedSector(newCustomer.sectors);
    }
  };

  const generateCustomerField = () => {
    const { customer } = editedContactDetail;
    if (customerList.length > 0) {
      if (toggledField === ContactFieldsEnum.CUSTOMER) {
        return (
          <Select
            aria-label="customer-dropdown"
            onChange={handleCustomerChange}
            options={objectNameAndIdToDropdownOptions(customerList)}
            value={objectNameAndIdToDropdownOptions(customerList).find(
              ({ value }) => customer?.id === value
            )}
            placeholder={t('selectCustomer')}
          />
        );
      }
      if (customer) {
        return createLinkIfAuthorised(
          EntityType.CUSTOMER,
          customer.name,
          customer.id
        );
      }
    }
    return null;
  };

  const handleIndustryChange = (sectors: IDropdownOption<number>[]) => {
    const { sectors: oldSectors } = contactDetail;
    if (sectors.length > 0) {
      const newSectors = dropdownOptionsToObjectNameAndId(sectors);
      if (inheritedSectorsStillPresent(inheritedSector, newSectors)) {
        updateFieldChanges(
          ContactFieldsEnum.CONTACT_PERSON_SECTORS,
          oldSectors === newSectors
        );
        setEditedContactDetail({
          ...editedContactDetail,
          sectors: newSectors,
        });
      } else {
        modalErrorHandler(t('inheritedSectorsNotPresent'));
      }
    } else {
      modalErrorHandler(t('atleastOneIndustryIsRequired'));
    }
  };

  const inheritSector = () => {
    const { sectors: oldSectors } = editedContactDetail;

    // Remove similar sectors with oldSectors
    const filteredInheritedSectors = removeSimilarSectors(
      inheritedSector,
      oldSectors
    );
    if (!isEmpty(filteredInheritedSectors)) {
      let sectorsToAdd = objectNameAndIdToDropdownOptions(
        filteredInheritedSectors
      );

      if (oldSectors) {
        sectorsToAdd = sectorsToAdd.concat(
          objectNameAndIdToDropdownOptions(oldSectors)
        );
      }

      handleIndustryChange(sectorsToAdd);
    }
  };

  /**
   * Update the customer based on the selected customer site
   * @param customerSiteId
   */
  const updateCustomer = async (customerSiteId: number) => {
    try {
      const { data: customerSite } = await getCustomerSites({
        'id.equals': `${customerSiteId}`,
      });
      if (!isEmpty(customerSite)) {
        const newCustomer = customerSite[0]?.customer;
        if (newCustomer && newCustomer.id) {
          handleCustomerChange({
            value: newCustomer.id,
            label: newCustomer.name,
          });
        }
      }
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveCustomerSite'), error);
    }
  };

  const handleCustomerSiteChange = ({ value }: IDropdownOption<number>) => {
    const { customerSite: oldCustomerSite } = contactDetail;
    const newCustomerSite = customerSiteList.find(({ id }) => id === value);
    if (newCustomerSite) {
      updateFieldChanges(
        ContactFieldsEnum.CUSTOMER_SITE,
        oldCustomerSite?.id === newCustomerSite?.id
      );
      setEditedContactDetail({
        ...editedContactDetail,
        customerSite: newCustomerSite,
      });

      const sectorsToAdd = removeSimilarSectors(
        newCustomerSite.sectors,
        inheritedSector
      );
      if (!isEmpty(sectorsToAdd)) {
        setInheritedSector([...inheritedSector, ...sectorsToAdd]);
      }
    }
  };

  const generateCustomerSiteField = () => {
    const { customerSite } = editedContactDetail;

    if (customerSiteList.length > 0) {
      const dropdownOptions = customerSiteList.map(
        ({ id, nameAndLocation }) => ({
          value: id,
          label: nameAndLocation,
        })
      );

      if (toggledField === ContactFieldsEnum.CUSTOMER_SITE) {
        return (
          <Select
            aria-label="customerSite-dropdown"
            onChange={handleCustomerSiteChange}
            options={dropdownOptions}
            value={dropdownOptions.find(
              ({ value }) => customerSite?.id === value
            )}
            placeholder={i18n.t('CustomerSitesDropdown.selectLocation')}
          />
        );
      }
      return dropdownOptions.find(({ value }) => customerSite?.id === value)
        ?.label;
    }
    return null;
  };

  const disableAddButton = (infoType: ContactInfoType) => {
    const { contactInformation } = editedContactDetail;
    const numberOfEntries = contactInformation.filter(
      (info) => info.type === infoType
    ).length;
    if (infoType === ContactInfoType.PHONE) {
      return numberOfEntries >= maxPhoneCount;
    }
    if (infoType === ContactInfoType.MOBILE) {
      return numberOfEntries >= maxMobileCount;
    }
    if (
      infoType === ContactInfoType.XING ||
      infoType === ContactInfoType.LINKEDIN ||
      infoType === ContactInfoType.EMAIL
    ) {
      return numberOfEntries >= maxProfileCount;
    }
    return false;
  };

  const handleCustomerSiteNumberChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const {
      customerSiteContactInformation: oldCustomerSiteContactInformation,
    } = contactDetail;
    const { customerSiteContactInformation } = editedContactDetail;
    const newNumber = event.target?.value.trim() || null;
    if (newNumber) {
      const updatedCustomerSiteContactInformation =
        customerSiteContactInformation.map((contact, i) =>
          i === index ? { ...contact, info: newNumber } : contact
        );
      updateFieldChanges(
        ContactFieldsEnum.CUSTOMER_SITE_NUMBER,
        oldCustomerSiteContactInformation ===
          updatedCustomerSiteContactInformation
      );
      setEditedContactDetail({
        ...editedContactDetail,
        customerSiteContactInformation: updatedCustomerSiteContactInformation,
      });
    }
  };

  const handleCustomerSiteNumberAdd = () => {
    const { customerSiteContactInformation } = editedContactDetail;
    const newCustomerSiteContactInformation = {
      id: 0,
      info: '',
      type: ContactInfoType.PHONE,
      status: ContactInformationStatus.UNKNOWN,
    } as IObjectContactInformation;
    setEditedContactDetail({
      ...editedContactDetail,
      customerSiteContactInformation: [
        ...customerSiteContactInformation,
        newCustomerSiteContactInformation,
      ],
    });
  };

  const handleCustomerSiteNumberDelete = (index: number) => {
    const { customerSiteContactInformation } = editedContactDetail;
    if (index !== -1) {
      // Filter out the contact at the index
      updateFieldChanges(ContactFieldsEnum.CUSTOMER_SITE_NUMBER, false);
      const updatedCustomerSiteContactInformation =
        customerSiteContactInformation.filter((_contact, i) => i !== index);
      setEditedContactDetail({
        ...editedContactDetail,
        customerSiteContactInformation: updatedCustomerSiteContactInformation,
      });
    }
  };

  const generateCustomerSiteNumberField = () => {
    const { customerSite, customerSiteContactInformation } =
      editedContactDetail;
    if (toggledField === ContactFieldsEnum.CUSTOMER_SITE_NUMBER) {
      return (
        <>
          {customerSiteContactInformation?.map((contact, index) => (
            <Row key={contact.id}>
              <Col>
                <Input
                  aria-label={`customerSitenumber-input-${index}`}
                  value={contact.info}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleCustomerSiteNumberChange(event, index)
                  }
                />
              </Col>
              <Col>
                <Button
                  aria-label={`customerSitenumber-delete-button-${index}`}
                  onClick={() => handleCustomerSiteNumberDelete(index)}
                >
                  {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            aria-label="add-customersitenumber-button"
            disabled={customerSite === null}
            onClick={handleCustomerSiteNumberAdd}
          >
            {generateTitle(BUTTON_TITLE_ENUM.ADD.code)}
          </Button>
        </>
      );
    }
    return customerSiteContactInformation?.map(({ id, info }) => (
      <Row key={id}>
        <Col>{info}</Col>
      </Row>
    ));
  };

  const generateIndustryField = () => {
    const { sectors } = editedContactDetail;
    if (toggledField === ContactFieldsEnum.CONTACT_PERSON_SECTORS) {
      return (
        <Select
          isMulti
          aria-label="industry-dropdown"
          options={objectNameAndIdToDropdownOptions(sectorList)}
          value={objectNameAndIdToDropdownOptions(sectorList)
            .filter((sector) => sectors.some(({ id }) => id === sector.value))
            .sort((a, b) => (a.label > b.label ? 1 : -1))}
          onChange={handleIndustryChange}
          placeholder={t('selectIndustries')}
        />
      );
    }
    return sectors?.map((sector) => (
      <Row key={sector.id}>
        <Col>{sector.name}</Col>
      </Row>
    ));
  };

  const handleRoleChange = (newRole: string) => {
    const { role: oldRole } = contactDetail;
    const { id, role: name } = roleList.find(
      ({ role }) => role === newRole
    ) ?? {
      id: 0,
      name: '',
    };
    const role = { id, name } as IObjectNameAndId;
    updateFieldChanges(ContactFieldsEnum.ROLE, oldRole.name === newRole);
    setEditedContactDetail({ ...editedContactDetail, role });
  };

  const generateRoleField = () => {
    const { role } = editedContactDetail;
    if (toggledField === ContactFieldsEnum.ROLE) {
      return (
        <CustomerRoleItem
          roleId={role.id}
          suggestions={roleList}
          onChangeHandler={handleRoleChange}
          size="medium"
        />
        /*
        <Input
          aria-label="role-input"
          type="text"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleRoleChange(event)
          }
          value={role}
          placeholder={t('role')}
        />
        */
      );
    }
    return role?.name;
  };

  const handleFunctionChange = ({ value }: IDropdownOption) => {
    const { functionRole: oldFunctionRole } = contactDetail;
    updateFieldChanges(ContactFieldsEnum.FUNCTION, oldFunctionRole === value);
    setEditedContactDetail({
      ...editedContactDetail,
      functionRole: value,
    });
  };

  const generateFunctionField = () => {
    const { functionRole: functionValue } = editedContactDetail;
    if (toggledField === ContactFieldsEnum.FUNCTION) {
      return (
        <Select
          aria-label="functionRole-dropdown"
          onChange={(event: IDropdownOption) => handleFunctionChange(event)}
          options={Object.values(CONTACT_PERSON_FUNCTION_ENUM)}
          value={Object.values(CONTACT_PERSON_FUNCTION_ENUM).find(
            ({ value }) => functionValue === value
          )}
        />
      );
    }
    return Object.values(CONTACT_PERSON_FUNCTION_ENUM).find(
      ({ value }) => functionValue === value
    )?.label;
  };

  const handleTopicChange = (topics: IDropdownOption[]) => {
    const { topics: oldTopics } = contactDetail;
    const newTopics = topics.map((topic) => ({
      id: parseInt(topic.value, 10),
      name: topic.label,
    }));
    updateFieldChanges(ContactFieldsEnum.TOPIC, oldTopics === newTopics);
    setEditedContactDetail({ ...editedContactDetail, topics: newTopics });
  };

  const generateTopicField = () => {
    const { topics } = editedContactDetail;
    if (toggledField === ContactFieldsEnum.TOPIC) {
      return (
        <Select
          isMulti
          aria-label="topic-dropdown"
          options={objectNameAndIdToDropdownOptions(topicList)}
          value={objectNameAndIdToDropdownOptions(topicList)
            .filter((topic) => topics.some((item) => item.id === topic.value))
            .sort((a, b) => (a.label > b.label ? 1 : -1))}
          onChange={handleTopicChange}
          placeholder={t('selectTopics')}
        />
      );
    }
    return topics?.map((topic) => (
      <Row key={topic.id}>
        <Col>{topic.name}</Col>
      </Row>
    ));
  };

  const handleContactNumberChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    infoType: ContactInfoType
  ) => {
    const { contactInformation: oldContactInformation } = contactDetail;
    const { contactInformation } = editedContactDetail;

    const oldNumber = contactInformation.find((_item, i) => i === index)
      ?.info as string;
    const newNumber = event.target.value;

    const formattedNumber = getCorrectContactNumberInput(oldNumber, newNumber);
    // Filter out the contact info based on the type then update the contact at the index
    const updatedContactInformation = contactInformation
      .filter((info) => info.type === infoType)
      .map((contact, i) =>
        i === index ? { ...contact, info: formattedNumber } : contact
      );
    const filteredOldContactInformation = oldContactInformation.filter(
      (info) => info.type === infoType
    );

    updateFieldChanges(
      ContactFieldsEnum.CONTACT_INFORMATION,
      filteredOldContactInformation === updatedContactInformation
    );
    setEditedContactDetail({
      ...editedContactDetail,
      contactInformation: [
        ...updatedContactInformation,
        ...contactInformation.filter((info) => info.type !== infoType),
      ],
    });
  };

  const handleContactInformationDelete = (
    index: number,
    infoType: ContactInfoType
  ) => {
    const { contactInformation } = editedContactDetail;
    if (index !== -1) {
      // Filter out the contact first based on the type and then remove contact at the index
      const updatedContactInformation = contactInformation
        .filter((info) => info.type === infoType)
        .filter((_contact, i) => i !== index);
      updateFieldChanges(ContactFieldsEnum.CONTACT_INFORMATION, false);
      setEditedContactDetail({
        ...editedContactDetail,
        contactInformation: [
          ...updatedContactInformation,
          ...contactInformation.filter((info) => info.type !== infoType),
        ],
      });
    }
  };

  const handleContactInformationAdd = (infoType: ContactInfoType) => {
    const { contactInformation } = editedContactDetail;
    const newContactInformation = {
      id: 0,
      type: infoType,
      info: '',
      status: ContactInformationStatus.UNKNOWN,
      uuid: v4(),
    };
    setEditedContactDetail({
      ...editedContactDetail,
      contactInformation: [...contactInformation, newContactInformation],
    });
  };

  const generatePhoneField = () => {
    const { contactInformation } = editedContactDetail;
    const phoneEntries = contactInformation?.filter(
      (info) => info.type === ContactInfoType.PHONE
    );

    if (toggledField === ContactFieldsEnum.PHONE_NUMBER) {
      return (
        <>
          {phoneEntries.map(({ info, uuid }, index) => (
            <Row key={uuid}>
              <Col>
                <Input
                  aria-label={`phoneNumber-input-${index}`}
                  value={info}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleContactNumberChange(
                      event,
                      index,
                      ContactInfoType.PHONE
                    )
                  }
                />
              </Col>
              <Col>
                <Button
                  aria-label={`phoneNumber-delete-button-${index}`}
                  onClick={() =>
                    handleContactInformationDelete(index, ContactInfoType.PHONE)
                  }
                >
                  {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            aria-label="add-phoneNumber-button"
            disabled={disableAddButton(ContactInfoType.PHONE)}
            onClick={() => handleContactInformationAdd(ContactInfoType.PHONE)}
          >
            {generateTitle(BUTTON_TITLE_ENUM.ADD.code)}
          </Button>
        </>
      );
    }
    return phoneEntries?.map(({ info, uuid }) => (
      <Row key={uuid}>
        <Col>{info}</Col>
      </Row>
    ));
  };

  const generateMobileField = () => {
    const { contactInformation } = editedContactDetail;
    const mobileEntries = contactInformation?.filter(
      (info) => info.type === ContactInfoType.MOBILE
    );

    if (toggledField === ContactFieldsEnum.MOBILE_NUMBER) {
      return (
        <>
          {mobileEntries.map(({ info, uuid }, index) => (
            <Row key={uuid}>
              <Col>
                <Input
                  aria-label={`mobileNumber-input-${index}`}
                  value={info}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleContactNumberChange(
                      event,
                      index,
                      ContactInfoType.MOBILE
                    )
                  }
                />
              </Col>
              <Col>
                <Button
                  aria-label={`mobileNumber-delete-button-${index}`}
                  onClick={() =>
                    handleContactInformationDelete(
                      index,
                      ContactInfoType.MOBILE
                    )
                  }
                >
                  {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            aria-label="add-mobileNumber-button"
            disabled={disableAddButton(ContactInfoType.MOBILE)}
            onClick={() => handleContactInformationAdd(ContactInfoType.MOBILE)}
          >
            {generateTitle(BUTTON_TITLE_ENUM.ADD.code)}
          </Button>
        </>
      );
    }

    return mobileEntries?.map(({ info, uuid }) => (
      <Row key={uuid}>
        <Col>{info}</Col>
      </Row>
    ));
  };

  const handleContactProfileChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    infoType: ContactInfoType
  ) => {
    const { contactInformation: oldContactInformation } = contactDetail;
    const { contactInformation } = editedContactDetail;
    const newInfo = event.target.value.trim() ?? '';

    // Filter out the contact info based on the type then update the contact at the index
    const updatedContactInformation = contactInformation
      .filter((info) => info.type === infoType)
      .map((contact, i) =>
        i === index ? { ...contact, info: newInfo } : contact
      );

    const filteredOldContactInformation = oldContactInformation.filter(
      (info) => info.type === infoType
    );

    updateFieldChanges(
      ContactFieldsEnum.CONTACT_INFORMATION,
      filteredOldContactInformation === updatedContactInformation
    );
    setEditedContactDetail({
      ...editedContactDetail,
      contactInformation: [
        ...updatedContactInformation,
        ...contactInformation.filter((info) => info.type !== infoType),
      ],
    });
  };

  const generateXingField = () => {
    const { contactInformation } = editedContactDetail;
    const xingEntries = contactInformation?.filter(
      (info) => info.type === ContactInfoType.XING
    );
    if (toggledField === ContactFieldsEnum.XING_PROFILE) {
      return (
        <>
          {xingEntries.map(({ info, uuid }, index) => (
            <Row key={uuid}>
              <Col>
                <Input
                  aria-label={`xingProfile-input-${index}`}
                  value={info}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleContactProfileChange(
                      event,
                      index,
                      ContactInfoType.XING
                    )
                  }
                />
              </Col>
              <Col>
                <Button
                  aria-label={`xingProfile-delete-button-${index}`}
                  onClick={() =>
                    handleContactInformationDelete(index, ContactInfoType.XING)
                  }
                >
                  {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            aria-label="add-xingProfile-button"
            disabled={disableAddButton(ContactInfoType.XING)}
            onClick={() => handleContactInformationAdd(ContactInfoType.XING)}
          >
            {generateTitle(BUTTON_TITLE_ENUM.ADD.code)}
          </Button>
        </>
      );
    }
    return xingEntries?.map(({ info, uuid }) => (
      <Row key={uuid}>
        <Col>{info}</Col>
      </Row>
    ));
  };

  const generateLinkedInField = () => {
    const { contactInformation } = editedContactDetail;
    const linkedInEntries = contactInformation?.filter(
      (info) => info.type === ContactInfoType.LINKEDIN
    );
    if (toggledField === ContactFieldsEnum.LINKEDIN_PROFILE) {
      return (
        <>
          {linkedInEntries.map(({ info, uuid }, index) => (
            <Row key={uuid}>
              <Col>
                <Input
                  aria-label={`linkedInProfile-input-${index}`}
                  value={info}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleContactProfileChange(
                      event,
                      index,
                      ContactInfoType.LINKEDIN
                    )
                  }
                />
              </Col>
              <Col>
                <Button
                  aria-label={`linkedInProfile-delete-button-${index}`}
                  onClick={() =>
                    handleContactInformationDelete(
                      index,
                      ContactInfoType.LINKEDIN
                    )
                  }
                >
                  {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            aria-label="add-linkedInProfile-button"
            disabled={disableAddButton(ContactInfoType.LINKEDIN)}
            onClick={() =>
              handleContactInformationAdd(ContactInfoType.LINKEDIN)
            }
          >
            {generateTitle(BUTTON_TITLE_ENUM.ADD.code)}
          </Button>
        </>
      );
    }
    return linkedInEntries?.map(({ info, uuid }) => (
      <Row key={uuid}>
        <Col>{info}</Col>
      </Row>
    ));
  };

  const handleContactEmailStatusChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { contactInformation } = editedContactDetail;
    const newStatus = event.target.checked
      ? ContactInformationStatus.GUESSED
      : ContactInformationStatus.UNKNOWN;

    const updatedContactInformation = contactInformation
      .filter((info) => info.type === ContactInfoType.EMAIL)
      .map((contact, i) =>
        i === index ? { ...contact, status: newStatus } : contact
      );

    updateFieldChanges(ContactFieldsEnum.CONTACT_INFORMATION, false);
    setEditedContactDetail({
      ...editedContactDetail,
      contactInformation: [
        ...updatedContactInformation,
        ...contactInformation.filter(
          (info) => info.type !== ContactInfoType.EMAIL
        ),
      ],
    });
  };

  const generateEmailAddressField = () => {
    const { contactInformation } = editedContactDetail;
    const emailEntries = contactInformation?.filter(
      (info) => info.type === ContactInfoType.EMAIL
    );

    if (toggledField === ContactFieldsEnum.EMAIL_ADDRESS) {
      return (
        <>
          {emailEntries.map(({ info, status, uuid }, index) => (
            <Row key={uuid}>
              <Col>
                <Input
                  aria-label={`emailAddress-input-${index}`}
                  value={info}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleContactProfileChange(
                      event,
                      index,
                      ContactInfoType.EMAIL
                    )
                  }
                />
              </Col>
              <Col>
                <Input
                  aria-label={`emailAddress-status-${index}`}
                  type="checkbox"
                  checked={status === ContactInformationStatus.GUESSED}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleContactEmailStatusChange(event, index)
                  }
                />
                {t(ContactInformationStatus.GUESSED)}
              </Col>

              <Col>
                <Button
                  aria-label={`emailAddress-delete-button-${index}`}
                  onClick={() =>
                    handleContactInformationDelete(index, ContactInfoType.EMAIL)
                  }
                >
                  {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            aria-label="add-emailAddress-button"
            disabled={disableAddButton(ContactInfoType.EMAIL)}
            onClick={() => handleContactInformationAdd(ContactInfoType.EMAIL)}
          >
            {generateTitle(BUTTON_TITLE_ENUM.ADD.code)}
          </Button>
        </>
      );
    }
    return emailEntries?.map(({ info, status, uuid }) => (
      <Row key={uuid}>
        <Col>{info}</Col>
        <Col>
          {' '}
          {status === ContactInformationStatus.GUESSED ? status : null}{' '}
        </Col>
      </Row>
    ));
  };

  const handlestatusGdprChange = ({ value }: IDropdownOption) => {
    // Look into generateInfoGdprForSaving old function

    const { statusGdpr: oldStatusGdpr } = contactDetail;
    updateFieldChanges(ContactFieldsEnum.STATUS_GDPR, value === oldStatusGdpr);
    setEditedContactDetail({
      ...editedContactDetail,
      statusGdpr: value,
    });
  };

  const generateStatusGdprField = () => {
    const { statusGdpr } = editedContactDetail;
    if (
      toggledField === ContactFieldsEnum.STATUS_GDPR &&
      (Auth.hasPermission(
        [PERMISSION_URI.campaignManagementAccess.readWrite.uri],
        [READWRITE]
      ) ||
        responsibilityContactPersonIdList.includes(contactDetail.id))
    ) {
      return (
        <Select
          aria-label="gdprStatus-dropdown"
          onChange={handlestatusGdprChange}
          options={gdprStatusDropdownOptions}
          value={gdprStatusDropdownOptions.find(
            ({ value }) => statusGdpr === value
          )}
          isClearable={false}
          placeholder={t('selectGdprStatus')}
        />
      );
    }
    return statusGdpr;
  };

  const handleContactAlumnusChange = ({ value }: IDropdownOption) => {
    const { contactAlumnus: oldContactAlumnus } = statusDetail;
    updateFieldChanges(
      ContactFieldsEnum.CONTACT_ALUMNUS,
      value === oldContactAlumnus
    );
    setEditedStatusDetail({
      ...editedStatusDetail,
      contactAlumnus: value,
    });
  };

  const generateContactAlumnusField = () => {
    const { contactAlumnus } = editedStatusDetail;
    if (toggledField === ContactFieldsEnum.CONTACT_ALUMNUS) {
      return (
        <Select
          aria-label="contactAlumnus-dropdown"
          onChange={handleContactAlumnusChange}
          options={Object.values(CONTACT_ALUMNUS_ENUM)}
          value={Object.values(CONTACT_ALUMNUS_ENUM).find(
            ({ value }) => contactAlumnus === value
          )}
        />
      );
    }
    return Object.values(CONTACT_ALUMNUS_ENUM).find(
      ({ value }) => contactAlumnus === value
    )?.label;
  };

  const handlePreferredLanguageChange = (event: IDropdownOption) => {
    const { preferredLanguage: oldPreferredLanguage } = contactDetail;
    updateFieldChanges(
      ContactFieldsEnum.PREFERRED_LANGUAGE,
      event.value === oldPreferredLanguage
    );
    setEditedContactDetail({
      ...editedContactDetail,
      preferredLanguage: event.value,
    });
  };

  const generatePreferredLanguage = () => {
    const { preferredLanguage } = editedContactDetail;
    if (toggledField === ContactFieldsEnum.PREFERRED_LANGUAGE) {
      return (
        <Select
          aria-label="mainLanguage-dropdown"
          onChange={(event: IDropdownOption) =>
            handlePreferredLanguageChange(event)
          }
          options={preferredLanguages}
          value={preferredLanguages.find(
            ({ value }) => preferredLanguage === value
          )}
          placeholder={t('selectLanguage')}
        />
      );
    }
    return preferredLanguages.find(({ value }) => preferredLanguage === value)
      ?.label;
  };

  const handleNewsletterChange = ({ value }: IDropdownOption) => {
    const { newsletter: oldNewsLetter } = contactDetail;
    updateFieldChanges(ContactFieldsEnum.NEWSLETTER, value === oldNewsLetter);
    setEditedContactDetail({ ...editedContactDetail, newsletter: value });
  };

  const generateNewsletter = () => {
    const { newsletter } = editedContactDetail;
    if (toggledField === ContactFieldsEnum.NEWSLETTER) {
      return (
        <Select
          aria-label="newsletter-dropdown"
          onChange={(event: IDropdownOption) => handleNewsletterChange(event)}
          options={enumValuesToDropdownOptions(Object.values(NewsletterStatus))}
          value={enumValuesToDropdownOptions(
            Object.values(NewsletterStatus)
          ).find(({ value }) => newsletter === value)}
        />
      );
    }
    return enumValuesToDropdownOptions(Object.values(NewsletterStatus)).find(
      ({ value }) => newsletter === value
    )?.label;
  };

  const saveNewContactPersonDetails = async () => {
    const updatedFieldsMapped = updatedFields.map((field) => {
      if (field === ContactFieldsEnum.CONTACT_ALUMNUS) {
        return {
          [field]: editedStatusDetail.contactAlumnus,
        };
      }
      return {
        [field]: editedContactDetail[field as keyof IContactDetail], // Way to get around type error
      };
    });

    // Combine the objects in the new array into a single object
    const propertiesToSave = Object.assign(
      { id: editedContactDetail.id },
      ...updatedFieldsMapped
    ) as IContactPersonDetail;

    try {
      const { data } = await updateContactPersonDetails(propertiesToSave);
      setUpdatedFields([]);
      setToggledField('');
      setThenRemoveAlert(t('Updated Contact Person Successfully'), 'success');
      // Pass the changes into the context
      const { contactDetail, statusDetail } =
        transformContactPersonToDetails(data);
      setContactDetail(contactDetail);
      setStatusDetail(statusDetail);
    } catch (error) {
      modalErrorHandler(t('failedToUpdateContactPerson'), error);
    }
  };

  // Fetch Dropdown data
  useEffect(() => {
    fetchTitleList();
    fetchCustomerList();
    fetchSectorList();
    fetchRoleList();
    fetchTopicList();
    fetchResponsibilityList();
    fetchCustomerSiteList(contactDetail.customer?.id ?? 0);
  }, []);

  useEffect(() => {
    if (
      contactDetail.customer?.id &&
      contactDetail.customer.id !== editedContactDetail.customer?.id
    ) {
      fetchCustomerSiteList(contactDetail.customer.id);
    }

    const { contactInformation = [] } = contactDetail;
    setEditedContactDetail({
      ...contactDetail,
      contactInformation: contactInformation.map((obj) => ({
        ...obj,
        uuid: v4(),
      })),
    });

    setEditedContactDetail(contactDetail);
    fetchInheritedSector();
  }, [contactDetail]);

  useEffect(() => {
    setEditedStatusDetail(statusDetail);
  }, [statusDetail]);

  // Check if there are unsaved changes
  useEffect(() => {
    setUnsavedDetail(editedContactDetail !== contactDetail);
  }, [editedContactDetail]);

  useEffect(() => {
    const { customer } = contactDetail;
    const { customerSite, customer: newCustomer } = editedContactDetail;
    if (customerSite && customerSite?.id && !customer && !newCustomer) {
      // Updates the customer based on customer site only when customer is previously null and edited customer is also null
      updateCustomer(customerSite.id);
    }
  }, [editedContactDetail.customerSite]);

  useEffect(() => {
    // Inherit sector when inherited sector changes
    inheritSector();
  }, [inheritedSector]);

  return (
    <Card style={{ marginBottom: 0 }}>
      <CardHeader>
        <div className="float-end">
          <Button
            color="primary"
            size="s"
            onClick={() => showPermissionModal()}
          >
            {t('grantPermission')}
          </Button>
          {toggledField !== '' && (
            <>
              <div className="float-end" style={{ paddingLeft: '5px' }}>
                <Button
                  aria-label="cancelForm-button"
                  color="primary"
                  size="s"
                  onClick={(event: MouseEvent) =>
                    clickHandler(event, cancelForm)
                  }
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
              <div className="float-end" style={{ paddingLeft: '5px' }}>
                <Button
                  aria-label="saveForm-button"
                  color="primary"
                  size="s"
                  onClick={(event: MouseEvent) =>
                    clickHandler(event, saveNewContactPersonDetails)
                  }
                >
                  {generateTitle(BUTTON_TITLE_ENUM.SAVE.code)}
                </Button>
              </div>
            </>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <Table striped className="my-1" style={{ width: '100%' }}>
          <tbody>
            <tr>
              <th style={{ width: '30%' }}>{t('name')}</th>
              <td
                aria-label="contactPerson-field"
                onClick={toggleUpdateField(
                  ContactFieldsEnum.CONTACT_PERSON_NAME
                )}
              >
                {generateContactPersonField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('gender')}</th>
              <td
                aria-label="gender-field"
                onClick={toggleUpdateField(ContactFieldsEnum.GENDER)}
              >
                {generateGenderField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('customer')}</th>
              <td
                aria-label="customer-field"
                onClick={toggleUpdateField(ContactFieldsEnum.CUSTOMER)}
              >
                {generateCustomerField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('customerSite')}</th>
              <td
                aria-label="customerSite-field"
                onClick={toggleUpdateField(ContactFieldsEnum.CUSTOMER_SITE)}
              >
                {generateCustomerSiteField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('customerSiteNumber')}</th>
              <td
                aria-label="customerSiteNumber-field"
                onClick={toggleUpdateField(
                  ContactFieldsEnum.CUSTOMER_SITE_NUMBER
                )}
              >
                {generateCustomerSiteNumberField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('industry')}</th>
              <td
                aria-label="industry-field"
                onClick={toggleUpdateField(
                  ContactFieldsEnum.CONTACT_PERSON_SECTORS
                )}
              >
                {generateIndustryField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('role')}</th>
              <td
                aria-label="role-field"
                onClick={toggleUpdateField(ContactFieldsEnum.ROLE)}
              >
                {generateRoleField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('function')}</th>
              <td
                aria-label="functionRole-field"
                onClick={toggleUpdateField(ContactFieldsEnum.FUNCTION)}
              >
                {generateFunctionField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('topic')}</th>
              <td
                aria-label="topic-field"
                onClick={toggleUpdateField(ContactFieldsEnum.TOPIC)}
              >
                {generateTopicField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('phoneNumber')}</th>
              <td
                aria-label="phoneNumber-field"
                onClick={toggleUpdateField(ContactFieldsEnum.PHONE_NUMBER)}
              >
                {generatePhoneField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('mobileNumber')}</th>
              <td
                aria-label="mobileNumber-field"
                onClick={toggleUpdateField(ContactFieldsEnum.MOBILE_NUMBER)}
              >
                {generateMobileField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('xingProfile')}</th>
              <td
                aria-label="xingProfile-field"
                onClick={toggleUpdateField(ContactFieldsEnum.XING_PROFILE)}
              >
                {generateXingField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('linkedInProfile')}</th>
              <td
                aria-label="linkedIn-field"
                onClick={toggleUpdateField(ContactFieldsEnum.LINKEDIN_PROFILE)}
              >
                {generateLinkedInField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{`${t('emailAddress')} ${t(
                'and'
              )} ${t('status')}`}</th>
              <td
                aria-label="emailAddress-field"
                onClick={toggleUpdateField(ContactFieldsEnum.EMAIL_ADDRESS)}
              >
                {generateEmailAddressField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('gdprStatus')}</th>
              <td
                aria-label="gdprStatus-field"
                onClick={toggleUpdateField(ContactFieldsEnum.STATUS_GDPR)}
              >
                {generateStatusGdprField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('contactAlumnus')}</th>
              <td
                aria-label="contactAlumnus-field"
                onClick={toggleUpdateField(ContactFieldsEnum.CONTACT_ALUMNUS)}
              >
                {generateContactAlumnusField()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('mainLanguage')}</th>
              <td
                aria-label="mainLanguage-field"
                onClick={toggleUpdateField(
                  ContactFieldsEnum.PREFERRED_LANGUAGE
                )}
              >
                {generatePreferredLanguage()}
              </td>
            </tr>
            <tr>
              <th style={{ width: '30%' }}>{t('newsletter')}</th>
              <td
                aria-label="newsletter-field"
                onClick={toggleUpdateField(ContactFieldsEnum.NEWSLETTER)}
              >
                {generateNewsletter()}
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (store: RootState) => ({
  employeeDetails: store.account.employeeDetails,
});

const mapDispatchToProps = { setThenRemoveAlert };
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(ContactPersonDetailsCard)));
