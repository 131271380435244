/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Input, Row, Table } from 'reactstrap';
import Select from 'react-select';
import { v4 } from 'uuid';

import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import { canWrite } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import { RootState } from '../../../redux/store';
import {
  IDropdownOption,
  IDropdownOptionInvolved,
  IObjectContactInformation,
  IObjectInvolvedResponsible,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import {
  IActivityListItem,
  ICountryState,
} from '../../../utils/types/modelTypes';
import FadeAlert from '../../../components/layout/FadeAlert';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  isEmpty,
  isInvolvedResponsiblesNotModified,
} from '../../../utils/helpers/array';
import { fetchEmployeesWithCustomerSiteListPermission } from '../../../redux/customerSiteListSlice';
import SelectNewResponsibleModal from '../../../components/form/SelectNewResponsibleModal';
import MultipleSectorSelect from '../../../components/dropdowns/MultipleSectorSelect';
import { TargetPriority } from '../../../utils/enums/customer';
import ActivityNotesCard from '../../../components/cards/ActivityNotesCard';
import DetailsCard from '../../../components/cards/DetailsCard';
import {
  IEditedCustomerSiteDetails,
  matchingSectors,
  showCustomerAccountLink,
  showCustomerLink,
  translate,
  showNoneIfNoValue,
  createFormValuesFromCustomerSite,
  checkContactInformationIfInvalid,
  isContactPersonModified,
} from './customerSiteDetailsHelper';
import {
  getCustomerSiteWithNameLocationInUse,
  saveCustomerSiteDetails,
} from '../../../services/api/customerSite';
import MultipleResponsibleDropdown from '../../../components/form/MultipleResponsibleDropdown';
import { CustomerSiteDetailsContext } from './CustomerSiteDetailsContextProvider';
import GeneralInfoForm from '../../../components/form/GeneralInfoForm/GeneralInfoForm';
import CountryDropdown from '../../../components/dropdowns/CountryDropdown';
import CountryStateDropdown from '../../../components/dropdowns/CountryStateDropdown';
import { customerSiteFieldsEnum } from '../../../utils/enums/enum';
import { emptyObjectNameAndId } from '../CustomerDetails/customerDetailsHelper';
import ResponsibleContactPersonEditor from '../../../components/form/ResponsibleContactPersonForm/ResponsibleContactPersonEditor';
import { dropdownOptionsToObjectNameAndId } from '../../../utils/helpers/dropdown';

interface IProps extends PropsFromRedux, IWithModalsProps {}

const CustomerSiteCard = ({
  // WithModals
  modalErrorHandler,
  modalFormHandler,
  modalConfirmHandler,
  toggleModalForm,
  // Redux
  account: {
    employeeDetails: { id, firstname, name: lastName },
  },
  employeesWithPermission,
  // Thunks
  fetchEmployeesWithCustomerSiteListPermission,
}: IProps) => {
  const { customerSiteDetails, setCustomerSiteDetails } = useContext(
    CustomerSiteDetailsContext
  );

  // States
  const [editedCustomerSiteDetails, setEditedCustomerSiteDetails] =
    useState<IEditedCustomerSiteDetails>({
      id: 0,
      name: '',
      location: '',
      targetPriority: TargetPriority.TO_BE_DEFINED,
      customerSiteState: '',
      sectors: [],
      responsible: {
        id: Number(id),
        name: `${firstname} ${lastName}`,
      },
      involvedEmployees: [],
      contactInformation: [],
      customerSiteType: '',
      street: '',
      zipCode: '',
      country: emptyObjectNameAndId,
      countryState: { ...emptyObjectNameAndId, country: emptyObjectNameAndId },
      contactPersons: [],
    });

  const [successMessage, setSuccessMessage] = useState('');
  const [hasWritePermission, setHasWritePermission] = useState(false);
  const [fieldForUpdate, setFieldForUpdate] = useState('');
  const [fieldsWithUpdates, setFieldsWithUpdates] = useState<string[]>([]);
  const [filteredInvolvedIds, setFilteredInvolvedIds] = useState<number[]>();

  const {
    name: nameField,
    location: locationField,
    targetPriority: targetPriorityField,
    sectors: sectorsField,
    involvedEmployees: involvedEmployeesField,
    contactInformation: contactInformationField,
    customerSiteType: customerSiteTypeField,
    street: streetField,
    zipCode: zipCodeField,
    country: countryField,
    countryState: countryStateField,
    contactPersons: contactPersonsField,
  } = customerSiteFieldsEnum;

  const {
    name,
    location,
    targetPriority,
    sectors,
    responsible,
    involvedEmployees,
    contactInformation,
    customerSiteType,
    street,
    zipCode,
    country,
    countryState,
    contactPersons,
  } = editedCustomerSiteDetails;
  const {
    id: customerSiteId,
    responsible: savedResponsible,
    customerSiteState: savedState,
    customer,
    activities,
  } = customerSiteDetails;

  // Check fieldsWithUpdates for save and reset button
  const isSaveDisabled = isEmpty(fieldsWithUpdates);

  const isContactInformationInvalid = checkContactInformationIfInvalid(
    fieldsWithUpdates,
    contactInformation
  );

  const checkPermission = async (
    userId: number | undefined,
    customerSiteId: number | undefined,
    objectType: string
  ) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      setHasWritePermission(
        (await canWrite(userId, customerSiteId, objectType)) as boolean
      );
    } catch (error) {
      modalErrorHandler(translate('failedToRetrieveUserAccessType'), error);
    }
  };

  // Functions, variables event handlers
  const toggleUpdateField = (field: string) => {
    if (fieldForUpdate !== field && hasWritePermission) {
      setFieldForUpdate(field);
    }
  };

  // Input changes handlers
  const updateFieldChanges = (fieldValue: string, noChange: boolean) => {
    setFieldsWithUpdates((prevFields) => {
      if (noChange) {
        return prevFields.filter((item) => item !== fieldValue);
      }
      if (prevFields.includes(fieldValue)) {
        return prevFields;
      }
      return [...prevFields, fieldValue];
    });
  };

  const handleResetForm = () => {
    setFieldForUpdate('');
    setFieldsWithUpdates([]);
    setEditedCustomerSiteDetails(
      createFormValuesFromCustomerSite(customerSiteDetails)
    );
  };

  const handleNameChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const isNameUnchanged = customerSiteDetails.name === value;
    updateFieldChanges(customerSiteFieldsEnum.name, isNameUnchanged);
    setEditedCustomerSiteDetails({ ...editedCustomerSiteDetails, name: value });
  };

  const handleLocationChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const isLocationUnchanged = customerSiteDetails.location === value;
    updateFieldChanges(customerSiteFieldsEnum.location, isLocationUnchanged);
    setEditedCustomerSiteDetails({
      ...editedCustomerSiteDetails,
      location: value,
    });
  };

  const handleCustomerSiteTypeChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const isTypeUnchanged = customerSiteDetails?.customerSiteType === value;
    updateFieldChanges(
      customerSiteFieldsEnum.customerSiteType,
      isTypeUnchanged
    );
    setEditedCustomerSiteDetails({
      ...editedCustomerSiteDetails,
      customerSiteType: value,
    });
  };

  const handleStreetChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const isStreetUnchanged = customerSiteDetails?.street === value;
    updateFieldChanges(
      customerSiteFieldsEnum.customerSiteType,
      isStreetUnchanged
    );
    setEditedCustomerSiteDetails({
      ...editedCustomerSiteDetails,
      street: value,
    });
  };

  const handleZipCodeChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const isZipCodeUnchanged = customerSiteDetails?.zipCode === value;
    updateFieldChanges(
      customerSiteFieldsEnum.customerSiteType,
      isZipCodeUnchanged
    );
    setEditedCustomerSiteDetails({
      ...editedCustomerSiteDetails,
      zipCode: value,
    });
  };

  const handleCountryChange = (country: IObjectNameAndId) => {
    const updatedCountry = {
      country,
      countryState,
    };
    if (countryState?.country.id !== country.id) {
      updatedCountry.countryState = {
        ...emptyObjectNameAndId,
        country: emptyObjectNameAndId,
      };
    }
    setEditedCustomerSiteDetails({
      ...editedCustomerSiteDetails,
      ...updatedCountry,
    });
    updateFieldChanges(
      customerSiteFieldsEnum.country,
      country.id === customerSiteDetails.country?.id
    );
    updateFieldChanges(
      customerSiteFieldsEnum.countryState,
      updatedCountry.countryState?.name ===
        customerSiteDetails.countryState?.name
    );
  };

  const handleCountryStateChange = (countryState: ICountryState) => {
    if (!country) {
      setEditedCustomerSiteDetails({
        ...editedCustomerSiteDetails,
        countryState,
        country: countryState.country,
      });
    } else {
      setEditedCustomerSiteDetails({
        ...editedCustomerSiteDetails,
        countryState,
      });
    }
    updateFieldChanges(
      customerSiteFieldsEnum.countryState,
      countryState.name === customerSiteDetails.countryState?.name
    );
    updateFieldChanges(
      customerSiteFieldsEnum.country,
      countryState.country.name === customerSiteDetails.country?.name
    );
  };

  const handleContactPersonChange = (newContactPersons: IObjectNameAndId[]) => {
    const isContactPersonChanged = isContactPersonModified(
      contactPersons,
      newContactPersons
    );

    updateFieldChanges(
      customerSiteFieldsEnum.contactPersons,
      isContactPersonChanged
    );
    setEditedCustomerSiteDetails({
      ...editedCustomerSiteDetails,
      contactPersons: newContactPersons,
    });
  };

  const handleTargetPriorityChange = ({ value }: IDropdownOption) => {
    updateFieldChanges(
      customerSiteFieldsEnum.targetPriority,
      customerSiteDetails?.targetPriority === value
    );
    setEditedCustomerSiteDetails({
      ...editedCustomerSiteDetails,
      targetPriority: value,
    });
  };

  const handleMultipleSectorSelectionChange = (sectors: IObjectNameAndId[]) => {
    if (sectors.length > 0) {
      updateFieldChanges(customerSiteFieldsEnum.sectors, false);
      setEditedCustomerSiteDetails({ ...editedCustomerSiteDetails, sectors });
    }
  };

  const handleResponsibleChange = ({ id }: IObjectNameAndId) => {
    updateFieldChanges(customerSiteFieldsEnum.responsible, false);
    const newResponsible = employeesWithPermission?.find(
      (r) => r.value === id
    ) as IDropdownOption<number>;

    setEditedCustomerSiteDetails({
      ...editedCustomerSiteDetails,
      responsible: {
        id: newResponsible.value,
        name: newResponsible.label,
      },
    });

    toggleModalForm();
  };

  const handleSetNewResponsible = () => {
    modalFormHandler(
      translate('newResponsible'),
      <SelectNewResponsibleModal
        objectName={editedCustomerSiteDetails.name}
        objectType={translate('customer')}
        currentResponsible={editedCustomerSiteDetails.responsible}
        employeesWithPermission={employeesWithPermission}
        onSetResponsible={handleResponsibleChange}
        onCancel={toggleModalForm}
      />
    );
  };

  const handleSetResponsible = (involved: IDropdownOptionInvolved[]) => {
    setEditedCustomerSiteDetails({
      ...editedCustomerSiteDetails,
      involvedEmployees: involved.map(
        ({ responsible, responsibleRole }) =>
          ({
            ...(responsible
              ? {
                  id: responsible.value,
                  name: responsible.label,
                }
              : {}),
            ...(responsibleRole
              ? {
                  responsibleRole: {
                    id: responsibleRole.value,
                    name: responsibleRole.label,
                  },
                }
              : {}),
          } as IObjectInvolvedResponsible)
      ),
    });
    updateFieldChanges(
      customerSiteFieldsEnum.involvedEmployees,
      isInvolvedResponsiblesNotModified(
        involvedEmployees,
        customerSiteDetails.involvedEmployees
      )
    );
  };

  const handleUpdateContactInformation = (
    contactInformation: IObjectContactInformation[]
  ) => {
    setEditedCustomerSiteDetails({
      ...editedCustomerSiteDetails,
      contactInformation,
    });
    updateFieldChanges(customerSiteFieldsEnum.contactInformation, false);
  };

  const saveNewCustomerSiteDetails = async () => {
    const propertiesToSave = {} as IEditedCustomerSiteDetails;
    propertiesToSave.id = customerSiteId;
    fieldsWithUpdates.forEach((field: string) => {
      const validField: keyof IEditedCustomerSiteDetails =
        field as keyof IEditedCustomerSiteDetails;
      if (field === customerSiteFieldsEnum.involvedEmployees) {
        propertiesToSave.involvedEmployees =
          editedCustomerSiteDetails.involvedEmployees.filter(
            (involved) => involved.id
          );
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (propertiesToSave as any)[validField] =
          editedCustomerSiteDetails[validField];
      }
    });

    try {
      const { data } = await saveCustomerSiteDetails(propertiesToSave);
      setFieldsWithUpdates([]);
      setSuccessMessage(translate('updatedCustomerSiteSuccessfully'));
      setCustomerSiteDetails(data);
    } catch (error) {
      modalErrorHandler(translate('failedToUpdateCustomerSite'), error);
    }
  };

  // Prop function for ActivityNotesCard
  const setActivities = (activities: IActivityListItem[]) => {
    setCustomerSiteDetails({
      ...customerSiteDetails,
      activities,
    });
  };

  const handleSaveDetails = async () => {
    setFieldForUpdate('');
    const { data: isCustomerSiteNameAndLocationExists } =
      await getCustomerSiteWithNameLocationInUse(customer.id, name, location);

    // If location/city is being updated and 'isCustomerSiteNameAndLocationExists' is true
    if (
      isCustomerSiteNameAndLocationExists &&
      fieldsWithUpdates.includes(locationField)
    ) {
      modalErrorHandler(translate('sameCustomerSiteExists'));
      return;
    }

    const matchedSectors = matchingSectors(
      customerSiteDetails.sectors,
      editedCustomerSiteDetails.sectors
    );

    // Show prompt if there are affected entities for to be deleted sectors
    if (!isEmpty(matchedSectors)) {
      modalConfirmHandler(
        translate('warning'),
        <>
          {translate('deleteSectorDialog')}:
          <br />
          <strong>
            {matchedSectors.map((sector) => sector.name).join(', ')}
          </strong>
          <br />
          {translate('deleteSectorEffectOnSites')}
        </>,
        () => {
          saveNewCustomerSiteDetails();
        }
      );
    } else {
      saveNewCustomerSiteDetails();
    }
  };

  const headerButtons = hasWritePermission && (
    <div className="card-actions float-end">
      <Button
        color="primary"
        onClick={() => handleSaveDetails()}
        disabled={isSaveDisabled || isContactInformationInvalid}
        data-testid="save"
      >
        {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, translate('save'))}
      </Button>{' '}
      <Button
        color="primary"
        onClick={handleResetForm}
        disabled={isSaveDisabled}
        data-testid="reset"
      >
        {generateTitle(BUTTON_TITLE_ENUM.UNDO.code, translate('reset'))}
      </Button>{' '}
    </div>
  );

  // Data preparation for DetailsCard
  const tableData = [
    {
      label: translate('customer'),
      value: showCustomerLink(customerSiteDetails.customer ?? undefined),
    },
    {
      label: translate('nameAtSite'),
      value: (
        <div
          onClick={() => toggleUpdateField(nameField)}
          style={{ height: '20%' }}
          data-testid="name"
        >
          {fieldForUpdate === nameField ? (
            <Input
              bsSize="lg"
              type="string"
              onChange={handleNameChange}
              value={name}
              data-testid="name-input"
            />
          ) : (
            showNoneIfNoValue(name)
          )}
        </div>
      ),
      isRequired: true,
    },
    {
      label: translate('account'),
      value: showCustomerAccountLink(
        customerSiteDetails.customerAccount ?? undefined
      ),
    },
    {
      label: translate('city'),
      value: (
        <div
          onClick={() => toggleUpdateField(locationField)}
          style={{ height: '20%' }}
          data-testid="location"
        >
          {fieldForUpdate === locationField ? (
            <Input
              bsSize="lg"
              type="string"
              onChange={handleLocationChange}
              value={location}
              data-testid="location-input"
            />
          ) : (
            showNoneIfNoValue(location)
          )}
        </div>
      ),
      isRequired: true,
    },
    {
      label: translate('status'),
      value: showNoneIfNoValue(savedState),
    },
    {
      label: translate('targetPriority'),
      value: (
        <div
          onClick={() => toggleUpdateField(targetPriorityField)}
          style={{ height: '20%' }}
          data-testid="targetpriority"
        >
          {fieldForUpdate === targetPriorityField ? (
            <div data-testid="targetprioritycombobox">
              <Select
                options={Object.values(TargetPriority).map((item) => ({
                  value: item,
                  label: i18n.t(`TargetPriority.${item}`),
                }))}
                value={{
                  label: i18n.t(`TargetPriority.${targetPriority}`),
                }}
                onChange={handleTargetPriorityChange}
              />
            </div>
          ) : (
            showNoneIfNoValue(i18n.t(`TargetPriority.${targetPriority}`))
          )}
        </div>
      ),
      isRequired: true,
    },
    {
      label: translate('sector'),
      value: (
        <div
          onClick={() => toggleUpdateField(sectorsField)}
          style={{ cursor: 'text' }}
          data-testid="sector-div"
        >
          {fieldForUpdate === sectorsField && (
            <div data-testid="sectorcombobox">
              <MultipleSectorSelect
                onChange={handleMultipleSectorSelectionChange}
                sectorIds={sectors.map((sector) => sector.id)}
              />
            </div>
          )}
          {fieldForUpdate !== sectorsField &&
            !isEmpty(sectors) &&
            sectors.map(({ name }, index) =>
              index === 0 ? name : `, ${name}`
            )}
        </div>
      ),
      isRequired: true,
    },
    {
      label: translate('type'),
      value: (
        <div
          onClick={() => toggleUpdateField(customerSiteTypeField)}
          style={{ height: '20%' }}
          data-testid="customerSiteType"
        >
          {fieldForUpdate === customerSiteTypeField ? (
            <Input
              bsSize="lg"
              type="string"
              onChange={handleCustomerSiteTypeChange}
              value={customerSiteType}
              data-testid="customerSiteType-input"
            />
          ) : (
            showNoneIfNoValue(customerSiteType)
          )}
        </div>
      ),
    },
    {
      label: translate('responsible'),
      value: (
        <div style={{ lineHeight: '1.5em' }}>
          <Link
            style={{ paddingRight: '30px' }}
            to={`/employees/employee-list/employee-detail/${savedResponsible?.id}`}
          >
            {savedResponsible?.name}
          </Link>
          <Button
            disabled={!hasWritePermission}
            color="primary"
            onClick={handleSetNewResponsible}
          >
            {translate('setNewResponsible')}
          </Button>
        </div>
      ),
    },
    {
      label: translate('involved'),
      value:
        fieldForUpdate === involvedEmployeesField ? (
          <div data-testid="involvedcombobox">
            <MultipleResponsibleDropdown
              responsibles={involvedEmployees.map(
                ({ id, name, responsibleRole }) =>
                  ({
                    ...(id && name
                      ? {
                          responsible: { label: name, value: id },
                        }
                      : {}),
                    ...(responsibleRole
                      ? {
                          responsibleRole: {
                            label: responsibleRole?.name,
                            value: responsibleRole.id,
                          },
                        }
                      : {}),
                  } as {
                    responsible: IDropdownOption<number>;
                    responsibleRole: IDropdownOption<number>;
                  })
              )}
              setResponsibles={handleSetResponsible}
              responsibleOptions={
                employeesWithPermission?.filter(
                  (employee) => !filteredInvolvedIds?.includes(employee.value)
                ) ?? ({} as IDropdownOption<number>[])
              }
            />
          </div>
        ) : (
          <div
            onClick={() => toggleUpdateField(involvedEmployeesField)}
            style={{ cursor: 'text' }}
            data-testid="involved"
          >
            {!isEmpty(involvedEmployees)
              ? involvedEmployees?.map(({ id, name, responsibleRole }) => (
                  <Row key={v4()} style={{ height: '22px' }}>
                    <Col key={v4()}>
                      <Link
                        to={`/employees/employee-list/employee-detail/${id}`}
                      >
                        {`${name} ${
                          responsibleRole?.id
                            ? `(${responsibleRole?.name})`
                            : ''
                        }`}
                      </Link>
                    </Col>
                  </Row>
                ))
              : translate('none')}
          </div>
        ),
    },

    {
      label: translate('street'),
      value: (
        <div
          onClick={() => toggleUpdateField(streetField)}
          style={{ height: '20%' }}
          data-testid="street"
        >
          {fieldForUpdate === streetField ? (
            <Input
              bsSize="lg"
              type="string"
              onChange={handleStreetChange}
              value={street}
              data-testid="street-input"
            />
          ) : (
            showNoneIfNoValue(street)
          )}
        </div>
      ),
    },
    {
      label: translate('zipCode'),
      value: (
        <div
          onClick={() => toggleUpdateField(zipCodeField)}
          style={{ height: '20%' }}
          data-testid="zipCode"
        >
          {fieldForUpdate === zipCodeField ? (
            <Input
              bsSize="lg"
              type="string"
              onChange={handleZipCodeChange}
              value={zipCode}
              data-testid="zipCode-input"
            />
          ) : (
            showNoneIfNoValue(zipCode)
          )}
        </div>
      ),
    },
    {
      label: translate('country'),
      value: (
        <div
          onClick={() => toggleUpdateField(countryField)}
          style={{ cursor: 'text' }}
          data-testid="country"
        >
          {fieldForUpdate === countryField ? (
            <CountryDropdown
              countryId={country?.id ?? 0}
              onChange={handleCountryChange}
            />
          ) : (
            showNoneIfNoValue(country?.name)
          )}
        </div>
      ),
    },
    {
      label: translate('countryState'),
      value: (
        <div
          onClick={() => toggleUpdateField(countryStateField)}
          style={{ cursor: 'text' }}
          data-testid="countryState"
        >
          {fieldForUpdate === countryStateField ? (
            <div data-testid="countryStatecombobox">
              <CountryStateDropdown
                countryId={country?.id ?? 0}
                countryStateId={countryState?.id ?? 0}
                onChange={handleCountryStateChange}
              />
            </div>
          ) : (
            showNoneIfNoValue(countryState?.name)
          )}
        </div>
      ),
    },
    {
      label: translate('contactPersons'),
      value: (
        <div
          onClick={() => toggleUpdateField(contactPersonsField)}
          style={{ cursor: 'text' }}
          data-testid="contactPersons"
        >
          {fieldForUpdate === contactPersonsField ? (
            <div data-testid="contactPersonscombobox">
              <ResponsibleContactPersonEditor
                responsibleContactPersons={contactPersons}
                customerId={customer?.id}
                customerSiteId={customerSiteId}
                onChange={(value) =>
                  handleContactPersonChange(
                    dropdownOptionsToObjectNameAndId(value)
                  )
                }
              />
            </div>
          ) : (
            contactPersons?.map((contact) => (
              <Row key={v4()}>
                <Col key={v4()}>
                  <Link
                    key={v4()}
                    to={`/customer/customers/details/${customer?.id}/contact-person-profile/${contact?.id}`}
                  >
                    {showNoneIfNoValue(contact?.name)}
                  </Link>
                </Col>
              </Row>
            ))
          )}
        </div>
      ),
    },
    {
      label: translate('generalContactInformation'),
      value: (
        <div
          onClick={() => toggleUpdateField(contactInformationField)}
          style={{ cursor: 'text' }}
          data-testid="generalcontactinformation"
        >
          <Table>
            <tbody>
              <tr>
                <td
                  onClick={() =>
                    toggleUpdateField(customerSiteFieldsEnum.contactInformation)
                  }
                  style={{ cursor: 'text', width: '100%' }}
                  colSpan={2}
                >
                  {fieldForUpdate ===
                  customerSiteFieldsEnum.contactInformation ? (
                    <div data-testid="generalcontactinformationcombobox">
                      <GeneralInfoForm
                        contactInformations={contactInformation}
                        onChange={handleUpdateContactInformation}
                      />
                    </div>
                  ) : (
                    !isEmpty(contactInformation) && (
                      <Table size="sm">
                        <thead>
                          <tr>
                            <th style={{ border: 0 }}>
                              {i18n.t('GeneralInfosFormInput.info')}
                            </th>
                            <th style={{ border: 0 }}>
                              {i18n.t('GeneralInfosFormInput.type')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {contactInformation.map(({ id, info, type }) => (
                            <tr key={id}>
                              <td style={{ border: 0 }}>{info}</td>
                              <td style={{ border: 0 }}>{type}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      ),
      isRequired: isContactInformationInvalid,
    },
  ];

  // UseEffects
  useEffect(() => {
    setFilteredInvolvedIds([
      responsible.id,
      ...involvedEmployees.map((involved) => involved.id),
    ]);
  }, [responsible, involvedEmployees]);

  useEffect(() => {
    if (customerSiteDetails?.id) {
      checkPermission(
        Number(id),
        customerSiteDetails?.id,
        OBJECT_TYPE_ENUM.customerSite.code
      );
      setEditedCustomerSiteDetails(
        createFormValuesFromCustomerSite(customerSiteDetails)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSiteDetails.id]);

  useEffect(() => {
    if (!employeesWithPermission)
      fetchEmployeesWithCustomerSiteListPermission((error) => {
        modalErrorHandler(translate('failedToRetrieveEmployees'), error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      {successMessage && (
        <FadeAlert color="success" data-testid="successmessage">
          {successMessage}
        </FadeAlert>
      )}
      <Row>
        <Col className="border-end border-light">
          <DetailsCard
            title={customerSiteDetails?.name}
            headerButtons={headerButtons as JSX.Element}
            leftTitle
            largeTitle
            fields={tableData}
          />
        </Col>
        <Col className="m-3">
          {customerSiteDetails?.id && (
            <ActivityNotesCard
              objectId={customerSiteDetails?.id}
              objectType={OBJECT_TYPE_ENUM.customerSite.code}
              activities={activities}
              setActivities={setActivities}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (store: RootState) => ({
  account: store.account,
  employees: store.employeeList.listItems,
  employeesWithPermission:
    store.customerSiteList.additionalState?.employeesWithPermission,
});

const mapDispatchToProps = {
  fetchEmployeesWithCustomerSiteListPermission,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(CustomerSiteCard));
