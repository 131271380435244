import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { useEffect, useState } from 'react';

import { default as axios } from '../../../services/axios/axios';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import {
  ACQUISITION_TYPE_ENUM,
  PROJECT_STATE_ENUM,
  PROJECT_TYPES_ENUM,
  STAFFED_ENUM,
} from '../../../utils/enums/project';
import {
  saveResponsibleContactPersons,
  saveResponsibles,
  sortByDate,
} from '../../../utils/helpers/GenericHelper';
import { isEmpty } from '../../../utils/helpers/array';
import { isStringNotEmpty } from '../../../utils/helpers/string';
import { clickHandler } from '../../../utils/helpers/click';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { RootState } from '../../../redux/store';
import {
  IActivity,
  IApprovalRequest,
  ICustomer,
  IOffer,
  IProject,
  IResponsible,
  IResponsibleContactPerson,
  IUserToDoActivity,
} from '../../../utils/types/modelTypes';
import { OfferState } from '../../../utils/enums/offer';
import { Ownership } from '../../../utils/enums/ownership';
import ProjectOptionDescriptionCard from './ProjectOptionDescriptionCard';
import ProjectOptionGeneralInfoCard from './ProjectOptionGeneralInfoCard';
import ProjectOptionResponsibleCard from './ProjectOptionResponsibleCard';
import CustomerAbbreviationInput from '../../../components/form/CustomerAbbreviationInput';
import ProjectBudgetAndScheduleCard from './ProjectBudgetAndScheduleCard';
import {
  IDropdownOption,
  IObjectCustomer,
} from '../../../utils/types/commonTypes';
import { dropdownOptionToObjectNameAndId } from '../../../utils/helpers/dropdown';
import { getOffers } from '../../../services/api/offer';

interface IProps extends PropsFromRedux, RouteComponentProps, IWithModalsProps {
  project?: IProject;
  approvalRequest?: IApprovalRequest | null;
  forApproval: boolean;
  onCancel: () => void;
  onSave?: (projectId: number) => void;
}

const UnbilledCustomerProjectOverview = ({
  project,
  approvalRequest = {} as IApprovalRequest,
  forApproval,
  onCancel,
  onSave,
  account,
  projects,
  modalFormHandler,
  modalErrorHandler,
}: IProps) => {
  const [stateProject, setStateProject] = useState<IProject>(
    project || {
      title: '',
      customer: {} as ICustomer,
      customerId: 0,
      projectSharePoint: '',
      originOfOption: null,
      estimationTemplatesLink: '',
      projectType: PROJECT_TYPES_ENUM.investmentProject.code,
      acquisitionType: ACQUISITION_TYPE_ENUM.undefined.code,
      staffedOption: STAFFED_ENUM.no_staff.code,
      state: PROJECT_STATE_ENUM.active.code,
    }
  );
  const [projectOriginal, setProjectOriginal] = useState<IProject>(
    project || {
      title: '',
      customer: {} as ICustomer,
      customerId: 0,
      projectSharePoint: '',
      originOfOption: null,
      estimationTemplatesLink: '',
      projectType: PROJECT_TYPES_ENUM.investmentProject.code,
      acquisitionType: ACQUISITION_TYPE_ENUM.undefined.code,
      staffedOption: STAFFED_ENUM.no_staff.code,
      state: PROJECT_STATE_ENUM.active.code,
    }
  );

  const [databaseProjectId, setDatabaseProjectId] = useState<number | null>(
    project?.id ?? null
  );

  const [responsibleContactPersons, setResponsibleContactPersons] = useState<
    IResponsibleContactPerson[]
  >([]);
  const [responsible, setResponsible] = useState<IResponsible>({
    employeeId: account?.employeeDetails?.id ?? 0,
    objectId: project?.id ?? 0,
    objectType: OBJECT_TYPE_ENUM.project.code,
    ownership: Ownership.RESPONSIBLE,
  } as IResponsible);
  const [involvedResponsibles, setInvolvedResponsibles] = useState<
    IResponsible[]
  >([]);
  const [, setCustomer] = useState<ICustomer>({} as ICustomer);
  const [newProjectActivities, setNewProjectActivities] = useState<
    IUserToDoActivity[]
  >([]);
  const [, setProjectActivities] = useState<IUserToDoActivity[]>([]);
  const [, setOffers] = useState<IOffer[]>([]);

  const [latestOffer, setLatestOffer] = useState<IOffer>({} as IOffer);

  const [offerOriginal, setOfferOriginal] = useState<IOffer>({} as IOffer);
  const [selectedApprover, setSelectedApprover] = useState<
    IDropdownOption<number>
  >({} as IDropdownOption<number>);
  const [forcedUpdate] = useState<boolean>(false);

  const translateCreateUnbilledProject = (keyName: string): string =>
    i18n.t('CreateUnbilledProject.' + keyName);

  /**
   * Method to retrieve offers of the project and set to this.state
   */
  const loadOffers = () => {
    const { customerId, id } = stateProject;
    getOffers({
      'customerId.equals': customerId.toString(),
      'projectId.equals': id?.toString() ?? '0',
    })
      .then(({ data }) => {
        if (!isEmpty(data)) {
          setOffers(data);
          setLatestOffer(data[0] as IOffer);
          setOfferOriginal(data[0] as IOffer);
        }
      })
      .catch((error) => {
        const mainError = `${translateCreateUnbilledProject(
          'failedToRetrieveOffer'
        )}`;
        modalErrorHandler(mainError, error);
      });
  };

  /**
   * Format the Activity entities based on how ProjectActivities look
   * @param {*} otherActivity
   * @returns
   */
  const formatOtherActivities = (otherActivity: IActivity) =>
    ({
      action: otherActivity?.description,
      dueDate: otherActivity?.date,
    } as IUserToDoActivity);

  /**
   * Method to retrieve activities of the project and set to this.state
   */
  const loadActivities = async () => {
    const databaseProjectId = stateProject.id ?? 0;
    const fetchedProjectActivities = await axios.project
      .get(`user-to-do-activities/PROJECT/${databaseProjectId}`)
      .then((res: AxiosResponse<IUserToDoActivity[]>) => res.data)
      .catch((error) => {
        modalErrorHandler(
          translateCreateUnbilledProject(
            'failedToRetrieveProjectActivityHistory'
          ),
          error
        );
      });

    // Retrieve the activities that are not necessarily "Project Option Activities" but are still considered as activities for the project option
    const otherActivities = await axios.sales
      .get(
        `activities?objectId.equals=${databaseProjectId}&objectType.equals=${OBJECT_TYPE_ENUM.projectOption.code}`
      )
      .then((res: AxiosResponse<IActivity[]>) => res.data)
      .catch((error) => {
        modalErrorHandler(
          translateCreateUnbilledProject(
            'failedToRetrieveProjectActivityHistory'
          ),
          error
        );
      });
    let allActivities: IUserToDoActivity[] = [];

    if (!isEmpty(otherActivities)) {
      const formattedOtherActivities: IUserToDoActivity[] = [];
      otherActivities?.forEach((otherActivity: IActivity) => {
        formattedOtherActivities.push(formatOtherActivities(otherActivity));
      });
      if (!isEmpty(formattedOtherActivities)) {
        allActivities = formattedOtherActivities;
      }
    }

    if (!isEmpty(fetchedProjectActivities) && fetchedProjectActivities) {
      allActivities = [...allActivities, ...fetchedProjectActivities];
    }

    setProjectActivities(
      sortByDate(allActivities, 'dueDate') as IUserToDoActivity[]
    );
  };

  useEffect(() => {
    async function fetchData() {
      let project: IProject;
      let responsibleContactPersons: IResponsibleContactPerson[] =
        [] as IResponsibleContactPerson[];
      let responsible: IResponsible = {
        employeeId: account?.employeeDetails?.id ?? 0,
        objectId: databaseProjectId ?? 0,
        objectType: OBJECT_TYPE_ENUM.project.code,
        ownership: Ownership.RESPONSIBLE,
      };
      let involvedResponsibles: IResponsible[] = [] as IResponsible[];
      if (databaseProjectId) {
        // Getting the project, responsibleContactPersons and responsibles
        const axiosArray = [];
        axiosArray.push(
          await axios.project.get(`projects/${databaseProjectId}`)
        );
        axiosArray.push(
          await axios.sales.get(
            `responsible-contact-people?objectType.equals=${OBJECT_TYPE_ENUM.project.code}&objectId.equals=${databaseProjectId}`
          )
        );
        axiosArray.push(
          await axios.sales.get(
            `responsibles?objectType.equals=${OBJECT_TYPE_ENUM.project.code}&objectId.equals=${databaseProjectId}`
          )
        );

        const responses = await Promise.all(axiosArray).catch((error) => {
          modalErrorHandler(
            translateCreateUnbilledProject('failedToRetrieveProjectDetails'),
            error
          );
        });
        if (!responses) return;

        project = projects?.contactDetailsClicked
          ? projects.editedProject
          : responses[0]?.data;

        responsibleContactPersons = projects?.contactDetailsClicked
          ? projects.editedResponsibleContactPersons
          : responses[1]?.data;

        const allResponsibles: IResponsible[] = responses[2]?.data;

        responsible =
          allResponsibles.find(
            (item: IResponsible) => item.ownership === Ownership.RESPONSIBLE
          ) ?? ({} as IResponsible);
        involvedResponsibles = allResponsibles.filter(
          (item: IResponsible) => item.ownership === Ownership.INVOLVED
        );

        const customerId = project?.customerId;

        // Get Data
        if (customerId) {
          loadOffers();
        }
      } else {
        // For new unbilled project, set state to active (default)
        project = {
          title: '',
          customer: {} as ICustomer,
          customerId: 0,
          customerSiteId: 0,
          projectSharePoint: '',
          originOfOption: null,
          estimationTemplatesLink: '',
          projectType: PROJECT_TYPES_ENUM.investmentProject.code,
          acquisitionType: ACQUISITION_TYPE_ENUM.undefined.code,
          staffedOption: STAFFED_ENUM.no_staff.code,
          state: PROJECT_STATE_ENUM.active.code,
        };
      }
      if (isEmpty(responsibleContactPersons)) {
        responsibleContactPersons = projects?.contactDetailsClicked
          ? projects.editedResponsibleContactPersons
          : [
              {
                id: null,
                objectId: databaseProjectId ?? null,
                objectType: OBJECT_TYPE_ENUM.project.code,
                contactPerson: null,
              },
            ];
        if (projects?.contactDetailsClicked) {
          setResponsibleContactPersons(responsibleContactPersons);
        }
      }

      if (project?.id) {
        // Retrieve the project activities
        await loadActivities();

        setStateProject(project);

        setResponsibleContactPersons(responsibleContactPersons);
        setResponsible(responsible);
        setInvolvedResponsibles(involvedResponsibles);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, []);

  /**
   * Handles change made to GeneralInformationCard
   * @param {*} project
   * @param {*} responsibleContactPersons
   * @returns
   */
  const handleGeneralInformationChange = (
    project: IProject,
    responsibleContactPersons: IResponsibleContactPerson[]
  ) => {
    const newProject = { ...stateProject, ...project };
    setStateProject(newProject);
    setResponsibleContactPersons(responsibleContactPersons);
  };

  /**
   * Handles change made to ProjectDescriptionCard
   * @param {*} project
   * @returns
   */
  const handleDescriptionChange = (project: IProject) => {
    const newProject = {
      ...stateProject,
      description: project.description ?? '',
    };
    setStateProject(newProject);
  };

  /**
   * Handles change made to Budget and Schedule Card
   * @param {*} project
   * @returns
   */
  const handleEstimationsChange = (project: IProject) => {
    const newProject = { ...stateProject } as IProject;
    newProject.start = project.start ?? '';
    newProject.end = project.end ?? '';
    newProject.projectGainProbability = project.projectGainProbability ?? 0;
    newProject.averageProjectSize = project.averageProjectSize ?? 0;
    newProject.totalHoursEstimated = project.totalHoursEstimated ?? 0;
    if (project.departments) newProject.departments = project.departments;
    newProject.duration = project.duration ?? 0;
    newProject.state = project.state;
    setStateProject(newProject);
  };

  const handleBudgetChange = (offer: IOffer) => {
    setLatestOffer((prevOffer) => ({
      ...prevOffer,
      budget: offer.budget ?? 0,
      currency: offer.currency ?? '',
    }));
  };

  const handleApproverChange = (employee: IDropdownOption<number>) => {
    setSelectedApprover(employee);
  };

  /**
   * Handles change made to ProjectResponsiblesCard on the responsible section
   * @param {*} responsible
   * @returns
   */
  const handleResponsibleChange = (responsible: IResponsible) => {
    setResponsible(responsible);
  };

  /**
   * Handles change made to ProjectResponsiblesCard on the involved section
   * @param {*} involvedResponsibles
   * @returns
   */
  const handleInvolvedResponsiblesChange = (
    involvedResponsibles: IResponsible[]
  ) => {
    setInvolvedResponsibles(involvedResponsibles);
  };

  /**
   * Handles the deleted involved employees
   * @param {*} deletedResponsible
   */
  const handleDeletedInvolved = (deletedResponsible: IResponsible) => {
    setInvolvedResponsibles(
      involvedResponsibles.filter(
        (item) => !item.id || item.id !== deletedResponsible.id
      )
    );
  };

  /**
   * Method that checks if all required fields are filed
   * @param project
   * @returns true or false
   */
  const isInputValid = (project: IProject, offer: IOffer) =>
    project?.title &&
    project?.customerId &&
    project?.customerSiteId &&
    !isEmpty(responsibleContactPersons) &&
    responsibleContactPersons.every((item) => item?.contactPerson?.id) &&
    project.start &&
    project.duration &&
    project.end &&
    project.state &&
    offer.budget &&
    offer.currency &&
    responsible;

  /**
   * Handles cancellation of project when rejected by approver
   */
  const handleRefuseProject = async () => {
    if (approvalRequest) {
      const updatedApprovalRequest = { ...approvalRequest };
      updatedApprovalRequest.rejectedDate = moment(new Date());
      await axios.project
        .save('approval-requests', updatedApprovalRequest)
        .then((response: AxiosResponse<IApprovalRequest>) => response.data)
        .catch((error) => {
          modalErrorHandler(
            translateCreateUnbilledProject('failedToSaveApprovalRequest'),
            error
          );
        });
    }

    if (onSave && stateProject.id) {
      onSave(stateProject.id);
    }
    onCancel();
  };

  /**
   * Handles approval of project
   */
  const handleApproveProject = async () => {
    const checkIfChanged =
      latestOffer.budget !== offerOriginal.budget ||
      latestOffer.currency !== offerOriginal.currency;

    // If no changes with offer no need to save
    if (checkIfChanged) {
      await axios.sales
        .save('offers', latestOffer)
        .then((res: AxiosResponse<IOffer>) => res.data)
        .catch((error: AxiosError) => {
          modalErrorHandler(
            translateCreateUnbilledProject('failedToSaveOffer'),
            error
          );
        });
    }

    if (approvalRequest) {
      const updatedApprovalRequest = { ...approvalRequest };
      updatedApprovalRequest.approvedDate = moment(new Date());
      await axios.project
        .save('approval-requests', updatedApprovalRequest)
        .then((response: AxiosResponse<IApprovalRequest>) => response.data)
        .catch((error) => {
          modalErrorHandler(
            translateCreateUnbilledProject('failedToSaveApprovalRequest'),
            error
          );
        });
    }

    if (onSave && stateProject.id) {
      onSave(stateProject.id);
    }
    onCancel();
  };

  /**
   * Handles saving of project
   * @returns
   */
  const saveProject = async () => {
    const projectCopy = {
      ...stateProject,
    };

    const checkIfUnchanged =
      stateProject?.title?.toString() === projectOriginal?.title?.toString();

    if (
      !checkIfUnchanged &&
      (!projectCopy.validTitle ||
        projectCopy.validTitle === null ||
        projectCopy.validTitle === undefined)
    ) {
      const error = {
        response: {
          data: {
            title: translateCreateUnbilledProject('invalidTitle'),
          },
        },
        message: translateCreateUnbilledProject('titleAlreadyExists'),
      };
      modalErrorHandler(
        translateCreateUnbilledProject('failedToSaveProject'),
        error
      );
      return;
    }
    const savedProject: IProject | void = await axios.project
      .save('projects', projectCopy)
      .then((res: AxiosResponse<IProject>) => res.data)
      .catch((error: AxiosError) => {
        modalErrorHandler(
          translateCreateUnbilledProject('failedToSaveProject'),
          error
        );
      });

    if (!savedProject) return;
    if (savedProject.id) setDatabaseProjectId(savedProject.id);
    const filteredResponsibleContactPersons = responsibleContactPersons?.filter(
      (item) =>
        item?.contactPerson !== undefined && item?.contactPerson !== null
    );

    const savedResponsibleContactPersons: IResponsibleContactPerson[] | void =
      await saveResponsibleContactPersons(
        filteredResponsibleContactPersons,
        savedProject?.id
      ).catch((error) => {
        const mainError = translateCreateUnbilledProject(
          'failedToSaveContactPersons'
        );
        modalErrorHandler(mainError, error);
      });
    if (!savedResponsibleContactPersons) return;

    const savedInvolvedResponsibles = await saveResponsibles(
      involvedResponsibles,
      savedProject.id
    ).catch((error) => {
      const mainError = translateCreateUnbilledProject(
        'failedToRetrieveResponsibles'
      );
      modalErrorHandler(mainError, error);
    });
    if (savedInvolvedResponsibles === undefined) return;

    const savedResponsible = await saveResponsibles(
      [responsible],
      savedProject?.id
    ).catch((error) => {
      const mainError = translateCreateUnbilledProject(
        'failedToRetrieveResponsibles'
      );
      modalErrorHandler(mainError, error);
    });
    if (!savedResponsible) return;
    const filteredNewProjectActivities = newProjectActivities.filter(
      (item) => item.action && item.dueDate
    );
    const projectActivityAxios = [];
    for (const projectActivity of filteredNewProjectActivities) {
      projectActivity.objectId = savedProject.id as number;
      projectActivity.objectType = OBJECT_TYPE_ENUM.project.code;
      projectActivityAxios.push(
        axios.project.post('user-to-do-activities', projectActivity)
      );
    }
    if (!isEmpty(projectActivityAxios)) {
      await Promise.all(projectActivityAxios).catch((error) => {
        const mainError = 'Failed to save Project Activity.';
        modalErrorHandler(mainError, error);
      });
    }

    if (!savedProject?.id) return;

    const allResponsibles: IResponsible[] | void = await axios.sales
      .get(
        `responsibles?objectType.equals=${OBJECT_TYPE_ENUM.project.code}&objectId.equals=${savedProject?.id}`
      )
      .then((res: AxiosResponse<IResponsible[]>) => res.data)
      .catch((error) => {
        const mainError = translateCreateUnbilledProject(
          'failedToRetrieveResponsibles'
        );
        modalErrorHandler(mainError, error);
      });

    const fetchedCustomer: ICustomer | void = await axios.sales
      .get(`customers?id.equals=${savedProject.customerId}`)
      .then((res: AxiosResponse<ICustomer[]>) => res.data[0])
      .catch((error) => {
        const mainError = translateCreateUnbilledProject(
          'failedToRetrieveCustomer'
        );
        modalErrorHandler(mainError, error);
      });

    setStateProject(savedProject);

    // Project
    setProjectOriginal({ ...savedProject });
    setCustomer(fetchedCustomer ?? ({} as ICustomer));
    setResponsibleContactPersons([...savedResponsibleContactPersons]);
    setResponsible(
      allResponsibles?.find(
        (item: IResponsible) => item.ownership === Ownership.RESPONSIBLE
      ) ?? ({} as IResponsible)
    );
    setInvolvedResponsibles(
      allResponsibles?.filter(
        (item: IResponsible) => item.ownership === Ownership.INVOLVED
      ) ?? []
    );
    setNewProjectActivities([]);
  };

  /**
   * Updates offer
   */
  const updateOffer = () => {
    const updatedLatestOffer = { ...latestOffer } as IOffer;
    updatedLatestOffer.title = stateProject.title + '_offer';
    updatedLatestOffer.date = moment(new Date());
    updatedLatestOffer.customerId = stateProject.customerId;
    updatedLatestOffer.offerVersion = 1;
    updatedLatestOffer.offerState = OfferState.VALID;
    if (stateProject.id) updatedLatestOffer.projectId = stateProject.id;
    setLatestOffer(updatedLatestOffer);
  };

  /**
   * Method for saving offers into backend
   */
  const saveOffer = async () => {
    const savedOffer = await axios.sales
      .save('offers', latestOffer)
      .then((res: AxiosResponse<IOffer>) => res.data)
      .catch((error: AxiosError) => {
        modalErrorHandler(
          translateCreateUnbilledProject('failedToSaveOffer'),
          error
        );
      });
    if (!savedOffer) return;
    setLatestOffer(savedOffer);
  };

  /**
   * Method for sending approval request
   */
  const sendApprovalRequest = () => {
    const updatedApprovalRequest = { ...approvalRequest } as IApprovalRequest;
    // Only ID is needed whens ending a post or put request
    updatedApprovalRequest.employee =
      dropdownOptionToObjectNameAndId(selectedApprover);
    updatedApprovalRequest.objectId = stateProject.id as number;
    updatedApprovalRequest.modifiable = true;
    updatedApprovalRequest.objectType = OBJECT_TYPE_ENUM.project.code;

    axios.project
      .save('approval-requests', updatedApprovalRequest)
      .then((response: AxiosResponse<IApprovalRequest>) => response.data)
      .catch((error) => {
        modalErrorHandler(
          translateCreateUnbilledProject('failedToSaveApprovalRequest'),
          error
        );
      });
  };

  /**
   * Saving of project, offer and approval request
   */
  const saveAllRelevantDetails = async () => {
    // Save project
    await saveProject();
    // Save Offer
    updateOffer();
    await saveOffer();
    // Make approval request and save
    sendApprovalRequest();
    if (onSave && stateProject.id) {
      onSave(stateProject.id);
    }
    onCancel();
  };

  /**
   * Displays modal for customer Abbreviation Input
   * @param {*} offer offer to be displayed
   */
  const displayAddCustomerAbbreviationModal = (customer: IObjectCustomer) => {
    modalFormHandler(
      translateCreateUnbilledProject('abbreviationCheck'),
      <CustomerAbbreviationInput
        customer={customer}
        onFinish={() => {
          void saveAllRelevantDetails();
        }}
      />,
      'xs'
    );
  };

  /**
   * Handles the logic of the OK button
   */
  const handleOkButton = async () => {
    const {
      customer: { id, name, customerAbbreviation },
    } = stateProject;
    const objectCustomer = {
      id,
      name,
      abbreviation: customerAbbreviation,
    } as IObjectCustomer;

    if (!isStringNotEmpty(customerAbbreviation)) {
      displayAddCustomerAbbreviationModal(objectCustomer);
    } else {
      await saveAllRelevantDetails();
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Row>
            <Col>
              <ProjectOptionGeneralInfoCard
                project={stateProject}
                projectOriginal={projectOriginal}
                responsibleContactPersons={responsibleContactPersons}
                projectActivities={newProjectActivities}
                onChange={handleGeneralInformationChange}
                lockInputs={forApproval}
                forcedUpdate={forcedUpdate}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProjectOptionDescriptionCard
                project={stateProject}
                onChange={handleDescriptionChange}
                lockInputs={forApproval}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <ProjectBudgetAndScheduleCard
                project={stateProject}
                offer={latestOffer}
                approvalRequest={approvalRequest}
                lockInputs={forApproval}
                onChange={handleEstimationsChange}
                onBudgetChange={handleBudgetChange}
                onApproverChange={handleApproverChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProjectOptionResponsibleCard
                project={stateProject}
                responsible={responsible}
                involvedResponsibles={involvedResponsibles}
                onResponsiblesChange={handleResponsibleChange}
                onInvolvedResponsiblesChange={handleInvolvedResponsiblesChange}
                onDelete={handleDeletedInvolved}
                lockInputs={forApproval}
                isProjectOption={false}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        {forApproval ? (
          <div className="centered-div">
            <Button
              color="primary"
              className="continue-and-cancel-button"
              onClick={() => handleApproveProject()}
              disabled={!isInputValid(stateProject, latestOffer)}
            >
              {translateCreateUnbilledProject('approve')}
            </Button>

            <Button
              color="primary"
              className="continue-and-cancel-button"
              onClick={() => handleRefuseProject()}
            >
              {translateCreateUnbilledProject('refuse')}
            </Button>
          </div>
        ) : (
          <div className="centered-div">
            <Button
              color="primary"
              className="continue-and-cancel-button"
              onClick={() => handleOkButton()}
              disabled={!isInputValid(stateProject, latestOffer)}
            >
              {translateCreateUnbilledProject('ok')}
            </Button>

            <Button
              color="primary"
              className="continue-and-cancel-button"
              onClick={(event: MouseEvent) => clickHandler(event, onCancel)}
            >
              {translateCreateUnbilledProject('cancel')}
            </Button>
          </div>
        )}
      </Row>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  account: store.account,
  projects: store.projects,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(
  withRouter(withModals(UnbilledCustomerProjectOverview))
);
