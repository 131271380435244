import moment, { Moment, unitOfTime } from 'moment';

import Auth from '../../../services/axios/Auth';
import { AccessType, PERMISSION_URI } from '../../../utils/enums/permission';
import {
  AcquisitionType,
  Activity,
  ProjectState,
  ProjectType,
  StaffedOption,
} from '../../../utils/enums/project';
import {
  addWeekdays,
  getDuration,
  getNearestWorkingDay,
  getNumberWorkDays,
} from '../../../utils/helpers/date';
import {
  dropdownOptionsToObjectNameAndId,
  dropdownOptionToObjectNameAndId,
  enumValuesToDropdownOptions,
  objectNameAndIdToDropdownOption,
  objectNameAndIdToDropdownOptions,
} from '../../../utils/helpers/dropdown';
import i18n from '../../../i18n';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { IEmployee } from '../../../utils/types/modelTypes';
import {
  IOfferListItem,
  IProjectMemberListItem,
  IProjectOptionDetails,
} from '../../../utils/types/responseTypes';
import type { ProjectOptionDetailsForm } from './ProjectOptionModal';
import { DurationType } from '../../../utils/enums/pageComponents';

/**
 * Translator function for Project Option Details
 * @param keyName Key for phrase to be translated
 * @returns Translated string
 */
export const t = (keyName: string) =>
  i18n.t(`ProjectOptionOverview.${keyName}`);

/**
 * Date format based on selected language
 */
export const dateFormat = moment.localeData(i18n.language).longDateFormat('L');

/**
 * Converts duration based on start and end dates, and duration type
 * @param start Start date
 * @param end End date
 * @param durationType Type of duration
 * @returns Converted duration
 */
export const convertDuration = (
  start: Moment,
  end: Moment,
  durationType: string
) =>
  durationType === DurationType.DAYS
    ? getNumberWorkDays(start, end)
    : getDuration(start, end, durationType);

/**
 * Returns end date based on start date, duration, and duration type
 * @param start Start date
 * @param duration Duration
 * @param durationType Type of duration
 * @returns End date
 */
export const getEndDate = (
  start: Moment,
  duration: number,
  durationType: string
) => {
  if (durationType === DurationType.DAYS) {
    return addWeekdays(start, duration - 1).toISOString();
  }
  const durationMs = moment
    .duration(duration, durationType as unitOfTime.DurationConstructor)
    .asMilliseconds();

  return moment(
    getNearestWorkingDay(start.add(durationMs, 'ms'))
  ).toISOString();
};

/**
 * Returns average project size based on total hours, start and end dates, and working hours
 * @param totalHoursEstimated Total hours
 * @param start Start date
 * @param end End date
 * @param workingHours Working hours
 * @returns Average project size
 */
export const getAverageProjectSize = (
  totalHoursEstimated: number,
  start: Moment,
  end: Moment,
  workingHours: string
) => {
  const size =
    totalHoursEstimated /
    getDuration(moment(start), moment(end), DurationType.MONTHS) /
    parseInt(workingHours, 10);

  return Number.isFinite(size) ? parseFloat(size.toFixed(2)) : 0;
};

/**
 * Returns project activities for dropdown options based on project state
 * @param projectState State of project
 * @returns Project activities dropdown options
 */
export const getProjectActivities = (projectState: string) => {
  switch (projectState) {
    case ProjectState.VAGUE:
      return enumValuesToDropdownOptions(
        [Activity.DO_FOLLOW_UP_MEETING, Activity.CALL_CONTACT],
        'ProjectActivities'
      );
    case ProjectState.DEFINED:
      return enumValuesToDropdownOptions(
        [
          Activity.DO_FOLLOW_UP_MEETING,
          Activity.CALL_CONTACT,
          Activity.SEND_EO,
          Activity.ARRANGE_PROPOSAL,
          Activity.DO_PITCH,
          Activity.CALL_FOR_SPEC,
        ],
        'ProjectActivities'
      );
    case ProjectState.PROPOSAL_AHEAD:
      return enumValuesToDropdownOptions(
        [
          Activity.CALL_CONTACT,
          Activity.SEND_EO,
          Activity.ARRANGE_PROPOSAL,
          Activity.PREPARE_PROPOSAL,
          Activity.DO_PROPOSAL,
          Activity.CALL_FOR_FEEDBACK,
        ],
        'ProjectActivities'
      );
    case ProjectState.PITCHING:
      return enumValuesToDropdownOptions(
        [
          Activity.DO_FOLLOW_UP_MEETING,
          Activity.CALL_CONTACT,
          Activity.DO_PITCH,
        ],
        'ProjectActivities'
      );
    case ProjectState.PLANNING:
      return enumValuesToDropdownOptions(
        [
          Activity.DO_FOLLOW_UP_MEETING,
          Activity.CALL_CONTACT,
          Activity.CREATE_OFFER,
          Activity.SEND_PURCHASE_DOCS,
          Activity.SEND_OFFER,
        ],
        'ProjectActivities'
      );
    case ProjectState.ESTIMATING:
      return enumValuesToDropdownOptions(
        [
          Activity.DO_FOLLOW_UP_MEETING,
          Activity.CALL_CONTACT,
          Activity.FINALIZE_ESTIMATION,
          Activity.DEFINE_ESTIMATION_TEAM,
          Activity.MODIFY_ESTIMATION,
        ],
        'ProjectActivities'
      );
    case ProjectState.ALIGNING:
      return enumValuesToDropdownOptions(
        [
          Activity.DO_FOLLOW_UP_MEETING,
          Activity.CALL_CONTACT,
          Activity.MODIFY_ESTIMATION,
          Activity.CREATE_OFFER,
          Activity.SEND_PURCHASE_DOCS,
          Activity.SEND_OFFER,
        ],
        'ProjectActivities'
      );
    case ProjectState.PROPOSAL_TECHNICAL_GO:
    case ProjectState.OFFER_SENT:
      return enumValuesToDropdownOptions(
        [
          Activity.CALL_CONTACT,
          Activity.CREATE_OFFER,
          Activity.SEND_PURCHASE_DOCS,
          Activity.SEND_OFFER,
        ],
        'ProjectActivities'
      );
    case ProjectState.ORDERED:
      return enumValuesToDropdownOptions(
        [
          Activity.CALL_CONTACT,
          Activity.SEND_PURCHASE_DOCS,
          Activity.DEFINE_PROJECT_MANAGER,
          Activity.INITIALIZE_KICK_OFF,
          Activity.INITIALIZE_INTERNAL_KICK_OFF,
        ],
        'ProjectActivities'
      );
    case ProjectState.ACTIVE:
      return enumValuesToDropdownOptions(
        [Activity.INITIALIZE_KICK_OFF, Activity.INITIALIZE_STATUS_MEETING],
        'ProjectActivities'
      );
    default:
      return [];
  }
};

/**
 * Returns default selected project activities for dropdowns based on project state and aquisition type
 * @param projectState State of project
 * @param acquisitionType Acquisition type of project
 * @returns Default selected project activities for dropdowns
 */
export const getSelectedProjectActivity = (
  projectState: string,
  acquisitionType: string
) => {
  switch (projectState) {
    case ProjectState.VAGUE:
      return enumValuesToDropdownOptions(
        [Activity.DO_FOLLOW_UP_MEETING],
        'ProjectActivities'
      ).map((activity) => ({ action: activity, dueDate: '' }));
    case ProjectState.DEFINED:
      switch (acquisitionType) {
        case AcquisitionType.PITCH:
          return enumValuesToDropdownOptions(
            [Activity.DO_PITCH],
            'ProjectActivities'
          ).map((activity) => ({ action: activity, dueDate: '' }));
        case AcquisitionType.PROPOSAL:
          return enumValuesToDropdownOptions(
            [Activity.SEND_EO, Activity.ARRANGE_PROPOSAL],
            'ProjectActivities'
          ).map((activity) => ({ action: activity, dueDate: '' }));
        case AcquisitionType.SPECIFICATION:
          return enumValuesToDropdownOptions(
            [Activity.CALL_FOR_SPEC],
            'ProjectActivities'
          ).map((activity) => ({ action: activity, dueDate: '' }));
        case AcquisitionType.UNDEFINED:
        default:
          return [];
      }
    case ProjectState.PROPOSAL_AHEAD:
      return enumValuesToDropdownOptions(
        [Activity.PREPARE_PROPOSAL, Activity.DO_PROPOSAL],
        'ProjectActivities'
      ).map((activity) => ({ action: activity, dueDate: '' }));
    case ProjectState.PROPOSAL_TECHNICAL_GO:
    case ProjectState.PLANNING:
    case ProjectState.OFFER_SENT:
      return enumValuesToDropdownOptions(
        [Activity.CREATE_OFFER],
        'ProjectActivities'
      ).map((activity) => ({
        action: activity,
        dueDate: moment(new Date()).startOf('day').add(1, 'week').toISOString(),
      }));
    case ProjectState.PITCHING:
      return enumValuesToDropdownOptions(
        [Activity.DO_PITCH],
        'ProjectActivities'
      ).map((activity) => ({ action: activity, dueDate: '' }));
    case ProjectState.ESTIMATING:
      return enumValuesToDropdownOptions(
        [Activity.DEFINE_ESTIMATION_TEAM, Activity.FINALIZE_ESTIMATION],
        'ProjectActivities'
      ).map((activity, idx) => ({
        action: activity,
        dueDate:
          idx === 0
            ? moment(new Date()).startOf('day').add(2, 'day').toISOString()
            : moment(new Date()).startOf('day').add(1, 'week').toISOString(),
      }));
    case ProjectState.ALIGNING:
      return enumValuesToDropdownOptions(
        [Activity.CALL_CONTACT, Activity.CREATE_OFFER],
        'ProjectActivities'
      ).map((activity, idx) => ({
        action: activity,
        dueDate:
          idx === 0
            ? moment(new Date()).startOf('day').add(3, 'day').toISOString()
            : moment(new Date()).startOf('day').add(1, 'week').toISOString(),
      }));
    case ProjectState.ORDERED:
      return enumValuesToDropdownOptions(
        [Activity.DEFINE_PROJECT_MANAGER, Activity.INITIALIZE_KICK_OFF],
        'ProjectActivities'
      ).map((activity) => ({ action: activity, dueDate: '' }));
    case ProjectState.ACTIVE:
      return enumValuesToDropdownOptions(
        [Activity.INITIALIZE_STATUS_MEETING],
        'ProjectActivities'
      ).map((activity) => ({ action: activity, dueDate: '' }));
    default:
      return [];
  }
};

export const hasPermissionToAddResource = (isOwner: boolean) =>
  (Auth.hasPermission(
    [PERMISSION_URI.assignResourcesToProjectOptions.readWrite.uri],
    [AccessType.READWRITE]
  ) &&
    isOwner) ||
  Auth.hasPermission(
    [PERMISSION_URI.assignResources.readWrite.uri],
    [AccessType.READWRITE]
  );

/**
 * Evaluates whether form values are valid
 * @param formValues Project Option form values
 * @param isCheckingTitle State if currently checking title
 * @param isTitleValid State if title is valid
 * @param projectId Id of project
 * @returns Boolean whether form values are valid
 */
export const isFormValid = (
  {
    title,
    customer,
    customerSite,
    contactPersons,
    originOfOption,
    responsible,
    state,
    start,
    projectGainProbability,
    duration,
    averageProjectSize,
    totalHoursEstimated,
  }: ProjectOptionDetailsForm,
  isCheckingTitle: boolean,
  isTitleValid: boolean,
  projectId?: number
) => {
  const areBasicFieldsValid =
    !!title &&
    isTitleValid &&
    !isCheckingTitle &&
    !!customer.value &&
    !!customerSite.value &&
    !!contactPersons[0] &&
    !!contactPersons[0].contactPerson &&
    !!contactPersons[0].contactPerson.value &&
    !!originOfOption.value &&
    !!responsible.value;

  if (!projectId || state === ProjectState.VAGUE) return areBasicFieldsValid;

  const areEstimationFieldsValid =
    !!start && !!projectGainProbability && !!duration && !!averageProjectSize;

  if (state === ProjectState.ALIGNING)
    return (
      areBasicFieldsValid && areEstimationFieldsValid && !!totalHoursEstimated
    );

  return areBasicFieldsValid && areEstimationFieldsValid;
};

/**
 * Evaluates if project option is ready to proceed to next stage based on form values
 * @param formValues Project Option form values
 * @returns Boolean if ready for next stage
 */
export const isNextStage = ({
  state,
  start,
  projectGainProbability,
  duration,
  averageProjectSize,
  acquisitionType: { value: acquisitionType },
  totalHoursEstimated,
  staffedOption: { value: staffedOption },
}: ProjectOptionDetailsForm) => {
  switch (state) {
    case ProjectState.VAGUE:
      return (
        !!start &&
        !!projectGainProbability &&
        !!duration &&
        !!averageProjectSize
      );
    case ProjectState.DEFINED:
      return (
        acquisitionType === AcquisitionType.PROPOSAL ||
        acquisitionType === AcquisitionType.SPECIFICATION
      );
    case ProjectState.ESTIMATING:
      return !!totalHoursEstimated;
    case ProjectState.PROPOSAL_AHEAD:
      return staffedOption !== StaffedOption.NO;
    default:
      return false;
  }
};

/**
 * Returns project state based on form values
 * @param formValues Project Option form values
 * @returns State of project
 */
export const getProjectState = (formValues: ProjectOptionDetailsForm) => {
  const {
    state,
    acquisitionType: { value: acquisitionType },
  } = formValues;

  if (isNextStage(formValues)) {
    if (state === ProjectState.VAGUE) {
      return ProjectState.DEFINED;
    }
    if (state === ProjectState.DEFINED) {
      if (acquisitionType === AcquisitionType.PROPOSAL) {
        return ProjectState.PROPOSAL_AHEAD;
      }
      if (acquisitionType === AcquisitionType.SPECIFICATION) {
        return ProjectState.ESTIMATING;
      }
    }
    if (state === ProjectState.ESTIMATING) {
      return ProjectState.ALIGNING;
    }
    if (state === ProjectState.PROPOSAL_AHEAD) {
      return ProjectState.PROPOSAL_TECHNICAL_GO;
    }
  }
  return state;
};

/**
 * Generates default form values for Project Option
 * @param currentUser Details of current user
 * @returns Project Option form with default form values
 */
export const generateDefaultFormValues = ({
  id,
  firstname,
  name,
}: IEmployee) => ({
  // General Information
  title: '',
  customer: {} as IDropdownOption<number>,
  customerSite: {} as IDropdownOption<number>,
  contactPersons: [] as {
    contactPerson: IDropdownOption<number>;
    role: string;
    functionRole: string;
  }[],
  // Estimations
  start: '',
  projectGainProbability: 0,
  duration: 0,
  durationType: {
    label: 'Months',
    value: DurationType.MONTHS as string,
  },
  end: '',
  averageProjectSize: 0,
  totalHoursEstimated: 0,
  departments: [] as IDropdownOption<number>[],
  originOfOption: {} as IDropdownOption,
  // Activities
  staffedOption: {
    value: StaffedOption.NO,
    label: i18n.t(`StaffedEnum.${StaffedOption.NO}`),
  },
  acquisitionType: {
    value: AcquisitionType.UNDEFINED,
    label: i18n.t(`Acquisitions.${AcquisitionType.UNDEFINED}`),
  },
  state: ProjectState.VAGUE,
  activities: [
    {
      action: {
        value: Activity.DO_FOLLOW_UP_MEETING as string,
        label: i18n.t(`ProjectActivities.${Activity.DO_FOLLOW_UP_MEETING}`),
      },
      dueDate: '',
    },
  ],
  description: '',
  responsible: {
    label: `${firstname} ${name}`,
    value: id as number,
  },
  involvedResponsibles: [] as {
    responsible: IDropdownOption<number>;
    responsibleRole: IDropdownOption<number>;
  }[],
  projectMembers: [] as IProjectMemberListItem[],
  offers: [] as IOfferListItem[],
});

/**
 * Generate form values based on Project Option
 * @param projectOption Details of Project Option
 * @returns Project Option form values
 */
export const createFormValuesFromProjectOption = ({
  title,
  customer,
  customerSite,
  responsibleContactPersons,
  start,
  projectGainProbability,
  duration,
  end,
  averageProjectSize,
  totalHoursEstimated,
  departments,
  originOfOption,
  staffedOption,
  acquisitionType,
  state,
  description,
  responsible,
  involvedResponsibles,
  projectMembers,
  offers,
}: IProjectOptionDetails) => ({
  title,
  customer: objectNameAndIdToDropdownOption(customer),
  customerSite: objectNameAndIdToDropdownOption(customerSite),
  contactPersons: responsibleContactPersons
    ? responsibleContactPersons.map(({ id, name, role, functionRole }) => ({
        contactPerson: {
          label: name,
          value: id,
        },
        role,
        functionRole,
      }))
    : [],
  start,
  projectGainProbability,
  duration,
  durationType: {
    label: 'Months',
    value: DurationType.MONTHS as string,
  },
  end,
  averageProjectSize,
  totalHoursEstimated,
  departments: objectNameAndIdToDropdownOptions(departments),
  originOfOption: {
    value: originOfOption,
    label: i18n.t(`Options.${originOfOption.toUpperCase()}`),
  },
  staffedOption: {
    value: staffedOption,
    label: i18n.t(`StaffedEnum.${staffedOption}`),
  },
  acquisitionType: {
    value: acquisitionType,
    label: i18n.t(`Acquisitions.${acquisitionType}`),
  },
  state,
  activities: getSelectedProjectActivity(state, acquisitionType),
  description,
  responsible: objectNameAndIdToDropdownOption(responsible),
  involvedResponsibles: involvedResponsibles.map(
    ({ id, name, responsibleRole }) => ({
      responsible: {
        label: name,
        value: id,
      },
      responsibleRole: objectNameAndIdToDropdownOption(responsibleRole),
    })
  ),
  projectMembers,
  offers,
});

/**
 * Generate Project Option based on form values
 * @param projectOption Details of Project Option
 * @param formValues Project Option form values
 * @returns Details of Project Option
 */
export const createProjectOptionFromFormValues = (
  {
    id,
    projectId,
    projectType,
    createdOn,
    nextAction,
    dueDate,
    activities,
    projectSharepoint,
    estimationTemplatesLink,
    // TODO: Move getting offers to formValues once implemented
    offers,
  }: IProjectOptionDetails,
  formValues: ProjectOptionDetailsForm
) => {
  const {
    title,
    customer,
    customerSite,
    responsible,
    projectGainProbability,
    contactPersons,
    staffedOption: { value: staffedOption },
    acquisitionType: { value: acquisitionType },
    activities: additionalActivities,
    description,
    start,
    end,
    averageProjectSize,
    totalHoursEstimated,
    departments,
    originOfOption: { value: originOfOption },
    involvedResponsibles,
    projectMembers,
  } = formValues;

  return {
    id: id ?? 0,
    title,
    projectId: projectId ?? '',
    state: getProjectState(formValues),
    projectType: projectType ?? ProjectType.CUSTOMER_PROJECT,
    customer: dropdownOptionToObjectNameAndId(customer),
    customerSite: dropdownOptionToObjectNameAndId(customerSite),
    responsible: dropdownOptionToObjectNameAndId(responsible),
    projectGainProbability,
    responsibleContactPersons: contactPersons.map(
      ({ contactPerson, role, functionRole }) => ({
        ...dropdownOptionToObjectNameAndId(contactPerson),
        role,
        functionRole,
        customerSite: dropdownOptionToObjectNameAndId(customerSite),
        phone: [],
        email: [],
      })
    ),
    staffedOption,
    createdOn: createdOn ?? '',
    nextAction,
    dueDate,
    acquisitionType,
    activities: [
      ...(activities ?? []),
      ...additionalActivities
        .map(({ action: { value: action }, dueDate }) => ({
          id: 0,
          action,
          dueDate,
        }))
        .filter(({ dueDate }) => dueDate !== ''),
    ],
    description,
    projectSharepoint: projectSharepoint ?? '',
    estimationTemplatesLink: estimationTemplatesLink ?? '',
    offers: offers ?? [],
    start,
    end,
    duration:
      convertDuration(moment(start), moment(end), DurationType.MONTHS) || 0,
    averageProjectSize,
    totalHoursEstimated,
    departments: dropdownOptionsToObjectNameAndId(departments),
    originOfOption,
    involvedResponsibles: involvedResponsibles.map(
      ({ responsible, responsibleRole }) => ({
        ...dropdownOptionToObjectNameAndId(responsible),
        responsibleRole: dropdownOptionToObjectNameAndId(responsibleRole),
      })
    ),
    projectMembers,
  };
};
