import React, { ChangeEvent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { isEmpty } from '../../../utils/helpers/array';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import useMounted from '../../../hooks/useMounted';
import {
  fetchGdprListItems,
  fetchEmployees,
  setPage,
  setHasMoreListItems,
  setFilters,
  setSortBy,
  setSortType,
} from '../../../redux/gdprListSlice';
import { RootState } from '../../../redux/store';
import { createGdprEntry, t } from './gdprOverviewHelpers';
import { IGdprListItem } from '../../../utils/types/responseTypes';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { SortBy, SortType } from '../../../utils/enums/sort';
import { getNextSortType } from '../../../utils/helpers/table';
import DynamicTable from '../../../components/tables/DynamicTable';
import {
  contactStatusDropdownOptions,
  gdprStatusDropdownOptions,
} from '../../../utils/helpers/dropdown';
import IssueSolvingModal from './IssueSolvingModal';
import {
  DEFAULT_LOAD_TIMEOUT,
  ISSUE_TO_SOLVE,
  ISSUE_TO_UNSOLVE,
} from '../../../utils/constants';

interface IProps extends IWithModalsProps, PropsFromRedux {}

const defaultFilterWidth = '100px';
const defaultWidth = '120px';

const GdprOverviewTable = ({
  // WithModals
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
  // Redux State,
  listItems,
  employees,
  filters,
  showFilters,
  hasMore,
  sortBy,
  sortType,
  // Redux Actions,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  setHasMoreListItems,
  // Thunks
  fetchGdprListItems,
  fetchEmployees,
}: IProps) => {
  // Check for component mount
  const isMounted = useMounted();

  /**
   * Shows modal for solving issue manually
   * @param gdprListItem GDPR contact to use for issue solving
   */
  const handleSolveIssueManually = (gdprListItem: IGdprListItem) => {
    modalFormHandler(
      t('solveIssueManually'),
      <IssueSolvingModal
        gdprListItem={gdprListItem}
        solveOrUnsolve={ISSUE_TO_SOLVE}
        toggleModalFormProp={() => toggleModalForm()}
      />
    );
  };

  /**
   * Shows modal for unsolving issue manually
   * @param gdprListItem GDPR contact to use for issue unsolving
   */
  const handleUnsolveIssueManually = (gdprListItem: IGdprListItem) => {
    modalFormHandler(
      t('unsolveIssueManually'),
      <IssueSolvingModal
        gdprListItem={gdprListItem}
        solveOrUnsolve={ISSUE_TO_UNSOLVE}
        toggleModalFormProp={() => toggleModalForm()}
      />
    );
  };

  // Prepare the data to be displayed on the table
  const preparedGdprListItems = listItems.map((gdprListItem: IGdprListItem) =>
    createGdprEntry(
      gdprListItem,
      () => handleSolveIssueManually(gdprListItem),
      () => handleUnsolveIssueManually(gdprListItem)
    )
  );

  /**
   * Handles the fetching of gdpr list items
   * @param isSortOrFilterFetchType Whether the data fetching is for sorting / filtering / scrolling
   */
  const fetchGdprList = (isSortOrFilterFetchType: boolean) => {
    fetchGdprListItems(isSortOrFilterFetchType, (error) => {
      modalErrorHandler(t('loadRecordFailed'), error);
    });
  };

  /**
   * Fetch gdpr contact person list
   */
  useEffect(() => {
    if (isEmpty(listItems)) {
      fetchGdprList(false);
    }

    if (isEmpty(employees)) {
      fetchEmployees((error) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });
    }
  }, []);

  // Fetch GDPR contact person list on sort update
  useEffect(() => {
    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);
      fetchGdprList(true);
    }
  }, [sortBy, sortType]);

  // Fetch GDPR contact person list on filter update
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);

      timer = setTimeout(() => {
        fetchGdprList(true);
      }, DEFAULT_LOAD_TIMEOUT);
    }

    return () => clearTimeout(timer);
  }, [filters]);

  /**
   * Handles changes to input filters
   * @param event event of HTML Input field
   * @param fieldName fieldName being filtered
   */
  const handleInputFilterChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: event.target.value ?? '',
    });
  };

  /**
   * Handles changes to dropdown filters
   * @param filterValue value of dropdown filter chosen
   * @param fieldName fieldName being filtered
   */
  const handleDropdownFilterChange = (
    filterValue: IDropdownOption[],
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: filterValue,
    });
  };

  /**
   * Handles table sort function
   * @param nextSortBy
   */
  const handleSort = (nextSortBy: SortBy) => {
    const nextSortType = getNextSortType(sortType, nextSortBy, sortBy);

    setSortBy(nextSortType === SortType.DEFAULT ? SortBy.NAME : nextSortBy);
    setSortType(
      nextSortType === SortType.DEFAULT ? SortType.ASCENDING : nextSortType
    );
  };

  const tableColumns = [
    {
      type: 'data',
      header: t('lastName'),
      accessor: 'lastname',
      show: 'true',
      sortFunc: handleSort,
      filterFunc: handleInputFilterChange,
      filterComponentWidth: defaultFilterWidth,
      width: defaultWidth,
    },
    {
      type: 'data',
      header: t('firstName'),
      accessor: 'firstname',
      show: 'true',
      sortFunc: handleSort,
      filterFunc: handleInputFilterChange,
      filterComponentWidth: defaultFilterWidth,
      width: defaultWidth,
    },
    {
      type: 'data',
      header: t('attributeType'),
      accessor: 'assignmentState',
      show: 'true',
      sortFunc: handleSort,
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: contactStatusDropdownOptions,
      filterComponentWidth: '150px',
      width: '150px',
      filterValue: filters.assignmentState,
    },
    {
      type: 'data',
      header: t('gdprStatus'),
      accessor: 'statusGdpr',
      show: 'true',
      sortFunc: handleSort,
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: gdprStatusDropdownOptions,
      filterComponentWidth: '150px',
      width: '150px',
      filterValue: filters.statusGdpr,
    },
    {
      type: 'data',
      header: t('responsibles'),
      accessor: 'responsible',
      show: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: employees,
      filterComponentWidth: '180px',
      width: '200px',
      filterValue: filters.responsible,
    },
    {
      type: 'data',
      header: t('timeOfLastActivity'),
      accessor: 'latestStatusDate',
      show: 'true',
      width: '150px',
    },
    {
      type: 'data',
      header: t('issueSolvedManually'),
      accessor: 'issueSolvedManually',
      show: 'true',
      width: '150px',
    },
  ];

  return (
    <DynamicTable
      data={preparedGdprListItems}
      columns={tableColumns}
      fetchData={fetchGdprList}
      hasMoreData={hasMore}
      infiniteScroll
      sortType={sortType}
      sortBy={sortBy}
      enableFilterFunction
      showFilters={showFilters}
    />
  );
};

const mapStateToProps = (store: RootState) => ({
  listItems: store.gdprList.listItems,
  employees: store.gdprList.additionalState?.employees,
  filters: store.gdprList.filters,
  showFilters: store.gdprList.showFilters,
  hasMore: store.gdprList.hasMore,
  sortBy: store.gdprList.sortBy,
  sortType: store.gdprList.sortType,
});

const mapDispatchToProps = {
  fetchGdprListItems,
  fetchEmployees,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  setHasMoreListItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(GdprOverviewTable));
