import i18n from '../../i18n';

export const SERVICE_CONFIG = {
  coldCalling: {
    service: 'Customer',
    key: 'com.invensity.com.itwcrm.customer.coldcalling',
    value: 'true',
  },
  workingHoursPerMonth: {
    service: 'Core',
    key: 'defaultWorkingHoursPerMonth',
  },
  customerPriority: {
    service: 'Customer',
    key: 'com.invensity.itwcrm.action.customer.targetPriority',
    value: {
      both: 'both',
      bddHead: 'bddHead',
      responsible: 'responsible',
    },
  },
  prioritizationTargetGoal: {
    service: 'Sales',
    key: 'priorityTargetGoal',
  },
};

/**
 * Service name enum to refer what service the notification is being categorized and to identify the menu tree route
 */
export const SERVICE_NAMES_ENUM = {
  timeTracking: {
    name: i18n.t('index.timeTracking'),
    code: 'Time Tracking',
  },
  crm: {
    name: i18n.t('index.crm'),
    code: 'CRM',
  },
  salesFunnel: {
    name: i18n.t('index.sales'),
    code: 'Sales Funnel',
  },
  projects: {
    name: i18n.t('index.projects'),
    code: 'Projects',
  },
  resourcePlanner: {
    name: i18n.t('index.resourcePlanner'),
    code: 'Resource Planner',
  },
  finance: {
    name: i18n.t('index.finance'),
    code: 'Finance',
  },
  employees: {
    name: i18n.t('index.employees'),
    code: 'Employees',
  },
  recruiting: {
    name: i18n.t('index.recruiting'),
    code: 'Recruiting',
  },
  absencePlanner: {
    name: i18n.t('index.absencePlanner'),
    code: 'Absence Planner',
  },
  all: {
    name: i18n.t('index.all'),
    code: 'All',
  },
};

/**
 * Send notification via
 */
export const SEND_NOTIFICATIONS_VIA_ENUM = {
  email: {
    name: 'Email',
    code: 'Via Email',
  },
  crmTool: {
    name: 'CRM Tool',
    code: 'Via CRM Tool',
  },
};
