import moment from 'moment';
import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import {
  faHandshake,
  faProjectDiagram,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';

import i18n from '../../i18n';
import { dateFormat } from '../../utils/constants';
import { OBJECT_TYPE_ENUM } from '../../utils/enums/objectType';
import {
  IAbsence,
  IAbsenceEntry,
  ICalendarDay,
  IExtendedUserToDoActivity,
  IUserToDoActivity,
} from '../../utils/types/modelTypes';
import { colorBadge } from '../../utils/helpers/GenericHelper';

/**
 * Handles translation for words used in Absence Calendar
 * @param {*} keyName
 * @returns translated word
 */
export const act = (keyName: string) => i18n.t(`AbsenceCalendar.${keyName}`);

/**
 * Handles translation for words used in Calendar strings
 * @param {*} keyName
 * @returns translated word
 */
export const cst = (keyName: string) => i18n.t(`CalendarStrings.${keyName}`);

export const translateToDoListOverview = (keyName: string) =>
  i18n.t(`ToDoListOverview.${keyName}`);

/**
 * Returns the section for the event agenda
 * @param param0
 * @returns
 */
export const eventAgenda = ({ event }: any) => {
  if (event.resource?.absenceState) {
    return (
      <span>
        <em>{event.title}</em>
        {act(`${event.resource?.absenceState}`)}
        <p>{event.desc}</p>
      </span>
    );
  }
  return (
    <span>
      <em>{event.title}</em>
    </span>
  );
};

const absenceTypeIcons = {
  Alarm: {
    value: 0,
    name: 'Alarm',
    component: <Icon.Alarm />,
  },
  Archive: {
    value: 1,
    name: 'Archive',
    component: <Icon.Archive />,
  },
  Award: {
    value: 2,
    name: 'Award',
    component: <Icon.Award />,
  },
  Bag: {
    value: 3,
    name: 'Bag',
    component: <Icon.Bag />,
  },
  Bell: {
    value: 4,
    name: 'Bell',
    component: <Icon.Bell />,
  },
  Briefcase: {
    value: 5,
    name: 'Briefcase',
    component: <Icon.Briefcase />,
  },
  Building: {
    value: 6,
    name: 'Building',
    component: <Icon.Building />,
  },
  Calendar: {
    value: 7,
    name: 'Calendar',
    component: <Icon.Calendar />,
  },
  Camera: {
    value: 8,
    name: 'Camera',
    component: <Icon.Camera />,
  },
  CardCheckList: {
    value: 9,
    name: 'Card CheckList',
    component: <Icon.CardChecklist />,
  },
  CardImage: {
    value: 10,
    name: 'Card Image',
    component: <Icon.CardImage />,
  },
  ChatDots: {
    value: 11,
    name: 'Chat Dots',
    component: <Icon.ChatDots />,
  },
  ClipboardData: {
    value: 12,
    name: 'Clipboard Data',
    component: <Icon.ClipboardData />,
  },
  ClockFill: {
    value: 13,
    name: 'Clock Fill',
    component: <Icon.ClockFill />,
  },
  Envelope: {
    value: 14,
    name: 'Envelope',
    component: <Icon.Envelope />,
  },
  ExclamationCircle: {
    value: 15,
    name: 'Exclamation Circle',
    component: <Icon.ExclamationCircle />,
  },
  Gift: {
    value: 16,
    name: 'Gift',
    component: <Icon.Gift />,
  },
  Heart: {
    value: 17,
    name: 'Heart',
    component: <Icon.Heart />,
  },
  House: {
    value: 18,
    name: 'House',
    component: <Icon.House />,
  },
  Tag: {
    value: 19,
    name: 'Tag',
    component: <Icon.Tag />,
  },

  Tools: {
    value: 20,
    name: 'Tools',
    component: <Icon.Tools />,
  },
};

/**
 * Returns the Icon based on the string value
 * @param iconStringValue
 * @returns
 */
function returnIcon(iconStringValue: string) {
  const stringValue = iconStringValue.replace(/ +/g, '');
  return absenceTypeIcons[stringValue as keyof typeof absenceTypeIcons]
    .component;
}

export const calendarEvent = ({ event }: any) => {
  const absenceType = event.resource?.absenceType;
  const appointmentStatus = event.resource?.appointmentState;
  let objectIcon = '';
  if (absenceType && absenceType.configuration) {
    const absenceTypeConfiguration = absenceType.configuration;
    const configurationObject = JSON.parse(absenceTypeConfiguration);
    objectIcon = configurationObject.Icon;
    return (
      <span className={colorBadge(event.resource?.absenceState)}>
        {returnIcon(objectIcon)} {event.title} -{' '}
        {act(`${event.resource?.absenceState}`)}
      </span>
    );
  }
  if (appointmentStatus) {
    return (
      <span className={colorBadge(event.resource?.absenceState)}>
        <tr>{event.customerName}</tr>
        <tr>{event.title}</tr>
      </span>
    );
  }
  // Holidays
  return (
    <span>
      <strong>{event.title}</strong>{' '}
    </span>
  );
};

// Used in Calendar View to get background color for an appointment based on its status
const appointmentStatusAndColor = {
  confirmed: {
    code: 'CONFIRMED',
    color: '#abc6e4',
  },
  notConfirmed: {
    code: 'NOTCONFIRMED',
    color: '#9aceb1',
  },
  accomplished: {
    code: 'ACCOMPLISHED',
    color: '#c5ac98',
  },
  processed: {
    code: 'PROCESSED',
    color: 'white',
  },
};

/**
 * Get the color of the appointment status
 * @param appointmentState
 * @returns
 */
const getColorForAppointmentStatus = (appointmentState: string) => {
  switch (appointmentState) {
    case appointmentStatusAndColor.confirmed.code:
      return appointmentStatusAndColor.confirmed.color;
    case appointmentStatusAndColor.notConfirmed.code:
      return appointmentStatusAndColor.notConfirmed.color;
    case appointmentStatusAndColor.accomplished.code:
      return appointmentStatusAndColor.accomplished.color;
    case appointmentStatusAndColor.processed.code:
      return appointmentStatusAndColor.processed.color;
    default:
      return '';
  }
};

/**
 * Check if instance of absence
 * @param object
 * @returns
 */
export const instanceOfAbsence = (object: any): object is IAbsence =>
  'absenceType' in object;

/**
 * Handles getting of event style
 * @param {*} event
 * @returns style
 */
export const eventStyleGetter = ({ resource }: IAbsenceEntry) => {
  let backgroundColor = '';
  if (!instanceOfAbsence(resource)) {
    return {
      style: {
        backgroundColor,
        color: 'white',
      },
    };
  }

  const { absenceType, absenceState: appointmentStatus } = resource;

  if (absenceType && absenceType.configuration) {
    const { Color: color } = JSON.parse(absenceType.configuration);
    backgroundColor = color;
  } else if (appointmentStatus) {
    backgroundColor = getColorForAppointmentStatus(appointmentStatus);
  }

  return {
    style: {
      backgroundColor,
      color: backgroundColor === 'white' ? 'black' : 'white',
    },
  };
};

/**
 * Check if instance of calendar day
 * @param object
 * @returns
 */
export const instanceOfCalendarDay = (object: any): object is ICalendarDay =>
  'calendar' in object;

/**
 * Handles changing of calendar days to event
 * @param {*} holidaysList
 * @returns event holiday list
 */
export const changeCalendarDaysToEvent = (holidaysList: ICalendarDay[]) =>
  holidaysList.map((holiday: ICalendarDay) => {
    const start = moment(holiday.date).startOf('day').toDate();
    return {
      title: holiday.title ?? '',
      allDay: false,
      start,
      end: moment(holiday.date).startOf('day').toDate(),
      description: holiday.description ?? '',
      resource: holiday,
    };
  });

/**
 * Creates absence entry object.
 * @param {*} absence
 * @returns absence entry object
 */
export const createAbsenceEntry = (absence: IAbsence) => {
  const { absenceType, startDate, endDate, description } = absence;
  return {
    title: absenceType.type,
    allDay: false,
    start: new Date(startDate),
    end: moment(endDate).endOf('day').toDate(),
    description,
    resource: absence,
  } as IAbsenceEntry;
};

/**
 * Returns converted date to dateFormat
 * @param {*} rawDate
 * @returns
 */
export const convertDate = (rawDate: string) =>
  moment(rawDate).format(dateFormat);

/**
 * @returns Todays date on dateFormat
 */
export const dateToday = moment(new Date()).format(dateFormat);

/**
 * Function to convert objectType to its icon equivalent
 * @param objectType objectTypeEnum.code
 * @returns icon equivalent of the objectType
 */
export const getActivityIcon = (objectType: string) => {
  switch (objectType) {
    case OBJECT_TYPE_ENUM.project.code:
      return faProjectDiagram;
    case OBJECT_TYPE_ENUM.contactPerson.code:
      return faHandshake;
    default:
      return faQuestion;
  }
};

/**
 * Function to format activities from extendedUserToDoActivities to just userToDoActivities with its object attached.
 * @param {IExtendedUserToDoActivity[]} activities - An array of extended user to-do activities.
 * @returns {IUserToDoActivity[]} - Formatted user to-do activities.
 */
export const flattenToDoActivities = (
  activities: IExtendedUserToDoActivity[]
): IUserToDoActivity[] =>
  activities.map((extendedActivity) => {
    const { contactPerson, project, userToDoActivity } = extendedActivity;
    const flattenedActivity: IUserToDoActivity = {
      ...userToDoActivity,
      ...(contactPerson && { contactPerson }),
      ...(project && { project }),
    };

    return flattenedActivity;
  });

export const convertActionToTranslatedLabel = (action: string) => {
  if (i18n.exists(`ProjectActivities.${action}`)) {
    return i18n.t(`ProjectActivities.${action}`);
  }
  return action;
};

/**
 * Returns false if the current activity's date is today to not show the Due Date column otherwise true
 * @param data The ITodoActivity array
 * @returns a stringified boolean
 */
export const showDueDateColumn = (data: IUserToDoActivity[]) =>
  (data[0] ? !(convertDate(data[0].dueDate) === dateToday) : true).toString();
