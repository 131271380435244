import moment, { Moment } from 'moment';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import Datetime from 'react-datetime';
import { connect, ConnectedProps } from 'react-redux';
import Select from 'react-select';
import { Input } from 'reactstrap';

import DetailsCard from '../../../../../components/cards/DetailsCard';
import { ProjectState } from '../../../../../utils/enums/project';
import {
  dropdownOptionToObjectNameAndId,
  durationDropdownOptions,
  enumValuesToDropdownOptions,
  sortOptionsByValue,
} from '../../../../../utils/helpers/dropdown';
import i18n from '../../../../../i18n';
import { RootState } from '../../../../../redux/store';
import { IDropdownOption } from '../../../../../utils/types/commonTypes';
import { IApprovalRequest } from '../../../../../utils/types/modelTypes';
import {
  convertDuration,
  dateFormat,
  getEndDate,
} from '../../../../salesFunnel/ProjectOptionModal/projectOptionHelpers';
import type { ProjectDetailsForm } from '../../CreateNewProjectModal';
import { DurationType } from '../../../../../utils/enums/pageComponents';
import { Currency } from '../../../../../utils/enums/currency';
import { IOfferListItem } from '../../../../../utils/types/responseTypes';
import { t } from '../../projectListHelpers';
import ModifiedEmployeeDropdown from '../../../../../components/dropdowns/EmployeeDropdown/ModifiedEmployeeDropdown';
import { ApprovalObjectAttribute } from '../../../../../utils/enums/approval';
import { objectTypeEnum } from '../../../../../utils/enums/enum';

const currencyOptions = enumValuesToDropdownOptions(Object.values(Currency));

export const dropdownProjectStateItems = [
  ProjectState.ACTIVE,
  ProjectState.ORDERED,
  ProjectState.PAUSED,
  ProjectState.CLOSED,
  ProjectState.CANCELLED,
];

const projectStateOptions = enumValuesToDropdownOptions(
  Object.values(dropdownProjectStateItems)
);

interface IProps extends PropsFromRedux {
  departmentList: IDropdownOption<number>[];
  formValues: ProjectDetailsForm;
  approvalRequest: IApprovalRequest;
  onApproverChange: (approvalRequest: IApprovalRequest) => void;
  setFormValues: Dispatch<SetStateAction<ProjectDetailsForm>>;
}

/**
 * Budet and Schedule Card in Unbilled Project Modal
 */
const UnbilledProjectBudgetAndScheduleCard = ({
  departmentList,
  formValues: {
    state,
    start,
    durationType,
    end,
    departments,
    duration,
    offers,
  },
  approvalRequest,
  onApproverChange,
  setFormValues,
}: IProps) => {
  const disabled =
    state === ProjectState.LOST || state === ProjectState.ORDERED;

  const handleStartChange = (start: Moment | string) => {
    setFormValues((formValues) => ({
      ...formValues,
      start: moment(start).toISOString(),
      ...(!start && {
        duration: 0,
        durationType: {
          label: 'Months',
          value: DurationType.MONTHS as string,
        },
        end: '',
      }),
      ...(start &&
        duration && {
          end: getEndDate(moment(start), duration, durationType.value),
        }),
    }));
  };

  /**
   * Handles changes to the state field
   * @param projectState
   */
  const handleStateChange = (projectState: IDropdownOption) => {
    setFormValues((formValues) => ({
      ...formValues,
      state: projectState.value,
    }));
  };

  const handleDurationChange = (duration: string) => {
    const regex = /^\d{0,10}(\.\d{0,1}){0,1}$/;

    if (duration.match(regex)) {
      setFormValues((formValues) => ({
        ...formValues,
        duration: parseFloat(duration),
        end: getEndDate(
          moment(start),
          parseFloat(duration),
          durationType.value
        ),
      }));
    }
  };

  const handleDurationTypeChange = (durationType: IDropdownOption) => {
    setFormValues((formValues) => ({
      ...formValues,
      durationType,
      ...(duration && {
        duration: convertDuration(
          moment(start),
          moment(end),
          durationType.value
        ),
      }),
    }));
  };

  const handleDepartmentsChange = (departments: IDropdownOption<number>[]) => {
    setFormValues((formValues) => ({
      ...formValues,
      departments,
    }));
  };

  const handlePlannedInvestmentChange = (plannedInvestment: string) => {
    const updatedOffer = offers[0] ?? ({} as IOfferListItem);
    updatedOffer.budget = parseFloat(plannedInvestment);
    setFormValues((formValues) => ({
      ...formValues,
      offers: [updatedOffer],
    }));
  };

  const handleCurrencyChange = (currency: IDropdownOption) => {
    setFormValues((formValues) => ({
      ...formValues,
      offers: [
        {
          ...(formValues.offers[0] ?? ({} as IOfferListItem)),
          currency: currency.value,
        },
      ],
    }));
  };

  const handleApprovalRequestChange = (
    approverEmployee: IDropdownOption<number>
  ) => {
    // Create approvalRequest
    onApproverChange({
      id: 0,
      objectId: 0,
      objectType: objectTypeEnum.project.code,
      objectAttribute: ApprovalObjectAttribute.PROJECT_STATE,
      attributeValueConfirmed: '',
      attributeValueRejected: '',
      modifiable: true,
      createdDate: '',
      employee: dropdownOptionToObjectNameAndId(approverEmployee),
    });
  };

  const fields = [
    {
      label: t('dateOfProjectStart'),
      value: (
        <Datetime
          dateFormat={dateFormat}
          closeOnSelect
          timeFormat={false}
          inputProps={{
            placeholder: dateFormat,
            disabled,
          }}
          value={start ? moment(start).format(dateFormat) : ''}
          onChange={handleStartChange}
          locale={i18n.language}
          aria-label="date-of-project-start-datetime"
        />
      ),
      isRequired: true,
    },
    {
      label: t('totalProjectDuration'),
      value: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Input
            aria-label="project-budget-schedule-duration-input"
            type="number"
            min="0"
            step={durationType?.value === DurationType.DAYS ? '1' : '0.5'}
            maxLength={durationType?.value === DurationType.MONTHS ? 2 : 7}
            value={duration}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleDurationChange(event.target.value)
            }
            title={t('projectDurationTooltip')}
            disabled={!start || disabled}
            style={{ width: '90px' }}
          />
          <Select
            aria-label="project-budget-schedule-duration-select"
            options={durationDropdownOptions}
            value={durationType}
            onChange={handleDurationTypeChange}
            isDisabled={!start || disabled}
          />
        </div>
      ),
      isRequired: true,
    },
    {
      label: t('plannedInvestment'),
      value: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Input
            aria-label="project-budget-schedule-planned-investment-input"
            type="number"
            min="0"
            step={1}
            value={offers ? offers[0]?.budget : 0}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handlePlannedInvestmentChange(event.target.value)
            }
            disabled={!offers[0] || disabled}
            style={{ width: '90px' }}
          />
          <Select
            aria-label="project-budget-schedule-currency-select"
            options={currencyOptions}
            value={currencyOptions.find(
              (currency) => currency.value === offers[0]?.currency
            )}
            onChange={handleCurrencyChange}
            isDisabled={!offers[0] || disabled}
          />
        </div>
      ),
      isRequired: true,
    },
    {
      label: t('topicDepartments'),
      value: (
        <Select
          aria-label="project-budget-and-schedule-input-departments"
          isMulti
          isClearable
          placeHolder={t('selectDepartments')}
          options={departmentList}
          value={departments}
          onChange={handleDepartmentsChange}
          isDisabled={disabled}
        />
      ),
    },
    {
      label: t('approvalRequest'),
      value: (
        <div style={{ width: '300px', alignItems: 'center', gap: '10px' }}>
          <ModifiedEmployeeDropdown
            permissions={[]}
            employeeId={approvalRequest?.employee?.id ?? 0}
            isDisabled={false}
            onChange={handleApprovalRequestChange}
          />
        </div>
      ),
      isRequired: true,
    },
    {
      label: t('projectStatus'),
      value: (
        <div style={{ width: '300px' }}>
          <Select
            aria-label="project-state-selector"
            value={projectStateOptions.find(
              (projectState) => projectState.value === state
            )}
            options={sortOptionsByValue(projectStateOptions)}
            onChange={handleStateChange}
            isDisabled={false}
            placeholder={t('selectState')}
            style={{ fontSize: 16 }}
          />
        </div>
      ),
      isRequired: true,
    },
  ];

  return <DetailsCard title={t('budgetAndSchedule')} fields={fields} border />;
};

const mapStateToProps = (state: RootState) => ({
  configurations: state.configs.configs,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UnbilledProjectBudgetAndScheduleCard);
