import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import axios from '../axios/Axios-Interceptors';
import { urlBuilder } from '../../utils/helpers/url';
import {
  IObjectEmployeeWithResponsible,
  IObjectNameAndId,
} from '../../utils/types/commonTypes';
import {
  IEmployee,
  IEmployeeGroup,
  IPermission,
  IResponsible,
  IResponsibleRole,
  IRole,
  IRoleGroup,
} from '../../utils/types/modelTypes';
import { Url } from './constants';
import {
  IEmployeeDetail,
  IEmployeeListItem,
} from '../../utils/types/responseTypes';

export const getListOfResponsibleNames = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IObjectNameAndId[]>>(
    axios.get(urlBuilder(Url.RESPONSIBLE_NAMES, queryParameters))
  );

export const getResponsibleRoleList = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IResponsibleRole[]>>(
    axios.get(urlBuilder(Url.RESPONSIBLE_ROLES, queryParameters))
  );

export const getResponsibles = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<IResponsible[]>>(
    axios.get(urlBuilder(Url.RESPONSIBLE, queryParameters))
  );

export const getEmployee = (employeeId: number) =>
  trackPromise<AxiosResponse<IEmployee>>(
    axios.get(urlBuilder(`${Url.EMPLOYEES}/${employeeId}`, {}))
  );

export const getEmployeeListItems = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IEmployeeListItem[]>>(
    axios.get(urlBuilder(Url.EMPLOYEE_LIST, queryParameters))
  );

export const getEmployeeNames = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<IObjectNameAndId[]>>(
    axios.get(urlBuilder(Url.EMPLOYEE_NAMES, queryParameters))
  );

export const getEmployeeNamesWithResponsible = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IObjectEmployeeWithResponsible[]>>(
    axios.get(urlBuilder(`${Url.EMPLOYEE_NAMES}/responsible`, queryParameters))
  );

export const getRoles = () =>
  trackPromise<AxiosResponse<IRole[]>>(
    axios.get(urlBuilder(`${Url.ROLES}`, {}))
  );

export const getRoleGroups = () =>
  trackPromise<AxiosResponse<IRoleGroup[]>>(
    axios.get(urlBuilder(`${Url.ROLE_GROUPS}`, {}))
  );

export const deleteSubject2Role = (subject2RoleId: number) =>
  trackPromise<AxiosResponse<void>>(
    axios.get(urlBuilder(`${Url.SUBJECT_2_ROLE}/${subject2RoleId}`, {}))
  );

export const getResponsibleRoles = () =>
  trackPromise<AxiosResponse<IResponsibleRole[]>>(
    axios.get(urlBuilder(`${Url.RESPONSIBLE_ROLES}`, {}))
  );

export const syncWithPersonio = (email: string) =>
  trackPromise<AxiosResponse<IEmployeeDetail>>(
    axios.put(urlBuilder(Url.PERSONIO_SYNC_BY_EMAIL, {}), email)
  );

export const getEmployeeFromDetail = (employeeId: number) =>
  trackPromise<AxiosResponse<IEmployeeDetail>>(
    axios.get(urlBuilder(`${Url.EMPLOYEE_DETAILS}/${employeeId}`, {}))
  );

export const updateEmployeeFromDetail = (employeeDetail: IEmployeeDetail) =>
  trackPromise<AxiosResponse<IEmployeeDetail>>(
    axios.patch(urlBuilder(Url.EMPLOYEE_DETAILS, {}), employeeDetail)
  );

export const getListOfEmployeeGroups = () =>
  trackPromise<AxiosResponse<IEmployeeGroup[]>>(
    axios.get(urlBuilder(Url.EMPLOYEE_GROUPS, {}))
  );

export const getPermissions = () =>
  trackPromise<AxiosResponse<IPermission[]>>(
    axios.get(urlBuilder(Url.PERMISSIONS, {}))
  );

export const savePermission = (permission: IPermission) =>
  trackPromise<AxiosResponse<IPermission>>(
    axios.post(urlBuilder(Url.PERMISSIONS, {}), permission)
  );

export const updatePermission = (permission: IPermission) =>
  trackPromise<AxiosResponse<IPermission>>(
    axios.put(urlBuilder(Url.PERMISSIONS, {}), permission)
  );

export const deletePermission = (permissionId: number) =>
  trackPromise<AxiosResponse<void>>(
    axios.delete(urlBuilder(`${Url.PERMISSIONS}/${permissionId}`, {}))
  );

export function getWorkingEmployeeDays(
  id: number,
  startDateString: string,
  endDateString: string
) {
  const axiosParameters = {
    id: id.toString(),
    startDateString,
    endDateString,
  };
  return axios
    .get(urlBuilder(Url.EMPLOYEE_WORKING_DAYS, axiosParameters))
    .then((response: AxiosResponse<number>) => response.data);
}
