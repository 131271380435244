import React, { useContext, useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardBody, Col, Row } from 'reactstrap';
import Select from 'react-select';

import InputFormLabel from '../../../components/form/InputFormLabel';
import { OfferDetailsContext } from './OfferDetailsProvider';
import { IDropdownOption, IProjectInvolvedResponsible } from '../../../utils/types/commonTypes';
import MultipleResponsibleDropdown from '../../../components/form/MultipleResponsibleDropdown';
import { translate } from './offerHelper';

const OfferResponsiblesCard = () => {
  const { formValues, responsibleList, setFormValues } =
    useContext(OfferDetailsContext);

  const { responsible, involved } = formValues;

  const [responsibleOptions, setResponsibleOptions] = useState(
    [] as IDropdownOption<number>[]
  );

  const handleResponsibleChange = (value: IDropdownOption<number>) => {
    setFormValues((values) => ({ ...values, responsible: value }));
  };

  const handleInvolvedChange = (involved: IProjectInvolvedResponsible[]) => {
    setFormValues((formValues) => ({
      ...formValues,
      involved,
    }));
  };

  useEffect(() => {
    setResponsibleOptions(
      responsibleList.filter(
        ({ value }) =>
          ![responsible, ...involved.map(({ responsible }) => responsible)]
            .map((responsible) => (responsible ? responsible.value : null))
            .includes(value)
      )
    );
  }, [responsible, involved]);

  return (
    <Card className="common-card with-border">
      <CardHeader>
        <CardTitle>
          <div style={{ textAlign: 'center' }}>
            <h3>{translate('responsibleTitle')}</h3>
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <InputFormLabel
              isRequired={false}
              text={translate('responsible')}
            />
          </Col>
          <Col>
            <Select
              value={responsible}
              options={responsibleList}
              onChange={handleResponsibleChange}
            />
          </Col>
        </Row>
        <Row>
          <InputFormLabel isRequired={false} text={translate('involved')} />
          <MultipleResponsibleDropdown
            responsibles={involved}
            setResponsibles={handleInvolvedChange}
            responsibleOptions={responsibleOptions}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default OfferResponsiblesCard;
