import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from 'reactstrap';

import i18n from '../../../i18n';
import withModals, {
  IWithModalsProps,
} from '../../../utils/withModals';
import { isEmpty } from '../../../utils/helpers/array';
import {
  ICustomerAccountDetail,
  ICustomerDetail,
  ICustomerListItem,
} from '../../../utils/types/responseTypes';
import { IObjectNameAndId } from '../../../utils/types/commonTypes';
import PaginatedTable from '../../../components/tables/PaginatedTable';
import { TableData } from '../../../components/tables/Table';
import {
  getCustomerListItems,
  saveMultipleCustomerDetails,
} from '../../../services/api/customer';
import { translateExistingCustomerSelection } from './customerAccountDetailsHelper';

interface IProps extends IWithModalsProps {
  customerAccountDetail: ICustomerAccountDetail;
  onConfirm: () => void;
  onCancel: () => void;
}

/**
 * Displays all the available Customer(with checkbox) that was not yet assigned to an account.\
 * Variable props:\
 *  customerAccount
 * Function props:\
 *  -onConfirm - returns all the Customer that was chosen\
 *  -onCancel\
 */
const ExistingCustomerSelection = ({
  customerAccountDetail,
  onConfirm,
  onCancel,
  modalErrorHandler,
}: IProps) => {
  const [selectedCustomers, setSelectedCustomers] = useState<
    ICustomerListItem[]
  >([]);
  const [customers, setCustomers] = useState<ICustomerListItem[]>([]);
  const [searchWord, setSearchWord] = useState<string>('');

  const preparedTableData: TableData[] = customers
    .filter(({ name }) => name.toLowerCase().includes(searchWord.toLowerCase()))
    .map((customer) => ({
      Customer: (
        <Input
          type="checkbox"
          onChange={() =>
            setSelectedCustomers((prevSelectedCustomers) => {
              const isSelected = prevSelectedCustomers.some(
                ({ id }) => id === customer.id
              );

              if (isSelected) {
                // If the customer is already selected, remove it
                return prevSelectedCustomers.filter(
                  ({ id }) => id !== customer.id
                );
              }
              // If the customer is not selected, add it
              return [...prevSelectedCustomers, customer];
            })
          }
          checked={selectedCustomers.some(({ id }) => id === customer.id)}
        />
      ),
      Name: customer.name ?? '',
      State: customer.state
        ? i18n.t(`CustomerStates.${customer.state}`)
        : 'N/A',
      Industries: customer.sectors
        ? customer.sectors
            .map((customerSector) => customerSector ?? '')
            .join(', ')
        : '',
    }));

  const fetchCustomerListItems = async () => {
    await getCustomerListItems({
      'customerAccountId.specified': 'false',
      sort: 'name%2Casc',
    })
      .then(({ data }) => {
        setCustomers(data);
      })
      .catch((error) => {
        modalErrorHandler(
          translateExistingCustomerSelection('failedToRetrieveCustomers'),
          error
        );
      });
  };

  const handleClearSearchValue = () => {
    setSearchWord('');
  };

  // Handles changes to title field
  const handleSearchChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchWord(value.trim());
  };

  const handleSaveSelectedCustomer = async () => {
    const customerDetails: ICustomerDetail[] = selectedCustomers.map(
      ({ id }) =>
        ({
          id,
          customerAccount: {
            name: customerAccountDetail.name,
            id: customerAccountDetail.id,
          } as IObjectNameAndId,
        } as ICustomerDetail)
    );

    if (!isEmpty(customerDetails)) {
      await saveMultipleCustomerDetails(customerDetails).catch((error) => {
        modalErrorHandler(
          translateExistingCustomerSelection(
            'failedToAddCustomersToTheAccount'
          ),
          error
        );
      });
    }
    onConfirm();
  };

  useEffect(() => {
    fetchCustomerListItems();
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Row>
            <Col lg="5">
              <Input
                type="string"
                onChange={handleSearchChange}
                value={searchWord ?? ''}
                placeholder={translateExistingCustomerSelection(
                  'searchCustomerName'
                )}
              />{' '}
            </Col>
            <Col lg="3">
              <Button
                color="primary"
                size="m"
                onClick={() => handleClearSearchValue()}
              >
                {translateExistingCustomerSelection('clear')}
              </Button>
            </Col>
          </Row>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div>
          {!isEmpty(customers) ? (
            <PaginatedTable tableData={preparedTableData} striped />
          ) : (
            <Label>{translateExistingCustomerSelection('noCustomers')}</Label>
          )}
        </div>
        <br />
        <div className="flex-button">
          <Button
            color="primary"
            size="m"
            onClick={() => handleSaveSelectedCustomer()}
            disabled={!(!isEmpty(selectedCustomers) && customerAccountDetail)}
          >
            {translateExistingCustomerSelection('confirm')}
          </Button>
          <Button color="primary" size="m" onClick={() => onCancel()}>
            {translateExistingCustomerSelection('cancel')}
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default withModals(ExistingCustomerSelection);
