import React from 'react';
import { Row, Col } from 'reactstrap';

import { Ownership } from '../../../utils/enums/ownership';
import { EntityType } from '../../../utils/enums/pageComponents';
import { SortBy, SortType } from '../../../utils/enums/sort';
import { isEmpty } from '../../../utils/helpers/array';
import { createLinkIfAuthorised } from '../../../utils/helpers/permission';
import i18n from '../../../i18n';
import { ICustomerSiteListItem } from '../../../utils/types/responseTypes';
import { ICustomerSiteListFilters } from '../../../utils/types/stateTypes';
import TableDataComponent from '../../../components/tables/TableDataComponent';

const snakeCaseToCamelCase = (str: string) =>
  str
    .toLowerCase()
    .replace(/([_][a-z])/g, (substr) => substr.toUpperCase().replace('_', ''));

/**
 * Translator function for Customer Site List
 * @param keyName Key for phrase to be translated
 * @returns Translated string
 */
export const t = (keyName: string) => i18n.t(`CustomerSiteList.${keyName}`);

/**
 * Creates the customer site entries to be shown in the DynamicTable
 * @param customer
 * @returns customerSiteEntry
 */
export const createCustomerSiteEntry = ({
  id,
  customer: { id: customerId, name: customerName },
  name,
  location,
  targetPriority,
  customerSiteState,
  sectors,
  responsible: { id: responsibleId, name: responsibleName },
  involvedEmployees,
}: ICustomerSiteListItem) => ({
  customer: createLinkIfAuthorised(
    EntityType.CUSTOMER,
    customerName,
    customerId
  ),
  name: createLinkIfAuthorised(
    EntityType.CUSTOMER_SITE,
    `${name} (${location})`,
    id
  ),
  targetPriority: i18n.t(
    `TargetPriority.${snakeCaseToCamelCase(targetPriority)}`
  ),
  customerSiteState: i18n.t(`CustomerStates.${customerSiteState}`),
  sectors: (
    <TableDataComponent components={sectors.map((sector) => sector.name)} />
  ),
  responsible: createLinkIfAuthorised(
    EntityType.EMPLOYEE,
    responsibleName,
    responsibleId
  ),
  involvedEmployees: involvedEmployees.map(({ id, name }) => (
    <Row key={id}>
      <Col key={id}>
        {createLinkIfAuthorised(EntityType.EMPLOYEE, name, id)}
      </Col>
    </Row>
  )),
});

/**
 * Creates the query parameters to be used to fetch data
 * @returns Object containing query parameters
 */
export const createQueryParameters = (
  page: number,
  {
    customer,
    name,
    targetPriority,
    customerSiteState,
    sectors,
    responsible,
    involvedEmployees,
    responsibleWithPermission,
    ownership,
  }: ICustomerSiteListFilters,
  sortBy: SortBy,
  sortType: SortType
) => ({
  page: page.toString(),
  ...(customer && {
    'customerName.contains': customer,
  }),
  ...(!isEmpty(customerSiteState) && {
    'customerSiteState.in': customerSiteState
      .map(({ value }) => value)
      .join(','),
  }),
  ...(sectors && {
    'sector.contains': sectors,
  }),
  ...(name && {
    'name.contains': name,
  }),
  ...(!isEmpty(responsible) && {
    'responsibleId.in': responsible.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(involvedEmployees) && {
    'involvedId.in': involvedEmployees.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(targetPriority) && {
    'targetPriority.in': targetPriority.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(ownership) && {
    'ownership.in': `${
      Array.isArray(ownership) ? ownership.join(',') : ownership
    }`,
    ...(responsibleWithPermission && {
      ...(ownership.includes(Ownership.RESPONSIBLE) && {
        'responsibleId.equals': responsibleWithPermission.value.toString(),
      }),
      ...(ownership.includes(Ownership.INVOLVED) && {
        'involvedId.equals': responsibleWithPermission.value.toString(),
      }),
    }),
  }),
  ...(sortBy &&
    sortType && {
      sort: `${sortBy}%2C${sortType}`,
    }),
});
