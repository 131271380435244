import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Input,
} from 'reactstrap';
import axios from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { handleError, isEmpty } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';
import ModalOK from '../../../components/modals/ModalOK';
import CountryDropdown from '../../../components/dropdowns/CountryDropdown';

/*
 * Class used when adding/updating a Sector
 */
class AddOrUpdateCountryState extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countryState: {
        id: props.countryState?.id ?? null,
        name: props.countryState?.name ?? null,
        country: props.countryState?.country ?? null,
      },
      showModalOK: false,

      error: null,
      showModalError: false,
    };
    this.isUpdate = props.countryState?.id ? true : false;
  }

  t(keyName) {
    return i18n.t('CountryStateListView.' + keyName);
  }

  //Toggle for showing the modal for duplicate entries.
  toggleModalOK = () => {
    this.setState({ showModalOK: !this.state.showModalOK });
  };

  //Toggle for showing the modal for error popup.
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  addOrUpdateCountryState = async (e) => {
    let countryState = this.state.countryState;
    if (!countryState?.name || !countryState.country) {
      this.message = this.t('nameAndCountryRequired');
      this.toggleModalOK();
      return;
    }
    var sameNameEntries = await axios.sales
      .get(`country-states?name.equals=${countryState?.name}`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(this.t('failedToValidateCountryState'), error);
      });
    if (sameNameEntries === undefined) return;

    if (!isEmpty(sameNameEntries)) {
      for (let existingState of sameNameEntries) {
        if (existingState?.country?.id === countryState?.country?.id) {
          if (
            (this.isUpdate && existingState.id === countryState.id) ||
            !this.isUpdate
          ) {
            this.message = this.t('nameAlreadyExist');
            this.toggleModalOK();
            return;
          }
        }
      }
    }
    this.saveCountryState();
  };

  handleError = (mainError, errorObject) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  saveCountryState = async () => {
    let countryState = this.state.countryState;
    let actionError = countryState?.id
      ? 'Failed to Update Country State'
      : 'Failed to Add Country State';

    let savedCountryState = await axios.sales
      .save('country-states', countryState)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.handleError(actionError, error);
      });

    if (savedCountryState && this.props.onSave) {
      this.props.onSave(savedCountryState);
    }
  };

  handleNameChange = (e) => {
    let value = e.target.value.trim() ? e.target.value : null;
    let countryState = this.state.countryState;
    countryState.name = value;
    this.setState({ countryState: countryState });
  };

  handleCountryChange = (country) => {
    let countryState = this.state.countryState;
    countryState.country = country;
    this.setState({ countryState: countryState });
  };

  render() {
    const countryState = this.state.countryState;
    return (
      <Container fluid>
        <Card>
          {this.state.isUpdate ? (
            <CardHeader>
              <CardTitle>
                <h1>Country State Details - {this.props.match.params.id}</h1>
              </CardTitle>
            </CardHeader>
          ) : null}
          <CardBody>
            <InputFormLabel isRequired={true} text={this.t('name')} />
            <Input
              bsSize="lg"
              type="string"
              onChange={this.handleNameChange}
              value={countryState?.name ? countryState.name : ''}
            ></Input>
            <br />
            <InputFormLabel isRequired text={this.t('country')} />
            <CountryDropdown
              onChange={this.handleCountryChange}
              countryId={countryState?.country?.id ?? 0}
            />
            <br />
            <div className="card-actions float-end">
              <Button
                color="primary"
                onClick={() => this.addOrUpdateCountryState()}
              >
                {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, this.t('submit'))}
              </Button>
            </div>
          </CardBody>
        </Card>
        <ModalOK
          isOpen={this.state.showModalOK}
          onClose={this.toggleModalOK}
          modalTitle={this.t('error')}
          modalBodyText={this.message}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}

export default withRouter(withTranslation()(AddOrUpdateCountryState));
