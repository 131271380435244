import { faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'moment/min/locales';
import React from 'react';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
} from 'reactstrap';
import Auth from '../../../services/axios/Auth';
import axios from '../../../services/axios/axios';
import { dataGeneration, updateRecord } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { ProjectEmployeeState } from '../../../utils/enums/project';
import { errorHandler } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';
import ModalOK from '../../../components/modals/ModalOK';
import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';
import { sortOptionsByValue } from '../../../utils/helpers/dropdown';

/**
 * This class displays the details of the employee
 * and adds the change of state for the project employee
 */

class EmployeeDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      role: '',
      validFrom: '',
      validUntil: '',
      status: '',
      showModalError: false,
      changeState: false,
      projectEmployee: {},
      showModalOk: false,
      title: '',
      bodyText: '',
      changesDetected: false,
      toggleStatusField: false,
    };
  }

  t(keyName) {
    return i18n.t('EmployeeDetailCard.' + keyName);
  }

  toggleStatusField = () => {
    this.setState({
      toggleStatusField: !this.state.toggleStatusField,
    });
  };

  async componentDidMount() {
    //gets the project employee
    var projectEmployee = await axios.project
      .get('project-employees/' + this.props.projectEmployeeId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.message = errorHandler(
          dataGeneration,
          error,
          this.t('projectEmployeeDetails')
        );
        this.ToggleModalError();
      });
    if (projectEmployee instanceof Object) {
      this.setState({
        status: projectEmployee.state,
        projectEmployee: projectEmployee,
      });

      // gets the employee details
      if (projectEmployee.employeeId) {
        await axios.employee
          .get('employees/' + projectEmployee.employeeId)
          .then((response) => {
            if (response.data instanceof Object) {
              this.setState({
                name: response.data.firstname + ' ' + response.data.name,
              });
            }
          })
          .catch((error) => {
            this.message = errorHandler(
              dataGeneration,
              error,
              this.t('employeeDetails')
            );
            this.ToggleModalError();
          });
      }
    }
  }

  ToggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  ToggleModalOk = () => {
    this.setState({
      showModalOk: !this.state.showModalOk,
    });
  };

  changeState = () => {
    this.setState({
      changeState: !this.state.changeState,
    });
  };

  saveState = () => {
    var projectEmployee = this.state.projectEmployee;
    projectEmployee.state = this.state.status;

    axios.project
      .put('project-employees', projectEmployee)
      .then(() => {
        this.setState({
          title: this.t('projectEmployeeState'),
          bodyText: this.t('projectEmployeeStateChanged'),
          changesDetected: false,
        });
        this.ToggleModalOk();
        this.toggleStatusField();
      })
      .catch((error) => {
        this.message = errorHandler(
          updateRecord,
          error,
          this.t('projectEmployeeState')
        );
        this.ToggleModalError();
      });
  };

  handleChangeState = (state) => {
    this.setState({
      status: state.value,
      changesDetected: true,
    });
  };

  resetChanges = () => {
    this.componentDidMount();
    this.setState({
      changesDetected: false,
    });
    this.toggleStatusField();
  };

  render() {
    const projectEmployee = this.state.projectEmployee;
    return (
      <Card>
        <CardHeader>
          <div className="card-actions float-end">
            {Auth.isAdmin() ? (
              <>
                <Button
                  color="primary"
                  size="m"
                  onClick={this.saveState}
                  disabled={!this.state.changesDetected}
                >
                  {generateTitle(
                    BUTTON_TITLE_ENUM.SAVE.code,
                    this.t('saveState')
                  )}
                </Button>{' '}
                <Button
                  color="primary"
                  size="m"
                  onClick={() => this.resetChanges()}
                  disabled={!this.state.changesDetected}
                >
                  {generateTitle(BUTTON_TITLE_ENUM.UNDO.code, this.t('reset'))}
                </Button>
              </>
            ) : null}
          </div>
          <CardTitle>
            <h1>{this.t('employeeDetails')}</h1>
          </CardTitle>
        </CardHeader>

        <CardBody>
          <Table size="m" className="my-2" cellPadding="20px" striped>
            <tbody>
              <tr>
                <th>{this.t('name')}</th>
                <td>{this.state.name}</td>
              </tr>
              <tr>
                <th>{this.t('projectRole')}</th>
                <td>{projectEmployee?.projectEmployeeRole?.role}</td>
              </tr>
              <tr>
                <th>{this.t('state')}</th>
                {!this.state.toggleStatusField ? (
                  <td onClick={() => this.toggleStatusField()}>
                    {i18n.exists(`EmployeeStatus.${this.state.status}`)
                      ? i18n.t(`EmployeeStatus.${this.state.status}`)
                      : this.state.status}
                  </td>
                ) : (
                  <td>
                    <Select
                      options={sortOptionsByValue(
                        enumValuesToDropdownOptions(
                          Object.values(ProjectEmployeeState),
                          'EmployeeStatus'
                        )
                      )}
                      value={enumValuesToDropdownOptions(
                        Object.values(ProjectEmployeeState),
                        'EmployeeStatus'
                      ).find(
                        (option) => option.value === this.state.status
                      )}
                      onChange={this.handleChangeState}
                    />
                  </td>
                )}
              </tr>
              {this.state.validFrom ? (
                <tr>
                  <th>{this.t('validFrom')}</th>
                  <td>{this.state.validFrom}</td>
                </tr>
              ) : null}
              {this.state.validUntil ? (
                <tr>
                  <th>{this.t('validUntil')}</th>
                  <td>{this.state.validUntil}</td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </CardBody>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.ToggleModalError}
          mainError={this.message?.mainError}
          errorReason={this.message?.errorReason}
          errorResponse={this.message?.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalOK
          isOpen={this.state.showModalOk}
          onClose={this.ToggleModalOk}
          modalBodyText={this.state.bodyText}
          modalTitle={this.state.title}
        ></ModalOK>
      </Card>
    );
  }
}

export default EmployeeDetailCard;
