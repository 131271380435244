import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { Button, Container } from 'reactstrap';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import ModalError from '../../../components/modals/ModalError';
import { dataGeneration } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { SERVICE_CONFIG } from "../../../utils/enums/service";
import { errorHandler, isEmpty } from '../../../utils/helpers/GenericHelper';
import {
  generateTitle,
} from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import ModalForm from '../../../components/modals/ModalForm';
import UpdateTargetPriorityPermission from './UpdateTargetPriorityPermission';

/**
 * Class for showing the Permission for Customer Target Priority in Sales Settings
 */
class TargetPriorityPermissionSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceConfigurations: [],

      showModalForm: false,
      showModalError: false,
      modalForm: null,
    };

    this.message = '';
  }

  t(keyName) {
    return i18n.t('TargetPriorityPermissionSettings.' + keyName);
  }

  /**
   * Toggles an error modal
   */
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  /**
   * Toggles a modal form
   */
  toggleModalForm = () => {
    this.setState({ showModalForm: !this.state.showModalForm });
  };

  async componentDidMount() {
    let serviceConfigurations = await axios.serviceConfiguration
      .get(
        `?service.equals=${SERVICE_CONFIG.customerPriority.service}&configKey.equals=${SERVICE_CONFIG.customerPriority.key}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.message = errorHandler(
          dataGeneration,
          error,
          this.t('failedToRetrieveTargetPriorityPermission')
        );
        this.toggleModalError();
      });

    if (!isEmpty(serviceConfigurations)) {
      this.setState({
        serviceConfigurations: serviceConfigurations,
      });
    }
  }

  /**
   * Prepares data for table
   * @param {*} serviceConfigurations
   * @returns tableData
   */
  prepareTableData = (serviceConfigurations) => {
    const newTableData = [];
    if (!isEmpty(serviceConfigurations)) {
      serviceConfigurations.forEach((serviceConfiguration) => {
        const entry = {
          id: serviceConfiguration?.id,
          service: serviceConfiguration?.service,
          configKey: serviceConfiguration?.configKey,
          configValue: this.t(serviceConfiguration?.configValue),
          editButton: (
            <>
              <Button
                color="primary"
                onClick={() =>
                  this.updatePriorityPermission(serviceConfiguration)
                }
              >
                {generateTitle(BUTTON_TITLE_ENUM.EDIT.code)}
              </Button>
            </>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  /**
   * Handles updating upon clicking of edit button
   * @param {*} serviceConfiguration
   */
  updatePriorityPermission = (serviceConfiguration) => {
    this.setState({
      modalForm: (
        <UpdateTargetPriorityPermission
          serviceConfiguration={serviceConfiguration}
          onSave={this.onSave}
        />
      ),
    });
    this.toggleModalForm();
  };

  /**
   * Updates the state of serviceConfigurations upon saving of the edited serviceConfiguration
   * @param {*} serviceConfiguration
   */
  onSave = (serviceConfiguration) => {
    let serviceConfigurations = this.state.serviceConfigurations;
    let index = serviceConfigurations.findIndex(
      (item) => item?.id === serviceConfiguration?.id
    );
    if (index >= 0) {
      serviceConfigurations[index] = serviceConfiguration;
    } else {
      serviceConfigurations.push(serviceConfiguration);
    }
    serviceConfigurations = serviceConfigurations?.sort((a, b) =>
      a?.configValue > b?.configValue ? 1 : -1
    );
    this.setState({ serviceConfigurations: serviceConfigurations });
    if (this.state.showModalForm) {
      this.toggleModalForm();
    }
  };

  render() {
    const serviceConfigurations = this.state.serviceConfigurations;
    const preparedColumns = [
      {
        type: 'component',
        header: this.t('targetPriorityPermission'),
        accessor: 'service',
        show: 'true',
      },
      {
        type: 'component',
        header: this.t('bddHeadOrResponsible'),
        accessor: 'configValue',
        show: 'true',
      },
      {
        type: 'component',
        header: '',
        accessor: 'editButton',
        show: 'true',
      },
    ];
    return (
      <Container fluid>
        <DynamicTable
          data={this.prepareTableData(serviceConfigurations)}
          columns={preparedColumns}
        />

        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.message?.mainError ?? ''}
          errorReason={this.message?.errorReason ?? ''}
          errorResponse={this.message?.errorResponse ?? ''}
          modalTitle={this.t('error')}
        />
        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleModalForm}
          ref={this.state.modalForm}
          modalTitle={this.t('editTargetPriorityPermission')}
        >
          {this.state.modalForm}
        </ModalForm>
      </Container>
    );
  }
}

export default TargetPriorityPermissionSettings;
