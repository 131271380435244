import React, { ChangeEvent, useContext, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { AxiosError, AxiosResponse } from 'axios';

import { OrderSummaryContext } from './OrderSummaryProvider';
import { ProjectIdModificationMessage } from '../../../../utils/enums/order';
import withModals, { IWithModalsProps } from '../../../../utils/withModals';
import { isProjectIdValid } from '../../../../services/api/project';
import DetailsCard from '../../../../components/cards/DetailsCard';
import { convertToFourDigitString, translate } from './orderHelper';

interface IProps extends IWithModalsProps {
  onClose: () => void;
}

const ModifyProjectIdModal = ({ onClose, modalErrorHandler }: IProps) => {
  const { project, formValues, setFormValues } =
    useContext(OrderSummaryContext);

  const { projectId } = formValues;
  const { customer } = project;

  const [isChecking, setIsChecking] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [numberString, setNumberString] = useState('');
  const [errorReason, setErrorReason] = useState('');
  const [formProjectId, setFormProjectId] = useState(projectId);

  const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const number = event.target.value.trim();
    const regexValidNumber = /^\d{1,4}$/;
    const regexAllZeroes = /^0+$/;

    setIsChecking(true);
    setIsValid(false);

    if (
      !number.match(regexValidNumber) ||
      regexAllZeroes.test(number) ||
      number === ''
    ) {
      setIsValid(false);
      setIsChecking(false);
      setErrorReason(ProjectIdModificationMessage.INVALID_NUMBER);
      setNumberString(number);
      return;
    }
    setNumberString(number);
    const convertedNumberString = convertToFourDigitString(number);

    const index = 9;
    const underscoreAndLocation = projectId.substring(index);
    const updatedProjectId = `${customer.abbreviation}${convertedNumberString}${underscoreAndLocation}`;

    isProjectIdValid({ projectId: updatedProjectId })
      .then((response: AxiosResponse<boolean>) => {
        if (response.data) {
          setIsValid(response.data);
          setIsChecking(false);
          setFormProjectId(updatedProjectId);
        } else {
          setIsValid(false);
          setIsChecking(false);
          setErrorReason(ProjectIdModificationMessage.STILL_IN_USE_PROJECT_ID);
        }
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(translate('error'), error);
      });
  };

  const handleSaveButton = () => {
    setFormValues((values) => ({
      ...values,
      projectId: formProjectId,
    }));

    onClose();
  };

  const detailList = [
    {
      label: translate('fixPreliminaryCustomerId'),
      value: (
        <Input
          type="string"
          name="customerAbbreviation"
          style={{ height: '38px' }}
          value={customer.abbreviation}
          readOnly
        />
      ),
    },
    {
      label: translate('manuallyChoseNumber'),
      value: (
        <Input
          type="text"
          aria-label="manually-chose-number"
          maxLength="4"
          style={{ height: '38px' }}
          value={numberString}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleNumberChange(event)
          }
        />
      ),
    },
    {
      label: translate('projectId'),
      value: (
        <>
          {isChecking && (
            <>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="margin-right text-muted"
              />
              {translate('checkingProjectId')}
            </>
          )}
          {!isChecking && projectId && (
            <>
              <FontAwesomeIcon
                icon={isValid ? faCheckCircle : faTimesCircle}
                className={`margin-right ${
                  isValid ? 'text-success' : 'text-warning'
                }`}
              />
              {translate(isValid ? 'isValidProjectId' : errorReason)}
            </>
          )}
          <Input
            type="text"
            aria-label="project-id"
            value={isValid ? formProjectId : ''}
            readOnly
          />
        </>
      ),
      isRequired: true,
    },
  ];

  const footerButtons = (
    <>
      <Button
        color="primary"
        className="continue-and-cancel-button"
        disabled={!isValid}
        onClick={() => handleSaveButton()}
      >
        {translate('ok')}
      </Button>
      <Button
        color="primary"
        className="continue-and-cancel-button"
        onClick={() => onClose()}
      >
        {translate('cancelIdModification')}
      </Button>
    </>
  );

  return (
    <DetailsCard title="" fields={detailList} footerButtons={footerButtons} />
  );
};

export default withModals(ModifyProjectIdModal);
