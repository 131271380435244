import { faPlus, faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Table,
} from 'reactstrap';
import Auth from '../../../services/axios/Auth';
import axios from '../../../services/axios/axios';
import {
  addRecord,
  dataGeneration,
  userRole,
} from '../../../utils/constants';
import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';
import { EmployeeDefence } from '../../../utils/enums/employee';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { errorHandler } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import { SkillLevel } from '../../../utils/enums/skill';
import FadeAlert from '../../../components/layout/FadeAlert';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import ModalOK from '../../../components/modals/ModalOK';
import SkillSetDropdown from './SkillSetDropdown';
import { sortOptionsByValue } from '../../../utils/helpers/dropdown';

const employeeFields = {
  LAST_NAME: 'LAST_NAME',
  FIRST_NAME: 'FIRST_NAME',
  SKILL_SET: 'SKILL_SET',
  MOBILITY: 'MOBILITY',
  RESTRICTIONS: 'RESTRICTIONS',
  PREFERENCES: 'PREFERENCES',
};

/*
 * Card used in Resource Profile Details page that show resource profile details a specific employee
 */
class ResourceProfileCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalError: false,
      modalForm: null,
      showModalForm: false,
      showModalOk: false,
      customerSectors: [],
      showModalDelete: false,
      fieldForUpdate: [], // determines which field is currently having an update
      fieldWithChanges: [], // determines what field are changed
      user: null,
      lastname: null,
      firstname: null,
      mobility: null,
      restrictions: null,
      preferences: null,
      skillSets: [],
      skills: [],
      projectAssignments: [],
    };
    this.mainError = '';
    this.errorReason = '';
    this.errorResponse = '';
    this.modalBody = null;
    this.modalBodyText = null;
    this.modalTitle = '';
    this.id = this.props.match.params.id;
    this.selectedAction = null;
    this.modalEvent = null;
  }

  t(keyName) {
    return i18n.t('ResourceProfileCard.' + keyName);
  }

  async componentDidMount() {
    await axios.employee
      .get('employees/' + this.id)
      .then(async (response) => {
        this.setState({
          user: response.data,
          lastname: response.data.name,
          firstname: response.data.firstname,
          mobility: response.data.mobility,
          employee: response.data,
        });
      })
      .catch((error) => {
        this.message = errorHandler(
          dataGeneration,
          error,
          this.t(userRole.toLowerCase())
        );
        this.toggleModalError();
      });

    // Get skill_sets based on the employee id.
    await axios.project
      .get('skill-sets?employeeId.equals=' + this.props.match.params.id)
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({
            skillSets: response.data,
          });
        }
      })
      .catch((error) => {
        this.message = errorHandler(dataGeneration, error, this.t('skillSets'));
        this.toggleModalError();
      });

    // Get all skills
    await axios.project
      .get('skills/count')
      .then((countResponse) => {
        axios.project
          .get(`skills?size=${countResponse?.data}`)
          .then((response) => {
            if (Array.isArray(response.data)) {
              this.setState({
                skills: response.data,
              });
            }
          })
          .catch((error) => {
            this.message = errorHandler(
              dataGeneration,
              error,
              this.t('skills')
            );
            this.toggleModalError();
          });
      })
      .catch((error) => {
        this.message = errorHandler(dataGeneration, error, this.t('skills'));
        this.toggleModalError();
      });

    this.setState({
      fieldForUpdate: null,
      fieldWithChanges: [],
    });
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  // Method that show modalform
  toggleShowModalForm = () => {
    this.setState({
      showModalForm: !this.state.showModalForm,
    });
  };

  toggleModalOk = () => {
    this.setState({ showModalOk: !this.state.showModalOk });
  };

  // Method that will show the error dialog
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  toggleModalDelete = () => {
    this.setState({ showModalDelete: !this.state.showModalDelete });
  };

  // Handles changes when customer is updated
  onResourceProfileUpdate = async () => {
    const user = this.state.user;

    if (user?.firstname === null || user?.firstname === undefined) {
      this.modalTitle = this.t('error');
      this.mainError = this.t('firstLastNameRequired');
      this.errorReason = this.errorResponse = '';
      if (!this.state.showModalError) {
        this.toggleModalError();
      }
      return;
    }

    if (user?.name === null || user?.name === undefined) {
      this.modalTitle = this.t('error');
      this.mainError = this.t('firstLastNameRequired');
      this.errorReason = this.errorResponse = '';
      if (!this.state.showModalError) {
        this.toggleModalError();
      }
      return;
    }

    let validSkillSets = [];
    const skillSets = this.state.skillSets;
    if (skillSets.length > 0) {
      skillSets.forEach((skillSet) => {
        if (skillSet.hasOwnProperty('skill')) {
          validSkillSets.push(skillSet);
        }
      });
    }

    const employeeDetails = this.state.user;

    const savedEmployee = await axios.employee
      .put('employees', employeeDetails)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.message = errorHandler(addRecord, error, this.t('addEmployee'));
        this.toggleModalError();
      });

    await this.saveEmployeeSkillSets(validSkillSets, savedEmployee);

    this.setState({ fieldForUpdate: null, fieldWithChanges: [] });
    this.componentDidMount();
  };

  // Method that handles error
  handleError = (errorMessage, mainError) => {
    this.modalTitle = generateTitle(
      BUTTON_TITLE_ENUM.ERROR.code,
      this.t('error')
    );
    this.mainError = mainError;
    this.errorReason = this.t('serverFailed');
    this.errorResponse = errorMessage;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleUpdateField = (field) => {
    let fieldForUpdate = this.state.fieldForUpdate;
    if (fieldForUpdate !== field) {
      fieldForUpdate = field;
      this.setState({ fieldForUpdate: fieldForUpdate });
    }
  };

  updateFieldChanges = (fieldValue, noChange) => {
    let fieldWithChanges = this.state.fieldWithChanges;
    if (noChange) {
      fieldWithChanges = fieldWithChanges.filter((item) => item !== fieldValue);
    } else {
      if (!fieldWithChanges.includes(fieldValue)) {
        fieldWithChanges.push(fieldValue);
      }
    }
    this.setState({ fieldWithChanges: fieldWithChanges });
  };

  handleFirstNameChange = (event) => {
    let value = event.target.value.trim() ? event.target.value : null;
    let user = this.state.user;
    this.updateFieldChanges(
      employeeFields.FIRST_NAME,
      user?.firstname === value
    );
    user.firstname = value;
    this.setState({ user });
  };

  handleLastNameChange = (event) => {
    let value = event.target.value.trim() ? event.target.value : null;
    let user = this.state.user;
    this.updateFieldChanges(employeeFields.LAST_NAME, user?.lastname === value);
    user.name = value;
    this.setState({ user });
  };

  handleMobilityChange = (mobility) => {
    let user = this.state.user;
    this.updateFieldChanges(employeeFields.MOBILITY, false);
    user.mobility = mobility;
    this.setState({ user });
  };

  // Adding new skillSets
  addSkillSet = () => {
    const newSkillSets = this.state.skillSets;
    const skillSetEntry = {
      skillLevel: SkillLevel[1].code,
    };
    newSkillSets.push(skillSetEntry);
    this.updateFieldChanges(employeeFields.SKILL_SET, false);
    this.setState({ skillSets: newSkillSets });
  };

  // If updating, deletion of skill set will update the backend.
  deleteSkillSet = async (skillSetToDelete, index) => {
    // If the skillSetTo delete if already in the backend
    if (skillSetToDelete?.id) {
      this.modalTitle = this.t('deleteSkillSet');
      this.modalBody = this.t('skillSetConfirmDelete');
      this.toggleModalDelete();

      // Call this event upon selecting the confirm button.
      this.modalEvent = async () => {
        if (skillSetToDelete.id) {
          // Delete skillSet
          await axios.employee
            .delete(`skill-sets/${skillSetToDelete.id}`)
            .catch((error) => {
              let mainError = this.t('deleteRecordFailed');
              this.handleError(error.message, mainError);
            });

          // Update the list of skillSet.
          let newSkillSets = this.state.skillSets.filter(
            (skillSet) => skillSet?.id !== skillSetToDelete.id
          );
          this.setState({ skillSets: newSkillSets });
        }
      };
    }
    // If deletion of not yet saved skillSet
    else {
      const newSkillSets = this.state.skillSets;
      newSkillSets.splice(index, 1);
      this.setState({ skillSets: newSkillSets });
    }
    this.updateFieldChanges(employeeFields.SKILL_SET, false);
  };

  handleSkillSetChange = (skillSet, index) => {
    const newSkillSets = this.state.skillSets;
    newSkillSets[index] = skillSet;
    this.updateFieldChanges(employeeFields.SKILL_SET, false);
    this.setState({ skillSets: newSkillSets });
  };

  showSkillSet = (skillSet) => {
    // <Skill Name> (<Skill Level Name>)
    if (skillSet instanceof Object) {
      const skillName = skillSet.skill?.skill;
      const skillLevelName = SkillLevel[skillSet.skillLevel]?.name;
      const employeeSkillSet = `${skillName} - (${skillLevelName})`;
      return employeeSkillSet;
    } else {
      return null;
    }
  };

  getProjectTitleAndRole = (projectEmployee) => {
    if (projectEmployee instanceof Object) {
      // Get project title
      let projectTitle = projectEmployee?.project?.title;
      let resourceRole = projectEmployee?.projectEmployeeRole?.role;
      return `${projectTitle} - (${resourceRole})`;
    } else {
      return null;
    }
  };

  saveEmployeeSkillSets = async (skillSets, savedEmployee) => {
    let axiosArray = [];
    if (Array.isArray(skillSets)) {
      for (let skillSet of skillSets) {
        const skillSetEntry = {
          id: skillSet.id,
          employee: savedEmployee,
          date: moment(),
          skill: skillSet.skill,
          skillAssignment: skillSet.skillAssignment,
          skillLevel: skillSet?.skillLevel,
        };

        axiosArray.push(axios.employee.save('skill-sets', skillSetEntry));
      }

      const savedEmployeeSkillSets = [];
      if (axiosArray.length > 0) {
        const responses = await Promise.all(axiosArray);
        for (let response of responses) {
          savedEmployeeSkillSets.push(response.data);
        }
      }
      return savedEmployeeSkillSets;
    }
    return [];
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.currentUserIsResponsible !== this.props.currentUserIsResponsible
    ) {
      this.render();
    }
  };

  render() {
    const fieldForUpdate = this.state.fieldForUpdate;
    const fieldWithChanges = this.state.fieldWithChanges;
    const isSaveDisabled =
      Array.isArray(fieldWithChanges) && fieldWithChanges.length > 0
        ? false
        : true;
    const user = this.state.user;
    const currentUserIsAdmin = Auth.isAdmin() ? true : false;

    const currentUserIsResponsible = this.props.currentUserIsResponsible;

    return (
      <Card>
        <CardHeader>
          {this.state?.successMessage !== '' &&
          this.state?.successMessage !== null ? (
            <FadeAlert color="success">{this.state.successMessage}</FadeAlert>
          ) : null}
          {
            <div>
              {currentUserIsResponsible || currentUserIsAdmin ? (
                <div
                  className="card-actions float-end"
                  style={{ paddingLeft: '10px' }}
                >
                  <Button
                    color="primary"
                    onClick={() => this.componentDidMount()}
                    disabled={isSaveDisabled}
                    style={{ paddingRight: '10px' }}
                  >
                    {generateTitle(
                      BUTTON_TITLE_ENUM.UNDO.code,
                      this.t('reset')
                    )}
                  </Button>
                </div>
              ) : null}
              {currentUserIsResponsible || currentUserIsAdmin ? (
                <div
                  className="card-actions float-end"
                  style={{ paddingLeft: '10px' }}
                >
                  <Button
                    color="primary"
                    onClick={() => this.onResourceProfileUpdate()}
                    disabled={isSaveDisabled}
                  >
                    {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, this.t('save'))}
                  </Button>
                </div>
              ) : null}

              {currentUserIsResponsible || currentUserIsAdmin ? (
                <div className="card-actions float-end">
                  <Button
                    color="primary"
                    onClick={() =>
                      this.props.assignEmployee(this.state.employee)
                    }
                  >
                    {generateTitle(
                      BUTTON_TITLE_ENUM.ADD.code,
                      this.t('assignResource')
                    )}
                  </Button>
                </div>
              ) : null}
            </div>
          }
          <CardTitle>
            <h1>{`${this.t('userId')} ${user?.userId}`}</h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="border-end border-light">
              <Table striped className="my-1" style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <th style={{ width: '20%' }} scope="row">
                      {this.t('firstName')}
                    </th>
                    <td
                      onClick={
                        currentUserIsResponsible || currentUserIsAdmin
                          ? () =>
                              this.toggleUpdateField(employeeFields.FIRST_NAME)
                          : undefined
                      }
                      style={{ height: '20%' }}
                    >
                      {fieldForUpdate === employeeFields.FIRST_NAME ? (
                        <Col md={6}>
                          <Input
                            bsSize="lg"
                            type="string"
                            onChange={this.handleFirstNameChange}
                            value={this.state.user?.firstname}
                          />
                        </Col>
                      ) : (
                        this.state.user?.firstname
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{this.t('lastName')}</th>
                    <td
                      onClick={
                        currentUserIsResponsible || currentUserIsAdmin
                          ? () =>
                              this.toggleUpdateField(employeeFields.LAST_NAME)
                          : undefined
                      }
                      style={{ height: '20%' }}
                    >
                      {fieldForUpdate === employeeFields.LAST_NAME ? (
                        <Col md={6}>
                          <Input
                            bsSize="lg"
                            type="string"
                            onChange={this.handleLastNameChange}
                            value={this.state.user?.name}
                          />
                        </Col>
                      ) : (
                        this.state.user?.name
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{this.t('mobility')}</th>
                    <td
                      onClick={
                        currentUserIsResponsible || currentUserIsAdmin
                          ? () =>
                              this.toggleUpdateField(employeeFields.MOBILITY)
                          : undefined
                      }
                    >
                      {fieldForUpdate === employeeFields.MOBILITY ? (
                        <Col md={6}>
                          <Select
                            placeholder={i18n.t('DynamicTable.select')}
                            value={enumValuesToDropdownOptions(
                              Object.values(EmployeeDefence)
                            ).find(
                              (option) =>
                                option.value === this.state.user?.mobility
                            )}
                            options={sortOptionsByValue(
                              enumValuesToDropdownOptions(
                                Object.values(EmployeeDefence)
                              )
                            )}
                            onChange={this.handleMobilityChange}
                          />
                        </Col>
                      ) : i18n.exists(
                          `EmployeeMobility.${this.state.user?.mobility}`
                        ) ? (
                        i18n.t(`EmployeeMobility.${this.state.user?.mobility}`)
                      ) : (
                        this.state.user?.mobility
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{this.t('skillSets')}</th>
                    {fieldForUpdate === employeeFields.SKILL_SET ? (
                      <td>
                        {Array.isArray(this.state.skillSets) &&
                          this.state.skillSets.map(
                            (skillSet, skillSetIndex) => (
                              <SkillSetDropdown
                                onChange={this.handleSkillSetChange}
                                selectedSkillSets={this.state.skillSets}
                                onDeleteSkillSet={this.deleteSkillSet}
                                skillSetIndex={skillSetIndex}
                                skillSets={this.state.skillSets}
                                key={skillSetIndex}
                                skills={this.state.skills}
                                skillSet={skillSet}
                                selectedSkillSet={skillSet}
                              />
                            )
                          )}
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => this.addSkillSet()}
                        >
                          {generateTitle(
                            BUTTON_TITLE_ENUM.ADD.code,
                            this.t('add')
                          )}
                        </Button>
                      </td>
                    ) : (
                      <td
                        onClick={
                          currentUserIsResponsible || currentUserIsAdmin
                            ? () =>
                                this.toggleUpdateField(employeeFields.SKILL_SET)
                            : undefined
                        }
                        style={{ cursor: 'text' }}
                      >
                        {this.state.skillSets?.map(
                          (skillSet, skillSetIndex) => (
                            <Row key={skillSetIndex} style={{ height: '22px' }}>
                              <Col key={skillSetIndex}>
                                <tr>{this.showSkillSet(skillSet)}</tr>
                              </Col>
                            </Row>
                          )
                        )}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <th>{this.t('projectAssignment')}</th>
                    <td>
                      {this.props.projectEmployees?.map(
                        (projectEmployee, projectEmployeeIndex) => (
                          <Row
                            key={projectEmployeeIndex}
                            style={{ height: '22px' }}
                          >
                            <Col key={projectEmployeeIndex}>
                              <Link
                                to={`/projects/overview/project-details/${projectEmployee?.project?.id}`}
                              >
                                {this.getProjectTitleAndRole(projectEmployee)}
                              </Link>
                            </Col>
                          </Row>
                        )
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <ModalDelete
            isOpen={this.state.showModalDelete}
            onClose={this.toggleModalDelete}
            event={this.modalEvent}
            modalTitle={this.modalTitle}
            modalBodyText={this.modalBody}
          ></ModalDelete>
          <ModalError
            isOpen={this.state.showModalError}
            onClose={this.toggleModalError}
            mainError={this.mainError}
            errorReason={this.errorReason}
            errorResponse={this.errorResponse}
            modalTitle={this.modalTitle}
          ></ModalError>
          <ModalOK
            isOpen={this.state.showModalOk}
            onClose={this.toggleModalOk}
            currentPage=""
            modalTitle={this.modalTitle}
            modalBodyText={this.modalBody}
          ></ModalOK>
          <ModalForm
            isOpen={this.state.showModalForm}
            eventOnClose={this.toggleShowModalForm}
            eventOnSubmit={this.onModalFormSubmit}
            modalTitle={this.modalTitle}
            size="xl"
          >
            {this.state.modalForm}
          </ModalForm>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(ResourceProfileCard);
