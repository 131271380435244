import React from 'react';
import Select from 'react-select';

import DetailsCard from './DetailsCard';
import MultipleResponsibleDropdown from '../form/MultipleResponsibleDropdown';
import { IDropdownOption } from '../../utils/types/commonTypes';
import { t } from '../../pages/salesFunnel/ProjectOptionModal/projectOptionHelpers';

interface IProps {
  responsibleList: IDropdownOption<number>[];
  responsible: IDropdownOption<number>;
  involvedResponsibles: {
    responsible: IDropdownOption<number>;
    responsibleRole: IDropdownOption<number>;
  }[];
  setResponsible: (responsible: IDropdownOption<number>) => void;
  setInvolvedResponsibles: (
    responsibles: {
      responsible: IDropdownOption<number>;
      responsibleRole: IDropdownOption<number>;
    }[]
  ) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
}

/**
 * Responsibles Card -
 * Displays responsible and involved responsibles
 */
const ResponsiblesCard = ({
  responsibleList,
  responsible,
  involvedResponsibles,
  setResponsible,
  setInvolvedResponsibles,
  isRequired = false,
  isDisabled = false,
}: IProps) => {
  const responsibleOptions = responsibleList.filter(
    ({ value }) =>
      ![
        responsible,
        ...involvedResponsibles.map(({ responsible }) => responsible),
      ]
        .map((responsible) => (responsible ? responsible.value : null))
        .includes(value)
  );

  const fields = [
    {
      label: t('responsible'),
      value: (
        <Select
          aria-label="responsibles-card-input-responsible"
          value={responsible}
          options={responsibleOptions}
          onChange={setResponsible}
          isDisabled={isDisabled}
        />
      ),
      isRequired,
    },
    {
      label: t('involved'),
      value: (
        <MultipleResponsibleDropdown
          responsibles={involvedResponsibles}
          setResponsibles={setInvolvedResponsibles}
          responsibleOptions={responsibleOptions}
          disabled={isDisabled}
        />
      ),
    },
  ];

  return <DetailsCard title={t('responsibles')} fields={fields} border />;
};

export default ResponsiblesCard;
