import { IObjectNameAndId } from '../../../utils/types/commonTypes';
import { IOfferListItem } from '../../../utils/types/responseTypes';
import { ProjectDetailFormValues } from './ProjectDetailOverview';

// eslint-disable-next-line import/prefer-default-export
export const generateDefaultFormValues = () =>
  ({
    title: '',
    projectId: '',
    customerSite: {} as IObjectNameAndId,
    state: '',
    responsibleContactPersons: [] as IObjectNameAndId[],
    description: '',
    departments: [] as IObjectNameAndId[],
    durationType: '',
    locationType: '',
    projectSharePoint: '',
    offers: [] as IOfferListItem[],
    pendingOffers: [] as IOfferListItem[],
  } as ProjectDetailFormValues);
