import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Table,
} from 'reactstrap';
import InputFormLabel from '../../../components/form/InputFormLabel';
import {
  SHAREPOINT_CREATED,
  styleWidth30Percent,
} from '../../../utils/constants';
import { PROJECT_STATE_ENUM } from '../../../utils/enums/project';
import i18n from '../../../i18n';

/**
 * Shows the ProjectOption description and sharepoint.\
 * Variable Props:\
 *  project\
 * Function Props:\
 *  onChange
 */
class ProjectOptionDescriptionCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.project ?? {},
    };
  }

  t(keyName) {
    return i18n.t('ProjectOptionOverview.' + keyName);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.project !== this.props.project) {
      this.setState({
        project: this.props.project,
      });
    }
  }

  createProjectSharePoint = () => {
    /**
     * TODO: Change implementation once implementation
     * of customer sharepointlink is available
     */
    let newProject = this.state.project;
    newProject.projectSharePoint = SHAREPOINT_CREATED;
    if (this.props.onChange) {
      this.props.onChange(newProject);
    }
    this.setState({
      project: newProject,
    });
  };

  handleDescriptionChange = (e) => {
    let newProject = this.state.project;
    let description = e.target.value.trim() === '' ? null : e.target.value;
    newProject.description = description;
    if (this.props.onChange) {
      this.props.onChange(newProject);
    }
    this.setState({
      project: newProject,
    });
  };

  render() {
    const project = this.state.project;
    return (
      <Card className="small-card">
        <div style={{ textAlign: 'center' }}>
          <CardHeader>
            <h3>{this.t('description')}</h3>
          </CardHeader>
        </div>
        <CardBody>
          <Input
            type="textarea"
            style={{ minHeight: '120px' }}
            value={project?.description ?? null}
            onChange={this.handleDescriptionChange}
            disabled={this.props.lockInputs}
          />
          <br />
          <Table borderless size="sm">
            <tr>
              <th style={{ width: styleWidth30Percent }}>
                <InputFormLabel text={'SharePoint'} />
              </th>
              <td>
                <Label>
                  {project?.projectSharePoint
                    ? project?.projectSharePoint
                    : this.t('none')}
                </Label>
              </td>
            </tr>
            {project?.state === PROJECT_STATE_ENUM.estimating.code ||
            project?.state === PROJECT_STATE_ENUM.aligning.code ? (
              <tr>
                <th style={{ width: styleWidth30Percent }}>
                  <InputFormLabel text={this.t('estimationTemplates')} />
                </th>
                <td>
                  <Label>
                    {project?.estimationTemplatesLink
                      ? project?.estimationTemplatesLink
                      : this.t('none')}
                  </Label>
                </td>
              </tr>
            ) : null}
          </Table>
          {!project?.projectSharePoint && !this.props.lockInputs ? (
            <Button
              color="primary"
              size="sm"
              className="float-end"
              onClick={() => this.createProjectSharePoint()}
              //disabled={!project?.id}
              disabled={true}
            >
              {this.t('createSharePoint')}
            </Button>
          ) : null}
        </CardBody>
      </Card>
    );
  }
}

export default ProjectOptionDescriptionCard;
