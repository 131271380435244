import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SEND_NOTIFICATIONS_VIA_ENUM } from "./utils/enums/service";
import { getNotificationSettingObjectOfCurrentUser } from './utils/helpers/GenericHelper';
import axios from './services/axios/axios';
import i18n from './i18n';
import ModalError from './components/modals/ModalError';
import { SortNotifications } from './NotificationFunctions';
import { loadNotifications } from './redux/notificationsSlice';
const NOTIFICATION_DATA_ERROR = i18n.t(
  'NotificationSetup.notificationDataError'
);
const OUTLOOK_SYNC_ERROR = i18n.t('NotificationSetup.outlookSyncError');

/**
 * Notification loading page.
 *
 * Component used to load in notifications at regular intervals
 *
 */
class NotificationSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.mainError = '';
    this.errorReason = '';
    this.errorResponse = '';
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  errorHandler(operationError, error) {
    this.mainError = operationError;
    this.errorReason = i18n.t('NotificationSetup.serverFailed');
    this.errorResponse = error.message;
    this.toggleModal();
  }

  timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  notificationReload = async (userId) => {
    while (true) {
      await Promise.all([
        this.loadNotifications(userId),
        this.syncOutlookContacts(),
        this.timeout(300000),
      ]);
    }
  };

  loadNotifications(employeeId) {
    let notificationSettingObject = getNotificationSettingObjectOfCurrentUser();

    let sendNotificationViaList =
      notificationSettingObject?.sendNotificationVia;

    // Check if the userSetting of sending a notification via crm tool is enabled
    if (
      !sendNotificationViaList?.includes(SEND_NOTIFICATIONS_VIA_ENUM.crmTool.code)
    ) {
      return;
    }

    // List of services for the retrieval of notification entries
    let selectedFromServiceList =
      notificationSettingObject?.selectedFromServices;

    axios.notification
      .get(
        `notifications?employeeId.equals=${employeeId}&fromService.in=${selectedFromServiceList}`
      )
      .then((response) => {
        let employeeNotifications = response.data;
        this.props.loadNotifications(SortNotifications(employeeNotifications));
      })
      .catch((error) => {
        this.errorHandler(NOTIFICATION_DATA_ERROR, error);
      });
  }

  syncOutlookContacts() {
    axios.sales.post(`change-records/sync-to-outlook`).catch((error) => {
      this.errorHandler(OUTLOOK_SYNC_ERROR, error);
    });
  }

  componentDidMount = () => {
    const userId = this.props.account?.employeeDetails?.id;
    if (userId === undefined) {
      return;
    }
    this.notificationReload(userId);
  };

  render() {
    return (
      <ModalError
        isOpen={this.state.showModal}
        onClose={this.toggleModal}
        mainError={this.mainError}
        errorReason={this.errorReason}
        errorResponse={this.errorResponse}
        modalTitle="Error"
      />
    );
  }
}

const mapStateToProps = (store) => ({
  account: store.account,
});

const mapDispatchToProps = {
  loadNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSetup);
