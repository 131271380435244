import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleRight,
  faPlus,
  faSave,
  faUndo,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Card, CardHeader, CardBody, CardTitle, Button } from 'reactstrap';

import {
  AcquisitionType,
  ProjectState,
} from '../../../utils/enums/project';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import ProjectOptionLostModal from './ProjectOptionLostModal';
import { IProject } from '../../../utils/types/modelTypes';
import { IProjectOptionDetails } from '../../../utils/types/responseTypes';
import { isNextStage, t } from './projectOptionHelpers';
import type { ProjectOptionDetailsForm } from './ProjectOptionModal';

import '../../../components/cards/Card.scss';

interface IProps extends IWithModalsProps {
  project: IProjectOptionDetails;
  isFormValid: boolean;
  formValues: ProjectOptionDetailsForm;
  onCancel: () => void;
  onLost: () => Promise<void>;
  onResetForm: () => void;
  onSave: () => Promise<void>;
}

/**
 * Project Funnel Card in Project Option Modal -
 * Displays current state of project, and handles resetting of form values,
 * saving of project option, and setting project option to lost
 */
const ProjectOptionFunnel = ({
  project: { id: projectId },
  isFormValid,
  formValues,
  formValues: {
    state,
    title,
    customer: { label: customerName },
    contactPersons,
    acquisitionType: { value: acquisitionType },
  },
  onCancel,
  onResetForm,
  onLost,
  onSave,
  modalFormHandler,
  toggleModalForm,
}: IProps) => {
  const saveButtonText = isNextStage(formValues) ? (
    <>
      <FontAwesomeIcon icon={faPlus} />
      <span style={{ marginLeft: '5px' }}>{t('nextStage')}</span>
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faSave} />
      <span style={{ marginLeft: '5px' }}>{t('save')}</span>
    </>
  );

  const stageButtonText = (stage: number) => {
    switch (acquisitionType) {
      case AcquisitionType.PROPOSAL:
        return stage === 3 ? t('proposing') : t('technicalGo');
      case AcquisitionType.SPECIFICATION:
        return stage === 3 ? t('estimating') : t('aligning');
      default:
        return 'TBD';
    }
  };

  const handleProjectOptionLost = () => {
    modalFormHandler(
      t('projectOptionLost'),
      <ProjectOptionLostModal
        closeLostProjectModal={async () => {
          await onLost();
          toggleModalForm();
        }}
        toggleModalExternal={toggleModalForm}
        customerDetailToShow={{
          customerName,
          mainContact: contactPersons.map(({ contactPerson }) =>
            contactPerson ? contactPerson.label : ''
          ),
          terminatedAfter: state,
        }}
        project={{ id: projectId, title, state } as IProject}
      />,
      'xl'
    );
  };

  return (
    <Card className="common-card with-border" style={{ padding: '7px 0' }}>
      <CardHeader>
        {state !== ProjectState.LOST && state !== ProjectState.ORDERED && (
          <div className="float-end header-buttons">
            <Button
              aria-label="project-option-funnel-button-reset"
              color="primary"
              onClick={() => onResetForm()}
            >
              <FontAwesomeIcon icon={faUndo} />
              <span style={{ marginLeft: '5px' }}>{t('reset')}</span>
            </Button>
            <Button
              aria-label="project-option-funnel-button-save"
              color="primary"
              onClick={() => onSave()}
              disabled={!isFormValid}
            >
              {saveButtonText}
            </Button>
            <Button
              aria-label="project-option-funnel-button-lost"
              color="primary"
              onClick={handleProjectOptionLost}
              disabled={!projectId}
            >
              <FontAwesomeIcon icon={faWindowClose} />
              <span style={{ marginLeft: '5px' }}>{t('optionLost')}</span>
            </Button>
            <Button
              aria-label="project-option-funnel-button-cancel"
              color="primary"
              onClick={() => onCancel()}
            >
              {t('cancel')}
            </Button>
          </div>
        )}
        <CardTitle className="title-left">
          <h3>{t('projectFunnel')}</h3>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          {state !== ProjectState.LOST && state !== ProjectState.ORDERED && (
            <>
              <span>{t('activityDateWarning')}</span>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '3px' }}
              >
                <Button
                  aria-label="project-option-funnel-button-stage-1"
                  color="primary"
                  size="sm"
                  disabled={!projectId || state !== ProjectState.VAGUE}
                >
                  {t('vague')}
                </Button>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
                <Button
                  aria-label="project-option-funnel-button-stage-2"
                  color="primary"
                  size="sm"
                  disabled={state !== ProjectState.DEFINED}
                >
                  {t('defined')}
                </Button>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
                <Button
                  aria-label="project-option-funnel-button-stage-3"
                  color="primary"
                  size="sm"
                  disabled={
                    state !== ProjectState.PROPOSAL_AHEAD &&
                    state !== ProjectState.ESTIMATING
                  }
                >
                  {stageButtonText(3)}
                </Button>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
                <Button
                  aria-label="project-option-funnel-button-stage-4"
                  color="primary"
                  size="sm"
                  disabled={
                    state !== ProjectState.PROPOSAL_TECHNICAL_GO &&
                    state !== ProjectState.ALIGNING
                  }
                >
                  {stageButtonText(4)}
                </Button>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
                <Button
                  aria-label="project-option-funnel-button-stage-5"
                  color="primary"
                  size="sm"
                  disabled={state !== ProjectState.OFFER_SENT}
                >
                  {t('offered')}
                </Button>
              </div>
            </>
          )}
          {state === ProjectState.LOST && (
            <Button
              aria-label="project-option-funnel-button-lost-text"
              className="danger-button"
            >
              <span className="danger-text">{t('projectLostDanger')}</span>
            </Button>
          )}
          {state === ProjectState.ORDERED && (
            <Button
              aria-label="project-option-funnel-button-ordered"
              className="warning-button"
            >
              <span className="warning-text">
                {t('projectHasAnOrderedOffer')}
              </span>
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default withModals(ProjectOptionFunnel);
