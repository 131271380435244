import moment from 'moment';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';

import CollapsibleTableCard from '../../../components/collapsibleCards/CollapsibleTableCard';
import { getDateFormat } from '../../../utils/helpers/date';
import i18n from '../../../i18n';
import { CustomerDetailsContext } from './CustomerDetailsContextProvider';

export const t = (keyName: string) => i18n.t(`ProjectsProjectList.${keyName}`);

/**
 * Currently only supports customer details
 */
const CollapsibleProjectHistoryCard = () => {
  const {
    customerDetails: { projectHistory },
  } = useContext(CustomerDetailsContext);

  const tableData = projectHistory.map(
    ({ title, state, startDate, endDate, projectEmployees }) => ({
      [t('projectTitle')]: title,
      [t('status')]: i18n.exists(`ProjectStates.${state}`)
        ? i18n.t(`ProjectStates.${state}`)
        : state,
      Type: i18n.exists(`ProjectStates.${state}`)
        ? i18n.t(`ProjectStates.${state}`)
        : state,
      [t('projectStartProjectEnd')]: `${moment(startDate).format(
        getDateFormat()
      )} - ${moment(endDate).format(getDateFormat())}`,
      [t('projectMember')]: (
        <div>
          {projectEmployees.map(({ id, name }) => (
            <Row key={id}>
              <Link to={`/employees/employee-list/employee-detail/${id}`}>
                {`${name}`}
              </Link>
            </Row>
          ))}
        </div>
      ),
    })
  );

  return (
    <CollapsibleTableCard
      title={i18n.t('ProjectHistoryCard.project history')}
      tableData={tableData}
      noDataPlaceholder={i18n.t('ProjectHistoryCard.noProjectFound')}
      striped
    />
  );
};

export default CollapsibleProjectHistoryCard;
