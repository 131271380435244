import React from 'react';
import Select from 'react-select';
import axios from '../../services/axios/axios';
import { sortByPropValue } from '../../utils/helpers/GenericHelper';
import i18n from '../../i18n';
import ModalError from '../modals/ModalError';

/**
 * @deprecated will be removed in the future
 * Dropdown that has all customers as options.\
 * props: update, customerId, onChange, lockedCustomer/readOnly(optional)\
 *  isClearable
 */
class CustomerDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      showModalError: false,
    };

    this.mainError = '';
    this.errorReason = '';
    this.errorResponse = '';
  }

  t(keyName) {
    return i18n.t('CustomerDropdown.' + keyName);
  }

  ToggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  componentDidMount() {
    axios.sales
      .get('customers/count')
      .then((countResponse) => {
        axios.sales
          .get(`customers?size=${countResponse.data}&sort=name%2Casc`)
          .then((response) => {
            if (Array.isArray(response.data)) {
              let customers = response?.data;
              this.setState({ customers: customers });
            }
          })
          .catch((error) => {
            this.errorHandler('Data Generation', error);
          });
      })
      .catch((error) => {
        this.errorHandler('Data Generation', error);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.render();
    }
    if (prevProps.update !== this.props.update) {
      this.componentDidMount();
    }
  }

  errorHandler = (currentOperation, error) => {
    if (currentOperation === 'Data Generation') {
      this.mainError = this.t('customerDataGenerationFailed');
    }
    this.errorReason = this.t('serverFailed');
    this.errorResponse = error.message;
    if (!this.state.showModalError) {
      this.ToggleModalError();
    }
  };

  onChangeHandler = (event) => {
    if (event === null) {
      this.props.onChange(null);
    } else {
      let customer = this.state.customers.find(
        (data) => data.id === parseInt(event.value)
      );
      if (customer) {
        this.props.onChange(customer);
      }
    }
  };

  render() {
    return (
      <div style={{ overflow: 'visible' }}>
        <Select
          value={
            this.props.customerId
              ? {
                  value: this.props.customerId,
                  label: this.state.customers?.find(
                    (dataEntry) => dataEntry.id === this.props.customerId
                  )?.name,
                }
              : null
          }
          options={sortByPropValue(
            this.state.customers?.map((customer) => ({
              value: customer.id,
              label: customer.name,
            })),
            'label'
          )}
          isMulti={false}
          {...this.props}
          placeholder={this.t('selectCustomer')}
          isDisabled={
            this.props.lockedCustomer || this.props.readOnly ? true : false
          }
          onChange={this.onChangeHandler}
          isClearable={this.props.isClearable ? true : false}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.ToggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle={this.t('error')}
        ></ModalError>
      </div>
    );
  }
}

export default CustomerDropdown;
