import { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { clickHandler, modalCloseButton } from '../../utils/helpers/click';
import i18n from '../../i18n';

/**
 * ModalOK contains commands that would enable a modal dialog for miscellaneous dialog
 * to appear, which only requests an "OK" input from the user as confirmation
 * @Author Reimon Tito
 */
const ModalOK = (props) => {
  const { modalTitle, modalBodyText, isOpen, onClose } = props;

  const [modal, setModal] = useState(isOpen);

  const toggle = () => {
    onClose && onClose();
    setModal(!modal);
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  var AcceptButton = (
    <Button color="dark" onClick={(event) => clickHandler(event, toggle)}>
      {i18n.t('ModalOK.ok')}
    </Button>
  );

  return (
    <Draggable>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader close={modalCloseButton(toggle)}>{modalTitle}</ModalHeader>
        <ModalBody>{modalBodyText}</ModalBody>
        <ModalFooter>{AcceptButton}</ModalFooter>
      </Modal>
    </Draggable>
  );
};

export default ModalOK;
