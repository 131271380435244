import React, { ChangeEvent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import DynamicTable from '../../../components/tables/DynamicTable';
import {
  CustomerState,
  TargetPriority,
} from '../../../utils/enums/customer';
import { SortBy, SortType } from '../../../utils/enums/sort';
import { isEmpty } from '../../../utils/helpers/array';
import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';
import { getNextSortType } from '../../../utils/helpers/table';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import useMounted from '../../../hooks/useMounted';
import {
  fetchCustomerInvolvedResponsibles,
  fetchCustomerListItems,
  fetchCustomerResponsibles,
  fetchCustomerAccountListItems,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  setHasMoreListItems,
} from '../../../redux/customerListSlice';
import { RootState } from '../../../redux/store';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { ICustomerListItem } from '../../../utils/types/responseTypes';
import { createCustomerEntry, t } from './customerListHelpers';
import { DEFAULT_LOAD_TIMEOUT } from '../../../utils/constants';

const customerStates = enumValuesToDropdownOptions(
  Object.values(CustomerState)
);
const targetPriorities = enumValuesToDropdownOptions(
  Object.values(TargetPriority)
);

interface IProps extends IWithModalsProps, PropsFromRedux {
  mergeDuplicatesMode: boolean;
  selectedCustomerIds: { id: number }[];
  setSelectedCustomerIds: (selectedCustomerIds: { id: number }[]) => void;
  onDelete: (customerId: number) => void;
}

/**
 * Displays the list of customers in a table
 */
const CustomerListTable = ({
  mergeDuplicatesMode,
  selectedCustomerIds,
  setSelectedCustomerIds,
  onDelete,
  // WithModals
  modalErrorHandler,
  // Redux State
  listItems,
  customerAccounts,
  responsibles,
  involvedResponsibles,
  filters,
  showFilters,
  hasMore,
  sortBy,
  sortType,
  // Redux Actions
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  setHasMoreListItems,
  // Thunks
  fetchCustomerListItems,
  fetchCustomerAccountListItems,
  fetchCustomerResponsibles,
  fetchCustomerInvolvedResponsibles,
}: IProps) => {
  // Check for component mount
  const isMounted = useMounted();

  // Prepare the data to be displayed on the table
  const preparedCustomerListItems = listItems.map(
    (customer: ICustomerListItem) =>
      createCustomerEntry(customer, () => {
        onDelete(customer.id);
      })
  );

  /**
   * Handles the fetching of customer list items
   * @param isSortOrFilterFetchType Whether the data fetching is for sorting / filtering / scrolling
   */
  const fetchCustomerList = (isSortOrFilterFetchType: boolean) => {
    fetchCustomerListItems(isSortOrFilterFetchType, (error) => {
      modalErrorHandler(t('failedToRetrieveCustomers'), error);
    });
  };

  /**
   * Fetch customer list, customer account list items, customer responsibles,
   * customer involved responsibles on component mount
   */
  useEffect(() => {
    if (isEmpty(listItems)) {
      fetchCustomerList(false);
    }

    if (isEmpty(customerAccounts)) {
      fetchCustomerAccountListItems((error) => {
        modalErrorHandler(t('failedToRetrieveCustomerAccounts'), error);
      });
    }

    if (isEmpty(responsibles)) {
      fetchCustomerResponsibles((error) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });
    }

    if (isEmpty(involvedResponsibles)) {
      fetchCustomerInvolvedResponsibles((error) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });
    }
  }, []);

  // Fetch customer list on sort update
  useEffect(() => {
    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);
      fetchCustomerList(true);
    }
  }, [sortBy, sortType]);

  // Fetch customer list on filter update
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);

      timer = setTimeout(() => {
        fetchCustomerList(true);
      }, DEFAULT_LOAD_TIMEOUT);
    }

    return () => clearTimeout(timer);
  }, [filters]);

  const handleInputFilterChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: event.target.value ?? '',
    });
  };

  const handleDropdownFilterChange = (
    filterValue: IDropdownOption[],
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: filterValue,
    });
  };

  /**
   * Handles table sort function
   * @param nextSortBy
   */
  const handleSort = (nextSortBy: SortBy) => {
    const nextSortType = getNextSortType(sortType, nextSortBy, sortBy);

    setSortBy(nextSortType === SortType.DEFAULT ? SortBy.NAME : nextSortBy);
    setSortType(
      nextSortType === SortType.DEFAULT ? SortType.ASCENDING : nextSortType
    );
  };

  const tableColumns = [
    {
      type: 'checkbox',
      header: '',
      accessor: 'checkbox',
      show: mergeDuplicatesMode ? 'true' : 'false',
    },
    {
      type: 'data',
      header: t('customer'),
      accessor: 'name',
      show: 'true',
      sortFunc: handleSort,
      filterFunc: handleInputFilterChange,
      filterComponentWidth: '150px',
      width: '170px',
    },
    {
      type: 'data',
      header: t('targetPriority'),
      accessor: 'targetPriority',
      show: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: targetPriorities,
      filterComponentWidth: '180px',
      width: '150px',
      filterValue: filters.targetPriority,
    },
    {
      type: 'data',
      header: t('status'),
      accessor: 'state',
      show: 'true',
      sortFunc: handleSort,
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: customerStates,
      filterComponentWidth: '180px',
      width: '150px',
      filterValue: filters.state,
    },
    {
      type: 'component',
      header: t('industry'),
      accessor: 'sectors',
      show: 'true',
      filterFunc: handleInputFilterChange,
    },
    {
      type: 'component',
      header: t('customerSites'),
      accessor: 'customerSites',
      show: 'true',
      filterFunc: handleInputFilterChange,
    },
    {
      type: 'data',
      header: t('account'),
      accessor: 'customerAccount',
      show: 'true',
      sortFunc: handleSort,
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: customerAccounts,
      filterComponentWidth: '150px',
      width: '170px',
      filterValue: filters.customerAccount,
    },
    {
      type: 'data',
      header: t('responsibles'),
      accessor: 'responsible',
      show: 'true',
      alignleft: 'true',
      showsearch: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: responsibles,
      filterComponentWidth: '180px',
      width: '200px',
      filterValue: filters.responsible,
    },
    {
      type: 'component',
      header: t('involved'),
      accessor: 'involvedResponsibles',
      show: 'true',
      alignleft: 'true',
      showsearch: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: involvedResponsibles,
      filterComponentWidth: '180px',
      width: '200px',
      filterValue: filters.involvedResponsibles,
    },
    !mergeDuplicatesMode
      ? {
          type: 'Dropdown',
          header: t('menu'),
          show: 'true',
          width: '110px',
          accessor: 'menuComponent',
        }
      : {},
  ];

  return (
    <DynamicTable
      data={preparedCustomerListItems}
      columns={tableColumns}
      fetchData={fetchCustomerList}
      hasMoreData={hasMore}
      infiniteScroll
      selectedItems={selectedCustomerIds}
      setSelectedItems={setSelectedCustomerIds}
      sortType={sortType}
      sortBy={sortBy}
      enableFilterFunction
      showFilters={showFilters}
    />
  );
};

const mapStateToProps = (store: RootState) => ({
  listItems: store.customerList.listItems,
  customerAccounts: store.customerList.additionalState?.customerAccountsList,
  responsibles: store.customerList.additionalState?.responsibles,
  involvedResponsibles:
    store.customerList.additionalState?.involvedResponsibles,
  filters: store.customerList.filters,
  showFilters: store.customerList.showFilters,
  hasMore: store.customerList.hasMore,
  sortBy: store.customerList.sortBy,
  sortType: store.customerList.sortType,
});

const mapDispatchToProps = {
  fetchCustomerListItems,
  fetchCustomerAccountListItems,
  fetchCustomerResponsibles,
  fetchCustomerInvolvedResponsibles,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  setHasMoreListItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(CustomerListTable));
