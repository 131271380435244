import Auth from '../../../services/axios/Auth';
import { EntityType } from '../../../utils/enums/pageComponents';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { READWRITE } from '../../../utils/constants';
import { isEmpty } from '../../../utils/helpers/array';
import { createLinkIfAuthorised } from '../../../utils/helpers/permission';
import i18n from '../../../i18n';
import { IResponsible } from '../../../utils/types/modelTypes';
import { ICustomerAccountListItem } from '../../../utils/types/responseTypes';
import { ICustomerAccountFilter } from '../../../utils/types/stateTypes';

export interface ISort {
  sortType: string | null;
  sortBy: string | null;
}

export const translateAccountList = (keyName: string) =>
  i18n.t(`CustomerAccountList.${keyName}`);
export const translateAddOrUpdate = (keyName: string) =>
  i18n.t(`AddOrUpdateCustomerAccount.${keyName}`);
export const translateCustomerAccountFormInput = (keyName: string) =>
  i18n.t(`CustomerAccountFormInput.${keyName}`);

/**
 * Return responsibles for an objectId and objectType
 * @param {*} allResponsibles
 * @param {*} objectId
 * @param {*} objectType
 */
export function getObjectResponsibles(
  allResponsibles: IResponsible[],
  objectId: number | undefined,
  objectType: string
) {
  if (!isEmpty(allResponsibles) && objectId && objectType) {
    const filteredResponsibles = allResponsibles.filter(
      (responsible) =>
        responsible.objectId === objectId &&
        responsible.objectType === objectType
    );
    if (!isEmpty(filteredResponsibles)) {
      return filteredResponsibles;
    }
  }
  return [];
}

/**
 * Creates the query parameters to be used to fetch data
 * @returns Object containing query parameters
 */
export const createQueryParameters = (
  page: number,
  { name, responsible }: ICustomerAccountFilter,
  sortBy: string,
  sortType: string
) => ({
  page: page.toString(),
  ...(name && { 'name.contains': name }),
  ...(responsible && { 'responsibles.contains': responsible }),
  ...(sortBy && sortType && { sort: `${sortBy}%2C${sortType}` }),
});

export function hasCreatePermissionCustomerAccount() {
  return Auth.hasPermission(
    [PERMISSION_URI.customerAccountList.readWrite.uri],
    [READWRITE]
  );
}

/**
 * Creates the preparedCustomerAccount entries to be shown in the DynamicTable
 * @param {*} extendedCustomerAccount
 * @returns
 */
export const createEntryCustomerAccount = (
  {
    id,
    name,
    responsible: { id: responsibleId, name: responsibleName },
  }: ICustomerAccountListItem,
  generateButton: (number: number) => void
) => {
  const entry = {
    name: id
      ? createLinkIfAuthorised(EntityType.CUSTOMER_ACCOUNT, name, id)
      : null,
    responsible: responsibleId
      ? createLinkIfAuthorised(
          EntityType.EMPLOYEE,
          responsibleName,
          responsibleId
        )
      : '',
    menuComponent: Auth.hasPermission(
      [PERMISSION_URI.customerAccount.readWrite.uri],
      [READWRITE],
      id
    )
      ? generateButton(id)
      : null,
  };
  return entry;
};
