import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';

import { IOfferDetails } from '../../../utils/types/responseTypes';
import { getOfferDetails } from '../../../services/api/offer';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { generateTitle } from '../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import TableCard from '../../../components/cards/TableCard';
import { generateOfferTitle } from '../OfferModal/offerHelper';
import { t } from './projectDetailHelper';
import { ProjectDetailFormValues } from './ProjectDetailOverview';

interface IProps extends IWithModalsProps {
  projectFormValues: ProjectDetailFormValues;
  toggleOfferModal: (modaltitle: string, offer?: IOfferDetails) => void;
}

const ProjectPendingOrders = ({
  projectFormValues: { pendingOffers },
  toggleOfferModal,
  modalErrorHandler,
}: IProps) => {
  const handleOfferModal = (offerId: number) => {
    getOfferDetails(offerId)
      .then(({ data: response }) =>
        toggleOfferModal(
          generateOfferTitle(response.title, response.offerVersion),
          response
        )
      )
      .catch((error: AxiosError) => modalErrorHandler(error.message));
  };

  const handleAddOfferModal = () => {
    toggleOfferModal(t('addOffer'));
  };

  const headerButtons = (
    <Button onClick={() => handleAddOfferModal()} color="primary">
      {generateTitle(BUTTON_TITLE_ENUM.ADD.code, t('addNewOffer'))}
    </Button>
  );

  const tableData = pendingOffers.map(
    ({ id, title, offerVersion, budget, currency, offerState }) => ({
      [t('offerName')]: (
        <Link
          to="#"
          className="wordWrap-class"
          onClick={() => {
            handleOfferModal(id);
          }}
        >
          {title}
        </Link>
      ),
      [t('offerLastVersion')]: `v${offerVersion}`,
      [t('budget')]: `${budget} ${currency}`,
      [t('state')]: `${offerState}`,
    })
  );

  return (
    <TableCard
      headerButtons={headerButtons}
      tableData={tableData}
      title={t('projectPendingOffers')}
      noDataPlaceholder={t('noPendingOffers')}
      leftTitle
      border
      striped
    />
  );
};

export default withModals(ProjectPendingOrders);
