import React, { ChangeEvent } from 'react';
import Select from 'react-select';
import { Button, Input } from 'reactstrap';

import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';
import { ContactInfoType } from '../../../utils/enums/contact';
import {
  IDropdownOption,
  IObjectContactInformation,
} from '../../../utils/types/commonTypes';
import { generateTitle } from '../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { getCorrectContactNumberInput } from '../../../utils/helpers/string';

const contactInformationTypes = enumValuesToDropdownOptions(
  Object.values(ContactInfoType)
);

interface IProps {
  onChange: (
    contactInformation: IObjectContactInformation,
    index: number
  ) => void;
  index: number;
  contactInformation: IObjectContactInformation;
  onDelete: (
    contactInformation: IObjectContactInformation,
    index: number
  ) => void;
}

const GeneralInfoFormRow = ({
  onChange,
  onDelete,
  index,
  contactInformation,
}: IProps) => {
  const { id, info, type, status } = contactInformation;

  const handleInfoChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (value.length <= 255) {
      onChange(
        {
          id,
          info:
            type === ContactInfoType.PHONE || type === ContactInfoType.MOBILE
              ? getCorrectContactNumberInput(
                  contactInformation.info?.replace(/[^\d\s]/g, ''),
                  value
                )
              : value,
          type,
          status,
        },
        index
      );
    }
  };

  const handleContactInformationTypeChange = ({ value }: IDropdownOption) => {
    onChange(
      {
        id,
        info,
        type: value,
        status,
      },
      index
    );
  };

  return (
    <tr>
      <td style={{ border: 0, paddingTop: '3px', paddingLeft: '0px' }}>
        <Input
          bsSize="lg"
          onChange={handleInfoChange}
          value={info}
          data-testid="contactinformation-input"
        />
      </td>
      <td style={{ border: 0, paddingTop: '0px' }}>
        <Select
          isRequired
          options={contactInformationTypes}
          value={contactInformationTypes.find(({ value }) => value === type)}
          onChange={handleContactInformationTypeChange}
          placeholder="Select Type"
          aria-label="general-info-form-row-select"
        />
      </td>
      <td style={{ border: 0, width: '10%' }}>
        <Button
          color="link"
          onClick={() => onDelete(contactInformation, index)}
          data-testid="general-info-form-row-button-delete"
        >
          {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
        </Button>
      </td>
    </tr>
  );
};

export default GeneralInfoFormRow;
