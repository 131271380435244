import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  Button,
  Container,
} from 'reactstrap';

import InputFormLabel from './InputFormLabel';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import { IObjectCustomer } from '../../utils/types/commonTypes';
import { ICustomerIdAndAbbreviation } from '../../utils/types/modelTypes';
import i18n from '../../i18n';
import {
  isValidCustomerAbbreviation,
  updateCustomerAbbreviation,
} from '../../services/api/customer';
import {
  DEFAULT_LOAD_TIMEOUT,
  OK,
  REGEX_VALID_CUSTOMER_ABBREVIATION,
} from '../../utils/constants';

interface IProps extends IWithModalsProps {
  customer: IObjectCustomer;
  onFinish: (newCustomer: ICustomerIdAndAbbreviation) => void;
}

const t = (keyName: string) => i18n.t(`CustomerAbbreviationCheck.${keyName}`);

/*
 * Component that will enable the user to enter a customer abbreviation.
 * The input is validated before the user is able to submit it.
 * -customer: obtain details about the customer to check and update the back end with
 * -onFinish: returns the updated customer as the first parameter
 */
const CustomerAbbreviationInput = ({
  customer,
  onFinish,
  modalErrorHandler,
}: IProps) => {
  const { name, id } = customer;
  const [isValid, setIsValid] = useState(false);
  const [errorReason, setErrorReason] = useState('exactlyFiveLetters');
  const [abbreviationInput, setAbbreviationInput] = useState('');
  const [isChecking, setIsChecking] = useState(false);

  /**
   * Gives the customer an abbreviation and updates the customer
   * @returns
   */
  const handleSubmit = async () => {
    try {
      const updatedCustomer = {
        id,
        abbreviation: abbreviationInput,
      };
      const { data: savedCustomer } = await updateCustomerAbbreviation(
        updatedCustomer
      );
      onFinish(savedCustomer);
    } catch (error) {
      modalErrorHandler(t('failedToSaveAbbreviation'), error);
    }
  };

  useEffect(() => {
    if (abbreviationInput.match(REGEX_VALID_CUSTOMER_ABBREVIATION)) {
      setIsChecking(true);
    }

    // Perform checks and added timeout to ensure it does not call the API on every keystroke
    const timer = setTimeout(() => {
      if (abbreviationInput.match(REGEX_VALID_CUSTOMER_ABBREVIATION)) {
        isValidCustomerAbbreviation({
          abbreviation: abbreviationInput,
        })
          .then(({ data }) => {
            setIsValid(data === OK);
            setIsChecking(false);
            setErrorReason(data !== OK ? data : 'inUseOrEmpty');
          })
          .catch((error) => {
            modalErrorHandler(t('error'), error);
          });
      } else {
        setErrorReason('exactlyFiveLetters');
        setIsValid(false);
        setIsChecking(false);
      }
    }, DEFAULT_LOAD_TIMEOUT);

    return () => clearTimeout(timer);
  }, [abbreviationInput]);

  return (
    <Container fluid>
      <Card>
        <CardHeader>
          <CardTitle>
            <h1>
              {t('introText')} - {name}
            </h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <InputFormLabel isRequired text={t('Abbreviation')} />
          <br />
          {isChecking ? (
            <>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="margin-right text-muted"
              />
              {` ${t('checkingAbbreviation')}`}
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={isValid ? faCheckCircle : faTimesCircle}
                className={`margin-right ${
                  isValid ? 'text-success' : 'text-danger'
                }`}
              />
              {` ${t(isValid ? 'isValid' : errorReason)}`}
            </>
          )}

          <br />
          <Input
            aria-label="abbreviation-input"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setAbbreviationInput(event.target.value.toUpperCase())
            }
            placeholder={t('Abbreviation')}
            value={abbreviationInput}
            maxLength="5"
          />
          <Button
            color="primary"
            className="float-end"
            onClick={() => handleSubmit()}
            disabled={!isValid}
          >
            {t('submit')}
          </Button>
        </CardBody>
      </Card>
    </Container>
  );
};

export default withModals(CustomerAbbreviationInput);
