import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Container,
  Input,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import Select from 'react-select';

import { enumToDropdownOptions } from '../../../utils/helpers/GenericHelper';
import axios from '../../../services/axios/axios';
import { REJECTION_CATEGORIES } from '../../../utils/enums/project';
import InputFormLabel from '../../../components/form/InputFormLabel';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IRejectionItem, IRejectionType } from '../../../utils/types/modelTypes';
import { IDropdownOption } from '../../../utils/types/commonTypes';

interface IProps extends IWithModalsProps {
  rejectionType: IRejectionType | null;
  rejectionItems: IRejectionItem[] | undefined;
  rejectionDidUpdate: boolean;
  onSave: (rejectionType: IRejectionType) => void;
  onCancel: () => void;
}
const RejectionTypeInput: React.FC<IProps> = ({
  rejectionType,
  rejectionItems,
  onSave,
  modalOkHandler,
  modalErrorHandler,
  onCancel,
}) => {
  const t = (keyName: string) => i18n.t('RejectionTypeSettings.' + keyName);

  const [name, setName] = useState(rejectionType ? rejectionType.name : '');

  const [description, setDescription] = useState(
    rejectionType ? rejectionType.description : ''
  );

  const [category, setCategory] = useState(
    rejectionType ? rejectionType.category : 'INV'
  );

  const submitEvent = async () => {
    if (name.length === 0) {
      modalOkHandler(t('warning'), t('rejectionNameRequired'));
      return;
    }

    const payload = {
      id: rejectionType ? rejectionType.id : null,
      name: name.trim(),
      description: description ? description.trim() : undefined,
      category,
    };

    await axios.employee
      .save('rejection-types', payload)
      .then((response: AxiosResponse<IRejectionType>) => {
        onSave(response.data);
        onCancel();
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedtoSaveRejectionType'), error);
      });
  };

  const translateRejectionItem = (textJSON: string | undefined) => {
    const currentLng = localStorage.getItem('i18nextLng');
    if (textJSON && currentLng) {
      const data = JSON.parse(textJSON);
      return data[currentLng.toUpperCase()];
    }
  };

  const rejectionCategoriesOptions: IDropdownOption[] =
    enumToDropdownOptions(REJECTION_CATEGORIES);

  return (
    <Container fluid>
      <Card>
        <CardBody>
          <InputFormLabel isRequired text={t('name')} />
          <Input
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
            value={name}
          />
          <br />
          <InputFormLabel isRequired={false} text={t('description')} />
          <Input
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(event.target.value);
            }}
            value={description}
          />
          <br />
          <InputFormLabel isRequired text={t('category')} />
          <Select
            onChange={(event: any) => setCategory(event.value)}
            options={rejectionCategoriesOptions}
            value={rejectionCategoriesOptions.find(
              (newCategory) => category === newCategory.value
            )}
            placeholder={t('selectCategory')}
          />
          <br />
          <Button
            color="primary"
            size="m"
            className="float-end"
            onClick={submitEvent}
            disabled={name.trim() === ''}
          >
            {t('save')}
          </Button>
        </CardBody>
      </Card>
      {rejectionItems && rejectionItems.length > 0 && (
        <Card>
          <CardBody>
            <Button color="outline" id="linkedRejectionItems">
              <FontAwesomeIcon icon={faCaretDown} />
            </Button>
            <InputFormLabel
              isRequired={false}
              text={t('linkedRejectionItems')}
            />
            <UncontrolledCollapse toggler="#linkedRejectionItems">
              <td className="col-md-2">
                {rejectionItems.map((item, index) => (
                  <Row key={index}>{translateRejectionItem(item.textJSON)}</Row>
                ))}
              </td>
            </UncontrolledCollapse>
          </CardBody>
        </Card>
      )}
    </Container>
  );
};

export default withModals(RejectionTypeInput);
