import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { default as React } from 'react';
import { MoreVertical } from 'react-feather';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { default as Select, components } from 'react-select';
import TableFilter from 'react-table-filter';
import {} from 'react-table-filter/lib/styles.css';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import { Ascending, RECORDS_PER_PAGE } from '../../utils/constants';
import {
  getDateFormat,
  isEmpty,
  sortByPropValue,
} from '../../utils/helpers/GenericHelper';
import i18n from '../../i18n';

/**
 * this Table will Take any Data
 * provide the Data and the Props as descriped in the State
 */

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

class DynamicTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /* Tabledata
      data: [
        {
          key: value
          ...
        },
        ...
      ] 
      
      */
      data: props.data,
      // The internal Data to Display filtered Table Data
      dataLatestState: props.data,
      // Determins the Title of the Table if set
      tableTitle: props.tableTitle,
      /*Enter the Columns in the following way with there Settings
        [
            {
                //You can Ignore everything that isnt Required if you dont want to use it
                type:                   /Type: String               /Default:-       /Required: true    /Type of Column data/dropdown/component
                header:                 /Type: String               /Default:accessor/Required: false   /Header Title
                accessor:               /Type: String               /Default:-       /Required: true    /Key to access the Data/only Required if not type Dropdown
                show: true,             /Type; String               /Default:"false" /Required: false   /Determines if the Selected Column will be shown in the Table 
                filterkey:              /Type: String               /Default:-       /Required: false   /Key by which the Column should be filtered(Key as present in single Item)
                itemdisplayvaluefunc:   /Type: function(itemValue)  /Default:-       /Required: false   /Optional Function that returns the Value that is displayed in the filter list for each item(Default is the item value - Item[key])
                itemsortvaluefunc:      /Type: function(itemValue)  /Default:-       /Required: false   /Optional Function that returns the Value that is used while sorting (Default is the item value - Item[key])
                alignleft:              /Type: String               /Default:"false" /Required: false   /Decides while side filter list should be aligned w.r.t Column. Allowed "true"/"false"
                casesensitive:          /Type: String               /Default:"false" /Required: false   /Case Sensitivity during sort. Allowed "true"/"false"
                showsearch:             /Type: String               /Default:"false" /Required: false   /Display/Hide the search input. Allowed "true"/"false"
                link:                   /Type: String               /Default:-       /Required: false   /The base Link String
                linkAccessor:           /Type: Sting                /Default:-       /Required: false   /The link additon this will be addet behind the link if exsisting
                addionalInfoAccessor:   /Type: Sting                /Default:-       /Required: false   /Key to access an additional info in one column of the data. Data must be an array
                tableSize               /Type: Sting                /Default:-       /Required: false   /Changes the size of the table
                paginationSize          /Type: Sting                /Default:"lg"    /Required: false   /Changes the size of the Pagination component
                dontShowDataPerPageOption /Type:boolean             /Default:-       /Required: false   /If exist in props, the table will not show the option for changing records per page
                linkFunc                /Type: function             /Default:-       /Required: false   /Optional function that will be called on clicking a Link
                linkFuncParameter       /Type: String               /Default:-       /Required: false   /The accessor for the parameter linkFunc 
                dataAlign               /Type: String               /Default:"left"  /Required: false   /Decides whether the Data of a column will be aligned left, right, center
                headerAlign             /Type: String               /Default:"left"  /Required: false   /Decides whether the Header of a column will be aligned left, right, center
              },
            ...
        ]
      */
      columns: props.columns,
      deleteAction: props.deleteAction,
      addAction: props.addAction,
      viewDetailsAction: props.viewDetailsAction,
      updateAction: props.updateAction,
      dropdown: props.dropdown,
      currentPage: 0,
      recordsPerPage: props.recordsPerPage
        ? props.recordsPerPage
        : RECORDS_PER_PAGE[3],
      pagesCount: 0,
      removeMenuPortalTarget: props.removeMenuPortalTarget,
      fetchData: props.fetchData,
      hasMoreData: props.hasMoreData,
      infiniteScroll: props.infiniteScroll ?? false,
      sortType: props.sortType ?? null,
      sortBy: props.sortBy ?? null,
      enableFilterFunction: props.enableFilterFunction ?? false,
      showColumnFilters: props.showFilters ?? false,
      selectedItems: props.selectedItems ?? [],
      setSelectedItems: props.setSelectedItems,
    };
    this.filterUpdated = this.filterUpdated.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.changeRecordsPerPage = this.changeRecordsPerPage.bind(this);
    this.paginateTable = this.paginateTable.bind(this);
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.updateTableData();
    if (!this.state.infiniteScroll) {
      this.paginateTable(this.state.data, this.state.recordsPerPage);
    }
  }
  updateTableData = () => {
    const loadingSign = document.getElementById('loader-id');
    if (
      loadingSign &&
      this.state.hasMoreData &&
      this.isInViewport(loadingSign)
    ) {
      this.fetchData();
    }
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize = () => {
    if (this.state.hasMoreData) {
      this.fetchData();
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (
        prevProps.hasMoreData !== this.props.hasMoreData &&
        this.props.hasMoreData
      ) {
        this.updateTableData();
      }
      this.setState(
        {
          data: this.props.data,
          dataLatestState: this.props.data,
          columns: this.props.columns,
          deleteAction: this.props.deleteAction,
          addAction: this.props.addAction,
          viewDetailsAction: this.props.viewDetailsAction,
          updateAction: this.props.updateAction,
          dropdown: this.props.dropdown,
          fetchData: this.props.fetchData,
          hasMoreData: this.props.hasMoreData,
          sortType: this.props.sortType ?? null,
          sortBy: this.props.sortBy ?? null,
          showColumnFilters: this.props.showFilters ?? false,
          selectedItems: this.props.selectedItems ?? [],
        },
        () => {
          if (!this.state.infiniteScroll) {
            this.paginateTable(this.state.data, this.state.recordsPerPage);
          }
        }
      );
    }
  }

  paginateTable(tableData, recordsPerPage) {
    let alltableData = [];
    // Check if the incoming Data is already Paginated with more then one Page
    if (Array.isArray(tableData[0])) {
      for (let i = 0; i < tableData.length; i++) {
        alltableData = [...alltableData, ...tableData[i]];
      }
    } else {
      alltableData = tableData;
    }
    let pagesCount = Math.ceil(alltableData.length / recordsPerPage?.value);
    let paginatedTableData = [];

    for (let page = 0; page < pagesCount; page++) {
      let data = alltableData.splice(0, recordsPerPage?.value);
      paginatedTableData.push(data);
    }
    this.setState(
      {
        dataLatestState: paginatedTableData,
        pagesCount: pagesCount,
        currentPage: pagesCount === 1 ? 0 : this.state.currentPage,
      },
      () => {
        // set current page to last page if it exceeds the total page
        if (this.state.currentPage >= pagesCount && pagesCount !== 0) {
          this.setState({ currentPage: pagesCount - 1 });
        }
      }
    );
  }

  handlePageChange(event) {
    // Checks if event has a key named value
    if (event.hasOwnProperty('value')) {
      this.setState({ currentPage: event.value - 1 });
    } else {
      this.setState({ currentPage: event.selected });
    }
  }

  changeRecordsPerPage = (recordsPerPage) => {
    this.setState(
      {
        recordsPerPage: recordsPerPage,
        currentPage: 0,
      },
      () => {
        if (!this.state.infiniteScroll) {
          this.paginateTable(this.state.dataLatestState, recordsPerPage);
        }
      }
    );
  };

  filterUpdated = (newData, filterConfiguration) => {
    this.setState(
      {
        dataLatestState: newData,
      },
      () => {
        if (!this.state.infiniteScroll) {
          this.paginateTable(
            this.state.dataLatestState,
            this.state.recordsPerPage
          );
        }
      }
    );
  };

  fetchData = () => {
    if (this.props.fetchData) {
      this.props.fetchData();
    }
  };

  isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= document.documentElement.clientHeight &&
      rect.right <= document.documentElement.clientWidth
    );
  };

  render() {
    const tableContent = (
      <>
        <Table
          striped
          className="my-0"
          size={this.props.tableSize ? this.props.tableSize : undefined}
        >
          <thead>
            <RenderColumnHeader state={this.state} func={this.filterUpdated} />
          </thead>
          <tbody>
            <RenderTableData state={this.state} />
          </tbody>
        </Table>
      </>
    );

    const pageNumberOptions = Array.from(
      { length: this.state.pagesCount },
      (_, i) => i + 1
    );

    const currentPage = {
      value: this.state.currentPage + 1,
      label: this.state.currentPage + 1,
    };

    return (
      <React.Fragment>
        {this.state.infiniteScroll ? (
          <InfiniteScroll
            dataLength={this.state.data.length}
            next={this.fetchData}
            hasMore={this.state.hasMoreData}
            loader={
              <p style={{ textAlign: 'center' }}>
                <br />
                <br />
                <b id="loader-id">{i18n.t('DynamicTable.loading')}</b>
              </p>
            }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <br />
                <br />
                <b>{i18n.t('DynamicTable.endOfList')}</b>
              </p>
            }
          >
            {tableContent}
          </InfiniteScroll>
        ) : (
          tableContent
        )}
        <br />
        {this.props.netCaption && <p>{this.props.netCaption}</p>}
        {this.props.grossCaption && <p>{this.props.grossCaption}</p>}
        {this.state.infiniteScroll ? null : (
          <Row>
            {this.props.dontShowPageNumbers ? null : (
              <Row>
                <Col>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    previousLabel="<"
                    onPageChange={this.handlePageChange}
                    pageRangeDisplayed={2}
                    pageCount={
                      this.state.pagesCount ? this.state.pagesCount : 0
                    }
                    renderOnZeroPageCount={null}
                    forcePage={this.state.currentPage}
                    containerClassName="ellipsis-pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active-page"
                    breakLinkClassName="page-break"
                  />
                </Col>
                <Col>
                  <div style={{ width: '150px' }}>
                    <Row>
                      <Label>{i18n.t('DynamicTable.goToPage')} </Label>
                      <div
                        style={{
                          paddingLeft: '10px',
                          marginTop: '-5px',
                          width: 'auto',
                        }}
                      >
                        <Select
                          options={pageNumberOptions.map((page) => ({
                            value: page,
                            label: page,
                          }))}
                          autosize={true}
                          value={currentPage}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
            {this.props.dontShowDataPerPageOption ? null : (
              <Col>
                <RenderPaginationEntries
                  state={this.state}
                  changeRecordsPerPage={this.changeRecordsPerPage}
                />
              </Col>
            )}
          </Row>
        )}
      </React.Fragment>
    );
  }
}

function RenderPaginationEntries(props) {
  return (
    <React.Fragment>
      <div className="float-end">
        <Label>{i18n.t('DynamicTable.dataPerPage')}</Label>
        <Col lg="12">
          <Select
            options={RECORDS_PER_PAGE}
            onChange={props.changeRecordsPerPage}
            placeholder="Select Customer per Page"
            value={props.state.recordsPerPage}
            menuPortalTarget={
              props.state.removeMenuPortalTarget ? undefined : document.body
            }
          />
        </Col>
      </div>
    </React.Fragment>
  );
}

function TableDropDownColumn(props) {
  const DropdownLink = (props) => {
    return (
      <Link
        to={props.link.linkString.concat(
          props.row[props.link.linkAccessorAdditon]
        )}
      >
        <DropdownItem>{props.link.label}</DropdownItem>
      </Link>
    );
  };

  const DropdownAction = (props) => {
    let thisaction;
    if (props.action.actionFunc === 'delete') {
      thisaction = props.state.deleteAction;
    } else if (props.action.actionFunc === 'add') {
      thisaction = props.state.addAction;
    } else if (props.action.actionFunc === 'update') {
      thisaction = props.state.updateAction;
    } else if (props.action.actionFunc === 'view') {
      thisaction = props.state.viewDetailsAction;
    }
    return (
      <DropdownItem
        onClick={() => thisaction(props.row[props.action.actionProps])}
      >
        {props.action.label}
      </DropdownItem>
    );
  };

  const DropdownBody = (props) => {
    return (
      <DropdownMenu right>
        {props.dropdown.links?.length > 0 &&
          Object.entries(props.dropdown.links).map(([key, link]) => {
            return <DropdownLink key={key} row={props.row} link={link} />;
          })}
        {props.dropdown.actions?.length > 0 &&
          Object.entries(props.dropdown.actions).map(([key, action]) => {
            return (
              <DropdownAction
                key={key}
                row={props.row}
                action={action}
                state={props.state}
              />
            );
          })}
      </DropdownMenu>
    );
  };

  return (
    <UncontrolledDropdown className="d-inline-block">
      <DropdownToggle tag="a">
        <MoreVertical />
      </DropdownToggle>
      <DropdownBody
        row={props.row}
        dropdown={props.dropdown}
        state={props.state}
      />
    </UncontrolledDropdown>
  );
}

function generateSortButtonTooltip(sortBy, sortType, accessor) {
  return sortType === null || sortBy !== accessor ? (
    <span>{i18n.t('DynamicTable.sortAscending')}</span>
  ) : sortType === Ascending ? (
    <span>{i18n.t('DynamicTable.sortDescending')}</span>
  ) : (
    <span>{i18n.t('DynamicTable.returnToDefaultSort')}</span>
  );
}

function RenderColumnHeader(props) {
  return props.state.infiniteScroll ? (
    <>
      {Object.entries(props.state.columns).map(([key, columnHeader]) => {
        if (columnHeader?.show === 'true') {
          return (
            <th
              className="bid-floor-col"
              style={{
                textAlign: columnHeader.headerAlign
                  ? columnHeader.headerAlign
                  : 'left',
                width: columnHeader.width,
              }}
              key={key}
              filterkey={columnHeader.filterkey ? columnHeader.filterkey : ''}
              itemdisplayvaluefunc={
                columnHeader.itemdisplayvaluefunc
                  ? columnHeader.itemdisplayvaluefunc
                  : undefined
              }
              itemsortvaluefunc={
                columnHeader.itemsortvaluefunc
                  ? columnHeader.itemsortvaluefunc
                  : undefined
              }
              alignleft={
                columnHeader.alignleft ? columnHeader.alignleft : 'false'
              }
              casesensitive={
                columnHeader.casesensitive
                  ? columnHeader.casesensitive
                  : 'false'
              }
              showsearch={
                columnHeader.showsearch ? columnHeader.showsearch : 'true'
              }
            >
              {columnHeader.filterFunc
                ? props.state.showColumnFilters && (
                    <Row>
                      <Col>
                        <InputGroup
                          className="column-width"
                          style={{
                            width: columnHeader.width,
                          }}
                        >
                          <div
                            className="column-width"
                            style={{
                              width: columnHeader.filterComponentWidth,
                              position: 'relative',
                              zIndex: 5,
                            }}
                          >
                            {columnHeader.filterType === 'dropdown' ? (
                              <Select
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                  Option,
                                }}
                                allowSelectAll={true}
                                options={sortByPropValue(
                                  columnHeader.filterOptions,
                                  'label'
                                )}
                                isMulti
                                onChange={(event) =>
                                  columnHeader.filterFunc(
                                    event,
                                    columnHeader.accessor
                                  )
                                }
                                isClearable={true}
                                value={columnHeader?.filterValue}
                                placeholder={i18n.t('DynamicTable.select')}
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    fontWeight: 'normal',
                                  }),
                                  container: (provided) => ({
                                    ...provided,
                                    fontWeight: 'normal',
                                  }),
                                }}
                              />
                            ) : columnHeader.filterType === 'date' ? (
                              <Input
                                type="date"
                                format={getDateFormat()}
                                name="date"
                                style={{ height: '38px' }}
                                onChange={(event) =>
                                  columnHeader.filterFunc(
                                    event,
                                    columnHeader.accessor
                                  )
                                }
                              />
                            ) : columnHeader.filterType === 'dateRange' ? (
                              <>
                                <Label>Start</Label>
                                <Input
                                  type="date"
                                  format={getDateFormat()}
                                  name="date"
                                  style={{ height: '38px' }}
                                  onChange={(event) =>
                                    columnHeader.filterFunc(
                                      event,
                                      columnHeader.accessor
                                    )
                                  }
                                />
                                <Label>End</Label>
                                <Input
                                  type="date"
                                  format={getDateFormat()}
                                  name="date"
                                  style={{ height: '38px' }}
                                  onChange={(event) =>
                                    columnHeader.filterFunc2(
                                      event,
                                      columnHeader.accessor
                                    )
                                  }
                                />
                              </>
                            ) : columnHeader.filterType ===
                              'dateRange + number' ? (
                              <>
                                <Label>Start</Label>
                                <Input
                                  type="date"
                                  format={getDateFormat()}
                                  name="date"
                                  style={{ height: '38px' }}
                                  onChange={(event) =>
                                    columnHeader.filterFunc(
                                      event,
                                      columnHeader.accessor
                                    )
                                  }
                                />
                                <Label>End</Label>
                                <Input
                                  type="date"
                                  format={getDateFormat()}
                                  name="date"
                                  style={{ height: '38px' }}
                                  onChange={(event) =>
                                    columnHeader.filterFunc2(
                                      event,
                                      columnHeader.accessor
                                    )
                                  }
                                />
                                <Label>Availability</Label>
                                <Input
                                  type="number"
                                  name="availability"
                                  style={{ height: '38px' }}
                                  onChange={(event) =>
                                    columnHeader.filterFunc3(
                                      event,
                                      columnHeader.accessor
                                    )
                                  }
                                />
                              </>
                            ) : columnHeader.filterType === 'number range' ? (
                              <>
                                <Input
                                  type="number"
                                  name="numberStart"
                                  placeholder={i18n.t('DynamicTable.MinValue')}
                                  style={{ height: '38px' }}
                                  onChange={(event) =>
                                    columnHeader.filterFunc(
                                      event,
                                      columnHeader.accessor
                                    )
                                  }
                                />
                                <Input
                                  type="number"
                                  name="numberEnd"
                                  placeholder={i18n.t('DynamicTable.MaxValue')}
                                  style={{ height: '38px' }}
                                  onChange={(event) =>
                                    columnHeader.filterFunc2(
                                      event,
                                      columnHeader.accessor
                                    )
                                  }
                                />
                              </>
                            ) : columnHeader.filterType ===
                              'dropdown + number range' ? (
                              <>
                                <Select
                                  options={sortByPropValue(
                                    columnHeader.filterOptions,
                                    'label'
                                  )}
                                  isMulti
                                  onChange={(event) =>
                                    columnHeader.filterFunc(
                                      event,
                                      columnHeader.accessor
                                    )
                                  }
                                  isClearable={true}
                                  value={columnHeader?.filterValue}
                                  placeholder={i18n.t('DynamicTable.select')}
                                  styles={{
                                    control: (base) => ({
                                      ...base,
                                      fontWeight: 'normal',
                                    }),
                                    container: (provided) => ({
                                      ...provided,
                                      fontWeight: 'normal',
                                    }),
                                  }}
                                />
                                <Input
                                  type="number"
                                  name="numberStart"
                                  placeholder={i18n.t('DynamicTable.MinValue')}
                                  style={{ height: '38px' }}
                                  onChange={(event) =>
                                    columnHeader.filterFunc2(
                                      event,
                                      columnHeader.accessor
                                    )
                                  }
                                />
                                <Input
                                  type="number"
                                  name="numberEnd"
                                  placeholder={i18n.t('DynamicTable.MaxValue')}
                                  style={{ height: '38px' }}
                                  onChange={(event) =>
                                    columnHeader.filterFunc3(
                                      event,
                                      columnHeader.accessor
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <Input
                                placeholder="Filter"
                                onChange={(event) =>
                                  columnHeader.filterFunc(
                                    event,
                                    columnHeader.accessor
                                  )
                                }
                                value={columnHeader?.filterValue}
                                style={{ height: '38px' }}
                              />
                            )}
                          </div>
                        </InputGroup>
                      </Col>
                    </Row>
                  )
                : null}
              <Row>
                <Col
                  onClick={() => {
                    columnHeader.sortFunc
                      ? columnHeader.sortFunc(columnHeader.accessor)
                      : null;
                  }}
                  disabled={!columnHeader.sortFunc}
                  role="Sort"
                  style={{ cursor: 'pointer' }}
                >
                  <Tooltip
                    title={generateSortButtonTooltip(
                      props.state.sortBy,
                      props.state.sortType,
                      columnHeader.accessor
                    )}
                    disabled={!columnHeader.sortFunc}
                  >
                    {columnHeader.header ? columnHeader.header : null}
                    {/**
                     * The icon stays at default if the sortType (asc, desc) is null
                     * or if the current active sort (sortBy) is not similar to the
                     * value in columnHeader.accessor
                     */}
                    {columnHeader.sortFunc ? (
                      <FontAwesomeIcon
                        icon={
                          props.state.sortType === null ||
                          props.state.sortBy !== columnHeader.accessor
                            ? faSort
                            : props.state.sortType === Ascending
                            ? faSortUp
                            : faSortDown
                        }
                        className="float-end"
                      />
                    ) : null}
                  </Tooltip>
                </Col>
              </Row>
            </th>
          );
        } else return null;
      })}
    </>
  ) : (
    <TableFilter
      rows={props.state.data}
      onFilterUpdate={props.func}
      rowClass={null}
      initialFilters={null}
      rowComponent={null}
    >
      {Object.entries(props.state.columns).map(([key, columnHeader]) => {
        if (columnHeader?.show === 'true') {
          return (
            <th
              style={{
                textAlign: columnHeader.headerAlign
                  ? columnHeader.headerAlign
                  : 'left',
                width: columnHeader.width,
              }}
              key={key}
              filterkey={columnHeader.filterkey ? columnHeader.filterkey : ''}
              itemdisplayvaluefunc={
                columnHeader.itemdisplayvaluefunc
                  ? columnHeader.itemdisplayvaluefunc
                  : undefined
              }
              itemsortvaluefunc={
                columnHeader.itemsortvaluefunc
                  ? columnHeader.itemsortvaluefunc
                  : undefined
              }
              alignleft={
                columnHeader.alignleft ? columnHeader.alignleft : 'false'
              }
              casesensitive={
                columnHeader.casesensitive
                  ? columnHeader.casesensitive
                  : 'false'
              }
              showsearch={
                columnHeader.showsearch ? columnHeader.showsearch : 'true'
              }
            >
              {columnHeader.header ? columnHeader.header : null}
            </th>
          );
        } else return null;
      })}
    </TableFilter>
  );
}

function isChecked(state, item) {
  const selectedItems = state.selectedItems;
  if (!isEmpty(selectedItems)) {
    return selectedItems.some((selectedItem) => selectedItem?.id === item?.id);
  }
  return false;
}

function checkboxEvent(state, item) {
  const selectedItems = state.selectedItems;
  let index = selectedItems?.findIndex(
    (selectedItem) => selectedItem?.id === item?.id
  );
  if (index >= 0) {
    selectedItems.splice(index, 1);
  } else {
    selectedItems.push(item);
  }
  if (state.setSelectedItems) {
    state.setSelectedItems(selectedItems);
  }
}

function RenderTableData(props) {
  let allKeys = [];
  let items = Array.isArray(
    props.state.dataLatestState[props.state.currentPage]
  )
    ? props.state.dataLatestState[props.state.currentPage]
    : props.state.dataLatestState;
  //Creates a Loop to Create a List of the Keys i want to have in the Table
  props.state.columns.forEach((column) => {
    if (column?.show === 'true' && column.accessor) {
      allKeys.push(column.accessor.toString());
    }
  });

  const rowStyle = (column) => {
    return {
      textAlign: column.dataAlign ? column.dataAlign : 'left',
      wordBreak: column.wordBreak ?? undefined,
      verticalAlign: 'text-top',
      whiteSpace: 'pre-wrap',
    };
  };

  const RenderRow = (props) => {
    return props.keys.map((key, index) => {
      let column = props.columns.find((obj) => obj.accessor === key);
      if (column.type === 'checkbox') {
        return (
          <td
            style={rowStyle(column)}
            onClick={() => checkboxEvent(props.state, props.data[key])}
          >
            <input
              type="checkbox"
              checked={isChecked(props.state, props.data[key])}
              readOnly
            />
          </td>
        );
      }

      if (column.type === 'component') {
        return <td style={rowStyle(column)}>{props.data[key]}</td>;
      }
      if (column.type === 'customComponent') {
        return (
          <td style={rowStyle(column)}>
            {column?.customComponent(props.data)}
          </td>
        );
      }
      if (column.type === 'customComponent') {
        return column?.customComponent(props.data);
      }
      if (column.type === 'data' && column.itemDisplayValueFunc !== undefined) {
        return (
          <td style={rowStyle(column)}>
            {column.itemDisplayValueFunc(props.data[key])}
          </td>
        );
      }
      let result = props.columns.filter((obj) => {
        return obj.accessor === key;
      });
      let link = '';
      let linkFunc = null;
      let linkList = [];

      const linkAccessorValue = props.data[result[0].linkAccessor];
      const accessorValue = props.data[result[0].accessor];
      const linkFuncParameterValue = props.data[result[0].linkFuncParameter];

      if (result[0] !== undefined) {
        if (
          linkAccessorValue &&
          Array.isArray(linkAccessorValue) &&
          linkAccessorValue.length > 0
        ) {
          linkAccessorValue.forEach((linkAccessor) => {
            linkList.push(result[0].link.concat(linkAccessor));
          });
        } else {
          link =
            result[0] !== undefined &&
            result[0].linkAccessor !== undefined &&
            result[0].link !== undefined
              ? result[0].link.concat(props.data[result[0].linkAccessor])
              : result[0].link;
        }

        linkFunc = result[0].linkFunc;
      }

      if (!isEmpty(linkList)) {
        return (
          <React.Fragment>
            <td style={rowStyle(column)}>
              {linkList.map((link, index) => (
                <Row key={(link, index)}>
                  <Link
                    to={link ? link : '#'}
                    onClick={
                      linkFunc
                        ? () =>
                            linkFunc(props.data[result[0].linkFuncParameter])
                        : undefined
                    }
                  >
                    {index !== linkList.length - 1 ? (
                      <Col key={`${key}-${props.data.id}`}>
                        {`${props.data[key][index]},`}
                      </Col>
                    ) : (
                      <Col key={`${key}-${props.data.id}`}>
                        {`${props.data[key][index]}`}
                      </Col>
                    )}
                  </Link>
                </Row>
              ))}
            </td>
          </React.Fragment>
        );
      }

      if (
        Array.isArray(accessorValue) &&
        accessorValue.length > 0 &&
        linkFuncParameterValue === undefined
      ) {
        return (
          <React.Fragment>
            <td style={rowStyle(column)}>
              {accessorValue.map((accessor, index) => (
                <Row key={(accessor, index)}>
                  <Link
                    to={link ? link : '#'}
                    onClick={
                      linkFunc
                        ? () =>
                            linkFunc(props.data[result[0].linkFuncParameter])
                        : undefined
                    }
                  ></Link>
                  {index !== accessorValue.length - 1 ? (
                    <Col key={`${key}-${props.data.id}`}>
                      {`${props.data[key][index]},`}
                    </Col>
                  ) : (
                    <Col key={`${key}-${props.data.id}`}>
                      {`${props.data[key][index]}`}
                    </Col>
                  )}
                </Row>
              ))}
            </td>
          </React.Fragment>
        );
      }

      if (!isEmpty(linkFuncParameterValue)) {
        return (
          <React.Fragment>
            <td style={rowStyle(column)}>
              {linkFuncParameterValue.map((accessor, index) => (
                <Row key={(accessor, index)}>
                  <Link
                    to={link ? link : '#'}
                    onClick={linkFunc ? () => linkFunc(accessor) : undefined}
                  >
                    {index !== linkFuncParameterValue.length - 1 ? (
                      <Col key={`${key}-${props.data.id}`}>
                        {`${props.data[key][index]},`}
                      </Col>
                    ) : (
                      <Col key={`${key}-${props.data.id}`}>
                        {`${props.data[key][index]}`}
                      </Col>
                    )}
                  </Link>
                </Row>
              ))}
            </td>
          </React.Fragment>
        );
      }

      return link || linkFunc ? (
        <React.Fragment>
          <td key={`${key}-${props.data.id}`} style={rowStyle(column)}>
            <Link
              to={link ? link : '#'}
              onClick={
                linkFunc
                  ? () => linkFunc(props.data[result[0].linkFuncParameter])
                  : undefined
              }
            >
              <Row key={`${key}-${props.data.id}`}>
                <Col key={`${key}-${props.data.id}`}>{props.data[key]}</Col>
              </Row>
              {props.columns[index]?.addionalInfoAccessor &&
              Array.isArray(
                props.data[props.columns[index]?.addionalInfoAccessor]
              )
                ? props.data[props.columns[index].addionalInfoAccessor]?.map(
                    (additonalInfo, i) => (
                      <Row key={i}>
                        <Col key={i}>{additonalInfo}</Col>
                      </Row>
                    )
                  )
                : null}
            </Link>
          </td>
        </React.Fragment>
      ) : (
        <td key={`${key}-${props.data.id}`} style={rowStyle(column)}>
          <Row key={`${key}-${props.data.id}`}>
            <Col key={`${key}-${props.data.id}`}>{props.data[key]}</Col>
          </Row>
          {props.columns[index]?.addionalInfoAccessor &&
          Array.isArray(props.data[props.columns[index]?.addionalInfoAccessor])
            ? props.data[props.columns[index].addionalInfoAccessor]?.map(
                (additonalInfo, i) => (
                  <Row key={i}>
                    <Col key={i}>{additonalInfo}</Col>
                  </Row>
                )
              )
            : null}
        </td>
      );
    });
  };

  props.state.columns.forEach((column) => {
    if (column?.show === true) {
      allKeys.push(column.accessor);
    }
  });

  return items.map((row, index) => {
    return (
      <tr
        key={'tr' + index}
        style={{
          backgroundColor:
            row.highlightRow !== undefined && row.highlightRow
              ? row.rowHighlightColor ?? null
              : null,
        }}
      >
        <RenderRow
          key={'row' + index}
          data={row}
          keys={allKeys}
          columns={props.state.columns}
          state={props.state}
        />
        {props.state.dropdown !== undefined && (
          <td>
            <TableDropDownColumn
              key={'td' + index}
              row={row}
              dropdown={props.state.dropdown}
              state={props.state}
            />
          </td>
        )}
      </tr>
    );
  });
}

export default DynamicTable;
