import React, { useContext } from 'react';
import { Button, Col, ModalBody, ModalFooter, Row } from 'reactstrap';
import { ConnectedProps, connect } from 'react-redux';

import { clickHandler } from '../../../utils/helpers/click';
import { generateTitle } from '../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { t, translateContactProfile } from './contactPersonHelper';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { ContactPersonDetailsContext } from './ContactPersonDetailsProvider';
import {
  exportContactPersonToOutlook,
  getContactPerson,
} from '../../../services/api/contactPerson';
import { setThenRemoveAlert } from '../../../redux/alertsSlice';

interface IProps extends IWithModalsProps, PropsFromRedux {
  toggleModal: () => void;
}

/**
 * Modal used to export contact to outlook
 */
const ModalExportContactForm = ({ toggleModal, modalErrorHandler }: IProps) => {
  const { contactDetail } = useContext(ContactPersonDetailsContext);

  /**
   * Handles the process to export this contact person to outlook
   */
  const getUpdatedContactPerson = async () => {
    const { id } = contactDetail;
    try {
      const { data } = await getContactPerson(id);
      return data;
    } catch (error) {
      modalErrorHandler(t('failedToGetContactPerson'), error);
    }
    return null;
  };

  /**
   * Handles the process to export this contact person to outlook
   */
  const handleExportToOutlook = async () => {
    const updatedContactPerson = await getUpdatedContactPerson();
    try {
      if (updatedContactPerson) {
        await exportContactPersonToOutlook(updatedContactPerson);
        setThenRemoveAlert(
          t('Exported Contact Person Successfully'),
          'success'
        );
      }
    } catch (error) {
      modalErrorHandler(t('failedToExportContactToOutlook'), error);
    }
    toggleModal();
  };

  return (
    <>
      <ModalBody>
        <Row>
          <Col>
            {translateContactProfile('exportThisContactToOutlookMessage')}
          </Col>
          <Col className="exclamation-color" md="auto">
            {generateTitle(BUTTON_TITLE_ENUM.INFORMATION.code, '')}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="dark"
          onClick={(event: MouseEvent) =>
            clickHandler(event, handleExportToOutlook)
          }
        >
          {translateContactProfile('proceed')}
        </Button>{' '}
        <Button
          color="dark"
          onClick={(event: MouseEvent) => clickHandler(event, toggleModal)}
        >
          {translateContactProfile('cancel')}
        </Button>
      </ModalFooter>
    </>
  );
};

const mapDispatchToProps = { setThenRemoveAlert };
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(ModalExportContactForm));
