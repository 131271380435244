import React from 'react';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import Auth from './services/axios/Auth';
import NotificationSetup from './NotificationSetup';
import {
  setAccountDetails,
  setDefaultCurrency,
  setEmployeeDetails,
  setPermissions,
} from './redux/accountSlice';
import { setConfigs } from './redux/configsSlice';
import { setUserSetting } from './redux/userSettingSlice';
import Routes from './routes/Routes';
import setUser from './setUser';
import user from './user';
import './assets/scss/modern.scss';

const mapDispatchToProps = (dispatch) => ({
  setAccountDetails: (userData) => {
    dispatch(setAccountDetails(userData));
  },
  setEmployeeDetails: (employeeData) => {
    dispatch(setEmployeeDetails(employeeData));
  },
  setDefaultCurrency: (defaultCurrency) => {
    dispatch(setDefaultCurrency(defaultCurrency));
  },
  setPermissions: (permissions) => {
    dispatch(setPermissions(permissions));
  },
  setConfigs: (configs) => {
    dispatch(setConfigs(configs));
  },
  setUserSetting: (userSetting) => {
    dispatch(setUserSetting(userSetting));
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: true,
    };
  }

  componentDidMount = async () => {
    // Remove `active` className from `.splash` element in `public/index.html`
    !document.querySelector('.splash') ||
      document.querySelector('.splash').classList.remove('active');
    if (Auth.getAuth() && !user.role && !user.rights && !user.userId) {
      this.setState({
        authenticated: false,
      });
      await setUser(this.props);
      if (user) {
        this.setState({
          authenticated: true,
        });
      }
    }
  };

  render() {
    return this.state.authenticated ? (
      <>
        <NotificationSetup />
        <Routes />
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </>
    ) : (
      <>
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(App);
