import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Container } from 'reactstrap';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { SERVICE_CONFIG } from "../../../utils/enums/service";
import { handleError, isEmpty } from '../../../utils/helpers/GenericHelper';
import {
  generateTitle,
} from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import UpdateWorkingHoursPerMonth from './UpdateWorkingHoursPerMonth';

/**
 * Class for showing the Working Hours Per Month in the Sales settings
 *
 * Created by: Reimon Angelo Tito
 * Created on: 28/09/2021
 */
class WorkingHoursPerMonthView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceConfigurations: [],

      showModalForm: false,
      showModalError: false,
      modalForm: null,
    };
  }

  t(keyName) {
    return i18n.t('WorkingHoursPerMonthView.' + keyName);
  }

  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  toggleModalForm = () => {
    this.setState({ showModalForm: !this.state.showModalForm });
  };

  handleError = (mainError, errorObject) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  componentDidMount = async () => {
    let serviceConfigurations = await axios.serviceConfiguration
      .get(
        `?service.equals=${SERVICE_CONFIG.workingHoursPerMonth.service}&configKey.equals=${SERVICE_CONFIG.workingHoursPerMonth.key}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let mainError = this.t('failedToRetrieveWorkingHoursPerMonth');
        this.handleError(mainError, error);
      });

    if (!isEmpty(serviceConfigurations)) {
      this.setState({
        serviceConfigurations: serviceConfigurations,
      });
    }
  };

  prepareTableData = (serviceConfigurations) => {
    const newTableData = [];
    if (!isEmpty(serviceConfigurations)) {
      serviceConfigurations.forEach((serviceConfiguration) => {
        const entry = {
          id: serviceConfiguration?.id,
          service: serviceConfiguration?.service,
          configKey: serviceConfiguration?.configKey,
          configValue: serviceConfiguration?.configValue,
          editButton: (
            <>
              <Button
                color="primary"
                onClick={() => this.updateWorkingHours(serviceConfiguration)}
              >
                {generateTitle(BUTTON_TITLE_ENUM.EDIT.code)}
              </Button>
            </>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  updateWorkingHours = (serviceConfiguration) => {
    this.setState({
      modalForm: (
        <UpdateWorkingHoursPerMonth
          serviceConfiguration={serviceConfiguration}
          onSave={this.onSave}
        />
      ),
    });
    this.toggleModalForm();
  };

  onSave = (serviceConfiguration) => {
    let serviceConfigurations = this.state.serviceConfigurations;
    let index = serviceConfigurations.findIndex(
      (item) => item?.id === serviceConfiguration?.id
    );
    if (index >= 0) {
      serviceConfigurations[index] = serviceConfiguration;
    } else {
      serviceConfigurations.push(serviceConfiguration);
    }
    serviceConfigurations = serviceConfigurations?.sort((a, b) =>
      a?.configValue > b?.configValue ? 1 : -1
    );
    this.setState({ serviceConfigurations: serviceConfigurations });
    if (this.state.showModalForm) {
      this.toggleModalForm();
    }
  };

  render() {
    const serviceConfigurations = this.state.serviceConfigurations;
    const preparedColumns = [
      {
        type: 'component',
        header: this.t('workingHoursPerMonth'),
        accessor: 'configValue',
        show: 'true',
      },
      {
        type: 'component',
        header: '',
        accessor: 'editButton',
        show: 'true',
      },
    ];
    return (
      <Container fluid>
        <DynamicTable
          data={this.prepareTableData(serviceConfigurations)}
          columns={preparedColumns}
        />

        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={'Error'}
        ></ModalError>
        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleModalForm}
          ref={this.state.modalForm}
          modalTitle={this.t('workingHoursPerMonth')}
        >
          {this.state.modalForm}
        </ModalForm>
      </Container>
    );
  }
}

export default WorkingHoursPerMonthView;
