import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { CONFIRM_OR_DISQUALIFY } from '../../../utils/enums/activity';
import i18n from '../../../i18n';

interface IProps {
  modalTitle: string;
  isOpen: boolean;
  toggleConfirmOrDisqualify: () => void;
  selectedDecision: (decision: string) => Promise<void>;
}

const t = (keyName: string) => i18n.t(`ModalAddActivity.${keyName}`);

/**
 * Shows ConfirmOrDiqualifyModal that let the user to decide to either diqualify or proceed to the selected next activity.
 * functional props:
 * * selectedDecision => call the function after deciding what to do next.
 * * toggleConfirmOrDisqualify => for toggling the modalForm
 * props:
 * * modalTitle => modal title
 *   isOpen => for opening the modal
 */
const ModalConfirmOrDisqualify = ({
  modalTitle,
  isOpen,
  toggleConfirmOrDisqualify,
  selectedDecision,
}: IProps) => (
  <Modal isOpen={isOpen} toggleConfirmOrDisqualify>
    <ModalHeader toggleConfirmOrDisqualify={() => toggleConfirmOrDisqualify()}>
      {modalTitle}
    </ModalHeader>
    <ModalBody>{t('confirmOrDisqualifyMessage')}</ModalBody>
    <ModalFooter>
      <Button
        color="dark"
        onClick={async () => {
          await selectedDecision(CONFIRM_OR_DISQUALIFY.disqualify.label);
          toggleConfirmOrDisqualify();
        }}
      >
        {t(CONFIRM_OR_DISQUALIFY.disqualify.code)}
      </Button>
      <Button
        color="dark"
        onClick={async () => {
          await selectedDecision(CONFIRM_OR_DISQUALIFY.keepNextActivity.label);
          toggleConfirmOrDisqualify();
        }}
      >
        {t(CONFIRM_OR_DISQUALIFY.keepNextActivity.code)}
      </Button>
      <Button
        color="dark"
        onClick={() => {
          toggleConfirmOrDisqualify();
        }}
      >
        {t('cancel')}
      </Button>
    </ModalFooter>
  </Modal>
);

export default ModalConfirmOrDisqualify;
