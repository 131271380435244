import { faHandshake } from '@fortawesome/free-solid-svg-icons';

import {
  SERVICE_CONFIG,
  SERVICE_NAMES_ENUM,
} from '../../utils/enums/service';
import { PERMISSION_URI } from '../../utils/enums/permission';
import i18n from '../../i18n';
import ApprovalsList from '../../pages/crm/ApprovalList/ApprovalsList';
import CallingStatistics from '../../pages/crm/CallingStatistics/CallingStatistics';
import ContactStatistics from '../../pages/crm/ContactStatistics/ContactStatistics';
import CampaignManager from '../../pages/crm/CampaignManager/CampaignManager';
import ContactList from '../../pages/crm/ContactList/ContactList';
import CustomerAccountDetail from '../../pages/crm/CustomerAccountDetails/CustomerAccountDetail';
import CustomerAccountList from '../../pages/crm/CustomerAccountList/CustomerAccountList';
import CustomerDetail from '../../pages/crm/CustomerDetails/CustomerDetail';
import CustomerList from '../../pages/crm/CustomerList/CustomerList';
import CustomerStatistics from '../../pages/crm/CustomerStatistics/CustomerStatistics';
import CustomerSiteList from '../../pages/crm/CustomerSiteList/CustomerSiteList';
import GdprOverview from '../../pages/crm/GdprOverview/GdprOverview';
import CustomerSiteDetail from '../../pages/crm/CustomerSiteDetails/CustomerSiteDetail';
import ContactPersonProfile from '../../pages/crm/ContactDetails/ContactPersonProfile';

export const customerRoutes = {
  path: '/customer',
  name: i18n.t('index.crm'),
  serviceName: SERVICE_NAMES_ENUM.crm.code,
  icon: faHandshake,
  isProtected: true,
  auth: true,
  badgeColor: 'primary',
  permissionUri: [
    PERMISSION_URI.fullCustomerAccountList.read.uri,
    PERMISSION_URI.customerAccountList.read.uri,
    PERMISSION_URI.fullCustomerList.read.uri,
    PERMISSION_URI.customerList.read.uri,
    PERMISSION_URI.fullCustomerSiteList.read.uri,
    PERMISSION_URI.customerSiteList.read.uri,
    PERMISSION_URI.fullContactPeopleList.read.uri,
    PERMISSION_URI.contactPeopleList.read.uri,
    PERMISSION_URI.campaignManager.readWrite.uri,
  ],
  children: [
    {
      path: '/customer/accounts',
      name: i18n.t('index.accounts'),
      component: CustomerAccountList,
      permissionUri: [
        PERMISSION_URI.customerAccountList.read.uri,
        PERMISSION_URI.fullCustomerAccountList.read.uri,
      ],
    },
    {
      path: '/customer/customers',
      name: i18n.t('index.customers'),
      component: CustomerList,
      permissionUri: [
        PERMISSION_URI.fullCustomerList.read.uri,
        PERMISSION_URI.customerList.read.uri,
      ],
    },
    {
      path: '/customer/customer-sites',
      name: i18n.t('index.customerSites'),
      component: CustomerSiteList,
      permissionUri: [
        PERMISSION_URI.fullCustomerSiteList.read.uri,
        PERMISSION_URI.customerSiteList.read.uri,
      ],
    },
    {
      path: '/customer/my-contacts',
      name: i18n.t('index.myContacts'),
      component: ContactList,
      permissionUri: [
        PERMISSION_URI.fullContactPeopleList.read.uri,
        PERMISSION_URI.contactPeopleList.read.uri,
      ],
    },
    {
      path: '/customer/campaign-manager',
      name: i18n.t('index.campaigns'),
      component: CampaignManager,
      permissionUri: [PERMISSION_URI.campaignManager.readWrite.uri],
    },
    {
      path: '/customer/gdpr-overview',
      name: i18n.t('index.gdprOverview'),
      component: GdprOverview,
      permissionUri: [PERMISSION_URI.gdprOverview.readWrite.uri],
    },
    {
      path: '/customer/calling-statistics',
      name: i18n.t('index.myCallingStatistics'),
      component: CallingStatistics,
      permissionUri: [PERMISSION_URI.myCallingStats.read.uri],
      configurationUri: {
        configKey: SERVICE_CONFIG.coldCalling.key,
        service: SERVICE_CONFIG.coldCalling.service,
        configValue: SERVICE_CONFIG.coldCalling.value,
      },
    },
    {
      path: '/customer/contact-statistics',
      name: i18n.t('index.globalCallingStatistics'),
      component: ContactStatistics,
      permissionUri: [PERMISSION_URI.globalCallingStats.read.uri],
    },
    {
      path: '/customer/Approvals',
      name: i18n.t('index.approvals'),
      component: ApprovalsList,
      permissionUri: [PERMISSION_URI.approval.readWrite.uri],
    },
  ],
};

export const customerPrivateRoutes = {
  path: '/customer/customers',
  name: i18n.t('index.customers'),
  isProtected: true,
  auth: true,
  permissionUri: [
    PERMISSION_URI.customerAccountList.read.uri,
    PERMISSION_URI.customerList.read.uri,
    PERMISSION_URI.contactPeopleList.read.uri,
    PERMISSION_URI.contactPerson.readWrite.uri,
  ],
  children: [
    {
      path: '/customer/customers/details/:id',
      component: CustomerDetail,
    },
    {
      path: '/customer/customers/details/:id/customer-site-details/:customerSiteId',
      component: CustomerSiteDetail,
    },
    {
      path: '/customer/customers/customer-statistics/:id',
      component: CustomerStatistics,
    },
    {
      path: '/customer/my-contacts/profile/:contactId',
      name: 'Contact Profile',
      component: ContactPersonProfile,
    },
    {
      path: '/customer/customers/details/:id/contact-person-profile/:contactId',
      name: 'Contact Profile',
      component: ContactPersonProfile,
    },
  ],
};

export const customerSiteRoutes = {
  path: '/customer/customer-sites',
  name: i18n.t('index.customerSites'),
  isProtected: true,
  permissionUri: [
    PERMISSION_URI.customerAccountList.read.uri,
    PERMISSION_URI.customerList.read.uri,
    PERMISSION_URI.contactPeopleList.read.uri,
  ],
  children: [
    {
      path: '/customer/customer-sites/details/:customerSiteId',
      component: CustomerSiteDetail,
    },
  ],
};

export const accountRoutes = {
  path: '/customer/accounts',
  name: i18n.t('index.accounts'),
  isProtected: true,
  permissionUri: [
    PERMISSION_URI.customerAccountList.read.uri,
    PERMISSION_URI.customerList.read.uri,
    PERMISSION_URI.contactPeopleList.read.uri,
  ],
  children: [
    {
      path: '/customer/accounts/details/:customerAccountId',
      component: CustomerAccountDetail,
    },
  ],
};
