import { faHandshake, faUsers } from '@fortawesome/free-solid-svg-icons';

import { SERVICE_NAMES_ENUM } from '../../utils/enums/service';
import { PERMISSION_URI } from '../../utils/enums/permission';
import i18n from '../../i18n';
// Role Management
import UserDetail from '../../pages/employees/EmployeeDetails/EmployeeDetails';
import EmployeeList from '../../pages/employees/EmployeeList/EmployeeList';

export const employeeRoutes = {
  path: '/employees',
  name: i18n.t('index.employees'),
  serviceName: SERVICE_NAMES_ENUM.employees.code,
  isProtected: true,
  auth: true,
  icon: faUsers,
  permissionUri: [
    PERMISSION_URI.employeeList.read.uri,
    PERMISSION_URI.employeeMyTeam.read.uri,
    PERMISSION_URI.employeeManagement.read.uri,
    PERMISSION_URI.employeeApprovalsList.read.uri,
  ],
  children: [
    {
      path: '/employees/employee-list',
      name: i18n.t('index.employeeOverview'),
      component: EmployeeList,
      permissionUri: [PERMISSION_URI.employeeList.read.uri],
    },
  ],
};

export const employeePrivateRoutes = {
  path: '/employees/employee-list',
  name: 'Employees',
  isProtected: true,
  permissionUri: [
    PERMISSION_URI.employeeList.read.uri,
    PERMISSION_URI.employeeManagement.read.uri,
  ],
  icon: faHandshake,
  children: [
    {
      path: '/employees/employee-list/employee-detail/:id',
      name: 'User',
      component: UserDetail,
    },
  ],
};
