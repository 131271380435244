import React from 'react';
import { Label } from 'reactstrap';

interface IProps {
  isRequired: boolean;
  text: string;
}

const InputFormLabel: React.FC<IProps> = ({ isRequired, text }) =>
  isRequired ? (
    <span className="input-label">
      <Label>
        <span className="marker-color">*</span>
        {text}
      </Label>
    </span>
  ) : (
    <Label className="input-label">{text}</Label>
  );

export default InputFormLabel;
