import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';

import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { NO_ID } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { handleError, isEmpty } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import { IErrorMessage } from '../../../utils/types/commonTypes';
import { IRole, ISubject2Role } from '../../../utils/types/modelTypes';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import AddRoleModal from '../../../components/form/AddRoleModal';

// !-- Used by EmployeeGroupDetail, RoleGroupDetail

interface IProps {
  cardTitle?: string;
  buttonName?: string;
  subjectId: number;
  subjectType: string;
  roles: ISubject2Role[];
  roleOptions?: IRoleOptions[];
  showRoleAdd?: boolean;
}

interface IState {
  roles: ISubject2Role[];
  showModalAddRole: boolean;
  showModalError: boolean;
  showModalOk: boolean;
  showModalDelete: boolean;
  roleOptions: IRoleOptions[];
  modalForm: JSX.Element;
}

interface IRoleOptions {
  value: number;
  label: string;
  description: string;
}

interface IEntry {
  role: JSX.Element;
  description: string;
  delete: JSX.Element;
}

/**
 * Class that shows the Role Card
 */
class RoleCard extends React.Component<IProps, IState> {
  error: IErrorMessage = {} as IErrorMessage;

  deleteRoleEvent = async () => {
    // Empty function default
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      roles: [],
      showModalAddRole: false,
      showModalError: false,
      showModalOk: false,
      showModalDelete: false,
      roleOptions: props?.roleOptions ?? [],
      modalForm: {} as JSX.Element,
    };
  }

  async componentDidMount() {
    await axios.employee
      .get('roles')
      .then((response: AxiosResponse<IRole[]>) => {
        this.setState({
          roleOptions: response.data.map((role: IRole) => ({
            value: role.id ?? (NO_ID as number),
            label: role.role ?? '',
            description: role.description ?? '',
          })),
        });
      })
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveRoles');
        this.handleError(mainError, error);
      });
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.roles !== this.props.roles) {
      this.setState({
        roles: this.props.roles,
      });
    }
    if (prevProps.roleOptions !== this.props.roleOptions) {
      this.setState({ roleOptions: this.props.roleOptions ?? [] });
    }
  }

  t(keyName: string) {
    return i18n.t(`RoleCard.${keyName}`);
  }

  handleError = (mainError: string, errorObject: AxiosError) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  toggleModalAddRole = () => {
    this.setState({
      showModalAddRole: !this.state.showModalAddRole,
    });
  };

  toggleDeleteRole = () => {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
    });
  };

  /**
   * Method that shows the Add Role Modal
   */
  addNewRole = () => {
    // Filter out the already existing role from the options
    const roleOptions = this.state.roleOptions.filter(
      (roleOption) =>
        !this.state.roles.some(
          (existingRole) => existingRole.roleId === roleOption.value
        )
    );
    this.setState({
      modalForm: (
        <AddRoleModal
          subjectId={this.props.subjectId}
          subjectType={this.props.subjectType}
          roleOptions={roleOptions}
          onSave={this.onSaveRole}
          onCancel={this.toggleModalAddRole}
        />
      ),
    });
    this.toggleModalAddRole();
  };

  /**
   * Method that adds saved role to the current list of roles on the frontend
   * @param role The subject2role Role
   */
  onSaveRole = (role: ISubject2Role) => {
    this.toggleModalAddRole();
    const { roles } = this.state;
    roles.push(role);
    this.setState({
      roles,
    });
  };

  /**
   * Method that handles deletion of a role
   * @param roleId The id of the role
   * @returns
   */
  deleteRole = (roleId: number) => {
    if (roleId === NO_ID) return;

    this.toggleDeleteRole();
    this.deleteRoleEvent = () =>
      axios.employee
        .delete(`subject-2-roles/${roleId}`)
        .then(() => {
          this.setState({
            roles: this.state.roles.filter((role) => role?.id !== roleId),
          });
        })
        .catch((error: AxiosError) => {
          const mainError = this.t('failedToDeleteRole');
          this.handleError(mainError, error);
        });
  };

  prepareTableData = (roles: ISubject2Role[]) => {
    const rolesData = this.state.roleOptions;
    if (!isEmpty(roles)) {
      const newTableData: IEntry[] = [];
      roles.forEach((role) => {
        const entry: IEntry = {
          role: (
            <Link
              to={`/settings/permissions/role-details/${
                role.roleId ?? (NO_ID as number)
              }`}
            >
              {
                rolesData.find((roleData) => roleData.value === role.roleId)
                  ?.label
              }
            </Link>
          ),
          description:
            rolesData.find((roleData) => roleData.value === role.roleId)
              ?.description ?? '',
          delete: (
            <Button
              color="primary"
              onClick={() => this.deleteRole(role.id ?? (NO_ID as number))}
            >
              {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
            </Button>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    }
    return [];
  };

  render() {
    const { roles } = this.state;
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('role'),
        accessor: 'role',
        show: 'true',
        filterkey: 'type',
        showsearch: 'true',
      },
      {
        type: 'data',
        header: this.t('description'),
        accessor: 'description',
        show: 'true',
        filterkey: 'type',
        showsearch: 'true',
      },
      {
        type: 'data',
        header: this.t('delete'),
        accessor: 'delete',
        show: 'true',
        alignRight: 'true',
      },
    ];
    return (
      <Container fluid>
        <Card>
          <CardHeader>
            <h2>{this.props.cardTitle ?? this.t('cardTitle')}</h2>

            {this.props.showRoleAdd ? (
              <Button
                className="float-end"
                color="primary"
                onClick={() => this.addNewRole()}
              >
                {this.props.buttonName ?? this.t('addRole')}
              </Button>
            ) : null}
          </CardHeader>
          <CardBody>
            {!isEmpty(roles) ? (
              <DynamicTable
                data={this.prepareTableData(roles)}
                columns={preparedColumns}
              />
            ) : (
              this.t('roleEmpty')
            )}
          </CardBody>
        </Card>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={this.t('error')}
        />
        <ModalForm
          isOpen={this.state.showModalAddRole}
          eventOnClose={this.toggleModalAddRole}
          ref={this.state.modalForm}
          modalTitle={this.t('addRole')}
          size="m"
        >
          {this.state.modalForm}
        </ModalForm>
        <ModalDelete
          isOpen={this.state.showModalDelete}
          event={this.deleteRoleEvent}
          onClose={this.toggleDeleteRole}
          modalTitle={this.t('deleteRole')}
          modalBodyText={this.t('confirmDeleteRole')}
        />
      </Container>
    );
  }
}

export default RoleCard;
