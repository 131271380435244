import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { Badge, Collapse } from 'reactstrap';

import avatar from '../../assets/img/avatars/silhouette-avatar.jpg';
import icons from '../../assets/img/icons/INV_Logo_White.png';
import Auth from '../../services/axios/Auth';
import { READ, READWRITE } from '../../utils/constants';
import { RootState } from '../../redux/store';
import routes from '../../routes/index';
import { IRoute, IRouteCategory } from '../../utils/types/commonTypes';
import { checkConfiguration } from '../../utils/helpers/configurationValue';

interface ISidebarCategoryProps extends RouteComponentProps {
  name: string;
  badgeColor?: string | undefined;
  badgeText?: string | undefined;
  icon: IconDefinition | IconProp;
  isOpen: boolean;
  children: ReactNode;
  onClick: () => void;
  to: string;
}

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }: ISidebarCategoryProps) => {
    const getSidebarItemClass = (path: string) =>
      location.pathname.indexOf(path) !== -1 ||
      (location.pathname === '/' && path === '/dashboard')
        ? 'active'
        : '';

    return (
      <li className={`sidebar-item ${getSidebarItemClass(to)}`}>
        <span
          data-bs-toggle="collapse"
          className={`sidebar-link ${!isOpen ? 'collapsed' : ''}`}
          onClick={onClick}
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle me-2"
          />{' '}
          <span className="align-middle" style={{ fontSize: '95%' }}>
            {name}
          </span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className="sidebar-dropdown list-unstyled">
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

// Get sub menu url based on the current location pathname
// Ex. http://localhost/customer/customers/customer-details/{:id}
// Get the sub menu url:  "/customer/customers"
const getSubMenuUrl = (currentLocationPath: string) => {
  const subMenuUrlStrings = currentLocationPath.split('/');
  return subMenuUrlStrings.slice(0, 3).join('/');
};

interface ISidebarItemProps extends RouteComponentProps {
  name: string | undefined;
  badgeColor?: string | undefined;
  badgeText?: string | undefined;
  icon?: IconProp;
  to: string;
}

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to }: ISidebarItemProps) => {
    const getSidebarItemClass = (path: string) => {
      const retrievedSubMenuUrl = getSubMenuUrl(location.pathname);

      return retrievedSubMenuUrl === path ? 'active' : '';
    };

    return (
      <li className={`sidebar-item ${getSidebarItemClass(to)}`}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {icon ? (
            <>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle me-2"
              />{' '}
              <span className="align-middle" style={{ fontSize: '95%' }}>
                {name}
              </span>
            </>
          ) : (
            name
          )}{' '}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

interface ISidebarProps extends PropsFromRedux, RouteComponentProps {}

interface ISidebarComponentState {
  [key: number]: boolean;
}

class Sidebar extends React.Component<ISidebarProps, ISidebarComponentState> {
  constructor(props: ISidebarProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { location } = this.props;
    const { pathname: pathName } = location;

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      this.setState(() => ({
        [index]: isActive,
      }));
    });
  }

  toggle = (index: number) => {
    // Collapse all elements
    Object.keys(this.state).forEach(
      (item) =>
        // eslint-disable-next-line react/destructuring-assignment
        this.state[index] ||
        this.setState(() => ({
          [item]: false,
        }))
    );

    // Toggle selected element
    this.setState((state) => ({
      [index]: !state[index],
    }));
  };

  render() {
    const { sidebar, account } = this.props;
    const { isOpen } = sidebar;

    return (
      <nav className={classNames('sidebar', !isOpen ? 'toggled' : '')}>
        <div className="sidebar-content">
          <a className="sidebar-brand text-end" href="/">
            <img
              style={{ width: '225px', height: '45px' }}
              src={icons}
              alt="Company Logo"
            />
          </a>

          <div className="sidebar-user">
            <img
              src={avatar}
              className="img-fluid rounded-circle mb-2"
              alt="Admin"
            />
            <div className="font-weight-bold">
              {`${account?.employeeDetails?.firstname} ${account?.employeeDetails?.name}`}
            </div>
          </div>
          <ul className="sidebar-nav">
            {routes.map((category: IRouteCategory, index: number) =>
              Auth.hasPermission(category.permissionUri, [READ, READWRITE]) ? (
                <>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}
                  {}
                  {category.children ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      // eslint-disable-next-line react/destructuring-assignment
                      isOpen={!!this.state[index]}
                      onClick={() => this.toggle(index)}
                    >
                      {category.children.map(
                        (route: IRoute) =>
                          Auth.hasPermission(route.permissionUri, [
                            READ,
                            READWRITE,
                          ]) &&
                          (route.configurationUri ? (
                            checkConfiguration(
                              route.configurationUri.service,
                              route.configurationUri.configKey,
                              route.configurationUri.configValue
                            ) && (
                              <SidebarItem
                                key={route.name}
                                name={route.name}
                                to={route.path}
                                badgeColor={route.badgeColor}
                                badgeText={route.badgeText}
                              />
                            )
                          ) : (
                            <SidebarItem
                              key={route.name}
                              name={route.name}
                              to={route.path}
                              badgeColor={route.badgeColor}
                              badgeText={route.badgeText}
                            />
                          ))
                      )}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                      name={category.name}
                      to={category.path}
                      icon={category.icon}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                    />
                  )}
                </>
              ) : null
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (store: RootState) => ({
  sidebar: store.sidebar,
  account: store.account,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(Sidebar));
