import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Container,
  CardHeader,
  Card,
  CardBody,
  Row,
  Button,
  Col,
  Label,
  Input,
} from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ConnectedProps, connect } from 'react-redux';
import Select from 'react-select';

import { RootState } from '../../../redux/store';
import UtilizationTable from './UtilizationTable';
import { getMonthRange, t } from './utilizationHelper';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { getEmployeeNames } from '../../../services/api/employee';
import { MONTH_FILTER_OPTIONS_ENUM } from '../../../utils/enums/projectUtilization';
import {
  objectNameAndIdToDropdownOptions,
  monthFilterOptions,
} from '../../../utils/helpers/dropdown';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { IObjectTimespan } from '../ResourceOverview/resourceOverviewHelper';

interface IProps
  extends RouteComponentProps,
    PropsFromRedux,
    IWithModalsProps {}

export interface IFilterOptions {
  responsibles: IDropdownOption<number>[];
  bookedPercentage: number;
  month: IDropdownOption;
}

/**
 * Utilization Overview
 * Displays the utilization of employees in each project for 3 months
 * Allowing th user to filter the data shown by Responsible, Booking Percentage, and Month
 */
const UtilizationOverview = ({
  location,
  currentUser,
  modalErrorHandler,
}: IProps) => {
  const { id: currentUserId, firstname, name } = currentUser;

  const [monthRange, setMonthRange] = useState<IObjectTimespan[]>(
    [] as IObjectTimespan[]
  );
  const [responsibleFilterOptions, setResponsibleFilterOptions] = useState<
    IDropdownOption<number>[]
  >([]);
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({
    responsibles: [],
    bookedPercentage: 100,
    month: {
      label: MONTH_FILTER_OPTIONS_ENUM.thisMonth.name,
      value: MONTH_FILTER_OPTIONS_ENUM.thisMonth.code,
    },
  });

  const {
    responsibles: responsiblesFilter,
    bookedPercentage,
    month: monthFilter,
  } = filterOptions;
  const { pathname } = location;

  const handleResponsibleFilterChange = (
    selectedResponsible: IDropdownOption<number>[]
  ) => {
    setFilterOptions((values) => ({
      ...values,
      responsibles: selectedResponsible,
    }));
  };

  const fetchResponsibleOptions = async () => {
    try {
      const { data: employees } = await getEmployeeNames({});
      const employeeOptions = objectNameAndIdToDropdownOptions(employees);
      // Set Options
      setResponsibleFilterOptions(employeeOptions);
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveEmployees'), error);
    }
  };

  const handleBookingChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setFilterOptions((values) => ({
      ...values,
      bookedPercentage: parseInt(value, 10),
    }));
  };

  const handleMonthFilterChange = (selectedMonthFilter: IDropdownOption) => {
    setFilterOptions((values) => ({ ...values, month: selectedMonthFilter }));
  };

  const handleApplyFilterButton = () => {
    setMonthRange(getMonthRange(monthFilter.value));
  };

  useEffect(() => {
    fetchResponsibleOptions();
    setMonthRange(getMonthRange(monthFilter.value));
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('utilizationOverview')}</HeaderTitle>
        {generateBreadcrumb(pathname, t('utilizationOverview'))}
      </Header>
      <Card>
        <CardHeader>
          <h1>{t('utilizationOverview')}</h1>
        </CardHeader>
        <CardBody>
          <Row className="d-flex justify-content-between">
            <Col>
              <h2>{t('filterOptions')}</h2>
            </Col>
            <Col>
              <Button
                className="float-end"
                color="primary"
                onClick={handleApplyFilterButton}
              >
                {t('applyFilter')}
              </Button>
            </Col>
          </Row>
          <Row className="my-3">
            <Col xs="6">
              <Label>{t('filterByResponsible')}</Label>
              <Select
                aria-label="utilization-overview-responsible-filter"
                defaultValue={[
                  {
                    value: currentUserId,
                    label: `${firstname} ${name}`,
                  } as IDropdownOption<number>,
                ]}
                values={responsiblesFilter}
                options={responsibleFilterOptions}
                onChange={handleResponsibleFilterChange}
                isMulti
                isClearable
              />
            </Col>
            <Col>
              <Label>{t('bookedWithPercentageOrLess')}</Label>
              <Input
                aria-label="utilization-overview-booking-filter"
                type="number"
                min="0.00"
                value={bookedPercentage}
                onChange={handleBookingChange}
              />
            </Col>
            <Col>
              <Label>{t('month')}</Label>
              <Select
                aria-label="utilization-overview-month-filter"
                value={monthFilter}
                options={monthFilterOptions}
                onChange={handleMonthFilterChange}
              />
            </Col>
          </Row>
          <UtilizationTable monthRange={monthRange} filters={filterOptions} />
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  currentUser: store.account.employeeDetails,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withModals(withRouter(connector(UtilizationOverview)));
