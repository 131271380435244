import React, { ChangeEvent, useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import {
  ICustomerSite,
  ICustomerSiteSector,
  IExtendedContactPerson,
  ISector,
} from '../../../utils/types/modelTypes';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { Ownership } from '../../../utils/enums/ownership';
import {
  getExtendedContactPerson,
  getExtendedContactPersonCallCount,
  getExtendedContactPersonCallOverdueCount,
} from '../../../services/api/contactPerson';
import { isEmpty } from '../../../utils/helpers/array';
import { contactStatus, customerStateEnum } from '../../../utils/enums/enum';
import { translate } from './contactListHelpers';
import { dataGeneration } from '../../../utils/constants';
import { getSectorOptions } from '../../../services/api/campaignManager';

interface IProps extends IWithModalsProps {
  modal: boolean;
  toggle: () => void;
  confirm: (
    payload: any,
    queryParameters: {
      [key: string]: any;
    }
  ) => Promise<void> | void;
}

const FILTER_INFORMATION_BOX = `${translate('filterInformationBox')} 
* ${translate('customerSectorFilterInfo')}
* ${translate('customerStateFilterInfo')}`;

const ContactListCallingModal: React.FC<IProps> = ({
  modalErrorHandler,
  confirm,
  modal,
  toggle,
}) => {
  const [sectorOptions, setSectorOptions] = useState<
    IDropdownOption<number | undefined>[]
  >([]);
  const [sites, setSites] = useState<IDropdownOption<number>[]>([]);
  const [sectorIds, setSectorIds] = useState<number[]>([]);
  const [siteIds, setSiteIds] = useState<number[]>([]);
  const [warmCalling, setWarmCalling] = useState<boolean>(true);
  const [coldCalling, setColdCalling] = useState<boolean>(true);
  const [overdueCalls, setOverdueCalls] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(true);
  const [prio1, setPrio1] = useState<boolean>(true);
  const [prio2, setPrio2] = useState<boolean>(true);
  const [prio3, setPrio3] = useState<boolean>(true);
  const [totalCalls, setTotalCalls] = useState<number>(0);
  const [overdueCallsCount, setOverdueCallsCount] = useState<number>(0);
  const [targetCalls, setTargetCalls] = useState<number>(50);
  const [customerSites, setCustomerSites] = useState<ICustomerSite[]>([]);
  const [customerSiteSectors, setCustomerSiteSectors] = useState<
    ICustomerSiteSector[]
  >([]);

  /**
   * Creates an array of site options for a dropdown, including an "All Sites" option.
   *
   * @param options An optional array of customer sites.
   * @returns An array of dropdown options, with the "All Sites" option first.
   */
  const createSiteOptions = (options?: ICustomerSite[]) => {
    const sites = [];
    sites.push({
      value: -1,
      label: 'All Sites',
    });
    if (options) {
      const filteredOptions = options.map((site) => ({
        label: site?.name ?? '',
        value: site.id as number,
      }));

      sites.push(...filteredOptions);
    }
    return sites;
  };

  /**
   * Fetches data about the number of calls and overdue calls for the current user.
   */
  const fetchData = async () => {
    const defaultQueryParameters: { [key: string]: any } = {
      'ownership.in': Ownership.RESPONSIBLE,
      'customerSiteId.in': siteIds,
      'callFlag.in': false,
    };
    const callCountQueryParameters = { ...defaultQueryParameters };
    const callOverdueCountQueryParameters = { ...defaultQueryParameters };

    // Handles both coldCalling and warmCalling
    if (coldCalling && warmCalling) {
      callCountQueryParameters['assignmentState.notIn'] = [
        contactStatus.disqualified.code,
        contactStatus.prospect.code,
      ];
      callOverdueCountQueryParameters['assignmentState.notIn'] = [
        contactStatus.disqualified.code,
        contactStatus.prospect.code,
      ];
    }
    // Handles coldCalling
    else if (coldCalling) {
      callCountQueryParameters['assignmentState.in'] = [
        contactStatus.cold.code,
        contactStatus.recall.code,
      ];
      callOverdueCountQueryParameters['assignmentState.in'] = [
        contactStatus.cold.code,
        contactStatus.recall.code,
      ];
    }
    // Handles warmCalling
    else if (warmCalling) {
      callCountQueryParameters['assignmentState.notIn'] = [
        contactStatus.disqualified.code,
        contactStatus.prospect.code,
        contactStatus.cold.code,
        contactStatus.recall.code,
      ];
      callOverdueCountQueryParameters['assignmentState.notIn'] = [
        contactStatus.disqualified.code,
        contactStatus.prospect.code,
        contactStatus.cold.code,
        contactStatus.recall.code,
      ];
    }

    try {
      const ecpCallCount = await getExtendedContactPersonCallCount(
        callCountQueryParameters
      ).then((response) => response.data);
      setTotalCalls(ecpCallCount);
    } catch (error) {
      modalErrorHandler(translate('failedToRetrieveCallCount'), error);
    }

    try {
      const ecpCallOverdueCount =
        await getExtendedContactPersonCallOverdueCount(
          callOverdueCountQueryParameters
        ).then((response) => response.data);
      setOverdueCallsCount(ecpCallOverdueCount);
    } catch (error) {
      modalErrorHandler(translate('failedToRetrieveCallOverdueCount'), error);
    }
  };

  useEffect(() => {
    getSectorOptions()
      .then((response) => {
        if (!isEmpty(response.data)) {
          const fetchedSectorOptions = response.data.map((sector: ISector) => ({
            value: sector.id,
            label: sector.sector,
          }));
          fetchedSectorOptions.unshift({
            value: -1,
            label: translate('allIndustries'),
          });
          fetchedSectorOptions.concat(
            response.data.map((sector) => ({
              value: sector.id,
              label: sector.sector,
            }))
          );
          setSectorIds(
            response.data.map((sector) => sector.id as number).concat(0)
          );
          setSectorOptions(fetchedSectorOptions);
        }
      })
      .catch((error) => {
        modalErrorHandler(dataGeneration, error);
      });

    getExtendedContactPerson({
      'ownership.in': `${Ownership.RESPONSIBLE}`,
      'assignmentState.notIn': [
        contactStatus.disqualified.code,
        contactStatus.prospect.code,
      ],
    })
      .then((response) => {
        let customerSiteSectors: ICustomerSiteSector[] = [];
        for (const contact of response.data) {
          const contactCustomerSiteSectors = contact?.customerSiteSectors;
          if (!isEmpty(contactCustomerSiteSectors)) {
            customerSiteSectors = customerSiteSectors.concat(
              contactCustomerSiteSectors as ICustomerSiteSector[]
            );
          } else {
            const contactCustomerSite = contact?.contactPerson?.customerSite;
            if (contactCustomerSite) {
              const customerSiteSector: ICustomerSiteSector = {
                customerSite: contactCustomerSite,
              };
              customerSiteSectors.push(customerSiteSector);
            }
          }
        }
        setCustomerSiteSectors(customerSiteSectors);

        // As default, show sites based on 'ACTIVE' customer
        // Input all the raw valid customer sites
        const rawSites = response.data
          .filter(
            (contact: IExtendedContactPerson) =>
              contact.contactPerson.customerSite !== null
          )
          .map(
            (contact: IExtendedContactPerson) =>
              contact.contactPerson.customerSite as ICustomerSite
          );

        // Clean out all the duplicates
        const cleanedSites = rawSites.filter(
          (value: ICustomerSite, index: number, self: ICustomerSite[]) =>
            index === self.findIndex((t) => t.id === value.id)
        );

        // The initial value of all sites
        const sites = createSiteOptions(cleanedSites);

        // Get All the active sites and make the the initial values
        const filteredSites = cleanedSites.filter(
          (site: ICustomerSite) =>
            site?.customerSiteState === customerStateEnum.active.code &&
            site?.id
        );
        const customerSiteIds = filteredSites.map(
          (site: ICustomerSite) => site.id as number
        );

        const validCustomerSites = response.data.filter(
          (extendedContactPerson) =>
            extendedContactPerson.contactPerson.customerSite !== null
        );
        setSites(sites);
        setSiteIds(customerSiteIds);
        setCustomerSiteSectors(customerSiteSectors);
        setCustomerSites(
          validCustomerSites.map(
            (contact: IExtendedContactPerson) =>
              contact.contactPerson.customerSite as ICustomerSite
          )
        );
      })
      .catch((error) => {
        modalErrorHandler(dataGeneration, error);
      });
  }, []);

  const filterSites = () => {
    let siteOptions: ICustomerSite[] = [];
    let newSiteIds: number[] = [];
    let filteredCustomerSiteSectors: ICustomerSiteSector[] = [];
    let filteredSiteIds: number[] = [];
    let filteredCustomerSites: ICustomerSite[] = [];
    if (!isEmpty(sectorIds)) {
      // Filter the customerSiteSector based on the selected sectors
      filteredCustomerSiteSectors = customerSiteSectors?.filter((siteSector) =>
        sectorIds.some((id) => id === siteSector?.sector?.id)
      );

      // Filtered siteIds based on filteredCustomerSiteSectors
      filteredSiteIds = siteIds?.filter(
        (id) =>
          filteredCustomerSiteSectors.some(
            (customerSiteSector) => customerSiteSector?.customerSite?.id === id
          ) ||
          !customerSiteSectors.some(
            (siteSector) => siteSector.customerSite?.id === id
          )
      );

      // FilteredCustomerSites based on filteredCustomerSiteSectors
      filteredCustomerSites = customerSites.filter(
        (site) =>
          filteredCustomerSiteSectors.some(
            (customerSiteSector) =>
              customerSiteSector?.customerSite?.id === site?.id
          ) ||
          !customerSiteSectors.some(
            (siteSector) => siteSector.customerSite?.id === site?.id
          )
      );
    }

    const priority: number[] = [];
    if (prio1) {
      priority.push(1);
    }
    if (prio2) {
      priority.push(2);
    }
    if (prio3) {
      priority.push(3);
    }
    if (active) {
      siteOptions = siteOptions.concat(
        filteredCustomerSites.filter(
          (site) => site?.customerSiteState === customerStateEnum.active.code
        )
      );
      newSiteIds = newSiteIds.concat(
        filteredSiteIds.filter((id) =>
          filteredCustomerSites.some((site) => site?.id === id)
        )
      );
    }
    if (!active) {
      siteOptions = siteOptions.concat(
        filteredCustomerSites.filter(
          (site) => site.customer?.state !== customerStateEnum.active.code
        )
      );
      newSiteIds = newSiteIds.concat(
        filteredSiteIds.filter((id) =>
          siteOptions.some((site) => site?.id === id)
        )
      );
    }
    if (priority.length > 0) {
      siteOptions = siteOptions.concat(
        filteredCustomerSites.filter((site) =>
          priority.some((priority) => priority === site?.customer?.priority)
        )
      );
      newSiteIds = newSiteIds.concat(
        filteredSiteIds.filter((id) =>
          siteOptions.some((site) => site?.id === id)
        )
      );
    }
    siteOptions = siteOptions.filter(
      (site, index) =>
        siteOptions.findIndex(
          (customerSite) => customerSite?.id === site?.id
        ) === index
    );
    newSiteIds = newSiteIds.filter(
      (site, index) => newSiteIds.findIndex((id) => site === id) === index
    );
    const newSiteOptions = createSiteOptions(siteOptions);
    setSites(newSiteOptions);
    setSiteIds(newSiteIds);
  };

  useEffect(() => {
    filterSites();
  }, [active, prio1, prio2, prio3]);

  const handleMultipleSectorChange = (
    selectedSectors: IDropdownOption<number | undefined>[]
  ) => {
    if (isEmpty(selectedSectors)) {
      setSectorIds([]);
      createSiteOptions();
      return;
    }

    let newSectors = selectedSectors;
    if (newSectors.some((newSector) => newSector.value === -1)) {
      newSectors = sectorOptions.filter((sector) => sector.value !== -1);
    }
    const sectorIds = newSectors.map((sector) => Number(sector.value));
    setSectorIds(sectorIds);
    filterSites();
  };

  const handleMultipleSitesChange = (
    selectedSites: IDropdownOption<number>[]
  ) => {
    if (isEmpty(selectedSites)) {
      setSiteIds([]);
      return;
    }
    let newSites = selectedSites;
    if (newSites.some((site) => site.value === -1)) {
      newSites = sites.filter((site) => site.value !== -1);
    }
    setSiteIds(newSites.map((site) => Number(site.value)));
  };

  const handleConfirm = async () => {
    const queryParameters: { [key: string]: any } = {
      'ownership.in': Ownership.RESPONSIBLE,
      'customerSiteId.in': siteIds,
      'callFlag.in': false,
    };
    if (coldCalling && warmCalling) {
      queryParameters['assigmentState.notIn'] = [
        contactStatus.disqualified.code,
        contactStatus.prospect.code,
      ];
    } else if (coldCalling) {
      queryParameters['assigmentState.in'] = [
        contactStatus.cold.code,
        contactStatus.recall.code,
      ];
    } else if (warmCalling) {
      queryParameters['assigmentState.notIn'] = [
        contactStatus.disqualified.code,
        contactStatus.prospect.code,
        contactStatus.cold.code,
        contactStatus.recall.code,
      ];
    }
    const payload = {
      overdueCalls,
      targetCalls,
    };

    await confirm(payload, queryParameters);
  };

  useEffect(() => {
    fetchData();
  }, [warmCalling, coldCalling, siteIds]);

  useEffect(() => {
    filterSites();
  }, [active, prio1, prio2, prio3]);

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>{translate('createCallList')}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Row>
              <Col md={3}>
                <Label>{translate('customerSector')}</Label>
              </Col>
              <Col>
                <Select
                  isMulti
                  options={sectorOptions}
                  value={sectorOptions?.filter((sectorOption) =>
                    sectorIds?.some((id) => id === Number(sectorOption.value))
                  )}
                  onChange={handleMultipleSectorChange}
                  isClearable
                  placeholder={translate('selectIndustries')}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>{translate('customerState')}</Label>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Label>
                      <Input
                        type="checkbox"
                        checked={active}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          setActive(event.target.checked)
                        }
                      />
                      {translate('active')}
                    </Label>
                  </Col>
                  <Col>
                    <Label>
                      <Input
                        type="checkbox"
                        checked={prio1}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          setPrio1(event.target.checked)
                        }
                      />
                      {translate('prio1')}
                    </Label>
                  </Col>
                  <Col>
                    <Label>
                      <Input
                        type="checkbox"
                        checked={prio2}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          setPrio2(event.target.checked)
                        }
                      />
                      {translate('prio2')}
                    </Label>
                  </Col>
                  <Col>
                    <Label>
                      <Input
                        type="checkbox"
                        checked={prio3}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          setPrio3(event.target.checked)
                        }
                      />
                      {translate('prio3')}
                    </Label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>{translate('callType')}</Label>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Label>
                      <Input
                        type="checkbox"
                        onClick={(event: ChangeEvent<HTMLInputElement>) =>
                          setWarmCalling(event.target.checked)
                        }
                        checked={warmCalling}
                      />
                      {translate('warmCalling')}
                    </Label>
                  </Col>
                  <Col>
                    <Label>
                      <Input
                        type="checkbox"
                        onClick={(event: ChangeEvent<HTMLInputElement>) =>
                          setColdCalling(event.target.checked)
                        }
                        checked={coldCalling}
                      />
                      {translate('coldCalling')}
                    </Label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>{translate('overdueCalls')}</Label>
              </Col>
              <Col>
                <Label>
                  <Input
                    type="checkbox"
                    checked={overdueCalls}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setOverdueCalls(event.target.checked)
                    }
                  />
                  {translate('prioritizeOverdue')}
                </Label>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>{translate('customerSiteSpecific')}</Label>
              </Col>
              <Col>
                <Select
                  isMulti
                  options={sites}
                  value={sites?.filter((site) =>
                    siteIds?.some((id) => id === Number(site.value))
                  )}
                  onChange={handleMultipleSitesChange}
                  isClearable
                  placeholder={translate('selectSites')}
                />
              </Col>
            </Row>
          </Col>
          <Col md={3}>
            <Label>{translate('possibleCalls')}</Label>
            <Input type="number" disabled value={totalCalls} />
            <Label>{translate('overdueCalls')}</Label>
            <Input type="number" disabled value={overdueCallsCount} />
            <Label>{translate('targetCalls')}</Label>
            <Input
              type="number"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setTargetCalls(Number(event.target.value))
              }
              max={totalCalls}
              min={1}
              value={targetCalls <= totalCalls ? targetCalls : totalCalls}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Input
              id="infoBox"
              bsSize="lg"
              type="textarea"
              rows={3}
              value={FILTER_INFORMATION_BOX}
              readOnly
              disabled
              style={{ fontSize: '15px', resize: 'none' }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleConfirm}>
          {translate('confirm')}
        </Button>
        &nbsp;
        <Button color="dark" onClick={toggle}>
          {translate('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withModals(ContactListCallingModal);
