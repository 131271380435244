import moment, { Moment } from 'moment';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import {
  IAccountState,
  IProjectListFilters,
} from '../../../utils/types/stateTypes';
import { DurationType, EntityType } from '../../../utils/enums/pageComponents';
import {
  AcquisitionType,
  ProjectEmployeeRole,
  ProjectEmployeeState,
  ProjectEmployeeWorkloadState,
  ProjectLocationTypeEnum,
  ProjectState,
  ProjectType,
  StaffedOption,
} from '../../../utils/enums/project';
import { isEmpty } from '../../../utils/helpers/array';
import i18n from '../../../i18n';
import {
  createLinkIfAuthorised,
  userHasPermission,
} from '../../../utils/helpers/permission';
import {
  IOfferListItem,
  IProjectDetail,
  IProjectListItem,
  IProjectMemberListItem,
} from '../../../utils/types/responseTypes';
import {
  getDuration,
  getNearestWorkingDay,
  getNumberWorkDays,
} from '../../../utils/helpers/date';
import {
  IApprovalRequest,
  IEmployee,
  IProject,
} from '../../../utils/types/modelTypes';
import { round } from '../../../utils/helpers/math';
import { NO_ID } from '../../../utils/constants';
import { ContactPersonFunction } from '../../../utils/enums/contactPerson';
import {
  IDropdownOption,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import { acquisitionTypeEnum, staffedEnum } from '../../../utils/enums/enum';
import type { ProjectDetailsForm } from './CreateNewProjectModal';
import { OfferContractType, OfferState } from '../../../utils/enums/offer';
import { DurationTypeEnum } from '../ProjectDetails/projectDetailHelper';
import {
  dropdownOptionToObjectNameAndId,
  dropdownOptionsToObjectNameAndId,
} from '../../../utils/helpers/dropdown';

/**
 * Translator function for Project List
 * @param keyName Key for phrase to be translated
 * @returns Translated string
 */
export const t = (keyName: string) => i18n.t(`ProjectsProjectList.${keyName}`);

/**
 * Date format based on selected language
 */
export const dateFormat = moment.localeData(i18n.language).longDateFormat('L');

/**
 * Creates the query parameters to be used to fetch data
 * @returns Object containing query parameters
 */
export const createQueryParameters = (
  page: number,
  pageSize: number,
  {
    customer,
    customerSite,
    projectId,
    title,
    responsible,
    state,
  }: IProjectListFilters,
  sortBy: string,
  sortType: string
) => ({
  page: page.toString(),
  size: pageSize.toString(),
  ...(customer && {
    'customerName.contains': customer,
  }),
  ...(projectId && {
    'projectId.contains': projectId,
  }),
  ...(title && {
    'title.contains': title,
  }),
  ...(customerSite && {
    'customerSiteName.contains': customerSite,
  }),
  ...(!isEmpty(responsible) && {
    'responsibleId.in': responsible.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(state) && {
    'state.in': state.map(({ value }) => value).join(','),
  }),
  ...(sortBy &&
    sortType && {
      sort: `${sortBy}%2C${sortType}`,
    }),
});

/**
 * Creates the project cell for the project list table
 * @param id Id of project
 * @param title Title of project
 * @param projectType Type of project
 * @param onUnbilledProjectUpdate Function to run onClick for unbilled projects
 * @returns
 */
const createProjectCellComponent = (
  id: number,
  title: string,
  projectType: string,
  onUnbilledProjectUpdate: () => Promise<void>
) => {
  if (userHasPermission(EntityType.PROJECT, id)) {
    return projectType === ProjectType.INVESTMENT_PROJECT ? (
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      <Link to="#" onClick={onUnbilledProjectUpdate}>
        {title}
      </Link>
    ) : (
      <Link to={`/projects/overview/project-details/${id}`}>{title}</Link>
    );
  }

  return title;
};

/**
 * Creates the link for the project
 * @param id Id of project
 * @param title Title of project
 * @returns
 */
export const creactProjectDetailLink = (id: number, title: string) =>
  id ? (
    <Link to={`/projects/overview/project-details/${id}`}>{title}</Link>
  ) : (
    <div>{title}</div>
  );

/**
 * Creates project list entry (row) in Project List Table
 * @param projectListItem Item to create a row for in Project List Table
 * @returns Row / Project Entry for Project List Table
 */
export const createProjectEntry = (
  {
    id,
    title,
    customer: { id: customerId, name: customerName },
    projectId,
    customerSite: { id: customerSiteId, name: customerSiteName },
    responsible: { id: responsibleId, name: responsibleName },
    state,
    projectType,
  }: IProjectListItem,
  onUnbilledProjectUpdate: () => Promise<void>,
  onDelete: () => void
) => ({
  customer: createLinkIfAuthorised(
    EntityType.CUSTOMER,
    customerName,
    customerId
  ),
  customerSite: createLinkIfAuthorised(
    EntityType.CUSTOMER_SITE,
    customerSiteName,
    customerSiteId
  ),
  projectId,
  title: createProjectCellComponent(
    id,
    title,
    projectType,
    onUnbilledProjectUpdate
  ),
  responsible: createLinkIfAuthorised(
    EntityType.EMPLOYEE,
    responsibleName,
    responsibleId
  ),
  state: i18n.t(`ProjectStates.${state.toUpperCase()}`),
  menu: userHasPermission(EntityType.PROJECT, id) ? (
    <div>
      <Button
        color="primary"
        onClick={() => onDelete()}
        aria-label="button-delete"
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </div>
  ) : (
    <div />
  ),
});

// Constants for the date length types
export const durationTypes = ['months', 'weeks', 'days'];
export const DAYS = 'days';
export const dropdownProjectStateItems = [
  ProjectState.ACTIVE,
  ProjectState.ORDERED,
  ProjectState.PAUSED,
  ProjectState.CLOSED,
  ProjectState.CANCELLED,
].map((option) => ({
  value: option,
  label: i18n.exists(`ProjectStates.${option}`)
    ? i18n.t(`ProjectStates.${option}`)
    : option,
}));
export interface IDurationObject {
  days: any;
  months: any;
  weeks: any;
}

export const cupt = (keyName: string) =>
  i18n.t(`CreateUnbilledProject.${keyName}`);

/**
 * Handles computation of project duration length
 * @param {*} startDate
 * @param {*} endDate
 * @returns duration object
 */
export const computeLength = (startDate: any, endDate: any) => {
  const start = moment(new Date(startDate));
  const end = moment(new Date(endDate));
  const durationObject = {} as IDurationObject;

  durationTypes.forEach((element) => {
    // Days requires a different set of calculations to months and weeks,
    durationObject[element as keyof IDurationObject] =
      element !== DAYS
        ? round(
            parseFloat(
              end
                .diff(start, element as moment.unitOfTime.Diff, true)
                .toFixed(1)
            ),
            0.5
          )
        : getNumberWorkDays(start, end);
  });
  return durationObject;
};

/**
 * Handles computation of project initial length
 * @param {*} project
 * @returns duration object
 */
export const computeLengthInitial = (project: IProject) => {
  const startDate = project?.start ?? null;
  const endDate = project?.end ?? null;
  type NewType = IDurationObject;

  let durationObject = {} as NewType;

  if (startDate && endDate) {
    durationObject = computeLength(
      new Date(startDate as any),
      new Date(endDate as any)
    );
  }
  return durationObject;
};

export const dateCalculatorForNonDays = (
  startDate: moment.Moment,
  valueFloat: number,
  valueType: string
) => {
  const duration = moment.duration(
    valueFloat,
    valueType as moment.unitOfTime.DurationConstructor
  );
  const asMilliseconds = duration.asMilliseconds();
  const endDate = startDate.add(asMilliseconds, 'ms');
  return moment(getNearestWorkingDay(endDate));
};

/**
 * It checks if the lsit of projectEmployees has a member as an OWNER.
 * @param projectEmployees - employees to check
 * @returns boolean - true if contains employee as an OWNER, false otherwise.
 */
export const hasNoProjectOwner = (
  projectEmployees: IProjectMemberListItem[]
) => {
  if (isEmpty(projectEmployees)) {
    return true;
  }
  if (projectEmployees.some(({ role }) => role === ProjectEmployeeRole.OWNER)) {
    return false;
  }
  return true;
};

/**
 * Evaluates whether form values are valid
 * @param formValues Project Option form values
 * @param validTitle State of title validity after checking
 * @param validAbbreviation State of customer abbreviation validity after checking
 * @returns true if all fields are valid, false otherwise.
 */
export const isCustomerProjectBilledValid = (
  {
    responsibleContactPersons,
    customer,
    customerSite,
    start,
    end,
    title,
  }: IProjectDetail,
  validTitle: boolean,
  validAbbreviation: boolean
) => {
  // Make sure that all the responsibleContactPersons are valid
  const validResponsibleContactPersons = responsibleContactPersons.every(
    (responsibleContactPerson) => responsibleContactPerson.id !== NO_ID
  );

  const areBasicFieldsValid =
    !!title &&
    customer.id !== NO_ID &&
    customerSite.id !== NO_ID &&
    !!start &&
    !!end &&
    validResponsibleContactPersons &&
    validTitle &&
    validAbbreviation;
  return areBasicFieldsValid;
};

export const getFunctionRole = (functionRole: string) => {
  const roleKey = functionRole as keyof typeof ContactPersonFunction;

  if (roleKey in ContactPersonFunction) {
    const roleValue = ContactPersonFunction[roleKey]
      .toString()
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return roleValue;
  }
  return 'N/A';
};

/**
 * Generates default form values for Projects created under project list
 * @param currentUser Details of current user
 * @returns Project form with default form values
 */
export const generateDefaultFormValues = (
  { id, firstname, name }: IEmployee,
  { defaultCurrency }: IAccountState,
  projectType: string
) => ({
  // General Information
  title: '',
  customer: {} as IDropdownOption<number>,
  customerSite: {} as IDropdownOption<number>,
  contactPersons: [] as {
    contactPerson: IDropdownOption<number>;
    role: string;
    functionRole: string;
  }[],
  projectType,

  // Budget and Schedule
  start: new Date().toISOString(),
  duration: 0,
  end: new Date().toISOString(),
  departments: [] as IDropdownOption<number>[],
  originOfOption: {} as IDropdownOption,
  state: ProjectState.ACTIVE,
  durationType: {
    label: 'Months',
    value: DurationType.MONTHS as string,
  },
  locationType: ProjectLocationTypeEnum.FULLY_ONSITE,
  // Description
  description: '',
  estimationTemplatesLink: '',
  projectSharePoint: '',

  // Responsibles
  responsible: {
    label: `${firstname} ${name}`,
    value: id as number,
  },
  involvedResponsibles: [] as {
    responsible: IDropdownOption<number>;
    responsibleRole: IDropdownOption<number>;
  }[],

  // Project Members
  projectMembers: [
    {
      id: 0,
      employee: { id: id as number, name: `${firstname} ${name}` },
      role: ProjectEmployeeRole.OWNER,
      validFrom: '',
      workloadPercentage: 100,
      workloadState: ProjectEmployeeWorkloadState.ASSIGNED,
      employeeState: ProjectEmployeeState.PLANNED,
    },
  ] as IProjectMemberListItem[],

  // Offers
  offers: [
    {
      id: 0,
      title: '',
      offerVersion: 1,
      contractType: OfferContractType.WORK_CONTRACT,
      currency: defaultCurrency,
      budget: 0,
      offerSent: '',
      offerState: OfferState.VALID,
      date: '',
    },
  ] as IOfferListItem[],

  // Activities
  staffedOption: {
    value: StaffedOption.NO,
    label: i18n.t(`StaffedEnum.${StaffedOption.NO}`),
  },
  acquisitionType: {
    value: AcquisitionType.UNDEFINED,
    label: i18n.t(`Acquisitions.${AcquisitionType.UNDEFINED}`),
  },
  activities: [],
});

/**
 * Converts duration based on start and end dates, and duration type
 * @param start Start date
 * @param end End date
 * @param durationType Type of duration
 * @returns Converted duration
 */
export const convertDuration = (
  start: Moment,
  end: Moment,
  durationType: string
) =>
  durationType === DurationType.MONTHS
    ? getNumberWorkDays(start, end)
    : getDuration(start, end, durationType);

/**
 * Evaluates whether all required fields have been answered.
 * @param formValues - Project form values.
 * @param isCheckingTitle - Indicates if currently checking the title.
 * @param isTitleValid - Indicates if the title is valid.
 * @returns Returns true if the form is valid, false otherwise.
 */
export const isInternalProjectValid = (
  { title, customer, customerSite, responsible }: ProjectDetailsForm,
  isCheckingTitle: boolean,
  isTitleValid: boolean
) => {
  const areBasicFieldsValid =
    !!title &&
    isTitleValid &&
    !isCheckingTitle &&
    !!customer.value &&
    !!customerSite.value &&
    !!responsible.value;
  return areBasicFieldsValid;
};

/**
 * Evaluates whether all required fields have been answered.
 * @param formValues - Project form values.
 * @param isCheckingTitle - Indicates if currently checking the title.
 * @param isTitleValid - Indicates if the title is valid.
 * @returns Returns true if the form is valid, false otherwise.
 */
export const isUnbilledCustomerProjectValid = (
  {
    title,
    customer,
    customerSite,
    contactPersons,
    start,
    duration,
    responsible,
    offers,
  }: ProjectDetailsForm,
  isCheckingTitle: boolean,
  isTitleValid: boolean,
  approvalRequest: IApprovalRequest
) => {
  const firstOffer = offers[0] ?? ({} as IOfferListItem);
  const validOffer = !!firstOffer.currency && !!firstOffer.budget;
  const areBasicFieldsValid =
    validOffer &&
    approvalRequest.employee &&
    !!title &&
    isTitleValid &&
    !isCheckingTitle &&
    !!customer.value &&
    !!customerSite.value &&
    !!contactPersons[0] &&
    !!contactPersons[0].contactPerson &&
    !!contactPersons[0].contactPerson.value &&
    !!start &&
    !!duration &&
    !!responsible.value;
  return areBasicFieldsValid;
};

export const createProjectDetailFromFormValues = (
  formValues: ProjectDetailsForm
) => {
  const {
    title,
    customer,
    customerSite,
    contactPersons,
    start,
    end,
    departments,
    state,
    description,
    estimationTemplatesLink,
    projectSharePoint,
    responsible,
    involvedResponsibles,
    projectMembers,
    locationType,
    projectType,
    offers,
  } = formValues;

  return {
    durationType: DurationTypeEnum.LONG_TERM,
    locationType,
    offers,
    pendingOffers: [],
    activities: [],
    fileAttachments: [],
    projectTimesheets: [],
    id: 0,
    title,
    projectId: '',
    state,
    projectType,
    customer: dropdownOptionToObjectNameAndId(customer),
    customerSite: dropdownOptionToObjectNameAndId(customerSite),
    responsible: dropdownOptionToObjectNameAndId(responsible),
    responsibleContactPersons: contactPersons
      .filter((contactPerson) => contactPerson.contactPerson)
      .map(({ contactPerson, role, functionRole }) => ({
        ...dropdownOptionToObjectNameAndId(contactPerson),
        role,
        functionRole,
        customerSite: dropdownOptionToObjectNameAndId(customerSite),
        phone: [],
        email: [],
      })),
    staffedOption: staffedEnum.no_staff.code,
    acquisitionType: acquisitionTypeEnum.undefined.code,
    start,
    end,
    duration:
      convertDuration(moment(start), moment(end), DurationType.MONTHS) || 0,
    departments: dropdownOptionsToObjectNameAndId(departments),
    description,
    estimationTemplatesLink: estimationTemplatesLink ?? '',
    involvedResponsibles: involvedResponsibles.map(
      ({ responsible, responsibleRole }) => ({
        ...dropdownOptionToObjectNameAndId(responsible),
        responsibleRole: dropdownOptionToObjectNameAndId(responsibleRole),
      })
    ),
    projectMembers,
    projectSharePoint,
  };
};
export const composeProjectMembers = (
  projectEmployees: IProjectMemberListItem[],
  employees: IObjectNameAndId[]
) =>
  projectEmployees
    .filter((projectEmployee) => !isEmpty(projectEmployee))
    .map(({ employee: { id: projectEmployeeId }, id, role }) => {
      const employee = employees.find(({ id }) => id === projectEmployeeId);

      if (employee) {
        return {
          id: id ?? (NO_ID as number),
          employee,
          role: role ?? '',
        };
      }

      return null;
    })
    .filter(Boolean) as {
    id: number;
    employee: IObjectNameAndId;
    role: string;
  }[];
