import React, { ReactElement } from 'react';

import PaginatedTable from '../tables/PaginatedTable';
import { TableData } from '../tables/Table';
import CollapsibleCard from './CollapsibleCard';

interface IProps {
  title: string;
  tableData: TableData[];
  noDataPlaceholder?: string;
  headerButtons?: ReactElement;
  striped?: boolean;
  defaultOpen?: boolean;
  defaultSize?: number;
}

/**
 * Collapsible Table Card -
 * Displays a data table, and contains title, header buttons,
 * and hamburger collapse icon
 */
const CollapsibleTableCard = ({
  title,
  tableData,
  noDataPlaceholder,
  headerButtons,
  striped,
  defaultOpen,
  defaultSize,
}: IProps) => (
  <CollapsibleCard
    title={title}
    {...(headerButtons && { headerButtons })}
    {...(defaultOpen && { defaultOpen })}
  >
    <PaginatedTable
      tableData={tableData}
      {...(noDataPlaceholder && { noDataPlaceholder })}
      {...(striped && { striped })}
      {...(defaultSize && { defaultSize })}
    />
  </CollapsibleCard>
);

export default CollapsibleTableCard;
