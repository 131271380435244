import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { handleError, isEmpty } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import AddOrUpdateCountryState from './AddOrUpdateCountryState';

/*
 * Class that shows all the sectors
 */
class CountryStateListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryStates: [],
      showModalDelete: false,
      showModalError: false,

      showModalForm: false,
      modalForm: null,
    };
    this.modalDeleteEvent = null;
    this.modalTitle = null;

    this.error = null;
    this.message = null;
  }

  t(keyName) {
    return i18n.t('CountryStateListView.' + keyName);
  }

  componentDidMount() {
    axios.sales
      .get('country-states')
      .then((response) => {
        if (!isEmpty(response?.data)) {
          let countryStates = response.data.sort((a, b) =>
            a?.name > b?.name ? 1 : -1
          );
          this.setState({ countryStates: countryStates });
        }
      })
      .catch((error) => {
        let mainError = this.t('failedToRetrieveCountryStates');
        this.handleError(mainError, error);
      });
  }

  // method to handle all errors encountered.
  // shows the modalError dialog
  handleError = (mainError, errorObject) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  toggleModalDelete = () => {
    this.setState({ showModalDelete: !this.state.showModalDelete });
  };

  toggleModalForm = () => {
    this.setState({ showModalForm: !this.state.showModalForm });
  };

  addOrUpdateCountryState = (countryState) => {
    this.modalTitle = this.t('add');
    this.setState({
      modalForm: (
        <AddOrUpdateCountryState
          countryState={countryState}
          onSave={this.onSave}
        />
      ),
    });
    this.toggleModalForm();
  };

  onSave = (countryState) => {
    let countryStates = this.state.countryStates;
    let index = countryStates.findIndex(
      (item) => item?.id === countryState?.id
    );
    if (index >= 0) {
      countryStates[index] = countryState;
    } else {
      countryStates.push(countryState);
    }
    countryStates = countryStates?.sort((a, b) => (a?.name > b?.name ? 1 : -1));
    this.setState({ countryStates: countryStates });
    if (this.state.showModalForm) {
      this.toggleModalForm();
    }
  };

  deleteCountryState = (countryStateToDelete) => {
    this.toggleModalDelete();
    this.modalDeleteEvent = async () => {
      if (countryStateToDelete?.id) {
        axios.sales
          .delete(`country-states/${countryStateToDelete.id}`)
          .then(() => {
            let newCountryStates = this.state.countryStates.filter(
              (countryState) => countryState.id !== countryStateToDelete.id
            );
            this.setState({ countryStates: newCountryStates });
          })
          .catch((error) => {
            let mainError = 'Failed to delete the country state';
            this.handleError(mainError, error);
          });
      }
    };
  };

  preparedTableData = (countryStates) => {
    const newTableData = [];
    if (!isEmpty(countryStates)) {
      countryStates.forEach((countryState) => {
        const entry = {
          id: countryState?.id,
          name: (
            <Link
              to="#"
              onClick={() => this.addOrUpdateCountryState(countryState)}
            >
              {countryState?.name}
            </Link>
          ),
          country: countryState?.country?.name ?? '',
          deleteButton: (
            <>
              <Button
                color="primary"
                onClick={() => this.deleteCountryState(countryState)}
              >
                {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
              </Button>
            </>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  render() {
    const countryStates = this.state.countryStates;
    const preparedColumns = [
      {
        type: 'component',
        header: this.t('name'),
        accessor: 'name',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('country'),
        accessor: 'country',
        show: 'true',
      },
      {
        type: 'component',
        header: this.t('delete'),
        accessor: 'deleteButton',
        show: 'true',
        alignRight: 'true',
      },
    ];
    return (
      <Container fluid>
        <div>
          <Button
            color="primary"
            className="float-end"
            onClick={() => this.addOrUpdateCountryState()}
          >
            {generateTitle(BUTTON_TITLE_ENUM.ADD.code, this.t('add'))}
          </Button>
          <br />
          <br />
        </div>

        <DynamicTable
          data={this.preparedTableData(countryStates)}
          columns={preparedColumns}
        />

        <ModalDelete
          isOpen={this.state.showModalDelete}
          event={this.modalDeleteEvent}
          onClose={this.toggleModalDelete}
          modalTitle={this.t('countryStateDelete')}
          modalBodyText={this.t('countryStateDeleteConfirm')}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={this.t('error')}
        ></ModalError>

        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleModalForm}
          ref={this.state.modalForm}
          modalTitle={this.modalTitle}
        >
          {this.state.modalForm}
        </ModalForm>
      </Container>
    );
  }
}

export default withRouter(withTranslation()(CountryStateListView));
