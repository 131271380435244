import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, ReactNode, useRef } from 'react';
import { Button, UncontrolledCollapse } from 'reactstrap';
import { v4 } from 'uuid';

interface IProps {
  title: string;
  headerButtons?: ReactElement;
  defaultOpen?: boolean;
  children: ReactNode;
}

/**
 * Collapsible Card -
 * Displays whatever is passed as children, and contains title, header buttons,
 * and hamburger collapse icon
 */
const CollapsibleCard = ({
  title,
  headerButtons,
  defaultOpen,
  children,
}: IProps) => {
  const id = useRef(v4());

  return (
    <>
      <Button
        aria-label="collapsible-card-button-collapse"
        color="outline"
        id={`collapsible-card-${id.current}`}
      >
        <div className="d-flex align-items-center gap-3">
          <FontAwesomeIcon icon={faBars} />
          <h2 style={{ margin: 0 }}>{title}</h2>
        </div>
      </Button>
      {headerButtons && <div className="float-end">{headerButtons}</div>}
      <UncontrolledCollapse
        toggler={`collapsible-card-${id.current}`}
        defaultOpen={defaultOpen}
      >
        {children}
      </UncontrolledCollapse>
    </>
  );
};

export default CollapsibleCard;
