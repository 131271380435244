import { AxiosError, AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import axios from '../../services/axios/axios';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import i18n from '../../i18n';
import {
  IDropdownOption,
  IObjectNameAndId,
} from '../../utils/types/commonTypes';
import { ITitle } from '../../utils/types/modelTypes';
import { dropdownOptionToObjectNameAndId } from '../../utils/helpers/dropdown';

interface IProps extends IWithModalsProps {
  onChange: (title: IObjectNameAndId) => void;
  selectedTitle: string | null;
}

/**
 * @deprecated simply recreate the component on the page you need
 * A component to display the dropdown of AcademicTitles
 */
const AcademicTitleDropdown = ({
  onChange,
  modalErrorHandler,
  selectedTitle,
}: IProps) => {
  const t = (keyName: string) =>
    i18n.t(`ContactPersonTitleDropdown.${keyName}`);
  const [dropdownOptions, setDropdownOptions] = useState(
    [] as IDropdownOption<number>[]
  );

  const fetchData = () => {
    axios.sales
      .get('titles')
      .then((response: AxiosResponse<ITitle[]>) => {
        const { data: fetchedTitles } = response;

        setDropdownOptions(
          fetchedTitles.map(
            (title: ITitle) =>
              ({
                label: title.title,
                value: title.id,
              } as IDropdownOption<number>)
          )
        );
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToGetAllTitles'), error);
      });
  };

  /**
   * Fetch the titles on component mount
   */
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the changes in the dropdown
   * @param {*} selectedTitle dropdown option
   */
  const handleChangeTitle = (selectedTitle: IDropdownOption) => {
    const title = dropdownOptions.find(
      ({ label }) => label === selectedTitle.label
    );
    if (title) {
      onChange(dropdownOptionToObjectNameAndId(title));
    }
  };

  return (
    <div data-testid="contact-person-title-div">
      <Select
        onChange={handleChangeTitle}
        options={dropdownOptions}
        value={dropdownOptions.filter(
          (option) => option.label === selectedTitle ?? ''
        )}
        placeholder={t('selectTitle')}
      />
    </div>
  );
};

export default withModals(AcademicTitleDropdown);
