import { AxiosError, AxiosResponse } from 'axios';
import React, { Component } from 'react';
import Select from 'react-select';
import { Button, Container, Form, FormGroup, Label } from 'reactstrap';

import axios from '../../services/axios/axios';
import { NO_ID, NO_RESTRICTION } from '../../utils/constants';
import { ROLE_ENUM } from '../../utils/enums/objectType';
import { handleError } from '../../utils/helpers/GenericHelper';
import i18n from '../../i18n';
import { IErrorMessage } from '../../utils/types/commonTypes';
import { ISubject2Role } from '../../utils/types/modelTypes';
import ModalError from '../modals/ModalError';

interface IProps {
  subjectId: number;
  subjectType: string;
  roleGroupOptions: IRoleGroupOptions[];
  onSave: (subject2role: ISubject2Role) => void;
  onCancel: () => void;
}

interface IState {
  subjectType: string;
  subjectId: number;
  roleGroupId: number;
  roleGroupOptions: IRoleGroupOptions[];
  showModalError: boolean;
}

interface IRoleGroupOptions {
  value: number;
  label: string;
  description: string;
}

/*
 * A component that contains the modal to add new role group to an item
 */
class AddRoleGroupModal extends Component<IProps, IState> {
  error: IErrorMessage = {} as IErrorMessage;

  modalTitle = '';

  constructor(props: IProps) {
    super(props);
    this.state = {
      subjectType: props.subjectType,
      subjectId: props.subjectId,
      roleGroupId: NO_ID as number,
      roleGroupOptions: props.roleGroupOptions,
      showModalError: false,
    };
  }

  t(keyName: string) {
    return i18n.t(`RoleGroupCard.${keyName}`);
  }

  handleError = (mainError: string, errorObject: AxiosError) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  /**
   * Method that handles the saving of a role group
   */
  saveRoleGroup = () => {
    if (this.state?.roleGroupId) {
      const payload = {
        subjectId: this.state.subjectId,
        subjectType: this.state.subjectType,
        roleType: ROLE_ENUM.roleGroup.code,
        roleId: this.state.roleGroupId,
        restriction: NO_RESTRICTION,
      };

      // Save the subject2role rolegroup
      axios.employee
        .post('subject-2-roles', payload)
        .then((response: AxiosResponse<ISubject2Role>) => {
          if (this.props.onSave) {
            this.props.onSave(response.data);
          }
        })
        .catch((error: AxiosError) => {
          const mainError = this.t('failedToSaveRoleGroup');
          this.handleError(mainError, error);
        });
    }
  };

  render() {
    return (
      <Container>
        <Form>
          <FormGroup>
            <Label>{this.t('selectRoleGroup')}</Label>
            <Select
              options={this.state.roleGroupOptions}
              onChange={(event: IRoleGroupOptions) =>
                this.setState({
                  roleGroupId: event.value,
                })
              }
            />
            <br />
            <div className="float-end">
              <Button
                color="primary"
                style={{ marginLeft: '5px' }}
                size="m"
                onClick={() => this.saveRoleGroup()}
              >
                {this.t('add')}
              </Button>{' '}
              <Button
                color="primary"
                size="m"
                onClick={() => this.props.onCancel()}
              >
                {this.t('cancel')}
              </Button>
            </div>
          </FormGroup>
        </Form>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error.mainError}
          errorReason={this.error.errorReason}
          errorResponse={this.error.errorResponse}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}
export default AddRoleGroupModal;
