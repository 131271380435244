import { useEffect, useState } from 'react';

/**
 * Custom hook to keep track of component first render on mount
 * @returns boolean if component done with first render
 */
const useMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
};

export default useMounted;
