import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import i18n from '../../i18n';

const Footer: React.FC = () => {
  const t = (keyName: string) => i18n.t(keyName);

  return (
    <footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col xs={8} className="text-start">
            <ul className="list-inline">
              <li className="list-inline-item">
                {/* TODO: change dashboard link to support page if implementation starts
                    Temporarily redirects back to dashboard */}
                <Link to="/" className="text-muted me-1">
                  {t('footer.support')}
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={4} className="text-end">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} -{' '}
              <a
                href="https://invensitygmbh.sharepoint.com/SitePages/Home.aspx"
                className="text-muted"
              >
                INVENSITY GmbH
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
