import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';
import moment, { Moment } from 'moment';
import { Button, Col, Input, Row } from 'reactstrap';

import { GDPR_STATUS_ENUM } from '../../../utils/enums/contact';
import { SortBy, SortType } from '../../../utils/enums/sort';
import { isEmpty } from '../../../utils/helpers/array';
import i18n from '../../../i18n';
import { getDateFormat } from '../../../utils/helpers/date';
import { IGdprListItem } from '../../../utils/types/responseTypes';
import { IGpdrListFilters } from '../../../utils/types/stateTypes';
import {
  ISSUE_TO_SOLVE,
  None,
  notApplicable,
} from '../../../utils/constants';
import { getAssignmentStatusName } from '../../../utils/helpers/assignmentStatus';
import { checkIfStringIsJson } from '../../../utils/helpers/string';

/**
 * Translator function for GDPR List
 * @param keyName Key for phrase to be translated
 * @returns Translated string
 */
export const t = (keyName: string) => i18n.t(`GdprOverview.${keyName}`);

/**
 * Creates the query parameters to be used to fetch data
 * @returns Object containing query parameters
 */
export const createQueryParameters = (
  page: number,
  {
    firstname,
    lastname,
    assignmentState,
    statusGdpr,
    responsible,
    ownership,
  }: IGpdrListFilters,
  sortBy: SortBy,
  sortType: SortType
) => ({
  page: page.toString(),
  ...{ 'withIssue.equals': 'TRUE' },
  ...(firstname && {
    'firstname.contains': firstname,
  }),
  ...(lastname && {
    'lastname.contains': lastname,
  }),
  ...(!isEmpty(assignmentState) && {
    'assignmentState.in': assignmentState.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(statusGdpr) && {
    'statusGdpr.in': statusGdpr.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(responsible) && {
    'employeeIds.in': responsible.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(ownership) && {
    'ownership.in': `${
      Array.isArray(ownership) ? ownership.join(',') : ownership
    }`,
  }),
  ...(sortBy &&
    sortType && {
      sort: `${sortBy}%2C${sortType}`,
    }),
});

/**
 * Creates info GDPR object when solving an issue and typing out a description
 * @param infoGdpr gdpr of contact
 * @param event event from Input textarea
 * @param userId userId of currently logged-in user
 * @returns updated infoGdpr object for gdpr contact person
 */
export const createInitialInfoGdpr = (
  { infoGdpr }: IGdprListItem,
  descriptionOfSolution: string,
  userId: string
) => ({
  ...(checkIfStringIsJson(infoGdpr) && {
    ...JSON.parse(infoGdpr),
    descriptionOfSolution,
    issueSolved: true,
    dateSolved: moment().format(getDateFormat(undefined, true)),
    solvedByUser: userId,
  }),
});

/**
 * Set info gdpr to be saved. Create object for it in case contact person object's info gdpr field is
 * not JSON, then afterwards modify object properties depending on whether solving or unsolving
 * the issue
 * @param contactPersonToUpdate gdpr contact person whose infoGdpr field will be saved
 * @param solveOrUnsolve identifies whether in solving or unsolving process
 * @returns info GDPR object for saving
 */
export const generateInfoGdprForSaving = (
  contactPersonToUpdate: IGdprListItem,
  solveOrUnsolve: string
) => {
  let infoGdprObject = null;
  if (checkIfStringIsJson(contactPersonToUpdate?.infoGdpr)) {
    infoGdprObject = JSON.parse(contactPersonToUpdate?.infoGdpr);
  } else {
    infoGdprObject = {
      descriptionOfSolution: null,
      issueSolved: false,
      dateSolved: null,
      solvedByUser: null,
      lastActivityDate: null,
    };
  }

  if (solveOrUnsolve === ISSUE_TO_SOLVE) {
    infoGdprObject.issueSolved = true;
  } else {
    infoGdprObject.descriptionOfSolution = null;
    infoGdprObject.issueSolved = false;
    infoGdprObject.dateSolved = null;
    infoGdprObject.solvedByUser = null;
  }

  return infoGdprObject;
};

/**
 * Generate the GDPR status tooltip for the GDPR status column
 * @param gdprStatus GDPR status from GDPR contact entry
 * @returns HTML Tooltip for GDPR status
 */
const generateGdprStatusTooltip = (gdprStatus: string) => {
  let status;
  switch (gdprStatus) {
    case GDPR_STATUS_ENUM.accepted.code:
      status = GDPR_STATUS_ENUM.accepted.description;
      break;
    case GDPR_STATUS_ENUM.declined.code:
      status = GDPR_STATUS_ENUM.declined.description;
      break;
    case GDPR_STATUS_ENUM.legacy.code:
      status = GDPR_STATUS_ENUM.legacy.description;
      break;
    case GDPR_STATUS_ENUM.requested.code:
      status = GDPR_STATUS_ENUM.requested.description;
      break;
    default:
      status = GDPR_STATUS_ENUM.none.description;
      break;
  }

  const toolTip = (
    <>
      <span>{`${t('description')}`}</span>
      <br />
      <span>{status}</span>
    </>
  );

  return toolTip;
};

/**
 * Gets translated GDPR status name
 * @param gdprStatus GDPR status to translate
 * @returns translated GDPR status name
 */
const getTranslatedStatusName = (gdprStatus: string) => {
  let translatedStatusName;
  switch (gdprStatus) {
    case GDPR_STATUS_ENUM.accepted.code:
      translatedStatusName = t('accepted');
      break;
    case GDPR_STATUS_ENUM.declined.code:
      translatedStatusName = t('declined');
      break;
    case GDPR_STATUS_ENUM.legacy.code:
      translatedStatusName = t('legacy');
      break;
    case GDPR_STATUS_ENUM.requested.code:
      translatedStatusName = t('requested');
      break;
    case GDPR_STATUS_ENUM.none.code:
      translatedStatusName = t('none');
      break;
    default:
      translatedStatusName = notApplicable;
      break;
  }

  return translatedStatusName;
};

/**
 * Get data InfoGDPR by a given property
 * @param infoGdprJSON string containing relevant GDPR info
 * @param propertyName property name to access in GDPR INFO JSON
 * @returns GDPR info retrieved based on property
 */
const getInfoGdprByProperty = (infoGdprJSON: string, propertyName: string) => {
  const infoGdprObject = JSON.parse(infoGdprJSON);
  if (Object.hasOwnProperty.call(infoGdprObject, propertyName)) {
    const objectPropertyValue = infoGdprObject[propertyName];
    if (moment(objectPropertyValue).isValid()) {
      return moment(objectPropertyValue).format(getDateFormat(undefined, true));
    }
    return infoGdprObject[propertyName] ?? None;
  }
  return None;
};

/**
 * Get Date solved property from Info GDPR
 * @param infoGdpr string containing relevant GDPR info
 * @returns solved date from info GDPR
 */
const getInfoGdprDateSolved = (infoGdpr: string) => {
  const infoGdprObject = JSON.parse(infoGdpr);
  if (Object.prototype.hasOwnProperty.call(infoGdprObject, 'dateSolved')) {
    if (infoGdprObject?.dateSolved === null) {
      return null;
    }
    return moment(infoGdprObject?.dateSolved).format(
      getDateFormat(undefined, true)
    );
  }
  return null;
};

/**
 * Get last activity date based on various conditions
 * @param infoGdpr string containing relevant GDPR info
 * @param statusGdpr current GDPR status of contact
 * @param latestStatusDate latest retrieve status date for contact
 */
const getLastActivityDate = (
  infoGdpr: string,
  statusGdpr: string,
  latestStatusDate: Date | Moment | string | undefined
) => {
  let latestDate;
  if (infoGdpr === null) {
    latestDate = t('noInfo');
  } else if (checkIfStringIsJson(infoGdpr)) {
    if (
      statusGdpr === GDPR_STATUS_ENUM.none.code ||
      statusGdpr === GDPR_STATUS_ENUM.legacy.code
    ) {
      if (moment(latestStatusDate).isValid()) {
        latestDate = moment(latestStatusDate).format(
          getDateFormat(undefined, true)
        );
      } else {
        latestDate = None;
      }
    } else {
      getInfoGdprByProperty(infoGdpr, 'lastActivityDate');
    }
  } else {
    latestDate = t('notAValidJSON');
  }
  return latestDate;
};

/**
 * Generate Info GDPR Tooltip for when issue of GDPR contact person
 * is solved
 * @param infoGdpr string containing relevant GDPR info
 * @returns Tooltip HTML object
 */
const generateInfoGdprTooltip = (infoGdpr: string) => {
  const infoGdprObject = JSON.parse(infoGdpr);
  const descriptionOfSolution = infoGdprObject?.descriptionOfSolution;
  const solvedByUser = infoGdprObject?.solvedByUser;
  if (infoGdprObject === null) {
    return <div />;
  }
  return (
    <>
      <span>{`${t('username')} ${solvedByUser}`}</span>
      <br />
      <span>{`${t('description')}`}</span>
      <br />
      <Input type="textarea" rows="5" value={descriptionOfSolution} />
    </>
  );
};

/**
 * Generate HTML elements for the Solve/Unsolve buttons for issue solving
 * @param gdprContactPerson object containing relevant gdpr contact info
 * @param handleSolveIssueManually method to show issue solving modal
 * @param handleUnsolveIssueManually method to show issue unsolving modal
 * @returns string to render or rendered HTML elements
 */
export const generateBeforeIssueSolvingHTML = (
  infoGdpr: string,
  handleSolveIssueManually: () => void,
  handleUnsolveIssueManually: () => void
) => {
  if (getInfoGdprDateSolved(infoGdpr) === null) {
    return (
      <Button
        color="primary"
        onClick={() => handleSolveIssueManually()}
        aria-label="button-solve"
      >
        {t('solve')}
      </Button>
    );
  }
  return (
    <div>
      <Row>
        <Col>
          <Tooltip title={generateInfoGdprTooltip(infoGdpr)}>
            <span>{getInfoGdprDateSolved(infoGdpr)}</span>
          </Tooltip>
        </Col>
        <Col>
          <Button
            color="primary"
            onClick={() => handleUnsolveIssueManually()}
            aria-label="button-unsolve"
          >
            {t('unsolve')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

/**
 * Before rendering HTML for Issue Solving feature, check additional
 * conditions
 * @param gdprContactPerson object containing relevant gdpr contact info
 * @param handleSolveIssueManually method to show issue solving modal
 * @param handleUnsolveIssueManually method to show issue unsolving modal
 * @returns string to render or rendered HTML elements
 */
export const checkConditionsBeforeIssueSolvingHTML = (
  infoGdpr: string,
  handleSolveIssueManually: () => void,
  handleUnsolveIssueManually: () => void
) => {
  if (infoGdpr === null) {
    return t('noInfo');
  }
  if (checkIfStringIsJson(infoGdpr)) {
    return generateBeforeIssueSolvingHTML(
      infoGdpr,
      handleSolveIssueManually,
      handleUnsolveIssueManually
    );
  }
  return t('noAValidJSON');
};

/**
 * Creates the GDPR entries to be shown in the DynamicTable
 * @param gdprContactPerson object containing relevant gdpr contact info
 * @param handleSolveIssueManually method to show issue solving modal
 * @param handleUnsolveIssueManually method to show issue unsolving modal
 * @returns gdprEntry gdpr contact entry for the Dynamic Table
 */
export const createGdprEntry = (
  {
    id,
    firstname,
    lastname,
    assignmentState,
    infoGdpr,
    statusGdpr,
    latestStatusDate,
    responsible,
  }: IGdprListItem,
  handleSolveIssueManually: () => void,
  handleUnsolveIssueManually: () => void
) => ({
  firstname,
  lastname: (
    <Link
      to={{
        pathname: `/customer/my-contacts/profile/${id}`,
      }}
    >
      {lastname ?? ''}
    </Link>
  ),
  assignmentState: getAssignmentStatusName(assignmentState),
  statusGdpr: (
    <Tooltip title={generateGdprStatusTooltip(statusGdpr)}>
      <span>{getTranslatedStatusName(statusGdpr)}</span>
    </Tooltip>
  ),
  latestStatusDate: getLastActivityDate(infoGdpr, statusGdpr, latestStatusDate),
  responsible: (
    <Link to={`/employees/employee-list/employee-detail/${responsible?.id}`}>
      {responsible?.name ?? ''}
    </Link>
  ),
  issueSolvedManually: checkConditionsBeforeIssueSolvingHTML(
    infoGdpr,
    handleSolveIssueManually,
    handleUnsolveIssueManually
  ),
});
