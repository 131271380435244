import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IExtendedContactPerson } from '../utils/types/modelTypes';
import { ICallingState } from '../utils/types/stateTypes';

const initialState: ICallingState = {
  callingList: [],
};

export const callingSlice = createSlice({
  name: 'calling',
  initialState,
  reducers: {
    loadCallingList: (
      state,
      { payload }: PayloadAction<IExtendedContactPerson[]>
    ) => ({
      ...state,
      callingList: payload,
    }),
  },
});

export const { loadCallingList } = callingSlice.actions;

export default callingSlice.reducer;
