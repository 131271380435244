/* eslint-disable no-nested-ternary */
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import {
  CardBody,
  CardHeader,
  Input,
  Table,
  Button,
  Col,
  Row,
  Container,
  Form,
  FormGroup,
} from 'reactstrap';
import { connect, ConnectedProps } from 'react-redux';

import {
  REGEX_ENGLISH_GERMAN_LETTERS,
  styleWidth30Percent,
  NO_ID,
  DEFAULT_LOAD_TIMEOUT,
  BLANK_OBJECT_NAME_ID,
  OK,
} from '../../../utils/constants';
import {
  ProjectEmployeeRole,
  ProjectEmployeeState,
  ProjectEmployeeWorkloadState,
  ProjectState,
  ProjectType,
} from '../../../utils/enums/project';
import {
  acceptedWorkDate,
  isNullOrUndefinedOrEmpty,
} from '../../../utils/helpers/GenericHelper';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import {
  ICustomerIdAndAbbreviation,
  ICustomerSite,
  IProject,
  IProjectEmployee,
} from '../../../utils/types/modelTypes';
import {
  getProjectCount,
  addProjectFromDetail,
} from '../../../services/api/project';
import {
  IContactPersonDetail,
  ICustomerDropdownItem,
  IProjectDetail,
  IProjectMemberListItem,
} from '../../../utils/types/responseTypes';
import {
  IObjectContactPerson,
  IObjectCustomer,
  IObjectCustomerSiteAddress,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import { RootState } from '../../../redux/store';
import { getDateFormat } from '../../../utils/helpers/date';
import {
  isValidCustomerAbbreviation,
  updateCustomerAbbreviation,
} from '../../../services/api/customer';
import ProjectMembersCard from './ProjectMembersCard';
import {
  hasNoProjectOwner,
  isCustomerProjectBilledValid,
  t,
} from './projectListHelpers';
import CustomerDropdown from '../../../components/dropdowns/CustomerDropdown';
import { generateTitle } from '../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import CustomerSitesDropdown from '../../../components/dropdowns/CustomerSitesDropdown';
import ContactPersonModal from '../../../components/form/ContactPersonModal';
import InputFormLabel from '../../../components/form/InputFormLabel';
import ProjectContactPerson from './ProjectContactPerson';

interface IProps extends IWithModalsProps, PropsFromRedux {
  onSave: (projectId: number) => void;
  onCancel: () => void;
}

const CreateCustomerProjectBilledForm = ({
  // IProps
  onSave,
  onCancel,
  // WithModals
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
  // Redux
  currentUser,
}: IProps) => {
  const [project, setProject] = useState<IProjectDetail>({
    id: NO_ID,
    customer: {
      id: NO_ID,
      name: '',
      abbreviation: '',
    } as IObjectCustomer,
    customerSite: {
      id: NO_ID,
      name: '',
      street: '',
      location: '',
      zipCode: '',
      country: '',
    } as IObjectCustomerSiteAddress,
    responsibleContactPersons: [] as IObjectNameAndId[],
    state: ProjectState.ACTIVE,
    start: new Date().toISOString(),
    end: new Date().toISOString(),
    projectType: ProjectType.CUSTOMER_PROJECT,
    departments: [] as IObjectNameAndId[],
  } as IProjectDetail);

  const [disableCustomerAbbreviation, setDisableCustomerAbbreviation] =
    useState(false);
  const [showResourceOverview, setShowResourceOverview] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(true);
  const [disableInputs, setDisableInputs] = useState(false);
  const [disableAddContactButtons, setDisableAddContactButtons] =
    useState(true);
  const [validTitle, setValidTitle] = useState<boolean>(false);
  const [isCheckingTitle, setIsCheckingTitle] = useState<boolean>(false);
  const [validAbbreviation, setValidAbbreviation] = useState<boolean>(false);
  const [projectEmployees, setProjectEmployees] = useState<IProjectEmployee[]>(
    [] as IProjectEmployee[]
  );
  const { title, start, end, customer, customerSite } = project;
  const customerObject = project.customer as IObjectCustomer;
  const abbreviationMessageToUse = validAbbreviation
    ? 'validCustomerAbbreviation'
    : customerObject.abbreviation.length === 5
      ? 'abbreviationIsAlreadyInUse'
      : 'exactlyFiveLetters';

  const startDate = start ? new Date(start.toString()) : new Date();
  const endDate = end ? new Date(end.toString()) : new Date();

  const handleTitleChange = (newTitle: string) => {
    setIsCheckingTitle(true);
    setProject((prevProject) => ({
      ...prevProject,
      title: newTitle,
    }));
  };

  /**
   * Handles the change in customer
   * @param selectedCustomer
   */
  const handleCustomerChange = (selectedCustomer: ICustomerDropdownItem) => {
    const { id, name, abbreviation } = selectedCustomer;
    setProject((prevProject) => ({
      ...prevProject,
      customer: {
        id,
        name,
        abbreviation,
      },
    }));
    setValidAbbreviation(abbreviation !== '');
    setDisableCustomerAbbreviation(!isNullOrUndefinedOrEmpty(abbreviation));
    setDisableAddContactButtons(false);
  };

  /**
   * Handles the change in customerAbbreviation
   * @param customerAbbreviationValue
   */
  const handleCustomerAbbreviationChange = async (
    customerAbbreviationValue: string
  ) => {
    const abbreviationLetterCount = 5;

    if (
      !customerAbbreviationValue.match(REGEX_ENGLISH_GERMAN_LETTERS) ||
      customerAbbreviationValue === ''
    ) {
      return;
    }

    const customerAbbreviationToUse =
      customerAbbreviationValue.toLocaleUpperCase();

    if (customerAbbreviationToUse.length === abbreviationLetterCount) {
      try {
        await isValidCustomerAbbreviation({
          abbreviation: customerAbbreviationToUse,
        }).then(({ data }) => {
          if (data === OK) {
            setValidAbbreviation(true);
          } else {
            setValidAbbreviation(false);
          }
        });
      } catch (error) {
        modalErrorHandler(t('failedToVerifyAbbreviationAvailability'), error);
      }
    } else {
      setValidAbbreviation(false);
    }

    setProject({
      ...project,
      customer: {
        ...project.customer,
        abbreviation: customerAbbreviationToUse,
      },
    });
  };

  /**
   * Handles the change in site
   * @param customerSite
   */
  const handleCustomerSiteChange = (customerSite: ICustomerSite) => {
    // Create object IObjectCustomerSiteAddress as it requires values.
    const objectCustomerSiteAddress = {
      id: customerSite?.id ?? '',
      name: customerSite?.name ?? '',
      street: customerSite?.street ?? '',
      location: customerSite?.location ?? '',
      zipCode: customerSite?.zipCode ?? '',
      country: customerSite?.country?.name ?? '',
    } as IObjectCustomerSiteAddress;
    const updatedProject = {
      ...project,
      customerSite: objectCustomerSiteAddress,
      responsibleContactPersons: [
        BLANK_OBJECT_NAME_ID,
      ] as IObjectContactPerson[],
    };

    setProject(updatedProject);
    setDisableAddContactButtons(false);
  };

  /**
   * Handles the change in project start
   * @param start
   */
  const handleStartDateChange = (start: Moment | string) => {
    setProject((prevProject) => ({
      ...prevProject,
      start: new Date(start as string).toISOString(),
    }));
  };

  /**
   * Handles the change in project end
   * @param end
   */
  const handleEndDateChange = (end: Moment | string) => {
    setProject((prevProject) => ({
      ...prevProject,
      end: new Date(end as string).toISOString(),
    }));
  };

  /**
   * Handles the change in responsibleContactPersons
   * @param responsibleContactPersons
   */
  const handleResponsibleContactPersonChange = (
    selectedResponsibleContactPersons: IObjectContactPerson[]
  ) => {
    const updatedProject = { ...project };
    const objectNameAndIdResponConPerson =
      selectedResponsibleContactPersons?.map(
        (contactPerson) =>
          contactPerson && {
            id: contactPerson.id ?? NO_ID,
            name: `${contactPerson.name}`,
          }
      ) as IObjectContactPerson[];
    updatedProject.responsibleContactPersons = objectNameAndIdResponConPerson;
    setProject(updatedProject);
  };

  const updateResponsibleContactPersons = (
    contactPerson: IContactPersonDetail
  ) => {
    const { responsibleContactPersons } = project;

    // Check the last object in the responsibleContactPersons array
    const lastResponsibleContactPerson =
      responsibleContactPersons[responsibleContactPersons.length - 1];

    const contactPersonNameAndId = {
      id: contactPerson.id,
      name: `${contactPerson.firstname} ${contactPerson.lastname}`,
    } as IObjectContactPerson;

    if (lastResponsibleContactPerson) {
      if (lastResponsibleContactPerson?.id) {
        setProject((prevProject) => ({
          ...prevProject,
          responsibleContactPersons: [
            ...responsibleContactPersons,
            contactPersonNameAndId,
          ],
        }));
      } else {
        // If the last object has a null or undefined contactPerson value, that means, in the UI,
        // That object's dropdown has no contact person selected yet
        setProject((prevProject) => ({
          ...prevProject,
          responsibleContactPersons: [contactPersonNameAndId],
        }));
      }
    }
  };

  /**
   * Handles what happens after a new contact person is added
   * @param {*} contactPerson
   */
  const handleUpdateResponsibleContactPersons = (
    contactPerson: IContactPersonDetail
  ) => {
    const { customer: customerFromCP, customerSite: customerSiteFromCP } =
      contactPerson;
    const newContactCustomerId = customerFromCP?.id ?? NO_ID;
    const newContactCustomerSiteId = customerSiteFromCP?.id ?? NO_ID;

    const {
      customer: { id: selectedCustomerId },
      customerSite: { id: selectedCustomerSiteId },
    } = project;

    // Check if the currently selected Customer and Site values
    // Match the customer and site of the newly created contact person
    if (
      newContactCustomerId === selectedCustomerId &&
      newContactCustomerSiteId === selectedCustomerSiteId
    ) {
      // Handles the update of ResponsibleContactPersons
      updateResponsibleContactPersons(contactPerson);
    }
    toggleModalForm();
  };

  /**
   * Handles the adding of new contact person
   */
  const addNewContactPerson = () => {
    modalFormHandler(
      generateTitle(BUTTON_TITLE_ENUM.INFORMATION.code, t('addContactPerson')),
      <ContactPersonModal
        onSave={(contactPersonDetail: IContactPersonDetail) =>
          handleUpdateResponsibleContactPersons(contactPersonDetail)
        }
        onCancel={toggleModalForm}
        disableCustomerAndCustomerSite
        customerId={customer.id ?? 0}
        customerSiteId={customerSite.id ?? 0}
      />,
      'xl'
    );
  };

  /**
   * Handles the adding of an existing contact person
   */
  const addExistingContactPerson = () => {
    const newObjectNameAndId = BLANK_OBJECT_NAME_ID as IObjectContactPerson;
    setProject((prevProject) => ({
      ...prevProject,
      responsibleContactPersons: [
        ...prevProject.responsibleContactPersons,
        newObjectNameAndId,
      ],
    }));
  };

  /**
   * Handle continue button
   * Show resource overview and disabled all project details input except the title/ make them readOnly.
   * Remove invalid responsibleContactPersons entries
   */
  const handleContinueClick = async () => {
    // Include the current user as the ProjectOwner
    const updatedProjectEmployees = [...projectEmployees];
    const { start, customer } = project;

    const projectEmployeeOwner = {
      employeeId: currentUser.id,
      state: ProjectEmployeeState.PLANNED,
      projectEmployeeRole: ProjectEmployeeRole.OWNER,
      validFrom: start ? new Date(start.toString()) : undefined,
      validUntil: end ? new Date(end.toString()) : undefined,
    } as IProjectEmployee;
    updatedProjectEmployees.push(projectEmployeeOwner);
    setProjectEmployees(updatedProjectEmployees);
    setProject({
      ...project,
      projectMembers: [
        {
          id: project.id,
          employee: {
            id: currentUser.id,
            name: `${currentUser.firstname} ${currentUser.name}`,
          },
          role: ProjectEmployeeRole.OWNER,
          validFrom: start ? new Date(start.toString()) : '',
          workloadPercentage: 0,
          workloadState: ProjectEmployeeWorkloadState.PLANNED,
          employeeState: ProjectEmployeeState.PLANNED,
        } as IProjectMemberListItem,
      ],
    });

    setShowResourceOverview(true);
    setShowContinueButton(!showContinueButton);
    setDisableInputs(true);
    setDisableAddContactButtons(true);

    // Save customer abbreviation when no initial abbreviation is available
    await updateCustomerAbbreviation({
      id: customer.id,
      abbreviation: customerObject.abbreviation,
    } as ICustomerIdAndAbbreviation)
      .then((response) => response.data)
      .catch((error) => {
        modalErrorHandler(t('failedToSaveCustomerAbbreviation'), error);
      });
  };

  /**
   * Handles the creation of customer project billed
   */
  const saveCustomerProjectBilled = async () => {
    try {
      await addProjectFromDetail({
        ...project,
      }).then(({ data }: AxiosResponse<IProjectDetail>) => {
        if (onSave) {
          const projectSavedId = data.id;
          onSave(projectSavedId);
        }
      });
    } catch (error) {
      modalErrorHandler(t('failedToSaveProject'), error);
      return;
    }
    onCancel();
  };

  /**
   * If an added resource/employee's start and end date not in range of the project
   * Update project's date to match with the resource/employee's date
   * @param project The updated project that contains the projectEmployees
   */
  const handleProjectMembersChange = (project: IProjectDetail) => {
    setProject(project);
  };

  useEffect(() => {
    const { title } = project;
    if (isNullOrUndefinedOrEmpty(title)) {
      setValidTitle(false);
    }

    const timer = setTimeout(() => {
      if (isNullOrUndefinedOrEmpty(title)) {
        setIsCheckingTitle(false);
        return;
      }
      try {
        const encodedString = encodeURIComponent(title);
        getProjectCount({ 'title.equals': encodedString }).then(({ data }) => {
          setValidTitle(data === 0);
          setIsCheckingTitle(false);
        });
      } catch (error) {
        modalErrorHandler(t('failedToGetProjectCount'), error);
      }
    }, DEFAULT_LOAD_TIMEOUT);
    return () => clearTimeout(timer);
  }, [title]);

  return (
    <Container fluid>
      <div style={{ textAlign: 'center' }}>
        <CardHeader>
          <h3>{t('generalInformation')}</h3>
        </CardHeader>
      </div>
      <CardBody>
        <Table borderless size="sm">
          <tbody>
            <tr>
              <th
                style={{ width: styleWidth30Percent }}
                aria-label="project-title-label-th"
              >
                <InputFormLabel isRequired text={`${t('projectTitle')}`} />
              </th>
              <td>
                <Input
                  data-testid="project-title-input"
                  type="string"
                  bsSize="lg"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleTitleChange(event.target.value)
                  }
                  value={title}
                />
                {isCheckingTitle && (
                  <>
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      style={{ color: 'gray' }}
                      className="margin-right"
                    />
                    {` ${t('checkingTitle')}`}
                  </>
                )}
                {!isCheckingTitle && (
                  <>
                    <FontAwesomeIcon
                      icon={validTitle ? faCheckCircle : faTimesCircle}
                      style={{ color: validTitle ? 'green' : 'red' }}
                      className="margin-right"
                    />
                    {` ${t(validTitle ? 'validTitle' : 'invalidTitle')}`}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <th aria-label="customer-label-th">
                <InputFormLabel isRequired text={t('customer')} />
              </th>
              {disableInputs ? (
                <td aria-label="customer-input-td">
                  <Input
                    type="string"
                    bsSize="lg"
                    value={customer.name}
                    disabled
                  />
                </td>
              ) : (
                <td aria-label="customer-dropdown-td">
                  <CustomerDropdown
                    customer={
                      {
                        id: customer.id,
                        name: customer.name,
                        abbreviation: customerObject.abbreviation,
                      } as IObjectCustomer
                    }
                    onCustomerSelect={handleCustomerChange}
                    isDisabled={false}
                  />
                </td>
              )}
            </tr>
            <tr>
              <th aria-label="customer-abbreviation-label-th">
                <InputFormLabel isRequired text={t('customerAbbreviation')} />
              </th>
              <td>
                <Input
                  data-testid="customer-abbreviation-input"
                  type="string"
                  bsSize="lg"
                  value={customerObject.abbreviation}
                  maxLength="5"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleCustomerAbbreviationChange(
                      event.target.value.trim() ? event.target.value : ''
                    )
                  }
                  placeholder={t('noAbbreviationAvailable')}
                  disabled={
                    customer.id === NO_ID ||
                    disableCustomerAbbreviation ||
                    disableInputs
                  }
                />
                {!isNullOrUndefinedOrEmpty(customerObject.abbreviation) &&
                  !disableCustomerAbbreviation && (
                    <>
                      <FontAwesomeIcon
                        icon={validAbbreviation ? faCheckCircle : faTimesCircle}
                        style={{
                          color: validAbbreviation ? 'green' : 'red',
                        }}
                        className="margin-right"
                      />
                      {` ${t(abbreviationMessageToUse)}`}
                    </>
                  )}
              </td>
            </tr>
            <tr>
              <th aria-label="customer-site-label-th">
                <InputFormLabel isRequired text={t('customerSite')} />
              </th>
              {customer.id === NO_ID ? (
                <td aria-label="customer-site-input-disabled-td">
                  <Input disabled bsSize="lg" />
                </td>
              ) : (
                <td aria-label="customer-site-dropdown-td">
                  <CustomerSitesDropdown
                    customerId={customer.id}
                    customerSiteId={customerSite.id}
                    onChange={handleCustomerSiteChange}
                  />
                </td>
              )}
            </tr>
            <tr>
              <th aria-label="project-start-label-th">
                <InputFormLabel isRequired text={t('projectStart')} />
              </th>
              {disableInputs ? (
                <td aria-label="project-start-input-td">
                  <Input
                    data-testid="project-start-input"
                    type="string"
                    bsSize="lg"
                    value={moment(startDate).format(getDateFormat())}
                    disabled
                  />
                </td>
              ) : (
                <td aria-label="project-start-datetime-td">
                  <Datetime
                    dateFormat={getDateFormat()}
                    isValidDate={(event: any) =>
                      acceptedWorkDate(event, 'Start', {
                        start,
                        end,
                      } as IProject)
                    }
                    input
                    timeFormat={false}
                    inputProps={{ placeholder: getDateFormat() }}
                    onChange={handleStartDateChange}
                    closeOnSelect
                    value={startDate}
                    locale={i18n.language}
                  />
                </td>
              )}
            </tr>
            <tr>
              <th aria-label="project-end-label-th">
                <InputFormLabel isRequired text={t('estimatedProjectEnd')} />
              </th>
              {disableInputs ? (
                <td aria-label="project-end-input-td">
                  <Input
                    data-testid="project-end-input"
                    type="string"
                    bsSize="lg"
                    value={moment(endDate).format(getDateFormat())}
                    disabled
                  />
                </td>
              ) : (
                <td aria-label="project-end-datetime-td">
                  <Datetime
                    dateFormat={getDateFormat()}
                    isValidDate={(event: any) =>
                      acceptedWorkDate(event, 'End', {
                        start,
                        end,
                      } as IProject)
                    }
                    input
                    timeFormat={false}
                    inputProps={{ placeholder: getDateFormat() }}
                    onChange={handleEndDateChange}
                    closeOnSelect
                    value={endDate}
                    locale={i18n.language}
                  />
                </td>
              )}
            </tr>
          </tbody>
        </Table>
      </CardBody>
      <ProjectContactPerson
        responsibleContactPersons={project.responsibleContactPersons}
        customerId={customer.id}
        customerSiteId={customerSite.id}
        onChange={handleResponsibleContactPersonChange}
      />
      <Row>
        <Col>
          <div
            className="card-actions float-end"
            style={{ paddingLeft: '5px' }}
          >
            <Button
              data-testid="add-existing-contact-person-button"
              color="primary"
              size="sm"
              onClick={() => addExistingContactPerson()}
              disabled={!customerSite.id || disableAddContactButtons}
            >
              {t('addExistingContactPerson')}
            </Button>
          </div>
          <div
            className="card-actions float-end"
            style={{ paddingLeft: '5px' }}
          >
            <Button
              data-testid="add-new-contact-person-button"
              color="primary"
              size="sm"
              onClick={() => addNewContactPerson()}
              disabled={!customerSite.id || disableAddContactButtons}
            >
              {t('addNewContact')}
            </Button>
          </div>
        </Col>
      </Row>
      {showContinueButton && (
        <div style={{ textAlign: 'center' }}>
          <br />
          <Button
            data-testid="continue-button"
            color="primary"
            size="lg"
            disabled={
              !isCustomerProjectBilledValid(
                project,
                validTitle,
                validAbbreviation
              )
            }
            onClick={handleContinueClick}
          >
            {t('continue')}
          </Button>
        </div>
      )}
      {showResourceOverview && (
        <Form>
          <FormGroup>
            <Col>
              <ProjectMembersCard
                project={project}
                onChange={handleProjectMembersChange}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <div className="centered-div">
              <Button
                color="primary"
                className="continue-and-cancel-button"
                disabled={
                  hasNoProjectOwner(project.projectMembers) ||
                  !isCustomerProjectBilledValid(
                    project,
                    validTitle,
                    validAbbreviation
                  )
                }
                onClick={() => saveCustomerProjectBilled()}
              >
                {t('createProject')}
              </Button>
              <Button
                className="continue-and-cancel-button"
                color="primary"
                onClick={() => onCancel()}
              >
                {t('cancel')}
              </Button>
            </div>
          </FormGroup>
        </Form>
      )}
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  currentUser: store.account.employeeDetails,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withModals(CreateCustomerProjectBilledForm));
