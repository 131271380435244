import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { IContactPersonStatisticsListItem } from '../../../utils/types/responseTypes';
import i18n from '../../../i18n';
import { getDayOfWeek } from '../../../utils/helpers/GenericHelper';
import { dateFormat, timeFormat } from '../../../utils/constants';

export const translateContactStatistics = (keyName: string) =>
  i18n.t(`ContactStatistics.${keyName}`);

export const createContactStatisticsEntry = (
  contactStatistic: IContactPersonStatisticsListItem
) => {
  const { name, dateCalled, called } = contactStatistic;

  return {
    name,
    date: moment(dateCalled).format(dateFormat),
    time: moment(dateCalled).format(timeFormat),
    day: getDayOfWeek(dateCalled),
    called: called ? (
      <FontAwesomeIcon icon={faCheck} aria-label="called-true" />
    ) : (
      <FontAwesomeIcon icon={faTimes} aria-label="called-false" />
    ),
  };
};
