import React, { ReactNode, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';
import { generateTitle } from '../../utils/helpers/icon';
import { clickHandler, modalCloseButton } from '../../utils/helpers/click';
import i18n from '../../i18n';

interface IProps {
  modalTitle: ReactNode;
  modalBodyText: JSX.Element | string;
  event: (() => Promise<void>) | (() => void);
  isOpen: boolean;
  onClose: () => void;
}

const ModalDelete = (props: IProps) => {
  const { modalTitle, modalBodyText, event, isOpen, onClose } = props;

  const [modal, setModal] = useState(isOpen);

  const toggle = () => {
    setModal(!modal);
    if (onClose) {
      onClose();
    }
  };

  const deleteEvent = async () => {
    await event();
    toggle();
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  return (
    <Draggable>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader close={modalCloseButton(toggle)}>
          {generateTitle(BUTTON_TITLE_ENUM.WARNING.code, modalTitle?.toString())}
        </ModalHeader>
        <ModalBody>{modalBodyText}</ModalBody>
        <ModalFooter>
          <Button
            aria-label="modal-delete-button"
            color="danger"
            onClick={(event: MouseEvent) => clickHandler(event, deleteEvent)}
          >
            {i18n.t('ModalDelete.delete')}
          </Button>{' '}
          <Button
            color="dark"
            onClick={(event: MouseEvent) => clickHandler(event, toggle)}
          >
            {i18n.t('ModalDelete.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};

export default ModalDelete;
