import React, { useContext, useState } from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import { Menu, MenuItem } from '@mui/material';

import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import {
  CONTACT_PERSON_ATTITUDE,
  CONTACT_PERSON_TARGET,
} from '../../../utils/enums/contactPerson';
import {
  CONTACT_ALUMNUS_ENUM,
  ContactStatus,
} from '../../../utils/enums/contact';
import {
  IAlumnusItem,
  IAttitudeItem,
  IStatusItem,
  ITargetItem,
} from '../../../utils/types/constTypes';
import { ContactPersonDetailsContext } from './ContactPersonDetailsProvider';
import {
  getContactPersonAttitudes,
  updateContactPersonDetails,
} from '../../../services/api/contactPerson';
import {
  ContactFieldsEnum,
  transformContactPersonToDetails,
} from './contactPersonHelper';
import { IContactPersonDetail } from '../../../utils/types/responseTypes';
import { screamingSnakeCaseToStartCase } from '../../../utils/helpers/string';

// !-- Used in ContactPersonOverview, ContactPersonDetails

interface IProps extends IWithModalsProps {
  userCanWrite: boolean;
  onSave: () => Promise<void>;
}

type IAnchor = (Element & EventTarget) | null;

const t = (keyName: string) => i18n.t(`ContactDetails.${keyName}`);

/**
 * Handles editing and display of the contact person status
 */
const ContactPersonStatusCard = ({
  userCanWrite,
  onSave,
  // WithModals
  modalErrorHandler,
}: IProps) => {
  const { contactDetail, setContactDetail, statusDetail, setStatusDetail } =
    useContext(ContactPersonDetailsContext);
  const [anchorAssignmentState, setAnchorAssignmentState] =
    useState<IAnchor>(null);
  const [anchorAttitude, setAnchorAttitude] = useState<IAnchor>(null);
  const [anchorTarget, setAnchorTarget] = useState<IAnchor>(null);
  const [anchorAlumnus, setAnchorAlumnus] = useState<IAnchor>(null);

  const enableEdit = (
    event: React.MouseEvent<Element, MouseEvent>,
    type: string
  ) => {
    if (userCanWrite) {
      event.preventDefault();
      switch (type) {
        case ContactFieldsEnum.ASIGNMENT_STATE:
          setAnchorAssignmentState(
            event.currentTarget as Element & EventTarget
          );
          break;
        case ContactFieldsEnum.ATTITUDE:
          setAnchorAttitude(event.currentTarget as Element & EventTarget);
          break;
        case ContactFieldsEnum.TARGET:
          setAnchorTarget(event.currentTarget as Element & EventTarget);
          break;
        case ContactFieldsEnum.ALUMNUS:
          setAnchorAlumnus(event.currentTarget as Element & EventTarget);
          break;
        default:
          break;
      }
    }
  };

  const saveContactPersonStatus = async (
    field: string,
    value: boolean | string
  ) => {
    const propertiesToSave = {
      id: contactDetail.id,
      [field]: value,
    } as unknown as IContactPersonDetail;
    try {
      const { data } = await updateContactPersonDetails(propertiesToSave);
      // Pass the changes into the context
      const { contactDetail, statusDetail } =
        transformContactPersonToDetails(data);
      setContactDetail(contactDetail);
      setStatusDetail(statusDetail);

      // After completing update, get contact person assignment
      onSave();
    } catch (error) {
      modalErrorHandler(t('failedToUpdateContactPerson'), error);
    }
  };

  /**
   * Handles updating of Contact Status / AssignmentState
   * @param {*} status
   * @returns
   */
  const updateContactStatus = ({ code }: IStatusItem) => {
    const { assignmentState } = statusDetail;
    if (assignmentState !== code) {
      setStatusDetail({
        ...statusDetail,
        assignmentState: code,
      });

      saveContactPersonStatus(ContactFieldsEnum.ASIGNMENT_STATE, code);
    }
  };

  /**
   * Handles updating of Contact Attitude
   * @param {*} attitude
   */
  const updateAttitude = async ({ value }: IAttitudeItem) => {
    try {
      const { data: attitudes } = await getContactPersonAttitudes();
      const newAttitude = attitudes.find(({ code }) => code === value);

      if (newAttitude?.attitude && newAttitude?.code) {
        setStatusDetail({
          ...statusDetail,
          attitude: newAttitude.attitude,
          attitudeCode: newAttitude.code,
        });

        saveContactPersonStatus(
          ContactFieldsEnum.ATTITUDE_CODE,
          newAttitude?.code
        );
      }
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveAttitudes'), error);
    }
  };

  /**
   * Handles updating of Target
   * @param {*} target
   */
  const updateTarget = ({ value }: ITargetItem) => {
    const { target } = statusDetail;
    if (target !== value) {
      setStatusDetail({
        ...statusDetail,
        target: value,
      });
      saveContactPersonStatus(ContactFieldsEnum.TARGET, value);
    }
  };

  /**
   * Handles updating of Alumnus
   * @param {*} alumnus
   */
  const updateAlumnus = ({ value }: IAlumnusItem) => {
    const { contactAlumnus } = statusDetail;
    if (contactAlumnus !== value) {
      setStatusDetail({
        ...statusDetail,
        contactAlumnus: value,
      });
      saveContactPersonStatus(ContactFieldsEnum.ALUMNUS, value);
    }
  };

  // Functions to change details
  const generateAssignmentField = () => {
    const { assignmentState } = statusDetail;
    // Get contactStatus  based on assignment code
    const contactStatusObject = Object.values(ContactStatus).find(
      ({ code }) => code === assignmentState
    );
    return (
      <>
        <Col
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            enableEdit(event, ContactFieldsEnum.ASIGNMENT_STATE)
          }
          aria-label="assignmentState-button"
        >
          <div
            className="circle"
            style={{
              backgroundColor: contactStatusObject?.color,
            }}
          >
            <p
              style={{
                margin: 'auto',
                fontSize: 'xxx-large',
                color: 'white',
              }}
              aria-label="assignmentState-code"
            >
              {assignmentState}
            </p>
          </div>
          <p aria-label="assignmentState-label">
            {contactStatusObject &&
              screamingSnakeCaseToStartCase(contactStatusObject.name)}
          </p>
        </Col>
        <Menu
          anchorEl={anchorAssignmentState}
          keepMounted
          open={Boolean(anchorAssignmentState)}
          onClose={() => setAnchorAssignmentState(null)}
        >
          {Object.values(ContactStatus).map((status) => (
            <MenuItem
              key={status.code}
              aria-label={status.code}
              onClick={() => {
                setAnchorAssignmentState(null);
                updateContactStatus(status);
              }}
            >
              {screamingSnakeCaseToStartCase(status.name)}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  const generateAttitudeField = () => {
    const { attitudeCode, attitude } = statusDetail;
    return (
      <>
        <Col
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            enableEdit(event, ContactFieldsEnum.ATTITUDE)
          }
          aria-label="attitude-button"
        >
          <div
            className="circle"
            style={{
              backgroundColor: Object.values(CONTACT_PERSON_ATTITUDE).find(
                ({ value }) => value === attitudeCode
              )?.color,
            }}
          >
            <p
              style={{
                margin: 'auto',
                fontSize: 'xxx-large',
                color: 'white',
              }}
              aria-label="attitude-code"
            >
              {attitudeCode}
            </p>
          </div>
          <p aria-label="attitude-label">{attitude}</p>
        </Col>
        <Menu
          anchorEl={anchorAttitude}
          keepMounted
          open={Boolean(anchorAttitude)}
          onClose={() => setAnchorAttitude(null)}
        >
          {Object.values(CONTACT_PERSON_ATTITUDE).map((attitude) => (
            <MenuItem
              key={attitude.value}
              aria-label={attitude.value}
              onClick={() => {
                setAnchorAttitude(null);
                updateAttitude(attitude);
              }}
            >
              {attitude.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  const generateAcquisitionField = () => {
    const { acquisition } = statusDetail;
    return (
      <Col>
        <div
          className="circle"
          style={{
            backgroundColor: acquisition ? 'green' : 'grey',
          }}
        >
          <p
            style={{
              margin: 'auto',
              fontSize: 'xxx-large',
              color: 'white',
            }}
            aria-label="acquisition-code"
          >
            A
          </p>
        </div>
        <p aria-label="acquisition-label">
          {acquisition ? t('pendingAcquisition') : t('noPendingAcquisition')}
        </p>
      </Col>
    );
  };

  const generateTargetField = () => {
    const { target } = statusDetail;
    return (
      <>
        <Col
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            enableEdit(event, ContactFieldsEnum.TARGET)
          }
          aria-label="target-button"
        >
          <div
            className="circle"
            style={{
              backgroundColor: target ? 'blue' : 'grey',
            }}
          >
            <p
              style={{
                margin: 'auto',
                fontSize: 'xxx-large',
                color: 'white',
              }}
              aria-label="target-code"
            >
              T
            </p>
          </div>
          <p aria-label="target-label">
            {target ? t('targetForAcquisition') : t('noTargetForAcquisition')}
          </p>
        </Col>
        <Menu
          anchorEl={anchorTarget}
          keepMounted
          open={Boolean(anchorTarget)}
          onClose={() => setAnchorTarget(null)}
        >
          {Object.values(CONTACT_PERSON_TARGET).map((item) => (
            <MenuItem
              key={item.label}
              aria-label={item.label}
              onClick={() => {
                setAnchorTarget(null);
                updateTarget(item);
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  const generateAlumnusField = () => {
    const { contactAlumnus } = statusDetail;
    if (contactAlumnus === 'NONE') {
      return <p />;
    }
    return (
      <>
        <Col
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            enableEdit(event, ContactFieldsEnum.ALUMNUS)
          }
          aria-label="alumnus-button"
        >
          <div
            className="circle"
            style={{
              backgroundColor: Object.values(CONTACT_ALUMNUS_ENUM).find(
                ({ value }) => contactAlumnus === value
              )?.color,
            }}
          >
            <p
              style={{
                margin: 'auto',
                fontSize: 'xxx-large',
                color: 'white',
              }}
              aria-label="alumnus-code"
            >
              {
                Object.values(CONTACT_ALUMNUS_ENUM).find(
                  ({ value }) => contactAlumnus === value
                )?.code
              }
            </p>
          </div>
          <p aria-label="alumnus-label">
            {
              Object.values(CONTACT_ALUMNUS_ENUM).find(
                ({ value }) => contactAlumnus === value
              )?.label
            }
          </p>
        </Col>
        <Menu
          anchorEl={anchorAlumnus}
          keepMounted
          open={Boolean(anchorAlumnus)}
          onClose={() => setAnchorAlumnus(null)}
        >
          {Object.values(CONTACT_ALUMNUS_ENUM).map((contact) => (
            <MenuItem
              key={contact.value}
              aria-label={contact.value}
              onClick={() => {
                setAnchorAlumnus(null);
                updateAlumnus(contact);
              }}
            >
              {contact.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  return (
    <CardBody style={{ borderStyle: 'solid' }}>
      <Row>
        {generateAssignmentField()}
        {generateAttitudeField()}
        {generateAcquisitionField()}
        {generateTargetField()}
        {generateAlumnusField()}
      </Row>
    </CardBody>
  );
};

export default withModals(ContactPersonStatusCard);
