import { Label, Form, FormGroup, Row, Col, Input, Button } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import Select from 'react-select';
import moment from 'moment';
import { AxiosError, AxiosResponse } from 'axios';

import i18n from '../../../i18n';
import {
  IDropdownOption,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import {
  ProjectEmployeeState,
  ProjectEmployeeRole,
  ProjectEmployeeWorkloadState,
} from '../../../utils/enums/project';
import {
  enumValuesToDropdownOptions,
  objectNameAndIdToDropdownOption,
  objectNameAndIdToDropdownOptions,
} from '../../../utils/helpers/dropdown';
import {
  IProjectDetail,
  IProjectEmployeeDetail,
  IProjectMemberListItem,
  IProjectOptionDetails,
} from '../../../utils/types/responseTypes';
import { getDateFormat } from '../../../utils/helpers/date';
import { READWRITE, defaultWorkingHoursPerDay } from '../../../utils/constants';
import { Currency } from '../../../utils/enums/currency';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  getProjectDetails,
  saveProjectEmployeeDetailInitial,
} from '../../../services/api/project';
import {
  acceptedDateEnd,
  acceptedDateStart,
  checkUserForResourceAssignment,
  isProjectOption,
} from './AssignResourceHelper';
import {
  getEmployeeNames,
  getWorkingEmployeeDays,
} from '../../../services/api/employee';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { clickHandler } from '../../../utils/helpers/click';
import ProjectDropdown from '../../dropdowns/ProjectDropdown';
import ComplexAssignResourceModal from './ComplexAssignResourceModal';

const enum WorkloadFormats {
  FULL = 'FULL',
  PERCENT = 'PERCENT',
  HOURS = 'HOURS',
}

const roles: IDropdownOption[] = enumValuesToDropdownOptions(
  Object.values(ProjectEmployeeRole),
  'ProjectEmployeeRole'
);

interface IProps extends IWithModalsProps {
  onSave: (members: IProjectMemberListItem[]) => void;
  project?: IProjectDetail | IProjectOptionDetails;
  selectedProjectEmployee?: IProjectEmployeeDetail;
  fixedEmployee?: IObjectNameAndId;
  hideComplexAssigner?: boolean;
  lockDates?: boolean;
  noDatabaseSave?: boolean;
}

const currencyList: IDropdownOption[] = Object.values(Currency).map((item) => ({
  value: item,
  label: item,
}));

const t = (keyName: string) => i18n.t(`AssignResourceView.${keyName}`);

const AssignResourceModal = ({
  // With Modals
  modalConfirmHandler,
  modalFormHandler,
  modalErrorHandler,
  // Props
  onSave,
  project,
  selectedProjectEmployee,
  fixedEmployee,
  hideComplexAssigner = false,
  lockDates = false,
  noDatabaseSave = false,
}: IProps) => {
  const [projectEmployeeDetails, setProjectEmployeeDetails] =
    useState<IProjectEmployeeDetail>(
      selectedProjectEmployee ??
        ({
          validUntil: moment().add(7, 'd').startOf('week'),
          validFrom: moment(),
          employee: fixedEmployee || { id: 0, name: '' },
          workloadPercentage: 100,
          workloadHours: 0,
          rate: 0,
        } as IProjectEmployeeDetail)
    );
  const [
    { id: projectId, title, start, end, state, projectMembers },
    setProject,
  ] = useState<IProjectDetail | IProjectOptionDetails>(
    project ??
      ({
        id: 0,
        title: '',
        start: moment(),
        end: moment().add(7, 'd').startOf('week'),
        state: '',
        projectMembers: [],
      } as unknown as IProjectDetail)
  );

  const {
    role,
    validUntil,
    validFrom,
    employee,
    workloadPercentage,
    workloadHours,
    rate,
    currency,
  } = projectEmployeeDetails;

  const [workloadFormat, setWorkloadFormat] = useState(WorkloadFormats.FULL);
  const [currentWorkloadHours, setWorkloadHours] = useState(0);

  const [employeeList, setEmployeeList] = useState(
    [] as IDropdownOption<number>[]
  );

  const fetchWorkHours = () => {
    if (employee && employee.id !== 0 && validFrom && validUntil) {
      getWorkingEmployeeDays(
        employee.id,
        moment(validFrom).format('yyyy-MM-DD'),
        moment(validUntil).format('yyyy-MM-DD')
      )
        .then((workingDays) => {
          const defaulWorkloadHours = workingDays * defaultWorkingHoursPerDay;
          setWorkloadHours(defaulWorkloadHours);
          // Set initial values for workload hours
          if (workloadFormat !== WorkloadFormats.HOURS) {
            const computedWorkloadHours =
              (defaulWorkloadHours * workloadPercentage) / 100;
            setProjectEmployeeDetails({
              ...projectEmployeeDetails,
              workloadHours: Number.parseFloat(
                computedWorkloadHours.toFixed(1)
              ),
            });
          } else {
            const computedPercentage =
              (100 * workloadHours) / defaulWorkloadHours;
            setProjectEmployeeDetails({
              ...projectEmployeeDetails,
              workloadPercentage: Number.parseFloat(
                computedPercentage.toFixed(1)
              ),
              workloadHours: workloadHours ?? defaulWorkloadHours,
            });
          }
        })
        .catch((error) => {
          modalErrorHandler(t('failedToLoadRecords'), error);
        });
    }
  };

  const handleEmployeeChange = (event: IDropdownOption) => {
    setProjectEmployeeDetails({
      ...projectEmployeeDetails,
      employee: {
        id: parseInt(event.value, 10),
        name: event.label,
      },
      role: '',
    });
  };

  const getRoleList = () => {
    if (employee) {
      const currentRoles = projectMembers
        .filter((member) => member.employee.id === employee.id)
        .map((member) => member.role);
      const newRoleList = roles.filter(
        (role) =>
          !currentRoles.some((currentRole) => currentRole === role.value)
      );
      return newRoleList;
    }
    return roles;
  };

  /**
   * Handle changes in Day Start field
   * @param {*} start
   */
  const handleProjectChange = (project: IObjectNameAndId) => {
    getProjectDetails(project.id)
      .then(({ data }: AxiosResponse<IProjectDetail>) => {
        setProject(data);
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToAddRecords'), error);
      });
  };

  /**
   * Handle changes in Day Start field
   * @param {*} start
   */
  const handleDayStartChange = (start: moment.Moment | string) => {
    setProjectEmployeeDetails({
      ...projectEmployeeDetails,
      validFrom: moment(start),
    });
  };

  /**
   * Handle changes in Day end field
   * @param {*} end
   */
  const handleDayEndChange = (end: moment.Moment | string) => {
    setProjectEmployeeDetails({
      ...projectEmployeeDetails,
      validUntil: moment(end),
    });
  };

  const handleRateChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setProjectEmployeeDetails({
      ...projectEmployeeDetails,
      rate: parseFloat(value),
    });
  };

  const handleCurrencyChange = (currencyDropdown: IDropdownOption) => {
    setProjectEmployeeDetails({
      ...projectEmployeeDetails,
      currency: currencyDropdown ? currencyDropdown.value : '',
    });
  };

  const handleRoleChange = ({ value: role }: IDropdownOption) => {
    setProjectEmployeeDetails({
      ...projectEmployeeDetails,
      role,
    });
  };

  const handleChangeWorkloadPercentage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const percentage = Number.parseFloat(event.target.value);
    const computedHours = (currentWorkloadHours * percentage) / 100;
    setProjectEmployeeDetails({
      ...projectEmployeeDetails,
      workloadHours: Number.parseFloat(computedHours.toFixed(1)),
      workloadPercentage: percentage,
    });
  };

  const handleChangeWorkloadHours = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const workloadPercentage =
      100 * (parseFloat(event.target.value) / currentWorkloadHours);
    const newWorkloadHours = parseFloat(event.target.value);
    setProjectEmployeeDetails({
      ...projectEmployeeDetails,
      workloadHours: Number.parseFloat(newWorkloadHours.toFixed(1)),
      workloadPercentage: Number.parseFloat(workloadPercentage.toFixed(1)),
    });
  };

  /**
   * Handles changes made in Workload Format field
   * @param {*} workloadFormat
   */
  const handleChangeWorkloadFormat = (format: WorkloadFormats) => {
    setWorkloadFormat(format);

    if (format === WorkloadFormats.FULL) {
      setProjectEmployeeDetails({
        ...projectEmployeeDetails,
        workloadPercentage: 100,
        workloadHours: currentWorkloadHours,
      });
    }
  };

  const saveProjectEmployeeDetails = (
    projectDetail: IProjectEmployeeDetail
  ) => {
    if (noDatabaseSave) {
      onSave([projectDetail]);
      return;
    }
    saveProjectEmployeeDetailInitial(projectDetail)
      .then(({ data }: AxiosResponse<IProjectMemberListItem>) => {
        onSave([data]);
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToAddRecords'), error);
      });
  };

  /**
   * Checks if start and end date for resource is valid
   * @param {*} workloadStateNew
   * @returns
   */
  const verifyProjectEmployeeDetails = (workloadStateNew: string) => {
    const projectEmployee = { ...projectEmployeeDetails };
    // Add the new items in
    projectEmployee.workloadState = workloadStateNew;
    projectEmployee.projectEmployeeState =
      workloadStateNew === ProjectEmployeeWorkloadState.BOOKED
        ? ProjectEmployeeState.CONFIRMED
        : ProjectEmployeeState.PLANNED;

    // Ensures that the user is aware if the end date and start date are beyond, then they will be changed
    const { validFrom, validUntil } = projectEmployee;
    if (
      moment(validFrom) < moment(start).startOf('day') ||
      moment(end) > moment(validUntil)
    ) {
      modalConfirmHandler(
        t('confirm'),
        workloadStateNew === ProjectEmployeeWorkloadState.BOOKED
          ? t('bookingConfirmationMessage')
          : t('planningConfirmationMessage'),
        () => saveProjectEmployeeDetails(projectEmployee)
      );
      return;
    }
    saveProjectEmployeeDetails(projectEmployee);
  };

  /**
   * Activates the complex modal assigner
   */
  const openComplexAssignerModal = () => {
    if (currency) {
      modalFormHandler(
        t('complexResourceAssigner'),
        <ComplexAssignResourceModal
          projectEmployee={projectEmployeeDetails}
          onSaveComplexResource={(data: IProjectMemberListItem[]) =>
            onSave(data)
          }
        />,
        'xl'
      );
    } else {
      modalConfirmHandler(t('noCurrencyConfirm'), t('notice'), () =>
        modalFormHandler(
          t('complexResourceAssigner'),
          <ComplexAssignResourceModal
            projectEmployee={projectEmployeeDetails}
            onSaveComplexResource={(data: IProjectMemberListItem[]) =>
              onSave(data)
            }
          />,
          'xl'
        )
      );
    }
  };

  /**
   * Checks if all required fields have been filled to save
   * @returns {boolean}
   */
  const isInputComplete = () =>
    ((projectId || noDatabaseSave) &&
      employee.id !== 0 &&
      role &&
      validFrom &&
      validUntil &&
      workloadHours &&
      workloadPercentage &&
      (!!(!rate && !currency) || !!(rate && currency))) as boolean;

  /**
   * Checks if all required fields have been filled
   * @returns {boolean}
   */
  const isInputValidComplex = () =>
    projectId && employee.id !== 0 && validFrom && validUntil;

  const fields = [
    {
      label: `${t('project')}`,
      value: project ? (
        <Input type="string" bsSize="lg" value={title} readOnly disabled />
      ) : (
        <ProjectDropdown
          isDisabled={project !== undefined}
          project={
            project ? { id: projectId, name: title } : { id: 0, name: '' }
          }
          onChange={handleProjectChange}
          resourceAssignmentPermission={checkUserForResourceAssignment()}
        />
      ),
      isRequired: true,
    },
    {
      label: `${t('employee')}`,
      value: (
        <Select
          value={objectNameAndIdToDropdownOption(employee)}
          aria-label="employee-selector"
          options={employeeList}
          onChange={handleEmployeeChange}
          isDisabled={!!fixedEmployee}
        />
      ),
      isRequired: true,
    },
    {
      label: `${t('role')}`,
      value: (
        <Select
          value={roles.find(({ value }) => value === role)}
          aria-label="role-selector"
          options={getRoleList()}
          isDisabled={employee === undefined}
          onChange={handleRoleChange}
        />
      ),
      isRequired: true,
    },
    {
      label: `${t('dateStart')}`,
      value: lockDates ? (
        <Input
          type="string"
          bsSize="lg"
          value={validFrom ? moment(validFrom).format(getDateFormat()) : ''}
          readOnly
          disabled
        />
      ) : (
        <Datetime
          inputProps={{ 'aria-label': 'dateStart' }}
          dateFormat={getDateFormat()}
          input
          timeFormat={false}
          closeOnSelect
          value={validFrom ? moment(validFrom).format(getDateFormat()) : ''}
          onChange={handleDayStartChange}
          isValidDate={(date) => acceptedDateStart(date, validUntil)}
          locale={i18n.language}
        />
      ),
      isRequired: true,
    },
    {
      label: `${t('dateEnd')}`,
      value: lockDates ? (
        <Input
          type="string"
          bsSize="lg"
          value={validUntil ? moment(validUntil).format(getDateFormat()) : ''}
          readOnly
          disabled
        />
      ) : (
        <Datetime
          inputProps={{ 'aria-label': 'dateEnd' }}
          dateFormat={getDateFormat()}
          input
          timeFormat={false}
          closeOnSelect
          value={validUntil ? moment(validUntil).format(getDateFormat()) : ''}
          onChange={handleDayEndChange}
          isValidDate={(date) => acceptedDateEnd(date, validFrom)}
          locale={i18n.language}
        />
      ),
      isRequired: true,
    },
    {
      value: (
        <Row>
          <Col>
            <FormGroup>
              <Label className="input-label">
                {!!(rate || currency) && (
                  <span className="marker-color">*</span>
                )}
                {t('rate')}
              </Label>
              <Input
                aria-label="rate"
                bsSize="lg"
                min="0"
                type="number"
                step="0.1"
                name="ratePerHour"
                placeholder={t('ratePerHour')}
                value={rate}
                onChange={handleRateChange}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="input-label">
                {!!(rate || currency) && (
                  <span className="marker-color">*</span>
                )}
                {t('currency')}
              </Label>
              <Select
                aria-label="currency-selector"
                options={currencyList}
                value={currencyList.find((check) => check.value === currency)}
                isClearable
                onChange={handleCurrencyChange}
              />
            </FormGroup>
          </Col>
        </Row>
      ),
    },
    {
      label: t('workload'),
      value: (
        <>
          {' '}
          <label
            htmlFor="radio_1"
            style={{ display: 'inline-block', marginRight: '15px' }}
            onChange={() => handleChangeWorkloadFormat(WorkloadFormats.FULL)}
          >
            <input
              type="radio"
              name="radio"
              id="radio_1"
              style={{ marginRight: '5px' }}
              checked={workloadFormat === WorkloadFormats.FULL}
            />
            {t('full')}
          </label>
          <label
            htmlFor="radio_2"
            style={{ display: 'inline-block', marginRight: '15px' }}
            onChange={() => handleChangeWorkloadFormat(WorkloadFormats.PERCENT)}
          >
            <input
              type="radio"
              name="radio"
              id="radio_2"
              style={{ marginRight: '5px' }}
              checked={workloadFormat === WorkloadFormats.PERCENT}
            />
            in %
          </label>
          <label
            htmlFor="radio_3"
            style={{ display: 'inline-block', marginRight: '15px' }}
            onChange={() => handleChangeWorkloadFormat(WorkloadFormats.HOURS)}
          >
            <input
              type="radio"
              name="radio"
              id="radio_3"
              style={{ marginRight: '5px' }}
              checked={workloadFormat === WorkloadFormats.HOURS}
            />
            in hours
          </label>
        </>
      ),
      isRequired: true,
    },
    {
      value: (
        <div>
          {workloadFormat !== WorkloadFormats.FULL && (
            <Row>
              <Col>
                <FormGroup>
                  {workloadFormat === WorkloadFormats.PERCENT ? (
                    <Row>
                      <Col>
                        <Input
                          aria-label="workloadPercentage"
                          className="form-control"
                          min="0"
                          max="100"
                          type="number"
                          step="0.1"
                          required
                          value={workloadPercentage}
                          onChange={handleChangeWorkloadPercentage}
                        />
                      </Col>
                      <Col>
                        <Label style={{ marginTop: '6px' }}>%</Label>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col>
                        <Input
                          aria-label="workloadHours"
                          className="form-control"
                          min="0"
                          type="number"
                          step="0.1"
                          required
                          value={workloadHours}
                          onChange={handleChangeWorkloadHours}
                        />
                      </Col>
                      <Col>
                        <Label style={{ marginTop: '6px' }}>hrs</Label>
                      </Col>
                    </Row>
                  )}
                </FormGroup>
              </Col>
              <Col />
            </Row>
          )}
        </div>
      ),
    },
    {
      label: t('assignedHours'),
      value: (
        <Row>
          <Col md={6}>
            <FormGroup>
              <Input
                aria-label="assignedHours"
                className="form-control"
                type="number"
                required
                readOnly
                disabled
                value={workloadHours ?? 0}
              />
            </FormGroup>
          </Col>
        </Row>
      ),
    },
  ];

  const buttonActions = (
    <Row>
      <Col>
        <Button
          color="primary"
          disabled={isProjectOption(state) || !isInputComplete()}
          onClick={(event: MouseEvent) =>
            clickHandler(event, () =>
              verifyProjectEmployeeDetails(ProjectEmployeeWorkloadState.BOOKED)
            )
          }
        >
          {t('bookResource')}
        </Button>{' '}
        <Button
          color="primary"
          disabled={!isInputComplete()}
          onClick={(event: MouseEvent) =>
            clickHandler(event, () =>
              verifyProjectEmployeeDetails(ProjectEmployeeWorkloadState.PLANNED)
            )
          }
        >
          {t('planResource')}
        </Button>
      </Col>
      <br />
      {!selectedProjectEmployee && !hideComplexAssigner && (
        <Row>
          <Col>
            <Button
              color="primary"
              disabled={!isInputValidComplex()}
              onClick={(event: MouseEvent) =>
                clickHandler(event, () => openComplexAssignerModal())
              }
            >
              {t('openComplexAssigner')}
            </Button>
          </Col>
        </Row>
      )}
    </Row>
  );

  useEffect(() => {
    const defaultPermissionUriList = [
      PERMISSION_URI.assignedToProjects.readWrite.uri,
      PERMISSION_URI.becomeProjectOwner.readWrite.uri,
      PERMISSION_URI.becomeProjectManager.readWrite.uri,
    ];
    getEmployeeNames({
      'permissionsFilter.in': defaultPermissionUriList.join(','),
      'accessTypeFilter.in': READWRITE,
    })
      .then(({ data: employees }: AxiosResponse<IObjectNameAndId[]>) => {
        setEmployeeList(objectNameAndIdToDropdownOptions(employees));
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToGetEmployees'), error);
      });
  }, []);

  useEffect(() => {
    setProjectEmployeeDetails({
      ...projectEmployeeDetails,
      validUntil: end ?? '',
      validFrom: start ?? '',
      workloadPercentage: 100,
      project: {
        id: projectId,
        name: title,
      },
    });
  }, [projectId, title]);

  useEffect(() => {
    fetchWorkHours();
  }, [employee, validFrom, validUntil]);

  return (
    <Form>
      {fields.map(({ label, value, isRequired }) => (
        <FormGroup key={label}>
          {label && (
            <Label className="input-label">
              {isRequired && <span className="marker-color">*</span>}
              {label}
            </Label>
          )}
          {value}
        </FormGroup>
      ))}
      {buttonActions}
    </Form>
  );
};

export default withModals(AssignResourceModal);
