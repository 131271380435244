import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Label,
  Input,
} from 'reactstrap';

import { OfferDetailsContext } from './OfferDetailsProvider';
import { translate } from './offerHelper';

const OfferDescriptionCard = () => {
  const { offer, setFormValues } = useContext(OfferDetailsContext);
  const { detail } = offer;

  const [isReadOnly, setIsReadOnly] = useState(false);

  const handleToggleDescription = () => {
    setIsReadOnly(!isReadOnly);
  };

  const handleResetDescription = () => {
    setFormValues((values) => ({ ...values, detail }));
    setIsReadOnly(!isReadOnly);
  };

  const handleChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setFormValues((values) => ({ ...values, detail: event.target.value }));
  };

  useEffect(() => {
    setIsReadOnly(false);
  }, [offer]);

  return (
    <Card className="common-card with-border">
      <CardHeader>
        <CardTitle>
          <div style={{ textAlign: 'center' }}>
            <h3>{translate('descriptionTitle')}</h3>
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div style={{ textAlign: 'center' }}>
          <Label style={{ fontWeight: 'bold' }}>{translate('details')}</Label>
        </div>
        <div onClick={handleToggleDescription}>
          <Input
            type="textarea"
            value={detail ?? ''}
            placeholder={detail ?? 'Write Something'}
            readOnly={!isReadOnly}
            onChange={handleChangeDescription}
          />
        </div>
        <br />
        {isReadOnly && (
          <div style={{ textAlign: 'right' }}>
            <Button color="primary" onClick={handleResetDescription}>
              {translate('reset')}
            </Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default OfferDescriptionCard;
