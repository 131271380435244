import SessionStorageService from '../axios/SessionStorage';
import { PERMISSION_URI } from '../../utils/enums/permission';
import setUser from '../../setUser';
import user from '../../user';
import axios from './axios';

const SessionService = SessionStorageService.getService();
const Auth = (function (props) {
  function _authenticate(state) {
    return axios.authentication.get('authenticate');
  }

  function _getAuth() {
    if (SessionService.getAccessToken() === null) {
      authenticate();
    }

    return SessionService.getAccessToken() !== null;
  }

  function _isAdmin() {
    if (!Array.isArray(user?.permissions)) {
      return false;
    }

    return user.permissions.some(
      (permission) =>
        permission.entityUri === PERMISSION_URI.adminAccess.readWrite.uri
    );
  }

  function _hasPermission(entityUris, accessTypes, restriction) {
    if (!Array.isArray(entityUris)) {
      return true;
    }

    if (!Array.isArray(accessTypes) || !Array.isArray(user?.permissions)) {
      return false;
    }

    if (_isAdmin()) {
      return true;
    }

    return user.permissions?.some(
      (permission) =>
        entityUris.some((entityUri) => entityUri === permission.entityUri) &&
        accessTypes.some(
          (accessType) => accessType === permission.accessType
        ) &&
        (!restriction ||
          permission.restriction === -1 ||
          permission.restriction === restriction)
    );
  }

  async function authenticate() {
    let response = await axios.authentication.get('authenticate');
    SessionStorageService.setToken(response.headers.authorization);
    await setUser();
  }

  return {
    isAdmin: _isAdmin,
    getAuth: _getAuth,
    authenticate: _authenticate,
    hasPermission: _hasPermission,
  };
})();

export default Auth;
