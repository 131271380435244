import { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { Button, Card, CardBody, Container, Input, Label } from 'reactstrap';

import axios from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import { clickHandler } from '../../../utils/helpers/click';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IRoleGroup } from '../../../utils/types/modelTypes';

interface IProps extends IWithModalsProps {
  roleGroups: IRoleGroup[];
  onSave: (group: IRoleGroup) => void;
  onCancel: () => void;
}
const EmployeeGroupAddForm: React.FC<IProps> = ({
  roleGroups,
  onSave,
  modalOkHandler,
  modalErrorHandler,
  onCancel,
}) => {
  const t = (keyName: string) => i18n.t(`RoleGroups.${keyName}`);

  const [roleGroup, setRoleGroup] = useState('');
  const [description, setDescription] = useState('');

  const submitEvent = async () => {
    if (roleGroup.length === 0) {
      modalOkHandler(t('warning'), t('nameRequired'));
      return;
    }
    if (roleGroups.some((group) => group.roleGroup === roleGroup.trim())) {
      modalOkHandler(t('error'), t('duplicateEntry'));
      return;
    }

    const payload = {
      roleGroup: roleGroup.trim(),
      description: description.trim(),
    };

    await axios.employee
      .save('role-groups', payload)
      .then((response: AxiosResponse<IRoleGroup>) => {
        onSave(response.data);
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToSaveRoleGroup'), error);
      });

    onCancel();
  };

  return (
    <Container fluid>
      <Card>
        <CardBody>
          <InputFormLabel isRequired text={t('roleGroup')} />
          <Input
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setRoleGroup(event.target.value);
            }}
          />
          <Label>{t('description')}:</Label>
          <Input
            type="textarea"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(event.target.value);
            }}
          />
          <br />
          <Button
            color="primary"
            size="m"
            className="float-end"
            onClick={(event: MouseEvent) => clickHandler(event, submitEvent)}
            disabled={roleGroup?.trim() === ''}
          >
            {t('save')}
          </Button>
        </CardBody>
      </Card>
    </Container>
  );
};

export default withModals(EmployeeGroupAddForm);
