import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Button, CardBody, CardHeader, Container, Label } from 'reactstrap';

import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { NO_ID } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { isEmpty, sortByPropValue } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IError } from '../../../utils/types/commonTypes';
import { IRoleGroup } from '../../../utils/types/modelTypes';
import RoleGroupAddForm from './RoleGroupAddForm';

interface IProps extends IWithModalsProps {}

interface IState {
  roleGroups: IRoleGroup[];
  roleGroup: string;
  description: string;
}

interface IEntry {
  roleGroup: string;
  roleGroupId: number;
  description: string;
  menuComponent: JSX.Element;
}

/*
 * Class that contains all Role Group Settings in the Permission Settings
 */
class RoleGroups extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      roleGroups: [],
      roleGroup: '',
      description: '',
    };
  }

  async componentDidMount() {
    // Get all of the role groups
    await trackPromise(
      axios.employee
        .get('role-groups')
        .then((response: AxiosResponse<IRoleGroup[]>) => {
          this.setState({ roleGroups: response.data });
        })
        .catch((error: AxiosError) => {
          this.props.modalErrorHandler(
            this.t('failedToRetrieveRoleGroups'),
            error
          );
        })
    );
  }

  t(keyname: string) {
    return i18n.t(`RoleGroups.${keyname}`);
  }

  // Here the Data is prepared to function with the DynamicTable Component
  prepareTabledata = (roleGroups: IRoleGroup[]) => {
    if (!isEmpty(roleGroups)) {
      const newTableData: IEntry[] = [];

      roleGroups.forEach((roleGroup) => {
        const entry: IEntry = {
          roleGroup: roleGroup.roleGroup,
          roleGroupId: roleGroup?.id ?? (NO_ID as number),
          description: roleGroup.description ?? '',
          menuComponent: (
            <div>
              <Button
                color="primary"
                onClick={() =>
                  this.deleteRole(roleGroup?.id ?? (NO_ID as number))
                }
              >
                {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
              </Button>
            </div>
          ),
        };
        newTableData.push(entry);
      });

      return newTableData;
    }
    return [];
  };

  /**
   * Method that handles the deletion of a role group
   * @param roleGroupId The id of role group
   */
  deleteRole = (roleGroupId: number) => {
    this.props.modalDeleteHandler(
      this.t('deleteRoleGroup'),
      <>
        <h1 style={{ color: 'red' }}>{this.t('warning')}</h1>
        <p>{this.t('initialWarning')}</p>
        <ul>
          <li>{this.t('linkedEmployeeGroups')}</li>
          <li>{this.t('linkedRoles')}</li>
        </ul>
        <p>{this.t('employeeWarning')}</p>
        <p>{this.t('proceed')}</p>
      </>,
      () => {
        axios.employee
          .delete(`role-groups/${roleGroupId}`)
          .then(() => {
            this.setState({
              roleGroups: this.state.roleGroups.filter(
                (roleGroup) => roleGroup.id !== roleGroupId
              ),
            });
          })
          .catch((error: AxiosError<IError>) => {
            const CONNECTED_EMPLOYEE_ERROR = 'Employee Still Connected';
            if (error.response?.data.title === CONNECTED_EMPLOYEE_ERROR) {
              this.props.modalOkHandler(
                this.t('warning'),
                <>
                  <p>{this.t('failedToDeleteRoleGroup')}</p>
                  <p>{this.t('employeeWarning')}</p>
                </>
              );
            } else {
              this.props.modalErrorHandler(
                this.t('failedToDeleteRoleGroup'),
                error
              );
            }
          });
      }
    );
  };

  /**
   * Method that updates the list of role groups
   */
  onSaveUpdateList = (group: IRoleGroup) => {
    const { roleGroups } = this.state;
    roleGroups.push(group);
    this.setState({ roleGroups });
  };

  /**
   * Method that shows the add role group modal
   */
  addRoleGroup = () => {
    this.props.modalFormHandler(
      this.t('addRoleGroup'),
      <RoleGroupAddForm
        roleGroups={this.state.roleGroups}
        onSave={this.onSaveUpdateList}
        onCancel={() => this.props.toggleModalForm()}
      />
    );
  };

  render() {
    const { roleGroups } = this.state;
    // Colum Settings for the Dynamic Table
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('roleGroup'),
        accessor: 'roleGroup',
        show: 'true',
        link: '/settings/permissions/role-group-details/',
        linkAccessor: 'roleGroupId',
      },
      {
        type: 'data',
        header: this.t('description'),
        accessor: 'description',
        show: 'true',
      },
      {
        type: 'Dropdown',
        header: this.t('menu'),
        show: 'true',
        accessor: 'menuComponent',
      },
    ];
    return (
      <Container fluid>
        <CardHeader>
          <div className="card-actions float-end">
            <Button
              color="primary"
              className="float-end"
              size="m"
              onClick={() => this.addRoleGroup()}
            >
              {this.t('addRoleGroup')}
            </Button>
          </div>
        </CardHeader>
        <br />
        <CardBody>
          {!isEmpty(roleGroups) ? (
            <DynamicTable
              data={this.prepareTabledata(
                sortByPropValue(roleGroups, 'roleGroup') as IRoleGroup[]
              )}
              columns={preparedColumns}
            />
          ) : (
            <Label className="text-center" tag="h4">
              {this.t('roleGroupEmpty')}
            </Label>
          )}
        </CardBody>
      </Container>
    );
  }
}

export default withModals(RoleGroups);
