import moment, { Moment } from 'moment';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import Datetime from 'react-datetime';
import Select from 'react-select';
import { Input } from 'reactstrap';

import DetailsCard from '../../../../../components/cards/DetailsCard';
import { ProjectState } from '../../../../../utils/enums/project';
import {
  currencyDropdownOptions,
  durationDropdownOptions,
  sortOptionsByValue,
} from '../../../../../utils/helpers/dropdown';
import i18n from '../../../../../i18n';
import { IDropdownOption } from '../../../../../utils/types/commonTypes';
import {
  IApprovalRequest,
  IEmployee,
} from '../../../../../utils/types/modelTypes';
import {
  convertDuration,
  dateFormat,
  getEndDate,
} from '../../../../salesFunnel/ProjectOptionModal/projectOptionHelpers';
import type { ProjectDetailsForm } from '../../CreateNewProjectModal';
import { DurationType } from '../../../../../utils/enums/pageComponents';
import { IOfferListItem } from '../../../../../utils/types/responseTypes';
import { dropdownProjectStateItems, t } from '../../projectListHelpers';

interface IProps {
  formValues: ProjectDetailsForm;
  approvalRequest: IApprovalRequest;
  setFormValues: Dispatch<SetStateAction<ProjectDetailsForm>>;
}

/**
 * Budet and Schedule Card in Internal Project Modal
 */
const InternalProjectBudgetAndScheduleCard = ({
  formValues: { state, start, durationType, end, duration, offers },
  approvalRequest,
  setFormValues,
}: IProps) => {
  const { id, firstname, name } = approvalRequest?.employee
    ? (approvalRequest.employee as IEmployee)
    : ({} as IEmployee);
  const headOfDepartmentFullName = `${firstname} ${name}`;
  const approvalRequestValue = id
    ? headOfDepartmentFullName
    : t('headOfDepartment');

  const disabled =
    state === ProjectState.LOST || state === ProjectState.ORDERED;

  const handleStartChange = (start: Moment | string) => {
    setFormValues((formValues) => ({
      ...formValues,
      start: moment(start).toISOString(),
      ...(!start && {
        duration: 0,
        durationType: {
          label: 'Months',
          value: DurationType.MONTHS as string,
        },
        end: '',
      }),
      ...(start &&
        duration && {
          end: getEndDate(moment(start), duration, durationType.value),
        }),
    }));
  };

  /**
   * Handles changes to the state field
   * @param projectState
   */
  const handleStateChange = (projectState: IDropdownOption) => {
    setFormValues((formValues) => ({
      ...formValues,
      state: projectState.value,
    }));
  };

  const handleDurationChange = (duration: string) => {
    const regex = /^\d{0,10}(\.\d{0,1}){0,1}$/;

    if (duration.match(regex)) {
      setFormValues((formValues) => ({
        ...formValues,
        duration: parseFloat(duration),
        end: getEndDate(
          moment(start),
          parseFloat(duration),
          durationType.value
        ),
      }));
    }
  };

  const handleDurationTypeChange = (durationType: IDropdownOption) => {
    setFormValues((formValues) => ({
      ...formValues,
      durationType,
      ...(duration && {
        duration: convertDuration(
          moment(start),
          moment(end),
          durationType.value
        ),
      }),
    }));
  };

  const handlePlannedInvestmentChange = (plannedInvestment: string) => {
    const updatedOffer = offers[0] ?? ({} as IOfferListItem);
    updatedOffer.budget = parseFloat(plannedInvestment);
    setFormValues((formValues) => ({
      ...formValues,
      offers: [updatedOffer],
    }));
  };

  const handleCurrencyChange = (currency: IDropdownOption) => {
    const updatedOffer = offers[0] ?? ({} as IOfferListItem);
    updatedOffer.currency = currency.value;
    setFormValues((formValues) => ({
      ...formValues,
      offers: [updatedOffer],
    }));
  };

  const fields = [
    {
      label: t('dateOfProjectStart'),
      value: (
        <Datetime
          dateFormat={dateFormat}
          closeOnSelect
          timeFormat={false}
          inputProps={{
            placeholder: dateFormat,
            disabled,
          }}
          value={start ? moment(start).format(dateFormat) : ''}
          onChange={handleStartChange}
          locale={i18n.language}
          aria-label="date-of-project-start-datetime"
        />
      ),
    },
    {
      label: t('totalProjectDuration'),
      value: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Input
            aria-label="project-budget-schedule-duration-input"
            type="number"
            min="0"
            step={durationType?.value === DurationType.DAYS ? '1' : '0.5'}
            maxLength={durationType?.value === DurationType.MONTHS ? 2 : 7}
            value={duration}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleDurationChange(event.target.value)
            }
            title={t('projectDurationTooltip')}
            disabled={!start || disabled}
            style={{ width: '90px' }}
          />
          <Select
            aria-label="project-budget-schedule-duration-select"
            options={durationDropdownOptions}
            value={durationType}
            onChange={handleDurationTypeChange}
            isDisabled={!start || disabled}
          />
        </div>
      ),
    },
    {
      label: t('plannedInvestment'),
      value: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Input
            aria-label="project-budget-schedule-planned-investment-input"
            type="number"
            min="0"
            step={1}
            value={offers ? offers[0]?.budget : 0}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handlePlannedInvestmentChange(event.target.value)
            }
            disabled={!offers[0] || disabled}
            style={{ width: '90px' }}
          />
          <Select
            aria-label="project-budget-schedule-currency-select"
            options={currencyDropdownOptions}
            value={currencyDropdownOptions.find(
              (currency) => currency.value === offers[0]?.currency
            )}
            onChange={handleCurrencyChange}
            isDisabled={!offers[0] || disabled}
          />
        </div>
      ),
    },
    {
      label: t('approvalRequest'),
      value: (
        <div style={{ width: '300px', alignItems: 'center', gap: '10px' }}>
          <Input
            type="string"
            bsSize="md"
            disabled
            value={approvalRequestValue}
          />
        </div>
      ),
    },
    {
      label: t('projectStatus'),
      value: (
        <div style={{ width: '300px' }}>
          <Select
            aria-label="project-state-selector"
            value={dropdownProjectStateItems.find(
              (projectState) => projectState.value === state
            )}
            options={sortOptionsByValue(dropdownProjectStateItems)}
            onChange={handleStateChange}
            isDisabled={false}
            placeholder={t('selectState')}
            style={{ fontSize: 16 }}
          />
        </div>
      ),
      isRequired: true,
    },
  ];

  return <DetailsCard title={t('budgetAndSchedule')} fields={fields} border />;
};

export default InternalProjectBudgetAndScheduleCard;
