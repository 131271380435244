import { faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React from 'react';
import { MoreVertical } from 'react-feather';
import { connect } from 'react-redux';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Table,
  ButtonDropdown,
} from 'reactstrap';
import axios from '../../../services/axios/axios';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { SERVICE_CONFIG } from '../../../utils/enums/service';
import { sortByPropValue } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import { setConfigs } from '../../../redux/configsSlice';
import FadeAlert from '../../../components/layout/FadeAlert';
import ModalAlert from '../../../components/modals/ModalAlert';
import ModalError from '../../../components/modals/ModalError';

/**
 * Configuration Settings
 *
 * A page for the product owner to change configuration settings of microservices
 *
 */
class ConfigurationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      selectedConfiguration: undefined,
      configurations: [],
      editingConfiguration: undefined,
      originalConfiguration: undefined,
      newConfigurations: [],
      showModal: false,
      showModalError: false,
    };
  }

  t(keyName) {
    return i18n.t(keyName);
  }

  /**
   * Toggles a modal form
   */
  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  /**
   * Method that shows an error dialog box for incomplete configuration details
   */
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  modalEvent = async () =>
    axios.serviceConfiguration
      .delete('/' + configuration.id, configuration)
      .then(() => {
        if (configuration.id === this.state.editingConfiguration?.id) {
          this.setState({ editingConfiguration: undefined });
        }
        this.props.history.push({
          pathname: '/settings/configuration',
          state: {
            successMessage: this.t(
              'ConfigurationSettings.deletionOfConfigurationIsSuccessful'
            ),
          },
        });

        let updatedConfigurations = this.state.configurations;
        const index = updatedConfigurations.indexOf(configuration);
        if (index > -1) {
          updatedConfigurations.splice(index, 1);
          this.setState({ configurations: updatedConfigurations });
          this.props.setConfigs(updatedConfigurations);
        }
      })
      .catch((error) => {
        this.props.history.push({
          pathname: '/settings/configuration',
          state: {
            errorMessage:
              error.toString() +
              `. ${this.t(
                'ConfigurationSettings.deletionOfConfigurationIsNotSuccessful'
              )}`,
          },
        });
      });

  /**
   * Deletes a configuration
   * @param {*} configuration
   */
  handleDelete = (configuration) => {
    let updatedConfigurations = this.state.newConfigurations;
    let originalConfig = updatedConfigurations.find(
      (config) => config.id === configuration.id
    );
    const index = updatedConfigurations.indexOf(originalConfig);
    if (index > -1) {
      updatedConfigurations.splice(index, 1);
      this.setState({ newConfigurations: updatedConfigurations });
    }
  };

  /**
   * Sets fields into edit mode
   * @param {*} configuration
   */
  handleEdit = (configuration) => {
    if (configuration === this.state.editingConfiguration) {
      this.setState({ editingConfiguration: undefined });
      this.setState({ originalConfiguration: undefined });
    } else {
      this.setState({ editingConfiguration: configuration });
      let clonedConfiguration = Object.assign({}, configuration);
      this.setState({ originalConfiguration: clonedConfiguration });
    }
  };

  /**
   * Save an edited configuration
   * @param {*} configuration
   * @returns
   */
  handleUpdate = (configuration) => {
    if (
      document.getElementById('configurationServiceTextbox').value === '' ||
      document.getElementById('configurationKeyTextbox').value === '' ||
      document.getElementById('configurationValueTextbox').value === ''
    ) {
      this.mainError = this.t(
        'ConfigurationSettings.fillServiceKeyAndValueFields'
      );
      this.errorResponse = '';
      this.errorReason = '';
      if (!this.state.showModalError) {
        this.toggleModalError();
      }
      return;
    }

    if (configuration === this.state.originalConfiguration) {
      this.setState({ editingConfiguration: undefined });
      this.setState({ originalConfiguration: undefined });
    } else {
      axios.serviceConfiguration
        .put('', configuration)
        .then((response) => {
          let updatingConfigurations = this.state.configurations;
          let originalConfig = updatingConfigurations.find(
            (config) => config.id === configuration.id
          );
          const index = updatingConfigurations.indexOf(originalConfig);
          if (index > -1) {
            updatingConfigurations[index] = response.data;
            this.setState({ configurations: updatingConfigurations });
            this.setState({ editingConfiguration: undefined });
            this.setState({ originalConfiguration: undefined });
          }
        })
        .catch((error) => {
          this.props.history.push({
            pathname: '/settings/configuration',
            state: {
              errorMessage:
                error.toString() +
                `. ${this.t(
                  'ConfigurationSettings.updatingConfigurationIsNotSuccessful'
                )}`,
            },
          });
        });
    }
  };

  /**
   * Saves a new configuration
   * @returns
   */
  handleSaving = () => {
    if (
      document.getElementById('configurationServiceTextbox').value === '' ||
      document.getElementById('configurationKeyTextbox').value === '' ||
      document.getElementById('configurationValueTextbox').value === ''
    ) {
      this.mainError = this.t(
        'ConfigurationSettings.fillServiceKeyAndValueFields'
      );
      this.errorResponse = '';
      this.errorReason = '';
      if (!this.state.showModalError) {
        this.toggleModalError();
      }
      return;
    }

    let newConfigurationAxiosArray = [];
    this.state.newConfigurations.forEach((configuration) => {
      newConfigurationAxiosArray.push(
        axios.serviceConfiguration.post('', configuration).catch((error) => {
          this.props.history.push({
            pathname: '/settings/configuration',
            state: {
              errorMessage:
                error.toString() +
                `. ${this.t(
                  'ConfigurationSettings.creationOfNewConfigurationsIsNotSuccessful'
                )}`,
            },
          });
        })
      );
    });

    Promise.all(newConfigurationAxiosArray)
      .then((response) => {
        let allConfigurations = this.state.configurations;
        response.forEach((configuration) => {
          allConfigurations.push(configuration.data);
        });
        this.setState({
          configurations: allConfigurations,
          newConfigurations: [],
        });
        this.props.setConfigs(allConfigurations);
      })
      .catch((error) => {
        this.props.history.push({
          pathname: '/settings/configuration',
          state: {
            errorMessage:
              error.toString() +
              `. ${this.t(
                'ConfigurationSettings.creationOfNewConfigurationsIsNotSuccessful'
              )}`,
          },
        });
      });
  };

  /**
   * Deletes a configuration
   * @param {*} configuration
   */
  handleRemove = (configuration) => {
    this.toggleModal();
  };

  /**
   * Handles addition of configuration
   */
  addNewConfiguration = () => {
    let newConfiguration = {
      id: undefined,
      service: '',
      configKey: '',
      configValue: '',
    };
    this.setState({
      newConfigurations: [...this.state.newConfigurations, newConfiguration],
    });
  };

  componentDidMount() {
    this.setState({ configurations: [...this.props.config.configs] });
  }

  /**
   * Handles changes made in service
   * @param {*} event
   */
  handleServiceChange = (event) => {
    const { editingConfiguration } = this.state;
    this.setState({
      editingConfiguration: {
        ...editingConfiguration,
        service: event.target.value,
      },
    });
  };

  /**
   * Handles changes made in key
   * @param {*} event
   */
  handleKeyChange = (event) => {
    const { editingConfiguration } = this.state;
    this.setState({
      editingConfiguration: {
        ...editingConfiguration,
        configKey: event.target.value,
      },
    });
  };

  /**
   * Handles changes made in value
   * @param {*} event
   */
  handleValueChange = (event) => {
    // regex for double, accepts 10 digit before the decimal point and 2 digits after the decimal point
    var regex = /^\d{0,10}(\.\d{0,2}){0,1}$/;
    let editingConfig = this.state.editingConfiguration;

    let value = event.target.value;
    if (value.trim() === '') {
      value = '';
    }
    const valueFloat = value ? parseFloat(value) : '';
    if (value.match(regex) || value === '') {
      editingConfig.configValue = valueFloat.toString();
      this.setState({
        editingConfig: editingConfig,
      });
    }
  };

  /**
   * Handles changes made in newly added service
   * @param {*} index
   * @param {*} e
   */
  handleNewServiceChange = (index, e) => {
    let updatingConfigurations = this.state.newConfigurations;
    let updatedConfiguration = updatingConfigurations[index];
    updatedConfiguration.service = e.target.value;

    updatingConfigurations[index] = updatedConfiguration;
    this.setState({ newConfigurations: updatingConfigurations });
  };

  /**
   * Handles changes made in newly added key
   * @param {*} index
   * @param {*} e
   */
  handleNewKeyChange = (index, e) => {
    let updatingConfigurations = this.state.newConfigurations;
    let updatedConfiguration = updatingConfigurations[index];
    updatedConfiguration.configKey = e.target.value;

    updatingConfigurations[index] = updatedConfiguration;
    this.setState({ newConfigurations: updatingConfigurations });
  };

  /**
   * Handles changes made in newly added value
   * @param {*} index
   * @param {*} e
   */
  handleNewValueChange = (index, e) => {
    let updatingConfigurations = this.state.newConfigurations;
    let updatedConfiguration = updatingConfigurations[index];
    updatedConfiguration.configValue = e.target.value;

    updatingConfigurations[index] = updatedConfiguration;
    this.setState({ newConfigurations: updatingConfigurations });
  };

  /**
   * Returns true if configuration is for workingHoursPerMonth or customerPriority
   * @param {*} configuration
   * @returns true or false
   */
  disableField = (configuration) => {
    return (
      (configuration?.service === SERVICE_CONFIG.workingHoursPerMonth.service &&
        configuration?.configKey === SERVICE_CONFIG.workingHoursPerMonth.key) ||
      (configuration?.service === SERVICE_CONFIG.customerPriority.service &&
        configuration?.configKey === SERVICE_CONFIG.customerPriority.key)
    );
  };

  /**
   * Generates tooltip title for workingHoursPerMonth/customerPriority configuration
   * @param {*} configuration
   * @returns title
   */
  generateTooltipTitle = (configuration) => {
    if (
      (configuration?.service === SERVICE_CONFIG.workingHoursPerMonth.service &&
        configuration?.configKey === SERVICE_CONFIG.workingHoursPerMonth.key) ||
      (configuration?.service === SERVICE_CONFIG.customerPriority.service &&
        configuration?.configKey === SERVICE_CONFIG.customerPriority.key)
    ) {
      return this.t('ConfigurationSettings.editInSalesSettings');
    }
    return '';
  };

  toggleMenu = (configuration) => {
    this.setState({ selectedConfiguration: configuration });
  };

  render() {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>
            {this.t('ConfigurationSettings.configuration')}
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem active>
              {this.t('ConfigurationSettings.configurationSettings')}
            </BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <Card>
          <CardHeader>
            {this.props.location.state?.successMessage && (
              <FadeAlert color="success">
                {this.props.location.state.successMessage}
              </FadeAlert>
            )}
            {this.props.location.state?.errorMessage && (
              <FadeAlert color="danger">
                {this.props.location.state.errorMessage}
              </FadeAlert>
            )}

            <CardTitle className="mb-0">
              <h1>{this.t('ConfigurationSettings.configurationsHeader')}</h1>
            </CardTitle>
            <div className="card-actions float-end">
              <Button
                color="primary"
                size="s"
                onClick={this.addNewConfiguration}
              >
                {generateTitle(
                  BUTTON_TITLE_ENUM.ADD.code,
                  this.t('ConfigurationSettings.add')
                )}
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <Table striped className="my-0">
              <tbody>
                {sortByPropValue([...this.state.configurations], 'service').map(
                  (configuration, i) => (
                    <>
                      {this.state.editingConfiguration === configuration ? (
                        <tr key={`entity-${i}`}>
                          <td className="d-none d-xl-table-cell">
                            <Input
                              id="configurationServiceTextbox"
                              onChange={this.handleServiceChange}
                              value={configuration?.service}
                              placeholder="Service"
                              readOnly={this.disableField(configuration)}
                            />
                          </td>
                          <td className="d-none d-xl-table-cell">
                            <Input
                              id="configurationKeyTextbox"
                              onChange={this.handleKeyChange}
                              value={configuration?.configKey}
                              placeholder="Key"
                              readOnly={this.disableField(configuration)}
                            />
                          </td>
                          <td className="d-none d-xl-table-cell">
                            <Input
                              id="configurationValueTextbox"
                              onChange={this.handleValueChange}
                              value={configuration?.configValue}
                              placeholder="Value"
                              type={
                                configuration?.service ===
                                  SERVICE_CONFIG.workingHoursPerMonth.service &&
                                configuration?.configKey ===
                                  SERVICE_CONFIG.workingHoursPerMonth.key
                                  ? 'number'
                                  : 'string'
                              }
                              readOnly={this.disableField(configuration)}
                            />
                          </td>
                          <td>
                            <Button
                              size="s"
                              color="primary"
                              onClick={() => {
                                this.handleUpdate(configuration);
                              }}
                              disabled={
                                this.disableField(configuration) &&
                                (configuration?.configValue === '0' ||
                                  configuration?.configValue === '')
                              }
                            >
                              {generateTitle(
                                BUTTON_TITLE_ENUM.SAVE.code,
                                this.t('ConfigurationSettings.save')
                              )}
                            </Button>{' '}
                            {!this.disableField(configuration) && (
                              <Button
                                size="s"
                                color="primary"
                                onClick={() => {
                                  this.handleRemove(configuration);
                                }}
                              >
                                {generateTitle(
                                  BUTTON_TITLE_ENUM.DELETE.code,
                                  this.t('ConfigurationSettings.delete')
                                )}
                              </Button>
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr key={`entity-${i}`}>
                          <td className="d-none d-xl-table-cell">
                            {configuration?.service}
                          </td>
                          <td className="d-none d-xl-table-cell">
                            {configuration?.configKey}
                          </td>
                          <td className="d-none d-xl-table-cell">
                            {configuration?.configValue}
                          </td>
                          <td>
                            <Tooltip
                              key={i}
                              placement="left"
                              title={this.generateTooltipTitle(configuration)}
                            >
                              <ButtonDropdown
                                disabled={this.disableField(configuration)}
                                isOpen={
                                  this.state.selectedConfiguration
                                    ?.configKey === configuration?.configKey
                                }
                                toggle={() => this.toggleMenu(configuration)}
                              >
                                <DropdownToggle tag="a">
                                  <MoreVertical />
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem
                                    onClick={() => {
                                      this.handleEdit(configuration);
                                    }}
                                  >
                                    {this.t('ConfigurationSettings.update')}
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      this.handleRemove(configuration);
                                    }}
                                  >
                                    {this.t('ConfigurationSettings.delete')}
                                  </DropdownItem>
                                </DropdownMenu>
                              </ButtonDropdown>
                            </Tooltip>
                          </td>
                        </tr>
                      )}
                    </>
                  )
                )}
                {this.state.newConfigurations.map((configuration, i) => (
                  <tr key={`entity-${i}`}>
                    <td className="d-none d-xl-table-cell">
                      <Input
                        id="configurationServiceTextbox"
                        onChange={(e) => this.handleNewServiceChange(i, e)}
                        placeholder={this.t('ConfigurationSettings.service')}
                      />
                    </td>
                    <td className="d-none d-xl-table-cell">
                      <Input
                        id="configurationKeyTextbox"
                        onChange={(e) => this.handleNewKeyChange(i, e)}
                        placeholder={this.t('ConfigurationSettings.key')}
                      />
                    </td>
                    <td className="d-none d-xl-table-cell">
                      <Input
                        id="configurationValueTextbox"
                        onChange={(e) => this.handleNewValueChange(i, e)}
                        placeholder={this.t('ConfigurationSettings.value')}
                      />
                    </td>
                    <td>
                      <Button
                        size="s"
                        color="primary"
                        onClick={() => {
                          this.handleSaving();
                        }}
                      >
                        {generateTitle(
                          BUTTON_TITLE_ENUM.SAVE.code,
                          this.t('ConfigurationSettings.save')
                        )}
                      </Button>{' '}
                      {
                        <Button
                          size="s"
                          color="primary"
                          onClick={() => {
                            this.handleDelete(configuration);
                          }}
                        >
                          {generateTitle(
                            BUTTON_TITLE_ENUM.DELETE.code,
                            this.t('ConfigurationSettings.delete')
                          )}
                        </Button>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <ModalAlert
              isOpen={this.state.showModal}
              onClose={this.toggleModal}
              event={this.modalEvent}
              modalTitle={this.t('ConfigurationSettings.deleteConfiguration')}
              modalBodyText={this.t(
                'ConfigurationSettings.deleteConfigurationConfirm'
              )}
              modalButtonColor="danger"
              modalButtonText="Delete"
            ></ModalAlert>
            <ModalError
              isOpen={this.state.showModalError}
              onClose={this.toggleModalError}
              mainError={this.mainError}
              errorReason={this.errorReason}
              errorResponse={this.errorResponse}
              modalTitle={this.t('error')}
            ></ModalError>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (store) => ({
  config: store.configs,
});

const mapDispatchToProps = {
  setConfigs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurationSettings);
