import React, { ElementType, ReactNode } from 'react';
import classNames from 'classnames';

interface IProps {
  children?: ReactNode;
  className?: string;
  tag?: ElementType;
}

const Header: React.FC<IProps> = ({
  children,
  className = '',
  tag: Tag = 'div',
  ...rest
}) => (
  <Tag className={classNames('header', className)} {...rest}>
    {children}
  </Tag>
);

export default Header;
