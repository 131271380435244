import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Form, FormGroup } from 'reactstrap';
import axios from '../../../services/axios/axios';
import { instantTimeFormat } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import {
  generateTitle,
} from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';
import ModalOK from '../../../components/modals/ModalOK';
import ProjectEmployeeWorkloadFormInput from './ProjectEmployeeWorkloadFormInput';

/**
 * Used for showing the dialog containing Appointment Form \
 * Variable Props:
 *  - projectEmployee = the projectEmployee for the workload
 *  - projectEmployeeWorkload = the entity. null for new data
 *  - project = project object contains project detail where the appointment is being created.
 * Function props:
 *  - onSave = holds the function to call upon submitting the form.For saving new projectEmployeeWorkload.
 *  - onCancel = hold the function to call. For cancelling and closing this Form.
 * Load appointment based on the props received.
 */
class AddOrUpdateProjectEmployeeWorkload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectEmployee: props.projectEmployee ? props.projectEmployee : null,
      projectEmployeeWorkload: props.projectEmployeeWorkload
        ? props.projectEmployeeWorkload
        : null,
      project: props.project ? props.project : null,
      dateFormat: instantTimeFormat,
      employee: props.employee ? props.employee : null,
      message: '',
      title: i18n.t('AddOrUpdateProjectEmployeeWorkload.notice'),
      showModalOk: false,
    };

    this.isUpdate = props.projectEmployeeWorkload?.id ? true : false;

    this.mainError = '';
    this.errorReason = '';
    this.errorResponse = '';

    this.modalTitle = '';
  }

  t(keyName) {
    return i18n.t('AddOrUpdateProjectEmployeeWorkload.' + keyName);
  }

  handleDetailsChange = (projectEmployeeWorkload) => {
    this.setState({
      projectEmployeeWorkload: projectEmployeeWorkload,
    });
  };

  //Enables a toggle to appear when an error is encountered.
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  // method that handles error
  handleError = (errorMessage, mainError) => {
    this.modalTitle = this.t('error');
    this.mainError = mainError;
    this.errorReason = this.t('serverFailed');
    this.errorResponse = errorMessage;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  // Function to call to check if the selected statDate and endDate are valid inputs.
  checkDateTimeIfValid(startDate, endDate) {
    // Convert dates to its corresponding time
    if (startDate === null || endDate === null) {
      return false;
    }
    const checkStartDateTime = new Date(startDate).getTime();
    const checkEndDateTime = new Date(endDate).getTime();
    if (checkEndDateTime >= checkStartDateTime) {
      return true;
    }
    return false;
  }

  toggleModalOk = () => {
    this.setState({
      showModalOk: !this.state.showModalOk,
    });
  };

  addOrUpdateProjectEmployeeWorkload = async () => {
    let currentProjectEmployeeWorkload = this.state.projectEmployeeWorkload;

    // check if all required fields where answered
    let endDate = currentProjectEmployeeWorkload.endDate;

    if (!endDate) {
      this.setState({
        message: this.t('dateEndRequired'),
      });
      this.toggleModalOk();
      return;
    }

    let projectEmployeeWorkload = this.state.projectEmployeeWorkload;
    let savedProjectEmployeeWorkload = await this.saveProjectEmployeeWorkload(
      projectEmployeeWorkload
    );
    if (savedProjectEmployeeWorkload === undefined) return;
    if (this.props.onSave) {
      this.props.onSave(savedProjectEmployeeWorkload);
    }
  };

  saveProjectEmployeeWorkload = async (projectEmployeeWorkload) => {
    let result;
    if (projectEmployeeWorkload.id && !this.props.extend) {
      result = await axios.project
        .put('project-employee-workloads', projectEmployeeWorkload)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          let mainError = this.t('failedToUpdateRecords');
          this.handleError(error.message, mainError);
        });
    } else {
      projectEmployeeWorkload.id = null;
      result = await axios.project
        .post('project-employee-workloads', projectEmployeeWorkload)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          let mainError = this.t('failedToAddRecords');
          this.handleError(error.message, mainError);
        });
    }
    return result;
  };

  render() {
    return (
      <Form>
        <FormGroup>
          <ProjectEmployeeWorkloadFormInput
            onChange={this.handleDetailsChange} // Update the appointment object
            projectEmployee={this.state.projectEmployee}
            projectEmployeeWorkload={this.state.projectEmployeeWorkload}
            extend={this.props.extend}
            employee={this.state.employee}
          />
          <br />
          <br />
          {this.isUpdate ? (
            <>
              {this.props.extend ? (
                <Button
                  color="primary"
                  onClick={(event) =>
                    this.addOrUpdateProjectEmployeeWorkload(event)
                  }
                >
                  {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, this.t('extend'))}
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={(event) =>
                    this.addOrUpdateProjectEmployeeWorkload(event)
                  }
                >
                  {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, this.t('update'))}
                </Button>
              )}{' '}
            </>
          ) : (
            <>
              <Button
                color="primary"
                onClick={(event) =>
                  this.addOrUpdateProjectEmployeeWorkload(event)
                }
                disabled={this.forShowingAppointmentDetail}
              >
                {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, this.t('submit'))}
              </Button>{' '}
            </>
          )}

          {this.props.onCancel ? (
            <Button color="primary" onClick={() => this.props.onCancel()}>
              {this.t('cancel')}
            </Button>
          ) : null}
        </FormGroup>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle={this.modalTitle}
        ></ModalError>
        <ModalOK
          isOpen={this.state.showModalOk}
          onClose={this.toggleModalOk}
          modalBodyText={this.state.message}
          modalTitle={this.state.title}
        ></ModalOK>
      </Form>
    );
  }
}

export default withRouter(AddOrUpdateProjectEmployeeWorkload);
