import React, { Dispatch, SetStateAction } from 'react';

import ResponsiblesCard from '../../../components/cards/ResponsiblesCard';
import { ProjectState } from '../../../utils/enums/project';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import type {
  ProjectDetailsForm,
  ProjectInvolvedResponsible,
} from './CreateNewProjectModal';

interface IProps {
  responsibleList: IDropdownOption<number>[];
  formValues: ProjectDetailsForm;
  setFormValues: Dispatch<SetStateAction<ProjectDetailsForm>>;
}

/**
 * Responsibles Card in Project Modal -
 * Displays project responsible and involved responsibles
 */
const ProjectResponsibles = ({
  responsibleList,
  formValues: { state, responsible, involvedResponsibles },
  setFormValues,
}: IProps) => (
  <ResponsiblesCard
    responsibleList={responsibleList}
    responsible={responsible}
    involvedResponsibles={involvedResponsibles}
    setResponsible={(value: IDropdownOption<number>) => {
      setFormValues((formValues) => ({
        ...formValues,
        responsible: value,
      }));
    }}
    setInvolvedResponsibles={(
      involvedResponsibles: ProjectInvolvedResponsible[]
    ) => {
      setFormValues((formValues) => ({
        ...formValues,
        involvedResponsibles,
      }));
    }}
    isRequired={state !== ProjectState.ORDERED}
    isDisabled={state === ProjectState.LOST || state === ProjectState.ORDERED}
  />
);

export default ProjectResponsibles;
