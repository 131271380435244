import { NextAction } from '../enums/activity';
import { CONTACT_STATUS, GDPR_STATUS_ENUM } from '../enums/contact';
import { TargetPriority } from '../enums/customer';
import { ProjectState } from '../enums/project';
import { MONTH_FILTER_OPTIONS_ENUM } from '../enums/projectUtilization';
import i18n from '../../i18n';
import {
  IDropdownOption,
  IObjectNameAndId,
  IObjectNameIdDescription,
} from '../types/commonTypes';
import { IContactPersonDropdownItem } from '../types/modelTypes';
import { notApplicable } from '../constants';
import { screamingSnakeCaseToStartCase } from './string';
import { Currency } from '../enums/currency';
import { DurationType } from '../enums/pageComponents';
import { employmentTypeEnum } from '../enums/enum';

/**
 * Handles the creation of dropdown options based on interface IObjectNameAndId
 * @param objects IObjectNameAndId array to convert to dropdown options
 * @returns Dropdown options based on IObjectNameAndId array
 */
export const objectNameAndIdToDropdownOptions = (
  objects: IObjectNameAndId[] | IObjectNameIdDescription[]
) =>
  objects.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

export const dropdownOptionsToObjectNameAndId = (
  dropdownOptions: IDropdownOption<number>[]
) =>
  dropdownOptions.map(({ label, value }) => ({
    id: value,
    name: label,
  }));

export const dropdownOptionToObjectNameAndId = ({
  label,
  value,
}: IDropdownOption<number>) => ({ id: value, name: label });

/**
 * Handles the creation of dropdown options based on interface IObjectNameAndId
 * @param objects IObjectNameAndId array to convert to dropdown options
 * @returns Dropdown options based on IObjectNameAndId array
 */
export const objectNameAndIdToDropdownOption = ({
  id,
  name,
}: IObjectNameAndId | IObjectNameIdDescription) => ({
  label: name,
  value: id,
});

/**
 * Constant that maps the contact person list item into a dropdown option
 * @param {*} contactPerson the contact person dropdown item
 * @returns Dropdown options for contact persons
 */
export const contactPersonToDropdownOptions = (
  contactPerson: IContactPersonDropdownItem[]
) =>
  contactPerson.map((item) => ({
    label: `${item.firstname} ${item.lastname}`,
    value: item.id as number,
  }));

/**
 * Creates dropdown options based on enum values passed
 * @param values String array of enum values
 * @param key String for keys in translation file
 * @returns Dropdown options
 */
export const enumValuesToDropdownOptions = (values: string[], key = '') =>
  values.map((value) => ({
    value,
    label:
      key && i18n.exists(`${key}.${value.toUpperCase()}`)
        ? i18n.t(`${key}.${value.toUpperCase()}`)
        : screamingSnakeCaseToStartCase(value),
  }));

/**
 * Creates dropdown options for targetPriotities enum
 * @returns TargetPriorityDropdown options
 */
export const targetPrioritiesDropdownOptions = enumValuesToDropdownOptions(
  Object.values(TargetPriority).map((item) => item, 'TargetPriority')
);

/**
 * Creates the project state dropdown options based on ProjectState enum
 * @returns Dropdown options for project state
 */
export const projectStateDropdownOptions = Object.values(ProjectState).map(
  (state) => ({
    value: state,
    label: i18n.exists(`ProjectStates.${state}`)
      ? i18n.t(`ProjectStates.${state}`)
      : screamingSnakeCaseToStartCase(state),
  })
);

/**
 * Creates the next action dropdown options based on Next Action enum
 * @returns Dropdown options for next action
 */
export const nextActionDropdownOptions = Object.values(NextAction).map(
  (state) => ({
    value: state,
    label: i18n.exists(`ProjectActivities.${state}`)
      ? i18n.t(`ProjectActivities.${state}`)
      : screamingSnakeCaseToStartCase(state),
  })
);

/**
 * @deprecated
 * Create dropdown options from Contact status enum
 * @returns Dropdown options for contact status
 */
export const contactStatusDropdownOptions = Object.values(CONTACT_STATUS)
  .map((state) => ({
    value: state.code,
    label: state.name,
  }))
  .sort((a, b) => (a.label > b.label ? 1 : -1));

/**
 * @deprecated
 * Create dropdown options from GDPR status enum
 * @returns Dropdown options for GDPR status
 */
export const gdprStatusDropdownOptions = Object.values(GDPR_STATUS_ENUM)
  .map((state) => ({
    value: state.code,
    label: `${state.name} - ${state.description}`,
  }))
  .sort((a, b) => (a.label > b.label ? 1 : -1));

/**
 * A label that returns N/A
 */
export const nonApplicableLabel = {
  label: notApplicable,
};

/**
 * A constant that extracts the label value
 * @param dropdownValue
 * @returns the label value
 */
export const labelValue = (dropdownValue: IDropdownOption | undefined) => {
  const { label } = dropdownValue || nonApplicableLabel;
  return label;
};

/**
 * Sorts options by label
 * @param options the options from dropdown to be sorted
 * @param property references the property of an object for sorting
 * @returns sorted options as type IDropdownOption<number>
 */
export const sortOptionsByValue = (
  options: IDropdownOption<number>[] | IDropdownOption<string>[]
) =>
  options.sort((a, b) => {
    if (a.label > b.label) {
      return -1;
    }
    if (a.label < b.label) {
      return 1;
    }
    return 0;
  });

export const monthFilterOptions = Object.values(MONTH_FILTER_OPTIONS_ENUM).map(
  ({ name, code }) => ({
    label: name,
    value: code,
  })
);

/**
 * Creates dropdown options for DurationType
 * @returns durationDropdownOptions options
 */
export const durationDropdownOptions = enumValuesToDropdownOptions(
  Object.values(DurationType)
);

/**
 * Creates dropdown options for Currency
 * @returns currencyDropdownOptions options
 */
export const currencyDropdownOptions = enumValuesToDropdownOptions(
  Object.values(Currency)
);

export const employmentTypeOptions = Object.values(employmentTypeEnum).map(
  ({ code, name }) => ({ value: code, label: name })
);
