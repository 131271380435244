import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import Select from 'react-select';

import axios from '../../services/axios/axios';
import { isNullOrUndefinedOrEmpty } from '../../utils/helpers/GenericHelper';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import i18n from '../../i18n';
import { IDropdownOption } from '../../utils/types/commonTypes';
import {
  IContactPersonTopic,
  IContactPersonTopicListItem,
} from '../../utils/types/modelTypes';

interface IProps extends IWithModalsProps {
  value: IContactPersonTopic[];
  onChange: (event: any) => void;
  readOnly?: boolean;
}

interface IState {
  topics: IContactPersonTopic[];
  topicIds: number[];
  dropdownOptions: IDropdownOption[];
}

/**
 * A class to display the multi-select dropdown of contact person topics
 */
class ContactPersonTopicDropdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      topics: [],
      topicIds: [],
      dropdownOptions: [],
    };
  }

  t = (keyName: string) => i18n.t(`ContactPersonTopicDropdown.${keyName}`);

  async componentDidMount() {
    const topics = await axios.sales
      .get('contact-person-topics-settings')
      .then((response: AxiosResponse<IContactPersonTopicListItem[]>) =>
        response.data
          .map((topic) => ({
            id: topic?.id ?? 0,
            topic: topic?.topic,
          }))
          .filter((topic) => topic.id !== 0)
      )
      .catch((error: AxiosError) => {
        this.props.modalErrorHandler(this.t('failedToRetrieveTopics'), error);
        return [];
      });

    const dropdownOptions = topics
      .map((topic) => ({
        label: topic?.topic ?? '',
        value: topic?.id?.toString() ?? '',
      }))
      .filter((option) => option.value !== '' || option.label !== '');

    this.setState({ topics, dropdownOptions });

    this.updateSelectedIds();
  }

  componentDidUpdate(prevProps: IProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.updateSelectedIds();
    }
  }

  /**
   * Updates the already selected Ids
   */
  updateSelectedIds = () => {
    const { value } = this.props;
    this.setState({ topicIds: value.map((topic) => topic.id ?? 0) });
  };

  /**
   * Handles the changes on the dropdown
   * @param selectedTopics
   * @returns
   */
  onChangeHandler = (selectedTopics: IDropdownOption<number>[]) => {
    const { onChange } = this.props;

    if (isNullOrUndefinedOrEmpty(selectedTopics)) {
      onChange([]);
      return;
    }
    onChange(selectedTopics);
  };

  render() {
    const { dropdownOptions, topicIds } = this.state;
    return (
      <div data-testid="contact-person-topic-dropdown-div">
        <Select
          isMulti
          options={dropdownOptions}
          value={dropdownOptions?.filter((topic) =>
            topicIds?.some((id) => id?.toString() === topic.value)
          )}
          onChange={this.onChangeHandler}
          isClearable
          isDisabled={this.props.readOnly ?? false}
          placeholder={this.t('selectTopics')}
        />
      </div>
    );
  }
}

export default withModals(ContactPersonTopicDropdown);
