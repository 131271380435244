import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';

import DynamicTable from '../../components/tables/DynamicTable';
import { ACCOMPLISHED, REMOVED } from '../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';
import { OBJECT_TYPE_ENUM } from '../../utils/enums/objectType';
import { SERVICE_NAMES_ENUM } from '../../utils/enums/service';
import { isEmpty, sortByDate } from '../../utils/helpers/GenericHelper';
import { generateTitle } from '../../utils/helpers/icon';
import { IUserToDoActivity } from '../../utils/types/modelTypes';
import ProjectOptionModal from '../salesFunnel/ProjectOptionModal/ProjectOptionModal';
import ContactPersonDetailsProvider from '../crm/ContactDetails/ContactPersonDetailsProvider';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import '../../assets/css/app.css';
import {
  getCurrentUserActivities,
  updateUserToDoActivities,
} from '../../services/api/project';
import {
  convertDate,
  convertActionToTranslatedLabel,
  dateToday,
  flattenToDoActivities,
  getActivityIcon,
  showDueDateColumn,
  translateToDoListOverview,
} from './homeHelper';
import ToDoListCalendar from './ToDoListCalendar';
import ContactPersonOverview from './ContactPersonOverview';

/**
 * Main dashboard page shows todoList overview
 */

interface IProps extends IWithModalsProps {
  activities: IUserToDoActivity[];
}
interface IEntry {
  completeTask: JSX.Element;
  dueDate: JSX.Element;
  activity: string;
  title: JSX.Element;
  module: JSX.Element;
  modify: JSX.Element;
}

const ToDoListOverview: React.FC<IProps> = ({
  activities,
  toggleModalForm,
  modalFormHandler,
  modalErrorHandler,
}: IProps) => {
  const [showFuture, setShowFuture] = useState<boolean>(true);
  const [activityCategoryFilter, setActivityCategoryFilter] = useState<string>(
    SERVICE_NAMES_ENUM.all.code
  );
  const [activitiesState, setActivitiesState] =
    useState<IUserToDoActivity[]>(activities);
  const [todaysActivities, setTodaysActivities] = useState<IUserToDoActivity[]>(
    []
  );
  const [futureActivities, setFutureActivities] = useState<IUserToDoActivity[]>(
    []
  );
  const [overdueActivities, setOverdueActivities] = useState<
    IUserToDoActivity[]
  >([]);

  /**
   * Function to filter activity array on a category
   * @param {*} activityToFilter
   * @param {*} filterCategory
   * @returns filtered array object on the specified category
   */
  const filterActivityOnCategory = (
    activityToFilter: IUserToDoActivity[],
    filterCategory: string
  ) => {
    switch (filterCategory) {
      case SERVICE_NAMES_ENUM.crm.code:
        return activityToFilter.filter(
          (item) => item?.objectType === OBJECT_TYPE_ENUM.contactPerson.code
        );
      case SERVICE_NAMES_ENUM.projects.code:
        return activityToFilter.filter(
          (item) => item?.objectType === OBJECT_TYPE_ENUM.project.code
        );
      default:
        return [];
    }
  };

  /**
   * Handles saving activities in calendar
   */
  const setUpdatedActivities = (updatedActivities: IUserToDoActivity[]) => {
    setActivitiesState(updatedActivities);
  };

  /**
   * Handles calendar icon click
   */
  const handleCalendarEditClick = (item: IUserToDoActivity) => {
    modalFormHandler(
      translateToDoListOverview('updateSchedule'),
      <ToDoListCalendar
        activity={item}
        activitiesState={activitiesState}
        setUpdatedActivities={setUpdatedActivities}
        closeCalendar={() => toggleModalForm()}
      />,
      'md'
    );
  };

  /**
   * Shows modal for updating the project
   * @param {*} event
   * @param {*} extendedProjectOption
   */
  const onProjectUpdate = (projectId: number) => {
    modalFormHandler(
      translateToDoListOverview('updateSchedule'),
      <ProjectOptionModal projectId={projectId} onClose={toggleModalForm} />,
      'xl'
    );
  };

  /**
   * Get Current User's Activities
   */
  const handleUpdateActivity = async () => {
    // Get Current User's Activities
    const response = await getCurrentUserActivities();
    const newActivities = flattenToDoActivities(response.data);
    setActivitiesState([...newActivities]);
  };

  const onContactClick = (contactId: number) => {
    modalFormHandler(
      translateToDoListOverview('updateContact'),
      <ContactPersonDetailsProvider>
        <ContactPersonOverview
          contactId={contactId}
          handleUpdateActivity={handleUpdateActivity}
        />
      </ContactPersonDetailsProvider>,
      'xl'
    );
  };

  /**
   * Updates the status of an activity to ACCOMPLISHED
   * @param taskId id of the userToDoActivity
   */
  const handleCompleteTaskClick = async (taskId: number) => {
    await updateUserToDoActivities(taskId, {
      id: taskId,
      status: ACCOMPLISHED,
    } as IUserToDoActivity)
      .then((response: AxiosResponse<IUserToDoActivity>) => {
        switch (response.data.objectType) {
          case OBJECT_TYPE_ENUM.project.code:
            onProjectUpdate(response.data.objectId);
            break;
          case OBJECT_TYPE_ENUM.contactPerson.code:
            onContactClick(response.data.objectId);
            break;
          default:
            break;
        }
        const updatedActivities = activities.filter(
          (item) => item.id !== taskId
        );
        setActivitiesState(updatedActivities);
      })
      .catch((error) => {
        modalErrorHandler(
          translateToDoListOverview('failedToSaveActivity'),
          error
        );
      });
  };

  /**
   * Updates the status of an activity to REMOVED
   * @param taskId id of the userToDoActivity
   */
  const handleDeleteTaskClick = async (taskId: number) => {
    await updateUserToDoActivities(taskId, {
      id: taskId,
      status: REMOVED,
    } as IUserToDoActivity)
      .then(() => {
        const updatedActivities = activities.filter(
          (item) => item.id !== taskId
        );
        setActivitiesState(updatedActivities);
      })
      .catch((error) => {
        modalErrorHandler(
          translateToDoListOverview('failedToDeleteActivity'),
          error
        );
      });
  };

  /**
   * Returns table to be displayed in JSX format
   * @param {*} data for table to be displayed
   * @returns
   */
  const getTableData = (data: IUserToDoActivity[]) => {
    const preparedColumns = [
      {
        type: 'data',
        header: '',
        accessor: 'completeTask',
        show: 'true',
      },
      {
        type: 'data',
        header: translateToDoListOverview('dueDate'),
        accessor: 'dueDate',
        show: showDueDateColumn(data),
      },
      {
        type: 'data',
        header: translateToDoListOverview('activity'),
        accessor: 'activity',
        show: 'true',
      },
      {
        type: 'data',
        header: translateToDoListOverview('with'),
        accessor: 'title',
        show: 'true',
      },
      {
        type: 'data',
        header: translateToDoListOverview('module'),
        accessor: 'module',
        show: 'true',
      },
      {
        type: 'data',
        header: translateToDoListOverview('modify'),
        accessor: 'modify',
        show: 'true',
      },
    ];
    if (!isEmpty(data)) {
      const dataEntries: IEntry[] = [];
      data.forEach((item: IUserToDoActivity) => {
        const {
          id,
          dueDate,
          contactPerson,
          project,
          action,
          objectId,
          objectType,
        } = item;
        const entry: IEntry = {
          completeTask: (
            <Tooltip
              title={
                <div style={{ fontSize: '15px' }}>
                  {translateToDoListOverview('completeTask')}
                </div>
              }
              arrow
            >
              <Button
                color="link"
                onClick={() => handleCompleteTaskClick(id as number)}
              >
                <FontAwesomeIcon icon={faCircle} />
              </Button>
            </Tooltip>
          ),
          dueDate: (
            <p style={{ color: showFuture ? '' : 'red' }}>
              {convertDate(dueDate)}
            </p>
          ),
          activity: convertActionToTranslatedLabel(action),
          title: project ? (
            <Button color="link" onClick={() => onProjectUpdate(objectId)}>
              {project.title}
            </Button>
          ) : (
            <>
              <Button
                color="link"
                onClick={() => onContactClick(objectId)}
                id={`contact-button-${id}`}
              >
                {`${contactPerson?.firstname} ${contactPerson?.lastname}`}
              </Button>
              <UncontrolledTooltip
                placement="bottom"
                target={`contact-button-${id}`}
              >
                {`Customer: ${contactPerson?.customer?.name}`}
              </UncontrolledTooltip>
            </>
          ),
          module: <FontAwesomeIcon icon={getActivityIcon(objectType)} />,
          modify: (
            <div>
              <Tooltip
                title={
                  <div style={{ fontSize: '15px' }}>
                    {translateToDoListOverview('rescheduleTask')}
                  </div>
                }
                arrow
              >
                <Button
                  color="link"
                  onClick={() => handleCalendarEditClick(item)}
                >
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  <div style={{ fontSize: '15px' }}>
                    {translateToDoListOverview('deleteTask')}
                  </div>
                }
                arrow
              >
                <Button
                  color="link"
                  onClick={() => handleDeleteTaskClick(id as number)}
                >
                  {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
                </Button>
              </Tooltip>
            </div>
          ),
        };
        dataEntries.push(entry);
      });
      return (
        <DynamicTable
          infiniteScroll
          columns={preparedColumns}
          data={dataEntries}
        />
      );
    }
    return (
      <Table>
        <tbody>
          <ListGroup>
            <ListGroupItem className="text-center">
              {translateToDoListOverview('noData')}
            </ListGroupItem>{' '}
          </ListGroup>
        </tbody>
      </Table>
    );
  };

  /**
   * Function to render buttons for rider filters
   * @returns JSX formatted buttons of rider filters
   */
  const renderFilterRiders = () =>
    Object.values(SERVICE_NAMES_ENUM).map((item) => (
      <Button
        key={item.code}
        onClick={() => setActivityCategoryFilter(item.code)}
        className="border-grey"
        color="white"
      >
        {item.name}
      </Button>
    ));

  useEffect(() => {
    setActivitiesState([...activities]);
  }, [activities]);

  useEffect(() => {
    // Filter and sort the activities based on your logic
    const filteredTodayActivities = activitiesState.filter(
      (activity) => convertDate(activity?.dueDate ?? '') === dateToday
    );

    const filteredFutureActivities = activitiesState.filter(
      (activity) => convertDate(activity?.dueDate ?? '') > dateToday
    );

    const filteredOverdueActivities = activitiesState.filter(
      (activity) => convertDate(activity?.dueDate ?? '') < dateToday
    );

    if (activityCategoryFilter !== SERVICE_NAMES_ENUM.all.code) {
      setTodaysActivities([
        ...filterActivityOnCategory(
          filteredTodayActivities,
          activityCategoryFilter
        ),
      ]);
      setFutureActivities([
        ...filterActivityOnCategory(
          filteredFutureActivities,
          activityCategoryFilter
        ),
      ]);
      setOverdueActivities([
        ...filterActivityOnCategory(
          filteredOverdueActivities,
          activityCategoryFilter
        ),
      ]);
    } else {
      setTodaysActivities([...filteredTodayActivities]);
      setFutureActivities([...filteredFutureActivities]);
      setOverdueActivities([...filteredOverdueActivities]);
    }
  }, [activitiesState, activityCategoryFilter]);

  return (
    <Card>
      <div className="flex-fill" id="to-do-list-filter-buttons">
        {renderFilterRiders()}
      </div>
      <CardHeader>
        <div className="float-end">
          <Button color="primary" onClick={() => setShowFuture(!showFuture)}>
            {showFuture
              ? translateToDoListOverview('showOverdue')
              : translateToDoListOverview('showFuture')}
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <p className="text-center">
          <strong>{translateToDoListOverview('todaysActivities')}</strong>
        </p>
        {getTableData(todaysActivities)}
        <p className="text-center">
          <strong>
            {showFuture
              ? translateToDoListOverview('futureActivities')
              : translateToDoListOverview('overdueActivities')}
          </strong>
        </p>
        {showFuture
          ? getTableData(
              sortByDate(
                futureActivities,
                'dueDate',
                false
              ) as IUserToDoActivity[]
            )
          : getTableData(
              sortByDate(overdueActivities, 'dueDate') as IUserToDoActivity[]
            )}
      </CardBody>
    </Card>
  );
};

export default withModals(ToDoListOverview);
