import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { ConnectedProps, connect } from 'react-redux';

import { changeActionColor, t } from './utilizationHelper';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import EmployeeProjectsRowView from './EmployeeProjectsRowView';
import { IUtilizationListItem } from '../../../utils/types/responseTypes';
import { dateFormat } from '../../../utils/constants';
import { IObjectProjectUtilization } from '../../../utils/types/commonTypes';
import {
  updateEmployeeAction,
  updateEmployeeLatestDate,
} from '../../../services/api/resourcePlanner';
import UtilizationActionModal from './UtilizationActionModal';
import AddCommentModal from './AddCommentModal';
import { deleteActivity } from '../../../services/api/activity';
import { RootState } from '../../../redux/store';
import {
  IObjectTimespan,
  ResourceViewMode,
} from '../ResourceOverview/resourceOverviewHelper';
import ProjectWorkloadRowView from '../ResourceOverview/ProjectWorkloadRowView';

interface IProps extends IWithModalsProps, PropsFromRedux {
  employeeUtilization: IUtilizationListItem;
  monthRange: IObjectTimespan[];
  setUtilizations: Dispatch<SetStateAction<IUtilizationListItem[]>>;
  fetchUtilizations: () => Promise<void>;
}

/**
 * UtilizationTableRow
 * Display the utilization of an individual employee
 */
const UtilizationTableRow = ({
  currentUser: { id: currentUserId },
  employeeUtilization,
  monthRange,
  setUtilizations,
  fetchUtilizations,
  modalErrorHandler,
  modalFormHandler,
  modalConfirmHandler,
  toggleModalForm,
}: IProps) => {
  const {
    employee,
    monthlyProjectUtilization,
    responsible: { id: responsibleId, name: responsibleName },
    latestUpdate,
    action,
    comments,
  } = employeeUtilization;
  const [showProjects, setShowProjects] = useState<boolean>(false);

  const handleUpdateEmployeeWorkloadStatus = (
    updatedProjectUtilization: IObjectProjectUtilization
  ) => {
    // Update Employee Utilization
    const updatedEmployeeUtilization = {
      ...employeeUtilization,
      monthlyProjectUtilization: [
        ...employeeUtilization.monthlyProjectUtilization.map(
          (projectUtilization) =>
            projectUtilization.id === updatedProjectUtilization.id
              ? updatedProjectUtilization
              : projectUtilization
        ),
      ],
    };
    // Update the list of states
    setUtilizations((utilizations) => [
      ...utilizations.map((utilization) =>
        utilization.employee.id === updatedEmployeeUtilization.employee.id
          ? updatedEmployeeUtilization
          : utilization
      ),
    ]);
  };

  const handleSaveAction = async (selectedAction: string) => {
    const { id: employeeId } = employee;
    try {
      const { data: action } = await updateEmployeeAction(
        employeeId,
        selectedAction
      );

      const updatedEmployeeUtilization = { ...employeeUtilization, action };
      const {
        employee: { id: updatedEmployeeId },
      } = updatedEmployeeUtilization;
      setUtilizations((values) =>
        values.map((employeeUtilization) => {
          const {
            employee: { id: employeeId },
          } = employeeUtilization;
          if (employeeId === updatedEmployeeId) {
            return updatedEmployeeUtilization;
          } else {
            return employeeUtilization;
          }
        })
      );

      toggleModalForm();
    } catch (error) {
      modalErrorHandler('failedToUpdateEmployee', error);
    }
  };

  const handleSetActionButton = () => {
    modalFormHandler(
      t('actionModalTitle'),
      <UtilizationActionModal action={action} onSave={handleSaveAction} />
    );
  };

  const handleAddCommentButton = (
    employeeUtilization: IUtilizationListItem
  ) => {
    modalFormHandler(
      t('addComment'),
      <AddCommentModal
        employeeUtilization={employeeUtilization}
        setUtilizations={setUtilizations}
        onClose={toggleModalForm}
      />
    );
  };

  const updateUtilizationDate = async () => {
    const { id: employeeId } = employee;
    try {
      const { data: date } = await updateEmployeeLatestDate(employeeId);

      const updatedEmployeeUtilization = {
        ...employeeUtilization,
        latestUpdate: moment(date).format(dateFormat).toString(),
      };

      const {
        employee: { id: updatedEmployeeId },
      } = updatedEmployeeUtilization;

      setUtilizations((values) =>
        values.map((employeeUtilization) => {
          const {
            employee: { id: employeeId },
          } = employeeUtilization;
          if (employeeId === updatedEmployeeId) {
            return updatedEmployeeUtilization;
          } else {
            return employeeUtilization;
          }
        })
      );
    } catch (error) {
      modalErrorHandler('failedToUpdateEmployee', error);
    }
  };

  const handleLatestUpdateButton = () => {
    modalConfirmHandler(
      t('updateLastModifiedDate'),
      t('lastModifiedDateConfirmMessage'),
      () => updateUtilizationDate()
    );
  };

  const handleDeleteCommentButton = async (toRemoveCommentId: number) => {
    const updatedComments = comments.filter(
      ({ id: commentId }) => commentId !== toRemoveCommentId
    );
    try {
      await deleteActivity(toRemoveCommentId);
    } catch (error) {
      modalErrorHandler('failedToDeleteComment', error);
    }
    const updatedEmployeeUtilization = {
      ...employeeUtilization,
      comments: updatedComments,
    };

    const {
      employee: { id: updatedEmployeeId },
    } = updatedEmployeeUtilization;

    setUtilizations((values) =>
      values.map((employeeUtilization) => {
        const {
          employee: { id: employeeId },
        } = employeeUtilization;
        if (employeeId === updatedEmployeeId) {
          return updatedEmployeeUtilization;
        } else {
          return employeeUtilization;
        }
      })
    );
  };

  return (
    <>
      <tr key={employee.id}>
        <EmployeeProjectsRowView
          modeView={ResourceViewMode.MONTHLY}
          showProjects={showProjects}
          setShowProjects={setShowProjects}
          employee={employee}
          projectUtilization={monthlyProjectUtilization}
          headerRange={monthRange}
          fetchUtilizations={fetchUtilizations}
        />
        <td
          className="utilizationTableData"
          style={{ backgroundColor: changeActionColor(action) }}
        >
          {responsibleName}
        </td>
        <td
          className="utilizationTableData"
          style={{ backgroundColor: changeActionColor(action) }}
        >
          <Label>
            {moment(latestUpdate).format(dateFormat).toString() ||
              t('noRecords')}
          </Label>
          <div>
            <Button
              disabled={currentUserId !== responsibleId}
              color="primary"
              size="m"
              onClick={() => handleLatestUpdateButton()}
            >
              {t('update')}
            </Button>
          </div>
        </td>
        <td
          className="utilizationTableData"
          style={{ backgroundColor: changeActionColor(action) }}
        >
          <Button
            disabled={currentUserId !== responsibleId}
            color="primary"
            size="m"
            onClick={() => handleSetActionButton()}
          >
            {t('set')}
          </Button>
        </td>
        <td
          className="utilizationTableData text-left"
          style={{ backgroundColor: changeActionColor(action) }}
        >
          {comments.map(({ id, name: description, creatorId }) => (
            <div key={id} style={{ textAlign: 'left', margin: '0 0 0.1vw 0' }}>
              <Button
                aria-label="utilization-comment-delete-button"
                disabled={creatorId !== responsibleId}
                color="danger"
                onClick={() => handleDeleteCommentButton(id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
              <span style={{ margin: '0 0 0 1vw' }}>{description}</span>
            </div>
          ))}
          <div className="text-center">
            <Button
              color="primary"
              size="m"
              onClick={() => handleAddCommentButton(employeeUtilization)}
            >
              <FontAwesomeIcon icon={faAdd} />
              <span className="ml-1">{t('addComment')}</span>
            </Button>
          </div>
        </td>
      </tr>
      {showProjects &&
        monthlyProjectUtilization.map((projectUtilization, idx) => (
          <ProjectWorkloadRowView
            key={idx}
            projectWorkload={projectUtilization}
            updateWorkloadStatus={handleUpdateEmployeeWorkloadStatus}
          />
        ))}
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  currentUser: store.account.employeeDetails,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withModals(connector(UtilizationTableRow));
