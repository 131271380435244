import React, { useContext, useEffect, useState } from 'react';
import { Card, Row, Input, Button } from 'reactstrap';
import Datetime from 'react-datetime';
import moment, { Moment } from 'moment';

import i18n from '../../../../i18n';
import { OrderSummaryContext } from './OrderSummaryProvider';
import DepartmentDropdown from '../../../../components/dropdowns/DepartmentDropdown';
import { PERMISSION_URI } from '../../../../utils/enums/permission';
import DetailsCard from '../../../../components/cards/DetailsCard';
import { IDropdownOption } from '../../../../utils/types/commonTypes';
import { IDepartment } from '../../../../utils/types/modelTypes';
import { isNullOrUndefinedOrEmpty } from '../../../../utils/helpers/GenericHelper';
import withModals, { IWithModalsProps } from '../../../../utils/withModals';
import { DATE_FORMAT, translate } from './orderHelper';
import ModifiedEmployeeDropdown from '../../../../components/dropdowns/EmployeeDropdown/ModifiedEmployeeDropdown';

interface IProps extends IWithModalsProps {
  toggleModal: () => void;
  saveOrder: () => void;
}

const OrderedProjectModal = ({ toggleModal, saveOrder }: IProps) => {
  const { project, formValues, setFormValues } =
    useContext(OrderSummaryContext);

  const {
    departments,
    startBillingDefined,
    kickOffMeeting,
    internalKickOff,
    employeeManager,
  } = formValues;
  const { projectTitle, projectId, sharepointLink, description } = project;

  const [isDisabled, setIsDisabled] = useState(true);

  const handleDepartmentChange = (selectedDepartments: IDepartment[]) => {
    const deptNameAndId = selectedDepartments.map(
      (selected) =>
        ({
          value: selected.id,
          label: selected.title,
        } as IDropdownOption<number>)
    );

    setFormValues((values) => ({ ...values, departments: deptNameAndId }));
  };

  const handleBillingDefined = (event: Moment | string) => {
    setFormValues((values) => ({
      ...values,
      startBillingDefined: moment(event).toISOString(),
    }));
  };

  const handleKickOffMeeting = (event: Moment | string) => {
    setFormValues((values) => ({
      ...values,
      kickOffMeeting: moment(event).toISOString(),
    }));
  };

  const handleInternalKickOff = (event: Moment | string) => {
    setFormValues((values) => ({
      ...values,
      internalKickOff: moment(event).toISOString(),
    }));
  };

  const handleManagerChange = (selectedManager: IDropdownOption<number>) => {
    setFormValues((values) => ({
      ...values,
      employeeManager: selectedManager,
    }));
  };

  const handleSaveProjectAndOrder = () => {
    saveOrder();
    toggleModal();
  };

  useEffect(() => {
    if (
      !isNullOrUndefinedOrEmpty(departments) &&
      !isNullOrUndefinedOrEmpty(employeeManager)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [departments, employeeManager]);

  const projectDetailList = [
    {
      label: translate('projectName'),
      value: <Input value={projectTitle} disabled />,
    },
    {
      label: translate('projectId'),
      value: <Input value={projectId} disabled />,
    },
    {
      label: translate('departments'),
      value: (
        <DepartmentDropdown
          selectedDepartments={
            departments.map((dept) => ({ id: dept.value, name: dept.label })) ??
            []
          }
          onChange={handleDepartmentChange}
          isMulti
        />
      ),
      isRequired: true,
    },
    {
      label: translate('sharepoint'),
      value: <Input value={sharepointLink} disabled />,
    },
  ];

  const orderSettingsList = [
    {
      label: translate('startOfBillingDefined'),
      value: (
        <Datetime
          dateFormat={DATE_FORMAT}
          locale={i18n.language}
          timeFormat={false}
          closeOnSelect
          value={moment(startBillingDefined).format(DATE_FORMAT)}
          onChange={handleBillingDefined}
        />
      ),
    },
    {
      label: translate('kickOffMeeting'),
      value: (
        <Datetime
          dateFormat={DATE_FORMAT}
          locale={i18n.language}
          timeFormat={false}
          closeOnSelect
          value={moment(kickOffMeeting).format(DATE_FORMAT)}
          onChange={handleKickOffMeeting}
        />
      ),
    },
    {
      label: translate('internalKickOff'),
      value: (
        <Datetime
          dateFormat={DATE_FORMAT}
          locale={i18n.language}
          timeFormat={false}
          closeOnSelect
          value={moment(internalKickOff).format(DATE_FORMAT)}
          onChange={handleInternalKickOff}
        />
      ),
    },
    {
      label: translate('projectDescription'),
      value: <Input value={description} disabled />,
    },
    {
      label: translate('projectManager'),
      value: (
        <ModifiedEmployeeDropdown
          onChange={handleManagerChange}
          employeeId={employeeManager.value}
          permissions={[
            PERMISSION_URI.becomeProjectManager.readWrite.uri,
            PERMISSION_URI.assignedToProjects.readWrite.uri,
          ]}
        />
      ),
      isRequired: true,
    },
  ];
  return (
    <Card style={{ border: '0', boxShadow: 'none' }}>
      <Row>
        <DetailsCard
          title={translate('newProjectCreated')}
          fields={projectDetailList}
        />
      </Row>
      <Row>
        <DetailsCard
          title={translate('defineSettings')}
          fields={orderSettingsList}
        />
      </Row>
      <Row>
        <div className="centered-div">
          <Button
            color="primary"
            className="continue-and-cancel-button"
            onClick={handleSaveProjectAndOrder}
            disabled={isDisabled}
          >
            {translate('ok')}
          </Button>
        </div>
      </Row>
    </Card>
  );
};

export default withModals(OrderedProjectModal);
