import { AxiosError, AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import axios from '../../../services/axios/axios';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { IRejectionType } from '../../../utils/types/modelTypes';

interface IProps extends IWithModalsProps {
  onChange: (rejectionType: IRejectionType) => void;
  selectedType: IRejectionType | undefined;
}

/**
 * A component to display the dropdown of RejectionTypes
 */
const RejectionTypeDropdown = ({
  onChange,
  modalErrorHandler,
  selectedType,
}: IProps) => {
  const t = (keyName: string) => i18n.t(`RejectionTypeDropdown.${keyName}`);
  const [types, setTypes] = useState([] as IRejectionType[]);
  const [dropdownOptions, setDropdownOptions] = useState(
    [] as IDropdownOption[]
  );

  const fetchData = () => {
    axios.sales
      .get('rejection-types')
      .then((response: AxiosResponse<IRejectionType[]>) => {
        const { data: fetchedTypes } = response;

        setTypes(fetchedTypes);
        setDropdownOptions(
          fetchedTypes?.map((type: IRejectionType) => ({
            label: type.name,
            value: JSON.stringify(type),
          }))
        );
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToGetAllTypes'), error);
      });
  };

  /**
   * Fetch the Rejection types on component mount
   */
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Handles the changes in the dropdown
   * @param {*} selectedType dropdown option
   */
  const handleChangeType = (selectedType: IDropdownOption) => {
    const type = types.find((item) => item.name === selectedType.label);
    if (type) {
      onChange(type);
    }
  };

  return (
    <Select
      onChange={handleChangeType}
      options={dropdownOptions}
      value={dropdownOptions.find(
        (option) => option.label == selectedType?.name
      )}
      placeholder={t('selectType')}
    />
  );
};

export default withModals(RejectionTypeDropdown);
