import React from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import Select from 'react-select';

import i18n from '../../i18n';
import {
  IDropdownOption,
  IObjectContactInformation,
} from '../../utils/types/commonTypes';
import {
  enumValuesToDropdownOptions,
  sortOptionsByValue,
} from '../../utils/helpers/dropdown';
import { ContactInformationStatus } from '../../utils/enums/contactPerson';
import { OBJECT_TYPE_ENUM } from '../../utils/enums/objectType';
import { ContactInfoType } from '../../utils/enums/contact';
import { generateTitle } from '../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';

const TEXTBOX_COLUMN_WIDTH = '7';
const CHECKBOX_COLUMN_WIDTH = '4';
const BUTTON_COLUMN_WIDTH = '1';
const contactInformationState = enumValuesToDropdownOptions(
  Object.values(ContactInformationStatus),
  'CampaignManager'
);
const t = (keyName: string) => i18n.t(`ContactInformationInput.${keyName}`);

interface IProps {
  index: number;
  contactInformation: IObjectContactInformation;
  objectType: string;
  onChange: (value: string, index: number) => void;
  onEmailStatusChange?: (status: string, index: number) => void;
  onDelete: (
    contactInformation: IObjectContactInformation,
    index: number
  ) => void;
}

const ContactInformationInput = ({
  index,
  contactInformation,
  contactInformation: { info, status, type: informationType },
  objectType,
  onChange,
  onEmailStatusChange,
  onDelete: onDeleteContactInformation,
}: IProps) => {
  const handleEmailStatusChange = (value: string, index: number) => {
    if (onEmailStatusChange) {
      onEmailStatusChange(value, index);
    }
  };

  return (
    <Row className="row-padding-5">
      <Col lg={TEXTBOX_COLUMN_WIDTH}>
        <Input
          bsSize="medium"
          type="string"
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => onChange(value, index)}
          value={info ?? ''}
        />
      </Col>
      {informationType === ContactInfoType.EMAIL && (
        <Col lg={CHECKBOX_COLUMN_WIDTH}>
          {objectType === OBJECT_TYPE_ENUM.contactPerson.code ? (
            <div className="text-center">
              <Input
                type="checkbox"
                checked={status === ContactInformationStatus.GUESSED}
                onChange={({
                  target: { checked },
                }: React.ChangeEvent<HTMLInputElement>) =>
                  handleEmailStatusChange(checked ? 'true' : 'false', index)
                }
              />
              {t(ContactInformationStatus.GUESSED)}
            </div>
          ) : (
            <Select
              options={sortOptionsByValue(contactInformationState)}
              objectId={status || ContactInformationStatus.UNKNOWN}
              onChange={({ value }: IDropdownOption) =>
                handleEmailStatusChange(value, index)
              }
              value={contactInformationState.find(
                ({ value }) => value === status
              )}
            />
          )}
        </Col>
      )}
      <Col lg={BUTTON_COLUMN_WIDTH}>
        <Button
          style={{
            padding: '0px',
          }}
          color="link"
          onClick={() => onDeleteContactInformation(contactInformation, index)}
        >
          {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
        </Button>
      </Col>
    </Row>
  );
};

export default ContactInformationInput;
