/* eslint-disable no-plusplus */
import { Link } from 'react-router-dom';
import React from 'react';

import {
  IObjectContactInformation,
  IObjectInvolvedResponsible,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import { ICustomerSiteDetail } from '../../../utils/types/responseTypes';
import {
  contactInfoEnum,
  customerSiteFieldsEnum,
} from '../../../utils/enums/enum';
import {
  IContactInformation,
  ICountryState,
  ICustomerSite,
} from '../../../utils/types/modelTypes';
import {
  getRemovedObjectNameAndIdsFromList,
  isEmpty,
} from '../../../utils/helpers/array';
import i18n from '../../../i18n';
import { REGEX_VALID_CONTACT_NUMBER_FORMAT } from '../../../utils/constants';

/**
 * Translator function for Customer Site Detail
 * @param keyName Key for phrase to be translated
 * @returns Translated string
 */
export const translate = (keyName: string) =>
  i18n.t(`CustomerSiteList.${keyName}`);

export const showNoneIfNoValue = (word: string | null | undefined) => {
  if (!word) {
    return translate('none');
  }
  return word.trim();
};

const getFirstName = (fullName: string) => {
  const lastNameIndex = fullName.lastIndexOf(' ');
  return fullName.substring(0, lastNameIndex);
};

const getLastName = (fullName: string) => fullName.split(' ').pop() || '';

export const matchingSectors = (
  siteSectors: IObjectNameAndId[],
  editedSiteSectors: IObjectNameAndId[]
) => {
  const removedSectors = getRemovedObjectNameAndIdsFromList(
    siteSectors,
    editedSiteSectors
  );

  return removedSectors.filter((sector) =>
    siteSectors.find(
      (siteSector: IObjectNameAndId) => sector.id === siteSector.id
    )
  );
};

export const convertContactPersonForMultipleContactPerson = (
  editedContactPersons: IObjectNameAndId[],
  customerSiteId: number
) =>
  editedContactPersons.map(({ id, name }) => ({
    id,
    lastname: getLastName(name),
    firstname: getFirstName(name),
    contactInformations: null,
    topics: null,
    customer: null,
    contactPersonRole: null,
    contactPersonAssignments: null,
    contactPersonAttitudes: null,
    contactPersonNotes: null,
    customerSite: { id: customerSiteId } as ICustomerSite,
  }));

export interface IEditedCustomerSiteDetails {
  id: number;
  name: string;
  location: string;
  targetPriority: string;
  customerSiteState: string;
  sectors: IObjectNameAndId[];
  responsible: IObjectNameAndId;
  involvedEmployees: IObjectInvolvedResponsible[];
  contactInformation: IObjectContactInformation[];
  customerSiteType: string;
  street: string;
  zipCode: string;
  country: IObjectNameAndId;
  countryState: ICountryState | null;
  contactPersons: IObjectNameAndId[];
}

export const createFormValuesFromCustomerSite = ({
  id,
  countryState,
  country,
  customerSiteState,
  location,
  sectors,
  contactInformation,
  contactPersons,
  name,
  street,
  zipCode,
  customerSiteType,
  targetPriority,
  responsible,
  involvedEmployees,
}: ICustomerSiteDetail) => ({
  id,
  name,
  location,
  targetPriority,
  customerSiteState,
  sectors,
  responsible,
  involvedEmployees,
  contactInformation,
  customerSiteType,
  street,
  zipCode,
  country,
  countryState,
  contactPersons,
});

export const showCustomerAccountLink = (
  customerAccount: IObjectNameAndId | undefined
) =>
  customerAccount && customerAccount.name !== '' ? (
    <Link to={`/customer/accounts/details/${customerAccount.id}`}>
      {customerAccount.name}
    </Link>
  ) : (
    translate('none')
  );

export const showCustomerLink = (customer: IObjectNameAndId | undefined) =>
  customer && customer.name !== '' ? (
    <Link to={`/customer/customers/details/${customer?.id}`}>
      {customer.name}
    </Link>
  ) : (
    translate('none')
  );

export const isContactPersonModified = (
  contactPersons: IObjectNameAndId[],
  newContactPersons: IObjectNameAndId[]
) =>
  isEmpty(
    contactPersons.filter((cpObjNameId) =>
      newContactPersons.find(
        (newContactPerson) => newContactPerson.id !== cpObjNameId.id
      )
    )
  );

/**
 * Check contact information for phone and mobile to follow valid entries
 * @param contactInformations - list of contactInformation to be checked if the phone or mobile follows
 * the valid contact number format
 * @returns boolean - true if follows number format, false otherwise
 */
export const checkForCorrectContactNumber = (
  contactInformations: IContactInformation[]
) => {
  let isValidContactNumber = true;
  contactInformations?.forEach((contactInformation) => {
    if (
      contactInformation !== null &&
      contactInformation?.contactInformationType !== null &&
      (contactInformation?.contactInformationType ===
        contactInfoEnum.phone.code ||
        contactInformation.contactInformationType ===
          contactInfoEnum.mobile.code)
    ) {
      if (
        !contactInformation?.info ||
        !contactInformation.info.match(REGEX_VALID_CONTACT_NUMBER_FORMAT)
      ) {
        isValidContactNumber = false;
      }
    }
  });
  return isValidContactNumber;
};

const isStringValid = (str: string) => str != null && str.trim() !== '';

export const checkContactInformationIfInvalid = (
  fieldsWithUpdates: string[],
  contactInformation: IObjectContactInformation[]
) => {
  if (fieldsWithUpdates.includes(customerSiteFieldsEnum.contactInformation)) {
    const invalidInfoEntries = contactInformation.filter(
      ({ info, type }) => !isStringValid(info) || !isStringValid(type)
    );
    return !isEmpty(invalidInfoEntries);
  }
  return false;
};
