import i18n from '../../i18n';

export const SKILL_ASSIGNMENT_ENUM = {
  requested: {
    name: i18n.t('SkillAssignments.REQUESTED'),
    code: 'REQUESTED',
  },
  accepted: {
    name: i18n.t('SkillAssignments.ACCEPTED'),
    code: 'ACCEPTED',
  },
  to_be_discussed: {
    name: i18n.t('SkillAssignments.TO_BE_DISCUSSED'),
    code: 'TO_BE_DISCUSSED',
  },
  preliminary: {
    name: i18n.t('SkillAssignments.PRELIMINARY'),
    code: 'PRELIMINARY',
  },
};

export const SKILLS_TYPE = {
  certification: {
    code: 'CERTIFICATION',
    name: 'Certification',
  },
  framework: {
    code: 'FRAMEWORK',
    name: 'Framework',
  },
  language: {
    code: 'LANGUAGE',
    name: ' Language',
  },
  library: {
    code: 'LIBRARY',
    name: 'Library',
  },
  method: {
    code: 'METHOD',
    name: 'Method',
  },
  protocol: {
    code: 'PROTOCOL',
    name: 'Protocol',
  },
  skill: {
    code: 'SKILL',
    name: 'Skill',
  },
  standard: {
    code: 'STANDARD',
    name: 'Standard',
  },
  tool: {
    code: 'TOOL',
    name: 'Tool',
  },
  topic: {
    code: 'TOPIC',
    name: 'Topic',
  },
  others: {
    code: 'OTHERS',
    name: 'Others',
  },
};

export const SkillLevel = {
  1: {
    code: 1,
    label: i18n.t('SkillLevel.level1Label'),
    name: i18n.t('SkillLevel.level1Name'),
    description: i18n.t('SkillLevel.level1Description'),
  },
  2: {
    code: 2,
    label: i18n.t('SkillLevel.level2Label'),
    name: i18n.t('SkillLevel.level2Name'),
    description: i18n.t('SkillLevel.level2Description'),
  },
  3: {
    code: 3,
    label: i18n.t('SkillLevel.level3Label'),
    name: i18n.t('SkillLevel.level3Name'),
    description: i18n.t('SkillLevel.level3Description'),
  },
  4: {
    code: 4,
    label: i18n.t('SkillLevel.level4Label'),
    name: i18n.t('SkillLevel.level4Name'),
    description: i18n.t('SkillLevel.level4Description'),
  },
  5: {
    code: 5,
    label: i18n.t('SkillLevel.level5Label'),
    name: i18n.t('SkillLevel.level5Name'),
    description: i18n.t('SkillLevel.level5Description'),
  },
};
