import React from 'react';

import { ContactPersonFunctionCampaign } from '../../../utils/enums/contactPerson';
import i18n from '../../../i18n';
import TableDataComponent from '../../../components/tables/TableDataComponent';
import { ContactInfoType } from '../../../utils/enums/contact';
import type { ICampaignManagerContactPersonListItem } from '../../../utils/types/responseTypes';
import type { ICampaignManagerContactPersonListFilters } from '../../../utils/types/stateTypes';
import { SortBy, SortType } from '../../../utils/enums/sort';
import { isEmpty } from '../../../utils/helpers/array';
import { IObjectContactInformation } from '../../../utils/types/commonTypes';
import { getAssignmentStatusName } from '../../../utils/helpers/assignmentStatus';

export const translateCampaignManager = (keyName: string) =>
  i18n.t(`CampaignManager.${keyName}`);

// Get the sector component
const getSectorComponent = (customerSectors: string[]) => (
  <TableDataComponent components={customerSectors} />
);

const getFunctionRole = (functionRole: string) => {
  const roleKey = functionRole as keyof typeof ContactPersonFunctionCampaign;

  if (roleKey in ContactPersonFunctionCampaign) {
    const roleValue = ContactPersonFunctionCampaign[roleKey]
      .toString()
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return roleValue;
  }
  return '';
};

export const createContactEntry = (
  contact: ICampaignManagerContactPersonListItem
) => {
  const {
    id,
    firstname,
    lastname,
    role,
    functionRole,
    sectors,
    customer,
    customerSite,
    country,
    assignmentState,
    newsletter,
  } = contact;

  return {
    id,
    firstname,
    lastname,
    role,
    functionRole: getFunctionRole(functionRole),
    sectors: getSectorComponent(sectors),
    customer,
    customerSite,
    country,
    assignmentState: getAssignmentStatusName(assignmentState),
    newsletter,
    contactInformations: '',
    contact,
  };
};

/**
 * Creates the query parameters to be used to fetch data
 * @returns Object containing query parameters
 */
export const createQueryParameters = (
  {
    role,
    customer,
    customerSite,
    functionRoles,
    industries,
    countries,
    statuses,
    emailStatuses,
    selectedLanguage,
    gdprStatuses,
    newsletters,
    topics,
  }: ICampaignManagerContactPersonListFilters,
  sortBy: SortBy,
  sortType: SortType,
  page?: number
) => ({
  ...(page && { page: page.toString() }),
  ...(role && {
    'contactPersonRole.contains': role,
  }),
  ...(customer && {
    'customerName.contains': customer,
  }),
  ...(customerSite && {
    'customerSiteName.contains': customerSite,
  }),
  ...(!isEmpty(functionRoles) && {
    'functionRole.in': functionRoles.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(industries) && {
    'customerSectors.in': industries.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(countries) && {
    'customerSiteCountry.in': countries.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(statuses) && {
    'assignmentState.in': statuses.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(emailStatuses) && {
    'contactInformationStatus.in': emailStatuses
      .map(({ value }) => value)
      .join(','),
  }),
  ...(!isEmpty(selectedLanguage) && {
    'preferredLanguage.in': selectedLanguage
      .map(({ value }) => value)
      .join(','),
  }),
  ...(!isEmpty(gdprStatuses) && {
    'statusGdpr.in': gdprStatuses.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(newsletters) && {
    'newsletter.in': newsletters.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(topics) && {
    'contactPersonTopicId.in': topics.map(({ value }) => value).join(','),
  }),
  ...(sortBy &&
    sortType && {
      sort: `${sortBy}%2C${sortType}`,
    }),
});

// Retrieve only the contacts with emails
export const retrieveContactsWithEmailsOnly = (
  contactsToSendEmailTo: ICampaignManagerContactPersonListItem[]
) =>
  contactsToSendEmailTo.filter(
    ({ contactInformations }: ICampaignManagerContactPersonListItem) =>
      contactInformations.some(
        ({ type }: IObjectContactInformation) => type === ContactInfoType.EMAIL
      )
  );
