import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import NavbarAlerts from './NavbarAlerts';
import Sidebar from './Sidebar';

const Dashboard = ({ children }) => (
  <React.Fragment>
    <div className="wrapper">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="content">
          <NavbarAlerts />
          {children}
        </div>
        <Footer />
      </div>
    </div>
  </React.Fragment>
);

export default Dashboard;
