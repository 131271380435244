/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import i18n from '../../../i18n';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IObjectContactPersonCustomerSite } from '../../../utils/types/responseTypes';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import {
  getAllCustomerSiteForContactPerson,
  getCustomerSiteForContactPerson,
} from '../../../services/api/customerSite';

interface IProps extends IWithModalsProps {
  customerId: number | undefined;
  customerSiteId: number | undefined;
  onChange: (customerSite: IObjectContactPersonCustomerSite) => void;
}

/**
 * Displays dropdown options for contactperson with customer site options
 */
const ContactPersonCustomerSiteSelect: React.FC<IProps> = ({
  modalErrorHandler,
  customerId,
  customerSiteId,
  onChange,
}) => {
  const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[]>([]);
  const [customerSites, setCustomerSites] = useState<
    IObjectContactPersonCustomerSite[]
  >([]);

  const fetchCustomerSites = async (id: number | null) => {
    try {
      const { data: fetchedCustomerSites } = id
        ? await getCustomerSiteForContactPerson({
            'customerId.equals': id.toString(),
          })
        : await getAllCustomerSiteForContactPerson();
      setCustomerSites(fetchedCustomerSites);
      setDropdownOptions(
        fetchedCustomerSites.map(({ id, nameAndLocation }) => ({
          value: id.toString(),
          label: nameAndLocation,
        }))
      );
    } catch (error) {
      modalErrorHandler('Data Generation', error);
    }
  };

  useEffect(() => {
    if (customerId || customerId === null) {
      fetchCustomerSites(customerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, customerSiteId]);

  const onChangeHandler = ({ value }: IDropdownOption) => {
    const customerSite = customerSites.find(
      (data) => data.id.toString() === value
    );
    if (customerSite) {
      onChange(customerSite);
    }
  };

  return (
    <div style={{ overflow: 'visible' }}>
      <Select
        value={dropdownOptions.find(
          (customerSite) => customerSite.value === customerSiteId?.toString()
        )}
        options={dropdownOptions}
        placeholder={i18n.t('CustomerSitesDropdown.selectLocation')}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default withModals(ContactPersonCustomerSiteSelect);
