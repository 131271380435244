import { IPermission, IResponsible, IRole } from './utils/types/modelTypes';

const user = {
  userId: '',
  role: '',
  permissions: [] as IPermission[],
  employeeId: '',
  employeeRoles: [] as IRole[],
  employeeResponsibles: [] as IResponsible[],
  userSetting: undefined,
};

export default user;
