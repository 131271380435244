import { PayloadAction } from '@reduxjs/toolkit';

import { getEmployeeNames, getListOfResponsibleNames } from '../services/api/employee';
import { Ownership } from '../utils/enums/ownership';
import { SortBy, SortType } from '../utils/enums/sort';
import { IDropdownOption } from '../utils/types/commonTypes';
import { ICustomerSiteListItem } from '../utils/types/responseTypes';
import { ICustomerSiteListFilters } from '../utils/types/stateTypes';
import createListSlice, { ListState } from './createListSlice';
import type { AppThunk } from './store';
import { objectNameAndIdToDropdownOptions } from '../utils/helpers/dropdown';
import { getCustomerSiteListItems } from '../services/api/customerSite';
import { removeDuplicates } from '../utils/helpers/table';
import { createQueryParameters } from '../pages/crm/CustomerSiteList/customerSiteListHelper';
import { maxPageLength } from '../utils/constants';
import {
  AccessType,
  PERMISSION_URI,
} from '../utils/enums/permission';
import { objectTypeEnum } from '../utils/enums/enum';

type AdditionalState = {
  responsibles: IDropdownOption<number>[];
  involvedEmployees: IDropdownOption<number>[];
  employeesWithPermission: IDropdownOption<number>[];
};

type CustomerSiteListState = ListState<
  ICustomerSiteListItem,
  ICustomerSiteListFilters,
  AdditionalState
>;

const initialState: CustomerSiteListState = {
  listItems: [],
  hasMore: true,
  page: 0,
  pageSize: 0,
  pageCount: 0,
  filters: {
    customer: '',
    targetPriority: [],
    customerSiteState: [],
    sectors: '',
    name: '',
    responsible: [],
    involvedEmployees: [],
    responsibleWithPermission: null,
    ownership: Ownership.RESPONSIBLE,
  },
  showFilters: false,
  sortBy: SortBy.NAME,
  sortType: SortType.ASCENDING,
  additionalState: {
    responsibles: [],
    involvedEmployees: [],
    employeesWithPermission: [],
  },
};

/**
 * CustomerSite List Slice - contains the state of the CustomerSite List page
 *
 * 1. List of customer site
 * 2. Page of items to be fetched and if there is more data to fetch
 * 3. List of customer site responsibles
 * 4. List of customer site involved responsibles
 * 5. List of employees with customer site list permission
 * 6. Applied filters
 * 7. Applied sorting criteria
 */

const customerSiteListSlice = createListSlice({
  name: 'customerSiteList',
  initialState,
  reducers: {
    setResponsibles: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        responsibles: [...payload],
      },
    }),
    setInvolvedEmployees: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        involvedEmployees: [...payload],
      },
    }),
    setEmployeesWithPermission: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        employeesWithPermission: [...payload],
      },
    }),
  },
});

export const {
  setListItems,
  setHasMoreListItems,
  setPage,
  setFilters,
  toggleFilter,
  setSortBy,
  setSortType,
  setResponsibles,
  setInvolvedEmployees,
  setEmployeesWithPermission,
} = customerSiteListSlice.actions;

/**
 * Thunk for fetching a single customer list item and updating customer site list slice
 * @param customerSiteId Id of customer site list item to fetch
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching a single customer list item
 */
export const fetchCustomerSiteListItem =
  (
    customerSiteId: number,
    successHandler: () => void,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        customerSiteList: { listItems },
      } = getState();

      const { data: fetchedListItem } = await getCustomerSiteListItems({
        'id.equals': customerSiteId.toString(),
      });
      dispatch(setListItems(removeDuplicates(listItems, fetchedListItem)));
      successHandler();
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching customer site list items and updating customer list slice
 * @param isSortOrFilterFetchType Whether fetching due to sort / filter / scroll update
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching customer list items
 */
export const fetchCustomerSiteListItems =
  (
    isSortOrFilterFetchType: boolean,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        customerSiteList: { page, filters, sortBy, sortType, listItems },
      } = getState();

      const { data: fetchedListItems } = await getCustomerSiteListItems(
        createQueryParameters(page, filters, sortBy, sortType)
      );

      const processedListItems = isSortOrFilterFetchType
        ? [...fetchedListItems]
        : removeDuplicates(listItems, fetchedListItems);

      dispatch(
        setPage(!(fetchedListItems.length < maxPageLength) ? page + 1 : page)
      );
      dispatch(setHasMoreListItems(!(fetchedListItems.length < maxPageLength)));
      dispatch(setListItems(processedListItems));
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching employees with customer site list permission and updating customer site list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching employees with customer site list permission
 */
export const fetchEmployeesWithCustomerSiteListPermission =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedEmployees } = await getEmployeeNames({
        'permissionsFilter.in': PERMISSION_URI.customerSiteList.readWrite.uri,
        'accessTypeFilter.in': AccessType.READWRITE,
      });

      dispatch(
        setEmployeesWithPermission(
          objectNameAndIdToDropdownOptions(fetchedEmployees)
        )
      );
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching customer site responsibles and updating customer list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching customer responsibles
 */
export const fetchCustomerSiteResponsibles =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedResponsibleNames } = await getListOfResponsibleNames(
        {
          'ownership.in': Ownership.RESPONSIBLE,
          'objectType.equals': objectTypeEnum.customerSite.code,
        }
      );

      dispatch(
        setResponsibles(
          objectNameAndIdToDropdownOptions(fetchedResponsibleNames)
        )
      );
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching customer site involved responsibles and updating customer list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching customer responsibles
 */
export const fetchCustomerSiteInvolvedEmployees =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedInvolvedResponsibleNames } =
        await getListOfResponsibleNames({
          'ownership.in': Ownership.INVOLVED,
          'objectType.equals': objectTypeEnum.customerSite.code,
        });

      dispatch(
        setInvolvedEmployees(
          objectNameAndIdToDropdownOptions(fetchedInvolvedResponsibleNames)
        )
      );
    } catch (error) {
      errorHandler(error);
    }
  };

export default customerSiteListSlice.reducer;
