import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { AxiosResponse } from 'axios';

import axios from '../../services/axios/axios';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import i18n from '../../i18n';
import {
  IDropdownOption,
  IObjectNameAndId,
} from '../../utils/types/commonTypes';
import { ISector } from '../../utils/types/responseTypes';
import { isEmpty } from '../../utils/helpers/array';

interface IProps extends IWithModalsProps {
  onChange: (sectors: IObjectNameAndId[]) => void;
  sectorIds: number[];
  isClearable?: boolean;
}

/**
 * Displays drowdown for a multiples sectors selection
 */
const MultipleSectorSelect: React.FC<IProps> = ({
  modalErrorHandler,
  onChange,
  sectorIds,
  isClearable,
}) => {
  const [dropdownOptions, setDropdownOptions] = useState<
    IDropdownOption<number>[]
  >([]);

  useEffect(() => {
    axios.sales
      .get('sectors')
      .then(({ data }: AxiosResponse<ISector[]>) => {
        const sectors = data.sort((a, b) => (a.sector > b.sector ? 1 : -1));
        setDropdownOptions(
          sectors.map(({ id, sector }) => ({
            value: id,
            label: sector,
          }))
        );
      })
      .catch((error) => {
        modalErrorHandler('Data Generation', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSectorsChange = (selectedSectors: IDropdownOption<number>[]) => {
    if (!isEmpty(selectedSectors)) {
      onChange(
        selectedSectors.map(({ label, value }) => ({
          id: value,
          name: label,
        }))
      );
    } else {
      modalErrorHandler(
        i18n.t('MultipleSectorSelection.atleastOneIndustryIsRequired')
      );
    }
  };

  return (
    <div data-testid="multiple-sector-div">
      <Select
        isMulti
        options={dropdownOptions}
        value={dropdownOptions?.filter(({ value }) =>
          sectorIds?.some((id) => id === value)
        )}
        onChange={handleSectorsChange}
        isClearable={isClearable}
        placeholder={i18n.t('MultipleSectorSelection.selectIndustries')}
      />
    </div>
  );
};

export default withModals(MultipleSectorSelect);
