import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Table,
} from 'reactstrap';

import axios from '../../../../services/axios/axios';
import Header from '../../../../components/layout/Header';
import HeaderTitle from '../../../../components/layout/HeaderTitle';
import { ROLE_ENUM } from '../../../../utils/enums/objectType';
import { generateBreadcrumb } from '../../../../utils/helpers/generateBreadcrumb';
import { handleError } from '../../../../utils/helpers/GenericHelper';
import i18n from '../../../../i18n';
import { RootState } from '../../../../redux/store';
import { IErrorMessage } from '../../../../utils/types/commonTypes';
import {
  IEmployeeGroup,
  IEmployeeGroupWithRelationship,
  IEmployeeWithRelationship,
  IExtendedRole,
  IRole,
  IRoleGroup,
  ISubject2Role,
} from '../../../../utils/types/modelTypes';
import ModalError from '../../../../components/modals/ModalError';
import EmployeeCard from '../EmployeeCard';
import { default as EmployeeGroupCard } from '../EmployeeGroupCard';
import RoleGroupCard from '../RoleGroupCard';
import Permissions from './Permissions';

type IProps = RouteComponentProps<{ id: string; pathname: string }>;

interface IState {
  role: IRole;
  roleGroups: ISubject2Role[];
  showModalError: boolean;
  employeesWithRelations: IEmployeeWithRelationship[];
  employeeGroupsWithRelations: IEmployeeGroupWithRelationship[];
  roleGroupOptions: IRoleGroup[];
  employeeGroupOptions: IEmployeeGroup[];
}

/*
 * Class that displays the details of a Role
 */
class RoleDetail extends React.Component<IProps, IState> {
  modalTitle = '';

  modalBodyText = '';

  error: IErrorMessage = {} as IErrorMessage;

  constructor(props: IProps) {
    super(props);
    this.state = {
      role: {} as IRole,
      roleGroups: [],
      showModalError: false,
      employeesWithRelations: [],
      employeeGroupsWithRelations: [],
      roleGroupOptions: [],
      employeeGroupOptions: [],
    };
  }

  async componentDidMount() {
    // Get the Role Details
    await axios.employee
      .get(`extended-roles?id.equals=${this.props.match.params.id}`)
      .then((response: AxiosResponse<IExtendedRole[]>) => {
        const extendedRoles = response.data;
        this.setState({
          role: extendedRoles[0]?.role ?? ({} as IRole),
          roleGroups: extendedRoles[0]?.roleGroups ?? [],
          employeesWithRelations: extendedRoles[0]?.employeeRelations ?? [],
          employeeGroupsWithRelations:
            extendedRoles[0]?.employeeGroupRelations ?? [],
        });
      })
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveRoleDetails');
        this.handleError(mainError, error);
      });

    // Get all of the role groups for the details and dropdown options
    await axios.employee
      .get('role-groups')
      .then((response: AxiosResponse<IRoleGroup[]>) => {
        this.setState({
          roleGroupOptions: response.data,
        });
      })
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveRoleGroups');
        this.handleError(mainError, error);
      });

    // Get all of the employee groups for the details and dropdown options
    await axios.employee
      .get('employee-groups')
      .then((response: AxiosResponse<IEmployeeGroup[]>) =>
        this.setState({ employeeGroupOptions: response.data })
      )
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveEmployeeGroups');
        this.handleError(mainError, error);
      });
  }

  t(keyName: string) {
    return i18n.t(`RoleDetail.${keyName}`);
  }

  /**
   * Method to handle all errors encountered
   * Shows the modalError dialog
   * @param {*} mainError
   * @param {*} error
   */
  handleError = (mainError: string, errorObject: AxiosError) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  render() {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{this.t('roleDetail')}</HeaderTitle>
          {generateBreadcrumb(this.props.location.pathname, 'Employees')}
        </Header>
        <Container fluid>
          <Card>
            <CardHeader>
              <CardTitle className="mb-0">
                <h1>{this.t('roleDetail')}</h1>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table>
                <tr>
                  <th>{this.t('role')}</th>
                  <td>{this.state.role?.role}</td>
                </tr>
                <tr>
                  <th>{this.t('description')}</th>
                  <td>{this.state.role?.description}</td>
                </tr>
              </Table>
            </CardBody>
          </Card>
        </Container>
        <EmployeeCard
          cardTitle={this.t('employees')}
          buttonName={this.t('addEmployee')}
          employees={this.state.employeesWithRelations}
          displayType={ROLE_ENUM.role.code}
          roleOrGroupId={parseInt(this.props.match.params.id)}
          roleGroups={this.state.roleGroupOptions}
          employeeGroups={this.state.employeeGroupOptions}
        />
        <EmployeeGroupCard
          cardTitle={this.t('employeeGroups')}
          subjectType={ROLE_ENUM.role.code}
          subjectId={parseInt(this.props.match.params.id)}
          employeeGroupsWithRelationship={
            this.state.employeeGroupsWithRelations
          }
          roleGroups={this.state.roleGroupOptions}
        />
        <RoleGroupCard
          cardTitle={this.t('roleGroup')}
          subjectType={ROLE_ENUM.role.code}
          subjectId={parseInt(this.props.match.params.id)}
          roleGroups={this.state.roleGroups}
          roleGroupOptions={this.state.roleGroupOptions}
        />
        <Permissions
          subjectType={ROLE_ENUM.role.code}
          subjectId={this.props.match.params.id}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}

export default connect((store: RootState) => ({
  calling: store.calling,
}))(RoleDetail);
