import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { SortBy, SortType } from '../../../utils/enums/sort';
import { isEmpty } from '../../../utils/helpers/array';
import { projectStateDropdownOptions } from '../../../utils/helpers/dropdown';
import { getNextSortType } from '../../../utils/helpers/table';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import useMounted from '../../../hooks/useMounted';
import {
  fetchProjectListItems,
  fetchProjectResponsibles,
  setFilters,
  setPage,
  setPageSize,
  setSortBy,
  setSortType,
  toggleFilter,
} from '../../../redux/projectListSlice';
import { RootState } from '../../../redux/store';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { IProjectListItem } from '../../../utils/types/responseTypes';
import { createProjectEntry, t } from './projectListHelpers';
import { DEFAULT_LOAD_TIMEOUT } from '../../../utils/constants';
import DynamicTableV2, {
  FilterType,
} from '../../../components/tables/DynamicTableV2';

const pageSizeOptions = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
];

interface IProps extends IWithModalsProps, PropsFromRedux {
  onDelete: (projectId: number) => void;
  onUnbilledProjectUpdate: (projectId: number) => Promise<void>;
}

/**
 * Displays the list of projects in a table
 */
const ProjectListTable = ({
  onDelete,
  onUnbilledProjectUpdate,
  // WithModals
  modalErrorHandler,
  // Redux State
  listItems,
  responsibles,
  filters,
  showFilters,
  page,
  pageSize,
  pageCount,
  sortBy,
  sortType,
  // Redux actions
  setFilters,
  setPage,
  setPageSize,
  setSortBy,
  setSortType,
  toggleFilter,
  // Thunks
  fetchProjectListItems,
  fetchProjectResponsibles,
}: IProps) => {
  // Check for component mount
  const isMounted = useMounted();

  // Prepare the data to be displayed on the table
  const preparedProjectListItems = listItems.map((project: IProjectListItem) =>
    createProjectEntry(
      project,
      () => onUnbilledProjectUpdate(project.id),
      () => onDelete(project.id)
    )
  );

  const tableColumns = [
    {
      name: t('customer'),
      accessor: 'customer',
      disableSort: true,
      filterType: FilterType.INPUT,
      filterValue: filters.customer,
    },
    {
      name: t('customerSite'),
      accessor: 'customerSite',
      disableSort: true,
      filterType: FilterType.INPUT,
      filterValue: filters.customerSite,
    },
    {
      name: t('projectId'),
      accessor: 'projectId',
      filterType: FilterType.INPUT,
      filterValue: filters.projectId,
    },
    {
      name: t('projectTitle'),
      accessor: 'title',
      filterType: FilterType.INPUT,
      filterValue: filters.title,
    },
    {
      name: t('responsible'),
      accessor: 'responsible',
      disableSort: true,
      filterType: FilterType.DROPDOWN,
      filterValue: filters.responsible,
      filterOptions: responsibles ?? [],
    },
    {
      name: t('projectStatus'),
      accessor: 'state',
      filterType: FilterType.DROPDOWN,
      filterValue: filters.state,
      filterOptions: projectStateDropdownOptions,
    },
    {
      name: t('menu'),
      disableSort: true,
      accessor: 'menu',
    },
  ];

  /**
   * Handles the fetching of project list items
   */
  const fetchProjectList = () => {
    fetchProjectListItems((error: AxiosError) => {
      modalErrorHandler(t('failedToRetrieveProjects'), error);
    });
  };

  const handleFilterChange = (
    value: IDropdownOption[] | string,
    accessor: string
  ) => {
    setFilters({
      ...filters,
      [accessor]: value,
    });
  };

  const handleFilterDisplay = () => {
    toggleFilter();
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const handleSortChange = (nextSortBy: SortBy) => {
    const nextSortType = getNextSortType(sortType, nextSortBy, sortBy);

    setSortType(
      nextSortType === SortType.DEFAULT ? SortType.DESCENDING : nextSortType
    );
    setSortBy(
      nextSortType === SortType.DEFAULT ? SortBy.LAST_MODIFIED_ON : nextSortBy
    );
  };

  // Fetch project list and employee list for filter on component mount
  useEffect(() => {
    if (isEmpty(listItems)) {
      fetchProjectList();
    }

    if (isEmpty(responsibles)) {
      fetchProjectResponsibles((error) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });
    }
  }, []);

  // Fetch project list on page update
  useEffect(() => {
    if (isMounted) {
      fetchProjectList();
    }
  }, [page]);

  // Fetch project list on page size update
  useEffect(() => {
    if (isMounted) {
      setPage(0);
      fetchProjectList();
    }
  }, [pageSize]);

  // Fetch project list on sort update
  useEffect(() => {
    if (isMounted) {
      setPage(0);
      fetchProjectList();
    }
  }, [sortType, sortBy]);

  // Fetch project list on filter update
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isMounted) {
      setPage(0);

      timer = setTimeout(() => {
        fetchProjectList();
      }, DEFAULT_LOAD_TIMEOUT);
    }

    return () => clearTimeout(timer);
  }, [filters]);

  return (
    <DynamicTableV2
      data={preparedProjectListItems}
      columns={tableColumns}
      page={page}
      pageSize={pageSize}
      pageCount={pageCount}
      pageSizeOptions={pageSizeOptions}
      sortBy={sortBy}
      sortType={sortType}
      displayFilters={showFilters}
      editableColumns
      setPage={handlePageChange}
      setPageSize={handlePageSizeChange}
      filterFn={handleFilterChange}
      sortFn={handleSortChange}
      setDisplayFilters={handleFilterDisplay}
    />
  );
};

const mapStateToProps = (store: RootState) => ({
  listItems: store.projectList.listItems,
  responsibles: store.projectList.additionalState?.responsibles,
  filters: store.projectList.filters,
  showFilters: store.projectList.showFilters,
  page: store.projectList.page,
  pageSize: store.projectList.pageSize,
  pageCount: store.projectList.pageCount,
  sortBy: store.projectList.sortBy,
  sortType: store.projectList.sortType,
});

const mapDispatchToProps = {
  fetchProjectListItems,
  fetchProjectResponsibles,
  setFilters,
  setPage,
  setPageSize,
  setSortBy,
  setSortType,
  toggleFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(ProjectListTable));
