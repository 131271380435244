import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Card, Container, Button } from 'reactstrap';
import { AxiosError, AxiosResponse } from 'axios';

import { IObjectNameIdDescription } from '../../../utils/types/commonTypes';
import i18n from '../../../i18n';
import { NO_ID } from '../../../utils/constants';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import AddRoleModal from '../../../components/form/AddRoleModal';
import { IRole, ISubject2Role } from '../../../utils/types/modelTypes';
import { getRoles } from '../../../services/api/employee';
import TableCard from '../../../components/cards/TableCard';

// !-- Rename to RoleCard

interface IProps extends IWithModalsProps {
  showRoleAdd: boolean;
  subjectId: number;
  subjectType: string;
  roles: IObjectNameIdDescription[];
}

interface IRoleOptions {
  value: number;
  label: string;
  description: string;
}

/**
 * This class displays the list of role given by the parent
 * TODO: add/delete functionality to add/delete role to a given item (can only add/delete to role groups and employee groups)
 * Do along side things that edit the following: Employee Group Detail and Role Detail
 */
const RoleViewCard: React.FC<IProps> = ({
  showRoleAdd,
  subjectId,
  subjectType,
  roles,
  // WithModal
  modalFormHandler,
  modalErrorHandler,
  toggleModalForm,
}: IProps) => {
  const t = (keyName: string) => i18n.t(`RoleCard.${keyName}`);
  const [roleDisplay, setRoleDisplay] = useState(roles);
  const [roleOptions, setRoleOptions] = useState([] as IRoleOptions[]);

  /**
   * Table Data format when using Roles
   */
  const prepareTableDataRole = roleDisplay.map(
    ({ id, name, description }: IObjectNameIdDescription) => {
      const entry = {
        [t('role')]: (
          <Link
            to={`/settings/permissions/role-details/${id ?? (NO_ID as number)}`}
          >
            {name}
          </Link>
        ),
        [t('description')]: description,
      };
      return entry;
    }
  );

  /**
   * Method that adds saved role to the current list of roles on the frontend
   * @param role The subject2role Role
   */
  const onSaveRole = (role: ISubject2Role) => {
    toggleModalForm();
    const roleToAdd = roleOptions.find(
      (roledata) => roledata.value === role.roleId
    );
    if (roleToAdd === undefined) {
      return;
    }

    setRoleDisplay([
      ...roleDisplay,
      {
        id: roleToAdd.value,
        name: roleToAdd.label,
        description: roleToAdd.description,
      },
    ]);
  };

  /**
   * Method that shows the Add Role Modal
   */
  const addNewRole = () => {
    // Filter out the already existing role from the options
    const roleOptionsFiltered = roleOptions.filter(
      (roleOption) =>
        !roles.some((existingRole) => existingRole.id === roleOption.value)
    );
    modalFormHandler(
      t('addRole'),
      <AddRoleModal
        subjectId={subjectId}
        subjectType={subjectType}
        roleOptions={roleOptionsFiltered}
        onSave={onSaveRole}
        onCancel={toggleModalForm}
      />
    );
  };

  useEffect(() => {
    getRoles()
      .then(({ data }: AxiosResponse<IRole[]>) => {
        setRoleOptions(
          data.map(({ id, role, description }: IRole) => ({
            value: id ?? (NO_ID as number),
            label: role ?? '',
            description: description ?? '',
          }))
        );
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToRetrieveRoles'), error);
      });
  }, []);

  useEffect(() => {
    setRoleDisplay(roles);
  }, [roles]);

  const headerButtons = (
    <Button className="float-end" color="primary" onClick={() => addNewRole()}>
      {t('addRole')}
    </Button>
  );

  return (
    <Container fluid>
      <Card>
        <TableCard
          tableData={prepareTableDataRole}
          noDataPlaceholder={t('roleEmpty')}
          border={false}
          title={t('cardTitle')}
          {...(showRoleAdd && {
            headerButtons,
          })}
          largeTitle
          leftTitle
        />
      </Card>
    </Container>
  );
};

export default withModals(RoleViewCard);
