import React, { Dispatch, SetStateAction } from 'react';

import ProjectDescriptionCard from '../../../components/cards/ProjectDescriptionCard';
import { ProjectState } from '../../../utils/enums/project';
import { IProjectOptionDetails } from '../../../utils/types/responseTypes';
import { t } from './projectOptionHelpers';
import type { ProjectOptionDetailsForm } from './ProjectOptionModal';

interface IProps {
  project: IProjectOptionDetails;
  formValues: ProjectOptionDetailsForm;
  setFormValues: Dispatch<SetStateAction<ProjectOptionDetailsForm>>;
}

/**
 * Description Card in Project Option Modal -
 * Displays project description, and sharepoint and estimation templates links
 */
const ProjectOptionDescription = ({
  project: { projectSharepoint, estimationTemplatesLink },
  formValues: { state, description },
  setFormValues,
}: IProps) => {
  const isDisabled =
    state === ProjectState.LOST || state === ProjectState.ORDERED;

  const fields = [
    ...(state === ProjectState.ESTIMATING || state === ProjectState.ALIGNING
      ? [
          {
            label: t('estimationTemplates'),
            value: estimationTemplatesLink || t('none'),
          },
        ]
      : []),
  ];

  const handleDescriptionChange = (description: string) => {
    setFormValues((formValues) => ({
      ...formValues,
      description,
    }));
  };

  return (
    <ProjectDescriptionCard
      description={description}
      setDescription={handleDescriptionChange}
      projectSharepoint={projectSharepoint}
      fields={fields}
      disabled={isDisabled}
    />
  );
};

export default ProjectOptionDescription;
