import {
  createCustomerAccount,
  deleteCustomerAccountListItem,
  getCustomerAccountListItems,
} from '../services/api/customer';
import { SortBy, SortType } from '../utils/enums/sort';
import { removeDuplicates } from '../utils/helpers/table';
import { createQueryParameters } from '../pages/crm/CustomerAccountList/customerAccountListHelper';
import {
  ICustomerAccountDetail,
  ICustomerAccountListItem,
} from '../utils/types/responseTypes';
import { ICustomerAccountFilter } from '../utils/types/stateTypes';
import createListSlice, { ListState } from './createListSlice';
import type { AppThunk } from './store';
import { maxPageLength } from '../utils/constants';

const initialState: ListState<
  ICustomerAccountListItem,
  ICustomerAccountFilter,
  Record<string, never>
> = {
  listItems: [],
  hasMore: false,
  page: 0,
  pageSize: 0,
  pageCount: 0,
  filters: {
    name: '',
    responsible: '',
  },
  showFilters: false,
  sortBy: SortBy.NAME,
  sortType: SortType.DESCENDING,
};

/**
 * Customer Account List Slice - contains the state of the Customer Account List page
 *
 * 1. List of customer accounts
 * 2. Applied sorting criteria
 */
export const customerAccountListSlice = createListSlice({
  name: 'customerAccountList',
  initialState,
  reducers: {},
});

export const {
  setHasMoreListItems,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  removeListItem,
  setListItems,
  toggleFilter,
} = customerAccountListSlice.actions;

/**
 * Thunk for fetching customer account list items and updating customer account list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching customer account list items
 */
export const fetchCustomerAccountListItems =
  (
    isSortOrFilterFetchType: boolean,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        customerAccountList: {
          sortBy,
          sortType,
          page,
          filters,
          listItems: existingItems,
        },
      } = getState();

      const { data: fetchedCustomerAccountListItems } =
        await getCustomerAccountListItems(
          createQueryParameters(page, filters, sortBy, sortType)
        );

      const processedListItems = isSortOrFilterFetchType
        ? [...fetchedCustomerAccountListItems]
        : removeDuplicates(existingItems, fetchedCustomerAccountListItems);

      dispatch(
        setPage(
          !(fetchedCustomerAccountListItems.length < maxPageLength)
            ? page + 1
            : page
        )
      );
      dispatch(
        setHasMoreListItems(
          !(fetchedCustomerAccountListItems.length < maxPageLength)
        )
      );
      dispatch(setListItems(processedListItems));
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for deleting customer account and updating the customer account list slice
 * @param customerAccountId Id of contact person to delete
 * @param successHandler Function for handling logic to execute upon successful contact person deletion
 * @param errorHandler Function for handling errors from deleting contact person
 * @returns Inner thunk function containing async logic for deleting contact person
 */
export const deleteCustomerAccount =
  (
    customerAccountId: number,
    successHandler: () => void,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      await deleteCustomerAccountListItem(customerAccountId);
      dispatch(removeListItem(customerAccountId));
      successHandler();
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for adding customer account and updating the customer account list slice
 * @param customerAccount details of the customerAccount to be added
 * @param successHandler Function for handling logic to execute upon successful contact person deletion
 * @param errorHandler Function for handling errors from deleting contact person
 */
export const addCustomerAccount =
  (
    customerAccount: ICustomerAccountDetail,
    successHandler: () => void,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        customerAccountList: { listItems: existingItems },
      } = getState();

      const { data: newItem } = await createCustomerAccount(customerAccount);
      dispatch(setListItems(removeDuplicates(existingItems, [newItem])));
      successHandler();
    } catch (error) {
      errorHandler(error);
    }
  };

export default customerAccountListSlice.reducer;
