import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
} from 'reactstrap';

import Auth from '../../../services/axios/Auth';
import { defaultWorkingHoursPerDay, READWRITE } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { ProjectEmployeeRole } from '../../../utils/enums/project';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import { RootState } from '../../../redux/store';
import FadeAlert from '../../../components/layout/FadeAlert';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  IProjectDetail,
  IProjectEmployeeDetail,
  IProjectMemberListItem,
} from '../../../utils/types/responseTypes';
import { composeProjectMembers } from './projectListHelpers';
import { getEmployeeNames } from '../../../services/api/employee';
import { IObjectNameAndId } from '../../../utils/types/commonTypes';
import AssignResourceModal from '../../../components/form/AssignResourceForm/AssignResourceModal';

interface IProps extends PropsFromRedux, IWithModalsProps {
  project: IProjectDetail;
  onChange: (project: IProjectDetail) => void;
}

/**
 * Displays the employees under the project
 */
const ProjectMembersCard = ({
  // IWithModalsProps
  modalErrorHandler,
  toggleModalForm,
  modalDeleteHandler,
  modalFormHandler,
  // Store
  account,
  // Props
  project,
  onChange,
}: IProps) => {
  const [employees, setEmployees] = useState<IObjectNameAndId[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [projectEmployees, setProjectEmployees] = useState<
    IProjectMemberListItem[]
  >(project.projectMembers ?? []);
  const [workload, setWorkload] = useState<number | undefined>(
    defaultWorkingHoursPerDay
  );

  const t = (keyName: string) => i18n.t(`ProjectMembersCard.${keyName}`);

  /**
   * Checks if the current user is a ProjectOwner
   * @returns true or false
   */
  const isUserAProjectOwner = () => {
    const userId = account?.employeeDetails?.id;
    const userEmployee = projectEmployees.find(
      (projectEmployee) => projectEmployee.employee.id === userId
    );
    const currentUserIsProjectOwner =
      userEmployee?.role === ProjectEmployeeRole.OWNER;
    return currentUserIsProjectOwner ?? false;
  };

  // Update the parent component for the changes in data
  const updateWorkloadAndProject = (
    project: IProjectDetail,
    workload?: number | undefined
  ) => {
    setWorkload(workload);
    onChange(project);
  };

  /**
   * Checks that the user has the right permission for the AssignResource button
   * @returns true or false
   */
  const handleAssignResourceButtonAccessibility = () => {
    const assignResourceToAll = Auth.hasPermission(
      [PERMISSION_URI.assignResources.readWrite.uri],
      [READWRITE]
    );

    const assignResourceToOwnedProject = Auth.hasPermission(
      [PERMISSION_URI.assignResourcesToProjects.readWrite.uri],
      [READWRITE]
    );

    return (
      (assignResourceToOwnedProject && isUserAProjectOwner()) ||
      assignResourceToAll
    );
  };

  const handleDeleteProjectEmployee = (
    idToCompare: number,
    roleToCompare: string
  ) => {
    modalDeleteHandler(
      t('deleteProjectMember'),
      t('deleteProjectMemberConfirm'),
      () => {
        const index = projectEmployees?.findIndex(
          ({ employee: { id }, role }) =>
            id === idToCompare && role === roleToCompare
        );
        const updatedProjectEmployees = [...projectEmployees];
        updatedProjectEmployees.splice(index, 1);
        const updatedProject = {
          ...project,
          projectMembers: updatedProjectEmployees,
        };
        setProjectEmployees(updatedProjectEmployees);
        updateWorkloadAndProject(updatedProject, workload);
      }
    );
  };

  const handleSaveProjectEmployee = (
    retunedProjectEmployees: IProjectMemberListItem[]
  ) => {
    if (retunedProjectEmployees.length === 0) {
      return;
    }
    const projectEmployee =
      retunedProjectEmployees[0] ?? ({} as IProjectMemberListItem);
    const updatedProjectEmployees = [...projectEmployees];
    const index = projectEmployees?.findIndex(
      ({ employee: { id }, role }) =>
        id === projectEmployee.employee.id && role === projectEmployee.role
    );
    let successMessage = null;
    if (index > 0) {
      updatedProjectEmployees[index] = projectEmployee;
      successMessage = t('updateMemberSuccessfully');
    } else {
      updatedProjectEmployees.push(projectEmployee);
      successMessage = t('addedMemberSuccessfully');
    }
    const updatedProject = {
      ...project,
      projectMembers: updatedProjectEmployees,
    };
    setSuccessMessage(successMessage);
    setProjectEmployees(updatedProjectEmployees);
    updateWorkloadAndProject(updatedProject, workload);
    toggleModalForm();
  };

  const updateProjectMember = (idToCompare: number, roleToCompare: string) => {
    const projectEmployee = projectEmployees.find(
      ({ employee: { id }, role }) =>
        id === idToCompare && role === roleToCompare
    );

    if (projectEmployee) {
      modalFormHandler(
        t('projectMemberDetails'),
        <AssignResourceModal
          onSave={handleSaveProjectEmployee}
          hideComplexAssigner
          lockDates
          noDatabaseSave
          selectedProjectEmployee={projectEmployee as IProjectEmployeeDetail}
          project={project}
        />,
        'lg'
      );
    }
  };

  const assignResource = () => {
    modalFormHandler(
      t('assignResource'),
      <AssignResourceModal
        onSave={handleSaveProjectEmployee}
        hideComplexAssigner
        lockDates
        noDatabaseSave
        project={project}
      />,
      'lg'
    );
  };

  const fetchEmployees = async () => {
    await getEmployeeNames({})
      .then(({ data }) => {
        if (Array.isArray(data)) {
          setEmployees(data);
        }
      })
      .catch((error) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <Card>
      <CardHeader>
        {successMessage && (
          <FadeAlert color="success">{successMessage}</FadeAlert>
        )}
        <div className="card-actions float-end">
          {handleAssignResourceButtonAccessibility() && (
            <Button color="primary" onClick={() => assignResource()}>
              {generateTitle(BUTTON_TITLE_ENUM.ADD.code, t('assignResource'))}
            </Button>
          )}
        </div>
        <div>
          <CardTitle>
            <h1>{t('resourceAssignment')}</h1>
          </CardTitle>
        </div>
      </CardHeader>
      <CardBody>
        <Table size="m" className="my-2" cellPadding="20px" striped>
          <thead>
            <tr>
              <th>{`${t('employeeName')} `}</th>
              <th>{t('role')}</th>
              <th aria-label="Empty header" />
            </tr>
          </thead>
          <tbody>
            {composeProjectMembers(projectEmployees, employees).map(
              (element, index) => (
                <tr key={`entity-${index}`}>
                  <td>{`${element.employee.name}`}</td>
                  <td>
                    {i18n.t(
                      `ProjectEmployeeRole.${
                        element.role
                          ? element.role.toLocaleLowerCase()
                          : 'noRole'
                      }`
                    )}
                  </td>
                  <td>
                    <div>
                      <Button
                        color="primary"
                        onClick={() =>
                          updateProjectMember(element.employee.id, element.role)
                        }
                        aria-label="edit-button"
                      >
                        {generateTitle(BUTTON_TITLE_ENUM.EDIT.code)}
                      </Button>{' '}
                      <Button
                        color="primary"
                        onClick={() =>
                          handleDeleteProjectEmployee(
                            element.employee.id,
                            element.role
                          )
                        }
                        aria-label="delete-button"
                      >
                        {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (store: RootState) => ({
  account: store.account,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(ProjectMembersCard));
