import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import Select from 'react-select';
import { Button, Container, Form, FormGroup, Label } from 'reactstrap';

import axios from '../../../services/axios/axios';
import { NO_ID, NO_RESTRICTION } from '../../../utils/constants';
import { SUBJECT_TYPE } from '../../../utils/enums/employee';
import { handleError } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import {
  IDropdownOption,
  IErrorMessage,
} from '../../../utils/types/commonTypes';
import { ISubject2Role } from '../../../utils/types/modelTypes';
import ModalError from '../../../components/modals/ModalError';
import { sortOptionsByValue } from '../../../utils/helpers/dropdown';

interface IProps {
  employeeOptions: IDropdownOption<number>[];
  displayType: string;
  roleOrGroupId: number;
  onSave: (id: number) => Promise<void>;
  onCancel: () => void;
}

interface IState {
  employeeOptions: IDropdownOption<number>[];
  selectedEmployee: number | undefined;
  isAddDisabled: boolean;
  showModalError: boolean;
  showModalAdd: boolean;
}

/*
 * A component that contains the modal to add new employee to an item
 */
class AddEmployeeModal extends React.Component<IProps, IState> {
  error: IErrorMessage = {} as IErrorMessage;

  modalTitle = '';

  constructor(props: IProps) {
    super(props);
    this.state = {
      employeeOptions: this.props.employeeOptions,
      selectedEmployee: undefined,
      isAddDisabled: true,
      showModalError: false,
      showModalAdd: false,
    };
  }

  t(keyName: string) {
    return i18n.t(`EmployeeCard.${keyName}`);
  }

  /**
   * Handles the error
   * @param {*} mainError
   * @param {*} errorObject
   */
  handleError = (mainError: string, errorObject: AxiosError) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  toggleModalAdd = () => {
    this.setState({ showModalAdd: !this.state.showModalAdd });
  };

  // Method that handles the saving of an employee depending on the display type
  addEmployee = async () => {
    let savedEmployee = NO_ID as number;
    if (this.props.displayType === SUBJECT_TYPE.employeeGroup.code) {
      await axios.employee
        .post(
          `employee-groups/${this.props.roleOrGroupId}/add-employee/${
            this.state.selectedEmployee ?? (NO_ID as number)
          }`
        )
        .then(() => {
          savedEmployee = this.state.selectedEmployee ?? (NO_ID as number);
        })
        .catch((error: AxiosError) => {
          const mainError = this.t('failedToSaveEmployee');
          this.handleError(mainError, error);
        });
    } else {
      const payload = {
        subjectId: this.state.selectedEmployee,
        subjectType: SUBJECT_TYPE.employee.code,
        roleType: this.props.displayType,
        roleId: this.props.roleOrGroupId,
        restriction: NO_RESTRICTION,
      };
      savedEmployee =
        (await axios.employee
          .save('subject-2-roles', payload)
          .then((res: AxiosResponse<ISubject2Role>) => res.data.subjectId)
          .catch((error: AxiosError) => {
            const mainError = this.t('failedToSaveEmployee');
            this.handleError(mainError, error);
          })) ?? (NO_ID as number);
    }
    if (this.props.onSave) {
      await this.props.onSave(savedEmployee);
    }
  };

  render() {
    return (
      <Container fluid>
        <Form>
          <FormGroup>
            <Label>{this.t('selectEmployee')}</Label>
            <Select
              options={sortOptionsByValue(this.state.employeeOptions)}
              onChange={(event: IDropdownOption<number>) => {
                this.setState({
                  selectedEmployee: event.value,
                  isAddDisabled: false,
                });
              }}
            />
            <br />
            <div className="card-actions float-end">
              <Button
                color="primary"
                size="m"
                onClick={this.addEmployee}
                disabled={this.state.isAddDisabled}
              >
                {this.t('add')}
              </Button>{' '}
              <Button color="primary" size="m" onClick={this.props.onCancel}>
                {this.t('cancel')}
              </Button>
            </div>
          </FormGroup>
        </Form>

        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}

export default AddEmployeeModal;
