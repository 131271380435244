import { faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  ChangeEvent,
  MouseEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Table,
  Label,
} from 'reactstrap';

import { saveCustomerDetails } from '../../../services/api/customer';
import ActivityNotesCard from '../../../components/cards/ActivityNotesCard';
import DetailsCard from '../../../components/cards/DetailsCard';
import CustomerAccountDropdown from '../../../components/dropdowns/CustomerAccountDropdown';
import MultipleResponsibleDropdown from '../../../components/form/MultipleResponsibleDropdown';
import MultipleSectorSelect from '../../../components/dropdowns/MultipleSectorSelect';
import { TargetPriority } from '../../../utils/enums/customer';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import {
  getRemovedObjectNameAndIdsFromList,
  isEmpty,
  isInvolvedResponsiblesNotModified,
} from '../../../utils/helpers/array';
import { paymentGoalOptions } from '../../../utils/enums/enum';
import { canWrite } from '../../../utils/helpers/GenericHelper';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { fetchEmployeesWithCustomerListPermission } from '../../../redux/customerListSlice';
import { RootState } from '../../../redux/store';
import {
  IDropdownOption,
  IDropdownOptionInvolved,
  IObjectContactInformation,
  IObjectInvolvedResponsible,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import { IActivityListItem } from '../../../utils/types/modelTypes';
import FadeAlert from '../../../components/layout/FadeAlert';
import SelectNewResponsibleModal from '../../../components/form/SelectNewResponsibleModal';
import GeneralInfoForm from '../../../components/form/GeneralInfoForm/GeneralInfoForm';
import { CustomerDetailsContext } from './CustomerDetailsContextProvider';
import {
  IEditedCustomerDetails,
  customerFields,
  distributeContext,
  showCustomerAccountLink,
  t,
} from './customerDetailsHelper';

interface IProps extends PropsFromRedux, IWithModalsProps {}

const CustomerCard = ({
  // WithModals
  modalErrorHandler,
  modalFormHandler,
  modalConfirmHandler,
  toggleModalForm,
  // Redux
  account: {
    employeeDetails: { id, firstname, name: lastName },
  },
  employeesWithPermission,
  // Thunks
  fetchEmployeesWithCustomerListPermission,
}: IProps) => {
  const { customerDetails, setCustomerDetails } = useContext(
    CustomerDetailsContext
  );

  // States
  const [editedCustomerDetails, setEditedCustomerDetails] =
    useState<IEditedCustomerDetails>({
      id: 0,
      name: '',
      customerAbbreviation: '',
      targetPriority: TargetPriority.TO_BE_DEFINED,
      state: '',
      customerAccount: null,
      sectors: [],
      paymentGoal: null,
      sharepointLink: null,
      responsible: {
        id: Number(id),
        name: `${firstname} ${lastName}`,
      },
      involvedResponsibles: [],
      contactInformation: [],
      contactPersons: [],
    });

  const [successMessage, setSuccessMessage] = useState('');
  const [hasWritePermission, setHasWritePermission] = useState(false);
  const [fieldForUpdate, setFieldForUpdate] = useState('');
  const [fieldsWithUpdates, setFieldsWithUpdates] = useState<string[]>([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [filteredInvolvedIds, setFilteredInvolvedIds] = useState<number[]>();

  const {
    name: nameField,
    customerAbbreviation: abbreviationField,
    targetPriority: targetPriorityField,
    customerAccount: customerAccountField,
    sectors: sectorsField,
    paymentGoal: paymentGoalField,
    sharepointLink: sharepointLinkField,
    involvedResponsibles: involvedResponsiblesField,
    contactInformation: contactInformationField,
  } = customerFields;
  const {
    name,
    customerAbbreviation,
    targetPriority,
    customerAccount,
    sectors,
    paymentGoal,
    sharepointLink,
    responsible,
    involvedResponsibles,
  } = editedCustomerDetails;
  const {
    customerAbbreviation: savedAbbreviation,
    state: savedState,
    responsible: savedResponsible,
  } = customerDetails;

  const checkPermission = async (
    userId: number | undefined,
    customerId: number | undefined,
    objectType: string
  ) => {
    try {
      setHasWritePermission(
        (await canWrite(userId, customerId, objectType)) as boolean
      );
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveUserAccessType'), error);
    }
  };

  // Functions, variables event handlers

  const toggleUpdateField = (field: string) => {
    if (fieldForUpdate !== field && hasWritePermission) {
      setFieldForUpdate(field);
    }
  };

  // Input changes handlers
  const updateFieldChanges = (fieldValue: string, noChange: boolean) => {
    setFieldsWithUpdates((prevFields) => {
      if (noChange) {
        return prevFields.filter((item) => item !== fieldValue);
      }
      if (prevFields.includes(fieldValue)) {
        return prevFields;
      }
      return [...prevFields, fieldValue];
    });
  };

  const handleResetForm = () => {
    setFieldForUpdate('');
    setFieldsWithUpdates([]);
    setEditedCustomerDetails({ ...distributeContext(customerDetails) });
  };

  const handleNameChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const currentValue = value.trim() || '';
    const isNameUnchanged = customerDetails?.name === currentValue;
    updateFieldChanges(customerFields.name, isNameUnchanged);
    setEditedCustomerDetails({ ...editedCustomerDetails, name: currentValue });
  };

  const handleCustomerAbbreviationChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const regexEngishGermanLetters = /^[a-zA-ZäöüÄÖÜß]+$/;
    const customerAbbreviationValue = value.trim();
    const { customerAbbreviation } = customerDetails;
    if (
      customerAbbreviationValue?.match(regexEngishGermanLetters) ||
      customerAbbreviationValue === ''
    ) {
      let customerAbbreviationToUse = '';
      if (customerAbbreviationValue !== '') {
        customerAbbreviationToUse = customerAbbreviationValue.toUpperCase();
      }
      updateFieldChanges(
        customerFields.customerAbbreviation,
        customerAbbreviation === customerAbbreviationToUse
      );

      setEditedCustomerDetails({
        ...editedCustomerDetails,
        customerAbbreviation: customerAbbreviationToUse,
      });
    }
  };

  const handleTargetPriorityChange = ({ value }: IDropdownOption) => {
    updateFieldChanges(
      customerFields.targetPriority,
      customerDetails?.targetPriority === value
    );
    setEditedCustomerDetails({
      ...editedCustomerDetails,
      targetPriority: value,
    });
  };

  const handleCustomerAccountChange = (
    customerAccount: IObjectNameAndId | null
  ) => {
    updateFieldChanges(customerFields.customerAccount, false);
    setEditedCustomerDetails({ ...editedCustomerDetails, customerAccount });
  };

  const handleMultipleSectorSelectionChange = (sectors: IObjectNameAndId[]) => {
    if (sectors.length > 0) {
      updateFieldChanges(customerFields.sectors, false);
      setEditedCustomerDetails({ ...editedCustomerDetails, sectors });
    }
  };

  const handlePaymentGoalChange = (paymentGoal: number) => {
    updateFieldChanges(
      customerFields.paymentGoal,
      editedCustomerDetails.paymentGoal === paymentGoal
    );
    setEditedCustomerDetails({ ...editedCustomerDetails, paymentGoal });
  };

  const handleSharepointLinkChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const sharepointLink = value.trim();
    updateFieldChanges(
      customerFields.sharepointLink,
      editedCustomerDetails.sharepointLink === sharepointLink
    );
    setEditedCustomerDetails({ ...editedCustomerDetails, sharepointLink });
  };

  const handleSharepointLinkClick = ({
    preventDefault,
  }: MouseEvent<HTMLElement>) => {
    preventDefault();
    window.open(
      editedCustomerDetails?.sharepointLink?.includes('https://')
        ? editedCustomerDetails.sharepointLink
        : `https://${editedCustomerDetails.sharepointLink}`
    );
  };

  const handleResponsibleChange = ({ id }: IObjectNameAndId) => {
    updateFieldChanges(customerFields.responsible, false);
    const newResponsible = employeesWithPermission?.find(
      (r) => r.value === id
    ) as IDropdownOption<number>;

    setEditedCustomerDetails({
      ...editedCustomerDetails,
      responsible: {
        id: newResponsible.value,
        name: newResponsible.label,
      },
    });

    toggleModalForm();
  };

  const handleSetNewResponsible = () => {
    modalFormHandler(
      t('newResponsible'),
      <SelectNewResponsibleModal
        objectName={editedCustomerDetails.name}
        objectType={t('customer')}
        currentResponsible={editedCustomerDetails.responsible}
        employeesWithPermission={employeesWithPermission}
        onSetResponsible={handleResponsibleChange}
        onCancel={toggleModalForm}
      />
    );
  };

  const handleSetResponsible = (involved: IDropdownOptionInvolved[]) => {
    setEditedCustomerDetails({
      ...editedCustomerDetails,
      involvedResponsibles: involved.map(
        ({ responsible, responsibleRole }) =>
          ({
            ...(responsible
              ? {
                  id: responsible?.value,
                  name: responsible?.label,
                }
              : {}),
            ...(responsibleRole
              ? {
                  responsibleRole: {
                    id: responsibleRole?.value,
                    name: responsibleRole?.label,
                  },
                }
              : {}),
          } as IObjectInvolvedResponsible)
      ),
    });
    updateFieldChanges(
      customerFields.involvedResponsibles,
      isInvolvedResponsiblesNotModified(
        involvedResponsibles,
        customerDetails.involvedResponsibles
      )
    );
  };

  const handleUpdateContactInformation = (
    contactInformation: IObjectContactInformation[]
  ) => {
    setEditedCustomerDetails({ ...editedCustomerDetails, contactInformation });
    updateFieldChanges(customerFields.contactInformation, false);
  };

  const saveNewCustomerDetails = async () => {
    const propertiesToSave = {
      id: customerDetails?.id,
    } as IEditedCustomerDetails;

    fieldsWithUpdates.forEach((field) => {
      if (field === customerFields.involvedResponsibles) {
        propertiesToSave.involvedResponsibles =
          editedCustomerDetails.involvedResponsibles.filter(
            (involved) => involved.id
          );
      } else {
        propertiesToSave[field as keyof IEditedCustomerDetails] =
          editedCustomerDetails[field as keyof IEditedCustomerDetails];
      }
    });

    try {
      const { data } = await saveCustomerDetails(propertiesToSave);
      setFieldsWithUpdates([]);
      setSuccessMessage('Updated Customer Successfully');
      setCustomerDetails(data);
    } catch (error) {
      modalErrorHandler(t('failedToUpdateCustomer'), error);
    }
  };

  // Prop function for ActivityNotesCard
  const setActivities = (activities: IActivityListItem[]) => {
    setCustomerDetails({
      ...customerDetails,
      activities,
    });
  };

  const handleSaveDetails = () => {
    setFieldForUpdate('');
    const removedSectors = getRemovedObjectNameAndIdsFromList(
      customerDetails?.sectors,
      editedCustomerDetails.sectors
    );

    // Check if any element on removedSector is found on any customerSitesector

    const matchingSectors = removedSectors.filter((sector) =>
      customerDetails?.customerSites.some((customerSite) =>
        customerSite.sectors.find((siteSector) => sector.id === siteSector.id)
      )
    );

    // Show prompt if there are affected entities for to be deleted sectors

    if (!isEmpty(matchingSectors)) {
      modalConfirmHandler(
        t('warning'),
        <>
          {t('deleteSectorDialog')}:
          <br />
          <strong>
            {matchingSectors.map((sector) => sector.name).join(', ')}
          </strong>
          <br />
          {t('deleteSectorEffect')}
        </>,
        () => {
          saveNewCustomerDetails();
        }
      );
    } else {
      saveNewCustomerDetails();
    }
  };

  const headerButtons = hasWritePermission && (
    <div className="card-actions float-end d-flex align-items-center gap-1">
      <Button
        color="primary"
        onClick={() => handleSaveDetails()}
        disabled={
          isSaveDisabled ||
          !editedCustomerDetails.name ||
          editedCustomerDetails.contactInformation.some(
            (contactInformation) =>
              contactInformation !== null &&
              contactInformation.info?.trim() === ''
          )
        }
        data-testid="save"
      >
        <FontAwesomeIcon icon={faSave} className="margin-right" />
        <span>{t('save')}</span>
      </Button>
      <Button
        color="primary"
        onClick={handleResetForm}
        disabled={isSaveDisabled}
        data-testid="reset"
      >
        <FontAwesomeIcon icon={faUndo} className="margin-right" />
        <span>{t('reset')}</span>
      </Button>
    </div>
  );

  // Data preparation for DetailsCard
  const tableData = [
    {
      label: t('name'),
      value: (
        <div
          onClick={() => toggleUpdateField(nameField)}
          style={{ height: '20%' }}
          data-testid="name"
        >
          {fieldForUpdate === nameField ? (
            <Input
              bsSize="lg"
              type="string"
              onChange={handleNameChange}
              value={name}
              data-testid="name-input"
            />
          ) : (
            name
          )}
        </div>
      ),
      isRequired: true,
    },
    {
      label: t('abbreviation'),
      value: (
        <div
          onClick={() =>
            savedAbbreviation === null || savedAbbreviation === ''
              ? toggleUpdateField(abbreviationField)
              : undefined
          }
          style={{ height: '20%' }}
          data-testid="abbreviation"
        >
          {fieldForUpdate === abbreviationField ? (
            <Input
              style={{ width: '240px' }}
              type="text"
              value={customerAbbreviation}
              onChange={handleCustomerAbbreviationChange}
              maxLength="5"
              data-testid="abbreviation-input"
            />
          ) : (
            <Label>{customerAbbreviation} </Label>
          )}
        </div>
      ),
      isRequired: false,
    },
    {
      label: t('targetPriority'),
      value: (
        <div
          onClick={() => toggleUpdateField(targetPriorityField)}
          style={{ height: '20%' }}
          data-testid="targetpriority"
        >
          {fieldForUpdate === targetPriorityField ? (
            <div data-testid="targetprioritycombobox">
              <Select
                options={Object.values(TargetPriority).map((item) => ({
                  value: item,
                  label: i18n.t(`TargetPriority.${item}`),
                }))}
                value={{
                  label: i18n.t(`TargetPriority.${targetPriority}`),
                }}
                onChange={handleTargetPriorityChange}
              />
            </div>
          ) : (
            i18n.t(`TargetPriority.${targetPriority}`)
          )}
        </div>
      ),
      isRequired: true,
    },
    {
      label: t('state'),
      value: savedState,
      isRequired: false,
    },
    {
      label: t('account'),
      value: (
        <div
          onClick={() => toggleUpdateField(customerAccountField)}
          style={{ cursor: 'text' }}
          data-testid="account"
        >
          {fieldForUpdate === customerAccountField ? (
            <div data-testid="accountcombobox">
              <CustomerAccountDropdown
                customerAccountId={customerAccount?.id as number}
                isClearable
                onChange={handleCustomerAccountChange}
              />
            </div>
          ) : (
            showCustomerAccountLink(customerAccount ?? undefined)
          )}
        </div>
      ),
      isRequired: false,
    },
    {
      label: t('sector'),
      value: (
        <div
          onClick={() => toggleUpdateField(sectorsField)}
          style={{ cursor: 'text' }}
          data-testid="sector"
        >
          {fieldForUpdate === sectorsField && (
            <div data-testid="sectorcombobox">
              <MultipleSectorSelect
                onChange={handleMultipleSectorSelectionChange}
                sectorIds={sectors.map((sector) => sector.id)}
              />
            </div>
          )}
          {fieldForUpdate !== sectorsField &&
            !isEmpty(sectors) &&
            sectors.map(({ name }, index) =>
              index === 0 ? name : `, ${name}`
            )}
        </div>
      ),
      isRequired: true,
    },
    {
      label: t('paymentGoal'),
      value: (
        <div
          onClick={() => toggleUpdateField(paymentGoalField)}
          style={{ cursor: 'text' }}
          data-testid="paymentgoal"
        >
          {fieldForUpdate === paymentGoalField ? (
            <div data-testid="paymentgoalcombobox">
              <Select
                value={paymentGoalOptions.find(
                  ({ value }) => value === paymentGoal
                )}
                options={paymentGoalOptions.filter(
                  ({ value }) => value !== paymentGoal
                )}
                onChange={(paymentGoal: IDropdownOption<number>) =>
                  handlePaymentGoalChange(paymentGoal.value)
                }
              />
            </div>
          ) : (
            <Label>{paymentGoal} </Label>
          )}
        </div>
      ),
      isRequired: false,
    },
    {
      label: 'Link',
      value: (
        <div
          onClick={() => toggleUpdateField(sharepointLinkField)}
          style={{ cursor: 'text' }}
          data-testid="sharepoint"
        >
          {fieldForUpdate === sharepointLinkField ? (
            <Input
              bsSize="lg"
              type="string"
              onChange={handleSharepointLinkChange}
              value={sharepointLink}
              data-testid="sharepointlink-input"
            />
          ) : (
            sharepointLink && (
              <Link
                to={
                  sharepointLink?.includes('https://')
                    ? sharepointLink
                    : `https://${sharepointLink}`
                }
                target="_blank"
                onClick={handleSharepointLinkClick}
              >
                {sharepointLink}
              </Link>
            )
          )}
        </div>
      ),
      isRequired: false,
    },
    {
      label: t('responsible'),
      value: (
        <div style={{ lineHeight: '1.5em' }}>
          <Link
            style={{ paddingRight: '30px' }}
            to={`/employees/employee-list/employee-detail/${savedResponsible?.id}`}
          >
            {savedResponsible?.name}
          </Link>
          <Button
            disabled={!hasWritePermission}
            color="primary"
            onClick={handleSetNewResponsible}
          >
            {t('setNewResponsible')}
          </Button>
        </div>
      ),
      isRequired: false,
    },
    {
      label: t('involved'),
      value:
        fieldForUpdate === involvedResponsiblesField ? (
          <div data-testid="involvedcombobox">
            <MultipleResponsibleDropdown
              responsibles={involvedResponsibles.map(
                ({ id, name, responsibleRole }) =>
                  ({
                    ...(id && name
                      ? {
                          responsible: { label: name, value: id },
                        }
                      : {}),
                    ...(responsibleRole
                      ? {
                          responsibleRole: {
                            label: responsibleRole.name,
                            value: responsibleRole.id,
                          },
                        }
                      : {}),
                  } as {
                    responsible: IDropdownOption<number>;
                    responsibleRole: IDropdownOption<number>;
                  })
              )}
              setResponsibles={handleSetResponsible}
              responsibleOptions={
                employeesWithPermission?.filter(
                  (employee) => !filteredInvolvedIds?.includes(employee.value)
                ) ?? ({} as IDropdownOption<number>[])
              }
            />
          </div>
        ) : (
          <div
            onClick={() => toggleUpdateField(involvedResponsiblesField)}
            style={{ cursor: 'text' }}
            data-testid="involved"
          >
            {!isEmpty(involvedResponsibles)
              ? involvedResponsibles?.map(({ id, name, responsibleRole }) => (
                  <Row key={id} style={{ height: '22px' }}>
                    <Col key={id}>
                      <Link
                        to={`/employees/employee-list/employee-detail/${id}`}
                      >
                        {`${name} ${
                          responsibleRole?.id ? `(${responsibleRole.name})` : ''
                        }`}
                      </Link>
                    </Col>
                  </Row>
                ))
              : t('none')}
          </div>
        ),
      isRequired: false,
    },
    {
      label: t('generalContactInformation'),
      value: (
        <div
          onClick={() => toggleUpdateField(contactInformationField)}
          style={{ cursor: 'text' }}
          data-testid="generalcontactinformation"
        >
          <Label />
        </div>
      ),
      isRequired: false,
    },
  ];

  // UseEffects
  useEffect(() => {
    setFilteredInvolvedIds([
      responsible.id,
      ...involvedResponsibles.map((involved) => involved.id),
    ]);
  }, [responsible, involvedResponsibles]);

  useEffect(() => {
    if (customerDetails?.id) {
      checkPermission(
        Number(id),
        customerDetails?.id,
        OBJECT_TYPE_ENUM.customer.code
      );
      setEditedCustomerDetails({ ...distributeContext(customerDetails) });
    }
  }, [customerDetails]);

  useEffect(() => {
    if (!employeesWithPermission)
      fetchEmployeesWithCustomerListPermission((error) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });
  }, []);

  // Check fieldsWithUpdates for save and reset button
  useEffect(() => {
    if (!isEmpty(fieldsWithUpdates)) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [fieldsWithUpdates]);

  return (
    <Card>
      {successMessage && (
        <FadeAlert color="success" data-testid="successmessage">
          {successMessage}
        </FadeAlert>
      )}
      <Row>
        <Col className="border-end border-light">
          <DetailsCard
            title={customerDetails?.name}
            headerButtons={headerButtons as JSX.Element}
            leftTitle
            largeTitle
            fields={tableData}
          />
          <CardBody>
            <Table>
              <tbody>
                <tr>
                  <td style={{ cursor: 'text', width: '100%' }} colSpan={2}>
                    <div
                      onClick={() =>
                        toggleUpdateField(customerFields.contactInformation)
                      }
                    >
                      {fieldForUpdate === customerFields.contactInformation ? (
                        <div data-testid="generalcontactinformationcombobox">
                          <GeneralInfoForm
                            contactInformations={
                              editedCustomerDetails.contactInformation
                            }
                            onChange={handleUpdateContactInformation}
                          />
                        </div>
                      ) : (
                        !isEmpty(editedCustomerDetails.contactInformation) && (
                          <Table size="sm">
                            <thead>
                              <tr>
                                <th style={{ border: 0 }}>
                                  {i18n.t('GeneralInfosFormInput.info')}
                                </th>
                                <th style={{ border: 0 }}>
                                  {i18n.t('GeneralInfosFormInput.type')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {editedCustomerDetails.contactInformation.map(
                                ({ id, info, type }) => (
                                  <tr key={id}>
                                    <td style={{ border: 0 }}>{info}</td>
                                    <td style={{ border: 0 }}>{type}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        )
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Col>
        <Col className="m-3">
          {customerDetails?.id && (
            <ActivityNotesCard
              objectId={customerDetails?.id}
              objectType={OBJECT_TYPE_ENUM.customer.code}
              activities={customerDetails.activities}
              setActivities={setActivities}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (store: RootState) => ({
  account: store.account,
  employees: store.employeeList.listItems,
  employeesWithPermission:
    store.customerList.additionalState?.employeesWithPermission,
});

const mapDispatchToProps = {
  fetchEmployeesWithCustomerListPermission,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(CustomerCard));
