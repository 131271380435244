import React, { ChangeEvent } from 'react';
import Select from 'react-select';

import { sortByPropValue } from '../../utils/helpers/GenericHelper';
import i18n from '../../i18n';

/**
 * @deprecated This will be removed in the future,
 * @description All reworks related to this must use Select directly to the component
 *
 * Dropdown that has all offers as options.\
 * props:
 *  options = array of label value object
 *  objectId = chosen ID from options. can be null
 *  onChange = method when changes in the dropdown occurs
 *  locked = if dropdown can't be edited(optional)
 *  isClearable = if selected can be cleared
 *  isMulti
 */

export interface IProps {
  objectId: string;
  options: { value: string; label: string }[];
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  hasOrderedOffers?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  locked?: boolean;
  placeholder?: string;
  readOnly?: boolean;
}

class Dropdown extends React.Component<IProps> {
  readOnly: boolean | undefined;

  constructor(props: IProps) {
    super(props);

    const { readOnly } = this.props;

    this.readOnly = readOnly;
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.readOnly !== this.props.readOnly) {
      this.readOnly = this.props.readOnly;
    }
  }

  onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const { onChange } = this.props;

    onChange(event);
  };

  render() {
    const {
      isClearable,
      isMulti,
      options,
      objectId,
      placeholder,
      hasOrderedOffers,
    } = this.props;

    // Obtains the drop down value, if single then get 1, if multi, then get all that include this value.
    const dropdownValue = isMulti
      ? options.filter((option) => objectId.includes(option.value))
      : options.find((option) => option.value === objectId);

    return (
      <Select
        {...this.props}
        value={
          objectId === null || objectId === undefined ? null : dropdownValue
        }
        options={sortByPropValue(options, 'label')}
        isMulti={isMulti ?? false}
        placeholder={placeholder ?? i18n.t('DynamicTable.select')}
        isDisabled={this.readOnly || hasOrderedOffers}
        onChange={this.onChangeHandler}
        isClearable={isClearable}
        styles={{
          menu: (styles: { [key: string]: React.CSSProperties }) => ({
            ...styles,
            zIndex: 100,
          }),
        }}
      />
    );
  }
}

export default Dropdown;
