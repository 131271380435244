import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Row } from 'reactstrap';

import { deleteCustomerSite } from '../../../services/api/customerSite';
import Auth from '../../../services/axios/Auth';
import CollapsibleTableCard from '../../../components/collapsibleCards/CollapsibleTableCard';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { isEmpty } from '../../../utils/helpers/array';
import { READWRITE } from '../../../utils/constants';
import withModals, {
  IWithModalsProps,
} from '../../../utils/withModals';
import i18n from '../../../i18n';
import { updateCustomerListItem } from '../../../redux/customerListSlice';
import { fetchEmployeesWithCustomerSiteListPermission } from '../../../redux/customerSiteListSlice';
import { RootState } from '../../../redux/store';
import { ICustomerSiteDetail } from '../../../utils/types/responseTypes';
import AddOrUpdateCustomerSiteModal from './AddOrUpdateCustomerSiteModal';
import {
  convertCustomerSiteDetailsToCollapsibleListItem,
  translateCustomerSite,
} from './customerDetailsHelper';
import { CustomerDetailsContext } from './CustomerDetailsContextProvider';

interface IProps extends PropsFromRedux, IWithModalsProps {}

const CollapsibleCustomerSiteCard = ({
  modalErrorHandler,
  modalFormHandler,
  modalDeleteHandler,
  toggleModalForm,
  employeesWithPermission,
  fetchEmployeesWithCustomerSiteListPermission,
}: IProps) => {
  const { customerDetails, setCustomerDetails } = useContext(
    CustomerDetailsContext
  );

  const handleDeleteCustomerSite = (customerSiteId: number) => {
    modalDeleteHandler(
      translateCustomerSite('deleteCustomerSite'),
      translateCustomerSite('deleteCustomerSiteConfirm'),
      () => {
        deleteCustomerSite(customerSiteId)
          .then(() => {
            setCustomerDetails({
              ...customerDetails,
              customerSites: customerDetails.customerSites.filter(
                (customerSite) => customerSite.id !== customerSiteId
              ),
            });
          })
          .catch((error) =>
            modalErrorHandler(
              translateCustomerSite('failedToDeleteCustomerSite'),
              error
            )
          );
      }
    );
  };

  // Handles the added/update customerSite from EditableCustomerSiteDetailsCard
  const handleUpdateCustomerSiteDetails = (
    savedCustomerSiteDetails: ICustomerSiteDetail
  ) => {
    const { customerSites } = customerDetails;
    const newListCustomerSites = customerSites.find(
      ({ id }) => id === savedCustomerSiteDetails.id
    )
      ? customerSites.map((customerSite) =>
          customerSite.id === savedCustomerSiteDetails.id
            ? savedCustomerSiteDetails
            : customerSite
        )
      : [
          ...customerSites,
          convertCustomerSiteDetailsToCollapsibleListItem(
            savedCustomerSiteDetails
          ),
        ];

    setCustomerDetails({
      ...customerDetails,
      customerSites: newListCustomerSites,
    });

    toggleModalForm();
  };

  const handleAddOrEditCustomerSite = (customerSiteId: number) => {
    const { id, name, sectors } = customerDetails;
    modalFormHandler(
      <>
        <FontAwesomeIcon icon={faEdit} className="margin-right" />
        <span>{translateCustomerSite('updateCustomerSite')}</span>
      </>,
      <AddOrUpdateCustomerSiteModal
        customerSiteId={customerSiteId}
        existingCustomer={{ id, name }}
        customerSectors={sectors}
        employeesWithPermission={employeesWithPermission}
        onSave={handleUpdateCustomerSiteDetails}
        onCancel={toggleModalForm}
      />,
      'xl'
    );
  };

  const headerButtons = (
    <Button
      color="primary"
      size="m"
      onClick={() => handleAddOrEditCustomerSite(0)}
    >
      <FontAwesomeIcon icon={faPlus} className="margin-right" />
      <span>{translateCustomerSite('add')}</span>
    </Button>
  );

  const tableData = customerDetails.customerSites.map(
    ({
      id,
      name,
      customerSiteType,
      customerSiteState,
      targetPriority,
      sectors,
      contactPersons,
      responsible,
    }) => ({
      [translateCustomerSite('siteName')]: (
        <Link
          to={`/customer/customers/details/${customerDetails.id}/customer-site-details/${id}`}
        >
          {name}
        </Link>
      ),
      [translateCustomerSite('siteType')]: customerSiteType,
      [translateCustomerSite('targetPriority')]:
        targetPriority && i18n.exists(`TargetPriority.${targetPriority}`)
          ? i18n.t(`TargetPriority.${targetPriority}`)
          : targetPriority,
      [translateCustomerSite('state')]:
        customerSiteState && i18n.exists(`CustomerStates.${customerSiteState}`)
          ? i18n.t(`CustomerStates.${customerSiteState}`)
          : customerSiteState,
      [translateCustomerSite('sector')]: (
        <div>
          {sectors.map(({ id, name }) => (
            <span key={id}>{name}</span>
          ))}
        </div>
      ),
      [translateCustomerSite('contactPerson')]: (
        <div>
          {contactPersons.map(({ id, name }) => (
            <Row key={id}>
              <Link
                to={`/customer/customers/details/${customerDetails.id}/contact-person-profile/${id}`}
              >
                {`${name}`}
              </Link>
            </Row>
          ))}
        </div>
      ),
      [translateCustomerSite('responsibles')]: responsible ? (
        <Link to={`/employees/employee-list/employee-detail/${responsible.id}`}>
          {`${responsible.name}`}
        </Link>
      ) : (
        ''
      ),
      [translateCustomerSite('menu')]: Auth.hasPermission(
        [PERMISSION_URI.customerSite.readWrite.uri],
        [READWRITE],
        id
      ) ? (
        <div className="d-flex align-items-center gap-1">
          <Button
            color="primary"
            onClick={() => handleAddOrEditCustomerSite(id)}
          >
            <FontAwesomeIcon icon={faEdit} className="margin-right" />
          </Button>
          <Button color="primary" onClick={() => handleDeleteCustomerSite(id)}>
            <FontAwesomeIcon icon={faTrash} className="margin-right" />
          </Button>
        </div>
      ) : (
        ''
      ),
    })
  );

  useEffect(() => {
    if (isEmpty(employeesWithPermission)) {
      fetchEmployeesWithCustomerSiteListPermission((error) =>
        modalErrorHandler(
          translateCustomerSite('failedToRetrieveEmployees'),
          error
        )
      );
    }
  }, []);

  return (
    <CollapsibleTableCard
      title={translateCustomerSite('customerSites')}
      tableData={tableData}
      noDataPlaceholder={translateCustomerSite('noCustomerSites')}
      {...(Auth.hasPermission(
        [PERMISSION_URI.customerSiteList.readWrite.uri],
        [READWRITE]
      ) && {
        headerButtons,
      })}
      striped
    />
  );
};

const mapStateToProps = (store: RootState) => ({
  employeesWithPermission:
    store.customerSiteList.additionalState?.employeesWithPermission,
});

const mapDispatchToProps = {
  updateCustomerListItem,
  fetchEmployeesWithCustomerSiteListPermission,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(CollapsibleCustomerSiteCard));
