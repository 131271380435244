import React, { Dispatch, SetStateAction } from 'react';

import { IProjectDetail } from '../../../utils/types/responseTypes';
import type { ProjectDetailsForm } from './CreateNewProjectModal';
import { ProjectState } from '../../../utils/enums/project';
import ProjectDescriptionCard from '../../../components/cards/ProjectDescriptionCard';

interface IProps {
  project: IProjectDetail;
  formValues: ProjectDetailsForm;
  setFormValues: Dispatch<SetStateAction<ProjectDetailsForm>>;
}

/**
 * Description Card in Unbilled Customer Project Modal -
 * Displays project description, and sharepoint links
 */
const ProjectDescription = ({
  project: { projectSharePoint },
  formValues: { state, description },
  setFormValues,
}: IProps) => {
  const isDisabled = state === ProjectState.ORDERED;

  const handleDescriptionChange = (description: string) => {
    setFormValues((formValues) => ({
      ...formValues,
      description,
    }));
  };

  return (
    <ProjectDescriptionCard
      description={description}
      setDescription={handleDescriptionChange}
      projectSharepoint={projectSharePoint}
      fields={[]}
      disabled={isDisabled}
    />
  );
};

export default ProjectDescription;
