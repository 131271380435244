import React from 'react';

import ContactPersonDetailsProvider from './ContactPersonDetailsProvider';
import ContactPersonDetails from './ContactPersonDetails';

/*
 * Base Component to apply provider for contact details component
 */

const ContactPersonProfile = () => (
  <ContactPersonDetailsProvider>
    <ContactPersonDetails />
  </ContactPersonDetailsProvider>
);

export default ContactPersonProfile;
