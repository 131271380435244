import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import i18n from '../../../i18n';
import EmployeeGroup from './EmployeeGroup';
import RoleGroups from './RoleGroups';
import Roles from './Roles';

/**
 * This class is for creating the Permission Settings Page
 */
type IProps = RouteComponentProps<{ pathname: string }>;

export default class PermissionSettingsView extends React.Component<IProps> {
  t(keyName: string) {
    return i18n.t('PermissionSettingsView.' + keyName);
  }

  render() {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{this.t('permissionSettings')}</HeaderTitle>
          {generateBreadcrumb(this.props.location.pathname, this.t('settings'))}
        </Header>
        <Card>
          <CardHeader>
            <CardTitle>
              <h1>{this.t('permissionSettings')}</h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Button
                className="gap-1"
                color="outline"
                id="employeeGroupToggle"
              >
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('employeeGroups')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#employeeGroupToggle">
              <EmployeeGroup />
            </UncontrolledCollapse>
            <Row>
              <Button className="gap-1" color="outline" id="roleGroupToggle">
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('roleGroups')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#roleGroupToggle">
              <RoleGroups />
            </UncontrolledCollapse>
            <Row>
              <Button className="gap-1" color="outline" id="roleToggle">
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('roles')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#roleToggle">
              <Roles />
            </UncontrolledCollapse>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
