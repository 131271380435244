import React, { useEffect, useState } from 'react';
import { Container, Button } from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import { getAllSkills } from '../../../services/api/skill';
import {
  IDropdownOption,
  IDropdownOptionSkillLevel,
} from '../../../utils/types/commonTypes';
import { objectNameAndIdToDropdownOptions } from '../../../utils/helpers/dropdown';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { generateLevelOptions, t } from './resourceOverviewHelper';

interface IProps extends IWithModalsProps {
  skillAndLevelFilter: IDropdownOptionSkillLevel[];
  setSkillFilter: (skillAndLevel: IDropdownOptionSkillLevel[]) => void;
}

const MultipleSkillFilter = ({
  skillAndLevelFilter,
  setSkillFilter,
  modalErrorHandler,
}: IProps) => {
  const [skillOptions, setSkillOptions] = useState<IDropdownOption<number>[]>(
    []
  );

  const fetchSkills = async () => {
    try {
      const { data: skills } = await getAllSkills({});
      setSkillOptions(objectNameAndIdToDropdownOptions(skills));
    } catch (error) {
      modalErrorHandler(t('failedToGetSkills'), error);
    }
  };

  const handleSkillChange = (skill: IDropdownOption<number>, idx: number) => {
    const modifiedSkillAndLevel = [...skillAndLevelFilter];
    modifiedSkillAndLevel.splice(idx, 1, {
      skill,
      level: skillAndLevelFilter[idx]?.level as IDropdownOption<number>,
    });
    setSkillFilter(modifiedSkillAndLevel);
  };

  const handleLevelChange = (level: IDropdownOption<number>, idx: number) => {
    const modifiedSkillAndLevel = [...skillAndLevelFilter];
    modifiedSkillAndLevel.splice(idx, 1, {
      skill: skillAndLevelFilter[idx]?.skill as IDropdownOption<number>,
      level,
    });
    setSkillFilter(modifiedSkillAndLevel);
  };

  const handleRemoveSkillAndLevelFilter = (idx: number) => {
    const modifiedSkillAndLevel = [...skillAndLevelFilter];
    modifiedSkillAndLevel.splice(idx, 1);
    setSkillFilter(modifiedSkillAndLevel);
  };

  const handleAddSkillAndLevelFilter = () => {
    setSkillFilter([
      ...skillAndLevelFilter,
      {} as { skill: IDropdownOption<number>; level: IDropdownOption<number> },
    ]);
  };

  const skillAndLevelFields = skillAndLevelFilter?.map((_, idx) => (
    <div
      key={skillAndLevelFilter[idx]?.skill?.label || idx}
      className="d-flex mb-1 gap-1"
    >
      <Select
        aria-label="multiple-skill-filter-skill-dropdown"
        className="flex-grow-1"
        value={skillAndLevelFilter[idx]?.skill}
        options={skillOptions}
        onChange={(value: IDropdownOption<number>) =>
          handleSkillChange(value, idx)
        }
      />
      <Select
        aria-label="multiple-skill-filter-level-dropdown"
        className="flex-grow-1"
        value={skillAndLevelFilter[idx]?.level}
        options={generateLevelOptions()}
        onChange={(value: IDropdownOption<number>) =>
          handleLevelChange(value, idx)
        }
      />
      <Button
        aria-label="multiple-skill-filter-delete-button"
        color="link"
        onClick={() => handleRemoveSkillAndLevelFilter(idx)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </div>
  ));

  useEffect(() => {
    fetchSkills();
  }, []);

  return (
    <Container fluid>
      {skillAndLevelFields}
      <Button
        aria-label="multiple-skill-filter-add-button"
        color="primary"
        onClick={() => handleAddSkillAndLevelFilter()}
      >
        <FontAwesomeIcon icon={faPlus} />
        <span className="ml-2">{t('add')}</span>
      </Button>
    </Container>
  );
};

export default withModals(MultipleSkillFilter);
