import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import axios from '../axios/Axios-Interceptors';
import { urlBuilder } from '../../utils/helpers/url';
import type { ProjectDetailFormValues } from '../../pages/projects/ProjectDetails/ProjectDetailOverview';
import {
  IApprovalRequest,
  IDepartment,
  IExtendedUserToDoActivity,
  IProject,
  IProjectEmployee,
  IProjectEmployeeRate,
  IUserToDoActivity,
  IWorkloadBreakdownReturn,
} from '../../utils/types/modelTypes';
import {
  IComplexProjectMemberObject,
  IProjectDetail,
  IProjectEmployeeDetail,
  IProjectListItem,
  IProjectMemberListItem,
  IProjectMemberObject,
  IProjectOptionDetails,
  IProjectOptionListItem,
  ResponseWithCount,
} from '../../utils/types/responseTypes';
import { Url } from './constants';
import { IObjectNameAndId } from '../../utils/types/commonTypes';

export const getDepartments = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<IDepartment[]>>(
    axios.get(urlBuilder(Url.DEPARTMENTS, queryParameters))
  );

export const getProjectListItems = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<ResponseWithCount<IProjectListItem[]>>>(
    axios.get(urlBuilder(Url.PROJECT_LIST, queryParameters))
  );

export const getProjectOptionListItems = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IProjectOptionListItem[]>>(
    axios.get(urlBuilder(Url.PROJECT_OPTION_LIST, queryParameters))
  );

export const deleteProject = (projectId: number) =>
  trackPromise<AxiosResponse<void>>(
    axios.delete(urlBuilder(`${Url.PROJECTS}/${projectId}`, {}))
  );

export const getProjects = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<IProject[]>>(
    axios.get(urlBuilder(Url.PROJECTS, queryParameters))
  );

export const getProjectById = (projectId: number) =>
  trackPromise<AxiosResponse<IProject>>(
    axios.get(urlBuilder(`${Url.PROJECTS}/${projectId}`, {}))
  );

export const getProjectApprovalStatus = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IApprovalRequest[]>>(
    axios.get(urlBuilder(Url.PROJECT_APPROVAL_STATUS, queryParameters))
  );

export const getProjectCount = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<number>>(
    axios.get(urlBuilder(Url.PROJECT_COUNT, queryParameters))
  );

export const getProjectDetails = (projectId: number) =>
  trackPromise<AxiosResponse<IProjectDetail>>(
    axios.get(urlBuilder(`${Url.PROJECT_DETAILS}/${projectId}`, {}))
  );

export const getProjectOptionDetails = (projectId: number) =>
  trackPromise<AxiosResponse<IProjectOptionDetails>>(
    axios.get(urlBuilder(`${Url.PROJECT_OPTION_DETAILS}/${projectId}`, {}))
  );

export const postProjectOptionDetails = (
  projectOption: IProjectOptionDetails
) =>
  trackPromise<AxiosResponse<IProjectOptionDetails>>(
    axios.post(urlBuilder(Url.PROJECT_OPTION_DETAILS, {}), projectOption)
  );

export const putProjectOptionDetails = (projectOption: IProjectOptionDetails) =>
  trackPromise<AxiosResponse<IProjectOptionDetails>>(
    axios.put(urlBuilder(Url.PROJECT_OPTION_DETAILS, {}), projectOption)
  );

export const updateProjectFromDetail = (
  projectDetail: ProjectDetailFormValues
) =>
  trackPromise<AxiosResponse<IProjectDetail>>(
    axios.put(urlBuilder(Url.UPDATE_PROJECT, {}), projectDetail)
  );

/**
 * Saved the project employee complete with a workflow and a rate (if included)
 * @param projectEmployeeDetail
 */
export const saveProjectEmployeeDetailInitial = (
  projectEmployeeDetail: IProjectEmployeeDetail
) =>
  trackPromise<AxiosResponse<IProjectMemberListItem>>(
    axios.post(
      urlBuilder(Url.PROJECT_EMPLOYEES_COMPLETE, {}),
      projectEmployeeDetail
    )
  );

/**
 * Update the project employee complete with a workflow and a rate (if included)
 * @param projectEmployeeDetail
 */
export const saveProjectEmployeeDetailUpdate = (
  projectEmployeeDetail: IProjectEmployeeDetail
) =>
  trackPromise<AxiosResponse<IProjectMemberListItem>>(
    axios.put(
      urlBuilder(Url.PROJECT_EMPLOYEES_COMPLETE, {}),
      projectEmployeeDetail
    )
  );

/**
 * Update the project employee complete with a workflow and a rate (if included)
 * @param projectEmployeeDetail
 */
export const saveProjectEmployeeComplex = (
  projectEmployeeDetail: IComplexProjectMemberObject[]
) =>
  trackPromise<AxiosResponse<IProjectMemberObject[]>>(
    axios.post(
      urlBuilder(Url.PROJECT_EMPLOYEES_COMPLEX, {}),
      projectEmployeeDetail
    )
  );

export const isProjectIdValid = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<boolean>>(
    axios.get(urlBuilder(Url.IS_PROJECT_ID_VALID, queryParameters))
  );

export const getCurrentUserActivities = () =>
  trackPromise<AxiosResponse<IExtendedUserToDoActivity[]>>(
    axios.get(urlBuilder(Url.CURRENT_USER_ACTIVITIES, {}))
  );

export const saveUserToDoActivities = (userToDoActivity: IUserToDoActivity) =>
  trackPromise<AxiosResponse<IUserToDoActivity>>(
    axios.post(urlBuilder(Url.USER_TO_DO_ACTIVITIES, {}), userToDoActivity)
  );

export const updateUserToDoActivities = (
  activityId: number,
  userToDoActivity: IUserToDoActivity
) =>
  trackPromise<AxiosResponse<IUserToDoActivity>>(
    axios.put(
      urlBuilder(`${Url.USER_TO_DO_ACTIVITIES}/${activityId}`, {}),
      userToDoActivity
    )
  );

export const deleteProjectEmployee = (projectEmployeeId: number) =>
  trackPromise<AxiosResponse<IProjectEmployee>>(
    axios.delete(
      urlBuilder(`${Url.PROJECT_EMPLOYEES}/${projectEmployeeId}`, {})
    )
  );

export const deleteProjectEmployeeRate = (projectEmployeeRateId: number) =>
  trackPromise<AxiosResponse<void>>(
    axios.delete(
      urlBuilder(`${Url.PROJECT_EMPLOYEES_RATE}/${projectEmployeeRateId}`, {})
    )
  );

export const getProjectEmployee = (projectEmployeeId: number) =>
  trackPromise<AxiosResponse<IProjectEmployee>>(
    axios.get(urlBuilder(`${Url.PROJECT_EMPLOYEES}/${projectEmployeeId}`, {}))
  );

export const updateProjectEmployee = (projectEmployee: IProjectEmployee) =>
  trackPromise<AxiosResponse<IProjectEmployee>>(
    axios.put(urlBuilder(Url.PROJECT_EMPLOYEES, {}), projectEmployee)
  );

export const createProjectEmployee = (projectEmployee: IProjectEmployee) =>
  trackPromise<AxiosResponse<IProjectEmployee>>(
    axios.post(urlBuilder(Url.PROJECT_EMPLOYEES, {}), projectEmployee)
  );

export const getProjectEmployees = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IProjectEmployee[]>>(
    axios.get(urlBuilder(`${Url.PROJECT_EMPLOYEES}`, queryParameters))
  );

export const saveProjectEmployeeRateCall = (
  projectEmployee: IProjectEmployeeRate
) => {
  if (projectEmployee.id) {
    return trackPromise<AxiosResponse<IProjectEmployee>>(
      axios.put(urlBuilder(Url.PROJECT_EMPLOYEES_RATE, {}), projectEmployee)
    );
  }
  return trackPromise<AxiosResponse<IProjectEmployee>>(
    axios.post(urlBuilder(Url.PROJECT_EMPLOYEES_RATE, {}), projectEmployee)
  );
};

export const addProjectFromDetail = (projectDetail: IProjectDetail) =>
  trackPromise<AxiosResponse<IProjectDetail>>(
    axios.post(urlBuilder(Url.ADD_PROJECT, {}), projectDetail)
  );

export const getProjectDropdown = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IObjectNameAndId[]>>(
    axios.get(urlBuilder(Url.PROJECT_DROPDOWN, queryParameters))
  );

export const getProjectEmployeeWorkloads = (
  projectDetail: IProjectEmployeeDetail
) =>
  trackPromise<AxiosResponse<IWorkloadBreakdownReturn>>(
    axios.post(
      urlBuilder(`${Url.PROJECT_WORKLOAD_BREAKDOWN}`, {}),
      projectDetail
    )
  );

export const getProjectEmployeeDetail = (id: number) =>
  trackPromise<AxiosResponse<IProjectEmployeeDetail>>(
    axios.get(urlBuilder(`${Url.PROJECT_EMPLOYEES_DETAIL}/${id}`, {}))
  );
export const postProjectDetails = (project: IProjectDetail) =>
  trackPromise<AxiosResponse<IProjectDetail>>(
    axios.post(urlBuilder(Url.ADD_PROJECT, {}), project)
  );

export const generateProjectId = (projectId: number) =>
  trackPromise<AxiosResponse<string>>(
    axios.get(urlBuilder(`${Url.GENERATE_PROJECT_ID}/${projectId}`, {}))
  );
