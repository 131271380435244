// File that contains constants

import { absenceStateEnum } from './enums/calendar';

export const workingHours = 40;

export const monthDateYearFormat = 'MM/DD/YYYY';

export const dateFormat = 'YYYY-MM-DD';

export const monthYearTextFormat = 'MMMM YYYY';

export const monthTextFormat = 'MMM';

export const dayFormat = 'dd';

export const dateFormatDotSeparator = 'YYYY.MM.DD';

export const timeFormat = 'HH:mm';

export const yearFormat = 'YYYY';

export const monthYearNumberFormat = 'MM-YYYY';

export const timeOut = 1000;

export const monthly = 'Monthly';

export const yearly = 'Yearly';

export const weekly = 'Weekly';

export const daily = 'Daily';

export const instantTimeFormat = 'YYYY-MM-DDThh:mm:ss.SSSZ';

export const dateTimeLocal = 'YYYY-MM-DDThh:mm:ss.SSS';

export const dataGeneration = 'Data Generation';

export const local = 'local';

export const deletion = 'Deletion';

export const addRecord = 'Add Record';

export const updateRecord = 'Update Record';

export const regexExpressionNumber = /^\d+$/;

export const regexExpressionDouble = /^\d{0,5}(?:\.\d{0,2}){0,1}$/;

export const REGEX_VALID_CONTACT_NUMBER_FORMAT = /^\+?[\d ]*$/gm;

export const REGEX_ENGLISH_GERMAN_LETTERS = /^[a-zA-ZäöüÄÖÜß]+$/;

export const REGEX_VALID_CUSTOMER_ABBREVIATION = /^[A-ZÄÖÜß]{5}$/;

export const dateTimeHourMinuteFormat = 'YYYY-MM-DDThh:mm';

export const workloadColorHigh = 'lightgreen';

export const workloadColorMedium = 'yellow';

export const workloadColorLow = 'orange';

export const workloadColorDanger = 'pink';

// String used to identify the service assigned to a a role
export const ALL_SERVICE = 'All';

export const CRM_SERVICE = 'Customer';

export const SALES_FUNNEL_SERVICE = 'Sales Funnel';

export const PROJECT_SERVICE = 'Project';

export const CUSTOMER_SERVICE = 'Customer';

export const NOTIFICATION_STATUS_NEW = 'NEW';

export const userToDoActivities = 'userToDoActivities';

// List that contains values that used to set number of records per page for data table
export const DEFAULT_SMALL_PAGE_RANGE = 3;
export const DEFAULT_MEDIUM_PAGE_RANGE = 10;
export const RECORDS_PER_PAGE = [
  {
    value: 3,
    label: 3,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
  {
    value: 200,
    label: 200,
  },
];

// Constant represents boolean true/false into string "Yes"/"No"
export const boolTrue = 'Yes';
export const boolFalse = 'No';
export const workloadHigh = 100;
export const workloadMedium = 80;
export const workloadLow = 50;
export const workloadDanger = 20;

export const offerStateOptions = [
  { value: 'DRAFT', label: 'DRAFT' },
  { value: 'ACCEPTED', label: 'ACCEPTED' },
  { value: 'DECLINED', label: 'DECLINED' },
  { value: 'CLOSED', label: 'CLOSED' },
  { value: 'SUBMITTED', label: 'SUBMITTED' },
  { value: 'EXPIRED', label: 'EXPIRED' },
];

export const add = 'Add';
export const update = 'Update';
export const DELETE = 'Delete';
export const SAVE = 'Save';
export const SAVE_AND_GOTO_DETAILS = 'Save_and_goto_details';
export const REMOVE = 'Remove';

export const fileAttachment = 'File Attachment';
export const uploadAttachment = 'Upload';

export const activity = 'Activity';

export const projectHistory = 'Project History';

export const employeeHistory = 'Employee History';

export const defaultWorkingHoursPerDay = 8;

export const defaultWorkingDaysPerWeek = 5;

export const maxPageLength = 20;

// Argument for the parseInt function, to convert string to base 10
export const radixOfBaseTen = 10;

export const styleWidth30Percent = '30%';

export const taxRates = [
  { value: 0.0, label: '0 %' },
  { value: 0.05, label: '5 %' },
  { value: 0.07, label: '7 %' },
  { value: 0.16, label: '16 %' },
  { value: 0.19, label: '19 %' },
];

// Constant for comparing string for role "Admin" , "User"
export const adminRole = 'Admin';
export const userRole = 'User';

export const SALES_MANAGER_ROLE = 'Sales Manager';

export const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const fileSizeLimit = 500000000;

export const READ = 'READ';
export const READWRITE = 'READWRITE';

export const NO_RESTRICTION = -1;

export const NOT_REACHED = 'Not Reached';

export const REACHED_AND_DISQUALIFIED = 'Reached & Disqualified';

export const REACHED_AND_INTERESTED = 'Reached & Interested';

export const REACHED_AND_SEND_EMAIL = 'Reached & Send Email';

export const REACHED_AND_APPOINTMENT = 'Reached & Appointment';

export const DISQUALIFIED = 'Disqualified';

export const MAIL_SENT = 'Mail Sent';

export const MET_PERSONALLY = 'Met Personally';

export const SOCIAL_MEDIA_CONTACT = 'Social Media Contact';

export const PROSPECTION_DONE = 'Prospection done';

export const PROSPECTION_CANCELLED = 'Prospection Cancelled';

export const PROSPECTION_POSTPONED = 'Prospection Postponed';

export const REACHED = 'REACHED';
export const ProjectOption = 'ProjectOption';
export const Project = 'Project';
export const Offer = 'Offer';
export const Order = 'Order';

// Language string as JSON key
export const LANGUAGE_AS_KEY = 'language';
export const COUNTRY_AS_KEY = 'country';

export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_COUNTRY = 'US';

export const GERMANY_COUNTRY = 'DE';

export const Contact = 'Contact';
export const Account = 'Account';
export const CustomerSite = 'Customer Site';

export const Inactive = 'Inactive';
export const Acquisition = 'In Acquisition';
export const Former = 'Former';
export const None = 'None';
export const maxInFilterSize = 300;

export const Ascending = 'asc';
export const Descending = 'desc';

export const ProjectOptionRowHighlight = '#ffae7f';
export const OfferGreyOutRowHighlight = '#A9A9A9';

export const DEFAULT_LOAD_TIMEOUT = 1500;

export const maxEmailCount = 3;
export const maxPhoneCount = 2;
export const maxMobileCount = 1;
export const maxProfileCount = 3;

export const PermissionAdmin = 'ADMIN';
export const PERMISSION_CAMPAIGN_MANAGEMENT = 'CAMPAIGN MANAGEMENT';
export const PermissionTimeTracking = 'TIMETRACKING';
export const PermissionCrm = 'CRM';
export const PermissionSales = 'SALES';
export const PermissionProject = 'PROJECT';
export const PermissionFinance = 'FINANCE';
export const PermissionEmployees = 'EMPLOYEES';
export const PermissionAbsences = 'ABSENCES';
export const PermissionData = 'DATA';
export const PERMISSION_RESOURCE_PLANNER = 'RESOURCE PLANNER';
export const PERMISSION_RECRUITING = 'RECRUITING';

export const defaultValidityDate = 14;

export const defaultTableLineHeight = { lineHeight: '0.8em' };

export const notApplicable = 'N/A';

export const notApplicableAbbreviation = 'n.a.';

// Used if a value is (!isFinite)
export const NOT_DISPLAYABLE = 'n.d.';

export const blankSpaceExpression = /\s/g;

export const preferredLanguages = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'German' },
  { value: 'es', label: 'Spanish' },
];

export const defaultCurrency = 'EUR';

// For the selection of approval view. (listView, historyView)
export const APPROVAL_LIST_VIEW = 'approvalListView';
export const APPROVAL_HISTORY_VIEW = 'approvalHistoryView';

// Relationship Types
export const CONNECTION_DIRECT = 'DIRECT';
export const CONNECTION_VIA_EMPLOYEE_GROUP = 'EMPLOYEEGROUP';
export const CONNECTION_VIA_ROLE_GROUP = 'ROLEGROUP';
export const CONNECTION_VIA_EMPLOYEEGROUP_FROM_ROLEGROUP =
  'EMPLOYEEGROUP/ROLEGROUP';

export const DEFAULT_RETRIEVE_COUNT_PER_PAGE = 20;

// Default absences states used when retrieving current user absences
export const DEFAULT_CURRENT_USER_ABSENCE_STATES = [
  absenceStateEnum.Accepted.code,
  absenceStateEnum.Planned.code,
  absenceStateEnum.Retreated.code,
  absenceStateEnum.Submitted.code,
];

// Sharepoint Created
export const SHAREPOINT_CREATED = 'Sharepoint Created';

// ReactQuil related
export const REACTQUILL_MODULES = {
  toolbar: [
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    [{ color: [] }, { background: [] }],
    ['clean'],
  ],
};

export const REACTQUILL_FORMATS = [
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'indent',
  'color',
  'background',
];

export const HTML_REGEX = /<(.|\n)*?>/g;

export const UPDATE_RESPONSIBLE_NOTIFICATION = 'updatedResponsible';

export const UPDATE_CUSTOMER_RESPONSIBLE_NOTIFICATION =
  'updatedCustomerResponsible';

export const PROJECT_NEXT_ACTIVITY_NOTIFICATION =
  'projectNextActivityNotificationMessage';

export const APPROVAL_REQUEST_NOTIFICATION = 'approvalRequest';
export const APPROVAL_REQUEST_APPROVED_NOTIFICATION = 'approvalRequestApproved';
export const APPROVAL_REQUEST_REJECTED_NOTIFICATION = 'approvalRequestRejected';

export const UPDATE_CUSTOMER_SITE_RESPONSIBLE_NOTIFICATION =
  'updatedCustomerSiteResponsible';

// Constants for determining of project is associated or current

export const PROJECT_ASSOCIATED = 'Associated Project';

export const PROJECT_CURRENT = 'Current Project';

// Constants for CRM Stats

export const TIME_RANGES = [
  { label: 'before 7am', start: '00:00:00', end: '07:00:00' },
  { label: '7-8am', start: '07:00:00', end: '08:00:00' },
  { label: '8-9am', start: '08:00:00', end: '09:00:00' },
  { label: '9-10am', start: '09:00:00', end: '10:00:00' },
  { label: '10-11am', start: '10:00:00', end: '11:00:00' },
  { label: '11-12nn', start: '11:00:00', end: '12:00:00' },
  { label: '12-1pm', start: '12:00:00', end: '13:00:00' },
  { label: '1-2pm', start: '13:00:00', end: '14:00:00' },
  { label: '2-3pm', start: '14:00:00', end: '15:00:00' },
  { label: '3-4pm', start: '15:00:00', end: '16:00:00' },
  { label: '4-5pm', start: '16:00:00', end: '17:00:00' },
  { label: '5-6pm', start: '17:00:00', end: '18:00:00' },
  { label: 'after 6pm', start: '18:00:00', end: '24:00:00' },
];

export const NO_INDUSTRY = 'no';
export const ALL = 'all';

export const CALLED_AND_APPOINTMENT = 'Called and appointment';
export const CALLED_AND_INTERESTED = 'Called and contact was interested';
export const MISSED_CALL = 'Missed Call';

export const NO_NAME = 'No Name';

export const ALL_PROJECTS = 'ALL_PROJECTS';
export const OWNED_PROJECTS = 'OWNED_PROJECTS';
export const OWNED_PROJECT_OPTIONS = 'OWNED_PROJECT_OPTIONS';
export const BOTH_OWNED_PROJECTS_AND_PROJECT_OPTIONS =
  'BOTH_OWNED_PROJECTS_AND_PROJECT_OPTIONS';
export const NONE = 'NONE';
export const NO_ID = 0;

export const ACCOMPLISHED = 'ACCOMPLISHED';
export const REMOVED = 'REMOVED';

export const STATUS_GDPR_DEFAULT_VALUE = 'NONE';

// Abbreviation check return values
export const OK = 'OK';
export const ERROR_USED_ABBR = 'shouldNotBeReused';

export const noEmployeeChoice = { value: NO_ID, label: 'None' };

export const ISSUE_TO_SOLVE = 'To Solve';
export const ISSUE_TO_UNSOLVE = 'To Unsolve';

export const OPTION_PLACEMENT = 'auto';

export const DEFAULT_RADIX = 10;

export const FLEX_WRAP_ROW = { flexDirection: 'row', flexWrap: 'wrap' };

export const BLANK_OBJECT_NAME_ID = { id: NO_ID, name: '' };
