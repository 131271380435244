import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { Ownership } from '../../../utils/enums/ownership';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { generateTitle } from '../../../utils/helpers/icon';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  deleteProjectOption,
  fetchProjectOptionListItem,
  setFilters,
  toggleFilter,
} from '../../../redux/projectOptionListSlice';
import { RootState } from '../../../redux/store';
import FadeAlert from '../../../components/layout/FadeAlert';
import ResponsibleOwnershipDropdown from '../../../components/dropdowns/ResponsibleOwnershipDropdown';
import ProjectOptionModal from '../ProjectOptionModal/ProjectOptionModal';
import ProjectOptionListTable from './ProjectOptionListTable';
import { t } from './projectOptionListHelpers';

interface IProps
  extends IWithModalsProps,
    PropsFromRedux,
    RouteComponentProps {}

/**
 * Project Option List page.
 * Contains Project Option List table, creation of new project option, merging of selected customers,
 * and toggling of table filter.
 */
const ProjectOptionList = ({
  // WithModals
  modalDeleteHandler,
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
  // Redux State
  filters,
  // Redux actions
  setFilters,
  toggleFilter,
  // Thunks
  fetchProjectOptionListItem,
  deleteProjectOption,
  // Route
  location,
}: IProps) => {
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');

  /**
   * Gets the newly saved project option
   * @param projectOptionId project option id
   */
  const getSavedProjectOption = (projectOptionId: number) => {
    fetchProjectOptionListItem(projectOptionId, (error) => {
      modalErrorHandler(t('failedToUpdateList'), error);
    });
  };

  /**
   * Handles the deletion of project option
   * @param projectId
   */
  const handleRemoveProjectOption = (projectId: number) => {
    modalDeleteHandler(t('deleteProject'), t('deleteProjectConfirm'), () => {
      deleteProjectOption(
        projectId,
        () => {
          setDeleteSuccessMessage(
            `${t('projectOptionDeleted')} - ${projectId}.`
          );
        },
        (error: AxiosError) => {
          modalErrorHandler(t('failedToDeleteProjectOption'), error);
        }
      );
    });
  };

  /**
   * Handles the values selected in the ownership filter
   * @param ownership
   */
  const handleOwnershipFilter = (ownership: Ownership | Ownership[]) => {
    if (
      ownership === Ownership.ALL ||
      ownership === Ownership.ALL.toLowerCase()
    ) {
      setFilters({
        ...filters,
        ownership: [],
      });
    } else {
      setFilters({
        ...filters,
        ownership: Array.isArray(ownership) ? ownership : [ownership],
      });
    }
  };

  /**
   * Displays add new project option/update project option modal.
   *
   * @param projectId The ID of the contact to edit, if any.
   */
  const showAddOrUpdateProjectOptionModal = (projectId?: number) => {
    modalFormHandler(
      generateTitle(
        BUTTON_TITLE_ENUM.INFORMATION.code,
        projectId ? t('updateProjectOption') : t('addProjectOption')
      ),
      <ProjectOptionModal
        projectId={projectId}
        onClose={toggleModalForm}
        onSave={getSavedProjectOption}
      />,
      'xl'
    );
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('projectOptions')}</HeaderTitle>
        {generateBreadcrumb(location.pathname, t('projectOptions'))}
      </Header>
      <Card>
        <CardHeader>
          {deleteSuccessMessage && (
            <FadeAlert color="success">{deleteSuccessMessage}</FadeAlert>
          )}

          <div
            className="card-actions float-end"
            style={{ paddingRight: '5px' }}
          >
            <Button
              color="primary"
              className="fontAwesomeIconAsButton"
              onClick={() => toggleFilter()}
              aria-label="button-filter"
            >
              <FontAwesomeIcon icon={faFilter} />
            </Button>{' '}
            <Button
              color="primary"
              size="m"
              onClick={() => showAddOrUpdateProjectOptionModal()}
            >
              {generateTitle(BUTTON_TITLE_ENUM.ADD.code, t('add'))}
            </Button>
          </div>
          <CardTitle className="mb-0">
            <h1>{t('projectOptions')}</h1>
            <Row>
              <Col md={4}>
                <ResponsibleOwnershipDropdown
                  includeResponsibleAndInvolvedOption
                  value={filters.ownership}
                  onChange={handleOwnershipFilter}
                />
              </Col>
            </Row>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <ProjectOptionListTable
            onDelete={handleRemoveProjectOption}
            onProjectOptionUpdate={showAddOrUpdateProjectOptionModal}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  currentUserData: store.account.employeeDetails,
  filters: store.projectOptionList.filters,
});

const mapDispatchToProps = {
  fetchProjectOptionListItem,
  deleteProjectOption,
  setFilters,
  toggleFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(ProjectOptionList)));
