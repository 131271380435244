import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { AxiosError } from 'axios';

import { IOfferDetails } from '../../../utils/types/responseTypes';
import {
  createFormValueFromOfferDetails,
  createOfferDocumentTitle,
  generateOfferTitle,
  isOfferValidToSend,
  translate,
} from './offerHelper';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import AddOfferVersion from '../../../components/form/AddOfferVersionForm/AddOfferVersion';
import { clickHandler } from '../../../utils/helpers/click';
import {
  getOfferDetailsOlderVersion,
  saveNewVersionOffer,
  saveOffer,
} from '../../../services/api/offer';
import { OfferDetailsContext } from './OfferDetailsProvider';
import { projectStateEnum } from '../../../utils/enums/enum';
import { isStringNotEmpty } from '../../../utils/helpers/string';
import OrderSummary from './OrderSummary/OrderSummary';
import OrderSummaryProvider from './OrderSummary/OrderSummaryProvider';
import { OfferState } from '../../../utils/enums/offer';
import { ProjectState } from '../../../utils/enums/project';
import CustomerAbbreviationInput from '../../../components/form/CustomerAbbreviationInput';
import { ICustomerIdAndAbbreviation } from '../../../utils/types/modelTypes';

interface IProps extends IWithModalsProps {
  updateOffer: () => Promise<void>;
  handleUpdateOfferList: (offer: IOfferDetails) => void;
}

const OfferActivitiesCard = ({
  modalErrorHandler,
  updateOffer,
  modalFormHandler,
  toggleModalForm,
  handleUpdateOfferList,
}: IProps) => {
  const {
    project,
    offer,
    setOffer,
    setFormValues,
    offersFromForms,
    pendingOfferFromForms,
    setProjectDetails,
    isProjectOption,
  } = useContext(OfferDetailsContext);

  const { id: projectRefId, state, customer } = project;

  const { id, offerState, offerVersion, title, date } = offer;

  const disabled = {
    sendLatestVersion: !isOfferValidToSend(state, offer),
    createNewVersion:
      !id ||
      offerState === OfferState.VALID ||
      offerState === OfferState.ORDERED,
    archivateOffer:
      !id ||
      offerState === OfferState.CANCELED ||
      offerState === OfferState.ORDERED ||
      offerState === OfferState.DECLINED ||
      offerState === OfferState.EXPIRED,
    currentVersionOrdered: !(
      offerState === OfferState.SENT || offerState === OfferState.EXPIRED
    ),
    oldVersionOrdered:
      offerVersion === 1 ||
      state === ProjectState.VAGUE ||
      offerState === OfferState.ORDERED,
  };

  const [selectedVersion, setSelectedVersion] = useState(offerVersion);

  const handleOnClose = () => {
    updateOffer();
    toggleModalForm();
  };

  /**
   * HAndles Sending the offer (SenOffer Button)
   */
  const handleSendOffer = () => {
    modalFormHandler(
      `${translate('sendOffer')} ${generateOfferTitle(title, offerVersion)}`,
      <AddOfferVersion onClose={handleOnClose} />,
      'lg'
    );
  };

  /**
   * Handles Creating New Version (CreateNewVersion Button)
   */
  const handleCreateNewVersion = () => {
    const tempOffer = {
      ...offer,
      projectId: projectRefId,
    } as IOfferDetails;

    saveNewVersionOffer(tempOffer)
      .then(({ data: response }) => {
        setOffer(response);
        setFormValues(createFormValueFromOfferDetails(response));
        handleUpdateOfferList(response);
      })
      .catch((error: AxiosError) =>
        modalErrorHandler(translate('failedToAddOffer'), error)
      );
  };

  /**
   * Handles archivating current offer (ArchivateOffer Button)
   */
  const handleArchivateOffer = async () => {
    const offerToArchive = {
      ...offer,
      offerState: OfferState.CANCELED,
    } as IOfferDetails;

    await saveOffer(offerToArchive).then(({ data: response }) => {
      setOffer(response);
      // Update List of Offers to show cancel
      handleUpdateOfferList(response);
    });
  };

  const displayOrderModal = (offerToOrder: IOfferDetails) => {
    modalFormHandler(
      translate('orderSummary'),
      <OrderSummaryProvider
        offerDetails={offerToOrder}
        projectDetails={project}
      >
        <OrderSummary
          offersFromForms={offersFromForms}
          pendingOffersFromForms={pendingOfferFromForms ?? []}
          // eslint-disable-next-line no-empty-function
          setProjectDetails={setProjectDetails ?? (() => {})}
          fromProjectOption={isProjectOption ?? false}
          onClose={handleOnClose}
        />
      </OrderSummaryProvider>,
      'xs'
    );
  };

  const onFinish = (updatedCustomer: ICustomerIdAndAbbreviation) => {
    const { name } = customer;
    const updatedOffer = {
      ...offer,
      customer: { ...updatedCustomer, name },
      offerDocumentTitle: createOfferDocumentTitle(
        customer.abbreviation,
        title,
        date.toString(),
        offerVersion
      ),
    };

    setOffer(updatedOffer);
    toggleModalForm();
    displayOrderModal(updatedOffer);
  };

  /**
   * Handles ordering current version (CurrentVersionOrdered Button)
   */
  const handleCurrentVersionOrdered = (offerToOrder: IOfferDetails) => {
    const { id, name, abbreviation } = customer;
    if (!isStringNotEmpty(customer.abbreviation)) {
      modalFormHandler(
        translate('abbreviationCheck'),
        <CustomerAbbreviationInput
          customer={{
            id,
            name,
            abbreviation,
          }}
          onFinish={onFinish}
        />
      );
    } else {
      displayOrderModal(offerToOrder);
    }
  };

  /**
   * Selects older version of offer
   * @param version of selected older version
   */
  const handleVersionSelect = (version: number) => {
    setSelectedVersion(version);
  };

  const handleOfferVersionConfirm = async () => {
    if (id) {
      await getOfferDetailsOlderVersion(id, selectedVersion).then(
        ({ data: response }) => {
          setOffer((prevState) => ({ ...prevState, response }));
          setFormValues(createFormValueFromOfferDetails(response));

          toggleModalForm();

          handleCurrentVersionOrdered(response);
        }
      );
    }
  };

  /**
   * Handles reverting back to older version of offer (OlderVersionOrdered Button)
   */
  const handleOldVersionOrdered = () => {
    const tempOffer = { ...offer };

    if (state === projectStateEnum.vague.code) {
      return;
    }

    modalFormHandler(
      translate('chooseOfferVersionToOrder'),
      <>
        <Select
          options={Array.from(
            { length: tempOffer.offerVersion - 1 },
            (_, index) => ({ value: index + 1, label: `v${index + 1}` })
          )}
          onChange={handleVersionSelect}
          placeholder={translate('selectVersion')}
        />
        <div className="float-right m-2">
          <Button
            color="primary"
            className="mx-2"
            onClick={handleOfferVersionConfirm}
          >
            {translate('confirm')}
          </Button>
          <Button color="primary" className="mx-2" onClick={handleOnClose}>
            {translate('cancel')}
          </Button>
        </div>
      </>,
      'xs'
    );
  };

  return (
    <Card className="common-card with-border">
      <CardHeader>
        <CardTitle>
          <div style={{ textAlign: 'center' }}>
            <h3>{translate('activitiesTitle')}</h3>
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col className="m-0 p-1">
            <Button
              color="primary"
              className="w-100 h-100"
              disabled={disabled.sendLatestVersion}
              onClick={(event: MouseEvent) =>
                clickHandler(event, handleSendOffer)
              }
            >
              {translate('sendLatestVersion')}
            </Button>
          </Col>
          <Col className="m-0 p-1">
            <Button
              color="primary"
              className="w-100 h-100"
              disabled={disabled.createNewVersion}
              onClick={(event: MouseEvent) =>
                clickHandler(event, handleCreateNewVersion)
              }
            >
              {translate('createNewVersion')}
            </Button>
          </Col>
          <Col className="m-0 p-1">
            <Button
              color="primary"
              className="w-100 h-100"
              disabled={disabled.archivateOffer}
              onClick={(event: MouseEvent) =>
                clickHandler(event, handleArchivateOffer)
              }
            >
              {translate('archivateOffer')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="8" className="m-0 p-1">
            <Button
              color="success"
              className="w-100 h-100"
              disabled={disabled.currentVersionOrdered}
              onClick={(event: MouseEvent) =>
                clickHandler(event, () => handleCurrentVersionOrdered(offer))
              }
            >
              {translate('currentVersionOrdered')}
            </Button>
          </Col>
          <Col className="m-0 p-1">
            <Button
              color="primary"
              className="w-100 h-100"
              disabled={disabled.oldVersionOrdered}
              onClick={(event: MouseEvent) =>
                clickHandler(event, handleOldVersionOrdered)
              }
            >
              {translate('oldVersionOrdered')}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withModals(OfferActivitiesCard);
