import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useState, useContext } from 'react';
import { Button } from 'reactstrap';

import CollapsibleTableCard from '../../../components/collapsibleCards/CollapsibleTableCard';
import { sortByDate } from '../../../utils/helpers/array';
import { getDateFormat } from '../../../utils/helpers/date';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IActivityListItem } from '../../../utils/types/modelTypes';
import { ContactPersonDetailsContext } from './ContactPersonDetailsProvider';
import { createEmployeeLinkFromActivity } from './contactPersonHelper';
import ModalAddInfo from './ModalAddInfo';

// !-- Used in ContactPersonDetails, ContactPersonOverview

interface IProps extends IWithModalsProps {}

interface ITableEntry {
  date: string;
  description: string;
  creator: React.JSX.Element | string | null;
  comments: string;
}

const t = (keyName: string) => i18n.t(`ActivityHistoryCard.${keyName}`);

/**
 * Card for displaying the history of activities of a contactperson
 *
 * Updated implementation of ActivityHistoryCard
 */
const CollapsibleActivityHistoryCard = ({
  // WithModals
  modalFormHandler,
  toggleModalForm,
}: IProps) => {
  const { historyOfActivitiesDetail } = useContext(ContactPersonDetailsContext);

  const [compactHistory, setCompactHistory] = useState(true);

  // Handles the modal to save a new activity
  const handleAddActivity = () => {
    modalFormHandler(
      t('add'),
      <ModalAddInfo onCancel={toggleModalForm} />,
      'm'
    );
  };

  const headerButtons = (
    <div className="d-flex align-items-center gap-1">
      {compactHistory ? (
        <Button
          color="primary"
          size="m"
          onClick={() => setCompactHistory(false)}
        >
          {t('expand')}
        </Button>
      ) : (
        <Button
          color="primary"
          size="m"
          onClick={() => setCompactHistory(true)}
        >
          {t('contract')}
        </Button>
      )}
      <Button
        aria-label="modal-add-info-button"
        color="primary"
        onClick={() => handleAddActivity()}
      >
        <FontAwesomeIcon icon={faPlus} className="margin-right" />
        <span>{t('add')}</span>
      </Button>
    </div>
  );

  const activities = historyOfActivitiesDetail
    .filter(({ comment }) =>
      compactHistory ? comment === 'important' || comment === 'relevant' : true
    )
    .map(({ date, description, creator, comment }) => ({
      date,
      description,
      creator: createEmployeeLinkFromActivity({
        creator,
      } as IActivityListItem),
      comments: comment,
    }))
    .sort((firstActivity: ITableEntry, secondActivity: ITableEntry) => {
      if (
        firstActivity.comments === 'important' &&
        secondActivity.comments !== 'important'
      ) {
        return -1;
      }
      if (
        firstActivity.comments !== 'important' &&
        secondActivity.comments === 'important'
      ) {
        return 1;
      }
      return 0;
    });

  const tableData = sortByDate(activities).map(
    ({ date, description, creator }) => ({
      [t('date')]: moment(date).format(getDateFormat()),
      [t('description')]: description,
      [t('creator')]: creator,
    })
  );

  return (
    <CollapsibleTableCard
      title={t('historyOfActivities')}
      tableData={tableData}
      headerButtons={headerButtons}
      striped
      defaultOpen
    />
  );
};

export default withModals(CollapsibleActivityHistoryCard);
