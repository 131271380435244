import { Tooltip } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';

import Auth from '../../../services/axios/Auth';
import { READWRITE } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { EmployeeState } from '../../../utils/enums/employee';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { generateTitle } from '../../../utils/helpers/icon';
import withModals, {
  IWithModalsProps,
} from '../../../utils/withModals';
import { RootState } from '../../../redux/store';
import {
  IDropdownOption,
  IObjectInvolvedResponsible,
} from '../../../utils/types/commonTypes';
import EmployeeOverviewGeneralInformation from './EmployeeOverviewGeneralInformation';
import EmployeeOverviewInternalRelations from './EmployeeOverviewInternalRelations';
import { IEmployeeDetail } from '../../../utils/types/responseTypes';
import { edt, updatedEmployeeDetail } from './employeeDetailHelper';
import { EmployeeDetailsContext } from './EmployeeDetailProvider';
import {
  syncWithPersonio,
  updateEmployeeFromDetail,
} from '../../../services/api/employee';

/**
 * This class displays the Employee Overview of the selected employee
 */
interface IProps extends PropsFromRedux, IWithModalsProps {}

const EmployeeOverviewCard = ({
  account: {
    employeeDetails: { id: employeeId },
  },
  modalErrorHandler,
}: IProps) => {
  const {
    employeeDetail,
    employeeDetail: {
      id: employeeDetailId,
      employeeState,
      mentorEmployee: { id: mentorId = undefined } = {},
      hrResponsible: { id: hrId = undefined } = {},
      email,
    },
    setEmployeeDetail,
  } = useContext(EmployeeDetailsContext);
  const [employeeEdited, setEmployeeEdited] = useState({} as IEmployeeDetail);
  const [selectEditableField, setEditableField] = useState('');

  // Obtaining user permission boolean
  const hasPermission =
    Auth.hasPermission(
      [PERMISSION_URI.employeeManagement.readWrite.uri],
      [READWRITE]
    ) ||
    (Auth.hasPermission(
      [PERMISSION_URI.employeeManageMyEmployees.readWrite.uri],
      [READWRITE]
    ) &&
      (mentorId === employeeId || hrId === employeeId));

  // Check if employee is former
  const selectedEmployeeIsFormer =
    employeeState === EmployeeState.FORMER ?? false;

  // Check if there are any field changes
  const fieldHasNoChanges = employeeEdited?.id === undefined;

  /**
   * Method that resets the employee
   */
  const resetEmployee = () => {
    setEmployeeEdited({} as IEmployeeDetail);
    setEditableField('');
  };

  const updateUserPersonio = (email: string) => {
    syncWithPersonio(email)
      .then((response: AxiosResponse<IEmployeeDetail>) => {
        setEmployeeDetail(response.data);
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(edt('failedToSaveEmployee'), error);
      });
  };

  /**
   * Method to save the employee using save prop from parent
   */
  const saveEmployee = () => {
    updateEmployeeFromDetail(employeeEdited)
      .then(() => {
        setEmployeeDetail(
          updatedEmployeeDetail(employeeDetail, employeeEdited)
        );
      })
      .catch((error: AxiosError) => {
        setEmployeeEdited({} as IEmployeeDetail);
        modalErrorHandler(edt('failedToSaveEmployee'), error);
      });
  };

  /**
   * Returns true if the user is authorized
   * Authroized if current user is Part of the following:
   * INV-V -> from PERSONIO
   * HRD-V -> from PERSONIO
   * Head of employee‘s Department -> from PERSONIO
   * Head of BDD -> from PERSONIO
   *
   * @param {*} selectedEmployee
   * @returns true or false
   */
  const isCurrentUserOverviewOrAuthorized = (
    selectedEmployee: IEmployeeDetail
  ) => {
    if (employeeDetailId === selectedEmployee?.id) {
      return true;
    }

    /**
     * NOTE:(do not remove) Still need to implement if the current user is authorized by checking if the user is part of the following.
     *  INV-V -> from PERSONIO
     * HRD-V -> from PERSONIO
     * Head of employee‘s Department -> from PERSONIO
     * Head of BDD -> from PERSONIO
     */

    return false;
  };

  const onChangeSelectedField = (selectEditableField: string) => {
    setEditableField(selectEditableField);
  };

  const updateItem = (
    itemToUpdate: string,
    value: IObjectInvolvedResponsible[] | string
  ) => {
    setEmployeeEdited({
      ...employeeEdited,
      id: employeeDetailId,
      [itemToUpdate]: value,
    });
  };

  useEffect(() => {
    resetEmployee();
  }, [employeeDetail]);

  return (
    <Container fluid>
      <Card>
        <CardHeader>
          <div className="float-end flex-button">
            {Auth.isAdmin() && (
              <Tooltip
                title={<div>{edt('ManualPersonioSyncSingle')}</div>}
                enterDelay={400}
                enterNextDelay={400}
              >
                <Button
                  color="primary"
                  onClick={() => updateUserPersonio(email)}
                >
                  {generateTitle(BUTTON_TITLE_ENUM.SYNC.code)}
                </Button>
              </Tooltip>
            )}
            {hasPermission && (
              <>
                <Tooltip
                  title={<div>{edt('saveChanges')}</div>}
                  enterDelay={400}
                  enterNextDelay={400}
                >
                  <Button
                    color="primary"
                    onClick={saveEmployee}
                    disabled={fieldHasNoChanges}
                  >
                    {generateTitle(BUTTON_TITLE_ENUM.SAVE.code)}
                  </Button>
                </Tooltip>
                <Tooltip
                  title={<div>{edt('resetChanges')}</div>}
                  enterDelay={400}
                  enterNextDelay={400}
                >
                  <Button
                    color="primary"
                    onClick={resetEmployee}
                    disabled={fieldHasNoChanges}
                  >
                    {generateTitle(BUTTON_TITLE_ENUM.UNDO.code)}
                  </Button>
                </Tooltip>
              </>
            )}
          </div>
          <CardTitle>
            <h1>{edt('employeeOverview')}</h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <EmployeeOverviewGeneralInformation
                selectedEmployee={employeeDetail}
                selectedEmployeeIsFormer={selectedEmployeeIsFormer}
                selectEditableField={selectEditableField}
                employeeEdited={employeeEdited}
                hasPermission={hasPermission}
                onChangeSelectedField={onChangeSelectedField}
                isCurrentUserOverviewOrAuthorized={isCurrentUserOverviewOrAuthorized(
                  employeeDetail
                )}
                onChangeReleaseStatus={({
                  value: releaseStatus,
                }: IDropdownOption) =>
                  updateItem('releaseStatus', releaseStatus)
                }
              />
            </Col>
            <Col>
              <EmployeeOverviewInternalRelations
                selectedEmployee={employeeDetail}
                selectedEmployeeIsFormer={selectedEmployeeIsFormer}
                selectEditableField={selectEditableField}
                employeeEdited={employeeEdited}
                hasPermission={hasPermission}
                onChangeSelectedField={onChangeSelectedField}
                onChangeResponsible={(
                  responsible: IObjectInvolvedResponsible[]
                ) => updateItem('involvedResponsibles', responsible)}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = ({ account }: RootState) => ({
  account,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(EmployeeOverviewCard));
