import React, { ReactElement } from 'react';
import { Label, Table } from 'reactstrap';
import { v4 } from 'uuid';

export type TableData = {
  [key: string]: ReactElement | number | string;
};

interface IProps {
  tableData: TableData[];
  highlightedRows?: number[];
  noDataPlaceholder?: string;
  striped?: boolean;
}

/**
 * Table -
 * Displays data in table form, and has options for highlighting rows
 * and striped rows
 */
const ReusableTable = ({
  tableData,
  highlightedRows = [],
  noDataPlaceholder,
  striped,
}: IProps) => (
  <>
    {tableData.length > 0 && tableData[0] && (
      <Table aria-label="reusable-table" striped={striped}>
        <thead>
          <tr>
            {Object.keys(tableData[0]).map((column) => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, i) => (
            <tr
              key={v4()}
              style={{
                backgroundColor: highlightedRows.includes(i) ? '#A9A9A9' : '',
              }}
            >
              {Object.values(data).map((value) => (
                <td key={v4()}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    )}
    {tableData.length === 0 && (
      <Label className="text-center" tag="h4">
        {noDataPlaceholder}
      </Label>
    )}
  </>
);

export default ReusableTable;
