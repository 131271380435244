import { PayloadAction } from '@reduxjs/toolkit';

import {
  getGdprListItems,
  saveInfoGdpr as saveInfoGdprToBackend,
} from '../services/api/contactPerson';
import { getEmployeeNames } from '../services/api/employee';
import { Ownership } from '../utils/enums/ownership';
import { SortBy, SortType } from '../utils/enums/sort';
import { maxPageLength } from '../utils/constants';
import { objectNameAndIdToDropdownOptions } from '../utils/helpers/dropdown';
import { removeDuplicates } from '../utils/helpers/table';
import {
  createQueryParameters,
  generateInfoGdprForSaving,
} from '../pages/crm/GdprOverview/gdprOverviewHelpers';
import { IDropdownOption } from '../utils/types/commonTypes';
import { IGdprListItem } from '../utils/types/responseTypes';
import { IGpdrListFilters } from '../utils/types/stateTypes';
import createListSlice, { ListState } from './createListSlice';
import type { AppThunk } from './store';

type AdditionalState = {
  employees: IDropdownOption<number>[];
};

type GdprListState = ListState<
  IGdprListItem,
  IGpdrListFilters,
  AdditionalState
>;

const initialState: GdprListState = {
  listItems: [],
  hasMore: true,
  page: 0,
  pageSize: 0,
  pageCount: 0,
  filters: {
    firstname: '',
    lastname: '',
    assignmentState: [],
    statusGdpr: [],
    responsible: [],
    ownership: Ownership.RESPONSIBLE,
  },
  showFilters: false,
  sortBy: SortBy.LAST_NAME,
  sortType: SortType.ASCENDING,
  additionalState: {
    employees: [],
  },
};

const gdprListSlice = createListSlice({
  name: 'gdprList',
  initialState,
  reducers: {
    setEmployees: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        employees: [...payload],
      },
    }),
  },
});

/**
 * GDPR List SLice = contains the state of the GDPR Overview page
 *
 * 1. List of Contact Persons with GDPR Info
 * 2. Page of items to be fetched and if there is more data to fetch
 * 3. Applied filters
 * 4. Applied sorting criteria
 * 5. List of employees for responsible filter
 * 6. Contact Person to solve/unsolve
 */
export const {
  setListItems,
  setHasMoreListItems,
  setPage,
  setFilters,
  toggleFilter,
  setSortBy,
  setSortType,
  setEmployees,
} = gdprListSlice.actions;

/**
 * Thunk for fetching gdpr list items and updating the gdpr list slice
 * @param isSortOrFilterFetchType Whether fetching due to sort / filter / scroll update
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching gdpr list items
 */
export const fetchGdprListItems =
  (
    isSortOrFilterFetchType: boolean,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        gdprList: { page, filters, sortBy, sortType, listItems },
      } = getState();

      const { data: fetchedListItems } = await getGdprListItems(
        createQueryParameters(page, filters, sortBy, sortType)
      );
      const processedListItems = isSortOrFilterFetchType
        ? [...fetchedListItems]
        : removeDuplicates(listItems, fetchedListItems);

      dispatch(
        setPage(!(fetchedListItems.length < maxPageLength) ? page + 1 : page)
      );
      dispatch(setHasMoreListItems(!(fetchedListItems.length < maxPageLength)));
      dispatch(setListItems(processedListItems));
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching employees and updating responsible filter
 * in gdpr list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching employee names and IDs
 */
export const fetchEmployees =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedEmployees } = await getEmployeeNames({});
      dispatch(
        setEmployees(objectNameAndIdToDropdownOptions(fetchedEmployees))
      );
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for updating contact person due to solving or unsolving GDPR issue, then updating gdpr list slice
 * @param contactID Id of contact to update
 * @returns Inner thunk function containing async logic for updating contact
 */
export const updateGdprListItem =
  (contactId: number): AppThunk =>
  async (dispatch, getState) => {
    const {
      gdprList: { listItems },
    } = getState();

    const {
      data: [updatedListItem],
    } = await getGdprListItems({
      'id.equals': contactId.toString(),
    });

    const updatedListItems = [...listItems];
    if (updatedListItem) {
      updatedListItems[
        updatedListItems.findIndex(({ id }) => id === contactId)
      ] = updatedListItem;
    }

    dispatch(setListItems(updatedListItems));
  };

/**
 * Thunk for saving contact person along with updated GDPR object
 * @param contactPersonToUpdate contact person to update
 * @param solveOrUnsolve identifies whether in solving or unsolving process
 * @param errorHandler Function for handling errors from saving data
 * @returns Inner thunk function containing async logic for saving gdpr list items
 */
export const saveInfoGdpr =
  (
    contactPersonToUpdate: IGdprListItem,
    solveOrUnsolve: string,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      const clonedContactPersonToUpdate: IGdprListItem = {
        ...JSON.parse(JSON.stringify(contactPersonToUpdate)),
        infoGdpr: JSON.stringify(
          generateInfoGdprForSaving(contactPersonToUpdate, solveOrUnsolve)
        ),
      };

      await saveInfoGdprToBackend(clonedContactPersonToUpdate);
      dispatch(updateGdprListItem(clonedContactPersonToUpdate.id));
    } catch (error) {
      errorHandler(error);
    }
  };

export default gdprListSlice.reducer;
