import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
} from 'reactstrap';

import {
  getProjects,
  getProjectApprovalStatus,
} from '../../../services/api/project';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import {
  BUTTON_TITLE_ENUM,
  EntityType,
} from '../../../utils/enums/pageComponents';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { generateTitle } from '../../../utils/helpers/icon';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  deleteProject,
  fetchProjectListItem,
} from '../../../redux/projectListSlice';
import { RootState } from '../../../redux/store';
import { IApprovalRequest } from '../../../utils/types/modelTypes';
import FadeAlert from '../../../components/layout/FadeAlert';
import UnbilledCustomerProjectOverview from './UnbilledCustomerProjectOverview';
import CreateNewProjectModal from './CreateNewProjectModal';
import { t } from './projectListHelpers';
import ProjectListTable from './ProjectListTable';

interface IProps
  extends PropsFromRedux,
    RouteComponentProps,
    IWithModalsProps {}

/**
 * Project Overview page.
 * Contains Project List Table, creation of new project, and toggling of table filter.
 */
const ProjectList = ({
  // WithModals
  modalDeleteHandler,
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
  // Redux state
  currentUserData,
  // Thunks
  deleteProject,
  fetchProjectListItem,
  // Route
  location,
}: IProps) => {
  const [showCreateNewProjectModal, setShowCreateNewProjectModal] =
    useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');

  /**
   * Handles the deletion of projects
   * @param idOfProject
   */
  const handleRemoveProject = (idOfProject: number) => {
    modalDeleteHandler(t('deleteProject'), t('deleteProjectConfirm'), () =>
      deleteProject(
        idOfProject,
        () => {
          setDeleteSuccessMessage(`${t('projectDeleted')}${idOfProject}`);
        },
        (error) => {
          modalErrorHandler(t('failedToDeleteProject'), error);
        }
      )
    );
  };

  /**
   * Gets the newly saved project
   * @param idOfProject
   */
  const getSavedProject = (idOfProject: number) => {
    fetchProjectListItem(idOfProject, (error) => {
      modalErrorHandler(t('failedToRetrieveProject'), error);
    });
  };

  /**
   * Show modal for updating unbilled project
   * @param idOfProject
   */
  const onUnbilledProjectUpdate = async (idOfProject: number) => {
    try {
      const {
        data: [unbilledProject],
      } = await getProjects({
        'id.equals': idOfProject.toString(),
      });

      if (unbilledProject) {
        const {
          data: [approvalRequest],
        } = await getProjectApprovalStatus({
          'objectType.in': EntityType.PROJECT.toUpperCase(),
          'objectId.in': idOfProject.toString(),
        });

        const isProjectForApproval =
          currentUserData.id === approvalRequest?.employee.id;

        modalFormHandler(
          isProjectForApproval
            ? t('requestForApproval')
            : t('updateUnbilledProject'),
          <UnbilledCustomerProjectOverview
            project={unbilledProject}
            approvalRequest={approvalRequest ?? ({} as IApprovalRequest)}
            forApproval={isProjectForApproval}
            onCancel={() => toggleModalForm()}
            onSave={getSavedProject}
          />,
          'xl'
        );
      }
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveApprovalRequest'), error);
    }
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('projects')}</HeaderTitle>
        {generateBreadcrumb(location.pathname, t('projects'))}
      </Header>
      <Card>
        <CardHeader>
          {deleteSuccessMessage && (
            <FadeAlert color="success">{deleteSuccessMessage}</FadeAlert>
          )}

          <div
            className="card-actions float-end"
            style={{ paddingRight: '5px' }}
          >
            <Button
              color="primary"
              onClick={() =>
                setShowCreateNewProjectModal(!showCreateNewProjectModal)
              }
            >
              {generateTitle(BUTTON_TITLE_ENUM.ADD.code, t('createNewProject'))}
            </Button>
          </div>
          <CardTitle className="mb-0">
            <h1>{t('projectList')}</h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <ProjectListTable
            onDelete={handleRemoveProject}
            onUnbilledProjectUpdate={onUnbilledProjectUpdate}
          />
        </CardBody>
      </Card>
      <CreateNewProjectModal
        isOpen={showCreateNewProjectModal}
        onCancel={() =>
          setShowCreateNewProjectModal(!showCreateNewProjectModal)
        }
        onSave={getSavedProject}
      />
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  currentUserData: store.account.employeeDetails,
});

const mapDispatchToProps = {
  deleteProject,
  fetchProjectListItem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(ProjectList)));
