import i18n from '../../../i18n';
import { isEmpty } from '../../../utils/helpers/array';
import {
  IObjectContactInformation,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';

export const translateCustomerAccountDetails = (keyName: string) =>
  i18n.t(`CustomerAccountDetailsCard.${keyName}`);

export const translateExistingCustomerSelection = (keyName: string) =>
  i18n.t(`ExistingCustomerSelection.${keyName}`);

/**
 * Creates the query parameters to be used to fetch contact-information
 * @param objectId - The id of the object
 * @param objectType - The type of the object
 * @returns An object containing query parameters for object data.
 */
export const createQueryParameters = (
  objectId?: string,
  objectType?: string
) => ({
  ...(objectId && {
    'objectId.equals': objectId,
  }),
  ...(objectType && {
    'objectType.equals': objectType,
  }),
});

/**
 * Return a sorted employees associated to the responsibles given
 * @param {*} responsibles
 * @param {*} allEmployees all employees
 */
export function getEmployeesFromResponsibles(
  responsibles: IObjectNameAndId[],
  allEmployees: IObjectNameAndId[]
) {
  if (!isEmpty(responsibles) && !isEmpty(allEmployees)) {
    const responsibleEmployees = allEmployees.filter((item) =>
      responsibles.some((responsible) => responsible.id === item?.id)
    );

    if (!isEmpty(responsibleEmployees)) {
      return responsibleEmployees.sort((a, b) => {
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
          return 1;
        }
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
          return 1;
        }
        return -1;
      });
    }
  }
  return [];
}

/**
 * Returns a list of unique sectors
 * @param {*} customerSectors
 */
export const getUniqueSectors = (customerSectors: string[]) => [
  ...new Set(customerSectors),
];

/**
 * Returns the contact info of the customer account
 * @param {*} contactInfo
 * @param {*} contactInfoType
 * @returns
 */
export const getContactInfo = (
  contactInfo: IObjectContactInformation[],
  contactInfoType: string
) => contactInfo.filter(({ type }) => type === contactInfoType);

/**
 * Returns the employee name of the responsible based on employee id
 * @param {*} employeeId
 * @returns
 */
export const getEmployeeName = (employeeId: number, employees: IObjectNameAndId[]) => {
  const employee = employees.find(({ id }) => id === employeeId);
  return employee ? `${employee.name}` : 'not applicable';
};

/**
 * Formats the responsible person in the details page
 * @param {*} employeeName
 * @param {*} responsibleRole
 * @param {*} ownership
 * @returns
 */
export const formatResponsible = (
  employeeName: string,
  responsibleRole: string,
  ownership: any
) => {
  let responsible = employeeName;
  if (responsibleRole && !ownership) {
    responsible += ` (${responsibleRole})`;
  } else if (!responsibleRole && ownership) {
    responsible += ` (${
      i18n.exists(`OwnershipModel.${ownership}`)
        ? i18n.t(`OwnershipModel.${ownership}`)
        : ownership
    })`;
  } else if (responsibleRole && ownership) {
    responsible += ` (${responsibleRole}; ${
      i18n.exists(`OwnershipModel.${ownership}`)
        ? i18n.t(`OwnershipModel.${ownership}`)
        : ownership
    })`;
  }
  return responsible;
};
