// eslint-disable-next-line import/prefer-default-export
export enum Approval {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export enum ApprovalObjectAttribute {
  PROJECT_STATE = 'PROJECT_STATE',
  OFFER_STATE = 'OFFER_STATE',
  DEPARTMENT_STATE = 'DEPARTMENT_STATE',
}
