import { PayloadAction } from '@reduxjs/toolkit';

import { getApprovalListItems } from '../services/api/approvalRequest';
import { SortBy, SortType } from '../utils/enums/sort';
import { maxPageLength } from '../utils/constants';
import { removeDuplicates } from '../utils/helpers/table';
import { createQueryParameters } from '../pages/crm/ApprovalList/approvalListHelpers';
import { IApprovalRequestListItem } from '../utils/types/responseTypes';
import { IApprovalRequestFilters } from '../utils/types/stateTypes';
import createListSlice, { ListState } from './createListSlice';
import type { AppThunk } from './store';

type AdditionalState = {
  historicListItems: IApprovalRequestListItem[];
  historicHasMore: boolean;
  historicPage: number;
};

type ApprovalRequestListState = ListState<
  IApprovalRequestListItem,
  IApprovalRequestFilters,
  AdditionalState
>;

const initialState: ApprovalRequestListState = {
  listItems: [],
  hasMore: true,
  page: 0,
  pageSize: 0,
  pageCount: 0,
  filters: {
    title: '',
    objectType: '',
    attribute: '',
    createdDate: null,
    approved: '',
    dateDecision: null,
  } as IApprovalRequestFilters,
  showFilters: false,
  sortBy: SortBy.ID,
  sortType: SortType.ASCENDING,
  additionalState: {
    historicListItems: [],
    historicHasMore: true,
    historicPage: 0,
  },
};

/**
 * Approvals List Slice - contains the state of the Approvals List page
 * 1.) List of Approvals
 * 2.) Page of items to be fetched and if there is more data to fetch
 * 3.) Applied filters
 * 4.) Applied sorting criteria
 * 5.) Also has historic varants of the list items
 */
const approvalRequestListSlice = createListSlice({
  name: 'approvalRequestList',
  initialState,
  reducers: {
    // Sets the historic version of the list Items
    setHistoricListItems: (
      state,
      { payload }: PayloadAction<IApprovalRequestListItem[]>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        historicListItems: [...payload],
      },
    }),
    // Sets the historic version of the "hasMore" boolean
    setHistoricHasMoreListItems: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        historicHasMore: payload,
      },
    }),
    // Sets the historic version of the page number
    setHistoricPage: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      historicPage: payload,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        historicPage: payload,
      },
    }),
  },
});

export const {
  setListItems,
  setHasMoreListItems,
  setPage,
  setFilters,
  toggleFilter,
  setSortBy,
  setSortType,
  setHistoricListItems,
  setHistoricHasMoreListItems,
  setHistoricPage,
} = approvalRequestListSlice.actions;

/**
 * Thunk for fetching Approval list items and updating approval list slice
 * @param isSortOrFilterFetchType Whether fetching due to sort / filter / scroll update
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching approval list items
 */
export const fetchApprovalRequestListItems =
  (
    isSortOrFilterFetchType: boolean,
    historicView: boolean,
    employeeId: number,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        approvalRequestList: {
          page,
          filters,
          sortBy,
          sortType,
          listItems,
          additionalState: { historicPage = 0, historicListItems = [] } = {},
        },
      } = getState();

      const { data: fetchedListItems } = await getApprovalListItems(
        createQueryParameters(
          historicView ? historicPage : page,
          filters,
          sortBy,
          sortType,
          historicView,
          employeeId
        )
      );

      if (historicView) {
        const processedListItems = isSortOrFilterFetchType
          ? [...fetchedListItems]
          : removeDuplicates(historicListItems, fetchedListItems);
        dispatch(
          setHistoricPage(
            !(fetchedListItems.length < maxPageLength) ? page + 1 : page
          )
        );
        dispatch(
          setHistoricHasMoreListItems(
            !(fetchedListItems.length < maxPageLength)
          )
        );
        dispatch(setHistoricListItems(processedListItems));
      } else {
        const processedListItems = isSortOrFilterFetchType
          ? [...fetchedListItems]
          : removeDuplicates(listItems, fetchedListItems);
        dispatch(
          setPage(!(fetchedListItems.length < maxPageLength) ? page + 1 : page)
        );
        dispatch(
          setHasMoreListItems(!(fetchedListItems.length < maxPageLength))
        );
        dispatch(setListItems(processedListItems));
      }
    } catch (error) {
      errorHandler(error);
    }
  };

export default approvalRequestListSlice.reducer;
