import axios from 'axios';
import { Url } from '../api/constants';
import SessionStorage from './SessionStorage';

const sessionStorageService = SessionStorage.getService();

// Use for adding the authorization header located in session storage
// on axios request.
axios.interceptors.request.use(
  (config) => {
    const token = sessionStorageService.getAccessToken();
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Interceptor for response, if error 401 - not authorized return to login page
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location.href = Url.LOGIN;
    }
    return Promise.reject(error);
  }
);

export default axios;
