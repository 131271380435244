import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { Button, CardBody, CardHeader, Container, Label } from 'reactstrap';

import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { NO_ID } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { isEmpty, sortByPropValue } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IError } from '../../../utils/types/commonTypes';
import { IEmployeeGroup } from '../../../utils/types/modelTypes';
import EmployeeGroupAddForm from './EmployeeGroupAddForm';

interface IProps extends IWithModalsProps {}

interface IState {
  employeeGroups: IEmployeeGroup[];
  name: string;
  description: string;
}

interface IEntry {
  name: string;
  groupId: number;
  description: string;
  menuComponent: JSX.Element;
}

/**
 * Class that contains all Employee Group Settings in the Permission Settings
 */
class EmployeeGroup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      employeeGroups: [],
      name: '',
      description: '',
    };
  }

  async componentDidMount() {
    await axios.employee
      .get('employee-groups')
      .then((response: AxiosResponse<IEmployeeGroup[]>) =>
        this.setState({ employeeGroups: response.data })
      )
      .catch((error: AxiosError) => {
        this.props.modalErrorHandler(
          this.t('failedToRetrieveEmployeeGroups'),
          error
        );
      });
  }

  t(keyname: string) {
    return i18n.t(`EmployeeGroup.${keyname}`);
  }

  // Here the Data is prepared to function with the DynamicTable Component
  prepareTabledata = (employeeGroups: IEmployeeGroup[]) => {
    if (!isEmpty(employeeGroups)) {
      const newTableData: IEntry[] = [];

      employeeGroups.forEach((group) => {
        const entry: IEntry = {
          name: group?.name ?? '',
          groupId: group?.id ?? (NO_ID as number),
          description: group?.description ?? '',
          menuComponent: (
            <div>
              <Button
                color="primary"
                onClick={() => this.deleteGroup(group?.id ?? (NO_ID as number))}
              >
                {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
              </Button>
            </div>
          ),
        };
        newTableData.push(entry);
      });

      return newTableData;
    }
    return [];
  };

  /**
   * Method that handles the deletion of an employee group
   * @param groupId The id of the employee group
   */
  deleteGroup = (groupId: number) => {
    this.props.modalDeleteHandler(
      this.t('delete'),
      <>
        <h1 style={{ color: 'red' }}>{this.t('warning')}</h1>
        <p>{this.t('initialWarning')}</p>
        <ul>
          <li>{this.t('linkedRoleGroups')}</li>
          <li>{this.t('linkedRoles')}</li>
        </ul>
        <p>{this.t('employeeWarning')}</p>
        <p>{this.t('proceed')}</p>
      </>,
      () => {
        axios.employee
          .delete(`employee-groups/${groupId}`)
          .then(() => {
            this.setState({
              employeeGroups: this.state.employeeGroups.filter(
                (group) => group.id !== groupId
              ),
            });
          })
          .catch((error: AxiosError<IError>) => {
            if (error.response?.data.title === 'Employee Still Connected') {
              this.props.modalOkHandler(
                this.t('warning'),
                <>
                  <p>{this.t('failedToDeleteEmployeeGroup')}</p>
                  <p>{this.t('employeeWarning')}</p>
                </>
              );
            } else {
              this.props.modalErrorHandler(
                this.t('failedToDeleteEmployeeGroup'),
                error
              );
            }
          });
      }
    );
  };

  /**
   * Method that updates the list of employee groups
   */
  onSaveUpdateList = (group: IEmployeeGroup) => {
    const { employeeGroups } = this.state;
    employeeGroups.push(group);
    this.setState({ employeeGroups });
  };

  /**
   * Method that shows the add employee group modal
   */
  addEmployeeGroup = () => {
    this.props.modalFormHandler(
      this.t('addEmployeeGroup'),
      <EmployeeGroupAddForm
        employeeGroups={this.state.employeeGroups}
        onSave={this.onSaveUpdateList}
        onCancel={() => this.props.toggleModalForm()}
      />
    );
  };

  render() {
    const employeeGroup = this.state.employeeGroups;
    // Colum Settings for the Dynamic Table
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('employeeGroup'),
        accessor: 'name',
        show: 'true',
        link: '/settings/permissions/employee-group-details/',
        linkAccessor: 'groupId',
      },
      {
        type: 'data',
        header: this.t('description'),
        accessor: 'description',
        show: 'true',
      },
      {
        type: 'Dropdown',
        header: this.t('menu'),
        show: 'true',
        accessor: 'menuComponent',
      },
    ];
    return (
      <Container fluid>
        <CardHeader>
          <div className="card-actions float-end">
            <Button
              color="primary"
              className="float-end"
              size="m"
              onClick={() => this.addEmployeeGroup()}
            >
              {this.t('addEmployeeGroup')}
            </Button>
          </div>
        </CardHeader>
        <br />
        <CardBody>
          {!isEmpty(employeeGroup) ? (
            <DynamicTable
              data={this.prepareTabledata(
                sortByPropValue(employeeGroup, 'name') as IEmployeeGroup[]
              )}
              columns={preparedColumns}
            />
          ) : (
            <Label className="text-center" tag="h4">
              {this.t('employeeGroupEmpty')}
            </Label>
          )}
        </CardBody>
      </Container>
    );
  }
}

export default withModals(EmployeeGroup);
