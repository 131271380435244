import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';

import axios from '../../../services/axios/axios';
import { CONNECTION_DIRECT, NO_ID } from '../../../utils/constants';
import { SUBJECT_TYPE } from '../../../utils/enums/employee';
import { handleError } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import {
  IDropdownOption,
  IErrorMessage,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import {
  IEmployeeGroup,
  IEmployeeWithRelationship,
  IRoleGroup,
  ISubject2Role,
} from '../../../utils/types/modelTypes';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import AddEmployeeModal from './AddEmployeeModal';
import EmployeeTable from './EmployeeTable';
import { getEmployeeNames } from '../../../services/api/employee';
import { objectNameAndIdToDropdownOptions } from '../../../utils/helpers/dropdown';

// !-- Used by EmployeeGroupDetail, RoleDetail, RoleGroupDetail

interface IProps {
  employees: IEmployeeWithRelationship[];
  cardTitle?: string;
  buttonName?: string;
  displayType: string;
  roleOrGroupId?: number;
  employeeGroups?: IEmployeeGroup[];
  roleGroups?: IRoleGroup[];
}

interface IState {
  employees: IEmployeeWithRelationship[];
  employeeOptions: IDropdownOption<number>[];
  employeeGroups: IEmployeeGroup[];
  roleGroups: IRoleGroup[];
  showAddEmployeeModal: boolean;
  showDeleteEmployeeModal: boolean;
  showModalForm: boolean;
  showModalError: boolean;
  showModalDelete: boolean;
  modalForm: JSX.Element | null;
}

/**
 * Class that shows the Employee Card
 */
class EmployeeCard extends React.Component<IProps, IState> {
  error: IErrorMessage = {} as IErrorMessage;

  deleteEmployeeEvent = async () => {
    // Empty function default
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      employees: props?.employees ?? [],
      employeeOptions: [],
      employeeGroups: props?.employeeGroups ?? [],
      roleGroups: props?.roleGroups ?? [],
      showAddEmployeeModal: false,
      showDeleteEmployeeModal: false,
      showModalForm: false,
      showModalError: false,
      showModalDelete: false,
      modalForm: null,
    };
  }

  async componentDidMount() {
    // Get all of the Employees; for the Dropdown Options
    await getEmployeeNames({})
      .then((response: AxiosResponse<IObjectNameAndId[]>) => {
        this.setState({
          employeeOptions: objectNameAndIdToDropdownOptions(response.data),
        });
      })
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveEmployees');
        this.handleError(mainError, error);
      });
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.employees !== this.props.employees) {
      this.setState({
        employees: this.props.employees,
      });
    }

    if (prevProps.roleGroups !== this.props.roleGroups) {
      this.setState({
        roleGroups: this.props.roleGroups ?? [],
      });
    }

    if (prevProps.employeeGroups !== this.props.employeeGroups) {
      this.setState({
        employeeGroups: this.props.employeeGroups ?? [],
      });
    }
  }

  t(keyName: string) {
    return i18n.t(`EmployeeCard.${keyName}`);
  }

  handleError = (mainError: string, errorObject: AxiosError) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalForm = () => {
    this.setState({ showModalForm: !this.state.showModalForm });
  };

  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  toggleModalDelete = () => {
    this.setState({ showModalDelete: !this.state.showModalDelete });
  };

  /**
   * Method that shows the Add Employee Modal
   */
  addEmployee = () => {
    // Filter the employees that are directly connected
    const employeesDirectlyConnected = this.state.employees.filter(
      (employee) => employee.relationship === CONNECTION_DIRECT
    );
    // Filter out the employees that is already selected
    const employeeOptions = this.state?.employeeOptions.filter(
      (employee) =>
        !employeesDirectlyConnected.some(
          (employeeWithRole) =>
            employeeWithRole?.employee.id === employee.value ??
            (NO_ID as number)
        )
    );
    this.setState({
      modalForm: (
        <AddEmployeeModal
          employeeOptions={employeeOptions}
          displayType={this.props.displayType}
          roleOrGroupId={this.props?.roleOrGroupId ?? (NO_ID as number)}
          onSave={this.onSaveEmployee}
          onCancel={this.toggleModalForm}
        />
      ),
    });
    this.toggleModalForm();
  };

  /**
   * Method that handles the changes on the frontend and employee dropdown options after saving an employee
   * @param employeeToAddId Id of the employee
   */
  onSaveEmployee = async (employeeToAddId: number) => {
    if (employeeToAddId === NO_ID) return;

    this.toggleModalForm();

    // Get the latest list of employees on save; to reflect the changes on frontend
    await axios.employee
      .get(
        `employee-tree/${this.props.displayType}/${
          this.props.roleOrGroupId ?? (NO_ID as number)
        }`
      )
      .then((response: AxiosResponse<IEmployeeWithRelationship[]>) => {
        this.setState({ employees: response.data });
      })
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveEmployees');
        this.handleError(mainError, error);
      });
  };

  /**
   * Method that handles the deletion of employee
   * @param employeeToDeleteId The id of the employee
   */
  deleteEmployee = (employeeToDeleteId: number) => {
    if (employeeToDeleteId === NO_ID) return;
    this.toggleModalDelete();
    this.deleteEmployeeEvent = async () => {
      // Filter out the employees that are Directly Linked and find the Employee that is set to be deleted
      const employeeToBeDeleted = this.state.employees
        .filter((employee) => employee.relationship === CONNECTION_DIRECT)
        .find((employee) => employee.primaryObjectId === employeeToDeleteId);

      if (this.props.displayType === SUBJECT_TYPE.employeeGroup.code) {
        // Remove employee from employee group
        await axios.employee
          .post(
            `employee-groups/${
              this.props.roleOrGroupId ?? (NO_ID as number)
            }/remove-employee/${employeeToDeleteId}`
          )
          .then(() => {
            const newEmployees = this.state.employees.filter(
              (employee) => employee !== employeeToBeDeleted
            );
            this.setState({ employees: newEmployees });
          })
          .catch((error: AxiosError) => {
            const mainError = this.t('failedToDeleteEmployee');
            this.handleError(mainError, error);
          });
      } else {
        // Get the link or subject2role between employee and role or role group using Employee Id and Role Id
        const subjectEmployeeToDelete =
          (await axios.employee
            .get(
              `subject-2-roles?subjectId.equals=${employeeToDeleteId}&roleId.equals=${
                this.props.roleOrGroupId ?? (NO_ID as number)
              }`
            )
            .then((res: AxiosResponse<ISubject2Role[]>) => res.data)
            .catch((error: AxiosError) => {
              const mainError = this.t('failedToRetrieveEmployee');
              this.handleError(mainError, error);
            })) ?? [];

        // Delete the role or rolegroup of the employee
        await axios.employee
          .delete(
            `subject-2-roles/${
              subjectEmployeeToDelete[0]?.id ?? (NO_ID as number)
            }`
          )
          .then(() => {
            const newEmployees = this.state.employees.filter(
              (employee) => employee !== employeeToBeDeleted
            );
            this.setState({ employees: newEmployees });
          })
          .catch((error: AxiosError) => {
            const mainError = this.t('failedToDeleteEmployee');
            this.handleError(mainError, error);
          });
      }
    };
  };

  render() {
    return (
      <Container fluid>
        <Card>
          <CardHeader>
            <h1>{this.props?.cardTitle ?? this.t('cardTitle')}</h1>
            <div className="card-actions float-end">
              <Button
                color="primary"
                className="float-end"
                size="m"
                onClick={() => this.addEmployee()}
              >
                {this.props?.buttonName ?? this.t('addEmployee')}
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <EmployeeTable
              employees={this.state.employees}
              removeEmployee={this.deleteEmployee}
              displayType={this.props.displayType}
              employeeGroups={this.state.employeeGroups}
              roleGroups={this.state.roleGroups}
            />
          </CardBody>
        </Card>
        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleModalForm}
          ref={this.state.modalForm}
          modalTitle={this.t('addEmployee')}
        >
          {this.state.modalForm}
        </ModalForm>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={this.t('error')}
        />
        <ModalDelete
          isOpen={this.state.showModalDelete}
          event={this.deleteEmployeeEvent}
          onClose={this.toggleModalDelete}
          modalTitle={this.t('deleteEmployee')}
          modalBodyText={this.t('confirmDeleteEmployee')}
        />
      </Container>
    );
  }
}

export default EmployeeCard;
