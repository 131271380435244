import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Container } from 'reactstrap';
import axios from '../../../services/axios/axios';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import { ProjectEmployeeWorkloadState } from '../../../utils/enums/project';
import { dataGeneration } from '../../../utils/constants';
import {
  errorHandler,
  getDateFormat,
} from '../../../utils/helpers/GenericHelper';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import ModalOK from '../../../components/modals/ModalOK';
import AssignResourceModal from '../../../components/form/AssignResourceForm/AssignResourceModal';
import ResourceProfileCard from './ResourceProfileCard';
import ResourceWorkloadCard from './ResourceWorkloadCard';
import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';

/**
 *
 */
class ResourceProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectEmployees: [],
      projectIds: [],
      showModalError: false,
      workloads: [],
      stateOptions: [],
      projectEmployeeWorkloads: [],
      dateFormat: getDateFormat(),
      currentUserIsResponsible: false,
    };

    this.ModalEvent = null;
    this.roleDeleteEvent = null;
    this.groupDeleteEvent = null;
  }

  t(keyName) {
    return i18n.t('ResourceProfileCard.' + keyName);
  }

  async componentDidMount() {
    // gets the states as options
    var stateOptions = enumValuesToDropdownOptions(
      Object.values(ProjectEmployeeWorkloadState),
      'WorkloadState'
    );
    this.setState({
      stateOptions: stateOptions,
    });

    let projectEmployeeCount = await axios.project
      .get('project-employees/count')
      .then((res) => res.data)
      .catch((error) => {
        this.message = errorHandler(
          dataGeneration,
          error,
          this.t('projectEmployee')
        );
        this.toggleModalError();
      });
    if (projectEmployeeCount === undefined) return;
    // Get all projectWorkloads of the selected employee.
    // Get unique project entry from project-employees.
    await axios.project
      .get(`project-employees?employeeId.equals=${this.props.match.params.id}`)
      .then((response) => {
        var workloads = [];
        if (response.data instanceof Object) {
          const projectEmployees = response.data;
          let projectEmployeeWorkloads = [];
          let projectIds = [];
          if (Array.isArray(projectEmployees) && projectEmployees.length > 0) {
            // Call function that returns list of projectWorkload entry
            projectEmployees.forEach((projectEmployee) => {
              projectIds.push(projectEmployee.project.id);
              projectEmployeeWorkloads.push(
                this.getProjectEmployeeWorkloads(projectEmployee)
              );
            });
          }
          this.setState({
            workloads,
            projectEmployees: response.data,
            projectEmployeeWorkloads,
            projectIds: projectIds,
          });
        }
      })
      .catch((error) => {
        this.message = errorHandler(
          dataGeneration,
          error,
          this.t('projectEmployee')
        );
        this.toggleModalError();
      });

    const currentUserData = this.props.account.employeeDetails;

    if (currentUserData) {
      const currentUserId = currentUserData.id;
      const employeeIdToView = this.props.match.params.id;
      const currentUserIsResponsible = await axios.sales
        .get(
          `responsibles?objectType.equals=${OBJECT_TYPE_ENUM.employee.code}&employeeId.equals=${currentUserId}&objectId.equals=${employeeIdToView}`
        )
        .then((response) => {
          return Array.isArray(response.data) && response.data.length > 0
            ? true
            : false;
        })
        .catch((error) => {
          this.errorHandler(dataGeneration, error);
        });

      this.setState({
        currentUserIsResponsible,
      });
    }
  }

  onSave = () => {
    this.toggleModalForm();
    this.componentDidMount();
  };

  getProjectEmployeeWorkloads = (projectEmployee) => {
    let projectEmployeeWorkloads = [];
    const workloads = projectEmployee.projectEmployeeWorkloads;

    if (Array.isArray(workloads) && workloads.length > 0) {
      workloads.forEach((workload) => {
        let workloadEntry = {
          project: projectEmployee.project?.title,
          projectId: projectEmployee.project?.id,
          workload: workload,
        };

        projectEmployeeWorkloads.push(workloadEntry);
      });

      return projectEmployeeWorkloads;
    }

    return [];
  };

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  toggleModalForm = () => {
    this.setState({ showModalForm: !this.state.showModalForm });
  };

  assignEmployee = (employee) => {
    this.modalTitle = 'Assign Resource';
    const assignResourceModalParam = {
      fixedEmployee: {
        id: employee.id ?? 0,
        name: `${employee.name} ${employee.firstname}`,
      },
    };
    // Lock employee, keep everything else unlocked
    this.setState({
      modalForm: (
        <AssignResourceModal
          {...assignResourceModalParam}
          onSave={this.onSave}
        />
      ),
    });
    this.toggleModalForm();
  };

  render() {
    const employeeWorkloads = this.state.projectEmployeeWorkloads;
    let mergedWorkloads = [].concat.apply([], employeeWorkloads);

    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{this.t('resourceProfile')}</HeaderTitle>
          {generateBreadcrumb(
            this.props.location.pathname,
            this.t('employees')
          )}
        </Header>
        <>
          <ResourceProfileCard
            projectEmployees={this.state.projectEmployees}
            currentUserIsResponsible={this.state.currentUserIsResponsible}
            assignEmployee={this.assignEmployee}
          />
          <Card className="flex-fill w-100">
            <ResourceWorkloadCard employeeWorkloads={mergedWorkloads} />
          </Card>
        </>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.message?.mainError}
          errorReason={this.message?.errorReason}
          errorResponse={this.message?.errorResponse}
          modalTitle={this.t('error')}
        ></ModalError>
        <ModalOK
          isOpen={this.state.showModalOk}
          onClose={this.ToggleModalOk}
          modalBodyText={this.state.bodyText}
          modalTitle={this.state.title}
        ></ModalOK>
        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleModalForm}
          modalTitle={this.modalTitle}
          size="lg"
        >
          {this.state.modalForm}
        </ModalForm>
      </Container>
    );
  }
}

const mapStateToProps = (store) => ({
  account: store.account,
});

export default connect(mapStateToProps)(withRouter(ResourceProfile));
