import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError, AxiosResponse } from 'axios';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import Auth from '../../../services/axios/Auth';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { enumToDropdownOptions, isEmpty } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import { READWRITE } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import { DEPARTMENT_STATUS_ENUM } from '../../../utils/enums/department';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import {
  IDepartment,
  IEmployee,
  IResponsible,
} from '../../../utils/types/modelTypes';
import DepartmentAddOrUpdate from './DepartmentAddOrUpdate';
import { Ownership } from '../../../utils/enums/ownership';

interface IProps extends IWithModalsProps {}

interface IState {
  departments: IDepartment[];
  showArchived: boolean;
  responsibleEmployees: IEmployee[];
  responsibles: IResponsible[];
}

interface IDepartmentTableData {
  id: number | null;
  title: JSX.Element | string;
  fieldOfBusiness: string;
  abbreviation: string | undefined;
  departmentId: string | undefined;
  responsible: ReactNode;
  status: string | undefined;
  menu: JSX.Element | null;
}

/**
 * Class that handles the view, add, and removal of Departments
 * Also handles the activation or archiving of Departments
 */
class DepartmentListView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      departments: [],
      showArchived: false,
      responsibleEmployees: [],
      responsibles: [],
    };
  }

  t(keyName: string) {
    return i18n.t(`DepartmentListView.${keyName}`);
  }

  async componentDidMount() {
    // Get all departments
    axios.project
      .get('departments')
      .then((response: AxiosResponse<IDepartment[]>) => {
        if (!isEmpty(response?.data)) {
          const departmentsData: IDepartment[] = response.data.sort(
            (a: IDepartment, b: IDepartment) => (a?.title > b?.title ? 1 : -1)
          );
          this.setState({ departments: departmentsData });
        }
      })
      .catch((error: AxiosError) => {
        this.props.modalErrorHandler(this.t('failedToGetDepartments'), error);
      });

    // Get the Responsibles
    const responsibles: IResponsible[] = await axios.project
      .get(
        `responsibles?objectType.equals=${OBJECT_TYPE_ENUM.department.code}&ownership.in=${Ownership.RESPONSIBLE}`
      )
      .then((response: AxiosResponse<IResponsible[]>) => response.data)
      .catch((error: AxiosError) => {
        this.props.modalErrorHandler(
          this.t('failedToRetrieveResponsibles'),
          error
        );
        return [] as IResponsible[];
      });

    const responsibleEmployeeIds = [
      ...new Set(
        responsibles?.map((responsible: IResponsible) => responsible.employeeId)
      ),
    ];

    // Get all responsible employees
    const responsibleEmployees: IEmployee[] =
      responsibleEmployeeIds &&
      (await axios.project
        .get(`employees?id.in=${responsibleEmployeeIds.join(',')}`)
        .then((response: AxiosResponse<IEmployee[]>) => response.data)
        .catch((error: AxiosError) => {
          this.props.modalErrorHandler(
            this.t('failedToRetrieveEmployees'),
            error
          );
          return [] as IEmployee[];
        }));

    this.setState({
      responsibles: responsibles ?? [],
      responsibleEmployees: responsibleEmployees ?? [],
    });
  }

  toggleShowArchived = () => {
    this.setState({ showArchived: !this.state.showArchived });
  };

  /**
   * Method that handles the add or update of a Department
   * @param {*} department
   * @param {*} isUpdate
   */
  addOrUpdateDepartment = (
    responsible?: IResponsible,
    department?: IDepartment,
    isUpdate?: boolean
  ) => {
    const modalTitle = isUpdate ? this.t('update') : this.t('add');

    this.props.modalFormHandler(
      modalTitle,
      <DepartmentAddOrUpdate
        responsible={responsible ?? ({} as IResponsible)}
        department={department ?? ({} as IDepartment)}
        departmentId={department?.departmentId ?? null}
        onSave={this.onSave}
      />
    );
  };

  /**
   * Method that reflects the save of department and responsible on the frontend
   * @param {*} department
   */
  onSave = async () => {
    await this.componentDidMount();
    this.props.toggleModalForm();
  };

  /**
   * Method that deletes a department and responsible on both frontend and backend
   * @param {*} departmentToDelete
   */
  deleteDepartment = (
    departmentToDelete: IDepartment,
    responsibleToDelete?: IResponsible
  ) => {
    if (
      departmentToDelete.status === DEPARTMENT_STATUS_ENUM.active.code ||
      departmentToDelete.status === DEPARTMENT_STATUS_ENUM.archived.code
    ) {
      return;
    }

    const formattedWarningText = (
      <>
        <h1 style={{ color: 'red' }}>{this.t('warning')}</h1>
        <p>{this.t('initialWarning')}</p>
        <ul>
          <li>{this.t('linkedProjects')}</li>
        </ul>
        <p>{this.t('proceed')}</p>
      </>
    );

    if (departmentToDelete?.id) {
      this.props.modalDeleteHandler(
        this.t('deleteDepartment'),
        formattedWarningText,
        async () => {
          await axios.project
            .delete(`departments/${departmentToDelete.id}`)
            .then(() => {
              const newDepartments = this.state.departments.filter(
                (department) => department.id !== departmentToDelete.id
              );
              this.setState({ departments: newDepartments });
            })
            .catch((error: AxiosError) => {
              this.props.modalErrorHandler(
                this.t('failedToDeleteDepartment'),
                error
              );
            });

          if (responsibleToDelete) {
            await this.deleteResponsible(responsibleToDelete);
          }
        }
      );
    }
  };

  /**
   * Deletes the responsible
   * @param responsibleToDelete
   */
  deleteResponsible = async (responsibleToDelete: IResponsible) => {
    if (responsibleToDelete?.id) {
      await axios.project
        .delete(`responsibles/${responsibleToDelete?.id}`)
        .then(() => {
          const newResponsibles = this.state.responsibles.filter(
            (responsible) => responsible.id !== responsibleToDelete.id
          );
          this.setState({ responsibles: newResponsibles });
        })
        .catch((error: AxiosError) => {
          this.props.modalErrorHandler(
            this.t('failedToDeleteResponsible'),
            error
          );
        });
    }
  };

  /**
   * Method that changes the status of a department to Archived
   * @param {*} departmentToChange
   * @param {*} responsibleToDelete
   */
  archiveDepartment = (
    departmentToChange: IDepartment,
    responsibleToDelete?: IResponsible
  ) => {
    const department = departmentToChange;

    this.props.modalConfirmHandler(
      this.t('confirmation'),
      this.t('confirmArchive'),
      async () => {
        if (department?.id) {
          // Change the current status of the Department to Archive
          department.status = DEPARTMENT_STATUS_ENUM.archived.code;
          await axios.project
            .save('departments', department)
            .then(() => {
              // Delete the reponsible of the department that was Archived for the frontend
              if (responsibleToDelete?.id) {
                const newResponsibles = this.state.responsibles.filter(
                  (responsible) => responsible.id !== responsibleToDelete.id
                );
                this.setState({ responsibles: newResponsibles });
              }
            })
            .catch((error: AxiosError) => {
              this.props.modalErrorHandler(this.t('archiveFailed'), error);
            });
        }
      }
    );
  };

  /**
   * Method that changes the status of a department to Active
   * @param {*} departmentToChange
   */
  activateDepartment = (departmentToChange: IDepartment) => {
    const department = departmentToChange;

    this.props.modalConfirmHandler(
      this.t('confirmation'),
      this.t('confirmActivate'),
      async () => {
        if (department?.id) {
          // Change the current status of the Department to Active
          department.status = DEPARTMENT_STATUS_ENUM.active.code;
          await axios.project
            .save('departments', department)
            .catch((error: AxiosError) => {
              this.props.modalErrorHandler(this.t('activateFailed'), error);
            });
        }
      }
    );
  };

  preparedTableData = (
    departments: IDepartment[],
    userHasPermission: boolean
  ) => {
    const isUpdate = true;
    const newTableData: IDepartmentTableData[] = [] as IDepartmentTableData[];

    if (!isEmpty(departments)) {
      departments.forEach((department) => {
        const responsible: IResponsible =
          this.state.responsibles?.find(
            (responsible: IResponsible) =>
              responsible?.objectId === department?.id
          ) ?? ({} as IResponsible);
        const responsibleEmployee: IEmployee =
          this.state.responsibleEmployees.find(
            (employee: IEmployee) => employee?.id === responsible?.employeeId
          ) ?? ({} as IEmployee);
        const entry: IDepartmentTableData = {
          id: department?.id ?? null,
          title: userHasPermission ? (
            <Link
              to="#"
              onClick={() =>
                this.addOrUpdateDepartment(responsible, department, isUpdate)
              }
            >
              {department.title}
            </Link>
          ) : (
            department.title
          ),
          fieldOfBusiness: department?.fieldOfBusiness?.title ?? '',
          abbreviation: department?.abbreviation,
          departmentId: department?.departmentId,
          responsible: (
            <Link
              to={`/employees/employee-list/employee-detail/${
                responsibleEmployee?.id ?? -1
              }`}
            >
              {`${responsibleEmployee?.firstname ?? ''} ${
                responsibleEmployee?.name ?? ''
              }`}
            </Link>
          ),
          status: enumToDropdownOptions(DEPARTMENT_STATUS_ENUM).find(
            (option: IDropdownOption) => option.value === department?.status
          )?.label as string,
          menu: userHasPermission ? (
            <>
              <div
                className="card-actions float-end"
                style={{ paddingRight: '5px' }}
              >
                {department?.status === DEPARTMENT_STATUS_ENUM.planned.code && (
                  <Button
                    color="primary"
                    onClick={() =>
                      this.deleteDepartment(department, responsible)
                    }
                  >
                    {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
                  </Button>
                )}
              </div>
              {department?.status !== DEPARTMENT_STATUS_ENUM.archived.code && (
                <div
                  className="card-actions float-end"
                  style={{ paddingRight: '5px' }}
                >
                  <Button
                    color="primary"
                    onClick={() =>
                      this.archiveDepartment(department, responsible)
                    }
                  >
                    {this.t('archive')}
                  </Button>
                </div>
              )}
              {department?.status !== DEPARTMENT_STATUS_ENUM.active.code && (
                <div
                  className="card-actions float-end"
                  style={{ paddingRight: '5px' }}
                >
                  <Button
                    color="primary"
                    onClick={() => this.activateDepartment(department)}
                  >
                    {this.t('activate')}
                  </Button>
                </div>
              )}
            </>
          ) : null,
        };
        newTableData.push(entry);
      });
      return this.state.showArchived
        ? newTableData
        : newTableData.filter(
            (department: IDepartmentTableData) =>
              department?.status !== DEPARTMENT_STATUS_ENUM.archived.code
          );
    }
    return [];
  };

  render() {
    const { departments } = this.state;
    const { showArchived } = this.state;
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('title'),
        accessor: 'title',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('fieldOfBusiness'),
        accessor: 'fieldOfBusiness',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('abbreviation'),
        accessor: 'abbreviation',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('departmentId'),
        accessor: 'departmentId',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('responsible'),
        accessor: 'responsible',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('status'),
        accessor: 'status',
        show: 'true',
      },
      {
        type: 'component',
        header: '',
        accessor: 'menu',
        show: 'true',
      },
    ];
    const userHasPermission = Auth.hasPermission(
      [PERMISSION_URI.manageDepartments.readWrite.uri],
      [READWRITE]
    );
    return (
      <Container fluid>
        <div>
          {userHasPermission && (
            <Button
              color="primary"
              className="float-end mx-1"
              onClick={() => this.addOrUpdateDepartment()}
            >
              <FontAwesomeIcon icon={faPlus} /> {this.t('add')}
            </Button>
          )}
          <Button
            color="primary"
            className="float-end"
            onClick={this.toggleShowArchived}
          >
            <FontAwesomeIcon
              icon={showArchived ? faEyeSlash : faEye}
              className="margin-right"
            />

            {` ${
              showArchived ? this.t('hideArchived') : this.t('showArchived')
            }`}
          </Button>
          <br />
          <br />
        </div>

        <DynamicTable
          data={this.preparedTableData(departments, userHasPermission)}
          columns={preparedColumns}
          infiniteScroll
        />
      </Container>
    );
  }
}

export default withModals(DepartmentListView);
