import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { AxiosError } from 'axios';
import moment from 'moment';

import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  IOfferDetails,
  IOfferListItem,
  IProjectOptionDetails,
} from '../../../utils/types/responseTypes';
import { generateTitle } from '../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import OfferDetailsProvider from '../../projects/OfferModal/OfferDetailsProvider';
import OfferModal from '../../projects/OfferModal/OfferModal';
import { IProjectDetailOffer } from '../../../utils/types/commonTypes';
import { deleteOffer, getOfferDetails } from '../../../services/api/offer';
import {
  DATE_FORMAT,
  generateOfferTitle,
} from '../../projects/OfferModal/offerHelper';
import AddOfferVersion from '../../../components/form/AddOfferVersionForm/AddOfferVersion';
import { OfferState } from '../../../utils/enums/offer';
import TableCard from '../../../components/cards/TableCard';
import { t } from './projectOptionHelpers';
import type { ProjectOptionDetailsForm } from './ProjectOptionModal';

interface IProps extends IWithModalsProps {
  project: IProjectOptionDetails;
  formValues: ProjectOptionDetailsForm;
  setFormValues: Dispatch<SetStateAction<ProjectOptionDetailsForm>>;
}

const ProjectOptionOffers = ({
  project,
  formValues: { offers },
  setFormValues,
  modalFormHandler,
  toggleModalForm,
  modalErrorHandler,
  modalConfirmHandler,
  modalOkHandler,
}: IProps) => {
  const {
    id,
    title,
    customerSite,
    customer,
    state,
    projectSharepoint,
    responsible,
    involvedResponsibles,
    projectId,
    departments,
    description,
    responsibleContactPersons,
  } = project;

  const toggleOfferModal = (modalTitle: string, offer?: IOfferDetails) => {
    const offerProject = {
      id,
      projectTitle: title,
      customerSite,
      customer,
      state,
      sharepointLink: projectSharepoint,
      responsible,
      involved: involvedResponsibles,
      projectId,
      departments,
      description,
      contactPerson: responsibleContactPersons,
    } as IProjectDetailOffer;

    modalFormHandler(
      modalTitle,
      <OfferDetailsProvider
        offerProject={offerProject}
        formOffers={offers}
        setOptionFormValues={setFormValues}
        fromOptions
      >
        <OfferModal onClose={toggleModalForm} offerId={offer && offer.id} />
      </OfferDetailsProvider>,
      'xl'
    );
  };

  const handleAddOfferModal = () => {
    toggleOfferModal(t('addOffer'));
  };

  const handleOfferModal = (offerId: number) => {
    getOfferDetails(offerId)
      .then(({ data: response }) =>
        toggleOfferModal(
          generateOfferTitle(response.title, response.offerVersion),
          response
        )
      )
      .catch((error: AxiosError) =>
        modalErrorHandler(t('failedToRetrieveOffer'), error.message)
      );
  };

  const handleSendButton = (offer: IOfferListItem) => {
    modalFormHandler(
      `${t('send')} ${generateOfferTitle(offer.title, offer.offerVersion)}`,
      <AddOfferVersion offerFromOption={offer} onClose={toggleModalForm} />,
      'lg'
    );
  };

  const deleteOfferAction = ({ id, title, offerVersion }: IOfferListItem) => {
    deleteOffer(id)
      .then(() => {
        modalOkHandler(
          t('deleteOffer'),
          `${t('offerDeleted')} ${generateOfferTitle(title, offerVersion)}`
        );
      })
      .catch((error: AxiosError) =>
        modalErrorHandler(t('failedToDeleteOffer'), error.message)
      );
  };

  const handleDeleteButton = (offer: IOfferListItem) => {
    modalConfirmHandler(t('deleteOffer'), t('deleteOfferConfirm'), () => {
      deleteOfferAction(offer);
    });
  };

  const headerButtons = (
    <Button onClick={() => handleAddOfferModal()} color="primary">
      {generateTitle(BUTTON_TITLE_ENUM.ADD.code, t('add'))}
    </Button>
  );

  const highlightedRows: number[] = [];

  const tableData = offers.map((offer, i) => {
    const {
      id: offerId,
      title,
      contractType,
      currency,
      budget,
      offerSent,
      offerState,
    } = offer;

    if (
      offer.offerState === OfferState.DECLINED ||
      offer.offerState === OfferState.EXPIRED ||
      offer.offerState === OfferState.CANCELED
    ) {
      highlightedRows.push(i);
    }

    return {
      [t('offerTitle')]: (
        <Link
          to="#"
          className="wordWrap-class"
          onClick={() => {
            handleOfferModal(offerId);
          }}
        >
          {title}
        </Link>
      ),
      [t('offerType')]: contractType,
      [t('offerBudget')]: `${currency} ${budget}`,
      [t('offerDateSent')]: `${
        moment(offerSent).format(DATE_FORMAT) || t('notSent')
      }`,
      [t('offerStatus')]: offerState,
      [t('offerSend')]:
        offerState === OfferState.VALID ? (
          <Button color="primary" onClick={() => handleSendButton(offer)}>
            {generateTitle(BUTTON_TITLE_ENUM.SEND.code)}
          </Button>
        ) : (
          ''
        ),
      [t('delete')]: (
        <Button color="primary" onClick={() => handleDeleteButton(offer)}>
          {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
        </Button>
      ),
    };
  });

  return (
    <TableCard
      title={t('offers')}
      tableData={tableData}
      highlightedRows={highlightedRows}
      headerButtons={headerButtons}
      noDataPlaceholder={t('noOffers')}
      border
    />
  );
};

export default withModals(ProjectOptionOffers);
