import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { sortByPropValue } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import ErrorFunction from './ErrorFunction';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalForm from '../../../components/modals/ModalForm';
import ModalOK from '../../../components/modals/ModalOK';
import DeclineReasonInputForm from './DeclineReasonInputForm';

//This class is for generating the list of decline-reasons available in the sales settings
class DeclineReasonList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      declineReason: [],
      id: null,
      showModalOK: false,
      showModalDelete: false,
      errorMessage: '',

      showInputForm: false,
      modalForm: null,
      modalTitle: '',
    };

    this.translation = this.props.t;

    this.ModalEvent = null;
    this.handleRemove = this.handleRemove.bind(this);
    this.ToggleModalDelete = this.ToggleModalDelete.bind(this);
    this.ToggleModalOK = this.ToggleModalOK.bind(this);

    this.showInputModal = this.showInputModal.bind(this);
    this.toggleInputModal = this.toggleInputModal.bind(this);
  }

  //Toggle for showing the modal for deleting decline-reasons.
  ToggleModalDelete() {
    this.setState({ showModalDelete: !this.state.showModalDelete });
  }

  //Toggle for showing the modal for error popup.
  ToggleModalOK() {
    this.setState({ showModalOK: !this.state.showModalOK });
  }

  toggleInputModal() {
    this.setState({ showInputForm: !this.state.showInputForm });
  }

  showInputModal = () => {
    this.setState({
      modalForm: <DeclineReasonInputForm onSave={this.onSaveType} />,
      modalTitle: this.translation('DeclineReasonList.addDeclineReason'),
    });
    this.toggleInputModal();
  };

  onSaveType = () => {
    this.toggleInputModal();
    this.componentDidMount();
  };

  //This method is for deleting the chosen declineReason
  handleRemove = (declineReason) => {
    this.ToggleModalDelete();
    this.ModalEvent = () =>
      axios.sales
        .delete(`decline-reasons/${declineReason.id}`)
        .then((response) => {
          let newDeclineReason = this.state.declineReason.filter((x) => {
            return x.id !== declineReason.id;
          });
          this.setState({ declineReason: newDeclineReason });
        })
        .catch((error) => {
          let pathname = '/settings/sales/';
          let state = {
            errorMessage:
              this.translation('DeclineReasonList.failedToLoadDelete') +
              declineReason.id +
              error.toString(),
          };
          ErrorFunction(this.props, pathname, state);
        });
  };

  //Commands to be executed when generating the declineReason list page for the first time.
  componentDidMount() {
    axios.sales
      .get('decline-reasons')
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({
            declineReason: response.data,
          });
        }
      })
      .catch((error) => {
        let pathname = '/settings/sales/';
        let state = {
          errorMessage:
            this.translation('DeclineReasonList.failedToLoadDecline-Reasons') +
            error.toString(),
        };
        ErrorFunction(this.props, pathname, state);
      });
  }

  preparedTableData = (salesData) => {
    const newTableData = [];
    if (Array.isArray(salesData) && salesData.length > 0) {
      salesData.forEach((declineReason) => {
        const entry = {
          id: declineReason?.id,
          reason: declineReason?.reason,
          deleteButton: (
            <>
              <Button
                color="primary"
                onClick={() => this.handleRemove(declineReason)}
              >
                {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
              </Button>
            </>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  render() {
    const preparedColumns = [
      {
        type: 'data',
        header: this.translation('DeclineReasonList.reason'),
        accessor: 'reason',
        show: 'true',
        link: '/settings/sales/decline-reason-update/',
        linkAccessor: 'id',
      },
      {
        type: 'data',
        header: this.translation('DeclineReasonList.delete'),
        accessor: 'deleteButton',
        show: 'true',
        alignRight: 'true',
      },
    ];
    return (
      <Container fluid>
        <div>
          <Button
            color="primary"
            className="float-end"
            onClick={() => this.showInputModal()}
          >
            {generateTitle(
              BUTTON_TITLE_ENUM.ADD.code,
              this.translation('DeclineReasonList.add')
            )}
          </Button>
          <br />
          <br />
        </div>

        <DynamicTable
          data={this.preparedTableData(
            sortByPropValue(this.state.declineReason, 'reason')
          )}
          columns={preparedColumns}
        />

        <ModalDelete
          isOpen={this.state.showModalDelete}
          onClose={this.ToggleModalDelete}
          event={this.ModalEvent}
          modalTitle={this.translation('DeclineReasonList.deleteDeclineReason')}
          modalBodyText={this.translation(
            'DeclineReasonList.deleteDeclineReasonConfirm'
          )}
        ></ModalDelete>
        <ModalOK
          isOpen={this.state.showModalOK}
          onClose={this.ToggleModalOK}
          modalBodyText={this.state.errorMessage}
          modalTitle="Error"
          currentPage=""
        ></ModalOK>
        <ModalForm
          isOpen={this.state.showInputForm}
          eventOnClose={this.toggleInputModal}
          ref={this.state.modalForm}
          modalTitle={this.state.modalTitle}
        >
          {this.state.modalForm}
        </ModalForm>
      </Container>
    );
  }
}

export default withRouter(withTranslation()(DeclineReasonList));
