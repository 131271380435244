import { PayloadAction } from '@reduxjs/toolkit';

import {
  getCustomerListItems,
  deleteCustomer as deleteCustomerItem,
  mergeCustomers,
  getCustomerAccountDropdown,
} from '../services/api/customer';
import { getEmployeeNames, getListOfResponsibleNames } from '../services/api/employee';
import { Ownership } from '../utils/enums/ownership';
import { EntityType } from '../utils/enums/pageComponents';
import {
  AccessType,
  PERMISSION_URI,
} from '../utils/enums/permission';
import { SortBy, SortType } from '../utils/enums/sort';
import { maxPageLength } from '../utils/constants';
import { objectNameAndIdToDropdownOptions } from '../utils/helpers/dropdown';
import { removeDuplicates } from '../utils/helpers/table';
import { createQueryParameters } from '../pages/crm/CustomerList/customerListHelpers';
import { IDropdownOption } from '../utils/types/commonTypes';
import { ICustomerListItem } from '../utils/types/responseTypes';
import { ICustomerListFilters } from '../utils/types/stateTypes';
import createListSlice, { ListState } from './createListSlice';
import type { AppThunk } from './store';

type AdditionalState = {
  responsibles: IDropdownOption<number>[];
  involvedResponsibles: IDropdownOption<number>[];
  employeesWithPermission: IDropdownOption<number>[];
  customerAccountsList: IDropdownOption<number>[];
};

type CustomerListState = ListState<
  ICustomerListItem,
  ICustomerListFilters,
  AdditionalState
>;

const initialState: CustomerListState = {
  listItems: [],
  hasMore: true,
  page: 0,
  pageSize: 0,
  pageCount: 0,
  filters: {
    name: '',
    targetPriority: [],
    state: [],
    sectors: '',
    customerSites: '',
    customerAccount: [],
    responsible: [],
    involvedResponsibles: [],
    ownership: Ownership.RESPONSIBLE,
    responsibleWithPermission: null,
  },
  showFilters: false,
  sortBy: SortBy.NAME,
  sortType: SortType.ASCENDING,
  additionalState: {
    responsibles: [],
    involvedResponsibles: [],
    employeesWithPermission: [],
    customerAccountsList: [],
  },
};

/**
 * Customer List Slice - contains the state of the Customer List page
 *
 * 1. List of customers
 * 2. Page of items to be fetched and if there is more data to fetch
 * 3. List of customer responsibles
 * 4. List of customer involved responsibles
 * 5. List of employees with customer list permission
 * 6. Applied filters
 * 7. Applied sorting criteria
 */
const customerListSlice = createListSlice({
  name: 'customerList',
  initialState,
  reducers: {
    setResponsibles: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        responsibles: [...payload],
      },
    }),
    setInvolvedResponsibles: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        involvedResponsibles: [...payload],
      },
    }),
    setEmployeesWithPermission: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        employeesWithPermission: [...payload],
      },
    }),
    setCustomerAccountsList: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...(state.additionalState as AdditionalState),
        customerAccountsList: [...payload],
      },
    }),
  },
});

export const {
  removeListItem,
  setListItems,
  setHasMoreListItems,
  setPage,
  setFilters,
  toggleFilter,
  setSortBy,
  setSortType,
  setResponsibles,
  setInvolvedResponsibles,
  setCustomerAccountsList,
  setEmployeesWithPermission,
} = customerListSlice.actions;

/**
 * Thunk for fetching a single customer list item and updating customer list slice
 * @param customerId Id of customer list item to fetch
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching a single customer list item
 */
export const fetchCustomerListItem =
  (customerId: number, errorHandler: (error: any) => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        customerList: { listItems },
      } = getState();

      const { data: fetchedListItem } = await getCustomerListItems({
        'id.equals': customerId.toString(),
      });

      dispatch(setListItems(removeDuplicates(listItems, fetchedListItem)));
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching customer list items and updating customer list slice
 * @param isSortOrFilterFetchType Whether fetching due to sort / filter / scroll update
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching customer list items
 */
export const fetchCustomerListItems =
  (
    isSortOrFilterFetchType: boolean,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        customerList: { page, filters, sortBy, sortType, listItems },
      } = getState();

      const { data: fetchedListItems } = await getCustomerListItems(
        createQueryParameters(page, filters, sortBy, sortType)
      );

      const processedListItems = isSortOrFilterFetchType
        ? [...fetchedListItems]
        : removeDuplicates(listItems, fetchedListItems);

      dispatch(
        setPage(!(fetchedListItems.length < maxPageLength) ? page + 1 : page)
      );
      dispatch(setHasMoreListItems(!(fetchedListItems.length < maxPageLength)));
      dispatch(setListItems(processedListItems));
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for deleting customer and updating customer list slice
 * @param customerId Id of customer to delete
 * @param successHandler Function for handling logic to execute upon successful customer deletion
 * @param errorHandler Function for handling errors from deleting customer
 * @returns Inner thunk function containing async logic for deleting customer
 */
export const deleteCustomer =
  (
    customerId: number,
    successHandler: () => void,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      await deleteCustomerItem(customerId);

      dispatch(removeListItem(customerId));
      successHandler();
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching customer responsibles and updating customer list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching customer responsibles
 */
export const fetchCustomerResponsibles =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedResponsibleNames } = await getListOfResponsibleNames(
        {
          'ownership.in': Ownership.RESPONSIBLE,
          'objectType.equals': EntityType.CUSTOMER.toUpperCase(),
        }
      );

      dispatch(
        setResponsibles(
          objectNameAndIdToDropdownOptions(fetchedResponsibleNames)
        )
      );
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching customer involved responsibles and updating customer list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching customer responsibles
 */
export const fetchCustomerInvolvedResponsibles =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedInvolvedResponsibleNames } =
        await getListOfResponsibleNames({
          'ownership.in': Ownership.INVOLVED,
          'objectType.equals': EntityType.CUSTOMER.toUpperCase(),
        });

      dispatch(
        setInvolvedResponsibles(
          objectNameAndIdToDropdownOptions(fetchedInvolvedResponsibleNames)
        )
      );
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching customer accounts and updating customer list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching customer responsibles
 */
export const fetchCustomerAccountListItems =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedInvolvedResponsibleNames } =
        await getCustomerAccountDropdown();

      dispatch(
        setCustomerAccountsList(
          objectNameAndIdToDropdownOptions(fetchedInvolvedResponsibleNames)
        )
      );
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching employees with customer list permission and updating project list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching employees with customer list permission
 */
export const fetchEmployeesWithCustomerListPermission =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedEmployees } = await getEmployeeNames({
        'permissionsFilter.in': PERMISSION_URI.customerList.readWrite.uri,
        'accessTypeFilter.in': AccessType.READWRITE,
      });

      dispatch(
        setEmployeesWithPermission(
          objectNameAndIdToDropdownOptions(fetchedEmployees)
        )
      );
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for merging selected customers and updating customer list slice
 * @param customerIdToKeep Id of customer to keep / merge the other customers to
 * @param selectedCustomerIds List of ids of selected customers to merge
 * @param errorHandler Function for handling errors from merging customers
 * @returns Inner thunk function containing async logic for merging customers
 */
export const mergeSelectedCustomers =
  (
    customerIdToKeep: number,
    selectedCustomerIds: number[],
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        customerList: { listItems },
      } = getState();

      const { data: updatedListItem } = await mergeCustomers(
        customerIdToKeep,
        selectedCustomerIds
      );
      const customersToRemove = selectedCustomerIds.filter(
        (id) => id !== customerIdToKeep
      );
      const updatedListItems = listItems.filter(({ id }) =>
        customersToRemove.every((idToRemove) => id !== idToRemove)
      );
      updatedListItems[
        updatedListItems.findIndex(({ id }) => id === customerIdToKeep)
      ] = updatedListItem;

      dispatch(setListItems(updatedListItems));
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for updating customer due to editing of customer or adding customer site, and updating customer list slice
 * @param customerId Id of customer to update
 * @returns Inner thunk function containing async logic for updating customer
 */
export const updateCustomerListItem =
  (customerId: number): AppThunk =>
  async (dispatch, getState) => {
    const {
      customerList: { listItems },
    } = getState();

    const {
      data: [updatedListItem],
    } = await getCustomerListItems({
      'id.equals': customerId.toString(),
    });

    const updatedListItems = [...listItems];
    if (updatedListItem) {
      updatedListItems[
        updatedListItems.findIndex(({ id }) => id === customerId)
      ] = updatedListItem;
    }

    dispatch(setListItems(updatedListItems));
  };

export default customerListSlice.reducer;
