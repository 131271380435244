import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Tooltip } from '@mui/material';
import React, { Fragment } from 'react';
import {
  Button,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import Auth from '../../../../services/axios/Auth';
import axios from '../../../../services/axios/axios';
import DynamicTable from '../../../../components/tables/DynamicTable';
import * as Constants from '../../../../utils/constants';
import { PERMISSION_URI } from '../../../../utils/enums/permission';
import { errorHandler, isEmpty } from '../../../../utils/helpers/GenericHelper';
import i18n from '../../../../i18n';
import ModalDelete from '../../../../components/modals/ModalDelete';
import ModalError from '../../../../components/modals/ModalError';

const defaultWidth = '500px';

/**
 * Class that shows available permissions
 */
class Permissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      showModalError: false,
      showModalDelete: false,
      options: [],
    };
    this.modalDeleteEvent = null;
    this.modalTitle = '';
    this.modalBodyText = '';
    this.message = '';
  }

  t(keyName) {
    return i18n.t('Permissions.' + keyName);
  }

  async componentDidMount() {
    await axios.employee
      .get(
        'permissions?subjectType.equals=' +
          this.props.subjectType +
          '&subjectId.equals=' +
          this.props.subjectId +
          '&restriction.equals=-1'
      )
      .then((response) => {
        this.setState({
          permissions: response.data,
        });
      })
      .catch((error) => {
        this.message = errorHandler(
          Constants.dataGeneration,
          error,
          this.t('failedToRetrievePermission')
        );
        this.toggleModalError();
      });
    let options = [];
    let permissions = Object.entries(PERMISSION_URI);
    for (let permission of permissions) {
      if (permission[1].read) {
        options.push(permission[1].read);
      }
      if (permission[1].readWrite) {
        options.push(permission[1].readWrite);
      }
    }
    this.setState({
      options: options,
    });
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  /**
   * Toggles error modal
   */
  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  /**
   * Toggles delete modal dialog
   * @param {*} e
   */
  toggleModalDelete = (e) => {
    this.setState({ showModalDelete: !this.state.showModalDelete });
  };

  /**
   * Deletes permission
   * @param {*} permission
   */
  deletePermission = (permission) => {
    this.toggleModalDelete();
    this.modalDeleteEvent = () =>
      axios.employee
        .delete(`permissions/${permission.id}`)
        .then(() => {
          let newPermissions = this.state.permissions.filter((value) => {
            return value.id !== permission.id;
          });
          this.setState({ permissions: newPermissions });
        })
        .catch((error) => {
          this.message = errorHandler(
            Constants.deletion,
            error,
            this.t('failedToRemovePermission')
          );
          this.toggleModalError();
        });
  };

  /**
   * Saves permission
   * @param {*} permission
   */
  savePermission = (permission) => {
    let payload = {
      subjectType: this.props.subjectType,
      subjectId: this.props.subjectId,
      accessType: permission.accessType,
      entityType: permission.entityType,
      entityUri: permission.uri,
      restriction: Number(-1),
    };
    axios.employee
      .post('permissions', payload)
      .then((response) => {
        this.setState({
          permissions: this.state.permissions.concat([response.data]),
        });
      })
      .catch((error) => {
        this.message = errorHandler(
          Constants.addRecord,
          error,
          this.t('failedToAddPermission')
        );
        this.toggleModalError();
      });
  };

  /**
   * Prepare Table data for permissions list
   * @param {*} permissionOptions
   * @param {*} section
   * @returns tableData
   */
  prepareTableData = (permissionOptions, section) => {
    const newTableData = [];
    if (!isEmpty(permissionOptions)) {
      permissionOptions.forEach((permissionOption, index) => {
        if (permissionOption.section === section) {
          const entry = {
            label: (
              <Tooltip
                key={index}
                title={this.generatePermissionTooltip(
                  permissionOption?.description
                )}
              >
                <span>{this.t(permissionOption?.label)}</span>
              </Tooltip>
            ),
            switch: (
              <div>
                <Label check>
                  <Input
                    type="checkbox"
                    id={permissionOption + index}
                    checked={this.userHasPermission(permissionOption)}
                    onChange={(e) =>
                      this.handleSwitchChange(e, permissionOption)
                    }
                    disabled={!Auth.isAdmin()}
                  />
                  <Label>{null}</Label>
                </Label>
              </div>
            ),
          };
          newTableData.push(entry);
        }
      });
      return newTableData;
    } else {
      return [];
    }
  };

  /**
   * Generates permission tooltip
   * @param {*} tooltipText
   * @returns tooltip text
   */
  generatePermissionTooltip = (tooltipText) => {
    return <span>{tooltipText}</span>;
  };

  /**
   * Handle switch on/off event
   * @param {*} event
   * @param {*} permissionOption
   */
  handleSwitchChange = (event, permissionOption) => {
    if (event.target.checked) {
      this.savePermission(permissionOption);
    } else {
      let permissionToDelete =
        this.findPermissionOptionInCurrentPermissions(permissionOption);
      if (permissionToDelete) {
        this.deletePermission(permissionToDelete);
      }
    }
  };

  /**
   * Checks if the current permission option is something that has already been applied
   * to the current role/employee group
   * @param {*} permissionOption
   * @returns true or false
   */
  userHasPermission = (permissionOption) => {
    let permissionOptionInCurrentPermissions =
      this.findPermissionOptionInCurrentPermissions(permissionOption);

    if (permissionOptionInCurrentPermissions) {
      return true;
    }

    return false;
  };

  /**
   * Find the equivalent user permission based on the permission option selected
   * @param {*} permissionOption
   * @returns permission
   */
  findPermissionOptionInCurrentPermissions = (permissionOption) => {
    let currentUserPermissions = Array.isArray(this.state.permissions)
      ? this.state.permissions
      : [];

    let permissionOptionInCurrentPermissions = null;
    if (currentUserPermissions) {
      permissionOptionInCurrentPermissions = currentUserPermissions.find(
        (currentUserPermission) =>
          currentUserPermission.accessType.toString() ===
            permissionOption.accessType.toString() &&
          currentUserPermission.entityUri.toString() ===
            permissionOption.uri.toString() &&
          currentUserPermission.entityType === permissionOption.entityType
      );
    }

    return permissionOptionInCurrentPermissions;
  };

  /**
   * Returns prepared colums for each Dynamic Table
   * @param {*} header
   * @returns prepared colums
   */
  prepareColumns = (header) => {
    return [
      {
        type: 'data',
        header: header,
        accessor: 'label',
        show: 'true',
        width: defaultWidth,
      },
      {
        type: 'data',
        header: null,
        accessor: 'switch',
        show: 'true',
        alignRight: 'true',
      },
    ];
  };

  /**
   * Returns DynamicTable component for each Permission group
   * @param {*} permissionType
   * @param {*} permissionHeader
   * @returns DynamicTable component
   */
  prepareDynamicTable = (permissionType, permissionHeader) => {
    return (
      <DynamicTable
        data={this.prepareTableData(this.state.options, permissionType)}
        columns={this.prepareColumns(permissionHeader)}
        dontShowDataPerPageOption
        dontShowPageNumbers
      />
    );
  };

  render() {
    return (
      <Container fluid>
        <Card>
          <CardBody>
            <Fragment>
              <Row>
                <Button color="outline" id="permission">
                  <FontAwesomeIcon icon={faBars} />
                </Button>
                <h2 style={{ lineHeight: '1.5em', margin: '0px' }}>
                  {this.t('permissions')}
                </h2>
              </Row>
              <UncontrolledCollapse toggler="#permission">
                <Row>
                  <div style={{ width: '100%' }}>
                    <Row>
                      <Col>
                        {this.prepareDynamicTable(
                          Constants.PermissionAdmin,
                          this.t('actionPermissionsAdmin')
                        )}
                        <br />
                        {this.prepareDynamicTable(
                          Constants.PERMISSION_CAMPAIGN_MANAGEMENT,
                          this.t('actionPermissionsCampaignManagement')
                        )}
                        <br />
                        {this.prepareDynamicTable(
                          Constants.PermissionTimeTracking,
                          this.t('actionPermissionsTimetracking')
                        )}
                        <br />
                        {this.prepareDynamicTable(
                          Constants.PermissionAbsences,
                          this.t('actionPermissionsAbsences')
                        )}
                        <br />
                        {this.prepareDynamicTable(
                          Constants.PermissionCrm,
                          this.t('actionPermissionsCRM')
                        )}
                        <br />
                        {this.prepareDynamicTable(
                          Constants.PermissionSales,
                          this.t('actionPermissionsSalesFunnel')
                        )}
                        <br />
                        {this.prepareDynamicTable(
                          Constants.PERMISSION_RESOURCE_PLANNER,
                          this.t('actionPermissionsResourcePlanner')
                        )}
                        <br />
                        {this.prepareDynamicTable(
                          Constants.PermissionProject,
                          this.t('actionPermissionsProjects')
                        )}
                        <br />
                        {this.prepareDynamicTable(
                          Constants.PermissionFinance,
                          this.t('actionPermissionsFinance')
                        )}
                        <br />
                        {this.prepareDynamicTable(
                          Constants.PermissionEmployees,
                          this.t('actionPermissionsEmployees')
                        )}
                        <br />
                      </Col>
                      <Col>
                        {this.prepareDynamicTable(
                          Constants.PermissionData,
                          this.t('dataPermissions')
                        )}
                      </Col>
                    </Row>
                  </div>
                </Row>
              </UncontrolledCollapse>
            </Fragment>
          </CardBody>
        </Card>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.message?.mainError}
          errorReason={this.message?.errorReason}
          errorResponse={this.message?.errorResponse}
          modalTitle={this.t('error')}
        />
        <ModalDelete
          isOpen={this.state.showModalDelete}
          event={this.modalDeleteEvent}
          onClose={this.toggleModalDelete}
          modalTitle={this.t('deletePermission')}
          modalBodyText={this.t('deletePermissionWarning')}
        />
      </Container>
    );
  }
}

export default Permissions;
