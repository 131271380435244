import i18n from '../../../i18n';
import { IEmployeeListItem } from '../../../utils/types/responseTypes';
import { IEmployeeListFilters } from '../../../utils/types/stateTypes';
import { createLinkIfAuthorised } from '../../../utils/helpers/permission';
import { EntityType } from '../../../utils/enums/pageComponents';
import { SortBy } from '../../../utils/enums/sort';

/**
 * Translator function for Project List
 * @param keyName Key for phrase to be translated
 * @returns Translated string
 */
export const t = (keyName: string) => i18n.t(`EmployeeList.${keyName}`);

/**
 * Creates the query parameters to be used to fetch data
 * @returns Object containing query parameters
 */
export const createQueryParameters = (
  page: number,
  {
    firstname,
    lastname,
    department,
    responsibleName,
    location,
    careerLevel,
  }: IEmployeeListFilters,
  sortBy: string,
  sortType: string
) => ({
  page: page.toString(),
  ...(firstname && {
    'firstname.contains': firstname,
  }),
  ...(lastname && { 'name.contains': lastname }),
  ...(department && { 'employeeTeam.contains': department }),
  ...(responsibleName && {
    'responsibleName.contains': responsibleName,
  }),
  ...(location && {
    'location.contains': location,
  }),
  ...(careerLevel && { 'careerLevel.contains': careerLevel }),
  ...(sortBy &&
    sortType && {
      sort: `${sortBy === 'lastname' ? SortBy.NAME : sortBy}%2C${sortType}`,
    }),
});

const LocationEnums = {
  Munich: 'MUC',
  München: 'MUC',
  Manila: 'MNL',
  Düsseldorf: 'DUS',
  Wiesbaden: 'WIE',
  Detroit: 'DTT',
  Stuttgart: 'STR',
  Hamburg: 'HAM',
  Barcelona: 'BCN',
  Bundesweit: 'ALL',
};

/**
 * Gets the location shorthand if it is present, returns the whole thing if not
 * @param officeString
 * @returns location full name (with shorthand if applicable)
 */
const getLocationFullName = (officeString: string | undefined) => {
  if (!officeString) {
    return '';
  }
  // If the location is multipule items
  if (officeString.includes(',')) {
    const stringArray = officeString.split(',');
    let multiStringBuilder = '';
    stringArray.forEach((location, index) => {
      const locationCleaned = location.replace(/\s/g, '');

      // If the enum exists, add the shorthand in parentheses
      if (locationCleaned in LocationEnums) {
        multiStringBuilder += `${
          LocationEnums[locationCleaned as keyof typeof LocationEnums]
        } (${locationCleaned})`;
      }
      // If not, just add the location name
      else {
        multiStringBuilder += locationCleaned;
      }

      // Inserts the comma
      if (index !== stringArray.length - 1) {
        multiStringBuilder += ', ';
      }
    });
    return multiStringBuilder;
  }

  // If the location already has the shorthand, do nothing
  if (officeString.endsWith(')')) {
    return officeString;
  }

  // If the location is in LocationEnums, add the shorthand in parentheses
  if (officeString in LocationEnums) {
    return `${officeString} (${
      LocationEnums[officeString as keyof typeof LocationEnums]
    })`;
  }

  // If not, just return the raw location
  return officeString;
};

/**
 * Get string from parenthesis
 * @param input
 * @returns main teams initial or name
 */
const getStringFromParenthesis = (input: string) => {
  if (input.endsWith(')')) {
    return input.substring(input.indexOf('(') + 1, input.lastIndexOf(')'));
  }
  return input;
};

/**
 * Creates employee list entry (row) in Employee List Table
 * @param EmployeeListItem Item to create a row for in Employee List Table
 * @returns Row / Employee Entry for Employee List Table
 */
export const createEmployeeEntry = ({
  id,
  lastname,
  firstname,
  abbreviation,
  department,
  location,
  careerLevel,
  responsibleEmployee,
}: IEmployeeListItem) => ({
  id,
  lastname: createLinkIfAuthorised(EntityType.EMPLOYEE, lastname, id),
  firstname,
  abbreviation,
  department: getStringFromParenthesis(department ?? ''),
  location: getLocationFullName(location),
  careerLevel: getStringFromParenthesis(careerLevel ?? ''),
  responsibleName: createLinkIfAuthorised(
    EntityType.EMPLOYEE,
    responsibleEmployee?.name,
    responsibleEmployee?.id
  ),
});
