import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form, Row, Col, Button, Input, Label } from 'reactstrap';
import Select from 'react-select';
import { connect, ConnectedProps } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import withModals, { IWithModalsProps } from '../../utils/withModals';
import { customerFields } from '../../pages/crm/CustomerDetails/customerDetailsHelper';
import InputFormLabel from './InputFormLabel';
import i18n from '../../i18n';
import { generateTitle } from '../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';
import GeneralInfoForm from './GeneralInfoForm/GeneralInfoForm';
import {
  IDropdownOption,
  IDropdownOptionInvolved,
  IObjectContactInformation,
  IObjectInvolvedResponsible,
  IObjectNameAndId,
} from '../../utils/types/commonTypes';
import MultipleResponsibleDropdown from './MultipleResponsibleDropdown';
import { RootState } from '../../redux/store';
import { fetchEmployeesWithCustomerListPermission } from '../../redux/customerListSlice';
import {
  DEFAULT_LOAD_TIMEOUT,
  REGEX_ENGLISH_GERMAN_LETTERS,
} from '../../utils/constants';
import {
  isEmpty,
  isInvolvedResponsiblesNotModified,
} from '../../utils/helpers/array';
import {
  getCustomerCount,
  saveNewCustomerDetails,
} from '../../services/api/customer';
import {
  ICustomerDetail,
  ICustomerSiteDetail,
} from '../../utils/types/responseTypes';
import { saveNewCustomerSiteDetails } from '../../services/api/customerSite';
import { targetPrioritiesDropdownOptions } from '../../utils/helpers/dropdown';
import ResponsibleContactPersonEditor from './ResponsibleContactPersonForm/ResponsibleContactPersonEditor';
import { ContactInfoType } from '../../utils/enums/contact';
import { customerStateEnum, paymentGoalOptions } from '../../utils/enums/enum';
import CustomerAccountDropdown from '../dropdowns/CustomerAccountDropdown';
import MultipleSectorSelect from '../dropdowns/MultipleSectorSelect';
import { ContactInformationStatus } from '../../utils/enums/contactPerson';
import { TargetPriority } from '../../utils/enums/customer';

interface IProps extends IWithModalsProps, PropsFromRedux {
  onSave: (newCustomer: ICustomerDetail) => void;
  onCancel: () => void;
  onContactPersonsPage?: boolean;
  onCustomerSitePage?: boolean;
  existingCustomerAccount?: IObjectNameAndId;
}

interface IInheritObjects {
  contactInformations: boolean;
  involved: boolean;
  contactPeople: boolean;
}

interface IEditedCustomerDetails {
  name: string;
  customerAbbreviation: string;
  targetPriority: string;
  state: string;
  customerAccount: IObjectNameAndId | null;
  sectors: IObjectNameAndId[];
  paymentGoal: number | null;
  sharepointLink: string | null;
  responsible: IObjectNameAndId;
  involvedResponsibles: IObjectInvolvedResponsible[];
  contactInformation: IObjectContactInformation[];
  contactPersons: IObjectNameAndId[];
}

const t = (keyName: string) => i18n.t(`AddCustomerModal.${keyName}`);

const getEditedFieldValues = (
  fieldsWithUpdates: string[],
  currentFieldValues: IEditedCustomerDetails
) => {
  const detailsToBeSaved = {} as ICustomerDetail;
  fieldsWithUpdates.forEach((field) => {
    (detailsToBeSaved as any)[field] = (currentFieldValues as any)[field];
  });
  return detailsToBeSaved;
};

const initialCustomerState = (
  responsible: IObjectNameAndId,
  customerAccount: IObjectNameAndId
) => ({
  name: '',
  customerAbbreviation: '',
  targetPriority: TargetPriority.TO_BE_DEFINED,
  state: customerStateEnum.none.code,
  customerAccount,
  sectors: [] as IObjectNameAndId[],
  paymentGoal: 0,
  sharepointLink: '',
  responsible,
  involvedResponsibles: [] as IObjectInvolvedResponsible[],
  contactInformation: [] as IObjectContactInformation[],
  contactPersons: [] as IObjectNameAndId[],
});

const AddCustomerModal = ({
  modalErrorHandler,
  onSave,
  onCancel,
  employeesWithPermission,
  currentUser,
  onContactPersonsPage = false,
  onCustomerSitePage = false,
  existingCustomerAccount = { id: 0, name: '' },
}: IProps) => {
  const [editedCustomer, setEditedCustomer] = useState<IEditedCustomerDetails>(
    initialCustomerState(currentUser, existingCustomerAccount)
  );

  const [fieldsWithUpdates, setFieldsWithUpdates] = useState<string[]>([]);
  const [customerSite, setCustomerSite] = useState({
    siteName: '',
    siteLocation: '',
  });

  const [inheritObjects, setInheritObjects] = useState<IInheritObjects>({
    contactInformations: true,
    involved: true,
    contactPeople: true,
  });

  const [addCustomerSite, setAddCustomerSite] = useState(false);
  const [isCheckingName, setIsCheckingName] = useState(false);
  const [isCustomerNameValid, setIsCustomerNameValid] = useState(true);

  const {
    name,
    customerAbbreviation,
    state,
    targetPriority,
    sharepointLink,
    customerAccount,
    sectors,
    paymentGoal,
    contactInformation,
    responsible,
    involvedResponsibles,
    contactPersons,
  } = editedCustomer;

  const originalDetails: IEditedCustomerDetails = initialCustomerState(
    currentUser,
    existingCustomerAccount
  );

  const filteredInvolvedIds = [
    responsible.id,
    ...involvedResponsibles.map((involved) => involved.id),
  ];

  const { siteName, siteLocation } = customerSite;

  const updateFieldsWithChanges = (fieldValue: string, noChange: boolean) => {
    setFieldsWithUpdates((prevFields) => {
      if (noChange) {
        return prevFields.filter((item) => item !== fieldValue);
      }
      if (prevFields.includes(fieldValue)) {
        return prevFields;
      }
      return [...prevFields, fieldValue];
    });
  };

  const handleInheritCheckBoxClick = ({
    target: { value, checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setInheritObjects({
      ...inheritObjects,
      [value]: checked,
    });
  };

  const toggleAddCustomerSite = () => {
    setAddCustomerSite(!addCustomerSite);
  };

  const handleSiteChange = (value: string, stateKey: string) => {
    setCustomerSite({ ...customerSite, [stateKey]: value });
  };

  const handleCustomerAbbreviationChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const customerAbbreviationValue = value.trim();
    if (
      customerAbbreviationValue.match(REGEX_ENGLISH_GERMAN_LETTERS) ||
      customerAbbreviationValue === ''
    ) {
      setEditedCustomer({
        ...editedCustomer,
        customerAbbreviation:
          customerAbbreviationValue !== ''
            ? customerAbbreviationValue.toUpperCase()
            : '',
      });
      updateFieldsWithChanges(
        customerFields.customerAbbreviation,
        value === originalDetails.customerAbbreviation
      );
    }
  };

  const handleCustomerAccountChange = (customerAccount: IObjectNameAndId) => {
    setEditedCustomer({ ...editedCustomer, customerAccount });
    updateFieldsWithChanges(
      customerFields.customerAccount,
      customerAccount.id === originalDetails.customerAccount?.id
    );
  };

  const handleMultipleSectorChange = (sectors: IObjectNameAndId[]) => {
    if (sectors.length > 0) {
      setEditedCustomer({ ...editedCustomer, sectors });
      updateFieldsWithChanges(customerFields.sectors, false);
    }
  };

  const handleResponsibleContactPersonChange = (
    newContactPersons: IDropdownOption<number>[]
  ) => {
    setEditedCustomer({
      ...editedCustomer,
      contactPersons: newContactPersons.map(({ value, label }) => ({
        id: value,
        name: label,
      })),
    });
    updateFieldsWithChanges('contactPersons', newContactPersons.length === 0);
  };

  const handleInputChange = (value: number | string, state: string) => {
    setEditedCustomer({ ...editedCustomer, [state]: value });
    updateFieldsWithChanges(
      state,
      value === originalDetails[state as keyof IEditedCustomerDetails]
    );
  };

  const handleResponsibleChange = ({ value }: IDropdownOption<number>) => {
    const newResponsible = employeesWithPermission?.find(
      (r) => r.value === value
    );
    if (newResponsible) {
      setEditedCustomer({
        ...editedCustomer,
        responsible: { id: newResponsible.value, name: newResponsible.label },
      });
      updateFieldsWithChanges(
        customerFields.responsible,
        newResponsible.value === originalDetails.responsible.id
      );
    }
  };

  const handleInvolvedResponsibleChange = (
    involved: IDropdownOptionInvolved[]
  ) => {
    setEditedCustomer({
      ...editedCustomer,
      involvedResponsibles: involved.map(
        ({ responsible, responsibleRole }) =>
          ({
            ...(responsible
              ? {
                  id: responsible.value,
                  name: responsible.label,
                }
              : {}),
            ...(responsibleRole
              ? {
                  responsibleRole: {
                    id: responsibleRole.value,
                    name: responsibleRole.label,
                  },
                }
              : {}),
          } as IObjectInvolvedResponsible)
      ),
    });
    updateFieldsWithChanges(
      customerFields.involvedResponsibles,
      isInvolvedResponsiblesNotModified(
        involvedResponsibles,
        originalDetails.involvedResponsibles
      )
    );
  };

  const handleUpdateContactInformation = (
    contactInformation: IObjectContactInformation[]
  ) => {
    setEditedCustomer({
      ...editedCustomer,
      contactInformation,
    });
    updateFieldsWithChanges(customerFields.contactInformation, false);
  };

  const handlePhoneChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setEditedCustomer({
      ...editedCustomer,
      contactInformation: [
        ...contactInformation.filter(
          (contactInfo) => contactInfo.type !== ContactInfoType.PHONE
        ),
        {
          id: 0,
          type: ContactInfoType.PHONE,
          info: value,
          status: ContactInformationStatus.UNKNOWN,
        },
      ],
    });
    updateFieldsWithChanges(customerFields.contactInformation, false);
  };

  const handleWebsiteChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const newWebsiteInfo: IObjectContactInformation = {
      id: 0,
      type: ContactInfoType.WEBSITE,
      info: value,
      status: ContactInformationStatus.UNKNOWN,
    };
    const newContactInformations = contactInformation.filter(
      (contactInfo) => contactInfo.type !== ContactInfoType.WEBSITE
    );
    newContactInformations.push(newWebsiteInfo);

    setEditedCustomer({
      ...editedCustomer,
      contactInformation: newContactInformations,
    });
    updateFieldsWithChanges(customerFields.contactInformation, false);
  };

  const handleSaveCustomerSiteDetails = async (
    savedCustomer: ICustomerDetail
  ) => {
    const customerSiteToSave = {
      name: siteName,
      location: siteLocation,
      responsible,
      sectors,
      customer: savedCustomer as IObjectNameAndId,
      targetPriority: TargetPriority.TO_BE_DEFINED,
      contactInformation: inheritObjects.contactInformations
        ? contactInformation
        : [],
      involvedEmployees: inheritObjects.involved ? involvedResponsibles : [],
      contactPersons: inheritObjects.contactPeople ? contactPersons : [],
    };
    await saveNewCustomerSiteDetails(
      customerSiteToSave as ICustomerSiteDetail
    ).catch((error) => {
      modalErrorHandler(t('failedToSaveCustomerSiteDetails'), error);
    });
  };

  const handleSaveCustomerDetails = () => {
    const detailsToSave = {
      ...getEditedFieldValues(fieldsWithUpdates, editedCustomer),
      targetPriority,
      responsible,
      state,
    };
    if (fieldsWithUpdates.includes(customerFields.contactInformation)) {
      detailsToSave.contactInformation =
        detailsToSave.contactInformation.filter(
          (contactInfo: IObjectContactInformation) => contactInfo.info !== ''
        );
    }
    if (existingCustomerAccount.id !== 0) {
      detailsToSave.customerAccount = existingCustomerAccount;
    }
    saveNewCustomerDetails(detailsToSave)
      .then(async ({ data }) => {
        if (addCustomerSite) {
          await handleSaveCustomerSiteDetails(data);
        }
        onSave(data);
      })
      .catch((error) => modalErrorHandler(t('failedToAddCustomer'), error));
  };

  useEffect(() => {
    if (name) {
      setIsCheckingName(true);
    }

    const timer = setTimeout(() => {
      if (name) {
        getCustomerCount({ 'name.equals': name }).then(({ data }) => {
          setIsCustomerNameValid(data === 0);
          setIsCheckingName(false);
        });
      }
    }, DEFAULT_LOAD_TIMEOUT);

    return () => clearTimeout(timer);
  }, [name]);

  return (
    <Form>
      <Row>
        <Col xs={3}>
          <InputFormLabel isRequired text={t('name')} />
        </Col>
        <Col>
          <Input
            data-testid="customer-name-input"
            type="string"
            bsSize="lg"
            value={name}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(value, customerFields.name)
            }
          />
          {isCheckingName && (
            <>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ color: 'gray' }}
                className="margin-right"
              />
              {t('checkingCustomerName')}
            </>
          )}
          {name && !isCheckingName && (
            <>
              <FontAwesomeIcon
                icon={isCustomerNameValid ? faCheckCircle : faTimesCircle}
                style={{ color: isCustomerNameValid ? 'green' : 'red' }}
                className="margin-right"
              />
              {t(isCustomerNameValid ? 'validName' : 'invalidName')}
            </>
          )}
        </Col>
      </Row>
      <br />
      {!onCustomerSitePage && (
        <>
          <Row>
            <Col xs={3}>
              <Button
                color="primary"
                onClick={toggleAddCustomerSite}
                data-testid="add-customer-site-button"
              >
                {generateTitle(BUTTON_TITLE_ENUM.ADD.code, t('customerSite'))}
              </Button>
            </Col>
          </Row>
          <br />
        </>
      )}
      {addCustomerSite && (
        <>
          <Row>
            <Col xs={3}>
              <InputFormLabel isRequired text={t('nameAtSite')} />
            </Col>
            <Col>
              <Input
                data-testid="customer-site-name-input"
                type="text"
                value={siteName}
                onChange={({
                  target: { value },
                }: ChangeEvent<HTMLInputElement>) =>
                  handleSiteChange(value, 'siteName')
                }
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={3}>
              <InputFormLabel isRequired text={t('city')} />
            </Col>
            <Col>
              <Input
                data-testid="customer-site-location-input"
                type="text"
                value={siteLocation}
                onChange={({
                  target: { value },
                }: ChangeEvent<HTMLInputElement>) =>
                  handleSiteChange(value, 'siteLocation')
                }
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={3}>{t('addToCustomerSite')}</Col>
            <Col>
              <Row>
                <Col>
                  <Label>
                    <Input
                      data-testid="customer-site-contact-information-checkbox"
                      type="checkbox"
                      checked={inheritObjects.contactInformations}
                      onChange={handleInheritCheckBoxClick}
                      value="contactInformations"
                    />
                    {t('contactInformations')}
                  </Label>
                </Col>
                <Col>
                  <Label>
                    <Input
                      data-testid="customer-site-involved-checkbox"
                      type="checkbox"
                      checked={inheritObjects.involved}
                      onChange={handleInheritCheckBoxClick}
                      value="involved"
                    />
                    {t('involvedOnCheckBox')}
                  </Label>
                </Col>
                <Col>
                  <Label>
                    <Input
                      data-testid="customer-site-contact-people-checkbox"
                      type="checkbox"
                      checked={inheritObjects.contactPeople}
                      onChange={handleInheritCheckBoxClick}
                      value="contactPeople"
                    />
                    {t('contactPeople')}
                  </Label>
                </Col>
              </Row>
              <br />
            </Col>
          </Row>
          <br />
        </>
      )}
      <Row>
        <Col xs={3}>
          <InputFormLabel
            isRequired={false}
            text={t('abbreviation') + t('fiveLetters')}
          />
        </Col>
        <Col>
          <Input
            style={{ width: '240px' }}
            type="text"
            value={customerAbbreviation}
            onChange={handleCustomerAbbreviationChange}
            maxlength="5"
            data-testid="abbreviation-input"
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3}>
          <InputFormLabel isRequired={false} text={t('state')} />
        </Col>
        <Col>
          <Input value={state} disabled />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3}>
          <InputFormLabel isRequired text={t('targetPriority')} />
        </Col>
        <Col>
          <div data-testid="target-priority-div">
            <Select
              options={targetPrioritiesDropdownOptions}
              value={targetPrioritiesDropdownOptions.find(
                ({ value }) => value === targetPriority
              )}
              onChange={({ value }: IDropdownOption) =>
                handleInputChange(value, customerFields.targetPriority)
              }
            />
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3}>
          <InputFormLabel isRequired={false} text={t('phone')} />
        </Col>
        <Col>
          <Input
            data-testid="phone-input"
            type="number"
            bsSize="lg"
            onChange={handlePhoneChange}
            value={
              contactInformation.find(
                ({ type }) => type === ContactInfoType.PHONE
              )?.info
            }
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3}>
          <InputFormLabel isRequired={false} text={t('companyWebsite')} />
        </Col>
        <Col>
          <Input
            data-testid="website-input"
            type="string"
            bsSize="lg"
            onChange={handleWebsiteChange}
            value={
              contactInformation.find(
                ({ type }) => type === ContactInfoType.WEBSITE
              )?.info
            }
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3}>
          <InputFormLabel isRequired={false} text={t('account')} />
        </Col>
        <Col>
          <CustomerAccountDropdown
            onChange={handleCustomerAccountChange}
            customerAccountId={customerAccount?.id as number}
            isClearable={false}
            isDisabled={existingCustomerAccount.id !== 0}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3}>
          <InputFormLabel isRequired text={t('sector')} />
        </Col>
        <Col>
          <MultipleSectorSelect
            onChange={handleMultipleSectorChange}
            sectorIds={sectors.map(({ id }) => id)}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3}>
          <InputFormLabel isRequired={false} text={t('paymentGoal')} />
        </Col>
        <Col>
          <div data-testid="payment-goal-div">
            <Select
              value={paymentGoalOptions.find(
                ({ value }) => value === paymentGoal
              )}
              options={paymentGoalOptions.filter(
                ({ value }) => value !== paymentGoal
              )}
              onChange={({ value }: IDropdownOption) =>
                handleInputChange(value, customerFields.paymentGoal)
              }
            />
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3}>
          <InputFormLabel
            isRequired={false}
            text={t('documentManagementSystemLink')}
          />
        </Col>
        <Col>
          <Input
            data-testid="sharepointlink-input"
            type="string"
            bsSize="lg"
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(value, customerFields.sharepointLink)
            }
            value={sharepointLink}
          />
        </Col>
      </Row>
      <br />
      {!onContactPersonsPage && (
        <>
          <Row>
            <Col xs={3}>
              <InputFormLabel isRequired={false} text={t('contactPersons')} />
            </Col>
            <Col>
              <ResponsibleContactPersonEditor
                responsibleContactPersons={contactPersons}
                customerId={0}
                onChange={handleResponsibleContactPersonChange}
              />
            </Col>
          </Row>
          <br />
        </>
      )}
      <Row>
        <Col xs={3}>
          <InputFormLabel isRequired={false} text={t('responsible')} />
        </Col>
        <Col>
          <div data-testid="responsible-div">
            <Select
              options={employeesWithPermission?.filter(
                (employee) =>
                  employee.value !== responsible.id &&
                  !involvedResponsibles.find(
                    (involved) => employee.value === involved.id
                  )
              )}
              value={{
                value: responsible.id,
                label: responsible.name,
              }}
              onChange={handleResponsibleChange}
            />
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3}>
          <InputFormLabel isRequired={false} text={t('involved')} />
        </Col>
        <Col>
          <MultipleResponsibleDropdown
            responsibles={involvedResponsibles.map(
              ({ id, name, responsibleRole }) =>
                ({
                  ...(id && name
                    ? {
                        responsible: { label: name, value: id },
                      }
                    : {}),
                  ...(responsibleRole
                    ? {
                        responsibleRole: {
                          label: responsibleRole.name,
                          value: responsibleRole.id,
                        },
                      }
                    : {}),
                } as {
                  responsible: IDropdownOption<number>;
                  responsibleRole: IDropdownOption<number>;
                })
            )}
            setResponsibles={handleInvolvedResponsibleChange}
            responsibleOptions={
              employeesWithPermission?.filter(
                (employee) => !filteredInvolvedIds?.includes(employee.value)
              ) as IDropdownOption<number>[]
            }
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3}>
          <InputFormLabel
            isRequired={false}
            text={t('generalContactInformation')}
          />
        </Col>
        <Col>
          <GeneralInfoForm
            contactInformations={contactInformation}
            onChange={handleUpdateContactInformation}
          />
        </Col>
      </Row>
      <br />
      <br />
      <Button
        data-testid="addcustomermodal-save-button"
        color="primary"
        onClick={handleSaveCustomerDetails}
        disabled={
          !(
            name &&
            isCustomerNameValid &&
            !isCheckingName &&
            !isEmpty(sectors) &&
            !isEmpty(fieldsWithUpdates) &&
            (addCustomerSite ? siteName && siteLocation : true)
          )
        }
      >
        {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, t('save'))}
      </Button>{' '}
      <Button color="primary" onClick={onCancel}>
        {t('cancel')}
      </Button>
    </Form>
  );
};

const mapStateToProps = (store: RootState) => ({
  employeesWithPermission:
    store.customerList.additionalState?.employeesWithPermission,
  currentUser: {
    id: store.account.employeeDetails.id,
    name: `${store.account.employeeDetails.firstname} ${store.account.employeeDetails.name}`,
  } as IObjectNameAndId,
});

const mapDispatchToProps = {
  fetchEmployeesWithCustomerListPermission,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(AddCustomerModal));
