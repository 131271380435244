import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import DynamicTable from '../../components/tables/DynamicTable';
import {
  SEND_NOTIFICATIONS_VIA_ENUM,
  SERVICE_NAMES_ENUM,
} from '../../utils/enums/service';
import { modalCloseButton } from '../../utils/helpers/click';

const defaultWidth = '500px';
const EMAIL_OR_CRM_TOOL = 'emailOrCRMToolOption';
const SERVICE_NAMES = 'serviceNames';

/**
 * ModalNotificationSetting contains checkboxes to control where the notification will be sent
 * @Author Joseph Ortega
 */
const ModalNotificationSetting = (props) => {
  const {
    isOpen,
    onClose,
    selectedNotificationFromService,
    selectedEmailOrCRMTool,
    updateFromService,
  } = props;

  const [modal, setModal] = useState(isOpen);

  const toggle = (e) => {
    onClose && onClose(e);
    setModal(!modal);
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  /**
   * Function to update the user setting for the notificationSetting,
   * to be used on what notitification entries to be received based on service selected
   * @param {*} selectedOption
   * @returns boolean value to control checkbox checked/unchecked state
   */
  const userSelectedOptions = (selectedOption, section) => {
    // Selected option to use based on section value
    let selectedOptionsToUse =
      section === EMAIL_OR_CRM_TOOL
        ? selectedEmailOrCRMTool
        : selectedNotificationFromService;

    // Check if the selectedOption is already included on the selectedOptionsToUse
    let isOptionFound = selectedOptionsToUse.find(
      (option) => option === selectedOption.value
    );

    if (isOptionFound) {
      return true;
    }

    return false;
  };

  const isAllServiceSelected = (arr, arr2) => {
    return arr.every((v) => arr2.includes(v));
  };

  const preparedSendNotificationToColumns = [
    {
      type: 'data',
      header: 'Send notification:',
      accessor: 'label',
      show: 'true',
      width: defaultWidth,
    },
    {
      type: 'data',
      header: null,
      accessor: 'switch',
      show: 'true',
      alignRight: 'true',
    },
  ];

  const preparedNotificationServiceColumns = [
    {
      type: 'data',
      header: 'Select notification service',
      accessor: 'label',
      show: 'true',
      width: defaultWidth,
    },
    {
      type: 'data',
      header: null,
      accessor: 'switch',
      show: 'true',
      alignRight: 'true',
    },
  ];

  // Set default options
  const emailOrCRMToolOptions = Object.values(SEND_NOTIFICATIONS_VIA_ENUM).map(
    (item) => ({
      value: item.code,
      label: item.name,
    })
  );

  // Set default options
  const serviceNamesOptions = Object.values(SERVICE_NAMES_ENUM).map((item) => ({
    value: item.code,
    label: item.name,
  }));

  // Prepare Table data for permissions list
  const prepareTableData = (options, section) => {
    const newTableData = [];
    if (Array.isArray(options) && options.length > 0) {
      options.forEach((option, index) => {
        if (option.label) {
          const entry = {
            label: (
              <Tooltip key={index} title={option.code}>
                <span>{option?.value}</span>
              </Tooltip>
            ),
            switch: (
              <div>
                <Label check>
                  <Input
                    type="checkbox"
                    id={option + index}
                    onChange={(e) => handleSwitchChange(e, option, section)}
                    defaultChecked={userSelectedOptions(option, section)}
                  />
                  <Label>{null}</Label>
                </Label>
              </div>
            ),
          };
          newTableData.push(entry);
        }
      });
      return newTableData;
    } else {
      return [];
    }
  };

  const handleSwitchChange = (event, selectedOption, section) => {
    // Constants
    let isAllSelected = selectedOption.value === SERVICE_NAMES_ENUM.all.code; // All is selected
    let selectedOptionsToUse =
      section === EMAIL_OR_CRM_TOOL
        ? selectedEmailOrCRMTool
        : selectedNotificationFromService;
    let removedAllFromServiceNamesOption = serviceNamesOptions
      .filter(
        (serviceName) => serviceName.value !== SERVICE_NAMES_ENUM.all.code
      )
      .map((serviceName) => serviceName.value);

    // For handling checking of entry
    if (event.target.checked) {
      if (isAllSelected) {
        let serviceNameValueOptions = Object.values(serviceNamesOptions).map(
          (item) => item.value
        );
        updateFromService(serviceNameValueOptions, section);
      } else {
        // If checked, then remove it from the list
        let try2find = selectedOptionsToUse.findIndex(
          (option) => option === selectedOption.value
        );
        if (try2find === -1) {
          selectedOptionsToUse.push(selectedOption?.value);
        }

        let allServiceSelected = isAllServiceSelected(
          removedAllFromServiceNamesOption,
          selectedOptionsToUse
        );

        if (allServiceSelected) {
          let allIsNotYetIncluded =
            selectedOptionsToUse.findIndex(
              (option) => option === SERVICE_NAMES_ENUM.all.code
            ) === -1;
          if (allIsNotYetIncluded) {
            selectedOptionsToUse.push(SERVICE_NAMES_ENUM.all.code);
          }
        }
        updateFromService(selectedOptionsToUse, section);
      }
    }
    // For handling of unchecked entry
    else {
      if (isAllSelected) {
        updateFromService([], section);
      } else {
        // Meaning, not yet included on the options, add the selectOption
        let filteredOpt = selectedOptionsToUse.filter(
          (option) =>
            option !== selectedOption.value &&
            option !== SERVICE_NAMES_ENUM.all.code
        );
        updateFromService(filteredOpt, section);
      }
    }
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader close={modalCloseButton(toggle)}>
          {'Notification Setting'}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <DynamicTable
                data={prepareTableData(
                  emailOrCRMToolOptions,
                  EMAIL_OR_CRM_TOOL
                )}
                columns={preparedSendNotificationToColumns}
                dontShowDataPerPageOption
                dontShowPageNumbers
              />
              {selectedEmailOrCRMTool?.includes(
                SEND_NOTIFICATIONS_VIA_ENUM.crmTool.code
              ) ? (
                <DynamicTable
                  data={prepareTableData(serviceNamesOptions, SERVICE_NAMES)}
                  columns={preparedNotificationServiceColumns}
                  dontShowDataPerPageOption
                  dontShowPageNumbers
                />
              ) : null}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalNotificationSetting;
