import React, { useEffect, useState } from 'react';
import {
  Container,
  CardHeader,
  Card,
  CardTitle,
  CardBody,
  Col,
  Row,
  Button,
  Label,
} from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { t } from './resourceOverviewHelper';
import {
  IDropdownOption,
  IDropdownOptionSkillLevel,
  IObjectEmployeeWithResponsible,
} from '../../../utils/types/commonTypes';
import MultipleSkillFilter from './MultipleSkillFilter';
import ResourceOverviewTable from './ResourceOverviewTable';
import {
  employmentTypeOptions,
  objectNameAndIdToDropdownOptions,
} from '../../../utils/helpers/dropdown';
import {
  getEmployeeNamesWithResponsible,
  getListOfResponsibleNames,
} from '../../../services/api/employee';
import { Ownership } from '../../../utils/enums/ownership';
import { EntityType } from '../../../utils/enums/pageComponents';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IResourceListItem } from '../../../utils/types/responseTypes';
import { getResourceList } from '../../../services/api/resourcePlanner';

interface IProps
  extends IWithModalsProps,
    RouteComponentProps<
      Record<string, never>,
      Record<string, never>,
      { message: string }
    > {}

interface IResourceFilter {
  responsibles: IDropdownOption<number>[];
  specificEmployee: IDropdownOption<number>[];
  skills: IDropdownOptionSkillLevel[];
  employmentTypes: IDropdownOption[];
}

const ResourceOverview = ({
  location: { pathname },
  modalErrorHandler,
}: IProps) => {
  const [filter, setFilter] = useState<IResourceFilter>({
    responsibles: [],
    specificEmployee: [],
    skills: [],
    employmentTypes: [],
  } as IResourceFilter);
  const [responsibleOptions, setResponsibleOptions] = useState<
    IDropdownOption<number>[]
  >([]);
  const [employeeList, setEmployeeList] = useState<
    IObjectEmployeeWithResponsible[]
  >([]);
  const [employeeOptions, setEmployeeOptions] = useState<
    IDropdownOption<number>[]
  >([]);
  const [resourceList, setResourceList] = useState<IResourceListItem[]>([]);
  const [timespanSelected, setTimespanSelected] = useState<{
    startingDate: Date;
    endingDate: Date;
  }>({
    startingDate: new Date(new Date().getFullYear(), 0, 1),
    endingDate: new Date(new Date().getFullYear(), 12, 0),
  });

  const { skills, employmentTypes, responsibles, specificEmployee } = filter;

  const fetchResources = async (queryParameters: { [key: string]: string }) => {
    try {
      const { data: resourceList } = await getResourceList(queryParameters);
      setResourceList(resourceList);
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveEmployees'), error);
    }
  };

  const handleApplyFilterButton = async () => {
    const { startingDate, endingDate } = timespanSelected;
    try {
      await fetchResources({
        ['responsibleIds']: responsibles.map(({ value }) => value).join(','),
        ['employeeIds']: specificEmployee.map(({ value }) => value).join(','),
        ['skills']: skills
          .map(
            ({ skill: { value: skillId }, level: { value: level } }) =>
              `${skillId}:${level}`
          )
          .join(','),
        ['employmentTypes']: employmentTypes
          .map(({ value }) => value)
          .join(','),
        ['startDate']: startingDate.toISOString(),
        ['endDate']: endingDate.toISOString(),
      });
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveEmployees'), error);
    }
  };

  const handleChangeResponsibleDropdown = (
    responsibles: IDropdownOption<number>[]
  ) => {
    setFilter((values) => ({ ...values, responsibles }));
  };

  const handleChangeEmployeeDropdown = (
    employees: IDropdownOption<number>[]
  ) => {
    setFilter((values) => ({ ...values, specificEmployee: employees }));
  };

  const handleChangeSkillFilter = (skillLevel: IDropdownOptionSkillLevel[]) => {
    setFilter((values) => ({ ...values, skills: skillLevel }));
  };

  const handleChangeEmploymentType = (
    selectedEmploymentTypes: IDropdownOption[]
  ) => {
    setFilter((values) => ({
      ...values,
      employmentTypes: selectedEmploymentTypes,
    }));
  };

  const fetchResponsiblesOptions = async () => {
    try {
      const { data: responsible } = await getListOfResponsibleNames({
        'Ownership.in': Ownership.RESPONSIBLE,
        'objectType.equals': EntityType.EMPLOYEE.toUpperCase(),
      });

      setResponsibleOptions(objectNameAndIdToDropdownOptions(responsible));
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveResponsible'), error);
    }
  };

  const fetchEmployeeOptions = async () => {
    try {
      const { data: employees } = await getEmployeeNamesWithResponsible({});

      setEmployeeList(employees);

      setEmployeeOptions(
        employees.map(({ id, name }) => ({ label: name, value: id }))
      );
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveEmployees'), error);
    }
  };

  useEffect(() => {
    fetchResponsiblesOptions();
    fetchEmployeeOptions();
  }, []);

  useEffect(() => {
    const updatedEmployeeOptions = employeeList.filter(({ responsibleId }) =>
      responsibleOptions.find(({ value }) => value === responsibleId)
    );

    setEmployeeOptions(
      updatedEmployeeOptions.map(({ id, name }) => ({ value: id, label: name }))
    );
  }, [responsibles]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('resourceOverview')}</HeaderTitle>
        {generateBreadcrumb(pathname, t('resourceOverview'))}
      </Header>
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">
            <h1>{t('employeeList')}</h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="d-flex justify-content-between">
            <Col>
              <h2>{t('filterOptions')}</h2>
            </Col>
            <Col>
              <Button
                className="float-end"
                color="primary"
                onClick={handleApplyFilterButton}
              >
                {t('applyFilter')}
              </Button>
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              <Row>
                <Label>{t('filterByResponsible')}</Label>
              </Row>
              <Row>
                <Select
                  aria-label="resource-overview-responsible-dropdown"
                  values={responsibles}
                  options={responsibleOptions}
                  onChange={handleChangeResponsibleDropdown}
                  isMulti
                  isClearable
                />
              </Row>
            </Col>
            <Col>
              <Row>
                <Label>{t('filterSpecificEmployees')}</Label>
              </Row>
              <Row>
                <Select
                  aria-label="resource-overview-employee-dropdown"
                  values={specificEmployee}
                  options={employeeOptions}
                  onChange={handleChangeEmployeeDropdown}
                  isMulti
                  isClearable
                />
              </Row>
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              <Row>
                <Label>{t('filterBySkills')}</Label>
              </Row>
              <Row>
                <MultipleSkillFilter
                  skillAndLevelFilter={skills}
                  setSkillFilter={handleChangeSkillFilter}
                />
              </Row>
            </Col>
            <Col>
              <Row>
                <Label>{t('filterByTypeOfEmployment')}</Label>
              </Row>
              <Row>
                <Select
                  aria-label="resource-overview-employementType-dropdown"
                  values={employmentTypes}
                  options={employmentTypeOptions}
                  onChange={handleChangeEmploymentType}
                  isMulti
                  isClearable
                />
              </Row>
            </Col>
          </Row>
          <ResourceOverviewTable
            timespanSelected={timespanSelected}
            setTimespanSelected={setTimespanSelected}
            resourceList={resourceList}
            setResources={setResourceList}
            fetchResources={fetchResources}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default withModals(withRouter(ResourceOverview));
