import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { BASE_URL, Url } from '../../services/api/constants';
import axios from '../../services/axios/axios';
import { IEmployee } from '../types/modelTypes';
import axiosPlain from '../../services/axios/Axios-Interceptors';

/**
 * Used to build the axios string for the axios calls
 * @param {*} baseURL The base URL of the item, Reads String
 * @param {*} parameters The basic parameters of the item, Reads a key value object
 */
export const urlBuilder = (
  url: string,
  parameters: {
    [key: string]: string;
  }
) =>
  [
    `${BASE_URL}${url}`,
    Object.entries(parameters)
      .map(([key, value]) => `${key}=${value}`)
      .join('&'),
  ].join('?');

/**
 * @deprecated
 * Use function in api/employee.ts
 * Get employees with a specific permission Yupe
 * @param {*} URI of the item in question (example: com.invensity.itwcrm.action.customer.customerAccountList)
 * @param {*} accessType of the URI (READ/READWRITE)
 * @returns
 */
export function getEmployeesWithPermissions(
  URI: string[] | string,
  accessType: string[] | string
) {
  const axiosParameters = {
    'permissionsFilter.in': Array.isArray(URI) ? URI.join(',') : URI,
    'accessTypeFilter.in': Array.isArray(accessType)
      ? accessType.join(',')
      : accessType,
  };
  return trackPromise(
    axiosPlain.get(urlBuilder(Url.EMPLOYEES, axiosParameters))
  ).then((response) => (Array.isArray(response.data) ? response.data : []));
}

/**
 * @deprecated
 * Get employees managed by the current user
 * @returns list of employees under the responsibility of the current user
 */
export function getEmployeesForResponsible() {
  return axios.employee
    .get('employees-under-responsible')
    .then((response: AxiosResponse<IEmployee[]>) =>
      Array.isArray(response.data) ? response.data : []
    );
}

/**
 * @deprecated
 * Get all employees
 * @returns list of employees under the responsibility of the current user
 */
export function getEmployees() {
  return axios.employee
    .get('employees')
    .then((response: AxiosResponse<IEmployee[]>) =>
      Array.isArray(response.data) ? response.data : []
    );
}

/**
 * @deprecated, replace with the one in employee.ts
 * Gets the working days available to the employee in question
 * @param {*} URI of the item in question (example: com.invensity.itwcrm.action.customer.customerAccountList)
 * @param {*} accessType of the URI (READ/READWRITE)
 * @returns
 */
export function getWorkingEmployeeDays(
  id: number,
  startDateString: string,
  endDateString: string
) {
  const axiosParameters = {
    id: id.toString(),
    startDateString,
    endDateString,
  };
  return axiosPlain
    .get(urlBuilder('/working-employee-days-count', axiosParameters))
    .then((response: AxiosResponse<number>) => response.data);
}
