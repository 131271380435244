import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';
import Datetime from 'react-datetime';
import { connect, ConnectedProps } from 'react-redux';
import Select from 'react-select';
import { Input, Button } from 'reactstrap';

import DetailsCard from '../../../components/cards/DetailsCard';
import { OriginOfOption, ProjectState } from '../../../utils/enums/project';
import { SERVICE_CONFIG } from '../../../utils/enums/service';
import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';
import i18n from '../../../i18n';
import { RootState } from '../../../redux/store';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { IServiceConfiguration } from '../../../utils/types/modelTypes';
import { IProjectOptionDetails } from '../../../utils/types/responseTypes';
import {
  convertDuration,
  dateFormat,
  getAverageProjectSize,
  getEndDate,
  t,
} from './projectOptionHelpers';
import { DurationType } from '../../../utils/enums/pageComponents';
import type { ProjectOptionDetailsForm } from './ProjectOptionModal';

const projectGainProbabilityTooltip = (
  <Tooltip
    title={
      <>
        <p style={{ fontSize: '14px' }}>
          {t('projectGainProbabilityTooltipTitle')}
        </p>
        <div style={{ fontSize: '12px' }}>
          <div>{t('projectGainProbabilityTooltipCriteriaOne')}</div>
          <div>{t('projectGainProbabilityTooltipCriteriaTwo')}</div>
          <div>{t('projectGainProbabilityTooltipCriteriaThree')}</div>
          <div>{t('projectGainProbabilityTooltipCriteriaFour')}</div>
          <br />
          <div>{t('projectGainProbabilityTooltipContentOne')}</div>
          <div>{t('projectGainProbabilityTooltipContentTwo')}</div>
          <div>{t('projectGainProbabilityTooltipContentThree')}</div>
          <div>{t('projectGainProbabilityTooltipContentFour')}</div>
          <div>{t('projectGainProbabilityTooltipContentFive')}</div>
        </div>
      </>
    }
  >
    <Button color="link">
      <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '20px' }} />
    </Button>
  </Tooltip>
);

const durationOptions = enumValuesToDropdownOptions(
  Object.values(DurationType)
);

const originOfOptionOptions = enumValuesToDropdownOptions(
  Object.values(OriginOfOption),
  'Options'
);

interface IProps extends PropsFromRedux {
  project: IProjectOptionDetails;
  departmentList: IDropdownOption<number>[];
  formValues: ProjectOptionDetailsForm;
  setFormValues: Dispatch<SetStateAction<ProjectOptionDetailsForm>>;
}

/**
 * Estimations Card in Project Option Modal -
 * Displays project start and end dates, duration, project gain probability,
 * average project size, total hours estimated, departments, and origin of option
 */
const ProjectOptionEstimations = ({
  project: { id: projectId },
  departmentList,
  formValues: {
    state,
    start,
    projectGainProbability,
    duration,
    durationType,
    end,
    averageProjectSize,
    totalHoursEstimated,
    departments,
    originOfOption,
  },
  setFormValues,
  configurations,
}: IProps) => {
  const { configValue: workingHours } = configurations.find(
    ({ service, configKey }) =>
      service === SERVICE_CONFIG.workingHoursPerMonth.service &&
      configKey === SERVICE_CONFIG.workingHoursPerMonth.key
  ) as IServiceConfiguration;

  const isRequired =
    !!projectId &&
    (state === ProjectState.VAGUE ||
      state === ProjectState.DEFINED ||
      state === ProjectState.PROPOSAL_AHEAD ||
      state === ProjectState.PROPOSAL_TECHNICAL_GO ||
      state === ProjectState.OFFER_SENT ||
      state === ProjectState.ESTIMATING ||
      state === ProjectState.ALIGNING);

  const disabled =
    state === ProjectState.LOST || state === ProjectState.ORDERED;

  const handleStartChange = (start: Moment | string) => {
    setFormValues((formValues) => ({
      ...formValues,
      start: moment(start).toISOString(),
      ...(!start && {
        duration: 0,
        durationType: {
          label: 'Months',
          value: DurationType.MONTHS as string,
        },
        end: '',
      }),
      ...(start &&
        duration && {
          end: getEndDate(moment(start), duration, durationType.value),
        }),
    }));
  };

  const handleProjectGainProbabilityChange = (
    projectGainProbability: string
  ) => {
    const regex = /^\d{0,3}(\.\d{0,2}){0,1}$/;

    if (projectGainProbability.match(regex)) {
      setFormValues((formValues) => ({
        ...formValues,
        projectGainProbability: parseFloat(projectGainProbability),
      }));
    }
  };

  const handleDurationChange = (duration: string) => {
    const regex = /^\d{0,10}(\.\d{0,1}){0,1}$/;

    if (duration.match(regex)) {
      setFormValues((formValues) => ({
        ...formValues,
        duration: parseFloat(duration),
        end: getEndDate(
          moment(start),
          parseFloat(duration),
          durationType.value
        ),
      }));
    }
  };

  const handleDurationTypeChange = (durationType: IDropdownOption) => {
    setFormValues((formValues) => ({
      ...formValues,
      durationType,
      ...(duration && {
        duration: convertDuration(
          moment(start),
          moment(end),
          durationType.value
        ),
      }),
    }));
  };

  const handleAverageProjectSizeChange = (averageProjectSize: string) => {
    const regex = /^\d{0,10}(\.\d{0,2}){0,1}$/;

    if (averageProjectSize.match(regex)) {
      setFormValues((formValues) => ({
        ...formValues,
        averageProjectSize: parseFloat(averageProjectSize),
      }));

      if (totalHoursEstimated) {
        const duration =
          totalHoursEstimated /
          parseFloat(averageProjectSize) /
          parseInt(workingHours as string, 10);

        const parsedDuration = Number.isFinite(duration)
          ? parseFloat(duration.toFixed(2))
          : 0;

        const newEndDate = getEndDate(
          moment(start),
          parsedDuration,
          DurationType.MONTHS
        );

        setFormValues((formValues) => ({
          ...formValues,
          end: newEndDate,
          duration: convertDuration(
            moment(start),
            moment(newEndDate),
            durationType.value
          ),
        }));
      }
    }
  };

  const handleDepartmentsChange = (departments: IDropdownOption<number>[]) => {
    setFormValues((formValues) => ({
      ...formValues,
      departments,
    }));
  };

  const handleOriginOfOptionChange = (originOfOption: IDropdownOption) => {
    setFormValues((formValues) => ({
      ...formValues,
      originOfOption,
    }));
  };

  const handleTotalHoursEstimatedChange = (totalHoursEstimated: string) => {
    const regex = /^\d{0,10}(\.\d{0,2}){0,1}$/;

    if (totalHoursEstimated.match(regex)) {
      setFormValues((formValues) => ({
        ...formValues,
        totalHoursEstimated: parseFloat(totalHoursEstimated),
        ...(duration && {
          averageProjectSize: getAverageProjectSize(
            parseFloat(totalHoursEstimated),
            moment(start),
            moment(end),
            workingHours as string
          ),
        }),
      }));
    }
  };

  const fields = [
    {
      label: t('dateOfProjectStart'),
      value: (
        <Datetime
          dateFormat={dateFormat}
          closeOnSelect
          timeFormat={false}
          inputProps={{
            placeholder: dateFormat,
            disabled,
          }}
          value={start ? moment(start).format(dateFormat) : ''}
          onChange={handleStartChange}
          locale={i18n.language}
        />
      ),
      isRequired,
    },
    {
      label: t('probabilityOfProjectGain'),
      value: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            aria-label="project-option-estimations-input-gain"
            type="number"
            max="100.00"
            step="10"
            value={projectGainProbability}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleProjectGainProbabilityChange(event.target.value)
            }
            disabled={disabled}
            style={{ width: '90px' }}
          />
          <span style={{ marginLeft: '10px' }}>%</span>
          {projectGainProbabilityTooltip}
        </div>
      ),
      isRequired,
    },
    {
      label: t('totalProjectDuration'),
      value: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Input
            aria-label="project-option-estimations-input-duration"
            type="number"
            min="0"
            step={durationType?.value === DurationType.DAYS ? '1' : '0.5'}
            maxLength={durationType?.value === DurationType.MONTHS ? 2 : 7}
            value={duration}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleDurationChange(event.target.value)
            }
            title={t('projectDurationTooltip')}
            disabled={!start || disabled}
            style={{ width: '90px' }}
          />
          <Select
            aria-label="project-option-estimations-input-duration-type"
            options={durationOptions}
            value={durationType}
            onChange={handleDurationTypeChange}
            isDisabled={!start || disabled}
          />
        </div>
      ),
      isRequired,
    },
    {
      label: t('dateOfProjectEnd'),
      value: (
        <Datetime
          dateFormat={dateFormat}
          timeFormat={false}
          inputProps={{
            placeholder: dateFormat,
            disabled: true,
          }}
          value={end ? moment(end).format(dateFormat) : ''}
          locale={i18n.language}
        />
      ),
    },
    {
      label: t('averageProjectSize'),
      value: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Input
            aria-label="project-option-estimations-input-size"
            type="number"
            step="1"
            value={averageProjectSize}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleAverageProjectSizeChange(event.target.value)
            }
            disabled={disabled}
            style={{ width: '90px' }}
          />
          <span>FTE</span>
        </div>
      ),
      isRequired,
    },
    {
      label: t('topicDepartments'),
      value: (
        <Select
          aria-label="project-option-estimations-input-departments"
          isMulti
          isClearable
          placeHolder={t('selectDepartments')}
          options={departmentList}
          value={departments}
          onChange={handleDepartmentsChange}
          isDisabled={disabled}
        />
      ),
    },
    {
      label: t('originOfOption'),
      value: (
        <Select
          aria-label="project-option-estimations-input-origin"
          placeholder={t('selectOriginOfOption')}
          options={originOfOptionOptions}
          value={originOfOption.value ? originOfOption : ''}
          onChange={handleOriginOfOptionChange}
          isDisabled={state !== ProjectState.VAGUE}
        />
      ),
      isRequired: state === ProjectState.VAGUE,
    },
  ];

  if (state === ProjectState.ESTIMATING || state === ProjectState.ALIGNING) {
    fields.splice(5, 0, {
      label: t('totalHoursEstimated'),
      value: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Input
            aria-label="project-option-estimations-input-hours"
            type="number"
            step="1"
            value={totalHoursEstimated}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleTotalHoursEstimatedChange(event.target.value)
            }
            disabled={disabled}
            style={{ width: '90px' }}
          />
          <span>h</span>
        </div>
      ),
      isRequired:
        state === ProjectState.ESTIMATING || state === ProjectState.ALIGNING,
    });
  }

  useEffect(() => {
    if (totalHoursEstimated) {
      setFormValues((formValues) => ({
        ...formValues,
        averageProjectSize: getAverageProjectSize(
          totalHoursEstimated,
          moment(start),
          moment(end),
          workingHours as string
        ),
      }));
    }
  }, [end]);

  return <DetailsCard title={t('estimations')} fields={fields} border />;
};

const mapStateToProps = (state: RootState) => ({
  configurations: state.configs.configs,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProjectOptionEstimations);
