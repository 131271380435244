import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import EmployeeDetailsProvider from './EmployeeDetailProvider';
import EmployeeDetailsCard from './EmployeeDetailsCard';
import { edt } from './employeeDetailHelper';

interface IProps
  extends RouteComponentProps<{ id: string }>,
    IWithModalsProps {}

const EmployeeDetails = ({
  // RouteComponentProps
  location: { pathname },
}: IProps) => (
  <Container fluid>
    <Header className="user-details-header">
      <HeaderTitle>{edt('employeeDetails')}</HeaderTitle>
      {generateBreadcrumb(pathname, edt('employees'))}
    </Header>
    <EmployeeDetailsProvider>
      <EmployeeDetailsCard />
    </EmployeeDetailsProvider>
  </Container>
);

export default withRouter(withModals(EmployeeDetails));
