import React from 'react';
import { Link } from 'react-router-dom';

import TableDataComponent from '../../../components/tables/TableDataComponent';
import { ContactInfoType } from '../../../utils/enums/contact';
import { TargetPriority } from '../../../utils/enums/customer';
import {
  IObjectContactInformation,
  IObjectInvolvedResponsible,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import { ICountryState } from '../../../utils/types/modelTypes';
import {
  ICustomerDetail,
  ICustomerSiteDetail,
  ICustomerSiteListItem,
} from '../../../utils/types/responseTypes';
import i18n from '../../../i18n';

/**
 * Translator function for Customer Details
 * @param keyName Key for phrase to be translated
 * @returns Translated string
 */
export const t = (keyName: string) => i18n.t(`CustomerList.${keyName}`);
export const translateCustomerSite = (keyName: string) =>
  i18n.t(`CustomerSiteList.${keyName}`);

export const customerFields = {
  name: 'name',
  customerAbbreviation: 'customerAbbreviation',
  targetPriority: 'targetPriority',
  state: 'state',
  customerAccount: 'customerAccount',
  sectors: 'sectors',
  paymentGoal: 'paymentGoal',
  sharepointLink: 'sharepointLink',
  responsible: 'responsible',
  involvedResponsibles: 'involvedResponsibles',
  contactInformation: 'contactInformation',
};

export const customerSiteFields = {
  customer: 'customer',
  location: 'location',
  country: 'country',
  sectors: 'sectors',
  contactInformation: 'contactInformation',
  contactPersons: 'contactPersons',
  name: 'name',
  street: 'street',
  zipCode: 'zipCode',
  countryState: 'countryState',
  customerSiteType: 'customerSiteType',
  targetPriority: 'targetPriority',
  responsible: 'responsible',
  involvedEmployees: 'involvedEmployees',
};

export interface IEditedCustomerDetails {
  id: number;
  name: string;
  customerAbbreviation: string;
  targetPriority: string;
  state: string;
  customerAccount: IObjectNameAndId | null;
  sectors: IObjectNameAndId[];
  paymentGoal: number | null;
  sharepointLink: string | null;
  responsible: IObjectNameAndId;
  involvedResponsibles: IObjectInvolvedResponsible[];
  contactInformation: IObjectContactInformation[];
  contactPersons: IObjectNameAndId[];

  [key: string]:
    | IObjectContactInformation[]
    | IObjectNameAndId
    | IObjectNameAndId[]
    | number
    | string
    | null
    | undefined;
}

export interface ICollapsibleCustomerSiteEditedDetails {
  id: number;
  customer: IObjectNameAndId;
  state?: string;
  location: string;
  country: IObjectNameAndId;
  sectors: IObjectNameAndId[];
  contactInformation: IObjectContactInformation[];
  contactPersons: IObjectNameAndId[];
  name: string;
  street: string;
  zipCode: string;
  countryState: ICountryState;
  customerSiteType: string;
  targetPriority: string;
  responsible: IObjectNameAndId;
  involvedEmployees: IObjectInvolvedResponsible[];
}

export const emptyObjectNameAndId: IObjectNameAndId = {
  id: 0,
  name: '',
};

export const customerSiteDetailsState = (
  customer: IObjectNameAndId,
  customerSectors: IObjectNameAndId[],
  currentUser: IObjectNameAndId
) => ({
  id: 0,
  state: '',
  location: '',
  customer,
  country: emptyObjectNameAndId,
  sectors: customerSectors,
  contactInformation: [] as IObjectContactInformation[],
  contactPersons: [] as IObjectNameAndId[],
  name: '',
  street: '',
  zipCode: '',
  countryState: { ...emptyObjectNameAndId, country: emptyObjectNameAndId },
  customerSiteType: '',
  targetPriority: TargetPriority.TO_BE_DEFINED as string,
  responsible: currentUser,
  involvedEmployees: [] as IObjectInvolvedResponsible[],
});

export const distributeContext = (customerDetails: ICustomerDetail) => {
  const basicCustomerDetails = {} as IEditedCustomerDetails;
  Object.keys(customerFields).map((field) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    if ((customerDetails as any)[field] !== null)
      basicCustomerDetails[field] = (customerDetails as any)[field];
  });
  return basicCustomerDetails;
};

export const distributeFieldValues = ({
  customer,
  location,
  country,
  sectors,
  contactInformation,
  contactPersons,
  name,
  street,
  zipCode,
  countryState,
  customerSiteType,
  targetPriority,
  responsible,
  involvedEmployees,
}: ICustomerSiteDetail) =>
  ({
    customer,
    location,
    country,
    sectors,
    contactInformation,
    contactPersons,
    name,
    street,
    zipCode,
    countryState,
    customerSiteType,
    targetPriority,
    responsible,
    involvedEmployees,
  } as ICollapsibleCustomerSiteEditedDetails);

export const getEditedFieldValues = (
  fieldsWithUpdates: string[],
  currentFieldValues: ICollapsibleCustomerSiteEditedDetails
) => {
  const detailsToBeSaved = {} as ICollapsibleCustomerSiteEditedDetails;
  fieldsWithUpdates.forEach((field) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (detailsToBeSaved as any)[field] = (currentFieldValues as any)[field];
  });
  return detailsToBeSaved;
};

export const convertCustomerSiteDetailsToCollapsibleListItem = ({
  id,
  name,
  customerSiteType,
  targetPriority,
  sectors,
  customerSiteState,
  contactPersons,
  responsible,
}: ICustomerSiteDetail) =>
  ({
    id,
    name,
    customerSiteType,
    targetPriority,
    sectors,
    customerSiteState,
    contactPersons,
    responsible,
  } as ICustomerSiteListItem);

/**
 * Returns the contactInformation component based on type
 * @param {*} contactPerson
 * @param {*} type
 */
export const getContactPersonInfoComponentBasedOnType = (
  contactId: number,
  contactInformation: IObjectContactInformation[],
  type: string
) => {
  const contactInformations =
    contactInformation?.filter((info) => info.type === type) ?? [];

  const components =
    type === ContactInfoType.EMAIL
      ? contactInformations.map((email) => (
          <a href={`mailto:${email.info}`} key={contactId}>
            {email?.info}
          </a>
        ))
      : contactInformations.map(
          (contactInformation) => contactInformation?.info
        );

  return <TableDataComponent components={components} />;
};

export const showCustomerAccountLink = (
  customerAccount: IObjectNameAndId | undefined
) =>
  customerAccount && customerAccount.name !== '' ? (
    <Link to={`/customer/accounts/details/${customerAccount.id}`}>
      {customerAccount.name}
    </Link>
  ) : (
    i18n.t('CustomerList.none')
  );
