import React, { useContext, useEffect } from 'react';
import {
  Card,
  CardBody,
  Table,
  Input,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import { ConnectedProps, connect } from 'react-redux';

import InputFormLabel from '../../../../components/form/InputFormLabel';
import { OrderSummaryContext } from './OrderSummaryProvider';
import { ORDER_INVOICE_SENDING } from '../../../../utils/enums/order';
import { IDropdownOption } from '../../../../utils/types/commonTypes';
import withModals, { IWithModalsProps } from '../../../../utils/withModals';
import ModifyProjectIdModal from './ModifyProjectIdModal';
import DetailsCard from '../../../../components/cards/DetailsCard';
import { translate } from './orderHelper';
import { RootState } from '../../../../redux/store';
import { generateProjectId } from '../../../../services/api/project';

interface IProps extends IWithModalsProps, PropsFromRedux {}

const OrderInvoiceDetailsCard = ({
  modalFormHandler,
  toggleModalForm,
  modalErrorHandler,
  projectList,
}: IProps) => {
  const { project, formValues, setFormValues } =
    useContext(OrderSummaryContext);

  const { id: projectReferenceId } = project;

  const {
    newProject,
    existingProject,
    preliminaryOrder,
    nameOfRecipient,
    address,
    zipCode,
    city,
    country,
    emailAddress,
    contractor,
    sendInvoiceBy,
    projectId,
  } = formValues;

  const projectIdOptionList = projectList.map(
    (project) =>
      ({
        value: project.id,
        label: `${project.projectId}`,
      } as IDropdownOption<number>)
  );

  const sendingInvoiceOptionList = Object.values(ORDER_INVOICE_SENDING).map(
    (option, index) =>
      ({ label: option.name, value: index } as IDropdownOption<number>)
  );

  const handleGenerateProjectId = async () => {
    try {
      const { data: projectId } = await generateProjectId(projectReferenceId);
      setFormValues((values) => ({ ...values, projectId }));
    } catch (error) {
      modalErrorHandler(translate('failedToGenerateProjectId'), error);
    }
  };

  const handleModifyProjectIdModal = () => {
    modalFormHandler(
      translate('modifyProjectId'),
      <ModifyProjectIdModal onClose={toggleModalForm} />
    );
  };

  const handlePreliminaryOrder = () =>
    setFormValues((values) => ({
      ...values,
      preliminaryOrder: !preliminaryOrder,
    }));

  const detailList = [
    {
      label: translate('nameOfRecipient'),
      value: (
        <Input
          value={nameOfRecipient}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFormValues((values) => ({
              ...values,
              nameOfRecipient: event.target.value,
            }))
          }
        />
      ),
      isRequired: !preliminaryOrder,
    },
    {
      label: translate('address'),
      value: (
        <Input
          type="textarea"
          value={address}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFormValues((values) => ({
              ...values,
              address: event.target.value,
            }))
          }
        />
      ),
      isRequired: !preliminaryOrder,
    },
    {
      label: translate('zipCode'),
      value: (
        <Input
          value={zipCode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFormValues((values) => ({
              ...values,
              zipCode: event.target.value,
            }))
          }
        />
      ),
      isRequired: !preliminaryOrder,
    },
    {
      label: translate('city'),
      value: (
        <Input
          value={city}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFormValues((values) => ({ ...values, city: event.target.value }))
          }
        />
      ),
      isRequired: !preliminaryOrder,
    },
    {
      label: translate('country'),
      value: (
        <Input
          value={country}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFormValues((values) => ({
              ...values,
              country: event.target.value,
            }))
          }
        />
      ),
      isRequired: !preliminaryOrder,
    },
    {
      label: translate('emailAddress'),
      value: (
        <Input
          value={emailAddress}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFormValues((values) => ({
              ...values,
              emailAddress: event.target.value,
            }))
          }
        />
      ),
    },
    {
      label: translate('contractor'),
      value: (
        <Input
          value={contractor}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFormValues((values) => ({
              ...values,
              contractor: event.target.value,
            }))
          }
        />
      ),
    },
    {
      label: translate('sendInvoiceBy'),
      value: (
        <Select
          value={sendingInvoiceOptionList.filter(
            (option) => sendInvoiceBy.label === option.label
          )}
          options={sendingInvoiceOptionList.filter(
            (option) => sendInvoiceBy.label !== option.label
          )}
          onChange={(option: IDropdownOption<number>) =>
            setFormValues((values) => ({ ...values, sendInvoiceBy: option }))
          }
        />
      ),
    },
    {
      label: translate('projectId'),
      value: (
        <div>
          <Input value={projectId} readOnly />
          <Button color="primary" onClick={() => handleModifyProjectIdModal()}>
            {translate('modifyId')}
          </Button>
        </div>
      ),
      isRequired: !preliminaryOrder,
    },
  ];

  useEffect(() => {
    handleGenerateProjectId();
  }, []);

  return (
    <Card className="common-card with-border">
      <CardBody>
        <Container className="mb-10">
          <Row className="d-flex justify-content-between">
            <Col className="checkbox-col col-auto">
              <Input
                aria-label="new-project-checkbox"
                type="checkbox"
                checked={newProject}
                onClick={() => {
                  setFormValues((values) => ({
                    ...values,
                    newProject: true,
                    existingProject: false,
                  }));
                }}
              />
              <span className="px-2">{translate('newProject')}</span>
            </Col>
            <Col className="checkbox-col col-auto">
              <Input
                aria-label="existing-project-checkbox"
                type="checkbox"
                checked={existingProject}
                onClick={() => {
                  setFormValues((values) => ({
                    ...values,
                    newProject: false,
                    existingProject: true,
                  }));
                }}
              />
              <span className="px-2">{translate('partOfExistingProject')}</span>
            </Col>
            <Col className="checkbox-col col-auto">
              <InputFormLabel isRequired text="" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                aria-label="preliminary-order-checkbox"
                type="checkbox"
                checked={preliminaryOrder}
                onClick={handlePreliminaryOrder}
              />
              <span className="px-2">
                {translate('orderNotYetPhysicallyReceived')}
              </span>
            </Col>
          </Row>
        </Container>
        <Container>
          <Table borderless size="sm">
            {existingProject && (
              <tbody>
                <tr>
                  <th>
                    <InputFormLabel isRequired text={translate('projectId')} />
                  </th>
                  <td>
                    <Select
                      value={projectIdOptionList.find(
                        (option) => option.label === projectId
                      )}
                      options={projectIdOptionList.filter(
                        (option) => option.label !== projectId
                      )}
                      onChange={(option: IDropdownOption<number>) =>
                        setFormValues((values) => ({
                          ...values,
                          projectId: option.label,
                        }))
                      }
                    />
                  </td>
                </tr>
              </tbody>
            )}
            {newProject && (
              <DetailsCard
                title={translate('invoiceRecipient')}
                fields={detailList}
                border
              />
            )}
          </Table>
        </Container>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (store: RootState) => ({
  projectList: store.projectList.listItems,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(OrderInvoiceDetailsCard));
