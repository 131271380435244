import {
  faInfoCircle,
  faPen,
  faPlus,
  faTrash,
  faUserSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import {
  deleteContactPerson as deleteContactPersonItem,
  removeContactPersonFromCustomer,
} from '../../../services/api/contactPerson';
import Auth from '../../../services/axios/Auth';
import CollapsibleTableCard from '../../../components/collapsibleCards/CollapsibleTableCard';
import Table from '../../../components/tables/Table';
import { ContactInfoType } from '../../../utils/enums/contact';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { isEmpty } from '../../../utils/helpers/array';
import {
  DELETE,
  READWRITE,
  REMOVE,
  SAVE_AND_GOTO_DETAILS,
  DEFAULT_MEDIUM_PAGE_RANGE,
} from '../../../utils/constants';
import { getDateFormat } from '../../../utils/helpers/date';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IActivityListItem } from '../../../utils/types/modelTypes';
import {
  IContactPersonDetail,
  ICustomerDetailContactPersonListItem,
} from '../../../utils/types/responseTypes';
import { convertContactPersonDetailsToCollapsibleListItem } from '../ContactDetails/contactPersonHelper';
import AddOrUpdateContactPersonModal from './AddOrUpdateContactPersonModal';
import { CustomerDetailsContext } from './CustomerDetailsContextProvider';
import ExistingContactPersonSelection from './ExistingContactPersonSelection';
import {
  getContactPersonInfoComponentBasedOnType,
  t,
} from './customerDetailsHelper';

interface IProps extends IWithModalsProps {}

const generateTooltip = (contactNotes: IActivityListItem[]) => (
  <Table
    tableData={contactNotes.map(({ createdOn, description }) => ({
      [t('date')]: moment(createdOn).format(getDateFormat()),
      [t('description')]: description,
    }))}
  />
);

const generateSimpleToolTip = (buttonAction: string) =>
  buttonAction === REMOVE ? (
    <div>{t('removeButtonToolTipDialogue')}</div>
  ) : (
    <div>{t('deleteButtonToolTipDialogue')}</div>
  );

const CollapsibleContactPersonCard = ({
  toggleModalForm,
  modalErrorHandler,
  modalDeleteHandler,
  modalFormHandler,
}: IProps) => {
  const { customerDetails, setCustomerDetails } = useContext(
    CustomerDetailsContext
  );
  const history = useHistory();

  const { contactPersons, name, sectors, id, customerAccount } =
    customerDetails;

  // Functions
  const handleSaveExistingContactPerson = (
    selectedContactPersons: ICustomerDetailContactPersonListItem[]
  ) => {
    setCustomerDetails({
      ...customerDetails,
      contactPersons: [...contactPersons, ...selectedContactPersons],
    });
    toggleModalForm();
  };

  const handleAddExisitingContactPerson = () => {
    modalFormHandler(
      <>
        <FontAwesomeIcon icon={faPlus} className="margin-right" />
        <span>{t('selectContactPerson')}</span>
      </>,
      <ExistingContactPersonSelection
        customerId={id}
        onSave={handleSaveExistingContactPerson}
        onCancel={toggleModalForm}
      />
    );
  };

  const handleAddingOrUpdatingContactPerson = (
    newContactPerson: IContactPersonDetail,
    afterSaveAction: string
  ) => {
    // Update contactPerson state on customerdetails
    const { contactPersons, id } = customerDetails;
    const newListContactPersons = contactPersons.find(
      ({ id }) => id === newContactPerson.id
    )
      ? contactPersons.map((contact) =>
          contact.id === newContactPerson.id ? newContactPerson : contact
        )
      : [
          ...contactPersons,
          convertContactPersonDetailsToCollapsibleListItem(newContactPerson),
        ];

    setCustomerDetails({
      ...customerDetails,
      contactPersons:
        newListContactPersons as ICustomerDetailContactPersonListItem[],
    });
    // PerformAfterSave action
    if (afterSaveAction === SAVE_AND_GOTO_DETAILS) {
      history.push({
        pathname: `/customer/customers/details/${id}/contact-person-profile/${newContactPerson.id}`,
      });
    }
    toggleModalForm();
  };

  const handleAddOrUpdateContactPerson = (contactId: number) => {
    modalFormHandler(
      <>
        <FontAwesomeIcon icon={faInfoCircle} className="margin-right" />
        <span>
          {typeof contactId === 'number'
            ? t('updateContactPerson')
            : t('addContactPerson')}
        </span>
      </>,
      <AddOrUpdateContactPersonModal
        contactPersonId={typeof contactId === 'number' ? contactId : null}
        customer={{ id, name }}
        customerSectors={sectors}
        onUpdate={handleAddingOrUpdatingContactPerson}
        onCancel={toggleModalForm}
      />,
      'xl'
    );
  };

  const handleRemoveContactPerson = (contactPersonId: number) => {
    modalDeleteHandler(
      t('removeContactPerson'),
      t('removeContactPersonConfirm'),
      () => {
        removeContactPersonFromCustomer(contactPersonId)
          .then(() => {
            const newContactList =
              contactPersons.length !== 0
                ? contactPersons.filter(({ id }) => id !== contactPersonId)
                : [];
            setCustomerDetails({
              ...customerDetails,
              contactPersons: newContactList,
            });
          })
          .catch((error) => {
            modalErrorHandler(t('failedToDeleteContactPerson'), error);
          });
      }
    );
  };

  const handleDeleteContactPerson = (contactPersonId: number) => {
    modalDeleteHandler(
      t('deleteContactPerson'),
      t('deleteContactPersonConfirm'),
      () => {
        const newContactList =
          contactPersons.length !== 0
            ? contactPersons.filter(({ id }) => id !== contactPersonId)
            : [];
        deleteContactPersonItem(contactPersonId)
          .then(() => {
            setCustomerDetails({
              ...customerDetails,
              contactPersons: newContactList,
            });
          })
          .catch((error) =>
            modalErrorHandler(t('failedToDeleteContactPerson'), error)
          );
      }
    );
  };

  const handleRemoveOrDeleteContactPerson = (
    contactPersonId: number,
    action: string
  ) =>
    action === REMOVE
      ? handleRemoveContactPerson(contactPersonId)
      : handleDeleteContactPerson(contactPersonId);

  const headerButtons = (
    <div className="d-flex align-items-center gap-1">
      <Button
        color="primary"
        size="s"
        onClick={handleAddOrUpdateContactPerson}
        data-testid="newbutton"
      >
        <FontAwesomeIcon icon={faPlus} className="margin-right" />
        <span>{t('new')}</span>
      </Button>
      <Button
        color="primary"
        size="s"
        onClick={handleAddExisitingContactPerson}
        data-testid="existingbutton"
      >
        <FontAwesomeIcon icon={faPlus} className="margin-right" />
        <span>{t('existing')}</span>
      </Button>
    </div>
  );

  const tableData = customerDetails.contactPersons.map(
    ({
      id,
      title,
      firstname,
      lastname,
      contactInformation,
      customerSite,
      role,
      notes,
    }) => ({
      [t('title')]: title?.name,
      [t('lastName')]: (
        <div>
          {!isEmpty(notes) ? (
            <Tooltip title={generateTooltip(notes)}>
              <Link
                to={`/customer/customers/details/${customerAccount.id}/contact-person-profile/${id}`}
              >
                {lastname}
              </Link>
            </Tooltip>
          ) : (
            <Link
              to={`/customer/customers/details/${customerAccount.id}}/contact-person-profile/${id}`}
            >
              {lastname}
            </Link>
          )}
        </div>
      ),
      [t('firstName')]: firstname,
      [t('phone')]: getContactPersonInfoComponentBasedOnType(
        id,
        contactInformation,
        ContactInfoType.PHONE
      ),
      [t('mobile')]: getContactPersonInfoComponentBasedOnType(
        id,
        contactInformation,
        ContactInfoType.MOBILE
      ),
      [t('email')]: getContactPersonInfoComponentBasedOnType(
        id,
        contactInformation,
        ContactInfoType.EMAIL
      ),
      [t('role')]: role,
      'Customer Site': customerSite?.name,
      [t('menu')]: Auth.hasPermission(
        [PERMISSION_URI.contactPerson.readWrite.uri],
        [READWRITE],
        id
      ) ? (
        <div className="d-flex align-items-center gap-1">
          <Button
            color="primary"
            onClick={() => handleAddOrUpdateContactPerson(id)}
          >
            <FontAwesomeIcon icon={faPen} className="margin-right" />
          </Button>
          <Tooltip title={generateSimpleToolTip(REMOVE)}>
            <Button
              color="primary"
              onClick={() => handleRemoveOrDeleteContactPerson(id, REMOVE)}
            >
              <FontAwesomeIcon icon={faUserSlash} />
            </Button>
          </Tooltip>
          <Tooltip title={generateSimpleToolTip(DELETE)}>
            <Button
              color="primary"
              onClick={() => handleRemoveOrDeleteContactPerson(id, DELETE)}
            >
              <FontAwesomeIcon icon={faTrash} className="margin-right" />
            </Button>
          </Tooltip>
        </div>
      ) : (
        ''
      ),
    })
  );

  return (
    <CollapsibleTableCard
      title={t('contactPersons')}
      tableData={tableData}
      noDataPlaceholder="No data"
      defaultSize={DEFAULT_MEDIUM_PAGE_RANGE}
      {...(Auth.hasPermission(
        [PERMISSION_URI.contactPeopleList.readWrite.uri],
        [READWRITE]
      ) && {
        headerButtons,
      })}
      striped
    />
  );
};

export default withModals(CollapsibleContactPersonCard);
