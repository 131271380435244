import moment from 'moment';

import { getDateFormat } from '../../../../utils/helpers/date';
import i18n from '../../../../i18n';
import type { OrderDetailFormValues } from './OrderSummaryProvider';
import {
  IOfferDetails,
  IOrderDetails,
} from '../../../../utils/types/responseTypes';
import {
  IDropdownOption,
  IProjectDetailOffer,
} from '../../../../utils/types/commonTypes';
import {
  dropdownOptionToObjectNameAndId,
  objectNameAndIdToDropdownOptions,
} from '../../../../utils/helpers/dropdown';

export const translate = (keyName: string) => i18n.t(`OrderSummary.${keyName}`);

export const DATE_FORMAT = getDateFormat();

export const calculateDiscountPercentage = (budget: number, value: number) =>
  Number(Math.abs((value / budget) * 100 - 100).toFixed(1));

export const convertToFourDigitString = (numberString: string) =>
  numberString.padStart(4, '0');

export const generateDefaultOrderFormValues = (
  { budget, paymentGoal }: IOfferDetails,
  { projectId, customer, customerSite, departments }: IProjectDetailOffer
) =>
  ({
    orderDate: moment().startOf('day').toISOString(),
    orderedSum: budget,
    orderNumber: '',
    paymentGoal,
    offerPartiallyOrdered: false,
    specialConditions: false,
    projectId,
    nameOfRecipient: customer.name,
    address: customerSite.street,
    zipCode: customerSite.zipCode,
    city: customerSite.location,
    country: customerSite.country,
    emailAddress: '',
    contractor: '',
    sendInvoiceBy: {} as IDropdownOption<number>,
    departments: objectNameAndIdToDropdownOptions(departments),
    startBillingDefined: moment().startOf('day').toISOString(),
    kickOffMeeting: moment().startOf('day').toISOString(),
    internalKickOff: moment().startOf('day').toISOString(),
    employeeManager: {} as IDropdownOption<number>,
    newProject: false,
    existingProject: false,
    preliminaryOrder: false,
  } as OrderDetailFormValues);

export const generateDefaultOrderValues = (
  { id: offerId, offerDocumentTitle }: IOfferDetails,
  { id: projectRefId }: IProjectDetailOffer
) =>
  ({
    offerId,
    projectRefId,
    title: offerDocumentTitle,
  } as IOrderDetails);

export const createOrderDetailsFromFormValues = (
  { id, offerId, projectRefId, title }: IOrderDetails,
  {
    orderDate,
    orderedSum: budget,
    orderNumber,
    paymentGoal,
    offerPartiallyOrdered,
    projectId,
    nameOfRecipient: invoiceName,
    address,
    zipCode,
    city,
    country,
    emailAddress,
    contractor,
    sendInvoiceBy,
    departments,
    startBillingDefined,
    kickOffMeeting,
    internalKickOff,
    employeeManager,
  }: OrderDetailFormValues
) =>
  ({
    id,
    offerId,
    projectRefId,
    title,
    orderDate,
    budget,
    orderNumber,
    offerPartiallyOrdered,
    paymentGoal,
    invoiceName,
    address,
    zipCode,
    city,
    country,
    emailAddress,
    contractor,
    projectId,
    sendInvoiceBy: dropdownOptionToObjectNameAndId(sendInvoiceBy),
    departments: departments.map((department) =>
      dropdownOptionToObjectNameAndId(department)
    ),
    startBillingDefined,
    kickOffMeeting,
    internalKickOff,
    employeeManager: dropdownOptionToObjectNameAndId(employeeManager),
  } as IOrderDetails);
