import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { sortByPropValue } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import ModalOK from '../../../components/modals/ModalOK';
import ContactPersonRoleInsertOrUpdate from './ContactPersonRoleInsertOrUpdate';

/*
 * Class that show all the contact person role
 */
class ContactPersonRoleListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactPersonRoles: [],
      contactPerson: [],
      showModal: false,
      showModalError: false,
      showModalOK: false,

      showInputForm: false,
      modalForm: null,
      modalTitle: '',
    };
    this.modalEvent = null;

    this.mainError = '';
    this.errorReason = '';
    this.errorResponse = '';

    this.translation = this.props.t;
    this.showInputModal = this.showInputModal.bind(this);
    this.toggleInputModal = this.toggleInputModal.bind(this);
  }

  componentDidMount() {
    axios.sales.get('contact-person-roles-unpaged').then((response) => {
      if (Array.isArray(response.data))
        this.setState({ contactPersonRoles: response.data });
    });

    axios.sales
      .get(`contact-people?contactPersonRoleId.notEquals=0`)
      .then((response) => {
        if (Array.isArray(response.data))
          this.setState({ contactPerson: response.data });
      });
  }

  handleError = (mainError, errorMessage) => {
    this.mainError = mainError;
    this.errorReason = 'Server Failed';
    this.errorResponse = errorMessage;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalError = () => {
    this.setState({ showModalError: this.state.showModalError });
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  toggleInputModal() {
    this.setState({ showInputForm: !this.state.showInputForm });
  }

  //Toggle for showing the modal for duplicate entries.
  ToggleModalOK = () => {
    this.setState({ showModalOK: !this.state.showModalOK });
  };

  showInputModal = () => {
    this.setState({
      modalForm: <ContactPersonRoleInsertOrUpdate onSave={this.onSaveRole} />,
      modalTitle: this.translation(
        'ContactPersonRoleListView.addContactPersonRole'
      ),
    });
    this.toggleInputModal();
  };

  onSaveRole = () => {
    this.toggleInputModal();
    this.componentDidMount();
  };

  deleteContactPersonRole = (contactPersonRole) => {
    if (
      this.state.contactPerson.some(
        (contact) => contact?.contactPersonRole['id'] === contactPersonRole?.id
      )
    ) {
      this.errorMessage =
        'Role is assigned to a contact person and cannot be deleted';
      this.ToggleModalOK();
      return;
    }
    this.toggleModal();
    this.modalEvent = () =>
      axios.sales
        .delete(`contact-person-roles/${contactPersonRole.id}`)
        .then(() => {
          let newcontactPersonRole = this.state.contactPersonRoles.filter(
            (x) => {
              return x.id !== contactPersonRole.id;
            }
          );
          this.setState({ contactPersonRoles: newcontactPersonRole });
        })
        .catch((error) => {
          let mainError = 'Failed to delete Contact Person Role';
          this.handleError(mainError, error.message);
        });
  };

  preparedTableData = (salesData) => {
    const newTableData = [];
    if (Array.isArray(salesData) && salesData.length > 0) {
      salesData.forEach((roles) => {
        const entry = {
          id: roles?.id,
          role: roles?.role,
          deleteButton: (
            <>
              <Button
                color="primary"
                onClick={() => this.deleteContactPersonRole(roles)}
              >
                {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
              </Button>
            </>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  render() {
    const preparedColumns = [
      {
        type: 'data',
        header: this.translation('ContactPersonRoleListView.role'),
        accessor: 'role',
        show: 'true',
        link: '/settings/sales/contact-person-roles-update/',
        linkAccessor: 'id',
      },
      {
        type: 'data',
        header: this.translation('ContactPersonRoleListView.delete'),
        accessor: 'deleteButton',
        show: 'true',
        alignRight: 'true',
      },
    ];

    return (
      <Container fluid>
        <div>
          <Button
            color="primary"
            className="float-end"
            onClick={() => this.showInputModal()}
          >
            {generateTitle(
              BUTTON_TITLE_ENUM.ADD.code,
              this.translation('ContactPersonRoleListView.add')
            )}
          </Button>
          <br />
          <br />
        </div>

        <DynamicTable
          data={this.preparedTableData(
            sortByPropValue(this.state.contactPersonRoles, 'role')
          )}
          columns={preparedColumns}
        />

        <ModalDelete
          isOpen={this.state.showModal}
          event={this.modalEvent}
          onClose={this.toggleModal}
          modalTitle={this.translation(
            'ContactPersonRoleListView.deleteContactPersonRoleRecord'
          )}
          modalBodyText={this.translation(
            'ContactPersonRoleListView.deleteContactPersonRoleConfirm'
          )}
        />
        <ModalForm
          isOpen={this.state.showInputForm}
          eventOnClose={this.toggleInputModal}
          ref={this.state.modalForm}
          modalTitle={this.state.modalTitle}
        >
          {this.state.modalForm}
        </ModalForm>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalOK
          isOpen={this.state.showModalOK}
          onClose={this.ToggleModalOK}
          modalTitle="Error"
          modalBodyText={this.errorMessage}
        />
      </Container>
    );
  }
}

export default withRouter(withTranslation()(ContactPersonRoleListView));
