import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Container,
  Card,
  CardHeader,
  Button,
  CardTitle,
  CardBody,
  Col,
  Row,
} from 'reactstrap';
import { ConnectedProps, connect } from 'react-redux';
import Select from 'react-select';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  setFilters,
  toggleFilter,
  fetchEmployeesWithCustomerSiteListPermission,
  fetchCustomerSiteListItem,
} from '../../../redux/customerSiteListSlice';
import withModals, {
  IWithModalsProps,
} from '../../../utils/withModals';
import { RootState } from '../../../redux/store';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import Header from '../../../components/layout/Header';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import FadeAlert from '../../../components/layout/FadeAlert';
import Auth from '../../../services/axios/Auth';
import {
  AccessType,
  PERMISSION_URI,
} from '../../../utils/enums/permission';
import { generateTitle } from '../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import ResponsibleOwnershipDropdown from '../../../components/dropdowns/ResponsibleOwnershipDropdown';
import { isEmpty } from '../../../utils/helpers/array';
import CustomerSiteListTable from './CustomerSiteListTable';
import { Ownership } from '../../../utils/enums/ownership';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { t } from './customerSiteListHelper';
import { ICustomerSiteDetail } from '../../../utils/types/responseTypes';
import AddOrUpdateCustomerSiteModal from '../CustomerDetails/AddOrUpdateCustomerSiteModal';

interface IProps
  extends IWithModalsProps,
    PropsFromRedux,
    RouteComponentProps {}

/**
 * Customer Site List page
 * Contains CustomerSiteListTable, Create customer site
 * and toggling of table filter
 */
const CustomerSiteList = ({
  // With Modals
  toggleModalForm,
  modalErrorHandler,
  modalFormHandler,
  // Redux State
  filters,
  employeesWithCustomerSiteListPermission,
  // Redux Actions
  setFilters,
  toggleFilter,
  fetchEmployeesWithCustomerSiteListPermission,
  fetchCustomerSiteListItem,
  location,
}: IProps) => {
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch employees with customer site list permission for filter dropdown on component mount
  useEffect(() => {
    if (isEmpty(employeesWithCustomerSiteListPermission)) {
      fetchEmployeesWithCustomerSiteListPermission((error) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the values selected in the ownership filter
   * @param ownership
   */
  const handleOwnershipFilter = (ownership: Ownership | Ownership[]) => {
    if (
      ownership === Ownership.ALL ||
      ownership === Ownership.ALL.toLowerCase()
    ) {
      setFilters({
        ...filters,
        ownership: [],
        responsibleWithPermission: null,
      });
    } else {
      setFilters({
        ...filters,
        ownership: Array.isArray(ownership) ? ownership : [ownership],
      });
    }
  };

  /**
   * Handles the values selected in the responsible filter
   * @param responsible
   */
  const handleResponsibleFilter = (responsible: IDropdownOption<number>) => {
    setFilters({
      ...filters,
      responsibleWithPermission: responsible,
    });
  };

  /**
   * Gets the newly saved customer site
   * @param customerSite
   */
  const getSavedCustomerSite = ({
    id: customerSiteId,
    name: customerSiteName,
  }: // eslint-disable-next-line require-await
  ICustomerSiteDetail) => {
    fetchCustomerSiteListItem(
      customerSiteId,
      () => {
        setSuccessMessage(`${t('customerSiteAdded')} - ${customerSiteName}`);
      },
      (error) => {
        modalErrorHandler(t('failedToAddCustomerSiteToList'), error);
      }
    );
    toggleModalForm();
  };

  /**
   * Handles the adding of customer site
   */
  const handleAddNewCustomerSite = () => {
    modalFormHandler(
      generateTitle(BUTTON_TITLE_ENUM.ADD.code, t('addCustomerSite')),
      <AddOrUpdateCustomerSiteModal
        employeesWithPermission={employeesWithCustomerSiteListPermission}
        existingCustomer={{ id: 0, name: '' }}
        customerSectors={[]}
        customerSiteId={0}
        onSave={getSavedCustomerSite}
        onCancel={toggleModalForm}
      />,
      'xl'
    );
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('customerSites')}</HeaderTitle>
        {generateBreadcrumb(location.pathname, t('customerSite'))}
      </Header>
      <Card>
        <CardHeader>
          {successMessage && (
            <FadeAlert color="success">{successMessage}</FadeAlert>
          )}
          <div
            className="card-actions float-end"
            style={{ paddingRight: '5px' }}
          >
            <Button
              color="primary"
              className="fontAwesomeIconAsButton"
              onClick={() => toggleFilter()}
              aria-label="button-filter"
            >
              <FontAwesomeIcon icon={faFilter} />
            </Button>{' '}
            {Auth.hasPermission(
              [PERMISSION_URI.customerSiteList.readWrite.uri],
              [AccessType.READWRITE]
            ) && (
              <Button
                color="primary"
                size="m"
                onClick={handleAddNewCustomerSite}
              >
                {generateTitle(
                  BUTTON_TITLE_ENUM.ADD.code,
                  t('addCustomerSite')
                )}
              </Button>
            )}
          </div>
          <div
            className="card-actions float-end"
            style={{ paddingRight: '5px' }}
          />
          <CardTitle className="mb-0">
            <h1>{t('customerSitesList')}</h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <ResponsibleOwnershipDropdown
                includeResponsibleAndInvolvedOption
                value={filters.ownership}
                onChange={handleOwnershipFilter}
              />
            </Col>
            {Auth.hasPermission(
              [PERMISSION_URI.fullCustomerSiteList.read.uri],
              [AccessType.READ]
            ) && (
              <Col md={4}>
                <Select
                  value={filters.responsibleWithPermission}
                  options={employeesWithCustomerSiteListPermission}
                  onChange={handleResponsibleFilter}
                  styles={{
                    menu: (styles: { [key: string]: React.CSSProperties }) => ({
                      ...styles,
                      zIndex: 100,
                    }),
                  }}
                  isDisabled={isEmpty(filters.ownership)}
                />
              </Col>
            )}
          </Row>
          <CustomerSiteListTable />
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  filters: store.customerSiteList.filters,
  employeesWithCustomerSiteListPermission:
    store.customerSiteList.additionalState?.employeesWithPermission,
});

const mapDispatchToProps = {
  setFilters,
  toggleFilter,
  fetchEmployeesWithCustomerSiteListPermission,
  fetchCustomerSiteListItem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(CustomerSiteList)));
