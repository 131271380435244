import { trackPromise } from 'react-promise-tracker';
import axios, { AxiosResponse } from 'axios';

import { Url } from './constants';
import { IOfferDetails, IOfferVersion } from '../../utils/types/responseTypes';
import { urlBuilder } from '../../utils/helpers/url';
import { IOffer } from '../../utils/types/modelTypes';

export const getOffers = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<IOffer[]>>(
    axios.get(`${urlBuilder(Url.OFFERS, queryParameters)}&sort=id%2Cdesc`)
  );

export const getOfferDetails = (offerId: number) =>
  trackPromise<AxiosResponse<IOfferDetails>>(
    axios.get(urlBuilder(`${Url.OFFER_DETAILS}/${offerId}`, {}))
  );

export const getOfferDetailsOlderVersion = (
  offerId: number,
  selectedVersion: number
) =>
  trackPromise<AxiosResponse<IOfferDetails>>(
    axios.get(
      urlBuilder(`${Url.OLDER_OFFER_DETAILS}/${offerId}/${selectedVersion}`, {})
    )
  );

export const updateOffer = (offer: IOfferDetails) =>
  trackPromise<IOfferDetails>(
    axios.put(urlBuilder(`${Url.OFFERS}}`, {}), offer)
  );

// Save/Update a new Offer
export const saveOffer = (offer: IOfferDetails) => {
  if (offer.id == null) {
    return trackPromise<AxiosResponse<IOfferDetails>>(
      axios.post(urlBuilder(`${Url.OFFER_DETAILS}`, {}), offer)
    );
  }
  return trackPromise<AxiosResponse<IOfferDetails>>(
    axios.patch(urlBuilder(`${Url.OFFER_DETAILS}`, {}), offer)
  );
};

/**
 * Save a new version of offer
 * @param offer
 * @returns Offer Details that is a new version
 */
export const saveNewVersionOffer = (offer: IOfferDetails) =>
  trackPromise<AxiosResponse<IOfferDetails>>(
    axios.post(urlBuilder(`${Url.OFFER_DETAILS}/new-version`, {}), offer)
  );

/**
 * Updates the offer to SENT
 * @param sentOffer
 * @returns OfferDetails
 */
export const sendOffer = (sentOffer: IOfferDetails) =>
  trackPromise<AxiosResponse<IOfferDetails>>(
    axios.patch(urlBuilder(`${Url.OFFER_DETAILS}`, {}), sentOffer)
  );

export const saveOfferVersion = (offerVersion: IOfferVersion) =>
  trackPromise<AxiosResponse>(
    axios.post(urlBuilder(`${Url.OFFER_VERSIONS}`, {}), offerVersion)
  );

export const getOfferCount = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<number>>(
    axios.get(urlBuilder(`${Url.OFFERS}/count`, queryParameters))
  );

export const deleteOffer = (offerId: number) =>
  trackPromise<AxiosResponse>(
    axios.delete(urlBuilder(`${Url.OFFERS}/${offerId}`, {}))
  );
