import React from 'react';
import { Link } from 'react-router-dom';

import Auth from '../../services/axios/Auth';
import { SUBJECT_TYPE } from '../enums/employee';
import { ENTITIES_LINK, EntityType } from '../enums/pageComponents';
import { AccessType, PERMISSION_URI } from '../enums/permission';
import { IPermission } from '../types/modelTypes';
import { IProjectListItem } from '../types/responseTypes';
import user from '../../user';
import { DEFAULT_RADIX, NO_RESTRICTION, READ, READWRITE } from '../constants';

/**
 * Check if user has permission to read and write
 * @param entityType Type of entity to check
 * @param entityId Id of entity to check
 * @returns boolean
 */
export const userHasPermission = (entityType: EntityType, entityId: number) =>
  Auth.hasPermission(
    [PERMISSION_URI[entityType].readWrite.uri],
    [AccessType.READWRITE],
    entityId
  );

const hasPermissionForEmployeeDetails = (entityId: number) => {
  // 1st, if entityId = employeeId, then approve
  if (parseInt(user.employeeId, DEFAULT_RADIX) === entityId) {
    return true;
  }

  // 2nd, if permissionUri.fullEmployeeDetails.read.uri = true, then show
  if (
    Auth.hasPermission(
      [PERMISSION_URI.fullEmployeeDetails.read.uri],
      [READ],
      NO_RESTRICTION
    ) ||
    Auth.hasPermission(
      [PERMISSION_URI.employee.readWrite.uri],
      [READWRITE],
      NO_RESTRICTION
    )
  ) {
    return true;
  }

  // 2nd, if permissionUri.employeeAsResponsible.read.uri = true, then show if id matches entityId, skip if first is already allowed
  if (
    !Auth.hasPermission(
      [PERMISSION_URI.employeeAsResponsible.readWrite.uri],
      [READ],
      NO_RESTRICTION
    ) &&
    user.employeeResponsibles.some((el) => el.id === entityId)
  ) {
    return true;
  }
  return false;
};

/**
 * Create link to entities
 * @param entityType Type of entity (i.e. customer, contactPerson, employee)
 * @param entityName Name of entity
 * @param entityId Id of entity
 * @returns Link to entity
 */
export const createLinkIfAuthorised = (
  entityType: EntityType,
  entityName: string,
  entityId: number
) => {
  switch (entityType) {
    case EntityType.CONTACT_PERSON:
    case EntityType.PROJECT:
    case EntityType.CUSTOMER_ACCOUNT:
    case EntityType.CUSTOMER:
    case EntityType.CUSTOMER_SITE:
      return userHasPermission(entityType, entityId) ? (
        <Link to={`${ENTITIES_LINK[entityType].link}${entityId}`}>
          {entityName}
        </Link>
      ) : (
        entityName
      );
    case EntityType.EMPLOYEE:
      return hasPermissionForEmployeeDetails(entityId) ? (
        <Link to={`${ENTITIES_LINK[entityType].link}${entityId}`}>
          {entityName}
        </Link>
      ) : (
        entityName
      );
    default:
      return entityName;
  }
};

/**
 * @deprecated Transfer permissions handling to backend
 */
export const setPermissionsForProjectListItem = (
  projectListItem: IProjectListItem
) => {
  // Check if item should be added to the permission list
  if (
    projectListItem.responsible.id === parseInt(user.employeeId, 10) &&
    Array.isArray(user.permissions)
  ) {
    // Create the permission
    const permission: IPermission = {
      subjectType: SUBJECT_TYPE.employee.code,
      subjectId: parseInt(user.employeeId, 10),
      accessType: PERMISSION_URI.project.readWrite.accessType,
      entityType: PERMISSION_URI.project.readWrite.entityType,
      entityUri: PERMISSION_URI.project.readWrite.uri,
      restriction: projectListItem.id,
    };

    // Set the permissions
    user.permissions = [...user.permissions, permission];
  }
};
