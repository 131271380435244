import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ModalBody } from 'reactstrap';

import i18n from '../../i18n';
import { dateFormat } from '../../utils/constants';
import {
  dateToday,
  getActivityIcon,
  translateToDoListOverview,
} from './homeHelper';
import { IUserToDoActivity } from '../../utils/types/modelTypes';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import { updateUserToDoActivities } from '../../services/api/project';

/**
 * Calendar for ToDoListOverview
 */
interface IProps extends IWithModalsProps {
  activity: IUserToDoActivity;
  activitiesState: IUserToDoActivity[];
  setUpdatedActivities: (updatedActivities: IUserToDoActivity[]) => void;
  closeCalendar: () => void;
}

interface ICalendarObject {
  start: Moment;
  end: Moment;
  title: JSX.Element;
}

const ToDoListCalendar: React.FC<IProps> = ({
  activity,
  activitiesState,
  closeCalendar,
  setUpdatedActivities,
  modalErrorHandler,
}: IProps) => {
  const [calendarAppointment, setCalendarAppointment] =
    useState<ICalendarObject>({
      start: moment(activity.dueDate),
      end: moment(activity.dueDate),
      title: <FontAwesomeIcon icon={getActivityIcon(activity.objectType)} />,
    });

  /**
   * Handles save button click on calendar modal
   */
  const saveNewAppoinment = async (activity: IUserToDoActivity) => {
    if (activity) {
      try {
        const updatedObjectToSave = {
          ...activity,
          dueDate: moment(calendarAppointment.start).toISOString(),
        } as IUserToDoActivity;

        // Make the asynchronous call and wait for it to complete
        await updateUserToDoActivities(
          updatedObjectToSave.id as number,
          updatedObjectToSave
        );

        // Update the state after the asynchronous call is complete
        const userActivities = activitiesState.map(
          (item: IUserToDoActivity) => {
            if (
              updatedObjectToSave !== null
                ? item.id === updatedObjectToSave.id
                : false
            ) {
              return {
                ...item,
                dueDate: moment(calendarAppointment.start).toISOString(),
              };
            }
            return item;
          }
        );

        setUpdatedActivities([...userActivities]);
        closeCalendar();
      } catch (error) {
        modalErrorHandler(
          translateToDoListOverview('failedToSaveActivity'),
          error
        );
      }
    }
  };

  /**
   * Handles date click on calendar
   * @param {*} event
   */
  const dateSelect = (event: any) => {
    if (moment(event.start).format(dateFormat) > dateToday) {
      const newEvent: ICalendarObject = {
        ...calendarAppointment,
        start: moment(event.start),
        end: moment(event.start),
      };

      setCalendarAppointment(newEvent);
    }
  };

  return (
    <ModalBody>
      <div>
        <Calendar
          localizer={momentLocalizer(moment)}
          defaultDate={moment().toDate()}
          views={['month']}
          style={{ height: 400 }}
          events={[calendarAppointment]}
          selectable
          onSelectSlot={dateSelect}
          culture={i18n.language}
        />
      </div>
      <div className="float-end m-1">
        <Button
          className="m-1"
          color="primary"
          onClick={() => saveNewAppoinment(activity)}
        >
          {translateToDoListOverview('save')}
        </Button>
        <Button className="m-1" color="primary" onClick={closeCalendar}>
          {translateToDoListOverview('cancel')}
        </Button>
      </div>
    </ModalBody>
  );
};

export default withModals(ToDoListCalendar);
