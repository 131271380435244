import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Select from 'react-select';
import { Button, Col, Row } from 'reactstrap';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { SKILL_ASSIGNMENT_ENUM } from '../../../utils/enums/skill';
import { sortByPropValue } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import { SkillLevel } from '../../../utils/enums/skill';

/**
 * A dropdown that has all the employees that can be assigned to an object.\
 * props: skillSet, onChange(), onDeleteSkillSet(), selectedSkillSets, skillSetIndex, entityIndex, skills\
 */
class SkillSetDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSkillSet: this.props.skillSet ? this.props.skillSet : null,
      selectedSkill: this.props.skillSet?.skill
        ? this.props.skillSet.skill
        : null, // contains the skill that will be selected
      skillLevel: this.props.skillSet?.skillLevel
        ? this.props.skillSet.skillLevel
        : SkillLevel[1],
      skillChoices: [],
      skillOptions: [],
      skillLevelOptions: [],
      skills: [],
      selectedSkillSets: null,
      verticalAllignment: this.props.verticalAllignment
        ? this.props.verticalAllignment
        : false,
    };
    this.ModalDeleteEvent = null;
    this.modalTitle = '';
    this.modalBodyText = '';
    this.mainError = '';
    this.errorReason = '';
    this.errorResponse = '';

    this.skillLevels = Object.values(SkillLevel);
  }

  t(keyName) {
    return i18n.t('SkillSetDropdown.' + keyName);
  }

  componentDidMount() {
    const selectedSkillSets = this.props.selectedSkillSets;
    const skills = this.props.skills;

    const skillChoices = this.removeSelectedFromOption(
      selectedSkillSets,
      skills
    );

    this.setState({
      skillChoices:
        skillChoices.length > 0
          ? skillChoices.map((skill) => ({
              value: skill.id,
              label: `${skill.skill}`,
              resource: skill,
            }))
          : [],
      skillLevelOptions: this.skillLevels.map((skillLevel) => ({
        value: skillLevel.code,
        label: i18n.exists(`SkillLevelModel.${skillLevel}`)
          ? i18n.t(`SkillLevelModel.${skillLevel.label}`)
          : skillLevel.label,
      })),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const skillChoices = this.removeSelectedFromOption(
        this.props.selectedSkillSets,
        this.props.skills
      );

      let options = [];
      options = skillChoices.map((skill) => ({
        value: skill.id,
        label: `${skill.skill}`,
        resource: skill,
      }));
      this.setState({
        skillChoices: options,
        selectedSkillSet: this.props.skillSet ? this.props.skillSet : null,
      });
    }
  }

  // remove selectedSkill as one of the skill option
  removeSelectedFromOption(selectedSkillSets, skills) {
    const skillChoices = [];

    if (Array.isArray(skills) && skills.length > 0) {
      skills.forEach((skill) => {
        if (Array.isArray(selectedSkillSets) && selectedSkillSets.length > 0) {
          // If skill is not in one of the selectedSkillSets, include in the choices
          if (
            !selectedSkillSets?.some(
              (skillSet) => parseInt(skillSet?.skill?.id) === parseInt(skill.id)
            )
          ) {
            skillChoices.push(skill);
          } else {
            if (
              this.props.skillSet?.skill?.id &&
              this.props.skillSet?.skill?.id === skill.id
            ) {
              skillChoices.push(skill);
            }
          }
        } else {
          skillChoices.push(skill);
        }
      });
    }
    return skillChoices;
  }

  // Method to send the selected skill set.
  handleSkillChange = async (event) => {
    const skillSet = {
      date: this.props.skillSet?.date ? this.props.skillSet.date : null,
      employee: this.props.skillSet?.employee
        ? this.props.skillSet.employee
        : null,
      id: this.props.skillSet?.id ? this.props.skillSet.id : null,
      skill: event.resource,
      skillAssignment: this.props.skillSet?.skillAssignment
        ? this.props.skillSet.skillAssignment
        : SKILL_ASSIGNMENT_ENUM.preliminary.code,
      skillLevel: this.props.skillSet?.skillLevel
        ? this.props.skillSet.skillLevel
        : this.state.skillLevel.code,
    };
    this.setState({ selectedSkillSet: skillSet });
    this.props.onChange(
      skillSet,
      this.props.skillSetIndex,
      this.props.entityIndex
    );
  };

  handleSkillLevelChange = async (event) => {
    let skillSet = this.state.selectedSkillSet;
    if (skillSet) {
      skillSet.skillLevel = event.value;
    }
    this.setState({
      selectedSkillSet: skillSet,
      skillLevel: event.value,
    });
    this.props.onChange(
      skillSet,
      this.props.skillSetIndex,
      this.props.entityIndex
    );
  };

  render() {
    const skillChoices = this.state.skillChoices;
    const mySkillLevelOptions = this.state.skillLevelOptions;
    const mySkillSet = this.state.selectedSkillSet;
    return (
      <>
        <Row>
          <Col style={{ marginTop: 8 }} lg={'8'}>
            <Select
              size="sm"
              value={
                this.state.skillChoices && this.state.selectedSkillSet
                  ? skillChoices?.find(
                      (skill) =>
                        skill.value === this.state.selectedSkillSet?.skill?.id
                    )
                  : null
              }
              options={sortByPropValue(skillChoices, 'label')}
              isMulti={false}
              {...this.props}
              onChange={this.handleSkillChange}
              placeholder={this.t('selectSkill')}
            />
          </Col>
          <Col style={{ marginTop: 8 }} lg={'8'}>
            <Select
              value={
                mySkillSet
                  ? mySkillLevelOptions.find(
                      (dataEntry) => dataEntry.value === mySkillSet?.skillLevel
                    )
                  : mySkillLevelOptions.find(
                      (dataEntry) =>
                        dataEntry.value === this.state.skillLevel.code
                    )
              }
              options={sortByPropValue(this.state.skillLevelOptions, 'label')}
              isMulti={false}
              {...this.props}
              onChange={this.handleSkillLevelChange}
              isDisabled={this.props.readOnly}
              placeholder={this.t('selectSkillLevel')}
            />
          </Col>
          <Col style={{ marginTop: 8 }} sm="1">
            <Button
              className="float-end p-1"
              color="link"
              onClick={() =>
                this.props.onDeleteSkillSet(
                  this.state.selectedSkillSet,
                  this.props.skillSetIndex,
                  this.props.entityIndex
                )
              }
            >
              {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
            </Button>
          </Col>
        </Row>
        <br />
      </>
    );
  }
}

export default SkillSetDropdown;
