import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { ICustomerDetail } from '../../../utils/types/responseTypes';
import { getCustomerDetails } from '../../../services/api/customer';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { t } from './customerDetailsHelper';

interface IProps extends IWithModalsProps {
  children: ReactNode;
}

interface RouterParams {
  id: string;
}

interface CustomerDetailsContextType {
  customerDetails: ICustomerDetail;
  setCustomerDetails: Dispatch<SetStateAction<ICustomerDetail>>;
}

export const CustomerDetailsContext = createContext(
  {} as CustomerDetailsContextType
);

const CustomerDetailsContextProvider = ({
  children,
  modalErrorHandler,
}: IProps) => {
  const [customerDetails, setCustomerDetails] = useState<ICustomerDetail>({
    id: 0,
    name: '',
    customerAbbreviation: '',
    targetPriority: '',
    state: '',
    customerAccount: {
      id: 0,
      name: '',
    },
    sectors: [],
    paymentGoal: 0,
    sharepointLink: '',
    responsible: {
      id: 0,
      name: '',
    },
    involvedResponsibles: [],
    contactInformation: [],
    activities: [],
    contactPersons: [],
    customerSites: [],
    fileAttachments: [],
    projectHistory: [],
  });

  const { id } = useParams<RouterParams>();

  const fetchCustomerDetails = async (customerId: string) => {
    try {
      const { data: fetchedDetails } = await getCustomerDetails(customerId);
      setCustomerDetails(fetchedDetails);
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveCustomerDetails'), error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchCustomerDetails(id);
    }
  }, [id]);

  return (
    <CustomerDetailsContext.Provider
      value={{ customerDetails, setCustomerDetails }}
    >
      {children}
    </CustomerDetailsContext.Provider>
  );
};

export default withModals(CustomerDetailsContextProvider);
