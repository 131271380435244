import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { handleError, isEmpty } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import AddOrUpdateCountry from './AddOrUpdateCountry';

/*
 * Class that shows all the sectors
 */
class CountryListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      showModalDelete: false,
      showModalError: false,

      showModalForm: false,
      modalForm: null,
    };
    this.modalDeleteEvent = null;
    this.modalTitle = null;

    this.error = null;
  }

  t(keyName) {
    return i18n.t('CountryListView.' + keyName);
  }

  componentDidMount() {
    axios.sales
      .get('countries')
      .then((response) => {
        if (!isEmpty(response?.data)) {
          let countries = response.data.sort((a, b) =>
            a?.name > b?.name ? 1 : -1
          );
          this.setState({ countries: countries });
        }
      })
      .catch((error) => {
        let mainError = this.t('failedToGetCountries');
        this.handleError(mainError, error);
      });
  }

  // method to handle all errors encountered.
  // shows the modalError dialog
  handleError = (mainError, errorObject) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  toggleModalDelete = () => {
    this.setState({ showModalDelete: !this.state.showModalDelete });
  };

  toggleModalForm = () => {
    this.setState({ showModalForm: !this.state.showModalForm });
  };

  addOrUpdateCountry = (country) => {
    this.modalTitle = this.t('add');
    this.setState({
      modalForm: <AddOrUpdateCountry country={country} onSave={this.onSave} />,
    });
    this.toggleModalForm();
  };

  onSave = (country) => {
    let countries = this.state.countries;
    let index = countries.findIndex((item) => item?.id === country?.id);
    if (index >= 0) {
      countries[index] = country;
    } else {
      countries.push(country);
    }
    countries = countries?.sort((a, b) => (a?.name > b?.name ? 1 : -1));
    this.setState({ countries: countries });
    if (this.state.showModalForm) {
      this.toggleModalForm();
    }
  };

  deleteCountry = (countryToDelete) => {
    this.toggleModalDelete();
    this.modalDeleteEvent = async () => {
      if (countryToDelete?.id) {
        axios.sales
          .delete(`countries/${countryToDelete.id}`)
          .then(() => {
            let newCountries = this.state.countries.filter(
              (country) => country.id !== countryToDelete.id
            );
            this.setState({ countries: newCountries });
          })
          .catch((error) => {
            let mainError = 'Failed to delete the country';
            this.handleError(mainError, error);
          });
      }
    };
  };

  preparedTableData = (countries) => {
    const newTableData = [];
    if (!isEmpty(countries)) {
      countries.forEach((country) => {
        const entry = {
          id: country?.id,
          name: (
            <Link to="#" onClick={() => this.addOrUpdateCountry(country)}>
              {country?.name}
            </Link>
          ),
          deleteButton: (
            <>
              <Button
                color="primary"
                onClick={() => this.deleteCountry(country)}
              >
                {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
              </Button>
            </>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  render() {
    const countries = this.state.countries;
    const preparedColumns = [
      {
        type: 'component',
        header: this.t('name'),
        accessor: 'name',
        show: 'true',
      },
      {
        type: 'component',
        header: this.t('delete'),
        accessor: 'deleteButton',
        show: 'true',
        alignRight: 'true',
      },
    ];
    return (
      <Container fluid>
        <div>
          <Button
            color="primary"
            className="float-end"
            onClick={() => this.addOrUpdateCountry()}
          >
            {generateTitle(BUTTON_TITLE_ENUM.ADD.code, this.t('add'))}
          </Button>
          <br />
          <br />
        </div>

        <DynamicTable
          data={this.preparedTableData(countries)}
          columns={preparedColumns}
        />

        <ModalDelete
          isOpen={this.state.showModalDelete}
          event={this.modalDeleteEvent}
          onClose={this.toggleModalDelete}
          modalTitle={this.t('deleteCountry')}
          modalBodyText={this.t('confirmDeleteCountry')}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={this.t('error')}
        ></ModalError>

        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleModalForm}
          ref={this.state.modalForm}
          modalTitle={this.modalTitle}
        >
          {this.state.modalForm}
        </ModalForm>
      </Container>
    );
  }
}

export default withRouter(withTranslation()(CountryListView));
