import React, { ChangeEvent, useContext, useState } from 'react';
import { Input } from 'reactstrap';
import Datetime from 'react-datetime';
import Select from 'react-select';
import moment, { Moment } from 'moment';

import i18n from '../../../../i18n';
import { notApplicable } from '../../../../utils/constants';
import DetailsCard from '../../../../components/cards/DetailsCard';
import { OrderSummaryContext } from './OrderSummaryProvider';
import {
  DATE_FORMAT,
  calculateDiscountPercentage,
  translate,
} from './orderHelper';
import { paymentGoalOptions } from '../../../../utils/enums/enum';

const OrderSummaryDetailsCard = () => {
  const { offer, formValues, setFormValues } = useContext(OrderSummaryContext);

  const {
    orderDate,
    orderedSum,
    orderNumber,
    paymentGoal,
    preliminaryOrder,
    offerPartiallyOrdered,
  } = formValues;

  const { title, offerDocumentTitle, offerVersion, budget } = offer;

  const [discountPercentage, setDiscountPercentage] = useState(0);

  const handleOrderDate = (event: Moment | string) =>
    setFormValues((values) => ({
      ...values,
      orderDate: moment(event).toISOString(),
    }));

  const handleOrderedSum = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    setFormValues((values) => ({
      ...values,
      orderedSum: value,
    }));
    setDiscountPercentage(calculateDiscountPercentage(budget, value));
  };

  const handlePaymentGoal = (paymentGoal: number) =>
    setFormValues((values) => ({
      ...values,
      paymentGoal,
    }));

  const orderDetailSummaryList = [
    {
      label: translate('offerName'),
      value: <Input value={title} disabled />,
    },
    {
      label: translate('offerTitle'),
      value: <Input value={offerDocumentTitle} disabled />,
    },
    {
      label: translate('version'),
      value: <Input value={offerVersion} disabled />,
    },
    {
      label: translate('dateOfOrder'),
      value: (
        <Datetime
          dateFormat={DATE_FORMAT}
          timeFormat={false}
          locale={i18n.language}
          closeOnSelect
          onChange={handleOrderDate}
          value={moment(orderDate).format(DATE_FORMAT)}
        />
      ),
      isRequired: !preliminaryOrder,
    },
    {
      label: translate('offeredSum'),
      value: <Input value={budget} disabled />,
    },
    {
      label: translate('orderedSum'),
      value: (
        <Input type="number" value={orderedSum} onChange={handleOrderedSum} />
      ),
      isRequired: !preliminaryOrder,
    },
    {
      label: translate('offerPartiallyOrdered'),
      value: (
        <Input
          aria-label="checkbox-offerPartialOrder"
          type="checkbox"
          checked={offerPartiallyOrdered}
          onClick={() =>
            setFormValues((values) => ({
              ...values,
              offerPartiallyOrdered: !offerPartiallyOrdered,
            }))
          }
        />
      ),
    },
    {
      label: translate('discount'),
      value: (
        <Input
          value={
            offerPartiallyOrdered ? `${discountPercentage}` : notApplicable
          }
          disabled
        />
      ),
    },
    {
      label: translate('orderNumber'),
      value: (
        <Input
          aria-label="orderNumber-input"
          type="text"
          value={orderNumber}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setFormValues((values) => ({
              ...values,
              orderNumber: event.target.value,
            }))
          }
        />
      ),
      isRequired: !preliminaryOrder,
    },
    {
      label: translate('paymentGoal'),
      value: (
        <Select
          value={paymentGoalOptions.find(({ value }) => value === paymentGoal)}
          options={paymentGoalOptions.filter(
            ({ value }) => value !== paymentGoal
          )}
          onChange={handlePaymentGoal}
        />
      ),
      isRequired: !preliminaryOrder,
    },
    {
      label: translate('specialConditions'),
      value: <Input type="checkbox" aria-label="checkbox-specialConditions" />,
    },
  ];

  return <DetailsCard title="" fields={orderDetailSummaryList} border />;
};

export default OrderSummaryDetailsCard;
