/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
} from 'react';

import {
  IOfferDetails,
  IOrderDetails,
} from '../../../../utils/types/responseTypes';
import {
  IDropdownOption,
  IProjectDetailOffer,
} from '../../../../utils/types/commonTypes';
import {
  generateDefaultOrderFormValues,
  generateDefaultOrderValues,
} from './orderHelper';

export type OrderDetailFormValues = {
  orderDate: string;
  orderedSum: number;
  orderNumber: string;
  paymentGoal: number;
  offerPartiallyOrdered: boolean;
  specialConditions: boolean;
  projectId: string;
  nameOfRecipient: string;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  emailAddress: string;
  contractor: string;
  sendInvoiceBy: IDropdownOption<number>;
  departments: IDropdownOption<number>[];
  startBillingDefined: string;
  kickOffMeeting: string;
  internalKickOff: string;
  employeeManager: IDropdownOption<number>;
  newProject: boolean;
  existingProject: boolean;
  preliminaryOrder: boolean;
};

type OrderSummaryContextType = {
  order: IOrderDetails;
  setOrder: Dispatch<SetStateAction<IOrderDetails>>;
  offer: IOfferDetails;
  project: IProjectDetailOffer;

  formValues: OrderDetailFormValues;
  setFormValues: Dispatch<SetStateAction<OrderDetailFormValues>>;
};

export const OrderSummaryContext = createContext({} as OrderSummaryContextType);

interface IProps {
  children: React.ReactNode;
  offerDetails: IOfferDetails;
  projectDetails: IProjectDetailOffer;
}

const OrderSummaryProvider = ({
  children,
  offerDetails,
  projectDetails,
}: IProps) => {
  const offer = offerDetails;
  const project = projectDetails;

  const [formValues, setFormValues] = useState<OrderDetailFormValues>(
    generateDefaultOrderFormValues(offer, project)
  );

  const [order, setOrder] = useState(
    generateDefaultOrderValues(offer, project)
  );

  return (
    <OrderSummaryContext.Provider
      value={{
        order,
        setOrder,
        offer,
        project,
        formValues,
        setFormValues,
      }}
    >
      {children}
    </OrderSummaryContext.Provider>
  );
};

export default OrderSummaryProvider;
