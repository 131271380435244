import i18n from '../../i18n';

export const INCOME_TYPE = {
  gross: { code: 'GROSS', name: 'Gross' },
  net: { code: 'NET', name: 'Net' },
};

export const OFFER_CONTRACT_TYPE_ENUM = {
  work_contract: {
    code: 'WORK_CONTRACT',
    name: i18n.t('OfferContractTypeEnum.WORK_CONTRACT'),
  },
  service_contract: {
    code: 'SERVICE_CONTRACT',
    name: i18n.t('OfferContractTypeEnum.SERVICE_CONTRACT'),
  },
  body_leasing: {
    code: 'BODY_LEASING',
    name: i18n.t('OfferContractTypeEnum.BODY_LEASING'),
  },
  maintenance: {
    code: 'MAINTENANCE',
    name: i18n.t('OfferContractTypeEnum.MAINTENANCE'),
  },
  ticker_system: {
    code: 'TICKET_SYSTEM',
    name: i18n.t('OfferContractTypeEnum.TICKET_SYSTEM'),
  },
  dynamic_contract: {
    code: 'DYNAMIC_CONTRACT',
    name: i18n.t('OfferContractTypeEnum.DYNAMIC_CONTRACT'),
  },
};

export enum OfferState {
  VALID = 'VALID',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  OUTDATED = 'OUTDATED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  ORDERED = 'ORDERED',
  CANCELED = 'CANCELED',
}

export enum OfferContractType {
  WORK_CONTRACT = 'WORK_CONTRACT',
  SERVICE_CONTRACT = 'SERVICE_CONTRACT',
  BODY_LEASING = 'BODY_LEASING',
  MAINTENANCE = 'MAINTENANCE',
  TICKET_SYSTEM = 'TICKET_SYSTEM',
  DYNAMIC_CONTRACT = 'DYNAMIC_CONTRACT',
}
