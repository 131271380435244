import React, { Dispatch, SetStateAction, useState } from 'react';
import DateTime from 'react-datetime';
import { Container, Row, Col, Button } from 'reactstrap';
import moment, { Moment } from 'moment';

import { t } from './resourceOverviewHelper';

interface IProps {
  startAndEndDate: { startingDate: Date; endingDate: Date };
  setTimespan: Dispatch<
    SetStateAction<{ startingDate: Date; endingDate: Date }>
  >;
  onClose: () => void;
}

const ChangeTimespanForm = ({
  startAndEndDate: { startingDate, endingDate },
  setTimespan,
  onClose,
}: IProps) => {
  const [fromDate, setFromDate] = useState(startingDate);
  const [toDate, setToDate] = useState(endingDate);

  const handleStartDateSelect = (selectedDate: Moment | string) => {
    const date =
      typeof selectedDate === 'string'
        ? moment(selectedDate).date(1).toDate()
        : selectedDate.date(1).toDate();

    setFromDate(date);
  };

  const handleEndDateSelect = (selectedDate: Moment | string) => {
    const date =
      typeof selectedDate === 'string'
        ? moment(selectedDate).endOf('month').toDate()
        : selectedDate.endOf('month').toDate();

    setToDate(date);
  };

  const handleOkButton = () => {
    setTimespan((values) => ({
      ...values,
      startingDate: fromDate,
      endingDate: toDate,
    }));

    onClose();
  };

  return (
    <Container fluid>
      <Row className="my-2">
        <Col>{t('from')}</Col>
        <Col>
          <DateTime
            data-testid="change-time-span-date-from"
            value={fromDate}
            onChange={(date: Moment | string) => handleStartDateSelect(date)}
            initialViewDate="day"
            closeOnSelect
            dateFormat="MM/YYYY"
            timeFormat={false}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>{t('to')}</Col>
        <Col>
          <DateTime
            data-testid="change-time-span-date-to"
            value={toDate}
            onChange={(date: Moment | string) => handleEndDateSelect(date)}
            initialViewDate="day"
            closeOnSelect
            dateFormat="MM/YYYY"
            timeFormat={false}
          />
        </Col>
      </Row>
      <Row>
        <Button
          style={{ justifyContent: 'center' }}
          color="primary"
          size="m"
          onClick={() => handleOkButton()}
        >
          {t('ok')}
        </Button>
      </Row>
    </Container>
  );
};

export default ChangeTimespanForm;
