import { PayloadAction } from '@reduxjs/toolkit';

import { getListOfResponsibleNames } from '../services/api/employee';
import {
  deleteProject as deleteProjectItem,
  getProjectListItems,
} from '../services/api/project';
import { Ownership } from '../utils/enums/ownership';
import { EntityType } from '../utils/enums/pageComponents';
import { SortBy, SortType } from '../utils/enums/sort';
import { objectNameAndIdToDropdownOptions } from '../utils/helpers/dropdown';
import { setPermissionsForProjectListItem } from '../utils/helpers/permission';
import { createQueryParameters } from '../pages/projects/ProjectList/projectListHelpers';
import { IDropdownOption } from '../utils/types/commonTypes';
import { IProjectListItem } from '../utils/types/responseTypes';
import { IProjectListFilters } from '../utils/types/stateTypes';
import createListSlice, { ListState } from './createListSlice';
import type { AppThunk } from './store';

type AdditionalState = {
  responsibles: IDropdownOption<number>[];
};

type ProjectListState = ListState<
  IProjectListItem,
  IProjectListFilters,
  AdditionalState
>;

const initialState: ProjectListState = {
  listItems: [],
  hasMore: true,
  page: 0,
  pageSize: 10,
  pageCount: 0,
  filters: {
    customer: '',
    customerSite: '',
    projectId: '',
    title: '',
    responsible: [],
    state: [],
  },
  showFilters: false,
  sortBy: SortBy.LAST_MODIFIED_ON,
  sortType: SortType.DESCENDING,
  additionalState: {
    responsibles: [],
  },
};

/**
 * Project List Slice - contains the state of the Project List page
 * 1.) List of projects
 * 2.) Page of items to be fetched and if there is more data to fetch
 * 3.) Applied filters
 * 4.) Applied sorting criteria
 * 5.) List of project responsibles
 */
const projectListSlice = createListSlice({
  name: 'projectList',
  initialState,
  reducers: {
    setResponsibles: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...state.additionalState,
        responsibles: [...payload],
      },
    }),
  },
});

export const {
  removeListItem,
  setListItems,
  setPage,
  setPageSize,
  setPageCount,
  setFilters,
  toggleFilter,
  setSortBy,
  setSortType,
  setResponsibles,
} = projectListSlice.actions;

/**
 * Thunk for fetching a single project list item and updating project list slice
 * Used for newly created projects
 * @param projectId Id of project list item to fetch
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching a single project list item
 */
export const fetchProjectListItem =
  (projectId: number, errorHandler: (error: any) => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        projectList: { listItems },
      } = getState();

      const {
        data: {
          data: [fetchedListItem],
        },
      } = await getProjectListItems({
        'id.equals': projectId.toString(),
      });

      if (fetchedListItem) {
        setPermissionsForProjectListItem(fetchedListItem);

        dispatch(
          setListItems([
            fetchedListItem,
            ...listItems.filter(({ id }) => id !== fetchedListItem.id),
          ])
        );
      }
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching project list items and updating project list slice
 * @param isSortOrFilterFetchType Whether fetching due to sort / filter / scroll update
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching project list items
 */
export const fetchProjectListItems =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        projectList: { page, pageSize, filters, sortBy, sortType },
      } = getState();

      const {
        data: { data: fetchedListItems, itemCount },
      } = await getProjectListItems(
        createQueryParameters(page, pageSize, filters, sortBy, sortType)
      );

      dispatch(setPageCount(Math.ceil(itemCount / pageSize)));
      dispatch(setListItems([...fetchedListItems]));
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for deleting project and updating project list slice
 * @param projectId Id of project to delete
 * @param successHandler Function for handling logic to execute upon successful project deletion
 * @param errorHandler Function for handling errors from deleting project
 * @returns Inner thunk function containing async logic for deleting project
 */
export const deleteProject =
  (
    projectId: number,
    successHandler: () => void,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      await deleteProjectItem(projectId);

      dispatch(removeListItem(projectId));
      successHandler();
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching project responsibles and updating project list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching project responsibles
 */
export const fetchProjectResponsibles =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedResponsibleNames } = await getListOfResponsibleNames(
        {
          'ownership.in': Ownership.RESPONSIBLE,
          'objectType.equals': EntityType.PROJECT.toUpperCase(),
        }
      );

      dispatch(
        setResponsibles(
          objectNameAndIdToDropdownOptions(fetchedResponsibleNames)
        )
      );
    } catch (error) {
      errorHandler(error);
    }
  };

export default projectListSlice.reducer;
