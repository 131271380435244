import React, { useEffect, ChangeEvent } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import DynamicTable from '../../../components/tables/DynamicTable';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { RootState } from '../../../redux/store';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { getNextSortType } from '../../../utils/helpers/table';
import { SortBy, SortType } from '../../../utils/enums/sort';
import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';
import {
  CustomerState,
  TargetPriority,
} from '../../../utils/enums/customer';
import {
  fetchCustomerSiteInvolvedEmployees,
  fetchCustomerSiteListItems,
  fetchCustomerSiteResponsibles,
  setFilters,
  setHasMoreListItems,
  setPage,
  setSortBy,
  setSortType,
} from '../../../redux/customerSiteListSlice';
import useMounted from '../../../hooks/useMounted';
import { ICustomerSiteListItem } from '../../../utils/types/responseTypes';
import { createCustomerSiteEntry, t } from './customerSiteListHelper';
import { isEmpty } from '../../../utils/helpers/array';
import { DEFAULT_LOAD_TIMEOUT } from '../../../utils/constants';

const customerSiteStates = enumValuesToDropdownOptions(
  Object.values(CustomerState)
);
const targetPriorities = enumValuesToDropdownOptions(
  Object.values(TargetPriority)
);

interface IProps extends IWithModalsProps, PropsFromRedux {}

const CustomerSiteListTable = ({
  // WithModals
  modalErrorHandler,
  // Redux state
  listItems,
  responsibles,
  involvedEmployees,
  filters,
  showFilters,
  hasMore,
  sortBy,
  sortType,
  // Redux Actions
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  setHasMoreListItems,
  // Thunks
  fetchCustomerSiteListItems,
  fetchCustomerSiteResponsibles,
  fetchCustomerSiteInvolvedEmployees,
}: IProps) => {
  // Check if component is mounted
  const isMounted = useMounted();

  // Prepare data to be displayed on the table
  const preparedCustomerSiteListItems = listItems.map(
    (customerSite: ICustomerSiteListItem) =>
      createCustomerSiteEntry(customerSite)
  );

  /**
   * Handles the fetching of customer list items
   * @param isSortOrFilterFetchType Whether the data fetching is for sorting / filtering / scrolling
   */
  const fetchCustomerSiteList = (isSortOrFilterFetchType: boolean) => {
    fetchCustomerSiteListItems(isSortOrFilterFetchType, (error) => {
      modalErrorHandler(t('failedToRetrieveSites'), error);
    });
  };

  /**
   * Fetch customer site list, customer site responsibles,
   * customer site involved responsibles on component mount
   */
  useEffect(() => {
    if (isEmpty(listItems)) {
      fetchCustomerSiteList(false);
    }

    if (isEmpty(responsibles)) {
      fetchCustomerSiteResponsibles((error) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });
    }

    if (isEmpty(involvedEmployees)) {
      fetchCustomerSiteInvolvedEmployees((error) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });
    }
  }, []);

  // Fetch customer list on sort update
  useEffect(() => {
    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);
      fetchCustomerSiteList(true);
    }
  }, [sortBy, sortType]);

  // Fetch customer list on filter update
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);

      timer = setTimeout(() => {
        fetchCustomerSiteList(true);
      }, DEFAULT_LOAD_TIMEOUT);
    }

    return () => clearTimeout(timer);
  }, [filters]);

  const handleInputFilterChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: event.target.value ?? '',
    });
  };

  const handleDropdownFilterChange = (
    filterValue: IDropdownOption[],
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: filterValue,
    });
  };

  /**
   * Handles sorting of data
   */
  const handleSort = (nextSortBy: SortBy) => {
    const nextSortType = getNextSortType(sortType, nextSortBy, sortBy);

    setSortBy(nextSortType === SortType.DEFAULT ? SortBy.NAME : nextSortBy);
    setSortType(
      nextSortType === SortType.DEFAULT ? SortType.ASCENDING : nextSortType
    );
  };

  const tableColumns = [
    {
      type: 'component',
      header: t('customer'),
      show: 'true',
      accessor: 'customer',
      alignleft: 'true',
      filterFunc: handleInputFilterChange,
      filterComponentWidth: '160px',
      width: '180px',
      sortFunc: handleSort,
    },
    {
      type: 'component',
      header: t('siteName'),
      accessor: 'name',
      show: 'true',
      filterFunc: handleInputFilterChange,
      filterComponentWidth: '160px',
      width: '200px',
      sortFunc: handleSort,
    },
    {
      type: 'data',
      header: t('targetPriority'),
      accessor: 'targetPriority',
      show: 'true',
      filterValue: filters.targetPriority,
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: targetPriorities,
      filterComponentWidth: '180px',
      width: '150px',
    },
    {
      type: 'data',
      header: t('state'),
      accessor: 'customerSiteState',
      show: 'true',
      filterValue: filters.customerSiteState,
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: customerSiteStates,
      filterComponentWidth: '120px',
      width: '120px',
      softFunc: handleSort,
    },
    {
      type: 'component',
      header: t('sectors'),
      accessor: 'sectors',
      show: 'true',
      filterFunc: handleInputFilterChange,
    },
    {
      type: 'component',
      header: t('responsibles'),
      accessor: 'responsible',
      show: 'true',
      alignleft: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: responsibles,
      filterComponentWidth: '180px',
      width: '200px',
      filterValue: filters.responsible,
    },
    {
      type: 'component',
      header: t('involved'),
      accessor: 'involvedEmployees',
      show: 'true',
      alignleft: 'true',
      showsearch: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: involvedEmployees,
      filterComponentWidth: '180px',
      width: '200px',
      filterValue: filters.involvedEmployees,
    },
  ];
  return (
    <DynamicTable
      data={preparedCustomerSiteListItems}
      columns={tableColumns}
      fetchData={fetchCustomerSiteList}
      hasMoreData={hasMore}
      infiniteScroll
      sortType={sortType}
      sortBy={sortBy}
      enableFilterFunction
      showFilters={showFilters}
    />
  );
};

const mapStateToProps = (store: RootState) => ({
  listItems: store.customerSiteList.listItems,
  responsibles: store.customerSiteList.additionalState?.responsibles,
  involvedEmployees: store.customerSiteList.additionalState?.involvedEmployees,
  filters: store.customerSiteList.filters,
  showFilters: store.customerSiteList.showFilters,
  hasMore: store.customerSiteList.hasMore,
  sortBy: store.customerSiteList.sortBy,
  sortType: store.customerSiteList.sortType,
});

const mapDispatchToProps = {
  fetchCustomerSiteListItems,
  fetchCustomerSiteResponsibles,
  fetchCustomerSiteInvolvedEmployees,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  setHasMoreListItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(CustomerSiteListTable));
