import i18n from '../../../i18n';
import {
  IDropdownOption,
  IObjectProjectResource,
  IObjectProjectUtilization,
} from '../../../utils/types/commonTypes';

export interface IObjectTimespan {
  month: number;
  year: number;
  label: string;
  week?: number;
}

export enum ResourceViewMode {
  MONTHLY = 'Monthly',
  WEEKLY = 'Weekly',
}

export const t = (keyName: string) => i18n.t(`ResourceOverview.${keyName}`);

export const generateLevelOptions = () => {
  const levelOptions = [] as IDropdownOption<number>[];
  const maximumLevel = 5;
  for (let i = 1; i <= maximumLevel; i++) {
    levelOptions.push({ value: i, label: `${t('level')} ${i.toString()}` });
  }

  return levelOptions;
};

const getDateWeekNumber = (date: Date) => {
  const currentDate = new Date(date);
  const januaryFirst = new Date(currentDate.getFullYear(), 0, 1);
  const numOfDays = Math.floor(
    (currentDate.getTime() - januaryFirst.getTime()) / (24 * 60 * 60 * 1000)
  );
  const result = Math.ceil((currentDate.getDay() + 1 + numOfDays) / 7);
  return result;
};

const generateWeeklyHeader = (startDate: Date, endDate: Date) => {
  const weeklyRangeList = [];

  // Create a copy of startDate to avoid modifying the original object
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    // Get the Sunday before the selected date as the start of the week
    const startOfWeek = new Date(currentDate); // Create a new Date object
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + 1);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    const startDateDay = startOfWeek.getDate();
    const startDateMonth = startOfWeek.toLocaleString('default', {
      month: 'short',
    });

    const endDateDay = endOfWeek.getDate();
    const endDateMonth = endOfWeek.toLocaleString('default', {
      month: 'short',
    });

    const formattedString = `${startDateDay} ${startDateMonth} - ${endDateDay} ${endDateMonth}`;

    const timeSpanObject = {
      month: startOfWeek.getMonth(),
      week: getDateWeekNumber(startOfWeek),
      year: startOfWeek.getFullYear(),
      label: formattedString,
    } as IObjectTimespan;

    weeklyRangeList.push(timeSpanObject);

    // Update currentDate for the next iteration
    currentDate.setDate(currentDate.getDate() + 7);
  }

  return weeklyRangeList;
};

const generateMonthlyHeader = (startDate: Date, endDate: Date) => {
  const monthList = [];

  const startDateMonth = startDate.getMonth();
  const startDateYear = startDate.getFullYear();
  const endDateMonth = endDate.getMonth();
  const endDateYear = endDate.getFullYear();

  for (let year = startDateYear; year <= endDateYear; year++) {
    const startMonth = year === startDateYear ? startDateMonth : 0;
    const endMonth = year === endDateYear ? endDateMonth : 11;

    for (let month = startMonth; month <= endMonth; month++) {
      const date = new Date(year, month, 1);
      const formattedString = `${date.toLocaleString('default', {
        month: 'short',
      })}(${date.getFullYear()})`;

      const timespan = {
        month,
        year: startDateYear,
        label: formattedString,
      } as IObjectTimespan;

      monthList.push(timespan);
    }
  }

  return monthList;
};

export const generateTimespanValues = (
  viewMode: ResourceViewMode,
  startDate?: string,
  endDate?: string
) => {
  if (startDate && endDate) {
    const startDateRef = new Date(startDate);
    const endDateRef = new Date(endDate);

    if (viewMode.toString() === ResourceViewMode.WEEKLY.toString()) {
      return generateWeeklyHeader(startDateRef, endDateRef);
    }
    return generateMonthlyHeader(startDateRef, endDateRef);
  }
  const currentYear = new Date().getFullYear();

  const monthList = [];

  for (let month = 0; month < 12; month++) {
    const date = new Date(currentYear, month, 1);
    const customFormat = `${date.toLocaleString('default', {
      month: 'short',
    })}(${date.getFullYear()})`;

    const timespan = {
      month,
      year: currentYear,
      label: customFormat,
    } as IObjectTimespan;

    monthList.push(timespan);
  }

  return monthList;
};

export const isCurrentWeek = (weekNumber: number) => {
  const today = new Date();
  const currentWeekNumber = getDateWeekNumber(today);
  return weekNumber === currentWeekNumber;
};

export const totalWorkloadUtilizationByWeek = (
  resources: (IObjectProjectResource | IObjectProjectUtilization)[],
  week: number
) => {
  let totalWorkloadInAMonth = 0;
  const workloadUtilization: { workloadPercentage: number; week: number }[] =
    [];
  resources.forEach((item) => {
    if ('weeklyWorkloadUtilizations' in item) {
      workloadUtilization.push(...item.weeklyWorkloadUtilizations);
    }
  });
  const workloadByMonth = workloadUtilization.filter(
    ({ week: weekNumber }) => weekNumber === week
  );

  workloadByMonth.forEach(
    ({ workloadPercentage }) => (totalWorkloadInAMonth += workloadPercentage)
  );
  return totalWorkloadInAMonth.toFixed(2);
};
