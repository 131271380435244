import {
  faHome,
  faSignOutAlt,
  faUserCog,
} from '@fortawesome/free-solid-svg-icons';
import i18n from '../i18n';
import logout from '../logout';
import {
  accountRoutes,
  customerPrivateRoutes,
  customerRoutes,
  customerSiteRoutes,
} from '../routes/RouteCategories/CustomerCategory';
import {
  employeePrivateRoutes,
  employeeRoutes,
} from '../routes/RouteCategories/EmployeeCategory';
import {
  projectPrivateRoute,
  projectRoutes,
} from '../routes/RouteCategories/ProjectCategory';
import { resourcePlannerRoutes } from '../routes/RouteCategories/ResourcePlannerCategory';
import {
  salesRoutes,
} from '../routes/RouteCategories/SalesCategory';
import {
  settingsPrivateRoutes,
  settingsRoutes,
} from './RouteCategories/SettingCategory';
import Home from '../pages/Home';

// Routes
export const dashboardRoutes = {
  path: '/',
  name: i18n.t('index.home'),
  header: i18n.t('index.main'),
  icon: faHome,
  component: Home,
  children: null,
  isProtected: true,
};

export const authRoutes = {
  path: '/auth',
  name: i18n.t('index.settings'),
  icon: faUserCog,
  children: [
    {
      path: '/logout',
      name: i18n.t('index.logout'),
      component: logout,
    },
  ],
};

export const loggingOutRoute = {
  path: '/logout',
  name: i18n.t('index.logout'),
  header: i18n.t('index.logout'),
  icon: faSignOutAlt,
  component: logout,
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  salesRoutes,
  customerRoutes,
  resourcePlannerRoutes,
  customerPrivateRoutes,
  projectRoutes,
  projectPrivateRoute,
  employeePrivateRoutes,
  employeeRoutes,
  settingsRoutes,
  settingsPrivateRoutes,
  accountRoutes,
  customerSiteRoutes,
  loggingOutRoute,
];

// Routes seen in sidebar
const defaultRoutes = [
  dashboardRoutes,
  customerRoutes,
  salesRoutes,
  resourcePlannerRoutes,
  projectRoutes,
  employeeRoutes,
  settingsRoutes,
  loggingOutRoute,
];

export default defaultRoutes;
