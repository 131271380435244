import moment from 'moment';
import React from 'react';
import Select from 'react-select';

import {
  notApplicable,
  notApplicableAbbreviation,
} from '../../../utils/constants';
import i18n from '../../../i18n';
import { IEmployeeDetail } from '../../../utils/types/responseTypes';
import { EmployeeDetailFieldsProperties, edt } from './employeeDetailHelper';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { EmployeeReleaseStatus } from '../../../utils/enums/employee';
import { enumValuesToDropdownOptions, sortOptionsByValue } from '../../../utils/helpers/dropdown';
import { getDateFormat } from '../../../utils/helpers/date';
import DetailsCard, { CardData } from '../../../components/cards/DetailsCard';

/**
 * Display General Information of the Employee Overview
 */

const employeeRelease = enumValuesToDropdownOptions(
  Object.values(EmployeeReleaseStatus),
  'EmployeeEditableFieldsEnum'
);

interface IProps {
  selectedEmployee: IEmployeeDetail;
  employeeEdited: IEmployeeDetail;
  isCurrentUserOverviewOrAuthorized: boolean;
  selectedEmployeeIsFormer: boolean;
  onChangeReleaseStatus: (status: IDropdownOption) => void;
  onChangeSelectedField: (field: string) => void;
  hasPermission: boolean;
  selectEditableField: string;
}

const dateFormat = getDateFormat();

const EmployeeOverviewGeneralInformation = ({
  selectedEmployee: {
    releaseStatus: releaseStatusOriginal,
    firstname,
    lastname,
    abbreviation,
    userId,
    contractType,
    startingDate,
    validUntil,
    weeklyWorkingHours,
  },
  isCurrentUserOverviewOrAuthorized,
  selectedEmployeeIsFormer,
  hasPermission,
  onChangeReleaseStatus,
  onChangeSelectedField,
  selectEditableField,
  employeeEdited: { releaseStatus: releaseStatusEdited },
}: IProps) => {
  const releaseStatus =
    releaseStatusEdited ?? releaseStatusOriginal ?? notApplicable;

  const setReleaseStatus = () => {
    onChangeSelectedField(EmployeeDetailFieldsProperties.RELEASE);
  };

  // Add the initial fields
  const fields = [
    {
      label: edt('firstName'),
      value: firstname,
      isRequired: false,
    },
    {
      label: edt('lastName'),
      value: lastname,
      isRequired: false,
    },
    {
      label: edt('invAbbreviation'),
      value: abbreviation,
      isRequired: false,
    },
  ] as CardData[];
  // If not former, add the ID
  if (!selectedEmployeeIsFormer) {
    fields.push({
      label: edt('userId'),
      value: userId,
      isRequired: false,
    });
  }
  // If current user is authorized, show contract
  if (isCurrentUserOverviewOrAuthorized && !selectedEmployeeIsFormer) {
    fields.push({
      label: edt('typeOfContract'),
      value: contractType,
      isRequired: false,
    });
  }
  // Start of contract is shown to everyone
  fields.push({
    label: selectedEmployeeIsFormer
      ? edt('startOfLastContract')
      : edt('startOfActualContract'),
    value: startingDate
      ? moment(startingDate).format(dateFormat)
      : notApplicableAbbreviation,
    isRequired: false,
  });
  // Only show if current user is authorized or if the person is former
  if (isCurrentUserOverviewOrAuthorized || selectedEmployeeIsFormer) {
    fields.push({
      label: edt('terminationOfContract'),
      value: validUntil
        ? moment(validUntil).format(dateFormat)
        : notApplicableAbbreviation,
      isRequired: false,
    });
  }
  // If current user is authorized, show working hours
  if (isCurrentUserOverviewOrAuthorized && !selectedEmployeeIsFormer) {
    fields.push({
      label: edt('availability'),
      value: `${weeklyWorkingHours} ${edt('hours')}/${edt('week')}`,
      isRequired: false,
    });
  }
  // If not former, add the release status
  if (!selectedEmployeeIsFormer) {
    fields.push({
      label: edt('releaseStatus'),
      value: (
        <div onClick={setReleaseStatus}>
          {selectEditableField === EmployeeDetailFieldsProperties.RELEASE &&
          hasPermission ? (
            <Select
              value={employeeRelease.find(
                (option) => option.value === releaseStatus
              )}
              options={sortOptionsByValue(employeeRelease)}
              placeholder={i18n.t('DynamicTable.select')}
              onChange={onChangeReleaseStatus}
              styles={{
                menu: (styles: { [key: string]: React.CSSProperties }) => ({
                  ...styles,
                  zIndex: 100,
                }),
              }}
            />
          ) : (
            employeeRelease.find((status) => status.value === releaseStatus)
              ?.label ?? releaseStatus
          )}
        </div>
      ),
      isRequired: false,
    });
  }
  return (
    <DetailsCard title={edt('generalInformation')} fields={fields} border />
  );
};

export default EmployeeOverviewGeneralInformation;
