import { PayloadAction } from '@reduxjs/toolkit';

import { getListOfResponsibleNames } from '../services/api/employee';
import {
  deleteProject as deleteProjectOptionItem,
  getProjectOptionListItems,
} from '../services/api/project';
import { Ownership } from '../utils/enums/ownership';
import { EntityType } from '../utils/enums/pageComponents';
import { SortBy, SortType } from '../utils/enums/sort';
import { maxPageLength } from '../utils/constants';
import { objectNameAndIdToDropdownOptions } from '../utils/helpers/dropdown';
import { removeDuplicates } from '../utils/helpers/table';
import { createQueryParameters } from '../pages/salesFunnel/ProjectOptionList/projectOptionListHelpers';
import { IDropdownOption } from '../utils/types/commonTypes';
import { IProjectOptionListItem } from '../utils/types/responseTypes';
import { IProjectOptionListFilters } from '../utils/types/stateTypes';
import createListSlice, { ListState } from './createListSlice';
import type { AppThunk } from './store';

type AdditionalState = {
  responsibles: IDropdownOption<number>[];
};

type ProjectOptionListState = ListState<
  IProjectOptionListItem,
  IProjectOptionListFilters,
  AdditionalState
>;

const initialState: ProjectOptionListState = {
  listItems: [],
  hasMore: true,
  page: 0,
  pageSize: 0,
  pageCount: 0,
  filters: {
    customer: '',
    customerSite: '',
    projectId: '',
    title: '',
    responsible: [],
    state: [],
    probabilityMin: '',
    probabilityMax: '',
    responsibleContactPerson: '',
    ownership: [],
    staffedOption: [],
    createdOn: '',
    nextAction: [],
    dueDate: '',
  },

  showFilters: false,
  sortBy: SortBy.LAST_MODIFIED_ON,
  sortType: SortType.DESCENDING,
  additionalState: {
    responsibles: [],
  },
};

/**
 * Project Options List Slice - contains the state of the Project Options List page
 * 1.) List of project options
 * 2.) Page of items to be fetched and if there is more data to fetch
 * 3.) Applied filters
 * 4.) Applied sorting criteria
 * 5.) List of project responsibles
 */
const projectOptionListSlice = createListSlice({
  name: 'projectOptionList',
  initialState,
  reducers: {
    setResponsibles: (
      state,
      { payload }: PayloadAction<IDropdownOption<number>[]>
    ) => ({
      ...state,
      additionalState: {
        ...state.additionalState,
        responsibles: [...payload],
      },
    }),
  },
});

export const {
  removeListItem,
  setListItems,
  setHasMoreListItems,
  setPage,
  setFilters,
  toggleFilter,
  setSortBy,
  setSortType,
  setResponsibles,
} = projectOptionListSlice.actions;

/**
 * Thunk for fetching a single project options list item and updating project options list slice
 * Used for newly created projects
 * @param projectOptionId Id of project option list item to fetch
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching a single project list item
 */
export const fetchProjectOptionListItem =
  (projectOptionId: number, errorHandler: (error: any) => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        projectOptionList: { listItems },
      } = getState();

      const {
        data: [fetchedListItem],
      } = await getProjectOptionListItems({
        'id.equals': projectOptionId.toString(),
      });

      if (fetchedListItem) {
        dispatch(
          setListItems([
            fetchedListItem,
            ...listItems.filter(({ id }) => id !== fetchedListItem.id),
          ])
        );
      }
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching project option list items and updating project option list slice
 * @param isSortOrFilterFetchType Whether fetching due to sort / filter / scroll update
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching project option list items
 */
export const fetchProjectOptionListItems =
  (
    isSortOrFilterFetchType: boolean,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        projectOptionList: { page, filters, sortBy, sortType, listItems },
      } = getState();

      const { data: fetchedListItems } = await getProjectOptionListItems(
        createQueryParameters(page, filters, sortBy, sortType)
      );

      const processedListItems = isSortOrFilterFetchType
        ? [...fetchedListItems]
        : removeDuplicates(listItems, fetchedListItems);

      dispatch(
        setPage(!(fetchedListItems.length < maxPageLength) ? page + 1 : page)
      );
      dispatch(setHasMoreListItems(!(fetchedListItems.length < maxPageLength)));
      dispatch(setListItems(processedListItems));
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for deleting project option and updating project option list slice
 * @param projectId Id of project to delete
 * @param successHandler Function for handling logic to execute upon successful project option deletion
 * @param errorHandler Function for handling errors from deleting project option
 * @returns Inner thunk function containing async logic for deleting project option
 */
export const deleteProjectOption =
  (
    projectId: number,
    successHandler: () => void,
    errorHandler: (error: any) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      await deleteProjectOptionItem(projectId);

      dispatch(removeListItem(projectId));
      successHandler();
    } catch (error) {
      errorHandler(error);
    }
  };

/**
 * Thunk for fetching project responsibles and updating project option list slice
 * @param errorHandler Function for handling errors from fetching data
 * @returns Inner thunk function containing async logic for fetching project responsibles
 */
export const fetchProjectResponsibles =
  (errorHandler: (error: any) => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data: fetchedResponsibleNames } = await getListOfResponsibleNames(
        {
          'ownership.in': Ownership.RESPONSIBLE,
          'objectType.equals': EntityType.PROJECT.toUpperCase(),
        }
      );

      dispatch(
        setResponsibles(
          objectNameAndIdToDropdownOptions(fetchedResponsibleNames)
        )
      );
    } catch (error) {
      errorHandler(error);
    }
  };

export default projectOptionListSlice.reducer;
