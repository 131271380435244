/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from 'reactstrap';

import i18n from '../../../i18n';
import ExistingContactPersonSelectionTable from './ExistingContactPersonSelectionTable';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { ICustomerDetailContactPersonListItem } from '../../../utils/types/responseTypes';
import { isEmpty } from '../../../utils/helpers/array';
import {
  addContactPeopleToCustomer,
  getExistingContactPersonList,
} from '../../../services/api/contactPerson';

interface IProps extends IWithModalsProps {
  customerId: number;
  onSave: (
    selectedContactPersons: ICustomerDetailContactPersonListItem[]
  ) => void;
  onCancel: () => void;
}

const t = (keyName: string) => i18n.t(`CustomerContactPersonAdd.${keyName}`);

const ExistingContactPersonSelection = ({
  customerId,
  modalErrorHandler,
  onSave,
  onCancel,
}: IProps) => {
  const [allContactPersonOptions, setAllContactPersonOptions] = useState<
    ICustomerDetailContactPersonListItem[]
  >([]);
  const [filteredContactPersons, setFilteredContactPersons] = useState<
    ICustomerDetailContactPersonListItem[]
  >([]);
  const [selectedContactPersonIds, setSelectedContactPersonsIds] = useState<
    number[]
  >([]);
  const [searchWord, setSearchWord] = useState('');

  const fetchContactPersonsOptions = async () => {
    try {
      const { data } = await getExistingContactPersonList();
      setAllContactPersonOptions(data);
      setFilteredContactPersons(data);
    } catch (error) {
      modalErrorHandler(t('retrieveAvailableContactsFailed'), error);
    }
  };

  const handleSearchChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (value) {
      setFilteredContactPersons(
        allContactPersonOptions.filter(
          ({ firstname, lastname }) =>
            firstname.toLowerCase().includes(value.toLowerCase()) ||
            lastname.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredContactPersons(allContactPersonOptions);
    }
    setSearchWord(value);
  };

  const handleContactPersonSelectChange = (contactPersonId: number) => {
    setSelectedContactPersonsIds((prevFields) => {
      if (prevFields.includes(contactPersonId)) {
        return prevFields.filter((id) => id !== contactPersonId);
      }
      return [...prevFields, contactPersonId];
    });
  };

  const handleSaveContactPersons = async () => {
    try {
      await addContactPeopleToCustomer(customerId, selectedContactPersonIds);
      onSave(
        allContactPersonOptions.filter(({ id }) =>
          selectedContactPersonIds.includes(id)
        )
      );
    } catch (error) {
      modalErrorHandler(t('failedToSaveContactPerson'), error);
    }
  };

  useEffect(() => {
    fetchContactPersonsOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Row>
            <Col lg="5">
              <Input
                type="string"
                onChange={handleSearchChange}
                value={searchWord}
                placeholder={t('searchName')}
                data-testid="search-input"
              />
            </Col>
          </Row>
        </CardTitle>
      </CardHeader>
      <CardBody>
        {!isEmpty(filteredContactPersons) ? (
          <ExistingContactPersonSelectionTable
            contactPersons={filteredContactPersons}
            selectedContactPersonIds={selectedContactPersonIds}
            onSelectionChange={handleContactPersonSelectChange}
          />
        ) : (
          <Label>{t('noContactPersonAvailable')}</Label>
        )}
        <br />
        <Button
          color="primary"
          size="m"
          onClick={handleSaveContactPersons}
          data-testid="save-existing-contact-person-button"
        >
          {t('confirm')}
        </Button>{' '}
        <Button
          color="primary"
          size="m"
          onClick={() => onCancel()}
          data-testid="cancel-existing-contact-person-button"
        >
          {t('cancel')}
        </Button>
      </CardBody>
    </Card>
  );
};

export default withModals(ExistingContactPersonSelection);
