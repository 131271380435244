import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';

import { getProjectById } from '../../../services/api/project';
import CollapsibleTableCard from '../../../components/collapsibleCards/CollapsibleTableCard';
import { ProjectState } from '../../../utils/enums/project';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { setThenRemoveAlert } from '../../../redux/alertsSlice';
import { loadCallingList } from '../../../redux/callingSlice';
import { RootState } from '../../../redux/store';
import ProjectOptionModal from '../../salesFunnel/ProjectOptionModal/ProjectOptionModal';
import { ContactPersonDetailsContext } from './ContactPersonDetailsProvider';
import { generateTitle } from '../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';

// !-- Used in ContactPersonDetails, ContactPersonOverview

interface IProps
  extends PropsFromRedux,
    IWithModalsProps,
    RouteComponentProps {}

const t = (keyName: string) => i18n.t(`CustomerRelatedEntities.${keyName}`);

/**
 * Collapsible Card to display related entities
 */
const CollapsibleRelatedEntitiesCard = ({
  // WithModals
  modalFormHandler,
  toggleModalForm,
  modalErrorHandler,
}: IProps) => {
  const { contactDetail, relatedEntities, setRelatedEntities } = useContext(
    ContactPersonDetailsContext
  );

  const handleUpdateRelatedEntities = async (entityId: number) => {
    try {
      const {
        data: { state, title, createdOn },
      } = await getProjectById(entityId);

      const existingEntity = relatedEntities.find(({ id }) => id === entityId);
      const updatedRelatedEntities = [...relatedEntities];

      // Update existing entity or add new entity
      if (existingEntity) {
        existingEntity.objectType =
          state === ProjectState.ACTIVE || state === ProjectState.ORDERED
            ? 'PROJECT'
            : 'PROJECT_OPTION';
        existingEntity.title = title;
        existingEntity.date = createdOn ?? new Date().toISOString();
      } else {
        updatedRelatedEntities.push({
          id: entityId,
          objectType:
            state === ProjectState.ACTIVE || state === ProjectState.ORDERED
              ? 'PROJECT'
              : 'PROJECT_OPTION',
          title,
          date: createdOn ?? new Date().toISOString(),
        });
      }

      setRelatedEntities(updatedRelatedEntities);
    } catch (error) {
      modalErrorHandler(t('failedToUpdateContactPerson'), error);
    }

    toggleModalForm();
  };

  const handleAddProjectOption = () => {
    const { id: contactPersonId, customer, customerSite } = contactDetail;

    if (customer && customerSite && customer.id && customerSite.id) {
      modalFormHandler(
        t('Add Project Option'),
        <ProjectOptionModal
          contactPersonId={contactPersonId}
          customerId={customer.id}
          customerSiteId={customerSite.id}
          onClose={toggleModalForm}
          onSave={handleUpdateRelatedEntities}
        />
      );
    } else {
      modalErrorHandler(t('noCustomerOrCustomerSite'));
    }
  };

  const handleUpdateProjectOption = (projectId?: number) => {
    modalFormHandler(
      generateTitle(
        BUTTON_TITLE_ENUM.INFORMATION.code,
        t('updateProjectOption')
      ),
      <ProjectOptionModal
        projectId={projectId}
        onClose={toggleModalForm}
        onSave={handleUpdateRelatedEntities}
      />,
      'xl'
    );
  };
  const headerButtons = (
    <Button color="primary" size="m" onClick={() => handleAddProjectOption()}>
      <FontAwesomeIcon icon={faPlus} className="margin-right" />
      <span>{t('projectOption')}</span>
    </Button>
  );

  const tableData = relatedEntities.map(({ id, objectType, title }) => {
    let titleValue;

    if (objectType === 'PROJECT_OPTION' && id) {
      titleValue = (
        <Link to={'#'} onClick={() => handleUpdateProjectOption(id)}>
          {title}
        </Link>
      );
    } else if (objectType === 'PROJECT' && id) {
      titleValue = (
        <Link to={`/projects/overview/project-details/${id}`}>{title}</Link>
      );
    } else {
      titleValue = title;
    }

    return {
      'Object Type': objectType,
      Title: titleValue,
    };
  });

  return (
    <CollapsibleTableCard
      title={t('relatedEntities')}
      tableData={tableData}
      noDataPlaceholder={t('noRelatedEntities')}
      headerButtons={headerButtons}
      striped
    />
  );
};

const mapStateToProps = (store: RootState) => ({
  callingList: store.calling.callingList,
  account: store.account,
});

const mapDispatchToProps = {
  loadCallingList,
  setThenRemoveAlert,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(
  withRouter(withModals(CollapsibleRelatedEntitiesCard))
);
