import axios, { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import {
  ICustomerSiteDetail,
  ICustomerSiteListItem,
  IObjectContactPersonCustomerSite,
  IObjectCustomerSiteSector,
} from '../../utils/types/responseTypes';
import { urlBuilder } from '../../utils/helpers/url';
import { Url } from './constants';
import { ICustomerSite } from '../../utils/types/modelTypes';
import { IEditedCustomerSiteDetails } from '../../pages/crm/CustomerSiteDetails/customerSiteDetailsHelper';

export const getCustomerSiteListItems = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<ICustomerSiteListItem[]>>(
    axios.get(urlBuilder(Url.CUSTOMER_SITE_LIST, queryParameters))
  );

export const getCustomerSites = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<ICustomerSite[]>>(
    axios.get(urlBuilder(Url.CUSTOMER_SITES, queryParameters))
  );

export const getCustomerSiteDetails = (customerSiteId: string) =>
  trackPromise<AxiosResponse<ICustomerSiteDetail>>(
    axios.get(urlBuilder(`${Url.CUSTOMER_SITE_DETAILS}/${customerSiteId}`, {}))
  );

export const getCustomerSiteForContactPerson = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IObjectContactPersonCustomerSite[]>>(
    axios.get(urlBuilder(Url.CUSTOMER_SITE_FOR_CONTACT_PERSON, queryParameters))
  );

export const getAllCustomerSiteForContactPerson = () =>
  trackPromise<AxiosResponse<IObjectContactPersonCustomerSite[]>>(
    axios.get(urlBuilder(Url.CUSTOMER_SITE_FOR_CONTACT_PERSON, {}))
  );

export const deleteCustomerSite = (customerSiteId: number) =>
  trackPromise<AxiosResponse>(
    axios.delete(urlBuilder(`${Url.CUSTOMER_SITES}/${customerSiteId}`, {}))
  );

export const saveCustomerSiteDetails = (
  newCustomerSiteDetails: IEditedCustomerSiteDetails
) =>
  trackPromise<AxiosResponse<ICustomerSiteDetail>>(
    axios.put(urlBuilder(Url.CUSTOMER_SITE_DETAILS, {}), newCustomerSiteDetails)
  );

export const saveNewCustomerSiteDetails = (
  newCustomerSiteDetails: IEditedCustomerSiteDetails
) =>
  trackPromise<AxiosResponse<ICustomerSiteDetail>>(
    axios.post(
      urlBuilder(Url.CUSTOMER_SITE_DETAILS, {}),
      newCustomerSiteDetails
    )
  );

export const getCustomerSiteWithNameLocationInUse = (
  customerId: number,
  name: string,
  location: string
) =>
  trackPromise<AxiosResponse<boolean>>(
    axios.get(
      urlBuilder(Url.CUSTOMER_SITE_NAME_AND_LOCATION, {
        customerId: customerId.toString(),
        name,
        location,
      })
    )
  );

export const getCustomerSiteSectorObjects = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IObjectCustomerSiteSector[]>>(
    axios.get(urlBuilder(Url.CUSTOMER_SITE_OBJECT, queryParameters))
  );
