import { AxiosError } from 'axios';
import React, { ChangeEvent, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import DynamicTable from '../../../components/tables/DynamicTable';
import { StaffedOption } from '../../../utils/enums/project';
import { SortBy, SortType } from '../../../utils/enums/sort';
import { isEmpty } from '../../../utils/helpers/array';
import {
  enumValuesToDropdownOptions,
  nextActionDropdownOptions,
  projectStateDropdownOptions,
} from '../../../utils/helpers/dropdown';
import { getNextSortType } from '../../../utils/helpers/table';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import useMounted from '../../../hooks/useMounted';
import {
  fetchProjectOptionListItems,
  fetchProjectResponsibles,
  setFilters,
  setHasMoreListItems,
  setPage,
  setSortBy,
  setSortType,
} from '../../../redux/projectOptionListSlice';
import { RootState } from '../../../redux/store';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { IProjectOptionListItem } from '../../../utils/types/responseTypes';
import { createProjectOptionEntry, t } from './projectOptionListHelpers';

const staffedOptionsDropdown = enumValuesToDropdownOptions(
  Object.values(StaffedOption),
  'StaffedEnum'
);

interface IProps extends IWithModalsProps, PropsFromRedux {
  onDelete: (projectId: number) => void;
  onProjectOptionUpdate: (projectId: number) => void;
}

/**
 * Displays the list of project options in a table
 */
const ProjectOptionListTable = ({
  onDelete,
  onProjectOptionUpdate,
  // WithModals
  modalErrorHandler,
  // Redux State
  listItems,
  responsibles,
  filters,
  showFilters,
  hasMore,
  sortBy,
  sortType,
  // Redux actions
  setHasMoreListItems,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  // Thunks
  fetchProjectOptionListItems,
  fetchProjectResponsibles,
}: IProps) => {
  // Check for component mount
  const isMounted = useMounted();

  // Prepare the data to be displayed on the table
  const preparedProjectListItems = listItems.map(
    (projectOption: IProjectOptionListItem) =>
      createProjectOptionEntry(
        projectOption,
        () => onDelete(projectOption.id),
        () => onProjectOptionUpdate(projectOption.id)
      )
  );

  /**
   * Handles the fetching of project option list items
   * @param isSortOrFilterFetchType Whether the data fetching is for sorting / filtering / scrolling
   */
  const fetchProjectOptionList = (isSortOrFilterFetchType: boolean) => {
    fetchProjectOptionListItems(
      isSortOrFilterFetchType,
      (error: AxiosError) => {
        modalErrorHandler(t('failedToRetrieveProjectOption'), error);
      }
    );
  };

  // Fetch project option list and employee list for filter on component mount
  useEffect(() => {
    if (isEmpty(listItems)) {
      fetchProjectOptionList(false);
    }

    if (isEmpty(responsibles)) {
      fetchProjectResponsibles((error) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });
    }
  }, []);

  // Fetch project option list on sort update
  useEffect(() => {
    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);
      fetchProjectOptionList(true);
    }
  }, [sortType, sortBy]);

  // Fetch project option list on filter update
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);

      timer = setTimeout(() => {
        fetchProjectOptionList(true);
      }, 1500);
    }

    return () => clearTimeout(timer);
  }, [filters]);

  const handleSort = (nextSortBy: SortBy) => {
    const nextSortType = getNextSortType(sortType, nextSortBy, sortBy);

    setSortType(
      nextSortType === SortType.DEFAULT ? SortType.DESCENDING : nextSortType
    );
    setSortBy(
      nextSortType === SortType.DEFAULT ? SortBy.LAST_MODIFIED_ON : nextSortBy
    );
  };

  const handleInputFilterChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: event.target.value ?? '',
    });
  };

  const handleProbabilityMinFilterChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setFilters({
      ...filters,
      ['probabilityMin']: !isNaN(parseInt(event?.target?.value))
        ? event?.target?.value
        : '',
    });
  };

  const handleProbabilityMaxFilterChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setFilters({
      ...filters,
      ['probabilityMax']: !isNaN(parseInt(event?.target?.value))
        ? event?.target?.value
        : '',
    });
  };

  const handleDropdownFilterChange = (
    filterValue: IDropdownOption[],
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: filterValue,
    });
  };

  const handleDateFilterChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: event.target.value ? new Date(event.target.value) : null,
    });
  };

  const tableColumns = [
    {
      type: 'Dropdown',
      header: t('menu'),
      accessor: 'menu',
      show: 'true',
      width: '60px',
    },
    {
      type: 'data',
      header: t('title'),
      accessor: 'title',
      show: 'true',
      width: '120px',
      filterValue: filters.title,
      filterFunc: handleInputFilterChange,
      sortFunc: handleSort,
      filterComponentWidth: '100px',
    },
    {
      type: 'data',
      header: t('status'),
      accessor: 'state',
      show: 'true',
      width: '200px',
      filterValue: filters.state,
      filterType: 'dropdown',
      filterOptions: projectStateDropdownOptions,
      filterFunc: handleDropdownFilterChange,
      filterComponentWidth: '180px',
    },
    {
      type: 'data',
      header: t('probability'), // Project Gain Probability
      accessor: 'projectGainProbability',
      show: 'true',
      filterFunc: handleProbabilityMinFilterChange,
      filterFunc2: handleProbabilityMaxFilterChange,
      filterType: 'number range',
      filterComponentWidth: '180px',
      width: '200px',
    },
    {
      type: 'data',
      header: t('customer'),
      accessor: 'customer',
      show: 'true',
      width: '120px',
      filterValue: filters.customer,
      filterComponentWidth: '100px',
      filterFunc: handleInputFilterChange,
    },
    {
      type: 'data',
      header: t('customerSite'),
      accessor: 'customerSite',
      show: 'true',
      width: '120px',
      filterValue: filters.customerSite,
      filterComponentWidth: '100px',
      filterFunc: handleInputFilterChange,
    },
    {
      type: 'data',
      header: t('contactPerson'),
      accessor: 'responsibleContactPerson',
      show: 'true',
      width: '120px',
      filterValue: filters.responsibleContactPerson,
      filterComponentWidth: '100px',
      filterFunc: handleInputFilterChange,
    },
    {
      type: 'data',
      header: t('staffed'),
      accessor: 'staffedOption',
      show: 'true',
      width: '200px',
      filterValue: filters.staffedOption,
      filterkey: 'staffedOption',
      showsearch: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: staffedOptionsDropdown,
      filterComponentWidth: '180px',
    },
    {
      type: 'data',
      header: t('responsibles'),
      accessor: 'responsible',
      show: 'true',
      width: '100px',
      filterValue: filters.responsible,
      filterkey: 'responsibleEntries',
      showsearch: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: responsibles,
      filterComponentWidth: '180px',
    },
    {
      type: 'data',
      header: t('created'),
      accessor: 'createdOn',
      show: 'true',
      filterType: 'date',
      filterFunc: handleDateFilterChange,
      filterComponentWidth: '180px',
      width: '200px',
    },
    {
      type: 'data',
      header: t('nextAction'),
      accessor: 'nextAction',
      show: 'true',
      width: '200px',
      filterValue: filters.nextAction,
      filterType: 'dropdown',
      filterOptions: nextActionDropdownOptions,
      filterFunc: handleDropdownFilterChange,
      filterComponentWidth: '180px',
    },
    {
      type: 'data',
      header: t('dueDate'),
      accessor: 'dueDate',
      show: 'true',
      filterType: 'date',
      filterFunc: handleDateFilterChange,
      filterComponentWidth: '180px',
      width: '200px',
    },
  ];

  return (
    <DynamicTable
      data={preparedProjectListItems}
      columns={tableColumns}
      fetchData={fetchProjectOptionList}
      hasMoreData={hasMore}
      infiniteScroll
      sortType={sortType}
      sortBy={sortBy}
      enableFilterFunction
      showFilters={showFilters}
    />
  );
};

const mapStateToProps = (store: RootState) => ({
  listItems: store.projectOptionList.listItems,
  responsibles: store.projectOptionList.additionalState?.responsibles,
  filters: store.projectOptionList.filters,
  showFilters: store.projectOptionList.showFilters,
  hasMore: store.projectOptionList.hasMore,
  page: store.projectOptionList.page,
  sortBy: store.projectOptionList.sortBy,
  sortType: store.projectOptionList.sortType,
});

const mapDispatchToProps = {
  fetchProjectOptionListItems,
  fetchProjectResponsibles,
  setHasMoreListItems,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(ProjectOptionListTable));
