import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Input,
  Button,
} from 'reactstrap';
import { AxiosError, AxiosResponse } from 'axios';

import {
  GENDER_ENUM,
  ContactInformationStatus,
  ContactPersonFunction,
} from '../../utils/enums/contactPerson';
import { OBJECT_TYPE_ENUM } from '../../utils/enums/objectType';
import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';
import { SERVICE_CONFIG } from '../../utils/enums/service';
import { isNullOrUndefinedOrEmpty } from '../../utils/helpers/GenericHelper';
import { generateTitle } from '../../utils/helpers/icon';
import { clickHandler } from '../../utils/helpers/click';
import i18n from '../../i18n';
import {
  enumValuesToDropdownOptions,
  objectNameAndIdToDropdownOption,
  objectNameAndIdToDropdownOptions,
  sortOptionsByValue,
  dropdownOptionToObjectNameAndId,
  dropdownOptionsToObjectNameAndId,
} from '../../utils/helpers/dropdown';
import {
  IContactPersonDetail,
  ICustomerDropdownItem,
  IObjectContactPersonCustomerSite,
  IObjectCustomerSiteSector,
  IObjectRelatedEntities,
} from '../../utils/types/responseTypes';
import {
  IActivityListItem,
  IContactPersonRole,
  ICustomer,
  ICustomerSector,
  ICustomerSite,
} from '../../utils/types/modelTypes';
import ContactInformationInput from './ContactInformationInput';
import {
  getContactPersonRoleItems,
  saveContactPersonDetails,
} from '../../services/api/contactPerson';
import {
  getCustomerSiteForContactPerson,
  getCustomerSiteSectorObjects,
} from '../../services/api/customerSite';
import MultipleResponsibleDropdown from './MultipleResponsibleDropdown';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import { isEmpty } from '../../utils/helpers/array';
import { ContactInfoType, ContactStatus } from '../../utils/enums/contact';
import {
  IObjectContactInformation,
  IDropdownOption,
  IObjectNameAndId,
  IProjectInvolvedResponsible,
  IObjectInvolvedResponsible,
} from '../../utils/types/commonTypes';
import { getCorrectContactNumberInput } from '../../utils/helpers/string';
import {
  getCustomerDropdownItems,
  getCustomerSectors,
} from '../../services/api/customer';
import { getEmployeeNames } from '../../services/api/employee';
import { PERMISSION_URI } from '../../utils/enums/permission';
import {
  maxEmailCount,
  maxPhoneCount,
  maxMobileCount,
  maxProfileCount,
  preferredLanguages,
  READWRITE,
  SAVE,
  SAVE_AND_GOTO_DETAILS,
} from '../../utils/constants';
import AcademicTitleDropdown from '../dropdowns/AcademicTitleDropdown';
import CustomerRoleItem from '../dropdowns/CustomerRoleItem';
import ContactPersonTopicDropdown from '../dropdowns/ContactPersonTopicDropdown';
import CustomerSitesDropdown from '../dropdowns/CustomerSitesDropdown';
import PreferredLanguageDropdown from '../dropdowns/PreferredLanguageDropdown';
import DetailsCard from '../cards/DetailsCard';
import { checkConfiguration } from '../../utils/helpers/configurationValue';
import MultipleSectorSelect from '../dropdowns/MultipleSectorSelect';
import CustomerDropdown from '../dropdowns/CustomerDropdown';

/**
 * Constants Dropdowns
 */
const genderDropdown = Object.values(GENDER_ENUM).map((gender) => ({
  label: gender,
  value: gender.toLowerCase(),
}));
const functionDropdownOptions = enumValuesToDropdownOptions(
  Object.values(ContactPersonFunction),
  'FunctionEnum'
);

/**
 * Generic translate function
 * @param keyName
 * @returns
 */
const t = (keyName: string) => i18n.t(`ContactPersonModal.${keyName}`);

/**
 * Validates the contact person's information and enables the button if all required fields are answered.
 *
 * @param {IContactPerson} contactPerson - An object containing contact person details.
 * @returns {boolean} Returns true if all required fields are answered, making the contact person valid; otherwise, returns false.
 */
const isContactPersonValid = (contactPerson: IContactPersonDetail) => {
  const { firstname, lastname, sectors, responsible } = contactPerson;

  return (
    !isNullOrUndefinedOrEmpty(firstname) &&
    !isNullOrUndefinedOrEmpty(lastname) &&
    !isEmpty(sectors) &&
    responsible?.id
  );
};

interface IProps extends IWithModalsProps {
  disableCustomerAndCustomerSite: boolean;
  customerId?: number;
  customerSiteId?: number;
  noSaveDetails?: boolean;
  onSave: (savedContactPerson: IContactPersonDetail, action: string) => void;
  onCancel: () => void;
}

const ContactPersonModal = ({
  disableCustomerAndCustomerSite,
  customerId,
  customerSiteId,
  noSaveDetails = true,
  onSave,
  onCancel,
  modalErrorHandler,
}: IProps) => {
  const [contactPerson, setContactPerson] = useState({
    title: { id: 0, name: '' },
    gender: '',
    customerSiteContactInformation: [] as IObjectContactInformation[],
    sectors: [] as IObjectNameAndId[],
    role: { id: 0, name: '' },
    contactInformation: [] as IObjectContactInformation[],
    preferredLanguage: '',
    topics: [] as IObjectNameAndId[],
    notes: '',
    notesId: 0,
    nextActivity: '',
    dueDate: '',
    activities: [] as IActivityListItem[],
    relatedEntities: [] as IObjectRelatedEntities[],
    id: 0,
    lastname: '',
    firstname: '',
    customerAccount: { id: 0, name: '' },
    customer: { id: 0, name: '' },
    customerSite: {
      id: 0,
      nameAndLocation: '',
      sectors: [] as IObjectNameAndId[],
    },
    acquisition: false,
    responsible: { id: 0, name: '' },
    involvedEmployees: [] as IObjectInvolvedResponsible[],
  } as IContactPersonDetail);
  const [employeesWithPermission, setEmployeesWithPermission] = useState(
    [] as IDropdownOption<number>[]
  );
  const [selectableEmployees, setSelectableEmployees] = useState(
    [] as IDropdownOption<number>[]
  );
  const [allContactPersonRoles, setAllContactPersonRoles] = useState(
    [] as IContactPersonRole[]
  );

  const {
    contactInformation,
    responsible,
    involvedEmployees,
    lastname,
    firstname,
    title,
    gender,
    customer,
    role,
    assignmentState,
    preferredLanguage,
    sectors,
    topics,
    customerSite,
    functionRole,
  } = contactPerson;

  const involvedSelected = () =>
    involvedEmployees.map(
      ({ id, name, responsibleRole }) =>
        ({
          responsible: { label: name, value: id },
          responsibleRole: objectNameAndIdToDropdownOption(responsibleRole),
        } as {
          responsible: IDropdownOption<number>;
          responsibleRole: IDropdownOption<number>;
        })
    );

  const isColdCallActivated = checkConfiguration(
    SERVICE_CONFIG.coldCalling.service,
    SERVICE_CONFIG.coldCalling.key,
    SERVICE_CONFIG.coldCalling.value
  );

  /**
   * Handle changes on text box
   * @param event The input in the text box
   * @param property The name of the property on an IContactPerson
   */
  const handleTextBoxChange = (
    { target: { value } }: ChangeEvent<HTMLInputElement>,
    property: string
  ) => {
    setContactPerson({
      ...contactPerson,
      [property]: value,
    });
  };

  /**
   * Handles changing of gender
   * @param {*} selectedGender
   */
  const handleGenderChange = ({ value: gender }: IDropdownOption) => {
    setContactPerson({
      ...contactPerson,
      gender,
    });
  };

  /**
   * Handles changing of title of contact person
   * @param {*} title
   */
  const handleContactPersonTitleChange = (title: IObjectNameAndId) => {
    setContactPerson({
      ...contactPerson,
      title,
    });
  };

  /**
   * Method for disabling a button after reaching a maximum number of inputs
   * @param {*} type
   * @param {*} value
   * @returns true or false
   */
  const disableButton = (type: string, value: number) => {
    const contactInfoArray = contactInformation.filter(
      (contact) => contact.type === type
    );
    if (contactInfoArray.length === value) {
      return true;
    }
    return false;
  };

  /**
   * Handles addition of contact information
   * @param {*} type
   */
  const handleAddContactInformation = (type: string) => {
    setContactPerson({
      ...contactPerson,
      contactInformation: [
        ...contactInformation,
        {
          id: 0,
          info: '',
          status: ContactInformationStatus.UNKNOWN,
          type,
        },
      ],
    });
  };

  /**
   * Removes contact detail from contact person
   * @param index
   */
  const handleRemoveContact = (index: number) => {
    const { contactInformation } = contactPerson;
    contactInformation.splice(index, 1);
    setContactPerson({
      ...contactPerson,
      contactInformation,
    });
  };

  /**
   * Handles changes in contact informations
   * @param {*} event
   * @param {*} index
   */
  const handleContactInformationChanges = (value: string, index: number) => {
    let newContactDetails = value;
    const previousNumberValue = contactInformation[index]?.info as string;

    const contactInformationToUpdate = contactInformation[
      index
    ] as IObjectContactInformation;

    if (newContactDetails.trim() === '') {
      contactInformationToUpdate.info = '';
      contactInformation[index] = contactInformationToUpdate;
    } else {
      // Checks if our current contactinformation is a mobile or a phone
      if (
        contactInformationToUpdate.type === ContactInfoType.MOBILE ||
        contactInformationToUpdate.type === ContactInfoType.PHONE
      ) {
        newContactDetails = getCorrectContactNumberInput(
          previousNumberValue,
          newContactDetails
        );
      }

      contactInformationToUpdate.info = newContactDetails;
      contactInformation[index] = contactInformationToUpdate;
    }
    setContactPerson({
      ...contactPerson,
      contactInformation,
    });
  };

  /**
   * Handles changes of email status
   * @param {*} event
   * @param {*} index
   */
  const handleEmailStatusChange = (checked: string, index: number) => {
    const contactInformationEdit: IObjectContactInformation = {
      ...(contactInformation[index] as IObjectContactInformation),
    };

    contactInformationEdit.status =
      checked === 'true'
        ? ContactInformationStatus.GUESSED
        : ContactInformationStatus.UNKNOWN;

    contactInformation[index] = contactInformationEdit;

    setContactPerson({
      ...contactPerson,
      contactInformation,
    });
  };

  /**
   * Handles changing of sectors/industy
   * @param {*} sectors
   */
  const handleMultipleSectorChange = (sectors: IObjectNameAndId[]) => {
    setContactPerson({
      ...contactPerson,
      sectors,
    });
  };

  /**
   * Handles changing of customer site
   * parameter values to be updated when https://dev.azure.com/invensitygmbh/ITW-Schindler/_workitems/edit/6125/ is done
   * @param {*} customerSite
   */
  const handleCustomerSiteChange = ({
    id = 0,
    name = '',
    location = '',
    customer: { id: customerId = 0, name: customerName } = {
      id: 0,
      name: '',
    } as ICustomer,
  }: ICustomerSite) => {
    getCustomerSiteSectorObjects({
      'customerSiteId.equals': id.toString(),
    })
      .then(({ data }: AxiosResponse<IObjectCustomerSiteSector[]>) => {
        const sectors = data.map(({ sector }) => sector);

        setContactPerson({
          ...contactPerson,
          customerSite: {
            id: id ?? 0,
            nameAndLocation: `${name} (${location})`,
            sectors: [],
          },
          customer: {
            id: customerId,
            name: customerName,
          },
          sectors,
        });
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToRetrieveCustomerSiteSectors'), error);
      });
  };

  /**
   * Handles changing of responsible
   * @param {*} responsible
   */
  const handleResponsibleChange = (responsible: IDropdownOption<number>) => {
    setContactPerson({
      ...contactPerson,
      responsible: dropdownOptionToObjectNameAndId(responsible),
    });
  };

  /**
   * Handles changing of involved responsible
   * @param {*} updatedInvolvedResponsibles
   */
  const handleInvolvedResponsiblesChange = (
    updatedInvolvedResponsibles: IProjectInvolvedResponsible[]
  ) => {
    setContactPerson({
      ...contactPerson,
      involvedEmployees: updatedInvolvedResponsibles.map(
        (involved: IProjectInvolvedResponsible) =>
          ({
            ...dropdownOptionToObjectNameAndId(involved.responsible),
            responsibleRole: {
              ...dropdownOptionToObjectNameAndId(involved.responsibleRole),
            },
          } as IObjectInvolvedResponsible)
      ),
    });
  };

  /**
   * Handles changing of customer
   * @param {*} customer
   */
  const handleCustomerChange = ({
    id,
    name,
    sectors,
  }: ICustomerDropdownItem) => {
    setContactPerson({
      ...contactPerson,
      customer: { id, name },
      sectors,
    });
  };

  /**
   * Handles changing of responsible
   * @param {*} state
   */
  const handleAssignmentState = ({
    value: assignmentState,
  }: IDropdownOption) => {
    setContactPerson({
      ...contactPerson,
      assignmentState,
    });
  };

  /**
   * Handles changing of role
   * @param {*} newRole
   */
  const handleContactPersonRoleChange = (newRole: string) => {
    const { id, role: name } = allContactPersonRoles.find(
      (contactRole) => contactRole.role === newRole
    ) ?? { id: 0, role: '' };
    setContactPerson({
      ...contactPerson,
      role: { id, name } as IObjectNameAndId,
    });
  };

  /**
   * Handles the changes in the Topic multi dropdown
   * @param selectedTopics The array of selected topics
   */
  const handleTopicsChange = (selectedTopics: IDropdownOption<number>[]) => {
    setContactPerson({
      ...contactPerson,
      topics: dropdownOptionsToObjectNameAndId(selectedTopics),
    });
  };

  /**
   * Handles changing of main language
   * @param {*} selectedLanguage
   */
  const handleMainLanguageChange = (selectedLanguage: string) => {
    setContactPerson({
      ...contactPerson,
      preferredLanguage: selectedLanguage,
    });
  };

  /**
   * Handles the saving of contact person
   */
  const addUpdateContactPersonDetails = (action: string) => {
    saveContactPersonDetails(contactPerson)
      .then(({ data }: AxiosResponse<IContactPersonDetail>) => {
        onSave(data, action);
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('updateContactPersonFailed'), error);
      });
  };

  /**
   * Initialize the values for when the card is called
   * @param customerDropdownValues
   */
  const initializeValues = (
    customerDropdownValues: IDropdownOption<number>[]
  ) => {
    // If customer site is listed, then add the customer site too
    if (customerId && customerDropdownValues) {
      getCustomerSectors({
        'customerId.equals': `${customerId.toString()}`,
      })
        .then((response: AxiosResponse<ICustomerSector[]>) => {
          // Set The customer Sectors first
          const customerSectors = response.data;
          const sectors = customerSectors.map(
            ({ sector }) =>
              ({
                id: sector?.id,
                name: sector?.sector,
              } as IObjectNameAndId)
          );
          // Set the customer value
          const customer = customerDropdownValues.find(
            (customer) => customer.value === customerId
          ) as IDropdownOption<number>;

          // If there is a customer site, then get it
          if (customerSiteId) {
            getCustomerSiteForContactPerson({
              'id.equals': customerSiteId.toString(),
            })
              .then(
                ({
                  data,
                }: AxiosResponse<IObjectContactPersonCustomerSite[]>) => {
                  if (data[0])
                    setContactPerson({
                      ...contactPerson,
                      customerSite: data[0],
                      customer: dropdownOptionToObjectNameAndId(customer),
                      sectors,
                    });
                }
              )
              .catch((error: AxiosError) => {
                modalErrorHandler(
                  t('failedToRetrieveCustomerSiteDetails'),
                  error
                );
              });
          }
          // If not, go straight to insert
          else {
            setContactPerson({
              ...contactPerson,
              customer: dropdownOptionToObjectNameAndId(customer),
              sectors,
            });
          }
        })
        .catch((error: AxiosError) => {
          modalErrorHandler(t('failedToRetrieveCustomerSectors'), error);
        });
    }
  };

  useEffect(() => {
    // Get employees with permissions to read and write in Contact Person list
    getEmployeeNames({
      'permissionsFilter.in': PERMISSION_URI.contactPeopleList.readWrite.uri,
      'accessTypeFilter.in': READWRITE,
    })
      .then(({ data: employeesWithPermission }) => {
        setEmployeesWithPermission(
          objectNameAndIdToDropdownOptions(employeesWithPermission)
        );
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });

    // Get all the contact person roles
    getContactPersonRoleItems()
      .then((res: AxiosResponse<IContactPersonRole[]>) => {
        if (!isEmpty(res.data)) {
          setAllContactPersonRoles(res.data);
        }
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToGetAllContactPersonRoles'), error);
      });

    // Get Customers and initialize dropdown values
    getCustomerDropdownItems()
      .then(({ data }: AxiosResponse<IObjectNameAndId[]>) => {
        if (!isEmpty(data)) {
          const customerDropdownValues = objectNameAndIdToDropdownOptions(data);
          initializeValues(customerDropdownValues);
        }
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToGetCustomerDropdownList'), error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectableEmployees(
      employeesWithPermission.filter(
        ({ value }: IDropdownOption<number>) =>
          ![responsible.id, ...involvedEmployees.map(({ id }) => id)].includes(
            value
          )
      )
    );
  }, [responsible, involvedEmployees, employeesWithPermission]);

  const fieldsLeft = [
    {
      label: t('name'),
      value: (
        <Row>
          <Col>
            <Input
              data-testid="firstname-input"
              type="string"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleTextBoxChange(event, 'firstname');
              }}
              value={firstname}
              placeholder={t('firstName')}
            />
          </Col>
          <Col>
            <Input
              data-testid="lastname-input"
              type="string"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleTextBoxChange(event, 'lastname');
              }}
              value={lastname}
              placeholder={t('lastName')}
            />
          </Col>
        </Row>
      ),
      isRequired: true,
    },
    {
      label: t('title'),
      value: (
        <AcademicTitleDropdown
          selectedTitle={title.name}
          onChange={handleContactPersonTitleChange}
        />
      ),
    },
    {
      label: t('gender'),
      value: (
        <div data-testid="gender-div">
          <Select
            value={genderDropdown.find(({ value }) => value === gender)}
            options={genderDropdown}
            onChange={handleGenderChange}
            placeholder={t('selectGender')}
          />
        </div>
      ),
    },
    {
      label: t('email'),
      value: (
        <div data-testid="contact-information-email-div">
          {contactInformation.map((info, infoIndex) =>
            info.type === ContactInfoType.EMAIL ? (
              <Row key={infoIndex}>
                <ContactInformationInput
                  index={infoIndex}
                  contactInformation={info}
                  onDelete={(_, index) => handleRemoveContact(index)}
                  onChange={handleContactInformationChanges}
                  onEmailStatusChange={handleEmailStatusChange}
                  objectType={OBJECT_TYPE_ENUM.contactPerson.code}
                />
              </Row>
            ) : null
          )}
          <Button
            color="primary"
            size="sm"
            disabled={disableButton(ContactInfoType.EMAIL, maxEmailCount)}
            onClick={() => handleAddContactInformation(ContactInfoType.EMAIL)}
          >
            <FontAwesomeIcon icon={faPlus} /> {t('add')}
          </Button>
        </div>
      ),
    },
    {
      label: t('phone'),
      value: (
        <div data-testid="contact-information-phone-div">
          {contactInformation.map((info, infoIndex) =>
            info.type === ContactInfoType.PHONE ? (
              <Row key={infoIndex}>
                <ContactInformationInput
                  index={infoIndex}
                  contactInformation={info}
                  onDelete={(_, index) => handleRemoveContact(index)}
                  onChange={handleContactInformationChanges}
                  objectType={OBJECT_TYPE_ENUM.contactPerson.code}
                />
              </Row>
            ) : null
          )}
          <Button
            color="primary"
            size="sm"
            disabled={disableButton(ContactInfoType.PHONE, maxPhoneCount)}
            onClick={() => handleAddContactInformation(ContactInfoType.PHONE)}
          >
            <FontAwesomeIcon icon={faPlus} /> {t('add')}
          </Button>
        </div>
      ),
    },
    {
      label: t('mobile'),
      value: (
        <div data-testid="contact-information-mobile-div">
          {contactInformation.map((info, infoIndex) =>
            info.type === ContactInfoType.MOBILE ? (
              <Row key={infoIndex}>
                <ContactInformationInput
                  index={infoIndex}
                  contactInformation={info}
                  onDelete={(_, index) => handleRemoveContact(index)}
                  onChange={handleContactInformationChanges}
                  objectType={OBJECT_TYPE_ENUM.contactPerson.code}
                />
              </Row>
            ) : null
          )}
          <Button
            color="primary"
            size="sm"
            disabled={disableButton(ContactInfoType.MOBILE, maxMobileCount)}
            onClick={() => handleAddContactInformation(ContactInfoType.MOBILE)}
          >
            <FontAwesomeIcon icon={faPlus} /> {t('add')}
          </Button>
        </div>
      ),
    },
    {
      label: t('xingProfile'),
      value: (
        <div data-testid="contact-information-xing-div">
          {contactInformation.map((info, infoIndex) =>
            info.type === ContactInfoType.XING ? (
              <Row key={infoIndex}>
                <ContactInformationInput
                  index={infoIndex}
                  contactInformation={info}
                  onDelete={(_, index) => handleRemoveContact(index)}
                  onChange={handleContactInformationChanges}
                  objectType={OBJECT_TYPE_ENUM.contactPerson.code}
                />
              </Row>
            ) : null
          )}
          <Button
            color="primary"
            size="sm"
            disabled={disableButton(ContactInfoType.XING, maxProfileCount)}
            onClick={() => handleAddContactInformation(ContactInfoType.XING)}
          >
            <FontAwesomeIcon icon={faPlus} /> {t('add')}
          </Button>
        </div>
      ),
    },
    {
      label: t('linkedInProfile'),
      value: (
        <div data-testid="contact-information-linkedin-div">
          {contactInformation.map((info, infoIndex) =>
            info.type === ContactInfoType.LINKEDIN ? (
              <Row key={infoIndex}>
                <ContactInformationInput
                  index={infoIndex}
                  contactInformation={info}
                  onDelete={(_, index) => handleRemoveContact(index)}
                  onChange={handleContactInformationChanges}
                  objectType={OBJECT_TYPE_ENUM.contactPerson.code}
                />
              </Row>
            ) : null
          )}
          <Button
            color="primary"
            size="sm"
            disabled={disableButton(ContactInfoType.LINKEDIN, maxProfileCount)}
            onClick={() =>
              handleAddContactInformation(ContactInfoType.LINKEDIN)
            }
          >
            <FontAwesomeIcon icon={faPlus} /> {t('add')}
          </Button>
        </div>
      ),
    },
    {
      label: t('responsible'),
      value: (
        <div data-testid="responsible-div">
          <Select
            value={selectableEmployees.find(
              ({ value }) => value === responsible.id
            )}
            options={selectableEmployees}
            onChange={handleResponsibleChange}
          />
        </div>
      ),
      isRequired: true,
    },
    {
      label: t('involved'),
      value: (
        <MultipleResponsibleDropdown
          setResponsibles={handleInvolvedResponsiblesChange}
          responsibles={involvedSelected()}
          responsibleOptions={selectableEmployees}
        />
      ),
    },
  ];
  const fieldsRight = [
    {
      label: t('customer'),
      value: (
        <CustomerDropdown
          customer={customer}
          onCustomerSelect={handleCustomerChange}
          isDisabled={disableCustomerAndCustomerSite}
        />
      ),
      isRequired: false,
    },
    {
      label: t('role'),
      value: (
        <CustomerRoleItem
          roleId={role.id}
          suggestions={allContactPersonRoles}
          onChangeHandler={handleContactPersonRoleChange}
          size="medium"
        />
      ),
    },
    {
      label: t('topic'),
      value: (
        <ContactPersonTopicDropdown
          value={topics.map(({ id, name }) => ({
            id,
            topic: name,
          }))}
          onChange={handleTopicsChange}
        />
      ),
    },
    {
      label: t('customerSite'),
      value: (
        <CustomerSitesDropdown
          customerId={customer.id}
          customerSiteId={customerSite.id}
          onChange={handleCustomerSiteChange}
        />
      ),
    },
  ];

  if (isColdCallActivated) {
    fieldsRight.push({
      label: t('function'),
      value: (
        <div data-testid="function-role-div">
          <Select
            type="string"
            onChange={(event: IDropdownOption) => {
              setContactPerson({
                ...contactPerson,
                functionRole: event.value,
              });
            }}
            options={functionDropdownOptions}
            value={functionDropdownOptions.find(
              (type) => type.value === functionRole
            )}
          />
        </div>
      ),
    });
    fieldsRight.push({
      label: t('contactPersonStatus'),
      value: (
        <div data-testid="contact-person-status-div">
          <Select
            onChange={handleAssignmentState}
            options={sortOptionsByValue(
              Object.values(ContactStatus).map((status) => ({
                value: status.code,
                label: status.name,
              }))
            )}
            value={Object.values(ContactStatus)
              .map((status) => ({
                value: status.code,
                label: status.name,
              }))
              .find((status) => status.value === assignmentState)}
          />
        </div>
      ),
    });
  }
  fieldsRight.push({
    label: t('sector'),
    value: (
      <MultipleSectorSelect
        onChange={handleMultipleSectorChange}
        sectorIds={sectors.map((item) => item.id)}
      />
    ),
    isRequired: true,
  });
  fieldsRight.push({
    label: t('mainLanguage'),
    value: (
      <PreferredLanguageDropdown
        value={Object.values(preferredLanguages).find(
          (language) => preferredLanguage === language.value
        )}
        onChange={handleMainLanguageChange}
      />
    ),
  });
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <h2>{t('addNewContact')}</h2>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <DetailsCard fields={fieldsLeft} />
          </Col>
          <Col>
            <DetailsCard fields={fieldsRight} />
          </Col>
        </Row>
        <Row>
          <div>
            <Button
              data-testid="save-and-close-button"
              color="primary"
              size="m"
              onClick={(event: MouseEvent) =>
                clickHandler(event, () => addUpdateContactPersonDetails(SAVE))
              }
              disabled={!isContactPersonValid(contactPerson)}
            >
              {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, t('saveAndClose'))}
            </Button>
            <span> </span>
            {!noSaveDetails && (
              <Button
                data-testid="save-and-go-to-details-button"
                color="primary"
                size="m"
                onClick={(event: MouseEvent) =>
                  clickHandler(event, () =>
                    addUpdateContactPersonDetails(SAVE_AND_GOTO_DETAILS)
                  )
                }
                disabled={!isContactPersonValid(contactPerson)}
              >
                {generateTitle(
                  BUTTON_TITLE_ENUM.SAVE.code,
                  t('saveAndGoToDetails')
                )}
              </Button>
            )}
            <span> </span>
            <Button
              data-testid="cancel-button"
              color="primary"
              size="m"
              onClick={() => onCancel()}
            >
              {t('cancel')}
            </Button>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withModals(ContactPersonModal);
