import axios from 'axios';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Row } from 'reactstrap';
import { Spinner } from './components/layout/Spinner';
import App from './App';
import store from './redux/store';

axios.defaults.headers.common['Authorization'] = '';

// Render app in `#root` element
ReactDOM.render(
  <Provider store={store}>
    <App />
    <Row className="justify-content-center align-self-center w-100 text-center">
      <Spinner />
    </Row>
  </Provider>,
  document.getElementById('root')
);
