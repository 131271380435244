/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

import { getContactPersons } from '../../../services/api/contactPerson';
import { getCustomers } from '../../../services/api/customer';
import { getCustomerSites } from '../../../services/api/customerSite';
import { getEmployeeNames } from '../../../services/api/employee';
import {
  getDepartments,
  getProjectCount,
  getProjectOptionDetails,
  postProjectOptionDetails,
  putProjectOptionDetails,
} from '../../../services/api/project';
import { AccessType, PERMISSION_URI } from '../../../utils/enums/permission';
import { ProjectState } from '../../../utils/enums/project';
import { DepartmentStatus } from '../../../utils/enums/department';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { RootState } from '../../../redux/store';
import {
  IDropdownOption,
  IProjectInvolvedResponsible,
} from '../../../utils/types/commonTypes';
import {
  IOfferListItem,
  IProjectMemberListItem,
  IProjectOptionDetails,
} from '../../../utils/types/responseTypes';
import ProjectOptionActivities from './ProjectOptionActivities';
import ProjectOptionDescription from './ProjectOptionDescription';
import ProjectOptionEstimations from './ProjectOptionEstimations';
import ProjectOptionFunnel from './ProjectOptionFunnel';
import ProjectOptionGeneralInformation from './ProjectOptionGeneralInformation';
import {
  createFormValuesFromProjectOption,
  createProjectOptionFromFormValues,
  generateDefaultFormValues,
  getProjectState,
  getSelectedProjectActivity,
  isFormValid,
  t,
} from './projectOptionHelpers';
import ProjectOptionHistory from './ProjectOptionHistory';
import ProjectOptionMembers from './ProjectOptionMembers';
import ProjectOptionResponsibles from './ProjectOptionResponsibles';
import ProjectOptionOffers from './ProjectOptionOffers';
import { DEFAULT_LOAD_TIMEOUT } from '../../../utils/constants';

export type ProjectContactPerson = {
  contactPerson: IDropdownOption<number>;
  role: string;
  functionRole: string;
};

export type ProjectActivity = {
  action: IDropdownOption;
  dueDate: string;
};

export type ProjectOptionDetailsForm = {
  // General Information
  title: string;
  customer: IDropdownOption<number>;
  customerSite: IDropdownOption<number>;
  contactPersons: ProjectContactPerson[];
  // Estimations
  start: string;
  projectGainProbability: number;
  duration: number;
  durationType: IDropdownOption;
  end: string;
  averageProjectSize: number;
  totalHoursEstimated: number;
  departments: IDropdownOption<number>[];
  originOfOption: IDropdownOption;
  // Activities
  staffedOption: IDropdownOption;
  acquisitionType: IDropdownOption;
  state: string;
  activities: ProjectActivity[];
  // Description
  description: string;
  // Responsibles
  responsible: IDropdownOption<number>;
  involvedResponsibles: IProjectInvolvedResponsible[];
  // Project Members
  projectMembers: IProjectMemberListItem[];
  // Offers
  offers: IOfferListItem[];
};

interface IProps extends IWithModalsProps, PropsFromRedux {
  projectId?: number | undefined;
  customerId?: number;
  customerSiteId?: number;
  contactPersonId?: number;
  onClose: () => void;
  onSave?: ((id: number) => Promise<void>) | ((id: number) => void);
}

/**
 * Project Option Modal -
 * Displays project option details, allows creation and updating of new project option,
 * and setting of project option to lost
 */
const ProjectOptionModal = ({
  projectId,
  customerId,
  customerSiteId,
  contactPersonId,
  onClose,
  onSave,
  modalErrorHandler,
  currentUser,
}: IProps) => {
  const [project, setProject] = useState<IProjectOptionDetails>(
    {} as IProjectOptionDetails
  );

  // Dropdowns
  const [customerList, setCustomerList] = useState(
    [] as IDropdownOption<number>[]
  );
  const [customerSiteList, setCustomerSiteList] = useState(
    [] as IDropdownOption<number>[]
  );
  const [contactPersonList, setContactPersonList] = useState(
    new Map<number, ProjectContactPerson>()
  );
  const [departmentList, setDepartmentList] = useState(
    [] as IDropdownOption<number>[]
  );
  const [responsibleList, setResponsibleList] = useState(
    [] as IDropdownOption<number>[]
  );

  const [isCheckingTitle, setIsCheckingTitle] = useState(false);
  const [isTitleValid, setIsTitleValid] = useState(true);

  const [formValues, setFormValues] = useState<ProjectOptionDetailsForm>(
    generateDefaultFormValues(currentUser)
  );

  const { title: originalTitle } = project;
  const { title, customer, customerSite, acquisitionType, state } = formValues;

  // TODO: Create endpoint with only name and id
  const fetchCustomers = async () => {
    try {
      const { data: customers } = await getCustomers({ sort: 'name%2Casc' });
      const customerList = customers.map(({ id, name }) => ({
        label: name,
        value: id as number,
      }));

      setCustomerList(customerList);

      if (customerId) {
        const customer = customerList.find(
          ({ value: id }) => id === customerId
        );

        setFormValues((formValues) => ({
          ...formValues,
          ...(customer && { customer }),
        }));
      }
    } catch (error) {
      modalErrorHandler(t('customerDetailsRetrieveFailed'), error);
    }
  };

  // TODO: Create endpoint with only name and id
  const fetchCustomerSites = async (customerId: number) => {
    try {
      const { data: customerSites } = await getCustomerSites({
        'customerId.equals': `${customerId}`,
        sort: 'name%2Casc',
      });
      const customerSiteList = customerSites.map(({ id, name, location }) => ({
        label: `${name} (${location})`,
        value: id as number,
      }));

      setCustomerSiteList(customerSiteList);

      if (customerSiteId) {
        const customerSite = customerSiteList.find(
          ({ value: id }) => id === customerSiteId
        );

        setFormValues((formValues) => ({
          ...formValues,
          ...(customerSite && { customerSite }),
        }));
      }
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveSites'), error);
    }
  };

  // TODO: Create endpoint with only ObjectContactPerson
  const fetchContactPersons = async (
    customerId: number,
    customerSiteId: number
  ) => {
    try {
      const { data: contactPersons } = await getContactPersons({
        'customerId.equals': `${customerId}`,
        'customerSiteId.equals': `${customerSiteId}`,
        sort: 'name%2Casc',
      });

      const contactPersonMap = new Map<number, ProjectContactPerson>();
      contactPersons.forEach(
        ({ id, firstname, lastname, contactPersonRole, functionRole }) => {
          contactPersonMap.set(id as number, {
            contactPerson: {
              label: `${firstname} ${lastname}`,
              value: id as number,
            },
            role: contactPersonRole?.role as string,
            functionRole: functionRole as string,
          });
        }
      );

      setContactPersonList(contactPersonMap);

      if (contactPersonId) {
        const contactPerson = contactPersonMap.get(contactPersonId);

        setFormValues((formValues) => ({
          ...formValues,
          ...(contactPerson && {
            contactPersons: [contactPerson],
          }),
        }));
      }
    } catch (error) {
      modalErrorHandler(t('retrieveContactPersonsFailed'), error);
    }
  };

  // TODO: Create endpoint with only name and id
  const fetchDepartments = async () => {
    try {
      const { data: departments } = await getDepartments({
        'status.in': `${DepartmentStatus.ACTIVE},${DepartmentStatus.PLANNED}`,
      });

      setDepartmentList(
        departments.map(({ id, title }) => ({
          label: title,
          value: id as number,
        }))
      );
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveDepartments'), error);
    }
  };

  const fetchResponsibles = async () => {
    try {
      const { data: responsibles } = await getEmployeeNames({
        'permissionsFilter.in': PERMISSION_URI.projectOptions.readWrite.uri,
        'accessTypeFilter.in': AccessType.READWRITE,
      });

      setResponsibleList(
        responsibles.map(({ id, name }) => ({
          label: name,
          value: id,
        }))
      );
    } catch (error) {
      modalErrorHandler(t('failedToGetEmployees'), error);
    }
  };

  const setForm = (projectOption: IProjectOptionDetails) => {
    setFormValues(createFormValuesFromProjectOption(projectOption));
  };

  const clearForm = () => {
    setFormValues(generateDefaultFormValues(currentUser));
  };

  const fetchProjectOptionDetails = async () => {
    if (projectId) {
      try {
        const { data: project } = await getProjectOptionDetails(projectId);

        setProject(project);
        setForm(project);
      } catch (error) {
        modalErrorHandler(t('failedToRetrieveProjectOptionDetails'), error);
      }
    }
  };

  const handleProjectOptionSave = async () => {
    try {
      let projectOption = createProjectOptionFromFormValues(
        project,
        formValues
      );

      if (projectId || project.id) {
        const {
          data: { activities },
        } = await putProjectOptionDetails(projectOption);

        setProject({ ...projectOption, activities });
        setFormValues((formValues) => ({
          ...formValues,
          state: getProjectState(formValues),
          activities: getSelectedProjectActivity(state, acquisitionType.value),
        }));
      } else {
        const {
          data: { id, projectMembers },
        } = await postProjectOptionDetails(projectOption);

        // Update project option id
        projectOption = { ...projectOption, id };

        setProject({
          ...projectOption,
          id,
          projectMembers,
        });

        setFormValues((formValues) => ({
          ...formValues,
          state: getProjectState(formValues),
          activities: getSelectedProjectActivity(state, acquisitionType.value),
          projectMembers,
        }));
      }

      if (onSave) {
        onSave(projectOption.id);
      }
    } catch (error) {
      modalErrorHandler(t('failedToSaveProjectOption'), error);
    }
  };

  const handleProjectOptionLost = async () => {
    try {
      const projectOption = createProjectOptionFromFormValues(project, {
        ...formValues,
        state: ProjectState.LOST,
      });

      await putProjectOptionDetails(projectOption);

      setProject(projectOption);
      setFormValues((formValues) => ({
        ...formValues,
        state: ProjectState.LOST,
      }));
    } catch (error) {
      modalErrorHandler(t('failedToSaveProjectOption'), error);
    }
  };

  useEffect(() => {
    fetchCustomers();
    fetchDepartments();
    fetchResponsibles();
    fetchProjectOptionDetails();
  }, []);

  useEffect(() => {
    if (title && title !== originalTitle) {
      setIsCheckingTitle(true);
    }

    const timer = setTimeout(() => {
      if (title !== originalTitle) {
        getProjectCount({ 'title.equals': title }).then(({ data }) => {
          setIsTitleValid(data === 0);
          setIsCheckingTitle(false);
        });
      }
    }, DEFAULT_LOAD_TIMEOUT);

    return () => clearTimeout(timer);
  }, [title]);

  useEffect(() => {
    if (customer.value) {
      fetchCustomerSites(customer.value);
    }
  }, [customer]);

  useEffect(() => {
    if (customer.value && customerSite.value) {
      fetchContactPersons(customer.value, customerSite.value);
    }
  }, [customer, customerSite]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <ProjectOptionFunnel
            project={project}
            isFormValid={isFormValid(
              formValues,
              isCheckingTitle,
              isTitleValid,
              projectId
            )}
            formValues={formValues}
            onCancel={onClose}
            onLost={handleProjectOptionLost}
            onResetForm={() =>
              projectId || project.id ? setForm(project) : clearForm()
            }
            onSave={handleProjectOptionSave}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <ProjectOptionGeneralInformation
                project={project}
                customerList={customerList}
                customerSiteList={customerSiteList}
                contactPersonList={contactPersonList}
                setContactPersonList={setContactPersonList}
                isCheckingTitle={isCheckingTitle}
                isTitleValid={isTitleValid}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProjectOptionActivities
                project={project}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProjectOptionDescription
                project={project}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {(projectId || project.id) && (
                <ProjectOptionOffers
                  project={{ ...project, offers: project.offers ?? [] }}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <ProjectOptionEstimations
                project={project}
                departmentList={departmentList}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProjectOptionHistory project={project} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProjectOptionResponsibles
                responsibleList={responsibleList}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {(projectId || project.id) && (
                <ProjectOptionMembers
                  project={project}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  currentUser: store.account.employeeDetails,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(ProjectOptionModal));
