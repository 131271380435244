import axios, { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { urlBuilder } from '../../utils/helpers/url';
import {
  IContactPersonDetail,
  IGdprListItem,
  IContactPersonListItem,
  ICustomerDetailContactPersonListItem,
} from '../../utils/types/responseTypes';
import { Url } from './constants';
import {
  IAttitude,
  ICallStatistics,
  IContactInformation,
  IContactPerson,
  IContactPersonAssignment,
  IContactPersonRole,
  IContactPersonTopicListItem,
  IExtendedContactPerson,
  IResponsible,
  ITitle,
} from '../../utils/types/modelTypes';

export const getContactPersons = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<IContactPerson[]>>(
    axios.get(urlBuilder(Url.CONTACT_PEOPLE, queryParameters))
  );

export const getContactPerson = (contactPersonId: number) =>
  trackPromise<AxiosResponse<IContactPerson>>(
    axios.get(urlBuilder(`${Url.CONTACT_PEOPLE}/${contactPersonId}`, {}))
  );

export const updateContactPersonDetails = (
  contactPersonDetails: IContactPersonDetail
) =>
  trackPromise<AxiosResponse<IContactPersonDetail>>(
    axios.put(urlBuilder(Url.CONTACT_PERSON_DETAILS, {}), contactPersonDetails)
  );

export const saveContactPersonDetails = (
  contactPersonDetails: IContactPersonDetail
) =>
  trackPromise<AxiosResponse<IContactPersonDetail>>(
    axios.post(urlBuilder(Url.CONTACT_PERSON_DETAILS, {}), contactPersonDetails)
  );

export const updateContactPersons = (contactPerson: IContactPerson) =>
  trackPromise<AxiosResponse<IContactPerson>>(
    axios.put(urlBuilder(Url.CONTACT_PEOPLE, {}), contactPerson)
  );

export const getContactPersonListItems = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IContactPersonListItem[]>>(
    axios.get(urlBuilder(Url.CONTACT_PERSON_LIST, queryParameters))
  );

export const deleteContactPerson = (contactPersonId: number) =>
  trackPromise<AxiosResponse<void>>(
    axios.delete(urlBuilder(`${Url.CONTACT_PEOPLE}/${contactPersonId}`, {}))
  );

export const deleteResponsibleContactPerson = (contactPersonId: number) =>
  trackPromise<AxiosResponse<void>>(
    axios.delete(
      urlBuilder(`${Url.RESPONSIBLE_CONTACT_PEOPLE}/${contactPersonId}`, {})
    )
  );

export const removeContactPersonFromCustomer = (contactPersonId: number) =>
  trackPromise<AxiosResponse<void>>(
    axios.put(urlBuilder(`${Url.REMOVE_CONTACT_PERSON}/${contactPersonId}`, {}))
  );

export const getGdprListItems = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<IGdprListItem[]>>(
    axios.get(urlBuilder(Url.GDPR_CONTACT_PERSON_LIST, queryParameters))
  );

export const saveInfoGdpr = (gdprListItem: IGdprListItem) =>
  trackPromise<AxiosResponse<void>>(
    axios.put(urlBuilder(Url.SAVE_INFO_GDPR, {}), gdprListItem)
  );

export const getContactPersonDetails = (contactPersonId: number) =>
  trackPromise<AxiosResponse<IContactPersonDetail>>(
    axios.get(
      urlBuilder(`${Url.CONTACT_PERSON_DETAILS}/${contactPersonId}`, {})
    )
  );

export const saveResponsible = (payload: IResponsible) => {
  if (payload.id) {
    return trackPromise<AxiosResponse<IResponsible>>(
      axios.put(urlBuilder(Url.RESPONSIBLE, {}), payload)
    );
  }
  return trackPromise<AxiosResponse<IResponsible>>(
    axios.post(urlBuilder(Url.RESPONSIBLE, {}), payload)
  );
};

export const postExtendedContactPerson = (
  queryParameters: {
    [key: string]: string;
  },
  payload: IExtendedContactPerson
) =>
  trackPromise<AxiosResponse<IExtendedContactPerson[]>>(
    axios.post(
      urlBuilder(Url.EXTENDED_CONTACT_PEOPLE, queryParameters),
      payload
    )
  );

export const getExtendedContactPerson = (queryParameters: {
  [key: string]: any;
}) =>
  trackPromise<AxiosResponse<IExtendedContactPerson[]>>(
    axios.get(urlBuilder(Url.EXTENDED_CONTACT_PEOPLE, queryParameters))
  );

export const getExtendedContactPersonCallCount = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<number>>(
    axios.get(
      urlBuilder(Url.EXTENDED_CONTACT_PEOPLE_COUNT_CALL, queryParameters)
    )
  );

export const getExtendedContactPersonCallOverdueCount = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<number>>(
    axios.get(
      urlBuilder(
        Url.EXTENDED_CONTACT_PEOPLE_COUNT_CALL_OVERDUE,
        queryParameters
      )
    )
  );

export const exportAllContactsToOutlook = (
  contactPersonIdsFromListItem: number[]
) =>
  trackPromise<AxiosResponse<void>>(
    axios.put(
      urlBuilder(Url.EXPORT_ALL_CONTACTS_TO_OUTLOOK, {}),
      contactPersonIdsFromListItem
    )
  );

export const updateContactPerson = (contactPerson: IContactPerson) =>
  trackPromise<AxiosResponse<void>>(
    axios.put(urlBuilder(Url.CONTACT_PEOPLE, {}), contactPerson)
  );

export const getStatisticsContactPeopleUpdateCreation = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<any>>(
    axios.get(
      urlBuilder(
        Url.STATISTICS_CONTACT_PEOPLE_UPDATE_CREATION,
        queryParameters
      ),
      { responseType: 'blob' }
    )
  );

export const saveContactPersonAssignment = (
  contactPersonAssignment: IContactPersonAssignment
) =>
  trackPromise<AxiosResponse<IContactPersonAssignment>>(
    axios.post(
      urlBuilder(Url.CONTACT_PERSON_ASSIGNMENTS, {}),
      contactPersonAssignment
    )
  );

export const updateContactPersonAssignment = (
  contactPersonAssignment: IContactPersonAssignment
) =>
  trackPromise<AxiosResponse<IContactPersonAssignment>>(
    axios.put(
      urlBuilder(Url.CONTACT_PERSON_ASSIGNMENTS, {}),
      contactPersonAssignment
    )
  );

export const sendContactPersonAssignmentEmail = (
  emailBody: any,
  assignmentId: number
) =>
  trackPromise<AxiosResponse<IContactPersonAssignment>>(
    axios.post(
      urlBuilder(
        `${Url.CONTACT_PERSON_ASSIGNMENTS}/${assignmentId}${Url.EMAIL}`,
        {}
      ),
      emailBody
    )
  );

export const getContactPersonAssignment = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IContactPersonAssignment[]>>(
    axios.get(urlBuilder(Url.CONTACT_PERSON_ASSIGNMENTS, queryParameters))
  );

export const getContactInformations = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IContactInformation[]>>(
    axios.get(urlBuilder(Url.CONTACT_INFORMATIONS, queryParameters))
  );

export const getContactPersonTopics = () =>
  trackPromise<AxiosResponse<IContactPersonTopicListItem[]>>(
    axios.get(urlBuilder(Url.CONTACT_PERSON_TOPICS, {}))
  );

export const getContactPersonTitle = () =>
  trackPromise<AxiosResponse<ITitle[]>>(axios.get(urlBuilder(Url.TITLES, {})));

export const exportContactPersonToOutlook = (contactPerson: IContactPerson) =>
  trackPromise<AxiosResponse<void>>(
    axios.put(
      urlBuilder(Url.EXPORT_CONTACT_PEOPLE_TO_OUTLOOK, {}),
      contactPerson
    )
  );

export const getContactPersonAttitudes = () =>
  trackPromise<AxiosResponse<IAttitude[]>>(
    axios.get(urlBuilder(Url.CONTACT_PERSON_ATTITUDES, {}))
  );

export const getContactPersonRoleItems = () =>
  trackPromise<AxiosResponse<IContactPersonRole[]>>(
    axios.get(urlBuilder(Url.CONTACT_PERSON_ROLES, {}))
  );

export const getUserCallStatistics = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<ICallStatistics>>(
    axios.get(urlBuilder(Url.CALL_STATISTICS, queryParameters))
  );

export const saveStatistics = (statistics: ICallStatistics) =>
  trackPromise<AxiosResponse<void>>(
    axios.post(urlBuilder(Url.CALL_STATISTICS, {}), statistics)
  );

export const saveContactInformation = (
  contactInformation: IContactInformation
) => {
  if (contactInformation.id) {
    return trackPromise<AxiosResponse<IContactInformation>>(
      axios.put(
        urlBuilder(`${Url.CONTACT_INFORMATIONS}`, {}),
        contactInformation
      )
    );
  }
  return trackPromise<AxiosResponse<IContactInformation>>(
    axios.post(
      urlBuilder(`${Url.CONTACT_INFORMATIONS}`, {}),
      contactInformation
    )
  );
};

export const deleteContactInformation = (contactInformationId: number) =>
  trackPromise<AxiosResponse<void>>(
    axios.delete(
      urlBuilder(`${Url.CONTACT_INFORMATIONS}/${contactInformationId}`, {})
    )
  );

export const resetContactPerson = (contactPersonId: number) =>
  trackPromise<AxiosResponse<IContactPersonDetail>>(
    axios.get(urlBuilder(`${Url.RESET_CONTACT_PERSON}/${contactPersonId}`, {}))
  );

export const setContactPersonFlag = (setTo: boolean, payload: number[]) =>
  trackPromise<AxiosResponse<void>>(
    axios.put(
      urlBuilder(
        `${Url.SET_FLAG_CONTACT_PERSON}/${setTo ? 'true' : 'false'}`,
        {}
      ),
      payload
    )
  );
export const addContactPeopleToCustomer = (
  customerId: number,
  contactPersonIds: number[]
) =>
  trackPromise(
    axios.put(
      urlBuilder(`${Url.ADD_CONTACT_PEOPLE_TO_CUSTOMER}/${customerId}`, {}),
      contactPersonIds
    )
  );

export const getExistingContactPersonList = () =>
  trackPromise<AxiosResponse<ICustomerDetailContactPersonListItem[]>>(
    axios.get(urlBuilder(`${Url.EXISTING_CONTACT_PERSON_LIST_ITEMS}`, {}))
  );
