import {
  faPause,
  faPlay,
  faStopCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

import i18n from '../../../i18n';

interface IProps {
  callEnd: boolean;
  endCall: (duration: string) => void;
  updateStatistics: (duration: string) => void;
}

const t = (keyName: string) => i18n.t(`CallingStatistics.${keyName}`);

/**
 * Display Call timer when in call mode
 */
const CallTimer = ({ callEnd, endCall, updateStatistics }: IProps) => {
  const [second, setSecond] = useState('00');
  const [minute, setMinute] = useState('00');
  const [hour, setHour] = useState('00');
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);

  const pause = () => {
    setIsActive(false);
  };

  const resume = () => {
    setIsActive(true);
  };

  const handleEndCall = () => {
    updateStatistics(`PT${counter.toString()}S`);
    pause();
    endCall(`PT${counter.toString()}S`);
  };

  useEffect(() => {
    setIsActive(true);
  }, []);

  useEffect(() => {
    let intervalId: any;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60) % 60;
        const hourCounter = Math.floor(counter / 3600);

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : `${secondCounter}`;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : `${minuteCounter}`;
        const computedHour =
          String(hourCounter).length === 1
            ? `0${hourCounter}`
            : `${hourCounter}`;

        setSecond(computedSecond);
        setMinute(computedMinute);
        setHour(computedHour);

        setCounter((counter) => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  return (
    <div>
      <div
        className="mt-7 pt-5"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {callEnd && <>{t('totalCallTime')} </>}
        {hour}:{minute}:{second}
      </div>
      <br />
      {!callEnd && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ButtonGroup>
            <Button color="primary" onClick={pause}>
              {t('pause')} <FontAwesomeIcon icon={faPause} />
            </Button>
            &nbsp;
            <Button color="primary" onClick={resume}>
              {t('resume')} <FontAwesomeIcon icon={faPlay} />
            </Button>
            &nbsp;
            <Button color="primary" onClick={handleEndCall}>
              {t('end')} <FontAwesomeIcon icon={faStopCircle} />
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

export default CallTimer;
