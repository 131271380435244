import { faChartPie } from '@fortawesome/free-solid-svg-icons';

import { PERMISSION_URI } from '../../utils/enums/permission';
import { SERVICE_NAMES_ENUM } from '../../utils/enums/service';
import i18n from '../../i18n';
// Project
import ProjectOptionsList from '../../pages/salesFunnel/ProjectOptionList/ProjectOptionList';

// eslint-disable-next-line import/prefer-default-export
export const salesRoutes = {
  path: '/sales',
  name: i18n.t('index.sales'),
  serviceName: SERVICE_NAMES_ENUM.salesFunnel.code,
  icon: faChartPie,
  isProtected: true,
  auth: true,
  badgeColor: 'primary',
  permissionUri: [PERMISSION_URI.projectOptions.read.uri],
  children: [
    {
      path: '/sales/project-options',
      name: i18n.t('index.projectOptions'),
      component: ProjectOptionsList,
      permissionUri: [PERMISSION_URI.projectOptions.read.uri],
    },
  ],
};
