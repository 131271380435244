import React, { useContext, useState } from 'react';
import { Col, Row, Label, Input } from 'reactstrap';
import Datetime from 'react-datetime';
import Select from 'react-select';
import moment, { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import i18n from '../../../i18n';
import {
  generateOfferTitle,
  getValidUntilDetails,
  getDateDetails,
  getOfferSum,
  getPaymentGoalDetails,
  createOfferDocumentTitle,
  translate,
  contractTypeList,
} from './offerHelper';
import { OfferState } from '../../../utils/enums/offer';
import { OfferDetailsContext } from './OfferDetailsProvider';
import { currencyDropdownOptions } from '../../../utils/helpers/dropdown';
import DetailsCard from '../../../components/cards/DetailsCard';
import { getDateFormat } from '../../../utils/helpers/date';
import { paymentGoalOptions } from '../../../utils/enums/enum';
import { IDropdownOption } from '../../../utils/types/commonTypes';

interface IProps {
  isCheckingTitle: boolean;
  isTitleValid: boolean;
}

const OfferDetailsCard = ({ isCheckingTitle, isTitleValid }: IProps) => {
  const { offer, project, formValues, setFormValues } =
    useContext(OfferDetailsContext);

  const {
    title,
    date,
    currency,
    budget,
    contractType,
    paymentGoal,
    validityPeriod,
  } = formValues;

  const [edit, setEdit] = useState({
    offerTitle: false,
    offerContractType: false,
    offerDateCreation: false,
    offerPaymentGoal: false,
    offerSum: false,
    offerValidityPeriod: false,
  });

  const [documentTitlePreview, setDocumentTitlePreview] = useState(
    createOfferDocumentTitle(
      project.customer.abbreviation,
      title,
      date.toString(),
      offer.offerVersion
    )
  );

  const isNewOffer = offer.id === undefined;

  const DATE_FORMAT = getDateFormat();

  const handleContractTypeChange = (contractType: {
    value: string;
    label: string;
  }) =>
    setFormValues((values) => ({
      ...values,
      contractType: contractType.value,
    }));

  const handlePaymentGoalChange = ({ value }: IDropdownOption<number>) =>
    setFormValues((values) => ({
      ...values,
      paymentGoal: value,
    }));

  const detailList = [
    {
      label: translate('offerTitle'),
      value: edit.offerTitle ? (
        <>
          <Input
            type="string"
            placeholder={translate('offerTitle')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDocumentTitlePreview(
                createOfferDocumentTitle(
                  project.customer.abbreviation,
                  event.target.value,
                  date.toString(),
                  offer.offerVersion
                )
              );
              setFormValues((values) => ({
                ...values,
                title: event.target.value,
              }));
            }}
          />
          {isCheckingTitle && (
            <>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ color: 'gray' }}
                className="margin-right"
              />
              {translate('checkingTitle')}
            </>
          )}
          {!isCheckingTitle && offer.title !== title && (
            <>
              <FontAwesomeIcon
                icon={isTitleValid ? faCheckCircle : faTimesCircle}
                style={{ color: isTitleValid ? 'green' : 'red' }}
                className="margin-right"
              />
              {translate(isTitleValid ? 'validTitle' : 'invalidTitle')}
            </>
          )}
        </>
      ) : (
        <Label> {generateOfferTitle(title, offer.offerVersion ?? 1)} </Label>
      ),
      isRequired: isNewOffer,
      action: () => {
        if (offer.offerState === OfferState.DRAFT) {
          setEdit({
            offerTitle: true,
            offerContractType: false,
            offerDateCreation: false,
            offerPaymentGoal: false,
            offerSum: false,
            offerValidityPeriod: false,
          });
        }
      },
    },
    {
      label: translate('currentOfferVersion'),
      value: <Label>{`v${offer.offerVersion}`}</Label>,
    },
    {
      label: translate('creationDate'),
      value: edit.offerDateCreation ? (
        <Datetime
          dateFormat={DATE_FORMAT}
          input
          timeFormat={false}
          inputProps={{
            placeholder: DATE_FORMAT,
          }}
          onChange={(event: Moment | string) => {
            setDocumentTitlePreview(
              createOfferDocumentTitle(
                project.customer.abbreviation,
                title,
                event.toString(),
                offer.offerVersion
              )
            );
            setFormValues((values) => ({
              ...values,
              date: moment(event).format(DATE_FORMAT),
            }));
          }}
          closeOnSelect
          value={moment(date).format(DATE_FORMAT)}
          locale={i18n.language}
        />
      ) : (
        <Label>{getDateDetails(date)}</Label>
      ),
      action: () => {
        if (
          offer.offerState === OfferState.DRAFT ||
          offer.offerState === OfferState.VALID
        ) {
          setEdit({
            offerTitle: false,
            offerContractType: false,
            offerDateCreation: true,
            offerPaymentGoal: false,
            offerSum: false,
            offerValidityPeriod: false,
          });
        }
      },
    },
    {
      label: translate('offerDocumentTitle'),
      value: (
        <Label>
          {offer.offerDocumentTitle
            ? offer.offerDocumentTitle
            : documentTitlePreview}
        </Label>
      ),
    },
    {
      label: translate('offerSent'),
      value:
        offer.offerSent && offer.offerState !== OfferState.DRAFT ? (
          <Label> {getDateDetails(offer.offerSent)} </Label>
        ) : (
          <Input disabled value={translate('notSent')} />
        ),
    },
    {
      label: translate('offerSum'),
      value: edit.offerSum ? (
        <Row>
          <Col>
            <Select
              aria-label="currency-select"
              value={currencyDropdownOptions.filter(
                (option) => option.value === currency
              )}
              options={currencyDropdownOptions.filter(
                (option) => option.value !== currency
              )}
              onChange={(selectedOption: { value: string; label: string }) =>
                setFormValues((values) => ({
                  ...values,
                  currency: selectedOption.value,
                }))
              }
            />
          </Col>
          <Col>
            <Input
              type="number"
              min={0}
              value={budget === 0 ? '' : budget}
              placeholder="Amount"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFormValues((values) => ({
                  ...values,
                  budget: Number(event.target.value),
                }))
              }
            />
          </Col>
        </Row>
      ) : (
        <Label>{getOfferSum(currency ?? '', String(budget) ?? '')}</Label>
      ),
      isRequired: isNewOffer,
      action: () => {
        if (
          offer.offerState === OfferState.DRAFT ||
          offer.offerState === OfferState.VALID
        ) {
          setEdit({
            offerTitle: false,
            offerContractType: false,
            offerDateCreation: false,
            offerPaymentGoal: false,
            offerSum: true,
            offerValidityPeriod: false,
          });
        }
      },
    },
    {
      label: translate('contractType'),
      value: edit.offerContractType ? (
        <Select
          aria-label="inputContractType"
          value={contractTypeList.find(
            (selectedContractType) =>
              selectedContractType.label === contractType
          )}
          options={contractTypeList}
          onChange={handleContractTypeChange}
        />
      ) : (
        <Label data-testid="contractType">{contractType}</Label>
      ),
      isRequired: isNewOffer,
      action: () => {
        if (
          offer.offerState === OfferState.DRAFT ||
          offer.offerState === OfferState.VALID
        ) {
          setEdit({
            offerTitle: false,
            offerContractType: true,
            offerDateCreation: false,
            offerPaymentGoal: false,
            offerSum: false,
            offerValidityPeriod: false,
          });
        }
      },
    },
    {
      label: translate('paymentGoal'),
      value: edit.offerPaymentGoal ? (
        <Select
          value={paymentGoalOptions.find(({ value }) => value === paymentGoal)}
          options={paymentGoalOptions.filter(
            ({ value }) => value !== paymentGoal
          )}
          onChange={handlePaymentGoalChange}
        />
      ) : (
        <Label data-testid="paymentGoal">
          {getPaymentGoalDetails(paymentGoal)}
        </Label>
      ),
      isRequired: isNewOffer,
      action: () => {
        if (
          offer.offerState === OfferState.DRAFT ||
          offer.offerState === OfferState.VALID
        ) {
          setEdit({
            offerTitle: false,
            offerContractType: false,
            offerDateCreation: false,
            offerPaymentGoal: true,
            offerSum: false,
            offerValidityPeriod: false,
          });
        }
      },
    },
    {
      label: translate('validityPeriod'),
      value: edit.offerValidityPeriod ? (
        <Input
          aria-label="inputValidityPeriod"
          type="number"
          min="0"
          value={validityPeriod}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFormValues((values) => ({
              ...values,
              validityPeriod: Number(event.target.value),
            }))
          }
        />
      ) : (
        <Label data-testid="validityPeriod">{`${validityPeriod} ${translate(
          'days'
        )}`}</Label>
      ),
      action: () => {
        if (
          offer.offerState === OfferState.DRAFT ||
          offer.offerState === OfferState.VALID
        ) {
          setEdit({
            offerTitle: false,
            offerContractType: false,
            offerDateCreation: false,
            offerPaymentGoal: false,
            offerSum: false,
            offerValidityPeriod: true,
          });
        }
      },
    },
    {
      label: translate('validUntil'),
      value: offer.offerSent ? (
        <Label>
          {getValidUntilDetails(
            offer.offerSent.toString(),
            offer.validityPeriod
          )}
        </Label>
      ) : (
        <Label>N/A</Label>
      ),
    },
  ];

  return (
    <DetailsCard
      title={translate('offerDetailsTitle')}
      fields={detailList}
      border
    />
  );
};

export default OfferDetailsCard;
