import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import { Button, Input, Table, ButtonGroup } from 'reactstrap';
import moment, { Moment } from 'moment';
import { ConnectedProps, connect } from 'react-redux';

import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { ContactPersonDetailsContext } from './ContactPersonDetailsProvider';
import {
  IActivity,
  IActivityListItem,
  IContactPersonAssignment,
  IContactPersonStatistics,
  IEmailBody,
  IUserToDoActivity,
} from '../../../utils/types/modelTypes';
import ModalAddActivity from './ModalAddActivity';
import ModalAction from './ModalAction';
import {
  ACTIVITY_COMMENTS,
  ACTIVITY_DESCRIPTIONS,
  ACTIVITY_STATE_ENUM,
  ActivityType,
  NEXT_ACTION_ENUMS,
  NEXT_ACTIVITY_ENUMS,
  TO_DO_ACTIVITY_STATE_ENUM,
} from '../../../utils/enums/activity';
import { getDateFormat } from '../../../utils/helpers/date';
import { ContactInfoType, ContactStatus } from '../../../utils/enums/contact';
import {
  DISQUALIFIED,
  MAIL_SENT,
  MET_PERSONALLY,
  NOT_REACHED,
  PROSPECTION_CANCELLED,
  PROSPECTION_DONE,
  PROSPECTION_POSTPONED,
  REACHED,
  REACHED_AND_APPOINTMENT,
  REACHED_AND_DISQUALIFIED,
  REACHED_AND_INTERESTED,
  REACHED_AND_SEND_EMAIL,
  SOCIAL_MEDIA_CONTACT,
  dateFormat,
  notApplicable,
} from '../../../utils/constants';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import {
  getAppointment,
  translateCallingActivityCard,
  transformContactPersonToDetails,
} from './contactPersonHelper';
import ProjectOptionModal from '../../salesFunnel/ProjectOptionModal/ProjectOptionModal';
import { ContactPersonAssignmentState } from '../../../utils/enums/contactPerson';
import { saveActivity } from '../../../services/api/activity';
import {
  sendContactPersonAssignmentEmail,
  updateContactPersonDetails,
} from '../../../services/api/contactPerson';
import user from '../../../user';
import { saveContactStatistics } from '../../../services/api/contactStatistics';
import { saveUserToDoActivities } from '../../../services/api/project';
import { IContactPersonDetail } from '../../../utils/types/responseTypes';
import ModalSendEmail from './ModalSendEmail';
import { RootState } from '../../../redux/store';
import CollapsibleCard from '../../../components/collapsibleCards/CollapsibleCard';
import { convertActionToTranslatedLabel } from '../../Home/homeHelper';

// !-- Used in ContactPersonDetails, ContactPersonOverview

interface IProps extends IWithModalsProps, PropsFromRedux {
  contactPersonId: number;
  contactProfileIndex?: number;
  contactPersonAssignment: IContactPersonAssignment;
  handleUpdateContactPersonAssignment: (
    contactPersonAssignment: IContactPersonAssignment
  ) => Promise<void>;
  updateCall?: (reached: boolean, dateSet: boolean) => void;
  handleNotReachedConfirm?: (selectedIndex: number) => Promise<void> | void;
  onDeleteContactPerson: () => Promise<void>;
  onResetContactPerson: () => Promise<void>;
  handleUpdateActivity?: () => Promise<void>;
}

/**
 * Card for displaying and interacting with calling activities of Contact Person
 * @param contactPersonId - the id of the ContactPerson
 * @param contactProfileIndex - the index of the ContactPerson in the ContactPersonDetails
 * @param contactPersonAssignment - the ContactPersonAssignment
 */
const CollapsibleRelatedActivitiesCard = ({
  contactPersonId,
  contactProfileIndex,
  contactPersonAssignment,
  employeeDetails: { id, firstname, name },
  updateCall,
  handleNotReachedConfirm,
  handleUpdateActivity,
  onDeleteContactPerson,
  onResetContactPerson,
  handleUpdateContactPersonAssignment,
  // WithModalsProps
  modalFormHandler,
  modalConfirmHandler,
  modalDeleteHandler,
  toggleModalForm,
  modalErrorHandler,
}: IProps) => {
  const {
    contactDetail,
    statusDetail,
    setStatusDetail,
    historyOfActivitiesDetail,
    setHistoryOfActivitiesDetail,
    unsavedDescription,
    unsavedDetail,
  } = useContext(ContactPersonDetailsContext);
  const [showModalAction, setShowModalAction] = useState(false);
  const [buttonAction, setButtonAction] = useState('');
  const [editedStatus, setEditedStatus] = useState<IContactPersonAssignment>(
    contactPersonAssignment
  );
  const [reached, setReached] = useState(false);
  const [dateSet, setDateSet] = useState(false);
  const [editNextActivity, setEditNextActivity] = useState(false);
  const [noScheduledRecall, setNoScheduledRecall] = useState(false);

  // Modal Action
  const [dueDate, setDueDate] = useState<Moment | null>(null);
  const [recall, setRecall] = useState<Moment | null>(null);
  const [recallTime, setRecallTime] = useState<Moment | null>(null);
  const [meeting, setMeeting] = useState<Moment | null>(null);
  const [meetingTime, setMeetingTime] = useState<Moment | null>(null);
  const [nextAction, setNextAction] = useState<string | undefined>('');
  const [target, setTarget] = useState(false);
  const [newStatus, setNewStatus] = useState(statusDetail.assignmentState);
  const [acquisition, setAcquisition] = useState(statusDetail.acquisition);
  const [cancelMeeting, setCancelMeeting] = useState(false);
  const [comments, setComments] = useState('');

  const [contactsReached, setContactsReached] = useState(0);
  const [agreedDates, setAgreedDates] = useState(0);
  const [attemptedCalls, setAttemptedCalls] = useState(0);

  // Modal Send Email
  const [emailAddress, setEmailAddress] = useState('');
  const [showModalSendEmail, setShowModalSendEmail] = useState(false);

  const [activityIndex, setActivityIndex] = useState(0);

  const { assignmentState } = contactPersonAssignment;

  /**
   * Generic change handler for updating state
   * @param {string} key - The key of the state property to update
   * @param {*} value - The new value to assign to the state property
   */
  const handleChange = (key: string, value: any) => {
    switch (key) {
      case ACTIVITY_STATE_ENUM.dueDate:
        setDueDate(value);
        break;
      case ACTIVITY_STATE_ENUM.recall:
        setRecall(value);
        break;
      case ACTIVITY_STATE_ENUM.recallTime:
        setRecallTime(value);
        break;
      case ACTIVITY_STATE_ENUM.meeting:
        setMeeting(value);
        break;
      case ACTIVITY_STATE_ENUM.meetingTime:
        setMeetingTime(value);
        break;
      case ACTIVITY_STATE_ENUM.nextAction:
        setNextAction(value);
        break;
      case ACTIVITY_STATE_ENUM.target:
        setTarget(value);
        break;
      case ACTIVITY_STATE_ENUM.newStatus:
        setNewStatus(value);
        break;
      case ACTIVITY_STATE_ENUM.acquisition:
        setAcquisition(value);
        break;
      default:
        break;
    }
  };

  /**
   * Handles different cases of button
   * @param {*} buttonAction
   * @returns
   */
  const actionButton = (buttonAction: string) => {
    const { acquisition } = statusDetail;

    // Default values
    let newAssignmentState = '';
    let newNextAction = '';
    let newReached = false;
    let newTarget = false;
    let newDateSet = false;

    setButtonAction(buttonAction);

    switch (buttonAction) {
      case NOT_REACHED:
        newAssignmentState = assignmentState ?? ContactStatus.COLD.code;
        newNextAction = NEXT_ACTION_ENUMS.callContact;
        break;

      case REACHED_AND_DISQUALIFIED:
        newAssignmentState = ContactStatus.DISQUALIFIED.code;
        newNextAction = NEXT_ACTION_ENUMS.none;
        newReached = true;
        break;

      case REACHED_AND_INTERESTED:
        newAssignmentState = ContactStatus.RECALL.code;
        newNextAction = NEXT_ACTION_ENUMS.callContact;
        newReached = true;
        newTarget = true;
        if (
          assignmentState === ContactStatus.MEETING.code ||
          assignmentState === ContactStatus.PROSPECT.code ||
          assignmentState === ContactStatus.MULTIPLE_PROJECT.code ||
          assignmentState === ContactStatus.FIRST_PROJECT.code ||
          assignmentState === ContactStatus.ONE_PROJECT.code ||
          assignmentState === ContactStatus.ANOTHER_PROJECT.code
        ) {
          newAssignmentState = assignmentState;
        }
        break;

      case REACHED:
        newAssignmentState = ContactStatus.RECALL.code;
        newNextAction = NEXT_ACTION_ENUMS.callContact;
        newReached = true;
        newTarget = true;
        if (
          assignmentState === ContactStatus.MEETING.code ||
          assignmentState === ContactStatus.PROSPECT.code
        ) {
          newAssignmentState = assignmentState;
        }
        break;

      case REACHED_AND_SEND_EMAIL:
        newAssignmentState = ContactStatus.RECALL.code;
        newNextAction = NEXT_ACTION_ENUMS.sendEmail;
        newReached = true;
        newTarget = true;
        if (assignmentState === ContactStatus.MEETING.code) {
          newAssignmentState = assignmentState;
        }
        break;

      case REACHED_AND_APPOINTMENT:
        newAssignmentState = ContactStatus.PROSPECT.code;
        newNextAction = NEXT_ACTION_ENUMS.doProspect;
        newReached = true;
        newTarget = true;
        newDateSet = true;
        if (
          assignmentState === ContactStatus.MEETING.code ||
          assignmentState === ContactStatus.MULTIPLE_PROJECT.code ||
          assignmentState === ContactStatus.FIRST_PROJECT.code ||
          assignmentState === ContactStatus.ONE_PROJECT.code ||
          assignmentState === ContactStatus.ANOTHER_PROJECT.code
        ) {
          newAssignmentState = assignmentState;
          newNextAction = NEXT_ACTION_ENUMS.doFollowUp;
        }
        break;

      case DISQUALIFIED:
        newAssignmentState = ContactStatus.DISQUALIFIED.code;
        break;

      case MAIL_SENT:
        newAssignmentState = ContactStatus.RECALL.code;
        if (
          assignmentState === ContactStatus.PROSPECT.code ||
          assignmentState === ContactStatus.MEETING.code
        ) {
          newAssignmentState = assignmentState;
        }
        break;

      case MET_PERSONALLY:
      case SOCIAL_MEDIA_CONTACT:
      case PROSPECTION_DONE:
      case PROSPECTION_CANCELLED:
        break;

      case PROSPECTION_POSTPONED:
        newAssignmentState = ContactStatus.PROSPECT.code;
        newTarget = true;
        if (assignmentState === ContactStatus.MEETING.code) {
          setAcquisition(acquisition);
          newAssignmentState = assignmentState;
        }
        break;

      default:
        break;
    }

    if (newAssignmentState !== '') {
      setNewStatus(newAssignmentState);
    }
    if (newNextAction !== '') {
      setNextAction(newNextAction);
    }
    setReached(newReached);
    setTarget(newTarget);
    setDateSet(newDateSet);
    setShowModalAction(!showModalAction);
  };

  /**
   * Checks Unsaved Changes in description and detail
   * @param button
   */
  const checkUnsavedChanges = (button: string) => {
    let messageBodyText = '';
    // Provide appropriate message
    if (unsavedDescription || unsavedDetail) {
      if (unsavedDescription && !unsavedDetail) {
        messageBodyText = translateCallingActivityCard(
          'unsavedChangesOnContactPersonDescription'
        );
      } else if (unsavedDetail && !unsavedDescription) {
        messageBodyText = translateCallingActivityCard(
          'unsavedChangesOnContactPersonDetail'
        );
      } else {
        messageBodyText = translateCallingActivityCard(
          'unsavedChangesConfirmation'
        );
      }

      modalConfirmHandler(
        translateCallingActivityCard('notice'),
        messageBodyText,
        () => {
          actionButton(button);
        }
      );
    } else {
      actionButton(button);
    }
  };

  /**
   * Diplays profile buttons depending on contactPersonAssignment
   * @returns profileButtons
   */
  const profileButtons = () => (
    <ButtonGroup>
      <Button
        color="primary"
        className="float-end"
        id="activity-buttons"
        size="s"
        onClick={() => checkUnsavedChanges(NOT_REACHED)}
      >
        {translateCallingActivityCard('notReached')}
      </Button>

      <Button
        color="primary"
        className="float-end"
        id="activity-buttons"
        size="s"
        disabled={assignmentState === ContactStatus.PROSPECT.code}
        onClick={() => checkUnsavedChanges(REACHED_AND_DISQUALIFIED)}
      >
        {translateCallingActivityCard('reachedAndDisqualified')}
      </Button>
      <Button
        color="primary"
        className="float-end"
        id="activity-buttons"
        size="s"
        onClick={() => checkUnsavedChanges(REACHED_AND_INTERESTED)}
      >
        {translateCallingActivityCard('reachedAndInterested')}
      </Button>
      <Button
        color="primary"
        className="float-end"
        id="activity-buttons"
        size="s"
        disabled={assignmentState === ContactStatus.PROSPECT.code}
        onClick={() => checkUnsavedChanges(REACHED_AND_APPOINTMENT)}
      >
        {translateCallingActivityCard('reachedAndAppointment')}
      </Button>
    </ButtonGroup>
  );

  /**
   * Displays meeting buttons
   * @returns meetingButtons
   */
  const meetingButtons = () => (
    <ButtonGroup>
      <Button
        color="primary"
        className="float-end"
        id="activity-buttons"
        size="s"
        onClick={() => {
          checkUnsavedChanges(PROSPECTION_DONE);
        }}
      >
        {assignmentState === ContactStatus.PROSPECT.code
          ? translateCallingActivityCard('prospectionDone')
          : translateCallingActivityCard('followUpDone')}
      </Button>
      <Button
        color="primary"
        className="float-end"
        id="activity-buttons"
        size="s"
        onClick={() => {
          checkUnsavedChanges(PROSPECTION_CANCELLED);
        }}
      >
        {assignmentState === ContactStatus.PROSPECT.code
          ? translateCallingActivityCard('prospectionCancelled')
          : translateCallingActivityCard('followUpCancelled')}
      </Button>
      <Button
        color="primary"
        className="float-end"
        id="activity-buttons"
        size="s"
        onClick={() => {
          checkUnsavedChanges(PROSPECTION_POSTPONED);
        }}
      >
        {assignmentState === ContactStatus.PROSPECT.code
          ? translateCallingActivityCard('prospectionPostponed')
          : translateCallingActivityCard('followUpPostponed')}
      </Button>
    </ButtonGroup>
  );

  /**
   * Displays buttons for other activities
   * @returns otherActivities
   */
  const otherActivities = () => {
    switch (assignmentState) {
      case ContactStatus.COLD.code:
      case ContactStatus.RECALL.code:
      case ContactStatus.PROSPECT.code:
      case ContactStatus.MEETING.code:
        return (
          <ButtonGroup>
            <Button
              color="primary"
              className="float-end"
              id="activity-buttons"
              size="s"
              onClick={() => checkUnsavedChanges(MAIL_SENT)}
            >
              {translateCallingActivityCard('contactViaMail')}
            </Button>
            <Button
              color="primary"
              className="float-end"
              id="activity-buttons"
              size="s"
              onClick={() => checkUnsavedChanges(MET_PERSONALLY)}
            >
              {translateCallingActivityCard('metPersonally')}
            </Button>
            <Button
              color="primary"
              className="float-end"
              id="activity-buttons"
              size="s"
              onClick={() => checkUnsavedChanges(SOCIAL_MEDIA_CONTACT)}
            >
              {translateCallingActivityCard('socialMediaContact')}
            </Button>
            <Button
              color="primary"
              className="float-end"
              id="activity-buttons"
              size="s"
              disabled={assignmentState === ContactStatus.PROSPECT.code}
              onClick={() => checkUnsavedChanges(DISQUALIFIED)}
            >
              {translateCallingActivityCard('disqualified')}
            </Button>
          </ButtonGroup>
        );
      default:
        return null;
    }
  };

  /**
   * Saves Activity and update the history of activities
   * @param activity - The activity object to be saved
   */
  const handleSaveActivity = async (activity: IActivity) => {
    try {
      const { data } = await saveActivity(activity);
      setHistoryOfActivitiesDetail([...historyOfActivitiesDetail, data]);
    } catch (error) {
      modalErrorHandler(
        translateCallingActivityCard('failedToSaveActivity'),
        error
      );
    }
  };

  /**
   * Handles changes in status depending on Action button
   * @param newState
   * @returns state description
   */
  const statusChange = (newState: string) => {
    switch (buttonAction) {
      case REACHED_AND_INTERESTED:
        return `Called and contact was interested. ${comments ?? ''}`;
      case REACHED_AND_DISQUALIFIED:
        return `Called and reached. Disqualified. Reason: ${comments ?? ''}`;
      case REACHED_AND_APPOINTMENT:
        return `Called and appointment for ${moment(meeting).format(
          dateFormat
        )}.${comments ?? ''}`;
      default:
        return `Called. Status changed to ${
          (
            Object.values(ContactStatus).find(
              ({ code }) => code === newState
            ) ?? { name: '' }
          ).name
        }. ${comments ?? ''}`;
    }
  };

  /**
   * Return new due date depending on the assignmentState, meeting and recall
   * @returns newDueDate
   */
  const getNewDueDate = () => {
    let newDueDate: moment.MomentInput;

    if (meeting) {
      newDueDate = getAppointment(meeting, meetingTime);
    } else if (newStatus === ContactStatus.DISQUALIFIED.code) {
      newDueDate = null;
    } else if (recall) {
      newDueDate = getAppointment(recall, recallTime);
    } else {
      newDueDate = getAppointment(dueDate, meetingTime);
    }
    return newDueDate;
  };

  /**
   * Generate description based on the contactPersonAssignment
   * @param newDueDate - new date set by user
   * @returns description
   */
  const generateDescription = (newDueDate: Moment | null) => {
    const {
      nextAction: contactPersonNextAction,
      date,
      dueDate,
    } = contactPersonAssignment;
    const momentValid = (date: Moment | string | null | undefined) =>
      moment(date).isValid()
        ? moment(date).format(getDateFormat())
        : notApplicable;

    // If Next action for new created activity is null, use the date instead of dueDate, dueDate is also null
    if (!contactPersonNextAction) {
      return `${ACTIVITY_DESCRIPTIONS.changedNextActivity} "${
        ContactStatus.COLD.name ?? notApplicable
      }": ${momentValid(date)} to "${
        NEXT_ACTION_ENUMS.callContact
      }": ${momentValid(newDueDate)}`;
    }

    return `${ACTIVITY_DESCRIPTIONS.changedNextActivity} "${
      contactPersonNextAction ?? notApplicable
    }": ${momentValid(dueDate)} to "${
      nextAction ?? notApplicable
    }": ${momentValid(dueDate)}`;
  };

  /**
   * Generate an Activity based on the contactPersonAssignment
   * @returns Activity with description based on button action
   */
  const generateActivityLog = () => {
    const activity = {
      description: '',
      comments: ACTIVITY_COMMENTS.relevant,
      date: new Date().toISOString(),
      objectId: contactPersonId,
      objectType: OBJECT_TYPE_ENUM.contactPerson.code,
      activityType: ActivityType.CUSTOM_ACTIVITY,
    };

    const setDescription = (newDescription: string) => {
      activity.description = `${newDescription} ${comments ?? ''}`;
    };

    const handleDefaultChange = () => {
      if (reached && assignmentState === newStatus) {
        setDescription(`${ACTIVITY_DESCRIPTIONS.called} `);
        activity.comments = ACTIVITY_COMMENTS.irrelevant;
        activity.objectType = OBJECT_TYPE_ENUM.call.code;
      } else if (reached && assignmentState !== newStatus) {
        setDescription(statusChange(newStatus));
        activity.objectType = OBJECT_TYPE_ENUM.call.code;
      } else if (!reached) {
        setDescription(`${ACTIVITY_DESCRIPTIONS.missedCall}`);
        activity.comments = ACTIVITY_COMMENTS.irrelevant;
        activity.objectType = OBJECT_TYPE_ENUM.call.code;
      }
    };

    let description = '';

    switch (buttonAction) {
      case MAIL_SENT:
        setDescription(`${ACTIVITY_DESCRIPTIONS.mailSent}`);
        break;
      case SOCIAL_MEDIA_CONTACT:
      case MET_PERSONALLY:
      case PROSPECTION_DONE:
      case PROSPECTION_CANCELLED:
        switch (buttonAction) {
          case SOCIAL_MEDIA_CONTACT:
            description = `${ACTIVITY_DESCRIPTIONS.contactedViaSocial} `;
            break;
          case MET_PERSONALLY:
            description = `${ACTIVITY_DESCRIPTIONS.metPersonally} `;
            break;
          case PROSPECTION_DONE:
          case PROSPECTION_CANCELLED:
            if (assignmentState === ContactStatus.PROSPECT.code) {
              description =
                buttonAction === PROSPECTION_DONE
                  ? `${ACTIVITY_DESCRIPTIONS.prospectionDone} `
                  : `${ACTIVITY_DESCRIPTIONS.prospectionCancelled} `;
            } else {
              description =
                buttonAction === PROSPECTION_DONE
                  ? `${ACTIVITY_DESCRIPTIONS.followUpDone} `
                  : `${ACTIVITY_DESCRIPTIONS.followUpCancelled} `;
            }
            break;
          default:
            break;
        }
        if (newStatus === ContactStatus.DISQUALIFIED.code) {
          description += ` ${ACTIVITY_DESCRIPTIONS.disqualified} `;
        }
        if (buttonAction !== PROSPECTION_CANCELLED && acquisition && target) {
          description += ` ${ACTIVITY_DESCRIPTIONS.projectOptionOutlined} `;
        }
        setDescription(`${description}`);
        break;
      case PROSPECTION_POSTPONED:
        if (assignmentState === ContactStatus.PROSPECT.code) {
          description = `${ACTIVITY_DESCRIPTIONS.prospectionPostponed} `;
        } else {
          description = `${ACTIVITY_DESCRIPTIONS.followUpPostponed} `;
        }
        setDescription(`${description}`);
        break;
      case REACHED:
        setDescription(`${ACTIVITY_DESCRIPTIONS.calledBeforeProspection}`);
        activity.objectType = OBJECT_TYPE_ENUM.call.code;
        break;
      default:
        handleDefaultChange();
        break;
    }

    return activity;
  };

  /**
   * Handles saving of contact person details and updating context (target)
   */
  const handleSaveContactPerson = async () => {
    const propertiesToSave = {
      id: contactPersonId,
      target,
    } as IContactPersonDetail;

    try {
      const { data } = await updateContactPersonDetails(propertiesToSave);
      // Pass the changes into the context
      const { statusDetail } = transformContactPersonToDetails(data);
      setStatusDetail(statusDetail);
      // Reset the comments
      setComments('');
    } catch (error) {
      modalErrorHandler(
        translateCallingActivityCard('failedToUpdateContactPerson'),
        error
      );
    }
  };

  /**
   * Handles saving of contact person statistics
   */
  const handleSaveContactPersonStatistics = async () => {
    const contactPersonsStatistics = {
      contactPersonId,
      dateCalled: new Date(),
      called: reached,
    } as IContactPersonStatistics;
    try {
      await saveContactStatistics(contactPersonsStatistics);
    } catch (error) {
      modalErrorHandler(
        translateCallingActivityCard('failedToUpdateContactPersonStatistics'),
        error
      );
    }
  };

  /**
   * Save user to do activities
   * @param {Moment | null} newDueDate - the new due date
   */
  const handleSaveUserToDoActivities = async (newDueDate: Moment | null) => {
    type NextActionType = keyof typeof NEXT_ACTION_ENUMS;
    const nextActionKey = Object.keys(NEXT_ACTION_ENUMS).find(
      (key) => NEXT_ACTION_ENUMS[key as NextActionType] === nextAction
    );
    if (NEXT_ACTION_ENUMS.none !== nextAction) {
      const userToDoActivities = {
        objectType: OBJECT_TYPE_ENUM.contactPerson.code,
        objectId: contactPersonId,
        action: NEXT_ACTIVITY_ENUMS[nextActionKey as NextActionType],
        dueDate: newDueDate?.toISOString() ?? '',
        description: nextAction ?? '',
        status: TO_DO_ACTIVITY_STATE_ENUM.ACTIVE,
      } as IUserToDoActivity;
      await saveUserToDoActivities(userToDoActivities);
    }
  };

  /**
   * Handles saving of status
   */
  const handleSaveStatus = async () => {
    const newDueDate = getNewDueDate();

    // Create new log for activity
    const activityLog = {
      description: generateDescription(newDueDate),
      date: new Date().toISOString(),
      comments: ACTIVITY_COMMENTS.relevant,
      objectId: contactPersonId,
      objectType: OBJECT_TYPE_ENUM.contactPerson.code,
      activityType: ActivityType.CUSTOM_ACTIVITY,
    };
    await handleSaveActivity(activityLog);

    // Create payload to create new contact person assignment
    const payload = {
      assignmentState: newStatus,
      contactPerson: {
        id: contactPersonId,
      },
      notes: comments,
      recall: noScheduledRecall
        ? undefined
        : getAppointment(recall, recallTime)?.format(),
      meeting: noScheduledRecall
        ? undefined
        : getAppointment(meeting, meetingTime)?.format(),
      addedBy: parseInt(user.employeeId, 10),
      previousState: assignmentState,
      nextAction: noScheduledRecall ? NEXT_ACTION_ENUMS.none : nextAction,
      date: new Date().toISOString(),
      dueDate: newDueDate ? newDueDate.toISOString() : '',
    } as IContactPersonAssignment;

    try {
      await handleUpdateContactPersonAssignment(payload);

      const activity = generateActivityLog();
      // Saving the initial activity
      await handleSaveActivity(activity);

      // Creation of another activity in case the "Cancel Follow Up" checkbox is clicked
      // For certain button actions
      if (
        cancelMeeting &&
        (assignmentState === ContactStatus.PROSPECT.code ||
          assignmentState === ContactStatus.MEETING.code)
      ) {
        const description =
          assignmentState === ContactStatus.MEETING.code
            ? ACTIVITY_DESCRIPTIONS.followUpMeeting
            : ACTIVITY_DESCRIPTIONS.prospection;
        const cancelFollowUpActivity = {
          description: `${description} (planned date:  ${moment(dueDate).format(
            dateFormat
          )}) cancelled.`,
          comments: ACTIVITY_COMMENTS.relevant,
          date: new Date().toISOString(),
          objectId: contactPersonId,
          objectType: OBJECT_TYPE_ENUM.contactPerson.code,
          activityType: ActivityType.CUSTOM_ACTIVITY,
        };

        // Saving the "Cancel Follow-up" activity
        await handleSaveActivity(cancelFollowUpActivity);
      }

      // Update contact person assignment and the contact's target
      await handleSaveContactPerson();

      // Update Contact Person Statistics
      await handleSaveContactPersonStatistics();

      // Update User To Do Activities
      await handleSaveUserToDoActivities(newDueDate);

      if (handleUpdateActivity) {
        try {
          await handleUpdateActivity();
        } catch (error) {
          modalErrorHandler(
            translateCallingActivityCard('failedToUpdateUserToDoActivity'),
            error
          );
        }
      }
    } catch (error) {
      modalErrorHandler(
        translateCallingActivityCard('failedToSaveContactPersonAssignment'),
        error
      );
    }
  };

  /**
   * Updates the Status
   * @param {*} confirmAndAddProjectOption
   */
  const updateActivityStatus = async (confirmAndAddProjectOption?: boolean) => {
    await handleSaveStatus();
    if (updateCall) {
      updateCall(reached, dateSet);
    }

    if (buttonAction === NOT_REACHED && handleNotReachedConfirm) {
      await handleNotReachedConfirm(contactProfileIndex ?? -1);
    }

    if (showModalAction) {
      setShowModalAction(!showModalAction);
    }

    if (reached) {
      setContactsReached(contactsReached + 1);
    }
    if (dateSet) {
      setAgreedDates(agreedDates + 1);
      setDateSet(false);
    }
    setAttemptedCalls(attemptedCalls + 1);
    if (confirmAndAddProjectOption) {
      const {
        customer: { id: customerId },
        customerSite: { id: customerSiteId },
        id: contactPersonId,
      } = contactDetail;
      modalFormHandler(
        translateCallingActivityCard('addProjectOption'),
        <ProjectOptionModal
          customerId={customerId}
          customerSiteId={customerSiteId}
          contactPersonId={contactPersonId}
          onClose={toggleModalForm}
        />,
        'xl'
      );
    }
  };

  /**
   * Handles confirmation of deletion of Contact Person
   */
  const deleteContactPerson = () => {
    modalDeleteHandler(
      translateCallingActivityCard('delete'),
      translateCallingActivityCard('deleteContactPersonConfirmation'),
      async () => {
        await onDeleteContactPerson();
      }
    );
  };

  /**
   * Handles confirmation of resetting of Contact Person's details
   */
  const resetContactPerson = () => {
    modalConfirmHandler(
      translateCallingActivityCard('sendEmail'),
      translateCallingActivityCard('resetContactPersonConfirmation'),
      async () => {
        await onResetContactPerson();
        setShowModalAction(!showModalAction);
      }
    );
  };

  /**
   * Handles noScheduledRecall state value change
   * @param {*} noScheduledRecall
   */
  const changeNoScheduledRecall = (noScheduledRecall: boolean) => {
    setNoScheduledRecall(noScheduledRecall);
    setNextAction(NEXT_ACTION_ENUMS.none);
  };

  /**
   * Method for sending email
   * @param emailBody
   */
  const sendEmail = async (emailBody: IEmailBody) => {
    const { id } = contactPersonAssignment;
    if (id) {
      try {
        await sendContactPersonAssignmentEmail(emailBody, id);
        setShowModalSendEmail(!showModalSendEmail);
      } catch (error) {
        modalErrorHandler(
          translateCallingActivityCard('failedToSendEmail'),
          error
        );
      }
    }
  };

  /**
   * Temporarily add activity history to the historyOfActivitiesDetail
   */
  const handleSaveActivityHistory = (
    newDueDate: string,
    newNextAction: string
  ) => {
    const { dueDate: oldDueDate, nextAction: oldNextAction } =
      contactPersonAssignment;
    if (oldDueDate) {
      const description = `${ACTIVITY_DESCRIPTIONS.changedNextActivity} "${
        oldNextAction ?? notApplicable
      }": ${moment(oldDueDate).format(getDateFormat())} to "${
        newNextAction ?? notApplicable
      }": ${moment(newDueDate).format(getDateFormat())}`;

      const newActivity = {
        id: activityIndex,
        date: new Date().toISOString(),
        description,
        creator: { id, name: `${firstname} ${name}` },
        createdOn: new Date().toISOString(),
        activityType: ActivityType.CUSTOM_ACTIVITY,
        comment: ACTIVITY_COMMENTS.relevant,
      } as IActivityListItem;

      setHistoryOfActivitiesDetail([newActivity, ...historyOfActivitiesDetail]);
      setActivityIndex(activityIndex + 1);
    }
  };

  /**
   * Display the modal add activity if it has a contact and assignmentState
   */
  const showAddActivityForm = () => {
    const { id } = contactDetail;

    // Proceed to the showing of Add Activity form that is based on the latestAssignmentState
    if (id && assignmentState) {
      modalFormHandler(
        translateCallingActivityCard('addActivity'),
        <ModalAddActivity
          closeAddActivityForm={() => {
            toggleModalForm();
          }}
          status={contactPersonAssignment}
          handleUpdateContactPersonAssignment={
            handleUpdateContactPersonAssignment
          }
          handleSaveActivityHistory={handleSaveActivityHistory}
          {...(handleUpdateActivity && { handleUpdateActivity })}
        />
      );
    }
  };

  /**
   * Handles changes of user defined next activity
   * @param event
   */
  const handleUserDefinedNextActivityChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.trim() ? event.target.value : '';
    if (editedStatus) {
      setEditedStatus({ ...editedStatus, nextAction: value });
    }
  };

  /**
   * Handles changes of user defined next activity dueDate
   * @param date
   */
  const handleUserDefinedDueDateChange = (date: Moment | string) => {
    const dueDate = moment(date).toISOString();
    if (editedStatus) {
      setEditedStatus({ ...editedStatus, dueDate });
    }
  };

  /**
   * Handles cancellation of user defined next activity
   */
  const onCancelUserDefinedNextActivity = () => {
    setEditedStatus(contactPersonAssignment);
    setEditNextActivity(!editNextActivity);
  };

  /**
   * Handles saving of user defined next activity
   */
  const onSaveUserDefinedNextActivity = async () => {
    const { nextAction, dueDate } = editedStatus;
    const statusToBeSaved = {
      ...contactPersonAssignment,
      nextAction: nextAction ?? NEXT_ACTION_ENUMS.none,
      dueDate: dueDate ?? '',
    } as IContactPersonAssignment;
    await handleUpdateContactPersonAssignment(statusToBeSaved);

    // Temporarily add activity history to the historyOfActivitiesDetail
    if (dueDate && nextAction) {
      handleSaveActivityHistory(dueDate, nextAction);
    }
    if (handleUpdateActivity) {
      try {
        await handleUpdateActivity();
      } catch (error) {
        modalErrorHandler(
          translateCallingActivityCard('failedToUpdateUserToDoActivity'),
          error
        );
      }
    }

    setEditNextActivity(!editNextActivity);
  };

  const headerButtons = (
    <Button
      color="primary"
      onClick={() => showAddActivityForm()}
      disabled={assignmentState === undefined}
    >
      <FontAwesomeIcon icon={faPlus} className="margin-right" />
      <span>{translateCallingActivityCard('addActivity')}</span>
    </Button>
  );

  // Obtain email address of the contact person
  useEffect(() => {
    const { contactInformation } = contactDetail;
    if (contactInformation) {
      const email = contactInformation.find(
        ({ type }) => type === ContactInfoType.EMAIL
      )?.info;
      if (email) {
        setEmailAddress(email);
      }
    }
  }, [contactDetail]);

  // Get the latest contactPersonAssignment
  useEffect(() => {
    const { nextAction } = contactPersonAssignment;
    setEditedStatus(contactPersonAssignment);
    setNextAction(nextAction);
    setDueDate(moment(contactPersonAssignment.dueDate));
  }, [contactPersonAssignment]);

  useEffect(() => {
    const { assignmentState, acquisition } = statusDetail;
    setNewStatus(assignmentState);
    setAcquisition(acquisition);
  }, [statusDetail]);

  return (
    <>
      <CollapsibleCard
        title={translateCallingActivityCard('relatedActivities')}
        headerButtons={headerButtons}
        defaultOpen
      >
        <Table striped className="my-1" style={{ width: '100%' }}>
          <tbody>
            <tr>
              <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
                {translateCallingActivityCard('nextPlannedActivity')}
              </th>
              {editNextActivity ? (
                <>
                  <td>
                    <Input
                      aria-label="nextActivity"
                      type="text"
                      value={editedStatus?.nextAction}
                      onChange={handleUserDefinedNextActivityChange}
                    />
                  </td>
                  <td>
                    <Datetime
                      aria-label="dueDate"
                      dateFormat
                      timeFormat={false}
                      closeOnSelect
                      value={moment(editedStatus?.dueDate) ?? ''}
                      onChange={handleUserDefinedDueDateChange}
                    />
                  </td>
                  <td>
                    <div className="float-end flex-button">
                      <Button
                        aria-label="related-activities-button-save"
                        color="primary"
                        onClick={() => onSaveUserDefinedNextActivity()}
                        disabled={editedStatus?.nextAction === ''}
                      >
                        <FontAwesomeIcon
                          icon={faSave}
                          className="margin-right"
                        />
                      </Button>
                      <Button
                        aria-label="related-activities-button-cancel"
                        color="primary"
                        onClick={() => onCancelUserDefinedNextActivity()}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="margin-right"
                        />
                      </Button>
                    </div>
                  </td>
                </>
              ) : (
                <>
                  <td>
                    <div onClick={() => setEditNextActivity(!editNextActivity)}>
                      {convertActionToTranslatedLabel(
                        contactPersonAssignment.nextAction ?? notApplicable
                      )}
                    </div>
                  </td>
                  <td>
                    {contactPersonAssignment.dueDate &&
                      moment(contactPersonAssignment.dueDate).format(
                        getDateFormat()
                      )}
                  </td>
                </>
              )}
            </tr>
          </tbody>
        </Table>
        <br />
        <th>{translateCallingActivityCard('outcome')}</th>
        <div style={{ paddingBottom: '5px' }}>
          {(assignmentState === ContactPersonAssignmentState.three ||
            assignmentState === ContactPersonAssignmentState.four) && (
            <tr>
              <th>{`${translateCallingActivityCard('prospection')}: `} </th>
              <td style={{ textAlign: 'right' }}>{meetingButtons()}</td>
            </tr>
          )}
          <tr>
            <th>{`${translateCallingActivityCard('called')}: `} </th>
            <td>{profileButtons()}</td>
          </tr>
          <tr>
            <th>{`${translateCallingActivityCard('further')}: `} </th>
            <td>{otherActivities()}</td>
          </tr>
        </div>
      </CollapsibleCard>
      <ModalAction
        modal={showModalAction}
        buttonAction={buttonAction}
        target={statusDetail.target}
        currentStatus={contactPersonAssignment}
        size="lg"
        noScheduledRecall={noScheduledRecall}
        commentsValue={comments}
        toggle={() => setShowModalAction(!showModalAction)}
        addComments={setComments}
        handleChange={handleChange}
        updateStatus={updateActivityStatus}
        cancelMeeting={(checked) => setCancelMeeting(checked)}
        deleteContactPerson={deleteContactPerson}
        resetContactPerson={resetContactPerson}
        changeNoScheduledRecall={changeNoScheduledRecall}
      />
      <ModalSendEmail
        modal={showModalSendEmail}
        toggle={() => setShowModalSendEmail(!showModalSendEmail)}
        sendEmail={sendEmail}
        status={contactPersonAssignment}
        emailAdd={emailAddress}
      />
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  employeeDetails: store.account.employeeDetails,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(CollapsibleRelatedActivitiesCard));
