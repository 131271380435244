/* eslint-disable no-nested-ternary */
import Select from 'react-select';
import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import Auth from '../../../services/axios/Auth';
import { READ, READWRITE } from '../../../utils/constants';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import ResponsibleContactPersonEditor from '../../../components/form/ResponsibleContactPersonForm/ResponsibleContactPersonEditor';
import {
  IDropdownOption,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import { projectStateDropdownOptions } from '../../../utils/helpers/dropdown';
import { ProjectDetailFieldsProperties, t } from './projectDetailHelper';
import DetailsCard, { CardData } from '../../../components/cards/DetailsCard';
import { IProjectDetail } from '../../../utils/types/responseTypes';
import type { ProjectDetailFormValues } from './ProjectDetailOverview';

interface IProps {
  project: IProjectDetail;
  formValues: ProjectDetailFormValues;
  setFormValues: Dispatch<SetStateAction<ProjectDetailFormValues>>;
  customerSiteList: IDropdownOption<number>[];
  fieldToUpdate: string;
  setFieldToUpdate: Dispatch<SetStateAction<string>>;
}

const ProjectGeneralInformation = ({
  project: { customer },
  formValues: {
    title,
    projectId,
    state,
    customerSite,
    responsibleContactPersons,
  },
  setFormValues,
  customerSiteList,
  fieldToUpdate,
  setFieldToUpdate,
}: IProps) => {
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((formValues) => ({
      ...formValues,
      title: event.target.value,
    }));
  };

  const handleProjectIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormValues((formValues) => ({
      ...formValues,
      projectId: event.target.value,
    }));
  };

  const handleCustomerSiteChange = (value: IDropdownOption<number>) => {
    setFormValues((formValues) => ({
      ...formValues,
      customerSite: { id: value.value, name: value.label },
    }));
  };

  const handleProjectStatusChange = (value: IDropdownOption<string>) => {
    setFormValues((formValues) => ({
      ...formValues,
      state: value.value,
    }));
  };

  const handleContactPersonChange = (value: IDropdownOption<number>[]) => {
    const contactPersons: IObjectNameAndId[] = value
      .filter((contact) => contact.value)
      .map((contact) => ({
        id: contact.value,
        name: contact.label,
      }));
    setFormValues((formValues) => ({
      ...formValues,
      responsibleContactPersons: contactPersons,
    }));
  };

  const fields: CardData[] = [
    {
      label: t('projectTitle'),
      value: (
        <div
          style={{ minHeight: '20px' }}
          onClick={() => setFieldToUpdate(ProjectDetailFieldsProperties.TITLE)}
        >
          {fieldToUpdate === ProjectDetailFieldsProperties.TITLE ? (
            <Input
              bsSize="lg"
              type="string"
              value={title}
              onChange={handleTitleChange}
            />
          ) : (
            title
          )}
        </div>
      ),
    },
    {
      label: t('projectId'),
      value: (
        <div
          style={{ minHeight: '20px' }}
          onClick={() =>
            setFieldToUpdate(ProjectDetailFieldsProperties.PROJECT_ID)
          }
        >
          {fieldToUpdate === ProjectDetailFieldsProperties.PROJECT_ID ? (
            <Input
              bsSize="lg"
              type="string"
              value={projectId}
              onChange={handleProjectIdChange}
            />
          ) : (
            projectId
          )}
        </div>
      ),
    },
    {
      label: t('customer'),
      value: (
        <div>
          {customer?.id &&
            (Auth.hasPermission(
              [PERMISSION_URI.customer.readWrite.uri],
              [READWRITE, READ],
              customer.id
            ) ? (
              <Link to={`/customer/customers/details/${customer.id}`}>
                {customer.name}
              </Link>
            ) : (
              customer.name
            ))}
        </div>
      ),
    },
    {
      label: t('customerSite'),
      value: (
        <div
          style={{ minHeight: '20px' }}
          onClick={() =>
            setFieldToUpdate(ProjectDetailFieldsProperties.CUSTOMER_SITE)
          }
        >
          {customer?.id &&
          fieldToUpdate === ProjectDetailFieldsProperties.CUSTOMER_SITE ? (
            <Select
              value={{ value: customerSite.id, label: customerSite.name }}
              options={customerSiteList}
              placeholder={t('selectCustomerSite')}
              onChange={handleCustomerSiteChange}
            />
          ) : (
            <Link to={`/customer/customer-sites/details/${customerSite.id}`}>
              {customerSite.name}
            </Link>
          )}
        </div>
      ),
    },
    {
      label: t('projectStatus'),
      value: (
        <div
          style={{ minHeight: '20px' }}
          onClick={() => setFieldToUpdate(ProjectDetailFieldsProperties.STATUS)}
        >
          {fieldToUpdate === ProjectDetailFieldsProperties.STATUS ? (
            <Select
              value={projectStateDropdownOptions.find(
                (projectState) =>
                  projectState.value.toUpperCase() === state.toUpperCase()
              )}
              options={projectStateDropdownOptions}
              placeholder={t('selectLocation')}
              onChange={handleProjectStatusChange}
            />
          ) : (
            projectStateDropdownOptions.find(
              (projectState) =>
                projectState.value.toUpperCase() === state.toUpperCase()
            )?.label
          )}
        </div>
      ),
    },
    {
      label: t('contactPersons'),
      value: (
        <div
          style={{ minHeight: '20px' }}
          onClick={() =>
            setFieldToUpdate(
              ProjectDetailFieldsProperties.RESPONSIBLE_CONTACT_PERSONS
            )
          }
        >
          {/* To do change responsible contact person editor */}
          {customer?.id &&
          fieldToUpdate ===
            ProjectDetailFieldsProperties.RESPONSIBLE_CONTACT_PERSONS ? (
            <ResponsibleContactPersonEditor
              responsibleContactPersons={responsibleContactPersons}
              customerId={customer.id}
              customerSiteId={customerSite.id}
              onChange={handleContactPersonChange}
            />
          ) : Array.isArray(responsibleContactPersons) &&
            responsibleContactPersons.length > 0 ? (
            responsibleContactPersons.map((responsibleContactPerson) => (
              <Row key={responsibleContactPerson.id}>
                <Col
                  key={responsibleContactPerson.id}
                  id={`responsible-contact-person-col-${responsibleContactPerson.id}`}
                >
                  <Link
                    to={`/customer/my-contacts/profile/${responsibleContactPerson?.id}`}
                  >
                    {`${responsibleContactPerson.name}`}
                  </Link>
                </Col>
              </Row>
            ))
          ) : (
            'None'
          )}
        </div>
      ),
    },
  ];

  return (
    <DetailsCard
      title={t('projectDetailsHeadder')}
      leftTitle
      fields={fields}
      border
    />
  );
};

export default ProjectGeneralInformation;
