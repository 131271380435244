import {
  faEye,
  faPencilAlt,
  faSlash,
  faTrash,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { CardBody, CardHeader, Button } from 'reactstrap';

import DynamicTable from '../../tables/DynamicTable';
import { READ, READWRITE } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { SUBJECT_TYPE } from '../../../utils/enums/employee';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import AccessDialogPermissionComponent from './AccessDialogPermissionComponent';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { IPermission } from '../../../utils/types/modelTypes';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import {
  deletePermission,
  getEmployeeNames,
  getListOfEmployeeGroups,
  getPermissions,
  savePermission,
  updatePermission,
} from '../../../services/api/employee';

interface IProps extends IWithModalsProps {
  restriction: number;
  entityType: string;
}

interface IPermissionOption extends IPermission {
  label: string;
}

interface ITableData {
  label: string;
  subjectType: React.JSX.Element;
  accessType: React.JSX.Element;
  deleteButton: React.JSX.Element;
}

const t = (keyName: string) => i18n.t(`AccessDialog.${keyName}`);

const preparedColumns = [
  {
    type: 'data',
    header: t('PermissionName'),
    accessor: 'label',
    show: 'true',
  },
  {
    type: 'data',
    header: t('PermissionType'),
    accessor: 'subjectType',
    show: 'true',
    dataAlign: 'center',
    headerAlign: 'center',
  },
  {
    type: 'data',
    header: t('Permissionlevel'),
    accessor: 'accessType',
    dataAlign: 'center',
    headerAlign: 'center',
    show: 'true',
  },
  {
    type: 'data',
    header: '',
    accessor: 'deleteButton',
    show: 'true',
    dataAlign: 'right',
    headerAlign: 'right',
  },
];

/**
 * Modal for displaying access rights.
 * props:
 *  restriction = ID of the item to be given access to
 *  entityType = restriction on what kind of entity
 *
 * */
const ModalAccessAddDialog = ({
  entityType,
  restriction,
  modalDeleteHandler,
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
}: IProps) => {
  // States
  const [employeeChoices, setEmployeeChoices] = useState(
    [] as IDropdownOption[]
  );
  const [employeeGroupChoices, setEmployeeGroupChoices] = useState(
    [] as IDropdownOption[]
  );
  const [rightsList, setRightsList] = useState([] as IPermission[]);
  const [rightsOptions, setRightsOptions] = useState([] as IPermissionOption[]);

  const permissionUri = Object.values(PERMISSION_URI.contactPerson).map(
    (permission) => permission.uri
  );

  // Retrieve available employees
  const fetchEmployees = async () => {
    try {
      const { data: employee } = await getEmployeeNames({});
      const options = employee.map(({ id, name }) => ({
        value: id?.toString() ?? '',
        label: name ?? '',
      }));
      setEmployeeChoices(options);
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveEmployees'), error);
    }
  };

  // Retrieve available employee groups
  const fetchEmployeeGroups = async () => {
    try {
      const { data: employeeGroups } = await getListOfEmployeeGroups();
      const options = employeeGroups.map(({ id, name }) => ({
        value: id?.toString() ?? '',
        label: name ?? '',
      }));
      setEmployeeGroupChoices(options);
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveEmployeeGroups'), error);
    }
  };

  // Retrieve all the correct people with current access
  const fetchPermissions = async () => {
    try {
      const { data: permissions } = await getPermissions();
      const getCorrectItems = permissions.filter(
        (correctedPermission: IPermission) =>
          correctedPermission.entityUri === permissionUri.toString() &&
          correctedPermission.restriction === restriction
      );
      setRightsList(getCorrectItems);
    } catch (error) {
      modalErrorHandler(t('permissionsGetError'), error);
    }
  };

  // This saves the data given by the user
  const saveAccessData = async (
    accessType: string,
    subjectId: number,
    subjectType: string
  ) => {
    // Data to send to the backend through API
    const payload = {
      subjectType,
      subjectId,
      accessType,
      entityType,
      entityUri: permissionUri.toString(),
      restriction,
    };

    try {
      await savePermission(payload);
      await fetchPermissions();
    } catch (error) {
      modalErrorHandler(t('failedToSaveAccessData'), error);
    }

    toggleModalForm();
  };

  // Remove existing subjects from the choices to prevent duplicates
  const removeExistingFromChoices = (choices: IDropdownOption[]) => {
    const newChoices = [...choices];
    rightsList.forEach((permission) => {
      const index = newChoices.findIndex(
        (choice) => parseInt(choice.value, 10) === permission.subjectId
      );
      if (index !== -1) {
        newChoices.splice(index, 1);
      }
    });

    return newChoices;
  };

  const createPermissionEmployee = () => {
    modalFormHandler(
      (BUTTON_TITLE_ENUM.ADD.code, t('addEmployeeRight')),
      <AccessDialogPermissionComponent
        dropdownLabel={t(SUBJECT_TYPE.employee.name)}
        subjectType={SUBJECT_TYPE.employee.code}
        options={removeExistingFromChoices(employeeChoices)}
        onSave={saveAccessData}
      />
    );
  };

  const createPermissionGroup = () => {
    modalFormHandler(
      (BUTTON_TITLE_ENUM.ADD.code, t('addGroupRight')),
      <AccessDialogPermissionComponent
        dropdownLabel={t(SUBJECT_TYPE.employeeGroup.name)}
        subjectType={SUBJECT_TYPE.employeeGroup.code}
        options={removeExistingFromChoices(employeeGroupChoices)}
        onSave={saveAccessData}
      />
    );
  };

  // Swaps Read and Write to Read only and vice versa
  const swapPermission = async (payload: IPermissionOption) => {
    const accessType = payload.accessType === READ ? READWRITE : READ;
    try {
      await updatePermission({ ...payload, accessType });
      // Update the state
      const newRightsOptions = rightsOptions.map((option) => {
        if (option.id === payload.id) {
          return { ...option, accessType };
        }
        return option;
      });
      setRightsOptions(newRightsOptions);
    } catch (error) {
      modalErrorHandler(t('failedToSwapPermissions'), error);
    }
  };

  // Method that handles the deletion of permission
  const handleDeletePermission = (permissionId: number | undefined) => {
    if (permissionId) {
      modalDeleteHandler(
        t('deletePermission'),
        t('deletePermissionWarning'),
        async () => {
          // Perform the deletion
          try {
            await deletePermission(permissionId);
            setRightsList(
              rightsList.filter((item) => item.id !== permissionId)
            );
          } catch (error) {
            modalErrorHandler(t('failedToDeletePermission'), error);
          }
        }
      );
    }
  };

  // Adds the names of the employees or employee groups to the permissions
  const nameAdder = () => {
    let updatedPermissionArray = [...rightsList] as IPermissionOption[];

    // Gets all the employees in an easy to sort key/value pair
    const employeeMasterListOrganized = {} as {
      [key: number]: IDropdownOption;
    };
    if (employeeChoices?.length > 0) {
      employeeChoices.forEach((employee) => {
        if (employee.value !== 'undefined') {
          employeeMasterListOrganized[parseInt(employee.value, 10)] = employee;
        }
      });
    }

    // Gets all the employee groups in an easy to sort key/value pair
    const groupMasterListArrayOrganized = {} as {
      [key: number]: IDropdownOption;
    };
    if (employeeGroupChoices?.length > 0) {
      employeeGroupChoices.forEach((item) => {
        if (item.value !== 'undefined') {
          groupMasterListArrayOrganized[parseInt(item.value, 10)] = item;
        }
      });
    }

    // Inserts the name into the permissions
    if (rightsList.length > 0) {
      updatedPermissionArray = rightsList.map((item) => {
        let label = t('emptyItem');
        if (item) {
          if (item.subjectType === SUBJECT_TYPE.employee.code) {
            label =
              employeeMasterListOrganized[item.subjectId]?.label ??
              t('emptyItem');
          } else if (item.subjectType === SUBJECT_TYPE.employeeGroup.code) {
            label =
              groupMasterListArrayOrganized[item.subjectId]?.label ??
              t('emptyItem');
          }
        }
        return { ...item, label };
      });
    }
    setRightsOptions(updatedPermissionArray);
  };

  // This sorts the table, this is temporary untill the proper filtering comes along.
  const employeeCortrectedArraySorted = rightsOptions.sort((a, b) =>
    a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1
  );

  const newTableData: ITableData[] = employeeCortrectedArraySorted.map(
    (permissionOption) => {
      const typeIcon =
        permissionOption.subjectType === SUBJECT_TYPE.employee.code ? (
          <Tooltip title={t(SUBJECT_TYPE.employee.code)}>
            <div>
              <FontAwesomeIcon icon={faUser} />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title={t(SUBJECT_TYPE.employeeGroup.code)}>
            <div>
              <FontAwesomeIcon icon={faUsers} />
            </div>
          </Tooltip>
        );
      const AccessIcon =
        permissionOption.accessType === READ ? (
          <Tooltip title={t(READ)}>
            <div>
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title={t(READWRITE)}>
            <div>
              <FontAwesomeIcon icon={faPencilAlt} />
            </div>
          </Tooltip>
        );

      return {
        label: permissionOption.label,
        subjectType: typeIcon,
        accessType: AccessIcon,
        deleteButton: (
          <>
            {permissionOption.accessType === READ ? (
              <Tooltip title={t('changeToReadAndWrite')}>
                <Button
                  color="primary"
                  size="s"
                  aria-label="toReadAndWrite-button"
                  onClick={() => swapPermission(permissionOption)}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title={t('changeToReadOnly')}>
                <Button
                  color="primary"
                  size="s"
                  aria-label="toRead-button"
                  onClick={() => swapPermission(permissionOption)}
                >
                  <div className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faPencilAlt} />
                    <FontAwesomeIcon icon={faSlash} />
                  </div>
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title={t('deletePermission')}
              style={{ marginLeft: '5px' }}
            >
              <Button
                color="primary"
                size="s"
                onClick={() => handleDeletePermission(permissionOption.id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Tooltip>
          </>
        ),
      };
    }
  );

  // Retrieve data
  useEffect(() => {
    fetchEmployees();
    fetchEmployeeGroups();
    fetchPermissions();
  }, []);

  // Trigger name adder when the dependencies change
  useEffect(() => {
    nameAdder();
  }, [rightsList, employeeChoices, employeeGroupChoices]);

  // Note: the &nbsp; was added to force the buttons to get space below it
  return (
    <>
      <CardHeader>
        <div className="card-actions float-end">
          <Tooltip title={t('addEmployeeRight')}>
            <Button
              color="primary"
              size="s"
              onClick={() => createPermissionEmployee()}
            >
              {generateTitle(BUTTON_TITLE_ENUM.ADD.code)}
              <FontAwesomeIcon icon={faUser} />
            </Button>
          </Tooltip>
          <Tooltip title={t('addGroupRight')} style={{ marginLeft: '5px' }}>
            <Button
              color="primary"
              size="s"
              onClick={() => createPermissionGroup()}
            >
              {generateTitle(BUTTON_TITLE_ENUM.ADD.code)}
              <FontAwesomeIcon icon={faUsers} />
            </Button>
          </Tooltip>
        </div>
        <div>&nbsp;</div>
      </CardHeader>
      <CardBody>
        <div>
          <DynamicTable
            data={newTableData}
            columns={preparedColumns}
            dontShowDataPerPageOption
            dontShowPageNumbers
          />
        </div>
      </CardBody>
    </>
  );
};

export default withModals(ModalAccessAddDialog);
