/* eslint-disable no-redeclare */
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import 'moment/min/locales';
import React from 'react';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  Table,
} from 'reactstrap';

import Auth from '../../../services/axios/Auth';
import axios from '../../../services/axios/axios';
import {
  dataGeneration,
  deletion,
  READWRITE,
  updateRecord,
} from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { ProjectEmployeeWorkloadState } from '../../../utils/enums/project';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import {
  errorHandler,
  getDateFormat,
} from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import {
  IDropdownOption,
  IErrorMessage,
} from '../../../utils/types/commonTypes';
import {
  IEmployee,
  IProjectEmployee,
  IProjectEmployeeWorkload,
} from '../../../utils/types/modelTypes';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import ModalOK from '../../../components/modals/ModalOK';
import AddOrUpdateProjectEmployeeWorkload from './AddOrUpdateProjectEmployeeWorkload';
import {
  enumValuesToDropdownOptions,
  sortOptionsByValue,
} from '../../../utils/helpers/dropdown';

const DATE_FORMAT = getDateFormat();

interface IProps {
  projectEmployeeId: number;
}

interface IState {
  workload: IExtendedProjectEmployeeWorkload[];
  showModalError: boolean;
  showModalOk: boolean;
  stateOptions: IDropdownOption[];
  projectEmployee: IProjectEmployee;
  showWorkloadInHours: boolean;
  showModal: boolean;
  showModalForm: boolean;
  modalForm: JSX.Element | null;
  employee: IEmployee;
  title: string;
  bodyText: string;
}

interface IExtendedProjectEmployeeWorkload extends IProjectEmployeeWorkload {
  changeState: boolean;
}

/**
 * Class to display the workload of employee and change state
 */
class EmployeeWorkloadCard extends React.Component<IProps, IState> {
  modalTitle: string;

  modalEvent: () => void;

  message: IErrorMessage = {} as IErrorMessage;

  constructor(props: IProps) {
    super(props);
    this.state = {
      workload: [],
      showModalError: false,
      showModalOk: false,
      stateOptions: [],
      projectEmployee: {} as IProjectEmployee,
      showWorkloadInHours: true,
      showModal: false,
      showModalForm: false,
      modalForm: null,
      employee: {} as IEmployee,
      title: '',
      bodyText: '',
    };
    this.modalTitle = '';
    this.modalEvent = () => {
      // Empty function default
    };
  }

  t(keyName: string) {
    return i18n.t('EmployeeWorkloadCard.' + keyName);
  }

  async componentDidMount() {
    // Gets the states as options
    const stateOptions = enumValuesToDropdownOptions(
      Object.values(ProjectEmployeeWorkloadState),
      'WorkloadState'
    );

    this.setState({
      stateOptions,
    });

    await axios.project
      .get(`project-employees/${this.props.projectEmployeeId}`)
      .then((response: AxiosResponse<IProjectEmployee>) => {
        type ProjectEmp = IProjectEmployee;
        const isProjectEmployee = (arg: any): arg is ProjectEmp =>
          arg.employeeId !== undefined && arg.project !== undefined;
        if (isProjectEmployee(response.data)) {
          const workloads = [];
          // Split the project workloads from the project employee
          const { projectEmployeeWorkloads, ...projectEmployee } =
            response.data;
          if (projectEmployeeWorkloads !== null) {
            // Place in array by use of for loop. also add the project employee back inside the project employee workload
            for (const workload of projectEmployeeWorkloads as IExtendedProjectEmployeeWorkload[]) {
              workload.changeState = false;
              workload.projectEmployee = projectEmployee;
              workloads.push(workload);
            }
          }
          this.setState({
            workload: workloads,
            projectEmployee: response.data,
          });
          axios.employee
            .get(`employees/${response.data.employeeId}`)
            .then((response: AxiosResponse<IEmployee>) => {
              this.setState({
                employee: response.data,
              });
            })
            .catch((error) => {
              this.message = errorHandler(
                dataGeneration,
                error,
                this.t('projectEmployeeWorkload')
              );
              this.toggleModalError();
            });
        }
      })
      .catch((error) => {
        this.message = errorHandler(
          dataGeneration,
          error,
          this.t('projectEmployeeWorkload')
        );
        this.toggleModalError();
      });
  }

  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  toggleModalOk = () => {
    this.setState({ showModalOk: !this.state.showModalOk });
  };

  toggleModalForm = () => {
    this.setState({ showModalForm: !this.state.showModalForm });
  };

  changeState = (workloadId: number) => {
    const workloads = this.state.workload;
    const workload = workloads.find(
      (workloadDetail) => workloadDetail.id === workloadId
    );
    if (workload) {
      workload.changeState = !workload.changeState;
    }
    this.setState({
      workload: workloads,
    });
  };

  saveState = (workloadId: number) => {
    const workloads = this.state.workload;
    const workload = workloads.find(
      (workloadDetail) => workloadDetail.id === workloadId
    );
    if (workload) {
      workload.changeState = !workload.changeState;
    }

    axios.project
      .put('project-employee-workloads', workload)
      .then(() => {
        this.setState({
          workload: workloads,
          title: this.t('projectEmployeeWorkloadState'),
          bodyText: this.t('projectEmployeeWorkloadStateChanged'),
        });
        this.toggleModalOk();
      })
      .catch((error) => {
        this.message = errorHandler(
          updateRecord,
          error,
          this.t('projectEmployeeWorkloadState')
        );
        this.toggleModalError();
      });
  };

  handleChangeState = (workloadId: number, state: IDropdownOption) => {
    const workloads = this.state.workload;
    const workload = workloads.find(
      (workloadDetail) => workloadDetail.id === workloadId
    );
    if (workload) workload.workloadState = state.value;
    this.setState({
      workload: workloads,
    });
  };

  handleWorkloadAppearanceChange = () => {
    this.setState({
      showWorkloadInHours: !this.state.showWorkloadInHours,
    });
  };

  workloadAppearance = (workloadHours: number) => {
    if (this.state.showWorkloadInHours) {
      return workloadHours.toString() + this.t('hours');
    } else if (this.state.employee.workhoursPerDay) {
      const percentWorkload =
        Math.ceil(
          (workloadHours / this.state.employee?.workhoursPerDay) * 100
        ).toString() + ' %';
      return percentWorkload;
    } else {
      return;
    }
  };

  addProjectEmployeeWorkload = (
    projectEmployeeWorkload: IExtendedProjectEmployeeWorkload,
    extend?: boolean
  ) => {
    this.modalTitle = this.t('addWorkload');
    this.setState({
      modalForm: (
        <AddOrUpdateProjectEmployeeWorkload
          projectEmployee={this.state.projectEmployee}
          employee={this.state.employee}
          onCancel={this.toggleModalForm}
          onSave={this.onAddingOrUpdatingWorkload}
          projectEmployeeWorkload={projectEmployeeWorkload}
          extend={extend}
        />
      ),
    });
    this.toggleModalForm();
  };

  onAddingOrUpdatingWorkload = async () => {
    await this.componentDidMount();
    this.toggleModalForm();
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  deleteWorkload = (workloadId: number) => {
    this.toggleModal();
    this.modalEvent = () => {
      const workloads = this.state.workload;
      const newWorkload = workloads.filter(
        (workloadDetail) => workloadDetail.id !== workloadId
      );
      const projectEmployee = this.state.projectEmployee;
      projectEmployee.projectEmployeeWorkloads = newWorkload;

      axios.project
        .put('project-employees', projectEmployee)
        .then((response: AxiosResponse<IProjectEmployee>) => {
          if (response.data instanceof Object) {
            if (response.data.projectEmployeeWorkloads !== undefined) {
              this.setState({
                workload: response.data
                  .projectEmployeeWorkloads as IExtendedProjectEmployeeWorkload[],
                title: this.t('projectEmployeeWorkloadDeleted'),
                bodyText: this.t('projectEmployeeWorkloadStateDeleted'),
              });
            }
            this.toggleModalOk();
          }
        })
        .catch((error) => {
          this.message = errorHandler(
            deletion,
            error,
            this.t('projectEmployeeWorkload')
          );
          this.toggleModalError();
        });
    };
  };

  render() {
    return (
      <Card>
        <CardHeader>
          <div className="card-actions float-end">
            {Auth.hasPermission(
              [
                PERMISSION_URI.assignResourcesToProjects.readWrite.uri,
                PERMISSION_URI.assignResourcesToProjectOptions.readWrite.uri,
                PERMISSION_URI.assignResources.readWrite.uri,
              ],
              [READWRITE]
            ) ? (
              <Button
                color="primary"
                size="m"
                onClick={this.addProjectEmployeeWorkload}
              >
                {generateTitle(
                  BUTTON_TITLE_ENUM.ADD.code,
                  this.t('addWorkload')
                )}
              </Button>
            ) : null}
          </div>
          <CardTitle>
            <h1>{this.t('employeeWorkload')}</h1>
          </CardTitle>
        </CardHeader>

        <CardBody>
          <td width="20px" />
          <td>
            <Input
              type="checkbox"
              onChange={this.handleWorkloadAppearanceChange}
              checked={this.state.showWorkloadInHours}
            />
          </td>
          <td>{this.t('showWorkloadInHours')}</td>
          <Table size="m" className="my-2" cellPadding="20px" striped>
            <thead>
              <tr>
                <th>{this.t('start')}</th>
                <th>{this.t('end')}</th>
                <th className="text-nowrap">{this.t('workload')}</th>
                <th className="text-nowrap">{this.t('state')}</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {this.state.workload.map((workload, i: number) => (
                <tr key={`entity-${i}`}>
                  <td>{moment(workload.startDate).format(DATE_FORMAT)}</td>
                  <td>{moment(workload.endDate).format(DATE_FORMAT)}</td>
                  <td>{this.workloadAppearance(workload?.workload ?? 0)}</td>
                  <td style={{ width: '30%' }}>
                    {workload.changeState ? (
                      <Select
                        options={sortOptionsByValue(
                          this.state.stateOptions.filter(
                            (option) =>
                              option.value !==
                                ProjectEmployeeWorkloadState.WAITING_FOR_APPROVAL &&
                              option.value !==
                                ProjectEmployeeWorkloadState.ASSIGNED &&
                              option.value !==
                                ProjectEmployeeWorkloadState.PLANNED
                          )
                        )}
                        value={this.state.stateOptions.find(
                          (option) => option.value === workload.workloadState
                        )}
                        onChange={(state: IDropdownOption) => {
                          if (workload.id)
                            this.handleChangeState(workload.id, state);
                        }}
                        isDisabled={!workload.changeState}
                      />
                    ) : (
                      <>
                        {' '}
                        {
                          this.state.stateOptions.find(
                            (option) => option.value === workload.workloadState
                          )?.label
                        }
                      </>
                    )}{' '}
                  </td>
                  <td>
                    {Auth.isAdmin() ? (
                      <>
                        {workload.changeState ? (
                          <Button
                            color="primary"
                            size="m"
                            onClick={() => {
                              if (workload.id) this.saveState(workload.id);
                            }}
                          >
                            {generateTitle(
                              BUTTON_TITLE_ENUM.SAVE.code,
                              this.t('saveState')
                            )}
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            size="m"
                            onClick={() => {
                              if (workload.id) this.changeState(workload.id);
                            }}
                          >
                            {generateTitle(
                              BUTTON_TITLE_ENUM.EDIT.code,
                              this.t('changeState')
                            )}
                          </Button>
                        )}
                      </>
                    ) : null}
                  </td>
                  <td style={{ width: '20%' }}>
                    <Button
                      color="primary"
                      size="m"
                      onClick={() => this.addProjectEmployeeWorkload(workload)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>{' '}
                    <Button
                      color="primary"
                      size="m"
                      onClick={() => {
                        if (workload.id) this.deleteWorkload(workload.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>{' '}
                    <Button
                      color="primary"
                      size="m"
                      onClick={() =>
                        this.addProjectEmployeeWorkload(workload, true)
                      }
                    >
                      Extend
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <br />
          <br />
        </CardBody>
        <ModalDelete
          isOpen={this.state.showModal}
          onClose={this.toggleModal}
          event={this.modalEvent}
          modalTitle={this.t('deleteEmployeeWorkload')}
          modalBodyText={this.t('deleteEmployeeWorkloadConfirm')}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.message?.mainError}
          errorReason={this.message?.errorReason}
          errorResponse={this.message?.errorResponse}
          modalTitle="Error"
        />
        <ModalOK
          isOpen={this.state.showModalOk}
          onClose={this.toggleModalOk}
          modalBodyText={this.state.bodyText}
          modalTitle={this.state.title}
        />
        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleModalForm}
          modalTitle={this.modalTitle}
        >
          {this.state.modalForm}
        </ModalForm>
      </Card>
    );
  }
}

export default EmployeeWorkloadCard;
