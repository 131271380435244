import { faBook, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
  Col,
  Container,
  Row,
} from 'reactstrap';

import DynamicTable from '../../../components/tables/DynamicTable';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { dataGeneration } from '../../../utils/constants';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { RootState } from '../../../redux/store';
import { alt, sortApprovalData } from './approvalListHelpers';
import {
  setHasMoreListItems,
  setPage,
  setFilters,
  toggleFilter,
  setSortBy,
  setSortType,
  fetchApprovalRequestListItems,
  setListItems,
  setHistoricHasMoreListItems,
  setHistoricPage,
} from '../../../redux/approvalRequestListSlice';
import { IApprovalRequestListItem } from '../../../utils/types/responseTypes';
import { SortBy, SortType } from '../../../utils/enums/sort';
import { getNextSortType } from '../../../utils/helpers/table';
import ApprovalProjectModal from './ApprovalProjectModal';
import { IApprovalRequestFilters } from '../../../utils/types/stateTypes';
import useMounted from '../../../hooks/useMounted';

interface IProps
  extends PropsFromRedux,
    RouteComponentProps<
      Record<string, never>,
      Record<string, never>,
      { message: string }
    >,
    IWithModalsProps {}

/**
 * Lists the approvals request. Can switch between Historic and regular views
 */
const ApprovalsList = ({
  // WithModals
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
  // Redux State
  listItems,
  historicListItems = [],
  showFilters,
  sortBy,
  sortType,
  hasMore,
  historicHasMore,
  // Redux actions
  setHasMoreListItems,
  setHistoricHasMoreListItems,
  setPage,
  setHistoricPage,
  setSortBy,
  setSortType,
  // Thunks
  fetchApprovalRequestListItems,
  // Others
  location: { pathname },
  account: {
    employeeDetails: { id: employeeId },
  },
}: IProps) => {
  const [historicView, setHistoricView] = useState(false);

  const isMounted = useMounted();

  /**
   * Fetches the data from the approvals list, the 2nd one is only for the historic data's first load
   */
  const fetchData = (refresh: boolean) => {
    fetchApprovalRequestListItems(
      refresh,
      historicView,
      employeeId ?? 0,
      (error) => {
        modalErrorHandler(dataGeneration, error);
      }
    );
    if (historicListItems.length === 0) {
      fetchApprovalRequestListItems(
        refresh,
        !historicView,
        employeeId ?? 0,
        (error) => {
          modalErrorHandler(dataGeneration, error);
        }
      );
    }
  };

  /**
   * Handles the sorting logic
   * @param nextSortBy
   */
  const handleSort = (nextSortBy: SortBy) => {
    const nextSortType = getNextSortType(sortType, nextSortBy, sortBy);

    setSortBy(
      nextSortType === SortType.DEFAULT ? SortBy.CREATED_DATE : nextSortBy
    );
    setSortType(
      nextSortType === SortType.DEFAULT ? SortType.ASCENDING : nextSortType
    );
  };

  // Refresh data
  const refreshData = () => {
    setHistoricPage(0);
    setHistoricHasMoreListItems(true);
    setPage(0);
    setHasMoreListItems(true);
    fetchData(true);
  };

  // Closes the modal and refreshes the data
  const closeModalAndRefresh = () => {
    toggleModalForm();
    refreshData();
  };

  /**
   * Toggle Dispalying modifiable items
   */
  const toggleModifiable = () => {
    setHistoricView(!historicView);
  };

  /**
   * Shows the Approval Modal for each of the respective items
   * @param event
   * @param approval
   * @param objectType
   */
  const showApprovalModal = ({
    objectType,
    id,
    attachedItemToRequest: { id: attatchedId },
  }: IApprovalRequestListItem) => {
    switch (objectType) {
      case 'PROJECT':
        modalFormHandler(
          alt('approveProject'),
          <ApprovalProjectModal
            projectId={attatchedId}
            approvalId={id}
            onUpdate={closeModalAndRefresh}
          />,
          'lg'
        );
        break;
      default:
        break;
    }
  };

  /**
   * Here the Data is prepared to function with the DynamicTable Component
   * @returns tableData
   */
  const prepareTabledata = (fetchedApprovals: IApprovalRequestListItem[]) =>
    fetchedApprovals.map(
      (approval: IApprovalRequestListItem) =>
        ({
          // Some data will only be shown depending on specific key parameters
          ...sortApprovalData(approval),
          title: approval.modifiable ? (
            <Button
              className="link-button"
              onClick={() => showApprovalModal(approval)}
              to={'#'}
            >
              {approval.attachedItemToRequest.name}
            </Button>
          ) : (
            approval.attachedItemToRequest.name ?? 'N/A'
          ),
          objectType: approval.objectType ?? 'UNKNOWN',
          createdDate: new Date(approval.createdDate).toDateString(),
        } as IApprovalRequestFilters)
    );

  useEffect(() => {
    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);
      fetchData(true);
    }
  }, [sortBy, sortType]);

  const preparedColumns = [
    {
      type: 'data',
      header: alt('title'),
      accessor: 'title',
      show: 'true',
      filterkey: 'title',
      showsearch: 'true',
    },
    {
      type: 'data',
      header: alt('objectType'),
      accessor: 'objectType',
      show: 'true',
      filterkey: 'objectType',
      showsearch: 'true',
    },
    {
      type: 'data',
      header: alt('attribute'),
      accessor: 'attribute',
      show: historicView ? 'true' : 'false',
      filterkey: 'attribute',
      showsearch: historicView ? 'true' : 'false',
    },
    {
      type: 'data',
      header: alt('creationDate'),
      accessor: 'createdDate',
      show: 'true',
      filterkey: 'creationDate',
      showsearch: 'true',
      sortFunc: handleSort,
    },
    {
      type: 'data',
      header: alt('approved'),
      accessor: 'approved',
      show: 'true',
      filterkey: 'approved',
      showsearch: 'true',
      width: '200px',
    },
    {
      type: 'data',
      header: alt('dateDecision'),
      accessor: 'dateDecision',
      show: historicView ? 'true' : 'false',
      filterkey: 'dateDecision',
      showsearch: historicView ? 'true' : 'false',
    },
  ];
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{alt('approvalsList')}</HeaderTitle>
        {generateBreadcrumb(pathname, alt('customer'))}
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <div
                className="card-actions float-end"
                style={{ paddingRight: '5px' }}
              >
                <Tooltip
                  title={
                    historicView ? alt('pendingOnly') : alt('historicalList')
                  }
                >
                  <Button
                    color={historicView === true ? 'primary' : 'danger'}
                    className="fontAwesomeIconAsButton"
                    onClick={() => toggleModifiable()}
                    role="button"
                  >
                    <FontAwesomeIcon
                      icon={historicView ? faExclamationCircle : faBook}
                    />
                  </Button>
                </Tooltip>
              </div>
              <CardTitle>
                <h1>{alt('approvalsList')}</h1>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <DynamicTable
                data={prepareTabledata(
                  historicView ? historicListItems : listItems
                )}
                columns={preparedColumns}
                infiniteScroll
                enableFilterFunction
                showFilters={showFilters}
                fetchData={() => fetchData(false)}
                hasMoreData={historicView ? historicHasMore : hasMore}
                sortType={sortType}
                sortBy={sortBy}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  account: store.account,
  listItems: store.approvalRequestList.listItems,
  historicListItems:
    store.approvalRequestList.additionalState?.historicListItems,
  filters: store.approvalRequestList.filters,
  showFilters: store.approvalRequestList.showFilters,
  hasMore: store.approvalRequestList.hasMore,
  historicHasMore: store.approvalRequestList.additionalState?.historicHasMore,
  page: store.approvalRequestList.page,
  sortBy: store.approvalRequestList.sortBy,
  sortType: store.approvalRequestList.sortType,
});

const mapDispatchToProps = {
  fetchApprovalRequestListItems,
  setListItems,
  setHasMoreListItems,
  setHistoricHasMoreListItems,
  setPage,
  setHistoricPage,
  setFilters,
  toggleFilter,
  setSortBy,
  setSortType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(ApprovalsList)));
