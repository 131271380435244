import { AxiosResponse } from 'axios';
import React, { ChangeEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  CardBody,
  Container,
  Input,
  FormGroup,
  FormFeedback,
} from 'reactstrap';

import Auth from '../../../services/axios/Auth';
import axiosMethods from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import { getEmployees } from '../../../utils/helpers/url';
import {
  addRecord,
  dataGeneration,
  NO_ID,
  READWRITE,
  updateRecord,
} from '../../../utils/constants';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import { FIELD_OF_BUSINESS_AND_DEPARTMENT_STATE_ENUM } from '../../../utils/enums/department';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import {
  errorHandler,
  isNullOrUndefinedOrEmpty,
  saveResponsibles,
} from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import { RootState } from '../../../redux/store';
import {
  IDropdownOption,
  IErrorMessage,
} from '../../../utils/types/commonTypes';
import {
  IEmployee,
  IFieldOfBusiness,
  IResponsible,
} from '../../../utils/types/modelTypes';
import ModalError from '../../../components/modals/ModalError';
import MultipleResponsibles from '../../../components/form/MultipleResponsibles';
import ResponsibleDropdown from '../../../components/dropdowns/ResponsibleDropdown';
import { Ownership } from '../../../utils/enums/ownership';

interface IProps extends PropsFromRedux, RouteComponentProps {
  onSave: (savedFieldOfBusiness: IFieldOfBusiness) => Promise<void>;
  onDeleteResponsible?: (involvedToDelete: IResponsible) => void;
  fieldOfBusiness?: IFieldOfBusiness;
  responsible?: IResponsible | undefined;
  involvedResponsibles?: IResponsible[];
}

interface IState {
  isUpdate: boolean;
  id?: number | undefined;
  title: string;
  fieldAbbreviation: string;
  fieldOfBusinessId: string;
  status: IDropdownOption;
  showModalError: boolean;
  responsible: IResponsible;
  involvedResponsibles: IResponsible[];
  employeesResponsible: IEmployee[];
  isSaveDisabled: boolean;
}

const ABBREVIATION_LENGTH = 5;

/**
 * This class is for either adding or updating field of business
 */
class FieldsOfBusinessAddOrUpdate extends React.Component<IProps, IState> {
  errorMessage: IErrorMessage = {} as IErrorMessage;

  modalTitle: string | null = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isUpdate: false,
      id: this.props.fieldOfBusiness?.id ?? undefined,
      title: this.props.fieldOfBusiness?.title ?? '',
      fieldAbbreviation: this.props.fieldOfBusiness?.fieldAbbreviation ?? '',
      fieldOfBusinessId: this.props.fieldOfBusiness?.fieldOfBusinessId ?? '',
      status:
        Object.values(FIELD_OF_BUSINESS_AND_DEPARTMENT_STATE_ENUM).find(
          (status) => status.value === this.props.fieldOfBusiness?.status
        ) ?? FIELD_OF_BUSINESS_AND_DEPARTMENT_STATE_ENUM.planned,
      showModalError: false,
      responsible: props.responsible ?? {
        employeeId: Auth.hasPermission(
          [PERMISSION_URI.adminAccess.readWrite.uri],
          [READWRITE]
        )
          ? this.props?.account?.employeeDetails?.id ?? NO_ID
          : NO_ID,
        objectType: OBJECT_TYPE_ENUM.fieldOfBusiness.code,
        objectId: NO_ID,
        ownership: Ownership.RESPONSIBLE,
      },
      involvedResponsibles: this.props.involvedResponsibles ?? [],
      employeesResponsible: [],
      isSaveDisabled: !this.props.fieldOfBusiness?.id,
    };
  }

  t(keyName: string) {
    return i18n.t('FieldsOfBusinessView.' + keyName);
  }

  /**
   * Handles toggling of error modal
   */
  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  async componentDidMount() {
    const employeesResponsible = await getEmployees().catch((error) => {
      this.errorMessage = errorHandler(
        dataGeneration,
        error,
        this.t('failedToRetrieveEmployees')
      );
      this.toggleModalError();
    });
    if (Array.isArray(employeesResponsible)) {
      this.setState({ employeesResponsible });
    }
  }

  /**
   * Handles changes in title
   * @param {*} event
   */
  handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: event.target.value }, () => this.isSaveDisabled());
  };

  /**
   * Handles changes in field abbreviation
   * @param {*} event
   */
  handleFieldAbbreviationChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fieldAbbreviation = (event.target.value.trim() ?? '').toUpperCase();
    this.setState(
      {
        fieldAbbreviation,
      },
      () => this.isSaveDisabled()
    );
  };

  /**
   * Handles changes in department id
   * @param {*} event
   */
  handleFieldOfBusinessIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    let fieldOfBusinessId = event.target.value.trim() ?? '';
    if (fieldOfBusinessId !== '') {
      fieldOfBusinessId = fieldOfBusinessId.toUpperCase();
    }
    this.setState(
      {
        fieldOfBusinessId,
      },
      () => this.isSaveDisabled()
    );
  };

  /**
   * Handles changes in status
   * @param {*} status
   */
  handleStatusChange = (status: IDropdownOption) => {
    this.setState({ status });
  };

  /**
   * Handles changes in responsible
   * @param {*} responsible
   */
  handleResponsibleChange = (responsible: IResponsible) => {
    this.setState({ responsible });
  };

  /**
   * Handles changes in involved responsibles
   * @param {*} involvedResponsibles
   */
  handleInvolvedResponsibleChange = (involvedResponsibles: IResponsible[]) => {
    this.setState({ involvedResponsibles: [...involvedResponsibles] });
  };

  /**
   * Handles deletion of involved responsible
   * @param {*} involvedToDelete
   */
  deleteInvolvedResponsible = (involvedToDelete: IResponsible) => {
    const involvedResponsibles = this.state.involvedResponsibles?.filter(
      (responsible: IResponsible) => responsible?.id !== involvedToDelete?.id
    );
    this.setState({
      involvedResponsibles,
    });

    // Handles the update of involved responsibles list being shown, remove from the current list
    if (this.props.onDeleteResponsible) {
      this.props.onDeleteResponsible(involvedToDelete);
    }
  };

  /**
   * Handles submittion of the entries in the modal
   */
  handleSaveResponsible = async (savedFieldOfBusiness: IFieldOfBusiness) => {
    const savedInvolvedResponsible = await saveResponsibles(
      this.state.involvedResponsibles,
      savedFieldOfBusiness?.id
    ).catch((error) => {
      this.errorMessage = errorHandler(
        addRecord,
        error,
        this.t('failedToSaveResponsibles')
      );
      this.toggleModalError();
    });
    if (savedInvolvedResponsible === undefined) {
      return;
    }

    const savedResponsible = await saveResponsibles(
      [this.state.responsible],
      savedFieldOfBusiness?.id
    ).catch((error) => {
      this.errorMessage = errorHandler(
        addRecord,
        error,
        this.t('failedToSaveResponsibles')
      );
      this.toggleModalError();
    });
    if (savedResponsible === undefined) {
      return;
    }
  };

  /**
   * Handles the disabling of save button based on the modal required fields.
   */
  isSaveDisabled = () => {
    this.setState({
      isSaveDisabled:
        isNullOrUndefinedOrEmpty(this.state.title) ||
        isNullOrUndefinedOrEmpty(this.state.fieldAbbreviation) ||
        isNullOrUndefinedOrEmpty(this.state.fieldOfBusinessId) ||
        this.state.fieldOfBusinessId.length !== ABBREVIATION_LENGTH,
    });
  };

  /**
   * Handles saving of field of business
   * @returns saved field of business
   */
  saveFieldOfBusiness = async () => {
    const fieldToSave = {
      id: this.state.id,
      title: this.state.title,
      fieldAbbreviation: this.state.fieldAbbreviation,
      fieldOfBusinessId: this.state.fieldOfBusinessId,
      status: this.state.status?.value ?? null,
    };
    const savedFieldOfBusiness = await axiosMethods.project
      .save('field-of-businesses', fieldToSave)
      .then(
        (response: AxiosResponse<IFieldOfBusiness>) => response.data ?? null
      )
      .catch((error) => {
        this.errorMessage = errorHandler(
          fieldToSave?.id ? updateRecord : addRecord,
          error,
          this.t('failedToSaveProjectEmployee')
        );
        this.toggleModalError();
      });

    if (savedFieldOfBusiness) {
      await this.handleSaveResponsible(savedFieldOfBusiness);
      await this.props.onSave(savedFieldOfBusiness);
    }

    return savedFieldOfBusiness;
  };

  render() {
    const employeesResponsible = this.state.employeesResponsible;
    const responsible = this.state.responsible;
    const involvedResponsibles = this.state.involvedResponsibles;
    return (
      <Container fluid>
        <CardBody>
          <FormGroup>
            <InputFormLabel isRequired={true} text={this.t('title')} />
            <Input
              onChange={this.handleTitleChange}
              value={this.state.title}
              name="title"
              type="text"
              invalid={this.state.title === undefined}
            />
            <FormFeedback>{this.t('titleRequired')}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <InputFormLabel isRequired={true} text={this.t('abbreviation')} />
            <Input
              onChange={this.handleFieldAbbreviationChange}
              value={this.state.fieldAbbreviation}
              name="fieldAbbreviation"
              type="text"
              invalid={this.state.fieldAbbreviation === undefined}
            />
            <FormFeedback>{this.t('abbreviationRequired')}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <InputFormLabel
              isRequired={true}
              text={`${this.t('fieldOfBusinessId')} (${this.t(
                'mustBeFiveCharacters'
              )})`}
            />
            <Input
              onChange={this.handleFieldOfBusinessIdChange}
              value={this.state.fieldOfBusinessId}
              name="fieldOfBusinessId"
              type="text"
              max={5}
              invalid={this.state.fieldOfBusinessId === undefined}
            />
            <FormFeedback>{this.t('fieldOfBusinessIdRequired')}</FormFeedback>
          </FormGroup>

          <InputFormLabel isRequired={false} text={this.t('status')} />
          <Select
            onChange={this.handleStatusChange}
            options={Object.values(FIELD_OF_BUSINESS_AND_DEPARTMENT_STATE_ENUM)}
            placeholder={this.t('selectStatus')}
            value={this.state.status}
          />
          <br />
          <div>
            <InputFormLabel text={this.t('responsible')} isRequired={false} />
            <ResponsibleDropdown
              onChange={this.handleResponsibleChange}
              employees={employeesResponsible}
              selectedResponsibles={[...involvedResponsibles, responsible]}
              objectType={OBJECT_TYPE_ENUM.fieldOfBusiness.code}
              objectId={this.state.id ?? 0}
              responsible={responsible}
              removeNoneOwnership
              hideOwnershipField
              disableDelete
            />
            <InputFormLabel text={this.t('involved')} isRequired={false} />
            <MultipleResponsibles
              employeesResponsible={employeesResponsible}
              responsibleOwnership={responsible}
              responsibles={involvedResponsibles}
              objectId={this.state.id}
              objectType={OBJECT_TYPE_ENUM.fieldOfBusiness.code}
              onChange={this.handleInvolvedResponsibleChange}
              onDelete={this.deleteInvolvedResponsible}
              hideOwnershipField
              involvedOnly
            />
          </div>
          <br />
          <br />
          <Button
            color="primary"
            className="float-end"
            size="lg"
            onClick={this.saveFieldOfBusiness}
            disabled={this.state.isSaveDisabled}
          >
            {this.t('save')}
          </Button>
        </CardBody>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.errorMessage?.mainError}
          errorReason={this.errorMessage?.errorReason}
          errorResponse={this.errorMessage?.errorResponse}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}

const mapStateToProps = (store: RootState) => ({
  account: store.account,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default withRouter(connector(FieldsOfBusinessAddOrUpdate));
