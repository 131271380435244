import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  FormGroup,
  FormFeedback,
  Input,
} from 'reactstrap';
import axios from '../../../services/axios/axios';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import InputFormLabel from '../../../components/form/InputFormLabel';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import ModalError from '../../../components/modals/ModalError';
import ModalOK from '../../../components/modals/ModalOK';

/*
 * Class used when adding/updating a Responsible Role
 */
class AddOrUpdateResponsibleRole extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdate: false,
      id: null,
      role: '',
      roles: [],
      showModalError: false,
      showModalOK: false,
    };

    this.translation = this.props.t;

    this.mainError = '';
    this.errorReason = '';
    this.errorResponse = '';
  }

  //Toggle for showing the modal for duplicate entries.
  ToggleModalOK = () => {
    this.setState({ showModalOK: !this.state.showModalOK });
  };

  componentDidMount() {
    axios.sales.get('responsible-roles').then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({
          roles: response.data,
        });
      }
    });
    if (this.props.match.params.id) {
      axios.sales
        .get(`responsible-roles/${this.props.match.params.id}`)
        .then((response) => {
          this.setState({
            role: response.data?.role,
          });
        })
        .catch((error) => {
          let mainError = this.translation(
            'AddOrUpdateResponsibleRole.failedToGetResponsibleRole'
          );
          this.handleError(mainError, error.message);
        });

      this.setState({
        id: this.props.match.params.id,
        isUpdate: true,
      });
    }
  }

  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };
  handleSubmit = (e) => {
    var sameNameExistingEntry = this.state.roles.find(
      (item) => item.role === this.state.role
    );
    if (
      sameNameExistingEntry !== undefined &&
      sameNameExistingEntry?.id !== parseInt(this.state.id, 10)
    ) {
      this.errorMessage = this.translation(
        'AddOrUpdateResponsibleRole.nameAlreadyExist'
      );
      this.ToggleModalOK();
      return;
    }
    if (this.state.isUpdate) {
      this.updateResponsibleRole();
    } else {
      this.insertResponsibleRole();
    }
  };

  // method to handle all errors encountered.
  // shows the modalError dialog
  handleError = (mainError, errorMessage) => {
    this.mainError = mainError;
    this.errorReason = this.translation(
      'AddOrUpdateResponsibleRole.serverFailed'
    );
    this.errorResponse = errorMessage;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  updateResponsibleRole() {
    axios.sales
      .put('responsible-roles', this.state)
      .then((response) => {
        this.props.history.push({
          pathname: '/settings/sales/',
          state: {
            successMessage:
              this.translation(
                'AddOrUpdateResponsibleRole.updatedResponsibleRole'
              ) + response.data.id,
          },
        });
      })
      .catch((error) => {
        let mainError = this.translation(
          'AddOrUpdateResponsibleRole.failedToUpdateResponsibleRole'
        );
        this.handleError(mainError, error.message);
      });
  }

  insertResponsibleRole() {
    axios.sales
      .post('responsible-roles', this.state)
      .then(() => {
        if (this.props.onSave) {
          this.props.onSave();
        } else {
          this.props.history.push({
            pathname: this.props.location?.state?.previousPath,
            state: {
              successMessage: this.translation(
                'AddOrUpdateResponsibleRole.addedNewResponsibleRole'
              ),
            },
          });
        }
      })
      .catch((error) => {
        let mainError = this.translation(
          'AddOrUpdateResponsibleRole.failedToUpdateResponsibleRole'
        );
        this.handleError(mainError, error.message);
      });
  }

  handleRoleChange = (e) => {
    this.setState({ role: e.target.value });
  };

  render() {
    return (
      <Container fluid>
        {this.state.isUpdate ? (
          <Header>
            <HeaderTitle>
              {this.translation('AddOrUpdateResponsibleRole.salesManagement')}
            </HeaderTitle>
            {generateBreadcrumb(
              this.props.location.pathname,
              this.translation('AddOrUpdateResponsibleRole.settings')
            )}
          </Header>
        ) : null}
        <Card>
          {this.state.isUpdate ? (
            <CardHeader>
              <CardTitle>
                <h1>Responsible Role Details - {this.props.match.params.id}</h1>
              </CardTitle>
            </CardHeader>
          ) : null}
          <CardBody>
            <FormGroup>
              <InputFormLabel
                isRequired={true}
                text={this.translation('AddOrUpdateResponsibleRole.role')}
              />
              <Input
                onChange={this.handleRoleChange}
                value={this.state.role}
                name="role"
                type="text"
                invalid={this.state.role === undefined}
              />
              <FormFeedback>
                {this.translation('AddOrUpdateResponsibleRole.roleRequired')}
              </FormFeedback>
            </FormGroup>

            <Button
              color="primary"
              className="float-end"
              onClick={this.handleSubmit}
              disabled={this.state.role === undefined}
            >
              {this.translation('AddOrUpdateResponsibleRole.submit')}
            </Button>
          </CardBody>
        </Card>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle="Error"
        ></ModalError>
        <ModalOK
          isOpen={this.state.showModalOK}
          onClose={this.ToggleModalOK}
          modalTitle="Error"
          modalBodyText={this.errorMessage}
        ></ModalOK>
      </Container>
    );
  }
}

export default withRouter(withTranslation()(AddOrUpdateResponsibleRole));
