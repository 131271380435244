import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { Ownership } from '../../../utils/enums/ownership';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { setFilters, toggleFilter } from '../../../redux/gdprListSlice';
import { RootState } from '../../../redux/store';
import { t } from './gdprOverviewHelpers';
import ResponsibleOwnershipDropdown from '../../../components/dropdowns/ResponsibleOwnershipDropdown';
import GdprOverviewTable from './GdprOverviewTable';

interface IProps
  extends IWithModalsProps,
    PropsFromRedux,
    RouteComponentProps {}

/**
 * Parent component for the Gdpr Overview, a page that allows a user to see list of contact person classified as "with issue" based from the following conditions.
 * The List only includes "issues", which can be:
    - a. Status_GDPR==declined && attr_type==2,3 or 4 
      OR 
    - b .Status_GDPR==none && attr_type==3 or 4 
      OR
    - c. The Tool realizes, if a contact has Status_GDPR=="none" and one of the following entries have been made:
    - Phone
    - Mobile
    - Email (if not guessed)
    - attr_type = not 0
 */
const GdprOverview = ({
  // Redux state
  filters,
  // Redux actions
  setFilters,
  toggleFilter,
  // Route
  location,
}: IProps) => {
  /**
   * Handles the values selected in the ownership filter
   * @param ownership
   */
  const handleOwnershipFilter = (ownership: Ownership | Ownership[]) => {
    if (
      ownership === Ownership.ALL ||
      ownership === Ownership.ALL.toLowerCase()
    ) {
      setFilters({
        ...filters,
        ownership: [],
      });
    } else {
      setFilters({
        ...filters,
        ownership: Array.isArray(ownership) ? ownership : [ownership],
      });
    }
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('gdprOverview')}</HeaderTitle>
        {generateBreadcrumb(location.pathname, t('customer'))}

        <Row>
          <Col>
            <Card className="flex-fill w-100">
              <CardHeader>
                <CardTitle className="mb-0">
                  <h1>
                    {t('gdprOverview')}
                    <div className="card-actions float-end">
                      <Button
                        color="primary"
                        className="fontAwesomeIconAsButton"
                        onClick={() => toggleFilter()}
                        aria-label="button-filter"
                      >
                        <FontAwesomeIcon icon={faFilter} />
                      </Button>{' '}
                    </div>
                  </h1>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <ResponsibleOwnershipDropdown
                      includeResponsibleAndInvolvedOption
                      value={filters.ownership}
                      onChange={handleOwnershipFilter}
                    />
                  </Col>
                </Row>
                <br />
                <GdprOverviewTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Header>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  filters: store.gdprList.filters,
});

const mapDispatchToProps = {
  setFilters,
  toggleFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(GdprOverview)));
