import i18n from '../../../i18n';
import { IProjectMemberListItem } from '../../../utils/types/responseTypes';
import { ProjectEmployeeRole } from '../../../utils/enums/project';

export const t = (keyName: string) =>
  i18n.t(`ProjectDetailOverview.${keyName}`);

// Data Constants for the projects section
export enum ProjectDetailFieldsProperties {
  PROJECT_ID = 'projectId',
  TITLE = 'title',
  PROJECT_TYPE = 'projectType',
  STATE = 'state',
  START = 'start',
  END = 'end',
  STATUS = 'state',
  DEPARTMENTS = 'departments',
  CUSTOMER_SITE = 'customerSite',
  RESPONSIBLE_CONTACT_PERSONS = 'responsibleContactPersons',
  ACQUISITION_TYPE = 'acquisitionType',
  DESCRIPTION = 'description',
  PROJECT_SHAREPOINT = 'projectSharePoint',
  PROJECT_GAIN_PROBABILITY = 'projectGainProbability',
  ORIGIN_OF_OPTION = 'originOfOption',
  AVERAGE_PROJECT_SIZE = 'averageProjectSize',
  PROJECT_DURATION = 'durationType',
  PROJECT_LOCATION = 'locationType',
  PROJECT_MEMBERS = 'projectMembers',
}

export enum DurationTypeEnum {
  LONG_TERM = 'LONG_TERM',
  SHORT_TERM = 'SHORT_TERM',
  MAINTENANCE = 'MAINTENANCE',
}

export enum LocationTypeEnum {
  FULLY_REMOTE = 'FULLY_REMOTE',
  FULLY_ONSITE = 'FULLY_ONSITE',
  PARTIALY_REMOTE_ONSITE = 'PARTIALY_REMOTE_ONSITE',
  MIXED_LOCATIONS = 'MIXED_LOCATIONS',
}

/**
 * Checks if the current user is a ProjectOwner
 * @returns true or false
 */
export const isUserAProjectOwner = (
  projectMembers: IProjectMemberListItem[],
  employeeId?: number
) => {
  const userEmployee = projectMembers.find(
    ({ employee }) => employee.id === employeeId
  );

  return userEmployee?.role === ProjectEmployeeRole.OWNER;
};
