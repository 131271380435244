import React, { useContext } from 'react';
import moment from 'moment';

import { OfferDetailsContext } from './OfferDetailsProvider';
import TableCard from '../../../components/cards/TableCard';
import { getDateFormat } from '../../../utils/helpers/date';
import { translate } from './offerHelper';

const OffersHistoryCard = () => {
  const { offer } = useContext(OfferDetailsContext);

  const { history } = offer;

  const tableData = history
    ? history.map(({ offerVersion, offerBudget, offerCreationDate }) => ({
        [translate('version')]: `${offerVersion}`,
        [translate('offerSum')]: `${offerBudget}`,
        [translate('dateOfCreation')]: `${moment(offerCreationDate).format(
          getDateFormat()
        )}`,
      }))
    : [];

  return (
    <TableCard
      tableData={tableData}
      title={translate('historyTitle')}
      border
      noDataPlaceholder={translate('none')}
    />
  );
};

export default OffersHistoryCard;
