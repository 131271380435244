import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import React from 'react';
import { Button, CardBody, Container, Label } from 'reactstrap';

import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { isEmpty } from '../../../utils/helpers/GenericHelper';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IError } from '../../../utils/types/commonTypes';
import { IRejectionItem } from '../../../utils/types/modelTypes';
import RejectionItemInput from './RejectionItemInput';

interface IState {
  rejectionItems: IRejectionItem[];
}

interface IProps extends IWithModalsProps {
  handleRejectionItemUpdate: () => void;
}

interface IRejectionInputEntry {
  textJSON: JSX.Element;
  deleteButton: JSX.Element;
}
/**
 * Class for showing the Rejection Types
 */
class RejectionItemSettings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      rejectionItems: [],
    };
  }

  t(keyName: string) {
    return i18n.t(`RejectionItemSettings.${keyName}`);
  }

  async componentDidMount() {
    const { modalErrorHandler } = this.props;
    await axios.employee
      .get('rejection-items')
      .then((response: AxiosResponse<IRejectionItem[]>) => {
        if (!isEmpty(response.data)) {
          const rejectionItems = response.data.sort((a, b) =>
            this.translateRejectionItem(a.textJSON) >
            this.translateRejectionItem(b.textJSON)
              ? 1
              : -1
          );
          this.setState({ rejectionItems });
        }
      })
      .catch((error: IError) => {
        modalErrorHandler(this.t('failedToRetrieveRejectionItem'), error);
      });
  }

  /**
   * Return the Rejection Item based on the current language
   * @param {string} textJSON
   * @returns translated Item
   */
  translateRejectionItem = (textJSON: string | undefined) => {
    const currentLng = localStorage.getItem('i18nextLng');
    if (textJSON && currentLng) {
      const data = JSON.parse(textJSON);
      return data[currentLng.toUpperCase()];
    }
  };

  /**
   * Prepares data for table
   * @param {*} rejectionItems
   * @returns tableData
   */
  prepareTableData = (rejectionItems: IRejectionItem[]) => {
    const newTableData: IRejectionInputEntry[] = [];
    if (isEmpty(rejectionItems)) return [];

    rejectionItems.forEach((rejectionItem: IRejectionItem) => {
      const entry = {
        textJSON: (
          <Button
            color="link"
            onClick={() => this.updateRejectionItem(rejectionItem)}
          >
            {this.translateRejectionItem(rejectionItem.textJSON)}
          </Button>
        ),
        deleteButton: (
          <Button
            color="primary"
            onClick={() => this.deleteRejectionItem(rejectionItem)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        ),
      };
      newTableData.push(entry);
    });
    return newTableData;
  };

  /**
   * Handles updating upon clicking of edit button
   * @param {*} rejectionItem
   */
  updateRejectionItem = (rejectionItem: IRejectionItem | null) => {
    const { modalFormHandler, toggleModalForm } = this.props;
    modalFormHandler(
      rejectionItem ? this.t('editRejectionItem') : this.t('addRejectionItem'),
      <RejectionItemInput
        rejectionItem={rejectionItem}
        onSave={this.onSave}
        onCancel={() => toggleModalForm()}
      />
    );
  };

  /**
   * Handles deleting rejection type
   * @param {*} rejectionItem
   */
  deleteRejectionItem(rejectionItem: IRejectionItem) {
    const { modalDeleteHandler, modalErrorHandler } = this.props;
    const { rejectionItems } = this.state;
    modalDeleteHandler(
      this.t('deleteRejectionItem'),
      this.t('deleteMessage'),
      async () => {
        await axios.project
          .delete(`rejection-items/${rejectionItem.id}`)
          .then(() => {
            const newList = rejectionItems.filter(
              (oldRejectionItem: IRejectionItem) =>
                oldRejectionItem.id !== rejectionItem.id
            );

            this.setState({
              rejectionItems: newList,
            });
          })
          .catch((error) => {
            modalErrorHandler(this.t('failedToDeleteRejectionItem'), error);
          });
      }
    );
  }

  /**
   * Updates the state of Rejection Items upon saving of the new or edited Rejection Type
   * @param {*} rejectionItem
   */
  onSave = (rejectionItem: IRejectionItem) => {
    const { handleRejectionItemUpdate } = this.props;
    const { rejectionItems: newList } = this.state;

    const index = newList.findIndex((item) => item.id === rejectionItem.id);
    if (index >= 0) {
      newList[index] = rejectionItem;
    } else {
      newList.push(rejectionItem);
    }

    this.setState({
      rejectionItems: newList,
    });

    handleRejectionItemUpdate();
    void this.componentDidMount();
  };

  render() {
    const { rejectionItems } = this.state;
    const preparedColumns = [
      {
        type: 'component',
        header: this.t('rejectionItem'),
        accessor: 'textJSON',
        show: 'true',
      },
      {
        type: 'component',
        header: this.t('delete'),
        accessor: 'deleteButton',
        show: 'true',
      },
    ];
    return (
      <Container fluid>
        <div>
          <Button
            color="primary"
            className="float-end"
            onClick={() => this.updateRejectionItem(null)}
          >
            <FontAwesomeIcon icon={faPlus} /> {this.t('add')}
          </Button>
        </div>
        <br />
        <CardBody>
          {!isEmpty(rejectionItems) ? (
            <DynamicTable
              data={this.prepareTableData(rejectionItems)}
              columns={preparedColumns}
            />
          ) : (
            <Label>{this.t('rejectionItemsEmpty')}</Label>
          )}
        </CardBody>
      </Container>
    );
  }
}

export default withModals(RejectionItemSettings);
