import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Table,
} from 'reactstrap';

import axios from '../../../services/axios/axios';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { ROLE_ENUM } from '../../../utils/enums/objectType';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { handleError } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import { RootState } from '../../../redux/store';
import { IErrorMessage } from '../../../utils/types/commonTypes';
import {
  IEmployeeGroup,
  IEmployeeWithRelationship,
  IExtendedRoleGroup,
  IRole,
  IRoleGroup,
  ISubject2Role,
} from '../../../utils/types/modelTypes';
import ModalError from '../../../components/modals/ModalError';
import EmployeeCard from './EmployeeCard';
import { default as EmployeeGroupCard } from './EmployeeGroupCard';
import RoleCard from './RoleCard';

type IProps = RouteComponentProps<{ id: string; pathname: string }>;

interface IState {
  roleGroup: IRoleGroup;
  showModalError: boolean;
  employeesWithRelations: IEmployeeWithRelationship[];
  subjectType: string;
  subjectId: number;
  employeeGroups: IEmployeeGroup[];
  subjectRoles: ISubject2Role[];
  roles: IRole[];
}

/*
 * Class that displays the details of a Role Group
 */
class RoleGroupDetail extends React.Component<IProps, IState> {
  error: IErrorMessage = {} as IErrorMessage;

  constructor(props: IProps) {
    super(props);
    this.state = {
      roleGroup: {} as IRoleGroup,
      showModalError: false,
      employeesWithRelations: [],
      subjectType: '',
      subjectId: -1,
      employeeGroups: [],
      subjectRoles: [],
      roles: [],
    };
  }

  async componentDidMount() {
    // Get the Role Group Details
    await axios.employee
      .get(`extended-role-groups?id.equals=${this.props.match.params.id}`)
      .then((response: AxiosResponse<IExtendedRoleGroup[]>) => {
        const extendedRoleGroup: IExtendedRoleGroup[] = response.data;
        this.setState({
          roleGroup: extendedRoleGroup[0]?.roleGroup ?? ({} as IRoleGroup),
          subjectRoles: extendedRoleGroup[0]?.roles ?? [],
          employeeGroups: extendedRoleGroup[0]?.employeeGroups ?? [],
          employeesWithRelations: extendedRoleGroup[0]?.employeeRelations ?? [],
        });
      })
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveRoleGroupDetails');
        this.handleError(mainError, error);
      });
  }

  t(keyName: string) {
    return i18n.t(`RoleGroupDetail.${keyName}`);
  }

  handleError = (mainError: string, errorObject: AxiosError) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  render() {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{this.t('roleGroupDetail')}</HeaderTitle>
          {generateBreadcrumb(this.props.location.pathname, 'Employees')}
        </Header>
        <Container fluid>
          <Card>
            <CardHeader>
              <CardTitle className="mb-0">
                <h1>{this.t('roleGroupDetail')}</h1>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table>
                <tr>
                  <th>{this.t('roleGroup')}</th>
                  <td>{this.state.roleGroup.roleGroup}</td>
                </tr>
                <tr>
                  <th>{this.t('description')}</th>
                  <td>{this.state.roleGroup.description}</td>
                </tr>
              </Table>
            </CardBody>
          </Card>
        </Container>
        <EmployeeCard
          cardTitle={this.t('employees')}
          buttonName={this.t('addEmployee')}
          employees={this.state.employeesWithRelations}
          displayType={ROLE_ENUM.roleGroup.code}
          roleOrGroupId={parseInt(this.props.match.params.id)}
          employeeGroups={this.state.employeeGroups}
        />
        <EmployeeGroupCard
          cardTitle={this.t('employeeGroups')}
          subjectType={ROLE_ENUM.roleGroup.code}
          subjectId={parseInt(this.props.match.params.id)}
          employeeGroups={this.state.employeeGroups}
        />
        <RoleCard
          cardTitle={this.t('roles')}
          buttonName={this.t('addRole')}
          subjectId={parseInt(this.props.match.params.id)}
          subjectType={ROLE_ENUM.roleGroup.code}
          roles={this.state.subjectRoles}
          showRoleAdd
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error.mainError}
          errorReason={this.error.errorReason}
          errorResponse={this.error.errorResponse}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}

export default connect((store: RootState) => ({
  calling: store.calling,
}))(RoleGroupDetail);
