import React, { useEffect, useState } from 'react';
import { Table, Button, Input } from 'reactstrap';
import Select from 'react-select';

import i18n from '../../i18n';
import { IDropdownOption, IObjectNameAndId } from '../../utils/types/commonTypes';
import InputFormLabel from './InputFormLabel';
import { styleWidth30Percent } from '../../utils/constants';

interface IProps {
  currentResponsible: IObjectNameAndId;
  employeesWithPermission: IDropdownOption<number>[] | undefined;
  objectType: string;
  objectName: string;
  onSetResponsible: (responsible: IObjectNameAndId) => void;
  onCancel: () => void;
}

const SelectNewResponsibleModal: React.FC<IProps> = ({
  currentResponsible,
  employeesWithPermission,
  objectType,
  objectName,
  onSetResponsible,
  onCancel,
}) => {
  const [responsibleIsChanged, setIsResponsibleChanged] = useState(false);
  const [newResponsible, setNewResponsible] = useState<IDropdownOption>({
    label: currentResponsible.name,
    value: currentResponsible.id?.toString(),
  });

  const handleResponsibleChange = (responsible: IDropdownOption) => {
    setNewResponsible(responsible);
  };

  useEffect(() => {
    if (newResponsible.value !== currentResponsible.id?.toString()) {
      setIsResponsibleChanged(true);
    } else {
      setIsResponsibleChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newResponsible.value]);

  return (
    <>
      <Table borderless>
        <tbody>
          <tr>
            <th style={{ width: styleWidth30Percent }}>
              <InputFormLabel isRequired={false} text={objectType} />
            </th>
            <td>
              <Input type="string" bsSize="md" disabled value={objectName} />
            </td>
          </tr>
          <tr>
            <th style={{ width: styleWidth30Percent }}>
              <InputFormLabel
                isRequired={false}
                text={i18n.t('SetNewResponsible.actualResponsible')}
              />
            </th>
            <td>
              <Input
                type="string"
                bsSize="md"
                disabled
                value={currentResponsible.name}
              />
            </td>
          </tr>
          <tr>
            <th>
              <InputFormLabel
                isRequired
                text={i18n.t('SetNewResponsible.newResponsible')}
              />
            </th>
            <td>
              <Select
                options={employeesWithPermission}
                value={newResponsible}
                onChange={handleResponsibleChange}
              />
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="float-right">
        <Button
          color="primary"
          onClick={() =>
            onSetResponsible({
              id: Number(newResponsible.value),
              name: newResponsible.label,
            })
          }
          disabled={!responsibleIsChanged}
        >
          {i18n.t('SetNewResponsible.setNewResponsible')}
        </Button>{' '}
        <Button color="primary" onClick={() => onCancel()}>
          {i18n.t('SetNewResponsible.cancel')}
        </Button>
      </div>
    </>
  );
};

export default SelectNewResponsibleModal;
