import axios, { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { urlBuilder } from '../../utils/helpers/url';
import { Url } from './constants';
import {
  IFileAttachment,
  IFileAttachmentListItem,
} from '../../utils/types/modelTypes';

export const saveAttachment = (activity: IFileAttachment) =>
  trackPromise<AxiosResponse<IFileAttachment>>(
    axios.post(urlBuilder(Url.FILE_ATTACHMENT, {}), activity)
  );

export const updateAttachment = (activity: IFileAttachment) =>
  trackPromise<AxiosResponse<IFileAttachment>>(
    axios.put(urlBuilder(Url.FILE_ATTACHMENT, {}), activity)
  );

export const deleteAttachment = (id: number) =>
  trackPromise<AxiosResponse<void>>(
    axios.delete(urlBuilder(`${Url.FILE_ATTACHMENT}/${id}`, {}))
  );

export const getAllAttachments = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<IFileAttachmentListItem[]>>(
    axios.get(urlBuilder(Url.FILE_ATTACHMENT, queryParameters))
  );
