/* eslint-disable @typescript-eslint/no-floating-promises */
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { getCustomerSiteDetails } from '../../../services/api/customerSite';
import {
  IObjectContactInformation,
  IObjectInvolvedResponsible,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import {
  IActivityListItem,
  IFileAttachmentListItem,
} from '../../../utils/types/modelTypes';
import { ICustomerSiteDetail } from '../../../utils/types/responseTypes';
import { translate } from './customerSiteDetailsHelper';

interface IProps extends IWithModalsProps {
  children: ReactNode;
}

interface RouterParams {
  customerSiteId: string;
}

interface CustomerSiteDetailsContextType {
  customerSiteDetails: ICustomerSiteDetail;
  setCustomerSiteDetails: Dispatch<SetStateAction<ICustomerSiteDetail>>;
}

export const CustomerSiteDetailsContext = createContext(
  {} as CustomerSiteDetailsContextType
);

const CustomerSiteDetailsContextProvider = ({
  children,
  modalErrorHandler,
}: IProps) => {
  const [customerSiteDetails, setCustomerSiteDetails] =
    useState<ICustomerSiteDetail>({
      id: 0,
      customerAccount: { id: 0, name: '' },
      countryState: {
        id: 0,
        name: '',
        country: {
          id: 0,
          name: '',
        },
      },
      country: {
        id: 0,
        name: '',
      },
      customerSiteState: '',
      customer: {
        id: 0,
        name: '',
      },
      state: '',
      location: '',
      sectors: [] as IObjectNameAndId[],
      contactInformation: [] as IObjectContactInformation[],
      contactPersons: [] as IObjectNameAndId[],
      name: '',
      street: '',
      zipCode: '',
      customerSiteType: '',
      targetPriority: '',
      responsible: { id: 0, name: '' },
      involvedEmployees: [] as IObjectInvolvedResponsible[],
      activities: [] as IActivityListItem[],
      fileAttachments: [] as IFileAttachmentListItem[],
    } as ICustomerSiteDetail);

  const { customerSiteId } = useParams<RouterParams>();

  const fetchCustomerSiteDetails = async (customerSiteId: string) => {
    try {
      const { data: fetchedDetails } = await getCustomerSiteDetails(
        customerSiteId
      );
      setCustomerSiteDetails(fetchedDetails);
    } catch (error) {
      modalErrorHandler(
        translate('failedToRetrieveCustomerSiteDetails'),
        error
      );
    }
  };

  useEffect(() => {
    if (customerSiteId) {
      fetchCustomerSiteDetails(customerSiteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSiteId]);

  return (
    <CustomerSiteDetailsContext.Provider
      value={{ customerSiteDetails, setCustomerSiteDetails }}
    >
      {children}
    </CustomerSiteDetailsContext.Provider>
  );
};

export default withModals(CustomerSiteDetailsContextProvider);
