import React from 'react';
import Select from 'react-select';

import { getEmployeesWithPermissions } from '../../utils/helpers/url';
import { READWRITE } from '../../utils/constants';
import { ProjectEmployeeRole } from '../../utils/enums/project';
import { PERMISSION_URI } from '../../utils/enums/permission';
import i18n from '../../i18n';
import { IDropdownOption } from '../../utils/types/commonTypes';
import { IEmployee } from '../../utils/types/modelTypes';
import { enumValuesToDropdownOptions } from '../../utils/helpers/dropdown';

interface IProps {
  employeeId: number | null;
  projectEmployeeRole: string | null;
  selectedProjectEmployeeRoles?: IDropdownOption[];
  onChange: (role: IDropdownOption) => void;
  isDisabled?: boolean;
}

interface IState {
  projectEmployeeRoleOptions: IDropdownOption[];
  ownerIds: number[];
  managerIds: number[];
  memberIds: number[];
}

class ProjectEmployeeRoleDropdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      projectEmployeeRoleOptions: [],
      ownerIds: [],
      managerIds: [],
      memberIds: [],
    };
  }

  t(keyName: string) {
    return i18n.t('ProjectEmployeeRole.' + keyName);
  }

  async componentDidMount() {
    const projectEmployeeRoleOptions: IDropdownOption[] =
      enumValuesToDropdownOptions(
        Object.values(ProjectEmployeeRole),
        'ProjectEmployeeRole'
      );

    const ownerIds = (
      await getEmployeesWithPermissions(
        PERMISSION_URI.becomeProjectOwner.readWrite.uri,
        READWRITE
      )
    ).map((employee: IEmployee) => employee.id) as number[];
    const managerIds = (
      await getEmployeesWithPermissions(
        PERMISSION_URI.becomeProjectManager.readWrite.uri,
        READWRITE
      )
    ).map((employee: IEmployee) => employee.id) as number[];
    const memberIds = (
      await getEmployeesWithPermissions(
        PERMISSION_URI.assignedToProjects.readWrite.uri,
        READWRITE
      )
    ).map((employee: IEmployee) => employee.id) as number[];

    this.setState({
      projectEmployeeRoleOptions,
      ownerIds,
      managerIds,
      memberIds,
    });
  }

  handleRoleChange = (role: IDropdownOption) => {
    this.props.onChange(role);
  };

  /**
   * Method that takes the already selected roles then filters out the dropdown choices by
   * already selected roles and by the permissions of the employee chosen
   * @param selectedProjectEmployeeRoles
   * @returns Filtered dropdown options
   */
  filterOptions = (selectedProjectEmployeeRoles: IDropdownOption[]) => {
    // Filter out the already selected role
    let filteredRoleOptions = this.state.projectEmployeeRoleOptions.filter(
      (roleOption) =>
        !selectedProjectEmployeeRoles.some(
          (selectedRoleOption) => roleOption.value === selectedRoleOption.value
        )
    );

    // Filter out the role options by permissions
    const employeeId = this.props?.employeeId;
    if (employeeId) {
      if (!this.state.ownerIds.includes(employeeId)) {
        filteredRoleOptions = filteredRoleOptions.filter(
          (option) => !(option.value === ProjectEmployeeRole.OWNER)
        );
      }

      if (!this.state.managerIds.includes(employeeId)) {
        filteredRoleOptions = filteredRoleOptions.filter(
          (option) => !(option.value === ProjectEmployeeRole.MANAGER)
        );
      }

      if (!this.state.memberIds.includes(employeeId)) {
        filteredRoleOptions = filteredRoleOptions.filter(
          (option) => !(option.value === ProjectEmployeeRole.MEMBER)
        );
      }
    }
    return filteredRoleOptions;
  };

  render() {
    const selectedProjectEmployeeRoles =
      this.props?.selectedProjectEmployeeRoles;
    // If there's already a selected project employee role prop then filter out the choices.
    const projectEmployeeRoleOptions = this.filterOptions(
      selectedProjectEmployeeRoles ?? []
    );

    const dropdownValue: IDropdownOption | undefined =
      projectEmployeeRoleOptions.find(
        (role: IDropdownOption) => this.props.projectEmployeeRole === role.value
      );

    return (
      <div>
        <Select
          onChange={this.handleRoleChange}
          options={this.props?.employeeId ? projectEmployeeRoleOptions : []}
          value={dropdownValue}
          isDisabled={this.props.isDisabled}
          placeholder={this.t('selectRole')}
        />
      </div>
    );
  }
}

export default ProjectEmployeeRoleDropdown;
