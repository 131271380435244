import axios, { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { IObjectNameAndId } from '../../utils/types/commonTypes';
import { urlBuilder } from '../../utils/helpers/url';
import { Url } from './constants';

export const getAllSkills = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<IObjectNameAndId[]>>(
    axios.get(urlBuilder(Url.SKILL_OPTIONS, queryParameters))
  );
