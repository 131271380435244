import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import React from 'react';
import { Button, CardBody, Container, Label } from 'reactstrap';

import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { isEmpty } from '../../../utils/helpers/GenericHelper';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IError } from '../../../utils/types/commonTypes';
import { IContactPersonTopicListItem } from '../../../utils/types/modelTypes';
import ContactPersonTopicInput from './ContactPersonTopicInput';

interface IState {
  contactPersonTopics: IContactPersonTopicListItem[];
}

interface IProps extends IWithModalsProps {}

interface IContactPersonEntry {
  name: JSX.Element;
  deleteButton: JSX.Element;
}
/**
 * Class for showing the Contact Person Topics
 */
class ContactPersonTopicSettings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      contactPersonTopics: [],
    };
  }

  t(keyName: string) {
    return i18n.t(`ContactPersonTopicSettings.${keyName}`);
  }

  async componentDidMount() {
    const { modalErrorHandler } = this.props;
    await axios.employee
      .get('contact-person-topics-settings')
      .then((response: AxiosResponse<IContactPersonTopicListItem[]>) => {
        if (!isEmpty(response.data)) {
          const contactPersonTopics = response.data.sort((a, b) =>
            a.topic > b.topic ? 1 : -1
          );
          this.setState({ contactPersonTopics });
        }
      })
      .catch((error: IError) => {
        modalErrorHandler(this.t('failedToRetrieveContactPersonTopic'), error);
      });
  }

  /**
   * Prepares data for table
   * @param {*} contactPersonTopics
   * @returns tableData
   */
  prepareTableData = (contactPersonTopics: IContactPersonTopicListItem[]) => {
    const newTableData: IContactPersonEntry[] = [];
    if (!isEmpty(contactPersonTopics)) {
      contactPersonTopics.forEach(
        (contactPersonTopic: IContactPersonTopicListItem) => {
          const entry = {
            name: (
              <Button
                color="link"
                onClick={() =>
                  this.updateContactPersonTopic(contactPersonTopic)
                }
              >
                {contactPersonTopic?.topic}
              </Button>
            ),
            deleteButton: (
              <>
                {isEmpty(contactPersonTopic.contactPeople) && (
                  <Button
                    color="primary"
                    onClick={() =>
                      this.deleteContactPersonTopic(contactPersonTopic)
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                )}
              </>
            ),
          };
          newTableData.push(entry);
        }
      );
      return newTableData;
    } else {
      return [];
    }
  };

  /**
   * Handles updating upon clicking of edit button
   * @param {*} serviceConfiguration
   */
  updateContactPersonTopic = (
    contactPersonTopic: IContactPersonTopicListItem | null
  ) => {
    const { modalFormHandler, toggleModalForm } = this.props;
    modalFormHandler(
      contactPersonTopic
        ? this.t('editContactPersonTopic')
        : this.t('addContactPersonTopic'),
      <ContactPersonTopicInput
        contactPersonTopic={contactPersonTopic}
        onSave={this.onSave}
        onCancel={() => toggleModalForm()}
      />
    );
  };

  deleteContactPersonTopic(contactPersonTopic: IContactPersonTopicListItem) {
    const { modalDeleteHandler, modalErrorHandler } = this.props;
    const { contactPersonTopics } = this.state;
    modalDeleteHandler(
      this.t('deleteContactPersonTopic'),
      this.t('deleteMessage'),
      async () => {
        await axios.project
          .delete(`contact-person-topics-settings/${contactPersonTopic.id}`)
          .then(() => {
            const newList = contactPersonTopics?.filter(
              (x: IContactPersonTopicListItem) => x.id !== contactPersonTopic.id
            );

            this.setState({
              contactPersonTopics: newList,
            });
          })
          .catch((error) => {
            modalErrorHandler(
              this.t('failedToDeleteContactPersonTopic'),
              error
            );
          });
      }
    );
  }

  /**
   * Updates the state of Contact Person Topic upon saving of the new or edited Contact Person Topic
   * @param {*} contactPersonInput
   */
  onSave = (contactPersonInput: IContactPersonTopicListItem) => {
    let { contactPersonTopics: newList } = this.state;

    const index = newList.findIndex(
      (item) => item?.id === contactPersonInput?.id
    );
    if (index >= 0) {
      newList[index] = contactPersonInput;
    } else {
      newList.push(contactPersonInput);
    }
    newList = newList?.sort((a, b) => (a.topic > b.topic ? 1 : -1));

    this.setState({
      contactPersonTopics: newList,
    });
  };

  render() {
    const { contactPersonTopics } = this.state;
    const preparedColumns = [
      {
        type: 'component',
        header: this.t('contactPersonTopic'),
        accessor: 'name',
        show: 'true',
      },
      {
        type: 'component',
        header: this.t('delete'),
        accessor: 'deleteButton',
        show: 'true',
      },
    ];
    return (
      <Container fluid>
        <div>
          <Button
            color="primary"
            className="float-end"
            onClick={() => this.updateContactPersonTopic(null)}
          >
            <FontAwesomeIcon icon={faPlus} /> {this.t('add')}
          </Button>
        </div>
        <br />
        <CardBody>
          {!isEmpty(contactPersonTopics) ? (
            <DynamicTable
              data={this.prepareTableData(contactPersonTopics)}
              columns={preparedColumns}
            />
          ) : (
            <Label>{this.t('contactPersonTopicsEmpty')}</Label>
          )}
        </CardBody>
      </Container>
    );
  }
}

export default withModals(ContactPersonTopicSettings);
