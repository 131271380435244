import React, { ChangeEvent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  CardTitle,
  CardHeader,
  CardBody,
  Container,
  Card,
} from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import DynamicTable from '../../../components/tables/DynamicTable';
import { dataGeneration, DEFAULT_LOAD_TIMEOUT } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { generateTitle } from '../../../utils/helpers/icon';
import { RootState } from '../../../redux/store';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  deleteCustomerAccount,
  fetchCustomerAccountListItems,
  setHasMoreListItems,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  toggleFilter,
} from '../../../redux/customerAccountListSlice';
import { getNextSortType } from '../../../utils/helpers/table';
import {
  createEntryCustomerAccount,
  hasCreatePermissionCustomerAccount,
  translateAccountList,
} from './customerAccountListHelper';
import { SortBy, SortType } from '../../../utils/enums/sort';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import AddCustomerAccountModal from './AddCustomerAccountModal';
import Header from '../../../components/layout/Header';
import useMounted from '../../../hooks/useMounted';
import { ICustomerAccountFilter } from '../../../utils/types/stateTypes';
import { isEmpty } from '../../../utils/helpers/array';

interface IProps
  extends PropsFromRedux,
    RouteComponentProps,
    IWithModalsProps {}

/**
 * Class that generates the actual table for the CustomerAccountList
 */
const CustomerAccountList = ({
  // WithModals
  modalErrorHandler,
  modalDeleteHandler,
  modalOkHandler,
  modalFormHandler,
  toggleModalForm,
  // Redux State
  listItems,
  filters,
  showFilters,
  sortBy,
  sortType,
  hasMore,
  // Redux actions
  fetchCustomerAccountListItems,
  deleteCustomerAccount,
  toggleFilter,
  setHasMoreListItems,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  // Others
  location: { pathname },
}: IProps) => {
  // Check for component mount
  const isMounted = useMounted();

  /**
   * Handles filter changes
   * @param {*} event
   * @param {*} accessor
   */
  const handleFilterChange = (
    event: ChangeEvent<HTMLInputElement>,
    accessor: string
  ) => {
    setFilters({ ...filters, [accessor]: event?.target?.value ?? null });
  };

  const fetchCustomerAccountData = (isSortOrFilterFetchType: boolean) => {
    fetchCustomerAccountListItems(isSortOrFilterFetchType, (error) => {
      modalErrorHandler(dataGeneration, error);
    });
  };

  /**
   * Method that handles the sorting of data when pressing the sort button for some columns if available
   * @param {*} nextSortBy
   */
  const sortData = (nextSortBy: SortBy) => {
    const nextSortType = getNextSortType(sortType, nextSortBy, sortBy);

    setSortBy(nextSortType === SortType.DEFAULT ? SortBy.NAME : nextSortBy);
    setSortType(
      nextSortType === SortType.DEFAULT ? SortType.ASCENDING : nextSortType
    );
  };

  /**
   * Method that handles the deletion of a customer
   * @param {*} customerAccountId
   */
  const handleRemove = (customerAccountId: number) => {
    modalDeleteHandler(
      translateAccountList('deleteAccount'),
      translateAccountList('deleteAccountConfirm'),
      () =>
        deleteCustomerAccount(
          customerAccountId,
          () =>
            modalOkHandler(
              translateAccountList('deleteAccount'),
              translateAccountList('accountDeletedSuccessfully')
            ),
          (error) => {
            modalErrorHandler(
              translateAccountList('failedToRetrieveCustomerAccounts'),
              error
            );
          }
        )
    );
  };

  // Method that sets the modal used to add customer accounts to the modalForm
  // State variable, then calls toggleModalForm();
  const addNewAccount = () => {
    modalFormHandler(
      translateAccountList('addAccount'),
      <AddCustomerAccountModal onClose={toggleModalForm} />
    );
  };

  /**
   * Generates the button to delete an entry
   * @param id
   * @returns
   */
  const generateButton = (id: number) => (
    <div>
      <Button
        color="primary"
        onClick={() => {
          handleRemove(id);
        }}
      >
        {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
      </Button>
    </div>
  );

  const preparedColumns = [
    {
      type: 'data',
      header: translateAccountList('accountName'),
      accessor: 'name',
      show: 'true',
      filterValue: filters['name' as keyof ICustomerAccountFilter],
      filterFunc: handleFilterChange,
      sortFunc: sortData,
    },
    {
      type: 'component',
      header: translateAccountList('responsibles'),
      accessor: 'responsible',
      show: 'true',
      filterValue: filters['responsible' as keyof ICustomerAccountFilter],
      filterFunc: handleFilterChange,
    },
    {
      type: 'component',
      header: translateAccountList('menu'),
      show: 'true',
      accessor: 'menuComponent',
    },
  ];

  const tableData = listItems.map((account) =>
    createEntryCustomerAccount(account, generateButton)
  );

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);

      timer = setTimeout(() => {
        fetchCustomerAccountData(true);
      }, DEFAULT_LOAD_TIMEOUT);
    }

    return () => clearTimeout(timer);
  }, [filters]);

  useEffect(() => {
    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);
      fetchCustomerAccountData(true);
    }
  }, [sortBy, sortType]);

  useEffect(() => {
    if (isEmpty(listItems)) {
      fetchCustomerAccountData(false);
    }
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{translateAccountList('accounts')}</HeaderTitle>
        {generateBreadcrumb(pathname, translateAccountList('customer'))}
      </Header>
      <Card>
        <CardHeader>
          <div
            className="card-actions float-end"
            style={{ paddingRight: '5px' }}
          >
            <Button
              color="primary"
              className="fontAwesomeIconAsButton"
              onClick={() => toggleFilter()}
            >
              <FontAwesomeIcon icon={faFilter} />
            </Button>{' '}
            {hasCreatePermissionCustomerAccount() && (
              <Button color="primary" onClick={() => addNewAccount()}>
                {generateTitle(
                  BUTTON_TITLE_ENUM.ADD.code,
                  translateAccountList('add')
                )}
              </Button>
            )}
          </div>
          <CardTitle className="mb-0">
            <h1>{translateAccountList('accountsList')}</h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <DynamicTable
            data={tableData}
            columns={preparedColumns}
            fetchData={() => fetchCustomerAccountData(false)}
            hasMoreData={hasMore}
            infiniteScroll
            sortType={sortType}
            sortBy={sortBy}
            enableFilterFunction
            showFilters={showFilters}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  account: store.account,
  listItems: store.customerAccountList.listItems,
  filters: store.customerAccountList.filters,
  showFilters: store.customerAccountList.showFilters,
  hasMore: store.customerAccountList.hasMore,
  sortBy: store.customerAccountList.sortBy,
  sortType: store.customerAccountList.sortType,
});

const mapDispatchToProps = {
  fetchCustomerAccountListItems,
  deleteCustomerAccount,
  // Redux actions
  setHasMoreListItems,
  toggleFilter,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(CustomerAccountList)));
