import { trackPromise } from 'react-promise-tracker';
import axios, { AxiosResponse } from 'axios';

import { IActivity, IActivityListItem } from '../../utils/types/modelTypes';
import { urlBuilder } from '../../utils/helpers/url';
import { Url } from './constants';

export const postActivity = (activity: IActivity) =>
  trackPromise<AxiosResponse<IActivity>>(
    axios.post(urlBuilder(Url.ACTIVITY, {}), activity)
  );

export const saveActivity = (activity: IActivity) =>
  trackPromise<AxiosResponse<IActivityListItem>>(
    axios.post(urlBuilder(Url.ACTIVITY, {}), activity)
  );

export const updateActivity = (activity: IActivity) =>
  trackPromise<AxiosResponse<IActivityListItem>>(
    axios.put(urlBuilder(Url.ACTIVITY, {}), activity)
  );

export const deleteActivity = (id: number) =>
  trackPromise<AxiosResponse<void>>(
    axios.delete(urlBuilder(`${Url.ACTIVITY}/${id}`, {}))
  );
