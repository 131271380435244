import React, {
  createContext,
  Dispatch,
  SetStateAction,
  ReactNode,
  useState,
} from 'react';

import {
  IEmployeeDetail,
  IEmployeeProjectListItem,
} from '../../../utils/types/responseTypes';
import { IDropdownOption, IObjectNameAndId } from '../../../utils/types/commonTypes';
import { BLANK_OBJECT_NAME_ID } from '../../../utils/constants';

/**
 * Context for the employee details. used to store the changes locally with minimal API calls
 */
type EmployeeDetailsContextType = {
  // Employee Detail
  employeeDetail: IEmployeeDetail;
  setEmployeeDetail: Dispatch<SetStateAction<IEmployeeDetail>>;

  // Dropdowns
  involvedEmployees: IDropdownOption<number>[];
  setInvolvedEmployees: Dispatch<SetStateAction<IDropdownOption<number>[]>>;
};

const defaultEmployeeDetail = {
  responsibleEmployee: BLANK_OBJECT_NAME_ID as IObjectNameAndId,
  mentorEmployee: BLANK_OBJECT_NAME_ID as IObjectNameAndId,
  hrResponsible: BLANK_OBJECT_NAME_ID as IObjectNameAndId,
  employeeProjectsActive: [] as IEmployeeProjectListItem[],
  employeeProjectsAssociated: [] as IEmployeeProjectListItem[],
} as IEmployeeDetail;

export const EmployeeDetailsContext = createContext(
  {} as EmployeeDetailsContextType
);

interface IProps {
  children: ReactNode;
}

const EmployeeDetailsProvider = ({ children }: IProps) => {
  const [employeeDetail, setEmployeeDetail] = useState(defaultEmployeeDetail);

  // Dropdowns
  const [involvedEmployees, setInvolvedEmployees] = useState(
    [] as IDropdownOption<number>[]
  );

  return (
    <EmployeeDetailsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        // Employee Detail
        employeeDetail,
        setEmployeeDetail,
        // Dropdowns
        involvedEmployees,
        setInvolvedEmployees,
      }}
    >
      {children}
    </EmployeeDetailsContext.Provider>
  );
};

export default EmployeeDetailsProvider;
