import React, { ChangeEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  Container,
  Input,
  FormGroup,
  FormFeedback,
} from 'reactstrap';

import axios from '../../../services/axios/axios';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import InputFormLabel from '../../../components/form/InputFormLabel';
import { SKILLS_TYPE } from '../../../utils/enums/skill';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import i18n from '../../../i18n';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { ISkill } from '../../../utils/types/modelTypes';
import { sortOptionsByValue } from '../../../utils/helpers/dropdown';

/**
 * Edited by: Reimon Angelo Tito
 * Edits made: Removed Description being required
 * Last edited: 14/08/2021
 *
 * Edited by: Reimon Angelo Tito
 * Edits made: Fixed CRM-Test issue where skills cannot be updated without putting a description
 * Last edited: 19/08/2021
 *
 * Edited by: Joseph Ortega
 * Edits made: Fix the bug the deletion of skill. Handle the update of skill and already assigned skillSet that uses this
 * deleted/retrieved skill
 */

interface IProps extends IWithModalsProps, RouteComponentProps<any> {
  onSave: () => void;
  onCancel: () => void;
}

interface IState {
  isUpdate: boolean;
  id: number | undefined;
  skillName: string;
  description: string;
  type: string;
  wikipediaLink: string;
  skill: ISkill | null;
  skills: ISkill[];
}

const skillTypes = Object.values(SKILLS_TYPE).map((skills) => ({
  value: skills.code,
  label: skills.name,
}));

class SkillInsertOrUpdate extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isUpdate: false,
      id: undefined,
      skill: null,
      skillName: '',
      skills: [],
      description: '',
      type: '',
      wikipediaLink: '',
    };
  }

  t(keyName: string) {
    return i18n.t('SkillInsertOrUpdate.' + keyName);
  }

  componentDidMount() {
    const { match, modalErrorHandler } = this.props;

    axios.employee
      .get('skills')
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({
            skills: response.data,
          });
        }
      })
      .catch((error) => {
        modalErrorHandler(this.t('skill'), error);
      });
    if (match.params.id) {
      axios.employee
        .get(`skills/${match.params.id}`)
        .then((response) => {
          this.setState({
            skill: response.data,
            skillName: response.data.skill,
            description: response.data.description,
            type: response.data.type,
            wikipediaLink: response.data.wikipediaLink,
          });
        })
        .catch((error) => {
          modalErrorHandler(this.t('skill'), error);
        });
      this.setState({
        id: match.params.id,
        isUpdate: true,
      });
    }
  }

  handleSubmit = async (event: MouseEvent) => {
    const { modalErrorHandler, modalConfirmHandler, modalOkHandler, onSave } =
      this.props;
    const { skillName: stateSkillName, id, isUpdate } = this.state;

    event.preventDefault();
    // Get all latest list of skills.

    const skillDetails = {
      skill: stateSkillName.trim(),
    };

    const retrievedSkills: ISkill[] = await axios.employee
      .post('skills/skills-by-name', skillDetails)
      .then((response) => {
        this.setState({
          skills: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        modalErrorHandler(this.t('skill'), error);
      });

    // Get the skill having the the same skill name
    const skillName = stateSkillName.trim();
    let skill = null;
    if (retrievedSkills.length > 0) {
      skill = retrievedSkills.find(
        (data) =>
          data.skill === skillName && data.id !== parseInt(id?.toString() ?? '')
      );
    }

    if (skill) {
      if (skill?.valid_until !== null) {
        const skillEntry = {
          id: skill.id,
          skill: skill.skill,
          description: skill.description,
          type: skill.type,
          wikipediaLink: skill.wikipediaLink,
          valid_until: null,
        };
        modalConfirmHandler(
          this.t('notice'),
          this.t('retrieveDeletedSkill'),
          async () => {
            await axios.employee
              .put('skills', skillEntry)
              .then(() => {
                if (onSave) {
                  onSave();
                }
              })
              .catch((error) => {
                modalErrorHandler(this.t('skill'), error);
              });
          }
        );
      } else {
        modalOkHandler('', this.t('nameAlreadyExist'));
      }
    } else if (isUpdate) {
      this.updateSkill();
    } else {
      this.insertSkill();
    }
  };

  updateSkill() {
    const { history, modalErrorHandler } = this.props;
    const { id, skillName, description, type, wikipediaLink } = this.state;

    const skillEntry = {
      id,
      skill: skillName.trim(),
      description: description ? description.trim() : null,
      type,
      wikipediaLink,
    };

    axios.employee
      .put('skills', skillEntry)
      .then((response) => {
        history.push({
          pathname: '/settings/skills',
          state: {
            successMessage: `${this.t('updatedSkill')}${response.data.id}`,
          },
        });
      })
      .catch((error) => {
        modalErrorHandler(this.t('skill'), error);
      });
  }

  insertSkill() {
    const { history, onSave, modalErrorHandler } = this.props;
    const { id, skillName, description, type, wikipediaLink } = this.state;

    const skillEntry = {
      id,
      skill: skillName.trim(),
      description: description ? description.trim() : null,
      type,
      wikipediaLink,
    };

    axios.employee
      .post('skills', skillEntry)
      .then(() => {
        if (onSave) {
          onSave();
        } else {
          history.push({
            state: {
              successMessage: this.t('addedNewSkill'),
            },
          });
        }
      })
      .catch((error) => {
        modalErrorHandler(this.t('skill'), error);
      });
  }

  handleSkillNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ skillName: e.target.value });
  };

  handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ description: e.target.value });
  };

  handleTypeChange = (e: { value: string; label: string }) => {
    this.setState({ type: e.value });
  };

  handleLinkChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ wikipediaLink: e.target.value });
  };

  render() {
    const { location, onCancel } = this.props;
    const { isUpdate, skillName, description, type, wikipediaLink } =
      this.state;

    return (
      <Container fluid>
        {isUpdate ? (
          <Header>
            <HeaderTitle>{this.t('skillDetails')}</HeaderTitle>
            {generateBreadcrumb(location.pathname, this.t('settings'))}
          </Header>
        ) : null}
        <Card>
          <CardBody>
            <FormGroup>
              <InputFormLabel isRequired={true} text={this.t('skill')} />
              <Input
                onChange={this.handleSkillNameChange}
                value={skillName}
                name="skill"
                type="text"
                invalid={skillName === undefined}
              />
              <FormFeedback>{this.t('skillRequired')}</FormFeedback>
            </FormGroup>
            <br />
            <FormGroup>
              <InputFormLabel isRequired={false} text={this.t('description')} />
              <Input
                onChange={this.handleDescriptionChange}
                value={description}
                name="description"
                type="textarea"
                rows="5"
              />
            </FormGroup>
            <br />
            <InputFormLabel isRequired={true} text="Skill Type" />
            <Select
              style={{ fontSize: 16 }}
              options={sortOptionsByValue(skillTypes)}
              onChange={this.handleTypeChange}
              value={skillTypes.find(
                (skill) => skill.value?.toString() === type?.toString()
              )}
            />
            <br />
            <InputFormLabel
              isRequired={false}
              text={this.t('wikipediaDefinition')}
            />
            <Input
              bsSize="lg"
              type="text"
              value={wikipediaLink}
              onChange={this.handleLinkChange}
            />
            <br />
            <Button
              disabled={!type || !skillName}
              color="primary"
              onClick={(event: MouseEvent) => this.handleSubmit(event)}
            >
              {this.t('confirm')}
            </Button>{' '}
            {onCancel ? (
              <Button color="primary" onClick={() => onCancel()}>
                {this.t('cancel')}
              </Button>
            ) : null}
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default withRouter(withModals(SkillInsertOrUpdate));
