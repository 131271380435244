import React, { Dispatch, SetStateAction, useState } from 'react';

import { IResourceListItem } from '../../../utils/types/responseTypes';
import { IObjectTimespan, ResourceViewMode } from './resourceOverviewHelper';
import EmployeeProjectsRowView from '../UtilizationOverview/EmployeeProjectsRowView';
import ProjectWorkloadRowView from './ProjectWorkloadRowView';
import {
  IObjectProjectResource,
  IObjectProjectUtilization,
} from '../../../utils/types/commonTypes';

interface IProps {
  modeView: ResourceViewMode;
  employeeResource: IResourceListItem;
  timespan: IObjectTimespan[];
  setResources: Dispatch<SetStateAction<IResourceListItem[]>>;
  fetchResources: (queryParameters: { [key: string]: string }) => Promise<void>;
}

const ResourceOverviewTableRow = ({
  modeView,
  employeeResource,
  timespan,
  setResources,
  fetchResources,
}: IProps) => {
  const { employee, projectResources } = employeeResource;

  const [showProjects, setShowProjects] = useState<boolean>(false);

  const handleUpdateResourceWorkloadStatus = (
    updatedProjectResource: IObjectProjectResource | IObjectProjectUtilization
  ) => {
    const updatedData =
      'weeklyWorkloadUtilizations' in updatedProjectResource
        ? updatedProjectResource
        : ({} as IObjectProjectResource);
    // Updated EmployeeUtilization
    const updatedEmployeeResource = {
      ...employeeResource,
      projectResources: [
        ...employeeResource.projectResources.map((resource) =>
          resource.id === updatedProjectResource.id ? updatedData : resource
        ),
      ],
    };
    // Update the list of states
    setResources((resources) => [
      ...resources.map((resource) =>
        resource.employee.id === updatedEmployeeResource.employee.id
          ? updatedEmployeeResource
          : resource
      ),
    ]);
  };

  return (
    <>
      <tr>
        <EmployeeProjectsRowView
          modeView={modeView}
          employee={employee}
          projectUtilization={projectResources}
          headerRange={timespan}
          showProjects={showProjects}
          setShowProjects={setShowProjects}
          fetchResources={fetchResources}
        />
      </tr>
      {showProjects &&
        projectResources?.map((resources, idx) => (
          <ProjectWorkloadRowView
            key={idx}
            projectWorkload={resources}
            updateWorkloadStatus={handleUpdateResourceWorkloadStatus}
            isWeekly={modeView === ResourceViewMode.WEEKLY}
          />
        ))}
    </>
  );
};

export default ResourceOverviewTableRow;
