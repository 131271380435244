import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import axios from '../../../services/axios/axios';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { dataGeneration } from '../../../utils/constants';
import { errorHandler } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';
import EmployeeDetailCard from './EmployeeDetailCard';
import EmployeeWorkloadCard from './EmployeeWorkloadCard';

/**
 * This class is the main view of the employee detail page.
 * It calls the Employee Detail Card, Employee Rate Card and the
 * Employee Workload Card.
 */
class EmployeeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      showModalError: false,
      employee: {},
      message: {},
    };
  }

  t(keyName) {
    return i18n.t('EmployeeDetail.' + keyName);
  }

  async componentDidMount() {
    var employeeId = await axios.project
      .get(`project-employees/${this.props.match.params.projectEmployeeId}`)
      .then((response) => {
        if (response.data instanceof Object) {
          this.setState({
            project: response.data.project,
          });
          return response.data.employeeId;
        }
        return;
      })
      .catch((error) => {
        this.message = errorHandler(
          dataGeneration,
          error,
          this.t('projectEmployeeDetails')
        );
        this.ToggleModalError();
      });
    if (employeeId) {
      await axios.employee
        .get('employees/' + employeeId)
        .then((response) => {
          if (response.data instanceof Object) {
            this.setState({
              employee: response.data,
            });
          }
        })
        .catch((error) => {
          this.message = errorHandler(
            dataGeneration,
            error,
            this.t('employeeDetails')
          );
          this.ToggleModalError();
        });
    }
  }

  ToggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  render() {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{this.t('projectDetails')}</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="#">{this.t('project')}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/projects/overview">{this.t('projects')}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link
                to={`/projects/overview/project-details/${this.state.project.id}`}
              >
                {this.state.project.title}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{this.state.employee.name}</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <Row>
          {/* <Col>
            <EmployeeDetailCard
              projectEmployeeId={this.props.match.params.projectEmployeeId}
            />
          </Col>
          <Col>
            <EmployeeRateCard
              projectEmployeeId={this.props.match.params.projectEmployeeId}
            />
          </Col> */}
          <Col>
            <EmployeeDetailCard
              projectEmployeeId={this.props.match.params.projectEmployeeId}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EmployeeWorkloadCard
              projectEmployeeId={this.props.match.params.projectEmployeeId}
            />
          </Col>
        </Row>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.ToggleModalError}
          mainError={this.message?.mainError}
          errorReason={this.message?.errorReason}
          errorResponse={this.message?.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </Container>
    );
  }
}

export default EmployeeDetail;
