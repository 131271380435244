import i18n from '../../i18n';

/**
 * Contact person function role specific to campaign manager
 */
export enum ContactPersonFunctionCampaign {
  BG = 'BUDGET_RESPONSIBLE',
  TE = 'TECHNICAL_EXPERT',
  TM = 'TEAM_MEMBER',
  EK = 'PURCHASE',
  SO = 'OTHER',
  CO = 'POTENTIAL_COLLABORATOR',
  ND = 'NOT_DEFINED',
}
/**
 * Contact person function role
 */
export enum ContactPersonFunction {
  BG = 'BG',
  TE = 'TE',
  TM = 'TM',
  EK = 'EK',
  SO = 'SO',
  CO = 'CO',
  ND = 'ND',
}

/**
 * @deprecated Use ContactPersonFunction
 */
export const CONTACT_PERSON_FUNCTION_ENUM = {
  budgetResponsible: {
    label: i18n.t('FunctionEnum.BG'),
    value: 'BG',
  },
  technicalExpert: {
    label: i18n.t('FunctionEnum.TE'),
    value: 'TE',
  },
  teamMember: {
    label: i18n.t('FunctionEnum.TM'),
    value: 'TM',
  },
  purchase: {
    label: i18n.t('FunctionEnum.EK'),
    value: 'EK',
  },
  other: {
    label: i18n.t('FunctionEnum.SO'),
    value: 'SO',
  },
  potentialCollaborator: {
    label: i18n.t('FunctionEnum.CO'),
    value: 'CO',
  },
  notDefined: {
    label: i18n.t('FunctionEnum.ND'),
    value: 'ND',
  },
};

export const GENDER_ENUM = {
  female: i18n.t('Gender.female'),
  male: i18n.t('Gender.male'),
  unknown: i18n.t('Gender.unknown'),
};

export const CONTACT_PERSON_ATTITUDE = {
  neutral: {
    value: '0',
    label: 'Neutral',
    color: 'blue',
  },
  positive: {
    value: '1',
    label: 'Positive',
    color: 'green',
  },
  veryPositive: {
    value: '2',
    label: 'Very Positive',
    color: 'green',
  },
  vipCollaborator: {
    value: '3',
    label: 'VIP Collaborator',
    color: 'green',
  },
  negative: {
    value: '-1',
    label: 'Negative',
    color: 'orange',
  },
  risk: {
    value: '-2',
    label: 'Project Risk when Involved',
    color: 'red',
  },
};

export const CONTACT_PERSON_TARGET = {
  targetForAcquisition: {
    value: true,
    label: i18n.t('ContactPersonTarget.targetForAcquisition'),
  },
  noTargetForAcquisition: {
    value: false,
    label: i18n.t('ContactPersonTarget.noTargetForAcquisition'),
  },
};

/**
 * Contact information status
 */
export enum ContactInformationStatus {
  DELIVERABLE = 'DELIVERABLE',
  UNDELIVERABLE = 'UNDELIVERABLE',
  RISKY = 'RISKY',
  UNKNOWN = 'UNKNOWN',
  GUESSED = 'GUESSED',
}

/**
 * @deprecated Use ContactInformationStatus
 */
export const CONTACT_INFORMATION_STATUS_ENUM = {
  deliverable: {
    name: i18n.t('CampaignManager.DELIVERABLE'),
    code: 'DELIVERABLE',
  },
  undeliverable: {
    name: i18n.t('CampaignManager.UNDELIVERABLE'),
    code: 'UNDELIVERABLE',
  },
  risky: {
    name: i18n.t('CampaignManager.RISKY'),
    code: 'RISKY',
  },
  unknown: {
    name: i18n.t('CampaignManager.UNKNOWN'),
    code: 'UNKNOWN',
  },
  guessed: {
    name: i18n.t('CampaignManager.GUESSED'),
    code: 'GUESSED',
  },
};

export enum ContactPersonAssignmentState {
  one = '01',
  two = '02',
  three = '03',
  four = '04',
  five = '05',
}
