import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Collapse, Nav, Navbar } from 'reactstrap';
import Cookies from 'js-cookie';

import axios from '../../services/axios/axios';
import {
  DEFAULT_COUNTRY,
  DEFAULT_LANGUAGE,
  GERMANY_COUNTRY,
} from '../../utils/constants';
import { toggleSidebar } from '../../redux/sidebarSlice';

function updateEmployeeConfiguration(employeeConfiguration: {
  language: string;
  country: string;
}) {
  axios.employee
    .put('employee-configuration', employeeConfiguration)
    .then(() => {
      // Changes the language in the i18n file
      Cookies.set('language', employeeConfiguration.language);
      window.location.reload();
    })
    .catch((error) => {
      // eslint-disable-next-line no-alert
      alert(error);
    });
}

// Changes the language based on the icon clicked
function changeLanguage(language: string) {
  let employeeConfiguration = null;
  switch (language) {
    case 'de':
      employeeConfiguration = {
        language,
        country: GERMANY_COUNTRY,
      };
      break;
    default:
      employeeConfiguration = {
        language: DEFAULT_LANGUAGE,
        country: DEFAULT_COUNTRY,
      };
      break;
  }

  updateEmployeeConfiguration(employeeConfiguration);
}

const NavbarLanguage = (
  <Collapse navbar>
    <Nav className="ms-auto" navbar>
      <Button
        color="primary"
        style={{ margin: '2px' }}
        onClick={() => changeLanguage('en')}
      >
        EN
      </Button>
      <span> </span>
      <Button
        color="primary"
        style={{ margin: '2px' }}
        onClick={() => changeLanguage('de')}
      >
        DE
      </Button>
    </Nav>
  </Collapse>
);

interface IProps extends PropsFromRedux {}

const NavbarComponent: React.FC<IProps> = ({ toggleSidebar }) => (
  <Navbar expand className="navbar-theme">
    <>
      <span
        className="sidebar-toggle d-flex me-2"
        onClick={() => toggleSidebar()}
      >
        <i className="hamburger align-self-center" />
      </span>
      <Collapse navbar>
        {/* <h1
            style={{
              color: "white",
              fontSize: "400%",
              marginLeft: "15px",
            }}
          >
            PIET
          </h1> */}
        <Nav className="ms-auto" navbar>
          {NavbarLanguage}
          {/* 
            NOTE: Comment out for now to disable notfication, until the notification service features and tasks related to it has been done
            Reason for this change was described on this ticket: https://dev.azure.com/invensitygmbh/ITW-Schindler/_workitems/edit/3287
            */}
          {/* <NotificationNavBar /> */}
        </Nav>
      </Collapse>
    </>
  </Navbar>
);

const mapDispatchToProps = {
  toggleSidebar,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NavbarComponent);
