import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError, AxiosResponse } from 'axios';
import React, { ChangeEvent } from 'react';
import { Button, Card, CardHeader, Col, Input, Row, Table } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';

import axios from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import {
  dataGeneration,
  DEFAULT_LOAD_TIMEOUT,
  styleWidth30Percent,
} from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import {
  PROJECT_STATE_ENUM,
  PROJECT_TYPES_ENUM,
} from '../../../utils/enums/project';
import {
  errorHandler,
  isEmpty,
  isNullOrUndefinedOrEmpty,
} from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import { IErrorMessage } from '../../../utils/types/commonTypes';
import {
  IContactPerson,
  ICustomer,
  ICustomerSite,
  IDepartment,
  IProject,
  IResponsibleContactPerson,
  IUserToDoActivity,
} from '../../../utils/types/modelTypes';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import CustomerDropdown from '../../../components/dropdowns/CustomerDropdownOld';
import CustomerSitesDropdown from '../../../components/dropdowns/CustomerSitesDropdown';
import DepartmentDropdown from '../../../components/dropdowns/DepartmentDropdown';
import ContactPersonModal from '../../../components/form/ContactPersonModal';
import { IContactPersonDetail } from '../../../utils/types/responseTypes';

interface IProps {
  lockInputs: boolean;
  project: IProject;
  projectOriginal: IProject;
  responsibleContactPersons: IResponsibleContactPerson[];
  projectActivities: IUserToDoActivity[];
  onChange: (
    project: IProject,
    responsibleContactPersons: IResponsibleContactPerson[]
  ) => void;
  forcedUpdate: boolean;
  onChangeDepartment?: (selectedDepartment: IDepartment) => void;
  isProjectInternal?: boolean;
}

interface IState {
  modalForm: any;
  showModalForm: boolean;
  showModalError: boolean;
  updateToggle: boolean;
  lockInputs: boolean;
  customer: ICustomer;
  customerSite: ICustomerSite;
}

/**
 * Display General Information of the Project Option \
 * Variable Props:
 *  - project
 *  - responsibleContactPersons
 * Function Props:
 *  - onChange
 */
class ProjectOptionGeneralInfoCard extends React.Component<IProps, IState> {
  modalTitle: JSX.Element | string = '';

  fetchTimeOut: NodeJS.Timeout | number;

  errorMessage = {} as IErrorMessage;

  constructor(props: IProps) {
    super(props);
    this.state = {
      modalForm: null,
      showModalForm: false,
      showModalError: false,
      updateToggle: false,
      lockInputs: this.props?.lockInputs ?? false,
      customer: {} as ICustomer,
      customerSite: {} as ICustomerSite,
    };

    this.fetchTimeOut = 0;
  }

  t(keyName: string) {
    return i18n.t(`ProjectOptionOverview.${keyName}`);
  }

  handleUpdateToggleChange = () => {
    this.setState({ updateToggle: !this.state.updateToggle });
  };

  toggleModalForm = () => {
    this.setState({
      showModalForm: !this.state.showModalForm,
    });
  };

  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  componentDidMount() {
    const project: IProject = this.props.project
      ? { ...this.props.project }
      : ({} as IProject);
    project.validTitle = this.props.project.title === null ? null : true;
    this.onChange(project, this.props.responsibleContactPersons);

    // Get customer
    if (project.customerId > 0) {
      axios.sales
        .get(`customers/${project.customerId}`)
        .then((response) => {
          this.setState({ customer: response.data });
        })
        .catch((error: AxiosError) => {
          this.errorMessage = errorHandler(
            dataGeneration,
            error,
            this.t('customerDetailsRetrieveFailed')
          );
          this.toggleModalError();
        });
    }
    // Get customer Site
    if (!isNullOrUndefinedOrEmpty(project?.customerSiteId)) {
      axios.sales
        .get(`customer-sites/${project.customerSiteId}`)
        .then((response) => {
          this.setState({ customerSite: response.data });
        })
        .catch((error: AxiosError) => {
          this.errorMessage = errorHandler(
            dataGeneration,
            error,
            this.t('failedToRetrieveSites')
          );
          this.toggleModalError();
        });
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.forcedUpdate !== this.props.forcedUpdate) {
      this.setState({ lockInputs: this.props?.lockInputs });
    }
    if (prevProps !== this.props) {
      this.render();
    }
  }

  /**
   * Update the parent component for the changes in data
   * @param {*} project
   * @param {*} responsibleContactPersons
   */
  onChange = (
    project: IProject,
    responsibleContactPersons: IResponsibleContactPerson[]
  ) => {
    if (this.props.onChange) {
      this.props.onChange(project, responsibleContactPersons);
    }
  };

  /**
   * Handles the changes made to the Name field
   * @param {*} event
   */
  handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const newTitle = event.target.value.trim() ? event.target.value : '';
    const project: IProject = this.props.project ?? {};
    project.title = newTitle;
    project.validTitle = null;
    this.onChange(project, this.props.responsibleContactPersons);

    if (this.fetchTimeOut) {
      clearTimeout(this.fetchTimeOut);
    }

    // Checks if the new title is even valid
    if (newTitle === null || newTitle === undefined) {
      project.validTitle = false;
      this.onChange(project, this.props.responsibleContactPersons);
      return;
    }
    // If the new title is the same as the original, then the title is considered valid, if not, go check
    if (this.props.projectOriginal.title === newTitle) {
      project.validTitle = true;
      this.onChange(project, this.props.responsibleContactPersons);
      return;
    }
    if (this.fetchTimeOut) {
      clearTimeout(this.fetchTimeOut);
    }
    // If Valid and not the same, it then checks if the new title is valid, time out added to ensure it does not call the API on every keystroke
    const voidCall = () =>
      void (async () => {
        await axios.project
          .get(`projects/count?title.equals=${newTitle}`)
          .then((countResponse: AxiosResponse<number>) => {
            project.validTitle = countResponse.data === 0;
            this.onChange(project, this.props.responsibleContactPersons);
          });
      })();
    this.fetchTimeOut = setTimeout(voidCall, DEFAULT_LOAD_TIMEOUT);
  };

  /**
   * Handles the change in customer
   * @param {*} customer
   */
  handleCustomerChange = (customer: ICustomer) => {
    const project: IProject = this.props.project
      ? { ...this.props.project }
      : ({} as IProject);
    const responsibleContactPerson: IResponsibleContactPerson = {
      objectId: this.props.project?.id ?? null,
      objectType: OBJECT_TYPE_ENUM.project.code,
      contactPerson: {} as IContactPerson,
    };
    if (customer?.id) {
      project.customerId = customer.id;
      project.customer = customer;
    }

    project.customerSiteId = this.state.customerSite?.id;

    this.setState({
      customer,
    });
    this.onChange(project, [responsibleContactPerson]);
  };

  /**
   * Handles the change in site
   * @param {*} customerSite
   */
  handleCustomerSiteChange = (customerSite: ICustomerSite) => {
    const project = this.props.project
      ? { ...this.props.project }
      : ({} as IProject);
    project.customerSiteId = customerSite?.id;
    if (customerSite?.customer?.id) {
      project.customerId = customerSite?.customer?.id;
    }
    const responsibleContactPerson: IResponsibleContactPerson = {
      objectId: this.props.project?.id ?? null,
      objectType: OBJECT_TYPE_ENUM.project.code,
      contactPerson: {} as IContactPerson,
    };
    this.setState({
      customerSite,
      customer: customerSite.customer as ICustomer,
    });
    this.onChange(project, [responsibleContactPerson]);
  };

  /**
   * Handles the change in responsible contact person
   * @param {*} responsibleContactPersons
   */
  handleResponsibleContactPersonChange = (
    responsibleContactPersons: IResponsibleContactPerson[]
  ) => {
    const project = this.props.project
      ? { ...this.props.project }
      : ({} as IProject);
    if (!isEmpty(responsibleContactPersons)) {
      project.customerId =
        responsibleContactPersons[0]?.contactPerson?.customer?.id ?? 0;
      project.customerSiteId =
        responsibleContactPersons[0]?.contactPerson?.customerSite?.id ?? 0;
    }
    this.onChange(project, responsibleContactPersons);
  };

  /**
   * Handles the adding of an existing contact person
   */
  addExistingContactPerson = () => {
    let { responsibleContactPersons } = this.props;
    const responsibleContactPerson: IResponsibleContactPerson = {
      objectId: this.props.project?.id ?? null,
      objectType: OBJECT_TYPE_ENUM.project.code,
      contactPerson: {} as IContactPerson,
    };
    responsibleContactPersons = [
      ...responsibleContactPersons,
      responsibleContactPerson,
    ];
    this.onChange(this.props.project, responsibleContactPersons);
  };

  /**
   * Handles the addition of a new contact person
   */
  addNewContactPerson = () => {
    const { customer, customerSite } = this.state;

    this.modalTitle = generateTitle(
      BUTTON_TITLE_ENUM.INFORMATION.code,
      this.t('addContactPerson')
    );

    this.setState({
      modalForm: (
        <ContactPersonModal
          onSave={(contactPersonDetail: IContactPersonDetail) =>
            this.afterAddingNewContactPerson(contactPersonDetail)
          }
          onCancel={this.toggleModalForm}
          disableCustomerAndCustomerSite={!!customerSite}
          customerId={customer.id ?? 0}
          customerSiteId={customerSite.id ?? 0}
        />
      ),
    });
    this.toggleModalForm();
  };

  /**
   * Handles what happens after a new contact person is added
   * @param {*} contactPerson
   */
  afterAddingNewContactPerson = (contactPerson: any) => {
    let { responsibleContactPersons } = this.props;
    const newContactCustomerId = contactPerson?.customer?.id ?? null;
    const newContactCustomerSiteId = contactPerson?.customerSite?.id ?? null;
    const selectedCustomerId = this.props?.project?.customerId ?? null;
    const selectedCustomerSiteId = this.props?.project?.customerSiteId ?? null;

    // Check if the currently selected Customer and Site values
    // Match the customer and site of the newly created contact person
    if (
      newContactCustomerId === selectedCustomerId &&
      newContactCustomerSiteId === selectedCustomerSiteId
    ) {
      // Check the last object in the responsibleContactPersons array
      const lastResponsibleContactPerson: IResponsibleContactPerson =
        responsibleContactPersons[responsibleContactPersons?.length - 1] ??
        ({} as IResponsibleContactPerson);
      if (
        lastResponsibleContactPerson &&
        lastResponsibleContactPerson?.contactPerson
      ) {
        // If the last entry has a contact person, add a new object to the array
        const responsibleContactPerson = {
          contactPerson,
          objectId: this.props.project?.id ?? null,
          objectType: OBJECT_TYPE_ENUM.project.code,
        };
        responsibleContactPersons = [
          ...responsibleContactPersons,
          responsibleContactPerson,
        ];
      } else {
        // If the last object has a null or undefined contactPerson value, that means, in the UI,
        // That object's dropdown has no contact person selected yet
        lastResponsibleContactPerson.contactPerson = contactPerson;
        responsibleContactPersons[responsibleContactPersons?.length - 1] =
          lastResponsibleContactPerson;
      }

      this.onChange(this.props.project, responsibleContactPersons);

      // Update toggle so that the options in the Contact Person dropdown is updated to account for the new
      this.handleUpdateToggleChange();
    }
    this.toggleModalForm();
  };

  /**
   * Checks if the CustomerDropdown should be disabled
   * @param project
   * @returns true or false
   */
  isCustomerDropdownDisabled = (project: IProject) => {
    if (project.projectType === PROJECT_TYPES_ENUM.investmentProject.code) {
      return this.props.lockInputs;
    }

    if (project.projectType === PROJECT_TYPES_ENUM.internalProject.code) {
      return false;
    }

    return (
      project.state !== PROJECT_STATE_ENUM.vague.code || this.props.lockInputs
    );
  };

  /**
   * Handles the creation of projectId based on the selected department
   * @param selectedDepartment
   */
  handleDepartmentChange = (selectedDepartment: IDepartment[]) => {
    if (!isEmpty(selectedDepartment)) {
      if (this.props.onChangeDepartment && selectedDepartment[0]) {
        this.props.onChangeDepartment(selectedDepartment[0]);
      }
    }
  };

  render() {
    const { project } = this.props;
    const validTitle =
      this.props.project.title === this.props.projectOriginal.title
        ? true
        : this.props.project.validTitle;
    const isProjectInternal = this.props.isProjectInternal ?? false;
    return (
      <Card className="small-card">
        <div style={{ textAlign: 'center' }}>
          <CardHeader>
            <h3>{this.t('generalInformation')}</h3>
          </CardHeader>
        </div>
        <CardBody>
          <Table borderless size="sm">
            <tbody>
              <tr>
                <th style={{ width: styleWidth30Percent }}>
                  <InputFormLabel
                    isRequired={
                      (!project?.id &&
                        project?.state === PROJECT_STATE_ENUM.vague.code) ||
                      isProjectInternal
                    }
                    text={
                      isProjectInternal
                        ? this.t('projectName')
                        : this.t('projectOption')
                    }
                  />
                </th>
                <td>
                  <Input
                    type="string"
                    bsSize="lg"
                    onChange={this.handleTitleChange}
                    disabled={this.props.lockInputs}
                    value={project?.title ?? ''}
                  />
                  {validTitle == null ? (
                    <>
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        style={{ color: 'gray' }}
                        className="margin-right"
                      />
                      {` ${this.t('checkingTitle')}`}
                    </>
                  ) : null}
                  {!isNullOrUndefinedOrEmpty(project?.title) &&
                  validTitle != null ? (
                    <>
                      <FontAwesomeIcon
                        icon={validTitle ? faCheckCircle : faTimesCircle}
                        style={{ color: validTitle ? 'green' : 'red' }}
                        className="margin-right"
                      />
                      {` ${this.t(validTitle ? 'validTitle' : 'invalidTitle')}`}
                    </>
                  ) : null}
                </td>
              </tr>
              <tr>
                <th>
                  <InputFormLabel isRequired text={this.t('customer')} />
                </th>
                <td style={{ zIndex: '1000' }}>
                  <CustomerDropdown
                    customerId={project.customerId}
                    onChange={this.handleCustomerChange}
                    readOnly={this.isCustomerDropdownDisabled(project)}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <InputFormLabel isRequired text={this.t('customerSite')} />
                </th>
                <td>
                  <CustomerSitesDropdown
                    customerId={project.customerId ?? 0}
                    customerSiteId={project.customerSiteId ?? 0}
                    onChange={this.handleCustomerSiteChange}
                  />
                </td>
              </tr>
              {isProjectInternal && !this.props.lockInputs && (
                <tr>
                  <th>
                    <InputFormLabel isRequired text={this.t('department')} />
                  </th>
                  <td>
                    <DepartmentDropdown
                      selectedDepartments={project?.departments ?? []}
                      onChange={this.handleDepartmentChange}
                      isMulti={false}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        <CardBody>
          <Row>
            <Col>
              {!this.props.lockInputs ? (
                <div className="card-actions float-end">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => this.addNewContactPerson()}
                    disabled={!project.customerId}
                    title={
                      !project.customerId ? this.t('addACustomerFirst') : null
                    }
                  >
                    {this.t('addNewContact')}
                  </Button>{' '}
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => this.addExistingContactPerson()}
                  >
                    {this.t('addExistingContactPerson')}
                  </Button>
                </div>
              ) : null}
            </Col>
          </Row>
        </CardBody>
        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleModalForm}
          modalTitle={this.modalTitle}
          size="xl"
        >
          {this.state.modalForm}
        </ModalForm>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.errorMessage.mainError}
          errorReason={this.errorMessage.errorReason}
          errorResponse={this.errorMessage.errorResponse}
          modalTitle={this.t('error')}
        />
      </Card>
    );
  }
}

export default ProjectOptionGeneralInfoCard;
