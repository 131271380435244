import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  FormGroup,
  Input,
  FormFeedback,
} from 'reactstrap';
import axios from '../../../services/axios/axios';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import InputFormLabel from '../../../components/form/InputFormLabel';
import {
  addRecord,
  dataGeneration,
  updateRecord,
} from '../../../utils/constants';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { errorHandler } from '../../../utils/helpers/GenericHelper';
import { sortOptionsByValue } from '../../../utils/helpers/dropdown';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';
import ModalOK from '../../../components/modals/ModalOK';

class LocationInsertOrUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdate: false,
      id: null,
      location: '',
      calendarId: null,
      calendars: [],
      locations: [],
      showModalOK: false,
    };
  }

  //Toggle for showing the modal for duplicate entries.
  toggleModalOK = () => {
    this.setState({ showModalOK: !this.state.showModalOK });
  };

  t(keyName) {
    return i18n.t('LocationInsertOrUpdate.' + keyName);
  }

  componentDidMount() {
    axios.employee.get('employee-locations').then((response) => {
      if (Array.isArray(response.data)) {
        this.setState({
          locations: response.data,
        });
      }
    });
    if (this.props.match.params.id) {
      axios.employee
        .get(`employee-locations/${this.props.match.params.id}`)
        .then((response) => {
          this.setState({
            location: response.data.location,
            calendarId: response.data.calendarId,
          });
        })
        .catch((error) => {
          this.message = errorHandler(
            dataGeneration,
            error,
            this.t('location')
          );
          this.toggleModalError();
        });
      this.setState({
        id: this.props.match.params.id,
        isUpdate: true,
      });
    }

    axios.absence
      .get('calendars')
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({
            calendars: response.data,
          });
        }
      })
      .catch((error) => {
        this.message = errorHandler(dataGeneration, error, this.t('location'));
        this.toggleModalError();
      });
  }

  handleSubmit = (e) => {
    var sameNameExistingEntry = this.state.locations.find(
      (item) => item.location === this.state.location
    );
    if (
      sameNameExistingEntry !== undefined &&
      sameNameExistingEntry?.id !== parseInt(this.state.id, 10)
    ) {
      this.errorMessage = this.t('nameAlreadyExist');
      this.toggleModalOK();
      return;
    }
    if (this.state.isUpdate) {
      this.updateLocation();
    } else {
      this.insertLocation();
    }
  };

  updateLocation() {
    axios.employee
      .put('employee-locations', this.state)
      .then((response) => {
        this.props.history.push({
          pathname: '/settings/employee',
          state: {
            successMessage: this.t('updatedLocation') + response.data.id,
          },
        });
      })
      .catch((error) => {
        this.message = errorHandler(updateRecord, error, this.t('location'));
        this.toggleModalError();
      });
  }

  insertLocation() {
    axios.employee
      .post('employee-locations', this.state)
      .then((response) => {
        if (this.props.onSave) {
          this.props.onSave();
        } else {
          this.props.history.push({
            pathname: this.props.location?.state?.previousPath,
            state: {
              successMessage: this.t('addedNewLocation'),
            },
          });
        }
      })
      .catch((error) => {
        this.message = errorHandler(addRecord, error, this.t('location'));
        this.toggleModalError();
      });
  }

  handleLocationChange = (e) => {
    this.setState({ location: e.target.value });
  };

  handleCalendarChange = (e) => {
    this.setState({
      calendarId: e.value,
    });
  };

  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  render() {
    return (
      <Container fluid>
        {this.state.isUpdate ? (
          <Header>
            <HeaderTitle>Location Detail</HeaderTitle>
            {generateBreadcrumb(
              this.props.location.pathname,
              this.t('settings')
            )}
          </Header>
        ) : null}
        <Card>
          <CardHeader>
            <CardTitle>
              <h1>
                {this.state.isUpdate
                  ? `${'Location Detail'} - ${this.props.match.params.id}`
                  : null}
              </h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <InputFormLabel isRequired={true} text={this.t('location')} />
              <Input
                onChange={this.handleLocationChange}
                value={this.state.location}
                name="location"
                type="text"
                invalid={this.state.location === undefined}
              />
              <FormFeedback>{this.t('projectTypeRequired')}</FormFeedback>
            </FormGroup>
            <InputFormLabel isRequired={false} text={this.t('calendar')} />
            <Select
              options={sortOptionsByValue(
                this.state.calendars.map((calendar) => ({
                  label: calendar.title,
                  value: calendar.id,
                }))
              )}
              onChange={this.handleCalendarChange}
              value={this.state.calendars
                .filter(
                  (calendar) =>
                    parseInt(calendar.id) === parseInt(this.state.calendarId)
                )
                .map((calendar) => ({
                  label: calendar.title,
                  value: calendar.id,
                }))}
            />
            <br />
            <Button
              color="primary"
              className="float-end"
              onClick={this.handleSubmit}
              disabled={
                this.state.location === undefined || this.state.location === ''
              }
            >
              {this.t('submit')}
            </Button>
          </CardBody>
        </Card>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.message?.mainError}
          errorReason={this.message?.errorReason}
          errorResponse={this.message?.errorResponse}
          modalTitle={this.t('error')}
        ></ModalError>
        <ModalOK
          isOpen={this.state.showModalOK}
          onClose={this.toggleModalOK}
          modalTitle={this.t('error')}
          modalBodyText={this.errorMessage}
        />
      </Container>
    );
  }
}

export default withRouter(withTranslation()(LocationInsertOrUpdate));
