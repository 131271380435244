import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import i18n from '../../../i18n';
import DepartmentListView from './DepartmentListView';
import FieldsOfBusinessView from './FieldsOfBusinessView';

interface IProps extends RouteComponentProps<{ location: any }> {}

const ProjectSettingsView: React.FC<IProps> = ({ location }) => {
  const t = (keyName: string) => i18n.t(keyName);
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('ProjectSettingsView.project')}</HeaderTitle>
        {generateBreadcrumb(
          location.pathname,
          i18n.t('ProjectSettingsView.settings')
        )}
      </Header>
      <Card>
        <CardHeader>
          <CardTitle>
            <h1>{t('ProjectSettingsView.projectSettings')}</h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Button
              className="gap-1"
              color="outline"
              id="projectFieldsOfBusinessToggler"
            >
              <FontAwesomeIcon icon={faCaretDown} />
              <h3>{t('ProjectSettingsView.fieldsOfBusiness')}</h3>
            </Button>
          </Row>
          <UncontrolledCollapse toggler="#projectFieldsOfBusinessToggler">
            <FieldsOfBusinessView />
          </UncontrolledCollapse>
          <br />
          <Row>
            <Button
              className="gap-1"
              color="outline"
              id="projectDepartmentToggler"
            >
              <FontAwesomeIcon icon={faCaretDown} />
              <h3>{t('ProjectSettingsView.department')}</h3>
            </Button>
          </Row>
          <UncontrolledCollapse toggler="#projectDepartmentToggler">
            <DepartmentListView />
          </UncontrolledCollapse>
        </CardBody>
      </Card>
    </Container>
  );
};

export default withRouter(ProjectSettingsView);
