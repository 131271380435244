import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup } from 'reactstrap';

import CustomerAccountFormInput from './CustomerAccountFormInput';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { generateTitle } from '../../../utils/helpers/icon';
import { translateAddOrUpdate } from '../CustomerAccountList/customerAccountListHelper';
import { ICustomerAccountDetail } from '../../../utils/types/responseTypes';
import {
  getCustomerAccounts,
  patchCustomerAccountDetail,
} from '../../../services/api/customer';

interface IProps extends IWithModalsProps, RouteComponentProps {
  customerAccountDetail: ICustomerAccountDetail;
  onSave: (customerAccount: ICustomerAccountDetail) => void;
  onCancel: () => void;
}

/**
 * For Updating Customer Account
 */
const UpdateCustomerAccountForm: React.FC<IProps> = ({
  customerAccountDetail,
  onSave,
  onCancel,
  modalErrorHandler,
  modalOkHandler,
}: IProps) => {
  const [customerAccountDetailState, setCustomerAccountDetailState] =
    useState<ICustomerAccountDetail>(customerAccountDetail);

  // Method that checks if the name already exist
  const isCustomerAccountNameExist = async (name: string) => {
    const existingCustomerAccountName = await getCustomerAccounts({
      'name.equals': name,
    })
      .then(({ data }) => data)
      .catch((error) => {
        modalErrorHandler(
          translateAddOrUpdate('failedToCheckIfAccoutNameExists'),
          error
        );
      });
    if (
      Array.isArray(existingCustomerAccountName) &&
      existingCustomerAccountName.length > 0
    ) {
      const customerAccountId = customerAccountDetail.id;
      const hasDifferentId = existingCustomerAccountName.some(
        ({ id }) => id !== customerAccountId
      );

      return hasDifferentId;
    }
    return false;
  };

  // Method that create or update a customerAccount in the database
  const saveCustomerAccountDetail = async (
    customerAccountDetail: ICustomerAccountDetail
  ) => {
    const savedCustomerAccountDetail = await patchCustomerAccountDetail(
      customerAccountDetail
    )
      .then(({ data }) => data)
      .catch((error) => {
        modalErrorHandler(
          translateAddOrUpdate('failedToUpdateCustomerAccount'),
          error
        );
      });
    return savedCustomerAccountDetail;
  };

  // Method that saves all the customer data on "Save" button click.
  // It saves the customer, responsibles and contact informations
  const updateCustomerAccount = async (event: MouseEvent) => {
    event.preventDefault();

    if (!customerAccountDetailState.name) {
      modalOkHandler('Error', translateAddOrUpdate('accountNameRequired'));
      return;
    }

    // Check if customer account name already exist
    const doesCustomerAccountNameExist = await isCustomerAccountNameExist(
      customerAccountDetailState.name
    );
    if (doesCustomerAccountNameExist) {
      modalOkHandler('Error', translateAddOrUpdate('accountSameNameExists'));
      return;
    }

    // Saving customerAccountDetail
    const savedCustomerAccount = await saveCustomerAccountDetail({
      ...customerAccountDetailState,
    });
    if (!savedCustomerAccount) {
      return;
    }

    if (onSave) {
      onSave(savedCustomerAccount);
    } else {
      onCancel();
    }
  };

  // Handle changes made in the customer input form
  const handleDetailsChange = (
    customerAccountDetail: ICustomerAccountDetail
  ) => {
    setCustomerAccountDetailState(customerAccountDetail);
  };

  return (
    <Form>
      <FormGroup>
        <CustomerAccountFormInput
          customerAccountDetail={customerAccountDetailState}
          onChange={handleDetailsChange}
        />
        <br />
        <div
          className="card-actions float-start"
          style={{ paddingLeft: '5px' }}
        >
          <Button
            color="primary"
            onClick={(event: MouseEvent) => updateCustomerAccount(event)}
          >
            {generateTitle(
              BUTTON_TITLE_ENUM.SAVE.code,
              translateAddOrUpdate('save')
            )}
          </Button>
        </div>
        <div
          className="card-actions float-start"
          style={{ paddingLeft: '5px' }}
        >
          <Button color="primary" onClick={onCancel}>
            {translateAddOrUpdate('cancel')}
          </Button>
        </div>
      </FormGroup>
    </Form>
  );
};

export default withRouter(withModals(UpdateCustomerAccountForm));
