import i18n from '../../i18n';

export enum UtilizationActionEnum {
  NO_ACTION = 'NO_ACTION',
  ACTION_DESIRED = 'ACTION_DESIRED',
  ACTION_NECESSARY = 'ACTION_NECESSARY',
}

export const MONTH_FILTER_OPTIONS_ENUM = {
  thisMonth: {
    code: 'THIS_MONTH',
    name: i18n.t('TimeSpanEnum.thisMonth'),
  },
  lastMonth: {
    code: 'LAST_MONTH',
    name: i18n.t('TimeSpanEnum.lastMonth'),
  },
  nextMonth: {
    code: 'NEXT_MONTH',
    name: i18n.t('TimeSpanEnum.nextMonth'),
  },
};

export const TOTAL_UTILIZATION_COLOR_ENUM = {
  allBooked: {
    name: 'Dark Blue',
    color: '#4192c4',
  },
  notAllBooked: {
    name: 'Dark Green',
    color: '#307237',
  },
};
export enum Months {
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
}
