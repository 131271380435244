import React, { useState, useEffect } from 'react';
import FileSaver from 'file-saver';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import InputFormLabel from '../../../components/form/InputFormLabel';
import { dataGeneration, preferredLanguages } from '../../../utils/constants';
import {
  ContactStatusEnum,
  GDPRStatus,
  NewsletterStatus,
} from '../../../utils/enums/contact';
import {
  CONTACT_PERSON_FUNCTION_ENUM,
  ContactInformationStatus,
} from '../../../utils/enums/contactPerson';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { loadEmployees } from '../../../redux/employeesSlice';
import CampaignManagerTable from './CampaignManagerTable';
import CampaignManagerEmailForm from './CampaignManagerEmailForm';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { RootState } from '../../../redux/store';
import { IExtendedContactPerson, ISector } from '../../../utils/types/modelTypes';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import {
  translateCampaignManager,
  retrieveContactsWithEmailsOnly,
  createQueryParameters,
} from './campaignManagerHelper';
import { removeDuplicates } from '../../../utils/helpers/table';
import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';
import { ICampaignManagerContactPersonListItem } from '../../../utils/types/responseTypes';
import {
  sendCampaignManagerContactPersonEmails,
  getCountryOptions,
  getSectorOptions,
  getTopicOptions,
  exportCampaignManagerContactPersonListItems,
} from '../../../services/api/campaignManager';
import { SortBy, SortType } from '../../../utils/enums/sort';
import {
  setListItems,
  setFilters,
  setPage,
  fetchCampaignManagerContactPersonListItems,
} from '../../../redux/campaignManagerContactPersonListSlice';
import { isEmpty } from '../../../utils/helpers/array';
import i18n from '../../../i18n';

interface IProps
  extends PropsFromRedux,
    IWithModalsProps,
    RouteComponentProps {}

enum Filters {
  // Title
  CAMPAIGN_NAME = 'campaignName',
  // Text Filters
  ROLE = 'role',
  CUSTOMER = 'customer',
  CUSTOMER_SITE = 'customerSite',
  // Dropdown Filters
  FUNCTION = 'functionRoles',
  INDUSTRIES = 'industries',
  COUNTRIES = 'countries',
  STATUSES = 'statuses',
  EMAIL_STATUSES = 'emailStatuses',
  SELECTED_LANGUAGE = 'selectedLanguage',
  GDPR_STATUSES = 'gdprStatuses',
  NEWSLETTERS = 'newsletters',
  TOPICS = 'topics',
}

// Retrieve Contact Statuses
const contactPersonAssignmentStates = enumValuesToDropdownOptions(
  Object.values(ContactStatusEnum)
);

// Add contact information State
const contactInformationState = enumValuesToDropdownOptions(
  Object.values(ContactInformationStatus)
);

// Add gdpr status as options
const gdprStateOptions = enumValuesToDropdownOptions(
  Object.values(GDPRStatus)
);

// Add newsletter as options
const newsletterOptions = enumValuesToDropdownOptions(
  Object.values(NewsletterStatus)
);

/**
 * Parent component for the Campaign Manager, a page that allows a Campaign Manager
 * to filter contacts through numerous filters, export selected contacts to an Excel file,
 * or send campaign emails
 */
const CampaignManager = ({
  modalErrorHandler,
  modalOkHandler,
  toggleModalForm,
  modalFormHandler,
  location,
  setPage,
  setListItems,
  listItems,
  filters,
  setFilters,
  fetchCampaignManagerContactPersonListItems,
}: IProps) => {
  // Table Data
  const [selectedContacts, setSelectedContacts] = useState<
    ICampaignManagerContactPersonListItem[]
  >([]);

  // Options
  const [sectorOptions, setSectorOptions] = useState<IDropdownOption[]>([]);
  const [countryOptions, setCountryOptions] = useState<IDropdownOption[]>([]);
  const [topicOptions, setTopicOptions] = useState<IDropdownOption[]>([]);

  // Retrieve Sector values
  useEffect(() => {
    getSectorOptions()
      .then((response) => {
        const fetchedSectorOptions = response.data.map((sector: ISector) => ({
          value: sector.sector,
          label: sector.sector,
        }));

        setSectorOptions(fetchedSectorOptions);
      })
      .catch((error) => {
        modalErrorHandler(dataGeneration, error);
      });
  }, []);

  // Retrieve Country values
  useEffect(() => {
    getCountryOptions()
      .then((response) => {
        const fetchedCountryOptions = response.data.map((country) => ({
          value: country.name,
          label: country.name,
        }));

        setCountryOptions(fetchedCountryOptions);
      })
      .catch((error) => {
        modalErrorHandler(dataGeneration, error);
      });
  }, []);

  // Retrieve topic values
  useEffect(() => {
    getTopicOptions()
      .then((response) => {
        const fetchedTopicOptions = response.data.map((topic) => ({
          value: topic.id.toString(),
          label: topic.topic,
        }));

        setTopicOptions(fetchedTopicOptions);
      })
      .catch((error) => {
        modalErrorHandler(dataGeneration, error);
      });
  }, []);

  // Create the Excel file for export
  const createExcelFile = async (
    contactsFilter: object,
    idExceptions: number[]
  ) => {
    try {
      // Get the required language to the excel sheet
      const { language } = i18n;
      const title = `${filters.campaignName}_${moment()
        .format('YYYY_MM_DD')
        .toString()}`;

      const response = await exportCampaignManagerContactPersonListItems(
        {
          'id.notIn': idExceptions.toString(),
          ...contactsFilter,
        },
        language.toUpperCase()
      );
      FileSaver(response.data, `Campaign_Contacts_${title}.xlsx`);
    } catch (error) {
      modalErrorHandler(dataGeneration, error);
    }
  };

  // Set the updated selected contacts list after the checkbox in the
  // DynamicTable inside CampaignManagerTable has been clicked
  const updateSelectedContacts = (
    contacts: ICampaignManagerContactPersonListItem[]
  ) => {
    setSelectedContacts((prevSelectedContacts) => [
      ...removeDuplicates(prevSelectedContacts, contacts),
    ]);
  };

  // Fetch data for infinite scrolling table
  const fetchData = () => {
    fetchCampaignManagerContactPersonListItems(
      updateSelectedContacts,
      (error) => {
        modalErrorHandler(
          translateCampaignManager('failedToRetrieveResponsibles'),
          error
        );
      }
    );
  };

  // Begin running the query while resetting some data
  const runQuery = () => {
    setListItems([]);
    setPage(0);
    setSelectedContacts([]);
    fetchData();
  };

  // Begin export of selected contacts
  const exportSelected = async () => {
    const contactsListCount = selectedContacts.length;

    // If 0 (or somehow negative), throw a warning and stop
    if (contactsListCount <= 0) {
      modalOkHandler(
        translateCampaignManager('warning'),
        translateCampaignManager('nothingToExport')
      );
      return;
    }

    // Get the axios string proper
    const queryParams = createQueryParameters(
      filters,
      SortBy.LAST_NAME,
      SortType.ASCENDING
    );

    // Get the ids
    const listedContactsIds = listItems.map((item) => item.id);
    const selectedContactsIds = selectedContacts.map((item) => item.id);
    const idItemsToRemove =
      listedContactsIds.length !== selectedContactsIds.length
        ? listedContactsIds.filter((x) => !selectedContactsIds.includes(x))
        : [];

    // Use queryParamsString in createExcelFile function
    await createExcelFile(queryParams, idItemsToRemove);
  };

  const retrieveContactsToEmail = () => {
    // Remove non-selected contacts from the listed contacts
    const contactsToExport = listItems.filter((contact) =>
      selectedContacts.some(
        (selectedContact) => contact.id === selectedContact.id
      )
    );

    return contactsToExport;
  };

  const handleInputFilterChange = (
    fieldName: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilters({
      ...filters,
      [fieldName]: event.target.value ?? null,
    });
  };

  const handleDropdownFilterChange = (
    fieldName: string,
    filterValue: IDropdownOption[]
  ) => {
    setFilters({
      ...filters,
      [fieldName]: filterValue,
    });
  };

  // Send the email
  const sendEmail = async (
    emailSubject: string,
    emailBody: string,
    recipients: IExtendedContactPerson[]
  ) => {
    try {
      const emailObject = {
        emailSubject,
        emailBody,
        recipients,
      };

      await sendCampaignManagerContactPersonEmails(emailObject);
      toggleModalForm();
    } catch (error) {
      modalErrorHandler(translateCampaignManager('couldNotSendEmails'), error);
    }
  };

  // Method for showing the email form;
  // Form will not appear if no contacts selected or
  // If none of the selected contacts have emails
  const showEmailForm = () => {
    const contactsToSendEmailTo = retrieveContactsToEmail();

    if (!isEmpty(contactsToSendEmailTo)) {
      const contactsWithEmails = retrieveContactsWithEmailsOnly(
        contactsToSendEmailTo
      );
      if (!isEmpty(contactsWithEmails)) {
        modalFormHandler(
          translateCampaignManager('sendEmail'),
          <CampaignManagerEmailForm
            onSend={sendEmail}
            onCancel={toggleModalForm}
            contacts={contactsWithEmails}
          />
        );
      } else {
        modalOkHandler(
          translateCampaignManager('warning'),
          translateCampaignManager('selectedCotactsNoValidEmail')
        );
      }
    } else {
      modalOkHandler(
        translateCampaignManager('warning'),
        translateCampaignManager('noContactsToSendEmailsTo')
      );
    }
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{translateCampaignManager('campaignManager')}</HeaderTitle>
        {generateBreadcrumb(
          location.pathname,
          translateCampaignManager('customer')
        )}
      </Header>
      <Card>
        <CardHeader />
        <CardBody>
          <CardTitle className="mb-0">
            <h1>{translateCampaignManager('campaignManager')}</h1>
          </CardTitle>
          <br />
          <Row>
            <Col md={4}>
              <h4>
                <Label>
                  <span className="marker-color">*</span>
                  {translateCampaignManager('campaignName')}
                </Label>
              </h4>
              <Input
                type="string"
                value={filters.campaignName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputFilterChange(Filters.CAMPAIGN_NAME, event)
                }
                aria-label="textbox-campaign-name"
              />
            </Col>
          </Row>
          <br />
          <h4>{translateCampaignManager('campaignManagerFilters')}</h4>
          <Row>
            <Col md={4}>
              <InputFormLabel
                text={translateCampaignManager('role')}
                isRequired={false}
              />
              <Input
                type="string"
                value={filters.role}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputFilterChange(Filters.ROLE, event)
                }
                aria-label="textbox-role"
              />
            </Col>
            <Col md={4}>
              <InputFormLabel
                text={translateCampaignManager('customerFilter')}
                isRequired={false}
              />
              <Input
                type="string"
                value={filters.customer}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputFilterChange(Filters.CUSTOMER, event)
                }
                aria-label="textbox-customer"
              />
            </Col>
            <Col md={4}>
              <InputFormLabel
                text={translateCampaignManager('customerSite')}
                isRequired={false}
              />
              <Input
                type="string"
                value={filters.customerSite}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputFilterChange(Filters.CUSTOMER_SITE, event)
                }
                aria-label="textbox-customer-site"
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4}>
              <InputFormLabel
                text={translateCampaignManager('function')}
                isRequired={false}
              />
              <Select
                isMulti
                onChange={(value: IDropdownOption[]) =>
                  handleDropdownFilterChange(Filters.FUNCTION, value)
                }
                options={Object.values(CONTACT_PERSON_FUNCTION_ENUM)}
                placeholder={translateCampaignManager('selectFunctions')}
                value={filters.functionRoles}
              />
            </Col>
            <Col md={4}>
              <InputFormLabel
                text={translateCampaignManager('industry')}
                isRequired={false}
              />
              <Select
                isMulti
                onChange={(value: IDropdownOption[]) =>
                  handleDropdownFilterChange(Filters.INDUSTRIES, value)
                }
                options={sectorOptions}
                placeholder={translateCampaignManager('selectIndustries')}
                value={filters.industries}
              />
            </Col>
            <Col md={4}>
              <InputFormLabel
                text={translateCampaignManager('country')}
                isRequired={false}
              />
              <Select
                isMulti
                onChange={(value: IDropdownOption[]) =>
                  handleDropdownFilterChange(Filters.COUNTRIES, value)
                }
                options={countryOptions}
                placeholder={translateCampaignManager('selectCountries')}
                value={filters.countries}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4}>
              <InputFormLabel
                text={translateCampaignManager('status')}
                isRequired={false}
              />
              <Select
                isMulti
                onChange={(value: IDropdownOption[]) =>
                  handleDropdownFilterChange(Filters.STATUSES, value)
                }
                options={contactPersonAssignmentStates}
                placeholder={translateCampaignManager('selectStatuses')}
                value={filters.statuses}
                aria-label="dropdown-statuses"
              />
            </Col>
            <Col md={4}>
              <InputFormLabel
                text={translateCampaignManager('emailStatus')}
                isRequired={false}
              />
              <Select
                isMulti
                onChange={(value: IDropdownOption[]) =>
                  handleDropdownFilterChange(Filters.EMAIL_STATUSES, value)
                }
                options={contactInformationState}
                placeholder={translateCampaignManager('selectEmailStatuses')}
                value={filters.emailStatuses}
                aria-label="dropdown-email-statuses"
              />
            </Col>
            <Col md={4}>
              <InputFormLabel
                text={translateCampaignManager('mainLanguage')}
                isRequired={false}
              />
              <Select
                isMulti
                onChange={(value: IDropdownOption[]) =>
                  handleDropdownFilterChange(Filters.SELECTED_LANGUAGE, value)
                }
                options={preferredLanguages}
                value={filters.selectedLanguage}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4}>
              <InputFormLabel
                text={translateCampaignManager('gdprStatus')}
                isRequired={false}
              />
              <Select
                isMulti
                onChange={(value: IDropdownOption[]) =>
                  handleDropdownFilterChange(Filters.GDPR_STATUSES, value)
                }
                options={gdprStateOptions}
                value={filters.gdprStatuses}
                placeholder={translateCampaignManager('selectGdprStatus')}
                aria-label="dropdown-gdpr-status"
              />
            </Col>
            <Col md={4}>
              <InputFormLabel
                text={translateCampaignManager('newsletter')}
                isRequired={false}
              />
              <Select
                isMulti
                onChange={(value: IDropdownOption[]) =>
                  handleDropdownFilterChange(Filters.NEWSLETTERS, value)
                }
                options={newsletterOptions}
                value={filters.newsletters}
                placeholder={translateCampaignManager('selectNewsletters')}
                aria-label="dropdown-newsletters"
              />
            </Col>
            <Col md={4}>
              <InputFormLabel
                text={translateCampaignManager('topic')}
                isRequired={false}
              />
              <Select
                isMulti
                onChange={(value: IDropdownOption[]) =>
                  handleDropdownFilterChange(Filters.TOPICS, value)
                }
                options={topicOptions}
                value={filters.topics}
                placeholder={translateCampaignManager('selectTopic')}
              />
            </Col>
          </Row>
          <br />
          <Button
            color="primary"
            onClick={() => runQuery()}
            disabled={!filters.campaignName}
          >
            {translateCampaignManager('runQuery')}
          </Button>{' '}
          <Button
            color="primary"
            onClick={() => exportSelected()}
            disabled={!filters.campaignName}
          >
            Export
          </Button>{' '}
          <Button
            color="primary"
            onClick={() => showEmailForm()}
            disabled={!filters.campaignName}
          >
            {translateCampaignManager('sendEmail')}
          </Button>
          <br />
          <br />
          <CampaignManagerTable
            selectedContacts={selectedContacts}
            setSelectedContacts={updateSelectedContacts}
            fetchData={fetchData}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  employees: store.employees,
  listItems: store.campaignManagerContactPersonList.listItems,
  filters: store.campaignManagerContactPersonList.filters,
  page: store.campaignManagerContactPersonList.page,
  hasMore: store.campaignManagerContactPersonList.hasMore,
});

const mapDispatchToProps = {
  loadEmployees,
  setListItems,
  setFilters,
  setPage,
  fetchCampaignManagerContactPersonListItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withModals(withRouter(connector(CampaignManager)));
