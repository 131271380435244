import React, { ChangeEvent, useContext, useState } from 'react';
import { Button, Form, Input, Label, ModalBody, ModalFooter } from 'reactstrap';

import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import i18n from '../../../i18n';
import { ActivityType } from '../../../utils/enums/activity';
import { ContactPersonDetailsContext } from './ContactPersonDetailsProvider';
import { IActivity } from '../../../utils/types/modelTypes';
import { saveActivity } from '../../../services/api/activity';
import withModals, { IWithModalsProps } from '../../../utils/withModals';

interface IProps extends IWithModalsProps {
  onCancel: () => void;
}

const t = (keyName: string) => i18n.t(`ActivityHistoryCard.${keyName}`);

/**
 * Modal to add information/action to the activity history
 */
const ModalAddInfo = ({ onCancel, modalErrorHandler }: IProps) => {
  const {
    contactDetail,
    historyOfActivitiesDetail,
    setHistoryOfActivitiesDetail,
  } = useContext(ContactPersonDetailsContext);
  const [description, setDescription] = useState('');

  const save = async () => {
    const payload = {
      description,
      comments: 'important',
      date: new Date().toISOString(),
      objectId: contactDetail.id,
      objectType: OBJECT_TYPE_ENUM.contactPerson.code,
      activityType: ActivityType.CUSTOM_ACTIVITY,
    } as IActivity;

    try {
      const { data: newActivity } = await saveActivity(payload);
      // Add the new activity to the list of activities
      setHistoryOfActivitiesDetail([...historyOfActivitiesDetail, newActivity]);
      onCancel();
    } catch (error) {
      modalErrorHandler('failedToAddActivity', error);
    }
  };

  return (
    <>
      <ModalBody>
        <Form>
          <Label>{t('info')}</Label>
          <Input
            aria-label="modal-add-info-textbox"
            type="textarea"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setDescription(event.target.value)
            }
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="float-right">
          <Button color="primary" onClick={() => save()}>
            {t('confirm')}
          </Button>{' '}
          <Button color="primary" onClick={() => onCancel()}>
            {t('cancel')}
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

export default withModals(ModalAddInfo);
