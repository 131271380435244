import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons';

import { SERVICE_NAMES_ENUM } from '../../utils/enums/service';
import { PERMISSION_URI } from '../../utils/enums/permission';
import i18n from '../../i18n';
// Employees
import EmployeeDetail from '../../pages/projects/ProjectEmployeeDetails/EmployeeDetail';
// Project
import ProjectList from '../../pages/projects/ProjectList/ProjectList';
import ResourceProfile from '../../pages/resourcePlanner/ResourceProfile/ResourceProfile';
import ProjectDetailOverview from '../../pages/projects/ProjectDetails/ProjectDetailOverview';

export const projectRoutes = {
  path: '/project',
  name: i18n.t('index.projects'),
  serviceName: SERVICE_NAMES_ENUM.projects.code,
  icon: faProjectDiagram,
  isProtected: true,
  auth: true,
  permissionUri: [PERMISSION_URI.projectList.read.uri],
  children: [
    {
      path: '/projects/overview',
      name: i18n.t('index.projectOverview'),
      component: ProjectList,
      permissionUri: [PERMISSION_URI.projectList.read.uri],
    },
  ],
};

export const projectPrivateRoute = {
  path: '/projects',
  name: 'Projects',
  isProtected: true,
  permissionUri: [PERMISSION_URI.projectList.read.uri],
  children: [
    {
      path: '/projects/overview/project-details/:projectId',
      name: 'Project Detail',
      component: ProjectDetailOverview,
    },
    {
      path: '/projects/overview/:projectId/view-employee/:projectEmployeeId',
      name: 'View Project Employee',
      component: EmployeeDetail,
    },
    {
      path: '/projects/resource-profile/:id',
      name: 'Resource Profile',
      component: ResourceProfile,
    },
  ],
};
