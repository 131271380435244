import moment, { Moment, LongDateFormatKey, unitOfTime } from 'moment';

import i18n from '../../i18n';

export const addWeekdays = (date: Moment, days: number) => {
  let newDate = date;
  let daysToAdd = days;

  while (daysToAdd > 0) {
    newDate = newDate.add(1, 'days');

    if (newDate.isoWeekday() !== 6 && newDate.isoWeekday() !== 7) {
      daysToAdd -= 1;
    }
  }

  return newDate;
};

export const getNearestWorkingDay = (date: Moment) => {
  switch (date.day()) {
    // Saturday
    case 6:
      return date.add(2, 'days');
    // Sunday
    case 0:
      return date.add(1, 'days');
    default:
      return date;
  }
};

export const getNumberWorkDays = (start: Moment, end: Moment) => {
  let numWorkDays = 0;
  let currentDate = start;

  while (currentDate.isSameOrBefore(end)) {
    // Skips Sunday and Saturday
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      numWorkDays += 1;
    }
    currentDate = currentDate.add(1, 'days');
  }

  return numWorkDays;
};

export const getDuration = (start: Moment, end: Moment, durationType: string) =>
  (Math.round(
    (parseFloat(
      end.diff(moment(start), durationType as unitOfTime.Diff, true).toFixed(2)
    ) *
      1.0) /
      0.5
  ) /
    1.0) *
  0.5;

/**
 * This function gets the date format based on the user's browser language setting and the formatOption being set.
 * @param formatOption - Optional parameter. Import the momentLongDateFormat from the src/helpers/enum. To select further dateFormat.
 *  * Here are the list of key(string): value(string) pair to give an idea on the expected return based on the key used : 
        {"LT": "h:mm A",
        "LTS": "h:mm:ss A",
        "L": "MM/DD/YYYY",
        "LL": "MMMM Do YYYY",
        "LLL": "MMMM Do YYYY LT",
        "LLLL": "dddd, MMMM Do YYYY LT"}
 * @param showTime - Optional boolean value. If true, allow the user to select time. Otherwise if false.
 * @returns string dateFormat to be used to format dates.
 */
export function getDateFormat(
  formatOption: LongDateFormatKey = 'L',
  showTime = false
) {
  // Set prefferedLanguage "en" as default
  const languageSelected = i18n.language;

  if (showTime) {
    // Get time based on the input date.
    const retrievedTime = moment
      .localeData(languageSelected)
      .longDateFormat('LT');
    const retrievedDate = moment
      .localeData(languageSelected)
      .longDateFormat(formatOption);
    return `${retrievedDate} ${retrievedTime}`;
  }
  return moment.localeData(languageSelected).longDateFormat(formatOption);
}

/**
 * Checks if provided date is between start and end
 */
export const isBetweenDate = (
  date: Date | Moment | string,
  start: Date | Moment | string,
  end: Date | Moment | string
) => {
  if (!date) {
    return true;
  }
  return moment(date).isBetween(moment(start), moment(end), undefined, '[]');
};
