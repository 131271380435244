import moment from 'moment';
import React, { useContext } from 'react';

import CollapsibleTableCard from '../../../components/collapsibleCards/CollapsibleTableCard';
import { EntityType } from '../../../utils/enums/pageComponents';
import { getDateFormat } from '../../../utils/helpers/date';
import { createLinkIfAuthorised } from '../../../utils/helpers/permission';
import i18n from '../../../i18n';
import { CustomerSiteDetailsContext } from './CustomerSiteDetailsContextProvider';

const t = (keyName: string) => i18n.t(`ActivityTable.${keyName}`);

const CollapsibleActivityCard = () => {
  const {
    customerSiteDetails: { activities },
  } = useContext(CustomerSiteDetailsContext);

  const tableData = activities.map(
    ({ date, activityType, description, creator: { id, name }, comment }) => ({
      [t('date')]: moment(date).format(getDateFormat()),
      [t('type')]: i18n.exists(`ActivityTypes.${activityType}`)
        ? i18n.t(`ActivityTypes.${activityType}`)
        : activityType,
      [t('description')]: description,
      [t('creator')]:
        createLinkIfAuthorised(EntityType.EMPLOYEE, name, id) ?? '',
      [t('comment')]: comment,
    })
  );

  return (
    <CollapsibleTableCard
      title={i18n.t('ActivityCard.logs')}
      tableData={tableData}
      noDataPlaceholder={i18n.t('ActivityCard.noActivityFound')}
      striped
    />
  );
};

export default CollapsibleActivityCard;
