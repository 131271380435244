import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import { ConnectedProps, connect } from 'react-redux';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import CustomerAccountDetailsCard from './CustomerAccountDetailsCard';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { translateCustomerAccountDetails } from './customerAccountDetailsHelper';
import { dataGeneration } from '../../../utils/constants';
import { fetchCustomerAccountListItems } from '../../../redux/customerAccountListSlice';

interface IProps
  extends IWithModalsProps,
    PropsFromRedux,
    RouteComponentProps<any> {}

/**
 * Class for showing the details of a specific customerAccount
 */
const CustomerAccountDetail = ({
  fetchCustomerAccountListItems,
  location,
  modalErrorHandler,
}: IProps) => {
  const fetchCustomerAccountData = (isSortOrFilterFetchType: boolean) => {
    fetchCustomerAccountListItems(isSortOrFilterFetchType, (error) => {
      modalErrorHandler(dataGeneration, error);
    });
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          {translateCustomerAccountDetails('accountDetails')}
        </HeaderTitle>
        {generateBreadcrumb(
          location.pathname,
          translateCustomerAccountDetails('customer')
        )}
      </Header>
      <CustomerAccountDetailsCard />
      <Link to="/customer/accounts">
        <Button
          color="primary"
          size="m"
          onClick={() => fetchCustomerAccountData(true)}
        >
          {translateCustomerAccountDetails('backToList')}
        </Button>
      </Link>
    </Container>
  );
};

const mapDispatchToProps = {
  fetchCustomerAccountListItems,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(CustomerAccountDetail)));
