import {
  IObjectInvolvedResponsible,
  IObjectNameAndId,
} from '../types/commonTypes';

/**
 * Function to check whether an object or array is empty.
 * Returns false if an object has properties or if an array has more than 1 item.
 * @param obj object to check
 */
export const isEmpty = (obj: any) => {
  if (!obj) {
    return true;
  }

  if (typeof obj === 'object') {
    return Array.isArray(obj)
      ? obj.length === 0
      : Object.keys(obj).length === 0;
  }

  return false;
};

export const isStringArraySimilar = (array1: string[], array2: string[]) =>
  array1.every((elem1) => array2.find((elem2) => elem1 === elem2)) &&
  array2.every((elem2) => array1.find((elem1) => elem2 === elem1));

/**
 * Sorts array by object dates from newest to oldest
 * @param {*} objectArray
 * @param {*} dateKey
 * @param {*} isDescending
 * @returns sorted date
 */
export const sortByDate = <T extends { [key: string]: any }>(
  objectArray: T[],
  dateKey = 'date',
  isDescending = true
): T[] => {
  if (objectArray.length > 0) {
    const multiplier = isDescending ? 1 : -1;

    return [...objectArray].sort(
      (object1, object2) =>
        multiplier *
        (new Date(object2[dateKey]).getTime() -
          new Date(object1[dateKey]).getTime())
    );
  }

  return [];
};

export const getRemovedObjectNameAndIdsFromList = (
  originalList: IObjectNameAndId[],
  selectedList: IObjectNameAndId[]
) =>
  originalList.filter(
    (originalItem) =>
      !selectedList.find((selectedItem) => originalItem.id === selectedItem.id)
  );

/**
 * Checks if involvedResponsibles is modified
 * @param newInvolvedResponsibles
 * @param currentInvolvedResponsibles
 * @returns boolean value
 */
export const isInvolvedResponsiblesNotModified = (
  newInvolvedResponsibles: IObjectInvolvedResponsible[],
  currentInvolvedResponsibles: IObjectInvolvedResponsible[]
) => {
  if (currentInvolvedResponsibles.length !== newInvolvedResponsibles.length) {
    return false;
  }

  const sortedOriginalInvolved = currentInvolvedResponsibles
    .slice()
    .sort(
      (a: IObjectInvolvedResponsible, b: IObjectInvolvedResponsible) =>
        a.id - b.id
    );
  const sortedEditedInvolved = newInvolvedResponsibles
    .slice()
    .sort(
      (a: IObjectInvolvedResponsible, b: IObjectInvolvedResponsible) =>
        a.id - b.id
    );

  for (let i = 0; i < sortedOriginalInvolved.length; i += 1) {
    const original = sortedOriginalInvolved[i];
    const edited = sortedEditedInvolved[i];

    if (
      original?.id !== edited?.id ||
      original?.name !== edited?.name ||
      original?.responsibleRole !== edited?.responsibleRole
    ) {
      return false;
    }
  }
  return true;
};
