import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import i18n from '../../i18n';

import { Spinner } from '../layout/Spinner';

import { modalCloseButton } from '../../utils/helpers/click';
import ModalAlert from './ModalAlert';
// A Modal that will show a children component(React.Fragment) in a modal dialog
const ModalForm = (props) => {
  const {
    modalTitle,
    eventOnSubmit,
    eventOnClose,
    isOpen,
    children,
    noCloseButton,
    centerTitle,
  } = props;

  const [modal, setModal] = useState(isOpen);
  const [alertOpen, setAlertOpen] = useState(false);

  const toggle = () => {
    setAlertOpen(true);
  };
  const closeAlert = () => {
    setAlertOpen(false);
  };
  const closeModal = async () => {
    eventOnClose && eventOnClose();
    setModal(!modal);
  };
  // This function runs on props change
  useEffect(() => {
    setModal(props.isOpen);
  }, [props.isOpen]);

  return (
    <Draggable handle=".handle">
      <Modal
        size={props.size}
        isOpen={modal}
        toggle={noCloseButton ? undefined : toggle}
      >
        <Row
          className="justify-content-center align-self-center w-100 text-center"
          style={{ zIndex: 999 }}
        >
          <Spinner />
        </Row>
        {centerTitle ? (
          <div style={{ textAlign: 'center', paddingTop: '10px' }}>
            <h3>{modalTitle}</h3>
          </div>
        ) : (
          <ModalHeader className="handle" close={modalCloseButton(toggle)}>
            {modalTitle}
          </ModalHeader>
        )}

        <ModalBody>
          {React.cloneElement(children ?? <React.Fragment></React.Fragment>, {
            externalOnSubmitEvent: eventOnSubmit,
          })}
        </ModalBody>
        <ModalAlert
          isOpen={alertOpen}
          toggle={toggle}
          modalTitle={i18n.t('ModalAlert.dataWillBeLost')}
          onClose={closeAlert}
          event={closeModal}
          modalBodyText={i18n.t('ModalAlert.areYouSureYouWantToLeave')}
        ></ModalAlert>
      </Modal>
    </Draggable>
  );
};

export default ModalForm;
