import React, { ChangeEvent, ReactElement } from 'react';
import { Button } from 'reactstrap';

import i18n from '../../i18n';
import TextareaCard from './TextareaCard';

interface IProps {
  description: string;
  setDescription: (description: string) => void;
  projectSharepoint?: string;
  fields?: {
    label: string;
    value: ReactElement | string;
  }[];
  disabled?: boolean;
}

const t = (key: string) => i18n.t(`ProjectOptionOverview.${key}`);

/**
 * Project Description -
 * Displays project description and sharepoint
 */
const ProjectDescriptionCard = ({
  description,
  setDescription,
  projectSharepoint,
  fields,
  disabled,
}: IProps) => {
  const cardFields = [
    {
      label: 'SharePoint',
      value: projectSharepoint || t('none'),
    },
    ...(fields || []),
  ];

  const footerButtons = (
    <Button color="primary" size="sm" disabled>
      {t('createSharePoint')}
    </Button>
  );

  const handleDescriptionChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setDescription(value);
  };

  return (
    <TextareaCard
      title={t('description')}
      value={description}
      onChange={handleDescriptionChange}
      fields={cardFields}
      footerButtons={footerButtons}
      {...(disabled && { disabled })}
      border
    />
  );
};

export default ProjectDescriptionCard;
