import React from 'react';
import { Button } from 'reactstrap';
/**
 * This file would be used for other helper functions for modals as well.
 */

/**
 * Function to apply to Modal Buttons to prevent triggering function multiple times
 * @param {*} event Click
 * @param {function} funcToLoad function that would be loaded
 */
export const clickHandler = (event: any, funcToLoad: (event: any) => any) => {
  if (event.detail === 1) {
    funcToLoad(event ?? null);
  }
};

/**
 * Renders the close button of Modal Headers that prevents multiple clicks
 * @param {function} toggleClose handles the visibility of the modal
 * @returns close button that uses clickHandler
 */
export const modalCloseButton = (toggleClose: () => void) => (
  <Button
    close
    aria-label="Close"
    onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
      clickHandler(event, toggleClose)
    }
  />
);
