import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Card, CardBody, Container } from 'reactstrap';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import CollapsibleActivityCard from './CollapsibleActivityCard';
import CollapsibleContactPersonCard from './CollapsibleContactPersonCard';
import CollapsibleCustomerSiteCard from './CollapsibleCustomerSiteCard';
import CollapsibleFileAttachmentCard from './CollapsibleFileAttachmentCard';
import CollapsibleProjectHistoryCard from './CollapsibleProjectHistoryCard';
import CustomerCard from './CustomerCard';
import CustomerDetailsContextProvider from './CustomerDetailsContextProvider';
import { t } from './customerDetailsHelper';

const CustomerDetail = () => {
  const { pathname } = useLocation();

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('customerDetails')}</HeaderTitle>
        {generateBreadcrumb(pathname, t('customer'))}
      </Header>
      <CustomerDetailsContextProvider>
        <CustomerCard />
        <Card>
          <CardBody>
            <CollapsibleContactPersonCard />
          </CardBody>
          <CardBody>
            <CollapsibleCustomerSiteCard />
          </CardBody>
          <CardBody>
            <CollapsibleFileAttachmentCard />
          </CardBody>
          <CardBody>
            <CollapsibleProjectHistoryCard />
          </CardBody>
          <CardBody>
            <CollapsibleActivityCard />
          </CardBody>
        </Card>
        <Link to="/customer/customers">
          <Button color="primary" size="m">
            {t('backToList')}
          </Button>
        </Link>
      </CustomerDetailsContextProvider>
    </Container>
  );
};

export default CustomerDetail;
