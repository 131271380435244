import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';

import { IOfferDetails } from '../../../utils/types/responseTypes';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { getOfferDetails } from '../../../services/api/offer';
import TableCard from '../../../components/cards/TableCard';
import { generateOfferTitle } from '../OfferModal/offerHelper';
import { t } from './projectDetailHelper';
import { ProjectDetailFormValues } from './ProjectDetailOverview';

interface IProps extends IWithModalsProps {
  projectFormValues: ProjectDetailFormValues;
  toggleOfferModal: (modalTitle: string, offer?: IOfferDetails) => void;
}

/**
 * Card to display ordered offers from project details
 */
const ProjectOrders = ({
  projectFormValues: { offers },
  toggleOfferModal,
  modalOkHandler,
  modalErrorHandler,
}: IProps) => {
  /**
   * A function that opens the budget details
   */
  const handleBudgetDetails = () => {
    const title = t('projectBudgetDetails');
    const body = t('underConstruction');
    modalOkHandler(title, body);
  };

  const handleOfferModal = (offerId: number) => {
    getOfferDetails(offerId)
      .then(({ data: response }) =>
        toggleOfferModal(
          generateOfferTitle(response.title, response.offerVersion),
          response
        )
      )
      .catch((error: AxiosError) => modalErrorHandler(error.message));
  };

  const footerButtons = (
    <Button color="primary" onClick={() => handleBudgetDetails()}>
      {t('projectShowDetails')}
    </Button>
  );

  const tableData = offers.map(
    ({ id, title, offerVersion, budget, currency }) => ({
      [t('offerName')]: (
        <Link
          to="#"
          className="wordWrap-class"
          onClick={() => {
            handleOfferModal(id);
          }}
        >
          {title}
        </Link>
      ),
      [t('version')]: `v${offerVersion}`,
      [t('budgetTotal')]: 'n.a.',
      [t('budgetAvailable')]: `${0 - budget} ${currency}`,
    })
  );

  return (
    <TableCard
      tableData={tableData}
      title={t('projectOrdersAndBudget')}
      footerButtons={footerButtons}
      noDataPlaceholder={t('noOrders')}
      leftTitle
      border
      striped
    />
  );
};

export default withModals(ProjectOrders);
