import React, { ChangeEvent, Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import DynamicTable from '../../../components/tables/DynamicTable';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { SKILLS_TYPE } from '../../../utils/enums/skill';
import { isEmpty } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { ISkill } from '../../../utils/types/modelTypes';
import { DEFAULT_LOAD_TIMEOUT } from '../../../utils/constants';

interface IProps {
  skills: ISkill[];
  fetchData: () => void;
  hasMoreData: boolean;
  fetchedSkills: ISkill[];
  setSkillsData: (fetchedSkills: ISkill[]) => void;
  onFilterUpdate: (filters: any) => void;
  sortData: (sortBy: string | null) => void;
  sortType: string | null;
  sortBy: string | null;
  showFilters: boolean;
  removedSkillId: number | undefined;
  lastSkillFromPage: ISkill | null;
  onDelete: (skill: number | undefined) => void;
  modifiedSkill: ISkill | null;
}

interface IState {
  preparedSkills: SkillEntry[];
  skill: string | null;
  skillTypes: Array<{ value: string }> | undefined;
}

interface SkillEntry {
  id: number | undefined;
  skill: string | undefined;
  type: string;
  wikipediaLink: JSX.Element;
  menuComponent: JSX.Element;
}

const skillTypes = Object.values(SKILLS_TYPE).map((skills) => ({
  value: skills.code,
  label: skills.name,
}));

function areDeepEqual(a: any, b: any): boolean {
  if (a === b) {
    return true;
  }

  if (a && b && typeof a === 'object' && typeof b === 'object') {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (const key of keysA) {
      if (
        !Object.prototype.hasOwnProperty.call(b, key) ||
        !areDeepEqual(a[key], b[key])
      ) {
        return false;
      }
    }

    return true;
  }

  return false;
}

class SkillListTable extends Component<IProps, IState> {
  timeout: NodeJS.Timeout | number;

  constructor(props: IProps) {
    super(props);
    this.state = {
      preparedSkills: [],

      // Filters
      skill: null,
      skillTypes: [],
    };
    this.timeout = 0;
  }

  createSkillsEntry = (skill: ISkill) => {
    const { onDelete } = this.props;

    return {
      id: skill.id,
      skill: skill.skill,
      type: skill?.type,
      wikipediaLink: (
        <a
          href={skill?.wikipediaLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {skill?.wikipediaLink}
        </a>
      ),
      menuComponent: (
        <div>
          <Link to={`/settings/skill-update/${skill?.id}`}>
            <Button color="primary">
              {generateTitle(BUTTON_TITLE_ENUM.UPDATE.code)}
            </Button>
          </Link>{' '}
          {onDelete && (
            <Button color="primary" onClick={() => onDelete(skill?.id)}>
              {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
            </Button>
          )}
        </div>
      ),
    };
  };

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { skills, removedSkillId, lastSkillFromPage, modifiedSkill } =
      this.props;
    const { preparedSkills } = this.state;

    const skillsHaveChanged = !areDeepEqual(skills, prevProps.skills);
    const preparedSkillsHaveChanged = !areDeepEqual(
      preparedSkills,
      prevState.preparedSkills
    );

    if (
      isEmpty(skills) &&
      !isEmpty(preparedSkills) &&
      (skillsHaveChanged || preparedSkillsHaveChanged)
    ) {
      this.setState({
        preparedSkills: [],
      });
    }

    if (prevProps.removedSkillId !== removedSkillId) {
      let newPreparedSkills = this.state.preparedSkills.filter(
        (skill) => skill?.id !== removedSkillId
      );

      if (prevProps.lastSkillFromPage !== lastSkillFromPage) {
        if (lastSkillFromPage !== null) {
          const skillsEntry = [this.createSkillsEntry(lastSkillFromPage)];
          newPreparedSkills = [...newPreparedSkills, ...skillsEntry];
        }
      }
      this.setState({
        preparedSkills: newPreparedSkills,
      });
    }
    if (prevProps.modifiedSkill !== modifiedSkill) {
      const preparedSkills = this.state.preparedSkills;
      if (modifiedSkill) {
        const skillEntry = this.createSkillsEntry(modifiedSkill);
        const skillIndex = preparedSkills.findIndex(
          (skill) =>
            parseInt(String(skill?.id), 10) ===
            parseInt(String(skillEntry?.id), 10)
        );

        if (skillIndex >= 0) {
          preparedSkills[skillIndex] = skillEntry;
          this.setState({
            preparedSkills,
          });
        }
      }
    }
  }

  onFilterUpdate = () => {
    const { onFilterUpdate } = this.props;
    const { skill, skillTypes: stateSkillTypes } = this.state;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      if (onFilterUpdate) {
        const filters = {
          skill,
          skillTypes: stateSkillTypes?.map((item) => item.value),
        };
        onFilterUpdate(filters);
      }
    }, DEFAULT_LOAD_TIMEOUT);
  };

  handleSkillFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.setState({ skill: event?.target?.value ?? null }, () =>
      this.onFilterUpdate()
    );
  };

  handleTypeFilterchange = (event: IDropdownOption[]) => {
    this.setState({ skillTypes: event }, () => {
      this.onFilterUpdate();
    });
  };

  removeDuplicates = (newTableData: SkillEntry[]) => {
    const { preparedSkills } = this.state;
    const initialSkillSet = new Set(preparedSkills.map((skill) => skill?.id));
    return [
      ...preparedSkills,
      ...newTableData.filter((skill) => !initialSkillSet.has(skill?.id)),
    ];
  };

  preparedTableData = (
    preparedSkills: SkillEntry[],
    fetchedSkills: ISkill[]
  ) => {
    const { setSkillsData } = this.props;

    if (Array.isArray(fetchedSkills) && fetchedSkills.length > 0) {
      const newTableData: SkillEntry[] = [];
      fetchedSkills.forEach((skill) => {
        const skillEntry = this.createSkillsEntry(skill);
        newTableData.push(skillEntry);
      });

      const newPreparedSkills = this.removeDuplicates(newTableData);

      if (setSkillsData) {
        setSkillsData(fetchedSkills);
      }

      newPreparedSkills.sort((a, b) =>
        (a.skill ?? '').localeCompare(b.skill ?? '')
      );

      this.setState({
        preparedSkills: newPreparedSkills,
      });

      return preparedSkills;
    } else {
      return preparedSkills;
    }
  };

  render() {
    const {
      fetchedSkills,
      hasMoreData,
      sortBy,
      sortType,
      showFilters,
      sortData,
      fetchData,
    } = this.props;
    const { preparedSkills, skill, skillTypes: stateSkillTypes } = this.state;
    const preparedColumns = [
      {
        type: 'data',
        header: 'Skill',
        accessor: 'skill',
        show: 'true',
        sortFunc: sortData ?? null,
        filterFunc: this.handleSkillFilterChange,
        filterValue: skill,
        filterComponentWidth: '220px',
        width: '250px',
      },
      {
        type: 'data',
        header: 'Skill Type',
        accessor: 'type',
        show: 'true',
        filterFunc: this.handleTypeFilterchange,
        filterValue: stateSkillTypes,
        filterType: 'dropdown',
        filterOptions: skillTypes,
        filterComponentWidth: '220px',
        width: '200px',
      },
      {
        type: 'data',
        header: 'Wikipedia Link',
        accessor: 'wikipediaLink',
        show: 'true',
        width: '200px',
      },
      {
        type: 'Dropdown',
        header: 'Menu',
        accessor: 'menuComponent',
        show: 'true',
        width: '110px',
      },
    ];
    return (
      <Fragment>
        <DynamicTable
          data={this.preparedTableData(preparedSkills, fetchedSkills)}
          columns={preparedColumns}
          fetchData={fetchData}
          hasMoreData={hasMoreData}
          infiniteScroll
          sortType={sortType}
          sortBy={sortBy}
          enableFilterFunction
          showFilters={showFilters}
        />
      </Fragment>
    );
  }
}

export default SkillListTable;
