import React, { ReactElement, ReactNode, useState } from 'react';
import { Subtract } from 'utility-types';

import i18n from '../i18n';
import ModalConfirm from '../components/modals/ModalConfirm';
import ModalDelete from '../components/modals/ModalDelete';
import ModalError from '../components/modals/ModalError';
import ModalForm from '../components/modals/ModalForm';
import ModalOK from '../components/modals/ModalOK';

const t = (key: string) => i18n.t(`Modals.${key}`);

export interface IWithModalsProps {
  modalConfirmHandler: (
    modalTitle: string,
    modalBodyText: JSX.Element | string,
    modalConfirmEvent: (() => Promise<void>) | (() => void)
  ) => void;
  modalDeleteHandler: (
    modalTitle: string,
    modalBodyText: JSX.Element | string,
    modalDeleteEvent: (() => Promise<void>) | (() => void)
  ) => void;
  modalErrorHandler: (mainError: string, errorObject?: any) => void;
  modalFormHandler: (
    modalTitle: ReactNode,
    formComponent: ReactElement,
    size?: string,
    noCloseButton?: boolean,
    centerTitle?: boolean,
    eventOnSubmit?: () => void
  ) => void;
  toggleModalForm: () => void;
  modalOkHandler: (
    modalTitle: string,
    modalBodyText: JSX.Element | string
  ) => void;
}

/**
 * Higher-order component to inject components with modals
 *
 * @param WrappedComponent component to be injected with modals
 * @returns wrapped component with modals
 */
const withModals = <WrappedComponentProps extends IWithModalsProps>(
  WrappedComponent: React.ComponentType<WrappedComponentProps>
): React.FC<Subtract<WrappedComponentProps, IWithModalsProps>> =>
  function WithModalsWrapper(props) {
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalConfirmProps, setModalConfirmProps] = useState<{
      modalTitle: string;
      modalBodyText: string;
      event: (() => Promise<void>) | (() => void);
    }>({
      modalTitle: '',
      modalBodyText: '',
      event: () => {
        // Empty function default
      },
    });

    const [showModalDelete, setShowModalDelete] = useState(false);
    const [modalDeleteProps, setModalDeleteProps] = useState<{
      modalTitle: string;
      modalBodyText: JSX.Element | string;
      event: (() => Promise<void>) | (() => void);
    }>({
      modalTitle: '',
      modalBodyText: '',
      event: () => {
        // Empty function default
      },
    });

    const [showModalError, setShowModalError] = useState(false);
    const [modalErrorProps, setModalErrorProps] = useState({
      mainError: '',
      errorReason: '',
      errorResponse: '',
    });

    const [showModalForm, setShowModalForm] = useState(false);
    const [modalFormProps, setModalFormProps] = useState<{
      modalTitle: ReactNode;
      size: string;
      noCloseButton: boolean;
      centerTitle: boolean;
      eventOnSubmit: () => void;
    }>({
      modalTitle: '',
      size: '',
      noCloseButton: false,
      centerTitle: false,
      eventOnSubmit: () => {
        // Empty function default
      },
    });
    const [modalFormComponent, setModalFormComponent] = useState(<></>);

    const [showModalOk, setShowModalOk] = useState(false);
    const [modalOkProps, setModalOkProps] = useState<{
      modalTitle: string;
      modalBodyText: JSX.Element | string;
    }>({
      modalTitle: '',
      modalBodyText: '',
    });

    const toggleModalConfirm = () => {
      setShowModalConfirm(!showModalConfirm);
    };

    const modalConfirmHandler = (
      modalTitle: string,
      modalBodyText: string,
      modalConfirmEvent: (() => Promise<void>) | (() => void)
    ) => {
      setModalConfirmProps({
        ...modalConfirmProps,
        modalTitle,
        modalBodyText,
        event: modalConfirmEvent,
      });
      toggleModalConfirm();
    };

    const toggleModalDelete = () => {
      setShowModalDelete((showModalDelete) => !showModalDelete);
    };

    const modalDeleteHandler = (
      modalTitle: string,
      modalBodyText: JSX.Element | string,
      modalDeleteEvent: (() => Promise<void>) | (() => void)
    ) => {
      setModalDeleteProps({
        ...modalDeleteProps,
        modalTitle,
        modalBodyText,
        event: modalDeleteEvent,
      });
      toggleModalDelete();
    };

    const toggleModalError = () => {
      setShowModalError(!showModalError);
    };

    const modalErrorHandler = (mainError: string, errorObject?: any) => {
      setModalErrorProps({
        ...modalErrorProps,
        mainError,
        ...(errorObject && {
          errorReason:
            errorObject?.response?.data?.detail ??
            errorObject?.response?.data?.title ??
            t('serverFailed'),
          errorResponse: errorObject?.message ?? t('unexpectedError'),
        }),
      });
      toggleModalError();
    };

    const toggleModalForm = () => {
      setShowModalForm((showModalForm) => !showModalForm);
    };

    const modalFormHandler = (
      modalTitle: ReactNode,
      formComponent: ReactElement,
      size?: string,
      noCloseButton?: boolean,
      centerTitle?: boolean,
      eventOnSubmit?: () => void
    ) => {
      setModalFormProps({
        modalTitle,
        size: size ?? '',
        noCloseButton: noCloseButton ?? false,
        centerTitle: centerTitle ?? false,
        eventOnSubmit:
          eventOnSubmit ??
          (() => {
            // Empty function default
          }),
      });
      setModalFormComponent(formComponent);
      toggleModalForm();
    };

    const toggleModalOk = () => {
      setShowModalOk(!showModalOk);
    };

    const modalOkHandler = (
      modalTitle: string,
      modalBodyText: JSX.Element | string
    ) => {
      setModalOkProps({
        ...modalOkProps,
        modalTitle,
        modalBodyText,
      });
      toggleModalOk();
    };

    return (
      <>
        <WrappedComponent
          {...(props as WrappedComponentProps)}
          modalErrorHandler={modalErrorHandler}
          modalOkHandler={modalOkHandler}
          modalConfirmHandler={modalConfirmHandler}
          modalDeleteHandler={modalDeleteHandler}
          modalFormHandler={modalFormHandler}
          toggleModalForm={toggleModalForm}
        />
        <ModalConfirm
          isOpen={showModalConfirm}
          onClose={toggleModalConfirm}
          {...modalConfirmProps}
        />
        <ModalDelete
          isOpen={showModalDelete}
          onClose={toggleModalDelete}
          {...modalDeleteProps}
        />
        <ModalError
          modalTitle={t('error')}
          isOpen={showModalError}
          onClose={toggleModalError}
          {...modalErrorProps}
        />
        <ModalForm
          isOpen={showModalForm}
          eventOnClose={toggleModalForm}
          {...modalFormProps}
        >
          {modalFormComponent}
        </ModalForm>
        <ModalOK
          isOpen={showModalOk}
          onClose={toggleModalOk}
          {...modalOkProps}
        />
      </>
    );
  };

export default withModals;
