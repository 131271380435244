import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ConnectedProps, connect } from 'react-redux';

import i18n from '../../../i18n';
import {
  checkIfCurrentMonthAndYear,
  getMonthAndYearTitle,
  t,
} from './utilizationHelper';
import UtilizationTableRow from './UtilizationTableRow';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IUtilizationListItem } from '../../../utils/types/responseTypes';
import { IFilterOptions } from './UtilizationOverview';
import { getUtilizationList } from '../../../services/api/resourcePlanner';
import { RootState } from '../../../redux/store';
import { IObjectTimespan } from '../ResourceOverview/resourceOverviewHelper';

interface IProps extends IWithModalsProps, PropsFromRedux {
  monthRange: IObjectTimespan[];
  filters: IFilterOptions;
}

/**
 * UtilizationTable
 * Displays the table of the employees utilization for 3 months
 */
const UtilizationTable = ({
  monthRange,
  filters,
  currentUser,
  modalErrorHandler,
}: IProps) => {
  const { id: currentUserId } = currentUser;
  const {
    responsibles: responsiblesFilter,
    bookedPercentage,
    month: monthFilter,
  } = filters;
  const [utilizations, setUtilizations] = useState<IUtilizationListItem[]>([]);

  const fetchUtilizationList = async () => {
    try {
      const { data: utilizations } = await getUtilizationList({
        ['responsibleIds']:
          responsiblesFilter.length <= 0
            ? [currentUserId].join(',')
            : responsiblesFilter.map(({ value }) => value).join(','),
        ['booking']: bookedPercentage.toString(),
        ['month']: monthFilter.value.toString(),
      });
      setUtilizations(utilizations);
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveEmployees'), error);
    }
  };

  useEffect(() => {
    fetchUtilizationList();
  }, [monthRange]);

  return (
    <InfiniteScroll
      dataLength={0}
      next={() => null}
      hasMore={false}
      endMessage={
        <p className="text-center mt-3">{i18n.t('DynamicTable.endOfList')}</p>
      }
      loader={
        <p className="text-center mt-3">{i18n.t('DynamicTable.loading')}</p>
      }
    >
      <table className="w-100 border-white text-center">
        <thead>
          <tr>
            <td colSpan={2}>{''}</td>
            {monthRange.map(({ month, year, label }) => (
              <td
                key={`${label}-${month}`}
                className="stickyHeaderMonthOrWeek py-2"
                style={{
                  fontWeight: checkIfCurrentMonthAndYear(month, year)
                    ? 'bold'
                    : 'normal',
                }}
              >
                {getMonthAndYearTitle(label, year)}
              </td>
            ))}
            <td
              className="utilizationTableHeader py-2"
              style={{ minWidth: '90px' }}
            >
              {t('invResponsible')}
            </td>
            <td
              className="utilizationTableHeader py-2"
              style={{ minWidth: '90px' }}
            >
              {t('latestUpdate')}
            </td>
            <td
              className="utilizationTableHeader py-2"
              style={{ minWidth: '90px' }}
            >
              {t('action')}
            </td>
            <td
              className="utilizationTableHeader"
              style={{ minWidth: '300px' }}
            >
              {t('comments')}
            </td>
          </tr>
        </thead>
        <tbody>
          {utilizations.map((employeeUtilization) => (
            <UtilizationTableRow
              key={`employee-${employeeUtilization.employee.id}`}
              employeeUtilization={employeeUtilization}
              monthRange={monthRange}
              setUtilizations={setUtilizations}
              fetchUtilizations={fetchUtilizationList}
            />
          ))}
        </tbody>
      </table>
    </InfiniteScroll>
  );
};

const mapStateToProps = (store: RootState) => ({
  currentUser: store.account.employeeDetails,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withModals(connector(UtilizationTable));
