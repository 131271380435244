import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IServiceConfiguration } from '../utils/types/modelTypes';
import { IConfigsState } from '../utils/types/stateTypes';

const initialState: IConfigsState = {
  configs: [],
};

export const configsSlice = createSlice({
  name: 'configs',
  initialState,
  reducers: {
    setConfigs: (
      state,
      { payload }: PayloadAction<IServiceConfiguration[]>
    ) => ({
      ...state,
      configs: payload,
    }),
  },
});

export const { setConfigs } = configsSlice.actions;

export default configsSlice.reducer;
