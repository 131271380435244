import React, { useContext } from 'react';

import CollapsibleFilesCard from '../../../components/collapsibleCards/CollapsibleFilesCard';
import { IFileAttachment } from '../../../utils/types/modelTypes';
import { CustomerDetailsContext } from './CustomerDetailsContextProvider';

const CollapsibleFileAttachmentCard = () => {
  const { customerDetails, setCustomerDetails } = useContext(
    CustomerDetailsContext
  );

  const { id, fileAttachments } = customerDetails;

  const handleSaveFile = (file: IFileAttachment) => {
    const { fileName, description, fileObject, fileObjectContentType } = file;
    const newFile = {
      id: file.id ?? 0,
      fileName,
      description: description ?? '',
      fileObject,
      fileObjectContentType: fileObjectContentType ?? '',
    };
    const modifiedFileAttachments = [...fileAttachments];
    const index = fileAttachments.findIndex(({ id }) => id === file.id);

    if (index >= 0) {
      modifiedFileAttachments.splice(index, 1, newFile);
    } else {
      modifiedFileAttachments.push(newFile);
    }

    setCustomerDetails((details) => ({
      ...details,
      fileAttachments: modifiedFileAttachments,
    }));
  };

  const handleDeleteFile = (fileId: number) => {
    const modifiedFileAttachments = [...fileAttachments];
    modifiedFileAttachments.splice(
      fileAttachments.findIndex(({ id }) => id === fileId),
      1
    );

    setCustomerDetails((details) => ({
      ...details,
      fileAttachments: modifiedFileAttachments,
    }));
  };

  return (
    <CollapsibleFilesCard
      fileAttachments={fileAttachments}
      objectId={id}
      objectType="CUSTOMER"
      onSave={handleSaveFile}
      onDelete={handleDeleteFile}
    />
  );
};

export default CollapsibleFileAttachmentCard;
