import store from '../../redux/store';
import { IServiceConfiguration } from '../types/modelTypes';

export function checkConfiguration(
  service: string,
  key: string,
  value: string
) {
  const configurations: IServiceConfiguration[] =
    store.getState().configs.configs;
  let configByService = false;
  if (Array.isArray(configurations) && configurations?.length > 0) {
    configByService = configurations.some(
      (config) =>
        config.service.includes(service) &&
        config.configKey.includes(key) &&
        config.configValue &&
        config.configValue.includes(value)
    );
  }

  return configByService;
}

export function findValueFromConfiguration(service: string, key: string) {
  const configurations = store.getState().configs.configs;
  const selectedConfiguration = configurations.find(
    (configuration) =>
      configuration.service === service && configuration.configKey === key
  );
  if (selectedConfiguration) {
    return selectedConfiguration?.configValue;
  }

  return null;
}
