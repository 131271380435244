import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { usePromiseTracker } from 'react-promise-tracker';
import '../../assets/css/spinner.css';

//This class is for the spinner used in loading the data during api calls
export const Spinner = () => {
  const { promiseInProgress } = usePromiseTracker({ delay: 1000 });

  return (
    promiseInProgress && (
      <div className="spinner">
        <ThreeDots color="#3e4676" height="100" width="100" visible />
      </div>
    )
  );
};
