import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { dataGeneration } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { errorHandler, isEmpty } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import ModalOK from '../../../components/modals/ModalOK';
import AddOrUpdateSector from './AddOrUpdateSector';

/*
 * Class that shows all the sectors
 */
class SectorListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectors: [],
      showModal: false,
      showModalError: false,

      showInputForm: false,
      modalForm: null,
      modalTitle: '',
      showModalOk: false,
    };
    this.modalEvent = null;
    this.message = '';
    this.warningMessage = '';
  }

  t(keyName) {
    return i18n.t('SectorListView.' + keyName);
  }

  componentDidMount() {
    axios.sales
      .get('sectors')
      .then((response) => {
        if (!isEmpty(response.data)) {
          let sectors = response.data.sort((a, b) =>
            a?.sector > b?.sector ? 1 : -1
          );
          this.setState({ sectors: sectors });
        }
      })
      .catch((error) => {
        this.message = errorHandler(
          dataGeneration,
          error,
          this.t('failedToGetSectors')
        );
        this.toggleModalError();
      });
  }

  /**
   * Toggles error dialog modal
   */
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  /**
   * Toggles modal
   */
  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  /**
   * Toggles input modal
   */
  toggleInputModal = () => {
    this.setState({ showInputForm: !this.state.showInputForm });
  };

  /**
   * Handles showing of modal for adding or updating a sector
   */
  showInputModal = () => {
    this.setState({
      modalForm: <AddOrUpdateSector onSave={this.onSaveType} />,
      modalTitle: this.t('addSector'),
    });
    this.toggleInputModal();
  };

  toggleModalOk = () => {
    this.setState({
      showModalOk: !this.state.showModalOk,
      modalTitle: this.t('warning'),
    });
  };

  /**
   * Handles saving of sector
   */
  onSaveType = () => {
    this.toggleInputModal();
    this.componentDidMount();
  };

  /**
   * Handles deletion of a sector
   * @param {*} sectorId
   */
  deleteSector = (sectorId) => {
    this.toggleModal();
    this.modalEvent = () =>
      axios.sales
        .delete(`sectors/${sectorId}`)
        .then(() => {
          let newSectors = this.state.sectors.filter((sector) => {
            return sector.id !== sectorId;
          });
          this.setState({ sectors: newSectors });
        })
        .catch((error) => {
          this.warningMessage = this.t('cannotDeleteSector');
          this.toggleModalOk();
        });
  };

  /**
   * Handles preparation of data for the dynamic table
   * @param {*} salesData
   * @returns table data
   */
  preparedTableData = (salesData) => {
    const newTableData = [];
    if (!isEmpty(salesData)) {
      salesData.forEach((sector) => {
        const entry = {
          id: sector?.id,
          sector: sector?.sector,
          deleteButton: (
            <>
              <Button
                color="primary"
                onClick={() => this.deleteSector(sector.id)}
              >
                {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
              </Button>
            </>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  render() {
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('sector'),
        accessor: 'sector',
        show: 'true',
        link: '/settings/sales/sectors-update/',
        linkAccessor: 'id',
      },
      {
        type: 'data',
        header: this.t('delete'),
        accessor: 'deleteButton',
        show: 'true',
        alignRight: 'true',
      },
    ];
    return (
      <Container fluid>
        <div>
          <Button
            color="primary"
            className="float-end"
            onClick={() => this.showInputModal()}
          >
            {generateTitle(BUTTON_TITLE_ENUM.ADD.code, this.t('add'))}
          </Button>
          <br />
          <br />
        </div>

        <DynamicTable
          data={this.preparedTableData(this.state.sectors)}
          columns={preparedColumns}
        />

        <ModalDelete
          isOpen={this.state.showModal}
          event={this.modalEvent}
          onClose={this.toggleModal}
          modalTitle={this.t('sectorDelete')}
          modalBodyText={this.t('sectorDeleteConfirm')}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.message?.mainError}
          errorReason={this.message?.errorReason}
          errorResponse={this.message?.errorResponse}
          modalTitle={this.t('error')}
        />
        <ModalForm
          isOpen={this.state.showInputForm}
          eventOnClose={this.toggleInputModal}
          ref={this.state.modalForm}
          modalTitle={this.state.modalTitle}
        >
          {this.state.modalForm}
        </ModalForm>
        <ModalOK
          isOpen={this.state.showModalOk}
          onClose={this.toggleModalOk}
          modalBodyText={this.warningMessage}
          modalTitle={this.state.modalTitle}
        />
      </Container>
    );
  }
}

export default withRouter(SectorListView);
