import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';

import Auth from '../../../services/axios/Auth';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { Ownership } from '../../../utils/enums/ownership';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { AccessType, PERMISSION_URI } from '../../../utils/enums/permission';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { isEmpty } from '../../../utils/helpers/array';
import { generateTitle } from '../../../utils/helpers/icon';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  deleteCustomer,
  fetchCustomerListItem,
  fetchEmployeesWithCustomerListPermission,
  mergeSelectedCustomers,
  setFilters,
  toggleFilter,
} from '../../../redux/customerListSlice';
import { RootState } from '../../../redux/store';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import FadeAlert from '../../../components/layout/FadeAlert';
import ResponsibleOwnershipDropdown from '../../../components/dropdowns/ResponsibleOwnershipDropdown';
import { t } from './customerListHelpers';
import CustomerListTable from './CustomerListTable';
import SelectCustomerToKeep from './SelectCustomerToKeep';
import AddCustomerModal from '../../../components/form/AddCustomerModal';
import { ICustomerDetail } from '../../../utils/types/responseTypes';

interface IProps
  extends IWithModalsProps,
    PropsFromRedux,
    RouteComponentProps {}

/**
 * Customer List page.
 * Contains Customer List table, creation of new customer, merging of selected customers,
 * and toggling of table filter.
 */
const CustomerList = ({
  // WithModals
  modalDeleteHandler,
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
  modalOkHandler,
  // Redux State
  customerListItems,
  employeesWithCustomerListPermission,
  filters,
  // Redux actions
  setFilters,
  toggleFilter,
  // Thunks
  fetchCustomerListItem,
  fetchEmployeesWithCustomerListPermission,
  deleteCustomer,
  mergeSelectedCustomers,
  // Route
  location,
}: IProps) => {
  const [mergeDuplicatesMode, setMergeDuplicatesMode] = useState(false);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<
    { id: number }[]
  >([]);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');

  // Fetch employees with customer list permission for filter dropdown on component mount
  useEffect(() => {
    if (isEmpty(employeesWithCustomerListPermission)) {
      fetchEmployeesWithCustomerListPermission((error) => {
        modalErrorHandler(t('failedToRetrieveEmployees'), error);
      });
    }
  }, []);

  /**
   * Gets the newly saved customer
   * @param customer
   */
  const handleSaveNewCustomer = ({ id }: ICustomerDetail) => {
    fetchCustomerListItem(id, (error) => {
      modalErrorHandler(t('failedToAddCustomerToList'), error);
    });

    toggleModalForm();
  };

  /**
   * Handles the adding of customer
   */
  const handleAddNewCustomer = () => {
    modalFormHandler(
      generateTitle(BUTTON_TITLE_ENUM.ADD.code, t('addCustomer')),
      <AddCustomerModal
        onSave={handleSaveNewCustomer}
        onCancel={toggleModalForm}
      />,
      'xl'
    );
  };

  /**
   * Handles the deletion of a customer
   * @param customerId
   */
  const handleRemoveCustomer = (customerId: number) => {
    modalDeleteHandler(t('deleteCustomer'), t('deleteCustomerConfirm'), () => {
      deleteCustomer(
        customerId,
        () => {
          setDeleteSuccessMessage(`${t('customerDeleted')} - ${customerId}.`);
        },
        (error) => {
          modalErrorHandler(t('failedToDeleteCustomer'), error);
        }
      );
    });
  };

  /**
   * Calls the thunk for merging the selected customers
   * @param customerIdToKeep
   */
  const mergeCustomers = (customerIdToKeep: number) => {
    const flattenedSelectedCustomerIds = selectedCustomerIds.map(
      ({ id }) => id
    );

    mergeSelectedCustomers(
      customerIdToKeep,
      flattenedSelectedCustomerIds,
      (error) => {
        modalErrorHandler(t('unableToMergeCustomers'), error);
      }
    );

    toggleModalForm();
  };

  /**
   * Handles the merging of customers
   *
   * Check if there are more than 1 selected customers, else show message that at least 2 is needed
   */
  const handleMergeCustomers = () => {
    if (selectedCustomerIds.length > 1) {
      const selectedCustomers = customerListItems.filter(({ id }) =>
        selectedCustomerIds.some(({ id: selectedId }) => id === selectedId)
      );

      modalFormHandler(
        t('selectCustomerToKeep'),
        <SelectCustomerToKeep
          selectedCustomers={selectedCustomers}
          onSave={mergeCustomers}
          onCancel={() => toggleModalForm()}
        />,
        'xl'
      );
    } else {
      modalOkHandler(
        t('insufficientCustomers'),
        t('selectAtleastTwoCustomers')
      );
    }
  };

  /**
   * Handles the values selected in the ownership filter
   * @param ownership
   */
  const handleOwnershipFilter = (ownership: Ownership | Ownership[]) => {
    if (
      ownership === Ownership.ALL ||
      ownership === Ownership.ALL.toLowerCase()
    ) {
      setFilters({
        ...filters,
        ownership: [],
        responsibleWithPermission: null,
      });
    } else {
      setFilters({
        ...filters,
        ownership: Array.isArray(ownership) ? ownership : [ownership],
      });
    }
  };

  /**
   * Handles the values selected in the responsible filter
   * @param responsible
   */
  const handleResponsibleFilter = (responsible: IDropdownOption<number>) => {
    setFilters({
      ...filters,
      responsibleWithPermission: responsible,
    });
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('customers')}</HeaderTitle>
        {generateBreadcrumb(location.pathname, t('customer'))}
      </Header>
      <Card>
        <CardHeader>
          {deleteSuccessMessage && (
            <FadeAlert color="success">{deleteSuccessMessage}</FadeAlert>
          )}
          {mergeDuplicatesMode ? (
            <>
              <div
                className="card-actions float-end"
                style={{ paddingRight: '5px' }}
              >
                <Button
                  color="primary"
                  size="m"
                  onClick={() => {
                    setMergeDuplicatesMode(false);
                    setSelectedCustomerIds([]);
                  }}
                >
                  {generateTitle(BUTTON_TITLE_ENUM.EXIT.code, t('cancel'))}
                </Button>
              </div>
              <div
                className="card-actions float-end"
                style={{ paddingRight: '5px' }}
              >
                <Button
                  color="primary"
                  className="float-end"
                  size="m"
                  onClick={() => handleMergeCustomers()}
                >
                  {t('mergeSelected')}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div
                className="card-actions float-end"
                style={{ paddingRight: '5px' }}
              >
                <Button
                  color="primary"
                  className="fontAwesomeIconAsButton"
                  onClick={() => toggleFilter()}
                  aria-label="button-filter"
                >
                  <FontAwesomeIcon icon={faFilter} />
                </Button>{' '}
                <Button
                  color="primary"
                  onClick={() => setMergeDuplicatesMode(true)}
                >
                  {t('mergeDuplicates')}
                </Button>{' '}
                {Auth.hasPermission(
                  [PERMISSION_URI.customerList.readWrite.uri],
                  [AccessType.READWRITE]
                ) && (
                  <Button
                    color="primary"
                    size="m"
                    onClick={() => handleAddNewCustomer()}
                  >
                    {generateTitle(
                      BUTTON_TITLE_ENUM.ADD.code,
                      t('addCustomer')
                    )}
                  </Button>
                )}
              </div>
              <div
                className="card-actions float-end"
                style={{ paddingRight: '5px' }}
              />
            </>
          )}

          <CardTitle className="mb-0">
            <h1>{t('customersList')}</h1>
          </CardTitle>
        </CardHeader>{' '}
        <CardBody>
          {!mergeDuplicatesMode && (
            <>
              <Row>
                <Col md={4}>
                  <ResponsibleOwnershipDropdown
                    includeResponsibleAndInvolvedOption
                    value={filters.ownership}
                    onChange={handleOwnershipFilter}
                  />
                </Col>
                {Auth.hasPermission(
                  [PERMISSION_URI.fullCustomerList.read.uri],
                  [AccessType.READ]
                ) && (
                  <Col md={4}>
                    <Select
                      value={filters.responsibleWithPermission}
                      options={employeesWithCustomerListPermission}
                      onChange={handleResponsibleFilter}
                      styles={{
                        menu: (styles: {
                          [key: string]: React.CSSProperties;
                        }) => ({ ...styles, zIndex: 100 }),
                      }}
                      isDisabled={isEmpty(filters.ownership)}
                    />
                  </Col>
                )}
              </Row>
              <br />
            </>
          )}
          <CustomerListTable
            mergeDuplicatesMode={mergeDuplicatesMode}
            selectedCustomerIds={selectedCustomerIds}
            setSelectedCustomerIds={(selectedCustomerIds) => {
              setSelectedCustomerIds([...selectedCustomerIds]);
            }}
            onDelete={handleRemoveCustomer}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  customerListItems: store.customerList.listItems,
  employeesWithCustomerListPermission:
    store.customerList.additionalState?.employeesWithPermission,
  filters: store.customerList.filters,
});

const mapDispatchToProps = {
  deleteCustomer,
  fetchCustomerListItem,
  fetchEmployeesWithCustomerListPermission,
  mergeSelectedCustomers,
  setFilters,
  toggleFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(CustomerList)));
