import axios, { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import {
  IResourceListItem,
  IUtilizationActivity,
  IUtilizationListItem,
} from '../../utils/types/responseTypes';
import { urlBuilder } from '../../utils/helpers/url';
import { Url } from './constants';
import {
  IObjectUtilizationComment,
  IObjectWorkload,
} from '../../utils/types/commonTypes';

export const getUtilizationList = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IUtilizationListItem[]>>(
    axios.get(urlBuilder(Url.UTILIZATION, queryParameters))
  );

export const getResourceList = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<IResourceListItem[]>>(
    axios.get(urlBuilder(Url.RESOURCE, queryParameters))
  );

export const updateWorkloadState = (updatedWorkload: IObjectWorkload) =>
  trackPromise<AxiosResponse<IObjectWorkload>>(
    axios.patch(urlBuilder(Url.WORKLOAD_UTILIZATION, {}), updatedWorkload)
  );

export const saveComment = (utilizationComment: IUtilizationActivity) =>
  trackPromise<AxiosResponse<IObjectUtilizationComment>>(
    axios.post(urlBuilder(Url.UTILIZATION_ACTIVITY, {}), utilizationComment)
  );

export const updateEmployeeLatestDate = (employeeId: number) =>
  trackPromise<AxiosResponse<string>>(
    axios.patch(urlBuilder(`${Url.EMPLOYEES}/change-date/${employeeId}`, {}))
  );

export const updateEmployeeAction = (employeeId: number, action: string) =>
  trackPromise<AxiosResponse<string>>(
    axios.patch(
      urlBuilder(`${Url.EMPLOYEES}/change-action/${employeeId}`, {}),
      action,
      { headers: { 'Content-Type': 'text/plain' } }
    )
  );
