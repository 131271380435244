import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Input, Table, Row } from 'reactstrap';
import Select from 'react-select';

import { ContactInformationStatus } from '../../../utils/enums/contactPerson';
import InputFormLabel from '../../../components/form/InputFormLabel';
import {
  maxEmailCount,
  maxMobileCount,
  maxPhoneCount,
  NO_ID,
  styleWidth30Percent,
} from '../../../utils/constants';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { ContactInfoType } from '../../../utils/enums/contact';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { generateTitle } from '../../../utils/helpers/icon';
import {
  translateAddOrUpdate,
  translateCustomerAccountFormInput,
} from '../CustomerAccountList/customerAccountListHelper';
import ContactInformationInput from '../../../components/form/ContactInformationInput';
import { ICustomerAccountDetail } from '../../../utils/types/responseTypes';
import {
  IDropdownOption,
  IDropdownOptionInvolved,
  IObjectContactInformation,
  IObjectInvolvedResponsible,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import MultipleResponsibleDropdown from '../../../components/form/MultipleResponsibleDropdown';
import {
  getEmployeeNames,
  getResponsibleRoles,
} from '../../../services/api/employee';
import {
  dropdownOptionToObjectNameAndId,
  objectNameAndIdToDropdownOption,
  objectNameAndIdToDropdownOptions,
} from '../../../utils/helpers/dropdown';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';

interface IProps extends IWithModalsProps, RouteComponentProps {
  customerAccountDetail: ICustomerAccountDetail;
  onChange: (customerAccountDetail: ICustomerAccountDetail) => void;
}

/**
 * Customer Account form input\
 */
const CustomerAccountFormInput: React.FC<IProps> = ({
  customerAccountDetail,
  onChange,
  modalErrorHandler,
  modalDeleteHandler,
}: IProps) => {
  const [employeesResponsible, setEmployeesResponsible] = useState<
    IObjectNameAndId[]
  >([]);
  const [responsibleRoleOptions, setResponsibleRoleOptions] = useState(
    [] as IDropdownOption<number>[]
  );

  const {
    name,
    responsible = {
      id: NO_ID,
      responsibleRole: { id: NO_ID },
    } as IObjectInvolvedResponsible,
    involvedResponsibles = [] as IObjectInvolvedResponsible[],
    contactInformation = [] as IObjectContactInformation[],
  } = customerAccountDetail;

  const disableButton = (infoType: string, value: number) =>
    contactInformation.filter(({ type }) => type === infoType).length === value;

  const involvedResponsibleOptionsFiltered = objectNameAndIdToDropdownOptions(
    employeesResponsible
  ).filter(
    ({ value }) =>
      value !== responsible.id &&
      !involvedResponsibles.some((e) => e.id === value)
  );

  const fetchEmployeesResponsible = async () => {
    const {
      employeeAsResponsible: {
        readWrite: { uri, accessType },
      },
    } = PERMISSION_URI;
    await getEmployeeNames({
      'permissionsFilter.in': uri,
      'accessTypeFilter.in': accessType,
    })
      .then(({ data }) => {
        setEmployeesResponsible(data);
      })
      .catch((error) => {
        modalErrorHandler(
          translateCustomerAccountFormInput('failedToRetrieveEmployees'),
          error
        );
      });
  };

  const fetchResponsibleRoles = async () => {
    await getResponsibleRoles()
      .then(({ data: responsibleRoles }) => {
        setResponsibleRoleOptions(
          responsibleRoles.map(({ id = 0, role }) => ({
            value: id,
            label: role as string,
          }))
        );
      })
      .catch((error) => {
        modalErrorHandler(translateAddOrUpdate('failedToRetrieveRoles'), error);
      });
  };

  // Adding new contactInformation
  const handleAddContactInformation = (infoType: string) => {
    onChange({
      ...customerAccountDetail,
      contactInformation: [
        ...contactInformation,
        {
          id: NO_ID,
          info: '',
          type: infoType,
          status: ContactInformationStatus.GUESSED,
        } as IObjectContactInformation,
      ],
    } as ICustomerAccountDetail);
  };

  // Handles the changes made to the Name field
  const handleNameChange = (name: string) => {
    onChange({
      ...customerAccountDetail,
      name,
    } as ICustomerAccountDetail);
  };

  const handleResponsibleChange = (
    updatedResponsible: IDropdownOption<number>
  ) => {
    onChange({
      ...customerAccountDetail,
      responsible: {
        ...responsible,
        ...dropdownOptionToObjectNameAndId(updatedResponsible),
      },
    } as ICustomerAccountDetail);
  };

  const handleResponsibleRoleChange = (
    responsibleRole: IDropdownOption<number>
  ) => {
    onChange({
      ...customerAccountDetail,
      responsible: {
        ...responsible,
        responsibleRole: {
          ...dropdownOptionToObjectNameAndId(responsibleRole),
        },
      },
    } as ICustomerAccountDetail);
  };

  const handleInvolvedResponsibleChange = (
    involvedResponsibles: IDropdownOptionInvolved[]
  ) => {
    onChange({
      ...customerAccountDetail,
      involvedResponsibles: involvedResponsibles.map(
        ({ responsible, responsibleRole }) =>
          ({
            ...(responsible
              ? {
                  id: responsible.value,
                  name: responsible.label,
                }
              : {}),
            ...(responsibleRole
              ? {
                  responsibleRole: {
                    id: responsibleRole.value,
                    name: responsibleRole.label,
                  },
                }
              : {}),
          } as IObjectInvolvedResponsible)
      ),
    } as ICustomerAccountDetail);
  };

  // Handles deleted contact informations
  const handleDeletedContactInformation = (
    info: IObjectContactInformation,
    index: number
  ) => {
    if (info.id) {
      modalDeleteHandler(
        'Delete Contact Information',
        'Are you sure to delete this contact information?',
        () => {
          const newContactInformations = contactInformation.filter(
            ({ id }) => id !== info.id
          );
          onChange({
            ...customerAccountDetail,
            contactInformation: newContactInformations,
          } as ICustomerAccountDetail);
        }
      );
    } else {
      const newContactInformations = [...contactInformation];
      newContactInformations.splice(index, 1);
      onChange({
        ...customerAccountDetail,
        contactInformation: newContactInformations,
      } as ICustomerAccountDetail);
    }
  };

  // Handle contact informations changes
  const handleContactInformationsChange = (info: string, index: number) => {
    const contactInformationToChange = contactInformation[index];

    if (contactInformationToChange) {
      const { id, type, status } = contactInformationToChange;
      const contactInformationUpdated = [...contactInformation];
      contactInformationUpdated.splice(index, 1, {
        id,
        type,
        info,
        status,
      });
      onChange({
        ...customerAccountDetail,
        contactInformation: contactInformationUpdated,
      } as ICustomerAccountDetail);
    }
  };

  const handleEmailStatusChange = (status: string, index: number) => {
    const contactInfoArray = [...contactInformation];
    const newContactInformation = contactInfoArray[index];
    if (newContactInformation) {
      newContactInformation.status = status;
      contactInfoArray.splice(index, 1, newContactInformation);

      onChange({
        ...customerAccountDetail,
        contactInformation: contactInfoArray,
      } as ICustomerAccountDetail);
    }
  };

  // Loads up data for the request form
  useEffect(() => {
    fetchEmployeesResponsible();
    fetchResponsibleRoles();
  }, []);

  return (
    <Table borderless>
      <tbody>
        <tr>
          <th style={{ width: styleWidth30Percent }}>
            <InputFormLabel
              isRequired
              text={translateCustomerAccountFormInput('name')}
            />
          </th>
          <td>
            <Input
              type="string"
              bsSize="lg"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleNameChange(event.target.value);
              }}
              value={name ?? ''}
              aria-label="textbox-name"
            />
          </td>
        </tr>
        <tr>
          <th style={{ width: styleWidth30Percent }}>
            <InputFormLabel
              isRequired
              text={translateCustomerAccountFormInput('responsible')}
            />
          </th>
          <td>
            <div className="d-flex mb-1 gap-1">
              <div className="col-6">
                <Select
                  value={
                    responsible.id
                      ? objectNameAndIdToDropdownOption(responsible)
                      : null
                  }
                  options={involvedResponsibleOptionsFiltered}
                  onChange={(value: IDropdownOption<number>) =>
                    handleResponsibleChange(value)
                  }
                  aria-label="dropdown-responsible"
                />
              </div>
              <div className="col-6">
                <Select
                  value={
                    responsible.responsibleRole.id
                      ? objectNameAndIdToDropdownOption(
                          responsible.responsibleRole
                        )
                      : null
                  }
                  options={responsibleRoleOptions}
                  placeholder={translateAddOrUpdate('responsibleRole')}
                  onChange={(value: IDropdownOption<number>) =>
                    handleResponsibleRoleChange(value)
                  }
                  aria-label="dropdown-responsible-role"
                />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th style={{ width: styleWidth30Percent }}>
            <InputFormLabel
              isRequired={false}
              text={translateCustomerAccountFormInput('involved')}
            />
          </th>
          <td>
            <MultipleResponsibleDropdown
              responsibles={involvedResponsibles.map(
                ({ id, name, responsibleRole }) =>
                  ({
                    ...(id && name
                      ? {
                          responsible: { label: name, value: id },
                        }
                      : {}),
                    ...(responsibleRole
                      ? {
                          responsibleRole: {
                            label: responsibleRole.name,
                            value: responsibleRole.id,
                          },
                        }
                      : {}),
                  } as {
                    responsible: IDropdownOption<number>;
                    responsibleRole: IDropdownOption<number>;
                  })
              )}
              setResponsibles={handleInvolvedResponsibleChange}
              responsibleOptions={involvedResponsibleOptionsFiltered}
            />
          </td>
        </tr>
        <tr>
          <th scope="row">{translateCustomerAccountFormInput('email')}</th>
          <td>
            {contactInformation.map((info, infoIndex) =>
              info?.type === ContactInfoType.EMAIL ? (
                <Row key={infoIndex}>
                  <ContactInformationInput
                    key={info.id}
                    index={infoIndex}
                    contactInformation={info}
                    onDelete={handleDeletedContactInformation}
                    onChange={handleContactInformationsChange}
                    onEmailStatusChange={handleEmailStatusChange}
                    objectType={OBJECT_TYPE_ENUM.customerAccount.code}
                  />
                </Row>
              ) : null
            )}
            <Button
              color="primary"
              size="sm"
              disabled={disableButton(ContactInfoType.EMAIL, maxEmailCount)}
              onClick={() => handleAddContactInformation(ContactInfoType.EMAIL)}
              aria-label="add-email-button"
            >
              {generateTitle(
                BUTTON_TITLE_ENUM.ADD.code,
                translateCustomerAccountFormInput('add')
              )}
            </Button>
          </td>
        </tr>
        <tr>
          <th scope="row">{translateCustomerAccountFormInput('phone')}</th>
          <td>
            {contactInformation.map((info, infoIndex) =>
              info?.type === ContactInfoType.PHONE ? (
                <Row key={infoIndex}>
                  <ContactInformationInput
                    index={infoIndex}
                    contactInformation={info}
                    onDelete={handleDeletedContactInformation}
                    onChange={handleContactInformationsChange}
                    objectType={OBJECT_TYPE_ENUM.contactPerson.code}
                  />
                </Row>
              ) : null
            )}
            <Button
              color="primary"
              size="sm"
              disabled={disableButton(ContactInfoType.PHONE, maxPhoneCount)}
              onClick={() => handleAddContactInformation(ContactInfoType.PHONE)}
              aria-label="add-phone-button"
            >
              {generateTitle(
                BUTTON_TITLE_ENUM.ADD.code,
                translateCustomerAccountFormInput('add')
              )}
            </Button>
          </td>
        </tr>
        <tr>
          <th scope="row">{translateCustomerAccountFormInput('mobile')}</th>
          <td>
            {contactInformation.map((info, infoIndex) =>
              info?.type === ContactInfoType.MOBILE ? (
                <Row key={infoIndex}>
                  <ContactInformationInput
                    index={infoIndex}
                    contactInformation={info}
                    onDelete={handleDeletedContactInformation}
                    onChange={handleContactInformationsChange}
                    objectType={OBJECT_TYPE_ENUM.contactPerson.code}
                  />
                </Row>
              ) : null
            )}
            <Button
              color="primary"
              size="sm"
              disabled={disableButton(ContactInfoType.MOBILE, maxMobileCount)}
              onClick={() =>
                handleAddContactInformation(ContactInfoType.MOBILE)
              }
              aria-label="add-mobile-button"
            >
              {generateTitle(
                BUTTON_TITLE_ENUM.ADD.code,
                translateCustomerAccountFormInput('add')
              )}
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default withRouter(withModals(CustomerAccountFormInput));
