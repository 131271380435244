import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useContext, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { AxiosResponse } from 'axios';

import {
  deleteContactPerson,
  getContactPersonAssignment,
  getContactPersonDetails,
  getUserCallStatistics,
  resetContactPerson,
  saveContactPersonAssignment,
  saveStatistics,
  setContactPersonFlag,
  updateContactPersonAssignment,
} from '../../../services/api/contactPerson';
import Auth from '../../../services/axios/Auth';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { ContactStatus } from '../../../utils/enums/contact';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { isEmpty } from '../../../utils/helpers/array';
import { READ } from '../../../utils/constants';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { setThenRemoveAlert } from '../../../redux/alertsSlice';
import { loadCallingList } from '../../../redux/callingSlice';
import { RootState } from '../../../redux/store';
import {
  IActivity,
  ICallStatistics,
  IContactPersonAssignment,
  IExtendedContactPerson,
} from '../../../utils/types/modelTypes';
import user from '../../../user';
import CallStatistics from './CallStatistics';
import CallTimer from './CallTimer';
import CollapsibleActivityHistoryCard from './CollapsibleActivityHistoryCard';
import CollapsibleRelatedActivitiesCard from './CollapsibleRelatedActivitiesCard';
import CollapsibleRelatedEntitiesCard from './CollapsibleRelatedEntitiesCard';
import ContactPersonDescriptionCard from './ContactPersonDescriptionCard';
import ContactPersonDetailsCard from './ContactPersonDetailsCard';
import { ContactPersonDetailsContext } from './ContactPersonDetailsProvider';
import { transformContactPersonToDetails } from './contactPersonHelper';
import ContactPersonResponsibleCard from './ContactPersonResponsibleCard';
import ContactPersonStatusCard from './ContactPersonStatusCard';
import ModalExportContactForm from './ModalExportContactForm';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import { canWrite } from '../../../utils/helpers/GenericHelper';

interface IProps extends PropsFromRedux, IWithModalsProps, RouteComponentProps {
  location: any;
}

interface RouterParams {
  contactId: string;
}

interface IGlobalStats {
  status: boolean;
  totalCalls: number;
  warmCalls: number;
  coldCalls: number;
  warmCallsReached: number;
  coldCallsReached: number;
  coldCallsAppointment: number;
  warmCallsAppointment: number;
  warmCallsAppointed: number;
  coldCallsAppointed: number;
  duration: string;
  callSession: number;
}

const t = (keyName: string) => i18n.t(`ContactProfile.${keyName}`);

/**
 * Handles logic between connected components for contact person
 */
const ContactPersonDetails = ({
  // Redux
  callingList,
  employeeDetails: { id: currentUserId },
  // WithModals
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
  modalConfirmHandler,
  // Router
  location,
  history,
}: IProps) => {
  const { contactId } = useParams<RouterParams>();

  const {
    contactDetail,
    setDescription,
    setContactDetail,
    setStatusDetail,
    setRelatedActivitiesDetail,
    setHistoryOfActivitiesDetail,
    setResponsibleDetail,
    setRelatedEntities,
    setUnsavedDescription,
    setUnsavedDetail,
    unsavedDescription,
    unsavedDetail,
  } = useContext(ContactPersonDetailsContext);

  const [index, setIndex] = useState(0);

  // Calling
  const [coldCalls, setColdCalls] = useState(0);
  const [warmCalls, setWarmCalls] = useState(0);
  const [totalCalls, setTotalCalls] = useState(0);
  const [overdueCalls, setOverdueCalls] = useState(0);
  const [coldCall, setColdCall] = useState(false);
  const [warmCall, setWarmCall] = useState(false);
  const [coldCallsReached, setColdCallsReached] = useState(0);
  const [warmCallsReached, setWarmCallsReached] = useState(0);
  const [coldCallsAppointment, setColdCallsAppointment] = useState(0);
  const [warmCallsAppointment, setWarmCallsAppointment] = useState(0);

  const [duration, setDuration] = useState(0);
  const [globalStats, setGlobalStats] = useState({} as IGlobalStats);
  const [successRate, setSuccessRate] = useState(0);
  const [appointmentRate, setAppointmentRate] = useState(0);
  const [contactPersonAssignment, setContactPersonAssignment] = useState(
    {} as IContactPersonAssignment
  );
  const activities = [] as IActivity[];
  const [singleCall, setSingleCall] = useState(false);
  const callMode = location.state?.callMode ?? false;

  // Call Timer
  const [callEnd, setCallEnd] = useState(false);
  const hasExportPermission = Auth.hasPermission(
    [PERMISSION_URI.contactPeopleList.readWrite.uri],
    [READ]
  );

  const [userCanWrite, setUserCanWrite] = useState(false);

  /**
   * Get contact person details
   */
  const fetchContactPersonDetails = async (contactId: number) => {
    try {
      const { data: contactPerson } = await getContactPersonDetails(contactId);
      const {
        contactDetail,
        statusDetail,
        description,
        relatedActivitiesDetail,
        historyOfActivitiesDetail,
        relatedEntitiesDetail,
        responsibleDetail,
      } = transformContactPersonToDetails(contactPerson);
      setContactDetail(contactDetail);
      setStatusDetail(statusDetail);
      setDescription(description);
      setRelatedActivitiesDetail(relatedActivitiesDetail);
      setHistoryOfActivitiesDetail(historyOfActivitiesDetail);
      setResponsibleDetail(responsibleDetail);
      setRelatedEntities(relatedEntitiesDetail);
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveContactPersonDetails'), error);
    }
  };

  /**
   * Sets the call statistics
   * @param {*} status
   */
  const setStatistics = ({
    assignmentState,
    dueDate,
  }: IContactPersonAssignment) => {
    if (
      assignmentState === ContactStatus.COLD.code ||
      assignmentState === ContactStatus.RECALL.code ||
      assignmentState == null
    ) {
      setColdCalls(coldCalls + 1);
      setColdCall(true);
    } else {
      setWarmCalls(warmCalls + 1);
      setWarmCall(true);
    }
    if (moment().startOf('day').isSameOrAfter(moment(dueDate).startOf('day'))) {
      setOverdueCalls(overdueCalls + 1);
    }
    setTotalCalls(totalCalls + 1);
  };

  /**
   * Obtain the latest contact person assignment
   */
  const fetchContactPersonAssignment = async () => {
    try {
      const { data: contactPersonAssignment } =
        await getContactPersonAssignment({
          'latest.equals': 'TRUE',
          'contactPersonId.equals': contactId,
        });
      // Null check
      if (!isEmpty(contactPersonAssignment) && contactPersonAssignment[0]) {
        setContactPersonAssignment(contactPersonAssignment[0]);
      }
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveContactPersonAssignment'), error);
    }
  };

  /**
   * Retrieves the permissions of the current user
   */
  const fetchPermissions = async (objectId: number) => {
    const objectType = OBJECT_TYPE_ENUM.contactPerson.code;
    try {
      setUserCanWrite(
        (await canWrite(currentUserId, objectId, objectType)) as boolean
      );
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveUserAccessType'), error);
    }
  };

  /**
   * Retrieves Global Stats
   */
  const loadGlobalStats = async () => {
    let obtainedData: AxiosResponse<ICallStatistics, any> = {} as AxiosResponse<
      ICallStatistics,
      any
    >;
    try {
      obtainedData = await getUserCallStatistics({
        'employeeId.equals': `${user.employeeId}`,
      });
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveGlobalStatistics'), error);
      return;
    }
    const { data: allStats } = obtainedData;

    const newGlobalStats = {} as IGlobalStats;
    if (Array.isArray(allStats)) {
      allStats.forEach((stats: IGlobalStats) => {
        newGlobalStats.totalCalls += Number(stats.totalCalls ?? 0);
        newGlobalStats.warmCalls += Number(stats.warmCalls ?? 0);
        newGlobalStats.coldCalls += Number(stats.coldCalls ?? 0);
        newGlobalStats.coldCallsReached += Number(stats.coldCallsReached ?? 0);
        newGlobalStats.warmCallsReached += Number(stats.warmCallsReached ?? 0);
        newGlobalStats.coldCallsAppointment += Number(
          stats.coldCallsAppointed ?? 0
        );
        newGlobalStats.warmCallsAppointment += Number(
          stats.warmCallsAppointed ?? 0
        );
        newGlobalStats.duration = moment
          .duration(newGlobalStats.duration)
          .add(moment.duration(stats.callSession))
          .toISOString();
      });
    }

    const {
      warmCallsReached: newWarmCallsReached = 0,
      coldCallsReached: newColdCallsReached = 0,
      totalCalls: newTotalCalls = 0,
      warmCallsAppointed: newWarmCallsAppointed = 0,
      coldCallsAppointed: newColdCallsAppointed = 0,
    } = allStats;

    let successRate = (warmCallsReached + coldCallsReached) / totalCalls;
    const newSuccessRate =
      (newWarmCallsReached + newColdCallsReached) / newTotalCalls;
    if (newSuccessRate > successRate || Number.isNaN(successRate)) {
      successRate = newSuccessRate;
    }
    successRate *= 100;
    successRate = Number.isNaN(successRate)
      ? 0
      : Number(successRate.toFixed(2));

    let appointmentRate =
      (warmCallsAppointment + coldCallsAppointment) /
      (warmCallsReached + coldCallsReached);
    const newAppointmentRate =
      (newWarmCallsAppointed + newColdCallsAppointed) /
      (newWarmCallsReached + newColdCallsReached);
    if (newAppointmentRate > appointmentRate || Number.isNaN(appointmentRate)) {
      appointmentRate = newAppointmentRate;
    }
    appointmentRate *= 100;
    appointmentRate = Number.isNaN(appointmentRate)
      ? 0
      : Number(appointmentRate.toFixed(2));
    setGlobalStats(newGlobalStats);
    setSuccessRate(successRate);
    setAppointmentRate(appointmentRate);
  };

  /**
   * Saves Statistics
   */
  const handleSaveStatistics = (
    callTime: string,
    { assignmentState, dueDate }: IContactPersonAssignment
  ) => {
    // Repeat logic from setStatistics to properly pass updated values
    let coldCallsResult = 0;
    let warmCallsResult = 0;
    let overdueCallsResult = 0;
    if (
      assignmentState === ContactStatus.COLD.code ||
      assignmentState === ContactStatus.RECALL.code ||
      assignmentState == null
    ) {
      coldCallsResult = coldCalls + 1;
    } else {
      warmCallsResult = warmCalls + 1;
    }
    if (moment().startOf('day').isSameOrAfter(moment(dueDate).startOf('day'))) {
      overdueCallsResult = overdueCalls + 1;
    }
    const totalCallsResult = totalCalls + 1;

    // Create payload
    const payload = {
      date: new Date().toISOString(),
      employeeId: parseInt(user.employeeId, 10),
      totalCalls: totalCallsResult,
      warmCalls: warmCallsResult,
      warmCallsReached,
      coldCalls: coldCallsResult,
      coldCallsReached,
      callSession: moment.duration(callTime).asSeconds(),
      coldCallsAppointed: coldCallsAppointment,
      warmCallsAppointed: warmCallsAppointment,
      overdueCalls: overdueCallsResult,
    };

    saveStatistics(payload)
      .then(() => {
        loadGlobalStats();
      })
      .catch((error) => {
        modalErrorHandler(t('failedToSaveStatistic'), error);
      });
  };

  /**
   * Removes Call Flag
   */
  const removeCallFlag = () => {
    setContactPersonFlag(
      false,
      callingList.map(
        ({ contactPerson: { id } }: IExtendedContactPerson) => id ?? 0
      )
    ).catch((error) => {
      modalErrorHandler(t('failedToSetFlag'), error);
    });
  };

  /**
   * Ends the call
   */
  const handleEndCall = (callTime: string) => {
    setStatistics(contactPersonAssignment);
    setCallEnd(true);
    removeCallFlag();
    handleSaveStatistics(callTime, contactPersonAssignment);
  };

  const handleDurationChange = (duration: string) => {
    // Convert duration string into seconds
    const durationInSeconds = moment.duration(duration).asSeconds();
    setDuration(durationInSeconds);
  };

  /**
   * Gets Global Stats
   */
  const handleGlobalStatsChange = () => {
    setGlobalStats({ ...globalStats, status: !globalStats.status });
  };

  /**
   * Handle the selecting of Next call button
   */
  const handleNextTargetChange = () => {
    const validCallingList = !isEmpty(callingList);
    setStatistics(contactPersonAssignment);
    if (validCallingList && index + 1 < callingList.length && !singleCall) {
      setIndex(index + 1);
    } else {
      handleEndCall(`${duration}`);
    }

    if (validCallingList) {
      history.push({
        pathname: `/customer/my-contacts/profile/${
          callingList[index + 1]?.contactPerson?.id ??
          callingList[index]?.contactPerson?.id ??
          0
        }`,
        state: {
          callMode: true,
        },
      });
    }
  };

  /**
   * Checks unsaved changes
   */
  const checkUnsavedChanges = () => {
    let messageBodyText = '';
    if (unsavedDescription || unsavedDetail) {
      if (unsavedDescription && !unsavedDetail) {
        messageBodyText = t('unsavedChangesOnContactPersonDescription');
      } else if (unsavedDetail && !unsavedDescription) {
        messageBodyText = t('unsavedChangesOnContactPersonDetail');
      } else {
        messageBodyText = t('unsavedChangesConfirmation');
      }
      modalConfirmHandler(t('notice'), t(messageBodyText), () => {
        handleNextTargetChange();
      });
    } else {
      handleNextTargetChange();
    }
  };

  /**
   * Shows the modal form to export the contact to outlook
   */
  const showExportContactModalForm = () => {
    modalFormHandler(
      t('exportThisContactToOutlook'),
      <ModalExportContactForm toggleModal={() => toggleModalForm()} />,
      'm'
    );
  };

  /**
   * Handle the change of page when the Not Reached activity is selected and confirmed.
   * @param {*} selectedIndex
   */
  const handleNotReachedConfirm = (selectedIndex: number) => {
    const validCallingList = !isEmpty(callingList);

    if (callMode) {
      if (validCallingList && selectedIndex + 1 < callingList.length) {
        setIndex(selectedIndex + 1);
        setColdCall(false);
        setWarmCall(false);
        setStatistics(contactPersonAssignment);
      } else {
        handleEndCall(`${duration}`);
      }

      setUnsavedDescription(false);
      setUnsavedDetail(false);

      if (validCallingList && callMode) {
        history.push({
          pathname: `/customer/my-contacts/profile/${
            callingList[selectedIndex + 1]?.contactPerson.id ??
            callingList[selectedIndex]?.contactPerson.id ??
            0
          }`,
          state: {
            callMode: true,
          },
        });
      }
    }
  };

  /**
   * Update Calls statistics based on calling activity card
   * @param {*} reached
   * @param {*} dateSet
   */
  const updateCall = (reached: boolean, dateSet: boolean) => {
    if (reached) {
      if (coldCall) {
        setColdCallsReached(coldCallsReached + 1);
      }
      if (warmCall) {
        setWarmCallsReached(warmCallsReached + 1);
      }
    }
    if (dateSet) {
      if (coldCall) {
        setColdCallsAppointment(coldCallsAppointment + 1);
      }
      if (warmCall) {
        setWarmCallsAppointment(warmCallsAppointment + 1);
      }
    }
  };

  /**
   * Deletes the current Contact Person
   */
  const handleDeleteContactPerson = async () => {
    try {
      await deleteContactPerson(parseInt(contactId, 10));
      setThenRemoveAlert(t('contactPersonSuccessfullyDeleted'), 'success');
      history.push({
        pathname: '/customer/my-contacts',
      });
    } catch (error) {
      modalErrorHandler(t('failedToDeleteContact'), error);
    }
  };

  /**
   * Handles resetting of Contact Person's details:
   * Customer, CustomerSite, Role, Function, Phone and Mobile Numbers
   */
  const handleResetContactPerson = async () => {
    try {
      const { data } = await resetContactPerson(parseInt(contactId, 10));
      // Pass the changes into the context
      const { contactDetail } = transformContactPersonToDetails(data);
      setContactDetail(contactDetail);
      // Update the states of the context
      setThenRemoveAlert(t('contactPersonSuccessfullyReset'), 'success');
    } catch (error) {
      modalErrorHandler(t('resettingContactPersonFailed'), error);
    }
  };

  /**
   * Handles updating contact person assignment
   */
  const handleUpdateContactPersonAssignment = async (
    contactPersonAssignment: IContactPersonAssignment
  ) => {
    try {
      const { data } = contactPersonAssignment.id
        ? await updateContactPersonAssignment(contactPersonAssignment)
        : await saveContactPersonAssignment(contactPersonAssignment);
      setContactPersonAssignment(data);
    } catch (error) {
      modalErrorHandler(t('failedToSaveContactPersonAssignment'), error);
    }
  };

  useEffect(() => {
    fetchContactPersonDetails(parseInt(contactId, 10));
    fetchContactPersonAssignment();
    fetchPermissions(parseInt(contactId, 10));
    // If there is only a single contact in the list
    if (!isEmpty(callingList) && callingList.length === 1) {
      setSingleCall(true);
    }
  }, [contactId]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('contactProfile')}</HeaderTitle>
        {generateBreadcrumb(location.pathname, t('customer'))}
        {!callEnd && (
          <h1 className="mt-7 pt-5">
            {`${contactDetail?.firstname || ''} ${
              contactDetail?.lastname || ''
            }`}
          </h1>
        )}

        {hasExportPermission && (
          <div className="card-actions float-end">
            <Button
              color="primary"
              className="float-end"
              size="m"
              onClick={showExportContactModalForm}
            >
              {t('exportThisContactToOutlook')}
            </Button>
          </div>
        )}
        {callMode && (
          <CallTimer
            callEnd={callEnd}
            endCall={handleEndCall}
            updateStatistics={handleDurationChange}
          />
        )}

        <br />
        <div className="card-actions float-end">
          {!callEnd &&
            !singleCall &&
            callMode &&
            index + 1 < callingList.length && (
              <Button
                color="primary"
                className="float-end"
                size="m"
                onClick={checkUnsavedChanges}
              >
                {t('nextTarget')} <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            )}
        </div>
      </Header>
      {callEnd ? (
        <CallStatistics
          totalCalls={totalCalls}
          duration={moment.duration(duration, 'seconds').toISOString()}
          warmCalls={warmCalls}
          coldCalls={coldCalls}
          warmCallsReached={warmCallsReached}
          coldCallsReached={coldCallsReached}
          warmCallsAppointment={warmCallsAppointment}
          coldCallsAppointment={coldCallsAppointment}
          activities={activities}
          callingList={callingList}
          globalStats={globalStats}
          successRate={successRate}
          appointmentRate={appointmentRate}
          getGlobalStats={handleGlobalStatsChange}
          overduePercentage={(overdueCalls / totalCalls) * 100}
          coldCallPercentage={(coldCalls / totalCalls) * 100}
          hideGlobalStatus={false}
          hideTitle={false}
        />
      ) : (
        <Row>
          <Col style={{ outerWidth: '50%' }}>
            <ContactPersonDetailsCard userCanWrite={userCanWrite} />
            <Card>
              <ContactPersonStatusCard
                userCanWrite={userCanWrite}
                onSave={fetchContactPersonAssignment}
              />
            </Card>
            <Card>
              <CardBody>
                <ContactPersonDescriptionCard />
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardBody>
                <CollapsibleRelatedActivitiesCard
                  handleNotReachedConfirm={handleNotReachedConfirm}
                  contactPersonId={contactDetail.id}
                  updateCall={updateCall}
                  contactProfileIndex={index}
                  onDeleteContactPerson={handleDeleteContactPerson}
                  onResetContactPerson={handleResetContactPerson}
                  contactPersonAssignment={contactPersonAssignment}
                  handleUpdateContactPersonAssignment={
                    handleUpdateContactPersonAssignment
                  }
                />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CollapsibleActivityHistoryCard />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CollapsibleRelatedEntitiesCard />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <ContactPersonResponsibleCard userCanWrite={userCanWrite} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  callingList: store.calling.callingList,
  employeeDetails: store.account.employeeDetails,
});

const mapDispatchToProps = {
  loadCallingList,
  setThenRemoveAlert,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(ContactPersonDetails)));
