import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { AxiosError } from 'axios';

import { getEmployeeNames } from '../../../services/api/employee';
import { READWRITE } from '../../../utils/constants';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import {
  objectNameAndIdToDropdownOptions,
  sortOptionsByValue,
} from '../../../utils/helpers/dropdown';

interface IProps extends IWithModalsProps {
  employeeId: number;
  permissions: string[];
  isDisabled?: boolean;
  onChange: (employee: IDropdownOption<number>) => void;
}

const ModifiedEmployeeDropdown = ({
  employeeId,
  permissions,
  isDisabled,
  onChange,
  modalErrorHandler,
}: IProps) => {
  const [dropdownOptions, setDropdownOptions] = useState(
    [] as IDropdownOption<number>[]
  );

  useEffect(() => {
    const parameters = {
      'permissionFilter.in': permissions.join(','),
      'accessTypeFilter.in': READWRITE,
    };

    getEmployeeNames(parameters)
      .then(({ data }) =>
        setDropdownOptions(objectNameAndIdToDropdownOptions(data))
      )
      .catch((error: AxiosError) =>
        modalErrorHandler('Failed to retrieve employees', error)
      );
  }, []);

  return (
    <Select
      aria-label="employee-dropdown"
      value={dropdownOptions.find(({ value }) => value === employeeId)}
      options={sortOptionsByValue(dropdownOptions)}
      isDisabled={isDisabled}
      onChange={onChange}
    />
  );
};

export default withModals(ModifiedEmployeeDropdown);
