import React, { useState } from 'react';
import Select from 'react-select';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';

import { ProjectType } from '../../../utils/enums/project';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { clickHandler } from '../../../utils/helpers/click';
import {
  IOfferListItem,
  IProjectMemberListItem,
} from '../../../utils/types/responseTypes';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import CreateCustomerProjectBilledForm from './CreateCustomerProjectBilledForm';
import UnbilledCustomerProjectModal from './ProjectModal/UnbilledCustomerProject/UnbilledCustomerProjectModal';
import { sortOptionsByValue } from '../../../utils/helpers/dropdown';
import { t } from './projectListHelpers';
import InternalProjectModal from './ProjectModal/InternalProject/InternalProjectModal';

const projectTypesOptions = [
  {
    value: ProjectType.CUSTOMER_PROJECT,
    label: 'Customer Project',
  },
  {
    value: ProjectType.INVESTMENT_PROJECT,
    label: 'Customer Project (unbilled)',
  },
  {
    value: ProjectType.INTERNAL_PROJECT,
    label: 'Internal Project',
  },
];

export type ProjectContactPerson = {
  contactPerson: IDropdownOption<number>;
  role: string;
  functionRole: string;
};

export type ProjectActivity = {
  action: IDropdownOption;
  dueDate: string;
};

export type ProjectInvolvedResponsible = {
  responsible: IDropdownOption<number>;
  responsibleRole: IDropdownOption<number>;
};

export type ProjectDetailsForm = {
  // General Information
  title: string;
  customer: IDropdownOption<number>;
  customerSite: IDropdownOption<number>;
  contactPersons: ProjectContactPerson[];
  projectType: string;
  // Budget and schedule
  start: string;
  end: string;
  departments: IDropdownOption<number>[];
  state: string;
  duration: number;
  durationType: IDropdownOption;
  locationType: string;
  // Description
  description: string;
  estimationTemplatesLink: string;
  projectSharePoint: string;
  // Responsibles
  responsible: IDropdownOption<number>;
  involvedResponsibles: ProjectInvolvedResponsible[];
  // Project Members
  projectMembers: IProjectMemberListItem[];
  // Offers
  offers: IOfferListItem[];
};

interface IProps extends IWithModalsProps {
  onCancel: () => void;
  isOpen: boolean;
  onSave: (idOfProject: number) => void;
}

/**
 * Modal for selecting type of project to create
 */
const CreateNewProjectModal = ({
  isOpen,
  onCancel,
  onSave,
  // WithModals
  modalFormHandler,
  toggleModalForm,
}: IProps) => {
  const [selectedProjectType, setSelectProjectType] =
    useState<IDropdownOption<ProjectType>>();

  /**
   * Handles the continuation of the creation of new project based on the selected project type
   * @param {*} selectedProjectType
   */
  const handleCreateNewProjectByType = () => {
    switch (selectedProjectType?.value) {
      case ProjectType.CUSTOMER_PROJECT:
        modalFormHandler(
          t('createNewCustomerProject'),
          <CreateCustomerProjectBilledForm
            onSave={onSave}
            onCancel={toggleModalForm}
          />,
          'lg'
        );
        break;
      case ProjectType.INVESTMENT_PROJECT:
        modalFormHandler(
          t('createUnbilledProject'),
          <UnbilledCustomerProjectModal
            onCancel={toggleModalForm}
            onSave={onSave}
          />,
          'xl'
        );
        break;
      case ProjectType.INTERNAL_PROJECT:
        modalFormHandler(
          t('createInternalProject'),
          <InternalProjectModal onCancel={toggleModalForm} onSave={onSave} />,
          'xl'
        );
        break;
      default:
        break;
    }
  };

  const handleSelectProjectType = (
    selectedProjectType: IDropdownOption<ProjectType>
  ) => {
    setSelectProjectType(selectedProjectType);
  };

  const handleSubmit = () => {
    handleCreateNewProjectByType();
    onCancel();
  };

  return (
    <Modal isOpen={isOpen} size="m" aria-label="modal-new-project">
      <div style={{ textAlign: 'center', paddingTop: '10px' }}>
        <h3>{t('createNewProject')}</h3>
      </div>
      <ModalBody>
        <Form>
          <FormGroup>
            <Row>
              <Col md={4}>
                <Label>{t('chooseTypeOfProject')}:</Label>
              </Col>
              <Col>
                <Select
                  options={sortOptionsByValue(projectTypesOptions)}
                  onChange={handleSelectProjectType}
                  value={selectedProjectType}
                  placeholder={t('typeOfProject')}
                  aria-label="modal-new-project-select"
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <div className="centered-div">
              <Button
                color="primary"
                className="continue-and-cancel-button"
                onClick={handleSubmit}
                disabled={!selectedProjectType}
              >
                {t('continue')}
              </Button>{' '}
              <Button
                className="continue-and-cancel-button"
                color="primary"
                onClick={(event: MouseEvent) => clickHandler(event, onCancel)}
              >
                {t('cancel')}
              </Button>
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default withModals(CreateNewProjectModal);
