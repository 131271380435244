import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import Auth from '../../../services/axios/Auth';
import { NO_ID, None, READ, READWRITE } from '../../../utils/constants';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { getResponsibleEmployeeComponent } from '../../../utils/helpers/GenericHelper';
import DetailsCard from '../../../components/cards/DetailsCard';
import {
  ICustomerAccountDetail,
  ICustomerListItem,
} from '../../../utils/types/responseTypes';
import { ContactInfoType } from '../../../utils/enums/contact';
import { isEmpty } from '../../../utils/helpers/array';
import {
  formatResponsible,
  getContactInfo,
  getEmployeeName,
  getEmployeesFromResponsibles,
  getUniqueSectors,
  translateCustomerAccountDetails,
} from './customerAccountDetailsHelper';
import { getEmployeeNames } from '../../../services/api/employee';
import { IObjectNameAndId } from '../../../utils/types/commonTypes';
import withModals, { IWithModalsProps } from '../../../utils/withModals';

interface IProps extends IWithModalsProps {
  customerAccountDetail: ICustomerAccountDetail;
}

/**
 * Table for displaying Customer Account details.\
 */
const CustomerAccountDetailsTable = ({
  customerAccountDetail: {
    customers,
    id,
    name,
    responsible,
    involvedResponsibles,
    contactInformation,
  },
  modalErrorHandler,
}: IProps) => {
  const [employees, setEmployees] = useState<IObjectNameAndId[]>([]);

  const responsibilityIdLists = customers ? customers.map(({ id }) => id) : [];
  const allSectors =
    customers && customers.length > 0
      ? customers
          .filter(
            (customer) =>
              customer.customerAccount && customer.customerAccount.id === id
          )
          .flatMap(({ sectors }) => sectors) || []
      : [];

  const accountIndustry = getUniqueSectors(allSectors);

  /**
   * Returns the responsibles in JSX format
   * @param {*} customer
   * @returns
   */
  const getCustomerResponsible = ({ responsible }: ICustomerListItem) => {
    const responsibleEmployee = getEmployeesFromResponsibles(
      [responsible],
      employees
    );
    if (!isEmpty(responsibleEmployee)) {
      return responsibleEmployee.map(({ id, name }) => (
        <Row key={`responsible-row-${id}`} style={{ height: '22px' }}>
          <Col key={`responsible-col-${id}`}>
            <Link
              key={`responsible-${id}`}
              to={`/employees/employee-list/employee-detail/${id}`}
            >
              {`${name ?? ''}`}
            </Link>
          </Col>
        </Row>
      ));
    }
    return None;
  };

  /**
   * Returns involved in JSX format
   * @param {*} customer
   * @returns
   */
  const getCustomerInvolved = (customer: ICustomerListItem) => {
    const involvedEmployees = getEmployeesFromResponsibles(
      customer.involvedResponsibles,
      employees
    );
    if (!isEmpty(involvedEmployees)) {
      return involvedEmployees.map((employee: IObjectNameAndId) =>
        getResponsibleEmployeeComponent(employee)
      );
    }
    return None;
  };

  /**
   * Returns customer sectors in JSX format
   * @param {*} customer
   * @returns
   */
  const getCustomerSector = (customer: ICustomerListItem) => (
    <>
      {customer.sectors.map((sector) => (
        <Row style={{ height: '22px' }} key={`sector-row-${sector}`}>
          <Col key={`sector-col-${sector}`}>{sector}</Col>
        </Row>
      ))}
    </>
  );

  const fields = [
    {
      label: translateCustomerAccountDetails('name'),
      value: <div>{name}</div>,
    },
    {
      label: translateCustomerAccountDetails('customer'),
      value: customers ? (
        <div>
          {customers
            .filter(
              (customer) =>
                customer.customerAccount && customer.customerAccount.id === id
            )
            .map((customer) => (
              <>
                <Row key={customer.id}>
                  <Col key={`customer-${customer.id}`}>
                    {(customer.id &&
                      (Auth.isAdmin() ||
                        Auth.hasPermission(
                          [PERMISSION_URI.customer.readWrite.uri],
                          [READWRITE, READ],
                          customer.id
                        ))) ||
                    (customer.id &&
                      responsibilityIdLists.includes(customer.id)) ? (
                      <Link
                        key={`customer-link-${customer.id}`}
                        to={{
                          pathname: `/customer/customers/details/${customer.id}`,
                        }}
                      >
                        {customer.name}
                      </Link>
                    ) : (
                      customer.name
                    )}
                  </Col>
                  <Col key={`industry-${customer.id}`}>
                    {translateCustomerAccountDetails('sector')}: <br />
                    {getCustomerSector(customer)}
                  </Col>
                  <Col key={`responsible-${customer.id}`}>
                    {translateCustomerAccountDetails('responsible')}: <br />
                    {getCustomerResponsible(customer)}
                  </Col>
                  <Col key={`responsible-${customer.id}`}>
                    {translateCustomerAccountDetails('involved')}: <br />
                    {getCustomerInvolved(customer)}
                  </Col>
                </Row>
                <br />
              </>
            ))}
        </div>
      ) : (
        <div>{None}</div>
      ),
    },
    {
      label: translateCustomerAccountDetails('sector'),
      value: (
        <div>
          {accountIndustry.map((sector) => (
            <Row style={{ height: '22px' }} key={`sector-row-${sector}`}>
              <Col key={`sector-col-${sector}`}>{sector}</Col>
            </Row>
          ))}
        </div>
      ),
    },
    {
      label: translateCustomerAccountDetails('responsible'),
      value: responsible ? (
        <Row style={{ height: '22px' }}>
          <Col>
            <Link
              to={`/employees/employee-list/employee-detail/${responsible.id}`}
            >
              {formatResponsible(
                getEmployeeName(responsible.id ?? NO_ID, employees),
                responsible.responsibleRole.name,
                null
              )}
            </Link>
          </Col>
        </Row>
      ) : (
        <div>{None}</div>
      ),
    },
    {
      label: translateCustomerAccountDetails('involved'),
      value: involvedResponsibles ? (
        <div>
          {involvedResponsibles.map(({ id, responsibleRole: { name } }) => (
            <Row key={id} style={{ height: '22px' }}>
              <Col key={id}>
                <Link to={`/employees/employee-list/employee-detail/${id}`}>
                  {formatResponsible(
                    getEmployeeName(id ?? NO_ID, employees),
                    name,
                    null
                  )}
                </Link>
              </Col>
            </Row>
          ))}
        </div>
      ) : (
        <div>{None}</div>
      ),
    },
    {
      label: translateCustomerAccountDetails('mobile'),
      value: contactInformation ? (
        <div>
          {getContactInfo(contactInformation, ContactInfoType.MOBILE).map(
            ({ id, info }) => (
              <Row key={id} style={{ height: '20px' }}>
                <Col key={id}>{info} </Col>
              </Row>
            )
          )}
        </div>
      ) : (
        <div>{None}</div>
      ),
    },
    {
      label: translateCustomerAccountDetails('phone'),
      value: contactInformation ? (
        <div>
          {getContactInfo(contactInformation, ContactInfoType.PHONE).map(
            ({ id, info }) => (
              <Row key={id} style={{ height: '20px' }}>
                <Col key={id}>{info} </Col>
              </Row>
            )
          )}
        </div>
      ) : (
        <div>{None}</div>
      ),
    },
    {
      label: translateCustomerAccountDetails('email'),
      value: contactInformation ? (
        <div>
          {getContactInfo(contactInformation, ContactInfoType.EMAIL).map(
            ({ id, info, status }) => (
              <Row key={id} style={{ height: '20px' }}>
                <Col key={id}>
                  <a href={`mailto:${info}`}>{info} </a>
                </Col>
                <Col> {translateCustomerAccountDetails(status)} </Col>
              </Row>
            )
          )}
        </div>
      ) : (
        <div>{None}</div>
      ),
    },
  ];

  const fetchEmployees = async () => {
    await getEmployeeNames({})
      .then(({ data }) => {
        if (Array.isArray(data)) {
          setEmployees(data);
        }
      })
      .catch((error) => {
        modalErrorHandler(
          translateCustomerAccountDetails('failedToRetrieveEmployees'),
          error
        );
      });
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  return <DetailsCard fields={fields} />;
};

export default withModals(CustomerAccountDetailsTable);
