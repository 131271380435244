import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import axios from '../../services/axios/axios';
import Header from '../../components/layout/Header';
import HeaderTitle from '../../components/layout/HeaderTitle';
import { userToDoActivities } from '../../utils/constants';
import { SEND_NOTIFICATIONS_VIA_ENUM } from '../../utils/enums/service';
import { getNotificationSettingObjectOfCurrentUser } from '../../utils/helpers/GenericHelper';
import i18n from '../../i18n';
import { loadNotifications } from '../../redux/notificationsSlice';
import AbsenceCalendar from './AbsenceCalendar';
import FadeAlert from '../../components/layout/FadeAlert';
import ModalNotificationSetting from './ModalNotificationSetting';
import {
  SortNotifications,
  UnreadNotifications,
} from '../../NotificationFunctions';
import NotificationsListTable from './NotificationsListTable';
import ToDoListOverview from './ToDoListOverview';

const calendarView = 'calendar';

const EMAIL_OR_CRM_TOOL = 'emailOrCRMToolOption';

function View(props) {
  if (props.view === calendarView) {
    return <AbsenceCalendar />;
  } else {
    return undefined;
  }
}

/**
 * Main page.
 *
 * Dashboard page that shows notifications and a calendar
 *
 */
class Index extends Component {
  currentCalendarView = '';

  constructor(props) {
    super(props);
    this.state = {
      view: calendarView,
      notifications: this.props.notifications.notifications,
      showModalNotificationSetting: false,
      selectedFromServiceList: [],
      sendNotificationViaList: [],
      toggleNotificationListTable: false,
      currentUserId: null,
      showActivityOverview: true,
      activities: [],
    };
    this.OnViewEvent = this.OnViewEvent.bind(this);
  }

  componentDidMount = async () => {
    let currentUserId = this.props.account?.employeeDetails?.id;
    if (currentUserId) {
      let notificationSettingObject =
        getNotificationSettingObjectOfCurrentUser();

      // List of selected services for notification retrieval
      let selectedFromServiceList =
        notificationSettingObject?.selectedFromServices;

      // List of selected way to send the notification
      let sendNotificationViaList =
        notificationSettingObject?.sendNotificationVia;

      this.setState({
        selectedFromServiceList,
        sendNotificationViaList,
        currentUserId,
      });
    }
    // Get Current User's Activities
    this.setState({
      activities: await axios.project
        .get('current-user-activities')
        .then((response) => this.flattenToDoActivities(response.data)),
    });
  };

  /**
   * Function to format activities from extendedUserToDoActivities to just userToDoActivities with its object attached
   * @param {*} activities
   * @returns formatted userToDoActivities
   */
  flattenToDoActivities = (activities) => {
    const flattenedActivities = [];
    activities.forEach((extendedActivity) => {
      // ExtendedActivities currently has format of @ extendedActivity{userToDoActivity,contactPerson,project}
      // Need to make it so it would look like activity{...userToDoActivity,{contactPerson|project}}
      let flattenedActivity = { ...extendedActivity.userToDoActivity };

      // Get all property names of the extendedActivity @ userToDoActivity|contactPerson|project ...
      const keyNames = Object.keys(extendedActivity);

      keyNames.forEach((item) => {
        // Only add the property to the flattenedActivity if its not userToDoActivities or not null
        if (item !== userToDoActivities || extendedActivity[item] !== null) {
          flattenedActivity[item] = extendedActivity[item];
        }
      });
      flattenedActivities.push(flattenedActivity);
    });
    return flattenedActivities;
  };

  t(keyName) {
    return i18n.t('index.' + keyName);
  }
  OnViewEvent(e) {
    this.currentCalendarView = e;
  }
  /**
   * Toggle for notif view and to do list overview
   */
  toggleView = () => {
    this.setState({ showActivityOverview: !this.state.showActivityOverview });
  };
  /**
   * Parse string to JSON object. Return the object if parseable or undefined if not
   */
  parseJSONString = (string) => {
    try {
      let jsonObject = JSON.parse(string);
      return jsonObject;
    } catch (e) {
      return undefined;
    }
  };

  updateUserSettingConfig = (userSetting, configObject) => {
    return {
      ...userSetting,
      config: JSON.stringify(configObject),
    };
  };

  updateFromService = async (updatedFromServiceOrViaTool, section) => {
    let currentNotificationSettingConfig =
      getNotificationSettingObjectOfCurrentUser();
    let userSetting = this.props.userSetting?.userSetting;
    let configObject = this.parseJSONString(userSetting.config);
    if (!(configObject instanceof Object)) {
      return;
    }

    let userSettingObject = configObject?.userSetting;
    if (!(userSettingObject instanceof Object)) {
      return;
    }

    if (section === EMAIL_OR_CRM_TOOL) {
      currentNotificationSettingConfig.sendNotificationVia =
        updatedFromServiceOrViaTool;

      userSettingObject.notificationSettingConfig =
        currentNotificationSettingConfig;

      configObject.userSetting = userSettingObject;

      const updatedUserSetting = this.updateUserSettingConfig(
        userSetting,
        configObject
      );

      // Update userSetting
      await axios.notification
        .put(`user-settings/${userSetting.id}`, updatedUserSetting)
        .then((response) => {
          return response?.data;
        });

      if (
        !updatedFromServiceOrViaTool.includes(
          SEND_NOTIFICATIONS_VIA_ENUM.crmTool.code
        )
      ) {
        this.props.loadNotifications(SortNotifications([]));
      } else {
        if (this.state.currentUserId) {
          await axios.notification
            .get(
              `notifications?employeeId.equals=${this.state.currentUserId}&fromService.in=${this.state.selectedFromServiceList}`
            )
            .then((response) => {
              let employeeNotifications = response.data;

              this.props.loadNotifications(
                SortNotifications(employeeNotifications)
              );
            });
        }
      }

      this.setState({
        sendNotificationViaList: updatedFromServiceOrViaTool,
        toggleNotificationListTable: !this.state.toggleNotificationListTable,
      });
    } else {
      currentNotificationSettingConfig.selectedFromServices =
        updatedFromServiceOrViaTool;

      userSettingObject.notificationSettingConfig =
        currentNotificationSettingConfig;

      configObject.userSetting = userSettingObject;

      const updatedUserSetting = this.updateUserSettingConfig(
        userSetting,
        configObject
      );

      // Update userSetting
      await axios.notification
        .put(`user-settings/${userSetting.id}`, updatedUserSetting)
        .then((response) => {
          return response?.data;
        });

      if (this.state.currentUserId) {
        await axios.notification
          .get(
            `notifications?employeeId.equals=${this.state.currentUserId}&fromService.in=${updatedFromServiceOrViaTool}`
          )
          .then((response) => {
            let employeeNotifications = response.data;

            this.props.loadNotifications(
              SortNotifications(employeeNotifications)
            );
          });
      }

      this.setState({
        selectedFromServiceList: updatedFromServiceOrViaTool,
        toggleNotificationListTable: !this.state.toggleNotificationListTable,
      });
    }
  };

  toggleNotificationSettingModal = () => {
    this.setState({
      showModalNotificationSetting: !this.state.showModalNotificationSetting,
    });
  };

  render() {
    let selectedNotificationFromService = this.state.selectedFromServiceList;
    let selectedEmailOrCRMTool = this.state.sendNotificationViaList;

    return (
      <Container fluid>
        <Header>
          <HeaderTitle>
            {this.t('welcomeBack')}
            {this?.props?.account?.accountDetails?.firstName}!
          </HeaderTitle>
        </Header>

        <Row>
          <Col>
            <Card className="flex-fill">{View({ view: this.state.view })}</Card>
          </Col>
          <Col xl="6">
            <Card>
              <CardHeader>
                <Button
                  className="float-end"
                  color="primary"
                  onClick={this.toggleView}
                >
                  {this.state.showActivityOverview
                    ? this.t('viewNotifications')
                    : this.t('viewActivities')}
                </Button>
              </CardHeader>
              {this.state.showActivityOverview ? (
                <ToDoListOverview activities={this.state.activities} />
              ) : (
                <Card className="flex-fill">
                  <CardHeader>
                    {this.props.location.state?.successMessage && (
                      <FadeAlert color="success">
                        {this.props.location.state.successMessage}
                      </FadeAlert>
                    )}
                    <h5>
                      <div className="float-start">
                        {this.state.sendNotificationViaList.includes(
                          SEND_NOTIFICATIONS_VIA_ENUM.crmTool.code
                        )
                          ? UnreadNotifications(
                              this.props.notifications.notifications
                            )?.length > 0
                            ? this.t('unreadNotifications') +
                              ': ' +
                              UnreadNotifications(
                                this.props.notifications.notifications
                              ).length
                            : this.t('noNewUnreadNotifications')
                          : this.t('notificationDisplayDisabled')}
                      </div>
                      <div className="float-end">
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => this.toggleNotificationSettingModal()}
                        >
                          <FontAwesomeIcon icon={faCogs} />
                        </Button>
                      </div>
                    </h5>
                  </CardHeader>
                  <NotificationsListTable
                    selectedNotificationFromService={
                      selectedNotificationFromService
                    }
                    selectedEmailOrCRMTool={selectedEmailOrCRMTool}
                    notifications={this.state.notifications}
                    toggleNotificationListTable={
                      this.state.toggleNotificationListTable
                    }
                  />
                </Card>
              )}
              <CardBody></CardBody>
            </Card>
          </Col>
        </Row>
        <ModalNotificationSetting
          isOpen={this.state.showModalNotificationSetting}
          onClose={this.toggleNotificationSettingModal}
          size="sm"
          selectedNotificationFromService={this.state.selectedFromServiceList}
          selectedEmailOrCRMTool={this.state.sendNotificationViaList}
          updateFromService={this.updateFromService}
        ></ModalNotificationSetting>
      </Container>
    );
  }
}

const mapStateToProps = (store) => ({
  notifications: store.notifications,
  account: store.account,
  userSetting: store.userSetting,
});

const mapDispatchToProps = {
  loadNotifications,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Index));
