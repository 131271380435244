import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import Datetime from 'react-datetime';
import Select from 'react-select';
import { Card, CardBody, CardHeader, Col, Input, Row, Table } from 'reactstrap';

import InputFormLabel from '../../../components/form/InputFormLabel';
import { defaultTableLineHeight } from '../../../utils/constants';
import {
  addWeekdays,
  getDateFormat,
} from '../../../utils/helpers/GenericHelper';
import withModals, {
  IWithModalsProps,
} from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import {
  IApprovalRequest,
  IDepartment,
  IEmployee,
  IOffer,
  IProject,
} from '../../../utils/types/modelTypes';
import CurrencyDropdown from '../../../components/dropdowns/CurrencyDropdown';
import DepartmentDropdown from '../../../components/dropdowns/DepartmentDropdown';
import ModifiedEmployeeDropdown from '../../../components/dropdowns/EmployeeDropdown/ModifiedEmployeeDropdown';
import { sortOptionsByValue } from '../../../utils/helpers/dropdown';
import { IDurationObject, computeLength, computeLengthInitial, cupt, dateCalculatorForNonDays, dropdownProjectStateItems } from './projectListHelpers';

// Constants for the date length types
const durationTypes = ['months', 'weeks', 'days'];
const DAYS = 'days';

interface IProps extends IWithModalsProps {
  project: IProject;
  offer: IOffer;
  approvalRequest?: IApprovalRequest | null | undefined;
  lockInputs: boolean;
  isProjectInternal?: boolean;
  headOfDepartment?: IEmployee | undefined;

  onChange: (project: IProject) => void;
  onBudgetChange: (offer: IOffer) => void;
  onApproverChange?: (employee: IDropdownOption<number>) => void;
}

/**
 * This class displays the unbilled project budget and schedule card.\
 */
const ProjectBudgetAndScheduleCard: React.FC<IProps> = ({
  onChange,
  onBudgetChange,
  onApproverChange,
  lockInputs,
  project,
  offer,
  approvalRequest,
  isProjectInternal,
  headOfDepartment,
}: IProps) => {
  const [selectedEmployeeForApprovalId, setSelectedEmployeeForApproval] =
    useState(approvalRequest?.employee?.id);
  const [projectDuration, setProjectDuration] = useState(
    computeLengthInitial(project)
  );
  const [projectDurationType, setProjectDurationType] = useState(
    durationTypes[0] as string
  );

  /**
   * Handles changes made in Employee field
   * @param {*} selectedEmployeeForApproval
   */
  const handleEmployeeChange = (
    selectedEmployeeForApproval: IDropdownOption<number>
  ) => {
    setSelectedEmployeeForApproval(selectedEmployeeForApproval.value);
    if (onApproverChange) {
      onApproverChange(selectedEmployeeForApproval);
    }
  };

  /**
   * Handles calculation of end date based on start and project duration
   * @param {*} value - duration value
   */
  const handleDateCalculations = (value: any) => {
    // Regex for double, accepts 10 digit before the decimal point and 2 digits after the decimal point
    const regex = /^\d{0,10}(\.\d{0,1}){0,1}$/;
    const projectCalc = project ? { ...project } : ({} as IProject);
    const valueType = projectDurationType;

    const valueFloat = value ? parseFloat(value) : 0;
    if (value.match(regex) || value === '') {
      if (projectCalc?.start) {
        const startDate = moment(projectCalc?.start);

        // Days have a slightly different calculation
        if (valueType !== DAYS) {
          projectCalc.end = dateCalculatorForNonDays(
            startDate,
            valueFloat,
            valueType
          ).toDate();
        } else {
          projectCalc.end = moment(
            addWeekdays(moment(projectCalc.start), valueFloat - 1)
          ).toDate();
        }
        onChange(projectCalc);
        const durationObject = computeLength(
          new Date(projectCalc.start as any),
          new Date(projectCalc.end)
        );
        setProjectDuration(durationObject);
        // Month is to be inserted in the duration column
        projectCalc.duration =
          durationObject[durationTypes[0] as keyof IDurationObject];
        onChange(projectCalc);
      } else {
        // Calculates the duration only but does not return a date
        const startDate = moment();
        let endinDate;

        // Days have a slightly different calculation
        if (valueType !== DAYS) {
          endinDate = dateCalculatorForNonDays(
            startDate,
            valueFloat,
            valueType
          );
        } else {
          endinDate = moment(addWeekdays(startDate, valueFloat - 1));
        }
        const noStartDurationObject = computeLength(
          new Date(startDate.toDate()),
          new Date(endinDate.toDate())
        );
        setProjectDuration(noStartDurationObject);
        projectCalc.duration =
          noStartDurationObject[durationTypes[0] as keyof IDurationObject];
        onChange(projectCalc);
      }

      // Month is to be inserted in the duration column
      if (valueType === durationTypes[0]) {
        projectCalc.duration = valueFloat;
      }
    }
  };

  /**
   * Handles changes to the state field
   * @param projectState
   */
  const handleStateChange = (projectState: IDropdownOption) => {
    const projectChange = project ? { ...project } : ({} as IProject);

    const state = projectState.value;

    projectChange.state = state;
    onChange(projectChange);
  };

  /**
   * Handles changes in start date
   * @param {*} start
   */
  const handleStartDateChange = (start: any) => {
    const projectChange = project ? { ...project } : ({} as IProject);
    const startDate = new Date(start);

    projectChange.start = startDate;
    onChange(projectChange);

    if (projectChange.duration) {
      handleDateCalculations(projectChange.duration.toString());
    }
  };

  /**
   * Handles changes in project duration field
   * @param {*} durationValue
   */
  const handleProjectDuration = (
    durationValue: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleDateCalculations(durationValue.target.value);
  };

  /**
   * Handles changes in duration type
   * @param {*} event
   */
  const handleDurationTypeChange = (event: IDropdownOption) => {
    setProjectDurationType(event.value);
  };

  /**
   * Handles changes in departments
   * @param {*} departments
   */
  const handleDepartmentChange = (departments: IDepartment[]) => {
    onChange({ ...project, departments });
  };

  /**
   * Handles changes made in Currency field
   * @param {*} currency
   */
  const handleCurrencyChange = (currency: string) => {
    const offerChanged = offer ? { ...offer } : ({} as IOffer);
    offerChanged.currency = currency;
    onBudgetChange(offerChanged);
  };

  const handlePlannedInvestmentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const offerChanged = offer ? { ...offer } : ({} as IOffer);
    offerChanged.budget = parseFloat(event.target.value);
    onBudgetChange(offerChanged);
  };

  const dateFormat = getDateFormat();
  const startDate = project?.start;
  const departments = project?.departments;
  const dateTimeProps = {
    style: { height: '30px' },
    dateFormat,
    input: true,
    timeFormat: false,
    inputProps: {
      placeholder: dateFormat,
      disabled: lockInputs,
    },
    onChange: handleStartDateChange,
    closeOnSelect: true,
    value: startDate ? moment(startDate)?.format(dateFormat) : '',
    locale: i18n.language,
  };
  const internalCheck = isProjectInternal ?? false;
  const approvalRequestValue = headOfDepartment?.id
    ? `${headOfDepartment?.firstname} ${headOfDepartment?.name}`
    : cupt('headOfDepartment');
  return (
    <Card className="small-card">
      <div style={{ textAlign: 'center' }}>
        <CardHeader>
          <h3>{cupt('budgetAndSchedule')}</h3>
        </CardHeader>
      </div>
      <CardBody>
        <fieldset>
          <Table borderless style={defaultTableLineHeight}>
            <tbody>
              <tr
                style={{
                  width: '100%',
                  textAlign: 'right',
                }}
              >
                <th style={{ width: '50%', textAlign: 'right' }}>
                  <InputFormLabel
                    isRequired={!internalCheck}
                    text={cupt('dateOfProjectStart')}
                  />
                </th>
                <td>
                  <Datetime {...dateTimeProps} />
                </td>
              </tr>
              <tr
                style={{
                  textAlign: 'right',
                }}
              >
                <th>
                  <InputFormLabel
                    isRequired={!internalCheck}
                    text={cupt('totalProjectDuration')}
                  />
                </th>
                <td align="left">
                  <Row>
                    <Col lg="6">
                      <Input
                        type="number"
                        min="0"
                        style={{ width: '140px', height: '30px' }}
                        value={
                          projectDuration[
                            projectDurationType as keyof IDurationObject
                          ]
                        }
                        onChange={handleProjectDuration}
                        // Months and weeks are to be placed in 0.5 increments, days is set to 1 increment
                        step={projectDurationType === DAYS ? 1 : 0.5}
                        disabled={lockInputs}
                        title={cupt('projectDurationTooltip')}
                      />
                    </Col>
                    <Col>
                      <Select
                        style={{ width: '140px', height: '30px' }}
                        options={durationTypes.map((duration) => ({
                          value: duration,
                          label: cupt(duration),
                        }))}
                        value={durationTypes
                          .map((duration) => ({
                            value: duration,
                            label: cupt(duration),
                          }))
                          .filter(
                            (choice) => choice.value === projectDurationType
                          )}
                        onChange={handleDurationTypeChange}
                        isDisabled={lockInputs}
                      />
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr style={{ textAlign: 'right' }}>
                <th>
                  <InputFormLabel
                    isRequired={!internalCheck}
                    text={cupt('plannedInvestment')}
                  />
                </th>
                <td align="left">
                  <Row>
                    <Col lg="6">
                      <Input
                        type="number"
                        min="0"
                        style={{ width: '140px', height: '30px' }}
                        value={offer.budget}
                        onChange={handlePlannedInvestmentChange}
                        step={1}
                      />
                    </Col>
                    <Col>
                      <CurrencyDropdown
                        currency={offer.currency ?? null}
                        onChange={handleCurrencyChange}
                        isClearable
                      />
                    </Col>
                  </Row>
                </td>
              </tr>
              {!internalCheck && (
                <tr style={{ textAlign: 'right' }}>
                  <th>
                    <InputFormLabel
                      text={cupt('departments')}
                      isRequired={false}
                    />
                  </th>
                  <td align="left">
                    <div style={{ width: '300px' }}>
                      <DepartmentDropdown
                        selectedDepartments={departments ?? []}
                        onChange={handleDepartmentChange}
                        isMulti
                      />
                    </div>
                  </td>
                </tr>
              )}
              <tr style={{ textAlign: 'right' }}>
                <th>
                  <InputFormLabel
                    text={cupt('approvalRequest')}
                    isRequired={!internalCheck}
                  />
                </th>
                {!internalCheck ? (
                  <td align="left">
                    <div style={{ width: '300px' }}>
                      <ModifiedEmployeeDropdown
                        permissions={[]}
                        employeeId={selectedEmployeeForApprovalId ?? 0}
                        isDisabled={lockInputs}
                        onChange={handleEmployeeChange}
                      />
                    </div>
                  </td>
                ) : (
                  <td>
                    <Input
                      type="string"
                      bsSize="md"
                      disabled
                      value={approvalRequestValue}
                    />
                  </td>
                )}
              </tr>
              <tr style={{ textAlign: 'right' }}>
                <th>
                  <InputFormLabel text={cupt('projectStatus')} isRequired />
                </th>
                <td align="left">
                  <div style={{ width: '300px' }}>
                    <Select
                      id="projectStateSelect"
                      style={{ fontSize: 16 }}
                      options={sortOptionsByValue(dropdownProjectStateItems)}
                      onChange={handleStateChange}
                      isDisabled={lockInputs}
                      placeholder={cupt('selectState')}
                      value={dropdownProjectStateItems.find(
                        (state) => state.value === project.state
                      )}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </fieldset>
      </CardBody>
    </Card>
  );
};

export default withModals(ProjectBudgetAndScheduleCard);
