import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { Menu, MenuItem } from '@mui/material';
import moment from 'moment';

import {
  IObjectProjectResource,
  IObjectProjectUtilization,
  IObjectWorkload,
  IObjectWorkloadResource,
  IObjectWorkloadUtilization,
} from '../../../utils/types/commonTypes';
import {
  changeCellColorByState,
  t,
  workloadt,
} from '../UtilizationOverview/utilizationHelper';
import { ProjectEmployeeWorkloadState } from '../../../utils/enums/project';
import { dateFormat } from '../../../utils/constants';
import { updateWorkloadState } from '../../../services/api/resourcePlanner';
import withModals, { IWithModalsProps } from '../../../utils/withModals';

interface IProps extends IWithModalsProps {
  projectWorkload: IObjectProjectResource | IObjectProjectUtilization;
  updateWorkloadStatus: (
    updatedProjectUtilization:
      | IObjectProjectResource
      | IObjectProjectUtilization
  ) => void;
  isWeekly?: boolean;
}

const ProjectWorkloadRowView = ({
  projectWorkload,
  updateWorkloadStatus,
  modalErrorHandler,
  isWeekly,
}: IProps) => {
  const { projectId, projectName, workloadUtilizations } =
    projectWorkload as IObjectProjectUtilization;
  const { weeklyWorkloadUtilizations } =
    projectWorkload as IObjectProjectResource;
  const workload = isWeekly ? weeklyWorkloadUtilizations : workloadUtilizations;

  const [selectedWorkload, setSelectedWorkload] = useState<IObjectWorkload>(
    {} as IObjectWorkload
  );
  const [workloadStateAnchor, setWorkloadStateAnchor] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleUpdateEmployeeUtilization = async (
    projectUtilizationReference: IObjectProjectUtilization,
    updatedWorkload: IObjectWorkload
  ) => {
    try {
      // Update the Database
      const { data: workload } = await updateWorkloadState(updatedWorkload);
      const { workloadState } = workload;
      // Update ProjectUtilization
      const { workloadUtilizations } = projectUtilizationReference;
      const updatedProjectUtilization = {
        ...projectUtilizationReference,
        workloadUtilizations: workloadUtilizations.map((workloadRef) => ({
          ...workloadRef,
          workloadState,
        })),
      };

      updateWorkloadStatus(updatedProjectUtilization);
    } catch (error) {
      modalErrorHandler(t('failedToUpdateProjectEmployeeWorkload'), error);
    }
  };

  const handleWorkloadStateContextMenu = (
    event: React.MouseEvent,
    workload: IObjectWorkloadResource | IObjectWorkloadUtilization
  ) => {
    event.preventDefault();
    setWorkloadStateAnchor(
      workloadStateAnchor === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );

    let toUpdateWorkload;
    if ('week' in workload) {
      const { week, ...item } = workload;
      toUpdateWorkload = item;
    } else {
      const { month, ...item } = workload;
      toUpdateWorkload = item;
    }
    setSelectedWorkload(toUpdateWorkload);
  };

  const handleCloseWorkloadStateMenu = () => {
    setWorkloadStateAnchor(null);
  };

  const handleWorkloadStatus = (
    projectUtilizationReference: IObjectProjectUtilization,
    state: string
  ) => {
    const updatedWorkload = {
      ...selectedWorkload,
      workloadState: state,
    };
    handleCloseWorkloadStateMenu();
    handleUpdateEmployeeUtilization(
      projectUtilizationReference,
      updatedWorkload
    );
  };

  return (
    <tr key={projectId}>
      <td className="stickyFirstColumn" />
      <td className="stickySecondColumn">
        <Link to={`/projects/overview/project-details/${projectId}`}>
          {projectName}
        </Link>
      </td>
      {workload.map((workloadUtilization, idx) => {
        const { workloadPercentage, workloadState, startDate, endDate } =
          workloadUtilization;
        return (
          <>
            <td
              key={`workload-${projectId}-${idx}`}
              id={`workload-${projectId}-${idx}`}
              className="border border-white"
              style={{
                cursor: 'pointer',
                color: 'black',
                backgroundColor: changeCellColorByState(
                  workloadState,
                  workloadPercentage
                ),
              }}
              onContextMenu={(event: React.MouseEvent) =>
                handleWorkloadStateContextMenu(event, workloadUtilization)
              }
            >
              {`${workloadPercentage.toFixed(2)}%`}
            </td>
            <UncontrolledTooltip
              placement="right"
              target={`workload-${projectId}-${idx}`}
            >
              <div>{`Start Date: ${
                startDate ? moment(startDate).format(dateFormat).toString() : ''
              }`}</div>
              <div>{`End Date: ${
                endDate ? moment(endDate).format(dateFormat).toString() : ''
              }`}</div>
              <div>{`Status: ${
                workloadState ? workloadt(workloadState) : ''
              }`}</div>
            </UncontrolledTooltip>
            <Menu
              open={workloadStateAnchor !== null}
              onClose={handleCloseWorkloadStateMenu}
              anchorReference="anchorPosition"
              anchorPosition={
                workloadStateAnchor !== null
                  ? {
                      top: workloadStateAnchor.mouseY,
                      left: workloadStateAnchor.mouseX,
                    }
                  : { top: 0, left: 0 }
              }
            >
              <MenuItem
                onClick={() =>
                  handleWorkloadStatus(
                    projectWorkload,
                    ProjectEmployeeWorkloadState.BOOKED
                  )
                }
              >
                {workloadt(ProjectEmployeeWorkloadState.BOOKED)}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleWorkloadStatus(
                    projectWorkload,
                    ProjectEmployeeWorkloadState.BOOKED_AND_OPEN
                  )
                }
              >
                {workloadt(ProjectEmployeeWorkloadState.BOOKED_AND_OPEN)}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleWorkloadStatus(
                    projectWorkload,
                    ProjectEmployeeWorkloadState.RESERVED
                  )
                }
              >
                {workloadt(ProjectEmployeeWorkloadState.RESERVED)}
              </MenuItem>
            </Menu>
          </>
        );
      })}
    </tr>
  );
};

export default withModals(ProjectWorkloadRowView);
