import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Button, Form, FormGroup, Input, Row } from 'reactstrap';
import { AxiosError } from 'axios';
import Select from 'react-select';

import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { generateTitle } from '../../../utils/helpers/icon';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { countCustomerAccount } from '../../../services/api/customer';
import {
  ICustomerAccountDetail,
  ICustomerListItem,
} from '../../../utils/types/responseTypes';
import {
  DEFAULT_LOAD_TIMEOUT,
  maxEmailCount,
  maxMobileCount,
  maxPhoneCount,
} from '../../../utils/constants';
import ContactInformationInputOld from '../../../components/form/ContactInformationInputOld';
import { ContactInfoType } from '../../../utils/enums/contact';
import {
  translateAddOrUpdate,
  translateCustomerAccountFormInput,
} from './customerAccountListHelper';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import {
  getEmployeeNames,
  getResponsibleRoles,
} from '../../../services/api/employee';
import {
  IDropdownOption,
  IObjectContactInformation,
  IObjectInvolvedResponsible,
  IResponsibleDropdown,
} from '../../../utils/types/commonTypes';
import {
  objectNameAndIdToDropdownOption,
  objectNameAndIdToDropdownOptions,
} from '../../../utils/helpers/dropdown';
import MultipleResponsibleDropdown from '../../../components/form/MultipleResponsibleDropdown';
import { IContactInformation } from '../../../utils/types/modelTypes';
import { ContactInformationStatus } from '../../../utils/enums/contactPerson';
import { addCustomerAccount } from '../../../redux/customerAccountListSlice';
import DetailsCard, { CardData } from '../../../components/cards/DetailsCard';
import useMounted from '../../../hooks/useMounted';

interface IProps extends PropsFromRedux, IWithModalsProps {
  onClose: () => void;
}

export type AccountInvolvedResponsible = {
  responsible: IDropdownOption<number>;
  responsibleRole: IDropdownOption<number>;
};

const AddCustomerAccountModal = ({
  onClose,
  modalErrorHandler,
  addCustomerAccount,
}: IProps) => {
  // Check for component mount
  const isMounted = useMounted();

  // Options for the dropdown
  const [involvedResponsibleOptions, setInvolvedResponsibleOptions] = useState(
    [] as IDropdownOption<number>[]
  );
  const [responsibleRoleOptions, setResponsibleRoleOptions] = useState(
    [] as IDropdownOption<number>[]
  );

  const [customerAccount, setCustomerAccount] =
    useState<ICustomerAccountDetail>({
      id: 0,
      name: '',
      responsible: {
        id: 0,
        name: '',
        responsibleRole: {
          id: 0,
          name: '',
        },
      },
      customers: [] as ICustomerListItem[],
      sectors: [] as string[],
      involvedResponsibles: [] as IObjectInvolvedResponsible[],
      contactInformation: [] as IObjectContactInformation[],
    });
  const [isNameValid, setIsNameValid] = useState(false);

  // Customer Account destructured
  const {
    responsible: { id: responsibleId, responsibleRole },
    responsible,
    involvedResponsibles,
    name,
    contactInformation,
  } = customerAccount;

  const involvedResponsibleOptionsFiltered = involvedResponsibleOptions.filter(
    ({ value }) =>
      value !== responsibleId &&
      !involvedResponsibles.some((e) => e.id === value)
  );

  const isInputValid = name !== '' && responsibleId !== 0 && isNameValid;

  // Handles the change of responsible
  const handleResponsibleChange = ({
    value: id,
    label: name,
  }: IDropdownOption<number>) => {
    setCustomerAccount({
      ...customerAccount,
      responsible: {
        ...responsible,
        id,
        name,
      },
    });
  };

  const handleResponsibleRoleChange = ({
    value: id,
    label: name,
  }: IDropdownOption<number>) => {
    setCustomerAccount({
      ...customerAccount,
      responsible: {
        ...responsible,
        responsibleRole: {
          id,
          name,
        },
      },
    });
  };

  const handleInvolvedChange = (involved: IResponsibleDropdown[]) => {
    const involvedConverted = involved.map(
      ({ responsible, responsibleRole }) =>
        ({
          ...(responsible
            ? {
                id: responsible.value,
                name: responsible.label,
              }
            : {}),
          ...(responsibleRole
            ? {
                responsibleRole: {
                  id: responsibleRole.value,
                  name: responsibleRole.label,
                },
              }
            : {}),
        } as IObjectInvolvedResponsible)
    );
    setCustomerAccount({
      ...customerAccount,
      involvedResponsibles: involvedConverted,
    });
  };

  // Handles the change of name
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerAccount({
      ...customerAccount,
      name: event.target.value,
    });
  };

  /**
   * Handles deletion of contact information
   * @param {*} _deletedContactInformation
   */
  const handleDeleteContactInformation = (
    _: IContactInformation,
    index: number
  ) => {
    const contactInformationSpliced = [...contactInformation];
    contactInformationSpliced.splice(index, 1);
    setCustomerAccount({
      ...customerAccount,
      contactInformation: contactInformationSpliced,
    });
  };

  /**
   * Handles contact information change
   */
  const handleContactInformationsChange = (
    { target: { value: info } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // Check if the item even exists
    const contactInformationToChange = contactInformation[index];

    // If it does, begin splicing
    if (contactInformationToChange !== undefined) {
      const { id, type, status } = contactInformationToChange;
      const contactInformationUpdated = [...contactInformation];
      contactInformationUpdated.splice(index, 1, {
        id,
        type,
        status,
        info,
      });
      setCustomerAccount({
        ...customerAccount,
        contactInformation: contactInformationUpdated,
      });
    }
  };

  const involvedDropdownItems = involvedResponsibles.map(
    ({ id, name, responsibleRole }) =>
      ({
        ...(id && name
          ? {
              responsible: { label: name, value: id },
            }
          : {}),
        ...(responsibleRole
          ? {
              responsibleRole: {
                label: responsibleRole.name,
                value: responsibleRole.id,
              },
            }
          : {}),
      } as {
        responsible: IDropdownOption<number>;
        responsibleRole: IDropdownOption<number>;
      })
  );

  const submitEvent = () => {
    addCustomerAccount(customerAccount, onClose, (error) =>
      modalErrorHandler(translateAddOrUpdate('failedToAddAccount'), error)
    );
  };

  /**
   * Method for disabling a button after reaching a maximum number of inputs
   * @param {*} type
   * @param {*} value
   * @returns true or false
   */
  const isButtonDisabled = (infoType: string, value: number) =>
    contactInformation.filter(({ type }) => type === infoType).length === value;

  /**
   * Adds a new contact information to the contact person.
   *
   * @param infoType The type of contact information to add.
   */
  const addContactInformation = (infoType: string) => {
    setCustomerAccount({
      ...customerAccount,
      contactInformation: [
        ...contactInformation,
        {
          id: 0,
          info: '',
          status: ContactInformationStatus.UNKNOWN,
          type: infoType,
        },
      ],
    });
  };

  useEffect(() => {
    const {
      employeeAsResponsible: {
        readWrite: { uri, accessType },
      },
    } = PERMISSION_URI;
    /* Get people that are allowed to be involved */
    getEmployeeNames({
      'permissionsFilter.in': uri,
      'accessTypeFilter.in': accessType,
    })
      .then(({ data: involvedResponsiblesOptions }) => {
        setInvolvedResponsibleOptions(
          objectNameAndIdToDropdownOptions(involvedResponsiblesOptions)
        );
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(translateAddOrUpdate('failedToGetEmployees'), error);
      });

    getResponsibleRoles()
      .then(({ data: responsibleRoles }) => {
        setResponsibleRoleOptions(
          responsibleRoles.map(({ id, role }) => ({
            value: id as number,
            label: role as string,
          }))
        );
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(translateAddOrUpdate('failedToRetrieveRoles'), error);
      });
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    setIsNameValid(false);

    // If name is empty, do not bother
    if (!name) {
      return () => clearTimeout(timer);
    }

    if (isMounted) {
      timer = setTimeout(() => {
        countCustomerAccount({ 'name.equals': name })
          .then((res) => {
            setIsNameValid(res.data <= 0);
          })
          .catch((error) => {
            modalErrorHandler(
              translateAddOrUpdate('failedToCheckIfAccoutNameExists'),
              error
            );
          });
      }, DEFAULT_LOAD_TIMEOUT);
    }

    return () => clearTimeout(timer);
  }, [name]);

  // Add the initial fields
  const fields = [
    {
      label: translateCustomerAccountFormInput('name'),
      value: (
        <Input
          data-testid="customer-account-name-input"
          type="string"
          bsSize="lg"
          onChange={handleNameChange}
          value={name || ''}
        />
      ),
      isRequired: true,
    },
    {
      label: translateCustomerAccountFormInput('responsible'),
      value: (
        <div className="d-flex mb-1 gap-1">
          <div
            className="col-6"
            data-testid="customer-account-responsible-dropdown-div"
          >
            <Select
              value={objectNameAndIdToDropdownOption(responsible)}
              options={involvedResponsibleOptionsFiltered}
              onChange={(value: IDropdownOption<number>) =>
                handleResponsibleChange(value)
              }
            />
          </div>
          <div
            className="col-6"
            data-testid="customer-account-responsible-role-dropdown-div"
          >
            <Select
              value={objectNameAndIdToDropdownOption(responsibleRole)}
              options={responsibleRoleOptions}
              placeholder={translateAddOrUpdate('responsibleRole')}
              onChange={(value: IDropdownOption<number>) =>
                handleResponsibleRoleChange(value)
              }
            />
          </div>
        </div>
      ),
      isRequired: true,
    },
    {
      label: translateCustomerAccountFormInput('involved'),
      value: (
        <MultipleResponsibleDropdown
          responsibles={involvedDropdownItems}
          setResponsibles={handleInvolvedChange}
          responsibleOptions={involvedResponsibleOptionsFiltered}
          disabled={false}
        />
      ),
      isRequired: false,
    },
    {
      label: translateCustomerAccountFormInput('email'),
      value: (
        <div>
          {contactInformation.map((info, infoIndex) =>
            info?.type === ContactInfoType.EMAIL ? (
              <Row key={infoIndex}>
                <ContactInformationInputOld
                  textBoxSize="medium"
                  index={infoIndex}
                  contactInformation={info}
                  onDeleteContactInformation={handleDeleteContactInformation}
                  onHandleContactInformationChange={
                    handleContactInformationsChange
                  }
                />
              </Row>
            ) : null
          )}
          <Button
            data-testid="add-email-button"
            color="primary"
            size="sm"
            disabled={isButtonDisabled(ContactInfoType.EMAIL, maxEmailCount)}
            onClick={() => addContactInformation(ContactInfoType.EMAIL)}
          >
            {generateTitle(
              BUTTON_TITLE_ENUM.ADD.code,
              translateCustomerAccountFormInput('add')
            )}
          </Button>
        </div>
      ),
      isRequired: false,
    },
    {
      label: translateCustomerAccountFormInput('phone'),
      value: (
        <div>
          {contactInformation.map((info, infoIndex) =>
            info?.type === ContactInfoType.PHONE ? (
              <Row key={infoIndex}>
                <ContactInformationInputOld
                  textBoxSize="medium"
                  index={infoIndex}
                  contactInformation={info}
                  onDeleteContactInformation={handleDeleteContactInformation}
                  onHandleContactInformationChange={
                    handleContactInformationsChange
                  }
                />
              </Row>
            ) : null
          )}
          <Button
            data-testid="add-phone-button"
            color="primary"
            size="sm"
            disabled={isButtonDisabled(ContactInfoType.PHONE, maxPhoneCount)}
            onClick={() => addContactInformation(ContactInfoType.PHONE)}
          >
            {generateTitle(
              BUTTON_TITLE_ENUM.ADD.code,
              translateCustomerAccountFormInput('add')
            )}
          </Button>
        </div>
      ),
      isRequired: false,
    },
    {
      label: translateCustomerAccountFormInput('mobile'),
      value: (
        <div>
          {contactInformation.map((info, infoIndex) =>
            info?.type === ContactInfoType.MOBILE ? (
              <Row key={infoIndex}>
                <ContactInformationInputOld
                  textBoxSize="medium"
                  index={infoIndex}
                  contactInformation={info}
                  onDeleteContactInformation={handleDeleteContactInformation}
                  onHandleContactInformationChange={
                    handleContactInformationsChange
                  }
                />
              </Row>
            ) : null
          )}
          <Button
            data-testid="add-mobile-button"
            color="primary"
            size="sm"
            disabled={isButtonDisabled(ContactInfoType.MOBILE, maxMobileCount)}
            onClick={() => addContactInformation(ContactInfoType.MOBILE)}
          >
            {generateTitle(
              BUTTON_TITLE_ENUM.ADD.code,
              translateCustomerAccountFormInput('add')
            )}
          </Button>
        </div>
      ),
      isRequired: false,
    },
  ] as CardData[];

  return (
    <Form>
      <FormGroup>
        <DetailsCard fields={fields} title="" />
        <br />
        <Button
          color="primary"
          onClick={submitEvent}
          disabled={!isInputValid}
          data-testid="save-and-close-button"
        >
          {generateTitle(
            BUTTON_TITLE_ENUM.SAVE.code,
            translateAddOrUpdate('saveAndClose')
          )}
        </Button>{' '}
        <Button color="primary" onClick={onClose}>
          {translateAddOrUpdate('cancel')}
        </Button>
      </FormGroup>
    </Form>
  );
};

const mapDispatchToProps = {
  addCustomerAccount,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(AddCustomerAccountModal));
