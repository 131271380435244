import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Button, Col, Container, Label, Row } from 'reactstrap';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { dataGeneration } from '../../../utils/constants';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import { errorHandler } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';

class ContactPersonView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      employeeChoices: [],
      responsibles: [],
      contactPeople: [],
      from: null,
      to: null,
      showModal: false,

      showInputForm: false,
      modalForm: null,
      modalTitle: '',
    };
    this.modalEvent = null;
  }

  t(keyName) {
    return i18n.t('ContactPersonView.' + keyName);
  }

  componentDidMount() {
    axios.employee
      .get('employees')
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({
            employees: response.data,
            employeeChoices: response.data.map((employee) => ({
              label: employee.firstname + ' ' + employee.name,
              value: employee.id,
            })),
          });
        }
      })
      .catch((error) => {
        this.message = errorHandler(dataGeneration, error, this.t('employees'));
        this.toggleModalError();
      });

    axios.employee
      .get(
        'responsibles?objectType.equals=' + OBJECT_TYPE_ENUM.contactPerson.code
      )
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({
            responsibles: response.data,
          });
        }
      })
      .catch((error) => {
        this.message = errorHandler(
          dataGeneration,
          error,
          this.t('contactPeople')
        );
        this.toggleModalError();
      });

    axios.employee
      .get('contact-people')
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({
            contactPeople: response.data,
          });
        }
      })
      .catch((error) => {
        this.message = errorHandler(
          dataGeneration,
          error,
          this.t('contactPeople')
        );
        this.toggleModalError();
      });
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  preparedTableData = (employeeId) => {
    const newTableData = [];
    let responsibles = this.state.responsibles.filter(
      (responsible) => responsible.employeeId === employeeId
    );
    let contactPeople = this.state.contactPeople.filter((contact) =>
      responsibles.some((responsible) => responsible.objectId === contact.id)
    );
    if (Array.isArray(contactPeople) && contactPeople.length > 0) {
      contactPeople.forEach((contact) => {
        const entry = {
          id: contact?.id,
          name: contact.firstname + ' ' + contact.lastname,
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  changeFrom = (event) => {
    this.setState({
      from: event.value,
      to: null,
    });
  };

  changeTo = (event) => {
    this.setState({
      to: event.value,
    });
  };

  transfer = () => {
    axios.employee
      .get(
        'responsibles/' +
          this.state.from +
          '/transfer/' +
          this.state.to +
          '/' +
          OBJECT_TYPE_ENUM.contactPerson.code
      )
      .then((response) => {
        this.setState({
          responsibles: response.data,
        });
      });
  };

  render() {
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('name'),
        accessor: 'name',
        show: 'true',
      },
    ];
    return (
      <Container fluid>
        <br></br>
        <h4>{this.t('transferAllContactPerson')}</h4>
        <Row>
          <Col>
            <Label>{this.t('transferFrom')}</Label>
            <Select
              options={this.state.employeeChoices}
              onChange={this.changeFrom}
              value={
                this.state.from
                  ? this.state.employeeChoices.find(
                      (choice) => choice.value === this.state.from
                    )
                  : null
              }
            />
            <DynamicTable
              data={this.preparedTableData(this.state.from)}
              columns={preparedColumns}
            />
          </Col>
          <Col>
            <Label>{this.t('transferTo')}</Label>
            <Select
              options={this.state.employeeChoices.filter(
                (employee) => employee.value !== this.state.from
              )}
              onChange={this.changeTo}
              value={
                this.state.to
                  ? this.state.employeeChoices.find(
                      (choice) => choice.value === this.state.to
                    )
                  : null
              }
            />
            <DynamicTable
              data={this.preparedTableData(this.state.to)}
              columns={preparedColumns}
            />
          </Col>
          <div className="float-end">
            <Button
              size="sm"
              color="primary"
              disabled={this.state.to === null || this.state.from === null}
              onClick={() => this.transfer()}
            >
              {this.t('transfer')}
            </Button>
          </div>
        </Row>

        <Row>
          <Label></Label>
        </Row>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.message?.mainError}
          errorReason={this.message?.errorReason}
          errorResponse={this.message?.errorResponse}
          modalTitle="Error"
        ></ModalError>
      </Container>
    );
  }
}

export default withRouter(withTranslation()(ContactPersonView));
