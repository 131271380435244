import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import Select from 'react-select';
import { Button, Card, CardBody, Container } from 'reactstrap';
import axios from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import ModalError from '../../../components/modals/ModalError';
import { updateRecord } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { SERVICE_CONFIG } from '../../../utils/enums/service';
import { errorHandler } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';

/**
 * A Class for updating the Permission for Customer Target Priority
 * Function props:
 *  onSave(required) - update parent props
 * Variable props:
 *  serviceConfiguration(required) - the entity to be udpated
 */
class UpdateTargetPriorityPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceConfiguration: props?.serviceConfiguration ?? null,
      permissionOptions: [],
      selectedPermission: null,

      error: null,
      showModalError: false,
    };
    this.message = '';
  }

  componentDidMount() {
    let selectedPermission = this.state.serviceConfiguration.configValue;
    let permissionOptions = Object.values(
      SERVICE_CONFIG.customerPriority.value
    ).map((options) => ({
      label: this.t(options),
      value: options,
    }));
    this.setState({ permissionOptions, selectedPermission });
  }

  t(keyName) {
    return i18n.t('TargetPriorityPermissionSettings.' + keyName);
  }

  /**
   * Toggles an error modal
   */
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  /**
   * Handles changes made in permissions
   * @param {*} option
   */
  handlePermissionChange = (option) => {
    let serviceConfiguration = this.state.serviceConfiguration;
    serviceConfiguration.configValue = option.value;
    this.setState({
      selectedPermission: option.value,
      serviceConfiguration,
    });
  };

  /**
   * Saves the service configuration
   */
  saveServiceConfiguration = async () => {
    let serviceConfiguration = this.state.serviceConfiguration;
    let savedServiceConfiguration;
    if (serviceConfiguration.id) {
      savedServiceConfiguration = await axios.serviceConfiguration
        .put('', serviceConfiguration)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.message = errorHandler(
            updateRecord,
            error,
            this.t('failedToUpdateTargetPriorityPermission')
          );
          this.toggleModalError();
        });
    }
    if (savedServiceConfiguration && this.props.onSave) {
      this.props.onSave(savedServiceConfiguration);
    }
  };

  render() {
    const serviceConfiguration = this.state.serviceConfiguration;
    return (
      <Container fluid>
        <Card>
          <CardBody>
            <InputFormLabel
              isRequired={true}
              text={this.t('targetPriorityPermission')}
            />
            <Select
              options={this.state.permissionOptions}
              value={this.state.permissionOptions.find(
                (option) => option.value === this.state.selectedPermission
              )}
              onChange={this.handlePermissionChange}
            />
            <br />
            <div className="card-actions float-end">
              <Button
                color="primary"
                disabled={!serviceConfiguration?.configValue}
                onClick={() => this.saveServiceConfiguration()}
              >
                {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, this.t('submit'))}
              </Button>
            </div>
          </CardBody>
        </Card>

        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.message?.mainError ?? ''}
          errorReason={this.message?.errorReason ?? ''}
          errorResponse={this.message?.errorResponse ?? ''}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}

export default UpdateTargetPriorityPermission;
