import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import axios from '../../services/axios/axios';
import { DEPARTMENT_STATUS_ENUM } from '../../utils/enums/department';
import { isNullOrUndefinedOrEmpty } from '../../utils/helpers/GenericHelper';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import i18n from '../../i18n';
import { IDropdownOption, IObjectNameAndId } from '../../utils/types/commonTypes';
import { IDepartment } from '../../utils/types/modelTypes';

interface IProps extends IWithModalsProps {
  selectedDepartments: IDepartment[] | IObjectNameAndId[];
  onChange: (event: IDepartment[]) => void;
  readonly?: boolean;
  isMulti: boolean;
}

/**
 * A Component to display the multi-select dropdown of Departments
 */
const DepartmentDropdown = ({
  selectedDepartments,
  onChange,
  readonly,
  isMulti,
  modalErrorHandler,
}: IProps) => {
  const t = (keyName: string) => i18n.t(`DepartmentDropdown.${keyName}`);
  const [departments, setDepartments] = useState([] as IDepartment[]);
  const [dropdownOptions, setDropdownOptions] = useState(
    [] as IDropdownOption<number>[]
  );
  const [departmentIds, setDepartmentIds] = useState([] as number[]);

  const fetchData = () => {
    axios.project
      .get(
        `departments?status.in=${[
          DEPARTMENT_STATUS_ENUM.active.code,
          DEPARTMENT_STATUS_ENUM.planned.code,
        ]}`
      )
      .then((response: AxiosResponse<IDepartment[]>) => {
        const { data: fetchedDepartments } = response;

        setDepartments(fetchedDepartments);
        setDropdownOptions(
          fetchedDepartments.map((department: IDepartment) => ({
            label: department.title,
            value: department.id ?? 0,
          }))
        );
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToRetrieveDepartments'), error);
      });
  };

  /**
   * Fetch the departments on component mount
   */
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Update the department Ids to be used on filtering out chosen departments
   */
  useEffect(() => {
    setDepartmentIds(
      selectedDepartments.map((department) => department.id ?? 0)
    );
  }, [selectedDepartments]);

  /**
   * Handles the changes on the dropdown and converts the chosen dropdown option to department object
   * @param selectedDepartmentsParam
   */
  const handleDropdownChange = (
    selectedDepartmentsParam: IDropdownOption | IDropdownOption[]
  ) => {
    if (isNullOrUndefinedOrEmpty(selectedDepartmentsParam)) {
      onChange([]);
      return;
    }

    let chosenDepartments;
    if (Array.isArray(selectedDepartmentsParam)) {
      const selectedDepartmentIds = selectedDepartmentsParam.map((option) =>
        parseInt(option.value, 10)
      );

      chosenDepartments = departments.filter(
        (department) =>
          department.id && selectedDepartmentIds.includes(department.id)
      );
    } else {
      chosenDepartments = departments.filter(
        (department) =>
          department.id &&
          department.id.toString() === selectedDepartmentsParam.value
      );
    }

    onChange(chosenDepartments);
  };

  return (
    <Select
      aria-label="department-dropdown"
      isMulti={isMulti}
      options={dropdownOptions}
      value={dropdownOptions.filter((option) =>
        departmentIds.some((id) => id === option.value)
      )}
      onChange={handleDropdownChange}
      isClearable={isMulti}
      isDisabled={readonly ?? false}
      placeholder={t('selectDepartments')}
    />
  );
};
export default withModals(DepartmentDropdown);
