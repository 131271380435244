import React from 'react';

import { SortBy, SortType } from '../enums/sort';
import i18n from '../../i18n';
import {
  IObjectContactPerson,
  IObjectCustomerSite,
} from '../types/commonTypes';
import { isEmpty } from './array';

/**
 * Remove duplicate items in list
 * @param currentItems Current list of items
 * @param newItems List of items to add
 * @returns List of items without duplicates
 */
export const removeDuplicates = <T extends { id: number }>(
  currentItems: T[],
  newItems: T[]
) => {
  const currentItemSet = new Set(currentItems.map(({ id }: T) => id));

  return [
    ...currentItems,
    ...newItems.filter(({ id }: T) => !currentItemSet.has(id)),
  ];
};

/**
 * Generates the contents of the tooltip for customer site
 * @param customerSite
 * @returns Contents of customer site tooltip
 */
export const generateCustomerSiteTooltip = ({
  customerSiteState,
  customerSiteType,
  street,
  location,
  zipCode,
  countryState,
  country,
}: IObjectCustomerSite) => (
  <span>
    {`${i18n.t('GenericHelper.status')}: ${
      i18n.t(`CustomerStates.${customerSiteState}`) ?? 'N/A'
    }`}
    <br />
    {`${i18n.t('GenericHelper.type')}: ${customerSiteType ?? 'N/A'}`}
    <br />
    {`${i18n.t('GenericHelper.street')}: ${street ?? 'N/A'}`}
    <br />
    {`${i18n.t('GenericHelper.location')}: ${location ?? 'N/A'}`}
    <br />
    {`${i18n.t('GenericHelper.zipCode')}: ${zipCode ?? 'N/A'}`}
    <br />
    {`${i18n.t('GenericHelper.countryState')}: ${countryState ?? 'N/A'}`}
    <br />
    {`${i18n.t('GenericHelper.country')}: ${country ?? 'N/A'}`}
    <br />
  </span>
);

/**
 * Generates the contents of the tooltip for contact person
 * @param contactPerson
 */
export const generateContactPersonTooltip = ({
  role,
  phone,
  email,
  customerSite,
}: IObjectContactPerson) => (
  <>
    <span>{`${i18n.t('GenericHelper.role')}: ${role || 'N/A'}`}</span>
    <br />
    <span>
      {`${i18n.t('GenericHelper.phoneNumber')}: ${
        !isEmpty(phone) ? phone.join(', ') : 'N/A'
      }`}
    </span>
    <br />
    <span>
      {`${i18n.t('GenericHelper.email')}: ${
        !isEmpty(email) ? email.join(', ') : 'N/A'
      }`}
    </span>
    <br />
    {`${i18n.t('GenericHelper.customerSites')}: ${
      customerSite ? customerSite.name : 'N/A'
    }`}
  </>
);

/**
 * Returns the next sort type
 * @param currentSortType the current sortType
 * @param nextSortBy the next sortBy value
 * @param prevSortBy previous sortBy value
 * @returns
 */
export const getNextSortType = (
  currentSortType: SortType,
  nextSortBy: SortBy,
  prevSortBy: SortBy
) => {
  if (nextSortBy !== prevSortBy) {
    return SortType.ASCENDING;
  }
  switch (currentSortType) {
    case SortType.ASCENDING:
      return SortType.DESCENDING;
    case SortType.DESCENDING:
      return SortType.DEFAULT;
    default:
      return SortType.ASCENDING;
  }
};
