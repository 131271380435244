import React, { useEffect, useState } from 'react';
import { Button, Input, Label, Form, FormGroup } from 'reactstrap';

import withModals, { IWithModalsProps } from '../../utils/withModals';
import {
  IFileAttachment,
  IFileAttachmentListItem,
} from '../../utils/types/modelTypes';
import { generateTitle } from '../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';
import * as Constants from '../../utils/constants';
import i18n from '../../i18n';
import { saveAttachment, updateAttachment } from '../../services/api/fileAttachment';

interface IProps extends IWithModalsProps {
  file?: IFileAttachmentListItem | undefined;
  objectId: number;
  objectType: string;
  onCancel: () => void;
  onSave: (attachment: IFileAttachment) => void;
}

const t = (keyName: string) => i18n.t(`AddOrUpdateFileAttachment.${keyName}`);

const AddOrUpdateFileAttachmentForm = ({
  file,
  onCancel,
  onSave,
  objectId,
  objectType,
  modalErrorHandler,
}: IProps) => {
  const [fileAttachment, setFileAttachment] = useState({} as IFileAttachment);
  const {fileObject, id, fileObjectContentType, fileName, description } = fileAttachment;

  let fileInput: HTMLInputElement | null;

  // Handles adding new/updated attachment
  const saveFileAttachment = async () => {
    if (!fileObject) {
      modalErrorHandler(t('pleaseSelectAFile'));
      return;
    }

    try {
      if (id !== undefined) {
        await updateAttachment(fileAttachment);

        if (onSave) {
          onSave(fileAttachment);
        }
      } else {
        const { data } = await saveAttachment(fileAttachment);

        if (onSave) {
          onSave(data);
        }
      }
    } catch (error) {
      modalErrorHandler(t('serverFailed'), error);
    }
  };

  const removeLoadedFile = () => {
    setFileAttachment({} as IFileAttachment);
  };

  const fileToBase64 = (file: Blob | File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };

      reader.readAsDataURL(file);
      reader.onerror = reject;
    });

  const onFileLoad = async (e: React.DragEvent<HTMLInputElement>) => {
    const selectedFile = e.dataTransfer?.files[0];
    if (selectedFile?.size && selectedFile.size > Constants.fileSizeLimit) {
      modalErrorHandler(t('fileExceedLimitOf500Mb'));
      return;
    }
    if (selectedFile) {
      try {
        const {name, type} = selectedFile;
        const blob = await fileToBase64(selectedFile);
        setFileAttachment({
          ...fileAttachment,
          fileName: name,
          fileObject: blob.split(',')[1] ?? '',
          fileObjectContentType: type, 
          created_on: new Date().toISOString(),
        });
      } catch (error) {
        modalErrorHandler(t('error'), error);
      }
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.currentTarget?.files
      ? e.currentTarget.files[0]
      : null;
    if (selectedFile?.size && selectedFile.size > Constants.fileSizeLimit) {
      modalErrorHandler(t('fileExceedLimitOf500Mb'));
      return;
    }
    if (selectedFile) {
      try {
        const {name, type} = selectedFile;
        const blob = await fileToBase64(selectedFile);
        setFileAttachment({
          ...fileAttachment,
          fileName: name,
          fileObject: blob.split(',')[1] ?? '',
          fileObjectContentType: type, 
          created_on: new Date().toISOString(),
        });
      } catch (error) {
        modalErrorHandler(t('error'), error);
      }
    }
  };

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileAttachment({...fileAttachment, fileName: event.target.value});
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFileAttachment({...fileAttachment, description: event.target.value});
  };

  useEffect(() => {
    setFileAttachment({
      fileName:'',
      fileObject:'',
      objectId: 0,
      objectType: '',
      ...(objectType && objectId && {
        objectId,
        objectType,
      }),
      ...(file && file),
    });
  }, [file, objectId, objectType]);

  return (
    <Form>
      <FormGroup>
        <div
          className="inner-container"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className="draggable-container">
            <input
              type="file"
              id="file-browser-input"
              name="file-browser-input"
              ref={(input) => (fileInput = input)}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDrop={(e) => void onFileLoad(e)}
              onChange={(e) => void onFileChange(e)}
            />
            {fileObject && (
              <div className="files-preview-container">
                <div
                  className="file"
                  style={{
                    height: fileObjectContentType?.includes(
                      'image'
                    )
                      ? '200px'
                      : undefined,
                  }}
                >
                  {fileObjectContentType?.includes('image') ? (
                    <img
                      alt=""
                      src={`data:${fileObjectContentType};base64,${fileObject}`}
                    />
                  ) : (
                    <Label>{fileName}</Label>
                  )}

                  <div className="container">
                    <span
                      className="remove-btn"
                      onClick={() => removeLoadedFile()}
                    >
                      {generateTitle(BUTTON_TITLE_ENUM.EXIT.code)}
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div className="helper-text">{t('dragAndDropFileHere')}</div>
            <div className="file-browser-container">
              <Button color="primary" onClick={() => fileInput?.click()}>
                {t('browse')}
              </Button>
            </div>
          </div>
        </div>
        <Label className="input-label" style={{ textAlign: 'right' }}>
          {t('fileName')}
        </Label>
        <Input
          onChange={handleFileNameChange}
          value={fileName || ''}
        />
        <br />
        <Label className="input-label">{t('description')}</Label>
        <Input
          onChange={handleDescriptionChange}
          value={description || ''}
        />
        <br />
        <Button
          color="primary"
          onClick={saveFileAttachment}
          disabled={!fileObject}
        >
          {t('confirm')}
        </Button>{' '}
        <Button color="primary" onClick={onCancel}>
          {t('cancel')}
        </Button>
      </FormGroup>
    </Form>
  );
};

export default withModals(AddOrUpdateFileAttachmentForm);
