import { AxiosError } from 'axios';
import React from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';

import InputFormLabel from '../../../components/form/InputFormLabel';
import { getEmployeesWithPermissions } from '../../../utils/helpers/url';
import { READWRITE } from '../../../utils/constants';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import { PROJECT_STATE_ENUM } from '../../../utils/enums/project';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IErrorMessage } from '../../../utils/types/commonTypes';
import { IEmployee, IProject, IResponsible } from '../../../utils/types/modelTypes';
import MultipleResponsibles from '../../../components/form/MultipleResponsibles';
import ResponsibleDropdown from '../../../components/dropdowns/ResponsibleDropdown';

/**
 * Shows Responsibles of Project Options \
 * Variable Props:\
 *  - project\
 *  - responsible\
 *  - involvedResponsibles\
 * Function Props:\
 *  - onResponsiblesChange
 */

interface IProps extends IWithModalsProps {
  // Variables
  project: IProject;
  responsible: IResponsible;
  involvedResponsibles: IResponsible[];
  lockInputs?: boolean;
  isProjectOption: boolean;
  // Function
  onResponsiblesChange: (responsible: IResponsible) => void;
  onInvolvedResponsiblesChange: (responsible: IResponsible[]) => void;
  onDelete: (responsible: IResponsible) => void;
}

interface IState {
  employees: IEmployee[];
  update: boolean;
}

class ProjectOptionResponsibleCard extends React.Component<IProps, IState> {
  error: IErrorMessage = {} as IErrorMessage;

  modalTitle: string = {} as string;

  constructor(props: IProps) {
    super(props);
    this.state = {
      employees: [],
      update: false,
    };
  }

  t(keyName: string) {
    return i18n.t('ProjectOptionOverview.' + keyName);
  }

  async componentDidMount() {
    const employees = await getEmployeesWithPermissions(
      PERMISSION_URI.projectOptions.readWrite.uri,
      READWRITE
    ).catch((error: AxiosError) => {
      this.props.modalErrorHandler(this.t('failedToGetEmployees'), error);
      return [] as IEmployee[];
    });
    this.setState({ employees });
  }

  handleResponsibleChange = (responsible: IResponsible) => {
    if (this.props.onResponsiblesChange) {
      this.props.onResponsiblesChange(responsible);
    }
  };

  handleInvolvedResponsiblesChange = (involvedResponsibles: IResponsible[]) => {
    if (this.props.onInvolvedResponsiblesChange) {
      this.props.onInvolvedResponsiblesChange(involvedResponsibles);
    }
  };

  deleteInvolvedResponsible = (deletedInvolvedResponsible: IResponsible) => {
    if (this.props.onDelete) {
      this.props.onDelete(deletedInvolvedResponsible);
    }
  };

  render() {
    const responsible = this.props.responsible;
    const involvedResponsibles = this.props.involvedResponsibles;
    const project = this.props.project;
    const lockInputs = this.props.lockInputs;
    const employees = this.state.employees;
    return (
      <Card className="small-card">
        <div style={{ textAlign: 'center' }}>
          <CardHeader>
            <h3>{this.t('responsibles')}</h3>
          </CardHeader>
        </div>
        <CardBody>
          <Row>
            <Col>
              <Table borderless size="sm">
                <tbody>
                  <tr>
                    <th style={{ width: '25%' }}>
                      {
                        <InputFormLabel
                          isRequired={
                            !project?.id &&
                            project?.state === PROJECT_STATE_ENUM.vague.code
                          }
                          text={this.t('responsible')}
                        />
                      }
                    </th>

                    <td>
                      <ResponsibleDropdown
                        responsible={responsible}
                        selectedResponsibles={[
                          ...involvedResponsibles,
                          responsible,
                        ]}
                        employees={employees}
                        objectId={project.id ?? 0}
                        objectType={OBJECT_TYPE_ENUM.project.code}
                        onChange={this.handleResponsibleChange}
                        lockInputs={lockInputs ?? false}
                        hideOwnershipField
                        disableDelete
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: '25%' }}>{this.t('involved')}</th>
                    <td>
                      <MultipleResponsibles
                        responsibleOwnership={responsible}
                        responsibles={involvedResponsibles}
                        employeesResponsible={employees}
                        objectId={project?.id}
                        objectType={OBJECT_TYPE_ENUM.project.code}
                        onChange={this.handleInvolvedResponsiblesChange}
                        onDelete={this.deleteInvolvedResponsible}
                        lockInputs={lockInputs as boolean}
                        hideOwnershipField
                        involvedOnly
                        projectOption={this.props.isProjectOption}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default withModals(ProjectOptionResponsibleCard);
