import moment, { isMoment } from 'moment';
import { Link } from 'react-router-dom';
import { Input, Label } from 'reactstrap';
import Auth from '../../services/axios/Auth';
import axios from '../../services/axios/axios';
import i18n from '../../i18n';
import { Ownership } from '../enums/ownership';
import TableDataComponent from '../../components/tables/TableDataComponent';
import { setEmployeeDetails } from '../../redux/accountSlice';
import store from '../../redux/store';
import user from '../../user';
import { monthsOfYear } from '../enums/calendar';
import {
  addRecord,
  Ascending,
  dataGeneration,
  dateFormat,
  DEFAULT_RETRIEVE_COUNT_PER_PAGE,
  deletion,
  Descending,
  maxInFilterSize,
  notApplicable,
  NOTIFICATION_STATUS_NEW,
  READWRITE,
  REGEX_VALID_CONTACT_NUMBER_FORMAT,
  updateRecord,
  workloadColorDanger,
  workloadColorHigh,
  workloadColorLow,
  workloadColorMedium,
  workloadDanger,
  workloadHigh,
  workloadLow,
  workloadMedium,
} from '../constants';
import { ENTITIES_LINK } from '../enums/pageComponents';
import {
  CONTACT_INFO_ENUM,
  GDPR_STATUS_ENUM,
  CONTACT_STATUS,
} from '../enums/contact';
import {
  ABSENCE_STATE_ENUM,
  PROJECT_STATE_ENUM,
  ProjectState,
} from '../enums/project';
import { CALL_STATUS_TYPE_ENUM } from '../enums/callStatus';
import { EMPLOYEMENT_TYPE_ENUM } from '../enums/employee';
import { PERMISSION_URI } from '../enums/permission';

/**
 * @deprecated Use isEmpty in arrayHelper
 *
 * Function checks if the object is undefined or empty
 */
export function isEmpty(obj) {
  if (obj === undefined || obj === null) {
    return true;
  }
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      if (obj.length === 0) {
        return true;
      } else {
        return false;
      }
    } else if (Object.keys(obj).length === 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * This function formats date from a date seperated by a slash to dash
 * and use this date format as a field on the calendar day and post it to the database.
 * @param inputDate
 * This parameter would only show the date in  MM/DD/YYYY
 * @returns date that is parsed into a moment in a format YYYY-MM-DD
 */
export function convertDateFromSlashToDash(inputDate) {
  var newDate = new Date(inputDate);
  var stringDate = moment(newDate).format(dateFormat);
  return stringDate;
}

/**
 * This function formats date based on the user's browser settings
 * and would also allow to add time on the format
 * @param date
 * This parameter would only show the date in  format type 4 (Month D, YYYY with month spelled out)
 * @param showTime
 * This parameter is a combination of date and time
 * @returns date that is parsed into a moment
 */
export function formatDate(date, showTime = false) {
  // Set prefferedLanguage "en" as default
  let prefferedLanguage = '';

  // Get all languages from the browser setting
  const browserLanguages = navigator.languages;

  if (browserLanguages !== undefined) {
    prefferedLanguage = navigator.languages[0];
  } else {
    prefferedLanguage = navigator.language;
  }

  const options = showTime
    ? {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
    : {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
  return moment(date).toDate().toLocaleString(prefferedLanguage, options);
}

/**
 * Remove an object or array of object to a larger array of the same object based on their field "id".
 * @param arrayOfObjects
 * the array of object where one or more of its element needs to be removed.
 * @param objectToDelete
 * an object or an array of object that will be removed from arrayOfObjects
 * @returns arrayOfObjects without objects from objectToDelete.
 */
export function removeObjectFromArrayBaseOnId(arrayOfObjects, objectToDelete) {
  if (Array.isArray(objectToDelete)) {
    if (objectToDelete.length === 0) {
      return arrayOfObjects;
    }
    // get all the ids of objectToDelete
    var listToDeleteId = [];
    objectToDelete.forEach((element) => {
      if (element.id) {
        listToDeleteId.push(element.id);
      }
    });
    var end = 0;
    for (var index = 0; index < arrayOfObjects.length; index++) {
      var obj = arrayOfObjects[index];
      if (obj.id) {
        if (listToDeleteId.indexOf(obj.id) === -1) {
          arrayOfObjects[end++] = obj;
        }
      }
    }
    arrayOfObjects.length = end;
    return arrayOfObjects;
  } else {
    if (objectToDelete.id) {
      return arrayOfObjects.filter((e) => e.id !== objectToDelete.id);
    }
    return null;
  }
}

/**
 * Checks an if an object array, and if it has data properties, it returns the latest one available
 * @param object An array that contains date values
 * @return the item within the array that contains the latest date value
 */
export function getLatestItem(object, propertyName = 'date') {
  var latest = undefined;
  if (object === undefined || object === null) {
    return;
  }
  if (!Array.isArray(object) || object.length === 0) {
    return object;
  }

  if (!object[0].hasOwnProperty(propertyName)) {
    return;
  }

  object.forEach((item) => {
    if (latest === undefined) {
      latest = item;
    }

    if (
      new Date(item[propertyName]).getTime() >
      new Date(latest[propertyName]).getTime()
    ) {
      latest = item;
    }
  });
  return latest;
}

/**
 * Handles the retrieval of dates between startDate and endDate
 * @param {*} startDate
 * @param {*} endDate
 * @returns dates
 */
export function GetDatesInBetween(startDate, endDate) {
  var currDate = moment(startDate).startOf('day');
  var lastDate = moment(endDate).startOf('day');
  var dates = [currDate.toString()];
  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }
  if (
    moment(startDate).startOf('day').toString() !==
    moment(endDate).startOf('day').toString()
  ) {
    dates.push(lastDate);
  }
  return dates;
}

// Function that filter dates in between excluding weekends
export function getDatesInBetweenExcludingWeekends(startDate, endDate) {
  let datesInBetween = GetDatesInBetween(startDate, endDate);

  return datesInBetween.filter((date) => {
    return moment(date).day() !== 6 && moment(date).day() !== 0;
  });
}

/**
 * @deprecated Use the method located in DateHelper.ts
 * This function gets the date format based on the user's browser language setting and the formatOption being set.
 * @param formatOption - Optional parameter. Import the momentLongDateFormat from the src/helpers/enum. To select further dateFormat.
 *  * Here are the list of key(string): value(string) pair to give an idea on the expected return based on the key used : 
        {"LT": "h:mm A",
        "LTS": "h:mm:ss A",
        "L": "MM/DD/YYYY",
        "LL": "MMMM Do YYYY",
        "LLL": "MMMM Do YYYY LT",
        "LLLL": "dddd, MMMM Do YYYY LT"}
 * @param showTime - Optional boolean value. If true, allow the user to select time. Otherwise if false.
 * @returns string dateFormat to be used to format dates.
 */
export function getDateFormat(formatOption = 'L', showTime = false) {
  // Set prefferedLanguage "en" as default
  const languageSelected = i18n.language;

  if (showTime) {
    // Get time based on the input date.
    const retrievedTime = moment
      .localeData(languageSelected)
      .longDateFormat('LT');
    const retrievedDate = moment
      .localeData(languageSelected)
      .longDateFormat(formatOption);
    return `${retrievedDate} ${retrievedTime}`;
  } else {
    return moment.localeData(languageSelected).longDateFormat(formatOption);
  }
}

/**
 * Handles the creation of error mesages for the modalError
 * @param {*} currentOperation
 * @param {*} error
 * @param {*} message
 * @returns errorMessage
 */
export function errorHandler(currentOperation, error, message) {
  var errorMessage = {
    mainError: '',
    errorReason: '',
    errorResponse: error?.message,
  };
  if (
    [dataGeneration, updateRecord, addRecord, deletion].includes(
      currentOperation
    )
  ) {
    errorMessage.mainError = message;
  }
  errorMessage.errorReason =
    error?.response?.data?.detail ??
    error?.response?.data?.title ??
    i18n.t('GenericHelper.serverFailed');
  errorMessage.errorResponse =
    errorMessage?.errorResponse ?? i18n.t('GenericHelper.unexpectedError');
  return errorMessage;
}

/**
 * Get project employees by employee id
 * @param {*} employeeId
 * @returns
 */
export async function getProjectEmployeesByEmployeeId(employeeId) {
  return axios.project
    .get(`project-employees?employeeId.equals=${employeeId}`)
    .then((response) => {
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        return [];
      }
    });
}

/**
 * Get timesheet by employee id and current day
 * @param {*} projectEmployeeId
 * @param {*} day
 * @returns timesheet
 */
export async function getTimesheet(projectEmployeeId, day) {
  return axios.timetracking
    .get(
      `timetrackings?projectEmployeeId.equals=${projectEmployeeId}
    &month.equals=${moment(day).month() + 1}
    &year.equals= ${moment(day).year()}`
    )
    .then((response) => {
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        return null;
      }
    });
}

/**
 * Get projectEmployee by id
 * @param {*} id
 * @returns project employee
 */
export async function getProjectEmployee(id) {
  return axios.project.get(`project-employees/${id}`).then((response) => {
    if (response.data instanceof Object) {
      return response.data;
    } else {
      return null;
    }
  });
}

/**
 * Get timetracking record by id
 * @param {*} id
 * @returns timetracking record
 */
export async function getTimetrackingRecord(id) {
  return axios.timetracking
    .get('timetracking-records/' + id)
    .then((response) => {
      if (response.data instanceof Object) {
        return response.data;
      } else {
        return null;
      }
    });
}

/**
 * Create time tracking
 * @param {*} timetracking
 * @returns time tracking
 */
export async function createTimetracking(timetracking) {
  return axios.timetracking
    .post('timetrackings', timetracking)
    .then((response) => {
      return response.data;
    });
}

/**
 * Update timetracking
 * @param {*} timetracking
 * @returns
 */
export async function updateTimetracking(timetracking) {
  return axios.timetracking
    .put('timetrackings', timetracking)
    .then((response) => {
      return response.data;
    });
}

/**
 * Update timetracking record
 * @param {*} record
 * @returns time tracing record
 */
export async function updateTimetrackingRecord(record) {
  return axios.timetracking
    .put('timetracking-records', record)
    .then((response) => {
      return response.data;
    });
}

/**
 * Create timetracking record
 * @param {*} record
 * @returns timetracking record
 */
export async function createTimetrackingRecord(record) {
  return axios.timetracking
    .post('timetracking-records', record)
    .then((response) => {
      return response.data;
    });
}

/**
 * @deprecated
 * Get employees
 * @returns employee
 */
export async function getEmployees() {
  return axios.employee.get('employees').then((response) => {
    if (Array.isArray(response.data)) {
      return response.data;
    } else {
      return [];
    }
  });
}

/**
 * Get Roles
 * @returns roles
 */
export async function getRoles() {
  return axios.employee.get('roles').then((response) => {
    if (Array.isArray(response.data)) {
      return response.data;
    } else {
      return [];
    }
  });
}

/**
 * Given date, returns the day of the week
 * @param {*} date
 * @returns day of the week
 */
export function getDayOfWeek(date) {
  var day = moment(date).day();
  var dayOfWeek;
  switch (day) {
    case 0:
      dayOfWeek = i18n.t('GenericHelper.sunday');
      break;
    case 1:
      dayOfWeek = i18n.t('GenericHelper.monday');
      break;
    case 2:
      dayOfWeek = i18n.t('GenericHelper.tuesday');
      break;
    case 3:
      dayOfWeek = i18n.t('GenericHelper.wednesday');
      break;
    case 4:
      dayOfWeek = i18n.t('GenericHelper.thursday');
      break;
    case 5:
      dayOfWeek = i18n.t('GenericHelper.friday');
      break;
    case 6:
      dayOfWeek = i18n.t('GenericHelper.saturday');
      break;
    default:
      break;
  }
  return dayOfWeek;
}

/**
 * Check what absence state is used, return the badge(code used for rendering absence status) that
 * is already defined on the properties of the specific absenceState under absenceStateEnum
 * @param absenceState absence state in a form of string that is written in "all caps"
 * @return the property badge that is defined along with the absenceState in a form of string
 */
export function colorBadge(absenceState) {
  switch (absenceState) {
    case ABSENCE_STATE_ENUM.Accepted.code:
      return ABSENCE_STATE_ENUM.Accepted.badge;

    case ABSENCE_STATE_ENUM.Canceled.code:
      return ABSENCE_STATE_ENUM.Canceled.badge;

    case ABSENCE_STATE_ENUM.Canceled_Pending.code:
      return ABSENCE_STATE_ENUM.Canceled_Pending.badge;

    case ABSENCE_STATE_ENUM.Declined.code:
      return ABSENCE_STATE_ENUM.Declined.badge;

    case ABSENCE_STATE_ENUM.Planned.code:
      return ABSENCE_STATE_ENUM.Planned.badge;

    case ABSENCE_STATE_ENUM.Retreated.code:
      return ABSENCE_STATE_ENUM.Retreated.badge;

    case ABSENCE_STATE_ENUM.Submitted.code:
      return ABSENCE_STATE_ENUM.Submitted.badge;

    default:
  }
}

/**
 * Get customer count
 * @returns customer count
 */
export async function getCustomerCount() {
  var size = await axios.sales.get('customers/count').then((response) => {
    return response.data;
  });
  return size;
}

/**
 * Compares two string to get it's edit distance(measure of how dissmilar two strings are)
 * using  Levenshtein distance algorithm
 * @param {*} stringToCompareTo string to compare to
 * @param {*} givenString string that is given
 * @returns edit distance
 */
export const getStringDistance = (stringToCompareTo = '', givenString = '') => {
  const track = Array(givenString.length + 1)
    .fill(null)
    .map(() => Array(stringToCompareTo.length + 1).fill(null));
  for (let i = 0; i <= stringToCompareTo.length; i += 1) {
    track[0][i] = i;
  }
  for (let j = 0; j <= givenString.length; j += 1) {
    track[j][0] = j;
  }
  for (let j = 1; j <= givenString.length; j += 1) {
    for (let i = 1; i <= stringToCompareTo.length; i += 1) {
      const indicator = stringToCompareTo[i - 1] === givenString[j - 1] ? 0 : 1;
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // Deletion
        track[j - 1][i] + 1, // Insertion
        track[j - 1][i - 1] + indicator // Substitution
      );
    }
  }
  return track[givenString.length][stringToCompareTo.length];
};

/**
 * Get project count
 * @returns project count
 */
export async function getProjectCount() {
  var size = await axios.project.get('projects/count').then((response) => {
    return response.data;
  });
  return size;
}

/**
 * Delete Activities of Orders
 * @param {*} orderId
 */
export async function deleteOrderActivities(orderId) {
  var orderActivities = await axios.sales
    .get(`activities?orderId.equals=${orderId}`)
    .then((res) => {
      return res.data;
    });
  let activitiesToBeDeleted = [];
  for (let index = 0; index < orderActivities.length; index++) {
    activitiesToBeDeleted.push(
      axios.sales.delete(`activities/${orderActivities[index]?.id}`)
    );
  }
  if (!isEmpty(activitiesToBeDeleted)) {
    Promise.all(activitiesToBeDeleted).then(() => {
      return;
    });
  }
  return;
}

/**
 * Save all the attachments with the given object
 * object: Request, Offer or Order
 * @param {*} object
 * @param {*} attachments
 */
export async function saveAttachmentsFromNewObject(object, attachments) {
  let axiosArray = [];
  if (Array.isArray(attachments)) {
    attachments.forEach((attachment) => {
      // assign the object's id to attachment's objectId
      // then post.
      attachment.objectId = object.id;
      axiosArray.push(axios.sales.post('file-attachments', attachment));
    });

    if (!isEmpty(axiosArray)) {
      Promise.all(axiosArray).then(() => {
        return;
      });
    }
  }
  return;
}

/**
 * Save Responsible contact person given the objectId
 * @param {*} responsibleContactPersons an array of responsible contact person
 * @param {*} objectId
 */
export async function saveResponsibleContactPersons(
  responsibleContactPersons,
  objectId
) {
  let axiosArray = [];
  if (Array.isArray(responsibleContactPersons)) {
    for (let responsibleContact of responsibleContactPersons) {
      // Assign the objectId then save

      const responsibleContactPerson = {
        ...responsibleContact,
        objectId,
      };

      axiosArray.push(
        axios.sales.save('responsible-contact-people', responsibleContactPerson)
      );
    }
    let savedResponsibleContactPersons = [];
    if (!isEmpty(axiosArray)) {
      let responses = await Promise.all(axiosArray);
      for (let response of responses) {
        savedResponsibleContactPersons.push(response.data);
      }
    }
    return savedResponsibleContactPersons;
  }
  return [];
}

/**
 * Save contact person to site
 * @param {*} responsibleContactPersons an array of responsible contact person
 * @param {*} objectId
 */
export async function saveContactPersonToSite(
  responsibleContactPersons,
  objectId
) {
  let axiosArray = [];
  if (Array.isArray(responsibleContactPersons)) {
    for (let responsibleContact of responsibleContactPersons) {
      // Assign the objectId then save
      responsibleContact.customerSite = { id: objectId };
      if (responsibleContact.id) {
        axiosArray.push(axios.sales.put('contact-people', responsibleContact));
      }
    }
    let savedResponsibleContactPersons = [];
    if (!isEmpty(axiosArray)) {
      let responses = await Promise.all(axiosArray);
      for (let response of responses) {
        savedResponsibleContactPersons.push(response.data);
      }
    }
    return savedResponsibleContactPersons;
  }
  return [];
}

/**
 * @deprecated
 * Save Responsibles given objectId
 * @param {*} responsibles
 * @param {*} objectId
 * @param (optional) serviceToSave
 */
export async function saveResponsibles(responsibles, objectId) {
  let axiosArray = [];
  if (Array.isArray(responsibles) && responsibles.length > 0) {
    for (let responsible of responsibles) {
      if (responsible) {
        if (objectId) {
          responsible.objectId = objectId;
        }
        if (responsible?.employeeId) {
          // if responsibleRole is not null and responsibleRole id is null, create
          // new responsibleRole
          if (
            responsible.responsibleRole &&
            responsible.responsibleRole.id === null
          ) {
            responsible.responsibleRole = await axios.sales
              .post('responsible-roles', responsible.responsibleRole)
              .then((response) => {
                return response.data;
              });
          }

          axiosArray.push(axios.sales.save('responsibles', responsible));
        }
      }
    }
    let savedResponsibles = [];
    if (!isEmpty(axiosArray)) {
      let responses = await Promise.all(axiosArray);
      for (let response of responses) {
        savedResponsibles.push(response.data);
      }
    }
    return savedResponsibles;
  }
  return [];
}

/**
 * Changing the input field of DateTime to <Input/>
 * @param {*} props
 * @returns
 */
export function changeDateTimeInputField(props) {
  return <Input bsSize="lg" {...props} />;
}

/**
 * Create notification status
 * @param {*} status
 * @param {*} date
 * @returns notification status
 */
export async function createNotificationStatus(status, date) {
  let notificationStatus = {
    status: status ? status : NOTIFICATION_STATUS_NEW,
    date: date ? date : new Date(),
  };

  let savedNotificationStatus = await axios.notification
    .post('notification-statuses', notificationStatus)
    .then((res) => res.data);
  return savedNotificationStatus;
}

/**
 * Creates notification
 * @param {*} message - message of the notification
 * @param {*} date - date of the notification
 * @param {*} employeeId - id of employee to be notified
 * @param {*} fromService - what service the notification comes from
 * @param {*} savedObject - savedObject used to get object details
 * @param {*} objectType - objectType to know where service to find the object using the objectId
 */
export async function createNotificationReminder(
  message,
  date,
  employeeId,
  fromService,
  savedObject,
  objectType
) {
  let notificationStatus = await createNotificationStatus(
    NOTIFICATION_STATUS_NEW,
    date
  );
  await createNotification(
    employeeId,
    fromService,
    message,
    [notificationStatus],
    savedObject,
    objectType
  );
  return true;
}

/**
 * Create Notification
 * @param {*} employeeId
 * @param {*} fromService
 * @param {*} message
 * @param {*} notificationStatuses
 * @param {*} savedObject
 * @param {*} objectType
 * @returns notification
 */
export async function createNotification(
  employeeId,
  fromService,
  message,
  notificationStatuses,
  savedObject,
  objectType
) {
  let notification = {
    employeeId: employeeId,
    fromService: fromService,
    msg: message,
    notificationStatuses: notificationStatuses,
    objectId: savedObject?.id ? savedObject.id : null,
    objectType: objectType ? objectType : null,
    dueDate: savedObject?.offerUntil ? savedObject?.offerUntil : null,
  };

  await axios.notification.post('notifications', notification);

  return;
}

/**
 * Saving of request
 * @param {*} request
 * @returns saved request
 */
export async function saveRequest(request) {
  let savedRequest;
  savedRequest = await axios.sales
    .save('requests', request)
    .then((res) => res.data);

  return savedRequest;
}

/**
 * Handles creation of contact information component
 * @param {*} responsibleContactPerson
 * @returns component showing contact informations
 */
export function showContactInformation(responsibleContactPerson) {
  let contactInformations =
    responsibleContactPerson?.contactPerson?.contactInformations;
  if (Array.isArray(contactInformations)) {
    let phones = contactInformations.filter(
      (info) => info?.contactInformationType === CONTACT_INFO_ENUM.phone.code
    );
    let emails = contactInformations.filter(
      (info) => info?.contactInformationType === CONTACT_INFO_ENUM.email.code
    );
    return (
      <Label>
        {'Role'}:{' '}
        {responsibleContactPerson?.contactPerson?.contactPersonRole?.role
          ? responsibleContactPerson.contactPerson.contactPersonRole.role
          : 'N/A'}
        <br />
        {'Phones'}:{' '}
        {Array.isArray(phones) && phones.length > 0
          ? phones.map((phone, phoneIndex) =>
              phoneIndex === 0 ? phone?.info : `, ${phone?.info}`
            )
          : 'N/A'}
        <br />
        {'Emails'}:{' '}
        {Array.isArray(emails) && emails.length > 0
          ? emails.map((email, emailIndex) =>
              emailIndex === 0 ? email?.info : `, ${email?.info}`
            )
          : 'N/A'}
      </Label>
    );
  }
  return '';
}

export function sortByLastName(a, b) {
  if (a.lastname < b.lastname) {
    return -1;
  }
  if (a.lastname > b.lastname) {
    return 1;
  }
  return 0;
}

/**
 * Method to update the UserRights. Currently used when adding new
 * project for the user to be able to go to project details right away.
 * @param {*} props - props of the calling class(should have an redux for account)
 */
export async function updateUserRights(props) {
  let employee = await axios.employee.get(
    `employees/${props.account.employeeDetails.id}`
  );
  let employeeRoles = [];
  if (Array.isArray(employee?.data?.employeeRoles)) {
    employeeRoles = employee.data.employeeRoles;
  }
  let rights = [];
  if (Array.isArray(employeeRoles)) {
    employeeRoles.forEach((userRole) => {
      if (Array.isArray(userRole?.role?.roleActions)) {
        userRole.role.roleActions.forEach((roleAction) => {
          rights.push(roleAction.action);
        });
      }
    });
  }
  props.dispatch(setEmployeeDetails(employee.data));
  user.rights = rights;
  return true;
}

/**
 * Handles getting of customer site name given the customerSite
 * @param {*} customerSite
 * @returns site name
 */
export function getSiteName(customerSite) {
  return customerSite?.name && customerSite?.location
    ? `${customerSite?.name} (${customerSite.location})`
    : '';
}

/**
 * @deprecated Use function in arrayHelper
 * Sorts array by object dates from newest to oldest
 * @param {*} objectArray
 * @param {*} dateKey
 * @param {*} isDescending
 * @returns sorted date
 */
export function sortByDate(objectArray, dateKey = 'date', isDescending = true) {
  if (Array.isArray(objectArray) && objectArray.length > 0) {
    var multiplier = isDescending ? 1 : -1;
    objectArray.sort(function (object1, object2) {
      return (
        multiplier *
        (new Date(object2[dateKey]).getTime() -
          new Date(object1[dateKey]).getTime())
      );
    });
    return objectArray;
  } else {
    return [];
  }
}

/**
 * @deprecated use sortOptionsByValue from dropdownHelper.ts
 * Sorts array alphabetically (A-Z) by property value
 * @param {*} objectArrayToSort
 * @param {*} property
 * @param {*} subProperty
 * @returns sorted array
 */
export function sortByPropValue(objectArray, property, subProperty = null) {
  const objectArrayToSort = [...objectArray];
  if (Array.isArray(objectArrayToSort) && objectArrayToSort.length > 0) {
    if (subProperty === null) {
      var result = objectArrayToSort.sort((object1, object2) =>
        object1[property] !== undefined
          ? object1[property]?.toString()?.toUpperCase() >
            object2[property]?.toString()?.toUpperCase()
            ? 1
            : object2[property]?.toString()?.toUpperCase() >
              object1[property]?.toString()?.toUpperCase()
            ? -1
            : 0
          : 0
      );
      return result;
    } else {
      var results = objectArrayToSort.sort((object1, object2) =>
        object1[property] !== undefined
          ? object1[property][subProperty]?.toString()?.toUpperCase() >
            object2[property][subProperty]?.toString()?.toUpperCase()
            ? 1
            : object2[property][subProperty]?.toString()?.toUpperCase() >
              object1[property][subProperty]?.toString()?.toUpperCase()
            ? -1
            : 0
          : 0
      );
      return results;
    }
  } else {
    return [];
  }
}

/**
 * Returns and error object with fields mainError, errorReason and errorResponse
 * @param {*} mainError
 * @param {*} error
 */
export function handleError(mainError, errorObject) {
  let error = {
    mainError: mainError,
    errorReason:
      errorObject?.response?.data?.title ??
      i18n.t('GenericHelper.serverFailed'),
    errorResponse: errorObject?.message,
  };
  return error;
}

/**
 * Gets the GDPR status name
 * @param {*} statusGdpr
 * @returns GDPR status name
 */
export function getGdprStatusName(statusGdpr) {
  switch (statusGdpr) {
    case GDPR_STATUS_ENUM.none.code:
      return GDPR_STATUS_ENUM.none.name;
    case GDPR_STATUS_ENUM.requested.code:
      return GDPR_STATUS_ENUM.requested.name;
    case GDPR_STATUS_ENUM.accepted.code:
      return GDPR_STATUS_ENUM.accepted.name;
    case GDPR_STATUS_ENUM.declined.code:
      return GDPR_STATUS_ENUM.declined.name;
    case GDPR_STATUS_ENUM.legacy.code:
      return GDPR_STATUS_ENUM.legacy.name;
    case GDPR_STATUS_ENUM.bounced.code:
      return GDPR_STATUS_ENUM.bounced.name;
    default:
      return null;
  }
}

/**
 * Return an array of component for each employees
 * @param {*} employees
 */
export function getResponsibleEmployeeComponent(employee) {
  if (employee) {
    return (
      <Link to={`/employees/employee-list/employee-detail/${employee?.id}`}>
        {`${employee?.name ?? ''}`}
      </Link>
    );
  }
  return null;
}

/**
 * Function that will divide arrayParam into smaller array before
 * calling the method. This will send arrayParam, param2 and param3
 * as parameter in function "method" in order
 * @param {*} method Required and must be a function. The function to be called.
 * @param {*} arrayParam Required and must be an array. First parameter of "method"
 * @param {*} param2 Optional. Second parameter of "method"
 * @param {*} param3 Optional. Third parameter of "method"
 * @returns an array of result
 */
export async function getAxiosResponseFromInFilter(
  method,
  arrayParam,
  param2,
  param3
) {
  if (method && arrayParam) {
    if (!isEmpty(arrayParam)) {
      let newArrayParam = [...arrayParam];
      let count = Math.ceil(newArrayParam.length / maxInFilterSize);
      // Group objectIds into small list with 300 as maximum size
      let axiosArray = [];
      for (let index = 0; index < count; index++) {
        let smallObjectArray = newArrayParam.splice(0, maxInFilterSize);
        axiosArray.push(method(smallObjectArray, param2, param3));
      }
      let responses = await Promise.all(axiosArray);
      return getAllResultsFromArrayOfResponses(responses);
    }
  }
  return [];
}

/**
 * Get all the results from an array of responses
 * Used when axios.all is used
 * @param {*} responses An array of responses
 * @returns
 */
function getAllResultsFromArrayOfResponses(responses) {
  let results = [];
  if (Array.isArray(responses)) {
    for (let response of responses) {
      if (Array.isArray(response?.data)) {
        results = [...results, ...response.data];
      }
    }
  }
  return results;
}

/**
 * Check if the string input is a valid e-mail address
 * @param {*} emailStringInput string to check if valid e-mail address
 * @returns boolean
 */
export async function isEmailAddressValid(emailAddress) {
  if (
    emailAddress === '' ||
    emailAddress === null ||
    emailAddress === undefined
  ) {
    return false;
  }
  const emailPattern =
    /^(([^<>().,;:\s@"]+(.[^<>().,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3})|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/;
  return emailPattern.test(String(emailAddress).toLowerCase());
}

/**
 * @deprecated - Use function from tableHelper.tsx
 * Returns the next sort type
 * @param {*} currentSortType the current sortType
 * @param {*} nextSortBy the next field to be sorted
 * @param {*} prevSortBy previous field that was sorted
 * @returns
 */
export function getNextSortType(currentSortType, nextSortBy, prevSortBy) {
  if (nextSortBy !== prevSortBy) {
    return Ascending;
  } else {
    switch (currentSortType) {
      case Ascending:
        return Descending;
      case Descending:
        return null;
      default:
        return Ascending;
    }
  }
}

/**
 * Get employees based on employeeId, objectId, objectType
 * @param {*} employeeId
 * @param {*} objectId
 * @param {*} objectType
 * @returns responsibles
 */
export async function getResponsibles(employeeId, objectId, objectType) {
  let url = 'responsibles';
  if (employeeId !== null) {
    url = url + '?employeeId.equals=' + employeeId;
  }
  if (objectId !== null) {
    url =
      employeeId !== null
        ? url + '&objectId.equals=' + objectId
        : url + '?objectId.equals=' + objectId;
  }
  if (objectType !== null) {
    url =
      employeeId !== null || objectId !== null
        ? url + '&objectType.equals=' + objectType
        : url + '?objectType.equals=' + objectType;
  }
  return axios.employee.get(url).then((response) => {
    if (Array.isArray(response.data)) {
      return response.data;
    } else {
      return [];
    }
  });
}

/**
 * Check if the project is a project option or not
 * @param {*} project
 * @returns
 * @author Zoren Alvano
 * @createdOn 19/08/2021
 */
export function isProjectOption(project) {
  return (
    project?.state === ProjectState.DEFINED ||
    project?.state === ProjectState.VAGUE ||
    project?.state === ProjectState.OFFERSENT ||
    project?.state === ProjectState.PROPOSALAHEAD ||
    project?.state === ProjectState.PROPOSALTECHNICALGO ||
    project?.state === ProjectState.PITCHING ||
    project?.state === ProjectState.ESTIMATING ||
    project?.state === ProjectState.LOST ||
    project?.state === ProjectState.PLANNING ||
    project?.state === ProjectState.ALIGNING
  );
}

/**
 * Check if the employee have an access type to WRITE for the entity having these objectId and objectType
 * @param {*} employeeId -> employeeId that want to check
 * @param {*} objectId -> objectId that represents the id for the given objectType
 * @param {*} objectType -> objectType that being checked
 * @returns boolean value true: employee have access type WRITE for the specfic objectType and objectId
 *                        false: employee don't have access type WRITE for the specific objectType and objectId
 * @author Joseph Ortega
 * @createdOn 25/08/2021
 */
export async function canWrite(employeeId, objectId, objectType) {
  let jsonString = {
    employeeId: employeeId,
    objectId: objectId,
    objectType: objectType,
  };

  if (Auth.isAdmin()) {
    return true;
  }

  return axios.employee
    .post(`permissions/can-write`, jsonString)
    .then((response) => {
      return response.data;
    });
}

/**
 * Converts string to array buffer
 * Used when exporting to Excel file
 * @param {} string string to convert to Array Buffer
 * @returns ArrayBuffer
 *
 * @author WKGT Team
 * @createdOn 31/08/2021
 */
export function stringToArrayBuffer(string) {
  var buf = new ArrayBuffer(string.length); //convert string to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var index = 0; index < string.length; index++)
    view[index] = string.charCodeAt(index) & 0xff; //convert to octet
  return buf;
}

/**
 * @deprecated Use function in fileAttachmentHelper
 * Generate link base on base64 string for downloading of file
 * @param {*} file IFileAttachmentListItem
 * @returns link
 */
export function generateLink(file) {
  const binary = atob(file.fileObject.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  const blob = new Blob([view], { type: file.fileObjectContentType ?? '' });
  return window.URL.createObjectURL(blob);
}

/**
 * Adjust columns for the Excel file according to the length
 * of the maximum character of each column
 * @param {*} arrayOfArrays array of arrays
 * @returns column lengths for use in the Excel File export
 *
 * @author Reimon Angelo Tito
 * @createdOn 31/08/2021
 */
export function fitToColumn(arrayOfArrays) {
  // Despite being unused, "a" is an important variable here
  // Do not remove in order for column fitting to work
  return arrayOfArrays[0].map((a, index) => ({
    wch: Math.max(
      ...arrayOfArrays.map((array2) =>
        array2[index] ? array2[index].toString().length : 0
      )
    ),
  }));
}

/**
 * Check if the employee is a responsbile/involved to an entity having this objectId and objectType
 * @param {*} employeeId -> employeeId that want to check, responsible for the specific objectType
 * @param {*} objectType -> objectType of the responsibles being retrieved
 * @param {*} ownerShip -> responsibles based on the following ownership, by default: for both Responsible and Involved
 * @returns {*} list of responsibles based on employeeId, objectId, objectType and ownership
 * @author Joseph Ortega
 * @createdOn 02/12/2021
 */
export async function getResponsibilityList(
  employeeId,
  objectType,
  ownerShip = [Ownership.INVOLVED, Ownership.RESPONSIBLE]
) {
  return axios.sales.get(
    `responsibles?ownership.in=${ownerShip}&employeeId.equals=${employeeId}&objectType.equals=${objectType}`
  );
}

export function getNotificationSettingObjectOfCurrentUser() {
  let userSetting = store.getState().userSetting.userSetting;

  let configJsonString = userSetting?.config;

  // Convert userSettingString JSON to Object. Get the specific configuration for the notificationSetting.
  let configObject = JSON.parse(configJsonString);
  let userSettingObject = configObject?.userSetting;
  let notificationSettingObject = userSettingObject?.notificationSettingConfig;
  return notificationSettingObject;
}

/**
 * Function to check if a string is undefined, null or empty
 */
export function isNullOrUndefinedOrEmpty(string) {
  if (string === null || string === undefined || string.length === 0) {
    return true;
  } else {
    return false;
  }
}

/**
 * @deprecated Use function in DateHelper
 * Counts the number of working days between the 2 dates
 * @param {*} startDate
 * @param {*} endDate
 * @returns computed number of workdays
 */
export function getNumberWorkDays(startDate, endDate) {
  var numWorkDays = 0;
  var currentDate = moment(startDate);
  while (currentDate.isSameOrBefore(moment(endDate))) {
    // Skips Sunday and Saturday
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      numWorkDays++;
    }
    currentDate = currentDate.add(1, 'days');
  }
  return numWorkDays;
}

/**
 * @deprecated Use function in DateHelper
 * Adds the number of working days to the date
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export function addWeekdays(date, days) {
  // Use a clone of the date to ensure it's a moment date
  date = moment(date);
  while (days > 0) {
    date = date.add(1, 'days');
    // Decrease "days" only if it's a weekday.
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1;
    }
  }
  return date;
}

/**
 * @deprecated
 * Rounds the value off at a specific interval, the default interval is 1.0
 * @param {*} value
 * @param {*} interval
 * @returns
 */
export function round(value, interval = 1.0) {
  var inv = 1.0 / interval;
  return Math.round(value * inv) / inv;
}

/**
 * Handles retrieval of employment type based on career level
 * @param {*} careerLevel
 * @returns employment type
 */
export function getEmploymentTypeByCareerLevel(careerLevel) {
  if (careerLevel == null) {
    return null;
  }
  let newEmploymentTypeEnum = Object.values(EMPLOYEMENT_TYPE_ENUM);
  let employmentTypeValue = newEmploymentTypeEnum.find((employmentType) =>
    employmentType?.careerLevels.includes(
      careerLevel.replace(/ *\([^)]*\) */g, '')
    )
  );
  return employmentTypeValue?.code ?? notApplicable;
}

/**
 * Sorts call data according to its type: Appointments, Reached, Calls
 * @param {*} data
 * @returns sorted Call Data
 */
export function sortCallDataByType(data) {
  let orderByType = [
    CALL_STATUS_TYPE_ENUM.appointments.name,
    CALL_STATUS_TYPE_ENUM.reached.name,
    CALL_STATUS_TYPE_ENUM.calls.name,
  ];
  return data.sort((a, b) => {
    return orderByType.indexOf(a.label) - orderByType.indexOf(b.label);
  });
}

/**
 * Method that removes the extra spaces on the contact number input and restricts space after the plus sign
 * @param {*} str
 * @returns contact number without spaces adjacent to each other
 */
function removeExtraSpacesOnContactNumber(str) {
  if (str === null || str === undefined) {
    return null;
  }
  return str
    .split('')
    .filter((char, index) => {
      if ((str[index - 1] === '+' || str[index - 1] === ' ') && char === ' ') {
        return null;
      }
      return char;
    })
    .join('');
}

/**
 * Method that checks if the phone or mobile fields of Customer and Customer Site are correct
 * @param {*} contactInformations
 * @returns a boolean value
 */
export function checkForCorrectContactNumber(contactInformations) {
  let isContactNumberCorrect = true;
  contactInformations?.forEach((contactInformation) => {
    if (
      contactInformation !== null &&
      contactInformation?.contactInformationType !== null &&
      (contactInformation?.contactInformationType ===
        CONTACT_INFO_ENUM.phone.code ||
        contactInformation.contactInformationType ===
          CONTACT_INFO_ENUM.mobile.code)
    ) {
      if (!contactInformation.info.match(REGEX_VALID_CONTACT_NUMBER_FORMAT)) {
        isContactNumberCorrect = false;
        return;
      }
    }
  });
  return isContactNumberCorrect;
}

/**
 *
 * @param {*} date - date to check, being hovered by the mouse.
 * @param {*} type - what kind of date type being checked, this can be 'Start' or 'End'.
 * @param {*} project - project object that contains the project 'start' or 'end' dates  where we do the comparison.
 * @returns - boolean value, it will check if the date being hovered by the mouse
 *  is clickable based on the 'type' value and project start/end date range.
 */
export function acceptedWorkDate(date, type, project) {
  const isDateValid = moment(date, getDateFormat(), true).isValid();
  if (date.day() === 0 || date.day() === 6) {
    return false;
  }
  if (isDateValid) {
    if (type === 'Start' && project.end) {
      return date <= moment(project.end);
    }
    if (type === 'End' && project.start) {
      return date >= moment(project.start);
    }
  }
  return false;
}

/**
 * @deprecated Use function in dropdownHelper
 *
 * Function that maps an enum that only has code and name to dropdown option
 * @param {*} enumObject Any enum that only has code and name
 * @returns Dropdown Options of an enum
 */
export function enumToDropdownOptions(enumObject) {
  const dropdownOptions = Object.values(enumObject).map((item) => ({
    label: item.name,
    value: item.code,
  }));
  return dropdownOptions;
}

/**
 * Function that will create a link to an employee if user has right permissions
 * @param {*} activity
 * @returns new link as jsx or defaults to activity.creator if no employee attached to activity
 */
export function createEmployeeLinkFromActivity(activity) {
  return activity?.employee
    ? createLinkIfAuthorised(
        ENTITIES_LINK.employee.code,
        activity.employee.firstname + ' ' + activity.employee.name,
        activity.employee.id
      )
    : activity.creator;
}

/**
 * @deprecated, this should be handled at the back end
 * Updates project if dateStart and dateEnd is outside the time frame of project start and end date
 * @param {*} project
 * @param {*} dateStart
 * @param {*} dateEnd
 * @returns updatedProject
 */
export async function updateProjectDate(project, dateStart, dateEnd) {
  let projectStartDate = project.start;
  let projectEndDate = project.end;

  if (moment(dateStart) < moment(projectStartDate).startOf('day')) {
    projectStartDate = new Date(moment(dateStart));
    project.start = projectStartDate;
  }

  if (moment(dateEnd) > moment(projectEndDate)) {
    projectEndDate = new Date(moment(dateEnd));
    project.end = projectEndDate;
  }

  let updatedProject = await axios.project
    .save('projects', project)
    .then((res) => res.data)
    .catch((error) => {
      let mainError = this.t('failedToSaveProjectOption');
      this.handleError(mainError, error);
    });

  return updatedProject;
}
