import { AxiosError } from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';

import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import {
  CONNECTION_DIRECT,
  CONNECTION_VIA_EMPLOYEEGROUP_FROM_ROLEGROUP,
  CONNECTION_VIA_ROLE_GROUP,
  NO_ID,
} from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { ROLE_ENUM } from '../../../utils/enums/objectType';
import { SUBJECT_TYPE } from '../../../utils/enums/employee';
import { handleError, isEmpty } from '../../../utils/helpers/GenericHelper';
import {
  generateTitle,
} from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import { IErrorMessage } from '../../../utils/types/commonTypes';
import {
  IEmployeeGroup,
  IEmployeeGroupWithRelationship,
  IRoleGroup,
} from '../../../utils/types/modelTypes';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalError from '../../../components/modals/ModalError';

// !-- Used by RoleDetail, RoleGroupDetail

interface IProps {
  employeeGroups?: IEmployeeGroup[];
  employeeGroupsWithRelationship?: IEmployeeGroupWithRelationship[];
  roleGroups?: IRoleGroup[];
  cardTitle?: string;
  subjectType: string;
  subjectId?: number;
  // Temporarily set to any because currently the UserDetails which feeds this props gives an amalgam of IPersonioEmployee and IEmployee
  user?: any;
  showRelationship?: boolean;
  showDeleteButton?: boolean;
}

interface IState {
  employeeGroups: IEmployeeGroup[];
  employeeGroupsWithRelationship: IEmployeeGroupWithRelationship[];
  showModalAddGroup: boolean;
  deleteGroupModal: boolean;
  showModalDelete: boolean;
  showModalError: boolean;
  showModalForm: boolean;
  modalForm: JSX.Element | null;
}

interface IEntry {
  id: number | undefined;
  name: JSX.Element | undefined;
  description: string | undefined;
  relationship?: JSX.Element | null;
  deleteButton?: JSX.Element;
}
export default class EmployeeGroupCard extends React.Component<IProps, IState> {
  error: IErrorMessage = {} as IErrorMessage;

  deleteEmployeeGroupEvent = () => {
    // Empty function default
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      employeeGroups: this.props.employeeGroups ?? [],
      employeeGroupsWithRelationship:
        this.props.employeeGroupsWithRelationship ?? [],
      showModalAddGroup: false,
      deleteGroupModal: false,

      showModalError: false,
      showModalForm: false,
      showModalDelete: false,
      modalForm: null,
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.employeeGroups !== this.props.employeeGroups &&
      this.props.employeeGroups
    ) {
      this.setState({
        employeeGroups: this.props.employeeGroups,
      });
    }
    if (
      prevProps.employeeGroupsWithRelationship !==
        this.props.employeeGroupsWithRelationship &&
      this.props.employeeGroupsWithRelationship
    ) {
      this.setState({
        employeeGroupsWithRelationship:
          this.props.employeeGroupsWithRelationship,
      });
    }
  }

  t(keyName: string) {
    return i18n.t('EmployeeGroupCard.' + keyName);
  }

  handleError = (mainError: string, errorObject: AxiosError) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalForm = () => {
    this.setState({ showModalForm: !this.state.showModalForm });
  };

  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  toggleModalDelete = () => {
    this.setState({ showModalDelete: !this.state.showModalDelete });
  };

  /**
   * Table Data format when using Employee Groups with Relationship
   * @param employeeGroupsWithRelationship
   * @returns The table format for Dynamic Table
   */
  prepareTableEmployeeGroupWithRelationship = (
    employeeGroupsWithRelationship?: IEmployeeGroupWithRelationship[]
  ) => {
    const newTableData: IEntry[] = [];

    if (
      !isEmpty(employeeGroupsWithRelationship) &&
      employeeGroupsWithRelationship
    ) {
      employeeGroupsWithRelationship.forEach((employeeGroup) => {
        let relationshipInfo: JSX.Element | null = null;
        switch (employeeGroup.relationship) {
          case CONNECTION_DIRECT:
            relationshipInfo = (
              <>
                <span>
                  {this.t(('RELATION/' + CONNECTION_DIRECT).toLowerCase())}
                </span>
              </>
            );
            break;
          case CONNECTION_VIA_EMPLOYEEGROUP_FROM_ROLEGROUP:
            relationshipInfo = (
              <>
                <span>
                  {this.t(
                    (
                      'RELATION/' + (CONNECTION_VIA_ROLE_GROUP as string)
                    ).toLowerCase()
                  )}
                </span>
                <Link
                  to={`/settings/permissions/role-group-details/${
                    employeeGroup?.secondaryObjectId ?? (NO_ID as number)
                  }`}
                >
                  {
                    this.props.roleGroups?.find(
                      (roleGroup) =>
                        roleGroup?.id === employeeGroup?.secondaryObjectId
                    )?.roleGroup
                  }
                </Link>
              </>
            );
            break;
          default:
            break;
        }

        const entry: IEntry = {
          id: employeeGroup?.primaryObjectId,
          name: (
            <>
              <Link
                to={`/settings/permissions/employee-group-details/${employeeGroup?.primaryObjectId}`}
              >
                {employeeGroup?.employeeGroup.name}
              </Link>
            </>
          ),
          description: employeeGroup.employeeGroup.description,
          relationship: relationshipInfo,
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  /**
   * Table Data format when using Employee Groups
   * @param employeeGroups
   * @returns The table format for Dynamic Table
   */
  prepareTableEmployeeGroup = (employeeGroups?: IEmployeeGroup[]) => {
    const newTableData: IEntry[] = [];
    if (!isEmpty(employeeGroups) && employeeGroups) {
      employeeGroups.forEach((employeeGroup) => {
        const entry: IEntry = {
          id: employeeGroup?.id,
          name: (
            <>
              <Link
                to={`/settings/permissions/employee-group-details/${
                  employeeGroup?.id ?? (NO_ID as number)
                }`}
              >
                {employeeGroup?.name}
              </Link>
            </>
          ),
          description: employeeGroup.description,
          deleteButton: (
            <>
              <Button
                color="primary"
                onClick={() =>
                  this.deleteGroup(employeeGroup.id ?? (NO_ID as number))
                }
              >
                {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
              </Button>
            </>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  /**
   * Method that handles the deletion of an employee group
   * @param groupToDeleteId The id of the employee group
   */
  deleteGroup = (groupToDeleteId: number) => {
    if (groupToDeleteId === NO_ID) return;

    this.toggleModalDelete();
    this.deleteEmployeeGroupEvent = () => {
      if (this.props.subjectType === SUBJECT_TYPE.employee.code) {
        // Remove employee from employee group
        axios.employee
          .post(
            `employee-groups/${groupToDeleteId}/remove-employee/${
              this.props.user.id ?? (NO_ID as number)
            }`
          )
          .then(() => {
            this.setState({
              employeeGroups: this.state.employeeGroups.filter(
                (employeeGroup: IEmployeeGroup) =>
                  employeeGroup.id !== groupToDeleteId
              ),
            });
          })
          .catch((error: AxiosError) => {
            const mainError = this.t('failedToDeleteEmployeeGroup');
            this.handleError(mainError, error);
          });
      }
    };
  };

  /**
   * Method that checks if the subject type is role
   * @returns
   */
  isSubjectTypeRole = () => this.props.subjectType === ROLE_ENUM.role.code;

  render() {
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('name'),
        accessor: 'name',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('description'),
        accessor: 'description',
        show: 'true',
      },
      {
        type: 'data',
        header: this.t('relationship'),
        accessor: 'relationship',
        show: (this.props?.subjectType === ROLE_ENUM.role.code).toString(),
      },
      {
        type: 'data',
        header: this.t('deleteButton'),
        accessor: 'deleteButton',
        show: (!(
          this.props?.subjectType === ROLE_ENUM.role.code ||
          this.props?.subjectType === ROLE_ENUM.roleGroup.code
        )).toString(),
      },
    ];
    const employeeGroupsWithRelations =
      this.state.employeeGroupsWithRelationship;
    const employeeGroups = this.state.employeeGroups;
    return (
      <Container fluid>
        <Card>
          <CardHeader>
            <h2>{this.props.cardTitle ?? this.t('cardTitle')}</h2>
          </CardHeader>
          <CardBody>
            {!isEmpty(
              this.isSubjectTypeRole()
                ? employeeGroupsWithRelations
                : employeeGroups
            ) ? (
              <DynamicTable
                data={
                  this.isSubjectTypeRole()
                    ? this.prepareTableEmployeeGroupWithRelationship(
                        employeeGroupsWithRelations
                      )
                    : this.prepareTableEmployeeGroup(employeeGroups)
                }
                columns={preparedColumns}
              />
            ) : (
              this.t('employeeGroupEmpty')
            )}
          </CardBody>
        </Card>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={this.t('error')}
        />
        <ModalDelete
          isOpen={this.state.showModalDelete}
          event={this.deleteEmployeeGroupEvent}
          onClose={this.toggleModalDelete}
          modalTitle={this.t('deleteGroup')}
          modalBodyText={this.t('confirmDeleteGroup')}
        />
      </Container>
    );
  }
}
