import { faCogs } from '@fortawesome/free-solid-svg-icons';

import { PERMISSION_URI } from '../../utils/enums/permission';
import i18n from '../../i18n';
import ConfigurationSettings from '../../pages/settings/ConfigurationSettings/ConfigurationSettings';
import ProjectSettingsView from '../../pages/settings/ProjectSettings/ProjectSettingsView';
import EmployeeGroupDetail from '../../pages/settings/PermissionSettings/EmployeeGroupDetail';
import EmployeeSettingsView from '../../pages/settings/EmployeeSettings/EmployeeSettingsView';
import PermissionSettingsView from '../../pages/settings/PermissionSettings/PermissionSettingsView';
import RoleDetail from '../../pages/settings/PermissionSettings/RoleDetails/RoleDetail';
import RoleGroupDetail from '../../pages/settings/PermissionSettings/RoleGroupDetail';
import ContractTypeInputForm from '../../pages/settings/EmployeeSettings/ContractTypeInputForm';
import LocationInsertOrUpdate from '../../pages/settings/EmployeeSettings/LocationInsertOrUpdate';
import SkillInsertOrUpdate from '../../pages/settings/Skills/SkillInsertOrUpdate';
import Skills from '../../pages/settings/Skills/SkillListView';
import ContactPersonRoleListView from '../../pages/settings/SalesSettings/ContactPersonRoleListView';
import DeclineReasonInputForm from '../../pages/settings/SalesSettings/DeclineReasonInputForm';
import ContactPersonRoleInsertOrUpdate from '../../pages/settings/SalesSettings/ContactPersonRoleInsertOrUpdate';
import AddOrUpdateResponsibleRole from '../../pages/settings/SalesSettings/AddOrUpdateResponsibleRole';
import SalesSettingsView from '../../pages/settings/SalesSettings/SalesSettingsView';
import AddOrUpdateSector from '../../pages/settings/SalesSettings/AddOrUpdateSector';

export const settingsRoutes = {
  name: i18n.t('index.applicationSettings'),
  path: '/settings',
  header: i18n.t('index.settings'),
  icon: faCogs,
  isProtected: true,
  auth: true,
  permissionUri: [PERMISSION_URI.adminAccess.readWrite.uri],
  children: [
    {
      path: '/settings/sales',
      name: i18n.t('index.salesSettings'),
      component: SalesSettingsView,
      permissionUri: [PERMISSION_URI.adminAccess.readWrite.uri],
    },
    {
      path: '/settings/configuration',
      name: i18n.t('index.configurationSettings'),
      component: ConfigurationSettings,
      permissionUri: [PERMISSION_URI.adminAccess.readWrite.uri],
    },
    {
      path: '/settings/project',
      name: i18n.t('index.projectSettings'),
      component: ProjectSettingsView,
      permissionUri: [PERMISSION_URI.adminAccess.readWrite.uri],
    },
    {
      path: '/settings/employee',
      name: i18n.t('index.employeeSettings'),
      component: EmployeeSettingsView,
      permissionUri: [PERMISSION_URI.adminAccess.readWrite.uri],
    },
    {
      path: '/settings/permissions',
      name: i18n.t('index.permissionSettings'),
      component: PermissionSettingsView,
      permissionUri: [PERMISSION_URI.adminAccess.readWrite.uri],
    },
    {
      path: '/settings/skills',
      name: 'Skills',
      component: Skills,
    },
  ],
};

export const settingsPrivateRoutes = {
  name: 'Application Settings',
  path: '/settings',
  header: 'Settings',
  isProtected: true,
  permissionUri: [PERMISSION_URI.adminAccess.readWrite.uri],
  children: [
    {
      path: '/settings/sales/contact-person-roles/',
      name: 'Contact Person Roles',
      component: ContactPersonRoleListView,
    },
    {
      path: '/settings/sales/contact-person-roles/add',
      name: 'Add Contact Person Roles',
      component: ContactPersonRoleInsertOrUpdate,
    },
    {
      path: '/settings/sales/contact-person-roles-update/:id',
      name: 'Add Contact Person Roles',
      component: ContactPersonRoleInsertOrUpdate,
    },
    {
      path: '/settings/sales/responsible-roles-update/:id',
      component: AddOrUpdateResponsibleRole,
    },
    {
      path: '/settings/sales/sectors-update/:id',
      component: AddOrUpdateSector,
    },
    {
      path: '/settings/employee/location-update/:id',
      name: 'Update location',
      component: LocationInsertOrUpdate,
    },
    {
      path: '/settings/employee/contract-type-update/:id',
      name: 'Update Contract Type',
      component: ContractTypeInputForm,
    },
    {
      path: '/settings/sales/decline-reason-update/:id',
      name: 'Update Decline Reason',
      component: DeclineReasonInputForm,
    },
    {
      path: '/settings/permissions/role-details/:id',
      name: 'Role Detail',
      component: RoleDetail,
    },
    {
      path: '/settings/permissions/role-group-details/:id',
      name: 'Role Group Detail',
      component: RoleGroupDetail,
    },
    {
      path: '/settings/skill-update/:id',
      name: 'Update Skill',
      component: SkillInsertOrUpdate,
    },
    {
      path: '/settings/permissions/employee-group-details/:id',
      name: 'Employee Group Detail',
      component: EmployeeGroupDetail,
    },
  ],
};
