import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { IAbsence } from '../../utils/types/modelTypes';
import { getSelectedAbsence } from '../../services/api/absence';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import { getDateFormat } from '../../utils/helpers/date';
import DetailsCard, { CardData } from '../../components/cards/DetailsCard';
import { act } from './homeHelper';

interface IProps extends IWithModalsProps {
  absenceDataId: number;
}

const CalendarViewAbsenceDialog = ({
  absenceDataId,
  modalErrorHandler,
}: IProps) => {
  const [absence, setAbsence] = useState({} as IAbsence);

  const dateFormat = getDateFormat();

  const organizeAbsence = (retrievedAbsence: IAbsence) => {
    const { startDate, endDate } = retrievedAbsence;
    const organizedAbsence: IAbsence = {
      ...retrievedAbsence,
      startDate: moment(startDate).startOf('day').format(dateFormat),
      endDate: moment(endDate).endOf('day').format(dateFormat),
    };
    setAbsence(organizedAbsence);
  };

  useEffect(() => {
    getSelectedAbsence(absenceDataId)
      .then(({ data }) => {
        organizeAbsence(data);
      })
      .catch((error) => {
        modalErrorHandler(act('failedToSaveAbsenceState'), error);
      });
  }, []);

  const {
    startDate,
    endDate,
    description,
    duration,
    absenceType,
    absenceState,
  } = absence;
  const fields: CardData[] = [
    {
      label: act('start'),
      value: <div style={{ minHeight: '20px' }}>{startDate}</div>,
    },
    {
      label: act('end'),
      value: <div style={{ minHeight: '20px' }}>{endDate}</div>,
    },
    {
      label: act('description'),
      value: <div style={{ minHeight: '20px' }}>{description}</div>,
    },
    {
      label: act('duration'),
      value: <div style={{ minHeight: '20px' }}>{duration}</div>,
    },
    {
      label: act('absenceType'),
      value: <div style={{ minHeight: '20px' }}>{absenceType.type}</div>,
    },
    {
      label: act('status'),
      value: <div style={{ minHeight: '20px' }}>{absenceState}</div>,
    },
  ];

  return <DetailsCard title="" fields={fields} />;
};
export default withModals(CalendarViewAbsenceDialog);
