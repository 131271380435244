import React, { ChangeEvent, ReactElement } from 'react';
import { Card, CardBody, CardTitle, Input, Table } from 'reactstrap';

import './Card.scss';

type CardData = {
  label: string;
  value: ReactElement | string;
};

interface IProps {
  title: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  fields?: CardData[];
  headerButtons?: ReactElement;
  footerButtons?: ReactElement;
  border?: boolean;
  leftTitle?: boolean;
  disabled?: boolean;
}

/**
 * Textarea Card -
 * Displays a textarea input and data below the textarea
 * in a vertical table style, and contains a title
 */
const TextareaCard = ({
  title,
  value,
  onChange,
  fields,
  headerButtons,
  footerButtons,
  border,
  leftTitle,
  disabled,
}: IProps) => (
  <Card
    aria-label="textarea-card"
    className={`common-card ${border ? 'with-border' : ''}`}
  >
    <CardBody>
      <CardTitle
        aria-label="textarea-card-title"
        className={`${leftTitle ? 'title-left' : 'title-center'}`}
      >
        <h3>{title}</h3>
      </CardTitle>
      {headerButtons && (
        <div className="float-end header-buttons">{headerButtons}</div>
      )}
    </CardBody>
    <CardBody>
      <Input
        aria-label="textarea-card-input"
        type="textarea"
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {fields && (
        <Table borderless>
          <tbody>
            {fields.map(({ label, value }) => (
              <tr key={label}>
                <th style={{ width: '140px' }}>{label}</th>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {footerButtons && (
        <div className="float-end footer-buttons">{footerButtons}</div>
      )}
    </CardBody>
  </Card>
);

export default TextareaCard;
