import React, {
  createContext,
  Dispatch,
  SetStateAction,
  ReactNode,
  useState,
} from 'react';

import {
  IObjectContactInformation,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import {
  IStatusDetail,
  IRelatedActivitiesDetail,
  IActivityListItem,
  IDescriptionDetail,
  IResponsibleDetail,
} from '../../../utils/types/modelTypes';
import {
  IObjectContactPersonCustomerSite,
  IObjectRelatedEntities,
} from '../../../utils/types/responseTypes';

// !-- Used in ContactPersonProfile, ContactPersonOverview

export interface IContactDetail {
  id: number;
  firstname: string;
  lastname: string;
  title: IObjectNameAndId;
  gender: string;
  genderPrefix: string;
  customerAccount: IObjectNameAndId;
  customer: IObjectNameAndId;
  customerSite: IObjectContactPersonCustomerSite;

  statusGdpr: string;
  customerSiteContactInformation: IObjectContactInformation[];
  contactInformation: IObjectContactInformation[];
  sectors: IObjectNameAndId[];
  role: IObjectNameAndId;
  functionRole: string;
  preferredLanguage: string;
  newsletter: string;
  topics: IObjectNameAndId[];
}

type ContactPersonDetailsContextType = {
  // Details
  unsavedDetail: boolean;
  setUnsavedDetail: Dispatch<SetStateAction<boolean>>;
  contactDetail: IContactDetail;
  setContactDetail: Dispatch<SetStateAction<IContactDetail>>;
  // Assignment Card
  statusDetail: IStatusDetail;
  setStatusDetail: Dispatch<SetStateAction<IStatusDetail>>;
  // Description
  description: IDescriptionDetail;
  setDescription: Dispatch<SetStateAction<IDescriptionDetail>>;
  unsavedDescription: boolean;
  setUnsavedDescription: Dispatch<SetStateAction<boolean>>;
  // Related Activities
  relatedActivitiesDetail: IRelatedActivitiesDetail;
  setRelatedActivitiesDetail: Dispatch<
    SetStateAction<IRelatedActivitiesDetail>
  >;
  // History of Activities
  historyOfActivitiesDetail: IActivityListItem[];
  setHistoryOfActivitiesDetail: Dispatch<SetStateAction<IActivityListItem[]>>;
  // Related Entities
  relatedEntities: IObjectRelatedEntities[];
  setRelatedEntities: Dispatch<SetStateAction<IObjectRelatedEntities[]>>;
  // Responsibles for Contact
  responsibleDetail: IResponsibleDetail;
  setResponsibleDetail: Dispatch<SetStateAction<IResponsibleDetail>>;
};

export const ContactPersonDetailsContext = createContext(
  {} as ContactPersonDetailsContextType
);

interface IProps {
  children: ReactNode;
}

/**
 * Provides context for contact details page
 */
const ContactPersonDetailsProvider = ({ children }: IProps) => {
  // Details Card
  const [contactDetail, setContactDetail] = useState<IContactDetail>({
    id: 0,
    firstname: '',
    lastname: '',
    title: {} as IObjectNameAndId,
    gender: '',
    genderPrefix: '',
    customerAccount: {} as IObjectNameAndId,
    customer: {} as IObjectNameAndId,
    customerSite: {} as IObjectContactPersonCustomerSite,

    statusGdpr: '',
    customerSiteContactInformation: [{}] as IObjectContactInformation[],
    contactInformation: [{}] as IObjectContactInformation[],
    sectors: [{}] as IObjectNameAndId[],
    role: {} as IObjectNameAndId,
    functionRole: '',
    preferredLanguage: '',
    newsletter: '',
    topics: [{}] as IObjectNameAndId[],
  });
  const [unsavedDetail, setUnsavedDetail] = useState(false);

  // Assignment Card
  const [statusDetail, setStatusDetail] = useState<IStatusDetail>({
    attitude: '',
    attitudeCode: '',
    contactAlumnus: '',
    target: false,
    acquisition: false,
    assignmentState: '',
  });

  // Description
  const [description, setDescription] = useState<IDescriptionDetail>({
    notes: '',
    notesId: 0,
  });
  const [unsavedDescription, setUnsavedDescription] = useState(false);

  // Related Activities
  const [relatedActivitiesDetail, setRelatedActivitiesDetail] =
    useState<IRelatedActivitiesDetail>({
      dueDate: '',
      nextAction: '',
    });

  // History of Activities
  const [historyOfActivitiesDetail, setHistoryOfActivitiesDetail] = useState<
    IActivityListItem[]
  >([]);

  // Related Entities
  const [relatedEntities, setRelatedEntities] = useState<
    IObjectRelatedEntities[]
  >([]);

  // Employees Responsible for Contact
  const [responsibleDetail, setResponsibleDetail] =
    useState<IResponsibleDetail>({} as IResponsibleDetail);

  return (
    <ContactPersonDetailsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        // Details
        unsavedDetail,
        setUnsavedDetail,
        contactDetail,
        setContactDetail,
        statusDetail,
        setStatusDetail,
        // Assignment Card
        // Description
        description,
        setDescription,
        unsavedDescription,
        setUnsavedDescription,
        // Related Activities
        relatedActivitiesDetail,
        setRelatedActivitiesDetail,
        // History of Activities
        historyOfActivitiesDetail,
        setHistoryOfActivitiesDetail,
        // Related Entities
        relatedEntities,
        setRelatedEntities,
        // Responsible
        responsibleDetail,
        setResponsibleDetail,
      }}
    >
      {children}
    </ContactPersonDetailsContext.Provider>
  );
};

export default ContactPersonDetailsProvider;
