import axios from './services/axios/axios';
import { getEmployeesForResponsible } from './utils/helpers/url';
import { defaultCurrency } from './utils/constants';
import { isEmpty } from './utils/helpers/array';
import { Url } from './services/api/constants';
import userData from './user';
import { Currency } from './utils/enums/currency';

// Method to set the user's id, roles and rights
export default async function setUser(props) {
  const user = await axios.account.get().catch((error) => {
    window.location.replace(Url.LOGIN);
    return;
  });
  if (user.data === undefined) {
    return;
  }
  const userRoles = user.data.authorities.toString().split(',');
  userData.role = userRoles;

  // Get Employee
  const employee = await axios.employee.get('employees/own').catch((error) => {
    console.log(
      'Failed to retrieve user details.\n' +
      'Failed to communicate with the server.\n' +
      error.message
    );
  });

  // Get Employees responsible for (this will be for user data)
  const employeeResponsible = await getEmployeesForResponsible().catch((error) => {
    console.log(
      'Failed to retrieve employee responsible details.\n' +
      'Failed to communicate with the server.\n' +
      error.message
    );
  });

  // Get Permissions
  const permissions = await axios.employee
    .get('permissions/own')
    .catch((error) => {
      console.log(
        'Failed to retrieve user permissions.\n' +
        'Failed to communicate with the server.\n' +
        error.message
      );
    });

  // Get Configuration
  const configuration = await axios.employee
    .get('service-configurations')
    .catch((error) => {
      console.log(
        'Failed to retrieve configurations .\n' +
        'Failed to communicate with the server.\n' +
        error.message
      );
    });

  // Get userSetting
  const userSetting = await axios.notification
    .get(`own-user-settings`)
    .catch((error) => {
      console.log(
        'Failed to retrieve user settings.\n' +
          'Failed to communicate with the server.\n' +
          error.message
      );
    });

  //Saving configurations to store
  props.setConfigs(configuration.data);

  //Saving account details to store
  props.setAccountDetails(user.data);

  //Saving employee Details to store
  props.setEmployeeDetails(employee.data);

  // Saving defaultCurrency to store
  props.setDefaultCurrency(Currency.EUR);

  // Saving employee permissions
  props.setPermissions(permissions.data);

  // Saving userSetting to userData
  props.setUserSetting(userSetting.data);
  // Set user data
  userData.userId = employee?.data?.userId;
  userData.permissions = permissions.data;
  userData.employeeId = employee?.data?.id;
  userData.userSetting = userSetting?.data;
  userData.employeeResponsibles = employeeResponsible;
}
