import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Alert, Container } from 'reactstrap';

import { removeAlert } from '../../redux/alertsSlice';
import { RootState } from '../../redux/store';
import { IAlert } from '../../utils/types/commonTypes';

/**
 *
 * Alert component
 * Used to create alerts
 *
 *  @param alerts redux store value mapped to state, available user alerts
 *  @param removeAlert redux action to remove alert
 */

interface IAlertModalProps {
  alert: IAlert;
  removeAlert: ActionCreatorWithPayload<string, 'alerts/removeAlert'>;
}

const AlertModal: React.FC<IAlertModalProps> = ({ alert, removeAlert }) => (
  <Container fluid="true">
    <Alert
      toggle={() => {
        removeAlert(alert.id);
      }}
      style={{
        zIndex: 0,
        color: 'white',
        background: alert.alertType === 'success' ? '#7ac142' : '#dc3545',
      }}
    >
      {alert.msg}
    </Alert>
  </Container>
);

interface INavbarAlertsProps extends PropsFromRedux {}

const NavbarAlerts: React.FC<INavbarAlertsProps> = ({
  alerts,
  removeAlert,
}) => (
  <>
    {alerts.map((alert: IAlert) => (
      <AlertModal key={alert.id} alert={alert} removeAlert={removeAlert} />
    ))}
  </>
);

const mapStateToProps = (state: RootState) => ({
  alerts: state.alerts.alerts,
});

const mapDispatchToProps = {
  removeAlert,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NavbarAlerts);
