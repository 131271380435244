import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Input } from 'reactstrap';

import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  IUtilizationActivity,
  IUtilizationListItem,
} from '../../../utils/types/responseTypes';
import { saveComment } from '../../../services/api/resourcePlanner';
import { t } from './utilizationHelper';

interface IProps extends IWithModalsProps {
  employeeUtilization: IUtilizationListItem;
  setUtilizations: Dispatch<SetStateAction<IUtilizationListItem[]>>;
  onClose: () => void;
}

const AddCommentModal = ({
  employeeUtilization,
  setUtilizations,
  onClose,
  modalErrorHandler,
}: IProps) => {
  const [description, setDescription] = useState<string>('');

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleSaveButtonClick = async () => {
    const {
      employee: { id: employeeId },
      comments,
    } = employeeUtilization;
    const commentToSave = { description, employeeId } as IUtilizationActivity;
    try {
      const { data: comment } = await saveComment(commentToSave);
      const updatedEmployeeUtilization = {
        ...employeeUtilization,
        comments: [...comments, comment],
      };
      const {
        employee: { id: updatedEmployeeId },
      } = updatedEmployeeUtilization;
      // Update values of Utilization State
      setUtilizations((values) =>
        values.map((employeeUtilization) => {
          const {
            employee: { id: employeeId },
          } = employeeUtilization;
          if (employeeId === updatedEmployeeId) {
            return updatedEmployeeUtilization;
          } else {
            return employeeUtilization;
          }
        })
      );
      onClose();
    } catch (error) {
      modalErrorHandler(t('failedToSaveActivity'), error);
    }
  };

  return (
    <Card>
      <CardHeader>
        <h3>{t('comment')}</h3>
      </CardHeader>
      <CardBody>
        <Input
          type="textarea"
          style={{ minHeight: '120px' }}
          onChange={handleDescriptionChange}
          value={description}
          aria-label="textbox-comment"
        />
        <br />
        <Button color="primary" onClick={() => handleSaveButtonClick()}>
          {t('save')}
        </Button>
      </CardBody>
    </Card>
  );
};

export default withModals(AddCommentModal);
