import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  FormGroup,
  FormFeedback,
  Input,
} from 'reactstrap';
import axios from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import { handleError, isEmpty } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';
import ModalOK from '../../../components/modals/ModalOK';

/*
 * Class used when adding/updating a Sector
 */
class AddOrUpdateCountry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      country: {
        id: props.country?.id ?? null,
        name: props.country?.name ?? null,
      },
      showModalOK: false,
      showModalError: false,
    };
    this.isUpdate = props.country?.id ? true : false;
    this.error = null;
    this.message = null;
    this.translation = this.props.t;
  }

  t(keyName) {
    return i18n.t('CountryListView.' + keyName);
  }

  //Toggle for showing the modal for duplicate entries.
  toggleModalOK = () => {
    this.setState({ showModalOK: !this.state.showModalOK });
  };

  //Toggle for showing the modal for error popup.
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  handleSubmit = async (e) => {
    let country = this.state.country;
    var sameNameExistingEntry = await axios.sales
      .get(`countries?name.equals=${country?.name}`)
      .then((res) => res.data)
      .catch((error) => {
        this.handleError(this.t('failedToValidateCountry'), error);
      });

    if (!isEmpty(sameNameExistingEntry)) {
      this.errorMessage = this.t('nameAlreadyExist');
      this.toggleModalOK();
      return;
    }
    this.saveCountry();
  };

  handleError = (mainError, errorObject) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  saveCountry = async () => {
    let country = this.state.country;
    let actionError = country?.id
      ? this.t('failedToUpdateCountry')
      : this.t('failedToAddCountry');

    let savedCountry = await axios.sales
      .save('countries', country)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.handleError(actionError, error);
      });
    if (savedCountry && this.props.onSave) {
      this.props.onSave(savedCountry);
    }
  };

  handleNameChange = (e) => {
    let value = e.target.value.trim() ? e.target.value : null;
    let country = this.state.country;
    country.name = value;
    this.setState({ country: country });
  };

  render() {
    const country = this.state.country;
    return (
      <Container fluid>
        <Card>
          {this.state.isUpdate ? (
            <CardHeader>
              <CardTitle>
                <h1>Country Details - {this.props.match.params.id}</h1>
              </CardTitle>
            </CardHeader>
          ) : null}
          <CardBody>
            <FormGroup>
              <InputFormLabel isRequired={true} text={this.t('name')} />
              <Input
                onChange={this.handleNameChange}
                value={country?.name}
                name="name"
                type="text"
                invalid={country?.name === undefined}
              />
              <FormFeedback>{this.t('nameRequired')}</FormFeedback>
            </FormGroup>

            <Button
              color="primary"
              className="float-end"
              onClick={this.handleSubmit}
              disabled={country?.name === undefined}
            >
              {this.t('submit')}
            </Button>
          </CardBody>
        </Card>
        <ModalOK
          isOpen={this.state.showModalOK}
          onClose={this.toggleModalOK}
          modalTitle={this.t('error')}
          modalBodyText={this.errorMessage}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.message?.mainError}
          errorReason={this.message?.errorReason}
          errorResponse={this.message?.errorResponse}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}

export default withRouter(withTranslation()(AddOrUpdateCountry));
