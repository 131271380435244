import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
} from 'reactstrap';

import { default as axios } from '../../../services/axios/axios';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import {
  ALL,
  CALLED_AND_APPOINTMENT,
  CALLED_AND_INTERESTED,
  MISSED_CALL,
  NO_INDUSTRY,
} from '../../../utils/constants';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import { CALL_STATUS_TYPE_ENUM } from '../../../utils/enums/callStatus';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { handleError, isEmpty } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import { IDropdownOption, IErrorMessage } from '../../../utils/types/commonTypes';
import {
  IActivity,
  IContactPersonAssignment,
  IContactPersonSector,
  IExtendedContactPerson,
  ISector,
} from '../../../utils/types/modelTypes';
import user from '../../../user';
import ModalError from '../../../components/modals/ModalError';
import CallingStatisticsDevelopmentCard from './CallingStatisticsDevelopmentCard';
import MatrixOverviewCard from './MatrixOverviewCard';
import SuccessfulHoursForCallingCard from './SuccessfulHoursForCallingCard';
import TargetPrioritiationCard from './TargetPrioritizationCard';
import { Ownership } from '../../../utils/enums/ownership';

const LINE_STEP_SIZE = 20;
const TENSION = 0;
const TICK_MAX = 100;
const TICK_MIN = 0;
const MAX_TICKS_LIMIT = 10;
const DEFAULT_COLOR = '#808080';

interface ICallData {
  id?: number | undefined;
  callStatusType?: string | undefined;
  date?: string | undefined;
  contactId?: number | undefined;
  assignmentState?: string | undefined;
  statusColor?: string | undefined;
}

interface IProps extends RouteComponentProps<Record<string, never>> {}

interface IState {
  callData: ICallData[] | undefined;
  callActivities: IActivity[];
  contactPersonAssignments: IContactPersonAssignment[];
  sectors: IDropdownOption[];
  sectorIds: string[];
  contactPersonSectors: IContactPersonSector[];
  showModalError: boolean;
  extendedContactPeople: IExtendedContactPerson[];
}

interface ICallStatusType {
  code: string;
  name: string;
  color: string;
}

/*
 * Class that shows calling statistics
 */
class CallingStatistics extends React.Component<IProps, IState> {
  message: IErrorMessage = {} as IErrorMessage;

  modalTitle: string;

  constructor(props: IProps) {
    super(props);
    this.state = {
      callData: [],
      callActivities: [],
      contactPersonAssignments: [],
      sectors: [],
      sectorIds: [],
      contactPersonSectors: [],
      showModalError: false,
      extendedContactPeople: [],
    };
    this.modalTitle = '';
  }

  t(keyName: string) {
    return i18n.t('CallingStatistics.' + keyName);
  }

  async componentDidMount() {
    const extendedContactPeople = await axios.sales
      .get(`extended-contact-people?.ownership.in=${[Ownership.RESPONSIBLE]}`)
      .then(
        (response: AxiosResponse<IExtendedContactPerson[]>) => response.data
      )
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveExtendedContactPeople');
        this.handleError(mainError, error);
        return [] as IExtendedContactPerson[];
      });

    const callActivities = await axios.sales
      .get(
        `activities?objectType.equals=${OBJECT_TYPE_ENUM.call.code}&employeeId.equals=${user.employeeId}`
      )
      .then((response: AxiosResponse<IActivity[]>) => response.data)
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveActivities');
        this.handleError(mainError, error);
        return [] as IActivity[];
      });

    axios.sales
      .get('sectors')
      .then((response: AxiosResponse<ISector[]>) => {
        if (Array.isArray(response.data)) {
          const sectors = [] as IDropdownOption[];
          sectors.push({
            value: ALL,
            label: this.t('allIndustries'),
          });
          sectors.push({
            value: NO_INDUSTRY,
            label: this.t('noIndustry'),
          });
          const sectorsAsDropdownOption = response.data.map(
            (sector: ISector) => ({
              value: sector.id?.toString(),
              label: sector.sector,
            })
          ) as IDropdownOption[];
          const retrivedSectorIds = response.data.map(
            (sector: any) => sector.id
          );

          this.setState({
            sectors: sectors.concat(sectorsAsDropdownOption),
            sectorIds: retrivedSectorIds.concat(NO_INDUSTRY),
          });
        }
      })
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveSectors');
        this.handleError(mainError, error);
      });

    const contactPersonSectors = await axios.sales
      .get('contact-person-sectors?')
      .then((response: AxiosResponse<IContactPersonSector[]>) => response?.data)
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveContactPersonSectors');
        this.handleError(mainError, error);
        return [] as IContactPersonSector[];
      });

    const contactPersonAssignments = await axios.sales
      .get(
        `contact-person-assignments?latest.equals=${true}&addedBy.equals=${
          user.employeeId
        }`
      )
      .then(
        (response: AxiosResponse<IContactPersonAssignment[]>) => response.data
      )
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveContactPeople');
        this.handleError(mainError, error);
        return [] as IContactPersonAssignment[];
      });

    const callData = this.prepareCallData(
      callActivities,
      contactPersonAssignments
    );

    this.setState({
      callActivities,
      contactPersonSectors,
      contactPersonAssignments,
      callData,
      extendedContactPeople,
    });
  }

  /**
   * Handles preparation of call data derived from call activity.
   * @param {*} callActivityData
   * @param {*} contactAssignments
   * @returns call data array
   */
  prepareCallData = (
    callActivityData: IActivity[],
    contactAssignments: IContactPersonAssignment[]
  ) => {
    const callDataArray: ICallData[] = [];
    if (!isEmpty(callActivityData)) {
      callActivityData.forEach((call: IActivity) => {
        let callStatusType = {} as ICallStatusType | undefined;
        if (!call?.description) {
          return;
        }

        if (call?.description.includes(CALLED_AND_APPOINTMENT)) {
          callStatusType = CALL_STATUS_TYPE_ENUM.appointments;
        } else if (call?.description.includes(CALLED_AND_INTERESTED)) {
          callStatusType = CALL_STATUS_TYPE_ENUM.reached;
        } else if (call?.description.includes(MISSED_CALL)) {
          callStatusType = CALL_STATUS_TYPE_ENUM.calls;
        } else {
          callStatusType = undefined;
        }

        const contactAssignmentState = contactAssignments.find(
          (contact: IContactPersonAssignment) =>
            contact.contactPerson.id === call?.objectId
        );

        const callData = {
          id: call?.id ?? undefined,
          callStatusType: callStatusType?.name,
          date: call?.date,
          contactId: call?.objectId,
          assignmentState: contactAssignmentState?.assignmentState,
          statusColor: callStatusType?.color ?? DEFAULT_COLOR,
        } as ICallData;

        callDataArray.push(callData);
      });
      return callDataArray;
    }
    return callDataArray;
  };

  /**
   * Method that handles error
   * @param {*} mainError
   * @param {*} errorObject
   */
  handleError = (mainError: string, errorObject: AxiosError) => {
    this.message = handleError(mainError, errorObject);
    this.modalTitle = this.t('error');
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  /**
   * Enables a toggle to appear when an error is encountered.
   */
  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  render() {
    const BAR_OPTIONS = {
      tooltips: {
        mode: 'label',
        callbacks: {
          label: (tooltipItem: any, data: any) => `${
            data.datasets[tooltipItem.datasetIndex].label
          }
          : ${tooltipItem.yLabel}`,
        },
      },
      scales: {
        x: {
          stacked: true,
          gridLines: { display: false },
        },
        y: {
          stacked: true,
          ticks: {
            callback: (value: any) => value.toString(),
            maxTicksLimit: MAX_TICKS_LIMIT,
          },
        },
      },
      legend: { display: true, position: 'bottom' },
      maintainAspectRatio: false,
    };

    const LINE_OPTIONS = {
      tooltips: {
        mode: 'label',
        callbacks: {
          label: (tooltipItem: any, data: any) => `${
            data.datasets[tooltipItem.datasetIndex].label
          }
          : ${tooltipItem.yLabel.toLocaleString('de-DE')}
          %`,
        },
      },
      scales: {
        x: {
          gridLines: { display: false },
        },
        y: {
          ticks: {
            min: TICK_MIN,
            max: TICK_MAX,
            stepSize: LINE_STEP_SIZE,
            callback: (value: any) => `${value}%`,
          },
          scaleLabel: {
            display: true,
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: { usePointStyle: true },
      },
      elements: {
        line: {
          tension: TENSION,
        },
      },
      bezierCurve: false,
      maintainAspectRatio: false,
    };
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{this.t('callStatistics')}</HeaderTitle>
          {generateBreadcrumb(this.props.location.pathname, this.t('customer'))}
        </Header>
        <Card>
          <CardHeader>
            <CardTitle className="mb-0">
              <h1>{this.t('callStatistics')}</h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Col>
              <MatrixOverviewCard
                callActivities={this.state.callActivities}
                sectors={this.state.sectors}
                sectorIds={this.state.sectorIds}
                contactPersonSectors={this.state.contactPersonSectors}
                contactPersonAssignments={this.state.contactPersonAssignments}
              />
              <br />
              <SuccessfulHoursForCallingCard
                callData={this.state.callData}
                sectors={this.state.sectors}
                sectorIds={this.state.sectorIds}
                contactPersonSectors={this.state.contactPersonSectors}
                contactPersonAssignments={this.state.contactPersonAssignments}
                lineOptions={LINE_OPTIONS}
                barOptions={BAR_OPTIONS}
              />
              <CallingStatisticsDevelopmentCard
                callData={this.state.callData}
                sectors={this.state.sectors}
                sectorIds={this.state.sectorIds}
                contactPersonSectors={this.state.contactPersonSectors}
                contactPersonAssignments={this.state.contactPersonAssignments}
                lineOptions={LINE_OPTIONS}
                barOptions={BAR_OPTIONS}
              />
              <br />
              <TargetPrioritiationCard
                callActivities={this.state.callActivities}
                sectors={this.state.sectors}
                sectorIds={this.state.sectorIds}
                contactPersonSectors={this.state.contactPersonSectors}
                contactPersonAssignments={this.state.contactPersonAssignments}
                extendedContactPeople={this.state.extendedContactPeople}
                lineOptions={LINE_OPTIONS}
              />
            </Col>
          </CardBody>
        </Card>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.message?.mainError ?? ''}
          errorReason={this.message?.errorReason ?? ''}
          errorResponse={this.message?.errorResponse ?? ''}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}

export default withRouter(CallingStatistics);
