import React, { ReactElement } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

import Table, { TableData } from '../tables/Table';

import './Card.scss';

interface IProps {
  title: string;
  tableData: TableData[];
  highlightedRows?: number[];
  noDataPlaceholder?: string;
  headerButtons?: ReactElement;
  footerButtons?: ReactElement;
  border?: boolean;
  leftTitle?: boolean;
  largeTitle?: boolean;
  striped?: boolean;
}

/**
 * Table Card -
 * Displays a data table, and contains title, header buttons, footer buttons
 */
const TableCard = ({
  title,
  tableData,
  highlightedRows = [],
  noDataPlaceholder,
  headerButtons,
  footerButtons,
  border,
  leftTitle,
  largeTitle,
  striped,
}: IProps) => (
  <Card className={`common-card ${border ? 'with-border' : ''}`}>
    <CardBody>
      <CardTitle className={`${leftTitle ? 'title-left' : 'title-center'}`}>
        {largeTitle ? <h1>{title}</h1> : <h3>{title}</h3>}
      </CardTitle>
      {headerButtons && (
        <div className="float-end header-buttons">{headerButtons}</div>
      )}
    </CardBody>
    <CardBody>
      <Table
        tableData={tableData}
        highlightedRows={highlightedRows}
        {...(noDataPlaceholder && { noDataPlaceholder })}
        {...(striped && { striped })}
      />
      {footerButtons && (
        <div className="float-end footer-buttons">{footerButtons}</div>
      )}
    </CardBody>
  </Card>
);

export default TableCard;
