import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import DynamicTable from '../../../components/tables/DynamicTable';
import DynamicTableColumnControl from '../../../components/tables/DynamicTableColumnControl';
import { DEFAULT_LOAD_TIMEOUT, DELETE, REMOVE } from '../../../utils/constants';
import { NEXT_ACTION_ENUMS } from '../../../utils/enums/activity';
import { getNextSortType } from '../../../utils/helpers/table';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  fetchContactPersonInvolvedResponsibles,
  fetchContactPersonListItems,
  fetchContactPersonResponsibles,
  setFilters,
  setHasMoreListItems,
  setPage,
  setSortBy,
  setSortType,
} from '../../../redux/contactPersonListSlice';
import { RootState } from '../../../redux/store';
import { createContactPersonEntry, translate } from './contactListHelpers';
import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';
import { isEmpty } from '../../../utils/helpers/array';
import { SortBy, SortType } from '../../../utils/enums/sort';
import { IContactPersonListItem } from '../../../utils/types/responseTypes';
import useMounted from '../../../hooks/useMounted';
import { ContactStatusEnum, GDPRStatus } from '../../../utils/enums/contact';

const defaultFilterWidth = '100px';
const defaultWidth = '130px';
const buttonWidth = '60px';

/**
 * Display the list of contacts in a table
 */
interface IProps extends PropsFromRedux, RouteComponentProps, IWithModalsProps {
  onDeleteOrRemove: (
    contactPersonListItem: IContactPersonListItem,
    action: string
  ) => void;
  showColumnToggler: boolean;
}

const acquisitionOrTargetOptions = [
  { label: translate('yes'), value: true },
  { label: translate('no'), value: false },
];

const nextActivityOptions = enumValuesToDropdownOptions(
  Object.values(NEXT_ACTION_ENUMS)
).sort((a, b) => (a.label > b.label ? 1 : -1));

const statusGdprOptions = enumValuesToDropdownOptions(
  Object.values(GDPRStatus)
).sort((a, b) => (a.label > b.label ? 1 : -1));

const contactPersonAssignmentStatesOptions = enumValuesToDropdownOptions(
  Object.values(ContactStatusEnum)
).sort((a, b) => (a.label > b.label ? 1 : -1));

const ContactListTable: React.FC<IProps> = ({
  // Props
  onDeleteOrRemove,
  showColumnToggler,
  // Redux State
  filters,
  showFilters,
  hasMore,
  listItems,
  sortBy,
  sortType,
  responsibles,
  involvedResponsibles,
  // Redux Actions
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  setHasMoreListItems,
  // Thunks
  fetchContactPersonInvolvedResponsibles,
  fetchContactPersonListItems,
  fetchContactPersonResponsibles,
  // Modal props
  modalErrorHandler,
}) => {
  const [columnListToShow, setColumnListToShow] = useState<string[]>([]);

  // Check for component mount
  const isMounted = useMounted();

  const handleDeleteOrRemove = (
    contactPersonListItem: IContactPersonListItem,
    action: string
  ) => {
    if (action === DELETE) {
      onDeleteOrRemove(contactPersonListItem, DELETE);
    } else {
      onDeleteOrRemove(contactPersonListItem, REMOVE);
    }
  };

  // Prepares the contact person list items to be displayed on the table.
  const preparedContactPersonListItems = listItems.map(
    (contactPersonListItem: IContactPersonListItem) =>
      createContactPersonEntry(contactPersonListItem, (action) =>
        handleDeleteOrRemove(contactPersonListItem, action)
      )
  );

  /**
   * Fetches the list of contact person list items.
   *
   * @param isSortOrFilterFetchType Whether the fetch is for sorting or filtering.
   */
  const fetchContactPersonList = (isSortOrFilterFetchType: boolean) => {
    fetchContactPersonListItems(isSortOrFilterFetchType, (error) => {
      modalErrorHandler(translate('failedToRetrieveContactPeople'), error);
    });
  };

  /**
   * Handles the change of a dropdown filter.
   *
   * @param filterValue The selected filter value.
   * @param fieldName The name of the field to filter on.
   */
  const handleDropdownFilterChange = (
    filterValue: IDropdownOption[],
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: filterValue,
    });
  };

  /**
   * Handles the change of a due date filter.
   *
   * @param event The event object for the due date change event.
   * @param fieldName The name of the field to filter on.
   */
  const handleDueDateFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: event.target.value
        ? new Date(event.target.value ?? '').toISOString()
        : null,
    });
  };

  /**
   * Handles the change of an input filter.
   *
   * @param event The event object for the input filter change event.
   * @param fieldName The name of the field to filter on.
   */
  const handleInputFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFilters({
      ...filters,
      [fieldName]: event.target.value ?? '',
    });
  };

  /**
   * Loads the specified columns into the table.
   *
   * @param newColumnList The list of columns to load.
   */
  const loadColumns = (newColumnList: string[]) => {
    setColumnListToShow([...newColumnList]);
  };

  /**
   * Handles the sorting of the contact person list.
   *
   * @param nextSortBy The next sort by value.
   */
  const handleSort = (nextSortBy: SortBy) => {
    const nextSortType = getNextSortType(sortType, nextSortBy, sortBy);
    setSortBy(
      nextSortType === SortType.DEFAULT ? SortBy.LAST_NAME : nextSortBy
    );
    setSortType(
      nextSortType === SortType.DEFAULT ? SortType.ASCENDING : nextSortType
    );
  };

  /**
   * Fetches the contact person list, responsibles, and involved responsibles if they are empty.
   */
  useEffect(() => {
    if (isEmpty(listItems)) {
      fetchContactPersonList(false);
    }

    if (isEmpty(responsibles)) {
      fetchContactPersonResponsibles((error) => {
        modalErrorHandler(translate('failedToRetrieveEmployees'), error);
      });
    }

    if (isEmpty(involvedResponsibles)) {
      fetchContactPersonInvolvedResponsibles((error) => {
        modalErrorHandler(translate('failedToRetrieveEmployees'), error);
      });
    }
  }, []);

  // Fetch contact person list on sort update
  useEffect(() => {
    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);
      fetchContactPersonList(true);
    }
  }, [sortBy, sortType]);

  // Fetch contact person list on filter update
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isMounted) {
      setPage(0);
      setHasMoreListItems(true);

      timer = setTimeout(() => {
        fetchContactPersonList(true);
      }, DEFAULT_LOAD_TIMEOUT);
    }

    return () => clearTimeout(timer);
  }, [filters]);

  const columnList: any[] = [
    {
      type: 'data',
      header: translate('menu'),
      accessor: 'menuComponent',
      width: buttonWidth,
      show: undefined,
    },
    {
      type: 'component',
      header: translate('lastName'),
      accessor: 'lastname',
      filterkey: 'lastname',
      showsearch: 'true',
      sortFunc: handleSort,
      filterFunc: handleInputFilterChange,
      filterComponentWidth: defaultFilterWidth,
      width: defaultWidth,
      show: undefined,
    },
    {
      type: 'data',
      header: translate('firstName'),
      accessor: 'firstname',
      filterkey: 'firstname',
      showsearch: 'true',
      sortFunc: handleSort,
      filterFunc: handleInputFilterChange,
      filterComponentWidth: defaultFilterWidth,
      width: defaultWidth,
      show: undefined,
    },
    {
      type: 'data',
      header: translate('account'),
      accessor: 'customerAccount',
      filterkey: 'customerAccount',
      showsearch: 'true',
      filterFunc: handleInputFilterChange,
      filterComponentWidth: '180px',
      width: '200px',
      filterValue: filters.customerAccount,
      show: undefined,
    },
    {
      type: 'component',
      header: translate('customer'),
      accessor: 'customerName',
      alignleft: 'true',
      filterkey: 'customerName',
      showsearch: 'true',
      sortFunc: handleSort,
      filterFunc: handleInputFilterChange,
      filterComponentWidth: defaultFilterWidth,
      width: defaultWidth,
      show: undefined,
    },
    {
      type: 'data',
      header: translate('customerSite'),
      accessor: 'customerSiteName',
      alignleft: 'true',
      filterkey: 'customerSiteName',
      showsearch: 'true',
      sortFunc: handleSort,
      filterFunc: handleInputFilterChange,
      filterComponentWidth: defaultFilterWidth,
      width: defaultWidth,
      show: undefined,
    },
    {
      type: 'data',
      header: translate('targetForCalling'),
      accessor: 'target',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: acquisitionOrTargetOptions,
      filterComponentWidth: '100px',
      width: '110px',
      filterValue: filters.target,
      show: undefined,
    },
    {
      type: 'data',
      header: translate('pendingAcquisition'),
      accessor: 'acquisition',
      alignleft: 'true',
      filterkey: 'acquisition',
      showsearch: 'true',
      sortFunc: handleSort,
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: acquisitionOrTargetOptions,
      filterComponentWidth: '90px',
      width: '110px',
      filterValue: filters.acquisition,
      show: undefined,
    },
    {
      type: 'data',
      header: translate('nextActivity'),
      accessor: 'nextAction',
      filterkey: 'nextAction',
      showsearch: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: nextActivityOptions,
      filterComponentWidth: '180px',
      width: '200px',
      filterValue: filters.nextAction,
      show: undefined,
    },
    {
      type: 'data',
      header: translate('dueDate'),
      accessor: 'dueDate',
      filterkey: 'dueDate',
      showsearch: 'true',
      alignleft: 'true',
      filterType: 'date',
      filterFunc: handleDueDateFilterChange,
      filterComponentWidth: '160px',
      width: '150px',
      filterValue: filters.dueDate,
      show: undefined,
    },
    {
      type: 'data',
      header: translate('responsible'),
      accessor: 'responsible',
      alignleft: 'true',
      filterkey: 'responsibleEntries',
      showsearch: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: responsibles,
      filterComponentWidth: '180px',
      width: '200px',
      filterValue: filters.responsible,
      show: undefined,
    },
    {
      type: 'component',
      header: translate('involved'),
      accessor: 'involvedEmployees',
      alignleft: 'true',
      filterkey: 'involvedEmployees',
      showsearch: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: involvedResponsibles,
      filterComponentWidth: '180px',
      width: '200px',
      filterValue: filters.involvedEmployees,
      show: undefined,
    },
    {
      type: 'data',
      header: translate('gdprStatus'),
      accessor: 'statusGdpr',
      filterkey: 'statusGdpr',
      showsearch: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: statusGdprOptions,
      filterComponentWidth: '90px',
      width: '90px',
      filterValue: filters.statusGdpr,
      show: undefined,
    },
    {
      type: 'data',
      header: translate('status'),
      accessor: 'assignmentState',
      filterkey: 'assignmentState',
      showsearch: 'true',
      filterFunc: handleDropdownFilterChange,
      filterType: 'dropdown',
      filterOptions: contactPersonAssignmentStatesOptions,
      filterComponentWidth: '150px',
      width: '150px',
      filterValue: filters.assignmentState,
      show: undefined,
    },
  ].map((column, index) => ({
    ...column,
    show: columnListToShow[index],
  }));

  return (
    <>
      <DynamicTableColumnControl
        columnList={columnList}
        columnReturn={loadColumns}
        toggleVisibility={showColumnToggler}
      />
      <DynamicTable
        data={preparedContactPersonListItems}
        columns={columnList}
        hasMoreData={hasMore}
        fetchData={fetchContactPersonList}
        infiniteScroll
        sortType={sortType}
        sortBy={sortBy}
        enableFilterFunction
        showFilters={showFilters}
      />
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  filters: store.contactPersonList.filters,
  showFilters: store.contactPersonList.showFilters,
  hasMore: store.contactPersonList.hasMore,
  listItems: store.contactPersonList.listItems,
  page: store.contactPersonList.page,
  sortBy: store.contactPersonList.sortBy,
  sortType: store.contactPersonList.sortType,
  responsibles: store.contactPersonList.additionalState?.responsibles,
  involvedResponsibles:
    store.contactPersonList.additionalState?.involvedResponsibles,
});
const mapDispatchToProps = {
  fetchContactPersonInvolvedResponsibles,
  fetchContactPersonListItems,
  fetchContactPersonResponsibles,
  setFilters,
  setPage,
  setSortBy,
  setSortType,
  setHasMoreListItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(ContactListTable)));
