import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Label,
  Button,
} from 'reactstrap';
import { AxiosError } from 'axios';

import OrderSummaryDetailsCard from './OrderSummaryDetailsCard';
import OrderInvoiceDetailsCard from './OrderInvoiceDetailsCard';
import { generateTitle } from '../../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../../utils/enums/pageComponents';
import { OrderSummaryContext } from './OrderSummaryProvider';
import withModals, {
  IWithModalsProps,
} from '../../../../utils/withModals';
import OrderedProjectModal from './OrderedProjectModal';
import { saveOrderDetails } from '../../../../services/api/order';
import { isNullOrUndefinedOrEmpty } from '../../../../utils/helpers/GenericHelper';
import {
  IOfferDetails,
  IOfferListItem,
} from '../../../../utils/types/responseTypes';
import { createOrderDetailsFromFormValues, translate } from './orderHelper';
import type { ProjectDetailFormValues } from '../../ProjectDetails/ProjectDetailOverview';

interface IProps extends IWithModalsProps {
  onClose: () => void;
  offersFromForms: IOfferListItem[];
  pendingOffersFromForms: IOfferListItem[];
  setProjectDetails: Dispatch<SetStateAction<ProjectDetailFormValues>>;
  fromProjectOption: boolean;
}

const OrderSummary = ({
  modalFormHandler,
  toggleModalForm,
  onClose,
  modalErrorHandler,
  offersFromForms,
  pendingOffersFromForms,
  setProjectDetails,
  fromProjectOption,
}: IProps) => {
  const { order, setOrder, formValues } = useContext(OrderSummaryContext);

  const {
    orderDate,
    orderedSum,
    orderNumber,
    paymentGoal,
    offerPartiallyOrdered,
    projectId,
    nameOfRecipient,
    address,
    zipCode,
    city,
    country,
    emailAddress,
    contractor,
    sendInvoiceBy,
    existingProject,
  } = formValues;

  const [isDisabled, setIsDisabled] = useState(true);

  const handleUpdateOffersListOnOrder = (offerId: number) => {
    if (!fromProjectOption) {
      // Get Object to put into offers
      const offerOrdered = pendingOffersFromForms.find(
        (offer) => offer.id === offerId
      );

      // Filter list to update lis of pending offers
      const updatedPendingOffers = pendingOffersFromForms.filter(
        (offer) => offer.id !== offerId
      );

      setProjectDetails((values) => ({
        ...values,
        offers: [...offersFromForms, offerOrdered ?? ({} as IOfferDetails)],
        pendingOffers: updatedPendingOffers,
      }));
    }
  };

  const saveOrder = () => {
    const updatedOrder = createOrderDetailsFromFormValues(order, formValues);

    setOrder(updatedOrder);

    saveOrderDetails(updatedOrder)
      .then(() => {
        handleUpdateOffersListOnOrder(updatedOrder.offerId);
        onClose();
      })
      .catch((error: AxiosError) =>
        modalErrorHandler(translate('serverFailed'), error)
      );
  };

  const handleSubmitButton = () => {
    if (!existingProject) {
      modalFormHandler(
        '',
        <OrderedProjectModal
          saveOrder={saveOrder}
          toggleModal={toggleModalForm}
        />,
        'xs'
      );
    } else {
      saveOrder();
    }
  };

  /**
   * Effect to set button to active once all required inputs have data
   */
  useEffect(() => {
    if (
      (isNullOrUndefinedOrEmpty(orderDate) ||
        isNullOrUndefinedOrEmpty(orderedSum) ||
        isNullOrUndefinedOrEmpty(orderNumber) ||
        isNullOrUndefinedOrEmpty(paymentGoal) ||
        isNullOrUndefinedOrEmpty(offerPartiallyOrdered) ||
        isNullOrUndefinedOrEmpty(nameOfRecipient) ||
        isNullOrUndefinedOrEmpty(projectId) ||
        isNullOrUndefinedOrEmpty(address) ||
        isNullOrUndefinedOrEmpty(zipCode) ||
        isNullOrUndefinedOrEmpty(city) ||
        isNullOrUndefinedOrEmpty(country)) &&
      !existingProject
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [
    orderDate,
    orderedSum,
    orderNumber,
    paymentGoal,
    offerPartiallyOrdered,
    projectId,
    nameOfRecipient,
    address,
    zipCode,
    city,
    country,
    emailAddress,
    contractor,
    sendInvoiceBy,
    existingProject,
  ]);

  return (
    <Card style={{ border: '0', boxShadow: 'center' }}>
      <CardHeader>
        <CardTitle style={{ textAlign: 'center' }}>
          <h3>
            <div>{translate('congratsOrderReceived')}</div>
            <div style={{ fontSize: 'smaller' }}>
              {translate('pleaseVerifyInputs')}
            </div>
          </h3>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <OrderSummaryDetailsCard />
        </Row>
        <Row>
          <OrderInvoiceDetailsCard />
        </Row>
        <Row className="text-danger align-items-center">
          <Col>
            <Label className="mx-auto">
              {translate('acceptedOfferAnnouncementDialogue')}
            </Label>
          </Col>
          <Col className="exclamation-color" md="auto">
            {generateTitle(BUTTON_TITLE_ENUM.WARNING.code)}
          </Col>
        </Row>
        <Row className="centered-div">
          <Button
            color="primary"
            className="continue-and-cancel-button"
            onClick={handleSubmitButton}
            disabled={isDisabled}
          >
            {translate('yesAllInputsAreCorrect')}
          </Button>
          <Button
            color="primary"
            className="continue-and-cancel-button"
            onClick={onClose}
          >
            {translate('cancel')}
          </Button>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withModals(OrderSummary);
