import { IFileAttachment, IFileAttachmentListItem } from '../types/modelTypes';

/**
 * Generate link base on base64 string for downloading of file
 * @param {*} file IFileAttachmentListItem
 * @returns link
 */
export const generateFileAttachmentLink = ({
  fileObject,
  fileObjectContentType,
}: IFileAttachmentListItem) => {
  const binary = atob(fileObject.replace(/\s/g, ''));
  const buffer = new ArrayBuffer(binary.length);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < binary.length; i += 1) {
    view[i] = binary.charCodeAt(i);
  }

  const blob = new Blob([view], { type: fileObjectContentType ?? '' });
  return window.URL.createObjectURL(blob);
};

/**
 * Converts file attachment to file attachment list item
 * @param {*} file IFileAttachment object
 * @returns IFileAttachmentListItem object
 */
export const convertToFileAttachmentListItem = ({
  id,
  fileName,
  description,
  fileObject,
  fileObjectContentType,
}: IFileAttachment) =>
  ({
    id,
    fileName,
    description,
    fileObject,
    fileObjectContentType,
  } as IFileAttachmentListItem);
