/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Moment } from 'moment';

import { IFileAttachmentListItem } from '../../../utils/types/modelTypes';
import {
  IDropdownOption,
  IProjectDetailOffer,
  IProjectInvolvedResponsible,
} from '../../../utils/types/commonTypes';
import type { RootState } from '../../../redux/store';
import { IOfferDetails, IOfferListItem } from '../../../utils/types/responseTypes';
import type {
  ProjectOptionDetailsForm,
} from '../../salesFunnel/ProjectOptionModal/ProjectOptionModal';
import {
  generateDefaultFormValues,
  generateDefaultOfferValues,
} from './offerHelper';
import type { ProjectDetailFormValues } from '../ProjectDetails/ProjectDetailOverview';

export type OfferDetailsForm = {
  title: string;
  contractType: string;
  currency: string;
  budget: number;
  date: Moment | string;
  paymentGoal: number;
  validityPeriod: number;
  detail: string;
  responsible: IDropdownOption<number>;
  involved: IProjectInvolvedResponsible[];
};

type OfferDetailsContextType = {
  isProjectOption?: boolean;

  offer: IOfferDetails;
  setOffer: Dispatch<SetStateAction<IOfferDetails>>;
  project: IProjectDetailOffer;

  offersFromForms: IOfferListItem[];
  setProjectOptions?: Dispatch<SetStateAction<ProjectOptionDetailsForm>>;
  pendingOfferFromForms?: IOfferListItem[];
  setProjectDetails?: Dispatch<SetStateAction<ProjectDetailFormValues>>;
  // DropdownList
  responsibleList: IDropdownOption<number>[];
  setResponsibleList: Dispatch<SetStateAction<IDropdownOption<number>[]>>;
  responsibleRoleList: IDropdownOption<number>[];
  setResponsibleRoleList: Dispatch<SetStateAction<IDropdownOption<number>[]>>;

  // Offer Details
  formValues: OfferDetailsForm;
  setFormValues: Dispatch<SetStateAction<OfferDetailsForm>>;

  // File Attachments
  fileAttachments: IFileAttachmentListItem[];
  setFileAttachments: Dispatch<SetStateAction<IFileAttachmentListItem[]>>;
};

export const OfferDetailsContext = createContext({} as OfferDetailsContextType);

interface IProps extends PropsFromRedux {
  children: ReactNode;
  offerProject: IProjectDetailOffer;
  // Used for ProjectOption and ProjectDetail
  formOffers: IOfferListItem[];
  // Used for ProjectDetail only
  formPendingOffers?: IOfferListItem[];
  setOptionFormValues?: Dispatch<SetStateAction<ProjectOptionDetailsForm>>;
  setDetailFormValues?: Dispatch<SetStateAction<ProjectDetailFormValues>>;
  fromOptions?: boolean;
}

const OfferDetailsProvider = ({
  children,
  offerProject,
  account,
  formOffers,
  formPendingOffers,
  setOptionFormValues,
  setDetailFormValues,
  fromOptions,
}: IProps) => {
  const project = offerProject;
  const offersFromForms = formOffers;
  const pendingOfferFromForms = formPendingOffers ?? [];
  const setProjectOptions = setOptionFormValues ?? (() => null);
  const setProjectDetails = setDetailFormValues ?? (() => null);
  const isProjectOption = fromOptions ?? false;
  // Dropdown Lists
  const [responsibleList, setResponsibleList] = useState(
    [] as IDropdownOption<number>[]
  );
  const [responsibleRoleList, setResponsibleRoleList] = useState(
    [] as IDropdownOption<number>[]
  );

  // Offer Details
  const [formValues, setFormValues] = useState<OfferDetailsForm>(
    generateDefaultFormValues(account, project)
  );

  // Offer File Attachments
  const [fileAttachments, setFileAttachments] = useState(
    [] as IFileAttachmentListItem[]
  );

  const [offer, setOffer] = useState(generateDefaultOfferValues(project));

  return (
    <OfferDetailsContext.Provider
      value={{
        isProjectOption,
        offer,
        setOffer,
        project,
        offersFromForms,
        pendingOfferFromForms,
        setProjectOptions,
        setProjectDetails,
        // Dropdown Lists
        responsibleList,
        setResponsibleList,
        responsibleRoleList,
        setResponsibleRoleList,
        // Offer Details
        formValues,
        setFormValues,
        fileAttachments,
        setFileAttachments,
      }}
    >
      {children}
    </OfferDetailsContext.Provider>
  );
};

const mapStateToProps = (store: RootState) => ({
  account: store.account,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OfferDetailsProvider);
