import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Card, CardBody, Container } from 'reactstrap';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { translate } from './customerSiteDetailsHelper';
import CollapsibleActivityCard from './CollapsibleActivityCard';
import CollapsibleFileAttachmentCard from './CollapsibleFileAttachmentCard';
import CustomerSiteCard from './CustomerSiteCard';
import CustomerSiteDetailsContextProvider from './CustomerSiteDetailsContextProvider';

const CustomerSiteDetail = () => {
  const { pathname } = useLocation();

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{translate('customerSiteDetails')}</HeaderTitle>
        {generateBreadcrumb(pathname, translate('customer'))}
      </Header>
      <CustomerSiteDetailsContextProvider>
        <CustomerSiteCard />
        <Card>
          <CardBody>
            <CollapsibleFileAttachmentCard />
          </CardBody>
          <CardBody>
            <CollapsibleActivityCard />
          </CardBody>
        </Card>
        <Link to="/customer/customers">
          <Button color="primary" size="m">
            {translate('backToList')}
          </Button>
        </Link>
      </CustomerSiteDetailsContextProvider>
    </Container>
  );
};

export default CustomerSiteDetail;
