import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { getSiteName } from '../../utils/helpers/GenericHelper';
import i18n from '../../i18n';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import { ICustomerSite } from '../../utils/types/modelTypes';
import { IDropdownOption } from '../../utils/types/commonTypes';
import { sortOptionsByValue } from '../../utils/helpers/dropdown';
import { getCustomerSites } from '../../services/api/customerSite';

interface IProps extends IWithModalsProps {
  customerSiteId: number;
  customerId: number;
  onChange: (customerSite: ICustomerSite) => void;
}

/**
 * Dropdown that has all CustomerSites as options.\
 * Variable props:
 *  - customerSiteId - ID of the chosen customer site
 *  - customerId - ID of chosen customer
 *  - onChange - handle changes made in the chosen customer site
 */
const CustomerSitesDropdown = ({
  customerSiteId,
  customerId,
  onChange,
  modalErrorHandler,
}: IProps) => {
  const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[]>([]);
  const [customerSites, setCustomerSites] = useState<ICustomerSite[]>([]);

  const t = (keyName: string) => i18n.t(`CustomerSitesDropdown.${keyName}`);

  const createDefaultSiteName = (customerSite: ICustomerSite | undefined) => {
    if (!customerSite?.name) {
      let newCustomerSiteName = '';
      if (customerSite?.customer?.name) {
        newCustomerSiteName += `${customerSite.customer.name} - `;
      }
      newCustomerSiteName += `Site ID: ${customerSite?.id}`;
      return newCustomerSiteName;
    }

    return getSiteName(customerSite);
  };

  const fetchCustomerSites = () => {
    getCustomerSites({
      'customerId.equals': customerId.toString(),
    })
      .then(({ data }) => {
        if (Array.isArray(data)) {
          setCustomerSites(data);
          setDropdownOptions(
            data.map((customerSite) => ({
              value: customerSite.id?.toString() ?? '',
              label: createDefaultSiteName(customerSite),
            }))
          );
        }
      })
      .catch((error) => {
        modalErrorHandler(t('failedToRetrieveSites'), error);
      });
  };

  const onChangeHandler = ({ value }: IDropdownOption) => {
    const customerSite = customerSites.find(
      ({ id }) => id === parseInt(value, 10)
    );
    if (customerSite) {
      onChange(customerSite);
    }
  };

  useEffect(() => {
    fetchCustomerSites();
  }, []);

  useEffect(() => {
    if (customerId != null) {
      fetchCustomerSites();
    }
  }, [customerId, customerSiteId]);

  return (
    <div
      style={{ overflow: 'visible' }}
      data-testid="customer-site-dropdown-div"
    >
      <Select
        value={
          dropdownOptions.find(
            (customerSite) => customerSite.value === customerSiteId?.toString()
          ) ?? t('selectLocation')
        }
        options={sortOptionsByValue(dropdownOptions)}
        placeholder={t('selectLocation')}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default withModals(CustomerSitesDropdown);
