import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Container } from 'reactstrap';
import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { SERVICE_CONFIG } from "../../../utils/enums/service";
import { handleError, isEmpty } from '../../../utils/helpers/GenericHelper';
import {
  generateTitle,
} from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import UpdatePrioritizationTargetGoal from './UpdatePrioritizationTargetGoal';


/**
 * @deprecated For new DTOs, use function in tableHelper.tsx
 */
function generateCustomerSiteTooltip(customerSite) {
  return (
    <span>
      {`${i18n.t('GenericHelper.status')}: ${
        customerSite?.customerSiteState
          ? i18n.t(`CustomerStates.${customerSite.customerSiteState}`)
          : 'N/A'
      }`}
      <br />
      {`${i18n.t('GenericHelper.type')}: ${
        customerSite?.customerSiteType ?? 'N/A'
      }`}
      <br />
      {`${i18n.t('GenericHelper.street')}: ${customerSite?.street ?? 'N/A'}`}
      <br />
      {`${i18n.t('GenericHelper.location')}: ${
        customerSite?.location ?? 'N/A'
      }`}
      <br />
      {`${i18n.t('GenericHelper.zipCode')}: ${customerSite?.zipCode ?? 'N/A'}`}
      <br />
      {`${i18n.t('GenericHelper.countryState')}: ${
        customerSite?.countryState?.name ?? 'N/A'
      }`}
      <br />
      {`${i18n.t('GenericHelper.country')}: ${
        customerSite?.country?.name ?? 'N/A'
      }`}
      <br />
    </span>
  );
}

/**
 * Class for showing the Prioritization Target Goal in the Sales settings
 */
class PrioritizationTargetGoal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priorityGoals: [],
      showModalForm: false,
      showModalError: false,
      modalForm: null,
    };
  }

  t(keyName) {
    return i18n.t('PrioritizationTargetGoal.' + keyName);
  }

  /**
   * Method that toggles the ModalError to show up or not
   */
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  /**
   * Method that toggles the ModalForm to show up or not
   */
  toggleModalForm = () => {
    this.setState({ showModalForm: !this.state.showModalForm });
  };

  /**
   * Handles the error
   * @param {*} mainError
   * @param {*} errorObject
   */
  handleError = (mainError, errorObject) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  componentDidMount = async () => {
    // Get the ServiceConfiguration's Priority Target Goal
    let priorityGoalsData = await axios.serviceConfiguration
      .get(
        `?service.equals=${SERVICE_CONFIG.prioritizationTargetGoal.service}&configKey.equals=${SERVICE_CONFIG.prioritizationTargetGoal.key}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let mainError = this.t('retrieveTargetPriorityFailed');
        this.handleError(mainError, error);
      });

    if (!isEmpty(priorityGoalsData)) {
      this.setState({
        priorityGoals: priorityGoalsData,
      });
    }
  };

  /**
   * Method that prepares the data needed for the Dynamic Table
   * @param {*} priorityGoals
   * @returns
   */
  prepareTableData = (priorityGoals) => {
    const newTableData = [];
    if (!isEmpty(priorityGoals)) {
      priorityGoals.forEach((priorityGoal) => {
        const parsedPriorityGoal = JSON.parse(priorityGoal.configValue);
        const entry = {
          id: priorityGoal?.id,
          service: priorityGoal?.service,
          configKey: priorityGoal?.configKey,
          configValue: priorityGoal?.configValue,
          highPriorityTarget: parsedPriorityGoal?.highPriority + '%',
          midPriorityTarget: parsedPriorityGoal?.midPriority + '%',
          lowPriorityTarget: parsedPriorityGoal?.lowPriority + '%',
          noPriorityTarget: parsedPriorityGoal?.noPriority + '%',
          tbdPriorityTarget: parsedPriorityGoal?.tbdPriority + '%',
          editButton: (
            <>
              <Button
                color="primary"
                onClick={() => this.updatePriorityTargetGoal(priorityGoal)}
              >
                {generateTitle(BUTTON_TITLE_ENUM.EDIT.code)}
              </Button>
            </>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  /**
   * Method that calls the UpdatePrioritizationTargetGoal to edit the Priority Target Goal
   * @param {*} priorityGoal
   */
  updatePriorityTargetGoal = (priorityGoal) => {
    this.setState({
      modalForm: (
        <UpdatePrioritizationTargetGoal
          priorityGoal={priorityGoal}
          onSave={this.onSave}
        />
      ),
    });
    this.toggleModalForm();
  };

  /**
   * Method that saves the priorityGoal
   * @param {*} priorityGoal
   */
  onSave = (priorityGoal) => {
    let priorityGoals = this.state.priorityGoals;
    let index = priorityGoals.findIndex(
      (item) => item?.id === priorityGoal?.id
    );
    if (index >= 0) {
      priorityGoals[index] = priorityGoal;
    } else {
      priorityGoals.push(priorityGoal);
    }
    priorityGoals = priorityGoals?.sort((a, b) =>
      a?.configValue > b?.configValue ? 1 : -1
    );
    this.setState({ priorityGoals: priorityGoals });
    if (this.state.showModalForm) {
      this.toggleModalForm();
    }
  };

  render() {
    const priorityGoals = this.state.priorityGoals;
    const preparedColumns = [
      {
        type: 'component',
        header: this.t('highPriority'),
        accessor: 'highPriorityTarget',
        show: 'true',
      },
      {
        type: 'component',
        header: this.t('midPriority'),
        accessor: 'midPriorityTarget',
        show: 'true',
      },
      {
        type: 'component',
        header: this.t('lowPriority'),
        accessor: 'lowPriorityTarget',
        show: 'true',
      },
      {
        type: 'component',
        header: this.t('noPriority'),
        accessor: 'noPriorityTarget',
        show: 'true',
      },
      {
        type: 'component',
        header: this.t('tbdPriority'),
        accessor: 'tbdPriorityTarget',
        show: 'true',
      },
      {
        type: 'component',
        header: ' ',
        accessor: 'editButton',
        show: 'true',
      },
    ];
    return (
      <Container fluid>
        <DynamicTable
          data={this.prepareTableData(priorityGoals)}
          columns={preparedColumns}
          infiniteScroll
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={this.t('error')}
        ></ModalError>
        <ModalForm
          isOpen={this.state.showModalForm}
          eventOnClose={this.toggleModalForm}
          ref={this.state.modalForm}
          modalTitle={this.t('prioritizationTargetGoal') + ' (%)'}
        >
          {this.state.modalForm}
        </ModalForm>
      </Container>
    );
  }
}

export default PrioritizationTargetGoal;
