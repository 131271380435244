import {
  PERMISSION_CAMPAIGN_MANAGEMENT,
  PERMISSION_RESOURCE_PLANNER,
  PermissionAbsences,
  PermissionAdmin,
  PermissionCrm,
  PermissionData,
  PermissionEmployees,
  PermissionFinance,
  PermissionProject,
  PermissionSales,
  PermissionTimeTracking,
  READ,
  READWRITE,
} from '../constants';
import i18n from '../../i18n';

export enum AccessType {
  READWRITE = 'READWRITE',
  READ = 'READ',
}

export const PERMISSION_URI = {
  adminAccess: {
    readWrite: {
      label: 'Admin Access',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: '*',
      description: i18n.t('PermissionDescriptions.adminAccess'),
      section: PermissionAdmin,
    },
  },
  campaignManagementAccess: {
    readWrite: {
      label: 'Campaign Management Access',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.campaignManagementAccess',
      description: i18n.t('PermissionDescriptions.campaignManagementAccess'),
      section: PERMISSION_CAMPAIGN_MANAGEMENT,
    },
  },
  timeTracking: {
    readWrite: {
      label: 'Display Timetracking',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.timeTracking.timeTracking',
      description: i18n.t('PermissionDescriptions.displayTimeTracking'),
      section: PermissionTimeTracking,
    },
  },
  timeTrackingApprovalList: {
    readWrite: {
      label: 'Display Timetracking Approval List',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.timeTracking.approvalList',
      description: i18n.t(
        'PermissionDescriptions.displayTimeTrackingApprovalList'
      ),
      section: PermissionTimeTracking,
    },
  },
  azkOverview: {
    readWrite: {
      label: 'Display AZK Overview',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.timeTracking.azkOverview',
      description: i18n.t('PermissionDescriptions.displayAzkOverview'),
      section: PermissionTimeTracking,
    },
  },
  fullCustomerAccountList: {
    read: {
      label: 'Display Full Customer Account List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.fullCustomerAccountList',
      description: i18n.t(
        'PermissionDescriptions.displayFullCustomerAccountList'
      ),
      section: PermissionCrm,
    },
  },
  customerAccountList: {
    read: {
      label: 'Display Own Customer Account List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerAccountList',
      description: i18n.t(
        'PermissionDescriptions.displayOwnCustomerAccountList'
      ),
      section: PermissionCrm,
    },
    readWrite: {
      label: 'Create Customer Accounts',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerAccountList',
      description: i18n.t('PermissionDescriptions.createCustomerAccounts'),
      section: PermissionCrm,
    },
  },
  responsibleHandoverNotificationForCustomer: {
    readWrite: {
      label:
        'Informed when Customer or Site Responsible handover is done within Account',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.responsibleHandoverNotificationForCustomer',
      description: i18n.t(
        'PermissionDescriptions.responsibleHandoverNotificationForCustomer'
      ),
      section: PermissionCrm,
    },
  },
  fullCustomerList: {
    read: {
      label: 'Display Full Customer List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.fullCustomerList',
      description: i18n.t('PermissionDescriptions.displayFullCustomerList'),
      section: PermissionCrm,
    },
  },
  customerList: {
    read: {
      label: 'Display Own Customer List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerList',
      description: i18n.t('PermissionDescriptions.displayOwnCustomerList'),
      section: PermissionCrm,
    },
    readWrite: {
      label: 'Create Customers',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerList',
      description: i18n.t('PermissionDescriptions.createCustomers'),
      section: PermissionCrm,
    },
  },
  responsibleHandoverNotificationForCustomerSite: {
    readWrite: {
      label:
        'Informed when Customer Site Responsible handover is done within Customer',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.responsibleHandoverNotificationForCustomerSite',
      description: i18n.t(
        'PermissionDescriptions.responsibleHandoverNotificationForCustomerSite'
      ),
      section: PermissionCrm,
    },
  },
  fullCustomerSiteList: {
    read: {
      label: 'Display Full Customer Site List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.fullCustomerSiteList',
      description: i18n.t('PermissionDescriptions.displayFullCustomerSiteList'),
      section: PermissionCrm,
    },
  },
  customerSiteList: {
    read: {
      label: 'Display Own Customer Site List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerSiteList',
      description: i18n.t('PermissionDescriptions.displayOwnCustomerSiteList'),
      section: PermissionCrm,
    },
    readWrite: {
      label: 'Create Customer Sites',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerSiteList',
      description: i18n.t('PermissionDescriptions.createCustomerSites'),
      section: PermissionCrm,
    },
  },
  fullContactPeopleList: {
    read: {
      label: 'Display Full Contact People List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.fullContactPeopleList',
      description: i18n.t(
        'PermissionDescriptions.displayFullContactPeopleList'
      ),
      section: PermissionCrm,
    },
  },
  contactPeopleList: {
    read: {
      label: 'Display Own Contact People List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.contactPeopleList',
      description: i18n.t('PermissionDescriptions.displayOwnContactPeopleList'),
      section: PermissionCrm,
    },
    readWrite: {
      label: 'Create Contact People',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.contactPeopleList',
      description: i18n.t('PermissionDescriptions.createContactPeople'),
      section: PermissionCrm,
    },
  },
  campaignManager: {
    readWrite: {
      label: 'Display Campaign Manager',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.campaignManager',
      description: i18n.t('PermissionDescriptions.displayCampaignManager'),
      section: PermissionCrm,
    },
  },
  gdprOverview: {
    readWrite: {
      label: 'Display GDPR Overview',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.gdprOverview',
      description: i18n.t('PermissionDescriptions.displayGdprOverview'),
      section: PermissionCrm,
    },
  },
  callingStats: {
    read: {
      label: 'Display Calling Stats for all Sales Managers',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.salesManagerCallingStats',
      description: i18n.t(
        'PermissionDescriptions.displaySalesManagerCallingStats'
      ),
      section: PermissionCrm,
    },
  },
  myCallingStats: {
    read: {
      label: 'Display My Calling Stats',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.myCallingStats',
      description: i18n.t('PermissionDescriptions.displayMyCallingStats'),
      section: PermissionCrm,
    },
  },
  globalCallingStats: {
    read: {
      label: 'Display Global Calling Stats',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.globalCallingStats',
      description: i18n.t('PermissionDescriptions.displayGlobalCallingStats'),
      section: PermissionCrm,
    },
  },
  customerTargetPriorityAccess: {
    readWrite: {
      label: 'Edit Customer Target Priority',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.customerTargetPriorityAccess',
      description: i18n.t(
        'PermissionDescriptions.customerTargetPriorityAccess'
      ),
      section: PermissionCrm,
    },
  },
  responsibleHandoverNotification: {
    readWrite: {
      label:
        'Informed when Customer or Site Responsible handover is done within INV',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.customer.responsibleHandoverNotification',
      description: i18n.t(
        'PermissionDescriptions.responsibleHandoverNotification'
      ),
      section: PermissionCrm,
    },
  },
  sales: {
    read: {
      label: 'Read Sales Data',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.sales',
      description: i18n.t('PermissionDescriptions.readSalesData'),
      section: PermissionCrm,
    },
    readWrite: {
      label: 'Access Sales Data',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.sales',
      description: i18n.t('PermissionDescriptions.accessSalesData'),
      section: PermissionCrm,
    },
  },
  projectOptions: {
    read: {
      label: 'Display Project Options List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.project.projectOptionList',
      description: i18n.t('PermissionDescriptions.displayProjectOptionsList'),
      section: PermissionSales,
    },
    readWrite: {
      label: 'Create Project Options',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.project.projectOption',
      description: i18n.t('PermissionDescriptions.createProjectOptions'),
      section: PermissionSales,
    },
  },
  resourceOverview: {
    read: {
      label: 'Display Resource Overview',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.resource',
      description: i18n.t('PermissionDescriptions.viewResources'),
      section: PERMISSION_RESOURCE_PLANNER,
    },
  },
  resourcePlannerAppear: {
    readWrite: {
      label: 'Appear in Resource Planner',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.resourcePlannerAppearance',
      description: i18n.t('PermissionDescriptions.resourcePlannerAppearance'),
      section: PERMISSION_RESOURCE_PLANNER,
    },
  },
  assignedToProjects: {
    readWrite: {
      label: 'Become Assigned to Projects/Project Options',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.assignedToProjects',
      description: i18n.t('PermissionDescriptions.assignedToProjects'),
      section: PERMISSION_RESOURCE_PLANNER,
    },
  },
  assignResourcesToProjects: {
    readWrite: {
      label: 'Assign Resources to owned Projects',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.assignResourcesToProjects',
      description: i18n.t('PermissionDescriptions.assignResourcesToProjects'),
      section: PERMISSION_RESOURCE_PLANNER,
    },
  },
  assignResourcesToProjectOptions: {
    readWrite: {
      label: 'Assign Resources to owned Project Options',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.assignResourcesToProjectOptions',
      description: i18n.t(
        'PermissionDescriptions.assignResourcesToProjectOptions'
      ),
      section: PERMISSION_RESOURCE_PLANNER,
    },
  },
  assignResources: {
    readWrite: {
      label: 'Assign Resources to all Projects and Project Options',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.assignResources',
      description: i18n.t('PermissionDescriptions.assignResources'),
      section: PERMISSION_RESOURCE_PLANNER,
    },
  },
  projectList: {
    read: {
      label: 'Display Full Project List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.projectList',
      description: i18n.t('PermissionDescriptions.displayProjectList'),
      section: PermissionProject,
    },
    readWrite: {
      label: 'Create Projects',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.projectList',
      description: i18n.t('PermissionDescriptions.createProjects'),
      section: PermissionProject,
    },
  },
  internalProject: {
    readWrite: {
      label: 'Create Internal Projects',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.internalProject',
      description: i18n.t('PermissionDescriptions.createInternalProjects'),
      section: PermissionProject,
    },
  },
  internalProjectApproval: {
    readWrite: {
      label: 'Approve Budget for Internal Projects',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.internalProjectApproval',
      description: i18n.t('PermissionDescriptions.internalProjectApproval'),
      section: PermissionProject,
    },
  },
  becomeProjectOwner: {
    readWrite: {
      label: 'Become Project Owner',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.becomeProjectOwner',
      description: i18n.t('PermissionDescriptions.becomeProjectOwner'),
      section: PermissionProject,
    },
  },
  becomeProjectManager: {
    readWrite: {
      label: 'Become Project Manager',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.becomeProjectManager',
      description: i18n.t('PermissionDescriptions.becomeProjectManager'),
      section: PermissionProject,
    },
  },
  projectBudget: {
    read: {
      label: 'Project Budget Permission',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.projectBudget',
      description: i18n.t('PermissionDescriptions.projectBudgetPermission'),
      section: PermissionProject,
    },
  },
  manageDepartments: {
    readWrite: {
      label: 'Manage Departments',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.project.manageDepartments',
      description: i18n.t('PermissionDescriptions.manageDepartments'),
      section: PermissionProject,
    },
  },
  controllingList: {
    read: {
      label: 'Display Controlling List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.finance.controllingList',
      description: i18n.t('PermissionDescriptions.displayControllingList'),
      section: PermissionFinance,
    },
  },
  finance: {
    read: {
      label: 'Finance Permission',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.finance',
      description: i18n.t('PermissionDescriptions.financePermission'),
      section: PermissionFinance,
    },
  },
  employeeList: {
    read: {
      label: 'Display Full Employee List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.employeeList',
      description: i18n.t('PermissionDescriptions.displayEmployeeList'),
      section: PermissionEmployees,
    },
  },
  fullEmployeeDetails: {
    read: {
      label: 'Display Full Employee Details',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.fullEmployeeDetails',
      description: i18n.t('PermissionDescriptions.displayFullEmployeeDetails'),
      section: PermissionEmployees,
    },
  },
  employeeManagement: {
    read: {
      label: 'Display Employee Management',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.management',
      description: i18n.t('PermissionDescriptions.displayEmployeeManagement'),
      section: PermissionEmployees,
    },
    readWrite: {
      label: 'Manage Employees',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.management',
      description: i18n.t('PermissionDescriptions.manageEmployees'),
      section: PermissionEmployees,
    },
  },
  employeeManageMyEmployees: {
    readWrite: {
      label: 'Manage My Employees',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.manageMyEmployees',
      description: i18n.t('PermissionDescriptions.manageMyEmployees'),
      section: PermissionEmployees,
    },
  },
  employeeMyTeam: {
    read: {
      label: 'displayMyTeam',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.myTeam',
      description: i18n.t('PermissionDescriptions.displayEmployeeMyTeam'),
      section: PermissionEmployees,
    },
  },
  employeeApprovalsList: {
    read: {
      label: 'Display Employee Approval List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.approvalList',
      description: i18n.t('PermissionDescriptions.displayEmployeeApprovalList'),
      section: PermissionEmployees,
    },
  },
  employeeAsResponsible: {
    readWrite: {
      label: 'Be in choice for INV-Responsible',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.employee.employeeAsResponsible',
      description: i18n.t('PermissionDescriptions.employeeAsResponsible'),
      section: PermissionEmployees,
    },
  },
  absenceApprovalsList: {
    read: {
      label: 'Display Absence Approval List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.absence.approvalList',
      description: i18n.t('PermissionDescriptions.displayAbsenceApprovalList'),
      section: PermissionAbsences,
    },
  },
  teamAbsencesList: {
    read: {
      label: 'Display Team Absences List',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.absence.teamAbsencesList',
      description: i18n.t('PermissionDescriptions.displayTeamAbsencesList'),
      section: PermissionAbsences,
    },
  },
  absenceApprovalsResponsibleList: {
    readWrite: {
      label: 'Approve Absences of responsible',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.absence.approvalListResponsible',
      description: i18n.t(
        'PermissionDescriptions.displayAbsenceApprovalListResponsible'
      ),
      section: PermissionAbsences,
    },
  },
  absenceStatistics: {
    read: {
      label: 'Read Absence Statistics',
      accessType: READ,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.action.absence.absenceStatistics',
      description: i18n.t('PermissionDescriptions.readAbsenceStatistics'),
      section: PermissionAbsences,
    },
  },
  adminDataAccess: {
    readWrite: {
      label: 'Admin Access',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: '*',
      description: i18n.t('PermissionDescriptions.adminAccess'),
      section: PermissionData,
    },
  },
  employee: {
    readWrite: {
      label: 'Employee Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.Employee',
      description: i18n.t('PermissionDescriptions.readWriteAllEmployeeData'),
      section: PermissionData,
    },
  },
  employeeGroup: {
    readWrite: {
      label: 'Employee Group Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.EmployeeGroup',
      description: i18n.t(
        'PermissionDescriptions.readWriteAllEmployeeGroupData'
      ),
      section: PermissionData,
    },
  },
  project: {
    readWrite: {
      label: 'Project Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.Project',
      description: i18n.t('PermissionDescriptions.readWriteAllProjectData'),
      section: PermissionData,
    },
  },
  customerAccount: {
    readWrite: {
      label: 'Customer Account Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.CustomerAccount',
      description: i18n.t(
        'PermissionDescriptions.readWriteAllCustomerAccountData'
      ),
      section: PermissionData,
    },
  },
  customer: {
    read: {
      label: 'Customer Read Permission',
      accessType: READ,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.Customer',
      description: i18n.t('PermissionDescriptions.readAllCustomerData'),
      section: PermissionData,
    },
    readWrite: {
      label: 'Customer ReadWrite Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.Customer',
      description: i18n.t('PermissionDescriptions.readWriteAllCustomerData'),
      section: PermissionData,
    },
  },
  customerSite: {
    readWrite: {
      label: 'Customer Site Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.CustomerSite',
      description: i18n.t(
        'PermissionDescriptions.readWriteAllCustomerSiteData'
      ),
      section: PermissionData,
    },
  },
  contactPerson: {
    readWrite: {
      label: 'Contact Person Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.ContactPerson',
      description: i18n.t(
        'PermissionDescriptions.readWriteAllContactPersonData'
      ),
      section: PermissionData,
    },
  },
  timetracking: {
    readWrite: {
      label: 'Timetracking Permission',
      accessType: READWRITE,
      entityType: 'DATA',
      uri: 'com.invensity.itwcrm.domain.Timetracking',
      description: i18n.t(
        'PermissionDescriptions.readWriteAllTimetrackingData'
      ),
      section: PermissionData,
    },
  },
  approval: {
    readWrite: {
      label: 'Approval Requests',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.domain.ApprovalRequest',
      description: i18n.t('PermissionDescriptions.approvalRequest'),
      section: PermissionData,
    },
  },
  offer: {
    readWrite: {
      label: 'Offer Permission',
      accessType: READWRITE,
      entityType: 'ACTION',
      uri: 'com.invensity.itwcrm.domain.Offer',
      descriptiom: i18n.t('PermissionDescriptions.createOffers'),
      sectiom: PermissionData,
    },
  },
};
