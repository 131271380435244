import {
  faInfoCircle,
  faExclamationTriangle,
  faPlus,
  faTrash,
  faEdit,
  faExclamationCircle,
  faSave,
  faWindowClose,
  faUpload,
  faUndo,
  faPen,
  faTimes,
  faEnvelope,
  faSync,
} from '@fortawesome/free-solid-svg-icons';

export const BUTTON_TITLE_ENUM = {
  INFORMATION: { code: 'INFORMATION', icon: faInfoCircle },
  WARNING: { code: 'WARNING', icon: faExclamationTriangle },
  ADD: { code: 'ADD', icon: faPlus },
  DELETE: { code: 'DELETE', icon: faTrash },
  UPDATE: { code: 'UPDATE', icon: faEdit },
  ERROR: { code: 'ERROR', icon: faExclamationCircle },
  SAVE: { code: 'SAVE', icon: faSave },
  EXIT: { code: 'EXIT', icon: faWindowClose },
  UPLOAD: { code: 'UPLOAD', icon: faUpload },
  UNDO: { code: 'UNDO', icon: faUndo },
  EDIT: { code: 'EDIT', icon: faPen },
  CANCEL: { code: 'CANCEL', icon: faTimes },
  SEND: { code: 'SEND', icon: faEnvelope },
  SYNC: { code: 'SYNC', icon: faSync },
};

export const LINK_REDIRECT_ENUM = {
  CUSTOMERPAGE: 1,
  DETAILSPAGE: 2,
  LISTPAGE: 3,
};

export const ENTITIES_LINK = {
  customer: {
    code: 'customer',
    link: '/customer/customers/details/',
  },
  customerAccount: {
    code: 'customerAccount',
    link: '/customer/accounts/details/',
  },
  customerSite: {
    code: 'customerSite',
    link: '/customer/customer-sites/details/',
  },
  contactPerson: {
    code: 'contactPerson',
    link: '/customer/my-contacts/profile/',
  },
  employee: {
    code: 'employee',
    link: '/employees/employee-list/employee-detail/',
  },
  project: {
    code: 'project',
    link: '/projects/overview/project-details/',
  },
};

export enum EntityType {
  CUSTOMER = 'customer',
  CUSTOMER_ACCOUNT = 'customerAccount',
  CUSTOMER_SITE = 'customerSite',
  CONTACT_PERSON = 'contactPerson',
  EMPLOYEE = 'employee',
  PROJECT = 'project',
  OFFER = 'offer',
}

export enum DurationType {
  MONTHS = 'months',
  WEEKS = 'weeks',
  DAYS = 'days',
}
