import React, { useState } from 'react';
import { Button, Card, CardBody, Input, Label } from 'reactstrap';

import { UtilizationActionEnum } from '../../../utils/enums/projectUtilization';
import { t } from './utilizationHelper';

interface IProps {
  onSave: (action: string) => Promise<void>;
  action: string;
}

const UtilizationActionModal = ({ onSave, action }: IProps) => {
  const [actionChoice, setActionChoice] = useState(
    action ?? UtilizationActionEnum.NO_ACTION.toString()
  );

  const handleSave = () => {
    onSave(actionChoice);
  };

  return (
    <Card>
      <CardBody>
        <table>
          <tbody>
            <tr>
              <td>
                <Input
                  data-testid="no-action-checkbox"
                  type="checkbox"
                  checked={actionChoice === UtilizationActionEnum.NO_ACTION}
                  onChange={() =>
                    setActionChoice(UtilizationActionEnum.NO_ACTION)
                  }
                />
                <Label className="mx-2">{t('noAction')}</Label>
              </td>
            </tr>
            <tr>
              <td>
                <Input
                  data-testid="action-desired-checkbox"
                  type="checkbox"
                  checked={
                    actionChoice.toString() ===
                    UtilizationActionEnum.ACTION_DESIRED
                  }
                  onChange={() =>
                    setActionChoice(
                      UtilizationActionEnum.ACTION_DESIRED.toString()
                    )
                  }
                />
                <Label className="mx-2">{t('actionDesired')}</Label>
              </td>
            </tr>
            <tr>
              <td>
                <Input
                  data-testid="action-necessary-checkbox"
                  type="checkbox"
                  checked={
                    actionChoice.toString() ===
                    UtilizationActionEnum.ACTION_NECESSARY.toString()
                  }
                  onChange={() =>
                    setActionChoice(
                      UtilizationActionEnum.ACTION_NECESSARY.toString()
                    )
                  }
                />
                <Label className="mx-2">{t('actionNecessary')}</Label>
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <Button
                  data-testid="utilization-action-modal-save-button"
                  color="primary"
                  onClick={() => handleSave()}
                >
                  {t('ok')}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

export default UtilizationActionModal;
