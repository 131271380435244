import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import {
  ALL_PROJECTS,
  NONE,
  OWNED_PROJECTS,
  OWNED_PROJECT_OPTIONS,
} from '../../utils/constants';
import i18n from '../../i18n';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import {
  IDropdownOption,
  IObjectNameAndId,
} from '../../utils/types/commonTypes';
import {
  dropdownOptionToObjectNameAndId,
  objectNameAndIdToDropdownOption,
} from '../../utils/helpers/dropdown';
import { isEmpty } from '../../utils/helpers/array';
import { getProjectDropdown } from '../../services/api/project';

interface IProps extends IWithModalsProps {
  resourceAssignmentPermission: string;
  customerId?: number;
  project?: IObjectNameAndId;
  isDisabled: boolean;

  onChange: (project: IObjectNameAndId) => void;
}

/**
 * Dropdown that has all Project as options.\
 * props:
 *  projectId, onChange, readOnly, index(optional), isClearable(optional)
 * customerId
 */
const ProjectDropdown = ({
  // With Modals
  modalErrorHandler,
  // Props
  customerId,
  project = {
    id: 0,
    name: '',
  },
  resourceAssignmentPermission,
  isDisabled,
  onChange,
}: IProps) => {
  const [projects, setProjects] = useState([] as IObjectNameAndId[]);

  const t = (keyName: string) => i18n.t(`ProjectDropdownInput.${keyName}`);

  /**
   * Handles creation of axios string depending on where the component is used and what permission the user have
   * @returns
   */
  const createAxiosObject = () => {
    let axiosObject = {};
    switch (resourceAssignmentPermission) {
      case NONE:
        return null;
      case OWNED_PROJECTS:
        axiosObject = { ...axiosObject, 'project-type': 'project' };
        break;
      case OWNED_PROJECT_OPTIONS:
        axiosObject = { ...axiosObject, 'project-type': 'project_option' };
        break;
      case ALL_PROJECTS:
      default:
        break;
    }

    if (customerId) {
      axiosObject = {
        ...axiosObject,
        'customerId.equals-type': 'project_option',
      };
    }
    return axiosObject;
  };

  const onChangeHandler = (project: IDropdownOption<number>) => {
    onChange(dropdownOptionToObjectNameAndId(project));
  };

  useEffect(() => {
    const axiosObject = createAxiosObject();

    if (axiosObject === null) {
      setProjects([]);
    } else {
      getProjectDropdown(axiosObject)
        .then((response: AxiosResponse<IObjectNameAndId[]>) => {
          if (!isEmpty(response.data)) {
            let projects = response.data;
            projects = projects?.sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            );

            setProjects(projects);
          }
        })
        .catch((error: AxiosError) => {
          modalErrorHandler(t('generateProjectDataFailed'), error);
        });
    }
  }, [customerId]);

  const options = projects.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  return (
    <Select
      options={options}
      value={objectNameAndIdToDropdownOption(project)}
      onChange={onChangeHandler}
      isDisabled={isDisabled}
      placeholder={t('select')}
    />
  );
};

export default withModals(ProjectDropdown);
