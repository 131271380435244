import { AxiosError, AxiosResponse } from 'axios';
import React, { Fragment } from 'react';
import { Line } from 'react-chartjs-2';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';

import axios from '../../../services/axios/axios';
import {
  ALL,
  dataGeneration,
  NOT_DISPLAYABLE,
  NO_INDUSTRY,
} from '../../../utils/constants';
import { TARGET_PRIORITY_ENUM } from '../../../utils/enums/customer';
import { TIME_SPAN_ENUM } from '../../../utils/enums/timeTracking';
import {
  errorHandler,
  handleError,
  isEmpty,
  isNullOrUndefinedOrEmpty,
} from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import {
  IDropdownOption,
  IErrorMessage,
} from '../../../utils/types/commonTypes';
import {
  IActivity,
  IContactPersonAssignment,
  IContactPersonSector,
  IExtendedContactPerson,
  ITargetPrioritizationCallStatistics,
} from '../../../utils/types/modelTypes';
import ModalError from '../../../components/modals/ModalError';
import TimeSpanDropdown from './TimeSpanDropdown';

interface IProps {
  callActivities: IActivity[];
  sectors: IDropdownOption[];
  sectorIds: string[];
  contactPersonSectors: IContactPersonSector[];
  contactPersonAssignments: IContactPersonAssignment[];
  extendedContactPeople: IExtendedContactPerson[];
  lineOptions: any;
}

interface IState {
  contactPersonAssignments: IContactPersonAssignment[];
  callActivities: IActivity[];
  contactPersonSectors: IContactPersonSector[];
  selectedTime: any;
  sectors: IDropdownOption[];
  sectorIds: string[];
  filteredContacts: IContactPersonAssignment[];
  filteredCallActivities: IActivity[];
  filteredColdContacts: [];
  filteredWarmContacts: [];
  statisticsTotal: [];
  showModalError: boolean;
  callStatisticsObject: null;
  dateFilteredCallActivities: [];
  monthOptionsWithStatisticsObject: ITargetPrioritizationCallStatistics[];
  extendedContactPeople: IExtendedContactPerson[];
}
/**
 * Card that contains the target prioritization table and it's filters
 */
class TargetPrioritiationCard extends React.Component<IProps, IState> {
  message: IErrorMessage = {} as IErrorMessage;

  modalTitle: string;

  constructor(props: IProps) {
    super(props);
    this.state = {
      contactPersonAssignments: [],
      callActivities: [],
      contactPersonSectors: [],
      selectedTime: TIME_SPAN_ENUM.thisMonth.code,
      sectors: [],
      sectorIds: [],
      filteredContacts: [],
      filteredCallActivities: [],
      filteredColdContacts: [],
      filteredWarmContacts: [],
      statisticsTotal: [],
      showModalError: false,
      callStatisticsObject: null,
      dateFilteredCallActivities: [],
      monthOptionsWithStatisticsObject: [],
      extendedContactPeople: [],
    };

    this.modalTitle = '';
  }

  /**
   * Handles translation for words used in "CallingStatistics"
   * @param {*} keyName
   * @returns translated word
   */
  t(keyName: string) {
    return i18n.t('CallingStatistics.' + keyName);
  }

  /**
   * Method that handles error
   * @param {*} mainError
   * @param {*} errorObject
   */
  handleError = (mainError: string, errorObject: AxiosError) => {
    this.message = handleError(mainError, errorObject);
    this.modalTitle = this.t('error');
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  /**
   * Enables a toggle to appear when an error is encountered.
   */
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  /**
   * On updating the component, various conditions are checked
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps: IProps, prevState: any) {
    if (prevProps !== this.props) {
      const filteredContactPersonSectors =
        this.props.contactPersonSectors.filter(
          (contact: any) => contact.contactPerson !== null
        );
      this.setState({
        contactPersonAssignments: this.props.contactPersonAssignments,
        callActivities: this.props.callActivities,
        contactPersonSectors: filteredContactPersonSectors,
        filteredContacts: this.props.contactPersonAssignments,
        filteredCallActivities: this.props.callActivities,
        sectors: this.props.sectors,
        sectorIds: this.props.sectorIds,
        extendedContactPeople: this.props.extendedContactPeople,
      });
      if (prevState === this.state) {
        setTimeout(() => {
          this.getTargetPrioritizationCallStatistics();
        }, 300);
      }
    }
  }

  /**
   * Handles the retrieval of taget prioritization call statistics data
   */
  getTargetPrioritizationCallStatistics = () => {
    if (!isEmpty(this.state.sectorIds)) {
      const sectorIds = this.state.sectorIds;
      sectorIds[sectorIds.findIndex((sectorId) => sectorId === NO_INDUSTRY)] =
        '-1';

      const selectedTime = this.state.selectedTime;
      axios.sales
        .get(
          `target-prioritization-call-statistics/${sectorIds}/${selectedTime}`
        )
        .then(
          (response: AxiosResponse<ITargetPrioritizationCallStatistics[]>) => {
            this.setState({
              monthOptionsWithStatisticsObject: response.data,
              sectorIds,
            });
          }
        )
        .catch((error) => {
          this.message = errorHandler(
            dataGeneration,
            error,
            this.t('failedToRetrieveTargetPrioritizationStatistics')
          );
          this.toggleModalError();
        });
    }
  };

  /**
   * Handles changes to time span dropdown
   * @param {*} time
   */
  handleTimeSpanChange = (time: string) => {
    this.setState({
      selectedTime: time,
    });
    setTimeout(() => {
      this.getTargetPrioritizationCallStatistics();
    }, 300);
  };

  /**
   * Handles changes to industries dropdown
   * @param {*} sectors
   */
  handleMultipleSectorChange = (sectors: IDropdownOption[]) => {
    if (Array.isArray(sectors)) {
      let newSectors = sectors;
      if (newSectors.some((sector: any) => sector.value.toString() === ALL)) {
        newSectors = this.state.sectors.filter(
          (sector: IDropdownOption) =>
            sector?.value && sector.value.toString() !== ALL
        );
      }
      const sectorIds = newSectors.map((sector) => sector.value);
      this.setState({
        sectorIds,
      });
      setTimeout(() => {
        this.getTargetPrioritizationCallStatistics();
      }, 300);
    } else {
      this.setState({
        sectorIds: [],
      });
    }
  };

  /**
   * Handle the creation of table component for the statistics display
   * @param {*} priorityTargetStat
   * @returns - render component
   */
  getPriorityTargetStatisticComponent = (
    priorityTargetStat: any,
    targetPriority: any
  ) =>
    priorityTargetStat ? (
      <>
        <td>{priorityTargetStat.newContacts}</td>
        <td>{priorityTargetStat.calls}</td>
        <td>{priorityTargetStat.reachedCalls}</td>
        <td>{priorityTargetStat.appointmentCalls}</td>
        {priorityTargetStat.callingSuccessRate === -1 ? (
          <td>{NOT_DISPLAYABLE}</td>
        ) : (
          <td>{priorityTargetStat.callingSuccessRate}%</td>
        )}
        {priorityTargetStat.appointmentRate === -1 ? (
          <td>{NOT_DISPLAYABLE}</td>
        ) : (
          <td>{priorityTargetStat.appointmentRate}%</td>
        )}
        {targetPriority === null && <td />}
        {targetPriority !== null && priorityTargetStat.percentOfCalls === -1 ? (
          <td>{NOT_DISPLAYABLE}</td>
        ) : (
          <td>{priorityTargetStat.percentOfCalls}%</td>
        )}
        {!isNullOrUndefinedOrEmpty(priorityTargetStat.goal) ? (
          <td>{priorityTargetStat.goal}%</td>
        ) : (
          <td />
        )}
      </>
    ) : null;

  /**
   * Handles the retrieval of data to be used on displaying the line graph
   * @param {*} monthOptionsWithStatisticsObject
   * @returns
   */
  getLineDataSet = (monthOptionsWithStatisticsObject: any) => {
    let overallPrioritizationPerMonth: any[] = [];
    if (!isEmpty(monthOptionsWithStatisticsObject)) {
      overallPrioritizationPerMonth = monthOptionsWithStatisticsObject.map(
        (monthOption: any) => monthOption.computedOverallPrioritization
      );
    }

    const legends = [this.t('myPrioritizationKpi')];
    const data = legends.map((rateType) => ({
      label: rateType,
      data: overallPrioritizationPerMonth.map(
        (percentagePerMonth) => percentagePerMonth
      ),
      backgroundColor: 'transparent',
      borderColor: '#FFA500',
      pointBackgroundColor: '#FFA500',
    }));
    return data;
  };

  /**
   * Handles the update of line options
   * @param {*} lineOptions
   * @returns - updated line options
   */
  getUpdatedLineOptions = (lineOptions: any) => {
    const updatedLineOptions = { ...lineOptions };
    if (updatedLineOptions.scales?.y[0]?.ticks) {
      updatedLineOptions.scales.y[0].ticks.max = 100;
      updatedLineOptions.scales.y[0].ticks.stepSize = 25;
      return updatedLineOptions;
    }
    return updatedLineOptions;
  };

  /**
   * Handles the retrieval of abbreviated month and year
   * @param {*} selectedMonth
   * @returns - returns the abbreviated month name and year
   */
  getMonthAbbreviationAndYear = (selectedMonth: any) => {
    const firstDayOfThisMonthAndYear = new Date(
      selectedMonth.year,
      selectedMonth.monthValue - 1,
      1
    );
    const languageSelected = i18n.language;
    const monthNameAbbreviation = firstDayOfThisMonthAndYear.toLocaleString(
      languageSelected,
      { month: 'short' }
    );

    return `${monthNameAbbreviation} ${selectedMonth.year}`;
  };

  render() {
    const selectedTime = this.state.selectedTime;
    const currentDate = new Date();
    if (selectedTime === TIME_SPAN_ENUM.lastMonth.code) {
      const dateMonth = currentDate.getMonth();
      currentDate.setMonth(dateMonth - 1);
    }
    const monthOptionsWithStatisticsObject =
      this.state.monthOptionsWithStatisticsObject;

    // Use statistics for this month by default
    // NOTE: Java LocalDate month indices starts with 1 instead of 0
    const monthOptionsWithStatisticsObjectCurrentMonth =
      monthOptionsWithStatisticsObject.find(
        (monthObject) => monthObject.monthValue - 1 === currentDate.getMonth()
      );

    return (
      <Fragment>
        <Col>
          <h3>{this.t('targetPrioritization')}</h3>
          <Row>
            <div style={{ width: '200px', padding: '10px' }}>
              <TimeSpanDropdown
                onChange={this.handleTimeSpanChange}
                selectedTime={selectedTime ?? TIME_SPAN_ENUM.thisMonth.code}
              />
            </div>
            <div style={{ width: '60%', padding: '10px' }}>
              <Select
                isMulti
                options={this.state.sectors}
                value={this.state.sectors?.filter((sector) =>
                  this.state.sectorIds?.some(
                    (sectorId) => sectorId === sector.value
                  )
                )}
                onChange={this.handleMultipleSectorChange}
                isClearable={true}
                placeholder={this.t('selectIndustries')}
              />
            </div>
          </Row>
          <table className="target-prioritization-table">
            <thead>
              <tr>
                <td />
                <td>{this.t('newContacts')}</td>
                <td>{this.t('calls')}</td>
                <td>{this.t('reached')}</td>
                <td>{this.t('appointments')}</td>
                <td>{this.t('callingSuccessRate')} </td>
                <td>{this.t('appointmentRate')}</td>
                <td>{this.t('percentOfCalls')}</td>
                <td>{this.t('goal')}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="target-prioritization-label">
                  {this.t('hiPrioTargets')}
                </td>
                {this.getPriorityTargetStatisticComponent(
                  monthOptionsWithStatisticsObjectCurrentMonth
                    ?.combinedPrioritizationStatistics?.highPriorityStatistics,
                  TARGET_PRIORITY_ENUM.highPriority.code
                )}
              </tr>
              <tr>
                <td className="target-prioritization-label">
                  {this.t('midPrioTargets')}
                </td>
                {this.getPriorityTargetStatisticComponent(
                  monthOptionsWithStatisticsObjectCurrentMonth
                    ?.combinedPrioritizationStatistics?.midPriorityStatistics,
                  TARGET_PRIORITY_ENUM.midPriority.code
                )}
              </tr>
              <tr>
                <td className="target-prioritization-label">
                  {this.t('lowPrioTargets')}
                </td>

                {this.getPriorityTargetStatisticComponent(
                  monthOptionsWithStatisticsObjectCurrentMonth
                    ?.combinedPrioritizationStatistics?.lowPriorityStatistics,
                  TARGET_PRIORITY_ENUM.lowPriority.code
                )}
              </tr>
              <tr>
                <td className="target-prioritization-label">
                  {this.t('noPrioTargets')}
                </td>
                {this.getPriorityTargetStatisticComponent(
                  monthOptionsWithStatisticsObjectCurrentMonth
                    ?.combinedPrioritizationStatistics?.noPriorityStatistics,
                  TARGET_PRIORITY_ENUM.noPriority.code
                )}
              </tr>
              <tr>
                <td className="target-prioritization-label">
                  {this.t('tbdPrioTargets')}
                </td>
                {this.getPriorityTargetStatisticComponent(
                  monthOptionsWithStatisticsObjectCurrentMonth
                    ?.combinedPrioritizationStatistics
                    ?.toBeDefinedPriorityStatistics,
                  TARGET_PRIORITY_ENUM.toBeDefined.code
                )}
              </tr>
              <tr>
                <td className="target-prioritization-label">
                  {this.t('total')}
                </td>
                {this.getPriorityTargetStatisticComponent(
                  monthOptionsWithStatisticsObjectCurrentMonth
                    ?.combinedPrioritizationStatistics?.totalPriorityStatistics,
                  null
                )}
              </tr>
            </tbody>
          </table>
          <div className="float-end">
            <span className="overall-prioritization-indicator">
              {this.t('overallPrioritizationIndicator')}
            </span>
            <Label className="overall-prioritization-indicator">
              {monthOptionsWithStatisticsObjectCurrentMonth?.computedOverallPrioritization
                ? `${monthOptionsWithStatisticsObjectCurrentMonth?.computedOverallPrioritization}`
                : 0}
              %
            </Label>
          </div>
          <div className="prioritization-graph-wide">
            <h3>{this.t('prioritizationDevelopment')}</h3>
            <Line
              data={{
                labels: monthOptionsWithStatisticsObject.map((month) =>
                  this.getMonthAbbreviationAndYear(month)
                ),
                datasets: this.getLineDataSet(monthOptionsWithStatisticsObject),
              }}
              options={this.getUpdatedLineOptions(this.props.lineOptions)}
            />
          </div>
        </Col>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.message?.mainError ?? ''}
          errorReason={this.message?.errorReason ?? ''}
          errorResponse={this.message?.errorResponse ?? ''}
          modalTitle={this.t('error')}
        />
      </Fragment>
    );
  }
}
export default TargetPrioritiationCard;
