import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import Select from 'react-select';
import { Input, Label, Row } from 'reactstrap';
import { monthsOfYear } from '../../../utils/enums/calendar';
import { ALL, NO_INDUSTRY } from '../../../utils/constants';
import { CONTACT_STATUS } from "../../../utils/enums/contact";
import {
  CALL_STATISTICS_RATE_ENUM,
  CALL_STATUS_TYPE_ENUM
} from "../../../utils/enums/callStatus";
import { isEmpty, sortCallDataByType } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';

class CallingStatisticsDevelopmentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callData: [],
      sectors: [],
      sectorIds: [],
      contactPersonSectors: [],

      isColdChecked: true,
      isWarmChecked: true,

      selectedYear: moment().year(),
      years: [],
    };
  }

  t(keyName) {
    return i18n.t('CallingStatisticsDevelopmentCard.' + keyName);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        callData: this.props.callData,
        sectors: this.props.sectors,
        sectorIds: this.props.sectorIds,
        contactPersonSectors: this.props.contactPersonSectors,
      });
      this.setYearOptions();
    }
  }

  /**
   * Setting the options for year filter based on the retrieved Call Data
   */
  setYearOptions = () => {
    let sortedYearOptions;
    if (this.props?.callData) {
      let yearOptions = this.props?.callData.map((item) =>
        moment(item.date).year()
      );
      yearOptions = [...new Set(yearOptions)];

      sortedYearOptions = yearOptions
        .sort((a, b) => a - b)
        .map((year) => ({
          label: year,
          value: year,
        }));
    } else {
      // If no yearOption can be retrieved from call data, set current year as option
      sortedYearOptions = [{ value: moment().year(), label: moment().year() }];
    }

    sortedYearOptions.unshift({
      label: this.t('allYears'),
      value: 0,
    });
    this.setState({ years: sortedYearOptions });
  };

  /**
   * Handles changes to industries dropdown
   * @param {*} sectors
   */
  handleMultipleSectorChange = (sectors) => {
    if (Array.isArray(sectors)) {
      let newSectors = sectors;
      if (newSectors.some((sector) => sector.value.toString() === ALL)) {
        newSectors = this.state.sectors.filter(
          (sector) => sector.value.toString() !== ALL
        );
      }
      let sectorIds = newSectors.map((sector) => sector.value);

      this.setState({
        sectorIds: sectorIds,
      });
    } else {
      this.setState({
        sectorIds: [],
      });
    }
  };

  /**
   * Handles changes to cold call checkbox
   * @param {*} event
   */
  handleColdContactCheck = (event) => {
    this.setState({
      isColdChecked: event.target.checked,
    });
  };

  /**
   * Handles changes to warm call checkbox
   * @param {*} event
   */
  handleWarmContactCheck = (event) => {
    this.setState({
      isWarmChecked: event.target.checked,
    });
  };

  /**
   * Handles changes made in year dropdown
   * @param {*} year
   */
  handleYearChange = (year) => {
    this.setState({
      selectedYear: year.value,
    });
  };

  /**
   * Handles data filtering
   * @returns filteredData
   */
  handleDataFilter = () => {
    let year = this.state.selectedYear;
    let sectorIds = this.state.sectorIds;

    let data = this.state.callData;
    let dataBasedFromAssignments = [];
    let toBeFilteredData = [];
    let filteredData = [];

    let contactsIdsFromSectorsChosen = this.state?.contactPersonSectors
      .filter((item) => sectorIds?.includes(item.sector?.id))
      .map((contact) => contact?.contactPerson?.id);

    let contactIdsFromSectors = this.state.contactPersonSectors.map(
      (contact) => contact?.contactPerson?.id
    );

    // Filtering for cold call data
    if (this.state.isColdChecked) {
      let coldContactData = data?.filter(
        (contact) =>
          contact.assignmentState === CONTACT_STATUS.cold.code ||
          contact.assignmentState === CONTACT_STATUS.recall.code ||
          contact.assignmentState === CONTACT_STATUS.prospect.code
      );

      if (!isEmpty(coldContactData)) {
        dataBasedFromAssignments.push(...coldContactData);
      }
    }

    // Filtering for warm call data
    if (this.state.isWarmChecked) {
      let warmContactData = data?.filter(
        (contact) =>
          contact.assignmentState === CONTACT_STATUS.meeting.code ||
          contact.assignmentState === CONTACT_STATUS.firstProject.code ||
          contact.assignmentState === CONTACT_STATUS.oneProject.code ||
          contact.assignmentState === CONTACT_STATUS.anotherProject.code ||
          contact.assignmentState === CONTACT_STATUS.multipleProject.code
      );
      if (!isEmpty(warmContactData)) {
        dataBasedFromAssignments.push(...warmContactData);
      }
    }

    // Filtering of call data by industries selected
    toBeFilteredData = dataBasedFromAssignments?.filter((call) =>
      contactsIdsFromSectorsChosen?.includes(call?.contactId)
    );

    if (sectorIds.includes(NO_INDUSTRY)) {
      let callsWithNoIndustry = dataBasedFromAssignments?.filter(
        (call) => !contactIdsFromSectors.includes(call?.contactId)
      );
      toBeFilteredData?.push(...callsWithNoIndustry);
    } else if (sectorIds.includes(ALL)) {
      toBeFilteredData = dataBasedFromAssignments;
    }

    // If "All Years" is selected, no need to filter
    if (year === 0) {
      filteredData = toBeFilteredData;
    } else {
      filteredData = toBeFilteredData?.filter(
        (item) => moment(item.date).year() === year
      );
    }

    return filteredData;
  };

  /**
   * Prepares data to be used in the bar graph
   * @returns data
   */
  getBarDataSet = () => {
    let filteredData = this.handleDataFilter();
    let legends = Object.values(CALL_STATUS_TYPE_ENUM).map((call) => call.name);
    legends = [...new Set(legends)];

    let data = legends.map((type) => ({
      label: type,
      data: Object.values(monthsOfYear).map(
        (month) =>
          filteredData.filter((item) => {
            return (
              moment(item.date).month() === month.value &&
              item.callStatusType === type
            );
          })?.length
      ),
      backgroundColor: Object.values(CALL_STATUS_TYPE_ENUM).find(
        (call) => call.name === type
      ).color,
    }));

    let sortedData = sortCallDataByType(data);
    return sortedData;
  };

  /**
   * Prepares data to be used in the line graph
   * @returns data
   */
  getLineDataSet = () => {
    let filteredData = this.handleDataFilter();
    let legends = Object.values(CALL_STATISTICS_RATE_ENUM).map(
      (rate) => rate.name
    );

    let data = legends.map((rateType) => ({
      label: rateType,
      data: Object.values(monthsOfYear).map((month) =>
        this.computeMonthlyConversionRate(filteredData, month, rateType)
      ),
      backgroundColor: 'transparent',
      borderColor: Object.values(CALL_STATISTICS_RATE_ENUM).find(
        (rate) => rate.name === rateType
      ).color,
      pointBackgroundColor: Object.values(CALL_STATISTICS_RATE_ENUM).find(
        (rate) => rate.name === rateType
      ).color,
      pointStyle: true,
    }));
    return data;
  };

  /**
   * Compute the appointment rate and calling success
   * @param {*} filteredData
   * @param {*} month
   * @param {*} rateType
   * @returns value for appointment rate or calling success
   */
  computeMonthlyConversionRate = (filteredData, month, rateType) => {
    let value = 0;
    let total = filteredData.filter((item) => {
      return moment(item.date).month() === month.value;
    })?.length;

    let reached = filteredData.filter((item) => {
      return (
        moment(item.date).month() === month.value &&
        item.callStatusType === CALL_STATUS_TYPE_ENUM.reached.name
      );
    })?.length;
    let appointments = filteredData.filter((item) => {
      return (
        moment(item.date).month() === month.value &&
        item.callStatusType === CALL_STATUS_TYPE_ENUM.appointments.name
      );
    })?.length;

    if (total !== 0) {
      switch (rateType) {
        case CALL_STATISTICS_RATE_ENUM.appointmentRate.name:
          value = appointments / total;
          break;
        case CALL_STATISTICS_RATE_ENUM.callingSuccess.name:
          value = (reached + appointments) / total;
          break;
        default:
          break;
      }
    }
    let roundedValue =
      value > 0.1 ? Math.round(value * 100) : Math.round(value * 1000) / 10;
    return roundedValue;
  };

  render() {
    return (
      <Fragment>
        <h3>{this.t('developmentOverTime')}</h3>
        <Row>
          <div style={{ width: '200px', padding: '30px' }}>
            <Label>{this.t('selectYear')}</Label>
            <Select
              options={this.state.years}
              value={this.state.years.find(
                (year) => year.value === this.state.selectedYear
              )}
              onChange={this.handleYearChange}
            />
          </div>
          <div style={{ width: '60%', padding: '30px' }}>
            <Select
              isMulti
              options={this.state.sectors}
              value={this.state.sectors?.filter((sector) =>
                this.state.sectorIds?.some((id) => id === sector.value)
              )}
              onChange={this.handleMultipleSectorChange}
              isClearable={true}
              placeholder={'Select Industries'}
            />
          </div>
        </Row>
        <Row>
          <div className="calling-stats-graph-wide">
            <Bar
              options={this.props.barOptions}
              data={{
                labels: Object.values(monthsOfYear).map((month) => month.name),
                datasets: this.getBarDataSet(),
              }}
            />
          </div>
          <div className="calling-stats-checkbox">
            <Row>
              <Label>
                <Input
                  type="checkbox"
                  checked={this.state.isColdChecked}
                  onChange={this.handleColdContactCheck}
                />
                {this.t('cold')}
              </Label>
            </Row>
            <Row>
              <Label>
                <Input
                  type="checkbox"
                  checked={this.state.isWarmChecked}
                  onChange={this.handleWarmContactCheck}
                />
                {this.t('warm')}
              </Label>
            </Row>
          </div>
          <div>
            <br />
            <br />
          </div>
          <div className="calling-stats-graph-wide">
            <Line
              data={{
                labels: Object.values(monthsOfYear).map((month) => month.name),
                datasets: this.getLineDataSet(),
              }}
              options={this.props.lineOptions}
            />
          </div>
        </Row>
      </Fragment>
    );
  }
}

export default CallingStatisticsDevelopmentCard;
