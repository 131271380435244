import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../../redux/store';
import { isUserAProjectOwner, t } from './projectDetailHelper';
import { buttonTitleEnum, permissionUri } from '../../../utils/enums/enum';
import { READWRITE, monthDateYearFormat } from '../../../utils/constants';
import { generateTitle } from '../../../utils/helpers/icon';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import TableCard from '../../../components/cards/TableCard';
import Auth from '../../../services/axios/Auth';
import AssignResourceModal from '../../../components/form/AssignResourceForm/AssignResourceModal';
import {
  IProjectDetail,
  IProjectMemberListItem,
} from '../../../utils/types/responseTypes';
import { deleteProjectEmployee } from '../../../services/api/project';

interface IProps extends PropsFromRedux, IWithModalsProps {
  project: IProjectDetail;
  setProject: Dispatch<SetStateAction<IProjectDetail>>;
}

/**
 * Contains the data for the current team members and their utilization percentages
 * NOTE: re-add complex assigner, maybe even work on it too
 */
const ProjectCurrentProjectTeam = ({
  modalDeleteHandler,
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
  setProject,
  account,
  project,
  project: { projectMembers },
}: IProps) => {
  const setProjectMembers = (members: IProjectMemberListItem[]) => {
    setProject((project) => ({
      ...project,
      projectMembers: members,
    }));
  };

  const onSaveProjectMembers = (members: IProjectMemberListItem[]) => {
    setProjectMembers([...projectMembers, ...members]);
    toggleModalForm();
  };

  const deleteEmployee = (id: number) => {
    modalDeleteHandler(
      t('deleteProjectMember'),
      t('deleteProjectMemberConfirm'),
      async () => {
        await deleteProjectEmployee(id)
          .then(() => {
            const filteredProjectEmployees = projectMembers.filter(
              (item) => item.id !== id
            );
            setProjectMembers(filteredProjectEmployees);
          })
          .catch((error) => {
            modalErrorHandler(t('failedToDeleteProjectEmployee'), error);
          });
      }
    );
  };

  /**
   * Checks that the user has the right permission for the AssignResource button
   * @returns true or false
   */
  const handleAssignResourceButtonAccessibility = () => {
    const assignResourceToAll = Auth.hasPermission(
      [permissionUri.assignResources.readWrite.uri],
      [READWRITE]
    );

    const assignResourceToOwnedProject = Auth.hasPermission(
      [permissionUri.assignResourcesToProjects.readWrite.uri],
      [READWRITE]
    );

    const userIsProjectOwner = isUserAProjectOwner(
      projectMembers,
      account.employeeDetails.id
    );

    return (
      (assignResourceToOwnedProject && userIsProjectOwner) ||
      assignResourceToAll
    );
  };

  /**
   * Opens the modal to assign an employee to the project
   */
  const handleAssignResourceClick = () => {
    modalFormHandler(
      t('assignResource'),
      <AssignResourceModal onSave={onSaveProjectMembers} project={project} />
    );
  };

  /**
   * Creates the table data
   */
  const tableData = projectMembers.map(
    ({
      id,
      employee: { name },
      role,
      validFrom,
      workloadPercentage,
      workloadState,
    }) => ({
      [t('employee')]: (
        <Link to={`/projects/overview/${id}/view-employee/${id}`}>{name}</Link>
      ),
      [t('role')]: i18n.t(
        `ProjectEmployeeRole.${role ? role.toLocaleLowerCase() : 'noRole'}`
      ),
      [t('start')]: validFrom
        ? moment(validFrom).format(monthDateYearFormat)
        : '',
      [t('allocation')]: workloadPercentage?.toString(),
      [t('status')]: workloadState,
      [t('delete')]: (
        <Button
          color="primary"
          onClick={() => {
            deleteEmployee(id);
          }}
          size="sm"
          aria-label="delete"
        >
          {generateTitle(buttonTitleEnum.DELETE.code)}
        </Button>
      ),
    })
  );

  const headerButtons = (
    <>
      (
      {handleAssignResourceButtonAccessibility() && (
        <Button
          color="primary"
          onClick={() => handleAssignResourceClick()}
          size="m"
        >
          {generateTitle(buttonTitleEnum.ADD.code, t('assignResource'))}
        </Button>
      )}
      )
    </>
  );

  return (
    <TableCard
      headerButtons={headerButtons}
      border
      leftTitle
      title={t('projectResourceHeadder')}
      tableData={tableData}
    />
  );
};

const mapStateToProps = (store: RootState) => ({
  account: store.account,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default withModals(connector(ProjectCurrentProjectTeam));
