import React, { useEffect, useState } from 'react';
import {
  CardBody,
  CardHeader,
  Button,
  Card,
  Container,
  Col,
  Row,
} from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  getDepartments,
  getProjectDetails,
  updateProjectFromDetail,
} from '../../../services/api/project';
import Auth from '../../../services/axios/Auth';
import { radixOfBaseTen, READWRITE } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { generateTitle } from '../../../utils/helpers/icon';
import {
  IOfferDetails,
  IOfferListItem,
  IProjectDetail,
} from '../../../utils/types/responseTypes';
import ProjectDescription from './ProjectDescription';
import ProjectGeneralInformation from './ProjectGeneralInformation';
import { getCustomerSiteListItems } from '../../../services/api/customerSite';
import { t } from './projectDetailHelper';
import ProjectCurrentProjectTeam from './ProjectCurrentProjectTeam';
import ProjectOrders from './ProjectOrders';
import ProjectPendingOrders from './ProjectPendingOrders';
import { objectTypeEnum } from '../../../utils/enums/enum';
import FileAttachmentCard from '../../../components/cards/FileAttachmentCard';
import { DepartmentStatus } from '../../../utils/enums/department';
import ActivityNotesCard from '../../../components/cards/ActivityNotesCard';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import {
  IDropdownOption,
  IObjectNameAndId,
  IProjectDetailOffer,
} from '../../../utils/types/commonTypes';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IActivityListItem } from '../../../utils/types/modelTypes';
import OfferDetailsProvider from '../OfferModal/OfferDetailsProvider';
import OfferModal from '../OfferModal/OfferModal';
import { generateDefaultFormValues } from './projectDetailFormHelper';

export type ProjectDetailFormValues = {
  title: string;
  projectId: string;
  customerSite: IObjectNameAndId;
  state: string;
  responsibleContactPersons: IObjectNameAndId[];
  description: string;
  departments: IObjectNameAndId[];
  durationType: string;
  locationType: string;
  projectSharePoint: string;
  offers: IOfferListItem[];
  pendingOffers: IOfferListItem[];
};

interface IProps
  extends RouteComponentProps<{ projectId: string }>,
    IWithModalsProps {}
/**
 * Section that contains the project general information.\
 * Items included:
 *  - Project Title
 *  - Project Id
 *  - Customer
 *  - Customer Site
 *  - Contact Persons
 * Variable props:
 *  - projectDetail: contains the project details
 */
const ProjectDetailOverview = ({
  match: {
    params: { projectId },
  },
  location: { pathname },
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
}: IProps) => {
  const [formValues, setFormValues] = useState<ProjectDetailFormValues>(
    generateDefaultFormValues()
  );
  const [project, setProject] = useState<IProjectDetail>({} as IProjectDetail);
  const {
    id,
    title,
    customer,
    customerSite,
    state,
    projectSharePoint,
    responsible,
    involvedResponsibles,
    departments,
    description,
    responsibleContactPersons,
    fileAttachments,
    activities,
    projectId: projectVisibleId,
  } = project;

  // Dropdowns
  const [customerSiteList, setCustomerSiteList] = useState(
    [] as IDropdownOption<number>[]
  );

  const [departmentList, setDepartmentList] = useState(
    [] as IDropdownOption<number>[]
  );

  const [fieldToUpdate, setFieldToUpdate] = useState('');

  const fetchProject = async () => {
    const projectIdParsed = parseInt(projectId, radixOfBaseTen);
    try {
      const { data: projectDetail } = await getProjectDetails(projectIdParsed);
      setFormValues({
        ...projectDetail,
      });
      setProject(projectDetail);
    } catch (error) {
      modalErrorHandler(t('failedToGetProject'), error);
    }
  };

  const fetchCustomerSites = async () => {
    try {
      const { data: customerSites } = await getCustomerSiteListItems({
        sort: 'name%2Casc',
        'customerId.equals': customer.id.toString(),
      });

      setCustomerSiteList(
        customerSites.map(({ id, name, location }) => ({
          label: `${name} (${location})`,
          value: id,
        }))
      );
    } catch (error) {
      modalErrorHandler(t('failedToGetCustomerSite'), error);
    }
  };

  const fetchDepartmentList = async () => {
    try {
      const { data: departments } = await getDepartments({
        'status.in': `${DepartmentStatus.ACTIVE},${DepartmentStatus.PLANNED}`,
      });
      setDepartmentList(
        departments.map(({ id, title }) => ({
          label: title,
          value: id as number,
        }))
      );
    } catch (error) {
      modalErrorHandler(t('failedToGetDepartments'), error);
    }
  };

  const resetProjectDetails = () => {
    setFormValues(project);
    setFieldToUpdate('');
  };

  const saveProjectDetails = async () => {
    try {
      const { data: projectDetail } = await updateProjectFromDetail(formValues);
      setProject(projectDetail);
      setFormValues(projectDetail);
      setFieldToUpdate('');
    } catch (error) {
      modalErrorHandler(t('failedToUpdateProject'), error);
    }
  };

  const isSaveDisabled = () => {
    for (const key in formValues) {
      const keyTyped = key as keyof typeof formValues;
      if (formValues[keyTyped] !== project[keyTyped]) {
        return false;
      }
    }
    return true;
  };

  const setActivities = (activities: IActivityListItem[]) => {
    setProject({
      ...project,
      activities,
    });
  };

  const handleOfferModal = (modalTitle: string, offer?: IOfferDetails) => {
    const projectDetailsForOffer = {
      id,
      projectTitle: title,
      customerSite,
      customer,
      state,
      sharepointLink: projectSharePoint,
      responsible,
      involved: involvedResponsibles,
      projectId: projectVisibleId,
      departments,
      description,
      contactPerson: responsibleContactPersons,
    } as IProjectDetailOffer;

    modalFormHandler(
      modalTitle,
      <OfferDetailsProvider
        offerProject={projectDetailsForOffer}
        formOffers={formValues.offers}
        formPendingOffers={formValues.pendingOffers}
        setDetailFormValues={setFormValues}
      >
        <OfferModal offerId={offer && offer.id} onClose={toggleModalForm} />
      </OfferDetailsProvider>,
      'xl'
    );
  };

  useEffect(() => {
    fetchProject();
    fetchDepartmentList();
  }, []);

  useEffect(() => {
    if (customer && customerSiteList.length === 0) {
      fetchCustomerSites();
    }
  }, [customer]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('projectDetails')}</HeaderTitle>
        {generateBreadcrumb(pathname, t('project'))}
      </Header>
      <Card>
        <CardBody>
          <>
            <CardHeader>
              <Row>
                <Col>
                  {title && (
                    <h1 className="collapse-label">{`${t(
                      'projectDetails'
                    )} ${title}`}</h1>
                  )}
                </Col>
                <Col>
                  <div
                    className="float-end header-buttons"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '3px',
                    }}
                  >
                    {projectId &&
                      Auth.hasPermission(
                        [PERMISSION_URI.project.readWrite.uri],
                        [READWRITE],
                        projectId
                      ) && (
                        <>
                          <Button
                            color="primary"
                            onClick={() => saveProjectDetails()}
                            disabled={isSaveDisabled()}
                          >
                            {generateTitle(
                              BUTTON_TITLE_ENUM.SAVE.code,
                              t('save')
                            )}
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => resetProjectDetails()}
                            disabled={isSaveDisabled()}
                          >
                            {generateTitle(
                              BUTTON_TITLE_ENUM.UNDO.code,
                              t('reset')
                            )}
                          </Button>
                        </>
                      )}
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      {id && (
                        <ProjectGeneralInformation
                          project={project}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          customerSiteList={customerSiteList}
                          fieldToUpdate={fieldToUpdate}
                          setFieldToUpdate={setFieldToUpdate}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {id && (
                        <ProjectCurrentProjectTeam
                          project={project}
                          setProject={setProject}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {id && (
                        <FileAttachmentCard
                          objectType={objectTypeEnum.project.code}
                          objectId={id}
                          fileAttachments={fileAttachments ?? []}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      {id && (
                        <ProjectDescription
                          formValues={formValues}
                          setFormValues={setFormValues}
                          departmentList={departmentList}
                          fieldToUpdate={fieldToUpdate}
                          setFieldToUpdate={setFieldToUpdate}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {id && (
                        <ProjectOrders
                          projectFormValues={formValues}
                          toggleOfferModal={handleOfferModal}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {id && (
                        <ProjectPendingOrders
                          projectFormValues={formValues}
                          toggleOfferModal={handleOfferModal}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </>
        </CardBody>
      </Card>
      {id && (
        <ActivityNotesCard
          activities={activities}
          objectId={id}
          objectType={objectTypeEnum.project.code}
          setActivities={setActivities}
        />
      )}
    </Container>
  );
};

export default withRouter(withModals(ProjectDetailOverview));
