import React, { Dispatch, SetStateAction, useState } from 'react';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

import withModals, { IWithModalsProps } from '../../../utils/withModals';
import avatar from '../../../assets/img/avatars/silhouette-avatar.jpg';
import {
  IObjectNameAndId,
  IObjectProjectResource,
  IObjectProjectUtilization,
} from '../../../utils/types/commonTypes';
import { t, totalWorkloadUtilizationByMonth } from './utilizationHelper';
import {
  IObjectTimespan,
  ResourceViewMode,
  totalWorkloadUtilizationByWeek,
} from '../ResourceOverview/resourceOverviewHelper';
import AssignResourceModal from '../../../components/form/AssignResourceForm/AssignResourceModal';

interface IProps extends IWithModalsProps {
  modeView: ResourceViewMode;
  showProjects: boolean;
  setShowProjects: Dispatch<SetStateAction<boolean>>;
  employee: IObjectNameAndId;
  projectUtilization: IObjectProjectResource[] | IObjectProjectUtilization[];
  headerRange: IObjectTimespan[];
  fetchUtilizations?: () => Promise<void>;
  fetchResources?: (queryParameters: {
    [key: string]: string;
  }) => Promise<void>;
}

/**
 * EmployeeProjectRowView
 * Displays the Employee and its assigned projects with corresponding
 * workload percentage in each month
 */
const EmployeeProjectsRowView = ({
  modeView,
  showProjects,
  setShowProjects,
  employee: { id, name },
  projectUtilization,
  headerRange,
  fetchUtilizations,
  fetchResources,
  toggleModalForm,
  modalFormHandler,
}: IProps) => {
  const [employeeAnchor, setEmployeeAnchor] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleEmployeeContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setEmployeeAnchor(
      employeeAnchor === null
        ? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 }
        : null
    );
  };

  const handleCloseEmployeeMenu = () => {
    setEmployeeAnchor(null);
  };

  const handleSaveAssignResource = () => {
    toggleModalForm();
    if (fetchUtilizations) {
      fetchUtilizations();
    }
    if (fetchResources) {
      fetchResources({});
    }
  };

  const handleMenuItemClick = () => {
    handleCloseEmployeeMenu();
    modalFormHandler(
      t('assignEmployee'),
      <AssignResourceModal
        hideComplexAssigner
        onSave={handleSaveAssignResource}
      />
    );
  };

  const handleShowProjects = () => {
    setShowProjects(!showProjects);
  };

  return (
    <>
      <td
        aria-label="utilization-employee-context=field"
        className="stickyFirstColumn"
        onContextMenu={handleEmployeeContextMenu}
      >
        <img
          alt="Avatar"
          src={avatar}
          className="rounded-circle employeeIconDailyView mx-2"
        />
        <Link to={`/projects/resource-profile/${id}`}>{name}</Link>
        <Menu
          open={employeeAnchor !== null}
          onClose={handleCloseEmployeeMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            employeeAnchor !== null
              ? {
                  top: employeeAnchor.mouseY,
                  left: employeeAnchor.mouseX,
                }
              : { top: 0, left: 0 }
          }
        >
          <MenuItem onClick={handleMenuItemClick}>{t('assign')}</MenuItem>
        </Menu>
      </td>
      <td className="h-100 stickySecondColumn">
        <div
          aria-label="utilization-project-toggle"
          onClick={handleShowProjects}
        >
          <FontAwesomeIcon
            icon={showProjects ? faCaretDown : faCaretRight}
            className="margin-right"
          />
          <span>{t('onProject')}</span>
        </div>
      </td>
      {modeView === ResourceViewMode.MONTHLY &&
        headerRange.map(({ month }) => (
          <td key={`month-${month}`}>
            {`${totalWorkloadUtilizationByMonth(projectUtilization, month)}%`}
          </td>
        ))}
      {modeView === ResourceViewMode.WEEKLY &&
        headerRange.map(({ week }) => (
          <td key={`week-${week}`}>
            {`${totalWorkloadUtilizationByWeek(
              projectUtilization,
              week as number
            )}%`}
          </td>
        ))}
    </>
  );
};

export default withModals(EmployeeProjectsRowView);
