import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';

import { SortBy, SortType } from '../utils/enums/sort';

// TODO: Remove hasMore when infinite scrolling is removed
// In all components that use createListSlice
export type ListState<T, F, S = Record<string, never>> = {
  listItems: T[];
  hasMore: boolean;
  page: number;
  pageSize: number;
  pageCount: number;
  filters: F;
  showFilters: boolean;
  sortBy: SortBy;
  sortType: SortType;
  additionalState?: S;
};

const createListSlice = <
  T extends { id: number },
  F,
  S,
  Reducers extends SliceCaseReducers<ListState<T, F, S>>
>({
  name,
  initialState,
  reducers,
}: {
  name: string;
  initialState: ListState<T, F, S>;
  reducers: ValidateSliceCaseReducers<ListState<T, F, S>, Reducers>;
}) =>
  createSlice({
    name,
    initialState,
    reducers: {
      removeListItem: (
        state: ListState<T, F, S>,
        { payload }: PayloadAction<number>
      ) => ({
        ...state,
        listItems: state.listItems.filter(({ id }: T) => id !== payload),
      }),
      setListItems: (state, { payload }: PayloadAction<T[]>) => ({
        ...state,
        listItems: [...payload],
      }),
      setHasMoreListItems: (state, { payload }: PayloadAction<boolean>) => ({
        ...state,
        hasMore: payload,
      }),
      setPage: (state, { payload }: PayloadAction<number>) => ({
        ...state,
        page: payload,
      }),
      setPageSize: (state, { payload }: PayloadAction<number>) => ({
        ...state,
        pageSize: payload,
      }),
      setPageCount: (state, { payload }: PayloadAction<number>) => ({
        ...state,
        pageCount: payload,
      }),
      setFilters: (
        state,
        {
          payload,
        }: PayloadAction<{
          [key in keyof F]: F[key];
        }>
      ) => ({
        ...state,
        filters: {
          ...payload,
        },
      }),
      toggleFilter: (state) => ({
        ...state,
        showFilters: !state.showFilters,
      }),
      setSortBy: (state, { payload }: PayloadAction<SortBy>) => ({
        ...state,
        sortBy: payload,
      }),
      setSortType: (state, { payload }: PayloadAction<SortType>) => ({
        ...state,
        sortType: payload,
      }),
      ...reducers,
    },
  });

export default createListSlice;
