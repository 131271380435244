/**
 * Rounds the value off at a specific interval, the default interval is 1.0
 * @param {*} value
 * @param {*} interval
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const round = (value: number, interval = 1.0) => {
  const inv = 1.0 / interval;

  return Math.round(value * inv) / inv;
};
