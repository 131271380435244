import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import {
  IDropdownOption,
  IObjectNameAndId,
} from '../../utils/types/commonTypes';
import { ICustomerDropdownItem } from '../../utils/types/responseTypes';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import { getCustomerDropdownItems } from '../../services/api/customer';
import { objectNameAndIdToDropdownOption } from '../../utils/helpers/dropdown';

interface IProps extends IWithModalsProps {
  customer: IObjectNameAndId;
  onCustomerSelect: (customer: ICustomerDropdownItem) => void;
  isDisabled: boolean;
}

const CustomerDropdown = ({
  modalErrorHandler,
  onCustomerSelect,
  isDisabled,
  customer,
}: IProps) => {
  const [customerListItems, setCustomerListItems] = useState<
    ICustomerDropdownItem[]
  >([]);
  const dropdownOptions = customerListItems.map(
    ({ id, name }) => ({ value: id, label: name } as IDropdownOption<number>)
  );

  const fetchCustomerDropdownItems = async () => {
    try {
      const { data } = await getCustomerDropdownItems();
      setCustomerListItems(data);
    } catch (error) {
      modalErrorHandler('Data Generation', error);
    }
  };

  const handleSelectCustomer = ({ value }: IDropdownOption<number>) => {
    onCustomerSelect(
      customerListItems.find(({ id }) => id === value) as ICustomerDropdownItem
    );
  };

  useEffect(() => {
    if (!isDisabled) fetchCustomerDropdownItems();
  }, []);

  return (
    <div data-testid="customer-dropdown-div">
      <Select
        options={dropdownOptions}
        value={
          isDisabled
            ? objectNameAndIdToDropdownOption(customer)
            : dropdownOptions.find(({ value }) => value === customer.id)
        }
        onChange={handleSelectCustomer}
        isDisabled={isDisabled}
        aria-label="customer-dropdown-select"
      />
    </div>
  );
};

export default withModals(CustomerDropdown);
