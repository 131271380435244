import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card, CardBody, Container, Input } from 'reactstrap';
import axios from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { handleError } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';

/**
 * Class for Updating the Working Hours Per Month
 *
 * Created by: Reimon Angelo Tito
 * Created on: 28/09/2021
 */
class UpdateWorkingHoursPerMonth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceConfiguration: props?.serviceConfiguration ?? null,

      error: null,
      showModalError: false,
    };
  }

  t(keyName) {
    return i18n.t('WorkingHoursPerMonthView.' + keyName);
  }

  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  handleError = (mainError, errorObject) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  handleConfigValueChange = (e) => {
    // regex for double, accepts 10 digit before the decimal point and 2 digits after the decimal point
    var regex = /^\d{0,10}(\.\d{0,2}){0,1}$/;
    let serviceConfiguration = this.state.serviceConfiguration ?? null;

    let value = e.target.value;
    if (value.trim() === '') {
      value = '';
    }
    const valueFloat = value ? parseFloat(value) : '';
    if (value.match(regex) || value === '') {
      serviceConfiguration.configValue = valueFloat.toString();
      this.setState({
        serviceConfiguration: serviceConfiguration,
      });
    }
  };

  saveServiceConfiguration = async () => {
    let serviceConfiguration = this.state.serviceConfiguration;
    let savedServiceConfiguration;
    if (serviceConfiguration.id) {
      savedServiceConfiguration = await axios.serviceConfiguration
        .put('', serviceConfiguration)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.handleError(this.t('failedToUpdateWorkingHours'), error);
        });
    }
    if (savedServiceConfiguration && this.props.onSave) {
      this.props.onSave(savedServiceConfiguration);
    }
  };

  render() {
    const serviceConfiguration = this.state.serviceConfiguration;
    return (
      <Container fluid>
        <Card>
          <CardBody>
            <InputFormLabel
              isRequired={true}
              text={this.t('workingHoursPerMonth')}
            />
            <Input
              min="0"
              bsSize="lg"
              type="number"
              onChange={this.handleConfigValueChange}
              value={
                serviceConfiguration?.configValue
                  ? serviceConfiguration.configValue
                  : ''
              }
            ></Input>
            <br />
            <div className="card-actions float-end">
              <Button
                color="primary"
                disabled={
                  !serviceConfiguration?.configValue ||
                  serviceConfiguration?.configValue === '0'
                    ? true
                    : false
                }
                onClick={() => this.saveServiceConfiguration()}
              >
                {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, this.t('submit'))}
              </Button>
            </div>
          </CardBody>
        </Card>

        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={'Error'}
        />
      </Container>
    );
  }
}

export default UpdateWorkingHoursPerMonth;
