import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Auth from '../services/axios/Auth';
import ScrollToTop from '../components/layout/ScrollToTop';
import { READ, READWRITE } from '../utils/constants';
import { Url } from '../services/api/constants';
import i18n from '../i18n';
import AuthLayout from '../components/layout/Auth';
import DashboardLayout from '../components/layout/Dashboard';
import Page404 from '../pages/Page404/Page404';
import { dashboard as dashboardRoutes } from './index';

const childRoutes = (Layout, routes) =>
  routes.map(
    (
      {
        name,
        children,
        path,
        component: Component,
        isProtected = false,
        permissionUri,
      },
      index
    ) =>
      children ? (
        // Route item with children
        children.map(({ rights, path, component: Component }, index) =>
          isProtected ? (
            <ProtectedRoute
              rights={rights}
              name={name}
              isProtected={isProtected}
              path={path}
              key={index}
              permissionUri={permissionUri}
              exact
              component={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          ) : (
            <Route
              key={index}
              path={path}
              exact
              render={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          )
        )
      ) : // Route item without children
      isProtected ? (
        <ProtectedRoute
          name={name}
          path={path}
          key={index}
          permissionUri={permissionUri}
          exact
          component={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ) : (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      )
  );

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        {childRoutes(DashboardLayout, dashboardRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </ScrollToTop>
  </Router>
);

const ProtectedRoute = ({
  permissionUri: PermissionUri,
  name: Name,
  component: Component,
  ...props
}) => (
  <Route
    {...props}
    render={(renderProps) =>
      Auth.getAuth() === true ? (
        Name === i18n.t('index.home') ? (
          <Component {...renderProps} />
        ) : Auth.hasPermission(PermissionUri, [READ, READWRITE]) === true ? (
          <Component {...renderProps} />
        ) : Auth.isAdmin() === true ? (
          <Component {...renderProps}></Component>
        ) : (
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        )
      ) : (
        window.location.replace(Url.LOGIN)
      )
    }
  />
);

export default Routes;
