import moment, { Moment } from 'moment';

import { ProjectState } from '../../../utils/enums/project';
import {
  OFFER_CONTRACT_TYPE_ENUM,
  OfferState,
} from '../../../utils/enums/offer';
import { IOfferDetails, IOfferListItem } from '../../../utils/types/responseTypes';
import { getDateFormat } from '../../../utils/helpers/date';
import i18n from '../../../i18n';
import Auth from '../../../services/axios/Auth';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { READ, READWRITE } from '../../../utils/constants';
import {
  IObjectOfferHistory,
  IProjectDetailOffer,
} from '../../../utils/types/commonTypes';
import { IAccountState } from '../../../utils/types/stateTypes';
import type { OfferDetailsForm } from './OfferDetailsProvider';
import { IFileAttachmentListItem } from '../../../utils/types/modelTypes';
import { dropdownOptionToObjectNameAndId, objectNameAndIdToDropdownOption } from '../../../utils/helpers/dropdown';

export const translate = (keyName: string) => i18n.t(`OfferDetails.${keyName}`);

export const DATE_FORMAT = getDateFormat();

/**
 * Function that creates a preview of Offer Document Title based on the
 * following paramaters if offerDocumentTitle is empty
 * @param {*} customerAbbreviation - customer abbreviation, if null, use "NA"
 * @param {*} offerTitle - title of the offer
 * @param {*} offerDate - date offer was created, date today as default value
 * @param {*} offerVersion - offer version
 */
export function createOfferDocumentTitle(
  customerAbbreviation: string,
  offerTitle: string,
  offerDate: string,
  offerVersion: number
) {
  const momentDate = moment(offerDate);
  return `INV_OFF_${customerAbbreviation}_${offerTitle}_${momentDate.format(
    'YYYY'
  )}-${momentDate.format('MM')}-${momentDate.format('DD')}_v${offerVersion}`;
}

/**
 * Checks if the offer is valid for sending.
 *
 * @param {object} project - The project object.
 * @param {object} offer - The offer object.
 * @returns {boolean} Returns true if the offer is valid for sending, otherwise false.
 */
export function isOfferValidToSend(projectState: string, offer: IOfferDetails) {
  return (
    offer?.id &&
    offer.offerState === OfferState.VALID &&
    projectState !== ProjectState.VAGUE
  );
}

/**
 * Function that concatinates the version with the title.
 * @param {*} offer containing the title and the version
 */
export function generateOfferTitle(title: string, version: number) {
  if (!title) {
    return '_v1';
  }
  return `${title}_v${version}`;
}

/**
 * A function to get the details of the date in mmmm-dd-yyyy format
 * @param offerDate
 * @returns null | string of the date offer created
 */
export const getDateDetails = (
  offerDate: Date | moment.Moment | string | undefined
) => (offerDate ? moment(offerDate).format(getDateFormat()).toString() : '');

/**
 * A function that concatenates the currencytype and budget in a string
 * @param currencyType
 * @param budget
 * @returns
 */
export const getOfferSum = (currencyType: string, budget: string) => {
  if (currencyType && budget) {
    return `${currencyType} ${budget}`;
  }
  if (currencyType) {
    return `${currencyType} (N/A)`;
  }
  return null;
};

/**
 * A function to calculate the valid until of an offer
 * @param offerState
 * @param offerSent
 * @param offerValidityPeriod
 * @returns a text in a date format
 */
export const getValidUntilDetails = (
  offerSent: string | undefined,
  offerValidityPeriod: number | undefined
) =>
  moment(offerSent)
    .add(offerValidityPeriod, 'days')
    .format(getDateFormat())
    .toString();

/**
 * A string to get the complete payment goal details in text
 * @param offerPaymentGoal
 * @returns returns the string concatenating the payment goal number and day
 */
export const getPaymentGoalDetails = (offerPaymentGoal: number) =>
  `${offerPaymentGoal} ${translate('days')}`;

/**
 * Check if it is an acceptable sent date, must not be less than the date of creation
 * @param {*} date
 * @returns boolean value of the if it is an acceptable sent date
 */
export const acceptedSentDate = (offerDate: Moment | string, date: Moment) => {
  const dateValidity = offerDate
    ? moment(date).format(DATE_FORMAT) >=
        moment(offerDate).format(DATE_FORMAT) &&
      date.day() !== 0 &&
      date.day() !== 6
    : date.day() !== 0 && date.day() !== 6;
  return dateValidity;
};

export const hasPermissionToAddOffer = () =>
  Auth.hasPermission([PERMISSION_URI.offer.readWrite.uri], [READ, READWRITE]);

export const contractTypeList = Object.values(OFFER_CONTRACT_TYPE_ENUM).map(
  (options) => ({ label: options.name, value: options.code })
);

export const generateDefaultFormValues = (
  { defaultCurrency }: IAccountState,
  { responsible, involved }: IProjectDetailOffer
) => ({
  title: '',
  contractType: '',
  currency: defaultCurrency,
  budget: 0,
  date: moment().startOf('day'),
  paymentGoal: 0,
  validityPeriod: 14,
  detail: '',
  responsible: objectNameAndIdToDropdownOption(responsible),
  involved: involved
    ? involved.map((indiv) => ({
        responsible: { value: indiv.id, label: indiv.name },
        responsibleRole: objectNameAndIdToDropdownOption(indiv.responsibleRole),
      }))
    : [],
});

export const generateDefaultOfferValues = ({
  contactPerson,
  customer,
  id,
}: IProjectDetailOffer) =>
  ({
    offerVersion: 1,
    offerState: OfferState.DRAFT,
    contactPerson,
    history: [] as IObjectOfferHistory[],
    offerFileAttachments: [] as IFileAttachmentListItem[],
    customerId: customer.id,
    projectId: id,
    offerSent: '',
  } as IOfferDetails);

export const createFormValueFromOfferDetails = ({
  title,
  contractType,
  date,
  currency,
  budget,
  paymentGoal,
  validityPeriod,
  detail,
  responsible,
  involved,
}: IOfferDetails) =>
  ({
    title,
    contractType,
    date,
    currency,
    budget,
    paymentGoal,
    validityPeriod,
    detail,
    responsible: objectNameAndIdToDropdownOption(responsible),
    involved: involved
      ? involved.map(({ id, name, responsibleRole }) => ({
          responsible: { value: id, label: name },
          responsibleRole: objectNameAndIdToDropdownOption(responsibleRole),
        }))
      : [],
  } as OfferDetailsForm);

export const createOfferListItemFromDetails = ({
  id,
  title,
  offerVersion,
  contractType,
  currency,
  budget,
  offerSent,
  offerState,
  date,
}: IOfferDetails) =>
  ({
    id,
    title,
    offerVersion,
    contractType,
    currency,
    budget,
    offerSent,
    offerState,
    date,
  } as IOfferListItem);

export const createOfferDetailsFromFormValues = (
  {
    id,
    offerVersion,
    contactPerson,
    projectId,
    customerId,
    offerSent,
    offerState,
  }: IOfferDetails,
  formValues: OfferDetailsForm
) => {
  const {
    title,
    contractType,
    currency,
    budget,
    date,
    paymentGoal,
    validityPeriod,
    detail,
    responsible,
    involved,
  } = formValues;
  return {
    id,
    title,
    offerVersion,
    contractType,
    currency,
    budget,
    offerSent,
    offerState,
    date,
    customerId,
    responsible: dropdownOptionToObjectNameAndId(responsible),
    involved: involved
      ? involved.map(({ responsible, responsibleRole }) => ({
          ...dropdownOptionToObjectNameAndId(responsible),
          responsibleRole: dropdownOptionToObjectNameAndId(responsibleRole),
        }))
      : [],
    contactPerson,
    paymentGoal,
    validityPeriod,
    detail,
    projectId,
  } as IOfferDetails;
};
