import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { withRouter } from 'react-router-dom';
import i18n from '../../i18n';

/*
 * Component for display contact person role
 * Can input an already save role or a role that
 * does not yet exist
 */
class CustomerRoleInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
    };
    this.index = this.props.index;
  }

  componentDidMount() {
    if (this.props.roleId) {
      let val = this.props.suggestions.find(
        (role) => role.id === parseInt(this.props.roleId)
      )?.role;
      if (val) {
        this.setState({ inputValue: val });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.componentDidMount();
    }
  }

  onChange = (event, value) => {
    this.setState({ inputValue: value });
    this.props.onChangeHandler(value, this.index);
  };

  render() {
    const suggestions = this.props.suggestions;
    const props = this.props;
    return (
      <Autocomplete
        aria-label="contact-person-role-auto-complete"
        id={props.id}
        inputValue={this.state.inputValue}
        onInputChange={(event, newInputValue) =>
          this.onChange(event, newInputValue)
        }
        freeSolo
        size={props.size}
        style={{ padding: 0 + 'px' }}
        options={suggestions.map((suggestion) => suggestion.role)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props?.label}
            margin="dense"
            variant="outlined"
            placeholder={i18n.t('ContactPersonFormInput.role')}
            InputProps={
              props.fromCustomer
                ? {
                    ...params.InputProps,
                    style: {
                      height: 28,
                      fontSize: 11,
                      padding: 0 + 'px',
                      color: 'gray',
                    },
                  }
                : { ...params.InputProps }
            }
          />
        )}
      />
    );
  }
}

export default withRouter(CustomerRoleInput);
