import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card, CardBody, Container, Input } from 'reactstrap';
import axios from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { handleError } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';
import ModalOK from '../../../components/modals/ModalOK';

/**
 * Class for Updating the Prioritization Target Goal
 *
 */
class UpdatePrioritizationTargetGoal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      priorityGoal: props?.priorityGoal ?? null,
      parsedPriorityGoalObject:
        JSON.parse(props?.priorityGoal.configValue) ?? null,
      error: null,
      showModalError: false,
      showModalOk: false,
    };
  }

  t(keyName) {
    return i18n.t('PrioritizationTargetGoal.' + keyName);
  }

  /**
   * Method for toggling the showModalError
   */
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  /**
   * Method for toggling the showModalOk
   */
  toggleModalOk = () => {
    this.setState({ showModalOk: !this.state.showModalOk });
  };

  /**
   * Handles the error
   * @param {*} mainError
   * @param {*} errorObject
   */
  handleError = (mainError, errorObject) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  /**
   * Handles updates on highPriority field of priorityGoal
   * @param {*} input
   */
  handleHighPriorityValueChange = (input) => {
    let priorityGoalObject = this.state.parsedPriorityGoalObject ?? null;

    // Puts the current priority back to the available allottable number
    const currentRemainingPriority =
      priorityGoalObject.highPriority + priorityGoalObject.remainingPriority;

    const valueInt = this.handleInput(input, currentRemainingPriority);
    priorityGoalObject.highPriority = valueInt;
    priorityGoalObject.remainingPriority = currentRemainingPriority - valueInt;
    this.setState({
      parsedPriorityGoalObject: priorityGoalObject,
    });
  };

  /**
   * Handles updates on midPriority field of priorityGoal
   * @param {*} input
   */
  handleMidPriorityValueChange = (input) => {
    let priorityGoalObject = this.state.parsedPriorityGoalObject ?? null;

    // Puts the current priority back to the available allottable number
    const currentRemainingPriority =
      priorityGoalObject.midPriority + priorityGoalObject.remainingPriority;

    const valueInt = this.handleInput(input, currentRemainingPriority);
    priorityGoalObject.midPriority = valueInt;
    priorityGoalObject.remainingPriority = currentRemainingPriority - valueInt;
    this.setState({
      parsedPriorityGoalObject: priorityGoalObject,
    });
  };

  /**
   * Handles updates on lowPriority field of priorityGoal
   * @param {*} input
   */
  handleLowPriorityValueChange = (input) => {
    let priorityGoalObject = this.state.parsedPriorityGoalObject ?? null;

    // Puts the current priority back to the available allottable number
    const currentRemainingPriority =
      priorityGoalObject.lowPriority + priorityGoalObject.remainingPriority;

    const valueInt = this.handleInput(input, currentRemainingPriority);
    priorityGoalObject.lowPriority = valueInt;
    priorityGoalObject.remainingPriority = currentRemainingPriority - valueInt;
    this.setState({
      parsedPriorityGoalObject: priorityGoalObject,
    });
  };

  /**
   * Handles updates on noPriority field of priorityGoal
   * @param {*} input
   */
  handleNoPriorityValueChange = (input) => {
    let priorityGoalObject = this.state.parsedPriorityGoalObject ?? null;

    // Puts the current priority back to the available allottable number
    const currentRemainingPriority =
      priorityGoalObject.noPriority + priorityGoalObject.remainingPriority;

    const valueInt = this.handleInput(input, currentRemainingPriority);
    priorityGoalObject.noPriority = valueInt;
    priorityGoalObject.remainingPriority = currentRemainingPriority - valueInt;
    this.setState({
      parsedPriorityGoalObject: priorityGoalObject,
    });
  };

  /**
   * Handles updates on tbdPriority field of priorityGoal
   * @param {*} input
   */
  handleTbdPriorityValueChange = (input) => {
    let priorityGoalObject = this.state.parsedPriorityGoalObject ?? null;

    // Puts the current priority back to the available allottable number
    const currentRemainingPriority =
      priorityGoalObject.tbdPriority + priorityGoalObject.remainingPriority;

    const valueInt = this.handleInput(input, currentRemainingPriority);
    priorityGoalObject.tbdPriority = valueInt;
    priorityGoalObject.remainingPriority = currentRemainingPriority - valueInt;
    this.setState({
      parsedPriorityGoalObject: priorityGoalObject,
    });
  };

  /**
   * Handles the input, converts it into an int and checks if it is greater than current available distribution
   * @param {*} input
   * @param {*} currentRemainingPriority
   * @returns An Integer value
   */
  handleInput = (input, currentRemainingPriority) => {
    let value = input.target.value;
    if (value.trim() === '') {
      value = '';
    }

    // parses the input to an integer otherwise will become an empty string
    let parsedValue = value ? parseInt(value) : '';

    // Makes the input the available distribution number if it exceeds it
    if (parsedValue > currentRemainingPriority) {
      parsedValue = currentRemainingPriority;
    }
    return parsedValue;
  };

  /**
   * Handles saves on priorityGoal
   */
  savePriorityGoal = async () => {
    let priorityGoal = this.state.priorityGoal;

    // Checks if the distribution of goals didn't reach to 100 then stop the save
    if (this.state.parsedPriorityGoalObject.remainingPriority > 0) {
      this.toggleModalOk();
      return;
    }

    // Converts the input/JSON object to a JSON string
    priorityGoal.configValue = JSON.stringify(
      this.state.parsedPriorityGoalObject
    );
    let savedPriorityGoal;
    if (priorityGoal.id) {
      savedPriorityGoal = await axios.serviceConfiguration
        .put('', priorityGoal)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.handleError(this.t('updateTargetPriorityFailed'), error);
        });
    }
    if (savedPriorityGoal && this.props.onSave) {
      this.props.onSave(savedPriorityGoal);
    }
  };

  /**
   * @param {*} priorityGoalObject
   * @returns True if all/some fields are empty otherwise False
   */
  hasEmptyFields = (priorityGoalObject) => {
    return (
      priorityGoalObject.highPriority === '' ||
      priorityGoalObject.midPriority === '' ||
      priorityGoalObject.lowPriority === '' ||
      priorityGoalObject.noPriority === '' ||
      priorityGoalObject.tbdPriority === ''
    );
  };

  render() {
    const priorityGoal = this.state.parsedPriorityGoalObject;
    return (
      <Container fluid>
        <Card>
          <CardBody>
            <InputFormLabel isRequired={true} text={this.t('highPriority')} />
            <Input
              min="0"
              bsSize="lg"
              type="number"
              onChange={this.handleHighPriorityValueChange}
              value={priorityGoal ? priorityGoal.highPriority : ''}
            ></Input>

            <br />

            <InputFormLabel isRequired={true} text={this.t('midPriority')} />
            <Input
              min="0"
              bsSize="lg"
              type="number"
              onChange={this.handleMidPriorityValueChange}
              value={priorityGoal ? priorityGoal.midPriority : ''}
            ></Input>

            <br />

            <InputFormLabel isRequired={true} text={this.t('lowPriority')} />
            <Input
              min="0"
              bsSize="lg"
              type="number"
              onChange={this.handleLowPriorityValueChange}
              value={priorityGoal ? priorityGoal.lowPriority : ''}
            ></Input>

            <br />

            <InputFormLabel isRequired={true} text={this.t('noPriority')} />
            <Input
              min="0"
              bsSize="lg"
              type="number"
              onChange={this.handleNoPriorityValueChange}
              value={priorityGoal ? priorityGoal.noPriority : ''}
            ></Input>

            <br />

            <InputFormLabel isRequired={true} text={this.t('tbdPriority')} />
            <Input
              min="0"
              bsSize="lg"
              type="number"
              onChange={this.handleTbdPriorityValueChange}
              value={priorityGoal ? priorityGoal.tbdPriority : ''}
            ></Input>

            <br />

            <div className="card-actions float-end">
              <Button
                color="primary"
                disabled={this.hasEmptyFields(priorityGoal)}
                onClick={() => this.savePriorityGoal()}
              >
                {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, this.t('submit'))}
              </Button>
            </div>
          </CardBody>
        </Card>
        <ModalOK
          isOpen={this.state.showModalOk}
          onClose={this.toggleModalOk}
          modalTitle={this.t('warning')}
          modalBodyText={this.t('warningText')}
        />

        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}

export default UpdatePrioritizationTargetGoal;
