import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Container,
} from 'reactstrap';

import axios from '../../../services/axios/axios';
import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { getNextSortType, isEmpty } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import SkillInsertOrUpdate from './SkillInsertOrUpdate';
import SkillListTable from './SkillListTable';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { ISkill } from '../../../utils/types/modelTypes';
import { DEFAULT_LOAD_TIMEOUT } from '../../../utils/constants';

/**
 * Edited by: Joseph Ortega
 * Edits made: Fix the bug the deletion of skill. Handle the update of skill and already assigned skillSet that uses this
 * deleted/retrieved skill
 *
 */

interface IProps extends IWithModalsProps, RouteComponentProps<any> {}

interface IState {
  skills: ISkill[];
  fetchedSkills: ISkill[];
  modifiedSkill: ISkill | null;
  removedSkillId: number | undefined;
  lastSkillFromPage: ISkill | null;

  hasMoreData: boolean;
  pageNumber: number;
  sortBy: string | null;
  sortType: string | null;
  showFilters: boolean;
}

class SkillListView extends React.Component<IProps, IState> {
  filters: {
    skill: string;
    skillTypes: [];
  };

  filterTimeOut: NodeJS.Timeout | number;

  fetchTimeOut: NodeJS.Timeout | number;

  constructor(props: IProps) {
    super(props);
    this.state = {
      skills: [],
      fetchedSkills: [],
      modifiedSkill: null,
      removedSkillId: undefined,
      lastSkillFromPage: null,

      hasMoreData: true,
      pageNumber: 0,
      sortBy: 'skill',
      sortType: 'desc',
      showFilters: false,
    };
    this.filters = {
      skill: '',
      skillTypes: [],
    };
    this.filterTimeOut = 0;
    this.fetchTimeOut = 0;
  }

  t(keyName: string) {
    return i18n.t(`SkillListView.${keyName}`);
  }

  createAxiosString = () => {
    const { pageNumber, sortBy, sortType } = this.state;
    let axiosString = `skills?page=${pageNumber}`;
    const { filters } = this;
    if (filters?.skill) {
      axiosString += `&skill.contains=${filters.skill}`;
    }
    if (!isEmpty(filters?.skillTypes)) {
      axiosString += `&type.in=${filters.skillTypes}`;
    }
    if (sortBy && sortType) {
      axiosString += `&sort=${sortBy}%2C${sortType}`;
    } else {
      axiosString += '&sort=skill%2Casc';
    }
    return axiosString;
  };

  fetchData = () => {
    const { modalErrorHandler } = this.props;
    const { pageNumber } = this.state;

    if (this.fetchTimeOut) {
      clearTimeout(this.fetchTimeOut);
    }
    this.fetchTimeOut = setTimeout(() => {
      const axiosString = this.createAxiosString();
      axios.employee
        .get(axiosString)
        .then((response) => {
          if (response.data.length > 0) {
            const fetchedSkills = response.data.sort((a: any, b: any) =>
              a.skill > b.skill ? 1 : -1
            );
            this.setState({
              fetchedSkills,
              pageNumber: pageNumber + 1,
              hasMoreData: !(response.data.length < 20),
            });
          } else {
            this.setState({
              hasMoreData: false,
            });
          }
        })
        .catch((error) => {
          modalErrorHandler(this.t('skill'), error);
        });
    }, 1000);
  };

  componentDidMount() {
    this.fetchData();
  }

  removeDuplicates = (newTableData: ISkill[]) => {
    const { skills } = this.state;
    const initialSkillSet = new Set(skills.map((skill) => skill?.id));
    return [
      ...skills,
      ...newTableData.filter((skill) => !initialSkillSet.has(skill?.id)),
    ];
  };

  setSkillsData = (fetchedSkills: ISkill[]) => {
    const { skills } = this.state;
    this.setState({
      skills: this.removeDuplicates([...skills, ...fetchedSkills]),
      fetchedSkills: [],
    });
  };

  onFilterUpdate = (filters: { skill: string; skillTypes: [] }) => {
    this.filters = filters;
    this.filterData();
  };

  filterData = () => {
    if (this.filterTimeOut) {
      clearTimeout(this.filterTimeOut);
    }
    this.filterTimeOut = setTimeout(() => {
      this.setState(
        {
          pageNumber: 0,
          hasMoreData: true,
          skills: [],
        },
        () => this.fetchData()
      );
    }, DEFAULT_LOAD_TIMEOUT);
  };

  sortData = (sortBy: string | null) => {
    const { sortBy: currentSortBy, sortType } = this.state;

    const nextSortType = getNextSortType(sortType, sortBy, currentSortBy);

    this.setState(
      {
        pageNumber: 0,
        hasMoreData: true,
        skills: [],
        sortType: nextSortType,
        sortBy: nextSortType === null ? null : sortBy,
      },
      () => {
        this.fetchData();
      }
    );
  };

  setStateAfterRemoval = (
    newSkills: ISkill[],
    skillId: number | undefined,
    lastSkillFromPage: ISkill | null
  ) => {
    this.setState({
      skills: newSkills,
      removedSkillId: skillId,
      lastSkillFromPage,
    });
  };

  removeSkillFromList = (skillId: number | undefined) => {
    const { modalErrorHandler } = this.props;
    const { skills } = this.state;

    let newSkills = skills.filter((skill) => skill?.id !== skillId);
    let lastSkillFromPage: ISkill | null = null;
    const axiosString = this.createAxiosString();

    axios.employee
      .get(axiosString)
      .then((response) => {
        if (response.data.length > 0) {
          const lastItemInPage = response.data[response.data.length - 1];

          const lastItemInPageIndex = newSkills.findIndex(
            (skill) =>
              parseInt(skill?.id?.toString() ?? '') ===
              parseInt(lastItemInPage?.id)
          );

          if (lastItemInPageIndex < 0) {
            newSkills = [...newSkills, lastItemInPage];
            lastSkillFromPage = lastItemInPage;
          }
        }
        this.setStateAfterRemoval(newSkills, skillId, lastSkillFromPage);
      })
      .catch((error) => {
        modalErrorHandler(this.t('skill'), error);
      });
  };

  deleteSkill = (skill: number | undefined) => {
    const { history, modalDeleteHandler, modalErrorHandler } = this.props;
    modalDeleteHandler(
      this.t('deleteSkill'),
      this.t('deleteSkillConfirm'),
      () =>
        axios.employee
          .delete(`skills/${skill}`)
          .then(() => {
            const ID_TO_EDIT = '<id>';
            const messageToEdit = this.t('skillDeleted');
            const updatedMessage = messageToEdit.replace(
              ID_TO_EDIT,
              skill?.toString() ?? ''
            );
            history.push({
              pathname: '/settings/skills',
              state: {
                successMessage: updatedMessage,
              },
            });
            this.removeSkillFromList(skill);
          })
          .catch((error) => {
            modalErrorHandler(this.t('skill'), error);
          })
    );
  };

  showInputModal = () => {
    const { modalFormHandler } = this.props;
    modalFormHandler(
      this.t('addSkill'),
      <SkillInsertOrUpdate onSave={this.onSave} onCancel={this.onCancel} />
    );
  };

  onSave = () => {
    const { toggleModalForm, modalErrorHandler } = this.props;
    const { pageNumber } = this.state;
    toggleModalForm();

    axios.employee
      .get('skills')
      .then((response) => {
        if (response.data.length > 0) {
          const fetchedSkills = response.data.sort((a: any, b: any) =>
            a.skill > b.skill ? 1 : -1
          );
          this.setState({
            fetchedSkills,
            pageNumber: pageNumber + 1,
            hasMoreData: !(response.data.length < 20),
          });
        } else {
          this.setState({
            hasMoreData: false,
          });
        }
      })
      .catch((error) => {
        modalErrorHandler(this.t('skill'), error);
      });
  };

  onCancel = () => {
    const { toggleModalForm } = this.props;
    toggleModalForm();
  };

  toggleShowFilters() {
    const { showFilters } = this.state;
    this.setState({ showFilters: !showFilters });
  }

  render() {
    const { location } = this.props;
    const {
      skills,
      fetchedSkills,
      removedSkillId,
      lastSkillFromPage,
      hasMoreData,
      sortBy,
      sortType,
      showFilters,
      modifiedSkill,
    } = this.state;

    return (
      <Container fluid>
        <Header>
          <HeaderTitle>Skills List</HeaderTitle>
          {generateBreadcrumb(
            location.pathname,
            i18n.t('EmployeeSettingsView.settings')
          )}
        </Header>
        <Card>
          <CardHeader>
            <div className="card-actions float-end">
              <Button
                color="primary"
                className="fontAwesomeIconAsButton"
                onClick={() => this.toggleShowFilters()}
              >
                <FontAwesomeIcon icon={faFilter} />
              </Button>{' '}
              <Button
                color="primary"
                size="m"
                onClick={() => this.showInputModal()}
              >
                {generateTitle(BUTTON_TITLE_ENUM.ADD.code, this.t('add'))}
              </Button>
            </div>
            <CardTitle className="mb-0">
              <h1>Skills List</h1>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <SkillListTable
              skills={skills}
              fetchData={this.fetchData}
              hasMoreData={hasMoreData}
              fetchedSkills={fetchedSkills}
              setSkillsData={this.setSkillsData}
              onFilterUpdate={this.onFilterUpdate}
              sortData={this.sortData}
              sortType={sortType}
              sortBy={sortBy}
              showFilters={showFilters}
              removedSkillId={removedSkillId}
              lastSkillFromPage={lastSkillFromPage}
              onDelete={this.deleteSkill}
              modifiedSkill={modifiedSkill}
            />
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default withRouter(withModals(SkillListView));
