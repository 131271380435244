import {
  faBookOpen,
  faEye,
  faEyeSlash,
  faPhone,
  faFilter,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import FileSaver from 'file-saver';
import moment from 'moment';
import 'moment/min/locales';
import Datetime from 'react-datetime';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import React, { useState, useEffect } from 'react';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import {
  DELETE,
  READ,
  READWRITE,
  SAVE_AND_GOTO_DETAILS,
} from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { AccessType, PERMISSION_URI } from '../../../utils/enums/permission';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import { isNullOrUndefinedOrEmpty } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import { loadCallingList } from '../../../redux/callingSlice';
import { RootState } from '../../../redux/store';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import {
  IContactPerson,
  IExtendedContactPerson,
} from '../../../utils/types/modelTypes';
import { Ownership } from '../../../utils/enums/ownership';
import ResponsibleOwnershipDropdown from '../../../components/dropdowns/ResponsibleOwnershipDropdown';
import { translate } from './contactListHelpers';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  deleteContactPerson,
  fetchContactPersonListItem,
  fetchEmployeesWithContactPersonListPermission,
  setFilters,
  toggleFilter,
} from '../../../redux/contactPersonListSlice';
import ContactListTable from './ContactListTable';
import { isEmpty } from '../../../utils/helpers/array';
import FadeAlert from '../../../components/layout/FadeAlert';
import Auth from '../../../services/axios/Auth';
import ExportContactToOutlookModalForm from './ExportContactToOutlookModalForm';
import { getDateFormat } from '../../../utils/helpers/date';
import ContactListCallingModal from './ContactListCallingModal';
import {
  exportAllContactsToOutlook,
  postExtendedContactPerson,
  updateContactPerson,
  getStatisticsContactPeopleUpdateCreation,
  setContactPersonFlag,
} from '../../../services/api/contactPerson';
import {
  IContactPersonDetail,
  IContactPersonListItem,
} from '../../../utils/types/responseTypes';
import ContactPersonModal from '../../../components/form/ContactPersonModal';

const DATE_FORMAT = getDateFormat();

enum ReportDateType {
  START = 'START',
  END = 'END',
}

/**
 * This class displays the overview of "My Contacts"
 */
interface IProps
  extends IWithModalsProps,
    PropsFromRedux,
    RouteComponentProps {}

const ContactList: React.FC<IProps> = ({
  // WithModals
  modalDeleteHandler,
  modalErrorHandler,
  modalFormHandler,
  modalOkHandler,
  toggleModalForm,
  // Route
  history,
  location,
  loadCallingList,
  // Redux State
  contactPersonList,
  filters,
  employeesWithContactPersonListPermission,
  // Redux actions
  setFilters,
  toggleFilter,
  // Thunks
  deleteContactPerson,
  fetchContactPersonListItem,
  fetchEmployeesWithContactPersonListPermission,
}) => {
  const [showCallingReport, setShowCallingReport] = useState<boolean>(false);
  const [callingReportDateStart, setCallingReportDateStart] =
    useState<Date | null>(null);
  const [callingReportDateEnd, setCallingReportDateEnd] = useState<Date | null>(
    null
  );
  const [showColumnToggler, setShowColumnToggler] = useState<boolean>(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');
  const [
    showExportMyContactsToOutlookModal,
    setShowExportMyContactsToOutlookModal,
  ] = useState<boolean>(false);

  /**
   * Displays a modal dialog with a message indicating that there is no one to call.
   */
  const toggleNoOneToCallModal = () => {
    modalOkHandler('', translate('noOneToCall'));
  };

  /*
   * This part should the handle restriction to call a contact by a multiple employees at the same time.
   */
  const flagContact = (totalCalls: IExtendedContactPerson[]) => {
    setContactPersonFlag(
      true,
      totalCalls.map(({ contactPerson: { id } }) => id ?? 0)
    ).catch((error) => {
      modalErrorHandler(translate('failedToSetFlag'), error);
    });
  };

  /**
   * Creates a new call list and navigates to the first contact person in the call list.
   *
   * @param axiosString The axios string to use to create the call list.
   * @param payload The payload to send to the axios request.
   */
  const createCallingList = async (
    payload: any,
    queryParameters: { [key: string]: any }
  ) => {
    try {
      const response = await postExtendedContactPerson(
        queryParameters,
        payload
      );
      const totalCalls = response.data;
      if (!isEmpty(totalCalls)) {
        loadCallingList(totalCalls);
        history.push({
          pathname: `/customer/my-contacts/profile/${totalCalls[0]?.contactPerson.id}`,
          state: {
            callMode: true,
          },
        });
        flagContact(totalCalls);
        localStorage.setItem('callList', JSON.stringify(totalCalls));
      } else {
        toggleNoOneToCallModal();
      }
    } catch (error) {
      modalErrorHandler(translate('failedToCreateCallList'), error);
    }
  };

  /**
   *  Shows the modal for creating a new call list.
   */
  const showContactListCallingModal = () => {
    modalFormHandler(
      translate('createCallList'),
      <ContactListCallingModal
        confirm={createCallingList}
        toggle={toggleModalForm}
        modal
      />,
      'xl'
    );
  };

  /**
   * Gets the newly saved contact person
   *
   * @param contactPersonId The ID of the contact person.
   */
  const getSavedContactPerson = (contactPersonId: number) => {
    fetchContactPersonListItem(contactPersonId, () => (error: AxiosError) => {
      modalErrorHandler(
        translate('failedToUpdateContactPersonListItems'),
        error
      );
    });
  };

  /**
   * Updates the contact person list item containing the new added contact person.
   *
   * @param contactPerson The new added contact person.
   * @param saveAction The save action that was performed.
   */
  const onUpdateContactPersonList = (
    contactPerson: IContactPersonDetail,
    saveAction?: string
  ) => {
    const { id: contactPersonId } = contactPerson;
    if (contactPersonId) {
      getSavedContactPerson(contactPersonId);
      if (saveAction === SAVE_AND_GOTO_DETAILS) {
        history.push({
          pathname: `/customer/my-contacts/profile/${contactPersonId}`,
        });
      }
    }
    toggleModalForm();
  };

  /**
   * Displays a modal dialog to confirm the deletion or removal of a contact person.
   *
   * @param contactPersonId The ID of the contact person to delete or remove.
   * @param action The action to perform: "DELETE" or "REMOVE".
   */
  const handleDeleteOrRemove = (
    contactPersonListItem: IContactPersonListItem,
    action: string
  ) => {
    modalDeleteHandler(
      translate('deleteContact'),
      translate('contactConfirmDelete'),
      action === DELETE
        ? () => {
            deleteContactPerson(
              contactPersonListItem.id,
              () => {
                setDeleteSuccessMessage(
                  `${translate('contactPersonDeleted')} - ${
                    contactPersonListItem.id
                  }.`
                );
              },
              (error: AxiosError) => {
                modalErrorHandler(
                  translate('failedToDeleteContactPerson'),
                  error
                );
              }
            );
          }
        : async () => {
            try {
              const updatedContactPerson = {
                id: contactPersonListItem.id,
                customerSite: null,
                lastname: contactPersonListItem.lastname,
                firstname: contactPersonListItem.firstname,
              } as IContactPerson;
              await updateContactPerson(updatedContactPerson);
              getSavedContactPerson(contactPersonListItem.id);
            } catch (error) {
              modalErrorHandler(
                translate('failedToRemoveContactPerson'),
                error
              );
            }
          }
    );
  };

  /**
   * Exports all contacts to Outlook.
   */
  const exportMyContactsToOutlook = async () => {
    // Get contact person ids from the extended contact persons
    const contactPersonIdsFromListItem = contactPersonList.map(
      (contactPersonItem) => contactPersonItem.id
    );
    try {
      await exportAllContactsToOutlook(contactPersonIdsFromListItem);
    } catch (error) {
      modalErrorHandler(translate('failedToExportContactToOutlook'), error);
    }
  };

  /**
   * Toggles the visibility of the export my contacts to outlook modal.
   */
  const toggleShowExportMyContactsModal = () => {
    setShowExportMyContactsToOutlookModal(!showExportMyContactsToOutlookModal);
  };

  /**
   * Displays the create new contact modal.
   *
   */
  const showCreateNewContactModal = () => {
    modalFormHandler(
      translate('addContactPerson'),
      <ContactPersonModal
        onSave={onUpdateContactPersonList}
        disableCustomerAndCustomerSite={false}
        onCancel={toggleModalForm}
        noSaveDetails={false}
      />,
      'xl'
    );
  };

  /**
   * Handles report date.
   *
   * @param data The new report date.
   * @param type The type of report date: "start" or "end".
   */
  const changeReportDate = (data: Date, type: string) =>
    type === ReportDateType.START
      ? setCallingReportDateStart(data)
      : setCallingReportDateEnd(data);

  /**
   * Generates a report of contact people created or updated within a specified date range.
   *
   * @param startDate The start date of the date range.
   * @param endDate The end date of the date range.
   * @param language The language of the report.
   */
  const generateReport = async () => {
    // Get the dates
    const startDate = moment(callingReportDateStart).format('YYYY-MM-DD');
    const endDate = moment(callingReportDateEnd).format('YYYY-MM-DD');
    // Get the required language to the excel sheet
    const language =
      i18n.language !== undefined ? i18n.language?.toUpperCase() : 'EN';
    const queryParameters: { [key: string]: any } = {
      start: startDate,
      end: endDate,
      lang: language,
    };

    await getStatisticsContactPeopleUpdateCreation(queryParameters)
      .then(({ data }) => {
        FileSaver(
          data,
          `Contacts_created_from_${startDate}_to_${endDate}_${language}.xlsx`
        );
      })
      .catch((error) => {
        modalErrorHandler(translate('loadRecordFailed'), error);
      });
  };

  /**
   * Handles the ownership filter.
   *
   * @param ownership The selected ownership value.
   */
  const handleOwnershipFilter = (ownership: Ownership | Ownership[]) => {
    if (
      ownership === Ownership.ALL ||
      ownership === Ownership.ALL.toLowerCase()
    ) {
      setFilters({
        ...filters,
        ownership: [],
        responsibleWithPermission: null,
      });
    } else {
      setFilters({
        ...filters,
        ownership: Array.isArray(ownership) ? ownership : [ownership],
      });
    }
  };

  /**
   * Handles the responsible filter
   * @param responsible The selected responsible value
   */
  const handleResponsibleFilter = (responsible: IDropdownOption<number>) => {
    setFilters({
      ...filters,
      responsibleWithPermission: responsible,
    });
  };

  /**
   * Checks if the user has the permission to create a contact person.
   */
  const hasCreatePermission = Auth.hasPermission(
    [PERMISSION_URI.contactPeopleList.readWrite.uri],
    [READWRITE]
  );

  /**
   * Checks if the user has the permission to export contact persons.
   */
  const hasExportPermission = Auth.hasPermission(
    [PERMISSION_URI.contactPeopleList.readWrite.uri],
    [READ]
  );

  /**
   * Checks if the current date is less than or equal to the calling report end date.
   * @param current The current date.
   * @returns A boolean value indicating whether the current date is less than or equal to the calling report end date.
   */
  const from = (current: Date) =>
    callingReportDateEnd
      ? moment(current).isSameOrBefore(callingReportDateEnd)
      : true;

  /**
   * Checks if the current date is greater than or equal to the calling report start date.
   *
   * @param current The current date.
   * @returns A boolean value indicating whether the current date is greater than or equal to the calling report start date.
   */
  const until = (current: Date) =>
    callingReportDateStart
      ? moment(current).isSameOrAfter(callingReportDateStart)
      : true;

  /**
   * Links to the calling statistics page.
   */
  const linkToCallingStatistics = () => {
    history.push({ pathname: '/customer/calling-statistics' });
  };

  // Fetch employees with contact people list permission for filter dropdown on component mount
  useEffect(() => {
    if (isEmpty(employeesWithContactPersonListPermission)) {
      fetchEmployeesWithContactPersonListPermission((error) => {
        modalErrorHandler(translate('failedToRetrieveEmployees'), error);
      });
    }
  }, []);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{translate('myContacts')}</HeaderTitle>
        {generateBreadcrumb(location.pathname, translate('customer'))}
      </Header>
      <Row>
        <Col>
          <Card className="flex-fill w-100">
            <CardHeader>
              {deleteSuccessMessage && (
                <FadeAlert color="success">{deleteSuccessMessage}</FadeAlert>
              )}
              <CardTitle className="mb-0">
                <h1>
                  {translate('myContacts')}
                  <div className="my-contacts-card-buttons">
                    {hasExportPermission && (
                      <Button
                        color="primary"
                        onClick={() => toggleShowExportMyContactsModal()}
                      >
                        {translate('exportMyContactsToOutlook')}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      className="fontAwesomeIconAsButton"
                      onClick={() => toggleFilter()}
                      aria-label="button-filter"
                    >
                      <FontAwesomeIcon icon={faFilter} />
                    </Button>

                    <Button
                      color="primary"
                      onClick={() => setShowColumnToggler(!showColumnToggler)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                      /
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        showContactListCallingModal();
                      }}
                      style={{ paddingButton: '10px' }}
                    >
                      <FontAwesomeIcon
                        icon={faPhone}
                        className="margin-right"
                      />
                      {translate('startCalling')}
                    </Button>
                    {hasCreatePermission && (
                      <Button
                        color="primary"
                        onClick={() => showCreateNewContactModal()}
                      >
                        {generateTitle(
                          BUTTON_TITLE_ENUM.ADD.code,
                          translate('add')
                        )}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      onClick={() => linkToCallingStatistics()}
                    >
                      <FontAwesomeIcon
                        icon={faBookOpen}
                        className="margin-right"
                      />
                      {translate('callHistory')}
                    </Button>
                    {Auth.isAdmin() && (
                      <Button
                        color="primary"
                        onClick={() => setShowCallingReport(!showCallingReport)}
                      >
                        {translate('generateReports')}
                      </Button>
                    )}
                  </div>
                </h1>
                {Auth.isAdmin() && showCallingReport && (
                  <div className="card-actions float-end">
                    {translate('startDate')}
                    <Datetime
                      dateFormat={DATE_FORMAT}
                      input
                      timeFormat={false}
                      inputProps={{ placeholder: DATE_FORMAT }}
                      closeOnSelect
                      onChange={(event: any) =>
                        changeReportDate(event, ReportDateType.START)
                      }
                      isValidDate={from}
                      locale={i18n.language}
                    />
                    <br />
                    {translate('endDate')}
                    <Datetime
                      dateFormat={DATE_FORMAT}
                      input
                      timeFormat={false}
                      inputProps={{ placeholder: DATE_FORMAT }}
                      closeOnSelect
                      onChange={(event: any) =>
                        changeReportDate(event, ReportDateType.END)
                      }
                      isValidDate={until}
                      locale={i18n.language}
                    />
                    <br />
                    <Button
                      color="primary"
                      style={{ paddingButton: '10px' }}
                      onClick={() => generateReport()}
                      disabled={
                        isNullOrUndefinedOrEmpty(callingReportDateEnd) ||
                        isNullOrUndefinedOrEmpty(callingReportDateStart)
                      }
                    >
                      {translate('generateCallingReport')}
                    </Button>
                  </div>
                )}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <ResponsibleOwnershipDropdown
                    includeResponsibleAndInvolvedOption
                    value={filters.ownership}
                    onChange={handleOwnershipFilter}
                  />
                </Col>
                {Auth.hasPermission(
                  [PERMISSION_URI.fullContactPeopleList.read.uri],
                  [AccessType.READ]
                ) && (
                  <Col md={4}>
                    <Select
                      value={filters.responsibleWithPermission}
                      options={employeesWithContactPersonListPermission}
                      onChange={handleResponsibleFilter}
                      styles={{
                        menu: (styles: {
                          [key: string]: React.CSSProperties;
                        }) => ({ ...styles, zIndex: 100 }),
                      }}
                      isDisabled={filters.ownership === Ownership.ALL}
                    />
                  </Col>
                )}
              </Row>
              <br />
              <ContactListTable
                showColumnToggler={showColumnToggler}
                onDeleteOrRemove={handleDeleteOrRemove}
              />
              <ExportContactToOutlookModalForm
                isOpen={showExportMyContactsToOutlookModal}
                modalTitle={translate('exportMyContactsToOutlook')}
                event={exportMyContactsToOutlook}
                modalBodyText={translate('exportAllContactsToOutlookMessage')}
                onClose={toggleShowExportMyContactsModal}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  deleteContactPerson,
  account: store.account,
  calling: store.calling,
  contactPersonList: store.contactPersonList.listItems,
  employeesWithContactPersonListPermission:
    store.contactPersonList.additionalState?.employeesWithPermission,
  filters: store.contactPersonList.filters,
});
const mapDispatchToProps = {
  loadCallingList,
  deleteContactPerson,
  fetchContactPersonListItem,
  fetchEmployeesWithContactPersonListPermission,
  toggleFilter,
  setFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withModals(ContactList)));
