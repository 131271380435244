import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { IAlert } from '../utils/types/commonTypes';
import { IAlertsState } from '../utils/types/stateTypes';
import type { AppThunk } from './store';

const initialState: IAlertsState = {
  alerts: [],
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlert: {
      reducer: (state, { payload }: PayloadAction<IAlert>) => ({
        ...state,
        alerts: [...state.alerts, payload],
      }),
      prepare: (id: string, msg: string, alertType: string) => ({
        payload: { id, msg, alertType },
      }),
    },
    removeAlert: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      alerts: state.alerts.filter(({ id }) => id !== payload),
    }),
  },
});

export const { setAlert, removeAlert } = alertsSlice.actions;

export const setThenRemoveAlert =
  (msg: string, alertType: string, timeout = 5000): AppThunk =>
  (dispatch) => {
    const id = uuidv4();

    dispatch(setAlert(id, msg, alertType));
    setTimeout(() => dispatch(removeAlert(id)), timeout);
  };

export default alertsSlice.reducer;
