import i18n from '../../i18n';

/**
 * @deprecated Use DepartmentStatus enum and enumValuesToDropdownOptions
 */
export const FIELD_OF_BUSINESS_AND_DEPARTMENT_STATE_ENUM = {
  active: {
    value: 'ACTIVE',
    label: 'Active',
  },
  planned: {
    value: 'PLANNED',
    label: 'Planned',
  },
  archived: {
    value: 'ARCHIVED',
    label: 'Archived',
  },
};

export enum DepartmentStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  PLANNED = 'PLANNED',
}

/**
 * @deprecated Use DepartmentStatus enum
 */
export const DEPARTMENT_STATUS_ENUM = {
  active: {
    code: 'ACTIVE',
    name: i18n.t('DepartmentState.active'),
  },
  planned: {
    code: 'PLANNED',
    name: i18n.t('DepartmentState.planned'),
  },
  archived: {
    code: 'ARCHIVED',
    name: i18n.t('DepartmentState.archived'),
  },
};
