import React, { ChangeEvent, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Form, ModalBody, Input, Label, Button, ModalFooter } from 'reactstrap';

import { ISSUE_TO_SOLVE } from '../../../utils/constants';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { saveInfoGdpr } from '../../../redux/gdprListSlice';
import { RootState } from '../../../redux/store';
import { IGdprListItem } from '../../../utils/types/responseTypes';
import { createInitialInfoGdpr, t } from './gdprOverviewHelpers';

interface IProps extends IWithModalsProps, PropsFromRedux {
  gdprListItem: IGdprListItem;
  solveOrUnsolve: string;
  toggleModalFormProp: () => void;
}

/**
 * Component that shows the Issue Solving/Unsolving Modal
 */
const IssueSolvingModal = ({
  gdprListItem,
  solveOrUnsolve,
  toggleModalFormProp,
  // WithModals
  modalErrorHandler,
  // Redux State
  account,
  // Thunks
  saveInfoGdpr,
}: IProps) => {
  const [descriptionOfSolution, setDescriptionOfSolution] =
    useState<string>('');

  /**
   * Call the thunk for solving or unsolving the contact person's
   * GDPR
   * @param contactPersonToUpdate contact person to update
   */
  const solveOrUnsolveGdpr = (contactPersonToUpdate: IGdprListItem) => {
    saveInfoGdpr(contactPersonToUpdate, solveOrUnsolve, (error) => {
      modalErrorHandler(
        solveOrUnsolve === ISSUE_TO_SOLVE
          ? 'failedToSolveIssue'
          : 'failedToUnsolveIssue',
        error
      );
    });
    toggleModalFormProp();
  };

  /**
   * Triggered on solving an issue or confirming an unsolve
   */
  const onSave = () => {
    const contactPersonToUpdate = JSON.parse(JSON.stringify(gdprListItem));
    if (solveOrUnsolve === ISSUE_TO_SOLVE && contactPersonToUpdate) {
      contactPersonToUpdate.infoGdpr = JSON.stringify(
        createInitialInfoGdpr(
          contactPersonToUpdate,
          descriptionOfSolution,
          account.employeeDetails.userId
        )
      );
    }
    solveOrUnsolveGdpr(contactPersonToUpdate);
  };

  return (
    <>
      <ModalBody>
        {solveOrUnsolve === ISSUE_TO_SOLVE ? (
          <Form>
            <Label>{t('descriptionOfSolution')}</Label>
            <Input
              id="descriptionOfSolution"
              aria-label="description-textarea"
              type="textarea"
              value={descriptionOfSolution}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setDescriptionOfSolution(event.target.value)
              }
            />
          </Form>
        ) : (
          t('solveToUnsolveConfirm')
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="dark"
          disabled={
            solveOrUnsolve === ISSUE_TO_SOLVE &&
            descriptionOfSolution.trim().length === 0
          }
          onClick={onSave}
        >
          {solveOrUnsolve === ISSUE_TO_SOLVE ? t('solve') : t('confirm')}
        </Button>{' '}
        <Button color="dark" onClick={() => toggleModalFormProp()}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  account: store.account,
});

const mapDispatchToProps = {
  saveInfoGdpr,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(IssueSolvingModal));
