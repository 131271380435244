import moment from 'moment';
import React from 'react';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Input, Row } from 'reactstrap';
import InputFormLabel from '../../../components/form/InputFormLabel';
import {
  defaultWorkingHoursPerDay,
  regexExpressionDouble,
} from '../../../utils/constants';
import { ProjectEmployeeWorkloadState } from "../../../utils/enums/project";
import { getDateFormat, getEmployees } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import ModalError from '../../../components/modals/ModalError';

/**
 * This class shows the form for adding/updating project employee workload to the project.
 *
 * Edited by: Zoren Alvano
 * date: 24/08/2021
 * changes: Allow decimal input in workload and workload percentage
 */
class ProjectEmployeeWorkloadFormInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectEmployeeWorkload: {
        id: props.projectEmployeeWorkload?.id ?? null,
        endDate:
          props.projectEmployeeWorkload?.endDate && !props?.extend
            ? moment(props.projectEmployeeWorkload.endDate)
            : null,
        projectEmployee:
          props.projectEmployeeWorkload?.projectEmployee ??
          props.projectEmployee ??
          null,
        startDate:
          props.projectEmployeeWorkload?.startDate && props?.extend
            ? moment(props.projectEmployeeWorkload?.end).add(1, 'day')
            : moment(new Date()),
        workloadState: props.extend
          ? ProjectEmployeeWorkloadState.RESERVED
          : props.projectEmployeeWorkload?.workloadState ??
            ProjectEmployeeWorkloadState.RESERVED,
        workload:
          props.projectEmployeeWorkload?.workload ??
          props?.employee?.workhoursPerDay,
        workloadPercentage:
          props.projectEmployeeWorkload?.workloadPercentage ??
          props?.employee?.workloadPercentage,
      },
      hours: props?.employee?.workhoursPerDay
        ? props.employee.workhoursPerDay
        : defaultWorkingHoursPerDay,
      employees: [],
      dateFormat: getDateFormat(),

      showModalError: false, // toggle modal error form
      employeeChoices: [],
      project: props.project ?? null,
    };

    this.isUpdate = props.projectEmployeeWorkload?.id ? true : false;

    this.mainError = '';
    this.errorReason = '';
    this.errorResponse = '';

    this.modalTitle = '';
  }

  //Enables a toggle to appear when an error is encountered.
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  t(keyName) {
    return i18n.t('AddOrUpdateProjectEmployeeWorkload.' + keyName);
  }

  //Loads up data for the appointment form
  async componentDidMount() {
    // Get all employees
    var employees;
    if (this.props.employees.employees.length === 0) {
      employees = await getEmployees().catch((error) => {
        let mainError = this.t('failedToRetrieveEmployees');
        this.handleError(mainError, error.message);
      });
    } else {
      employees = this.props.employees.employees;
    }
    if (Array.isArray(employees)) {
      let options = employees.map((employee) => ({
        value: employee.id,
        label: `${employee.name} ${employee.lastname}`,
      }));
      this.setState({ employees: employees, employeeChoices: options });
    }
  }

  // Handles caught error
  handleError = (mainError, errorMessage) => {
    this.modalTitle = this.t('error');
    this.mainError = mainError;
    this.errorReason = this.t('serverFailed');
    this.errorResponse = errorMessage;
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  // Updates the parent component for the changes in data
  onChange = (projectEmployeeWorkload) => {
    this.setState({ projectEmployeeWorkload: projectEmployeeWorkload });
    if (this.props.onChange) {
      this.props.onChange(projectEmployeeWorkload);
    }
  };

  handleStartDateChange = (start) => {
    let projectEmployeeWorkload = this.state.projectEmployeeWorkload;
    projectEmployeeWorkload.startDate = moment(start);
    this.onChange(projectEmployeeWorkload);
  };

  handleEndDateChange = (end) => {
    let projectEmployeeWorkload = this.state.projectEmployeeWorkload;
    projectEmployeeWorkload.endDate = moment(end);
    this.onChange(projectEmployeeWorkload);
  };

  acceptedDateStart = (date) => {
    let projectEmployeeWorkload = this.state.projectEmployeeWorkload;
    return projectEmployeeWorkload.endDate
      ? date <= moment(projectEmployeeWorkload.endDate)
      : date;
  };

  acceptedDateEnd = (date) => {
    let projectEmployeeWorkload = this.state.projectEmployeeWorkload;
    return projectEmployeeWorkload.startDate
      ? date >= moment(projectEmployeeWorkload.startDate)
      : date;
  };

  handleHoursChange = (hours) => {
    let totalBudgetHour = hours.target.value;
    if (totalBudgetHour.trim() === '') {
      totalBudgetHour = '0';
    }
    let workload = parseFloat(totalBudgetHour);
    if (
      totalBudgetHour.match(regexExpressionDouble) &&
      workload !== undefined
    ) {
      let projectEmployeeWorkload = this.state.projectEmployeeWorkload;
      projectEmployeeWorkload.workload = parseFloat(totalBudgetHour).toString();
      this.onChange(projectEmployeeWorkload);
    }
  };

  handleWorkloadPercentageChange = (workloadPercentage) => {
    let value = workloadPercentage.target.value;
    if (value.trim() === '') {
      value = '0';
    }
    const valueFloat = parseFloat(value);
    if (
      value.match(regexExpressionDouble) &&
      valueFloat !== undefined &&
      valueFloat <= 100
    ) {
      let projectEmployeeWorkload = this.state.projectEmployeeWorkload;
      projectEmployeeWorkload.workloadPercentage = valueFloat?.toString();
      this.onChange(projectEmployeeWorkload);
    }
  };

  render() {
    const projectEmployeeWorkload = this.state.projectEmployeeWorkload;
    const dateFormat = this.state.dateFormat;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <InputFormLabel text={this.t('dateStart')} />
            <Datetime
              dateFormat={dateFormat}
              input={true}
              timeFormat={false}
              closeOnSelect={true}
              inputProps={{ placeholder: dateFormat }}
              onChange={this.handleStartDateChange}
              value={
                moment(
                  projectEmployeeWorkload?.startDate,
                  dateFormat,
                  true
                ).isValid()
                  ? moment(projectEmployeeWorkload?.startDate).format(
                      dateFormat
                    )
                  : projectEmployeeWorkload?.startDate
              }
              isValidDate={this.acceptedDateStart}
              locale={i18n.language}
            />
            <br />
            <InputFormLabel isRequired text={this.t('dateEnd')} />
            <Datetime
              dateFormat={dateFormat}
              input={true}
              timeFormat={false}
              closeOnSelect={true}
              inputProps={{ placeholder: dateFormat }}
              onChange={this.handleEndDateChange}
              value={projectEmployeeWorkload?.endDate}
              isValidDate={this.acceptedDateEnd}
              locale={i18n.language}
            />
            <br />
            <InputFormLabel text={this.t('totalWorkloadHours')} />
            <Input
              className="form-control"
              type="number"
              onChange={this.handleHoursChange}
              value={projectEmployeeWorkload.workload}
              required={true}
            />
            <br />
            <InputFormLabel text={this.t('workloadPercentage')} />
            <Input
              className="form-control"
              max="100"
              type="number"
              onChange={this.handleWorkloadPercentageChange}
              value={projectEmployeeWorkload.workloadPercentage ?? '0'}
              required={true}
            />
          </Col>
        </Row>
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.mainError}
          errorReason={this.errorReason}
          errorResponse={this.errorResponse}
          modalTitle={this.t('error')}
        ></ModalError>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => ({
  employees: store.employees,
});

export default connect(mapStateToProps)(
  withRouter(ProjectEmployeeWorkloadFormInput)
);
