import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import i18n from '../../i18n';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import { getCountryOptions } from '../../services/api/campaignManager';
import {
  IDropdownOption,
  IObjectNameAndId,
} from '../../utils/types/commonTypes';
import { dropdownOptionToObjectNameAndId } from '../../utils/helpers/dropdown';

interface IProps extends IWithModalsProps {
  onChange: (country: IObjectNameAndId) => void;
  countryId: number;
}

const t = (keyName: string) => i18n.t(`CountryDropdown.${keyName}`);

const CountryDropdown = ({
  modalErrorHandler,
  onChange,
  countryId,
}: IProps) => {
  const [dropdownOptions, setDropdownOptions] = useState<
    IDropdownOption<number>[]
  >([]);

  const fetchCountries = () => {
    getCountryOptions()
      .then(({ data }) => {
        setDropdownOptions(
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          }))
        );
      })
      .catch((error) => {
        modalErrorHandler(t('failedToRetrieveCountries'), error);
      });
  };

  useEffect(() => {
    fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid="country-div">
      <Select
        options={dropdownOptions}
        value={dropdownOptions.find(({ value }) => value === countryId) ?? null}
        onChange={(country: IDropdownOption<number>) =>
          onChange(dropdownOptionToObjectNameAndId(country))
        }
      />
    </div>
  );
};
export default withModals(CountryDropdown);
