import React from 'react';
import { Card, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import i18n from '../../../i18n';
import { IObjectNameIdDescription } from '../../../utils/types/commonTypes';
import { NO_ID } from '../../../utils/constants';
import TableCard from '../../../components/cards/TableCard';

interface IProps {
  employeeGroups: IObjectNameIdDescription[];
}

const EmployeeGroupViewCard: React.FC<IProps> = ({
  employeeGroups,
}: IProps) => {
  const t = (keyName: string) => i18n.t(`EmployeeGroupCard.${keyName}`);

  /**
   * Table Data format when using Employee Groups
   */
  const prepareTableEmployeeGroup = employeeGroups.map(
    ({ id, name, description }: IObjectNameIdDescription) => {
      const entry = {
        [t('name')]: (
          <Link
            to={`/settings/permissions/employee-group-details/${
              id ?? (NO_ID as number)
            }`}
          >
            {name}
          </Link>
        ),
        [t('description')]: description,
        value: '',
      };
      return entry;
    }
  );

  return (
    <Container fluid>
      <Card>
        <TableCard
          tableData={prepareTableEmployeeGroup}
          noDataPlaceholder={t('employeeGroupEmpty')}
          border={false}
          title={t('cardTitle')}
          largeTitle
          leftTitle
        />
      </Card>
    </Container>
  );
};

export default EmployeeGroupViewCard;
