import { AxiosError } from 'axios';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'reactstrap';

import axios from '../../services/axios/axios';
import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';
import { OBJECT_TYPE_ENUM } from '../../utils/enums/objectType';
import { getEmployees, isEmpty } from '../../utils/helpers/GenericHelper';
import { generateTitle } from '../../utils/helpers/icon';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import i18n from '../../i18n';
import { RootState } from '../../redux/store';
import { IEmployee, IResponsible } from '../../utils/types/modelTypes';
import { Ownership } from '../../utils/enums/ownership';
import ResponsibleDropdown from '../dropdowns/ResponsibleDropdown';

interface IProps extends PropsFromRedux, IWithModalsProps {
  responsibleOwnership?: IResponsible;
  responsibles: IResponsible[];
  objectId?: number | undefined;
  objectType?: string;
  onChange?: (item: IResponsible[]) => Promise<void> | void;
  onDelete?: (item: IResponsible, index?: number) => void;
  hideOwnershipField?: boolean;
  involvedOnly?: boolean;
  projectOption?: boolean;
  enableResponsibleRole?: boolean;
  forceRefresh?: boolean;
  serviceToUse?: string;
  removeEmployee?: boolean;
  employeeId?: number;
  disableDelete?: boolean;
  disableAdd?: boolean;
  employeesResponsible?: IEmployee[];
  ownershipRequired?: boolean;
  removeNoneOwnership?: boolean;
  lockInputs?: boolean;
  removeResponsibleRole?: boolean;
}

interface IState {
  responsibles: IResponsible[];
  employees: IEmployee[];
}

/**
 * @deprecated Use MultipleResponsibleDropdown
 * Responsibles form input\
 * Variable props:\
 *  responsibleOwnership
 *  responsibles - responsibles for an entity.\
 *  objectId - ID of the entity
 *  objectType - type of entity(OFFER, REQUEST, ORDER, CUSTOMER_SITE)
 *  ownershipRequired - new responsible will have default ownership of Responsible
 *  removeNoneOwnership - remove None as choice in Ownership
 * Function props:\
 *  onChange - handles changes in responsibles. send responsibles as param\
 *  onDelete - called when deleting an already saved responsible. send the deleted responsible as param
 */
class MultipleResponsibles extends React.Component<IProps, IState> {
  isUpdate: boolean;

  constructor(props: IProps) {
    super(props);
    this.state = {
      responsibles: Array.isArray(props.responsibles)
        ? [...props.responsibles]
        : [],
      employees: !isEmpty(this.props.employees?.employees ?? [])
        ? this.props.employees?.employees ?? []
        : [],
    };
    this.isUpdate = !!props.objectId;
  }

  t(keyName: string) {
    return i18n.t(`MultipleResponsibles.${keyName}`);
  }

  async componentDidMount() {
    // Get all employees
    let employees: IEmployee[];
    if (this.props.employees.employees.length === 0) {
      employees = await getEmployees().catch((error: AxiosError) => {
        this.props.modalErrorHandler(this.t('failedToGetEmployees'), error);
        return [] as IEmployee[];
      });
    } else {
      employees = this.props.employees.employees;
    }
    if (this.props.removeEmployee) {
      employees = employees.filter(
        (employee: IEmployee) => employee.id !== this.props.employeeId
      );
    }
    this.setState({
      employees,
    });
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.responsibles?.length !== this.props.responsibles?.length ||
      prevProps.forceRefresh !== this.props.forceRefresh
    ) {
      this.setState({ responsibles: this.props.responsibles });
    }
    if (
      prevProps.responsibleOwnership !== this.props.responsibleOwnership ||
      prevProps.employeesResponsible !== this.props.employeesResponsible
    ) {
      this.render();
    }
  }

  // Handles changes made in responsibles
  handleResponsibleChange = async (
    responsible: IResponsible,
    index: number | undefined
  ) => {
    const responsibles = [...this.state.responsibles];
    if (typeof index === 'number') {
      responsibles[index] = responsible;
    }
    this.setState({ responsibles });
    await this.onChange(responsibles);
  };

  addResponsible = async () => {
    const responsibles = [...this.state.responsibles];
    // These are for adding an Involved Responsible and are defaults values
    // These will be changed on the parent component
    if (this.props.ownershipRequired) {
      responsibles.push({
        employeeId: 0,
        objectId: 0,
        objectType: OBJECT_TYPE_ENUM.none.code,
        ownership: Ownership.RESPONSIBLE,
      });
    } else if (this.props.involvedOnly) {
      responsibles.push({
        employeeId: 0,
        objectId: 0,
        objectType: OBJECT_TYPE_ENUM.none.code,
        ownership: Ownership.INVOLVED,
      });
    } else {
      responsibles.push({
        employeeId: 0,
        objectId: 0,
        objectType: OBJECT_TYPE_ENUM.none.code,
        ownership: Ownership.NONE,
      });
    }
    this.setState({ responsibles });
    await this.onChange(responsibles);
  };

  // Method for deleting a contact information
  deleteResponsible = async (
    responsibleToDelete: IResponsible,
    index: number
  ) => {
    let itemToDelete: IResponsible = {} as IResponsible;
    if (responsibleToDelete?.id) {
      itemToDelete =
        this.state.responsibles?.find(
          (item) => item?.id === responsibleToDelete.id
        ) ?? ({} as IResponsible);
    }

    if (itemToDelete?.id) {
      this.props.modalDeleteHandler(
        this.t('deleteResponsible'),
        this.t('deleteResponsibleConfirm'),
        async () => {
          if (itemToDelete.id) {
            // Delete contact information
            await axios.sales
              .delete(`responsibles/${itemToDelete.id}`)
              .catch((error: AxiosError) => {
                this.props.modalErrorHandler(
                  this.t('failedToDeleteResponsible'),
                  error
                );
              });
            // Remove the deleted contact information in the list
            const responsibles = this.state.responsibles.filter(
              (item) => item?.id !== itemToDelete.id
            );
            this.setState({
              responsibles,
            });
            if (this.props.onDelete) {
              this.props.onDelete(itemToDelete, index);
            }
          }
        }
      );
    } else {
      const { responsibles } = this.state;
      responsibles.splice(index, 1);
      this.setState({ responsibles });
      await this.onChange(responsibles);
    }
  };

  // Update the parent component for the changes in data
  onChange = async (responsibles: IResponsible[]): Promise<void> => {
    if (this.props.onChange) {
      await this.props.onChange(responsibles);
    }
  };

  render() {
    const { responsibles } = this.state;
    const employees = this.props.employeesResponsible
      ? this.props.employeesResponsible
      : this.state.employees;
    const responsibleOwnership = this.props.responsibleOwnership ?? null;
    const disableDelete = this.props.disableDelete ?? false;
    const disableAdd = this.props.disableAdd ?? false;
    return (
      <>
        {Array.isArray(responsibles) && responsibles.length > 0 ? (
          <>
            {responsibles.map((responsible, index) => (
              <ResponsibleDropdown
                onChange={this.handleResponsibleChange}
                employees={employees}
                selectedResponsibles={
                  responsibleOwnership
                    ? [...responsibles, responsibleOwnership]
                    : responsibles
                }
                onDeleteResponsible={() =>
                  this.deleteResponsible(responsible, index)
                }
                objectType={this.props.objectType ?? ''}
                objectId={this.props.objectId ?? 0}
                responsibleIndex={index}
                responsible={responsible}
                key={index}
                enableResponsibleRole={!this.props.removeResponsibleRole}
                removeNoneOwnership={this.props.removeNoneOwnership ?? false}
                hideOwnershipField={this.props.hideOwnershipField ?? false}
                disableDelete={disableDelete}
                lockInputs={this.props.lockInputs ?? false}
              />
            ))}
            <br />
          </>
        ) : null}
        <Button
          color="primary"
          size="sm"
          onClick={() => this.addResponsible()}
          disabled={disableAdd}
        >
          {generateTitle(BUTTON_TITLE_ENUM.ADD.code, this.t('add'))}
        </Button>
      </>
    );
  }
}

const mapStateToProps = (store: RootState) => ({
  employees: store.employees,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(MultipleResponsibles));
