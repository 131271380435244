/* eslint-disable react/no-array-index-key */
import { AxiosError, AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
} from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';

import InputFormLabel from '../InputFormLabel';
import { defaultWorkingHoursPerDay } from '../../../utils/constants';
import { ProjectEmployeeWorkloadState } from '../../../utils/enums/project';
import {
  getMonthAbbreviationAndYear,
  getWeekDaysByWeekObject,
} from '../../../utils/helpers/resourcePlanner';
import i18n from '../../../i18n';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import {
  IWorkloadBreakdown,
  IWorkloadBreakdownReturn,
} from '../../../utils/types/modelTypes';
import ProjectEmployeeRoleDropdown from '../../dropdowns/ProjectEmployeeRoleDropdown';
import { clickHandler } from '../../../utils/helpers/click';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  IComplexProjectMemberObject,
  IProjectEmployeeDetail,
  IProjectMemberListItem,
  IProjectMemberObject,
  IProjectWorkloadObject,
} from '../../../utils/types/responseTypes';
import { getDateFormat } from '../../../utils/helpers/date';
import {
  getProjectEmployeeWorkloads,
  saveProjectEmployeeComplex,
} from '../../../services/api/project';
import {
  COLOR_WHITE,
  getMonthOptions,
  resourceFields,
  setCellColor,
} from './AssignResourceHelper';

interface IProps extends IWithModalsProps {
  projectEmployee: IProjectEmployeeDetail;
  onSaveComplexResource: (projectEmployees: IProjectMemberListItem[]) => void;
}

interface IMonthObject {
  value: number;
  label: string;
  year: number;
}

interface ICellForUpdate {
  fieldForUpdate: string;
  colNumber: number;
}

interface IConstantField {
  bookedPercentage: boolean;
  plannedPercentage: boolean;
  bookedOrOpenPercentage: boolean;
  role: boolean;
  rate: boolean;
}

/**
 * A class component that shows Complex Resource Assigner
 * Variable props:
 *  - projectemployee - contails all the details to be used
 * Funtion props:
 *  - onSaveComplexResource
 */
const ComplexAssignResourceModal = ({
  // With Modals
  modalErrorHandler,
  // Props
  projectEmployee,
  onSaveComplexResource,
}: IProps) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [workloadTemplate, setWorkloadTemplates] = useState({
    workloadMonthly: [],
    workloadWeekly: [],
    workingDays: [],
    projectOption: true,
  } as IWorkloadBreakdownReturn);
  const [selectedWorkloads, setSelectedWorkloads] = useState(
    [] as IWorkloadBreakdown[]
  );
  const [selectedMonths, setSelectedMonths] = useState([] as IMonthObject[]);
  const [selectedWeeks, setSelectedWeeks] = useState([] as string[]);
  const [{ fieldForUpdate, colNumber }, setCellForUpdate] = useState({
    fieldForUpdate: '',
    colNumber: 0,
  } as ICellForUpdate);
  const [isConstant, setIsConstant] = useState({
    bookedPercentage: false,
    plannedPercentage: false,
    bookedOrOpenPercentage: false,
    role: false,
    rate: false,
  } as IConstantField);
  const [draggedValue, setDraggedValue] = useState('');

  const { projectOption } = workloadTemplate;
  const dateFormat = getDateFormat();

  const t = (keyName: string) => i18n.t(`ComplexResourceAssigner.${keyName}`);

  /**
   * Method that toggles edit mode of fields
   * @param {*} fieldForUpdate
   * @param {*} index
   */
  const toggleUpdateField = (fieldForUpdate: string, colNumber: number) => {
    if (projectOption && fieldForUpdate === resourceFields.bookedPercentage) {
      setCellForUpdate({
        fieldForUpdate: '',
        colNumber: 0,
      });
    } else if (
      !isConstant[fieldForUpdate as keyof IConstantField] ||
      colNumber < 1
    ) {
      setCellForUpdate({ fieldForUpdate, colNumber });
    }
  };

  /**
   * Handles initial computation of workload hours
   * @param workload
   * @param field
   * @returns workload hours
   */
  const handleWorkloadComputation = (
    workload: IWorkloadBreakdown,
    field: string
  ) => {
    const { workingDays } = workloadTemplate;
    const { startDate, endDate } = workload;
    const workableDates = workingDays.filter(
      (date: Date | Moment | string) =>
        moment(date).isSameOrAfter(moment(startDate)) &&
        moment(date).isSameOrBefore(moment(endDate))
    );
    const duration = workableDates.length;
    const computedWorkload =
      (duration *
        defaultWorkingHoursPerDay *
        workload[field as keyof typeof workload]) /
      100;
    return computedWorkload;
  };

  /**
   * Create month object of the next month based from the date
   * @param {*} date - date
   * @returns monthObject - object with the following attributes: date, label, value, year
   */
  const getNextMonthObjectByDate = (date: Moment) => {
    const monthOptions = getMonthOptions();
    const startNewDate = date.toDate();
    const momentStartMonth = startNewDate.getMonth();
    const momentStartYear = startNewDate.getFullYear();
    const monthObject = monthOptions[momentStartMonth];
    if (monthObject) {
      monthObject.year = momentStartYear;
    }
    return monthObject;
  };

  /**
   * Sets the column for dates to show
   */
  const setSelectedMonthsOrWeeks = (
    isMonthly: boolean,
    { workloadMonthly, workloadWeekly }: IWorkloadBreakdownReturn
  ) => {
    if (isMonthly) {
      const filteredMonths: IMonthObject[] = [];
      workloadMonthly.forEach(({ startDate }) => {
        const monthObject = getNextMonthObjectByDate(
          moment(startDate)
        ) as IMonthObject;
        filteredMonths.push(monthObject);
      });
      setSelectedMonths(filteredMonths);
      setSelectedWorkloads(workloadMonthly);
    } else {
      const filteredDays = [] as string[];
      workloadWeekly.forEach(({ startDate }) => {
        const weekStart = {
          weekNumber: moment(startDate).week(),
          year: moment(startDate).year(),
        };
        const retrievedDaysByWeekNumber = getWeekDaysByWeekObject(weekStart);
        filteredDays.push(...retrievedDaysByWeekNumber);
      });

      // Get all mondays. To be used to represent the week
      const allMondays = [] as string[];
      for (let i = 0; i < filteredDays.length; i += 1) {
        const dateEntry = new Date(filteredDays[i] as string);
        if (dateEntry.getDay() === 1) {
          const date = filteredDays[i];
          if (date && !allMondays.includes(date)) {
            allMondays.push(date);
          }
        }
      }
      setSelectedWeeks(allMondays);
      setSelectedWorkloads(workloadWeekly);
    }
  };

  /**
   * Toggles between monthly and weekly
   */
  const toggleMonthlyOrWeekly = () => {
    setSelectedMonthsOrWeeks(!isMonthly, workloadTemplate);
    setIsMonthly(!isMonthly);
  };

  /**
   * Handle updates in computed workload
   * @param workload
   * @param field
   * @returns
   */
  const handleUpdateWorkloadHours = (
    workload: IWorkloadBreakdown,
    field: string
  ) => {
    const updatedWorkload = workload;
    switch (field) {
      case resourceFields.bookedPercentage:
        updatedWorkload.bookedWorkload = handleWorkloadComputation(
          workload,
          field
        );
        break;
      case resourceFields.bookedOrOpenPercentage:
        updatedWorkload.bookedOrOpenWorkload = handleWorkloadComputation(
          workload,
          field
        );
        break;
      case resourceFields.plannedPercentage:
        updatedWorkload.plannedWorkload = handleWorkloadComputation(
          workload,
          field
        );
        break;
      default:
        break;
    }
    return workload;
  };

  /**
   * Handles changes made in bookedPercentage, openAndBookedPercentage and plannedPercentage field
   * @param event
   * @param index
   * @param field
   */
  const handleFieldChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    field: string
  ) => {
    const fieldValue = parseFloat(event.target.value);
    if (fieldValue <= 100) {
      const updatedWorkloads = [...selectedWorkloads];
      const workload = { ...updatedWorkloads[index] } as IWorkloadBreakdown;
      workload[field as keyof typeof workload] = fieldValue;
      handleUpdateWorkloadHours(workload, field);
      updatedWorkloads[index] = workload;

      // If KeepConstant is checked, updated succeeding columns
      if (isConstant[field as keyof IConstantField]) {
        for (let i = 1; i < updatedWorkloads.length; i += 1) {
          const succeedingWorkload = {
            ...updatedWorkloads[i],
          } as IWorkloadBreakdown;
          succeedingWorkload[field as keyof typeof succeedingWorkload] =
            fieldValue;
          handleUpdateWorkloadHours(succeedingWorkload, field);
          updatedWorkloads[i] = succeedingWorkload;
        }
      }
      setSelectedWorkloads(updatedWorkloads);
    }
  };

  /**
   * Handles changes made in role field
   * @param role
   * @param index
   */
  const handleRoleChange = (role: IDropdownOption, index: number) => {
    const updatedWorkloads = [...selectedWorkloads];
    const workload = { ...updatedWorkloads[index] } as IWorkloadBreakdown;
    workload.role = role.value;
    updatedWorkloads[index] = workload;
    // Reset selected cell for update
    setCellForUpdate({
      fieldForUpdate: '',
      colNumber: 0,
    });

    // If KeepConstant is checked, updated succeeding columns
    if (isConstant.role) {
      for (let i = 1; i < updatedWorkloads.length; i += 1) {
        const succeedingWorkload = {
          ...updatedWorkloads[i],
        } as IWorkloadBreakdown;
        succeedingWorkload.role = role.value;
        updatedWorkloads[i] = succeedingWorkload;
      }
    }
    setSelectedWorkloads(updatedWorkloads);
  };

  /**
   * Handles changes made in rate field
   * @param event
   * @param index
   */
  const handleRateChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const rate = parseFloat(event.target.value);
    const updatedWorkloads = [...selectedWorkloads];
    const workload = { ...updatedWorkloads[index] } as IWorkloadBreakdown;
    workload.rate = rate;
    updatedWorkloads[index] = workload;

    // If KeepConstant is checked, updated succeeding columns
    if (isConstant.rate) {
      for (let i = 1; i < updatedWorkloads.length; i += 1) {
        const succeedingWorkload = {
          ...updatedWorkloads[i],
        } as IWorkloadBreakdown;
        succeedingWorkload.rate = rate;
        updatedWorkloads[i] = succeedingWorkload;
      }
    }
    setSelectedWorkloads(updatedWorkloads);
  };

  /**
   * Handle copying of values to target cells
   * @param event
   * @param colNumber
   * @param field
   */
  const handleFieldDragEnter = (
    event: any,
    colNumber: number,
    field: string
  ) => {
    if (
      event?.relatedTarget?.className === event.currentTarget.className &&
      draggedValue
    ) {
      const value =
        field === resourceFields.bookedPercentage ||
        field === resourceFields.bookedOrOpenPercentage ||
        field === resourceFields.plannedPercentage
          ? parseFloat(draggedValue)
          : draggedValue;
      const rawWorkloads = [...selectedWorkloads];
      const workload = { ...rawWorkloads[colNumber] } as IWorkloadBreakdown;
      workload[field as keyof typeof workload] = value;
      handleUpdateWorkloadHours(workload, field);
      rawWorkloads[colNumber] = workload;
      setSelectedWorkloads(rawWorkloads);
    }
  };

  /**
   * Handles dragging of value from Label
   * @param event
   */
  const handleDragStart = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eve = event.target?.innerText?.replace('%', '');
    setDraggedValue(eve);
  };

  /**
   * Handles dragging of value from Input box
   * @param event
   */
  const handleInputDragStart = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eve = event.target.value;
    setDraggedValue(eve);
  };

  /**
   * Toggles Keep Constant checkbox
   * @param event
   * @param field
   */
  const toggleKeepConstant = (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    isConstant[field as keyof typeof isConstant] = checked;
    setIsConstant(isConstant);
    if (checked) {
      // Set the first value to be the values of other columns
      const firstField = selectedWorkloads[0];
      const firstFieldValue: number = firstField?.[
        field as keyof typeof firstField
      ] as number;
      const rawWorkloads = [...selectedWorkloads];
      // eslint-disable-next-line no-restricted-syntax
      for (const workload of rawWorkloads) {
        workload[field as keyof typeof workload] = firstFieldValue;
        handleUpdateWorkloadHours(workload, field);
      }
      setSelectedWorkloads(rawWorkloads);
    }
    // Reset selected cell for update
    setCellForUpdate({
      fieldForUpdate: '',
      colNumber: 0,
    });
  };

  /**
   * Handles rendering of columns for bookedPercentage, openAndBookedPercentage, plannedPercentage
   * @param workload
   * @param field
   * @param index
   * @returns JSX Element
   */
  const renderColumns = (
    workload: IWorkloadBreakdown,
    field: string,
    index: number
  ) => (
    <td>
      <div
        className={field}
        key={index}
        onClick={() => toggleUpdateField(field, index)}
        onDragEnter={(event) => handleFieldDragEnter(event, index, field)}
        onDragOver={(event) => event.preventDefault()}
        style={{
          textAlign: 'center',
          backgroundColor: setCellColor(workload, field, projectOption),
        }}
      >
        {fieldForUpdate === field && colNumber === index ? (
          <Input
            draggable={!isConstant[field as keyof IConstantField]}
            min="0"
            max="100"
            type="number"
            step="1"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleFieldChange(event, index, field)
            }
            value={workload[field as keyof typeof workload]}
            onDragStart={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleInputDragStart(event)
            }
          />
        ) : (
          <Label
            draggable={!isConstant[field as keyof IConstantField]}
            style={{
              textAlign: 'center',
            }}
            onDragStart={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleDragStart(event)
            }
          >
            {`${workload[field as keyof typeof workload]}%`}
          </Label>
        )}
      </div>
    </td>
  );

  /**
   * Combines entered workloads with similar properties (workload percentage, role, rate)
   * @returns workloads
   */
  const combineSimilarWorkloads = () => {
    const combinedWorkloads = [] as IWorkloadBreakdown[];
    let combinedWorkload = null;
    for (let i = 0; i < selectedWorkloads.length; i += 1) {
      // Check for adjacent workloads if similar
      if (
        selectedWorkloads[i + 1] &&
        selectedWorkloads[i]?.bookedPercentage ===
          selectedWorkloads[i + 1]?.bookedPercentage &&
        selectedWorkloads[i]?.bookedOrOpenPercentage ===
          selectedWorkloads[i + 1]?.bookedOrOpenPercentage &&
        selectedWorkloads[i]?.plannedPercentage ===
          selectedWorkloads[i + 1]?.plannedPercentage &&
        selectedWorkloads[i]?.role === selectedWorkloads[i + 1]?.role &&
        selectedWorkloads[i]?.rate === selectedWorkloads[i + 1]?.rate
      ) {
        if (combinedWorkload) {
          combinedWorkload = {
            ...combinedWorkload,
            endDate: selectedWorkloads[i + 1]?.endDate as moment.Moment,
          } as IWorkloadBreakdown;
        } else {
          combinedWorkload = {
            ...(selectedWorkloads[i] as IWorkloadBreakdown),
            endDate: selectedWorkloads[i + 1]?.endDate as moment.Moment,
            bookedWorkload:
              (selectedWorkloads[i] as IWorkloadBreakdown).bookedWorkload +
              (selectedWorkloads[i + 1] as IWorkloadBreakdown).bookedWorkload,
            plannedWorkload:
              (selectedWorkloads[i] as IWorkloadBreakdown).plannedWorkload +
              (selectedWorkloads[i + 1] as IWorkloadBreakdown).plannedWorkload,
            bookedOrOpenWorkload:
              (selectedWorkloads[i] as IWorkloadBreakdown)
                .bookedOrOpenWorkload +
              (selectedWorkloads[i + 1] as IWorkloadBreakdown)
                .bookedOrOpenWorkload,
          };
        }
      } else if (combinedWorkload) {
        combinedWorkloads.push(combinedWorkload);
        combinedWorkload = null;
      } else {
        combinedWorkload = selectedWorkloads[i] as IWorkloadBreakdown;
        combinedWorkloads.push(combinedWorkload);
        combinedWorkload = null;
      }
    }
    setSelectedWorkloads(combinedWorkloads);
    return combinedWorkloads;
  };

  const convertWorkloads = (workloads: IWorkloadBreakdown[]) => {
    const convertedComplexProjectMember = [] as IComplexProjectMemberObject[];
    workloads.forEach((item) => {
      const objectWorkload: IProjectWorkloadObject[] = [];
      if (item.bookedPercentage) {
        objectWorkload.push({
          workloadHours: item.bookedWorkload,
          workloadPercentage: item.bookedPercentage,
          workloadState: ProjectEmployeeWorkloadState.BOOKED,
        });
      }
      if (item.bookedOrOpenPercentage) {
        objectWorkload.push({
          workloadHours: item.bookedOrOpenPercentage,
          workloadPercentage: item.bookedOrOpenPercentage,
          workloadState: ProjectEmployeeWorkloadState.BOOKED_AND_OPEN,
        });
      }
      if (item.plannedPercentage) {
        objectWorkload.push({
          workloadHours: item.plannedWorkload,
          workloadPercentage: item.plannedPercentage,
          workloadState: ProjectEmployeeWorkloadState.PLANNED,
        });
      }
      convertedComplexProjectMember.push({
        ...(projectEmployee as IProjectMemberObject),
        validFrom: item.startDate,
        validUntil: item.endDate,
        objectWorkload,
      } as IComplexProjectMemberObject);
    });
    return convertedComplexProjectMember;
  };

  /**
   * Handles save button
   */
  const saveAllResourceObjects = () => {
    const convertedAndCombinedWorkloads = convertWorkloads(
      combineSimilarWorkloads()
    );

    saveProjectEmployeeComplex(convertedAndCombinedWorkloads)
      .then(({ data }: AxiosResponse<IProjectMemberObject[]>) => {
        if (data[0] && data.length > 0) {
          setSelectedMonthsOrWeeks(isMonthly, workloadTemplate);
          onSaveComplexResource(data);
        }
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToRetrieveEmployee'), error);
      });
  };

  /**
   * Checks if all required fields have been filled
   * @returns {boolean}
   */
  const isInputValid = () => {
    for (let i = 0; i < selectedWorkloads.length; i += 1) {
      // Check if each column has a role and at least one booked, open or planned workload
      if (
        !(
          selectedWorkloads[i]?.bookedWorkload ||
          selectedWorkloads[i]?.bookedOrOpenWorkload ||
          selectedWorkloads[i]?.plannedWorkload
        ) ||
        !selectedWorkloads[i]?.role ||
        (projectEmployee.currency && projectEmployee.currency !== ''
          ? !selectedWorkloads[i]?.rate
          : selectedWorkloads[i]?.rate)
      )
        return false;
    }
    return true;
  };

  useEffect(() => {
    const projectEmployeeToCheck = { ...projectEmployee };
    if (projectEmployee.currency && projectEmployee.currency !== '') {
      projectEmployeeToCheck.currency = undefined;
    }
    getProjectEmployeeWorkloads(projectEmployeeToCheck)
      .then(({ data }: AxiosResponse<IWorkloadBreakdownReturn>) => {
        setWorkloadTemplates(data);
        setSelectedMonthsOrWeeks(isMonthly, data);
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToRetrieveEmployee'), error);
      });
  }, []);

  const {
    project: { name: projectName },
    employee: { name: employeeName, id: employeeId },
    currency,
  } = projectEmployee;
  return (
    <Container fluid>
      <Form>
        <FormGroup>
          <InputFormLabel text={t('project')} isRequired={false} />
          <Input type="text" value={projectName} readOnly />
        </FormGroup>
        <FormGroup>
          <InputFormLabel text={t('employee')} isRequired={false} />
          <Input type="text" value={employeeName} readOnly />
        </FormGroup>
      </Form>
      <Card>
        <div>
          <Row>
            <Col>
              <Table responsive>
                <thead>
                  <tr>
                    <td
                      className="stickyFirstColumn"
                      style={{
                        background: COLOR_WHITE,
                        minWidth: 150,
                      }}
                    >
                      {' '}
                      <div className="form-inline text-center">
                        <Label style={{ marginRight: '5px' }}>
                          {t('weekly')}
                        </Label>
                        <Label>
                          <Input
                            type="switch"
                            checked={isMonthly}
                            onChange={toggleMonthlyOrWeekly}
                          />
                          {t('monthly')}
                        </Label>
                      </div>
                    </td>
                    <td
                      className="stickyKeepConstant text-center"
                      style={{
                        maxWidth: 'min-content',
                      }}
                    >
                      {t('keepConstant')}
                    </td>
                    {!isMonthly &&
                      selectedWeeks.map(
                        (date, index) =>
                          date !== null && (
                            <td
                              key={index}
                              id={`week-${index}`}
                              style={{
                                fontWeight:
                                  moment(new Date()).week() ===
                                    moment(date).week() &&
                                  moment(new Date()).year() ===
                                    moment(date).year()
                                    ? 'bold'
                                    : 'normal',
                              }}
                            >
                              {`(${moment(date).week()}) ${moment(date).format(
                                dateFormat
                              )}`}
                            </td>
                          )
                      )}
                    {isMonthly &&
                      selectedMonths.map(
                        (month, index) =>
                          month !== null && (
                            <td
                              key={index}
                              id={`month-${index}`}
                              style={{
                                fontWeight: 'normal',
                                minWidth: 150,
                                maxWidth: 200,
                              }}
                            >
                              {getMonthAbbreviationAndYear(month)}
                            </td>
                          )
                      )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="stickyFirstColumn">
                      <Label>{t('maxAvailability')}</Label>
                    </td>
                    <td
                      className="stickyKeepConstant text-center"
                      style={{
                        maxWidth: 'min-content',
                      }}
                    >
                      {' '}
                    </td>
                    {selectedWorkloads.map((workload, index) => (
                      <td
                        key={index}
                        id={`maxAvailability-${index}`}
                        style={{
                          textAlign: 'center',
                          backgroundColor: 'lightgray',
                        }}
                      >
                        {`${workload.maxAvailability}%`}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="stickyFirstColumn">
                      <Label>{t('minAvailability')}</Label>
                    </td>
                    <td
                      className="stickyKeepConstant text-center"
                      style={{
                        maxWidth: 'min-content',
                      }}
                    >
                      {' '}
                    </td>
                    {selectedWorkloads.map((workload, index) => (
                      <td
                        key={index}
                        id={`minAvailability-${index}`}
                        style={{
                          backgroundColor: 'lightgray',
                          textAlign: 'center',
                        }}
                      >
                        {`${workload.minAvailability}%`}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="stickyFirstColumn">
                      <InputFormLabel
                        isRequired={false}
                        text={t('bookResource')}
                      />
                    </td>
                    <td
                      className="stickyKeepConstant text-center"
                      style={{
                        maxWidth: 'min-content',
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isConstant.bookedPercentage}
                        disabled={projectOption}
                        onChange={(event) =>
                          toggleKeepConstant(
                            event,
                            resourceFields.bookedPercentage
                          )
                        }
                      />
                    </td>
                    {selectedWorkloads.map((workload, index) =>
                      renderColumns(
                        workload,
                        resourceFields.bookedPercentage,
                        index
                      )
                    )}
                  </tr>
                  <tr>
                    <td className="stickyFirstColumn">
                      <InputFormLabel
                        isRequired={false}
                        text={t('bookOpenForNew')}
                      />
                    </td>
                    <td
                      className="stickyKeepConstant text-center"
                      style={{
                        maxWidth: 'min-content',
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isConstant.bookedOrOpenPercentage}
                        onChange={(event) =>
                          toggleKeepConstant(
                            event,
                            resourceFields.bookedOrOpenPercentage
                          )
                        }
                      />
                    </td>
                    {selectedWorkloads.map((workload, index) =>
                      renderColumns(
                        workload,
                        resourceFields.bookedOrOpenPercentage,
                        index
                      )
                    )}
                  </tr>
                  <tr>
                    <td className="stickyFirstColumn">
                      <InputFormLabel
                        isRequired={false}
                        text={t('addPlanResource')}
                      />
                    </td>
                    <td
                      className="stickyKeepConstant text-center"
                      style={{
                        maxWidth: 'min-content',
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isConstant.plannedPercentage}
                        onChange={(event) =>
                          toggleKeepConstant(
                            event,
                            resourceFields.plannedPercentage
                          )
                        }
                      />
                    </td>
                    {selectedWorkloads.map((workload, index) =>
                      renderColumns(
                        workload,
                        resourceFields.plannedPercentage,
                        index
                      )
                    )}
                  </tr>
                  <tr>
                    <td className="stickyFirstColumn">
                      <InputFormLabel isRequired text={t('role')} />
                    </td>
                    <td
                      className="stickyKeepConstant text-center"
                      style={{
                        maxWidth: 'min-content',
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isConstant.role}
                        onChange={(event) =>
                          toggleKeepConstant(event, resourceFields.role)
                        }
                      />
                    </td>
                    {selectedWorkloads.map((workload, index) => (
                      <td key={index}>
                        <div
                          className={resourceFields.role}
                          onClick={() =>
                            toggleUpdateField(resourceFields.role, index)
                          }
                          onDragEnter={(event) =>
                            handleFieldDragEnter(
                              event,
                              index,
                              resourceFields.role
                            )
                          }
                          onDragOver={(event) => event.preventDefault()}
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          {fieldForUpdate === resourceFields.role &&
                          colNumber === index ? (
                            <ProjectEmployeeRoleDropdown
                              employeeId={employeeId}
                              onChange={(role) => handleRoleChange(role, index)}
                              projectEmployeeRole={workload.role}
                            />
                          ) : (
                            <Label
                              draggable={!isConstant.role}
                              style={{
                                textAlign: 'center',
                              }}
                              onDragStart={(event: any) =>
                                handleDragStart(event)
                              }
                            >
                              {workload.role}
                            </Label>
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="stickyFirstColumn">
                      <InputFormLabel
                        isRequired={!!currency}
                        text={t('avgRateCard')}
                      />
                    </td>
                    <td
                      className="stickyKeepConstant text-center"
                      style={{
                        maxWidth: 'min-content',
                      }}
                    >
                      <input
                        type="checkbox"
                        disabled={!currency}
                        checked={isConstant.rate}
                        onChange={(event) =>
                          toggleKeepConstant(event, resourceFields.rate)
                        }
                      />
                    </td>
                    {selectedWorkloads.map((workload, index) => (
                      <td key={index}>
                        <div
                          className={resourceFields.rate}
                          onClick={() =>
                            toggleUpdateField(resourceFields.rate, index)
                          }
                          onDragEnter={(event) =>
                            handleFieldDragEnter(
                              event,
                              index,
                              resourceFields.rate
                            )
                          }
                          onDragOver={(event) => event.preventDefault()}
                          style={{
                            textAlign: 'center',
                            backgroundColor: currency ? 'white' : 'lightgrey',
                          }}
                        >
                          {currency &&
                          currency !== '' &&
                          fieldForUpdate === resourceFields.rate &&
                          colNumber === index ? (
                            <Input
                              draggable={!isConstant.rate}
                              min="0"
                              type="number"
                              step="1"
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => handleRateChange(event, index)}
                              value={workload.rate}
                              onDragStart={(event: any) =>
                                handleInputDragStart(event)
                              }
                            />
                          ) : (
                            <Label
                              draggable={!isConstant.rate}
                              style={{
                                textAlign: 'center',
                              }}
                              onDragStart={(event: any) =>
                                handleDragStart(event)
                              }
                            >
                              {workload.rate}
                            </Label>
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <br />
        </div>
      </Card>
      <div className="text-center">
        <Button
          color="primary"
          onClick={(event: MouseEvent) =>
            clickHandler(event, () => saveAllResourceObjects())
          }
          disabled={!isInputValid()}
        >
          OK
        </Button>
      </div>
    </Container>
  );
};

export default withModals(ComplexAssignResourceModal);
