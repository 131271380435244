import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Label,
  Row,
  Col,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { AxiosError } from 'axios';

import { OfferDetailsContext } from './OfferDetailsProvider';
import { OfferState } from '../../../utils/enums/offer';
import { generateTitle } from '../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { saveOffer } from '../../../services/api/offer';
import {
  translate,
  hasPermissionToAddOffer,
  createFormValueFromOfferDetails,
  createOfferDetailsFromFormValues,
} from './offerHelper';
import { IOfferDetails } from '../../../utils/types/responseTypes';

interface IProps extends IWithModalsProps {
  isTitleValid: boolean;
  onClose: () => void;
  handleAddToOfferList: (offer: IOfferDetails) => void;
  handleUpdateToOfferList: (offer: IOfferDetails) => void;
}

const OfferHeaderCard = ({
  modalErrorHandler,
  isTitleValid,
  handleAddToOfferList,
  handleUpdateToOfferList,
  onClose,
}: IProps) => {
  const { offer, setOffer, formValues, setFormValues } =
    useContext(OfferDetailsContext);

  const {
    title,
    date,
    currency,
    contractType,
    budget,
    paymentGoal,
    detail,
    validityPeriod,
    responsible,
  } = formValues;

  const {
    id,
    offerState,
    title: offertTitle,
    date: offerDate,
    currency: offerCurrency,
    budget: offerBudget,
    contractType: offerContractType,
    paymentGoal: offerPaymentGoal,
    detail: offerDetail,
    validityPeriod: offerValidityPeriod,
  } = offer;

  const [isUpdate, setIsUpdate] = useState(id !== undefined);

  const isDeclined = offerState === OfferState.DECLINED;

  const isDisabled = {
    draft:
      !(
        offerState === OfferState.DRAFT ||
        offerState === OfferState.VALID ||
        offerState === OfferState.SENT ||
        offerState === OfferState.ORDERED ||
        offerState === OfferState.EXPIRED
      ) || !id,
    valid: !(
      offerState === OfferState.VALID ||
      offerState === OfferState.SENT ||
      offerState === OfferState.ORDERED ||
      offerState === OfferState.EXPIRED
    ),
    sent: !(
      offerState === OfferState.SENT ||
      offerState === OfferState.ORDERED ||
      offerState === OfferState.EXPIRED
    ),
    ordered: offerState !== OfferState.ORDERED,
    expired: offerState !== OfferState.EXPIRED,
    declined: offerState !== OfferState.SENT,
  };

  const handleSubmitButton = (event: ChangeEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (isTitleValid) {
      const updatedOffer = createOfferDetailsFromFormValues(offer, formValues);

      saveOffer(updatedOffer)
        .then(({ data: response }) => {
          setOffer(response);
          setFormValues(createFormValueFromOfferDetails(response));

          // Check if updatedOffer has id (add or update an object in a list)
          if (!updatedOffer.id) {
            handleAddToOfferList(response);
          } else {
            handleUpdateToOfferList(response);
          }
        })
        .catch((error: AxiosError) =>
          modalErrorHandler(translate('failedToSaveOffer'), error.message)
        );
    } else {
      modalErrorHandler('Title must be Valid');
    }
  };

  const handleDeclineOffer = () => {
    const declinedOffer = { ...offer, offerState: OfferState.DECLINED };
    saveOffer(declinedOffer)
      .then(({ data: response }) => {
        setOffer(response);
        setFormValues(createFormValueFromOfferDetails(response));

        // Update List of Offer on Project
        handleUpdateToOfferList(response);
      })
      .catch((error: AxiosError) =>
        modalErrorHandler(translate('failedToDeclineOffer'), error.message)
      );
  };

  // Show save button if there are changes in the offer
  useEffect(() => {
    if (
      offertTitle !== title ||
      offerDate !== date ||
      offerCurrency !== currency ||
      offerContractType !== contractType ||
      offerBudget !== budget ||
      offerPaymentGoal !== paymentGoal ||
      offerDetail !== detail ||
      offerValidityPeriod !== validityPeriod
    ) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, [
    title,
    date,
    currency,
    contractType,
    budget,
    paymentGoal,
    detail,
    validityPeriod,
    responsible,
  ]);

  return (
    <Card className="common-card with-border">
      <CardHeader>
        <div className="float-end">
          {hasPermissionToAddOffer() && isUpdate && (
            <Button color="primary" size="sm" onClick={handleSubmitButton}>
              {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, translate('save'))}
            </Button>
          )}{' '}
          {isUpdate && (
            <Button color="primary" size="sm" onClick={onClose}>
              {generateTitle(
                BUTTON_TITLE_ENUM.CANCEL.code,
                translate('cancel')
              )}
            </Button>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <div className="card-actions float-start">
          <CardTitle>
            <h3>{translate('status')}</h3>
          </CardTitle>
        </div>

        {isDeclined ? (
          <div className="card-actions float-start">
            <Label className="text-center text-white vw-25 bg-brown">
              {translate('declined')}
            </Label>
          </div>
        ) : (
          <span className="card-actions float-start">
            <Row className="text-center">
              <Col>
                <Button color="primary" size="sm" disabled={isDisabled.draft}>
                  {translate('draft')}
                </Button>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
                <Button color="primary" size="sm" disabled={isDisabled.valid}>
                  {translate('valid')}
                </Button>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
                <Button color="primary" size="sm" disabled={isDisabled.sent}>
                  {translate('sent')}
                </Button>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
                <Button color="primary" size="sm" disabled={isDisabled.ordered}>
                  {translate('ordered')}
                </Button>
                <Button
                  color={
                    offer.offerState !== OfferState.EXPIRED
                      ? 'primary'
                      : 'warning'
                  }
                  size="sm"
                  disabled={isDisabled.expired}
                >
                  {translate('expired')}
                </Button>
              </Col>
            </Row>
          </span>
        )}
        {!isUpdate && offer.offerState === OfferState.SENT && (
          <div className="card-actions float-end">
            <Row style={{ textAlign: 'center' }}>
              <Col>
                {isDeclined === false && (
                  <Button
                    color="primary"
                    size="sm"
                    disabled={isDisabled.declined}
                    onClick={() => handleDeclineOffer()}
                  >
                    {translate('declined')}
                  </Button>
                )}
              </Col>
              <Col>
                <Button color="primary" size="sm" onClick={onClose}>
                  {generateTitle(BUTTON_TITLE_ENUM.CANCEL.code)}
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default withModals(OfferHeaderCard);
