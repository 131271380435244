import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUserSetting } from '../utils/types/modelTypes';
import { IUserSettingState } from '../utils/types/stateTypes';

const initialState: IUserSettingState = {
  userSetting: undefined,
};

export const userSettingSlice = createSlice({
  name: 'userSetting',
  initialState,
  reducers: {
    setUserSetting: (state, { payload }: PayloadAction<IUserSetting>) => ({
      ...state,
      userSetting: payload,
    }),
  },
});

export const { setUserSetting } = userSettingSlice.actions;

export default userSettingSlice.reducer;
