import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Table } from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import InputFormLabel from '../../../components/form/InputFormLabel';
import { sortByPropValue } from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { getFunctionRole, t } from './projectListHelpers';
import {
  IDropdownOption,
  IObjectContactPerson,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import { getContactPersonListItems } from '../../../services/api/contactPerson';
import { IContactPersonListItem } from '../../../utils/types/responseTypes';
import { NO_ID } from '../../../utils/constants';
import {
  dropdownOptionToObjectNameAndId,
  objectNameAndIdToDropdownOption,
  objectNameAndIdToDropdownOptions,
} from '../../../utils/helpers/dropdown';

interface IProps extends IWithModalsProps, RouteComponentProps {
  responsibleContactPersons: IObjectContactPerson[];
  customerId: number;
  customerSiteId: number;
  onChange: (responsibleContactPersons: IObjectContactPerson[]) => void;
}

/**
 * Responsible contact persons form input\
 * Variable props:\
 *  responsibleContactPersons - responsibleContactPerson for an entity.\
 *  customerId - ID of customer
 *  customerSiteId - ID of customerSite
 * Function props:\
 *  onChange - handles changes in responsibleContactPersons. send responsibleContactPersons as param\
 */
const ProjectContactPerson: React.FC<IProps> = ({
  responsibleContactPersons,
  customerId,
  customerSiteId,
  onChange,
  modalErrorHandler,
  modalConfirmHandler,
  history,
}: IProps) => {
  const [contactPersons, setContactPersons] = useState<
    IContactPersonListItem[]
  >([]);
  const defaultedContactPersons: IObjectContactPerson[] =
    responsibleContactPersons || [];

  const convertContactPersonListItemToObjectNameAndId = (
    contactPersons: IContactPersonListItem[]
  ): IObjectNameAndId[] =>
    contactPersons.map(({ id, firstname, lastname }) => ({
      id: id || NO_ID,
      name: `${firstname || ''} ${lastname || ''}`,
    }));

  const fetchContactPersons = async () => {
    if (customerId && customerSiteId) {
      const customerContactPersons = await getContactPersonListItems({
        'customerId.equals': customerId.toString(),
        'customerSiteId.equals': customerSiteId.toString(),
      })
        .then((res) => res.data)
        .catch((error) => {
          modalErrorHandler(t('retrieveContactPersonsFailed'), error);
        });
      if (Array.isArray(customerContactPersons)) {
        setContactPersons(sortByPropValue(customerContactPersons, 'firstname'));
      }
    }
  };

  // Handles changes made in contact informations
  const handleContactPersonChange = (
    value: IDropdownOption<number>,
    index: number
  ) => {
    const newContactPerson = dropdownOptionToObjectNameAndId(value);
    const updatedContactPersons = [...defaultedContactPersons];

    updatedContactPersons.splice(
      index,
      1,
      newContactPerson as IObjectContactPerson
    );

    onChange(updatedContactPersons);
  };

  // Handles removal of contact person
  const handleContactPersonDelete = (index: number) => {
    if (defaultedContactPersons.length === 1) {
      modalErrorHandler(t('atLeastOneContactPersonRequired'));
      return;
    }

    const updatedContactPersons = [...defaultedContactPersons];
    updatedContactPersons.splice(index, 1);

    onChange(updatedContactPersons);
  };

  // Handles push to contact details page upon clicking of contactDetails button
  const handleContactPersonDetails = (index: number) => {
    modalConfirmHandler(
      i18n.t('ModalAlert.dataWillBeLost'),
      i18n.t('ModalAlert.areYouSureYouWantToLeave'),
      () => {
        history.push(
          `/customer/my-contacts/profile/${defaultedContactPersons[index]?.id}`
        );
      }
    );
  };

  useEffect(() => {
    fetchContactPersons();
  }, [customerId, customerSiteId]);

  return (
    <>
      {defaultedContactPersons.map((responsibleContactPerson, index) => (
        <Card
          key={responsibleContactPerson.id}
          className="border border-light"
          fluid
        >
          <CardBody>
            <Table size="sm" borderless>
              <tbody>
                <tr>
                  <th
                    style={{ width: '30%' }}
                    aria-label="contact-person-label-th"
                  >
                    <InputFormLabel isRequired text={t('contactPerson')} />
                  </th>
                  <td
                    className="gap-1"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div style={{ width: '300px ' }}>
                      <Select
                        value={objectNameAndIdToDropdownOption(
                          responsibleContactPerson
                        )}
                        options={objectNameAndIdToDropdownOptions(
                          convertContactPersonListItemToObjectNameAndId(
                            contactPersons
                          )
                        ).filter(
                          ({ value }) =>
                            !defaultedContactPersons.some((e) => e.id === value)
                        )}
                        onChange={(value: IDropdownOption<number>) =>
                          handleContactPersonChange(value, index)
                        }
                      />
                    </div>
                    <Button
                      color="link"
                      onClick={() => handleContactPersonDelete(index)}
                      aria-label="delete-button"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th>{t('role')}</th>
                  <td>{responsibleContactPerson?.role ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th>{t('function')}</th>
                  <td>
                    {getFunctionRole(
                      responsibleContactPerson?.functionRole || ''
                    )}
                  </td>
                </tr>
                <tr>
                  {defaultedContactPersons[index]?.id ? (
                    <div className="card-actions">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => handleContactPersonDetails(index)}
                      >
                        {t('contactPersonDetails')}
                      </Button>
                    </div>
                  ) : (
                    ''
                  )}
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      ))}
    </>
  );
};

export default withRouter(withModals(ProjectContactPerson));
