export const absenceStateEnum = {
  Planned: {
    name: 'Planned',
    code: 'PLANNED',
    badge: 'badge badge-primary',
  },
  Submitted: {
    name: 'Submitted',
    code: 'SUBMITTED',
    badge: 'badge badge-warning',
  },
  Retreated: {
    name: 'Retreated',
    code: 'RETREATED',
    badge: 'badge badge-info',
  },
  Declined: {
    code: 'DECLINED',
    name: 'Declined',
    badge: 'badge badge-danger',
  },
  Accepted: {
    name: 'Accepted',
    code: 'ACCEPTED',
    badge: 'badge badge-success',
  },
  Canceled: {
    name: 'Canceled',
    code: 'CANCELED',
    badge: 'badge badge-dark',
  },
  Canceled_Pending: {
    name: 'Canceled_Pending',
    code: 'CANCELED_PENDING',
    badge: 'badge badge-secondary',
  },
};

// Enumeration that represents the Months
export const monthsOfYear = {
  January: {
    name: 'January',
    value: 0,
  },
  February: {
    name: 'February',
    value: 1,
  },
  March: {
    name: 'March',
    value: 2,
  },
  April: {
    name: 'April',
    value: 3,
  },
  May: {
    name: 'May',
    value: 4,
  },
  June: {
    name: 'June',
    value: 5,
  },
  July: {
    name: 'July',
    value: 6,
  },
  August: {
    name: 'August',
    value: 7,
  },
  September: {
    name: 'September',
    value: 8,
  },
  October: {
    name: 'October',
    value: 9,
  },
  November: {
    name: 'November',
    value: 10,
  },
  December: {
    name: 'December',
    value: 11,
  },
};
