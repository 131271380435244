import { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { clickHandler, modalCloseButton } from '../../utils/helpers/click';
import i18n from '../../i18n';

/*
 *
 * A more general implementation of a modal alert, where the user is able to change the title, text, the color of the modal button, and the text it contains.
 *
 */
const ModalAlert = (props) => {
  const {
    modalTitle,
    modalBodyText,
    event,
    isOpen,
    onClose,
    modalButtonColor,
    modalButtonText,
  } = props;

  const [modal, setModal] = useState(isOpen);

  const toggle = () => {
    onClose && onClose();
    setModal(!modal);
  };

  const confirmEvent = () => {
    toggle();
    event();
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader close={modalCloseButton(toggle)}>{modalTitle}</ModalHeader>
      <ModalBody>{modalBodyText}</ModalBody>
      <ModalFooter>
        <Button
          color={
            modalButtonColor === undefined || modalButtonColor === null
              ? 'dark'
              : modalButtonColor
          }
          onClick={(event) => clickHandler(event, confirmEvent)}
        >
          {modalButtonText === undefined || modalButtonText === null
            ? i18n.t('ModalConfirm.confirm')
            : modalButtonText}
        </Button>{' '}
        <Button color="dark" onClick={(event) => clickHandler(event, toggle)}>
          {i18n.t('ModalAlert.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAlert;
