import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import { reducer as toastr } from 'react-redux-toastr';

import account from './accountSlice';
import alerts from './alertsSlice';
import approvalRequestList from './approvalRequestListSlice';
import calling from './callingSlice';
import campaignManagerContactPersonList from './campaignManagerContactPersonListSlice';
import configs from './configsSlice';
import contactPersonList from './contactPersonListSlice';
import contactStatisticsList from './contactStatisticsListSlice';
import customerAccountList from './customerAccountListSlice';
import customerList from './customerListSlice';
import customerSiteList from './customerSiteListSlice';
import customers from './customersSlice';
import employeeList from './employeeListSlice';
import employee from './employeeSlice';
import employees from './employeesSlice';
import gdprList from './gdprListSlice';
import notifications from './notificationsSlice';
import projectList from './projectListSlice';
import projectOptionList from './projectOptionListSlice';
import projects from './projectsSlice';
import sidebar from './sidebarSlice';
import userSetting from './userSettingSlice';

export const rootReducer = combineReducers({
  account,
  alerts,
  calling,
  configs,
  contactPersonList,
  campaignManagerContactPersonList,
  contactStatisticsList,
  customerAccountList,
  customerList,
  customers,
  customerSiteList,
  employee,
  employees,
  gdprList,
  notifications,
  projectList,
  projectOptionList,
  projects,
  sidebar,
  employeeList,
  userSetting,
  approvalRequestList,
  toastr,
});

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
export type RootState = ReturnType<typeof store.getState>;

export default store;
