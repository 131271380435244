enum LastLanguage {
  EN = 'EN',
  DE = 'DE',
};

/**
 * Checks if the browser's current language matches those in the
 * language list (as dictated by LastLanguageEnums)
 * @param {*} language the current language of the browser
 */
export const getBrowserLanguage = (language: string) => {
  const languageArray = Object.values(LastLanguage).map((lang) =>
    lang.toLowerCase()
  );

  if (languageArray.indexOf(language) === -1) {
    return 'en';
  }

  return language;
};
