import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  Container,
  Input,
  FormGroup,
  FormFeedback,
} from 'reactstrap';

import axios from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import {
  DEPARTMENT_STATUS_ENUM,
  FIELD_OF_BUSINESS_AND_DEPARTMENT_STATE_ENUM,
} from '../../../utils/enums/department';
import {
  enumToDropdownOptions,
  handleError,
  isEmpty,
  saveResponsibles,
} from '../../../utils/helpers/GenericHelper';
import i18n from '../../../i18n';
import {
  IDropdownOption,
  IErrorMessage,
} from '../../../utils/types/commonTypes';
import {
  IDepartment,
  IEmployee,
  IFieldOfBusiness,
  IResponsible,
} from '../../../utils/types/modelTypes';
import { Ownership } from '../../../utils/enums/ownership';
import ModalError from '../../../components/modals/ModalError';
import ModalOK from '../../../components/modals/ModalOK';
import ResponsibleDropdown from '../../../components/dropdowns/ResponsibleDropdown';

/*
 * Class used when adding/updating a Department
 * Props:
 * Objects:
 * Department
 * Department Id
 * Responsible
 *
 * Function:
 * OnSave - A Function that makes the frontend reflect the changes made here
 */

interface IProps {
  // Variables
  department: IDepartment;
  departmentId: string | null;
  responsible: IResponsible;
  // Functions
  onSave: () => Promise<void>;
}

interface IState {
  id: number | null;
  department: IDepartment;
  responsible: IResponsible;
  responsibleEmployees: IEmployee[];
  showModalOK: boolean;
  showModalError: boolean;
  departmentFormFields: IDepartmentFormFields;
  fieldOfBusinesses: IFieldOfBusiness[];
}

interface IDepartmentFormFields {
  titleField: boolean;
  fieldOfBusinessField: boolean;
  abbreviationField: boolean;
  departmentIdField: boolean;
  responsibleField: boolean;
  statusField: boolean;
}
class DepartmentAddOrUpdate extends React.Component<IProps, IState> {
  isUpdate: boolean = {} as boolean;

  error: IErrorMessage = {} as IErrorMessage;

  message: string = {} as string;

  noResponsible: IResponsible = {} as IResponsible;

  constructor(props: IProps) {
    super(props);
    this.state = {
      id: null,
      department: {
        ...props.department,
        status: props.department.status ?? DEPARTMENT_STATUS_ENUM.active.code,
      },
      responsible: props?.responsible
        ? props.responsible
        : {
            employeeId: 0,
            objectId: 0,
            objectType: OBJECT_TYPE_ENUM.department.code,
            ownership: Ownership.RESPONSIBLE,
          },
      responsibleEmployees: [] as IEmployee[],
      showModalOK: false,
      showModalError: false,
      departmentFormFields: {
        titleField: false,
        fieldOfBusinessField: false,
        abbreviationField: false,
        departmentIdField: false,
        responsibleField: false,
        statusField: false,
      },
      fieldOfBusinesses: [] as IFieldOfBusiness[],
    };

    this.isUpdate = !!props.department?.id;
  }

  t(keyName: string) {
    return i18n.t('DepartmentListView.' + keyName);
  }

  async componentDidMount() {
    await axios.project
      .get('employees')
      .then((response: AxiosResponse<IEmployee[]>) => {
        if (!isEmpty(response?.data)) {
          const responsibleEmployeesData = response.data;
          this.setState({
            responsibleEmployees: responsibleEmployeesData,
          });
        }
      })
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToGetEmployees');
        this.handleError(mainError, error);
      });

    // Get all of the field of business that is only active
    await axios.project
      .get(
        `field-of-businesses?status.equals=${FIELD_OF_BUSINESS_AND_DEPARTMENT_STATE_ENUM.active.value}`
      )
      .then((response: AxiosResponse<IFieldOfBusiness[]>) => {
        this.setState({ fieldOfBusinesses: response.data ?? [] });
      })
      .catch((error: AxiosError) => {
        const mainError = this.t('failedToRetrieveFieldOfBusinesses');
        this.handleError(mainError, error);
      });

    this.disableFieldsBasedOnStatus();
  }

  // Toggle for showing the modal for duplicate entries.
  toggleModalOK = () => {
    this.setState({ showModalOK: !this.state.showModalOK });
  };

  // Toggle for showing the modal for error popup.
  toggleModalError = () => {
    this.setState({ showModalError: !this.state.showModalError });
  };

  /**
   * Method that handles what happens if the submit button is clicked
   */
  handleSubmit = async () => {
    const department = this.state.department;
    let sameDepartmentIdExistingEntry: IDepartment[] = [] as IDepartment[];

    // Checks if the departmentId does not exist when adding a new Department
    // Or updating a department with a status of PLANNED
    if (
      !this.isUpdate ||
      department.status === DEPARTMENT_STATUS_ENUM.planned.code
    ) {
      sameDepartmentIdExistingEntry =
        (await axios.project
          .get(`departments?departmentId.equals=${department?.departmentId}`)
          .then((res: AxiosResponse<IDepartment[]>) => res.data)
          .catch((error: AxiosError) => {
            this.handleError(this.t('failedToValidateDepartment'), error);
          })) ?? ([] as IDepartment[]);
    }

    // Checks if the department id isn't changed when updating a department with a status of PLANNED
    // If it is, remove all the contents of the array that found existing entries
    if (this.isUpdate && department.departmentId === this.props.departmentId) {
      sameDepartmentIdExistingEntry?.splice(
        0,
        sameDepartmentIdExistingEntry.length
      );
    }

    if (!isEmpty(sameDepartmentIdExistingEntry)) {
      this.message = this.t('departmentIdAlreadyExist');
      this.toggleModalOK();
      return;
    }
    await this.saveDepartment();
  };

  handleError = (mainError: string, errorObject: AxiosError) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  /**
   *  Method that handles the saving of the department on the backend
   */
  saveDepartment = async () => {
    const department = this.state.department;
    const responsible = this.state.responsible;

    const actionError = department?.id
      ? this.t('failedToUpdateDepartment')
      : this.t('failedToAddDepartment');

    const savedDepartment = await axios.project
      .save('departments', department)
      .then((response: AxiosResponse<IDepartment>) => response.data)
      .catch((error: AxiosError) => {
        this.handleError(actionError, error);
      });

    if (savedDepartment === undefined) {
      return;
    }

    let savedResponsible;
    if (department?.status !== DEPARTMENT_STATUS_ENUM.archived.code) {
      savedResponsible = await saveResponsibles(
        [responsible],
        savedDepartment?.id
      ).catch((error: AxiosError) => {
        const mainError = this.t('failedToSaveResponsible');
        this.handleError(mainError, error);
      });

      if (savedResponsible === undefined) {
        return;
      }
    }

    // Handles the update of changes on the List View
    await this.props.onSave();
  };

  /**
   * Method that handles the change of Title
   * @param event
   */
  handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const department = this.state.department;
    department.title = event.target.value ?? '';
    this.setState({ department });
  };

  /**
   * Method that handles the change of Abbreviation
   * @param event
   */
  handleAbbreviationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const department = this.state.department;
    department.abbreviation = (event.target.value.trim() ?? '').toUpperCase();
    this.setState({ department });
  };

  /**
   * Method that handles the change of Field Of Business
   * @param selectedFieldOfBusiness
   */
  handleFieldOfBusinessChange = (event: IDropdownOption) => {
    const department = this.state.department;
    const selectedFieldOfBusiness =
      this.state.fieldOfBusinesses.find(
        (fieldOfBusiness) => fieldOfBusiness.id === parseInt(event.value, 10)
      ) ?? ({} as IFieldOfBusiness);
    department.fieldOfBusiness = selectedFieldOfBusiness;
    this.setState({ department });
  };

  /**
   * Method that handles the change of Department-Id
   * @param event
   */
  handleDepartmentIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const department = this.state.department;
    department.departmentId = (event.target.value.trim() ?? '').toUpperCase();
    this.setState({ department });
  };

  /**
   * Method that handles the change of Responsible
   * @param selectedResponsible
   */
  handleResponsibleChange = (selectedResponsible: IResponsible) => {
    let responsible = this.state.responsible;
    responsible = selectedResponsible;

    this.setState({ responsible });
  };

  /**
   * Method that handles the change of Department status
   * @param selectedStatus
   */
  handleStatusChange = (selectedStatus: IDropdownOption) => {
    const department = this.state.department;
    const departmentFormFields = this.state.departmentFormFields;
    department.status = selectedStatus.value;

    // If the selected status is Archived, reset the responsibles and disable the dropdown
    if (selectedStatus.value === DEPARTMENT_STATUS_ENUM.archived.code) {
      departmentFormFields.responsibleField = true;

      this.setState({
        department,
        responsible: this.noResponsible,
        departmentFormFields,
      });
    } else {
      departmentFormFields.responsibleField = false;

      this.setState({ department, departmentFormFields });
    }
  };

  /**
   * Disables the fields depending on status
   */
  disableFieldsBasedOnStatus = () => {
    if (this.isUpdate) {
      const department = this.state.department;

      const newDepartmentFormFields = {
        titleField: department.status === DEPARTMENT_STATUS_ENUM.archived.code,
        fieldOfBusinessField:
          department.status === DEPARTMENT_STATUS_ENUM.archived.code,
        abbreviationField:
          department.status === DEPARTMENT_STATUS_ENUM.archived.code,
        departmentIdField:
          department.status === DEPARTMENT_STATUS_ENUM.archived.code ||
          department.status === DEPARTMENT_STATUS_ENUM.active.code,
        responsibleField:
          department.status === DEPARTMENT_STATUS_ENUM.archived.code,
        statusField: true,
      };

      this.setState({ departmentFormFields: newDepartmentFormFields });
    }
  };

  /**
   * Disables the submit button when no changes were made on the fields
   * @returns boolean
   */
  disableSubmitButton = () =>
    this.state.department.departmentId === this.props.department.departmentId &&
    this.state.department.title === this.state.department.title;

  render() {
    const department = this.state.department;
    const responsible = this.state.responsible;
    const departmentFormFields = this.state.departmentFormFields;
    const fieldOfBusinesses = this.state.fieldOfBusinesses;
    const departmentStateOptions: IDropdownOption[] = enumToDropdownOptions(
      DEPARTMENT_STATUS_ENUM
    );

    return (
      <Container fluid>
        <Card>
          <CardBody>
            <FormGroup>
              <InputFormLabel isRequired={true} text={this.t('title')} />
              <Input
                onChange={this.handleTitleChange}
                disabled={departmentFormFields.titleField}
                value={department.title ? department.title : ''}
                name="title"
                invalid={department?.title === undefined}
              />
              <FormFeedback>{this.t('titleRequired')}</FormFeedback>
            </FormGroup>
            <InputFormLabel
              isRequired={false}
              text={this.t('fieldOfBusiness')}
            />
            <Select
              onChange={this.handleFieldOfBusinessChange}
              options={fieldOfBusinesses.map(
                (fieldOfBusiness: IFieldOfBusiness) => ({
                  label: fieldOfBusiness.title,
                  value: fieldOfBusiness.id?.toString() ?? '',
                })
              )}
              value={
                fieldOfBusinesses.find(
                  ({ id }) => department.fieldOfBusiness?.id === id
                )
                  ? {
                      label: fieldOfBusinesses.find(
                        ({ id }) => department.fieldOfBusiness?.id === id
                      )?.title,
                      value: department.fieldOfBusiness?.id?.toString() ?? '',
                    }
                  : null
              }
            />
            <InputFormLabel isRequired={false} text={this.t('abbreviation')} />
            <Input
              type="text"
              onChange={this.handleAbbreviationChange}
              value={department.abbreviation ? department.abbreviation : ''}
              disabled={departmentFormFields.abbreviationField}
            />
            <FormGroup>
              <InputFormLabel isRequired={true} text={this.t('departmentId')} />
              <Input
                onChange={this.handleDepartmentIdChange}
                disabled={departmentFormFields.departmentIdField}
                value={department.departmentId ? department.departmentId : ''}
                name="departmentid"
                type="text"
                maxlength={5}
                invalid={department?.departmentId === undefined}
              />
              <FormFeedback>{this.t('departmentIdRequired')}</FormFeedback>
            </FormGroup>
            <InputFormLabel isRequired={false} text={this.t('responsible')} />
            <ResponsibleDropdown
              responsible={responsible}
              objectId={department?.id ?? 0}
              employees={this.state.responsibleEmployees}
              objectType={OBJECT_TYPE_ENUM.department.code}
              selectedResponsibles={responsible as unknown as []}
              onChange={this.handleResponsibleChange}
              hideOwnershipField
              disableDelete
              removeNoneOwnership
            />
            <InputFormLabel isRequired={true} text={this.t('status')} />
            <Select
              onChange={this.handleStatusChange}
              options={departmentStateOptions}
              value={departmentStateOptions.find(
                (status) => department?.status === status.value
              )}
              isSearchable={false}
              isDisabled={departmentFormFields.statusField}
            />
            <br />
            <Button
              color="primary"
              className="float-end"
              disabled={this.disableSubmitButton()}
              onClick={() => {
                void this.handleSubmit();
              }}
            >
              {this.t('submit')}
            </Button>
          </CardBody>
        </Card>
        <ModalOK
          isOpen={this.state.showModalOK}
          onClose={this.toggleModalOK}
          modalTitle={this.t('error')}
          modalBodyText={this.message}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}

export default DepartmentAddOrUpdate;
