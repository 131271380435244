import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';

import OfferHeaderCard from './OfferHeaderCard';
import OfferProjectDetailsCard from './OfferProjectDetailsCard';
import OfferActivitiesCard from './OfferActivitiesCard';
import OfferDescriptionCard from './OfferDescriptionCard';
import OfferFilesCard from './OfferFilesCard';
import OfferDetailsCard from './OfferDetailsCard';
import OffersHistoryCard from './OffersHistoryCard';
import OfferResponsiblesCard from './OfferResponsiblesCard';
import { OfferDetailsContext } from './OfferDetailsProvider';
import {
  getListOfResponsibleNames,
  getResponsibleRoleList,
} from '../../../services/api/employee';
import { getOfferCount, getOfferDetails } from '../../../services/api/offer';
import { Ownership } from '../../../utils/enums/ownership';
import { EntityType } from '../../../utils/enums/pageComponents';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  createFormValueFromOfferDetails,
  createOfferListItemFromDetails,
  translate,
} from './offerHelper';
import { DEFAULT_LOAD_TIMEOUT } from '../../../utils/constants';
import { IOfferDetails } from '../../../utils/types/responseTypes';

interface IProps extends IWithModalsProps {
  offerId?: number | undefined;
  onClose: () => void;
}

const OfferModal = ({ offerId, modalErrorHandler, onClose }: IProps) => {
  const {
    offer,
    setOffer,
    offersFromForms,
    pendingOfferFromForms,
    setProjectDetails,
    setProjectOptions,
    isProjectOption,
    setResponsibleList,
    setResponsibleRoleList,
    formValues,
    setFormValues,
    setFileAttachments,
  } = useContext(OfferDetailsContext);

  const { title } = formValues;

  const [isCheckingTitle, setIsCheckingTitle] = useState(false);
  const [isTitleValid, setIsTitleValid] = useState(true);

  const fetchOfferDetails = async () => {
    if (offerId) {
      try {
        const { data: offer } = await getOfferDetails(offerId);

        const { offerFileAttachments } = offer;

        // Offer Details
        setOffer(offer);

        // Information
        setFormValues(createFormValueFromOfferDetails(offer));

        // File Attachments
        setFileAttachments(offerFileAttachments);
      } catch (error) {
        modalErrorHandler(translate('failedToRetrieveOffer'), error);
      }
    }
  };

  const fetchResponsibles = async () => {
    try {
      const { data: responsibles } = await getListOfResponsibleNames({
        'ownership.in': Ownership.RESPONSIBLE,
        'objectType.equals': EntityType.OFFER.toUpperCase(),
      });

      setResponsibleList(
        responsibles.map((responsible) => ({
          label: `${responsible.name}`,
          value: responsible.id,
        }))
      );
    } catch (error) {
      modalErrorHandler(translate('failedToRetrieveResponsibles'), error);
    }
  };

  const fetchResponsibleRoles = async () => {
    try {
      const { data: roles } = await getResponsibleRoleList({});

      setResponsibleRoleList(
        roles.map((options) => ({
          label: options.role as string,
          value: options.id as number,
        }))
      );
    } catch (error) {
      modalErrorHandler(translate('failedToRetrieveResponsibleRoles'), error);
    }
  };

  const handleAddOfferToOfferList = (offer: IOfferDetails) => {
    if (isProjectOption && setProjectOptions) {
      setProjectOptions((values) => ({
        ...values,
        offers: [...offersFromForms, createOfferListItemFromDetails(offer)],
      }));
    } else if (setProjectDetails) {
      setProjectDetails((values) => ({
        ...values,
        pendingOffers: [
          ...(pendingOfferFromForms ?? []),
          createOfferListItemFromDetails(offer),
        ],
      }));
    }
  };

  const handleUpdateOfferToOfferList = (offer: IOfferDetails) => {
    if (isProjectOption && setProjectOptions) {
      const updatedOfferList = offersFromForms.map((indivOffer) =>
        indivOffer.id === offer.id
          ? { ...indivOffer, ...createOfferListItemFromDetails(offer) }
          : indivOffer
      );
      setProjectOptions((values) => ({
        ...values,
        offers: updatedOfferList,
      }));
    } else if (setProjectDetails) {
      const updatedPendingOffersList = pendingOfferFromForms
        ? pendingOfferFromForms.map((indivOffer) =>
            indivOffer.id === offer.id
              ? { ...indivOffer, ...createOfferListItemFromDetails(offer) }
              : indivOffer
          )
        : [];
      setProjectDetails((values) => ({
        ...values,
        pendingOffers: updatedPendingOffersList,
      }));
    }
  };

  // Loads data when modal opens
  useEffect(() => {
    fetchOfferDetails();
    // Fetch data for dropdowns
    fetchResponsibles();
    fetchResponsibleRoles();
  }, []);

  // Checks Title if valid or not
  useEffect(() => {
    if (title !== offer.title) {
      setIsCheckingTitle(true);
    }

    const timer = setTimeout(() => {
      if (title !== offer.title) {
        getOfferCount({ 'title.equals': title }).then(({ data }) => {
          setIsTitleValid(data === 0);
          setIsCheckingTitle(false);
        });
      }

      return () => clearTimeout(timer);
    }, DEFAULT_LOAD_TIMEOUT);
  }, [title]);

  const componentListLeft = [
    <OfferProjectDetailsCard key="OfferProjectDetailsCard" />,
    <OfferActivitiesCard
      updateOffer={fetchOfferDetails}
      handleUpdateOfferList={handleUpdateOfferToOfferList}
      key="OfferActivitiesCard"
    />,
    <OfferDescriptionCard key="OfferDescriptionCard" />,
    <OfferFilesCard key="OfferFilesCard" />,
  ];
  const componentListRight = [
    <OfferDetailsCard
      isCheckingTitle={isCheckingTitle}
      isTitleValid={isTitleValid}
      key="OfferDetailsCard"
    />,
    <OffersHistoryCard key="OfferHistoryCard" />,
    <OfferResponsiblesCard key="OfferResponsiblesCard" />,
  ];

  const componentMapper = (component: JSX.Element, index: number) => (
    <Row key={index}>
      <Col>{component}</Col>
    </Row>
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <OfferHeaderCard
            onClose={onClose}
            isTitleValid={isTitleValid}
            handleAddToOfferList={handleAddOfferToOfferList}
            handleUpdateToOfferList={handleUpdateOfferToOfferList}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {componentListLeft.map((component, index) =>
            componentMapper(component, index)
          )}
        </Col>
        <Col>
          {componentListRight.map((component, index) =>
            componentMapper(component, index)
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Col>
            <OfferHeaderCard
              onClose={onClose}
              isTitleValid={isTitleValid}
              handleAddToOfferList={handleAddOfferToOfferList}
              handleUpdateToOfferList={handleUpdateOfferToOfferList}
            />
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default withModals(OfferModal);
