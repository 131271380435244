import axios, { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { urlBuilder } from '../../utils/helpers/url';
import { IAbsence, ICalendarDay } from '../../utils/types/modelTypes';
import { Url } from './constants';

export const refreshPersonioAbsences = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<ICalendarDay[]>>(
    axios.get(urlBuilder(Url.PERSONIO_ABSENCES, queryParameters))
  );

export const getCurrentAbsences = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IAbsence[]>>(
    axios.get(urlBuilder(Url.ABSENCES_OF_CURRENT_USER, queryParameters))
  );

export const getSelectedAbsence = (absenceId: number) =>
  trackPromise<AxiosResponse<IAbsence>>(
    axios.get(urlBuilder(`${Url.ABSENCES}/${absenceId}`, {}))
  );
