import { AxiosError, AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import { Button, Card, CardBody, Form, FormGroup, Input } from 'reactstrap';

import InputFormLabel from './InputFormLabel';
import { NO_ID, regexExpressionDouble } from '../../utils/constants';
import { ProjectEmployeeState } from '../../utils/enums/project';
import { updateProjectDate } from '../../utils/helpers/GenericHelper';
import i18n from '../../i18n';
import { IDropdownOption } from '../../utils/types/commonTypes';
import {
  IProject,
  IProjectEmployee,
  IProjectEmployeeRate,
} from '../../utils/types/modelTypes';
import CurrencyDropdown from '../dropdowns/CurrencyDropdown';
import ProjectEmployeeRoleDropdown from '../dropdowns/ProjectEmployeeRoleDropdown';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import { PERMISSION_URI } from '../../utils/enums/permission';
import {
  createProjectEmployee,
  getProjectEmployee,
  saveProjectEmployeeRateCall,
  updateProjectEmployee,
} from '../../services/api/project';
import { getDateFormat, isBetweenDate } from '../../utils/helpers/date';
import ModifiedEmployeeDropdown from '../dropdowns/EmployeeDropdown/ModifiedEmployeeDropdown';

const dateFormat = getDateFormat();

interface IProps extends IWithModalsProps {
  projectEmployeeId: number;
  project: IProject;
  onSave: (projectEmployee: IProjectEmployee) => void;
  onCancel: () => void;
  onChange: (project: IProject) => void;
}

/**
 * This class adds employees to project with their rate.
 */
const UpdateProjectEmployeeModal: React.FC<IProps> = ({
  projectEmployeeId,
  project,
  onSave,
  onCancel,
  onChange,
  modalErrorHandler,
  modalOkHandler,
  modalConfirmHandler,
}: IProps) => {
  const [projectEmployee, setProjectEmployee] = useState(
    {} as IProjectEmployee
  );
  const [rateState, setRateState] = useState(0);
  const [currency, setCurrency] = useState('');

  const permissionList = [
    PERMISSION_URI.assignedToProjects.readWrite.uri,
    PERMISSION_URI.becomeProjectOwner.readWrite.uri,
    PERMISSION_URI.becomeProjectManager.readWrite.uri,
  ];

  const isUpdate = !!projectEmployeeId ?? false;
  const projectEmployees = [
    ...(project.projectEmployees as IProjectEmployee[]),
  ];
  const validFrom = projectEmployee.validFrom ?? null;
  const validUntil = projectEmployee.validUntil ?? null;
  const from = function (current: string) {
    if (validUntil) {
      return moment(current).isSameOrBefore(validUntil);
    }
    return true;
  };
  const until = function (current: string) {
    if (validFrom) {
      return moment(current).isSameOrAfter(validFrom);
    }
    return true;
  };
  const aeft = (keyName: string) => i18n.t(`AddEmployeeForm.${keyName}`);

  /**
   * Handles changes made in Employee field
   * @param {*} employee
   */
  const handleEmployeeOnChange = (employee: IDropdownOption<number>) => {
    const projectEmployeeUpdated = { ...projectEmployee };
    projectEmployeeUpdated.employeeId = employee.value;
    setProjectEmployee(projectEmployeeUpdated);
  };

  /**
   * Handles changes made in Role field
   * @param {*} role
   */
  const handleRoleChange = (event: IDropdownOption) => {
    const projectEmployeeUpdated = { ...projectEmployee };
    projectEmployeeUpdated.projectEmployeeRole = event.value;
    setProjectEmployee(projectEmployeeUpdated);
  };

  /**
   * Handles changes made in Role Start
   * @param {*} date
   */
  const handleDateStartChange = (date: Moment | string) => {
    const projectEmployeeUpdated = { ...projectEmployee };
    projectEmployeeUpdated.validFrom = moment(date).toISOString();
    setProjectEmployee(projectEmployeeUpdated);
  };

  /**
   * Handles changes made in Role End
   * @param {*} date
   */
  const handleDateEndChange = (date: Moment | string) => {
    const projectEmployeeUpdated = { ...projectEmployee };
    projectEmployeeUpdated.validUntil = moment(date).toISOString();
    setProjectEmployee(projectEmployeeUpdated);
  };

  /**
   * Handles changes made in Currency field
   * @param {*} currency
   */
  const handleCurrencyOnChange = (currency: string) => {
    setCurrency(currency);
  };

  /**
   * Updates the project employee rate
   * @param {*} projectEmployee
   */
  const updateProjectEmployeeRate = (projectEmployee: IProjectEmployee) => {
    const rate = {
      id: projectEmployee.projectEmployeeRate?.id ?? 0,
      start: projectEmployee.validFrom,
      end: projectEmployee.validUntil,
      rate: rateState,
      currency,
      state: ProjectEmployeeState.PLANNED,
    };
    saveProjectEmployeeRateCall(rate)
      .then((response: AxiosResponse<IProjectEmployeeRate>) => {
        // ProjectEmployee.projectEmployeeRate?.id = response.data.id;
        const updatedProjectEmployee = {
          ...projectEmployee,
          projectEmployeeRate: response.data,
        };
        updateProjectEmployee(updatedProjectEmployee).catch(
          (error: AxiosError) => {
            modalErrorHandler(aeft('failedToSaveProjectEmployee'), error);
          }
        );
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(aeft('failedToSaveProjectEmployeeRate'), error);
      });
  };

  /**
   * Saves the project employee rate
   */
  const saveProjectEmployeeRate = (projectEmployee: IProjectEmployee) => {
    const rate: IProjectEmployeeRate = {
      id: 0,
      start: projectEmployee.validFrom ?? '',
      end: projectEmployee.validUntil ?? '',
      rate: rateState ?? 0,
      currency: currency ?? '',
      state: ProjectEmployeeState.PLANNED,
    };

    saveProjectEmployeeRateCall(rate)
      .then((response: AxiosResponse<IProjectEmployeeRate>) => {
        const updatedProjectEmployee = {
          ...projectEmployee,
          projectEmployeeRate: response.data,
        };

        updateProjectEmployee(updatedProjectEmployee).catch(
          (error: AxiosError) => {
            modalErrorHandler(aeft('failedToSaveProjectEmployee'), error);
          }
        );
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(aeft('failedToSaveProjectEmployeeRate'), error);
      });
  };

  /**
   * Saves the project employee
   * @returns
   */
  const saveRecord = () => {
    const payload = { ...projectEmployee };
    // If the saving after adding employee is for updating the employee on the project
    if (isUpdate) {
      updateProjectEmployee(payload)
        .then((response: AxiosResponse<IProjectEmployee>) => {
          const projectEmployee = response.data;
          onSave(projectEmployee);

          // Save rate
          updateProjectEmployeeRate(projectEmployee);
        })
        .catch((error: AxiosError) => {
          modalErrorHandler(aeft('failedToSaveProjectEmployee'), error);
        });
    }
    // Creating/adding new employee to the project
    else {
      const projectEmp = { ...projectEmployee };
      // Check if employee is already part of project
      const existingProjectEmployee = projectEmployees.find(
        (projectEmployee) =>
          projectEmployee.projectEmployeeRole === projectEmp.projectEmployeeRole
        // &&
        // ProjectEmployee.employeeId === employee.id
      );
      if (existingProjectEmployee) {
        modalOkHandler('', aeft('employeeAlreadyHasThisRole'));
      } else {
        // Save new project employee
        createProjectEmployee(payload)
          .then((response: AxiosResponse<IProjectEmployee>) => {
            const projectEmployee = response.data;
            onSave(projectEmployee);

            // Save rate
            saveProjectEmployeeRate(projectEmployee);
          })
          .catch((error: AxiosError) => {
            modalErrorHandler(aeft('failedToSaveProjectEmployee'), error);
          });
      }
    }
  };

  /**
   * Handles changes made in Rate field
   * @param {*} ratePerHour
   */
  const handleRateOnChange = (
    ratePerHour: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { value } = ratePerHour.target;
    if (value.trim() === '') {
      value = '';
    }
    if (value === null) {
      setRateState(0);
    } else if (value.match(regexExpressionDouble)) {
      setRateState(parseFloat(value));
    }
  };

  /**
   * Checks if all the required values are filled
   * @returns
   */
  const insertRecord = () => {
    // Check if dates are int between
    let validFrom = true;
    let validUntil = true;
    if (project?.start && project?.end) {
      validFrom = isBetweenDate(
        projectEmployee.validFrom ?? '',
        project?.start,
        project?.end
      );
      validUntil = isBetweenDate(
        projectEmployee.validUntil ?? '',
        project?.start,
        project?.end
      );
    }

    if (!projectEmployee.employeeId) {
      modalOkHandler(aeft('notice'), aeft('employeeRequired'));
      return;
    }

    if (projectEmployee.projectEmployeeRole === null) {
      modalOkHandler(aeft('notice'), aeft('roleRequired'));
      return;
    }

    if (!projectEmployee.validUntil) {
      modalOkHandler(aeft('notice'), aeft('validUntilRequired'));
      return;
    }

    if (!currency && rateState) {
      modalOkHandler(aeft('notice'), aeft('currencyRequired'));
      return;
    }

    if (!rateState && currency) {
      modalOkHandler(aeft('notice'), aeft('ratePerHourRequired'));
      return;
    }

    // If true, continue else show popup
    if (validFrom && validUntil) {
      saveRecord();
    } else {
      const body =
        aeft('selectedDateIsOutsideProjectDateContinue') +
        moment(project?.start).format(getDateFormat()) +
        aeft('until') +
        moment(project?.end).format(getDateFormat()) +
        aeft('doYouWantToContinue');
      modalConfirmHandler(
        aeft('selectedDateIsOutsideProjectDate'),
        body,
        async () => {
          saveRecord();
          const updatedProject: IProject = (await updateProjectDate(
            project,
            projectEmployee.validFrom,
            projectEmployee.validUntil
          )) as IProject;
          onChange(updatedProject);
        }
      );
    }
  };

  useEffect(() => {
    if (isUpdate) {
      getProjectEmployee(projectEmployeeId)
        .then((response: AxiosResponse<IProjectEmployee>) => {
          const projectEmployeeData = response.data;
          setProjectEmployee(projectEmployeeData);
          setRateState(projectEmployeeData.projectEmployeeRate?.rate ?? 0);
          setCurrency(projectEmployeeData.projectEmployeeRate?.currency ?? '');
        })
        .catch((error: AxiosError<IProjectEmployee>) => {
          modalErrorHandler(aeft('failedToRetrieveProjectEmployee'), error);
        });
    } else {
      const newProjectEmployee = {} as IProjectEmployee;
      newProjectEmployee.validFrom = moment().toISOString();
      setProjectEmployee(newProjectEmployee);
    }
  }, []);

  return (
    <Card>
      <CardBody>
        <Form>
          <FormGroup>
            <InputFormLabel isRequired text={aeft('employee')} />
            <ModifiedEmployeeDropdown
              isDisabled={isUpdate}
              permissions={permissionList}
              employeeId={projectEmployee?.employeeId ?? (NO_ID as number)}
              onChange={handleEmployeeOnChange}
            />
          </FormGroup>
          <FormGroup>
            <InputFormLabel isRequired text={aeft('role')} />
            <ProjectEmployeeRoleDropdown
              onChange={handleRoleChange}
              projectEmployeeRole={projectEmployee?.projectEmployeeRole ?? ''}
              employeeId={projectEmployee?.employeeId ?? null}
            />
          </FormGroup>
          <FormGroup>
            <InputFormLabel isRequired={false} text={aeft('validFrom')} />
            <Datetime
              dateFormat={dateFormat}
              input
              timeFormat={false}
              inputProps={{ placeholder: dateFormat }}
              closeOnSelect
              onChange={handleDateStartChange}
              value={moment(projectEmployee.validFrom)}
              isValidDate={from}
              locale={i18n.language}
            />
          </FormGroup>
          <FormGroup>
            <InputFormLabel isRequired text={aeft('validUntil')} />
            <Datetime
              dateFormat={dateFormat}
              input
              timeFormat={false}
              inputProps={{ placeholder: dateFormat }}
              closeOnSelect
              onChange={handleDateEndChange}
              value={moment(projectEmployee.validUntil)}
              isValidDate={until}
              locale={i18n.language}
            />
          </FormGroup>
          <FormGroup>
            <InputFormLabel isRequired={!!rateState} text={aeft('currency')} />
            <CurrencyDropdown
              currency={currency}
              onChange={handleCurrencyOnChange}
              isClearable
            />
          </FormGroup>
          <InputFormLabel isRequired={!!currency} text={aeft('rate')} />
          <Input
            bsSize="lg"
            type="number"
            name="ratePerHour"
            min="0"
            step="0.1"
            onChange={handleRateOnChange}
            value={rateState === null ? '' : rateState?.toString()}
            placeholder={aeft('ratePerHour')}
          />
          <FormGroup />
        </Form>
        <br />
        <Button color="primary" onClick={insertRecord}>
          {isUpdate ? aeft('updateEmployee') : aeft('addEmployee')}
        </Button>{' '}
        <Button onClick={() => onCancel()} color="primary">
          {aeft('cancel')}
        </Button>
      </CardBody>
    </Card>
  );
};

export default withModals(UpdateProjectEmployeeModal);
