import moment, { Moment } from 'moment';

import { createLinkIfAuthorised } from '../../../utils/helpers/permission';
import i18n from '../../../i18n';
import {
  IActivityListItem,
  IDescriptionDetail,
  IRelatedActivitiesDetail,
  IStatusDetail,
  IResponsibleDetail,
  IEditedContactPersonDetail,
  ICustomerSector,
  IContactPerson,
  IContactPersonTopicListItem,
  IContactPersonRole,
  ICustomerSite,
  ICustomer,
} from '../../../utils/types/modelTypes';
import { EntityType } from '../../../utils/enums/pageComponents';
import {
  IContactPersonDetail,
  ICustomerDetailContactPersonListItem,
  IObjectRelatedEntities,
} from '../../../utils/types/responseTypes';
import {
  CONTACT_PERSON_ATTITUDE,
  CONTACT_PERSON_TARGET,
} from '../../../utils/enums/contactPerson';
import { ContactStatus } from '../../../utils/enums/contact';
import { NONE, notApplicable } from '../../../utils/constants';
import { IContactDetail } from './ContactPersonDetailsProvider';
import { IObjectNameAndId } from '../../../utils/types/commonTypes';
import { isEmpty } from '../../../utils/helpers/array';

export const defaultStatusDetail = {
  defaultAttitude: {
    label: CONTACT_PERSON_ATTITUDE.neutral.label,
    code: CONTACT_PERSON_ATTITUDE.neutral.value,
  },
  defaultTarget: CONTACT_PERSON_TARGET.noTargetForAcquisition.value,
  defaultAcquisition: false,
  defaultAssignmentState: ContactStatus.COLD.code,
  defaultContactAlumnus: NONE,
};

export const t = (keyName: string) => i18n.t(`ContactPersonDetails.${keyName}`);

export const translateCallingActivityCard = (keyName: string) =>
  i18n.t(`ActivityCard.${keyName}`);

export const translateContactProfile = (keyName: string) =>
  i18n.t(`ContactProfile.${keyName}`);

/**
 * Cleans the value for date and time
 * @param {*} date
 * @param {*} time
 * @returns
 */
export const getAppointment = (
  date: Moment | null,
  time: Moment | null | undefined
) => {
  // If date is null, return null
  if (!(date instanceof Object)) {
    return null;
  }

  // Time is a datetime that contains the time to used by the date..
  if (!time) {
    return date;
  }

  // Combine the date and time
  const appointment = date.set({
    hour: time.get('hour'),
    minute: time.get('minute'),
  });

  return appointment;
};

export const createEmployeeLinkFromActivity = ({
  creator: { name, id },
}: IActivityListItem) => createLinkIfAuthorised(EntityType.EMPLOYEE, name, id);

/**
 * Method that returns the corresponding gender prefix
 * @param {string} gender
 * @returns {string} newGenderPrefix
 */
export const handleGenderPrefixChange = (gender: string) => {
  let newGenderPrefix = '';
  if (gender) {
    switch (gender.toLocaleLowerCase()) {
      case 'male': {
        newGenderPrefix = 'Mr';
        break;
      }
      case 'female': {
        newGenderPrefix = 'Ms';
        break;
      }
      default:
        break;
    }
  }
  return newGenderPrefix;
};

/**
 * Method to transform response into seperate details and add default values if needed
 * @param contactPerson IContactPersonDetail
 * @returns
 *
 * contactDetail
 *
 * statusDetail
 *
 * description
 *
 * relatedActivitiesDetail
 *
 * historyOfActivitiesDetail
 *
 * relatedEntitiesDetail
 *
 * responsibleDetail
 */
export const transformContactPersonToDetails = (
  contactPerson: IContactPersonDetail
) => {
  const {
    // Contact Detail
    id,
    firstname,
    lastname,
    title,
    gender,
    customerAccount,
    customer,
    customerSite,
    statusGdpr,
    customerSiteContactInformation,
    contactInformation,
    sectors,
    role,
    functionRole,
    preferredLanguage,
    newsletter,
    topics,
    // Status Detail
    attitude,
    attitudeCode,
    target,
    acquisition,
    assignmentState,
    contactAlumnus,
    // Description
    notes,
    notesId,
    // Related Activities
    dueDate,
    nextActivity,
    // History of Activities
    activities,
    // Related Entities
    relatedEntities,
    // Responsible
    responsible,
    involvedEmployees,
  } = contactPerson;

  const {
    defaultAttitude,
    defaultTarget,
    defaultAcquisition,
    defaultAssignmentState,
    defaultContactAlumnus,
  } = defaultStatusDetail;

  const contactDetail: IContactDetail = {
    id,
    firstname,
    lastname,
    title,
    gender,
    genderPrefix: handleGenderPrefixChange(gender),
    customerAccount,
    customer,
    customerSite,
    statusGdpr,
    customerSiteContactInformation,
    contactInformation,
    sectors,
    role: role ?? { id: 0, name: notApplicable },
    functionRole,
    preferredLanguage,
    newsletter,
    topics,
  };

  const statusDetail: IStatusDetail = {
    attitude: attitude ?? defaultAttitude.label,
    attitudeCode: attitudeCode ?? defaultAttitude.code,
    target: target ?? defaultTarget,
    acquisition: acquisition ?? defaultAcquisition,
    assignmentState: assignmentState ?? defaultAssignmentState,
    contactAlumnus: contactAlumnus ?? defaultContactAlumnus,
  };

  const description: IDescriptionDetail = {
    notes: notes ?? '',
    notesId: notesId ?? 0,
  };

  const relatedActivitiesDetail: IRelatedActivitiesDetail = {
    dueDate,
    nextAction: nextActivity,
  };

  const historyOfActivitiesDetail: IActivityListItem[] = activities;

  const relatedEntitiesDetail: IObjectRelatedEntities[] = relatedEntities;

  const responsibleDetail: IResponsibleDetail = {
    responsible,
    involvedEmployees,
  };

  return {
    contactDetail,
    statusDetail,
    description,
    relatedActivitiesDetail,
    historyOfActivitiesDetail,
    relatedEntitiesDetail,
    responsibleDetail,
  };
};

/**
 * Convert ISO 8601 duration to HH:MM:SS
 * @param {string} durationString
 * @returns {string} formattedDuration
 */
export const formatToHHMMSS = (durationString: string) => {
  const durationPattern = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
  const isoDuration = durationPattern.exec(durationString);
  if (isoDuration) {
    const hours = parseInt(isoDuration[1] || '0', 10).toString();
    const minutes = parseInt(isoDuration[2] || '0', 10).toString();
    const seconds = parseInt(isoDuration[3] || '0', 10).toString();

    const formattedHours = hours.padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');
    const formattedSeconds = seconds.padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return 'Invalid duration string.';
};

/**
 * Convert moment duration to HH:MM:SS
 * @param {moment.Duration} duration
 * @returns {string} formattedDuration
 */
export const formatMomentDurationToHHMMSS = (duration: moment.Duration) => {
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  // Format the duration as 'hh:mm:ss'
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

/**
 * Builds initial value of contactpersondetail state
 * @param customer
 * @param userNameAndId
 * @returns initial value for the state
 */
export const generateContactDetailState = (
  customer: IObjectNameAndId | null,
  userNameAndId: IObjectNameAndId
) => ({
  title: null,
  lastname: '',
  firstname: '',
  gender: '',
  contactInformation: [],
  responsible: userNameAndId,
  involvedEmployees: [],
  customer,
  customerSite: null,
  role: '',
  topics: [],
  sectors: [],
  preferredLanguage: '',
});

const contactPersonFields = {
  firstname: 'firstname',
  lastname: 'lastname',
  sectors: 'sectors',
  responsible: 'responsible',
  involvedEmployees: 'involvedEmployees',
  contactInformation: 'contactInformation',
  title: 'title',
  gender: 'gender',
  role: 'role',
  topics: 'topics',
  customerSite: 'customerSite',
  preferredLanguage: 'preferredLanguage',
};

export const getEditedFieldValues = (
  fieldsWithUpdates: string[],
  currentFieldValues: IEditedContactPersonDetail
) => {
  const detailsToBeSaved = {} as IEditedContactPersonDetail;
  fieldsWithUpdates.forEach((field) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    detailsToBeSaved[field] = (currentFieldValues as any)[field];
  });
  return detailsToBeSaved;
};

export const distributeFieldValues = (fetchedData: IContactPersonDetail) => {
  const modalContactPersonDetails = {} as IEditedContactPersonDetail;
  Object.keys(contactPersonFields).forEach((field) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    modalContactPersonDetails[field] = (fetchedData as any)[field];
  });
  return modalContactPersonDetails;
};

export const convertContactPersonDetailsToCollapsibleListItem = ({
  id,
  title,
  lastname,
  firstname,
  contactInformation,
  role,
  customerSite,
  activities,
}: IContactPersonDetail) =>
  ({
    id,
    title,
    lastname,
    firstname,
    contactInformation,
    role: role?.name,
    customerSite: customerSite
      ? { id: customerSite.id, name: customerSite.nameAndLocation }
      : null,
    notes: activities,
  } as ICustomerDetailContactPersonListItem);

export enum ContactFieldsEnum {
  CONTACT_PERSON_NAME = 'contactPersonName',
  TITLE = 'title',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  GENDER = 'gender',
  CUSTOMER = 'customer',
  CUSTOMER_SITE = 'customerSite',
  CUSTOMER_SITE_NUMBER = 'customerSiteContactInformation',
  CONTACT_INFORMATION = 'contactInformation',
  CONTACT_PERSON_SECTORS = 'sectors',
  ROLE = 'role',
  FUNCTION = 'functionRole',
  TOPIC = 'topics',
  PHONE_NUMBER = 'phoneNumber',
  MOBILE_NUMBER = 'mobileNumber',
  XING_PROFILE = 'xingProfile',
  LINKEDIN_PROFILE = 'linkedInProfile',
  EMAIL_ADDRESS = 'emailAddress',
  STATUS_GDPR = 'statusGdpr',
  CONTACT_ALUMNUS = 'contactAlumnus',
  NEWSLETTER = 'newsletter',
  PREFERRED_LANGUAGE = 'preferredLanguage',
  ASIGNMENT_STATE = 'assignmentState',
  ATTITUDE = 'attitude',
  ATTITUDE_CODE = 'attitudeCode',
  TARGET = 'target',
  ACQUISITION = 'acquisition',
  ALUMNUS = 'contactAlumnus',
}

export const translateModalAddActivity = (keyName: string) =>
  i18n.t(`ModalAddActivity.${keyName}`);

export const weekdaysOnly = (current: Moment) =>
  current.day() !== 0 && current.day() !== 6;

/**
 * Method to clean the values of the date and time pickers
 */
export const cleanValues = (date: Moment, time: Moment) =>
  time === null
    ? moment(`${moment(date).format('YYYY-MM-DD')}\t 00:00`)
    : moment(
        `${moment(date).format('YYYY-MM-DD')}\t${moment(time).format('HH:mm')}`
      );

/**
 * Convert CustomerSector into ObjectNameAndId
 * @param objectSectors
 * @returns customer
 */
export const convertCustomerSectorToObjectNameAndId = (
  customer: ICustomerSector[]
) => {
  const objectSectorObjectNameAndId: IObjectNameAndId[] = [];
  customer.forEach(({ sector: { id, sector } = {} }) => {
    if (id && sector)
      objectSectorObjectNameAndId.push({
        id,
        name: sector,
      });
  });
  return objectSectorObjectNameAndId;
};

/**
 * Checks if inherited sectors are included in new sectors
 */
export const inheritedSectorsStillPresent = (
  inheritedSectors: IObjectNameAndId[],
  newSectors: IObjectNameAndId[]
) =>
  isEmpty(inheritedSectors) ||
  inheritedSectors.every((sector) =>
    newSectors.some(({ id }) => sector.id === id)
  );

/**
 * Remove ids between two arrays
 * @returns filteredSectors
 */
export const removeSimilarSectors = (
  inheritedSector: IObjectNameAndId[],
  oldSector: IObjectNameAndId[]
) =>
  inheritedSector.filter(
    ({ id: inheritedId }) =>
      !oldSector.some(({ id: oldId }) => oldId === inheritedId)
  );

/**
 * Converts Details to The regular object
 * This is a temp fix to fix a larger issue of unconverted items as of ticket 5063
 * @param contactDetail
 */
export const convertDetailToRegular = ({
  id,
  lastname,
  firstname,
  gender,
  customer,
  customerSite: { id: customerSiteId, nameAndLocation },
  contactInformation,
  topics,
  role,
}: IContactPersonDetail) => {
  const convertedItem: IContactPerson = {
    id,
    lastname,
    firstname,
    gender,
    contactInformations: contactInformation.map(
      ({ id, info, status, type }) => ({
        id,
        status,
        info,
        contactInformationType: type,
      })
    ),
    topics: topics.map(
      ({ id, name }) =>
        ({
          id,
          topic: name,
        } as IContactPersonTopicListItem)
    ),
    customer: { id: customer.id, name: customer.name } as ICustomer,
    contactPersonRole: {
      id: role?.id ?? 0,
      role: role?.name ?? '',
    } as IContactPersonRole,
    contactPersonAssignments: [],
    contactPersonAttitudes: [],
    contactPersonNotes: [],
    customerSite: {
      id: customerSiteId,
      name: nameAndLocation.slice(0, nameAndLocation.indexOf('(') - 1),
    } as ICustomerSite,
  };
  return convertedItem;
};
