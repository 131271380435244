import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';

import { ContactPersonDetailsContext } from './ContactPersonDetailsProvider';
import { updateContactPersonDetails } from '../../../services/api/contactPerson';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { transformContactPersonToDetails } from './contactPersonHelper';
import { IContactPersonDetail } from '../../../utils/types/responseTypes';
import TextareaCard from '../../../components/cards/TextareaCard';

// !-- Used in ContactPersonDetails, ContactPersonOverview

interface IProps extends IWithModalsProps {}

const t = (keyName: string) => i18n.t(`NotesCard.${keyName}`);

/**
 * Card for displaying notes of a contactperson
 */
const ContactPersonDescriptionCard = ({ modalErrorHandler }: IProps) => {
  const {
    contactDetail: { id },
    description,
    setDescription,
    setUnsavedDescription,
  } = useContext(ContactPersonDetailsContext);

  const [editedDescription, setEditedDescription] = useState('');

  const { notes, notesId } = description;

  const handleSaveContactPersonDescription = async () => {
    try {
      const { data } = await updateContactPersonDetails({
        id,
        notes: editedDescription,
        notesId,
      } as IContactPersonDetail);
      const { description } = transformContactPersonToDetails(data);
      setDescription(description);
    } catch (error) {
      modalErrorHandler(t('failedToSaveDescription'), error);
    }
  };

  const footerButtons = (
    <Button
      color="primary"
      onClick={() => handleSaveContactPersonDescription()}
    >
      {t('save')}
    </Button>
  );

  const handleChangeDescription = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setEditedDescription(value);
  };

  // Updates the editable description when the description is saved
  useEffect(() => {
    if (notes) {
      setEditedDescription(notes);
    }
  }, [description]);

  // Check if there are unsaved changes
  useEffect(() => {
    setUnsavedDescription(editedDescription !== notes);
  }, [editedDescription]);

  return (
    <TextareaCard
      title={t('descriptionOfContactPerson')}
      value={editedDescription}
      onChange={handleChangeDescription}
      footerButtons={footerButtons}
      leftTitle
    />
  );
};

export default withModals(ContactPersonDescriptionCard);
