import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import React from 'react';
import { Button, CardBody, Container, Label } from 'reactstrap';

import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { isEmpty } from '../../../utils/helpers/GenericHelper';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IError } from '../../../utils/types/commonTypes';
import { IRejectionItem, IRejectionType } from '../../../utils/types/modelTypes';
import RejectionTypeInput from './RejectionTypeInput';

interface IState {
  rejectionTypes: IRejectionType[];
  rejectionItems: IRejectionItem[];
  matchingRejection: Map<IRejectionType, IRejectionItem[]>;
  tableData: IRejectionTypeEntry[];
}

interface IProps extends IWithModalsProps {
  rejectionDidUpdate: boolean;
  handleRejectionTypeUpdate: () => void;
}

interface IRejectionTypeEntry {
  name: JSX.Element;
  deleteButton: JSX.Element;
}

/**
 * Class for showing the Rejection Types
 */
class RejectionTypeSettings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      rejectionTypes: [],
      rejectionItems: [],
      matchingRejection: new Map(),
      tableData: [],
    };
  }

  t(keyName: string) {
    return i18n.t(`RejectionTypeSettings.${keyName}`);
  }

  async componentDidMount() {
    await this.loadRejectionData();
  }

  async loadRejectionData() {
    const { modalErrorHandler } = this.props;
    await axios.employee
      .get('rejection-types')
      .then((response: AxiosResponse<IRejectionType[]>) => {
        if (!isEmpty(response.data)) {
          const rejectionTypes = response.data.sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          this.setState({ rejectionTypes });
        }
      })
      .catch((error: IError) => {
        modalErrorHandler(this.t('failedToRetrieveRejectionType'), error);
      });

    await axios.employee
      .get('rejection-items')
      .then((response: AxiosResponse<IRejectionItem[]>) => {
        if (!isEmpty(response.data)) {
          this.setState({
            rejectionItems: response.data,
          });
        }
      })
      .catch((error: IError) => {
        modalErrorHandler(this.t('failedToRetrieveRejectionType'), error);
      });

    this.createMatchingRejection();
  }

  /**
   * Create a Map with rejection types as keys and rejection items as values
   */
  createMatchingRejection = () => {
    const { rejectionItems, rejectionTypes } = this.state;
    const matchingRejection: Map<IRejectionType, IRejectionItem[]> = new Map();
    rejectionTypes.forEach((rejectionType: IRejectionType) => {
      matchingRejection.set(
        rejectionType,
        rejectionItems.filter(
          (item) => item.rejectionType?.name === rejectionType.name
        )
      );
    });

    this.setState({
      matchingRejection,
    });
  };

  async componentDidUpdate(prevProps: IProps) {
    const { rejectionDidUpdate, handleRejectionTypeUpdate, modalErrorHandler } =
      this.props;
    if (
      rejectionDidUpdate &&
      prevProps.rejectionDidUpdate !== rejectionDidUpdate
    ) {
      handleRejectionTypeUpdate();
      await axios.employee
        .get('rejection-items')
        .then((response: AxiosResponse<IRejectionItem[]>) => {
          if (!isEmpty(response.data)) {
            this.setState({
              rejectionItems: response.data,
            });
          }
        })
        .catch((error: IError) => {
          modalErrorHandler(this.t('failedToRetrieveRejectionType'), error);
        });

      this.createMatchingRejection();
    }
  }

  /**
   * Prepares data for table
   * @param {*} rejectionTypes
   * @returns tableData
   */
  prepareTableData = () => {
    const { rejectionTypes, matchingRejection } = this.state;
    const newTableData: IRejectionTypeEntry[] = [];

    if (isEmpty(rejectionTypes)) return [];

    rejectionTypes.forEach((rejectionType: IRejectionType) => {
      const entry = {
        name: (
          <Button
            color="link"
            onClick={() => this.updateRejectionType(rejectionType)}
          >
            {rejectionType.name}
          </Button>
        ),
        deleteButton: (
          <>
            {isEmpty(matchingRejection.get(rejectionType)) && (
              <Button
                color="primary"
                onClick={() => this.deleteRejectionType(rejectionType)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </>
        ),
      };
      newTableData.push(entry);
    });
    return newTableData;
  };

  /**
   * Handles updating upon clicking the item
   * @param {*} rejectionType
   */
  updateRejectionType = (rejectionType: IRejectionType | null) => {
    const { rejectionDidUpdate, modalFormHandler, toggleModalForm } =
      this.props;
    const { matchingRejection } = this.state;
    modalFormHandler(
      rejectionType ? this.t('editRejectionType') : this.t('addRejectionType'),
      <RejectionTypeInput
        rejectionType={rejectionType}
        rejectionItems={
          rejectionType ? matchingRejection.get(rejectionType) : undefined
        }
        rejectionDidUpdate={rejectionDidUpdate}
        onSave={this.onSave}
        onCancel={() => toggleModalForm()}
      />
    );
  };

  /**
   * Handles deleting rejection type
   * @param {*} rejectionType
   */
  deleteRejectionType(rejectionType: IRejectionType) {
    const { modalDeleteHandler, modalErrorHandler } = this.props;
    const { rejectionTypes } = this.state;
    modalDeleteHandler(
      this.t('deleteRejectionType'),
      this.t('deleteMessage'),
      async () => {
        await axios.project
          .delete(`rejection-types/${rejectionType.id}`)
          .then(() => {
            const newList = rejectionTypes.filter(
              (oldRejectionType: IRejectionType) =>
                oldRejectionType.id !== rejectionType.id
            );

            this.setState({
              rejectionTypes: newList,
            });
          })
          .catch((error) => {
            modalErrorHandler(this.t('failedToDeleteRejectionType'), error);
          });
      }
    );
  }

  /**
   * Updates the state of Rejection Type upon saving of the new or edited Rejection Type
   * @param {*} rejectionType
   */
  onSave = (rejectionType: IRejectionType) => {
    const { modalErrorHandler } = this.props;
    const { rejectionTypes: newList } = this.state;

    const index = newList.findIndex((item) => item.id === rejectionType.id);
    if (index >= 0) {
      newList[index] = rejectionType;
    } else {
      newList.push(rejectionType);
    }

    axios.employee
      .get('rejection-items')
      .then((response: AxiosResponse<IRejectionItem[]>) => {
        if (!isEmpty(response.data)) {
          this.setState({
            rejectionItems: response.data,
          });
        }
      })
      .catch((error: IError) => {
        modalErrorHandler(this.t('failedToRetrieveRejectionType'), error);
      });

    this.createMatchingRejection();

    this.setState({ rejectionTypes: newList });
    void this.componentDidMount();
  };

  render() {
    const { rejectionTypes } = this.state;
    const tableData = this.prepareTableData();
    const preparedColumns = [
      {
        type: 'component',
        header: this.t('rejectionType'),
        accessor: 'name',
        show: 'true',
      },
      {
        type: 'component',
        header: this.t('delete'),
        accessor: 'deleteButton',
        show: 'true',
      },
    ];
    return (
      <Container fluid>
        <div>
          <Button
            color="primary"
            className="float-end"
            onClick={() => this.updateRejectionType(null)}
          >
            <FontAwesomeIcon icon={faPlus} /> {this.t('add')}
          </Button>
        </div>
        <br />
        <CardBody>
          {!isEmpty(rejectionTypes) ? (
            <DynamicTable data={tableData} columns={preparedColumns} />
          ) : (
            <Label>{this.t('rejectionTypesEmpty')}</Label>
          )}
        </CardBody>
      </Container>
    );
  }
}

export default withModals(RejectionTypeSettings);
