import React, { useContext, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import ReactQuill from 'react-quill';

import Auth from '../../../services/axios/Auth';
import {
  REACTQUILL_FORMATS,
  REACTQUILL_MODULES,
  READWRITE,
} from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { generateTitle } from '../../../utils/helpers/icon';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import {
  enumValuesToDropdownOptions,
  labelValue,
  sortOptionsByValue,
} from '../../../utils/helpers/dropdown';
import { IEmployeeDetail } from '../../../utils/types/responseTypes';
import { RootState } from '../../../redux/store';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { EmployeeDetailsContext } from './EmployeeDetailProvider';
import { updateEmployeeFromDetail } from '../../../services/api/employee';
import {
  EmployeeAbroad,
  EmployeeDefence,
  EmployeeMobility,
} from '../../../utils/enums/employee';
import { edt, updatedEmployeeDetail } from './employeeDetailHelper';
import DetailsCard, { CardData } from '../../../components/cards/DetailsCard';

const employeeDefence = enumValuesToDropdownOptions(
  Object.values(EmployeeDefence),
  'EmployeeEditableFieldsEnum'
);

const employeeMobility = enumValuesToDropdownOptions(
  Object.values(EmployeeMobility),
  'EmployeeEditableFieldsEnum'
);

const employeeAbroad = enumValuesToDropdownOptions(
  Object.values(EmployeeAbroad),
  'EmployeeEditableFieldsEnum'
);

interface IProps extends PropsFromRedux, IWithModalsProps {}

const employeeEditableFields = {
  mobility: 'mobility',
  employeeDefense: 'employeeDefense',
  employeeAbroad: 'employeeAbroad',
};

/**
 * This class displays Skills, Commitments, Desires and Constraints of selected employee.
 */
const EmployeeSkillsEtcCard = ({
  modalErrorHandler,
  account: {
    employeeDetails: { id: employeeId },
  },
}: IProps) => {
  const {
    employeeDetail,
    employeeDetail: {
      mobility: originalMobility,
      employeeDefense: originalEmployeeDefense,
      employeeAbroad: originalEmployeeAbroad,
      commitment: originalCommitment,
      mentorEmployee: { id: mentorId },
      hrResponsible: { id: hrId },
    },
    setEmployeeDetail,
  } = useContext(EmployeeDetailsContext);

  const [employeeEdited, setEmployeeEdited] = useState({} as IEmployeeDetail);
  const [selectEditableField, setEditableField] = useState('');

  const hasPermission =
    Auth.hasPermission(
      [PERMISSION_URI.employeeManagement.readWrite.uri],
      [READWRITE]
    ) ||
    (Auth.hasPermission(
      [PERMISSION_URI.employeeManageMyEmployees.readWrite.uri],
      [READWRITE]
    ) &&
      (mentorId === employeeId || hrId === employeeId));

  // Check if the item has any changes
  const fieldHasNoChanges = employeeEdited.id === undefined;

  // The mobility dropdown and label
  const employeeMobilityDropdownValue = employeeMobility.find(
    (option) => option.value === (employeeEdited.mobility ?? originalMobility)
  );

  // The defence dropdown and label
  const employeeDefenceDropdownValue = employeeDefence.find(
    (option) =>
      option.value ===
      (employeeEdited.employeeDefense ?? originalEmployeeDefense)
  );

  // The abroad dropdown and label
  const employeeAbroadDropdownValue = employeeAbroad.find(
    (option) =>
      option.value === (employeeEdited.employeeAbroad ?? originalEmployeeAbroad)
  );

  // Commitment Value
  const displayedCommitment = originalCommitment ?? originalCommitment;

  /**
   * Method that resets the employee
   */
  const resetEmployee = () => {
    setEmployeeEdited({} as IEmployeeDetail);
    setEditableField('');
  };

  /**
   * Method that determines what field is currently clicked
   * @param {*} field The cicked field
   */
  const toggleUpdateField = (field: string) => {
    if (selectEditableField !== field) {
      setEditableField(field);
    }
  };

  /**
   * Method that handles changes on the dropdown and sets it to the correct employee property using dynamically-computed name
   * @param {*} selectedDropdownOption
   * @param {*} property
   */
  const handleDropdownChanges = (
    { value }: IDropdownOption<string>,
    property: string
  ) => {
    setEmployeeEdited({
      ...employeeEdited,
      id: employeeDetail.id,
      [property]: value,
    });
  };

  /**
   * Handles changes in commitment
   * @param {*} commitment
   */
  const handleCommitmentChange = (commitment: string) => {
    setEmployeeEdited({
      ...employeeEdited,
      id: employeeDetail.id,
      commitment,
    });
  };

  /**
   * Method that saves the employee and resets the fields
   */
  const saveEmployee = () => {
    updateEmployeeFromDetail(employeeEdited)
      .then(() => {
        setEmployeeDetail(
          updatedEmployeeDetail(employeeDetail, employeeEdited)
        );
      })
      .catch((error) => {
        setEmployeeEdited({} as IEmployeeDetail);
        modalErrorHandler(edt('failedToSaveEmployee'), error);
      });
  };

  useEffect(() => {
    resetEmployee();
  }, [employeeDetail]);

  // Add the initial fields
  const fields = [
    {
      label: edt('mobility'),
      value: (
        <div onClick={() => toggleUpdateField(employeeEditableFields.mobility)}>
          {selectEditableField === employeeEditableFields.mobility &&
          hasPermission ? (
            <Select
              placeholder={i18n.t('DynamicTable.select')}
              styles={{
                menu: (styles: { [key: string]: React.CSSProperties }) => ({
                  ...styles,
                  zIndex: 100,
                }),
              }}
              value={employeeMobilityDropdownValue}
              options={sortOptionsByValue(employeeMobility)}
              onChange={(selectedValue: IDropdownOption<string>) =>
                handleDropdownChanges(
                  selectedValue,
                  employeeEditableFields.mobility
                )
              }
            />
          ) : (
            labelValue(employeeMobilityDropdownValue)
          )}
        </div>
      ),
      isRequired: false,
    },
    {
      label: edt('affinityDefense'),
      value: (
        <div
          onClick={() =>
            toggleUpdateField(employeeEditableFields.employeeDefense)
          }
        >
          {selectEditableField === employeeEditableFields.employeeDefense &&
          hasPermission ? (
            <Select
              placeholder={i18n.t('DynamicTable.select')}
              styles={{
                menu: (styles: { [key: string]: React.CSSProperties }) => ({
                  ...styles,
                  zIndex: 100,
                }),
              }}
              value={employeeDefenceDropdownValue}
              options={sortOptionsByValue(employeeDefence)}
              onChange={(selectedValue: IDropdownOption<string>) =>
                handleDropdownChanges(
                  selectedValue,
                  employeeEditableFields.employeeDefense
                )
              }
            />
          ) : (
            labelValue(employeeDefenceDropdownValue)
          )}
        </div>
      ),
      isRequired: false,
    },
    {
      label: edt('projectAbroad'),
      value: (
        <div
          onClick={() =>
            toggleUpdateField(employeeEditableFields.employeeAbroad)
          }
        >
          {selectEditableField === employeeEditableFields.employeeAbroad &&
          hasPermission ? (
            <Select
              placeholder={i18n.t('DynamicTable.select')}
              styles={{
                menu: (styles: { [key: string]: React.CSSProperties }) => ({
                  ...styles,
                  zIndex: 100,
                }),
              }}
              value={employeeAbroadDropdownValue}
              options={sortOptionsByValue(employeeAbroad)}
              onChange={(selectedValue: IDropdownOption<string>) =>
                handleDropdownChanges(
                  selectedValue,
                  employeeEditableFields.employeeAbroad
                )
              }
            />
          ) : (
            labelValue(employeeAbroadDropdownValue)
          )}
        </div>
      ),
      isRequired: false,
    },
  ] as CardData[];

  return (
    <Container fluid>
      <Card>
        <CardHeader>
          {hasPermission && (
            <div className="float-end flex-button">
              <Button
                color="primary"
                size="m"
                onClick={() => {
                  saveEmployee();
                }}
                disabled={fieldHasNoChanges}
              >
                {generateTitle(BUTTON_TITLE_ENUM.SAVE.code)}
              </Button>
              <Button
                color="primary"
                size="m"
                onClick={() => {
                  resetEmployee();
                }}
                disabled={fieldHasNoChanges}
              >
                {generateTitle(BUTTON_TITLE_ENUM.UNDO.code)}
              </Button>
            </div>
          )}
          <CardTitle className="mb-0">
            <h1>{edt('skillsEtc')}</h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="border-end border-light" md="4">
              <DetailsCard title="" fields={fields} border={false} />
              <div className="text-center">
                <Button
                  color="primary"
                  disabled // Temporarily disabled
                  size="m"
                >
                  {edt('openSkillProfile')}
                </Button>
              </div>
            </Col>
            <Col>
              <ReactQuill
                theme="snow"
                modules={REACTQUILL_MODULES}
                formats={REACTQUILL_FORMATS}
                onChange={handleCommitmentChange}
                value={displayedCommitment}
                placeholder={i18n.t('EmployeeSkillsEtcCard.enterCommitment')}
                readOnly={!hasPermission}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = ({ account }: RootState) => ({
  account,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(EmployeeSkillsEtcCard));
