import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import i18n from '../../../i18n';
import FadeAlert from '../../../components/layout/FadeAlert';
import ContactPersonRoleListView from './ContactPersonRoleListView';
import ContactPersonTopicSettings from './ContactPersonTopicSettings';
import CountryListView from './CountryListView';
import CountryStateListView from './CountryStateListView';
import DeclineReasonList from './DeclineReasonList';
import PrioritizationTargetGoal from './PrioritizationTargetGoal';
import RejectionItemSettings from './RejectionItemSettings';
import RejectionTypeSettings from './RejectionTypeSettings';
import ResponsibleRoleListView from './ResponsibleRoleListView';
import SectorListView from './SectorListView';
import TargetPriorityPermissionSettings from './TargetPriorityPermissionSettings';
import WorkingHoursPerMonthView from './WorkingHoursPerMonthView';

/*
 * This class is for creating the Sales Settings Page
 * which allows you to navigate on various of settings in the sales entity
 */
interface IProps
  extends RouteComponentProps<
    Record<string, never>,
    Record<string, never>,
    { successMessage: string }
  > {}

interface IState {
  rejectionDidUpdate: boolean;
}

class SalesSettingsView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      rejectionDidUpdate: false,
    };
  }

  handleRejectionItemUpdate = () => {
    this.setState({ rejectionDidUpdate: true });
  };

  handleRejectionTypeUpdate = () => {
    this.setState({ rejectionDidUpdate: false });
  };

  /**
   * Handles translation for words used in the view
   * @param {*} keyName
   * @returns translated word
   */
  t(keyName: string) {
    return i18n.t(`SalesSettingsView.${keyName}`);
  }

  render() {
    const { rejectionDidUpdate } = this.state;
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{this.t('salesManagement')}</HeaderTitle>
          {generateBreadcrumb(this.props.location.pathname, this.t('settings'))}
        </Header>

        <Card>
          <CardHeader>
            {this.props.location.state?.successMessage && (
              <FadeAlert color="success">
                {this.props.location.state.successMessage}
              </FadeAlert>
            )}
            <h1>{this.t('salesSettings')}</h1>
          </CardHeader>
          <CardBody>
            <Row>
              <Button className="gap-1" color="outline" id="salesBudgetType">
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('budgetTypes')}</h3>
              </Button>
            </Row>
            <br />
            <Row>
              <Button
                className="gap-1"
                color="outline"
                id="salesContactPersonRoles"
              >
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('contactPersonRoles')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#salesContactPersonRoles">
              <ContactPersonRoleListView />
            </UncontrolledCollapse>
            <br />
            <Row>
              <Button className="gap-1" color="outline" id="salesCurrency">
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('currencies')}</h3>
              </Button>
            </Row>
            <br />
            <Row>
              <Button
                className="gap-1"
                color="outline"
                id="salesDeclineReasons"
              >
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('declineReasons')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#salesDeclineReasons">
              <DeclineReasonList />
            </UncontrolledCollapse>
            <br />
            <Row>
              <Button
                className="gap-1"
                color="outline"
                id="salesResponsibleRoles"
              >
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('responsibleRoles')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#salesResponsibleRoles">
              <ResponsibleRoleListView />
            </UncontrolledCollapse>
            <br />
            <Row>
              <Button className="gap-1" color="outline" id="salesSectors">
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('sectors')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#salesSectors">
              <SectorListView />
            </UncontrolledCollapse>
            <br />
            <Row>
              <Button className="gap-1" color="outline" id="salesCountry">
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('country')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#salesCountry">
              <CountryListView />
            </UncontrolledCollapse>
            <br />
            <Row>
              <Button className="gap-1" color="outline" id="salesCountryState">
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('countryState')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#salesCountryState">
              <CountryStateListView />
            </UncontrolledCollapse>
            <br />
            <Row>
              <Button className="gap-1" color="outline" id="salesWorkingHours">
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('workingHoursPerMonth')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#salesWorkingHours">
              <WorkingHoursPerMonthView />
            </UncontrolledCollapse>
            <br />
            <Row>
              <Button
                className="gap-1"
                color="outline"
                id="salesPrioritizationTargetGoal"
              >
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('prioritizationTargetGoal')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#salesPrioritizationTargetGoal">
              <PrioritizationTargetGoal />
            </UncontrolledCollapse>
            <br />
            <Row>
              <Button className="gap-1" color="outline" id="targetPriority">
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('targetPriorityPermission')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#targetPriority">
              <TargetPriorityPermissionSettings />
            </UncontrolledCollapse>
            <br />
            <Row>
              <Button className="gap-1" color="outline" id="contactPersonTopic">
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('contactPersonTopic')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#contactPersonTopic">
              <ContactPersonTopicSettings />
            </UncontrolledCollapse>
            <br />
            <Row>
              <Button className="gap-1" color="outline" id="rejectionType">
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('rejectionType')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#rejectionType">
              <RejectionTypeSettings
                rejectionDidUpdate={rejectionDidUpdate}
                handleRejectionTypeUpdate={this.handleRejectionTypeUpdate}
              />
            </UncontrolledCollapse>
            <br />
            <Row>
              <Button className="gap-1" color="outline" id="rejectionItem">
                <FontAwesomeIcon icon={faCaretDown} />
                <h3>{this.t('rejectionItem')}</h3>
              </Button>
            </Row>
            <UncontrolledCollapse toggler="#rejectionItem">
              <RejectionItemSettings
                handleRejectionItemUpdate={this.handleRejectionItemUpdate}
              />
            </UncontrolledCollapse>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default SalesSettingsView;
