import { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import axios from '../axios/Axios-Interceptors';
import { urlBuilder } from '../../utils/helpers/url';
import {
  ICustomer,
  ICustomerAccount,
  ICustomerIdAndAbbreviation,
  ICustomerSector,
} from '../../utils/types/modelTypes';
import {
  ICustomerAccountDetail,
  ICustomerAccountListItem,
  ICustomerDetail,
  ICustomerDropdownItem,
  ICustomerListItem,
} from '../../utils/types/responseTypes';
import { Url } from './constants';
import { IEditedCustomerDetails } from '../../pages/crm/CustomerDetails/customerDetailsHelper';

export const getCustomerAccountListItems = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<ICustomerAccountListItem[]>>(
    axios.get(urlBuilder(Url.CUSTOMER_ACCOUNT_LIST, queryParameters))
  );

export const countCustomerAccount = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<number>>(
    axios.get(urlBuilder(`${Url.CUSTOMER_ACCOUNT}/count`, queryParameters))
  );

export const createCustomerAccount = (
  customerAccount: ICustomerAccountDetail
) =>
  trackPromise<AxiosResponse<ICustomerAccountListItem>>(
    axios.post(urlBuilder(Url.CUSTOMER_ACCOUNT_LIST, {}), customerAccount)
  );

export const deleteCustomerAccountListItem = (id: number) =>
  trackPromise<AxiosResponse<ICustomerAccountListItem[]>>(
    axios.delete(urlBuilder(`${Url.CUSTOMER_ACCOUNT}/${id}`, {}))
  );

export const getCustomerListItems = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<ICustomerListItem[]>>(
    axios.get(urlBuilder(Url.CUSTOMER_LIST, queryParameters))
  );

export const getCustomerAccountDropdown = () =>
  trackPromise<AxiosResponse<ICustomerListItem[]>>(
    axios.get(urlBuilder(Url.CUSTOMER_ACCOUNT_DROPDOWN, {}))
  );

export const getCustomers = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<ICustomer[]>>(
    axios.get(urlBuilder(`${Url.CUSTOMERS}`, queryParameters))
  );

export const getCustomerDetails = (customerId: string) =>
  trackPromise<AxiosResponse<ICustomerDetail>>(
    axios.get(urlBuilder(`${Url.CUSTOMER_DETAILS}/${customerId}`, {}))
  );

export const deleteCustomer = (customerId: number) =>
  trackPromise<AxiosResponse<void>>(
    axios.delete(urlBuilder(`${Url.CUSTOMERS}/${customerId}`, {}))
  );

export const mergeCustomers = (
  customerIdToKeep: number,
  selectedCustomerIds: number[]
) =>
  trackPromise<AxiosResponse<ICustomerListItem>>(
    axios.put(
      urlBuilder(`${Url.MERGE_CUSTOMERS}/${customerIdToKeep}`, {}),
      selectedCustomerIds
    )
  );

export const getCustomerNames = () =>
  trackPromise<AxiosResponse<string[]>>(
    axios.get(urlBuilder(Url.CUSTOMER_EXISTING_NAME, {}))
  );

export const saveCustomerDetails = (
  newCustomerDetails: IEditedCustomerDetails
) =>
  trackPromise<AxiosResponse<ICustomerDetail>>(
    axios.put(urlBuilder(Url.CUSTOMER_DETAILS, {}), newCustomerDetails)
  );

export const saveNewCustomerDetails = (newCustomerDetails: ICustomerDetail) =>
  trackPromise<AxiosResponse<ICustomerDetail>>(
    axios.post(urlBuilder(Url.CUSTOMER_DETAILS, {}), newCustomerDetails)
  );

export const getCustomerCount = (queryParameters: { [key: string]: string }) =>
  trackPromise<AxiosResponse<number>>(
    axios.get(urlBuilder(Url.CUSTOMERS_COUNT, queryParameters))
  );

export const getCustomerAccountDetails = (id: string) =>
  trackPromise<AxiosResponse<ICustomerAccountDetail>>(
    axios.get(urlBuilder(`${Url.CUSTOMER_ACCOUNT_DETAILS}/${id}`, {}))
  );

export const patchCustomerAccountDetail = (
  customerAccountDetail: ICustomerAccountDetail
) =>
  trackPromise<AxiosResponse<ICustomerAccountDetail>>(
    axios.patch(
      urlBuilder(`${Url.CUSTOMER_ACCOUNT_DETAIL}`, {}),
      customerAccountDetail
    )
  );

export const getCustomerAccount = (id: string) =>
  trackPromise<AxiosResponse<ICustomerAccount>>(
    axios.get(urlBuilder(`${Url.CUSTOMER_ACCOUNT}/${id}`, {}))
  );

export const getCustomerAccounts = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<ICustomerAccount>>(
    axios.get(urlBuilder(`${Url.CUSTOMER_ACCOUNT}`, queryParameters))
  );

export const getCustomerSectors = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<ICustomerSector[]>>(
    axios.get(urlBuilder(Url.CUSTOMER_SECTORS, queryParameters))
  );

export const saveMultipleCustomerDetails = (
  customerDetails: ICustomerDetail[]
) =>
  trackPromise<AxiosResponse<ICustomerDetail>>(
    axios.put(urlBuilder(Url.CUSTOMER_DETAILS_MULTIPLE, {}), customerDetails)
  );

export const getCustomerDropdownItems = () =>
  trackPromise<AxiosResponse<ICustomerDropdownItem[]>>(
    axios.get(urlBuilder(`${Url.CUSTOMER_WITH_SECTORS_DROPDOWN}`, {}))
  );

export const isValidCustomerAbbreviation = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<string>>(
    axios.get(urlBuilder(Url.CUSTOMER_ABBREVIATION_IS_VALID, queryParameters))
  );

export const updateCustomerAbbreviation = (
  objectCustomer: ICustomerIdAndAbbreviation
) =>
  trackPromise<AxiosResponse<ICustomerIdAndAbbreviation>>(
    axios.put(urlBuilder(Url.CUSTOMER_ABBREVIATION, {}), objectCustomer)
  );
