import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Button } from 'reactstrap';

import DetailsCard from '../../../components/cards/DetailsCard';
import {
  AcquisitionType,
  ProjectState,
  StaffedOption,
} from '../../../utils/enums/project';
import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { IProjectOptionDetails } from '../../../utils/types/responseTypes';
import ActivityDropdown from './ActivityDropdown';
import {
  getProjectActivities,
  getSelectedProjectActivity,
  t,
} from './projectOptionHelpers';
import type {
  ProjectActivity,
  ProjectOptionDetailsForm,
} from './ProjectOptionModal';

const headerButtons = (
  <Link to="/resource-planner/resource-overview/" target="_blank">
    <Button color="primary" size="sm">
      {t('viewResourceManager')}
    </Button>
  </Link>
);

const projectStaffingOptions = enumValuesToDropdownOptions(
  Object.values(StaffedOption),
  'StaffedEnum'
);
const acquisitionTypeOptions = enumValuesToDropdownOptions(
  Object.values(AcquisitionType),
  'Acquisitions'
);

interface IProps extends IWithModalsProps {
  project: IProjectOptionDetails;
  formValues: ProjectOptionDetailsForm;
  setFormValues: Dispatch<SetStateAction<ProjectOptionDetailsForm>>;
}

/**
 * Activities Card in Project Option Modal -
 * Displays project staffing, acquisition type, and multiple activity dropdowns,
 * and allows displaying of resource manager
 */
const ProjectOptionActivities = ({
  project: { id: projectId },
  formValues: { staffedOption, acquisitionType, state, activities },
  setFormValues,
  modalOkHandler,
}: IProps) => {
  const disabled =
    state === ProjectState.LOST || state === ProjectState.ORDERED;

  const activityOptions = getProjectActivities(state).filter(
    ({ value }) =>
      !activities
        .map(({ action }) => (action ? action.value : null))
        .includes(value)
  );

  const handleFormFieldChange = (value: IDropdownOption, field: string) => {
    setFormValues((formValues) => ({
      ...formValues,
      [field]: value,
    }));
  };

  const fields = [
    {
      label: t('projectStaffing'),
      value: (
        <Select
          aria-label="project-option-activities-select-project-staffing"
          value={staffedOption}
          options={projectStaffingOptions}
          onChange={(value: IDropdownOption) =>
            handleFormFieldChange(value, 'staffedOption')
          }
          isDisabled={disabled}
        />
      ),
      isRequired:
        !!projectId &&
        (state === ProjectState.PROPOSAL_AHEAD ||
          state === ProjectState.PROPOSAL_TECHNICAL_GO ||
          state === ProjectState.OFFER_SENT),
    },
    {
      label: t('acquisitionType'),
      value: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '300px ' }}>
            <Select
              aria-label="project-option-activities-select-acquisition-type"
              value={acquisitionType}
              options={acquisitionTypeOptions}
              onChange={(value: IDropdownOption) =>
                handleFormFieldChange(value, 'acquisitionType')
              }
              isDisabled={
                state !== ProjectState.VAGUE && state !== ProjectState.DEFINED
              }
            />
          </div>

          <Button
            aria-label="project-option-activities-button-tooltip"
            color="link"
            onClick={() => {
              modalOkHandler(
                '',
                <>
                  <strong>{t('proposalTitle')}: </strong>
                  {t('proposalContent')}
                  <br />
                  <strong>{t('specificationTitle')}: </strong>
                  {t('specificationContent')}
                </>
              );
            }}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </Button>
        </div>
      ),
      isRequired: !!projectId && state === ProjectState.DEFINED,
    },
  ];

  const handleActivitiesChange = (activities: ProjectActivity[]) => {
    setFormValues((formValues) => ({ ...formValues, activities }));
  };

  const additionalSections = [
    {
      title: t('nextActivity'),
      fields: [
        {
          value: (
            <ActivityDropdown
              activities={activities}
              setActivities={handleActivitiesChange}
              activityOptions={activityOptions}
              disabled={disabled}
            />
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    handleActivitiesChange(
      getSelectedProjectActivity(state, acquisitionType.value)
    );
  }, [state, acquisitionType]);

  return (
    <DetailsCard
      title={t('activities')}
      fields={fields}
      additionalSections={additionalSections}
      headerButtons={headerButtons}
      border
    />
  );
};

export default withModals(ProjectOptionActivities);
