import { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';

import { userRole } from '../../../utils/constants';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import EmployeeOverviewCard from './EmployeeOverviewCard';
import EmployeeProjectsCard from './EmployeeProjectsCard';
import EmployeeSkillsEtcCard from './EmployeeSkillsEtcCard';
import { IEmployeeDetail } from '../../../utils/types/responseTypes';
import EmployeeGroupViewCard from './EmployeeGroupViewCard';
import RoleGroupViewCard from './RoleGroupViewCard';
import { SUBJECT_TYPE } from '../../../utils/enums/employee';
import RoleViewCard from './RoleViewCard';
import { EmployeeDetailsContext } from './EmployeeDetailProvider';
import { getEmployeeFromDetail } from '../../../services/api/employee';
import { edt } from './employeeDetailHelper';

interface IProps
  extends RouteComponentProps<{ id: string }>,
    IWithModalsProps {}

const EmployeeDetailsCard = ({
  // RouteComponentProps
  match,
  // WithModal
  modalErrorHandler,
}: IProps) => {
  const {
    employeeDetail: {
      id: employeeId,
      employeeGroups = [],
      roleGroups = [],
      roles = [],
    },
    setEmployeeDetail,
  } = useContext(EmployeeDetailsContext);

  const [showRoleCards, setShowRoleCards] = useState(true);

  // Obtain the user details
  const getUserDetail = () => {
    const {
      params: { id },
    } = match;
    getEmployeeFromDetail(parseInt(id, 10))
      .then((response: AxiosResponse<IEmployeeDetail>) => {
        setEmployeeDetail(response.data);
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(edt(userRole.toLowerCase()), error);
        setShowRoleCards(false);
      });
  };

  useEffect(() => {
    getUserDetail();
  }, [match]);

  return (
    <Container fluid>
      <EmployeeOverviewCard />
      <EmployeeProjectsCard />
      <EmployeeSkillsEtcCard />
      {showRoleCards && ( // EmployeeGroupViewCard is to be used by other items
        <EmployeeGroupViewCard employeeGroups={employeeGroups} />
      )}
      {showRoleCards && ( // RoleGroupViewCard is to be used by other items
        <RoleGroupViewCard
          showRoleGroupAdd={false}
          subjectId={employeeId}
          subjectType={SUBJECT_TYPE.employee.code}
          roleGroups={roleGroups}
        />
      )}
      {showRoleCards && ( // RoleViewCard is to be used by other items
        <RoleViewCard
          showRoleAdd={false}
          subjectId={employeeId}
          subjectType={SUBJECT_TYPE.employee.code}
          roles={roles}
        />
      )}
    </Container>
  );
};

export default withRouter(withModals(EmployeeDetailsCard));
