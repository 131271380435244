import {
  faCheck,
  faQuestion,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { SortBy } from '../../../utils/enums/sort';
import i18n from '../../../i18n';
import {
  IApprovalRequestListItem,
  IProjectDetail,
} from '../../../utils/types/responseTypes';
import { IApprovalRequestFilters } from '../../../utils/types/stateTypes';
import { Approval } from '../../../utils/enums/approval';

export const alt = (keyName: string) => i18n.t(`ApprovalsList.${keyName}`);

/**
 * Project Detail Template for the approval project
 */
export const templateProjectDetail: IProjectDetail = {
  id: 0,
  title: '',
  projectId: '',
  state: '',
  customer: {
    id: 0,
    name: '',
    abbreviation: '',
  },
  customerSite: {
    id: 0,
    name: '',
    street: '',
    location: '',
    zipCode: '',
    country: '',
  },
  responsible: {
    id: 0,
    name: '',
  },
  involvedResponsibles: [],
  responsibleContactPersons: [],
  start: '',
  end: '',
  description: '',
  durationType: '',
  locationType: '',
  departments: [],
  projectSharePoint: '',
  offers: [],
  pendingOffers: [],
  fileAttachments: [],
  projectTimesheets: [],
  projectMembers: [],
  activities: [],
  projectType: '',
  estimationTemplatesLink: '',
};

/**
 * Template for approval request object
 */
export const templateApprovalRequest: IApprovalRequestListItem = {
  id: 0,
  responseDate: '',
  modifiable: true,
  objectType: '',
  attachedItemToRequest: { id: 0, name: '' },
  createdDate: '',
  attributeValue: '',
  approvalStatus: '',
};
/**
 * Method that checks if all required fields are filed
 * @param project
 * @returns true or false
 */
export const isInputValid = (project: IProjectDetail) =>
  project.title !== '' &&
  project.customer.id !== 0 &&
  project.customerSite.id !== 0 &&
  project.responsible.id !== 0;

/**
 * Creates the query parameters to be used to fetch data
 * @returns Object containing query parameters
 */
export const createQueryParameters = (
  page: number,
  {
    title,
    objectType,
    attribute,
    createdDate,
    approved,
    dateDecision,
  }: IApprovalRequestFilters,
  sortBy: string,
  sortType: string,
  historicView: boolean,
  employeeId: number
) => ({
  page: page.toString(),
  ...(title && {
    'title.contains': title.toString(),
  }),
  ...(objectType && { 'objectType.contains': objectType }),
  ...(attribute && {
    'attribute.contains': attribute,
  }),
  ...(createdDate && {
    'created_date.contains': createdDate,
  }),
  ...(approved && {
    'approved.contains': approved.toString(),
  }),
  ...(dateDecision && {
    'dateDecision.contains': dateDecision,
  }),
  ...{
    'employeeId.equals': employeeId.toString(),
  },
  ...{
    'historyMode.equals': historicView.toString(),
  },
  ...(sortBy &&
    sortType && {
      sort: `${sortBy === 'lastname' ? SortBy.NAME : sortBy}%2C${sortType}`,
    }),
});

/**
 * Toggle Dispalying modifiable items
 */
export const sortApprovalData = ({
  approvalStatus,
  responseDate,
  attributeValue,
}: IApprovalRequestListItem) => {
  switch (approvalStatus) {
    case Approval.REJECTED:
      return {
        approved: (
          <Tooltip title={alt('rejected')}>
            <FontAwesomeIcon icon={faTimes} />
          </Tooltip>
        ),
        attribute: attributeValue,
        dateDecision: new Date(responseDate).toDateString(),
      };
    case Approval.APPROVED:
      return {
        approved: (
          <Tooltip title={alt('approved')}>
            <FontAwesomeIcon icon={faCheck} />
          </Tooltip>
        ),
        attribute: attributeValue,
        dateDecision: new Date(responseDate).toDateString(),
      };
    case Approval.PENDING:
    default:
      return {
        approved: (
          <Tooltip title={alt('pending')}>
            <FontAwesomeIcon icon={faQuestion} />
          </Tooltip>
        ),
        attribute: attributeValue,
        dateDecision: null,
      };
  }
};
