import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import DynamicTable from '../../../components/tables/DynamicTable';
import { ICampaignManagerContactPersonListItem } from '../../../utils/types/responseTypes';
import {
  translateCampaignManager,
  createContactEntry,
} from './campaignManagerHelper';
import { RootState } from '../../../redux/store';

const defaultWidth = '200px';

interface IProps extends PropsFromRedux {
  selectedContacts: ICampaignManagerContactPersonListItem[];
  setSelectedContacts: (
    selectedContacts: ICampaignManagerContactPersonListItem[]
  ) => void;
  fetchData: () => void;
}

interface ContactEntry {
  id: number;
  firstname: string;
  lastname: string;
  role: string;
  functionRole: string;
  sectors: JSX.Element | null;
  customer: string;
  customerSite: string;
  country: string;
  assignmentState: string | null;
  newsletter: string;
  contactInformations: string;
  contact: ICampaignManagerContactPersonListItem;
}

const CampaignManagerTable = ({
  listItems: contacts,
  selectedContacts,
  hasMore: hasMoreData,
  fetchData,
  setSelectedContacts,
}: IProps) => {
  const preparedContacts: ContactEntry[] = contacts.map((contact) =>
    createContactEntry(contact)
  );

  const preparedColumns = [
    {
      type: 'checkbox',
      header: '',
      accessor: 'contact',
      show: 'true',
    },
    {
      type: 'data',
      header: translateCampaignManager('lastName'),
      accessor: 'lastname',
      show: 'true',
      width: 120,
      wordBreak: 'break-all',
    },
    {
      type: 'data',
      header: translateCampaignManager('firstName'),
      accessor: 'firstname',
      show: 'true',
      width: 120,
      wordBreak: 'break-all',
    },
    {
      type: 'data',
      header: translateCampaignManager('role'),
      accessor: 'role',
      show: 'true',
    },
    {
      type: 'data',
      header: translateCampaignManager('function'),
      accessor: 'functionRole',
      show: 'true',
    },
    {
      type: 'data',
      header: translateCampaignManager('industry'),
      accessor: 'sectors',
      show: 'true',
    },
    {
      type: 'data',
      header: translateCampaignManager('customerFilter'),
      accessor: 'customer',
      show: 'true',
      width: 150,
      wordBreak: 'break-all',
    },
    {
      type: 'data',
      header: translateCampaignManager('customerSite'),
      accessor: 'customerSite',
      show: 'true',
      width: defaultWidth,
      wordBreak: 'break-all',
    },
    {
      type: 'data',
      header: translateCampaignManager('country'),
      accessor: 'country',
      show: 'true',
      width: defaultWidth,
      wordBreak: 'break-all',
    },
    {
      type: 'data',
      header: translateCampaignManager('status'),
      accessor: 'status',
      show: 'true',
      width: defaultWidth,
      wordBreak: 'break-all',
    },
    {
      type: 'data',
      header: translateCampaignManager('newsletter'),
      accessor: 'newsletter',
      show: 'true',
      width: defaultWidth,
      wordBreak: 'break-all',
    },
  ];

  return (
    <DynamicTable
      data={preparedContacts}
      columns={preparedColumns}
      fetchData={fetchData}
      hasMoreData={hasMoreData}
      infiniteScroll
      selectedItems={selectedContacts}
      setSelectedItems={setSelectedContacts}
    />
  );
};

const mapStateToProps = (store: RootState) => ({
  // Map the Redux state to props
  listItems: store.campaignManagerContactPersonList.listItems,
  hasMore: store.campaignManagerContactPersonList.hasMore,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CampaignManagerTable);
