import { faBan, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import moment from 'moment';

import { Ownership } from '../../../utils/enums/ownership';
import { EntityType } from '../../../utils/enums/pageComponents';
import { SortBy, SortType } from '../../../utils/enums/sort';
import { isEmpty } from '../../../utils/helpers/array';
import i18n from '../../../i18n';
import { IContactPersonListItem } from '../../../utils/types/responseTypes';
import { IContactPersonListFilters } from '../../../utils/types/stateTypes';
import {
  createLinkIfAuthorised,
  userHasPermission,
} from '../../../utils/helpers/permission';
import { generateCustomerSiteTooltip } from '../../../utils/helpers/table';
import { DELETE, REMOVE, dateFormat } from '../../../utils/constants';
import { ContactStatusEnum } from '../../../utils/enums/contact';
import { enumValuesToDropdownOptions } from '../../../utils/helpers/dropdown';

/**
 * Translator function for Contact List
 * @param keyName Key for phrase to be translated
 * @returns Translated string
 */
export const translate = (keyName: string) => i18n.t(`ContactList.${keyName}`);

/**
 * Generates query parameters for contact person data based on provided filters and sorting criteria.
 * @param page - The page number for pagination.
 * @param filters - An object containing various filters for contact person list data.
 * @param sortBy - The field by which to sort the results.
 * @param sortType - The type of sorting (asc or desc).
 * @returns An object containing query parameters for contact person list data.
 */
export const createQueryParameters = (
  page: number,
  filters: IContactPersonListFilters,
  sortBy: SortBy,
  sortType: SortType
) => ({
  page: page.toString(),
  ...(filters.firstname && {
    'firstname.contains': filters.firstname,
  }),
  ...(filters.lastname && {
    'lastname.contains': filters.lastname,
  }),
  ...(!isEmpty(filters.customerAccount) && {
    'accountName.contains': `${encodeURIComponent(
      filters.customerAccount.trim()
    )}`,
  }),
  ...(filters.customerName && {
    'customerName.contains': filters.customerName,
  }),
  ...(filters.customerSiteName && {
    'customerSiteName.contains': `${encodeURIComponent(
      filters.customerSiteName.trim()
    )}`,
  }),
  ...(!isEmpty(filters.target) && {
    'target.in': filters.target.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(filters.acquisition) && {
    'acquisition.in': filters.acquisition.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(filters.acquisition) && {
    'acquisition.in': filters.acquisition.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(filters.nextAction) && {
    'nextAction.in': filters.nextAction.map(({ value }) => value).join(','),
  }),
  ...(filters.dueDate && {
    'dueDate.greaterThanOrEqual': moment(filters.dueDate).toISOString(),
  }),
  ...(filters.dueDate && {
    'dueDate.lessThanOrEqual': moment(filters.dueDate)
      .endOf('day')
      .toISOString(),
  }),
  ...(!isEmpty(filters.responsible) && {
    'responsibleId.in': filters.responsible.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(filters.involvedEmployees) && {
    'involvedId.in': filters.involvedEmployees
      .map(({ value }) => value)
      .join(','),
  }),
  ...(!isEmpty(filters.statusGdpr) && {
    'statusGdpr.in': filters.statusGdpr.map(({ value }) => value).join(','),
  }),
  ...(!isEmpty(filters.assignmentState) && {
    'assignmentState.in': filters.assignmentState
      .map(({ value }) => value)
      .join(','),
  }),
  ...(!isEmpty(filters.ownership) && {
    'ownership.in': `${
      Array.isArray(filters.ownership)
        ? filters.ownership.join(',')
        : filters.ownership
    }`,
    ...(filters.responsibleWithPermission && {
      ...(filters.ownership.includes(Ownership.RESPONSIBLE) && {
        'responsibleId.equals':
          filters.responsibleWithPermission.value.toString(),
      }),
      ...(filters.ownership.includes(Ownership.INVOLVED) && {
        'involvedId.equals': filters.responsibleWithPermission.value.toString(),
      }),
    }),
  }),
  ...(sortBy &&
    sortType && {
      sort: `${sortBy}%2C${sortType}`,
    }),
});

/**
 * Generates a div component for a tooltip display
 * @param buttonAction
 * @returns a simple div with dialogue
 */
const generateSimpleToolTip = (action: string) =>
  action === REMOVE ? (
    <div>{translate('removeButtonToolTipDialogue')}</div>
  ) : (
    <div>{translate('deleteButtonToolTipDialogue')}</div>
  );

/**
 * Creates a React component for a contact person entry.
 *
 * @param contactPersonListItem An object containing the data for the contact person entry.
 * @param onDelete A function to be called when the contact person entry is deleted.
 * @returns A React component containing the contact person entry.
 */
export const createContactPersonEntry = (
  {
    id,
    lastname,
    firstname,
    customerAccount,
    customer,
    customerSite,
    target,
    acquisition,
    nextActivity: nextAction,
    dueDate,
    responsible,
    involvedEmployees,
    assignmentState,
    statusGdpr,
  }: IContactPersonListItem,
  onDeleteOrRemove: (action: string) => void
) => {
  const { id: accountId, name: accountName } = { ...customerAccount };
  const { id: customerId, name: customerName } = { ...customer };
  const {
    id: customerSiteId,
    name: customerSiteName,
    location,
  } = { ...customerSite };
  const { id: responsibleId, name: responsibleName } = { ...responsible };

  return {
    id,
    menuComponent: userHasPermission(EntityType.CONTACT_PERSON, id) ? (
      <div>
        <Tooltip title={generateSimpleToolTip(DELETE)}>
          <Button color="primary" onClick={() => onDeleteOrRemove(DELETE)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Tooltip>
        <Tooltip title={generateSimpleToolTip(REMOVE)}>
          <Button
            color="primary"
            onClick={() => onDeleteOrRemove(REMOVE)}
            disabled={!customerSiteId}
          >
            <FontAwesomeIcon icon={faBan} />
          </Button>
        </Tooltip>
      </div>
    ) : null,
    lastname: (
      <Tooltip title={`${translate('account')}: ${accountName ?? 'N/A'}`}>
        <span>
          {createLinkIfAuthorised(EntityType.CONTACT_PERSON, lastname, id)}
        </span>
      </Tooltip>
    ),
    firstname: (
      <Tooltip title={`${translate('account')}: ${accountName ?? 'N/A'}`}>
        <span>
          {createLinkIfAuthorised(EntityType.CONTACT_PERSON, firstname, id)}
        </span>
      </Tooltip>
    ),
    customerAccount: createLinkIfAuthorised(
      EntityType.CUSTOMER_ACCOUNT,
      accountName,
      accountId
    ),
    customerName: createLinkIfAuthorised(
      EntityType.CUSTOMER,
      customerName,
      customerId
    ),
    customerSiteName: customerSiteName && (
      <Tooltip
        key={id}
        title={generateCustomerSiteTooltip(customerSite)}
        leaveTouchDelay={3000}
      >
        <span>{`${customerSiteName} (${location})`}</span>
      </Tooltip>
    ),
    target: target ? translate('yes') : translate('no'),
    acquisition: acquisition ? translate('yes') : translate('no'),
    nextAction,
    dueDate: dueDate ? moment(dueDate).format(dateFormat) : null,
    responsible: createLinkIfAuthorised(
      EntityType.EMPLOYEE,
      responsibleName,
      responsibleId
    ),
    involvedEmployees: involvedEmployees.map(({ id, name }) => (
      <Row key={id}>
        <Col key={id}>
          {createLinkIfAuthorised(EntityType.EMPLOYEE, name, id)}
        </Col>
      </Row>
    )),
    assignmentState: enumValuesToDropdownOptions(
      Object.values(ContactStatusEnum)
    ).find((item) => item.value === assignmentState)?.label,
    statusGdpr,
  };
};
