import React from 'react';
import Draggable from 'react-draggable';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import {
  clickHandler,
  modalCloseButton,
} from '../../../utils/helpers/click';
import { generateTitle } from '../../../utils/helpers/icon';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { translate } from './contactListHelpers';

interface IProps {
  fromContactProfile?: boolean;
  modalTitle: string;
  modalBodyText: string;
  event: (() => Promise<void>) | (() => void);
  isOpen: boolean;
  onClose: () => void;
}

const ExportContactToOutlookModalForm: React.FC<IProps> = ({
  modalTitle,
  modalBodyText,
  event,
  isOpen,
  onClose,
  fromContactProfile,
}) => {
  const confirmEvent = async () => {
    await event();
    onClose();
  };

  return (
    <Draggable>
      <Modal isOpen={isOpen} toggle={onClose} size>
        <ModalHeader close={modalCloseButton(onClose)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>{modalBodyText}</Col>
            {fromContactProfile ? null : (
              <Col className="exclamation-color" md="auto">
                {generateTitle(BUTTON_TITLE_ENUM.INFORMATION.code, '')}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="dark"
            onClick={(event: MouseEvent) => clickHandler(event, confirmEvent)}
          >
            {translate('proceed')}
          </Button>{' '}
          <Button
            color="dark"
            onClick={(event: MouseEvent) => clickHandler(event, onClose)}
          >
            {translate('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};

export default ExportContactToOutlookModalForm;
