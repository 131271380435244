import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import i18n from '../../i18n';
import withModals, { IWithModalsProps } from '../../utils/withModals';
import {
  IDropdownOption,
  IObjectNameAndId,
} from '../../utils/types/commonTypes';
import { ICountryState } from '../../utils/types/modelTypes';
import { getCountryStateOptions } from '../../services/api/campaignManager';
import { objectNameAndIdToDropdownOptions } from '../../utils/helpers/dropdown';

interface IProps extends IWithModalsProps {
  countryId: number;
  countryStateId: number;
  onChange: (countryState: ICountryState) => void;
}

const t = (keyName: string) => i18n.t(`CountryStateDropdown.${keyName}`);

const CountryStateDropdown = ({
  modalErrorHandler,
  countryId,
  countryStateId,
  onChange,
}: IProps) => {
  const [countryStates, setCountryStates] = useState<ICountryState[]>([]);

  const dropdownOptions = objectNameAndIdToDropdownOptions(
    countryStates.filter((countryState) =>
      countryId ? countryState.country.id === countryId : countryState
    ) as IObjectNameAndId[]
  );

  const fetchCountryStates = () => {
    getCountryStateOptions()
      .then(({ data }) => {
        setCountryStates(data);
      })
      .catch((error) => {
        modalErrorHandler(t('failedToRetrieveCountryStates'), error);
      });
  };

  useEffect(() => {
    fetchCountryStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid="country-state-div">
      <Select
        options={dropdownOptions}
        value={
          dropdownOptions.find(({ value }) => value === countryStateId) ?? null
        }
        onChange={({ value }: IDropdownOption<number>) =>
          onChange(
            countryStates.find(({ id }) => value === id) as ICountryState
          )
        }
      />
    </div>
  );
};

export default withModals(CountryStateDropdown);
