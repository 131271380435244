import React, { ReactElement } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Table,
} from 'reactstrap';

import './Card.scss';

export type CardData = {
  label?: string;
  value: ReactElement | string;
  isRequired?: boolean;
  action?: () => void;
};

export type CardSection = {
  key?: string;
  title?: string;
  fields: CardData[];
};

interface IProps {
  title?: string;
  fields: CardData[];
  noDataPlaceholder?: string;
  additionalSections?: CardSection[];
  headerButtons?: ReactElement;
  footerButtons?: ReactElement;
  border?: boolean;
  leftTitle?: boolean;
  largeTitle?: boolean;
}

const DetailsCardSection = ({ fields }: { fields: CardData[] }) => (
  <Table borderless size="sm">
    <tbody>
      {fields.map(({ label, value, isRequired, action }) => (
        <tr key={label}>
          {label && (
            <th style={{ width: '140px' }}>
              {isRequired && <span className="marker-color">*</span>}
              {label}
            </th>
          )}
          <td onClick={action}>{value}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

/**
 * Details Card -
 * Displays data in a vertical table style, allows creation of additional data sections,
 * and contains title, header buttons, footer buttons
 */
const DetailsCard = ({
  title,
  fields,
  noDataPlaceholder,
  additionalSections,
  headerButtons,
  footerButtons,
  border,
  leftTitle,
  largeTitle,
}: IProps) => (
  <Card className={`common-card ${border ? 'with-border' : ''}`}>
    <CardBody>
      {title && (
        <CardTitle className={`${leftTitle ? 'title-left' : 'title-center'}`}>
          {largeTitle ? <h1>{title}</h1> : <h3>{title}</h3>}
        </CardTitle>
      )}
      {headerButtons && (
        <div className="float-end header-buttons">{headerButtons}</div>
      )}
      {fields.length > 0 ? (
        <DetailsCardSection fields={fields} />
      ) : (
        noDataPlaceholder
      )}
    </CardBody>
    {additionalSections && additionalSections.length > 0 && (
      <ListGroup flush>
        {additionalSections.map(({ key, title, fields }) => (
          <ListGroupItem key={key}>
            <span className="section-title">{title}</span>
            <DetailsCardSection fields={fields} />
          </ListGroupItem>
        ))}
      </ListGroup>
    )}
    {footerButtons && (
      <CardBody>
        <div className="float-end footer-buttons">{footerButtons}</div>
      </CardBody>
    )}
  </Card>
);

export default DetailsCard;
