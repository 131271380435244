import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import Dropdown from '../dropdowns/Dropdown';
import { BUTTON_TITLE_ENUM } from '../../utils/enums/pageComponents';
import { OBJECT_TYPE_ENUM } from '../../utils/enums/objectType';
import { CONTACT_INFO_ENUM } from '../../utils/enums/contact';
import { CONTACT_INFORMATION_STATUS_ENUM } from '../../utils/enums/contactPerson';
import { generateTitle } from '../../utils/helpers/icon';
import i18n from '../../i18n';

const TEXTBOX_COLUMN_WIDTH = '7';
const CHECKBOX_COLUMN_WIDTH = '4';
const BUTTON_COLUMN_WIDTH = '1';

/**
 * @deprecated Please move on to ContactInformationInput
 * A dropdown that has all the employees that can be assigned to an object.\
 * props: index, contactInformation, onDeleteContactInformation, onHandleContactInformationChange,
 * lg(optional), buttonPadding, textBoxSize\
 * lg = optional(default: 10)\
 * buttonPadding = optional(default: 0)
 * textBoxSize = optional(default: sm)
 */
class ContactInformationInputOld extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.render();
    }
  }

  t(keyName) {
    return i18n.t('ContactInformationInput.' + keyName);
  }

  render() {
    const contactInformation = this.props.contactInformation;
    const index = this.props.index;
    const props = this.props;
    const informationType = contactInformation?.contactInformationType;
    let contactInformationState = Object.values(
      CONTACT_INFORMATION_STATUS_ENUM
    ).map((contactInfoState) => ({
      value: contactInfoState.code,
      label: contactInfoState.name,
    }));
    return (
      <Row className="row-padding-5">
        <Col lg={props.lg ? props.lg : TEXTBOX_COLUMN_WIDTH}>
          <Input
            bsSize={props.textBoxSize ? props.textBoxSize : 'sm'}
            type="string"
            onChange={(e) => props.onHandleContactInformationChange(e, index)}
            value={contactInformation?.info ? contactInformation.info : ''}
          ></Input>
        </Col>
        {informationType === CONTACT_INFO_ENUM.email.code ? (
          <Col lg={props.lg ? props.lg : CHECKBOX_COLUMN_WIDTH}>
            {contactInformation.objectType ===
            OBJECT_TYPE_ENUM.contactPerson.code ? (
              <div className="text-center">
                <Input
                  type="checkbox"
                  checked={
                    contactInformation?.status ===
                    CONTACT_INFORMATION_STATUS_ENUM.guessed.code
                  }
                  onChange={(e) => props.onHandleEmailStatusChange(e, index)}
                />
                {this.t(CONTACT_INFORMATION_STATUS_ENUM.guessed.code)}
              </div>
            ) : (
              <Dropdown
                options={contactInformationState}
                objectId={
                  contactInformation?.status
                    ? contactInformation?.status
                    : CONTACT_INFORMATION_STATUS_ENUM.unknown.code
                }
                onChange={(e) => props.onHandleEmailStatusChange(e, index)}
              />
            )}
          </Col>
        ) : null}
        <Col lg={props.lg ? props.lg : BUTTON_COLUMN_WIDTH}>
          <Button
            style={{
              padding: `${props.buttonPadding ? props.buttonPadding : 0}px`,
            }}
            color="link"
            onClick={() =>
              props.onDeleteContactInformation(contactInformation, index)
            }
          >
            {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
          </Button>
        </Col>
      </Row>
    );
  }
}

export default ContactInformationInputOld;
