import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import Datetime from 'react-datetime';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Button, Form, FormGroup, Label, ModalBody } from 'reactstrap';

import { NEXT_ACTION_ENUMS, NextAction } from '../../../utils/enums/activity';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import {
  IContactPersonAssignment,
  IUserToDoActivity,
} from '../../../utils/types/modelTypes';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';
import { saveUserToDoActivities } from '../../../services/api/project';
import { IDropdownOption } from '../../../utils/types/commonTypes';
import { translateModalAddActivity as t } from './contactPersonHelper';
import { nextActionDropdownOptions } from '../../../utils/helpers/dropdown';

interface IProps extends RouteComponentProps, IWithModalsProps {
  status: IContactPersonAssignment | null;
  closeAddActivityForm: () => void;
  handleUpdateContactPersonAssignment: (
    contactPersonAssignment: IContactPersonAssignment
  ) => Promise<void>;
  handleSaveActivityHistory: (
    newDueDate: string,
    newNextAction: string
  ) => void;
  handleUpdateActivity?: () => Promise<void>;
}

/**
 * Modal used to change the Next Action and due date of the ContactPersonAssignment
 * @param status - the ContactPersonAssignment
 * @param handleUpdateContactPersonAssignment - function to update the ContactPersonAssignment
 * @param closeAddActivityForm - function to close the modal
 */
const ModalAddActivity = ({
  status: assignmentState,
  handleSaveActivityHistory,
  handleUpdateContactPersonAssignment,
  handleUpdateActivity,
  closeAddActivityForm,
  modalErrorHandler,
}: IProps) => {
  const [dueDate, setDueDate] = useState(moment(assignmentState?.dueDate));
  const [nextAction, setNextAction] = useState('');

  const handleSaveActivity = async () => {
    const updatedAssignmentState = {
      ...assignmentState,
      dueDate: dueDate.toISOString(),
      nextAction,
    } as IContactPersonAssignment;

    try {
      // Update the contactPersonAssignment in the ContactPersonDetails
      await handleUpdateContactPersonAssignment(updatedAssignmentState);

      // Temporarily update the activity history
      handleSaveActivityHistory(dueDate.toISOString(), nextAction);

      type NextActionType = keyof typeof NextAction;
      const nextActionKey = Object.keys(NextAction).find(
        (key) => NextAction[key as NextActionType] === nextAction
      );

      if (NEXT_ACTION_ENUMS.none !== nextAction) {
        const userToDoActivities = {
          objectType: OBJECT_TYPE_ENUM.contactPerson.code,
          objectId: updatedAssignmentState.contactPerson?.id,
          action: NextAction[nextActionKey as NextActionType],
          contactPerson: updatedAssignmentState.contactPerson,
          dueDate: dueDate.toISOString(),
          description: nextAction,
        } as IUserToDoActivity;
        await saveUserToDoActivities(userToDoActivities).catch((error) => {
          modalErrorHandler(t('failedToSaveUserToDoActivities'), error);
        });
        if (handleUpdateActivity) {
          await handleUpdateActivity().catch((error) => {
            modalErrorHandler(t('failedToUpdateActivity'), error);
          });
        }
      }
    } catch (error) {
      modalErrorHandler(t('failedToUpdateContactPersonAssignments'), error);
    }

    closeAddActivityForm();
  };

  return (
    <ModalBody>
      <Form>
        <FormGroup>
          <Label>{t('nextActivity')}:</Label>
          <Select
            options={nextActionDropdownOptions}
            aria-label="nextActivity-dropdown"
            onChange={(event: IDropdownOption) => setNextAction(event.value)}
          />
          <Label>{t('dueDate')}:</Label>
          <Datetime
            dateFormat
            timeFormat={false}
            closeOnSelect
            value={dueDate}
            onChange={(newDate: Moment | string) => setDueDate(moment(newDate))}
          />
        </FormGroup>
      </Form>
      <div className="float-end" style={{ paddingLeft: '5px' }}>
        <Button color="primary" onClick={() => closeAddActivityForm()}>
          {t('cancel')}
        </Button>
      </div>
      <div className="float-end" style={{ paddingLeft: '5px' }}>
        <Button color="primary" onClick={() => handleSaveActivity()}>
          {t('confirm')}
        </Button>
      </div>
    </ModalBody>
  );
};

export default withRouter(withModals(ModalAddActivity));
