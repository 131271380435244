/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Container, Row, Button } from 'reactstrap';

import { getContactPersons } from '../../../../../services/api/contactPerson';
import {
  getDepartments,
  getProjectCount,
  postProjectDetails,
} from '../../../../../services/api/project';
import { IDropdownOption } from '../../../../../utils/types/commonTypes';
import { DEFAULT_LOAD_TIMEOUT } from '../../../../../utils/constants';
import withModals, { IWithModalsProps } from '../../../../../utils/withModals';
import type {
  ProjectContactPerson,
  ProjectDetailsForm,
} from '../../CreateNewProjectModal';
import { IProjectDetail } from '../../../../../utils/types/responseTypes';
import {
  createProjectDetailFromFormValues,
  generateDefaultFormValues,
  isInternalProjectValid,
  t,
} from '../../projectListHelpers';
import { RootState } from '../../../../../redux/store';
import { getCustomers } from '../../../../../services/api/customer';
import { getCustomerSites } from '../../../../../services/api/customerSite';
import {
  AccessType,
  PERMISSION_URI,
} from '../../../../../utils/enums/permission';
import { getEmployeeNames } from '../../../../../services/api/employee';
import { DepartmentStatus } from '../../../../../utils/enums/department';
import { IApprovalRequest } from '../../../../../utils/types/modelTypes';
import { postApprovalRequest } from '../../../../../services/api/approvalRequest';
import ProjectDescription from '../../ProjectDescription';
import ProjectResponsibles from '../../ProjectResponsibles';
import InternalProjectGeneralInformationCard from './InternalProjectGeneralInformationCard';
import InternalProjectBudgetAndScheduleCard from './InternalProjectBudgetAndScheduleCard';
import { Currency } from '../../../../../utils/enums/currency';
import { IAccountState } from '../../../../../utils/types/stateTypes';
import { projectTypesEnum } from '../../../../../utils/enums/enum';

interface IProps extends IWithModalsProps, PropsFromRedux {
  customerId?: number;
  customerSiteId?: number;
  contactPersonId?: number;
  onCancel: () => void;
  onSave: (projectId: number) => void;
}

/**
 * Internal Project Modal -
 * Allow creation of Internal Project
 */
const InternalProjectModal = ({
  customerId,
  customerSiteId,
  contactPersonId,
  modalErrorHandler,
  onCancel,
  onSave,
  currentUser,
}: IProps) => {
  const [project] = useState<IProjectDetail>({} as IProjectDetail);
  const [contactPersonList, setContactPersonList] = useState(
    new Map<number, ProjectContactPerson>()
  );
  const [departmentList, setDepartmentList] = useState(
    [] as IDropdownOption<number>[]
  );

  const [isCheckingTitle, setIsCheckingTitle] = useState(false);
  const [isTitleValid, setIsTitleValid] = useState(true);

  const [formValues, setFormValues] = useState<ProjectDetailsForm>(
    generateDefaultFormValues(
      currentUser,
      {
        defaultCurrency: Currency.EUR,
      } as IAccountState,
      projectTypesEnum.internalProject.code
    )
  );

  const [approvalRequest, setApprovalRequest] = useState<IApprovalRequest>(
    {} as IApprovalRequest
  );

  // Dropdowns
  const [customerList, setCustomerList] = useState(
    [] as IDropdownOption<number>[]
  );

  const [responsibleList, setResponsibleList] = useState(
    [] as IDropdownOption<number>[]
  );

  const [customerSiteList, setCustomerSiteList] = useState(
    [] as IDropdownOption<number>[]
  );

  const { title, customer, customerSite } = formValues;
  const { value: customerValue } = customer;
  const { value: customerSiteValue } = customerSite;

  const fetchCustomers = async () => {
    try {
      const { data: customers } = await getCustomers({ sort: 'name%2Casc' });
      const customerList = customers.map(({ id, name }) => ({
        label: name,
        value: id as number,
      }));

      setCustomerList(customerList);

      if (customerId) {
        const customer = customerList.find(
          ({ value: id }) => id === customerId
        );

        setFormValues((formValues) => ({
          ...formValues,
          ...(customer && { customer }),
        }));
      }
    } catch (error) {
      modalErrorHandler(t('customerDetailsRetrieveFailed'), error);
    }
  };

  const fetchCustomerSites = async (customerId: number) => {
    try {
      const { data: customerSites } = await getCustomerSites({
        'customerId.equals': `${customerId}`,
        sort: 'name%2Casc',
      });
      const customerSiteList = customerSites.map(({ id, name, location }) => ({
        label: `${name} (${location})`,
        value: id as number,
      }));

      setCustomerSiteList(customerSiteList);

      if (customerSiteId) {
        const customerSite = customerSiteList.find(
          ({ value: id }) => id === customerSiteId
        );

        setFormValues((formValues) => ({
          ...formValues,
          ...(customerSite && { customerSite }),
        }));
      }
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveSites'), error);
    }
  };

  const fetchContactPersons = async (
    customerId: number,
    customerSiteId: number
  ) => {
    try {
      const { data: contactPersons } = await getContactPersons({
        'customerId.equals': `${customerId}`,
        'customerSiteId.equals': `${customerSiteId}`,
        sort: 'name%2Casc',
      });

      const contactPersonMap = new Map<number, ProjectContactPerson>();
      contactPersons.forEach(
        ({ id, firstname, lastname, contactPersonRole, functionRole }) => {
          contactPersonMap.set(id as number, {
            contactPerson: {
              label: `${firstname} ${lastname}`,
              value: id as number,
            },
            role: contactPersonRole?.role as string,
            functionRole: functionRole as string,
          });
        }
      );

      setContactPersonList(contactPersonMap);

      if (contactPersonId) {
        const contactPerson = contactPersonMap.get(contactPersonId);

        setFormValues((formValues) => ({
          ...formValues,
          ...(contactPerson && {
            contactPersons: [contactPerson],
          }),
        }));
      }
    } catch (error) {
      modalErrorHandler(t('retrieveContactPersonsFailed'), error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const { data: departments } = await getDepartments({
        'status.in': `${DepartmentStatus.ACTIVE},${DepartmentStatus.PLANNED}`,
      });

      setDepartmentList(
        departments.map(({ id, title }) => ({
          label: title,
          value: id as number,
        }))
      );
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveDepartments'), error);
    }
  };

  const fetchResponsibles = async () => {
    try {
      const { data: responsibles } = await getEmployeeNames({
        'permissionsFilter.in': PERMISSION_URI.project.readWrite.uri,
        'accessTypeFilter.in': AccessType.READWRITE,
      });

      setResponsibleList(
        responsibles.map(({ id, name }) => ({
          label: name,
          value: id,
        }))
      );
    } catch (error) {
      modalErrorHandler(t('failedToGetEmployees'), error);
    }
  };

  const handleProjectSave = async () => {
    let savedProjectDetail = {} as IProjectDetail;
    try {
      const projectToSave = createProjectDetailFromFormValues(formValues);
      savedProjectDetail = await postProjectDetails(projectToSave).then(
        (response) => response.data
      );
      onCancel();
      onSave(savedProjectDetail.id);
    } catch (error) {
      modalErrorHandler(t('failedToSaveProject'), error);
    }

    try {
      await postApprovalRequest({
        ...approvalRequest,
        objectId: savedProjectDetail.id,
        createdDate: new Date().toISOString(),
      });
    } catch (error) {
      modalErrorHandler(t('failedToSaveApprovalRequest'), error);
    }
  };

  useEffect(() => {
    fetchCustomers();
    fetchDepartments();
    fetchResponsibles();
  }, []);

  useEffect(() => {
    if (title) {
      setIsCheckingTitle(true);
    }

    const timer = setTimeout(() => {
      getProjectCount({ 'title.equals': title }).then(({ data }) => {
        setIsTitleValid(data === 0);
        setIsCheckingTitle(false);
      });
    }, DEFAULT_LOAD_TIMEOUT);

    return () => clearTimeout(timer);
  }, [title]);

  useEffect(() => {
    if (customerValue) {
      fetchCustomerSites(customerValue);
    }
  }, [customer]);

  useEffect(() => {
    if (customerValue && customerSiteValue) {
      fetchContactPersons(customerValue, customerSiteValue);
    }
  }, [customer, customerSite]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Row>
            <Col>
              <InternalProjectGeneralInformationCard
                project={project}
                customerList={customerList}
                customerSiteList={customerSiteList}
                contactPersonList={contactPersonList}
                departmentList={departmentList}
                setContactPersonList={setContactPersonList}
                isCheckingTitle={isCheckingTitle}
                isTitleValid={isTitleValid}
                formValues={formValues}
                setFormValues={setFormValues}
                onApproverChange={setApprovalRequest}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProjectDescription
                project={project}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <InternalProjectBudgetAndScheduleCard
                formValues={formValues}
                approvalRequest={approvalRequest}
                setFormValues={setFormValues}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProjectResponsibles
                responsibleList={responsibleList}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <div className="centered-div">
          <Button
            aria-label="internal-project-button-ok"
            color="primary"
            className="continue-and-cancel-button"
            onClick={handleProjectSave}
            disabled={
              !isInternalProjectValid(formValues, isCheckingTitle, isTitleValid)
            }
          >
            {t('ok')}
          </Button>

          <Button
            aria-label="internal-project-button-cancel"
            color="primary"
            className="continue-and-cancel-button"
            onClick={onCancel}
          >
            {t('cancel')}
          </Button>
        </div>
      </Row>
    </Container>
  );
};

const mapStateToProps = (store: RootState) => ({
  currentUser: store.account.employeeDetails,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(InternalProjectModal));
