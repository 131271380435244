import React, { useState } from 'react';
import { Button, Container, Table } from 'reactstrap';

import { ICustomerListItem } from '../../../utils/types/responseTypes';
import { createMergeCustomerEntry, t } from './customerListHelpers';

interface IProps {
  selectedCustomers: ICustomerListItem[];
  onCancel: () => void;
  onSave: (customerIdToKeep: number) => void;
}

/**
 * Form for selecting customer to keep after merging customers
 */
const SelectCustomerToKeep = ({
  selectedCustomers,
  onCancel,
  onSave,
}: IProps) => {
  const [customerIdToKeep, setCustomerIdToKeep] = useState(
    selectedCustomers[0]?.id ?? 0
  );

  const preparedSelectedCustomerItems = selectedCustomers.map((customer) => {
    const { id } = customer;
    const { name, phone, state, sector, sites, contactPerson, responsible } =
      createMergeCustomerEntry(customer);

    return (
      <tr
        key={`entity-${id}`}
        onClick={() => {
          setCustomerIdToKeep(id);
        }}
      >
        <td>
          <input
            type="radio"
            checked={id === customerIdToKeep}
            id={`myRadioButton-${id}`}
            readOnly
          />
        </td>
        <td>{name}</td>
        <td>{phone}</td>
        <td>{state}</td>
        <td>{sector}</td>
        <td>{sites}</td>
        <td>{contactPerson}</td>
        <td>{responsible}</td>
      </tr>
    );
  });

  return (
    <Container fluid>
      <Table borderless id="customersTable" hover>
        <thead>
          <tr>
            <th aria-label="Empty header" />
            <th>{t('customerName')}</th>
            <th>{t('phone')}</th>
            <th>{t('status')}</th>
            <th>{t('sector')}</th>
            <th>{t('customerSites')}</th>
            <th>{t('contactPerson')}</th>
            <th>{t('responsible')}</th>
          </tr>
        </thead>
        <tbody>{preparedSelectedCustomerItems}</tbody>
      </Table>
      <div className="card-actions float-end">
        <div className="card-actions float-end" style={{ paddingRight: '5px' }}>
          <Button color="primary" size="m" onClick={() => onCancel()}>
            {t('cancel')}
          </Button>
        </div>
        <div className="card-actions float-end" style={{ paddingRight: '5px' }}>
          <Button
            color="primary"
            size="m"
            onClick={() => onSave(customerIdToKeep)}
          >
            {t('save')}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default SelectCustomerToKeep;
