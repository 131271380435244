import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import DynamicTable from '../../../components/tables/DynamicTable';
import {
  CONNECTION_DIRECT,
  CONNECTION_VIA_EMPLOYEEGROUP_FROM_ROLEGROUP,
  CONNECTION_VIA_EMPLOYEE_GROUP,
  CONNECTION_VIA_ROLE_GROUP,
  NO_ID,
} from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { ROLE_ENUM } from '../../../utils/enums/objectType';
import { SUBJECT_TYPE } from '../../../utils/enums/employee';
import { isEmpty } from '../../../utils/helpers/GenericHelper';
import {
  generateTitle,
} from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import {
  IEmployeeGroup,
  IEmployeeWithRelationship,
  IRoleGroup,
} from '../../../utils/types/modelTypes';

interface IProps {
  employees?: IEmployeeWithRelationship[];
  displayType: string;
  roleGroups?: IRoleGroup[];
  employeeGroups?: IEmployeeGroup[];
  removeEmployee: (employeeId: number) => void;
}

interface IState {
  employees: IEmployeeWithRelationship[];
}

interface IEntry {
  name: JSX.Element;
  relationship: JSX.Element;
  delete: JSX.Element | null;
}

/**
 * Class that Displays the Employees for the Employee Card
 * Current columns of the table: Name, Relationship, Delete
 */
class EmployeeTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      employees: props.employees ?? [],
    };
  }

  t(keyName: string) {
    return i18n.t('EmployeeGroupDetail.' + keyName);
  }

  prepareTableData = (employees: IEmployeeWithRelationship[]) => {
    const employeeGroups = this.props?.employeeGroups;
    if (!isEmpty(employees)) {
      const newTableData: IEntry[] = [];
      employees.forEach((employee) => {
        let relationshipInfo = null;
        const foundEmployeeGroup = employeeGroups?.find(
          (employeeGroup) => employeeGroup.id === employee?.secondaryObjectId
        );
        const foundRoleGroup = this.props.roleGroups?.find(
          (roleGroup) => roleGroup.id === employee?.secondaryObjectId
        );
        // Create the relationshipInfo/Links based on the current relationship of the employee
        switch (employee.relationship) {
          case CONNECTION_VIA_EMPLOYEE_GROUP:
            relationshipInfo = (
              <>
                <span>
                  {this.t(
                    (
                      'RELATION/' + (CONNECTION_VIA_EMPLOYEE_GROUP as string)
                    ).toLowerCase()
                  )}
                </span>
                <Link
                  to={`/settings/permissions/employee-group-details/${
                    employee?.secondaryObjectId ?? (NO_ID as number)
                  }`}
                >
                  {foundEmployeeGroup?.name}
                </Link>
              </>
            );
            break;
          case CONNECTION_VIA_ROLE_GROUP:
            relationshipInfo = (
              <>
                <span>
                  {this.t(
                    (
                      'RELATION/' + (CONNECTION_VIA_ROLE_GROUP as string)
                    ).toLowerCase()
                  )}
                </span>
                <Link
                  to={`/settings/permissions/role-group-details/${
                    employee?.secondaryObjectId as number
                  }`}
                >
                  {foundRoleGroup?.roleGroup}
                </Link>
              </>
            );
            break;
          case CONNECTION_VIA_EMPLOYEEGROUP_FROM_ROLEGROUP:
            relationshipInfo = (
              <>
                <span>
                  {this.t(
                    (
                      'RELATION/' + (CONNECTION_VIA_EMPLOYEE_GROUP as string)
                    ).toLowerCase()
                  )}
                </span>
                <Link
                  to={`/settings/permissions/employee-group-details/${employee?.primaryObjectId}`}
                >
                  {
                    employeeGroups?.find(
                      (employeeGroup) =>
                        employeeGroup.id === employee?.primaryObjectId
                    )?.name
                  }
                </Link>
                <span>{this.t('relation/fromRoleGroup')}</span>
                <Link
                  to={`/settings/permissions/role-group-details/${
                    employee?.secondaryObjectId ?? (NO_ID as number)
                  }`}
                >
                  {foundRoleGroup?.roleGroup}
                </Link>
              </>
            );
            break;
          case CONNECTION_DIRECT:
          default:
            relationshipInfo = (
              <span>
                {this.t(('RELATION/' + CONNECTION_DIRECT).toLowerCase())}
              </span>
            );
            break;
        }
        const entry: IEntry = {
          name: (
            <Link
              to={`/employees/employee-list/employee-detail/${
                employee?.employee?.id ?? (NO_ID as number)
              }`}
            >
              {employee.employee?.firstname + ' ' + employee.employee?.name}
            </Link>
          ),
          relationship: relationshipInfo,
          delete:
            employee.relationship === CONNECTION_DIRECT ? (
              <Button
                onClick={() => {
                  this.props.removeEmployee(
                    employee.employee?.id ?? (NO_ID as number)
                  );
                }}
                color="primary"
              >
                {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
              </Button>
            ) : null,
        };
        newTableData.push(entry);
      });
      return newTableData;
    } else {
      return [];
    }
  };

  /**
   * Gets the column by display type
   * @param displayType Display Type of the card/table
   * @returns A column for the table
   */
  getColumnByDisplayType = (displayType: string) => {
    switch (displayType) {
      case ROLE_ENUM.role.code:
      case ROLE_ENUM.roleGroup.code:
        return [
          {
            type: 'data',
            header: this.t('name'),
            accessor: 'name',
            show: 'true',
            filterkey: 'type',
            showsearch: 'true',
          },
          {
            type: 'data',
            header: this.t('relationship'),
            accessor: 'relationship',
            show: 'true',
            filterkey: 'type',
            showsearch: 'true',
          },
          {
            type: 'data',
            header: this.t('delete'),
            accessor: 'delete',
            show: 'true',
          },
        ];
      case SUBJECT_TYPE.employeeGroup.code:
        return [
          {
            type: 'data',
            header: this.t('name'),
            accessor: 'name',
            show: 'true',
            filterkey: 'type',
            showsearch: 'true',
          },
          {
            type: 'data',
            header: this.t('delete'),
            accessor: 'delete',
            show: 'true',
          },
        ];
      default:
        return [];
    }
  };

  render() {
    const groupEmployees = this.props.employees ?? [];
    const preparedColumns = this.getColumnByDisplayType(this.props.displayType);
    return (
      <>
        {!isEmpty(groupEmployees) ? (
          <DynamicTable
            data={this.prepareTableData(groupEmployees)}
            columns={preparedColumns}
          />
        ) : (
          this.t('noEmployees')
        )}
      </>
    );
  }
}

export default EmployeeTable;
