import { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { Button, Card, CardBody, Container, Input } from 'reactstrap';

import axios from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { IRejectionItem } from '../../../utils/types/modelTypes';
import RejectionTypeDropdown from './RejectionTypeDropdown';

interface IProps extends IWithModalsProps {
  rejectionItem: IRejectionItem | null;
  onSave: (rejectionItem: IRejectionItem) => void;
  onCancel: () => void;
}

const RejectionItemInput: React.FC<IProps> = ({
  rejectionItem,
  onSave,
  modalOkHandler,
  modalErrorHandler,
  onCancel,
}) => {
  const t = (keyName: string) => i18n.t('RejectionItemSettings.' + keyName);

  const oldTextJSON = rejectionItem?.textJSON;

  const [enReason, setEnReason] = useState(
    rejectionItem ? JSON.parse(oldTextJSON || '').EN : ''
  );
  const [deReason, setDeReason] = useState(
    rejectionItem ? JSON.parse(oldTextJSON || '').DE : ''
  );
  const [rejectionType, setRejectionType] = useState(
    rejectionItem ? rejectionItem.rejectionType : undefined
  );

  const isSaveButtonDisabled =
    enReason.trim() === '' ||
    deReason.trim() === '' ||
    rejectionType === undefined;

  const submitEvent = async () => {
    if (enReason.length === 0 || deReason.length === 0) {
      modalOkHandler(t('warning'), t('rejectionReasonRequired'));
      return;
    }

    const payload = {
      id: rejectionItem ? rejectionItem.id : null,
      textJSON: JSON.stringify({ EN: enReason, DE: deReason }),
      rejectionType,
    };

    await axios.employee
      .save('rejection-items', payload)
      .then((response: AxiosResponse<IRejectionItem>) => {
        onSave(response.data);
        onCancel();
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToSaveRejectionItem'), error);
      });
  };

  return (
    <Container fluid>
      <Card>
        <CardBody>
          <InputFormLabel isRequired text={t('enReason')} />
          <Input
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEnReason(event.target.value);
            }}
            value={enReason}
          />
          <br />
          <InputFormLabel isRequired text={t('deReason')} />
          <Input
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDeReason(event.target.value);
            }}
            value={deReason}
          />
          <br />
          <InputFormLabel isRequired text={t('rejectionType')} />
          <RejectionTypeDropdown
            onChange={(event: any) => {
              setRejectionType(event);
            }}
            selectedType={rejectionType}
          />
          <br />
          <Button
            color="primary"
            size="m"
            className="float-end"
            onClick={submitEvent}
            disabled={isSaveButtonDisabled}
          >
            {t('save')}
          </Button>
        </CardBody>
      </Card>
    </Container>
  );
};

export default withModals(RejectionItemInput);
