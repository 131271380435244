import { REGEX_VALID_CONTACT_NUMBER_FORMAT } from '../constants';

/**
 * Checks if the string in use is not empty
 * @param stringToCheck
 * @returns
 */
export const isStringNotEmpty = (stringToCheck: string | null | undefined) =>
  stringToCheck != null &&
  stringToCheck !== undefined &&
  stringToCheck.trim() !== '';

/**
 * Use to check if the string is a valid JSON.
 */
export function checkIfStringIsJson(string: string | null | undefined) {
  if (!string) {
    return false;
  }
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Method that removes the extra spaces on the contact number input and restricts space after the plus sign
 * @param {*} str
 * @returns contact number without spaces adjacent to each other
 */
const removeExtraSpacesOnContactNumber = (str: string) =>
  str
    .split('')
    .filter((char, index) => {
      if ((str[index - 1] === '+' || str[index - 1] === ' ') && char === ' ') {
        return null;
      }
      return char;
    })
    .join('');

export const getCorrectContactNumberInput = (
  previousNumber: string,
  currentNumber: string
) => {
  // A regex to only accept numbers with or without a plus(+) symbol at the beginning
  const [newNumber] = currentNumber?.match(
    REGEX_VALID_CONTACT_NUMBER_FORMAT
  ) || [previousNumber];

  return removeExtraSpacesOnContactNumber(newNumber);
};

/**
 * Converts string from screaming snake case to start case
 * @param str String in screaming snake case to convert
 * @returns str in start case
 */
export const screamingSnakeCaseToStartCase = (str: string) =>
  str
    .toLowerCase()
    .split('_')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');
