import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import React from 'react';
import Datetime from 'react-datetime';
import Select from 'react-select';
import { Button } from 'reactstrap';
import { v4 } from 'uuid';

import { IDropdownOption } from '../../../utils/types/commonTypes';
import { dateFormat, t } from './projectOptionHelpers';

const generateActivityFields = (
  key: string,
  { action, dueDate }: { action: IDropdownOption; dueDate: string },
  idx: number,
  options: IDropdownOption[],
  disabled: boolean,
  onChangeActivity: (value: IDropdownOption) => void,
  onChangeDate: (value: Moment | string) => void,
  onDelete: (idx: number) => void
) => (
  <div
    key={key}
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      marginBottom: '10px',
    }}
  >
    <div style={{ width: '220px' }}>
      <Select
        aria-label="activity-dropdown-select"
        value={action}
        options={options}
        onChange={(value: IDropdownOption) => onChangeActivity(value)}
        isDisabled={disabled}
      />
    </div>
    <Datetime
      dateFormat={dateFormat}
      closeOnSelect
      input
      timeFormat={false}
      inputProps={{
        placeholder: dateFormat,
        disabled,
      }}
      onChange={(value) => onChangeDate(value)}
      renderInput={(props) => (
        <input
          {...props}
          value={dueDate ? moment(dueDate).format(dateFormat) : ''}
        />
      )}
    />
    <Button
      aria-label="activity-dropdown-button-delete"
      color="link"
      onClick={() => onDelete(idx)}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  </div>
);

interface IProps {
  activities: {
    action: IDropdownOption;
    dueDate: string;
  }[];
  setActivities: (
    activities: { action: IDropdownOption; dueDate: string }[]
  ) => void;
  activityOptions: IDropdownOption[];
  disabled?: boolean;
}

/**
 * Displays multiple activity dropdowns with date pickers
 */
const ActivityDropdown = ({
  activities,
  setActivities,
  activityOptions,
  disabled = false,
}: IProps) => {
  const handleActivityChange = (action: IDropdownOption, idx: number) => {
    const modifiedActivities = [...activities];
    modifiedActivities.splice(idx, 1, {
      action,
      dueDate: activities[idx]?.dueDate as string,
    });

    setActivities(modifiedActivities);
  };

  const handleDateChange = (date: Moment | string, idx: number) => {
    const modifiedActivities = [...activities];
    modifiedActivities.splice(idx, 1, {
      action: activities[idx]?.action as IDropdownOption,
      dueDate: moment(date).toISOString(),
    });

    setActivities(modifiedActivities);
  };

  const handleActivityDelete = (idx: number) => {
    const modifiedActivities = [...activities];
    modifiedActivities.splice(idx, 1);

    setActivities(modifiedActivities);
  };

  const activityFields = activities.map((_, idx) =>
    generateActivityFields(
      v4(),
      activities[idx] as {
        action: IDropdownOption;
        dueDate: string;
      },
      idx,
      activityOptions,
      disabled,
      (action: IDropdownOption) => handleActivityChange(action, idx),
      (date) => handleDateChange(date, idx),
      (idx) => handleActivityDelete(idx)
    )
  );

  const handleActivityAdd = () => {
    setActivities([
      ...activities,
      {} as {
        action: IDropdownOption;
        dueDate: string;
      },
    ]);
  };

  return (
    <>
      {activityFields}
      <div className="float-end">
        <Button
          aria-label="activity-dropdown-button-add"
          color="primary"
          size="sm"
          onClick={() => handleActivityAdd()}
          disabled={disabled}
        >
          {t('addProjectActivity')}
        </Button>
      </div>
    </>
  );
};

export default ActivityDropdown;
