import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { clickHandler, modalCloseButton } from '../../utils/helpers/click';
import i18n from '../../i18n';

interface IProps {
  modalTitle: string;
  modalBodyText: string;
  event: (() => Promise<void>) | (() => void);
  isOpen: boolean;
  onClose: () => void;
}

const ModalConfirm = (props: IProps) => {
  const { modalTitle, modalBodyText, event, isOpen, onClose } = props;

  const [modal, setModal] = useState(isOpen);

  const toggle = () => {
    if (onClose) {
      onClose();
    }
    setModal(!modal);
  };

  const confirmEvent = async () => {
    await event();
    toggle();
  };

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  return (
    <Draggable>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader close={modalCloseButton(toggle)}>{modalTitle}</ModalHeader>
        <ModalBody>{modalBodyText}</ModalBody>
        <ModalFooter>
          <Button
            aria-label="modal-confirm-button"
            color="dark"
            onClick={(event: MouseEvent) => clickHandler(event, confirmEvent)}
          >
            {i18n.t('ModalConfirm.confirm')}
          </Button>{' '}
          <Button
            color="dark"
            onClick={(event: MouseEvent) => clickHandler(event, toggle)}
          >
            {i18n.t('ModalConfirm.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};

export default ModalConfirm;
