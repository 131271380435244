import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

import axios from '../services/axios/axios';
import {
  IProject,
  IProjectEmployee,
  IResponsibleContactPerson,
  IUserToDoActivity,
} from '../utils/types/modelTypes';
import { IProjectsState } from '../utils/types/stateTypes';
import type { AppThunk } from './store';

const initialState: IProjectsState = {
  contactDetailsClicked: false,
  editedProject: {} as IProject,
  editedResponsibleContactPersons: [],
  projectEmployees: [],
  projectList: [],
  unsavedProjectActivities: [],
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    loadContactDetailsClicked: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      contactDetailsClicked: payload,
    }),
    loadEditedProject: (state, { payload }: PayloadAction<IProject>) => ({
      ...state,
      editedProject: payload,
    }),
    loadEditedResponsibleContactPersons: (
      state,
      { payload }: PayloadAction<IResponsibleContactPerson[]>
    ) => ({
      ...state,
      editedResponsibleContactPersons: payload,
    }),
    loadProjectEmployees: (
      state,
      { payload }: PayloadAction<IProjectEmployee[]>
    ) => ({
      ...state,
      projectEmployees: payload,
    }),
    loadUnsavedProjectActivities: (
      state,
      { payload }: PayloadAction<IUserToDoActivity[]>
    ) => ({
      ...state,
      unsavedProjectActivities: payload,
    }),
    setProjectList: (state, { payload }: PayloadAction<IProject[]>) => ({
      ...state,
      projectList: payload,
    }),
  },
});

export const {
  loadProjectEmployees,
  loadContactDetailsClicked,
  loadEditedResponsibleContactPersons,
  loadEditedProject,
  loadUnsavedProjectActivities,
  setProjectList,
} = projectsSlice.actions;

export const getProjectList =
  (
    successHandler: (projectList: IProject[]) => void,
    failureHandler: (error: AxiosError) => void
  ): AppThunk =>
  (dispatch) => {
    axios.project
      .get('projects')
      .then(({ data }: AxiosResponse<IProject[]>) => {
        dispatch(setProjectList(data));
        successHandler(data);
      })
      .catch((error: AxiosError) => {
        failureHandler(error);
      });
  };

export default projectsSlice.reducer;
