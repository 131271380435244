import { faBookReader } from '@fortawesome/free-solid-svg-icons';

import { SERVICE_NAMES_ENUM } from '../../utils/enums/service';
import { PERMISSION_URI } from '../../utils/enums/permission';
import i18n from '../../i18n';
import ResourceOverview from '../../pages/resourcePlanner/ResourceOverview/ResourceOverview';
import UtilizationOverview from '../../pages/resourcePlanner/UtilizationOverview/UtilizationOverview';

// eslint-disable-next-line import/prefer-default-export
export const resourcePlannerRoutes = {
  path: '/resource-planner',
  name: i18n.t('index.resourcePlanner'),
  serviceName: SERVICE_NAMES_ENUM.resourcePlanner.code,
  icon: faBookReader,
  isProtected: true,
  auth: true,
  badgeColor: 'primary',
  permissionUri: [PERMISSION_URI.resourceOverview.read.uri],
  children: [
    {
      path: '/resource-planner/resource-overview',
      name: i18n.t('index.resourceOverview'),
      component: ResourceOverview,
      permissionUri: [PERMISSION_URI.resourceOverview.read.uri],
    },
    {
      path: '/resource-planner/utilization-overview',
      name: i18n.t('index.utilizationOverview'),
      component: UtilizationOverview,
      permissionUri: [PERMISSION_URI.resourceOverview.read.uri],
    },
  ],
};
