export enum SortBy {
  DEFAULT = '',
  ID = 'id',
  NAME = 'name',
  FIRST_NAME = 'firstname',
  LAST_NAME = 'lastname',
  STATE = 'state',
  LAST_MODIFIED_ON = 'lastModifiedOn',
  CUSTOMER_ACCOUNT = 'customerAccount',
  RESPONSIBLE_NAME = 'responsibleName',
  LOCATION = 'location',
  TITLE = 'title',
  CUSTOMER_NAME = 'customerName',
  CREATED_DATE = 'createdDate',
}

export enum SortType {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
  DEFAULT = '',
}
