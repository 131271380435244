import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import { Card, CardBody, CardHeader, Table, Button } from 'reactstrap';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import { AxiosResponse } from 'axios';

import {
  isEmpty,
  errorHandler,
  sortByPropValue,
} from '../../../utils/helpers/GenericHelper';
import InputFormLabel from '../../../components/form/InputFormLabel';
import i18n from '../../../i18n';
import { REJECTION_CATEGORIES } from '../../../utils/enums/project';
import axios from '../../../services/axios/axios';
import {
  addRecord,
  dataGeneration,
  styleWidth30Percent,
  defaultTableLineHeight,
} from '../../../utils/constants';
import {
  ICustomer,
  IProject,
  IRejectionType,
  IRejectionItem,
} from '../../../utils/types/modelTypes';
import { IDropdownOption } from '../../../utils/types/commonTypes';

interface IProps extends RouteComponentProps {
  closeLostProjectModal: (() => Promise<void>) | (() => void);
  toggleModalExternal: () => void;
  customerDetailToShow: ICustomerDetails;
  project: IProject;
}

interface IState {
  officialRejectionItems: IRejectionItem[];
  internalRejectionItems: IRejectionItem[];
  internalAnalysisChoices: any;
  officialReasonForRejectionChoices: any;
  selectedOfficialReasons: IDropdownOption[];
  selectedRejectionCategory: string;
  selectedInternalReasons: IDropdownOption[];
  description: string;
  rejectionTypes: IRejectionType[];
  rejectionItems: IRejectionItem[];
}

interface ICustomerDetails {
  customerName: any;
  mainContact: string[] | null;
  terminatedAfter: string;
}

/**
 * Contains the offer details in more detail \
 * Props:
 *  -customerDetailToShow - holds value for cutomerName, mainContact, projectLatestState
 *  -project - contining project details
 * Func props:
 *  -closeLostProjectModal - for closing the modal upon saving
 *  -toggleModalExternal - close modal when canceling
 */

class ProjectOptionLostModal extends React.Component<IProps, IState> {
  isOfficialSelected: boolean;

  isInternalSelected: boolean;

  customerDetailToShow: ICustomer | null;

  constructor(props: IProps) {
    super(props);
    this.state = {
      officialRejectionItems: [],
      internalRejectionItems: [],
      internalAnalysisChoices: [],
      officialReasonForRejectionChoices: [],
      selectedOfficialReasons: [],
      selectedRejectionCategory: '',
      selectedInternalReasons: [],
      description: '',
      rejectionTypes: [],
      rejectionItems: [],
    };

    this.isOfficialSelected = false;
    this.isInternalSelected = false;
    this.customerDetailToShow = null;
  }

  t(keyName: string) {
    return i18n.t('ProjectOptionLostModal.' + keyName);
  }

  componentDidMount() {
    // Get all rejection items
    axios.sales
      .get('rejection-items')
      .then((response: AxiosResponse<IRejectionItem[]>) => {
        const rejectionItems: IRejectionItem[] = response.data;
        if (Array.isArray(rejectionItems) && !isEmpty(rejectionItems)) {
          const officialRejectionItems = rejectionItems.filter(
            (item) =>
              item?.rejectionType?.category ===
              REJECTION_CATEGORIES.official.code
          );
          const internalRejectionItems = rejectionItems.filter(
            (item) =>
              item?.rejectionType?.category ===
              REJECTION_CATEGORIES.internal.code
          );
          this.setState({
            officialRejectionItems,
            internalRejectionItems,
          });
        }
      })
      .catch((error) => {
        errorHandler(dataGeneration, error, error.errorMessage);
      });

    // Retrieve rejection types
    axios.sales
      .get('rejection-types')
      .then((response: AxiosResponse<IRejectionType[]>) => {
        this.setState({
          rejectionTypes: response.data,
        });
      })
      .catch((error) => {
        errorHandler(dataGeneration, error, error.errorMessage);
      });
  }

  // Check if string follows correct JSON format and can be parsed
  checkIfStringIsJson = (string: string | undefined) => {
    if (!string) {
      return false;
    }
    try {
      const parsedMessage =
        JSON.parse(string)[i18n.language.toLocaleUpperCase()];
      return parsedMessage != null;
    } catch (e) {
      return false;
    }
  };

  // Handles the creation of Reasons as choices by group/rejectionType based on the current user selected language
  createInternalAnalysisChoicesByGroup = (
    selectedRejectionItems: IRejectionItem[],
    selectedCategory: string
  ) => {
    const rejectionTypesByCategory = this.state.rejectionTypes.filter(
      (rejectionType) => rejectionType.category === selectedCategory
    );
    if (isEmpty(rejectionTypesByCategory) || isEmpty(selectedRejectionItems)) {
      return [];
    }

    const createdOptions = rejectionTypesByCategory.map((rejectionType) => ({
      label: rejectionType?.name,
      options: sortByPropValue(
        selectedRejectionItems
          .filter(
            (reason) => reason?.rejectionType?.name === rejectionType.name
          )
          .map((reason) => {
            const label =
              typeof reason.textJSON === 'string' &&
              this.checkIfStringIsJson(reason.textJSON)
                ? JSON.parse(reason.textJSON)[i18n.language.toLocaleUpperCase()]
                : `Rejection item doens't follow correct JSON format, check rejection item with id of ${reason.id}`;
            return {
              label,
              value: reason,
            };
          }),
        'label'
      ),
    }));

    // Filter createdOptions, remove items with options value of empty list
    const filteredCreatedOptions = createdOptions.filter(
      (entry) => !isEmpty(entry.options)
    );
    return filteredCreatedOptions;
  };

  // Handles the change for the selected RejectedBy/Category
  handleRejectedByChange = (selectedRejectionCategory: IDropdownOption) => {
    const selectedCategory = selectedRejectionCategory.value;

    if (selectedCategory === REJECTION_CATEGORIES.official.code) {
      const officialAnalysisChoices = this.createInternalAnalysisChoicesByGroup(
        this.state.officialRejectionItems,
        selectedCategory
      );

      this.isOfficialSelected = true;
      this.isInternalSelected = false;
      this.setState({
        internalAnalysisChoices: officialAnalysisChoices,
        officialReasonForRejectionChoices: officialAnalysisChoices,
        selectedRejectionCategory: selectedCategory,
        selectedInternalReasons: [],
      });
    }

    if (selectedCategory === REJECTION_CATEGORIES.internal.code) {
      const internalAnalysisChoices = this.createInternalAnalysisChoicesByGroup(
        this.state.internalRejectionItems,
        selectedCategory
      );
      this.isOfficialSelected = false;
      this.isInternalSelected = true;
      this.setState({
        internalAnalysisChoices,
        selectedRejectionCategory: selectedCategory,
        selectedInternalReasons: [],
        selectedOfficialReasons: [],
      });
    }
  };

  // Hanlde the change for the selection of "Official reason for rejection".
  handleOfficialReasonForRejectionChange = (
    selectedOfficialReasons: IDropdownOption[]
  ) => {
    this.setState({
      selectedOfficialReasons,
    });
  };

  // Hanlde the change for the selection of "Internal Analysis/Reason".
  handleInternalAnalysisChange = (
    selectedInternalReasons: IDropdownOption[]
  ) => {
    this.setState({
      selectedInternalReasons,
    });
  };

  // Handle description change
  handleDescriptionChange = (description: string) => {
    this.setState({
      description,
    });
  };

  // Handles the closing of projectOption
  onSaveCloseProjectOption = async () => {
    // Retrieve list of internalReasonDetails from the selectedInternalReasons
    const selectedInternalReasons = this.state.selectedInternalReasons;
    const internalReasonDetails: string[] = [];
    selectedInternalReasons.forEach((internalReason) => {
      const internalReasonDetail = internalReason.value;
      internalReasonDetails.push(internalReasonDetail);
    });

    // Retrieve list of officialReasonDetails from the selectedOfficialReasons
    const selectedOfficialReasons = this.state.selectedOfficialReasons;
    const officialReasonDetails: string[] = [];
    selectedOfficialReasons.forEach((officialReason) => {
      const officialReasonDetail = officialReason.value;
      officialReasonDetails.push(officialReasonDetail);
    });

    const project = this.props.project;
    const rejectionDetails = {
      id: null,
      projectId: project?.id,
      description: this.state.description,
      rejectionDate: moment().startOf('day'),
      lastStage: project.state,
      internalReasonDetails,
      officialReasonDetails,
    };

    await axios.sales
      .post('rejection-details', rejectionDetails)
      .catch((error) => {
        errorHandler(addRecord, error, error.errorMessage);
      });

    if (this.props.closeLostProjectModal) {
      await this.props.closeLostProjectModal();
    }
  };

  render() {
    const headerTitle = `${this.t('closeProjectOption')} "${
      this.props.project?.title
    }"`;
    const closeOptionDisabled =
      this.state.selectedRejectionCategory === null ||
      isEmpty(this.state.selectedInternalReasons) ||
      (this.isOfficialSelected && isEmpty(this.state.selectedOfficialReasons));
    const { customerDetailToShow } = this.props;
    const { customerName, mainContact, terminatedAfter } = customerDetailToShow;
    const select = this.t('select');
    const selectRejectedByFirst = this.t('selectRejectedByFirst');
    return (
      <Card className="flex-fill w-100">
        <div style={{ textAlign: 'center' }}>
          <CardHeader>
            <h3>{headerTitle}</h3>
          </CardHeader>
        </div>
        <CardBody>
          <Table borderless style={defaultTableLineHeight}>
            <tbody>
              <tr>
                <th scope="row" style={{ width: styleWidth30Percent }}>
                  {this.t('customer')}:
                </th>
                <td width="70%">{customerName}</td>
              </tr>
              <tr>
                <th scope="row" style={{ width: styleWidth30Percent }}>
                  {this.t('mainContact')}
                </th>
                <td>{mainContact}</td>
              </tr>
              <tr>
                <th style={{ width: styleWidth30Percent }}>
                  <InputFormLabel
                    isRequired={false}
                    text={this.t('terminatedAfter')}
                  />
                </th>
                <td>{terminatedAfter}</td>
              </tr>
              <tr>
                <th style={{ width: styleWidth30Percent }}>
                  <InputFormLabel
                    isRequired={true}
                    text={this.t('rejectedBy')}
                  />
                </th>
                <td>
                  <Select
                    onChange={this.handleRejectedByChange}
                    options={Object.values(REJECTION_CATEGORIES).map(
                      (category) => ({
                        value: category.code,
                        label: category.name,
                      })
                    )}
                  />
                </td>
              </tr>
              <tr />

              {this.isOfficialSelected === true ? (
                <tr>
                  <th style={{ width: styleWidth30Percent }}>
                    <InputFormLabel
                      isRequired={!!this.isOfficialSelected}
                      text={this.t('officialReasonForRejection')}
                    />
                  </th>
                  <td>
                    <Select
                      options={this.state.officialReasonForRejectionChoices}
                      onChange={this.handleOfficialReasonForRejectionChange}
                      placeholder={
                        this.isOfficialSelected === true ||
                        this.isInternalSelected === true
                          ? select
                          : selectRejectedByFirst
                      }
                      isMulti={true}
                      readOnly={!this.isOfficialSelected}
                      value={this.state.selectedOfficialReasons}
                    />
                  </td>
                </tr>
              ) : null}
              <tr>
                <th style={{ width: '25%' }}>
                  <InputFormLabel
                    isRequired={
                      !!(this.isOfficialSelected || this.isInternalSelected)
                    }
                    text={this.t('internalAnalysis')}
                  />
                </th>
                <td>
                  <Select
                    options={this.state.internalAnalysisChoices}
                    value={this.state.selectedInternalReasons}
                    onChange={this.handleInternalAnalysisChange}
                    placeholder={
                      this.isOfficialSelected === true ||
                      this.isInternalSelected === true
                        ? select
                        : selectRejectedByFirst
                    }
                    isMulti={true}
                    readOnly={
                      !!(
                        this.isOfficialSelected === false &&
                        this.isInternalSelected === false
                      )
                    }
                    isDisabled={
                      !!(
                        this.isOfficialSelected === false &&
                        this.isInternalSelected === false
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <th style={{ width: '25%' }}>
                  <InputFormLabel
                    isRequired={false}
                    text={this.t('description')}
                  />
                </th>
                <td style={{ width: '50%' }}>
                  <ReactQuill
                    readOnly={false}
                    modules={{
                      toolbar: true,
                    }}
                    onChange={this.handleDescriptionChange}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
        <div
          className="card-actions float-start"
          style={{
            paddingLeft: '10px',
            paddingBottom: '10px',
          }}
        >
          <Button
            color="primary"
            size="m"
            onClick={() => this.onSaveCloseProjectOption()}
            disabled={closeOptionDisabled}
          >
            {this.t('closeProjectOption')}
          </Button>{' '}
          <Button
            color="primary"
            size="m"
            onClick={() => this.props.toggleModalExternal()}
          >
            {this.t('cancel')}
          </Button>
        </div>
      </Card>
    );
  }
}

export default withRouter(ProjectOptionLostModal);
