import axios, { AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

import { IApprovalRequestListItem } from '../../utils/types/responseTypes';
import { urlBuilder } from '../../utils/helpers/url';
import { Url } from './constants';
import { IApprovalRequest } from '../../utils/types/modelTypes';

export const getApprovalListItems = (queryParameters: {
  [key: string]: string;
}) =>
  trackPromise<AxiosResponse<IApprovalRequestListItem[]>>(
    axios.get(urlBuilder(Url.APPROVAL_REQUEST_LIST, queryParameters))
  );

export const updateApprovalListItems = (payload: IApprovalRequestListItem) =>
  trackPromise<AxiosResponse<IApprovalRequestListItem[]>>(
    axios.patch(urlBuilder(Url.APPROVAL_REQUEST_PATCH, {}), payload)
  );

export const postApprovalRequest = (approvalRequest: IApprovalRequest) =>
  trackPromise<AxiosResponse<IApprovalRequest>>(
    axios.post(urlBuilder(Url.PROJECT_APPROVAL_STATUS, {}), approvalRequest)
  );
