import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';

import axios from '../../../services/axios/axios';
import DynamicTable from '../../../components/tables/DynamicTable';
import { NO_ID } from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { ROLE_ENUM } from '../../../utils/enums/objectType';
import { SUBJECT_TYPE } from '../../../utils/enums/employee';
import { handleError, isEmpty } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import i18n from '../../../i18n';
import { IErrorMessage } from '../../../utils/types/commonTypes';
import { IRoleGroup, ISubject2Role } from '../../../utils/types/modelTypes';
import ModalDelete from '../../../components/modals/ModalDelete';
import ModalError from '../../../components/modals/ModalError';
import ModalForm from '../../../components/modals/ModalForm';
import AddRoleGroupModal from '../../../components/form/AddRoleGroupModal';

// !-- Used in RoleDetail, EmployeeGroupDetail

interface IProps {
  cardTitle?: string;
  buttonName?: string;
  subjectType: string;
  subjectId: number;
  roleGroups: ISubject2Role[];
  roleGroupOptions?: IRoleGroup[];
}

interface IState {
  roleGroups: ISubject2Role[];
  roleGroupOptions: IRoleGroupOptions[];
  showModalAddRole: boolean;
  showModalError: boolean;
  showModalOk: boolean;
  deleteModal: boolean;
  modalForm: JSX.Element | null;
}

interface IRoleGroupOptions {
  value: number;
  label: string;
  description: string;
}

interface IEntry {
  roleGroup: JSX.Element;
  description: string;
  delete: JSX.Element;
}

/**
 * Class that shows the Role Group Card
 */
class RoleGroupCard extends React.Component<IProps, IState> {
  error: IErrorMessage = {} as IErrorMessage;

  deleteRoleGroupEvent = async () => {
    // Empty function default
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      roleGroups: [],
      roleGroupOptions: [],
      showModalAddRole: false,
      showModalError: false,
      showModalOk: false,
      deleteModal: false,
      modalForm: null,
    };
  }

  async componentDidMount() {
    if (!this.props.roleGroupOptions) {
      // Get all of the role groups for the details and dropdown options
      await axios.employee
        .get('role-groups')
        .then((response: AxiosResponse<IRoleGroup[]>) => {
          this.setState({
            roleGroupOptions: response.data.map((role) => ({
              value: role.id ?? (NO_ID as number),
              label: role.roleGroup ?? '',
              description: role.description ?? '',
            })),
          });
        })
        .catch((error: AxiosError) => {
          const mainError = this.t('failedToRetrieveRoleGroups');
          this.handleError(mainError, error);
        });
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.roleGroups !== this.props.roleGroups) {
      this.setState({
        roleGroups: this.props.roleGroups,
      });
    }
    if (
      prevProps.roleGroupOptions !== this.props.roleGroupOptions &&
      this.props.roleGroupOptions
    ) {
      this.setState({
        roleGroupOptions: this.props?.roleGroupOptions.map((role) => ({
          value: role.id ?? (NO_ID as number),
          label: role.roleGroup ?? '',
          description: role.description ?? '',
        })),
      });
    }
  }

  t(keyName: string) {
    return i18n.t(`RoleGroupCard.${keyName}`);
  }

  handleError = (mainError: string, errorObject: AxiosError) => {
    this.error = handleError(mainError, errorObject);
    if (!this.state.showModalError) {
      this.toggleModalError();
    }
  };

  toggleModalError = () => {
    this.setState({
      showModalError: !this.state.showModalError,
    });
  };

  toggleModalOK = () => {
    this.setState({
      showModalOk: !this.state.showModalOk,
    });
  };

  toggleAddRoleGroup = () => {
    this.setState({
      showModalAddRole: !this.state.showModalAddRole,
    });
  };

  toggleDeleteRoleGroup = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  /**
   * Method that shows the Add Group modal
   */
  addNewRoleGroup = () => {
    // Filter out the already existing role group from the options
    const roleGroupOptions = this.state.roleGroupOptions.filter(
      (roleGroupOption) =>
        !this.state.roleGroups.some(
          (existingRolegroup) =>
            (this.isSubjectTypeRole(this.props.subjectType)
              ? existingRolegroup?.subjectId
              : existingRolegroup?.roleId) === roleGroupOption.value
        )
    );
    this.setState({
      modalForm: (
        <AddRoleGroupModal
          subjectId={this.props.subjectId}
          subjectType={this.props.subjectType}
          roleGroupOptions={roleGroupOptions}
          onSave={this.onSaveRoleGroup}
          onCancel={this.toggleAddRoleGroup}
        />
      ),
    });
    this.toggleAddRoleGroup();
  };

  /**
   * Saves the rolegroup to the user/object that it's currently in and adds it in
   * @param {*} roleGroup
   */
  onSaveRoleGroup = (roleGroup: ISubject2Role) => {
    this.toggleAddRoleGroup();
    const { roleGroups } = this.state;
    roleGroups.push(roleGroup);
    this.setState({
      roleGroups,
    });
  };

  /**
   * Method that handles the deletion of a role group
   * @param {*} roleGroupId The id of the role group
   * @returns
   */
  deleteRoleGroup = (roleGroupId: number) => {
    if (roleGroupId === NO_ID) return;

    this.toggleDeleteRoleGroup();
    this.deleteRoleGroupEvent = () =>
      axios.employee
        .delete(`subject-2-roles/${roleGroupId}`)
        .then(() => {
          this.setState({
            roleGroups: this.state.roleGroups.filter(
              (role) => role?.id !== roleGroupId
            ),
          });
        })
        .catch((error: AxiosError) => {
          const mainError = this.t('failedToDeleteRoleGroup');
          this.handleError(mainError, error);
        });
  };

  prepareTableData = (roleGroups: ISubject2Role[]) => {
    const roleGroupsData = this.state.roleGroupOptions;
    const { subjectType } = this.props;
    if (!isEmpty(roleGroups)) {
      const newTableData: IEntry[] = [];
      roleGroups.forEach((roleGroup) => {
        const foundRoleGroup = roleGroupsData.find(
          (id) =>
            id.value ===
            (this.isSubjectTypeRole(subjectType)
              ? roleGroup?.subjectId
              : roleGroup.roleId)
        );

        const entry: IEntry = {
          roleGroup: (
            <Link
              to={`/settings/permissions/role-group-details/${
                (this.isSubjectTypeRole(subjectType)
                  ? roleGroup?.subjectId
                  : roleGroup?.roleId) ?? (NO_ID as number)
              }`}
            >
              {foundRoleGroup?.label}
            </Link>
          ),
          description: foundRoleGroup?.description ?? '',
          delete: (
            <Button
              color="primary"
              onClick={() =>
                this.deleteRoleGroup(roleGroup?.id ?? (NO_ID as number))
              }
            >
              {generateTitle(BUTTON_TITLE_ENUM.DELETE.code)}
            </Button>
          ),
        };
        newTableData.push(entry);
      });
      return newTableData;
    }
    return [];
  };

  /**
   * Checks if the subjectType is a Role
   * @param {*} subjectType subject type string
   * @returns a boolean
   */
  isSubjectTypeRole = (subjectType: string) =>
    subjectType === ROLE_ENUM.role.code;

  render() {
    const { roleGroups } = this.state;
    const preparedColumns = [
      {
        type: 'data',
        header: this.t('roleGroup'),
        accessor: 'roleGroup',
        show: 'true',
        filterkey: 'type',
        showsearch: 'true',
      },
      {
        type: 'data',
        header: this.t('description'),
        accessor: 'description',
        show: 'true',
        filterkey: 'type',
        showsearch: 'true',
      },
      {
        type: 'data',
        header: this.t('delete'),
        accessor: 'delete',
        show: (!this.isSubjectTypeRole(this.props.subjectType)).toString(),
        alignRight: 'true',
      },
    ];
    return (
      <Container fluid>
        <Card>
          <CardHeader>
            <h2>{this.props.cardTitle ?? this.t('cardTitle')}</h2>

            {this.props.subjectType === SUBJECT_TYPE.employeeGroup.code && (
              <Button
                className="float-end"
                color="primary"
                onClick={() => this.addNewRoleGroup()}
              >
                {this.props.buttonName ?? this.t('addRoleGroup')}
              </Button>
            )}
          </CardHeader>
          <CardBody>
            {!isEmpty(roleGroups) ? (
              <DynamicTable
                data={this.prepareTableData(roleGroups)}
                columns={preparedColumns}
              />
            ) : (
              this.t('roleGroupEmpty')
            )}
          </CardBody>
        </Card>
        <ModalForm
          isOpen={this.state.showModalAddRole}
          eventOnClose={this.toggleAddRoleGroup}
          ref={this.state.modalForm}
          modalTitle={this.t('addRoleGroup')}
          size="m"
        >
          {this.state.modalForm}
        </ModalForm>
        <ModalDelete
          isOpen={this.state.deleteModal}
          event={this.deleteRoleGroupEvent}
          onClose={this.toggleDeleteRoleGroup}
          modalTitle={this.t('deleteRoleGroup')}
          modalBodyText={this.t('confirmDeleteGroup')}
        />
        <ModalError
          isOpen={this.state.showModalError}
          onClose={this.toggleModalError}
          mainError={this.error?.mainError}
          errorReason={this.error?.errorReason}
          errorResponse={this.error?.errorResponse}
          modalTitle={this.t('error')}
        />
      </Container>
    );
  }
}

export default RoleGroupCard;
