import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Table,
  Col,
  Row,
} from 'reactstrap';

import DynamicTable from '../../../components/tables/DynamicTable';
import i18n from '../../../i18n';
import {
  formatMomentDurationToHHMMSS,
  formatToHHMMSS,
} from './contactPersonHelper';
import {
  IActivity,
  IExtendedContactPerson,
} from '../../../utils/types/modelTypes';

interface IProps {
  duration: string;
  totalCalls: number;
  warmCalls: number;
  coldCalls: number;
  warmCallsReached: number;
  coldCallsReached: number;
  warmCallsAppointment: number;
  coldCallsAppointment: number;
  activities: IActivity[];
  callingList: IExtendedContactPerson[];
  globalStats: IGlobalStats;
  successRate: number;
  appointmentRate: number;
  getGlobalStats: () => void;
  overduePercentage: number;
  coldCallPercentage: number;
  hideGlobalStatus?: boolean;
  hideTitle?: boolean;
}

interface IGlobalStats {
  status: boolean;
  totalCalls: number;
  warmCalls: number;
  coldCalls: number;
  warmCallsReached: number;
  coldCallsReached: number;
  coldCallsAppointment: number;
  warmCallsAppointment: number;
  duration: string;
}

const t = (keyName: string) => i18n.t(`CallingStatistics.${keyName}`);

/**
 * Display Call Statistics
 *
 */
const CallStatistics = ({
  duration,
  totalCalls,
  warmCalls,
  coldCalls,
  warmCallsReached,
  coldCallsReached,
  warmCallsAppointment,
  coldCallsAppointment,
  activities,
  callingList,
  globalStats,
  successRate,
  appointmentRate,
  getGlobalStats,
  overduePercentage,
  coldCallPercentage,
  hideGlobalStatus,
  hideTitle,
}: IProps) => {
  const preparedColumns = [
    {
      type: 'data',
      header: t('activity'),
      accessor: 'activity',
      show: 'true',
    },
    {
      type: 'data',
      header: t('contact'),
      accessor: 'contact',
      show: 'true',
    },
    {
      type: 'data',
      header: t('customerSite'),
      accessor: 'customerSite',
      show: 'true',
    },
  ];

  // Properly format duration using the functions
  const formattedDuration = formatToHHMMSS(duration);
  const formattedGlobalDuration = formatMomentDurationToHHMMSS(
    moment.duration(globalStats.duration)
  );

  const newTableData = activities.map(({ objectId, description }) => {
    const activityContact = callingList.find(
      ({ contactPerson }) => objectId === contactPerson.id
    )?.contactPerson;

    const entry = {
      activity: description,
      contact: activityContact && activityContact.customer && (
        <Link
          target="_blank"
          to={`/customer/customers/details/${activityContact.customer.id}/contact-person-profile/${activityContact.id}`}
        >
          {`${activityContact.lastname}, ${activityContact.firstname}`}
        </Link>
      ),
      customerSite: activityContact ? activityContact.customerSite?.name : null,
    };

    return entry;
  });

  return (
    <div data-testid="call-statistics-div">
      <CardTitle>
        {!hideTitle && (
          <h1
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {globalStats.status ? 'Global Stats' : <>{t('completedTheCall')}</>}
          </h1>
        )}
      </CardTitle>{' '}
      <CardBody>
        {globalStats.status ? (
          <>
            <Row>
              <Col>
                <Card>
                  <CardTitle>{t('summary')}</CardTitle>
                  <CardBody>
                    <Table>
                      <tr>
                        <th>{t('callSession')}:</th>
                        <td data-testid="global-stats-duration-display">
                          {formattedGlobalDuration}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('calls')}</th>
                        <td data-testid="global-stats-total-calls-display">
                          {globalStats.totalCalls}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('reached')}:</th>
                        <td data-testid="global-stats-total-calls-reached-display">
                          {globalStats.warmCallsReached +
                            globalStats.coldCallsReached}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('appointments')}:</th>
                        <td data-testid="global-stats-total-appointments-display">
                          {globalStats.warmCallsAppointment +
                            globalStats.coldCallsAppointment}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('callingFrequency')}:</th>
                        <td data-testid="global-stats-calling-frequency-display">
                          {Number.isNaN(
                            totalCalls /
                              moment.duration(globalStats.duration).asMinutes()
                          )
                            ? '0.00'
                            : (
                                totalCalls /
                                moment
                                  .duration(globalStats.duration)
                                  .asMinutes()
                              ).toFixed(2)}{' '}
                          /minute
                        </td>
                      </tr>
                      <tr>
                        <th>{t('callingSuccess')}:</th>
                        <td data-testid="global-stats-calling-success-display">
                          {Number.isNaN(
                            100 *
                              ((globalStats.warmCallsReached +
                                globalStats.coldCallsReached) /
                                globalStats.totalCalls)
                          )
                            ? '0.00'
                            : (
                                100 *
                                ((globalStats.warmCallsReached +
                                  globalStats.coldCallsReached) /
                                  globalStats.totalCalls)
                              ).toFixed(2)}{' '}
                          %
                        </td>
                      </tr>
                      <tr>
                        <th>{t('appointmentRate')}:</th>
                        <td data-testid="global-stats-appointment-rate-display">
                          {Number.isNaN(
                            100 *
                              ((globalStats.warmCallsAppointment +
                                globalStats.coldCallsAppointment) /
                                globalStats.totalCalls)
                          )
                            ? '0.00'
                            : (
                                100 *
                                ((globalStats.warmCallsAppointment +
                                  globalStats.coldCallsAppointment) /
                                  globalStats.totalCalls)
                              ).toFixed(2)}{' '}
                          %
                        </td>
                      </tr>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardTitle>{t('coldCalling')}</CardTitle>
                  <CardBody>
                    <Table>
                      <tr>
                        <th>{t('coldCalls')}:</th>
                        <td data-testid="global-stats-cold-calls-display">
                          {globalStats.coldCalls}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('reached')}:</th>
                        <td data-testid="global-stats-cold-calls-reached-display">
                          {globalStats.coldCallsReached}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('prospection')}:</th>
                        <td data-testid="global-stats-cold-calls-appointment-display">
                          {globalStats.coldCallsAppointment}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('coldCallingSuccess')}:</th>
                        <td data-testid="global-stats-cold-calling-success-display">
                          {Number.isNaN(
                            100 *
                              (globalStats.coldCallsReached /
                                globalStats.coldCalls)
                          )
                            ? '0.00'
                            : (
                                100 *
                                (globalStats.coldCallsReached /
                                  globalStats.coldCalls)
                              ).toFixed(2)}{' '}
                          %
                        </td>
                      </tr>
                      <tr>
                        <th>{t('propsectionRate')}:</th>
                        <td data-testid="global-stats-cold-calls-prospection-rate-display">
                          {Number.isNaN(
                            100 *
                              (globalStats.coldCallsAppointment /
                                globalStats.coldCallsReached)
                          )
                            ? '0.00'
                            : (
                                100 *
                                (globalStats.coldCallsAppointment /
                                  globalStats.coldCallsReached)
                              ).toFixed(2)}{' '}
                          %
                        </td>
                      </tr>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardTitle>{t('warmCalling')}</CardTitle>
                  <CardBody>
                    <Table>
                      <tr>
                        <th>{t('warmCalls')}:</th>
                        <td data-testid="global-stats-warm-calls-display">
                          {globalStats.warmCalls}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('reached')}:</th>
                        <td data-testid="global-stats-warm-calls-reached-display">
                          {globalStats.warmCallsReached}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('followUp')}:</th>
                        <td data-testid="global-stats-warm-calls-appointment-display">
                          {globalStats.warmCallsAppointment}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('warmCallingSuccess')}:</th>
                        <td data-testid="global-stats-warm-calling-success-display">
                          {Number.isNaN(
                            100 *
                              (globalStats.warmCallsReached /
                                globalStats.warmCalls)
                          )
                            ? '0.00'
                            : (
                                100 *
                                (globalStats.warmCallsReached /
                                  globalStats.warmCalls)
                              ).toFixed(2)}{' '}
                          %
                        </td>
                      </tr>
                      <tr>
                        <th>{t('appointmentRate')}:</th>
                        <td data-testid="global-stats-warm-calls-appointment-rate-display">
                          {Number.isNaN(
                            100 *
                              (globalStats.warmCallsAppointment /
                                globalStats.warmCallsReached)
                          )
                            ? '0.00'
                            : (
                                100 *
                                (globalStats.warmCallsAppointment /
                                  globalStats.warmCallsReached)
                              ).toFixed(2)}{' '}
                          %
                        </td>
                      </tr>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="float-right">
                  <Button
                    color="primary"
                    className="float-right"
                    size="m"
                    data-testid="back-to-daily-stats-button"
                    onClick={() => getGlobalStats()}
                  >
                    {t('backToDailyStats')}
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col>
                <Card>
                  <CardTitle>{t('summary')}</CardTitle>
                  <CardBody>
                    <Table>
                      <tr>
                        <th>{t('callSession')}</th>
                        <td data-testid="duration-display">
                          {formattedDuration}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('calls')}:</th>
                        <td data-testid="total-calls-display">{totalCalls}</td>
                      </tr>
                      <tr>
                        <th>{t('reached')}:</th>
                        <td data-testid="total-calls-reached-display">
                          {warmCallsReached + coldCallsReached}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('appointments')}:</th>
                        <td data-testid="total-appointments-display">
                          {warmCallsAppointment + coldCallsAppointment}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('callingFrequency')}:</th>
                        <td data-testid="calling-frequency-display">
                          {Number.isNaN(
                            totalCalls / moment.duration(duration).asMinutes()
                          )
                            ? '0.00'
                            : (
                                totalCalls /
                                moment.duration(duration).asMinutes()
                              ).toFixed(2)}{' '}
                          /minute
                        </td>
                      </tr>
                      <tr>
                        <th>{t('callingSuccess')}:</th>
                        <td data-testid="calling-success-rate-display">
                          {Number.isNaN(
                            100 *
                              ((warmCallsReached + coldCallsReached) /
                                totalCalls)
                          )
                            ? '0.00'
                            : (
                                100 *
                                ((warmCallsReached + coldCallsReached) /
                                  totalCalls)
                              ).toFixed(2)}{' '}
                          %
                        </td>
                      </tr>
                      <tr>
                        <th>{t('appointmentRate')}:</th>
                        <td data-testid="appointment-rate-display">
                          {Number.isNaN(
                            100 *
                              ((warmCallsAppointment + coldCallsAppointment) /
                                (warmCallsReached + coldCallsReached))
                          )
                            ? '0.00'
                            : (
                                100 *
                                ((warmCallsAppointment + coldCallsAppointment) /
                                  (warmCallsReached + coldCallsReached))
                              ).toFixed(2)}{' '}
                          %
                        </td>
                      </tr>
                      {!Number.isNaN(overduePercentage) && (
                        <tr>
                          <th>{t('overduePercentage')}:</th>
                          <td data-testid="overdue-percentage-display">{`${overduePercentage}%`}</td>
                        </tr>
                      )}
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardTitle>{t('coldCalling')}</CardTitle>
                  <CardBody>
                    <Table>
                      <tr>
                        <th>{t('coldCalls')}:</th>
                        <td data-testid="cold-calls-display">{coldCalls}</td>
                      </tr>
                      <tr>
                        <th>{t('reached')}:</th>
                        <td data-testid="cold-calls-reached-display">
                          {coldCallsReached}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('prospection')}:</th>
                        <td data-testid="cold-calls-appointment-display">
                          {coldCallsAppointment}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('coldCallingSuccess')}:</th>
                        <td data-testid="cold-calling-success-rate-display">
                          {Number.isNaN(100 * (coldCallsReached / coldCalls))
                            ? '0.00'
                            : (100 * (coldCallsReached / coldCalls)).toFixed(
                                2
                              )}{' '}
                          %
                        </td>
                      </tr>
                      <tr>
                        <th>{t('prospectionRate')}:</th>
                        <td data-testid="cold-calls-prospection-rate-display">
                          {Number.isNaN(
                            100 * (coldCallsAppointment / coldCallsReached)
                          )
                            ? '0.00'
                            : (
                                100 *
                                (coldCallsAppointment / coldCallsReached)
                              ).toFixed(2)}{' '}
                          %
                        </td>
                      </tr>
                      {!Number.isNaN(coldCallPercentage) && (
                        <tr>
                          <th>{t('coldCallPercentage')}:</th>
                          <td data-testid="cold-calls-percentage-display">{`${coldCallPercentage}%`}</td>
                        </tr>
                      )}
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardTitle>{t('warmCalling')}</CardTitle>
                  <CardBody>
                    <Table>
                      <tr>
                        <th>{t('warmCalls')}:</th>
                        <td data-testid="warm-calls-display">{warmCalls}</td>
                      </tr>
                      <tr>
                        <th>{t('reached')}:</th>
                        <td data-testid="warm-calls-reached-display">
                          {warmCallsReached}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('followUp')}:</th>
                        <td data-testid="warm-calls-appointment-display">
                          {warmCallsAppointment}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('warmCallingSuccess')}:</th>
                        <td data-testid="warm-calling-success-rate-display">
                          {Number.isNaN(100 * (warmCallsReached / warmCalls))
                            ? '0.00'
                            : (100 * (warmCallsReached / warmCalls)).toFixed(
                                2
                              )}{' '}
                          %
                        </td>
                      </tr>
                      <tr>
                        <th>{t('appointmentRate')}:</th>
                        <td data-testid="warm-calls-appointment-rate-display">
                          {Number.isNaN(
                            100 * (warmCallsAppointment / warmCallsReached)
                          )
                            ? '0.00'
                            : (
                                100 *
                                (warmCallsAppointment / warmCallsReached)
                              ).toFixed(2)}{' '}
                          %
                        </td>
                      </tr>{' '}
                      {!Number.isNaN(coldCallPercentage) && (
                        <tr>
                          <th>{t('warmCallPercentage')}</th>
                          <td data-testid="warm-calls-percentage-display">
                            {warmCalls > 0
                              ? `${100 - coldCallPercentage}%`
                              : '0.00%'}
                          </td>
                        </tr>
                      )}
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!hideGlobalStatus && (
              <Row>
                <Col>
                  <div>
                    Your Success Rate is better than {successRate} % of your
                    callings!
                  </div>
                  <div>
                    Your Appointment Rate is better than {appointmentRate} % of
                    your callings!
                  </div>
                </Col>
                <div className="float-right">
                  <Button
                    data-testid="get-global-stats-button"
                    color="primary"
                    className="float-right"
                    size="m"
                    onClick={() => getGlobalStats()}
                  >
                    {t('getGlobalStats')}
                  </Button>
                </div>
              </Row>
            )}
          </>
        )}
      </CardBody>
      {Array.isArray(activities) && activities.length > 0 && (
        <Container fluid>
          <Card>
            <CardHeader>
              <h1>{t('activitiesDuringCallSession')}</h1>
            </CardHeader>
            <CardBody>
              <DynamicTable data={newTableData} columns={preparedColumns} />
            </CardBody>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default CallStatistics;
