/* eslint-disable @typescript-eslint/no-floating-promises */
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError, AxiosResponse } from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Table,
} from 'reactstrap';
import { v4 } from 'uuid';

import axios from '../../../services/axios/axios';
import InputFormLabel from '../../../components/form/InputFormLabel';
import {
  maxEmailCount,
  maxMobileCount,
  maxPhoneCount,
  maxProfileCount,
  preferredLanguages,
  READWRITE,
  SAVE,
  SAVE_AND_GOTO_DETAILS,
} from '../../../utils/constants';
import { BUTTON_TITLE_ENUM } from '../../../utils/enums/pageComponents';
import { ContactInfoType } from '../../../utils/enums/contact';
import {
  ContactInformationStatus,
  GENDER_ENUM,
} from '../../../utils/enums/contactPerson';
import { PERMISSION_URI } from '../../../utils/enums/permission';
import { isNullOrUndefinedOrEmpty } from '../../../utils/helpers/GenericHelper';
import { generateTitle } from '../../../utils/helpers/icon';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import i18n from '../../../i18n';
import { RootState } from '../../../redux/store';
import {
  IDropdownOption,
  IDropdownOptionInvolved,
  IObjectContactInformation,
  IObjectInvolvedResponsible,
  IObjectNameAndId,
} from '../../../utils/types/commonTypes';
import {
  IContactInformation,
  IEditedContactPersonDetail,
  ITitle,
} from '../../../utils/types/modelTypes';
import { clickHandler } from '../../../utils/helpers/click';
import {
  IContactPersonDetail,
  IEmployeeListItem,
  IObjectContactPersonCustomerSite,
} from '../../../utils/types/responseTypes';
import {
  isEmpty,
  isInvolvedResponsiblesNotModified,
  isStringArraySimilar,
} from '../../../utils/helpers/array';
import {
  getContactPersonDetails,
  updateContactPersonDetails,
  saveContactPersonDetails,
} from '../../../services/api/contactPerson';
import { getEmployeeListItems } from '../../../services/api/employee';
import ContactPersonTopicSelect from './ContactPersonTopicSelect';
import MultipleSectorSelect from '../../../components/dropdowns/MultipleSectorSelect';
import ContactPersonCustomerSiteSelect from './ContactPersonCustomerSiteSelect';
import MultipleResponsibleDropdown from '../../../components/form/MultipleResponsibleDropdown';
import {
  distributeFieldValues,
  generateContactDetailState,
  getEditedFieldValues,
} from '../ContactDetails/contactPersonHelper';
import { objectTypeEnum } from '../../../utils/enums/enum';
import ContactRoleItem from './ContactRoleItem';
import { getCorrectContactNumberInput } from '../../../utils/helpers/string';
import ContactInformationInput from '../../../components/form/ContactInformationInput';
import { OBJECT_TYPE_ENUM } from '../../../utils/enums/objectType';

interface IProps extends PropsFromRedux, IWithModalsProps {
  contactPersonId: number | null;
  customer: IObjectNameAndId | null;
  customerSectors: IObjectNameAndId[];
  onUpdate: (
    contactPerson: IContactPersonDetail,
    afterSaveAction: string
  ) => void;
  onCancel: () => void;
}

const t = (keyName: string) => i18n.t(`ContactPersonModal.${keyName}`);

const AddOrUpdateContactPersonModal = ({
  // WithModals
  modalDeleteHandler,
  modalConfirmHandler,
  modalErrorHandler,
  userNameAndId,
  contactPersonId,
  customer,
  customerSectors,
  onUpdate,
  onCancel,
}: IProps) => {
  const [formValues, setFormValues] = useState<IEditedContactPersonDetail>(
    generateContactDetailState(customer, userNameAndId)
  );

  const [employeesWithPermission, setEmployeesWithPermission] = useState<
    IEmployeeListItem[]
  >([]);
  const [responsibleSelection, setResponsibleSelection] = useState<
    IEmployeeListItem[]
  >([]);
  const [allTitles, setAllTitles] = useState<IDropdownOption[]>([]);
  const [disableSave, setDisableSave] = useState(true);
  const [filteredInvolvedIds, setFilteredInvolvedIds] = useState<number[]>();
  const [originalDetails, setOriginalDetails] =
    useState<IEditedContactPersonDetail>(
      generateContactDetailState(customer, userNameAndId)
    );
  const [sectorsFromSite, setSectorsFromSite] = useState<
    IObjectNameAndId[] | null
  >(null);
  const [fieldsWithUpdates, setFieldsWithUpdates] = useState<string[]>([]);

  const {
    firstname,
    lastname,
    sectors,
    responsible,
    involvedEmployees,
    contactInformation,
    title,
    gender,
    role,
    topics,
    customerSite,
    preferredLanguage,
  } = formValues;

  const fetchContactPersonDetails = async (contactPersonId: number) => {
    try {
      const { data: fetchedData } = await getContactPersonDetails(
        contactPersonId
      );
      const { contactInformation = [] } = fetchedData;
      const fetchedDataInitialized = {
        ...fetchedData,
        contactInformation: contactInformation.map((obj) => ({
          ...obj,
          uuid: v4(),
        })),
      };
      setFormValues(distributeFieldValues(fetchedDataInitialized));
      setOriginalDetails(distributeFieldValues(fetchedDataInitialized));
    } catch (error) {
      modalErrorHandler(t('failedToRetrieveContactPersonDetails'));
    }
  };

  const fetchEmployeesWithPermission = async () => {
    const { data: fetchedEmployees } = await getEmployeeListItems({
      'permissionsFilter.in': PERMISSION_URI.contactPeopleList.readWrite.uri,
      'accessTypeFilter.in': READWRITE,
    });
    setEmployeesWithPermission(fetchedEmployees);
  };

  const fetchAllTitles = async () => {
    await axios.sales
      .get('titles')
      .then((res: AxiosResponse<ITitle[]>) => {
        if (!isEmpty(res.data)) {
          setAllTitles(
            res.data.map(
              (title) => ({ label: title.title, value: title.id } as any)
            )
          );
        }
      })
      .catch((error: AxiosError) => {
        modalErrorHandler(t('failedToGetAllTitles'), error);
      });
  };

  const disableButton = (type: string, value: number) =>
    contactInformation.filter((contact) => contact.type === type).length ===
    value;

  const updateFieldsWithChanges = (fieldValue: string, noChange: boolean) => {
    setFieldsWithUpdates((prevFields) => {
      if (noChange) {
        return prevFields.filter((item) => item !== fieldValue);
      }
      if (prevFields.includes(fieldValue)) {
        return prevFields;
      }
      return [...prevFields, fieldValue];
    });
  };

  // Event handlers

  const handleMultipleSectorSelectionChange = (sectors: IObjectNameAndId[]) => {
    if (sectors.length > 0) {
      setFormValues({
        ...formValues,
        sectors,
      });
      updateFieldsWithChanges(
        'sectors',
        isStringArraySimilar(
          sectors.map((sector) => sector.name),
          originalDetails.sectors.map((sector) => sector.name)
        )
      );
    }
  };

  const handleTextBoxChange = (
    { target: { value } }: ChangeEvent<HTMLInputElement>,
    property: string
  ) => {
    setFormValues({ ...formValues, [property]: value });
    updateFieldsWithChanges(property, value === originalDetails[property]);
  };

  // Handles changes on react-select component that will update a state with type IObjectNameAndId
  const handleSelectChange = (
    { value, label }: IDropdownOption,
    property: string
  ) => {
    if (property === 'gender') {
      setFormValues({
        ...formValues,
        [property]: value,
      });
      updateFieldsWithChanges(property, value === originalDetails[property]);
    } else {
      setFormValues({
        ...formValues,
        [property]: {
          id: value,
          name: label,
        } as unknown as IObjectNameAndId,
      });
      updateFieldsWithChanges(
        property,
        value === (originalDetails[property] as IObjectNameAndId)?.id.toString()
      );
    }
  };

  const handleSetResponsible = (involved: IDropdownOptionInvolved[]) => {
    const involvedEmployees = involved.map(
      ({ responsible, responsibleRole }) =>
        ({
          ...(responsible
            ? {
                id: responsible?.value,
                name: responsible?.label,
              }
            : {}),
          ...(responsibleRole
            ? {
                responsibleRole: {
                  id: responsibleRole?.value,
                  name: responsibleRole?.label,
                },
              }
            : {}),
        } as IObjectInvolvedResponsible)
    );
    setFormValues({
      ...formValues,
      involvedEmployees,
    });
    updateFieldsWithChanges(
      'involvedEmployees',
      isInvolvedResponsiblesNotModified(
        involvedEmployees,
        originalDetails.involvedEmployees
      )
    );
  };

  const addContactInformation = (infoType: string) => {
    const newContactInformation: IObjectContactInformation = {
      id: 0,
      info: '',
      status: ContactInformationStatus.UNKNOWN,
      type: infoType,
      uuid: v4(),
    };
    setFormValues({
      ...formValues,
      contactInformation: [...contactInformation, newContactInformation],
    });

    updateFieldsWithChanges('contactInformation', false);
  };

  const handleTopicsChange = (selectedTopics: IDropdownOption<number>[]) => {
    const topics = !isEmpty(selectedTopics)
      ? selectedTopics.map((topic) => ({
          id: topic.value,
          name: topic.label,
        }))
      : [];
    setFormValues({
      ...formValues,
      topics,
    });
    updateFieldsWithChanges(
      'topics',
      isStringArraySimilar(
        topics.map((topic) => topic.name),
        originalDetails.topics.map((topic) => topic.name)
      )
    );
  };

  const handleCustomerSiteChange = (
    customerSite: IObjectContactPersonCustomerSite
  ) => {
    setFormValues({ ...formValues, customerSite });
    updateFieldsWithChanges(
      'customerSite',
      customerSite?.id === originalDetails.customerSite?.id
    );
    setSectorsFromSite(customerSite.sectors);
    handleMultipleSectorSelectionChange(customerSite.sectors);
  };

  const handleContactPersonRoleChange = (role: string) => {
    setFormValues({
      ...formValues,
      role,
    });
    updateFieldsWithChanges('role', role === formValues.role);
  };

  const handleEmailStatusChange = (checked: string, index: number) => {
    const contactInformations = contactInformation;
    const newContactInformation = contactInformations[index];

    if (newContactInformation) {
      newContactInformation.status = checked
        ? ContactInformationStatus.GUESSED
        : ContactInformationStatus.UNKNOWN;

      contactInformations[index] = newContactInformation;

      setFormValues({
        ...formValues,
        contactInformation: contactInformations,
      });
    }
  };

  const handleContactInformationChanges = (value: string, index: number) => {
    const contactInformations = contactInformation;
    const newContactInformation = contactInformations[index];
    let newValue = value;

    if (newContactInformation) {
      if (newValue.trim() === '') {
        newContactInformation.info = '';
        contactInformations[index] = newContactInformation;
      } else {
        // Checks if our current contactinformation is a mobile or a phone
        if (
          newContactInformation.type === ContactInfoType.MOBILE ||
          newContactInformation?.type === ContactInfoType.PHONE
        ) {
          newValue = getCorrectContactNumberInput(
            newContactInformation.info,
            newValue
          );
        }

        newContactInformation.info = value;
        contactInformations[index] = newContactInformation;
      }
      setFormValues({
        ...formValues,
        contactInformation: contactInformations,
      });
    }
  };

  const handleSaveContactPersonDetails = (
    detailToSave: IEditedContactPersonDetail,
    afterSaveAction: string
  ) => {
    if (detailToSave.id === null) {
      saveContactPersonDetails({
        ...detailToSave,
        customer,
      } as unknown as IContactPersonDetail)
        .then(({ data }) => {
          onUpdate(data, afterSaveAction);
        })
        .catch((error) =>
          modalErrorHandler(t('failedToSaveContactPerson'), error)
        );
    } else {
      updateContactPersonDetails(
        detailToSave as unknown as IContactPersonDetail
      )
        .then(({ data }) => {
          onUpdate(data, afterSaveAction);
        })
        .catch((error) =>
          modalErrorHandler(t('updateContactPersonFailed'), error)
        );
    }
  };

  const handleSaveWithSectors = (
    detailToSave: IEditedContactPersonDetail,
    afterSaveAction: string
  ) => {
    const sectorsToCompare = sectorsFromSite ?? customerSectors;
    const inheritedFrom = sectorsFromSite
      ? objectTypeEnum.customerSite.name
      : objectTypeEnum.customer.name;
    // Check if all present sectors are existing in sectorsToCompare

    const unlinkedSectors = detailToSave.sectors.filter(
      (newSector) =>
        !sectorsToCompare.find(
          (oldSector: IObjectNameAndId) => newSector.id === oldSector.id
        )
    );
    if (isEmpty(unlinkedSectors)) {
      handleSaveContactPersonDetails(detailToSave, afterSaveAction);
    } else {
      modalConfirmHandler(
        t('warning'),
        <>
          {t('warningCauseDialog')}
          {inheritedFrom}:
          <br />
          <strong>
            {unlinkedSectors.map((sector) => sector.name).join(', ')}
          </strong>
          <br />
          {t('warningEffectDialog')}
        </>,
        () => {
          handleSaveContactPersonDetails(detailToSave, afterSaveAction);
        }
      );
    }
  };

  const addUpdateContactPersonDetails = (afterSaveAction: string) => {
    const detailToSave = {
      id: contactPersonId,
      ...getEditedFieldValues(fieldsWithUpdates, formValues),
    };
    if (fieldsWithUpdates.includes('contactInformation')) {
      detailToSave.contactInformation = detailToSave.contactInformation.filter(
        (contactInfo) => contactInfo.info !== ''
      );
    }
    if (fieldsWithUpdates.includes('sectors')) {
      handleSaveWithSectors(detailToSave, afterSaveAction);
    } else {
      handleSaveContactPersonDetails(detailToSave, afterSaveAction);
    }
  };

  const deleteContactInformation = (
    selectedContactInformation: IContactInformation,
    index: number
  ) => {
    if (selectedContactInformation.id) {
      modalDeleteHandler(
        t('deleteContactInfo'),
        t('deleteContactInfoConfirm'),
        async () => {
          await axios.sales
            .delete(`contact-informations/${selectedContactInformation.id}`)
            .catch((error: AxiosError) => {
              modalErrorHandler(t('deleteContactInfoFailed'), error);
            });

          const newContactInformations = contactInformation.filter(
            (info) =>
              info.info !== selectedContactInformation.info &&
              info.type !== selectedContactInformation.contactInformationType
          );
          setFormValues({
            ...formValues,
            contactInformation: newContactInformations,
          });
        }
      );
    } else {
      const contactInformations = contactInformation;
      contactInformations.splice(index, 1);
      setFormValues({
        ...formValues,
        contactInformation: contactInformations,
      });
    }
  };

  useEffect(() => {
    setFilteredInvolvedIds([
      responsible.id,
      ...involvedEmployees.map((involved) => involved.id),
    ]);
  }, [responsible, involvedEmployees]);

  useEffect(() => {
    if (contactPersonId != null) {
      // Get details contact person
      fetchContactPersonDetails(contactPersonId);
    }

    fetchEmployeesWithPermission();
    fetchAllTitles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDisableSave(
      isNullOrUndefinedOrEmpty(firstname) ||
        isNullOrUndefinedOrEmpty(lastname) ||
        isEmpty(sectors)
    );
  }, [firstname, lastname, sectors]);

  useEffect(() => {
    setResponsibleSelection(
      employeesWithPermission.filter(
        (employee) =>
          responsible.id !== employee.id &&
          involvedEmployees.every((involved) => involved.id !== employee.id)
      )
    );
  }, [responsible, involvedEmployees, employeesWithPermission]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <h2>
            {contactPersonId ? t('updateContactPerson') : t('addNewContact')}
          </h2>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Table borderless>
              <tbody>
                <tr>
                  <th scope="row">
                    <InputFormLabel isRequired text={t('name')} />
                  </th>
                  <td>
                    <Row>
                      <Col>
                        <Input
                          type="string"
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            handleTextBoxChange(event, 'firstname');
                          }}
                          value={firstname}
                          placeholder={t('firstName')}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="string"
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            handleTextBoxChange(event, 'lastname');
                          }}
                          value={lastname}
                          placeholder={t('lastName')}
                        />
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: '30%' }}>
                    {t('title')}
                  </th>
                  <td>
                    <Select
                      options={allTitles}
                      value={
                        title != null
                          ? {
                              value: title.id,
                              label: title.name,
                            }
                          : null
                      }
                      onChange={(event: IDropdownOption) =>
                        handleSelectChange(event, 'title')
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">{t('gender')}</th>
                  <td>
                    <Select
                      value={{
                        label: gender,
                        value: gender,
                      }}
                      options={Object.values(GENDER_ENUM).map((gender) => ({
                        label: gender,
                        value: gender.toLowerCase(),
                      }))}
                      onChange={(event: IDropdownOption) =>
                        handleSelectChange(event, 'gender')
                      }
                      placeholder={t('selectGender')}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row"> {t('email')}</th>
                  <td>
                    {contactInformation?.map((info, infoIndex) =>
                      info.type === ContactInfoType.EMAIL ? (
                        <ContactInformationInput
                          key={info.uuid}
                          index={infoIndex}
                          contactInformation={info}
                          onDelete={deleteContactInformation}
                          onChange={handleContactInformationChanges}
                          objectType={OBJECT_TYPE_ENUM.contactPerson.code}
                          onEmailStatusChange={handleEmailStatusChange}
                        />
                      ) : null
                    )}
                    <Button
                      color="primary"
                      size="sm"
                      disabled={disableButton(
                        ContactInfoType.EMAIL,
                        maxEmailCount
                      )}
                      onClick={() =>
                        addContactInformation(ContactInfoType.EMAIL)
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> {t('add')}
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row"> {t('phone')}</th>
                  <td>
                    {contactInformation?.map((info, infoIndex) =>
                      info?.type === ContactInfoType.PHONE ? (
                        <ContactInformationInput
                          key={info.uuid}
                          index={infoIndex}
                          contactInformation={info}
                          onDelete={deleteContactInformation}
                          onChange={handleContactInformationChanges}
                          objectType={OBJECT_TYPE_ENUM.contactPerson.code}
                        />
                      ) : null
                    )}
                    <Button
                      color="primary"
                      size="sm"
                      disabled={disableButton(
                        ContactInfoType.PHONE,
                        maxPhoneCount
                      )}
                      onClick={() =>
                        addContactInformation(ContactInfoType.PHONE)
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> {t('add')}
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row"> {t('mobile')}</th>
                  <td>
                    {contactInformation?.map((info, infoIndex) =>
                      info.type === ContactInfoType.MOBILE ? (
                        <ContactInformationInput
                          key={info.uuid}
                          index={infoIndex}
                          contactInformation={info}
                          onDelete={deleteContactInformation}
                          onChange={handleContactInformationChanges}
                          objectType={OBJECT_TYPE_ENUM.contactPerson.code}
                        />
                      ) : null
                    )}
                    <Button
                      color="primary"
                      size="sm"
                      disabled={disableButton(
                        ContactInfoType.MOBILE,
                        maxMobileCount
                      )}
                      onClick={() =>
                        addContactInformation(ContactInfoType.MOBILE)
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> {t('add')}
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row"> {t('xingProfile')}</th>
                  <td>
                    {contactInformation.map((info, infoIndex) =>
                      info.type === ContactInfoType.XING ? (
                        <ContactInformationInput
                          key={info.uuid}
                          index={infoIndex}
                          contactInformation={info}
                          onDelete={deleteContactInformation}
                          onChange={handleContactInformationChanges}
                          objectType={OBJECT_TYPE_ENUM.contactPerson.code}
                        />
                      ) : null
                    )}
                    <Button
                      color="primary"
                      size="sm"
                      disabled={disableButton(
                        ContactInfoType.XING,
                        maxProfileCount
                      )}
                      onClick={() =>
                        addContactInformation(ContactInfoType.XING)
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> {t('add')}
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row"> {t('linkedInProfile')}</th>
                  <td>
                    {contactInformation.map((info, infoIndex) =>
                      info.type === ContactInfoType.LINKEDIN ? (
                        <ContactInformationInput
                          key={info.uuid}
                          index={infoIndex}
                          contactInformation={info}
                          onDelete={deleteContactInformation}
                          onChange={handleContactInformationChanges}
                          objectType={OBJECT_TYPE_ENUM.contactPerson.code}
                        />
                      ) : null
                    )}
                    <Button
                      color="primary"
                      size="sm"
                      disabled={disableButton(
                        ContactInfoType.LINKEDIN,
                        maxProfileCount
                      )}
                      onClick={() =>
                        addContactInformation(ContactInfoType.LINKEDIN)
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> {t('add')}
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: '30%' }}>
                    {' '}
                    {t('responsible')}
                  </th>
                  <td>
                    <Select
                      value={{
                        value: responsible.id,
                        label: responsible.name,
                      }}
                      options={responsibleSelection.map((employee) => ({
                        value: employee.id,
                        label: `${employee.firstname} ${employee.lastname}`,
                      }))}
                      onChange={(event: IDropdownOption) =>
                        handleSelectChange(event, 'responsible')
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: '30%' }}>
                    {' '}
                    {t('involved')}
                  </th>
                  <td>
                    <MultipleResponsibleDropdown
                      responsibles={involvedEmployees.map(
                        ({ id, name, responsibleRole }) =>
                          ({
                            ...(id && name
                              ? {
                                  responsible: { label: name, value: id },
                                }
                              : {}),
                            ...(responsibleRole
                              ? {
                                  responsibleRole: {
                                    label: responsibleRole.name,
                                    value: responsibleRole.id,
                                  },
                                }
                              : {}),
                          } as {
                            responsible: IDropdownOption<number>;
                            responsibleRole: IDropdownOption<number>;
                          })
                      )}
                      setResponsibles={handleSetResponsible}
                      responsibleOptions={
                        employeesWithPermission
                          ?.filter(
                            (employee) =>
                              !filteredInvolvedIds?.includes(employee.id)
                          )
                          .map(({ id, firstname, lastname }) => ({
                            value: id,
                            label: `${firstname} ${lastname}`,
                          })) ?? ({} as IDropdownOption<number>[])
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>

          <Col>
            <Table borderless>
              <tbody>
                <tr>
                  <th scope="row" style={{ width: '35%' }}>
                    {' '}
                    {t('customer')}
                  </th>
                  <td>
                    <Row>
                      <Col lg="10">
                        <Select
                          value={{
                            value: customer?.id,
                            label: customer?.name,
                          }}
                          isDisabled
                        />
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th scope="row"> {t('role')}</th>
                  <td>
                    <ContactRoleItem
                      role={role}
                      onChange={handleContactPersonRoleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row"> {t('topic')} </th>
                  <td>
                    <ContactPersonTopicSelect
                      topicIds={topics.map((topic) => topic.id)}
                      onChange={handleTopicsChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row"> {t('customerSite')}</th>
                  <td>
                    <ContactPersonCustomerSiteSelect
                      customerId={customer?.id}
                      customerSiteId={customerSite?.id}
                      onChange={handleCustomerSiteChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <InputFormLabel isRequired text={t('sector')} />
                  </th>
                  <td>
                    <MultipleSectorSelect
                      onChange={handleMultipleSectorSelectionChange}
                      sectorIds={sectors.map((sector) => sector.id)}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">{t('mainLanguage')}</th>
                  <td>
                    <Select
                      onChange={(event: IDropdownOption) =>
                        handleSelectChange(event, 'preferredLanguage')
                      }
                      value={preferredLanguages.find(
                        (language) => preferredLanguage === language.value
                      )}
                      options={preferredLanguages}
                      placeholder={i18n.t(
                        'PreferredLanguageDropdown.selectLanguage'
                      )}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <div>
            <Button
              color="primary"
              size="m"
              onClick={(event: MouseEvent) =>
                clickHandler(event, () => addUpdateContactPersonDetails(SAVE))
              }
              disabled={disableSave}
            >
              {generateTitle(BUTTON_TITLE_ENUM.SAVE.code, t('saveAndClose'))}
            </Button>
            <span> </span>

            <Button
              color="primary"
              size="m"
              onClick={(event: MouseEvent) =>
                clickHandler(event, () =>
                  addUpdateContactPersonDetails(SAVE_AND_GOTO_DETAILS)
                )
              }
              disabled={disableSave}
            >
              {generateTitle(
                BUTTON_TITLE_ENUM.SAVE.code,
                t('saveAndGoToDetails')
              )}
            </Button>

            <span> </span>
            <Button color="primary" size="m" onClick={() => onCancel()}>
              {t('cancel')}
            </Button>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (store: RootState) => ({
  userNameAndId: {
    id: store.account.employeeDetails.id,
    name: `${store.account.accountDetails.firstName} ${store.account.accountDetails.lastName}`,
  } as IObjectNameAndId,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withModals(AddOrUpdateContactPersonModal));
