import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { deleteProjectEmployee } from '../../../services/api/project';
import TableCard from '../../../components/cards/TableCard';
import { ProjectState } from '../../../utils/enums/project';
import withModals, { IWithModalsProps } from '../../../utils/withModals';
import { IProject, IProjectEmployee } from '../../../utils/types/modelTypes';
import {
  IProjectMemberListItem,
  IProjectOptionDetails,
} from '../../../utils/types/responseTypes';
import UpdateProjectEmployeeModal from '../../../components/form/UpdateProjectEmployeeModal';
import { hasPermissionToAddResource, t } from './projectOptionHelpers';
import type { ProjectOptionDetailsForm } from './ProjectOptionModal';
import AssignResourceModal from '../../../components/form/AssignResourceForm/AssignResourceModal';

interface IProps extends IWithModalsProps {
  project: IProjectOptionDetails;
  formValues: ProjectOptionDetailsForm;
  setFormValues: Dispatch<SetStateAction<ProjectOptionDetailsForm>>;
}

/**
 * Project Members Card in Project Option Modal -
 * Displays the employees assigned to the project, and allows editing and deleting
 * of project members, and assigning new employees to the project
 */
const ProjectOptionMembers = ({
  project,
  formValues: { state, start, end, projectMembers },
  setFormValues,
  modalDeleteHandler,
  modalErrorHandler,
  modalFormHandler,
  toggleModalForm,
}: IProps) => {
  const disabled =
    state === ProjectState.LOST || state === ProjectState.ORDERED;

  const onSaveProjectMembers = (newProjectMember: IProjectMemberListItem[]) => {
    setFormValues((formValues) => ({
      ...formValues,
      projectMembers: [...projectMembers, ...newProjectMember],
    }));
    toggleModalForm();
  };

  const handleAssignResourceClick = () => {
    modalFormHandler(
      t('assignResource'),
      <AssignResourceModal onSave={onSaveProjectMembers} project={project} />
    );
  };

  // TODO: Refactor assigning resource and editing project member
  const headerButtons = (
    <Button
      aria-label="project-option-members-button-assign-resource"
      color="primary"
      size="sm"
      onClick={handleAssignResourceClick}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={faPlus} />
      <span style={{ marginLeft: '5px' }}>{t('assignResource')}</span>
    </Button>
  );

  const handleDeleteMember = async (idx: number) => {
    try {
      const { id } = projectMembers[idx] as IProjectMemberListItem;
      await deleteProjectEmployee(id);

      const modifiedMembers = [...projectMembers];
      modifiedMembers.splice(idx, 1);

      setFormValues((formValues) => ({
        ...formValues,
        projectMembers: modifiedMembers,
      }));
    } catch (error) {
      modalErrorHandler(t('failedToDeleteProjectEmployee'), error);
    }
  };

  const handleProjectMemberEdit = (id: number, idx: number) => {
    modalFormHandler(
      t('updateEmployee'),
      <UpdateProjectEmployeeModal
        projectEmployeeId={id}
        project={
          {
            start,
            end,
            projectEmployees: [] as IProjectEmployee[],
          } as IProject
        }
        onSave={({ projectEmployeeRole }) => {
          const modifiedMembers = [...projectMembers];
          modifiedMembers.splice(idx, 1, {
            ...(modifiedMembers[idx] as IProjectMemberListItem),
            role: projectEmployeeRole as string,
          });

          setFormValues((formValues) => ({
            ...formValues,
            projectMembers: modifiedMembers,
          }));
          toggleModalForm();
        }}
        onCancel={() => toggleModalForm()}
        onChange={() => {
          // Do something
        }}
      />,
      'lg'
    );
  };

  const handleProjectMemberDelete = (idx: number) => {
    modalDeleteHandler(
      t('deleteProjectMember'),
      t('deleteProjectMemberConfirm'),
      () => handleDeleteMember(idx)
    );
  };

  const tableData = projectMembers.map(
    ({ id, employee: { name }, role }, idx) => ({
      [t('employee')]: (
        <Link to={`/projects/overview/${project.id}/view-employee/${id}`}>
          {name}
        </Link>
      ),
      [t('role')]: t(`${role.toLowerCase()}`),
      '': (
        <div className="table-buttons float-end">
          <Button
            aria-label="project-option-members-button-edit"
            color="primary"
            onClick={() => handleProjectMemberEdit(id, idx)}
            disabled={disabled}
          >
            <FontAwesomeIcon icon={faPen} />
          </Button>
          <Button
            aria-label="project-option-members-button-delete"
            color="primary"
            onClick={() => handleProjectMemberDelete(idx)}
            disabled={disabled}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
    })
  );

  return (
    <TableCard
      title={t('projectMembers')}
      tableData={tableData}
      {...(hasPermissionToAddResource(false) && {
        headerButtons,
      })}
      noDataPlaceholder={t('noData')}
      border
    />
  );
};

export default withModals(ProjectOptionMembers);
