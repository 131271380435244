import React, { CSSProperties, ElementType, ReactNode } from 'react';
import classNames from 'classnames';

interface IProps {
  children?: ReactNode;
  className?: string;
  tag?: ElementType;
  style?: CSSProperties;
}

const HeaderTitle: React.FC<IProps> = ({
  children,
  className = '',
  tag: Tag = 'h1',
  ...rest
}) => (
  <Tag className={classNames('header-title', className)} {...rest}>
    {children}
  </Tag>
);

export default HeaderTitle;
