import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
} from 'reactstrap';

import Header from '../../../components/layout/Header';
import HeaderTitle from '../../../components/layout/HeaderTitle';
import { generateBreadcrumb } from '../../../utils/helpers/generateBreadcrumb';
import EmployeeListTable from './EmployeeListTable';
import { t } from './employeeListHelpers';
import { toggleFilter } from '../../../redux/employeeListSlice';

interface IProps extends PropsFromRedux, RouteComponentProps {}

/**
 * Employee List page.
 * Contains Employee List Table, and toggling of table filter.
 */
const EmployeeList = ({
  // Redux actions
  toggleFilter,
  // Route
  location,
}: IProps) => (
  <Container fluid>
    <Header>
      <HeaderTitle>{t('employeeList')}</HeaderTitle>
      {generateBreadcrumb(location.pathname, t('employees'))}
    </Header>
    <Card>
      <CardHeader>
        <div className="card-actions float-end">
          <Button
            color="primary"
            className="fontAwesomeIconAsButton"
            onClick={() => toggleFilter()}
          >
            <FontAwesomeIcon icon={faFilter} />
          </Button>
        </div>
        <CardTitle className="mb-0">
          <h1>{t('employeeList')}</h1>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <EmployeeListTable />
      </CardBody>
    </Card>
  </Container>
);

const mapDispatchToProps = {
  toggleFilter,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(EmployeeList));
